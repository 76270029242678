<template>
  <template v-if="shouldRender">
    <slot />
  </template>
</template>

<script setup lang="ts">
import { nextTick, ref } from '@/composition/vue/compositionApi'

const shouldRender = ref<boolean>(false)

const onIdle = () => {
  nextTick(() => {
    shouldRender.value = true
  })
}

onIdle()
</script>

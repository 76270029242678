import { inject, injectable } from 'inversify'
import type { RegisteredServices } from '@/core/container/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type LoggerService from '@/services/loggerService'
import type { Factory } from '@/types'
import type TableElementManagerInterface from '@/services/tables/managers/tableElementManagerInterface'
import type { LoggerChannels } from '@/config/configDev'
import { TmTableElementManagerFactoryNotFoundError } from '@/core/error/table/tableManager/tmTableElementManagerFactoryNotFoundError'
import { TmTableElementManagerFactoryInvalidError } from '@/core/error/table/tableManager/tmTableElementManagerFactoryInvalidError'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import { TmTableElementManagerServiceNotFoundError } from '@/core/error/table/tableManager/tmTableElementManagerServiceNotFoundError'
import type { IServiceManager } from '@/core/middlewares/types'
import type { Service } from '@/config/types'
import type { GetLocator } from '@/core/container/container'

@injectable()
export default abstract class TableElementManagerBase<T, S extends Record<string, unknown> = Record<string, unknown>>
  implements TableElementManagerInterface<T, S>, IServiceManager
{
  protected abstract readonly managerId: string

  protected abstract readonly loggerChannel: LoggerChannels

  protected factories = new Set<RegisteredServices>()

  protected availableFactories: Partial<Record<RegisteredServices, Factory<T>>> = {}

  protected tableFactories: Record<string, RegisteredServices> = {}

  protected services: Record<string, T> = {}

  protected tables: Record<string, Set<string> | undefined> = {}

  @inject('GetLocator')
  protected readonly get: GetLocator

  protected constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {}

  private getTableKey(tableId: string) {
    return `${this.managerId}.${tableId}`
  }

  private getTableServices(tableId: string) {
    return this.tables[this.getTableKey(tableId)]
  }

  private addTableService(tableId: string, serviceId: string) {
    const tableKey = this.getTableKey(tableId)
    if (!this.tables[tableKey]) {
      this.tables[tableKey] = new Set<string>([serviceId])
    } else {
      this.tables[tableKey]?.add(serviceId)
    }
  }

  private removeTableService(tableId: string, serviceId: string) {
    const tableKey = this.getTableKey(tableId)
    if (!this.tables[tableKey]) {
      return
    }
    this.tables[tableKey]?.delete(serviceId)
  }

  public addService(service: Service): void {
    this.factories.add(service.id as RegisteredServices)
  }

  public registerFactory(serviceId: RegisteredServices, factory: Factory<T>): void {
    this.availableFactories = {
      ...this.availableFactories,
      [serviceId]: factory,
    }

    this.log(`Registered new factory: "${serviceId}"`, 'registerFactory')
  }

  public validateFactory(serviceId: RegisteredServices): boolean {
    return this.factories.has(serviceId) // serviceId in this.availableFactories
  }

  public hasFactoryForTable(tableId: string): boolean {
    return tableId in this.tableFactories
  }

  public getFactoryForTable(tableId: string): Factory<T> {
    const serviceId = this.tableFactories[tableId]
    if (!serviceId) {
      throw new TmTableElementManagerFactoryNotFoundError(`No factory "${serviceId}" is registered`, { tableId })
    }

    const factory = this.getFactoryByServiceId(serviceId)
    if (!factory) {
      throw new TmTableElementManagerFactoryNotFoundError('Service factory for table must be set before getting it', {
        tableId,
      })
    }

    return factory
  }

  public setFactoryForTable(tableId: string, serviceId: RegisteredServices): Factory<T> {
    if (this.hasFactoryForTable(tableId)) {
      this.log(`Using existing service factory "${serviceId}" for table "${tableId}"`, 'setFactoryForTable')
      return this.getFactoryForTable(tableId)
    }

    if (!this.validateFactory(serviceId)) {
      throw new TmTableElementManagerFactoryInvalidError(
        `The service "${serviceId}" you've provided is not a valid factory`,
        { tableId },
      )
    }

    this.tableFactories[tableId] = serviceId

    this.log(`Set table "${tableId}" service factory to "${serviceId}"`, 'setFactoryForTable')
    return this.getFactoryForTable(tableId)!
  }

  public hasServicesForTable(tableId: string): boolean {
    const tableServices = this.getTableServices(tableId)
    return !!tableServices && tableServices.size > 0
  }

  public hasServiceForTable(tableId: string, serviceKey: string): boolean {
    const serviceId = this.getServiceIdForTableByKey(tableId, serviceKey)
    const tableServices = this.getTableServices(tableId)
    return !!tableServices && tableServices.has(serviceId)
  }

  public getServicesForTable(tableId: string): Record<string, T> {
    const tableServices = this.getTableServices(tableId)

    if (!tableServices) {
      return {}
    }

    return Object.fromEntries([...tableServices].map((serviceId) => [serviceId, this.getServiceByServiceId(serviceId)]))
  }

  public getServiceForTable(tableId: string, serviceKey: string): T {
    const serviceId = this.getServiceIdForTableByKey(tableId, serviceKey)
    const tableServices = this.getTableServices(tableId)

    if (!tableServices?.has(serviceId)) {
      throw new TmTableElementManagerServiceNotFoundError(`There is no such service with key "${serviceKey}"`, {
        tableId,
      })
    }

    return this.getServiceByServiceId(serviceId)
  }

  public getFirstServiceForTable(tableId: string): T {
    return this.getServiceForTable(tableId, '0')
  }

  public removeFirstServiceForTable(tableId: string): void {
    return this.removeServiceForTable(tableId, '0')
  }

  public getServiceByServiceId(serviceId: string): T {
    return this.services[serviceId]
  }

  public addServiceForTable(tableId: string, settings = {}) {
    const instance = this.getFactoryForTable(tableId)()
    const key = this.getNextServiceKeyForTable(tableId)
    const serviceId = this.getServiceIdForTableByKey(tableId, key)

    this.services[serviceId] = instance
    this.addTableService(tableId, serviceId)

    this.log(`Add new service "${serviceId}"`, 'addServiceForTable')
    return {
      serviceId,
      instance,
    }
  }

  public removeAllServicesForTable(tableId: string) {
    this.log(`Starting removing services for table "${tableId}"...`, 'removeAllServicesForTable')

    this.getTableServices(tableId)?.forEach((serviceId) => this.removeServiceById(tableId, serviceId))

    this.log(`Removing services for table "${tableId}" completed`, 'removeAllServicesForTable')
  }

  public removeServiceForTable(tableId: string, serviceKey: string): void {
    return this.removeServiceById(tableId, this.getServiceIdForTableByKey(tableId, serviceKey))
  }

  public cleanup() {
    this.tableFactories = {}
    this.services = {}
    this.tables = {}
  }

  /**
   * @param {string} tableId
   * @param {string} serviceKey
   * @return {string} Unique qualified key for given {@link tableId}/{@link serviceKey} pair (i.e. _"myTable.0"_ for the first service of the _"myTable"_ table)
   */
  protected getServiceIdForTableByKey(tableId: string, serviceKey: string): string {
    return [tableId, serviceKey].join('.')
  }

  protected parseServiceId(serviceId: string) {
    const [tableId, serviceKey] = serviceId.split('.')
    return {
      tableId,
      serviceKey,
    }
  }

  /**
   * @param {string} tableId
   * @return {string} Nearest next integer for a range of existing numeric keys
   * @protected
   */
  protected getNextServiceKeyForTable(tableId: string): string {
    const tableServices = this.getTableServices(tableId)

    const lastServiceId = [...(tableServices || [])].at(-1)
    const { serviceKey: lastServiceKey } = lastServiceId ? this.parseServiceId(lastServiceId) : { serviceKey: null }

    if (!lastServiceKey) {
      return '0'
    }

    return `${parseInt(lastServiceKey, 10) + 1}`
  }

  protected getFactoryByServiceId(serviceId: RegisteredServices): Factory<T> | undefined {
    return this.get(serviceId)
  }

  protected removeServiceById(tableId: string, serviceId: string) {
    this.log(`Remove service "${serviceId}"`, 'removeServiceByServiceId')

    this.removeTableService(tableId, serviceId)
    delete this.services[serviceId]
  }

  protected log(message: string, methodName?: string) {
    const subchannel = methodName ? `manager:${methodName}` : 'manager'

    if (this.loggerService.shouldLogByChannel(this.loggerChannel, [subchannel])) {
      this.loggerService.log(this.loggerChannel, message, subchannel)
    }
  }
}

import { AttrField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

export enum EmailCampaignMessageStatus {
  Sent = 'sent',
  Failed = 'failed',
  Delivered = 'delivered',
  Rejected = 'rejected',
  Pending = 'pending',
}

@OrmModel(modelNames.EMAIL_CAMPAIGN_MESSAGE)
export default class EmailCampaignMessage extends BaseModel {
  @StringField()
  public sendTime: string

  @AttrField()
  public fromName: string | null

  @StringField()
  public fromEmail: string

  @StringField()
  public replyToEmail: string

  @AttrField()
  public contactId: number | null

  @AttrField()
  public recipientFullName: string | null

  @AttrField()
  public recipientEmail: string | null

  @StringField()
  public emailSubject: string

  @StringField()
  public emailContent: string

  @StringField()
  public source: unknown

  @StringField()
  public status: EmailCampaignMessageStatus

  @NumberField()
  public cost: number

  @AttrField()
  public statusReason: string | null

  @StringField()
  public initiatorId: string
}

import { inject, injectable } from 'inversify'
import type { EndpointParams } from '@/services/endpoints'
import type { PaginationUrlFilterType, PaginationUrlParametersSortType } from '@/services/tables/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type PhoneService from '@/services/phoneService'
import type { AutocompletableBase, AutocompleteResultBase, PhoneAutocompleteItem } from '@/services/types'

@injectable()
export default class PhoneValidateAutocompleteService implements AutocompletableBase<PhoneAutocompleteItem> {
  constructor(@inject(SERVICE_TYPES.PhoneService) protected readonly phoneService: PhoneService) {}

  public async autocomplete(
    search: string,
    endpointParams: EndpointParams,
    page: number,
    filters: PaginationUrlFilterType,
    perPage?: number,
    sort?: PaginationUrlParametersSortType,
  ): Promise<AutocompleteResultBase<PhoneAutocompleteItem>> {
    const items: PhoneAutocompleteItem[] = []
    if (this.phoneService.isValidPhoneNumber(search)) {
      items.push({
        value: search,
        country: this.phoneService.getCountryByPhone(search)!,
      })
    }
    return {
      items,
      pageCount: 1,
    }
  }
}

import { inject, injectable } from 'inversify'
import { isMatch, uniq } from 'lodash-es'
import { SERVICE_TYPES } from '@/core/container/types'
import type ValidationService from '@/services/validation/validationService'
import type {
  FieldValidationErrorEventPayload,
  FormValidatorInterface,
  ParamsOfFormGroupValidation,
  ParamsOfFormValidationById,
  ParamsOfFormValidationByName,
  ErrorInitiator,
  TpFormData,
  ValidationInitiator,
} from '@/services/forms/types'
import { ValidationModes } from '@/services/forms/types'
import type { LowLevelUpdateBody } from '@/services/vuex/types'
import type { ActionsMap, ActionType, FormBuilderInterface } from '@/services/forms/baseForm/types'
import type SubscriptionService from '@/services/transport/subscriptionService'
import type { InternalErrorResponse, ServerErrors } from '@/services/transport/types'

import type BaseFieldRepository from '@/data/repositories/form/baseFieldRepository'
import type BaseFormRepository from '@/data/repositories/form/baseFormRepository'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import { TmFormValidatorError } from '@/core/error/tmFormValidatorError'
import type LoggerService from '@/services/loggerService'
import type FormManager from '@/services/forms/baseForm/formManager'
import type BaseForm from '@/services/forms/baseForm'
import type { ValidationRule } from '@/services/validation/types'
import type { Dict } from '@/types'
import type BaseFormService from '@/services/forms/baseFormService'
import type { Forms } from '@/services/forms/formTypes'
import { TmIncorrectContractError } from '@/core/error/tmIncorrectContractError'
import type { MonitoringServiceInterface } from '@/services/monitoring/types'
import { LogLevel } from '@/core/logger/types'
import type BaseFieldModel from '@/data/models/forms/BaseFieldModel'

const defaultFormValidationState = {
  valid: false,
  invalid: false,
  changed: false,
  touched: false,
  untouched: true,
  pristine: true,
  dirty: false,
  pending: false,
  required: false,
  validated: false,
  passed: false,
  failed: false,
}
const defaultValidationState = {
  ...defaultFormValidationState,
  errors: [],
  serverErrors: [],
  subfieldServerErrors: {},
  hasSomeValid: false,
  hasEveryValid: true,
}

@injectable()
export default class FormValidationService implements FormValidatorInterface {
  protected validationErrorsSuccessHandlersMap: boolean[] = []

  constructor(
    @inject(SERVICE_TYPES.ValidationService) protected readonly validationService: ValidationService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.FormManager) protected readonly formManager: FormManager,
    @inject(SERVICE_TYPES.MonitoringService) protected readonly monitoringService: MonitoringServiceInterface,
    @inject(SERVICE_TYPES.BaseFormRepository) protected readonly baseFormRepository: BaseFormRepository,
    @inject(SERVICE_TYPES.BaseFieldRepository) protected readonly baseFieldRepository: BaseFieldRepository,
  ) {}

  public async onInputAction(id: string, actionType: ActionType, formId: Forms) {
    this.loggerService.log('formValidation', `Start; fieldName=${id}, actionType=${actionType}`, 'onInputAction')

    const form = this.formManager.getForm(formId)
    const field = this.getFormField(form, id, actionType)
    if (!field) return

    const dataForUpdate = this.actionsMap[actionType](field)

    if (actionType === 'input') {
      const changeSet = {
        touched: true,
        untouched: false,
        pristine: false,
      }

      this.updateField([
        {
          id: field.id,
          ...dataForUpdate,
        },
      ])
      this.updateForm({
        id: formId,
        ...changeSet,
      })
    }

    if (this.shouldValidate(field, actionType)) {
      this.loggerService.log('formValidation', `Field ${id} should validate`, 'onInputAction')
      await this.validate({
        id,
        formId,
        validationInitiator: 'field',
      })
    }

    if (field.crossField.length) {
      this.loggerService.log('formValidation', `Field ${id} has crossField`, 'onInputAction')
      for (const crossFieldName of field.crossField) {
        await this.validate({
          id: form.getBuilder().getFieldId(crossFieldName, ''),
          formId,
          validationInitiator: 'crossField',
        })
      }
    }

    this.loggerService.log('formValidation', `Finish; fieldName=${id}, actionType=${actionType}`, 'onInputAction')
    this.updateField([
      {
        id: field.id,
        ...dataForUpdate,
      },
    ])
  }

  protected shouldValidate(field: BaseFieldModel, actionType: string): boolean {
    const { validationMode } = field
    const isBluring = actionType === 'blur'
    const isInputing = actionType === 'input'

    if (validationMode === 'aggressive') return true
    if (validationMode === 'lazy') return isBluring
    if (validationMode === 'eager') {
      if (!field.validated) {
        return isBluring
      }
      if (field.invalid) {
        return isBluring || isInputing
      }

      if (field.valid) {
        return isBluring
      }
    }
    if (validationMode === 'onSubmit') {
      return field.invalid && isInputing
    }

    return false
  }

  public async validateByName({ name, ...commonParams }: ParamsOfFormValidationByName): Promise<boolean> {
    const form = this.formManager.getForm(commonParams.formId)
    const { field } = form.getField(name)
    return this.validate({
      ...commonParams,
      id: field.id,
    })
  }

  public async validate({
    id,
    formId,
    shouldValidateChild = true,
    formData,
    shouldSyncFormValidation = true,
    validationInitiator,
  }: ParamsOfFormValidationById): Promise<boolean> {
    this.loggerService.log('formValidation', `Start; fieldName=${id}`, 'validate')
    const form = this.formManager.getForm(formId)
    const _formData = formData ?? form.getFormData('object')
    const formField = form.getFieldById(id)
    const { field } = formField
    const localValidators = formField.getLocalValidators()
    const isFieldGroup = this.isFieldGroup(id, formId)

    if (!field.validators && !isFieldGroup) {
      this.loggerService.log('formValidation', `field ${id} is not a group and has no validators`, 'validate')
      this.loggerService.log('formValidation', `Finish, name=${id}, valid=${true}`, 'validate')
      return true
    }

    if (isFieldGroup && shouldValidateChild) {
      return this.validateGroup({
        id,
        formId,
        formData: _formData,
        shouldSyncFormValidation,
        validationInitiator,
      })
    }

    this.updateField([
      {
        id: field.id,
        pending: true,
      },
    ])

    const { validator, values: crossFieldValues } = this.getValidationValues(field, formId, id)
    const value = isFieldGroup ? field : field.value
    this.loggerService.log(
      'formValidation',
      `validationService.validate result: fieldName=${id}, validator=${validator.toString()}`,
      'validate',
    )
    this.loggerService.raw('formValidation', value)
    const { valid, errors, rulesResults } = await this.validationService.validate({
      value,
      validators: validator,
      formValues: _formData,
      crossFieldValues,
      localValidators,
    })
    this.loggerService.log(
      'formValidation',
      `validationService.validate result: fieldName=${id}, valid=${valid}`,
      'validate',
    )

    if (!valid) {
      this.emitValidationFailed(formId, {
        name: form.getNameById(field.id),
        errors,
        initiator: validationInitiator,
      })
    }

    this.updateField([
      {
        id: field.id,
        pending: false,
        validated: true,
        valid,
        invalid: !valid,

        passed: valid,
        failed: !valid,

        errors,
        serverErrors: [],
        subfieldServerErrors: {},
        validationRulesResults: rulesResults,
      },
    ])

    if (shouldSyncFormValidation) {
      this.syncFormValidation(formId)
    }

    this.loggerService.log('formValidation', `Finish, name=${id}, valid=${valid}`, 'validate')
    return valid
  }

  public async validateGroup({
    id,
    formId,
    formData,
    shouldSyncFormValidation = true,
    validationInitiator,
  }: ParamsOfFormGroupValidation) {
    const group = this.formManager.getForm(formId).getFieldById(id)
    return this.groupValidator(formId, group, formData, shouldSyncFormValidation, validationInitiator)
  }

  protected async groupValidator(
    formId: Forms,
    root: BaseForm<BaseFieldModel>,
    formData?: TpFormData,
    shouldSyncFormValidation: boolean = true,
    validationInitiator?: ValidationInitiator,
  ): Promise<boolean> {
    const changeSet = {
      id: root.field.id,
      hasSomeValid: false,
      hasEveryValid: true,
    }

    const res = await Promise.all(
      root.childrens.map((child) => {
        const { id } = child.field
        if (this.isFieldGroup(id, formId)) {
          return this.groupValidator(formId, child, formData, shouldSyncFormValidation, validationInitiator)
        }
        return this.validate({ id, formId, formData, shouldSyncFormValidation, validationInitiator })
      }),
    )

    if (!res.length) {
      changeSet.hasSomeValid = true
    }

    res.forEach((isItemValid) => {
      if (isItemValid) {
        changeSet.hasSomeValid = true
      } else {
        changeSet.hasEveryValid = false
      }
    })

    this.updateField([changeSet])

    return this.validate({
      id: root.field.id,
      formId,
      shouldValidateChild: false,
      formData,
      shouldSyncFormValidation,
      validationInitiator,
    })
  }

  public async validateForm(formId: Forms) {
    this.resetFormValidator(formId)

    const shouldSyncFormValidation = false
    const validationInitiator: ValidationInitiator = 'form'
    const formService = this.formManager.getForm(formId)
    const formObj = formService.getForm().toArray()
    const formData = this.formManager.getForm(formId).getFormData('object')

    this.updateForm({
      id: formId,
      pending: true,
      valid: true,
      invalid: false,
    })

    let isFormValid = true
    const res = await Promise.all(
      formObj.map((field) => {
        const { id } = field.field
        if (this.isFieldGroup(id, formId)) {
          return this.groupValidator(formId, field, formData, shouldSyncFormValidation, validationInitiator)
        }
        return this.validate({ id, formId, formData, shouldSyncFormValidation, validationInitiator })
      }),
    )
    if (res.some((t) => !t)) {
      isFormValid = false
    }

    this.updateForm({
      id: formId,
      pending: false,

      validated: true,
      valid: isFormValid,
      invalid: !isFormValid,

      passed: isFormValid,
      failed: !isFormValid,
    })

    if (!isFormValid) formService.triggerFormEventCallback()

    return isFormValid
  }

  public isValidForm(formId: Forms): boolean {
    const form = this.formManager.getForm(formId).getForm().toArray()
    return !form.some((field) => field.field.invalid)
  }

  public setServerErrors(errorResponse: InternalErrorResponse, formId: Forms) {
    this.initSuccessHandlers()
    if (errorResponse.errors && errorResponse.errors.fields) {
      this.setErrors(errorResponse.errors, formId)
      if (!this.isFullyHandled()) {
        throw new TmIncorrectContractError('Form contains an invalid fields')
      }
    } else if (errorResponse.errors && errorResponse.errors.common) {
      errorResponse.errors.common.forEach((e) => {
        this.setFormErrorMessage(e, formId)
      })
    } else if (errorResponse.message) {
      this.setFormErrorMessage(errorResponse.message, formId)
    }
  }

  public setFormErrorMessage(message: string, formId: Forms) {
    this.formManager.getForm(formId).setFormErrorMessage(message)
  }

  public setErrors(errors: ServerErrors, formId: Forms, fieldPath: string[] = []) {
    const isErrorObject = !Array.isArray(errors) && typeof errors !== 'string'
    const form = this.formManager.getForm(formId)
    if (isErrorObject) {
      Object.entries(errors.fields).forEach(([subFieldName, subErrors]) => {
        const formFieldName = form.getErrorFieldName(subFieldName)
        const subFieldId = [formId, ...fieldPath, subFieldName].join('.')
        if (form.hasErrorField(subFieldId, subFieldName)) {
          this.setErrors(subErrors as ServerErrors, formId, [...fieldPath, formFieldName])
        } else {
          if (isErrorObject) {
            this.monitoringService.logInfo(
              `No supported field for form ${formId} by server error response ${JSON.stringify(errors)}`,
              LogLevel.ERROR,
            )
          }
          if (typeof errors === 'string') {
            this.monitoringService.logInfo(
              `No supported field for form ${formId} by server error response ${errors}`,
              LogLevel.ERROR,
            )
          }
          this.addSuccessHandler(false)
        }
      })
      return
    }

    this.setError(errors, formId, fieldPath, 'server')
  }

  public setError(errors: ServerErrors, formId: Forms, fieldPath: string[], initiator?: ErrorInitiator) {
    const errorsToUpdate = this.calcErrorsList(errors)

    const formService = this.formManager.getForm(formId)

    const fieldId = fieldPath.join('.')
    const fieldIdWithForm = [formId, ...fieldPath].join('.')
    formService.triggerServerErrorHandler(fieldId, errorsToUpdate)

    const subfieldServerErrors = this.calcSubfieldServerErrors(errors)

    const changeSet: LowLevelUpdateBody<BaseFieldModel>[] = [
      {
        id: fieldIdWithForm,
        valid: false,
        invalid: true,
        serverErrors: errorsToUpdate,
        subfieldServerErrors,
      },
    ]

    this.updateField(changeSet)
    this.emitValidationFailed(formId, {
      errors: errorsToUpdate as any,
      name: formService.getNameById(fieldIdWithForm),
      initiator,
    })
  }

  public makeRequired(formId: Forms, name: string) {
    const field = this.getFieldByName(formId, name)
    this.baseFieldRepository.update([
      {
        id: field.id,
        validators: this.validationRulesBuilderService.createRules(field.validators).required().getRules(),
      },
    ])
  }

  public makeOptional(formId: Forms, name: string, deep = false) {
    const form = this.formManager.getForm(formId)
    const formField = form.getField(name)
    const { field } = formField
    this.baseFieldRepository.update([
      {
        id: field.id,
        validators: this.validationRulesBuilderService.createRules(field.validators).optional().getRules(),
      },
    ])
    this.resetFieldValidator(formId, name)
    if (deep && form.isGroupField(formField)) {
      formField.childrens.forEach((child) => this.makeOptional(formId, form.getNameById(child.field.id), deep))
    }
  }

  public changeValidationMode(formId: Forms, name: string, mode: ValidationModes) {
    const { field } = this.formManager.getForm(formId).getField(name)
    this.baseFieldRepository.update([
      {
        id: field.id,
        validationMode: mode,
      },
    ])
  }

  public makeAggresiveMode(formId: Forms, name: string) {
    this.changeValidationMode(formId, name, ValidationModes.aggressive)
  }

  private getValidationFailedEventName(formId: Forms) {
    return `/field-validation-failed/${formId}`
  }

  private emitValidationFailed(formId: Forms, params: FieldValidationErrorEventPayload) {
    this.loggerService.log(
      'formValidation',
      `fieldName: ${params.name}, errors: ${JSON.stringify(params.errors)}`,
      'emitValidationFailed',
    )
    this.subscriptionService.emit(this.getValidationFailedEventName(formId), params)
  }

  public subscribeToValidationFailed(formId: Forms, callback: (params: FieldValidationErrorEventPayload) => void) {
    return this.subscriptionService.subscribe(this.getValidationFailedEventName(formId), callback)
  }

  public unsubscribeFromValidationFailed(key: string) {
    this.subscriptionService.unsubscribe(key)
  }

  public resetFormValidator(formId: Forms) {
    const form = this.formManager.getForm(formId)
    const changeset = form.getChangeset(defaultValidationState)

    this.updateForm({
      id: form.getFormId(),
      ...defaultFormValidationState,
    })

    return this.baseFieldRepository.update(changeset)
  }

  public resetFieldValidator(formId: Forms, fieldName: string) {
    const changeset = this.formManager.getForm(formId).getFieldChangeset(defaultValidationState, fieldName)
    this.baseFieldRepository.update([changeset])
  }

  public getFirstError(fieldId: string, formId: Forms) {
    const form = this.formManager.getForm(formId)
    const field = form.getFieldById(fieldId)

    if (this.isFieldGroup(fieldId, formId)) {
      for (const child of field.childrens) {
        const { errors } = child.field

        if (errors.length > 0) {
          return errors[0]
        }
      }
    }

    const { errors } = field.field

    return errors.length > 0 ? errors[0] : null
  }

  protected getValidationValues(
    field: BaseFieldModel,
    formId: Forms,
    id: string,
  ): { validator: ValidationRule[]; values: { [key: string]: any } } {
    if (!field.validators) {
      throw new TmFormValidatorError('Empty validators')
    }
    const values: { [key: string]: any } = {}
    const updatedValidator = this.replaceIndex(field.validators, field)

    updatedValidator.forEach((validator) => {
      const params = validator.name.match(/@[\w.]+/gi)
      if (params && params.length) {
        const form = this.formManager.getForm(formId)
        params.forEach((param) => {
          const p = param.substring(1)
          values[p] = form.getFieldDataByName(p)
        })
      }
    })

    const valuesKeys = Object.keys(values)
    if (valuesKeys.length) {
      this.updateField([
        {
          id: field.id,
          crossField: uniq([...this.getField(formId, id).crossField, ...valuesKeys]),
        },
      ])
    }

    return {
      validator: updatedValidator,
      values,
    }
  }

  private actionsMap = {
    input(field: BaseFieldModel) {
      return {
        dirty: true,
        pristine: false,
        changed: field.initialValue !== field.value,
      }
    },

    focus(_field: BaseFieldModel) {
      return {
        untouched: false,
      }
    },

    blur(_field: BaseFieldModel) {
      return {
        touched: true,
      }
    },
  } as const satisfies ActionsMap

  private isFieldGroup(id: string, formId: Forms) {
    const fieldConfig = this.formManager.getForm(formId).getFieldById(id).config
    const fieldType = !fieldConfig.modelType ? 'field' : fieldConfig.modelType
    return fieldType === 'fieldGroup' || fieldType === 'fieldArray'
  }

  private getField(formId: Forms, id: string) {
    return this.formManager.getForm(formId).getFieldById(id).field
  }

  private getFieldByName(formId: Forms, name: string) {
    return this.formManager.getForm(formId).getField(name).field
  }

  private replaceIndex = (validators: ValidationRule[], field: BaseFieldModel): ValidationRule[] =>
    validators.map((validator) => ({
      ...validator,
      name: validator.name.replace(/\{index\}/g, String(field.parentIndex)),
    }))

  protected updateField(changeSet: LowLevelUpdateBody<BaseFieldModel>[]) {
    return this.baseFieldRepository.update(changeSet)
  }

  protected updateForm(changeSet: LowLevelUpdateBody<BaseFieldModel>) {
    const formModel = this.baseFormRepository.findEntityByIdOrNull(changeSet.id)
    if (formModel && isMatch(formModel, changeSet)) {
      return undefined
    }
    return this.baseFormRepository.update([changeSet])
  }

  protected initSuccessHandlers(): void {
    this.validationErrorsSuccessHandlersMap = []
  }

  protected isFullyHandled(): boolean {
    return this.validationErrorsSuccessHandlersMap.find((handler: boolean) => !handler) === undefined
  }

  protected addSuccessHandler(isHandled: boolean): void {
    this.validationErrorsSuccessHandlersMap.push(isHandled)
  }

  private syncFormValidation(formId: Forms) {
    const fields = this.baseFieldRepository.getFieldsByForm(formId)
    const allValid = fields.every(({ valid }) => valid)
    this.updateForm({
      id: formId,
      valid: allValid,
      invalid: !allValid,
    })
  }

  private calcErrorsList(errors: ServerErrors): string[] {
    if (Array.isArray(errors)) return errors
    if (typeof errors === 'string') return [errors]
    return []
  }

  private calcSubfieldServerErrors(errors: ServerErrors): Dict<string[]> {
    return Array.isArray(errors) || typeof errors === 'string' || errors.fields.fields
      ? {}
      : (errors.fields as Dict<string[]>)
  }

  private getFormField(form: BaseFormService<FormBuilderInterface>, fieldId: string, actionType: ActionType) {
    try {
      const { field } = form.getFieldById(fieldId)
      return field
    } catch (e) {
      if (actionType === 'blur') return undefined // Ignore error - the field could be deleted at that moment
      throw e
    }
  }
}

import { AttrField, BooleanField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { TmCountryCode } from '@/services/types'

export interface LoginWhitelistIp {
  ip: string
  countryId: TmCountryCode
}
export type TwoFactorType = 'email' | 'phone' | 'totp' | null

@OrmModel(modelNames.ACCOUNT_SETTINGS_SECURITY)
export default class AccountSettingsSecurity extends BaseModel {
  @BooleanField()
  public tfaEnabled: boolean

  @AttrField()
  public loginWhitelistIps: LoginWhitelistIp[]

  @AttrField()
  public defaultTwoFactorType: TwoFactorType

  public get hasPhoneOrTotpTwoFa() {
    return this.tfaEnabled && (this.defaultTwoFactorType === 'phone' || this.defaultTwoFactorType === 'totp')
  }
}

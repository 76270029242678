import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  EmailCampaignRepository,
  EmailCampaignService,
  EmailCampaignDomainRepository,
  EmailCampaignDomainService,
  EmailCampaignEmailSenderRepository,
  EmailCampaignEmailSenderService,

  CampaignsEmailColumnService,
  CampaignsSendersDomainColumnService,
  CampaignsSendersDomainGridable,

  CampaignsSendersFlowTypeDomainFormService,
  CampaignsSendersFlowTypeEmailFormService,
  CampaignsSendersFlowSendModalFormService,
  CampaignsSendersFlowVerifyEmailFormService,
  CampaignsSendersFlowCompleteDomainFormService,
  CampaignsSendersFlowCompleteEmailFormService,
  CampaignsEmailSenderModalFormService,

  EmailCampaignRecipientsRepository,
  EmailCampaignRecipientsService,

  EmailCampaignMessageRepository,
  EmailCampaignMessageService,

  EmailCampaignRecipientService,

  EmailCampaignOpenRepository,
  EmailCampaignOpenService,

  EmailCampaignUnsubscribeRepository,
  EmailCampaignUnsubscribeService,

  EmailCampaignSpamReportRepository,
  EmailCampaignSpamReportService,

  EmailCampaignClickSummaryRepository,
  EmailCampaignClickSummaryService,

  EmailCampaignClickSummaryStatisticsRepository,
  EmailCampaignClickSummaryStatisticsService,

  EmailCampaignClickRepository,
  EmailCampaignClickService,
}

export type EmailCampaignsServices = keyof typeof SERVICES

export const EMAIL_CAMPAIGNS_SERVICE_TYPES: Record<EmailCampaignsServices, symbol> =
  makeServicesTypes<EmailCampaignsServices>(enumKeysToArray<EmailCampaignsServices>(SERVICES))

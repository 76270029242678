import { AttrField, BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { isEqual } from 'lodash-es'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'
import type { FieldValue } from '@/data/models/forms/types'
import type { FieldType, SelectOption, ValidationModes } from '@/services/forms/types'
import type { ValidationRule, ValidationRuleError } from '@/services/validation/types'
import type { ServerErrorFields } from '@/services/transport/types'

@OrmModel(modelNames.BASE_FIELD)
export default class BaseFieldModel extends BaseModel {
  @StringField()
  public id: string

  /**
   * It would prevent focus issue.
   * For instance: editor + editor in expand modal bound to the same baseFieldModel
   * So the last one would be focused
   */
  @AttrField([])
  public inputIds: string[]

  @StringField()
  public formId: string

  @StringField()
  public name: string

  @AttrField()
  public value: FieldValue

  @AttrField([])
  public options: SelectOption[] | Record<string, any>[]

  @StringField('text')
  public fieldType: FieldType

  @NumberField()
  public parentIndex: number

  @BooleanField(false)
  public loading: boolean

  /**
   * VALIDATION
   * */
  @AttrField()
  public initialValue: FieldValue

  // Validation state
  /**
   * If The field is valid.
   */
  @BooleanField(false)
  public valid: boolean

  /**
   * If the field is invalid.
   */
  @BooleanField(false)
  public invalid: boolean

  /**
   * If the field value has been changed.
   */
  @BooleanField(false)
  public changed: boolean

  /**
   * If the field has been blurred.
   */
  @BooleanField(false)
  public touched: boolean

  /**
   * If the field wasn't blurred.
   */
  @BooleanField(true)
  public untouched: boolean

  /**
   * If the field value was not manipulated.
   */
  @BooleanField(true)
  public pristine: boolean

  /**
   * If the field value has been manipulated.
   */
  @BooleanField(false)
  public dirty: boolean

  /**
   * Indicates if the field validation is in progress.
   */
  @BooleanField(false)
  public pending: boolean

  /**
   * If the field is required.
   */
  @BooleanField(false)
  public required: boolean

  /**
   * If the field has been validated at least once.
   */
  @BooleanField(false)
  public validated: boolean

  /**
   * If the field has been validated and is valid.
   */
  @BooleanField(false)
  public passed: boolean

  /**
   * If the field has been validated and is invalid.
   */
  @BooleanField(false)
  public failed: boolean

  @AttrField([])
  public errors: ValidationRuleError[]

  @AttrField([])
  public serverErrors: string[]

  @AttrField({})
  public subfieldServerErrors: Record<string, string[]>

  @AttrField([])
  public serverErrorFields: ServerErrorFields

  @AttrField([])
  public validators: ValidationRule[]

  @StringField('eager')
  public validationMode: keyof typeof ValidationModes

  @AttrField([])
  public crossField: string[]

  @StringField()
  /**
   * @deprecated Should not be used, please consider messagePath param in validation config instead
   */
  public errorCustomMessage: string

  @AttrField({})
  public validationRulesResults: Record<string, boolean>

  /**
   * GETTERS
   * */
  public isChanged(): boolean {
    return !isEqual(this.value, this.initialValue)
  }

  public getApiValue(): FieldValue {
    return this.value
  }

  public setValue(value: FieldValue): void {
    this.value = value
  }

  public getName(): string {
    return this.name
  }

  public getFieldType(): FieldType {
    return this.fieldType
  }

  public reset(): void {
    this.value = null
  }

  public getComponent(): string {
    return 'Field'
  }
}

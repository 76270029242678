import { NumberField, OrmModel, PrimaryKey, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.MESSAGES_ATTACHMENT_FILE)
export default class MessagesAttachmentFile extends BaseModel {
  @PrimaryKey()
  @StringField()
  public resource: string

  @NumberField()
  public chars: number

  @StringField()
  public href: string

  @StringField()
  public name: string

  @NumberField()
  public size: number

  @NumberField()
  public width: number

  @NumberField()
  public height: number
}

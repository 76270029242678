import { getConfirmationService, getTranslateService, getWrapperManagerService } from '@/core/container/ioc'
import { ref, computed } from '@/composition/vue/compositionApi'
import type { ConfirmationConfig, ConfirmationPasswordConfig } from '@/types'
import type { TmWrappers } from '@/wrappers'
import { handleFormError } from '@/composition/forms'
import type { AppModule } from '@/config/types'

export function useConfirmation(modules?: AppModule[]) {
  const isPendingSubmit = ref(false)
  const confirmationService = getConfirmationService()
  const wrapperManagerService = getWrapperManagerService()
  const translateService = getTranslateService()

  const wrapperId = computed<TmWrappers>(() => confirmationService.getWrapperId())
  const confirmationConfig = computed<ConfirmationConfig>(() => confirmationService.getConfirmationConfig())

  const confirm = async (callback: (...args: any[]) => any, config?: ConfirmationConfig): Promise<boolean> => {
    const confirmation = await confirmationService.confirm(config || {}, 'confirmation', modules)
    if (confirmation) {
      try {
        isPendingSubmit.value = true
        await callback()
      } catch (e) {
        handleFormError(e)
      } finally {
        isPendingSubmit.value = false
      }
    }
    wrapperManagerService.close(wrapperId.value)

    return new Promise((resolve) => {
      resolve(confirmation)
    })
  }

  const confirmDelete = async (name: string, callback: (...args: any[]) => any, config?: ConfirmationConfig) => {
    const confirmation = await confirmationService.confirm(
      {
        title: translateService.t('common.confirmations.delete', { entity: name }),
        ...config,
      },
      'confirmationDelete',
      modules,
    )

    if (confirmation) {
      try {
        await callback()
      } catch (e) {
        handleFormError(e)
      }
    }

    wrapperManagerService.close(wrapperId.value)

    return new Promise((resolve) => {
      resolve(confirmation)
    })
  }

  const confirmPassword = async (callback: (...args: any[]) => any, config: ConfirmationPasswordConfig) => {
    const confirmation = await confirmationService.confirm(
      {
        text: {
          translateKey: 'passwordConfirmationModal.text',
          slots: [
            {
              name: 'which',
              value: config.target,
            },
            {
              name: 'accountPassword',
              value: translateService.t('passwordConfirmationModal.accountPassword'),
            },
          ],
        },
        ...config,
      },
      'confirmationPassword',
      modules,
    )

    if (confirmation) {
      await callback(confirmation)
    }
    wrapperManagerService.close(wrapperId.value)

    return new Promise((resolve) => {
      resolve(confirmation)
    })
  }

  const submit = (response: any = true) => {
    isPendingSubmit.value = true // We don't switch it off! We don't know when actual action will be finished, so we wait until the modal will be closed
    confirmationService.resolve(response)
  }

  const cancel = () => confirmationService.resolve(false)

  return {
    wrapperId,
    confirmationConfig,
    confirm,
    confirmDelete,
    confirmPassword,
    submit,
    cancel,
    isPendingSubmit,
  }
}

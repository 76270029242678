import { injectable } from 'inversify'
import type { IWindowOpenParams } from '@/services/browser/types'

@injectable()
export default class WindowService {
  public get<T>(key: string) {
    return (window as Window & typeof globalThis & Record<string, T>)[key] as T
  }

  public self() {
    return window
  }

  public getLocation() {
    return window.location
  }

  public redirect(location: string) {
    this.getLocation().href = location
  }

  public replace(location: string) {
    this.getLocation().replace(location)
  }

  public open(link: string, target: '_blank' | '_self' = '_self', params?: IWindowOpenParams) {
    let paramsStr: string | undefined
    if (params) {
      const paramsArr: string[] = []
      Object.keys(params).forEach((t) => {
        paramsArr.push(`${t}=${params[t]}`)
      })
      paramsStr = paramsArr.join(', ')
    }
    window.open(link, target, paramsStr)
  }

  public reload() {
    this.getLocation().reload()
  }

  public setAttribute<K extends keyof Window>(key: K, value: (Window & typeof globalThis)[K]) {
    window[key] = value
  }

  public getStyle(el: HTMLElement) {
    return window.getComputedStyle(el)
  }

  public setDocumentTitle(title: string) {
    document.title = title
  }
}

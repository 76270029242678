import { AttrField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseSmsSurvey from '@/data/models/domain/services/smsSurveys/BaseSmsSurvey'
import {
  isListRecipient,
  type SmsSurveyDetailsStatistics,
  type SmsSurveyDetailsRecipient,
} from '@/data/models/domain/services/smsSurveys/types'
import type { SmsSurveyDetailsCountries } from '@/services/domain/services/smsSurveys/types'

@OrmModel(modelNames.SMS_SURVEY_DETAILS)
export default class SmsSurveyDetails extends BaseSmsSurvey {
  @StringField()
  public updatedAt: string

  @AttrField([])
  public recipients: SmsSurveyDetailsRecipient[]

  @AttrField()
  public surveyStats: SmsSurveyDetailsStatistics

  @NumberField()
  public uniqueRecipientsCount: number

  @StringField()
  public lastRespondedAt: string

  @AttrField()
  public countries: SmsSurveyDetailsCountries[]

  public get recipientsCount(): number {
    return this.recipients.reduce((acc, item) => {
      if (isListRecipient(item)) {
        return acc + item.count
      }

      return acc + 1
    }, 0)
  }
}

import { injectable } from 'inversify'
import type { AddRuleFunc, ValidationRegistratorInterface } from '@/services/validation/validationRegistratorInterface'
import { mmsAttachmentFileFormats } from '@/utils/file/const/mmsAttachmentFileFormats'
import { smsAttachmentFileFormats } from '@/utils/file/const/smsAttachmentFileFormats'
import { getFileSizeLabel } from '@/utils/file/getFileSizeLabel'

import { getFileExt } from '@/utils/file/getFileExt'
import type { RuleConfig } from './types'
import { tableDocumentMimeTypes } from '@/constants/mimeTypes'
import type { AvatarFile } from '@/data/models/domain/Contact'
import { isAvatarFile } from '@/data/models/domain/types'
import type { FileUploadModelAreaValue } from '@/services/forms/types'
import {
  whatsAppAttachmentFileFormats,
  whatsAppAttachmentMediaFileFormats,
} from '@/utils/file/const/whatsAppAttachmentFileFormats'
import { pointAiAttachmentFileFormats } from '@/utils/file/const/pointAiAttachmentFileFormats'
import { instagramAttachmentMediaFileFormats } from '@/utils/file/const/instagramAttachmentFileFormats'

@injectable()
export default class FileValidation implements ValidationRegistratorInterface {
  public register(addRule: AddRuleFunc) {
    addRule(
      'maxFileSize',
      (file: File | AvatarFile, _params: unknown, _formValues: Record<string, unknown>, config?: RuleConfig) => {
        if (!file) {
          return true
        }
        if (isAvatarFile(file)) {
          // No need to chek size: it is already a file
          return true
        }

        // TODO: figure out server file limit. Temporary set to 10Mb
        let limitFileSize = 10 * 1024 * 1024
        if (config?.data) {
          const fileTypes = Object.keys(config.data)
          let key = fileTypes.find((type) => file.type.startsWith(type))
          if (!key && config.data['*']) {
            key = '*'
          }
          if (key) {
            const size = Number(config.data[key])
            if (!Number.isNaN(size)) {
              limitFileSize = size
            }
          }
        }
        return (file.size ?? 0) <= limitFileSize
          ? true
          : {
              message: {
                path: 'fileValidationRegistrator.maxFileSize',
                params: {
                  fileSize: getFileSizeLabel(limitFileSize, 1),
                },
              },
            }
      },
    )

    addRule('isImage', (file: File) => {
      if (!file) {
        return false
      }
      const imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif']
      return imageMimeTypes.includes(file.type) ? true : { message: { path: 'fileValidationRegistrator.isImage' } }
    })

    addRule('fileAllowed', (file: File) => {
      if (!file) {
        return true
      }
      const restrictedMimeTypes = [
        'application/x-binary',
        'application/octet-stream',
        'application/x-msdownload',
        'application/x-mach-binary',
        'application/x-elf',
      ]
      return !restrictedMimeTypes.includes(file.type)
        ? true
        : { message: { path: 'fileValidationRegistrator.fileAllowed' } }
    })

    addRule('isTableDocument', (file: File) => {
      if (!file) {
        return true
      }
      return (
        tableDocumentMimeTypes.includes(file.type) || { message: { path: 'fileValidationRegistrator.isTableDocument' } }
      )
    })

    addRule('isMessagesAttachment', (file: File) => this.fileAttachmentRule(file, smsAttachmentFileFormats))

    addRule('isMmsAttachment', (file: File) => this.fileAttachmentRule(file, mmsAttachmentFileFormats))

    addRule('isWhatsAppFileAttachment', (file: File) => this.fileAttachmentRule(file, whatsAppAttachmentFileFormats))

    addRule('isPointAiFileAttachment', (file: File) => this.fileAttachmentRule(file, pointAiAttachmentFileFormats))

    addRule('isWhatsAppMediaAttachment', (file: File) =>
      this.fileAttachmentRule(file, whatsAppAttachmentMediaFileFormats),
    )

    addRule('isInstagramMediaAttachment', (file: File) =>
      this.fileAttachmentRule(file, instagramAttachmentMediaFileFormats),
    )

    addRule('isFileOrResponse', (file: FileUploadModelAreaValue, mimeTypes: string[]) => {
      if (!file) return true
      const fileType = file instanceof File ? file.type : file.mimeType
      const defaultMessage = { message: { path: 'fileValidationRegistrator.isImage' } }
      return mimeTypes.includes(fileType) ? true : defaultMessage
    })
  }

  private fileAttachmentRule(file: File, formats: Record<string, string | string[]>) {
    if (!file) {
      return true
    }
    const msg = {
      message: {
        path: 'fileValidationRegistrator.isMessagesAttachment',
        params: {
          formats: Object.keys(formats)
            .join(', ')
            .replace(/^(.*)(,\s)(\..*)$/, '$1 & $3'), // changes the last comma to "&"
        },
      },
    }
    const availableFilesExt = Object.keys(formats)
    const result = availableFilesExt.includes(getFileExt(file.name))
    if (!result) {
      return msg
    }
    const availableMimeTypes = Object.values(formats)
    return availableMimeTypes.flat().includes(file?.type) || msg
  }
}

import { reduce } from 'lodash-es'
import { getSymbolFor } from '@/utils/app'
import { enumKeysToArray } from '@/utils/enum'

export const makeServicesTypes = <T extends string>(servicesNames: T[]) =>
  reduce<T, Record<T, symbol>>(
    servicesNames,
    (acc, item) => {
      acc[item] = getSymbolFor(item)
      return acc
    },
    {} as Record<T, symbol>,
  )

// just helper that allows to write less boilerplate code
export const makeServicesTypesFromEnum = <T extends string>(servicesEnum: unknown) =>
  makeServicesTypes(enumKeysToArray<T>(servicesEnum))

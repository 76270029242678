import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { PhoneWithCountryFieldConfig, SelectFieldConfig, StringFieldConfig } from '@/services/forms/types'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type { Translatable } from '@/services/types'
import type { CallForwardingFormScheme, ForwardCallsTo } from '@/services/domain/callForwarding/types'
import type PhoneService from '@/services/phoneService'
import type CountryService from '@/services/domain/countryService'
import type { TypedFormBuilderInterface } from '@/services/forms/baseForm/typedFormBuilder/types'

@injectable()
export default class CallForwardingFormBuilderService {
  constructor(
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.TranslateService) private readonly translateService: Translatable,
    @inject(SERVICE_TYPES.PhoneService) private readonly phoneService: PhoneService,
    @inject(SERVICE_TYPES.CountryService) private readonly countryService: CountryService,
  ) {}

  public buildForm(
    typedBuilder: TypedFormBuilderInterface<CallForwardingFormScheme>,
    formData: CallForwardingFormScheme,
    forwardCallsToValues: ForwardCallsTo[],
  ) {
    typedBuilder
      .property((t) => t.forwardCallsTo)
      .control<SelectFieldConfig<ForwardCallsTo>>({
        fieldType: 'select',
        value: formData.forwardCallsTo,
        options: () =>
          forwardCallsToValues.map((t) => ({
            text: this.translateService.t(`callForwardingFormBuilderService.forwardCallsToValues.${t}`),
            value: t,
          })),
      })

    const phoneCountryOptions = this.countryService.getCountryPhoneDataOptions()
    typedBuilder
      .property((t) => t.forwardCallsToYourNumber)
      .control<PhoneWithCountryFieldConfig>({
        fieldType: 'phone',
        value: formData.forwardCallsToYourNumber,
        options: () => phoneCountryOptions,
        validators: this.validationRulesBuilderService.createRules<string>().custom('', (_value, formValues) => {
          const formScheme = formValues as CallForwardingFormScheme
          return this.checkValidForwardCallsToYourNumber(formScheme)
        }),
      })

    typedBuilder
      .property((t) => t.greeting)
      .control<StringFieldConfig>({
        fieldType: 'text',
        value: formData.greeting,
      })
  }

  /**
   * @todo it is better to replace it with a check "invalid" field in form, but form validation execute after call hook
   */
  public checkValidForwardCallsToYourNumber(formScheme: CallForwardingFormScheme) {
    if (
      formScheme.forwardCallsTo === 'phone' &&
      !this.phoneService.isValidPhoneNumber(formScheme.forwardCallsToYourNumber.phone)
    ) {
      return { message: { path: 'callForwardingFormBuilderService.pleaseEnterValidNumber' } }
    }
    return true
  }
}

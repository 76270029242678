<template>
  <tm-base-modal-content
    hide-cancel-button
    class="tm-preloader-modal-content"
  >
    <template v-slot:modal-title>
      <template v-if="title">
        {{ title }}
      </template>
      <tm-skeleton
        v-else
        width="200px"
        height="30px"
      />
    </template>
    <template v-slot:modal-content>
      <slot>
        <tm-loader />
      </slot>
    </template>
  </tm-base-modal-content>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmBaseModalContent from '@/components/shared/modals/tmModal/TmBaseModalContent.vue'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import TmLoader from '@/components/shared/TmLoader.vue'
import { useModalConfigMergedWithProps } from '@/composition/dialog'

export default defineComponent({
  components: { TmLoader, TmSkeleton, TmBaseModalContent },
  setup(props) {
    return useModalConfigMergedWithProps(props)
  },
})
</script>

<style lang="scss" scoped>
.tm-preloader-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  :deep(.tm-modal__main) {
    display: flex;
    flex-direction: column;
  }
}
</style>

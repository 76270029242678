import { inject, injectable } from 'inversify'
import type { Gridable } from '@/services/types'
import type { PaginationUrlType } from '@/services/tables/types'
import type { PaginationParams } from '@/services/tables/pagination/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { EndpointParams } from '@/services/endpoints'
import type Note from '@/data/models/domain/Note'
import type NoteService from '@/services/domain/note/noteService'
import TmLogicError from '@/core/error/tmLogicError'

@injectable()
export default class NoteGridable implements Gridable<Note> {
  private contactId: string | null = null

  constructor(@inject(SERVICE_TYPES.NoteService) protected readonly noteService: NoteService) {}

  public setContactId(id: string) {
    this.contactId = id
  }

  public getFetchEndpointParams(): EndpointParams {
    if (this.contactId === null) {
      throw new TmLogicError('"contactId" is null')
    }
    return [this.contactId]
  }

  public async gridRequest(
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
    searchFields?: string[],
  ) {
    return this.noteService
      .getDomainRepository()
      .getNotes(this.getFetchEndpointParams(), queryParameterBag, paginationParamsBag, searchQuery, searchFields)
  }
}

import type { CustomErrorRule } from '@/core/error/transport/types'
import { TmApiAccountPhoneAlreadyVerifiedError } from '@/core/error/transport/tmApiAccountPhoneAlreadyVerifiedError'
import { EndpointsEnum } from '@/services/endpoints'

export const phoneNumberAlreadyVerifiedErrorRule: CustomErrorRule = {
  klass: TmApiAccountPhoneAlreadyVerifiedError,
  endpoint: EndpointsEnum.sendVerifyCodeOnPhone,
  predicateFn: (error) => {
    return error.response.data.message.includes('already verified')
  },
}

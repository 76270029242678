<template>
  <tm-router-reloader-link
    v-ripple
    class="main-sidebar-item-child-link"
    active-class="main-sidebar-item-child-link--active"
    :class="{
      'main-sidebar-item-child-link--highlight-exact-active': highlightExactActive,
      'main-sidebar-item-child-link--small-padding': smallPadding,
    }"
    :to="route!"
    @click="closeMenu"
  >
    <div class="d-flex align-center overflow-hidden">
      <span class="sidebar-button__text ellipsis">
        {{ title }}
      </span>

      <tm-gradient-badge-small
        v-if="badge"
        class="flex-grow-1 ml-2"
        :text="badge"
      />
    </div>

    <main-sidebar-warning-icon
      v-if="rightIcon"
      v-bind="rightIcon"
    />
  </tm-router-reloader-link>
</template>

<script lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import TmRouterReloaderLink from '@/components/shared/TmRouterReloaderLink.vue'
import { useMenuState } from '@/composition/menuState'
import type { PageContentSidebarLink } from '@/components/layout/pageContent/sidebar/types'
import type { TmIconType } from '@/components/shared/types'
import MainSidebarWarningIcon from '@/components/layout/mainSidebar/MainSidebarWarningIcon.vue'
import TmGradientBadgeSmall from '@/components/shared/badge/TmGradientBadgeSmall.vue'

export default defineComponent({
  components: {
    TmGradientBadgeSmall,
    MainSidebarWarningIcon,
    TmRouterReloaderLink,
  },
  props: {
    title: {
      type: String,
    },
    route: {
      type: Object as PropType<RouteLocationRaw>,
    },
    badge: {
      type: String as PropType<PageContentSidebarLink['badge']>,
    },
    rightIcon: {
      type: Object as PropType<TmIconType>,
    },
    highlightExactActive: {
      type: Boolean,
    },
    smallPadding: {
      type: Boolean,
    },
  },
  setup() {
    const { closeMenu } = useMenuState()
    return {
      closeMenu,
    }
  },
})
</script>

<style lang="scss" scoped>
.main-sidebar-item-child-link {
  display: flex;
  padding: 0 16px 0 48px;
  line-height: 32px;
  border-radius: 4px;
  position: relative;
  color: $light;
  font-size: $font-size-root;
  font-weight: $semi-bold-font;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;

  &--small-padding {
    padding: 0 16px 0 32px;
  }

  &--highlight-exact-active.router-link-exact-active,
  &:not(.main-sidebar-item-child-link--highlight-exact-active).main-sidebar-item-child-link--active {
    background: rgba(255, 255, 255, 0.12);
    color: $white;
  }
}
</style>

import { injectable } from 'inversify'
import { format } from '@/utils/string/format'
import type { Endpoint, EndpointParams } from '@/services/endpoints'
import { EndpointsEnum } from '@/services/endpoints'

export interface AbstractEndpointsInterface {
  getPath(name: string, params?: EndpointParams): string
}

@injectable()
export default class EndpointsService implements AbstractEndpointsInterface {
  public getPath(name: Endpoint, params?: EndpointParams): string {
    return this._interpolateEndpoint(name, params)
  }

  private _interpolateEndpoint(name: Endpoint, params?: EndpointParams): string {
    const endpoint: string = EndpointsEnum[name] as string

    if (params) {
      return format(endpoint, params)
    }
    return endpoint
  }
}

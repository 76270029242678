import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'

export const campaignsSentSmsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.sent.sms',
  path: 'sms',
  component: () => import('@/components/views/campaigns/sms/CampaignsSentSms.vue'),
  meta: {
    modules: [AppModule.SmsCampaigns],
  },
}

export const campaignsSentEmailRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.sent.email',
  path: 'email',
  component: () => import('@/components/views/campaigns/email/CampaignsEmailStub.vue'),
}

export const campaignsSentTabsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.sent.tabs',
  path: '',
  component: () => import('@/components/views/campaigns/CampaignsSent.vue'),
  redirect: { name: campaignsSentSmsRoute.name },
  children: [campaignsSentSmsRoute, campaignsSentEmailRoute],
}

export const campaignsSentRoute = {
  name: 'user.campaigns.sent',
  path: 'sent',
  redirect: { name: campaignsSentTabsRoute.name },
  children: [campaignsSentTabsRoute],
  meta: {
    tabTitle: 'pageTabTitle.campaigns.sent',
  },
} as const satisfies TmNamedRouteRecord

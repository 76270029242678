import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type LoggerService from '@/services/loggerService'
import type ResolverManager from '@/services/resolvers/resolverManager'
import type { PromiseExecutorPoolManager } from '@/services/promiseExecutorPoolManager'
import type { ResolverConfig } from '@/services/types'
import type { Route } from '@/services/route/types'
import type { ResolverExecutorServiceInterface } from '@/services/resolvers/types'
import type { PerformanceMarkService } from '@/services/performanceMarkService'
import { ResolverExecutorService } from '@/services/resolvers/resolverExecutorService'

@injectable()
export class MeasureableResolverExecutorService
  extends ResolverExecutorService
  implements ResolverExecutorServiceInterface
{
  constructor(
    @inject(SERVICE_TYPES.PromiseExecutorPoolManager) protected readonly poolManager: PromiseExecutorPoolManager,
    @inject(SERVICE_TYPES.ResolverManager) protected readonly resolverManager: ResolverManager,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.PerformanceMarkService) protected readonly performanceMarkService: PerformanceMarkService,
  ) {
    super(poolManager, resolverManager, loggerService)
  }

  public async resolve(config: ResolverConfig, route?: Route, fromRoute?: Route): Promise<unknown> {
    const finishMeasure = this.performanceMarkService.startMeasure(`Resolve: ${config.service}`)
    const promise = super.resolve(config, route, fromRoute)
    promise.finally(finishMeasure)
    return promise
  }

  public async unresolve(config: ResolverConfig, route?: Route, fromRoute?: Route): Promise<unknown> {
    const finishMeasure = this.performanceMarkService.startMeasure(`Unresolve: ${config.service}`)
    const promise = super.unresolve(config, route, fromRoute)
    promise.finally(finishMeasure)
    return promise
  }
}

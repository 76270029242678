import { isFormServiceForModel } from '@/services/serviceGuards'
import { TmFormError } from '@/core/error/tmFormError'
import type BaseModel from '@/data/models/BaseModel'
import type BaseFormService from '@/services/forms/baseFormService'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import type { TpFormData } from '@/services/forms/types'
import type { GetFormSubmitConfigType } from '@/types'

export type FormSubmitMethod<T, R> = (formData: TpFormData, config: T) => Promise<R>

export interface GetSubmitMethod {
  <T extends BaseFormService<FormBuilderInterface>>(
    formService: T,
  ): FormSubmitMethod<GetFormSubmitConfigType<T>, ReturnType<T['submit']>>
  <T extends BaseFormService<FormBuilderInterface>>(
    formService: T,
    byModel?: typeof BaseModel,
  ): FormSubmitMethod<GetFormSubmitConfigType<T>, unknown>
}

export const getSubmitMethod: GetSubmitMethod = <T extends BaseFormService<FormBuilderInterface>>(
  formService: T,
  byModel?: typeof BaseModel,
) => {
  if (byModel) {
    if (!isFormServiceForModel(formService)) {
      throw new TmFormError('For strategy "byModel" you must use form service that implements "FormServiceForModel"')
    }
    return (formData) => formService.submitByModel(formData, byModel)
  }
  return (formData, config) => formService.submit(formData, config)
}

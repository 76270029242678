import { inject, injectable } from 'inversify'
import type { RouteLocationRaw, RouteRecordRaw } from 'vue-router'
import { SERVICE_TYPES } from '@/core/container/types'
import type RouterService from '@/services/route/routerService'
import type RouterBuilderMiddlewareInterface from '@/services/route/routerBuilderMiddleware/types'
import appRoutes from '@/routes'
import type { Route, RouterBuilderInterface, TmNavigationGuard } from '@/services/route/types'
import type LoggerService from '@/services/loggerService'
import { PageAvailableFor } from '@/types'
import type SubscriptionService from '@/services/transport/subscriptionService'
import { ROUTER_AFTER_EACH } from '@/services/route/types'
import type LoggedInStatusService from '@/services/auth/loggedInStatusService'
import type ResolverService from '@/services/resolvers/resolverService'
import type { AppModule } from '@/config/types'
import type { MonitoringServiceInterface } from '@/services/monitoring/types'

@injectable()
export default class RouterBuilderService implements RouterBuilderInterface {
  private beforeEachHandlers: TmNavigationGuard[] = []

  private beforeResolveHandlers: TmNavigationGuard[] = []

  protected middlewares: RouterBuilderMiddlewareInterface[] = []

  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.LoggedInStatusService) protected readonly loggedInStatusService: LoggedInStatusService,
    @inject(SERVICE_TYPES.ResolverService) protected readonly resolverService: ResolverService,
    @inject(SERVICE_TYPES.MonitoringService) protected readonly sentryMonitoringService: MonitoringServiceInterface,
  ) {}

  public build(modules: AppModule[] = []) {
    const r = this.runMiddlewares(appRoutes)
    this.routerService.setRoutes(r)
    this.routerService.initRouter()

    const router = this.routerService.getRouter()
    router.afterEach(async (to, from) => {
      requestIdleCallback(
        () => {
          this.subscriptionService.emit(ROUTER_AFTER_EACH, to)
        },
        { timeout: 3000 },
      )
      await this.routerService.afterEach(to, from)
    })

    router.beforeResolve(async (to, from) => {
      this.beforeResolveHandlers.forEach((t) => t(to, from, () => {}))
    })

    let resolvedDepsOnStart = false
    router.beforeEach(async (to, from, next) => {
      if (!resolvedDepsOnStart) {
        /**
         * call this method once - when the application starts
         */
        modules = Array.isArray(to.meta?.modules) ? Array.from(new Set(modules.concat(to.meta?.modules))) : modules
        await this.resolverService.resolveDeps(String(to.name), modules)
        resolvedDepsOnStart = true
      }
      let isPreventStepToRoute: null | boolean = null
      this.beforeEachHandlers.forEach((t) =>
        t(to, from, () => {
          isPreventStepToRoute = true
        }),
      )

      this.sentryMonitoringService.toggleWidgetButton?.(!to.meta.sentryWidgetButtonHidden)

      const isAuthenticated = this.loggedInStatusService.isUserLoggedIn()
      this.loggerService.log('routerBuilder', `isAuthenticated = ${JSON.stringify(isAuthenticated)}`)
      this.loggerService.log('routerBuilder', JSON.stringify(to.meta))
      await this.routerService.beforeEach(to, from)
      const checkPermissionsResult = this.checkAuthPermissions(isAuthenticated, to)
      if (isPreventStepToRoute) {
        next(false)
      } else if (typeof checkPermissionsResult === 'boolean') {
        next()
      } else {
        resolvedDepsOnStart = false
        next(checkPermissionsResult)
      }
    })
  }

  public addBeforeEachHandler(fn: TmNavigationGuard) {
    this.beforeEachHandlers.push(fn)
  }

  public removeBeforeEachHandler(fn: TmNavigationGuard) {
    const index = this.beforeEachHandlers.findIndex((t) => t === fn)
    this.beforeEachHandlers.splice(index, 1)
  }

  public addBeforeResolveHandler(fn: TmNavigationGuard) {
    this.beforeResolveHandlers.push(fn)
  }

  public removeBeforeResolveHandler(fn: TmNavigationGuard) {
    const index = this.beforeResolveHandlers.findIndex((t) => t === fn)
    this.beforeResolveHandlers.splice(index, 1)
  }

  public addMiddleware(middleware: RouterBuilderMiddlewareInterface) {
    this.middlewares.push(middleware)
  }

  protected checkAuthPermissions(isAuthenticated: boolean, to: Route) {
    let result: RouteLocationRaw | boolean = true
    if (to.name === 'auth.logout') {
      return result
    }

    const pageAvailableFor = to.meta?.pageAvailableFor || PageAvailableFor.authenticated
    if (isAuthenticated && pageAvailableFor === PageAvailableFor.notAuthenticated) {
      this.loggerService.log('routerBuilder', 'to: user.base')
      result = { name: 'user.base' }
    } else if (!isAuthenticated && pageAvailableFor === PageAvailableFor.authenticated) {
      this.loggerService.log('routerBuilder', 'to: auth.login')
      result = { name: 'auth.login' }
    }

    this.loggerService.log('routerBuilder', `return ${JSON.stringify(result)}`)
    return result
  }

  // more info here - https://textmagic.atlassian.net/wiki/spaces/TM30/pages/1885995009/Resolvers
  private dumpRoutes(routes: RouteRecordRaw[]): Record<string, any> {
    return routes.map((route: RouteRecordRaw) => ({
      name: route.name,
      path: route.path,
      children: route.children && route.children.length > 0 ? this.dumpRoutes(route.children) : [],
    }))
  }

  private runMiddlewares(routes: RouteRecordRaw[]): RouteRecordRaw[] {
    this.middlewares.forEach((middleware: RouterBuilderMiddlewareInterface) => {
      routes = middleware.pipe(routes)
    })
    return routes
  }
}

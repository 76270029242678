import type { RouteLocationRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import type { TmNamedRouteRecord } from '@/services/route/types'
import type { CampaignsComposeSmsRouteParams } from '@/components/views/compose/types'
import { scheduleRouteHash } from '@/components/views/compose/types'

export const campaignsComposeCampaignRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.compose.campaign',
  path: 'sms-campaign',
  meta: {
    tabTitle: 'pageTabTitle.user.compose.campaign',
  },
  component: () => import('@/components/views/campaigns/compose/CampaignsComposeCampaign.vue'),
}

export const campaignsComposeSmsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.compose.sms',
  path: 'sms',
  meta: {
    tabTitle: 'pageTabTitle.user.compose.campaign',
  },
  props: (route) => ({
    openSchedule: route.hash === scheduleRouteHash,
  }),
  component: () => import('@/components/views/campaigns/compose/CampaignsComposeSms.vue'),
}

export const campaignsComposeEmailRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.compose.email',
  path: 'email-coming-soon',
  component: () => import('@/components/views/campaigns/compose/CampaignsComposeEmail.vue'),
  meta: {
    tabTitle: 'pageTabTitle.user.compose.email',
  },
}

export const campaignsComposeRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.compose',
  path: 'compose',
  component: () => import('@/components/views/campaigns/compose/CampaignsCompose.vue'),
  redirect: { name: campaignsComposeSmsRoute.name },
  meta: {
    modules: [
      AppModule.SenderSettingsCountries,
      AppModule.Contacts,
      AppModule.ContactList,
      AppModule.ScheduledCalendar,
      AppModule.ScheduledNotSent,
      AppModule.RRule,
      AppModule.MessageSend,
      AppModule.Compose,
      AppModule.RecentRecipient,
      AppModule.ContactCounter,
      AppModule.SentSmsBase,
      AppModule.Templates,
      AppModule.FilteredViews,
      AppModule.Segments,
    ],
  },
  children: [campaignsComposeSmsRoute, campaignsComposeCampaignRoute, campaignsComposeEmailRoute],
}

type CampaignsComposeSmsRouteRaw = {
  params?: CampaignsComposeSmsRouteParams
  openSchedule?: boolean
}

export const getComposeMessageRoute = (routeRaw?: CampaignsComposeSmsRouteRaw): RouteLocationRaw => ({
  name: campaignsComposeSmsRoute.name,
  state: routeRaw?.params,
  hash: routeRaw?.openSchedule ? scheduleRouteHash : '',
})

<template>
  <div
    class="tm-angle-wrap-tooltip"
    :class="{
      [`tm-angle-wrap-tooltip--${direction}`]: direction,
      [`tm-angle-wrap-tooltip--${color}`]: color,
    }"
    :style="{
      '--arrow-offset-x': arrowOffset[0],
      '--arrow-offset-y': arrowOffset[1],
    }"
  >
    <div class="tm-angle-wrap-tooltip__main">
      <slot />
      <svg class="tm-angle-wrap-tooltip__angle tm-angle-wrap-tooltip__angle--horizontal">
        <path
          d="M4.58579 4.58579C5.36683 5.36683 6.63316 5.36684 7.41421 4.58579L12 0L0 0L4.58579 4.58579Z"
          fill="currentColor"
        />
      </svg>
      <svg class="tm-angle-wrap-tooltip__angle tm-angle-wrap-tooltip__angle--vertical">
        <path
          d="M4.58579 7.41421C5.36683 6.63317 5.36684 5.36684 4.58579 4.58579L0 0L0 12L4.58579 7.41421Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from '@/composition/vue/compositionApi'
import { addPxToNumber } from '@/utils/string/addPxToNumber'
import {
  type TmAngleWrapTooltipPropsType,
  tooltipDefaultColor,
  tooltipDefaultDirection,
} from '@/components/shared/tooltip/types'

const props = withDefaults(defineProps<TmAngleWrapTooltipPropsType>(), {
  direction: tooltipDefaultDirection,
  color: tooltipDefaultColor,
})

const arrowHalfWidth = 6
const tooltipMargin = 2 // Minimum margin around the arrow (for beauty)

const arrowOffset = computed(() => {
  const [horizontalOffset] = props.offset || [0]
  if (props.anchorBounding && props.tooltipPosition && props.viewportWidth && props.viewportHeight) {
    let offsetX = 0
    let offsetY = 0
    const { top, right, bottom, left } = props.tooltipPosition

    if (typeof top === 'number') {
      const offCenterTop = Math.abs(Math.min(props.anchorBounding.top / 2, 0))
      const offCenterBottom = Math.max((props.anchorBounding.bottom - props.viewportHeight) / 2, 0)
      const safeCenterY = Math.min(
        props.anchorBounding.centerY + offCenterTop - offCenterBottom,
        props.viewportHeight - arrowHalfWidth - tooltipMargin,
      )
      offsetY = Math.max(safeCenterY - top - arrowHalfWidth, tooltipMargin)
    }
    if (typeof bottom === 'number') {
      const offCenterTop = Math.abs(Math.min(props.anchorBounding.top / 2, 0))
      const offCenterBottom = Math.max((props.anchorBounding.bottom - props.viewportHeight) / 2, 0)
      offsetY =
        bottom - (props.viewportHeight - props.anchorBounding.centerY - offCenterTop + offCenterBottom) + arrowHalfWidth
      offsetY = Math.min(offsetY, -tooltipMargin)
    }
    if (typeof right === 'number') {
      const offCenterRight = Math.max((props.anchorBounding.right - props.viewportWidth) / 2, 0)
      const offCenterLeft = Math.abs(Math.min(props.anchorBounding.left / 2, 0))
      offsetX =
        Math.max(props.viewportWidth - props.anchorBounding.right + horizontalOffset, 0) -
        (props.viewportWidth - (props.anchorBounding.centerX - offCenterRight + offCenterLeft)) +
        arrowHalfWidth
      offsetX = Math.min(offsetX, -tooltipMargin)
    }
    if (typeof left === 'number') {
      const offCenterRight = Math.max((props.anchorBounding.right - props.viewportWidth) / 2, 0)
      const offCenterLeft = Math.abs(Math.min(props.anchorBounding.left / 2, 0))
      const safeCenterX = Math.min(
        props.anchorBounding.centerX - offCenterRight + offCenterLeft,
        props.viewportWidth - arrowHalfWidth - tooltipMargin,
      )
      offsetX = Math.max(safeCenterX - left - arrowHalfWidth, tooltipMargin)
    }

    return [addPxToNumber(Math.round(offsetX)), addPxToNumber(Math.round(offsetY))]
  }
  return [0, 0]
})
</script>

<style lang="scss" scoped>
$tooltip--dark: (
  color: $white,
  background: $strong,
);
$tooltip--white: (
  color: $neutral,
  background: $white,
);

.tm-angle-wrap-tooltip {
  --arrow-offset-x: 0;
  --arrow-offset-y: 0;
  position: relative;
  max-width: 360px;

  &--dark {
    --tooltip-background-color: #{map-get($tooltip--dark, background)};
    --tooltip-angle-color: #{map-get($tooltip--dark, background)};
    --tooltip-color: #{map-get($tooltip--dark, color)};
    --link-hover: $white;
  }
  &--white {
    --tooltip-background-color: #{map-get($tooltip--white, background)};
    --tooltip-angle-color: #{map-get($tooltip--white, background)};
    --tooltip-color: #{map-get($tooltip--white, color)};
  }

  &__main {
    position: relative;
    padding: 4px 8px;
    font-size: $sm-x-font;
    font-weight: $regular-font;
    line-height: 20px;
    word-break: break-word;
    color: var(--tooltip-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: $border-radius;
    background-color: var(--tooltip-background-color);
  }

  &__angle {
    position: absolute;
    display: none;
    color: var(--tooltip-angle-color);
  }

  // horizontal styles
  &--top,
  &--top-left,
  &--top-right {
    padding-bottom: 10px;
  }
  &--bottom,
  &--bottom-left,
  &--bottom-right {
    padding-top: 10px;
  }

  &--top &__angle,
  &--top-left &__angle,
  &--top-right &__angle,
  &--bottom &__angle,
  &--bottom-left &__angle,
  &--bottom-right &__angle {
    width: 12px;
    height: 6px;
    &--horizontal {
      display: flex;
    }
  }

  &--top &__angle,
  &--top-left &__angle,
  &--top-right &__angle {
    top: 100%;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2));
    transform: translate(var(--arrow-offset-x, 0), 0);
  }

  &--bottom &__angle,
  &--bottom-left &__angle,
  &--bottom-right &__angle {
    top: -6px;
    filter: drop-shadow(0 1px 0.5px rgba(0, 0, 0, 0.2));
    transform: translate(var(--arrow-offset-x, 0), 0) rotate(180deg);
  }
  &--top &__angle,
  &--top-right &__angle,
  &--bottom &__angle,
  &--bottom-right &__angle {
    left: 0;
  }
  &--top-left &__angle,
  &--bottom-left &__angle {
    right: 0;
  }

  // vertical styles
  &--left,
  &--left-top,
  &--left-bottom {
    padding-right: 10px;
  }
  &--right,
  &--right-top,
  &--right-bottom {
    padding-left: 10px;
  }

  &--right &__angle,
  &--right-top &__angle,
  &--right-bottom &__angle,
  &--left &__angle,
  &--left-top &__angle,
  &--left-bottom &__angle {
    width: 6px;
    height: 12px;
    &--vertical {
      display: flex;
    }
  }

  &--right &__angle,
  &--right-top &__angle,
  &--right-bottom &__angle {
    left: -6px;
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.09));
    transform: translate(0, var(--arrow-offset-y, 0)) rotate(-180deg);
  }
  &--left &__angle,
  &--left-top &__angle,
  &--left-bottom &__angle {
    right: -6px;
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.09));
    transform: translate(0, var(--arrow-offset-y, 0));
  }
  &--right &__angle,
  &--right-bottom &__angle,
  &--left &__angle,
  &--left-bottom &__angle {
    top: 0;
  }
  &--right-top &__angle,
  &--left-top &__angle {
    bottom: 0;
  }
}
</style>

import type { RouteRecordRaw } from 'vue-router'
import type { NotFoundResolverParams } from '@/services/resolvers/types'
import { DealsRoutes } from '@/routes/user/deals/types'
import DealPipeline from '@/data/models/domain/deals/DealPipeline'
import DealPipelineArchived from '@/data/models/domain/deals/DealPipelineArchived'

export const getDealsSettingsRoutes = (): RouteRecordRaw[] => [
  {
    name: DealsRoutes.settingsBase,
    path: '',
    meta: {
      resolvers: [{ service: 'DealPipelineSettingsResolverService' }],
      resolverComponent: () => import('@/components/resolvers/SectionSettingsResolver.vue'),
    },
    redirect: { name: DealsRoutes.settingsOverview },
    children: [
      {
        name: DealsRoutes.settingsOverview,
        path: 'overview',
        component: () => import('@/components/views/deals/DealsSettingsAll.vue'),
      },
      {
        name: DealsRoutes.settingsPipelineBase,
        path: ':pipelineId',
        props: true,
        redirect: { name: DealsRoutes.settingsPipeline },
        children: [
          {
            name: DealsRoutes.settingsPipeline,
            props: true,
            path: '',
            component: () => import('@/components/views/deals/DealsSettingsPipeline.vue'),
            meta: {
              resolvers: [
                {
                  service: 'RedirectableSingleModelResolverService',
                  params: {
                    requestParam: 'pipelineId',
                    model: DealPipeline,
                    source: 'router',
                    redirectToIfNotFound: { name: DealsRoutes.settingsOverview },
                  } as NotFoundResolverParams,
                },
              ],
              resolverComponent: () => import('@/components/resolvers/SectionSettingsResolver.vue'),
            },
          },
          {
            name: DealsRoutes.settingsPipelineArchived,
            props: true,
            path: 'archived',
            component: () => import('@/components/views/deals/DealsSettingsPipeline.vue'),
            meta: {
              resolvers: [
                {
                  service: 'RedirectableSingleModelResolverService',
                  params: {
                    requestParam: 'pipelineId',
                    model: DealPipelineArchived,
                    source: 'router',
                    redirectToIfNotFound: { name: DealsRoutes.settingsOverview },
                  } as NotFoundResolverParams,
                },
              ],
              resolverComponent: () => import('@/components/resolvers/SectionSettingsResolver.vue'),
            },
          },
          {
            name: DealsRoutes.settingsPipelineStages,
            props: true,
            path: 'stages',
            component: () => import('@/components/views/deals/DealsSettingsStages.vue'),
            meta: {
              resolvers: [
                {
                  service: 'RedirectableSingleModelResolverService',
                  params: {
                    requestParam: 'pipelineId',
                    model: DealPipeline,
                    source: 'router',
                    redirectToIfNotFound: { name: DealsRoutes.settingsOverview },
                  } as NotFoundResolverParams,
                },
              ],
              resolverComponent: () => import('@/components/resolvers/SectionSettingsResolver.vue'),
            },
          },
        ],
      },
    ],
  },
]

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum SERVICES {
  AuditLogService,
  AuditLogRepository,
  AuditLogReportingColumnService,
  AuditLogFilterServiceFactory,
  AuditLogGridable,
  AuditLogExportService,
}

export type AuditLogsServices = keyof typeof SERVICES

export const AUDIT_LOGS_SERVICES_TYPES = makeServicesTypesFromEnum<AuditLogsServices>(SERVICES)

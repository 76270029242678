import { BooleanField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.WEB_WIDGET)
export default class WebWidget extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @StringField()
  public lastUpdatedAt: string

  @StringField()
  public uniqueCode: string

  @StringField()
  public status: 'active' | 'inactive'

  @BooleanField()
  public hasActiveChannels: boolean
}

import type { RouteRecordRaw } from 'vue-router'
import SmsSurveyDetails from '@/data/models/domain/services/smsSurveys/SmsSurveyDetails'
import type { SingleModelResolverParams } from '@/services/resolvers/types'
import { AppModule } from '@/config/types'
import { ServicesRouteName } from '@/routes/types'
import { withAsyncWrapper } from '@/components/resolvers/wrappers/withAsyncWrapper'
import SmsSurveyFlowResolver from '@/components/resolvers/SmsSurveyFlowResolver.vue'
import AsyncComponentResolverWrapper from '@/components/resolvers/wrappers/AsyncComponentResolverWrapper.vue'
import { SmsSurveyRoutes } from '@/routes/user/services/types'

export const getSmsSurveysDetailsRoutes = (): RouteRecordRaw[] => [
  {
    name: SmsSurveyRoutes.details,
    path: 'details/:smsSurveyId',
    component: () => import('@/components/views/services/smsSurveys/details/SmsSurveyDetails.vue'),
    props: (route) => ({ smsSurveyId: route.params.smsSurveyId }),
    meta: {
      parent: ServicesRouteName,
      tabTitle: 'pageTabTitle.user.services.smsSurveyDetails',
      detailsPage: {
        source: 'router',
        requestParam: 'smsSurveyId',
        model: SmsSurveyDetails,
        cache: false,
      } as SingleModelResolverParams,
      resolvers: [{ service: 'SmsSurveyNodeTreeResolver', params: { skipSubscription: true } }],
      redirectToIfNotFound: { name: SmsSurveyRoutes.index },
      modules: [AppModule.SmsSurveys, AppModule.SentSmsBase],
      accessControlGroups: ['NotCampaignsSmsSurveysDetailsStrategy'],
    },
    redirect: { name: SmsSurveyRoutes.detailsOverview },
    children: [
      {
        name: SmsSurveyRoutes.detailsOverview,
        path: 'overview',
        component: () => import('@/components/views/services/smsSurveys/details/overview/SmsSurveyDetailsOverview.vue'),
        props: (route) => ({ smsSurveyId: route.params.smsSurveyId }),
      },
      {
        name: SmsSurveyRoutes.detailsReplies,
        path: 'replies/:questionId?',
        meta: {
          resolvers: [{ service: 'SmsSurveyDetailsStatusGuardResolver' }],
          modules: [AppModule.HistoryReceivedSms],
        },
        component: () => import('@/components/views/services/smsSurveys/details/replies/SmsSurveyDetailsReplies.vue'),
        props: (route) => ({ smsSurveyId: route.params.smsSurveyId }),
      },
      {
        name: SmsSurveyRoutes.detailsAnalytics,
        path: 'analytics',
        meta: {
          resolvers: [{ service: 'SmsSurveyDetailsStatusGuardResolver' }],
        },
        component: () =>
          import('@/components/views/services/smsSurveys/details/analytics/SmsSurveyDetailsAnalytics.vue'),
        props: (route) => ({ smsSurveyId: route.params.smsSurveyId }),
      },
    ],
  },
]

export const getSmsSurveysBuilderRoutes = (): RouteRecordRaw[] => [
  {
    name: SmsSurveyRoutes.viewFlow,
    path: ':smsSurveyId',
    meta: {
      parent: ServicesRouteName,
      tabTitle: 'pageTabTitle.user.services.smsSurveyDetails',
      hideHeader: true,
      modules: [AppModule.SmsSurveys, AppModule.Contacts],
      suspenseResolvers: [{ service: 'SmsSurveyFlowResolver' }, { service: 'SmsSurveyNodeTreeResolver' }],
      accessControlGroups: ['NotCampaignsSmsSurveysViewFlowStrategy'],
    },
    component: withAsyncWrapper(
      () => import('@/components/views/services/smsSurveys/SmsSurvey.vue'),
      SmsSurveyFlowResolver,
      {
        withFallback: true,
        wrapper: AsyncComponentResolverWrapper,
      },
    ),
    props: (route) => ({ smsSurveyId: route.params.smsSurveyId, readonly: true }),
  },
  {
    name: SmsSurveyRoutes.edit,
    path: 'edit/:smsSurveyId',
    meta: {
      parent: ServicesRouteName,
      hideHeader: true,
      suspenseResolvers: [{ service: 'SmsSurveyFlowResolver' }, { service: 'SmsSurveyNodeTreeResolver' }],
      modules: [
        AppModule.SmsSurveys,
        AppModule.Contacts,
        AppModule.RecentRecipient,
        AppModule.SenderSettingsCountries,
        AppModule.ContactCounter,
      ],
      accessControlGroups: ['NotCampaignsSmsSurveysEditStrategy'],
    },
    component: withAsyncWrapper(
      () => import('@/components/views/services/smsSurveys/SmsSurvey.vue'),
      SmsSurveyFlowResolver,
      {
        withFallback: true,
        wrapper: AsyncComponentResolverWrapper,
      },
    ),
    props: (route) => ({ smsSurveyId: route.params.smsSurveyId }),
  },
]

import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

export enum COMING_SOON_SERVICES {
  ComingSoonService,
}

export type ComingSoonServices = keyof typeof COMING_SOON_SERVICES

export const COMING_SOON_SERVICE_TYPES: Record<ComingSoonServices, symbol> = makeServicesTypes<ComingSoonServices>(
  enumKeysToArray<ComingSoonServices>(COMING_SOON_SERVICES),
)

import { inject, injectable } from 'inversify'
import type { CountryCode } from 'libphonenumber-js'
import DomainBaseService from '@/services/domain/domainBaseService'
import type CountryRepository from '@/data/repositories/domain/countryRepository'
import { DomainSettings } from '@/decorators/domainDecorators'
import Country from '@/data/models/domain/Country'
import { selectOptionLabelKey, selectOptionValueKey } from '@/services/forms/types'
import type { CountrySelectOption } from '@/services/forms/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import {
  countriesNotSupportAllSendingSettings,
  TmFakeCountryCode,
  type TmUndefinedCountryCode,
  type TmCountryCode,
} from '@/services/types'
import { countryPhoneData } from '@/constants/countryPhoneData'
import type { CountryPhoneDataServiceInterface } from '@/services/domain/user/types'
import { LRUCacheService } from '@/services/cache/lruCacheService'

@DomainSettings({
  model: Country,
})
@injectable()
export default class CountryService
  extends DomainBaseService<CountryRepository>
  implements CountryPhoneDataServiceInterface
{
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  private readonly countriesWithThePrefix: CountryCode[] = [
    'US',
    'GB',
    'NL',
    'PH',
    'BS',
    'GM',
    'MV',
    'CD',
    'CG',
    'CF',
    'CZ',
    'AE',
    'KM',
    'MH',
    'SC',
  ]

  private topCountries: TmCountryCode[] = ['US', 'GB', 'AU', 'CA']

  private countryNameCache = new LRUCacheService<string>(25, 10000)

  public getSelectOptionCountries(onlyRealCountries = false): CountrySelectOption[] {
    const countries = this.getDomainRepository().all()
    const countryOptions = countries.map((country, index) => ({
      country,
      [selectOptionValueKey]: country.id,
      [selectOptionLabelKey]: country.name,
      index: index + 1,
    }))
    const filteredOptions = this.filterCountryOptions(countryOptions, onlyRealCountries)
    return this.sortCountryOptions(filteredOptions)
  }

  public getCountryPhoneDataOptions(onlyRealCountries = false) {
    const filteredOptions = this.filterCountryOptions(countryPhoneData, onlyRealCountries)
    return this.sortCountryOptions(filteredOptions)
  }

  public getCountryPhone(countryCode: TmCountryCode) {
    return countryPhoneData.find(({ value }) => value === countryCode)
  }

  public getFirstCountryPhone() {
    return countryPhoneData[0]
  }

  public getCountryName(countryCode: TmCountryCode | typeof TmUndefinedCountryCode) {
    if (!this.countryNameCache.get(countryCode)) {
      this.countryNameCache.set(countryCode, this.findEntityByIdOrNull(countryCode)?.name || '')
    }
    return this.countryNameCache.get(countryCode) as string
  }

  public checkCountrySupportsAllSenderSettings(countryId: TmCountryCode) {
    // `Sender ID` and `Own mobile`
    // The user is registered in a country where `Sender IDs` and `Your mobile phone` options are supported.
    return !countriesNotSupportAllSendingSettings.includes(countryId)
  }

  public isValidCountryCode(countryCode: string): countryCode is TmCountryCode {
    const res = this.getDomainRepository().find(countryCode)
    return res !== null
  }

  public filterCountryOptions<T extends { value: TmCountryCode }>(countries: T[], onlyRealCountries: boolean): T[] {
    if (!onlyRealCountries) return countries
    return countries.filter(({ value }) => value !== TmFakeCountryCode)
  }

  public sortCountryOptions<T extends { value: TmCountryCode }>(countries: T[]): T[] {
    const orderList = [...this.topCountries].reverse()
    return countries.sort((a, b) => {
      const aOrder = orderList.indexOf(a.value)
      const bOrder = orderList.indexOf(b.value)
      return bOrder - aOrder
    })
  }

  public getFormattedCountryName(countryId: CountryCode) {
    const countryName = this.getCountryName(countryId)
    if (this.countriesWithThePrefix.includes(countryId)) {
      return `the ${countryName}`
    }
    return countryName
  }
}

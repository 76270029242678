export const coersceBoolean = <T, F>(value: unknown, trueVal: T, falseVal: F): T | F => {
  switch (String(value)) {
    case 'true':
    case '1':
      return trueVal
    case 'false':
    case '0':
    case '':
    default:
      return falseVal
  }
}

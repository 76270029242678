import { isNull } from 'lodash-es'
import type { DefaultColumns, CellProps } from '@/core/tables/types'
import type BaseModel from '@/data/models/BaseModel'
import { isRecordUnknown } from '@/utils/typeGuards'
import type { Currency } from '@/types'
import type { CustomFieldValue } from '@/data/models/domain/CustomFieldValue'

export const CUSTOM_FIELD_FORM_FIELD_NAME = 'customFieldsValues'

export const CUSTOM_FIELD_SCHEMA_TITLE_MAX_LEN = 50 as const
export const CUSTOM_FIELD_SCHEMA_OPTION_MAX_LEN = 50 as const
export const CUSTOM_FIELD_TEXT_VALUE_MAX_LEN = 1000 as const

export interface CustomFieldInterface {
  id: string
  name: string
  userCustomFieldId?: string
  value?: string
  createdAt?: string
}

export interface CustomFieldsRepositoryInterface {
  findByIdsAndUserCustomFieldId(ids: string[], userCustomFieldId: string): CustomFieldInterface
}

export interface CustomFieldServiceInterface {
  getCustomFieldColumns(columnOrder: number, visible: boolean): DefaultColumns
  computePropsForColumns(props: CellProps<any>, customFieldsEntityType: typeof BaseModel): Record<string, any>
  isCustomField(props: CellProps<any>): boolean
}

export const CustomFieldTypeValuesBase = ['text', 'numeric', 'monetary', 'date'] as const
export const CustomFieldTypeValuesWithOptions = ['singleOption', 'multiOption'] as const

export const CustomFieldTypeValues = [...CustomFieldTypeValuesBase, ...CustomFieldTypeValuesWithOptions] as const

export type CustomFieldTypeBase = (typeof CustomFieldTypeValuesBase)[number]
export type CustomFieldTypeWithOptions = (typeof CustomFieldTypeValuesWithOptions)[number]

export type CustomFieldType = CustomFieldTypeBase | CustomFieldTypeWithOptions

export const isCustomFieldType = (value: unknown): value is CustomFieldType =>
  CustomFieldTypeValues.includes(value as CustomFieldType)

export const isCustomFieldTypeBase = (value: CustomFieldType): value is CustomFieldTypeBase =>
  CustomFieldTypeValuesBase.includes(value as never)

export const isCustomFieldTypeWithOptions = (value: CustomFieldType): value is CustomFieldTypeWithOptions =>
  CustomFieldTypeValuesWithOptions.includes(value as never)

export type CustomFieldOption = {
  id: string
  value: string
  order?: number
}

type CustomFieldValueBase<FieldType extends CustomFieldType, Value> = {
  fieldType: FieldType
  value: Value
}

export type CustomFieldTextValueType = CustomFieldValueBase<'text', string>
export type CustomFieldNumberValueType = CustomFieldValueBase<'numeric', number>
export type CustomFieldDateValueType = CustomFieldValueBase<'date', string>
export type CustomFieldMonetaryValueType = CustomFieldValueBase<'monetary', number> & {
  currency: Currency
}
export type CustomFieldSingleOptionValueType = CustomFieldValueBase<'singleOption', string | number>

export type CustomFieldMultiOptionsValueType = CustomFieldValueBase<'multiOption', string[]>

export type CustomFieldValueType =
  | CustomFieldTextValueType
  | CustomFieldNumberValueType
  | CustomFieldDateValueType
  | CustomFieldMonetaryValueType
  | CustomFieldSingleOptionValueType
  | CustomFieldMultiOptionsValueType

export const isCustomFieldValue = (value: unknown): value is CustomFieldValueType => {
  return (
    isRecordUnknown(value) &&
    'value' in value &&
    'fieldType' in value &&
    CustomFieldTypeValues.includes(value.fieldType as CustomFieldType)
  )
}

export const isCustomFieldTextValueType = (value: unknown): value is CustomFieldTextValueType => {
  return isCustomFieldValue(value) && value.fieldType === 'text' && typeof value.value === 'string'
}

export const isCustomFieldNumberValueType = (value: unknown): value is CustomFieldNumberValueType => {
  return isCustomFieldValue(value) && value.fieldType === 'numeric' && typeof value.value === 'number'
}

export const isCustomFieldMonetaryValueType = (value: unknown): value is CustomFieldMonetaryValueType => {
  return (
    isCustomFieldValue(value) &&
    value.fieldType === 'monetary' &&
    typeof value.value === 'number' &&
    'currency' in value
  )
}

export const isCustomFieldSingleOptionValueType = (value: unknown): value is CustomFieldSingleOptionValueType => {
  return isCustomFieldValue(value) && value.fieldType === 'singleOption'
}

export const isCustomFieldMultiOptionsValueType = (value: unknown): value is CustomFieldMultiOptionsValueType => {
  return isCustomFieldValue(value) && value.fieldType === 'multiOption'
}

export const isCustomFieldDateValueType = (value: unknown): value is CustomFieldDateValueType => {
  return isCustomFieldValue(value) && value.fieldType === 'date' && typeof value.value === 'string'
}

export const isCustomField = (value: unknown): value is CustomFieldValue => {
  return (
    isRecordUnknown(value) &&
    typeof value.fieldId === 'string' &&
    typeof value.fieldTitle === 'string' &&
    isCustomFieldType(value.fieldType) &&
    (isNull(value.value) || isCustomFieldValue(value.value))
  )
}
type AbstractCustomField<T extends CustomFieldType, V extends CustomFieldValueType> = {
  fieldType: T
  value: V
}
type TextCustomField = AbstractCustomField<'text', CustomFieldTextValueType>
export const isTextCustomField = (value: unknown): value is TextCustomField => {
  return isCustomField(value) && value.fieldType === 'text'
}

type NumericCustomField = AbstractCustomField<'numeric', CustomFieldNumberValueType>
export const isNumericCustomField = (value: unknown): value is NumericCustomField => {
  return isCustomField(value) && value.fieldType === 'numeric'
}

type DateCustomField = AbstractCustomField<'date', CustomFieldDateValueType>
export const isDateCustomField = (value: unknown): value is DateCustomField => {
  return isCustomField(value) && value.fieldType === 'date'
}

type MonetaryCustomField = AbstractCustomField<'monetary', CustomFieldMonetaryValueType>
export const isMonetaryCustomField = (value: unknown): value is MonetaryCustomField => {
  return isCustomField(value) && value.fieldType === 'monetary'
}

type SingleOptionCustomField = AbstractCustomField<'singleOption', CustomFieldSingleOptionValueType>
export const isSingleOptionCustomField = (value: unknown): value is SingleOptionCustomField => {
  return isCustomField(value) && value.fieldType === 'singleOption'
}

type MultiOptionsCustomField = AbstractCustomField<'multiOption', CustomFieldMultiOptionsValueType>
export const isMultiOptionsCustomField = (value: unknown): value is MultiOptionsCustomField => {
  return isCustomField(value) && value.fieldType === 'multiOption'
}

export type CreateCustomFieldRequest = {
  title: string
  fieldType: CustomFieldType
  fieldOptions?: CustomFieldOption[]
}

export type UpdateCustomFieldRequest = {
  id: string
  title: string
  fieldOptions?: CustomFieldOption[]
}

export type CustomFieldUpdateFormBody = {
  fieldId: number
  fieldType: CustomFieldType
  value: CustomFieldValueType | null
}

export const SYM_COLUMNS_CUSTOM_FIELDABLE = Symbol('ColumnsCustomFieldable')

import type { IconSize } from '@/components/shared/types'
import { addPxToNumber } from '@/utils/string/addPxToNumber'

const widthMap: Record<IconSize, number> = {
  xxSmall: 10,
  xSmall: 12,
  size13: 13,
  small: 14,
  size15: 15,
  medium: 16,
  xMedium: 17,
  large: 18,
  xLarge: 20,
  xxLarge: 22,
  xxxLarge: 24,
  size26: 26,
  xxxxLarge: 28,
  xxxxxLarge: 32,
  huge: 38,
  xHuge: 48,
}

export const getIconSizePx = (size: IconSize) => addPxToNumber(widthMap[size])

import { BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'
import type { ContactImportStatus } from '@/types'

@OrmModel(modelNames.CONTACT_IMPORT)
export default class ContactImport extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public fileName: string

  @StringField()
  public fileSize: string

  @NumberField()
  public status: ContactImportStatus

  @NumberField()
  public countImported: number

  @BooleanField()
  public hasImportFile: boolean

  @BooleanField()
  public hasCarrierLookupFile: boolean

  @BooleanField()
  public hasAvailableDetails: boolean

  @StringField()
  public createdAt: string
}

import { OrmModel, StringField, NumberField, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { UserCurrency } from '@/services/domain/user/types'

@OrmModel(modelNames.SENDER_ID_PRICE)
export default class SenderIdPrice extends BaseModel {
  @StringField()
  public id: string

  @NumberField()
  public price: number

  @AttrField()
  public currency: UserCurrency
}

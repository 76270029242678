import { inject } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type TranslateService from '@/services/translateService'
import {
  TendDlcTwilioBrandOrganizationType,
  TenDlcTwilioBrandStatus,
} from '@/services/domain/services/regulations/tenDlcBrand/types'
import type { StatusCellProps } from '@/services/domain/types'
import { getStatusColor, type MapStatusColor } from '@/utils/status'
import type TenDlcTwilioBrandRepository from '@/data/repositories/domain/services/regulations/tenDlcTwilioBrandRepository'
import { DomainSettings } from '@/decorators/domainDecorators'
import TenDlcTwilioBrand from '@/data/models/domain/services/regulations/TenDlcTwilioBrand'
import type { Nullable } from '@/types'

@DomainSettings({
  model: TenDlcTwilioBrand,
})
export default class TenDlcTwilioBrandService extends DomainBaseService<TenDlcTwilioBrandRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public async getBrand(): Promise<Nullable<TenDlcTwilioBrand>> {
    return this.getDomainRepository().getBrand()
  }

  public findBrand(): Nullable<TenDlcTwilioBrand> {
    return this.getDomainRepository().findBrand()
  }

  public getStatus(status: TenDlcTwilioBrandStatus): StatusCellProps {
    const labelMap: Record<TenDlcTwilioBrandStatus, string> = {
      [TenDlcTwilioBrandStatus.draft]: this.translateService.t('status.tenDlcBrandStatus.draft'),
      [TenDlcTwilioBrandStatus.supportReview]: this.translateService.t('status.tenDlcBrandStatus.pending'),
      [TenDlcTwilioBrandStatus.providerReview]: this.translateService.t('status.tenDlcBrandStatus.inReview'),
      [TenDlcTwilioBrandStatus.verified]: this.translateService.t('status.tenDlcBrandStatus.verified'),
      [TenDlcTwilioBrandStatus.actionRequired]: this.translateService.t('status.tenDlcBrandStatus.actionRequired'),
    }
    const colorMap: MapStatusColor<TenDlcTwilioBrandStatus> = {
      gray: [TenDlcTwilioBrandStatus.draft],
      green: [TenDlcTwilioBrandStatus.verified],
      blue: [TenDlcTwilioBrandStatus.supportReview, TenDlcTwilioBrandStatus.providerReview],
      orange: [TenDlcTwilioBrandStatus.actionRequired],
    }

    return {
      value: labelMap[status] || '',
      color: getStatusColor<TenDlcTwilioBrandStatus>(colorMap, status),
    }
  }

  public getLegalFormName(organizationType: Nullable<TendDlcTwilioBrandOrganizationType>): string {
    const verticalMap: Record<TendDlcTwilioBrandOrganizationType, string> = {
      [TendDlcTwilioBrandOrganizationType.public]: this.translateService.t('tenDlcBrandEntityType.public'),
      [TendDlcTwilioBrandOrganizationType.private]: this.translateService.t('tenDlcBrandEntityType.private'),
      [TendDlcTwilioBrandOrganizationType.nonProfit]: this.translateService.t('tenDlcBrandEntityType.nonProfit'),
      [TendDlcTwilioBrandOrganizationType.government]: this.translateService.t('tenDlcBrandEntityType.government'),
    }

    return organizationType ? verticalMap[organizationType] || '' : ''
  }

  public formatTaxEin(taxEin: string): string {
    const taxNumber = taxEin.trim()
    const isValidEin = taxNumber.substring(2, 3) === '-'
    if (!isValidEin) {
      return `${taxNumber.substring(0, 2)}-${taxNumber.substring(2, taxNumber.length)}`
    }
    return taxNumber
  }

  public async updateErrorFields(id: string, errorFields: string[]): Promise<void> {
    await this.insertOrUpdate([
      {
        id,
        errorFields,
      },
    ])
  }
}

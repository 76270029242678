import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { Endpoint } from '@/services/endpoints'
import ForwardAudio from '@/data/models/domain/ForwardAudio'

@RepoSettings<Endpoint>({
  model: ForwardAudio,
  fetch: 'audios',
  single: 'audio',
  create: 'audioUpload',
})
@injectable()
export default class ForwardAudioRepository extends OrmApiRepository<ForwardAudio> {
  public async uploadFile(file: File) {
    const fData = new FormData()
    fData.append('file', file, file.name ?? 'file')

    return this.postRequest(fData)
  }

  public downloadFile(audioId: string) {
    const path = this.endpointsService.getPath('audioDownload', [audioId])
    return this.getApiSource().getFile(path)
  }
}

import { injectable } from 'inversify'
import OrmLocalStorageRepository from '@/data/repositories/ormLocalStorageRepository'
import GlobalState from '@/data/models/GlobalState'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { ModelRaw } from '@/types'

@RepoSettings({
  model: GlobalState,
  fetch: GlobalState.entity,
  create: GlobalState.entity,
  single: GlobalState.entity,
})
@injectable()
export default class GlobalStateRepository extends OrmLocalStorageRepository<GlobalState> {
  protected id = '1'

  public init() {
    return this.updateState({
      showAuthLoader: false,
      oldVersion: false,
    })
  }

  public getLoadedState() {
    return this.findEntityByIdOrNull(this.id)
  }

  public async getState(): Promise<GlobalState> {
    return this.getRequest<GlobalState>(this.id)
  }

  public getStateSync() {
    return this.getRequestSync<GlobalState>(this.id)
  }

  public async updateState(body: Omit<Partial<ModelRaw<GlobalState>>, 'id'>) {
    const state = await this.getState()
    await this.postRequest({
      ...state,
      ...body,
      id: this.id,
    })
    await this.fill(this.id)
  }

  public setFieldValue(fieldName: keyof ModelRaw<GlobalState>, value: unknown) {
    return this.updateState({ [fieldName]: value })
  }

  public async getFieldValue(fieldName: keyof ModelRaw<GlobalState>) {
    const data = await this.getState()

    if (!data) return null

    return data[fieldName]
  }

  public getFieldValueSync(fieldName: keyof ModelRaw<GlobalState>) {
    const data = this.getStateSync()

    if (!data) return null

    return data[fieldName]
  }
}

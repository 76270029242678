import { TmApiClientError } from '@/core/error/transport/tmApiClientError'
import type { InternalErrorResponse } from '@/services/transport/types'
import type { TmTransportHttpErrorCode } from '@/core/error/transport/types'

export class TmApiValidationError extends TmApiClientError<InternalErrorResponse> {
  public name = 'TmApiValidationError'

  protected override code: TmTransportHttpErrorCode = 400

  public shouldBeMonitored(): boolean {
    return false
  }

  public static isNotEnoughMoney(message: string) {
    return message?.toLowerCase().includes('buy credit')
  }
}

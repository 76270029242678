import { injectable, inject } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { TpFormData } from '@/services/forms/types'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import BaseFormService from '@/services/forms/baseFormService'
import type FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type LoggerService from '@/services/loggerService'
import type UserService from '@/services/domain/user/userService'
import type BillingDetailsService from '@/services/domain/billing/billingDetailsService'
import type BillingService from '@/services/domain/billing/billingService'

@injectable()
export default class VatInformationFormService extends BaseFormService<FormBuilderService> {
  constructor(
    @inject(SERVICE_TYPES.FormBuilderFactoryService) formBuilderFactoryService: () => FormBuilderService,
    @inject(SERVICE_TYPES.EntityManager) em: EntityManagerService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService) validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) logger: LoggerService,
    @inject(SERVICE_TYPES.BillingService) protected billingService: BillingService,
    @inject(SERVICE_TYPES.UserService) protected userService: UserService,
    @inject(SERVICE_TYPES.BillingDetailsService) protected billingDetailsService: BillingDetailsService,
  ) {
    super(formBuilderFactoryService, em, validationRulesBuilderService, logger)
  }

  public async buildForm() {
    const builder = this.getBuilder()
    builder.control({
      fieldType: 'text',
      name: 'vatNumber',
      value: '',
      validators: this.validationRulesBuilderService.createRules<string>().custom(
        'vatChecker',
        (value) => {
          const billingDetailsSettings = this.billingDetailsService.findSettings()
          if (!billingDetailsSettings) {
            return false
          }
          return this.billingService.checkVatNumber(value, billingDetailsSettings.paymentCountry)
        },
        {
          messagePath: 'vatInformationEditModal.formFields.vatNumber.formErrors.invalid',
        },
      ),
    })
  }

  public async submit(formData: TpFormData) {
    await this.userService.updateVatNumber(
      this.billingService.getVatNumberWithoutCode(formData.vatNumber, this.userService.currentUser().countryId),
    )
  }
}

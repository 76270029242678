import '@/styles/app'
import 'reflect-metadata'
import '@/polyfills/requestIdleCallback'
import '@/polyfills/promiseWithResolvers'
import '@/polyfills/localStoragePolyfill'
import 'virtual:mdi-icons-round.css'
import 'virtual:tmi-icons.css'
import App from '@/components/views/App.vue'
import RouterMiddleware from '@/core/middlewares/routerMiddleware'
import type VueService from '@/services/vueService'
import LoggerMiddleware from '@/core/middlewares/loggerMiddleware'
import GlobalErrorHandlerMiddleware from '@/core/middlewares/errorHandlerMiddleware'
import { buildContainer, get } from '@/core/container/container'
import { getServicesMiddlewares, processMiddlewares } from '@/core/middlewares/middleware'
import servicesWeb from './config/servicesWeb'
import {
  backgroundLoadAndBindAllDeps,
  checkDeps,
  loadAndBindStartupDeps,
  printServicesInModules,
} from '@/core/container/deps'
import retryingDynamicImport from '@/retrying-dynamic-import'
import type { TelemetryService } from '@/services/telemetry/telemetryService'
import { lazyLoadEmojiData } from '@/utils/emojiHelpers'
import TranslateMiddleware from '@/core/middlewares/translateMiddleware'

retryingDynamicImport({
  checkOnlineUrl: import.meta.env.VUE_APP_APP_URL,
})

const modules = buildContainer(servicesWeb, checkDeps)
if (import.meta.env.VUE_APP_SERVICES_IN_MODULES_WARNING === 'true') {
  printServicesInModules()
}

processMiddlewares([
  new GlobalErrorHandlerMiddleware(),
  new RouterMiddleware(modules),
  new LoggerMiddleware(),
  new TranslateMiddleware(),
  ...getServicesMiddlewares(),
])

get<VueService>('VueService').create('#app', App)

lazyLoadEmojiData()

loadAndBindStartupDeps()

if (import.meta.env.VUE_APP_DISABLE_BACKGROUND_MODULE_LOADING !== 'true') {
  backgroundLoadAndBindAllDeps()
}

const telemetryService = get<TelemetryService>('TelemetryService')
telemetryService.init()

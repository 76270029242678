import { injectable } from 'inversify'

@injectable()
export class SmsContentService {
  public isSms(content: string): boolean {
    return !this.isMms(content) && !this.isVoiceBroadcast(content)
  }

  public isMms(content: string): boolean {
    return />(.+)<\/media>/.test(content)
  }

  public isVoiceBroadcast(content: string): boolean {
    return />(.+)<\/audio>/.test(content)
  }
}

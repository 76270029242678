import { injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import type SessionShortRepository from '@/data/repositories/domain/session/sessionShortRepository'
import SessionShort from '@/data/models/domain/session/SessionShort'

@DomainSettings({
  model: SessionShort,
})
@injectable()
export default class SessionShortService extends DomainBaseService<SessionShortRepository> {}

import { UidField, StringField, OrmModel, NumberField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'
import type { TicketCloseDataInterval } from '@/data/models/domain/tickets/types'

export const TICKET_WORKSPACE_ID = 'ticketWorkspace'

@OrmModel(modelNames.TICKET_WORKSPACE)
export default class TicketWorkspace extends BaseModel {
  @UidField(() => TICKET_WORKSPACE_ID)
  public id: string

  @StringField()
  public name: string

  @StringField()
  public domain: string

  @NumberField(null)
  public demoTicketId: number | null

  @StringField()
  public solvedTicketLifetime: TicketCloseDataInterval

  @NumberField()
  public startTicketId: number

  public get hasDemoTicket() {
    return this.demoTicketId !== null
  }
}

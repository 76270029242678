import { inject, injectable } from 'inversify'
import type { TypedFieldConfig } from '@/services/forms/baseForm/typedFormBuilder/types'
import {
  type SelectOption,
  type SelectSearchFacebookPageSubfieldNames,
  type SelectSearchFacebookPageFieldConfig,
  SelectSearchFacebookPageType,
  type SelectFacebookPageOverrideOption,
  type SelectFacebookPageOption,
  type SelectFacebookPageSubheaderOption,
} from '@/services/forms/types'
import { fieldFacebookPageRulePrefix } from '@/components/forms/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type TranslateService from '@/services/translateService'
import type FacebookPageService from '@/services/domain/messenger/facebook/facebookPageService'
import { facebookFlowRoute, facebookSettingsRoute } from '@/routes/user/messenger/facebook'

type FacebookPageFieldParams = {
  withCustomFacebookPage?: boolean
}

@injectable()
export default class SelectFacebookPageFieldBuilderService {
  constructor(
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    @inject(SERVICE_TYPES.FacebookPageService) protected readonly facebookPageService: FacebookPageService,
  ) {}

  public getSubfieldValidationRuleName(fieldName: SelectSearchFacebookPageSubfieldNames) {
    return `${fieldFacebookPageRulePrefix}-${fieldName}`
  }

  public getFieldConfig({
    withCustomFacebookPage,
  }: FacebookPageFieldParams): TypedFieldConfig<SelectSearchFacebookPageFieldConfig> {
    return {
      fieldType: 'selectSearchFacebookPage',
      value: {
        valueType: SelectSearchFacebookPageType.DEFAULT,
      },
      componentProps: {
        withCustomFacebookPage: () => withCustomFacebookPage,
      },
    }
  }

  public extendOptions(options: SelectOption[]): SelectFacebookPageOption[] {
    const subheaderOption: SelectFacebookPageSubheaderOption = {
      text: 'connectedFacebookPage',
      value: 'connectedFacebookPage',
      subheader: this.translateService.t('fieldSelectSearchFacebookPage.facebookPages'),
      disabled: true,
    }

    const newFacebookPageOption: SelectFacebookPageOverrideOption = {
      text: this.translateService.t('fieldSelectSearchFacebookPage.connectNew'),
      value: 'newFacebookPageValue',
      overrideOption: true,
      type: 'button',
      to: {
        name: this.facebookPageService.hasItems() ? facebookSettingsRoute.name : facebookFlowRoute.name,
      },
    }

    const resultOptions: SelectFacebookPageOption[] = [subheaderOption]

    if (options.length) {
      resultOptions.push(...options)
    } else {
      resultOptions.push(newFacebookPageOption)
    }

    return resultOptions
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable, SingleModelResolverParams } from '@/services/resolvers/types'
import type BaseResolverService from '@/services/resolvers/baseResolverService'
import DomainSettingsService from '@/services/domain/domainSettingsService'
import { TmResolverError } from '@/core/error/tmResolverError'

@injectable()
export default class SingleSettingsModelResolverService implements Resolvable<SingleModelResolverParams> {
  constructor(@inject(SERVICE_TYPES.BaseResolverService) protected readonly baseResolverService: BaseResolverService) {}

  public async resolve(params: SingleModelResolverParams) {
    const service = this.getService(params)
    await service.getSettings()
  }

  public isFailed(params: SingleModelResolverParams) {
    const service = this.getService(params)
    return !service.findSettings()
  }

  private getService(params: SingleModelResolverParams) {
    const service = this.baseResolverService.getDomainService(params.model)

    if (!(service instanceof DomainSettingsService)) {
      throw new TmResolverError('Service is not DomainSettingsService')
    }

    return service
  }
}

import type { PluginComponents, Model } from '@vuex-orm/core'
import { BelongsTo, HasManyBy } from '@vuex-orm/core'
import type { Record } from '@vuex-orm/core/dist/src/data'

export const fixUpdatingForeignFieldsPlugin = {
  install: ({ Model }: PluginComponents) => {
    const initialInsertOrUpdate = Model.insertOrUpdate

    Model.insertOrUpdate = function insertOrUpdate(payload) {
      if (Array.isArray(payload.data)) {
        payload.data.forEach((record) => {
          fixRecord(record, this)
        })
      } else {
        fixRecord(payload.data, this)
      }

      return initialInsertOrUpdate.call(this, payload)
    }
    const initialInsertOrUpdate$ = Model.prototype.$insertOrUpdate
    Model.prototype.$insertOrUpdate = function $insertOrUpdate(payload) {
      if (Array.isArray(payload.data)) {
        payload.data.forEach((record) => {
          fixRecord(record, this.$self())
        })
      } else {
        fixRecord(payload.data, this.$self())
      }

      return initialInsertOrUpdate$.call(this.$self(), payload)
    }
  },
}

/**
 * VuexORM doesn't update foreign key values when related fields are null or empty
 * We do it ourselves
 */
function fixRecord(record: Record, model: typeof Model) {
  const modelAttrs = model.fields()
  const modelAttrEntries = Object.entries(modelAttrs)

  // eslint-disable-next-line consistent-return
  modelAttrEntries.forEach(([key, attr]) => {
    const payloadAttrValue = record[key]
    if (attr instanceof BelongsTo) {
      if (payloadAttrValue === null && !record[attr.foreignKey]) {
        record[attr.foreignKey] = modelAttrs[attr.foreignKey].value
      } else if (payloadAttrValue) {
        return fixRecord(payloadAttrValue as Record, attr.parent)
      }
    }
    if (attr instanceof HasManyBy) {
      if (payloadAttrValue?.length === 0 && (!record[attr.foreignKey] || record[attr.foreignKey].length === 0)) {
        record[attr.foreignKey] = modelAttrs[attr.foreignKey].value
      } else if (payloadAttrValue) {
        ;(payloadAttrValue as Record[]).forEach((nestedRecord: Record) => {
          fixRecord(nestedRecord, attr.parent)
        })
      }
    }
  })
}

import { injectable } from 'inversify'
import type { CancelToken } from 'axios'
import Note from '@/data/models/domain/Note'
import OrmApiRepository from '@/data/repositories/ormApiRepository'

import { RepoSettings } from '@/decorators/repositoryDecorators'

import type { Endpoint } from '@/services/endpoints'
import type { NotePostBody } from '@/services/domain/note/types'
import type { PaginationUrlType } from '@/services/tables/types'
import type { PaginationOffsetResponse, PaginationParams } from '@/services/tables/pagination/types'

@RepoSettings<Endpoint>({
  model: Note,
  single: 'note',
  fetch: 'notes',
  create: 'noteCreate',
  bulkDeleteEndpoint: 'noteBulkDelete',
})
@injectable()
export default class NoteRepository extends OrmApiRepository<Note> {
  private isLoading: boolean = false

  private queuedActions: (() => void)[] = []

  private processQueuedActions() {
    for (const action of this.queuedActions) {
      action()
    }
    this.queuedActions = []
  }

  public createNote(note: NotePostBody, contactId: string) {
    try {
      this.isLoading = true
      const endpoint = this.endpointsService.getPath(this.settings().create!, [contactId])
      return this.getApiSource().post(endpoint, note)
    } finally {
      this.isLoading = false
      this.processQueuedActions()
    }
  }

  public getNotesByIds(ids: string[], limit: number = 2 ** 53 - 1) {
    // polyfill of `Number.MAX_SAFE_INTEGER` for IE11 here
    return this.query().with(['user.*']).whereIdIn(ids).orderBy('createdAt', 'desc').limit(limit).get()
  }

  public getTotalCount(ids: string[]): number {
    return this.query().whereIdIn(ids).count()
  }

  public getNotes(
    fetchEndpointParams: string[],
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
    searchFields?: string[],
    cancelToken?: CancelToken,
  ): Promise<PaginationOffsetResponse<Note>> {
    return this.doGrid<Note>(
      this.getSettingsFetch(),
      fetchEndpointParams,
      queryParameterBag,
      paginationParamsBag,
      searchQuery,
      searchFields,
      cancelToken,
    )
  }

  public async queueInsertOrUpdate(notes: Pick<Note, 'id' | 'note' | 'userId' | 'createdAt'>[], ignoreLoading = false) {
    return new Promise((resolve) => {
      const action = async () => {
        await this.insertOrUpdate(notes)
        resolve(notes)
      }

      if (this.isLoading && !ignoreLoading) {
        this.queuedActions.push(action)
      } else {
        action()
      }
    })
  }
}

import { BooleanField, AttrField, OrmModel } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'

@OrmModel(modelNames.BYOC)
export default class Byoc extends BaseModel {
  @BooleanField()
  public isAvailableForCountry: boolean

  @BooleanField()
  public hasFailedNumbers: boolean

  @BooleanField()
  public hasFailedProvider: boolean

  @BooleanField()
  public hasConnectedProviders: boolean

  @BooleanField()
  public hasSenderIds: boolean

  @BooleanField()
  public hasPhones: boolean

  @AttrField()
  public access: ByocAccess
}

type ByocAccess = IByocAccessEmpty<null> | IByocAccessEmpty<'сountry'> | IByocAccess<'plan'> | IByocAccess<'trial'>

interface IByocAccessEmpty<T> {
  accessType: T
  isActive: boolean
}

interface IByocAccess<T> {
  accessType: T
  isActive: boolean
  expiredAt: string | null
}

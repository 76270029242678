import type { RouteRecordRaw } from 'vue-router'
import { DealsRoutes } from '@/routes/user/deals/types'
import { AppModule } from '@/config/types'

export const getDealsPipelineRoutes = (): RouteRecordRaw[] => [
  {
    name: DealsRoutes.base,
    path: '',
    redirect: { name: DealsRoutes.pipelineBase },
    meta: {
      modules: [AppModule.ComingSoon],
      resolvers: [{ service: 'DealPipelineResolverService' }, { service: 'FetchAllAccountsResolver' }],
      resolverComponent: () => import('@/components/resolvers/DealsPipelineResolver.vue'),
    },
    children: [
      {
        name: DealsRoutes.pipelineBase,
        path: '',
        meta: {
          resolvers: [{ service: 'DealsRedirectToFirstPipelineResolverService' }],
          resolverComponent: () => import('@/components/resolvers/DealsPipelineResolver.vue'),
        },
        component: () => import('@/components/layout/PassThrough.vue'),
      },
      {
        name: DealsRoutes.overview,
        path: 'overview',
        component: () => import('@/components/views/deals/DealsEmptyOverview.vue'),
      },
      {
        name: DealsRoutes.pipeline,
        path: 'pipeline/:pipelineId',
        props: true,
        component: () => import('@/components/views/deals/Pipeline.vue'),
      },
    ],
  },
]

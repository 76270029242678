import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import type { DistributionDetailsPayload } from '@/services/domain/services/distribution/types'
import DistributionDetails from '@/data/models/domain/services/distribution/DistributionDetails'
import type DistributionDetailsRepository from '@/data/repositories/domain/services/distribution/distributionDetailsRepository'

@DomainSettings({
  model: DistributionDetails,
})
@injectable()
export default class DistributionDetailsService extends DomainBaseService<DistributionDetailsRepository> {
  public createDistribution(payload: DistributionDetailsPayload) {
    return this.getDomainRepository().createDistribution(payload)
  }

  public updateDistribution(id: string, payload: DistributionDetailsPayload) {
    return this.getDomainRepository().updateDistribution(id, payload)
  }
}

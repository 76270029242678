import { injectable } from 'inversify'
import type { RouteRecordRaw } from 'vue-router'
import type RouterBuilderMiddlewareInterface from '@/services/route/routerBuilderMiddleware/types'
import type { AppModule } from '@/config/types'

@injectable()
export default class ModulesRouterBuilderMiddleware implements RouterBuilderMiddlewareInterface {
  public pipe(routes: RouteRecordRaw[]): RouteRecordRaw[] {
    this.addModulesToRoute(routes)
    return routes
  }

  private addModulesToRoute(routes: RouteRecordRaw[], modules: AppModule[] = []) {
    for (const route of routes) {
      if (modules?.length) {
        if (!route.meta) {
          route.meta = {}
        }
        if (!route.meta.modules) {
          route.meta.modules = modules
        } else {
          route.meta.modules = Array.from(new Set(route.meta.modules.concat(modules)))
        }
      }
      if (route.children?.length) {
        this.addModulesToRoute(route.children, route.meta?.modules)
      }
    }
  }
}

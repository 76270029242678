import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  InstagramAccountRepository,
  InstagramAccountService,
  InstagramColumnService,
}

export type InstagramServices = keyof typeof SERVICES

export const INSTAGRAM_SERVICE_TYPES: Record<InstagramServices, symbol> = makeServicesTypes<InstagramServices>(
  enumKeysToArray<InstagramServices>(SERVICES),
)

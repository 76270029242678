import { injectable } from 'inversify'
import { uniqBy } from 'lodash-es'
import type {
  RuleConfig,
  ValidationRule,
  ValidationRuleFunctionCustom,
  ValidationRulesMap,
} from '@/services/validation/types'
import type { NestedKeyOf } from '@/types'

@injectable()
export default class ValidationRulesBuilderService {
  // @todo better get type from fieldConfig[value]
  public createRules<TValue = unknown>(
    rules?: ValidationRule[],
    localValidators?: ValidationRulesMap,
  ): ValidationRules<TValue> {
    return new ValidationRules<TValue>(rules, localValidators)
  }

  public compose<TValue = unknown>(...rules: ValidationRules<TValue>[]): ValidationRules<TValue> {
    const composedRules: ValidationRule[] = []
    const composedValidators: ValidationRulesMap = new Map()

    for (const rule of rules) {
      composedRules.push(...rule.getRules())
      rule.getLocalValidators().forEach((value, key) => {
        composedValidators.set(key, value)
      })
    }

    return this.createRules(uniqBy(composedRules, 'name'), composedValidators)
  }
}

export class BaseValidationRules<TValue = unknown> {
  constructor(
    protected rules: ValidationRule[] = [],
    protected localValidators: ValidationRulesMap = new Map(),
  ) {}

  protected addRule(ruleName: string, config?: RuleConfig): this {
    if (!this.rules.some((r) => r.name === ruleName)) {
      this.rules.push({ name: ruleName, ...config })
    }
    return this
  }

  protected removeRule(ruleName: string): this {
    this.rules = this.rules.filter((existsRule) => existsRule.name !== ruleName)
    return this
  }

  public getRules(): ValidationRule[] {
    return this.rules
  }

  public getLocalValidators(): ValidationRulesMap {
    return this.localValidators
  }

  public custom(name: string, func: ValidationRuleFunctionCustom<TValue>, config?: RuleConfig) {
    this.localValidators.set(name, (value, params, formValues, validatorConfig) =>
      func(value, formValues, validatorConfig),
    )
    this.addRule(name, config)

    return this
  }
}

export class ValidationRules<TValue = unknown> extends BaseValidationRules<TValue> {
  public required(config?: RuleConfig): this {
    return this.addRule('required', config)
  }

  public optional(): this {
    return this.removeRule('required')
  }

  public minLength(length: number, config?: RuleConfig): this {
    return this.addRule(`min:${length}`, config)
  }

  public maxLength(length: number, config?: RuleConfig): this {
    return this.addRule(`max:${length}`, config)
  }

  public lessThan(max: number | `${number}`, config?: RuleConfig): this {
    return this.addRule(`lessThan:${max}`, config)
  }

  public hasLetters(config?: RuleConfig): this {
    return this.addRule('hasLetters', config)
  }

  public hasNumbers(config?: RuleConfig): this {
    return this.addRule('hasNumbers', config)
  }

  public hasSpecialChars(config?: RuleConfig): this {
    return this.addRule('hasSpecialChars', config)
  }

  public hasLowercaseAndUppercase(config?: RuleConfig): this {
    return this.addRule('hasLowercaseAndUppercase', config)
  }

  public hasUppercase(config?: RuleConfig): this {
    return this.addRule('hasUppercase', config)
  }

  public hasLowercase(config?: RuleConfig): this {
    return this.addRule('hasLowercase', config)
  }

  public numeric(config?: RuleConfig): this {
    return this.addRule('numeric', config)
  }

  public alphaNumeric(config?: RuleConfig): this {
    return this.addRule('alpha_num', config)
  }

  public minValue(value: number, config?: RuleConfig): this {
    return this.addRule(`minValue:${value}`, config)
  }

  public email(config?: RuleConfig): this {
    return this.addRule('email', config)
  }

  public hasEveryValid(config?: RuleConfig): this {
    return this.addRule('hasEveryValid', config)
  }

  public hasSomeValid(config?: RuleConfig): this {
    return this.addRule('hasSomeValid', config)
  }

  public hasNoText(text: string, config?: RuleConfig) {
    return this.addRule(`hasNoText:${text}`, config)
  }

  public confirmation(targetFieldName: string, config?: RuleConfig) {
    return this.addRule(`confirmation:@${targetFieldName}`, config)
  }

  public noEqual(targetFieldName: string, config?: RuleConfig) {
    return this.addRule(`noEqual:@${targetFieldName}`, config)
  }

  public vat(targetFieldName: string, config?: RuleConfig) {
    return this.addRule(`vat:@${targetFieldName}`, config)
  }

  public phone(countryId?: string, config?: RuleConfig) {
    const rule = countryId ? `phoneNumber:${countryId}` : 'phoneNumber'
    return this.addRule(rule, config)
  }

  public phoneRequired(config?: RuleConfig) {
    return this.addRule('phoneRequired', config)
  }

  public phoneValid(config?: RuleConfig) {
    return this.addRule('phoneValid', config)
  }

  public contactPhoneValid(config?: RuleConfig) {
    return this.addRule('contactPhoneValid', config)
  }

  public oneOf(values: string[], config?: RuleConfig) {
    return this.addRule(`one_of:${values.join(',')}`, config)
  }

  public fileAllowed(config?: RuleConfig) {
    return this.addRule('fileAllowed', config)
  }

  public fileMaxSize(config?: RuleConfig) {
    return this.addRule('maxFileSize', config)
  }

  public fileIsImage(config?: RuleConfig) {
    return this.addRule('isImage', config)
  }

  public domain(config?: RuleConfig) {
    return this.addRule('domain', config)
  }

  public domainOrEmail(config?: RuleConfig) {
    return this.addRule('domainOrEmail', config)
  }

  public someOfValidators(...builders: BaseValidationRules[]) {
    return this.addRule(
      `someOfValidators:${builders
        .map((builder) =>
          builder
            .getRules()
            .map((rule) => rule.name)
            .join('|'),
        )
        .join(',')}`,
    )
  }

  public fileIsTableDocument(config?: RuleConfig) {
    return this.addRule('isTableDocument', config)
  }

  public fileIsMessagesAttachment(config?: RuleConfig) {
    return this.addRule('isMessagesAttachment', config)
  }

  public fileIsMmsAttachment(config?: RuleConfig) {
    return this.addRule('isMmsAttachment', config)
  }

  public fileIsWhatsAppFileAttachment(config?: RuleConfig) {
    return this.addRule('isWhatsAppFileAttachment', config)
  }

  public fileIsWhatsAppMediaAttachment(config?: RuleConfig) {
    return this.addRule('isWhatsAppMediaAttachment', config)
  }

  public fileIsInstagramMediaAttachment(config?: RuleConfig) {
    return this.addRule('isInstagramMediaAttachment', config)
  }

  public fileIsPointAiFileAttachment(config?: RuleConfig) {
    return this.addRule('isPointAiFileAttachment', config)
  }

  public notOverlapWithFields(config?: RuleConfig, ...targetFieldNames: string[]) {
    for (const fieldName of targetFieldNames) {
      this.addRule(`notOverlapWithFields:@${fieldName}`, config)
    }

    return this
  }

  public isUniqueInArray(targetFieldName: string, config?: RuleConfig) {
    return this.addRule(`isUniqueInArray:@${targetFieldName}`, config)
  }

  public url(config?: RuleConfig) {
    return this.addRule('url', config)
  }

  public urlRegex(config?: RuleConfig) {
    return this.addRule('urlRegex', config)
  }

  public ip(config?: RuleConfig) {
    return this.addRule('ip', config)
  }

  public hasComposeContent(config?: RuleConfig) {
    return this.addRule('hasComposeContent', config)
  }

  public composeAvailableTags(config?: RuleConfig) {
    return this.addRule('composeAvailableTags', config)
  }

  public fullNameRequired(config?: RuleConfig) {
    return this.addRule('fullNameRequired', config)
  }

  public requiredFilters(config?: RuleConfig) {
    return this.addRule('requiredFilters', config)
  }

  public requiredColumns(config?: RuleConfig) {
    return this.addRule('requiredColumns', config)
  }

  public requiredNested<T extends Record<string, unknown> = Record<string, unknown>>(
    key: NestedKeyOf<T>,
    config?: RuleConfig,
  ) {
    return this.addRule(`requiredNested:${key}`, config)
  }

  public maxLengthNested<T extends Record<string, unknown> = Record<string, unknown>>(
    key: NestedKeyOf<T>,
    length: number,
    config?: RuleConfig,
  ) {
    return this.addRule(`maxLenNested:${key},${length}`, config)
  }

  public isFileOrResponse(mimeTypes: string[], config?: RuleConfig) {
    return this.addRule(`isFileOrResponse:${mimeTypes.join(',')}`, config)
  }

  public richEditorRequired(config?: RuleConfig) {
    return this.addRule('richEditorRequired', config)
  }

  public dateIsEarlierThan(to: string | number, config?: RuleConfig): this {
    return this.addRule(`dateIsEarlierThan:${to}`, config)
  }

  public textCustomField(config?: RuleConfig): this {
    return this.addRule('textCustomField', config)
  }

  public numericCustomField(config?: RuleConfig): this {
    return this.addRule('numericCustomField', config)
  }

  public monetaryCustomField(config?: RuleConfig): this {
    return this.addRule('monetaryCustomField', config)
  }

  public dateCustomField(config?: RuleConfig): this {
    return this.addRule('dateCustomField', config)
  }
}

import { injectable } from 'inversify'
import type { AutocompleteKeyBuilderI } from '@/services/types'
import type BaseModel from '@/data/models/BaseModel'
import type { PaginationUrlFilterType } from './tables/types'
import { getStringHashCode } from '@/utils/string/getStringHashCode'

@injectable()
export class AutocompleteKeyBuilder implements AutocompleteKeyBuilderI {
  public pageMapKey(model: typeof BaseModel, page: number, filtersKey: string) {
    return ['autocomplete', model.entity, filtersKey, page].filter(Boolean).join('-')
  }

  public autocompleteKey(model: typeof BaseModel, filtersKey: string) {
    return [model.entity, filtersKey].filter(Boolean).join('-')
  }

  public entityFromAutocompleteKey(key: string) {
    return key.split('-')[0]
  }

  public autocompleteFiltersKey(filters: PaginationUrlFilterType) {
    return filters.length ? String(getStringHashCode(JSON.stringify(filters))) : ''
  }
}

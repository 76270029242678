import { Loading, type QuasarPluginOptions } from 'quasar'
import { quasarIconSet } from '@/assets/icons/quasar-icon-set'

export default {
  config: {},
  plugins: {
    Loading,
  },
  iconSet: quasarIconSet,
} satisfies Partial<QuasarPluginOptions>

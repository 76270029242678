<template>
  <transition name="q-transition--fade">
    <div
      v-if="showComponent"
      ref="tooltipEl"
      :data-id="id"
      :class="[
        tooltipWrapperClassName,
        {
          [`${tooltipWrapperClassName}--hidden`]: hideContent,
          [`${tooltipWrapperClassName}--interactive`]: interactive,
        },
      ]"
      :style="wrapperPositionStyle"
    >
      <component
        :is="componentName"
        v-bind="componentProps"
      />
    </div>
  </transition>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from '@/composition/vue/compositionApi'
import { addPxToNumber } from '@/utils/string/addPxToNumber'
import {
  type TooltipVariants,
  tooltipDefaultDirection,
  tooltipDefaultVariant,
  TmTextTooltipPropsKeys,
  TmTranslateTooltipPropsKeys,
  SentSmsStatusTooltipPropsKeys,
  tooltipWrapperClassName,
} from '@/components/shared/tooltip/types'
import {
  getScrollbarWraps,
  getTooltipPositionStyle,
  isElementInsideContainer,
} from '@/composition/tooltip/positionEngineTooltip'

import TmTextTooltip from '@/components/shared/tooltip/TmTextTooltip.vue'
import TmTranslateTooltip from '@/components/shared/tooltip/TmTranslateTooltip.vue'
import SentSmsStatusTooltip from '@/components/domain/history/sms/sent/status/SentSmsStatusTooltip.vue'
import { useTooltipManager } from '@/composition/tooltip/useTooltipManager'

const props = withDefaults(
  defineProps<{
    id: string
    data: Record<string, any>
    anchorEl: HTMLElement
  }>(),
  {
    data: () => ({}),
  },
)

const { setUpdatePositionCallback, removeTooltip } = useTooltipManager()

const tooltipMap = {
  text: TmTextTooltip,
  translate: TmTranslateTooltip,
  sentSmsStatusTooltip: SentSmsStatusTooltip,
}

const tooltipPropsMap = {
  text: TmTextTooltipPropsKeys,
  translate: TmTranslateTooltipPropsKeys,
  sentSmsStatusTooltip: SentSmsStatusTooltipPropsKeys,
}

const componentProps = computed(() => {
  const propKeys = tooltipPropsMap[variant.value] || []
  return propKeys.reduce(
    (acc, key) => {
      if (key in data.value) {
        acc[key] = data.value[key]
      }
      return acc
    },
    {} as Record<string, any>,
  )
})

const closestScrolls = ref<HTMLElement[]>([])
const scrollHandlers = new Map()
const showComponent = ref(false)
const hideContent = ref(true)
const wrapperPositionStyle = ref<Record<string, string | undefined>>()
const tooltipEl = ref<HTMLElement | null>(null)
const data = ref({ ...props.data })
const variant = ref<TooltipVariants>(data.value.variant || tooltipDefaultVariant)
const interactive = ref(data.value.interactive || false)

const componentName = computed(() => tooltipMap[variant.value] || tooltipMap.text)

const clearHandlers = () => {
  window.removeEventListener('resize', updatePosition)
  props.anchorEl.removeEventListener('mouseleave', handleMouseLeave)
  tooltipEl.value?.removeEventListener('mouseleave', handleMouseLeave)
  scrollHandlers.forEach((handler, el) => {
    el.removeEventListener('scroll', handler)
  })
}

const hideTooltip = () => {
  clearHandlers()
  showComponent.value = false
  setTimeout(() => removeTooltip(props.id), 0) // time to fade
}

const shouldHideTooltip = (e?: MouseEvent): boolean => {
  return !(
    e?.relatedTarget &&
    (isElementInsideContainer(e.relatedTarget as Node, tooltipEl.value) ||
      isElementInsideContainer(e.relatedTarget as Node, props.anchorEl))
  )
}

const handleMouseLeave = (e?: MouseEvent) => {
  if (shouldHideTooltip(e)) {
    hideTooltip()
  }
}

const updatePosition = async () => {
  wrapperPositionStyle.value = undefined // prevents content from being compressed when there is still space on the left side
  await nextTick(() => {
    if (!props.anchorEl || !tooltipEl.value) return
    const { position, top, right, bottom, left, anchorBoundingRect, viewportWidth, viewportHeight } =
      getTooltipPositionStyle(
        props.data.direction || tooltipDefaultDirection,
        props.anchorEl,
        tooltipEl.value,
        props.data.offset,
      )
    wrapperPositionStyle.value = {
      top: (typeof top === 'number' && addPxToNumber(top)) || undefined,
      right: (typeof right === 'number' && addPxToNumber(right)) || undefined,
      bottom: (typeof bottom === 'number' && addPxToNumber(bottom)) || undefined,
      left: (typeof left === 'number' && addPxToNumber(left)) || undefined,
    }
    if (
      anchorBoundingRect.top + anchorBoundingRect.height < 0 || // top
      anchorBoundingRect.left > viewportWidth || // right
      anchorBoundingRect.top > viewportHeight || // bottom
      anchorBoundingRect.right < 0 // left
    ) {
      hideTooltip()
      return
    }
    data.value.offset = props.data.offset
    data.value.direction = position
    data.value.viewportWidth = viewportWidth
    data.value.viewportHeight = viewportHeight
    data.value.anchorBounding = anchorBoundingRect
    data.value.tooltipPosition = { top, right, bottom, left }

    autoCloseOnScroll()
  })
}

const autoCloseOnScroll = () => {
  closestScrolls.value = getScrollbarWraps(props.anchorEl)

  if (closestScrolls.value.length) {
    closestScrolls.value.forEach((scrollEl) => {
      if (!scrollHandlers.has(scrollEl)) {
        scrollEl.addEventListener('scroll', updatePosition)
      }
    })
  }
}

const showTooltip = async () => {
  showComponent.value = true
  await updatePosition()
  autoCloseOnScroll()
  setUpdatePositionCallback(props.id, updatePosition)
  window.addEventListener('resize', updatePosition)

  props.anchorEl.addEventListener('mouseleave', handleMouseLeave)
  if (data.value.interactive) {
    tooltipEl.value?.addEventListener('mouseleave', handleMouseLeave)
  }

  hideContent.value = false
}

onMounted(showTooltip)
</script>

<style lang="scss" scoped>
.tm-tooltip-wrapper {
  position: fixed;
  z-index: 7000; // q-menu: 6000

  &:not(&--interactive) {
    pointer-events: none !important;
  }
  &--hidden {
    visibility: hidden;
  }
}
</style>

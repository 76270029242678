import { inject, injectable } from 'inversify'
import type { RouteLocationNormalized } from 'vue-router'
import { cloneDeep } from 'lodash-es'
import type { AccessControlStrategy } from '@/services/accessControl/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type GettingStartedInfoService from '@/services/domain/gettingStartedInfoService'
import type { FeatureFlagsService } from '@/services/featureFlagsService'
import type UserService from '@/services/domain/user/userService'
import type TeamWorkflowService from '@/services/domain/accounts/teamWorkflowService'
import { UserRole } from '@/services/domain/user/types'
import { FeatureFlags } from '@/services/types'
import type UserSettingsService from '@/services/domain/user/userSettingsService'
import type WebWidgetsService from '@/services/domain/messages/settings/webWidgets/widgetsService'
import type PermissionsService from '@/services/permissionsService'
import type { TmRoutes } from '@/services/route/routerTypes'
import type DealsKanbanService from '@/services/domain/deals/dealsKanbanService'
import type KanbanService from '@/services/kanban/KanbanService'
import type ByocService from '@/services/domain/byoc/byocService'
import type RouterService from '@/services/route/routerService'
import { campaignsCalendarRoute } from '@/routes/user/campaigns/campaigns'
import { campaignsScheduledUpcomingRoute } from '@/routes/user/campaigns/scheduled/campaignsScheduledUpcoming'
import {
  campaignsScheduledDetailsRoute,
  campaignsScheduledEditRoute,
  campaignsScheduledRoute,
} from '@/routes/user/campaigns/scheduled/campaignsScheduled'
import { campaignsScheduledPausedRoute } from '@/routes/user/campaigns/scheduled/campaignsScheduledPaused'
import { campaignsScheduledNotSentRoute } from '@/routes/user/campaigns/scheduled/campaignsScheduledNotSent'
import { campaignsScheduledCompletedRoute } from '@/routes/user/campaigns/scheduled/campaignsScheduledCompleted'
import {
  campaignsComposeCampaignRoute,
  campaignsComposeEmailRoute,
  campaignsComposeSmsRoute,
} from '@/routes/user/campaigns/campaignsCompose'
import { campaignsSmsDetailsRoute } from '@/routes/user/campaigns/campaignsSms'
import { campaignsTemplatesAllRoute, campaignsTemplatesCategoryRoute } from '@/routes/user/campaigns/campaignsTemplates'
import { campaignsSurveysRoute } from '@/routes/user/campaigns/surveys/campaignsSurveys'
import {
  campaignsSurveysDetailsEditRoute,
  campaignsSurveysDetailsFlowRoute,
  campaignsSurveysDetailsRoute,
} from '@/routes/user/campaigns/surveys/campaignsSurveysDetails'
import type AccountSettingsSecurityService from '@/services/domain/accounts/accountSettingsSecurityService'
import { composeMessageRouteName } from '@/routes/user/compose'
import type { ComposeMessageRouteQueryParams } from '@/components/views/compose/types'

@injectable()
export class GettingStartedStrategy implements AccessControlStrategy {
  constructor(
    @inject(SERVICE_TYPES.GettingStartedInfoService)
    protected readonly gettingStartedInfoService: GettingStartedInfoService,
  ) {}

  public async resolve() {
    if (!this.gettingStartedInfoService.findInfo()) {
      await this.gettingStartedInfoService.getInfo()
    }
  }

  public isAccessAllowed(): boolean {
    const info = this.gettingStartedInfoService.findInfoOrFail()
    return info.showGettingStartedPage
  }
}

@injectable()
export class TenDlcStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.UserSettingsService) protected readonly userSettingsService: UserSettingsService) {}

  public isAccessAllowed(): boolean {
    return this.userSettingsService.currentUserSettingsOrNull()?.showTenDlcTab ?? false
  }
}

@injectable()
export class TollFreeStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.UserSettingsService) protected readonly userSettingsService: UserSettingsService) {}

  public isAccessAllowed(): boolean {
    return this.userSettingsService.currentUserSettingsOrNull()?.showTollFreeTab ?? false
  }
}

@injectable()
export class SenderIdsStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.UserSettingsService) protected readonly userSettingsService: UserSettingsService) {}

  public isAccessAllowed(): boolean {
    return this.userSettingsService.currentUserSettingsOrNull()?.showSenderIds ?? false
  }
}

@injectable()
export class SubAccountsImportFlowStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.UserService) protected readonly userService: UserService) {}

  public isAccessAllowed(): boolean {
    return this.userService.isAllowInviteSubAccount()
  }
}

@injectable()
export class UserWorkflowStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.TeamWorkflowService) protected readonly teamWorkflowService: TeamWorkflowService) {}

  public isAccessAllowed(): boolean {
    return this.teamWorkflowService.isUsersWorkflow()
  }
}

@injectable()
export class SubAccountsWorkflowStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.TeamWorkflowService) protected readonly teamWorkflowService: TeamWorkflowService) {}

  public isAccessAllowed(): boolean {
    return this.teamWorkflowService.isSubaccountsWorkflow()
  }
}

@injectable()
export class SubAccountDetailsStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.TeamWorkflowService) protected readonly teamWorkflowService: TeamWorkflowService) {}

  public isAccessAllowed(): boolean {
    return !this.teamWorkflowService.isUsersWorkflow()
  }
}

@injectable()
export class UsersWorkflowNotUserRoleStrategy implements AccessControlStrategy {
  constructor(
    @inject(SERVICE_TYPES.TeamWorkflowService) protected readonly teamWorkflowService: TeamWorkflowService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {}

  public isAccessAllowed(): boolean {
    return this.teamWorkflowService.isUsersWorkflowNotUserRole()
  }
}

@injectable()
export class UsersWorkflowSuperAdminRoleStrategy implements AccessControlStrategy {
  constructor(
    @inject(SERVICE_TYPES.TeamWorkflowService) protected readonly teamWorkflowService: TeamWorkflowService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {}

  public isAccessAllowed(): boolean {
    if (!this.teamWorkflowService.isUsersWorkflow()) {
      return true
    }
    return this.userService.currentUser().subaccountType === UserRole.SUPER_ADMIN
  }
}

@injectable()
export class TicketsStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return this.featureFlagsService.isFeatureEnabled(FeatureFlags.Tickets)
  }
}

@injectable()
export class TasksReadOnlyStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.KanbanService) protected readonly kanbanService: KanbanService) {}

  public isAccessAllowed(): boolean {
    return !this.kanbanService.isReadonly()
  }
}

@injectable()
export class DealsReadOnlyStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.DealsKanbanService) protected dealsKanbanService: DealsKanbanService) {}

  public isAccessAllowed(): boolean {
    return !this.dealsKanbanService.isReadonly()
  }
}

@injectable()
export class SuperAdminRoleStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.UserService) protected readonly userService: UserService) {}

  public isAccessAllowed(): boolean {
    return this.userService.currentUser().subaccountType === UserRole.SUPER_ADMIN
  }
}

@injectable()
export class DealsStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return this.featureFlagsService.isFeatureEnabled(FeatureFlags.Deals)
  }
}

@injectable()
export class SegmentsStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return this.featureFlagsService.isFeatureEnabled(FeatureFlags.Segments)
  }
}

// temp
@injectable()
export class WebWidgetsStrategy implements AccessControlStrategy {
  constructor(
    @inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService,
    @inject(SERVICE_TYPES.WebWidgetsService) protected readonly webWidgetService: WebWidgetsService,
  ) {}

  public isAccessAllowed(): boolean {
    return this.webWidgetService.isAllowWebWidgetForCurrentUser()
  }
}

@injectable()
export class CampaignsStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }
}

@injectable()
export class NotCampaignsCalendarStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute() {
    return {
      name: campaignsCalendarRoute.name,
    }
  }
}

@injectable()
export class NotCampaignsScheduledStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute() {
    return {
      name: campaignsScheduledRoute.name,
    }
  }
}

@injectable()
export class NotCampaignsScheduledUpcomingStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute() {
    return {
      name: campaignsScheduledUpcomingRoute.name,
    }
  }
}

@injectable()
export class NotCampaignsScheduledPausedStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute() {
    return {
      name: campaignsScheduledPausedRoute.name,
    }
  }
}

@injectable()
export class NotCampaignsScheduledNotSentStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute() {
    return {
      name: campaignsScheduledNotSentRoute.name,
    }
  }
}

@injectable()
export class NotCampaignsScheduledCompletedStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute() {
    return {
      name: campaignsScheduledCompletedRoute.name,
    }
  }
}

@injectable()
export class NotCampaignsScheduledDetailsStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute(route: RouteLocationNormalized) {
    return {
      name: campaignsScheduledDetailsRoute.name,
      params: route.params,
    }
  }
}

@injectable()
export class NotCampaignsScheduledEditStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute(route: RouteLocationNormalized) {
    return {
      name: campaignsScheduledEditRoute.name,
      params: route.params,
    }
  }
}

@injectable()
export class NotCampaignsComposeMessageStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute(route: RouteLocationNormalized) {
    return {
      name: campaignsComposeSmsRoute.name,
      query: cloneDeep(route.query),
      hash: route.hash,
    }
  }
}

@injectable()
export class NotCampaignsComposeSuccessMessageStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute(route: RouteLocationNormalized) {
    return {
      name: campaignsSmsDetailsRoute.name,
      params: route.params,
    }
  }
}

@injectable()
export class NotCampaignsComposeSuccessMessagesStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute(route: RouteLocationNormalized) {
    return {
      name: campaignsSmsDetailsRoute.name,
      params: route.params,
    }
  }
}

@injectable()
export class NotCampaignsComposeSuccessScheduledStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute(route: RouteLocationNormalized) {
    return {
      name: campaignsSmsDetailsRoute.name,
      params: route.params,
    }
  }
}

@injectable()
export class NotCampaignsComposeCampaignStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute() {
    return {
      name: campaignsComposeCampaignRoute.name,
    }
  }
}

@injectable()
export class NotCampaignsComposeEmailStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute() {
    return {
      name: campaignsComposeEmailRoute.name,
    }
  }
}

@injectable()
export class NotCampaignsSmsSurveysStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute() {
    return {
      name: campaignsSurveysRoute.name,
    }
  }
}

@injectable()
export class NotCampaignsSmsSurveysDetailsStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute(route: RouteLocationNormalized) {
    return {
      name: campaignsSurveysDetailsRoute.name,
      params: route.params,
    }
  }
}

@injectable()
export class NotCampaignsSmsSurveysViewFlowStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute(route: RouteLocationNormalized) {
    return {
      name: campaignsSurveysDetailsFlowRoute.name,
      params: route.params,
    }
  }
}

@injectable()
export class NotCampaignsSmsSurveysEditStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute(route: RouteLocationNormalized) {
    return {
      name: campaignsSurveysDetailsEditRoute.name,
      params: route.params,
    }
  }
}

@injectable()
export class InstagramStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return this.featureFlagsService.isFeatureEnabled(FeatureFlags.Instagram)
  }
}

@injectable()
export class CampaignsEmailStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return this.featureFlagsService.isFeatureEnabled(FeatureFlags.CampaignsEmail)
  }
}

@injectable()
export class MonthlyPlansStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.PermissionsService) private readonly permissionsService: PermissionsService) {}

  public async resolve() {
    await this.permissionsService.getPermissionsIfNotLoaded()
  }

  public isAccessAllowed() {
    return this.permissionsService.isActivePermission('autorechargeSettings')
  }

  public getRedirectRoute(): TmRoutes {
    return {
      name: 'payment',
    }
  }
}

@injectable()
export class ByocStrategy implements AccessControlStrategy {
  constructor(
    @inject(SERVICE_TYPES.ByocService) protected byocService: ByocService,
    @inject(SERVICE_TYPES.PermissionsService) private readonly permissionsService: PermissionsService,
  ) {}

  public async resolve() {
    const promisesList: Promise<unknown>[] = [this.permissionsService.getPermissionsIfNotLoaded()]
    const hasSettings = this.byocService.hasSettings()
    if (!hasSettings) {
      promisesList.push(this.byocService.fetchSettings())
    }
    await Promise.all(promisesList)
  }

  public isAccessAllowed() {
    return this.byocService.isAvailable()
  }
}

@injectable()
export class PaymentCongratsStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.RouterService) protected routerService: RouterService) {}

  public isAccessAllowed() {
    const allowedRoutes: TmRoutes['name'][] = ['payment.congratsRedirect']

    const previousRoute = this.routerService.getPreviousRoute()
    // page should not be available by the direct link
    return !!allowedRoutes.find((t) => previousRoute?.name === t)
  }
}

@injectable()
export class NotCampaignsTemplatesAllStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute() {
    return {
      name: campaignsTemplatesAllRoute.name,
    }
  }
}

@injectable()
export class NotCampaignsTemplatesCategoryStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return !this.featureFlagsService.isFeatureEnabled(FeatureFlags.Campaigns)
  }

  public getRedirectRoute(route: RouteLocationNormalized) {
    return {
      name: campaignsTemplatesCategoryRoute.name,
      params: route.params,
    }
  }
}

@injectable()
export class TwoFactorSetupStrategy implements AccessControlStrategy {
  constructor(
    @inject(SERVICE_TYPES.AccountSettingsSecurityService)
    protected accountSettingsSecurityService: AccountSettingsSecurityService,
  ) {}

  public async resolve() {
    if (this.accountSettingsSecurityService.getLoadedSettings()) {
      return
    }
    await this.accountSettingsSecurityService.getSettings()
  }

  public isAccessAllowed() {
    return !this.accountSettingsSecurityService.getLoadedSettingsOrFail().hasPhoneOrTotpTwoFa
  }

  public getRedirectRoute(): TmRoutes {
    return {
      name: 'user.accounts.security',
    }
  }
}

@injectable()
export class PaymentStrategy implements AccessControlStrategy {
  constructor(
    @inject(SERVICE_TYPES.PermissionsService) private readonly permissionsService: PermissionsService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {}

  public async resolve() {
    await this.permissionsService.getPermissionsIfNotLoaded()
  }

  public isAccessAllowed() {
    return !this.permissionsService.isActivePermission('autorechargeSettings')
  }

  public getRedirectRoute() {
    return {
      name: composeMessageRouteName,
      query: {
        openCreditModal: 'true',
      } satisfies ComposeMessageRouteQueryParams,
    }
  }
}

@injectable()
export class PointAiManagementStrategy implements AccessControlStrategy {
  constructor(
    @inject(SERVICE_TYPES.UsersWorkflowNotUserRoleStrategy)
    private readonly usersWorkflowNotUserRoleStrategy: UsersWorkflowNotUserRoleStrategy,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {}

  public isAccessAllowed() {
    return this.usersWorkflowNotUserRoleStrategy.isAccessAllowed()
  }
}

@injectable()
export class UnsubscribedEmailStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return this.featureFlagsService.isFeatureEnabled(FeatureFlags.EmailUnsubscribers)
  }
}

@injectable()
export class HistoryOutboundEmailsStrategy implements AccessControlStrategy {
  constructor(@inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService) {}

  public isAccessAllowed(): boolean {
    return this.featureFlagsService.isFeatureEnabled(FeatureFlags.HistoryOutboundEmails)
  }
}

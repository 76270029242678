import { AppModule } from '@/config/types'
import type { TmNamedRouteRecord } from '@/services/route/types'

export const reportingOverviewRoute: TmNamedRouteRecord = {
  name: 'user.reporting.overview',
  path: 'overview',
  meta: {
    tags: ['REPORTING_BAR_LIST'],
    tabTitle: 'pageTabTitle.user.reporting.overview',
    permission: 'reportingOverview',
  },
  component: () => import('@/components/views/reporting/ReportingOverview.vue'),
}

export const reportingMessagesRoute: TmNamedRouteRecord = {
  name: 'user.reporting.messages',
  path: 'messages',
  meta: {
    tags: ['REPORTING_BAR_LIST'],
    tabTitle: 'pageTabTitle.user.reporting.messages',
    permission: 'reportingMessages',
  },
  component: () => import('@/components/views/reporting/messages/ReportingMessages.vue'),
}

export const reportingCallsRoute: TmNamedRouteRecord = {
  name: 'user.reporting.calls',
  path: 'calls',
  meta: {
    tags: ['REPORTING_BAR_LIST'],
    tabTitle: 'pageTabTitle.user.reporting.calls',
    permission: 'reportingCalls',
  },
  component: () => import('@/components/views/reporting/calls/ReportingCalls.vue'),
}

export const reportingAuditLogsRoute: TmNamedRouteRecord = {
  name: 'user.reporting.auditLogs',
  path: 'audit-logs',
  meta: {
    tags: ['REPORTING_BAR_LIST'],
    tabTitle: 'pageTabTitle.user.reporting.auditLogs',
    permission: 'reportingAuditLogs',
    modules: [AppModule.AuditLogs],
  },
  component: () => import('@/components/views/reporting/auditLogs/ReportingAuditLogs.vue'),
}

export const reportingSubAccountsRoute: TmNamedRouteRecord = {
  name: 'user.reporting.subAccounts',
  path: 'sub-accounts',
  meta: {
    tags: ['REPORTING_BAR_LIST'],
    tabTitle: 'pageTabTitle.user.reporting.subAccounts',
    permission: 'reportingSubAccounts',
    accessControlGroups: ['SubAccountsWorkflowStrategy'],
  },
  component: () => import('@/components/views/reporting/subAccounts/ReportingSubAccounts.vue'),
}

export const reportingUsersRoute: TmNamedRouteRecord = {
  name: 'user.reporting.users',
  path: 'users',
  meta: {
    tags: ['REPORTING_BAR_LIST'],
    tabTitle: 'pageTabTitle.user.reporting.users',
    permission: 'reportingUsers',
    accessControlGroups: ['UserWorkflowStrategy'],
  },
  component: () => import('@/components/views/reporting/users/ReportingUsers.vue'),
}

export const reportingNumbersRoute: TmNamedRouteRecord = {
  name: 'user.reporting.numbers',
  path: 'numbers',
  meta: {
    tags: ['REPORTING_BAR_LIST'],
    tabTitle: 'pageTabTitle.user.reporting.numbers',
    permission: 'reportingNumbers',
  },
  component: () => import('@/components/views/reporting/Numbers.vue'),
}

export const reportingRoute: TmNamedRouteRecord = {
  name: 'user.reporting',
  path: 'reporting',
  meta: {
    tags: ['USER_TOP_MENU'],
    menu: () => import('@/components/domain/reporting/ReportingMenu.vue'),
    modules: [AppModule.Reporting],
  },
  component: () => import('@/components/views/reporting/Reporting.vue'),
  children: [
    reportingOverviewRoute,
    reportingMessagesRoute,
    reportingCallsRoute,
    reportingAuditLogsRoute,
    reportingSubAccountsRoute,
    reportingUsersRoute,
    reportingNumbersRoute,
  ],
  redirect: { name: reportingOverviewRoute.name },
}

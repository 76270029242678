import { random, range } from 'lodash-es'

const memoizedRandomsRange = () => {
  const cache = new Map<`${number}_${number}_${number}`, number[]>()

  return (count: number, from: number, to: number) => {
    const key = `${count}_${from}_${to}` as const
    if (cache.has(key)) {
      return cache.get(key)!
    }

    const result = range(count).map(() => random(from, to))

    cache.set(key, result)
    return result
  }
}

export const getRandomsRange = memoizedRandomsRange()

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SIGNUP_BY_INVITE_SERVICES {
  SignUpByInviteFormService,
  SignUpByInviteTokenCheckResolver,
  SignUpByInviteSSOFormService,
}

export type SignupByInviteServices = keyof typeof SIGNUP_BY_INVITE_SERVICES

export const SIGNUP_BY_INVITE_SERVICE_TYPES: Record<SignupByInviteServices, symbol> =
  makeServicesTypes<SignupByInviteServices>(enumKeysToArray<SignupByInviteServices>(SIGNUP_BY_INVITE_SERVICES))

import type { TmWrappers } from '@/wrappers'
import type { PhoneWithCountryCode } from '@/services/forms/types'
import type { UserRefusalSurveyReason } from '@/services/domain/user/types'

export const accountDetailsModalId: TmWrappers = 'accountDetails'

export const switchSubAccountModal: TmWrappers = 'switchSubAccountModal'

export const resendInvitationSubAccountModalId: TmWrappers = 'resendInvitationSubAccountModal'

export const auditLogsEventSummaryModalId: TmWrappers = 'auditLogsEventSummaryModal'

export const closeAccountModalId: TmWrappers = 'closeAccountModal'

export const closeSubAccountModalId: TmWrappers = 'closeSubAccountModal'

export interface AccountNotificationsFormScheme {
  balanceAlerts: AccountNotificationsBalanceFormScheme
  incomingAlerts: AccountNotificationsIncomingFormScheme
  otherAlerts: AccountNotificationsOtherFormScheme
  tasksAlerts: AccountNotificationsTasksFormScheme
  ticketsAlerts: AccountNotificationsTicketsFormScheme
}

export interface AccountNotificationsBalanceFormScheme {
  lowBalanceAlert: boolean
  lowBalanceValue: string
  alertPhoneNumber: PhoneWithCountryCode
  accountEmailAddress: string
  alertEmailAddresses: string[]
}
export interface AccountNotificationsIncomingFormScheme {
  incomingMessageAlert: boolean
  incomingWhatsappBusinessMessageAlert: boolean
  incomingFacebookMessageAlert: boolean
  incomingInstagramMessageAlert: boolean
  includingChatHistory: boolean
  inHtmlFormat: boolean
  incomingCallAlert: boolean
  newLiveChatAlert: boolean
  missedLiveChatAlert: boolean
  alertEmailAddresses: string[]
  chatAssignedAlert: boolean
}
export interface AccountNotificationsOtherFormScheme {
  recipientUnsubscribedAlert: boolean
  dailyReport: boolean
  monthlyReport: boolean
  scheduledMessageNotSentAlert: boolean
  incomingCallMissedAlert: boolean
  importSessionCompletedAlert: boolean
  onboardingEmails: boolean
  inboundRenewalAlert: boolean
  promotionEmails: boolean
}

export interface AccountNotificationsTasksFormScheme {
  newCommentAddedAlert: boolean
  taskAssignedToMeAlert: boolean
}

export interface AccountNotificationsTicketsFormScheme {
  ticketAssignedToMeAlert: boolean
  newTicketReplyReceivedAlert: boolean
}

export interface AccountSecurityTwoFaEditIpAddressesFormScheme {
  ipAddresses: string[]
}

export interface AccountSecurityTwoFaAddQuestionFormScheme {
  question: string
  answer: string
}

export interface AccountSecurityTwoFaEditQuestionFormScheme {
  password: string
  newQuestion: string
  newAnswer: string
}

export type MessageRetentionPeriodValue = '' | '6 months' | '12 months' | '24 months' | '36 months'
export interface MessageRetentionPeriod {
  dataMessagesLifetime: MessageRetentionPeriodValue
}
export interface AccountSecurityPasswordChangeFormScheme {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

export interface AccountSettingsPhoneVerificationFormScheme {
  confirmationCode: string
}

export type MyDataExportType = 'sent' | 'received' | 'contacts' | 'people' | 'e2s'

export type MyDataExportConfig = {
  type: MyDataExportType
  userId: string
}

export const subAccountDetailsModalId: TmWrappers = 'subAccountDetails'

export const accountSsoDetailsModalId: TmWrappers = 'accountSsoDetailsModal'

export enum AccountSsoSettingsForceSsoLogin {
  sso,
  ssoAndPassword,
}

export interface AccountSsoDetailsFormSchema {
  entityId: string
  ssoService: string
  sloService: string
  x509Cert: string
  forceSsoLogin: AccountSsoSettingsForceSsoLogin
}

export interface AccountSsoSettingsUpdateSchema {
  entityId: string
  ssoService: string
  sloService: string
  x509Cert: string
  forceSsoLogin: AccountSsoSettingsForceSsoLogin
}

export const maxQuantityLoginHistoryItems = 5

export interface BillingAutoRechargeSettingsEditFormSchema {
  status: 'active' | 'inactive'
  ifBalanceBallsBelow: string
  recharge: string
}

export enum TeamPlanType {
  monthlyPlan = 'monthlyPlan',
  annualPlan = 'annualPlan',
}

export interface TeamPlanFormScheme {
  teamPlanType: TeamPlanType
  userSeats: number
}

export enum TeamSubscriptionPeriod {
  monthly = 'monthly',
  annual = 'annual',
}

export enum TeamSubscriptionType {
  team = 'team',
}

export interface CentrifugeSubscriptionToken {
  channel: string
  subscriptionToken: string
}

export interface CentrifugeTokensResponse {
  connectionToken: string
  subscriptionTokens: CentrifugeSubscriptionToken[]
}

export enum UserSubscriptionName {
  team = 'team',
}

export enum UserSubscriptionStatus {
  active = 'active',
  process = 'process',
  failed = 'failed',
}

export interface IUserSubscriptionMetadata {
  availableSeats: number
  period: TeamSubscriptionPeriod
  renewalDate: string
  lastPaymentDate: string
  totalCost: number
  usedSeats: number
}

export interface FetchStatementsSummaryOptions {
  from?: string
  to?: string
}

export interface CloseAccountFormScheme {
  contacts: boolean
  messages: boolean
  reason: UserRefusalSurveyReason | ''
  description: string
}

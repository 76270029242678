import type { CustomErrorRule } from '@/core/error/transport/types'
import { TmApiDealsStageNotFoundError } from '@/core/error/transport/tmApiDealsStageNotFoundError'

export const dealsStageNotFoundErrorRule: CustomErrorRule = {
  klass: TmApiDealsStageNotFoundError,
  predicateFn: (error) => {
    return (
      error.response.data.message.includes('DealStage object not found') ||
      error.response.data.message.includes('Deal stage not found') ||
      error.response.data.errorCode === 'deals.stage_not_found'
    )
  },
}

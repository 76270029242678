import type { RouteRecordRaw } from 'vue-router'
import DistributionDetails from '@/data/models/domain/services/distribution/DistributionDetails'
import type { NotFoundResolverParams } from '@/services/resolvers/types'
import { ServicesRouteName } from '@/routes/types'
import { AppModule } from '@/config/types'

export const getEmailToSmsDetailsRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.services.emailToSms.createDistribution',
    path: 'email-to-sms/distribution-lists/new',
    component: () => import('@/components/views/services/emailToSms/ServiceDistribution.vue'),
    meta: {
      parent: ServicesRouteName,
      tabTitle: 'pageTabTitle.user.services.distributionListsCreate',
      permission: 'distributionLists',
      modules: [AppModule.RecentRecipient, AppModule.Contacts, AppModule.EmailToSms, AppModule.ContactCounter],
    },
  },
  {
    name: 'user.services.emailToSms.editDistribution',
    path: 'email-to-sms/distribution-lists/:distributionId',
    props: true,
    component: () => import('@/components/views/services/emailToSms/ServiceDistribution.vue'),
    meta: {
      parent: ServicesRouteName,
      tabTitle: 'pageTabTitle.user.services.distributionListsEdit',
      permission: 'distributionLists',
      resolvers: [
        {
          service: 'RedirectableSingleModelResolverService',
          params: {
            source: 'router',
            requestParam: 'distributionId',
            model: DistributionDetails,
            redirectToIfNotFound: { name: 'user.services.emailToSms.distributionLists' },
          } as NotFoundResolverParams,
        },
      ],
      modules: [AppModule.RecentRecipient, AppModule.Contacts, AppModule.EmailToSms, AppModule.ContactCounter],
    },
  },
]

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

export enum CHANNEL_PRESENCE_SERVICES {
  ChannelPresenceRepository,
  ChannelPresenceService,
  ChannelPresenceResolver,
}

export type ChannelPresenceServices = keyof typeof CHANNEL_PRESENCE_SERVICES

export const CHANNEL_PRESENCE_SERVICE_TYPES: Record<ChannelPresenceServices, symbol> =
  makeServicesTypes<ChannelPresenceServices>(enumKeysToArray<ChannelPresenceServices>(CHANNEL_PRESENCE_SERVICES))

import { injectable } from 'inversify'
import { RedirectableSingleModelResolverService } from '@/services/resolvers/redirectableSingleModelResolverService'
import type { NotFoundResolverParams } from '@/services/resolvers/types'

@injectable()
export class NonBlockedRedirectableSingleModelResolverService extends RedirectableSingleModelResolverService {
  public override async resolve(params: NotFoundResolverParams): Promise<void> {
    super.resolve(params)
  }
}

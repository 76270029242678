<template>
  <div class="flex flex-column gap-6">
    <div
      v-for="(size, index) in headerSizes"
      :key="index"
    >
      <tm-skeleton
        class="ten-dlc-select-campaign-details-modal-preloader__title"
        :width="size"
        height="18px"
      />
      <tm-skeleton-details-table />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import TmSkeletonDetailsTable from '@/components/shared/skeletons/TmSkeletonDetailsTable.vue'

interface Item {
  labelSize: string
}

export default defineComponent({
  components: { TmSkeletonDetailsTable, TmSkeleton },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  setup() {
    const items: Item[] = [
      {
        labelSize: '20%',
      },
      {
        labelSize: '30%',
      },
      {
        labelSize: '50%',
      },
      {
        labelSize: '60%',
      },
      {
        labelSize: '60%',
      },
    ]

    const headerSizes = ['30%', '40%', '50%']

    return {
      items,
      headerSizes,
    }
  },
})
</script>
<style lang="scss" scoped>
.ten-dlc-select-campaign-details-modal-preloader {
  &__title {
    margin-bottom: 22px;
  }
}
</style>

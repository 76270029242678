import { isEmpty, intersection, merge, isEqual } from 'lodash-es'
import type { HistoryState } from 'vue-router'
import { TmApiServerError } from '@/core/error/transport/tmApiServerError'
import {
  FILTERS_URL_QUERY_PARAM,
  COLUMNS_URL_QUERY_PARAM,
  GROUPING_URL_QUERY_PARAM,
  PAGING_URL_QUERY_PARAM,
  SEARCH_URL_QUERY_PARAM,
  SORTS_URL_QUERY_PARAM,
  UI_STATE_URL_QUERY_PARAM,
} from '@/core/tables/types'
import type {
  BaseFilterInterface,
  BaseTableIsDefaultStateOptions,
  BaseTableSettings,
  ColumnsServiceInterface,
  GrouperInterface,
  RowKey,
  SortDirection,
  SorterInterface,
  TableKeyBuilderInterface,
  TablePageMoveDirection,
  CustomFiltersDefaultState,
  Column,
  RefreshRowsParams,
  SearcherTableInterface,
  SearchUrlParams,
} from '@/core/tables/types'
import type {
  PaginationInterface,
  PaginationOffsetResponse,
  PaginationUrlParams,
  PaginationData,
  PaginationNextPageData,
  PaginationParams,
} from '@/services/tables/pagination/types'
import type { ModelRaw } from '@/types'
import type NotificationService from '@/services/notificationService'
import type { BaseTableInterface, TableRowExtended } from '@/core/tables/baseTableInterface'
import type ExportService from '@/services/exportService'
import type { ExportAvailableExtensions, Gridable, ExportBody } from '@/services/types'
import { objectEmptyRecursive } from '@/utils/object/objectEmptyRecursive'
import type {
  CounterInterface,
  PaginationUrlColumnType,
  PaginationUrlFilterType,
  PaginationUrlParametersGroupingType,
  PaginationUrlParametersSortType,
  PaginationUrlType,
  TableFacetSettings,
} from '@/services/tables/types'
import { rowsReloadable } from '@/core/tables/tableDecorators'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type LoggerService from '@/services/loggerService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type BaseModel from '@/data/models/BaseModel'
import Table from '@/data/models/tables/Table'
import AllSelectedTables from '@/data/models/tables/AllSelectedTables'
import type TableRepository from '@/data/repositories/table/tableRepository'
import type SelectedRowRepository from '@/data/repositories/table/selectedRowRepository'
import SelectedRow from '@/data/models/tables/SelectedRow'
import type OrmApiRepository from '@/data/repositories/ormApiRepository'
import type AllSelectedTablesRepository from '@/data/repositories/table/allSelectedTablesRepository'
import type { TableExpandable, Historyable, QueryChangedPayload } from '@/services/route/types'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import { TmTableError } from '@/core/error/table/tmTableError'
import type BulkManager from '@/services/bulk/bulkManager'
import type { BulkWrapperParams, BulkQuery } from '@/services/wrappers/types'
import type FilterServiceManagerInterface from '@/services/tables/managers/filterServiceManagerInterface'
import TmTestError from '@/core/error/tmTestError'
import type DomainManagerService from '@/services/domain/domainManagerService'
import type DomainBaseService from '@/services/domain/domainBaseService'
import { TmTableColumnError } from '@/core/error/table/tmTableColumnError'
import { TmTableFilterError } from '@/core/error/table/tmTableFilterError'
import type TablePaginatorManagerInterface from '@/services/tables/managers/tablePaginatorManagerInterface'
import type TableSorterManagerInterface from '@/services/tables/managers/tableSorterManagerInterface'
import type TableGrouperManagerInterface from '@/services/tables/managers/tableGrouperManagerInterface'
import type TableSearcherManagerInterface from '@/services/tables/managers/tableSearcherManagerInterface'
import type TableColumnManagerInterface from '@/services/tables/managers/tableColumnManagerInterface'
import type { InternalEmitterPayload, ExportResultResponse, ModelEmitterPayload } from '@/services/transport/types'
import { isRecordUnknown } from '@/utils/typeGuards'
import type { RegisteredServices } from '@/core/container/types'
import type {
  WrapperTableParams,
  WrapperTableParamsToSave,
  WrapperTableServiceInterface,
} from '@/services/wrappers/table/types'
import { isModelEventType, ModelEventType } from '@/services/transport/types'
import type TableRecoveryDataRepository from '@/data/repositories/table/tableRecoveryDataRepository'
import TableRecoveryData from '@/data/models/tables/TableRecoveryData'
import type PageMapPreload from '@/data/models/PageMapPreload'
import type Pagination from '@/data/models/tables/Pagination'
import type { TableRecoveryServiceInterface } from '@/services/tables/recovery/tableRecoveryServiceInterface'
import type { BulkDeleteParams } from '@/services/bulk/types'
import type { FilterCompareService } from '@/services/tables/filters/filterCompareService'
import type { FilterInterface } from '@/services/forms/types'

export default class BaseTable implements BaseTableInterface {
  protected _loggerService: LoggerService

  protected readonly _gridService?: Gridable<BaseModel>

  protected readonly _tableModelId: string

  protected readonly _tableWrapperId: string

  private filterServiceManager: FilterServiceManagerInterface

  private sorterManager: TableSorterManagerInterface

  private grouperManager: TableGrouperManagerInterface

  private paginatorManager: TablePaginatorManagerInterface

  private searcherManager: TableSearcherManagerInterface

  private columnManager: TableColumnManagerInterface

  private _em: EntityManagerService

  private _domainManager: DomainManagerService

  private _bulkManager: BulkManager

  private _exportService: ExportService

  private _subscriptionService: ModelSubscriptionService

  private _notificationService: NotificationService

  private _historyService: Historyable

  private _recoveryService: TableRecoveryServiceInterface

  private _subscriptions: string[]

  private _subscriptionKeys: string[] = []

  private _keyBuilder: TableKeyBuilderInterface

  private _preloaderManager: PreloaderManager

  private _filterCompareService: FilterCompareService

  private _entity: typeof BaseModel

  private _expandService: TableExpandable

  private tableWrapperService: WrapperTableServiceInterface

  private baseFilterService: BaseFilterInterface

  private counterService?: CounterInterface

  private facetSettings?: TableFacetSettings

  private tableWrapperParamsToSave?: WrapperTableParamsToSave

  private customFiltersDefaultState?: CustomFiltersDefaultState

  private canBeRestored = true

  private fastInsert = false

  private noSelectAll = false

  private allowSelectAllWithNonDefaultFilters = false

  private shouldResetOnCreateOrDelete = true

  constructor(settings: BaseTableSettings) {
    this._tableModelId = settings.tableModelId
    this._gridService = settings.gridService
    this._tableWrapperId = settings.tableWrapperId
  }

  private makeColumns() {
    return this.getColumns().makeColumns(
      this.getTableModelId(),
      this.getQueryByKey<PaginationUrlColumnType>(COLUMNS_URL_QUERY_PARAM, undefined),
      (columns) => this.saveColumns(columns, true),
    )
  }

  private recoveryColumns() {
    return this.getColumns().recoveryColumns(
      this.getTableModelId(),
      this.getQueryByKey<PaginationUrlColumnType>(COLUMNS_URL_QUERY_PARAM, undefined),
      (columns) => this.saveColumns(columns, true),
    )
  }

  public initTableParams() {
    this.getRepository(Table).insertOrUpdateRaw([{ id: this.getTableModelId() }])
    this.initFilter()
    this.getPagination().init(this.getQueryByKey<PaginationUrlParams>(PAGING_URL_QUERY_PARAM))
    this.getSorters().init(this.getQueryByKey<PaginationUrlParametersSortType>(SORTS_URL_QUERY_PARAM))
    this.getGrouping().init(this.getQueryByKey<PaginationUrlParametersGroupingType>(GROUPING_URL_QUERY_PARAM))
    this.getSearch().init(this.getQueryByKey<SearchUrlParams>(SEARCH_URL_QUERY_PARAM))
  }

  public async initColumns() {
    const recoveryData = this.getRecoveryData()

    try {
      await this.getColumns().init()

      if (recoveryData?.canBeRestored) {
        await this.recoveryColumns()
      } else {
        await this.makeColumns()
      }
    } catch (e) {
      if (e instanceof Error) {
        throw new TmTableColumnError(e.toString())
      }
    }
  }

  public recoveryTable(): boolean {
    const recoveryData = this.getRecoveryData()

    if (!recoveryData?.canBeRestored || !recoveryData.items?.length) {
      return false
    }

    const entities = this.getRepository(this._entity).all()
    const entitiesMap = Object.fromEntries(entities.map((entity) => [entity.id, entity]))

    const paginationFromHistory = this.getPaginationFromHistory()

    if (paginationFromHistory) {
      const items = this.getRecoveredItems(recoveryData.items, entitiesMap)

      if (!items) {
        return false
      }

      this.getPagination().setData(paginationFromHistory)
      this.selectedRowRepository.unselectAll(this.getTableModelId())
      this.updateTablePreloaders(paginationFromHistory.pageKey, recoveryData.items)
    } else {
      const currentPerPage = this.getPagination().getPerPage()
      const initialPerPage = recoveryData.initialPagination.perPage

      if (currentPerPage !== initialPerPage) {
        return false
      }

      const items = this.getRecoveredItems(recoveryData.initialItems, entitiesMap)

      if (!items) {
        return false
      }

      this.getPagination().setData(recoveryData.initialPagination)
      this.selectedRowRepository.unselectAll(this.getTableModelId())
      this.updateTablePreloaders(this.getPageKey(), recoveryData.initialItems)
    }

    return true
  }

  public async load() {
    this.log('Starting table load...')

    if (!this.tableRepo.findEntityByIdOrNull(this.getTableModelId())) {
      this.tableRepo.reset(this.getTableModelId())
    }

    this.initTableParams()
    await this.initColumns()

    this.setIsInit(this.recoveryTable())

    this.allSelectedTablesRepository.insertOrUpdate([{ id: this.getTableModelId(), isAllSelected: false }])
    await this.initialRefreshRows()
    this.makeSubscriptions()
    this.setIsInit(true)

    await this.changeActive(true)
    this.log('Table load completed')
  }

  public isInit() {
    return this.tableRepo.getTableInitFlag(this.getTableModelId()) ?? false
  }

  public setIsInit(value: boolean) {
    return this.tableRepo.setTableInitFlag(this.getTableModelId(), value)
  }

  public isExportInProgress(): boolean {
    return this.tableRepo.getIsExportInProgressFlag(this.getTableModelId())
  }

  public nextPage() {
    return this.movePage('next')
  }

  public prevPage() {
    return this.movePage('prev')
  }

  public getLastRowId() {
    const allIds = this.getAllPagesRowsIds()

    if (allIds.length === 0) throw new TmTableError("Can't get last raw of empty table")

    return allIds[allIds.length - 1]
  }

  public moveToFirstPage() {
    this.getPagination().moveToFirstPage()
    return this.setParamsByTableData({ [PAGING_URL_QUERY_PARAM]: this.getPagination().toQuery() })
  }

  public movePage(direction: TablePageMoveDirection) {
    if (direction === 'prev') {
      this.getPagination().moveToPrevPage()
    } else {
      const data: PaginationNextPageData = {
        lastId: this.getPagination().getData().nextId,
      }
      const counter = this.hasCounterService()
        ? this.getCounterService().getCount(this.getTableModelId() as RegisteredServices)
        : null
      if (counter) {
        data.counterServiceData = counter
      }
      this.getPagination().moveToNextPage(data)
    }
    return this.setParamsByTableData({ [PAGING_URL_QUERY_PARAM]: this.getPagination().toQuery() })
  }

  public changePerPage(perPage: number) {
    const firstPage = this.getPagination().getFirstPage()

    return this.setParamsByTableData({
      [PAGING_URL_QUERY_PARAM]: {
        ...firstPage.requestParams,
        perPage,
      },
    })
  }

  public resetFilters(shouldReplaceState = false) {
    this.getPagination().moveToFirstPage()
    return this.setParamsByTableData(
      {
        [FILTERS_URL_QUERY_PARAM]: [],
        [PAGING_URL_QUERY_PARAM]: this.getPagination().toQuery(),
      },
      shouldReplaceState,
    )
  }

  public async removeFilter(fsKey: string) {
    this.filterServiceManager.getServiceForTable(this.getTableModelId(), fsKey).reset([])
    return this.onApplyFilter()
  }

  public async onDisableFilter(fsKey: string, filterName: string) {
    let shouldApply = false

    const filterService = this.getFiltersRecord()[fsKey]
    if (!filterService) {
      throw new TmTableFilterError(`There's no "${fsKey}" filter service inside the "${this.getTableModelId()}" table`)
    }

    if (filterService.isFilterChanged(filterName)) {
      filterService.resetField(filterName)
      shouldApply = true
    }

    if (shouldApply) {
      return this.onApplyFilter()
    }

    return undefined
  }

  protected onApplyFilterQueryParamsPreparer(filterQuery: PaginationUrlFilterType): Record<string, unknown> {
    return {
      [FILTERS_URL_QUERY_PARAM]: !objectEmptyRecursive(filterQuery) ? filterQuery : [],
      [PAGING_URL_QUERY_PARAM]: this.getPagination().toQuery(),
    }
  }

  public async onApplyFilter(shouldReplaceState = false) {
    const newFilterQuery = this.getFiltersQuery(true)
    const currentFilterQuery = this.getQueryByKey<PaginationUrlFilterType>(FILTERS_URL_QUERY_PARAM, [])

    if (isEqual(currentFilterQuery, newFilterQuery)) {
      return
    }

    this.getPagination().moveToFirstPage()

    const transformedFilterQuery = this.onApplyFilterQueryParamsPreparer(newFilterQuery)
    await this.setParamsByTableData(transformedFilterQuery, shouldReplaceState)
    this.resetPreloaders()
    this.unselectAllRows()
    if (!shouldReplaceState) {
      this.tableRepo.setCanBeRestored(this.getTableModelId(), false)
    }
  }

  public async applySort(name?: string, direction?: SortDirection, shouldReplaceState = false) {
    if (!name) {
      this.getSorters().reset()
    } else if (direction) {
      this.getSorters().applySort(name, direction)
    } else {
      this.getSorters().applySort(name)
    }
    this.getPagination().moveToFirstPage()
    this.resetPreloaders()
    return this.setParamsByTableData(
      {
        [SORTS_URL_QUERY_PARAM]: this.getSortsUrlQuery(),
        [PAGING_URL_QUERY_PARAM]: this.getPagination().toQuery(),
      },
      shouldReplaceState,
    )
  }

  public async applyGrouping(name: string, direction?: SortDirection) {
    this.getPagination().moveToFirstPage()
    if (!name) {
      this.getGrouping().reset()
      await this.cleanQuery([GROUPING_URL_QUERY_PARAM], true)
      return this.setParamsByTableData({
        [GROUPING_URL_QUERY_PARAM]: this.getGroupingUrlQuery(),
        [PAGING_URL_QUERY_PARAM]: this.getPagination().toQuery(),
      })
    }
    if (direction) {
      this.getGrouping().applyGrouping(name, direction)
    } else {
      this.getGrouping().applyGrouping(name)
    }
    this.getPagination().moveToFirstPage()
    return this.setParamsByTableData({
      [GROUPING_URL_QUERY_PARAM]: this.getGroupingUrlQuery(),
      [PAGING_URL_QUERY_PARAM]: this.getPagination().toQuery(),
    })
  }

  public search(searchQuery: string) {
    if (!this.isChangedSearch(searchQuery.trim())) {
      return
    }

    this.resetPreloaders()
    this.unselectAllRows()
    this.getSearch().apply(searchQuery)
    this.getPagination().moveToFirstPage()
    this.setParamsByTableData({
      [SEARCH_URL_QUERY_PARAM]: this.getSearchUrlQuery(),
      [PAGING_URL_QUERY_PARAM]: this.getPagination().toQuery(),
    })
  }

  public resetSearch() {
    const searchQuery = this.getSearch().getSearchQuery()
    if (searchQuery) {
      this.search('')
    }
  }

  public isChangedSearch(inputValue: string): boolean {
    const currentSearchQuery = this.getSearch().getSearchQuery()

    return inputValue !== currentSearchQuery
  }

  public exists(pageKey: string): boolean {
    return this.getPreloaderManager().getTablePreloader(this.getEntity()).isPreloaded(this.getPageMapId(pageKey))
  }

  public isFailed(): boolean {
    return this.getPreloaderManager()
      .getTablePreloader(this.getEntity())
      .isFailed(this.getPageMapId(this.getPagination().getCurrentPage().pageKey), this.getEntity())
  }

  protected makeSubscriptions() {
    this.log('Make subscription')
    for (const sub of this._subscriptions) {
      const subKey = this._subscriptionService.subscribe(sub, (event) => {
        this.subscriptionsCallback(event)
      })
      this._subscriptionKeys.push(subKey)
    }
  }

  protected subscriptionsCallback(event: InternalEmitterPayload) {
    this.log('Refresh on subscription')
    if (isModelEventType(event)) {
      this.updateTablePreloadersOnEvent(event)
    }
    const dataLength = event.eventType !== ModelEventType.RELOAD ? this.getCurrentPageRowsIds().length : 0

    this.resetPaginationIfDataEmpty(dataLength)
    this.refreshRows({
      isSilent: !!dataLength,
      isDisabled: true,
      isReset: this.getShouldResetOnCreateOrDelete(event),
    })
  }

  public isSelectedRow(id: string) {
    return this.getSelectedRowsIds().includes(id)
  }

  public isRowSelected(id: string) {
    return this.selectedRowRepository.isRowSelected(this.getTableModelId(), id)
  }

  public getLastSelectedRow() {
    const tableModelId = this.getTableModelId()
    return this.tableRepo.getLastSelectedRow(tableModelId)
  }

  public setLastSelectedRow(id: string) {
    const tableModelId = this.getTableModelId()
    this.tableRepo.setLastSelectedRow(tableModelId, id)
  }

  public isAllSelected() {
    const isAllSelectedModel = this.getAllSelectedModel()
    return isAllSelectedModel.isAllSelected
  }

  public isAllAvailableSelected(): boolean {
    return this.selectedCount() === this.getTotalCount()
  }

  private setIsAllSelected(selected: boolean) {
    const isAllSelectedModel = this.getAllSelectedModel()
    isAllSelectedModel.isAllSelected = selected

    this.allSelectedTablesRepository.update([
      {
        id: isAllSelectedModel.id,
        isAllSelected: selected,
      },
    ])
  }

  public isNoneSelected(): boolean {
    return this.selectedRowRepository.getSelectedRows(this.getTableModelId()).size === 0
  }

  public selectedCount(): number {
    if (this.isAllSelected()) {
      return this.getTotalCount()
    }
    return this.getSelectedRowsIds().length
  }

  public getSelectedRowsIds(): string[] {
    const selectedRows = this.selectedRowRepository.getSelectedRows(this.getTableModelId())
    return [...selectedRows]
  }

  public getSelectedRowsInCurrentPage(): string[] {
    return intersection(this.getSelectedRowsIds(), this.getCurrentPageRowsIds())
  }

  public getAllPagesRowsIds(): string[] {
    const pageMaps = this.getPreloader()
      .allTablePreloaders(this.getEntity())
      .filter((pageMapPreload) => pageMapPreload.accessoryId === this.getTableModelId())

    return pageMaps.reduce<string[]>((result, pageMap) => [...result, ...pageMap.map], [])
  }

  public getCurrentPageRowsIds(): Array<string> {
    const pageMap = this.getPageMapPreloader()
    if (!pageMap) {
      return []
    }
    return pageMap.map ?? []
  }

  public getCurrentPageRows<T extends BaseModel = BaseModel>(): T[] {
    const currentPageRowsIds = this.getCurrentPageRowsIds()
    return this.entityRepository.findIn(currentPageRowsIds, ['*'])
  }

  public getCurrentPageRowsExtended<T extends BaseModel = BaseModel>(): TableRowExtended<T>[] {
    const columns = this.getColumns()
    const entities = this.getCurrentPageRows<T>()
    return entities.map((entity) => ({
      id: entity.id,
      entity,
      disabled: columns.isRowDisabled({ row: entity }),
    }))
  }

  public async updateTotalCount() {
    const {
      pagination: { totalCount },
    } = await this.fetch({
      pageKey: '-1',
      requestParams: {
        currentPage: 1,
        lastId: '0',
        perPage: 1,
      },
    })

    this.getPagination().setTotalCount(totalCount)
  }

  @rowsReloadable
  public async refreshRows(params?: RefreshRowsParams) {
    const { isSilent = false, isReset = true, isInit = false, isDisabled = false } = params || {}
    this.log('Refresh rows')

    if (isDisabled) {
      this.setIsDisabled(true)
    }

    if (!isSilent) {
      // refresh rows with loader
      this.setIsRowsLoading(this.getTableModelId(), true)
    } else {
      this.setIsRowsLoading(this.getTableModelId(), false)
    }

    let pageKey = this.getPagination().getCurrentPage().pageKey

    try {
      let data = await this.fetch()
      this.getPagination().onDataLoad(data.pagination)

      if (this.resetPaginationIfDataEmpty(data.items.length)) {
        pageKey = this.getPagination().getCurrentPage().pageKey
        data = await this.fetch()
      }

      await this.addRows(data, pageKey, isReset)

      // render rows first and then wait for count call that could take a while
      this.fetchStandaloneCount(data.pagination).then(() => {
        this.handleFacets()

        const table = this.tableRepo.findEntityByIdOrFail(this.getTableModelId())
        if (table.canBeRestored || isInit) {
          this.saveRecoveryData(data)
        }
      })
    } catch (e) {
      if (e instanceof TmApiServerError) {
        this.getPreloader().markAsFailed(pageKey, this.getTableModelId(), this.getEntity())
      }

      this.log('Error occurred in refreshRows')
      this._loggerService.raw('table', e)

      this._notificationService.notifyFromError(e)
      if (e instanceof TmTestError) throw e
    } finally {
      this.setIsRowsLoading(this.getTableModelId(), false)
      this.setIsDisabled(false)
    }
  }

  public async fetch(paginationParams?: PaginationParams): Promise<PaginationOffsetResponse<BaseModel>> {
    const gridService: Gridable<BaseModel> = this._gridService ?? this.getDomainRepository(this._entity)
    return gridService.gridRequest(
      this.toQuery(),
      paginationParams || this.getPagination().getCurrentPage(),
      this.getSearch().getSearchQuery(),
      this.getSearch().getSearchFields(),
    )
  }

  public async fetchStandaloneCount(paginationData: PaginationData): Promise<void> {
    if (!this.hasCounterService()) {
      return
    }

    await this.getCounterService().fetchCount(
      this.getTableModelId(),
      this.toQuery(),
      this.getPagination().getCurrentPage(),
      paginationData,
      this.getSearch().getSearchQuery(),
      this.getSearch().getSearchFields(),
    )
    const counter = this.getCounterService().getCount(this.getTableModelId())
    this.getPagination().setData({
      id: this.getTableModelId(),
      totalCount: counter?.total,
    })
  }

  public toQuery(): PaginationUrlType {
    return {
      filter: this.getFiltersQuery(true),
      sort: this.getSorters().toQuery(),
      groupBy: this.getGrouping().toQuery(),
    }
  }

  public async bulkDelete(deleteParams?: Partial<BulkDeleteParams>) {
    return this._bulkManager
      .getService(this.getEntity())
      .bulkDelete(
        {},
        this.isAllSelected() ? [] : this.getSelectedRowsIds(),
        this.isAllSelected(),
        this.getFiltersQuery(true),
        this.getEntity(),
        { ...(deleteParams ?? {}) },
      )
  }

  public setIsShowFilters(isShowFilters: boolean) {
    return this.updateTable({ isShowFilters })
  }

  public toggleFilters() {
    const table = this.getTableModel()

    return this.setIsShowFilters(!table.isShowFilters)
  }

  public isLoading() {
    return this.tableRepo.getRowsLoading(this.getTableModelId())
  }

  public isDisabled() {
    return this.tableRepo.isDisabled(this.getTableModelId())
  }

  public isEmptyState() {
    return (
      this.isInit() &&
      !this.isLoading() &&
      !this.getCurrentPageRowsIds().length &&
      this.isFiltersDefaultState(false) &&
      this.getPagination().isDefaultState() &&
      this.getSearch().isDefaultState()
    )
  }

  public selectRowByIds(ids: string[], isSelected: boolean) {
    this.selectedRowRepository.selectRows(this.getTableModelId(), ids, isSelected)
  }

  public isExpanded() {
    return this._expandService.isExpanded(this.getTableModelId())
  }

  public expandAllRows(isExpanded: boolean) {
    this._expandService.expandAllRows(this.getTableModelId(), isExpanded)
  }

  public updateTable(changeSet: Partial<Table>) {
    const id = this.getTableModelId()
    this.tableRepo.update([{ id, ...changeSet }])
  }

  public selectRows(key: RowKey) {
    if (key === 'pageAll') {
      return this.selectAllPageRows()
    }
    if (key === 'all') {
      this.selectAllRows()
      return undefined
    }
    if (this.selectedCount() === 0) {
      return this.selectRowByIds([key], true)
    }
    if (!this.isSelectedRow(key)) {
      return this.selectRowByIds([key], true)
    }

    return undefined
  }

  public unselectRows(key: RowKey) {
    if (key === 'all') {
      // absolutely cleanup selection from table
      this.unselectAllRows()
      this.allSelectedTablesRepository.update([
        {
          id: this.getTableModelId(),
          isAllSelected: false,
        },
      ])
    } else if (key === 'pageAll') {
      // unselect all from current page
      if (this.isAllSelected()) {
        // absolutely cleanup selection from table
        this.unselectAllRows()
      } else {
        this.selectRowByIds(this.getCurrentPageRowsIds(), false)
      }
    } else {
      if (this.isAllSelected()) {
        this.unselectAllRows()
        const rowsIds = this.getCurrentPageNonDisabledRowsIds()
        this.selectRowByIds(rowsIds, true)
      }
      this.selectRowByIds([key], false)
    }

    if (this.isAllSelected()) {
      this.setIsAllSelected(false)
    }
  }

  public selectAllPageRows() {
    const rows = this.getCurrentPageNonDisabledRowsIds()
    if (rows.length === this.getTotalCount() && this.isDefaultStateForTotalCount()) {
      this.allSelectedTablesRepository.selectAll(this.getTableModelId())
    }
    return this.selectRowByIds(rows, true)
  }

  public unselectAllPageRows() {
    this.selectedRowRepository.unselectAll(this.getTableModelId())
  }

  public selectAllRows() {
    this.allSelectedTablesRepository.selectAll(this.getTableModelId())
    this.selectAllPageRows()
  }

  public unselectAllRows() {
    this.allSelectedTablesRepository.unselectAll(this.getTableModelId())
    this.unselectAllPageRows()
  }

  public async saveColumns(columns: Column[], disabledSync?: boolean) {
    try {
      const orderedColumns = await this.getColumns().saveColumns(columns, this.getTableModelId(), disabledSync)
      this.setParamsByTableData({
        [COLUMNS_URL_QUERY_PARAM]: orderedColumns,
      })
    } catch (e) {
      this._notificationService.notifyFromError(e)
    }
  }

  public async export(extension: ExportAvailableExtensions, email?: string): Promise<ExportResultResponse> {
    this.setIsExportInProgress(true)
    const sort = this.getSortsUrlQuery()
    const params: ExportBody = {
      searchQuery: this.getSearch().getSearchQuery(),
      fileFormat: extension,
      ...this.getFilters()[0]?.getData(),
    }
    if (sort && Object.keys(sort).length) {
      params.sort = sort
    }
    if (email) {
      params.email = email
    }
    try {
      return await this._exportService.exportByEntity(
        this._entity,
        params,
        this.getTableModelId() as RegisteredServices,
        this._gridService?.getFetchEndpointParams?.() || [],
      )
    } finally {
      this.setIsExportInProgress(false)
    }
  }

  public getCurrentPage() {
    return this.getPagination().getCurrentPage()
  }

  public getFirstPage() {
    return this.getPagination().getFirstPage()
  }

  public getPerPage() {
    return this.getPagination().getPerPage()
  }

  // call it in onUnmount hook in createTable composition to reset table on each transition
  public cleanUp() {
    this.log('Starting table components cleanup')
    this.filterServiceManager.removeAllServicesForTable(this.getTableModelId())
    this.sorterManager.removeAllServicesForTable(this.getTableModelId())
    this.grouperManager.removeAllServicesForTable(this.getTableModelId())
    this.paginatorManager.removeAllServicesForTable(this.getTableModelId())
    this.searcherManager.removeAllServicesForTable(this.getTableModelId())
    this.columnManager.removeAllServicesForTable(this.getTableModelId())

    this.log('Starting table cleanup...')
    this.selectedRowRepository.cleanUp(this.getTableModelId())
    this.getPreloader().deleteTablePreloadersByCondition(this.getTableModelId())
    this.allSelectedTablesRepository.cleanUp(this.getTableModelId())
    this.tableRepo.cleanUp(this.getTableModelId())
    this.log('Table cleanup completed')
  }

  public isFiltersDefaultState(includeHiddenFilters: boolean) {
    const filters = this.getFilters()
    return filters.every((filter) => {
      if (this.customFiltersDefaultState) {
        return this.customFiltersDefaultState(filter)
      }
      return filter.isDefaultState(includeHiddenFilters)
    })
  }

  public isDefaultState(
    options: BaseTableIsDefaultStateOptions = {
      includeHiddenFilters: true,
    },
  ) {
    return (
      this.isFiltersDefaultState(options.includeHiddenFilters) &&
      this.getSorters().isDefaultState() &&
      this.getPagination().isDefaultState() &&
      this.getSearch().isDefaultState()
    )
  }

  public isDefaultStateForTotalCount() {
    return this.isFiltersDefaultState(false) && this.getSearch().isDefaultState()
  }

  public unsubscribe() {
    this._subscriptionService.unsubscribe(this._subscriptionKeys)
    this._subscriptionKeys = []
  }

  public getPageMapId(pageKey?: string) {
    return this._keyBuilder.key(pageKey || this.getPageKey(), this.getTableModelId())
  }

  public setIsRowsLoading(id: string, isRowsLoading: boolean) {
    this.tableRepo.setIsRowsLoading(id, isRowsLoading)
  }

  public setIsDisabled(isDisabled: boolean) {
    this.tableRepo.setIsDisabled(this.getTableModelId(), isDisabled)
  }

  public reset() {
    this.getFilters().forEach((filter) => filter.reset([]))
    this.getSorters().reset()
    this.getSearch().reset()
    this.getPagination().reset()
    this.getColumns().reset()

    this.cleanQuery(this.tableWrapperService.getAllTableParamsKeys(this.getTableWrapperId()))
  }

  // leave this method here for future
  protected cleanQuery(
    keysToRemove = [
      FILTERS_URL_QUERY_PARAM,
      SORTS_URL_QUERY_PARAM,
      PAGING_URL_QUERY_PARAM,
      COLUMNS_URL_QUERY_PARAM,
      GROUPING_URL_QUERY_PARAM,
    ],
    shouldReplaceState = false,
  ) {
    return this.tableWrapperService.removeParamsByTableKeys(this.getTableWrapperId(), keysToRemove, shouldReplaceState)
  }

  public getQueryByKey<T>(key: string, defaultValue = {}): T {
    const params = this.getParam(key) as T
    if (!params) {
      return defaultValue as T
    }
    return params
  }

  protected async setParamsByTableData(params: WrapperTableParams, shouldReplaceState = false) {
    const { canBeRestored } = await this.tableWrapperService.setParamsByTableData(
      this.getTableWrapperId(),
      params,
      this.tableWrapperParamsToSave || {},
      shouldReplaceState,
    )
    this.tableRepo.setCanBeRestored(this.getTableModelId(), canBeRestored)
  }

  public getFiltersQuery(exportHidden = false) {
    return this.filterServiceManager.getQueryForTable(this.getTableModelId(), exportHidden)
  }

  public populateFiltersFromUrl(params: PaginationUrlFilterType, setInitial = false) {
    return this.filterServiceManager.populateFromUrl(this.getTableModelId(), params, setInitial)
  }

  public isSelectAllAvailable(): boolean {
    if (this.getNoSelectAll() || !this.getSearch().isDefaultState()) {
      return false
    }

    if (this.isFiltersDefaultState(true)) {
      return true
    }

    return this.getAllowSelectAllWithNonDefaultFilters()
  }

  public setAllowSelectAllWithNonDefaultFilters(value: boolean) {
    this.allowSelectAllWithNonDefaultFilters = value
  }

  public getAllowSelectAllWithNonDefaultFilters(): boolean {
    return this.allowSelectAllWithNonDefaultFilters
  }

  protected getSortsUrlQuery() {
    return this.getSorters().toQuery()
  }

  protected getPagingUrlQuery() {
    return this.getPagination().toQuery()
  }

  protected getGroupingUrlQuery() {
    return this.getGrouping().toQuery()
  }

  protected getSearchUrlQuery() {
    return this.getSearch().toQuery()
  }

  protected saveRecoveryData(data: PaginationOffsetResponse<BaseModel>) {
    const currentRecoveryData = this.getRecoveryData()
    const pagination = this.getPagination().getData() as Pagination

    const items = data.items.map((item) => item.id)
    const currentPage = Math.max(pagination.currentPage, pagination.page)
    const initialItems = currentPage === 1 ? items : currentRecoveryData?.initialItems || []
    const initialPagination = currentPage === 1 ? pagination : currentRecoveryData?.initialPagination || {}

    this._recoveryService.save({
      id: this.getTableModelId(),
      canBeRestored: this.canBeRestored,
      items,
      initialItems,
      pagination,
      initialPagination,
    } as TableRecoveryData)
    this.savePaginationToHistory(pagination)
  }

  protected getRecoveryData() {
    return this._recoveryService.get(this.getTableModelId())
  }

  protected getRecoveredItems(sourceItems: string[], entitiesMap: Record<string, BaseModel>) {
    const items = sourceItems.map((id) => entitiesMap[id]).filter((item) => !!item)

    if (items.length !== sourceItems.length) {
      return undefined
    }

    return items
  }

  protected savePaginationToHistory(pagination: Pagination) {
    const routerHistory = this._historyService.getRouterHistory()
    routerHistory.replace(routerHistory.location, {
      ...routerHistory.state,
      [this.getTableModelId()]: pagination,
    } as HistoryState)
  }

  protected getPaginationFromHistory() {
    const { state } = this._historyService.getRouterHistory()
    return state[this.getTableModelId()] as Pagination | undefined
  }

  public async on(payload: QueryChangedPayload) {
    if (!this.isActive()) {
      return
    }
    this.log(`on: ${JSON.stringify(payload, null, 2)}`)
    const filterFlag = this.filterSubscription()(payload)
    const sortFlag = this.sortSubscription()(payload)
    const groupingFlag = this.groupingSubscription()(payload)
    const searchFlag = this.searchSubscription()(payload)
    this.columnSubscription()(payload)
    if (filterFlag || sortFlag || groupingFlag || searchFlag) {
      this.pagingSubscription()(payload)
      await this.refreshRows({
        isSilent: false,
        isReset: groupingFlag,
      })
      if (filterFlag) {
        this.getFilters().forEach((fs) => fs.setApplied(true))
      }
    } else {
      const oldPagination = this.getPagination().getData()
      const pagingFlag = this.pagingSubscription()(payload)

      if (pagingFlag) {
        const newPagination = this.getPagination().getData()

        if (
          // page is NOT changed
          oldPagination.page === newPagination.page &&
          // perPage IS changed
          oldPagination.perPage !== newPagination.perPage &&
          // there are fewer rows than previous perPage have
          oldPagination.totalCount <= oldPagination.perPage
        ) {
          this.slicedClonePageMap(oldPagination.pageKey, newPagination.pageKey, newPagination.perPage)
        } else {
          await this.refreshRows({
            isSilent: false,
            isReset: false,
          })
        }
      }
    }

    const { isAllSelected } = this.getAllSelectedModel()
    if (isAllSelected) {
      this.selectAllPageRows()
    }
  }

  public filterSubscription() {
    return this.queryUrlHandlers(
      FILTERS_URL_QUERY_PARAM,
      () => {
        this.populateFiltersFromUrl(this.getQueryByKey<PaginationUrlFilterType>(FILTERS_URL_QUERY_PARAM, []))
      },
      () => this.getFilters().forEach((fs) => fs.reset([])),
    )
  }

  public sortSubscription() {
    return this.queryUrlHandlers(
      SORTS_URL_QUERY_PARAM,
      () =>
        this.getSorters().applySortByUrlParams(
          this.getQueryByKey<PaginationUrlParametersSortType>(SORTS_URL_QUERY_PARAM),
        ),
      () => this.getSorters().reset(),
    )
  }

  public groupingSubscription() {
    return this.queryUrlHandlers(
      GROUPING_URL_QUERY_PARAM,
      () =>
        this.getGrouping().applyGroupingByUrlParams(
          this.getQueryByKey<PaginationUrlParametersGroupingType>(GROUPING_URL_QUERY_PARAM),
        ),
      () => this.getGrouping().reset(),
    )
  }

  public searchSubscription() {
    return this.queryUrlHandlers(
      SEARCH_URL_QUERY_PARAM,
      () => this.getSearch().applyByUrlParams(this.getQueryByKey<SearchUrlParams>(SEARCH_URL_QUERY_PARAM)),
      () => this.getSearch().reset(),
    )
  }

  public pagingSubscription() {
    return (payload: QueryChangedPayload) =>
      this.queryUrlHandlers(
        PAGING_URL_QUERY_PARAM,
        () => {
          const query = this.getQueryByKey<PaginationUrlParams>(PAGING_URL_QUERY_PARAM)
          this.getPagination().applyUrlParams(query)
        },
        () => this.getPagination().reset(),
      )(payload) && !this.exists(this.getPagination().getCurrentPage().pageKey)
  }

  public columnSubscription() {
    return (payload: QueryChangedPayload) =>
      this.queryUrlHandlers(
        COLUMNS_URL_QUERY_PARAM,
        () => {
          this.makeColumns()
        },
        () => this.makeColumns(),
      )(payload)
  }

  protected queryUrlHandlers(urlKey: string, updateFunc: () => void, resetFunc: () => void) {
    return (payload: QueryChangedPayload) => {
      const addedOrUpdated = merge(payload.diff.added, payload.diff.updated)
      const { deleted } = payload.diff
      if (isEmpty(addedOrUpdated) && isEmpty(deleted)) {
        return false
      }
      if (urlKey in deleted) {
        resetFunc()
        return true
      }
      if (urlKey in addedOrUpdated) {
        updateFunc()
        return true
      }
      return false
    }
  }

  protected resetPreloaders() {
    this.getPreloader().deleteTablePreloadersByCondition(this.getTableModelId())
  }

  protected async addRows(
    response: PaginationOffsetResponse<Record<string, unknown>>,
    pageKey: string,
    isReset = false,
  ) {
    if (response.items.length) {
      if (this.fastInsert) {
        await this.getRepository(this._entity).insertItems(response.items)
      } else {
        await this.getRepository(this._entity).insertOrUpdate(response.items)
      }
    }
    if (!response.pagination || isEmpty(response.pagination)) {
      throw new TmTableError('Table response without pagination')
    }

    this.getPagination().setData({
      ...response.pagination,
      id: this.getTableModelId(),
      pageKey,
    })

    if (isReset) {
      this.allSelectedTablesRepository.insertOrUpdateRaw([{ id: this.getTableModelId(), isAllSelected: false }])
      this.selectedRowRepository.unselectAll(this.getTableModelId())
      this.resetPreloaders()
    } else {
      const { isAllSelected } = this.getAllSelectedModel()
      if (isAllSelected) {
        const itemIds = response.items.map((item) => item.id)
        this.selectedRowRepository.selectRows(this.getTableModelId(), itemIds, isAllSelected)
      }
    }

    this.updateTablePreloaders(
      pageKey,
      response.items.map((item) => item.id),
    )
  }

  public getAllSelectedModel(): ModelRaw<AllSelectedTables> {
    return this.allSelectedTablesRepository.findEntityByIdOrFail(this.getTableModelId())!
  }

  public getTableModelId(): string {
    if (!this._tableModelId) {
      throw new TmTableError('tableModelId is not set for the table')
    }

    return this._tableModelId
  }

  public getTableWrapperId(): string {
    return this._tableWrapperId
  }

  public getTableModel(): ModelRaw<Table> {
    return this.tableRepo.findEntityByIdOrFail(this.getTableModelId())
  }

  public changeActive(isActive: boolean) {
    if (!isActive) {
      return this.tableWrapperService.removeParamsByTableKeys(
        this.getTableWrapperId(),
        [UI_STATE_URL_QUERY_PARAM],
        true,
      )
    }
    return this.setParamsByTableData({
      [UI_STATE_URL_QUERY_PARAM]: { isActive },
    })
  }

  public isActive(): boolean {
    try {
      const uiState = this.getParam(UI_STATE_URL_QUERY_PARAM)
      if (
        isRecordUnknown(uiState) &&
        typeof uiState.isActive !== 'undefined' &&
        typeof uiState.isActive === 'boolean'
      ) {
        return uiState.isActive
      }
      return false
    } catch (e) {
      this.log('isActive caught error')
      this._loggerService.raw('table', e)
      return false
    }
  }

  public getPagination<T extends PaginationUrlParams = PaginationUrlParams>(): PaginationInterface<T> {
    return this.paginatorManager.getFirstServiceForTable(this.getTableModelId()) as PaginationInterface<T>
  }

  protected getPageKey() {
    const data = this.getPagination().getData()
    if (!data) {
      throw new TmTableError('There is no pagination')
    }
    return data.pageKey
  }

  /* eslint-disable */
  public getDomainRepository<T extends OrmApiRepository>(model: typeof BaseModel): T {
    try {
      // @ts-ignore
      return this._domainManager.getService<DomainBaseService<T>>(model).getDomainRepository()
    } catch (e) {
      return this.getRepository<T>(model)
    }
  }

  public getRepository<T extends OrmApiRepository>(model: typeof BaseModel): T {
    return this._em.getRepository<T>(model)
  }

  public setFilterServiceManager(manager: FilterServiceManagerInterface) {
    this.filterServiceManager = manager
  }

  public setSorterManager(manager: TableSorterManagerInterface) {
    this.sorterManager = manager
  }

  public setGrouperManager(manager: TableGrouperManagerInterface) {
    this.grouperManager = manager
  }

  public setPaginatorManager(manager: TablePaginatorManagerInterface) {
    this.paginatorManager = manager
  }

  public setSearcherManager(manager: TableSearcherManagerInterface) {
    this.searcherManager = manager
  }

  public setColumnManager(manager: TableColumnManagerInterface) {
    this.columnManager = manager
  }

  public setBaseFilterService(filterService: BaseFilterInterface) {
    this.baseFilterService = filterService
  }

  public setCustomFiltersDefaultState(callback?: CustomFiltersDefaultState) {
    this.customFiltersDefaultState = callback
  }

  public initFilter() {
    const tableModelId = this.getTableModelId()

    this.populateFiltersFromUrl(this.getQueryByKey<PaginationUrlFilterType>(FILTERS_URL_QUERY_PARAM, []))

    if (this.filterServiceManager.hasServicesForTable(tableModelId)) {
      this.setIsShowFilters(true)
    } else if (this.filterServiceManager.hasFactoryForTable(tableModelId)) {
      // If there are no services after populateFromUrl() call, then create a new empty service
      this.filterServiceManager.addServiceForTable(this.getTableModelId())
    }
  }

  public getFiltersRecord(): Record<string, FilterInterface> {
    return this.filterServiceManager.getServicesForTable(this.getTableModelId())
  }

  public getFilters(): Array<FilterInterface> {
    return Object.values(this.getFiltersRecord())
  }

  public getSorters(): SorterInterface {
    return this.sorterManager.getFirstServiceForTable(this.getTableModelId())
  }

  public getGrouping(): GrouperInterface {
    return this.grouperManager.getFirstServiceForTable(this.getTableModelId())
  }

  public getSearch(): SearcherTableInterface {
    return this.searcherManager.getFirstServiceForTable(this.getTableModelId())
  }

  public getColumns(): ColumnsServiceInterface {
    return this.columnManager.getFirstServiceForTable(this.getTableModelId())
  }

  public setEntity(entity: typeof BaseModel) {
    this._entity = entity
  }

  public setLogger(loggerService: LoggerService) {
    this._loggerService = loggerService
  }

  public setBulkService(bulkManager: BulkManager) {
    this._bulkManager = bulkManager
  }

  public setNotificationService(notificationService: NotificationService) {
    this._notificationService = notificationService
  }

  public setHistoryService(historyService: Historyable) {
    this._historyService = historyService
  }

  public setRecoveryService(recoveryService: TableRecoveryServiceInterface) {
    this._recoveryService = recoveryService
  }

  public setSubscriptionService(subscriptionService: ModelSubscriptionService) {
    this._subscriptionService = subscriptionService
  }

  public setSubscriptions(subscriptions: string[]) {
    this._subscriptions = subscriptions
  }

  public setExportService(exportService: ExportService) {
    this._exportService = exportService
  }

  public setEm(em: EntityManagerService) {
    this._em = em
  }

  public getEm(): EntityManagerService {
    return this._em
  }

  public getLogger(): LoggerService {
    return this._loggerService
  }

  public setDomainManager(dm: DomainManagerService) {
    this._domainManager = dm
  }

  public getEntity(): typeof BaseModel {
    return this._entity
  }

  public setPreloaderManager(preloaderManager: PreloaderManager) {
    this._preloaderManager = preloaderManager
  }

  public getPreloaderManager() {
    return this._preloaderManager
  }

  public setFilterCompareService(filterCompareService: FilterCompareService) {
    this._filterCompareService = filterCompareService
  }

  public setKeyBuilder(builder: TableKeyBuilderInterface) {
    this._keyBuilder = builder
  }

  public setExpandService(expandService: TableExpandable) {
    this._expandService = expandService
  }

  public toBulk(): BulkWrapperParams {
    return {
      isAllSelected: this.isAllSelected(),
      filters: this.getFiltersQuery(true),
      tableId: this.getTableModelId(),
      selectedIds: this.isAllSelected() ? this.getCurrentPageRowsIds().slice(0, 3) : this.getSelectedRowsIds(),
      total: this.getPagination().getData().totalCount,
    }
  }

  public getBulkQuery(): BulkQuery {
    const isAllSelected = this.isAllSelected()
    return {
      selectedIds: isAllSelected ? [] : this.getSelectedRowsIds(),
      isAllSelected,
    }
  }

  public getFilterServiceManager() {
    return this.filterServiceManager
  }

  public setCounterService(counterService?: CounterInterface) {
    this.counterService = counterService
  }

  public getCounterService(): CounterInterface {
    if (this.counterService) {
      return this.counterService
    }

    throw new TmTableError('No counter service was specified')
  }

  public hasCounterService(): boolean {
    return !!this.counterService
  }

  public getTotalCount() {
    return this.hasCounterService()
      ? this.getCounterService().getCount(this.getTableModelId() as RegisteredServices)?.total || 0
      : this.getPagination().getData().totalCount
  }

  public setFacetSettings(settings?: TableFacetSettings) {
    this.facetSettings = settings
  }

  public setTableWrapperParamsToSave(params?: WrapperTableParamsToSave) {
    this.tableWrapperParamsToSave = params
  }

  public setCanBeRestored(value: boolean) {
    this.canBeRestored = value
  }

  public setFastInsert(value: boolean) {
    this.fastInsert = value
  }

  public setNoSelectAll(value: boolean) {
    this.noSelectAll = value
  }

  public getNoSelectAll(): boolean {
    return this.noSelectAll
  }

  public handleFacets() {
    if (!this.facetSettings || !this.isDefaultStateForTotalCount()) {
      return
    }
    this.facetSettings.faceter.mergeFacets({
      [this.facetSettings.facetKey]: this.getTotalCount(),
    })
  }

  public getTableWrapperService() {
    return this.tableWrapperService
  }

  public setTableWrapperService(tableWrapperService: WrapperTableServiceInterface): void {
    this.tableWrapperService = tableWrapperService
  }

  public getRow(rowId: string) {
    return this.entityRepository.findEntityByIdOrFail(rowId)
  }

  public getCurrentPageNonDisabledRowsIds() {
    const rowsIds = this.getCurrentPageRowsIds()
    return this.filterNonDisabledRowsIds(rowsIds)
  }

  public getShouldResetOnCreateOrDelete(event: InternalEmitterPayload): boolean {
    if (this.shouldResetOnCreateOrDelete) {
      return true
    }

    return ![ModelEventType.CREATE, ModelEventType.DELETE, ModelEventType.BULK_DELETE].includes(
      event.eventType as ModelEventType,
    )
  }

  public setShouldResetOnCreateOrDelete(value: boolean) {
    this.shouldResetOnCreateOrDelete = value
  }

  protected get tableRepo() {
    return this.getRepository<TableRepository>(Table)
  }

  protected get allSelectedTablesRepository() {
    const repository = this.getRepository<AllSelectedTablesRepository>(AllSelectedTables)

    if (!repository.findEntityByIdOrNull(this.getTableModelId())) {
      repository.insert([{ id: this.getTableModelId(), isAllSelected: false }])
    }

    return repository
  }

  protected get tableRecoveryDataRepository() {
    return this.getRepository<TableRecoveryDataRepository>(TableRecoveryData)
  }

  protected get entityRepository() {
    return this._em.getRepository(this.getEntity())
  }

  protected get selectedRowRepository() {
    return this.getRepository<SelectedRowRepository>(SelectedRow)
  }

  protected setIsExportInProgress(state: boolean) {
    this.tableRepo.setIsExportInProgressFlag(this.getTableModelId(), state)
  }

  protected getPreloader() {
    return this.getPreloaderManager().getTablePreloader(this.getEntity())
  }

  protected getPageMapPreloader(): PageMapPreload | undefined {
    const pageMapId = this.getPageMapId()
    const preloader = this.getPreloader()
    return preloader.findTablePreloader(pageMapId)
  }

  protected getParams() {
    return this.tableWrapperService.getTableParams(this.getTableWrapperId())
  }

  protected getParam(key: string) {
    return this.tableWrapperService.getTableParam(this.getTableWrapperId(), key)
  }

  protected log(message: string) {
    this._loggerService.log('table', message, this.getTableModelId())
  }

  protected updateTablePreloaders(pageKey: string, ids: string[]) {
    this.getPreloader().updateTablePreloaders(pageKey, this.getTableModelId(), ids, this.getEntity())
  }

  protected slicedClonePageMap(fromPageKey: string, toPageKey: string, sliceSize?: number) {
    if (this.exists(toPageKey)) {
      return
    }

    const fromPreloaderKey = this.getPageMapId(fromPageKey)
    const fromMap = this.getPreloader().findTablePreloader(fromPreloaderKey)

    if (!fromMap) {
      throw new TmTableError(`No page map preloader for key "${fromPreloaderKey}"`)
    }
    this.updateTablePreloaders(toPageKey, fromMap.map.slice(0, sliceSize))
  }

  protected updateTablePreloadersOnEvent(event: ModelEmitterPayload) {
    const tablePreloader = this.getPageMapPreloader()
    if (!tablePreloader) return
    const ids = this.filterNotificationIds(tablePreloader.map, event)
    if (ids.length === tablePreloader.map.length) return
    this.updateTablePreloaders(tablePreloader.pageKey, ids)

    if (this.getShouldResetOnCreateOrDelete(event)) {
      this.unselectRows('all')
    }
  }

  protected filterNotificationIds(ids: string[], event: ModelEmitterPayload) {
    if (this._gridService?.filterIdsByStore) return this._gridService.filterIdsByStore(ids)
    if (event.eventType === ModelEventType.DELETE || event.eventType === ModelEventType.BULK_DELETE) {
      return ids.filter((id) => !event.ids.includes(id))
    }
    return ids
  }

  protected resetPaginationIfDataEmpty(dataLength: number) {
    const needReset = !dataLength && !this.getPagination().isFirstPage()
    if (needReset) {
      // if we refresh current page and there aren't items on current page - move to first page
      this.getPagination().moveToFirstPage()
      this.setParamsByTableData({ [PAGING_URL_QUERY_PARAM]: this.getPagination().toQuery() })
    }
    return needReset
  }

  protected filterNonDisabledRowsIds(rowsIds: string[]) {
    const rows = this.getRowsByRowsIds(rowsIds)
    const nonDisabledRows = this.filterNonDisabledRows(rows)
    return nonDisabledRows.map(({ id }) => id)
  }

  protected getRowsByRowsIds(rowsIds: string[]) {
    return this.entityRepository.findIn(rowsIds) as ModelRaw<unknown>[]
  }

  protected filterNonDisabledRows(rows: ModelRaw<unknown>[]) {
    const columns = this.getColumns()
    return rows.filter((row) => !columns.isRowDisabled({ row }))
  }

  protected async initialRefreshRows(): Promise<void> {
    await this.refreshRows({ isSilent: this.isInit(), isReset: !this.isInit(), isInit: true })
  }
}

<template>
  <div class="top-filter-margin-wrapper">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.top-filter-margin-wrapper {
  margin-bottom: 16px;

  @include media-md-max {
    margin-bottom: 12px;
  }
}
</style>

import { injectable } from 'inversify'

import type { CacheService } from '@/services/cache/cacheService'
import type BaseModel from '@/data/models/BaseModel'

type LRUCacheValue<T> = {
  value: T
  createdAt: number
}

@injectable()
export class LRUCacheService<T = BaseModel> implements CacheService<T> {
  private cache: Map<string, LRUCacheValue<T>> = new Map()

  private size: number

  private ttl?: number

  constructor(size: number = 25, ttl?: number) {
    this.size = size
    this.ttl = ttl
  }

  public get(key: string): T | null {
    const value = this.cache.get(key)

    if (!value) {
      return null
    }

    if (this.ttl && Date.now() - value.createdAt > this.ttl) {
      this.cache.delete(key)
      return null
    }

    this.set(key, value.value)

    return value.value
  }

  public set(key: string, value: T): void {
    this.cache.delete(key)
    if (this.cache.size >= this.size) {
      const firstKey = this.cache.keys().next().value
      if (typeof firstKey === 'string') {
        this.cache.delete(firstKey)
      }
    }
    this.cache.set(key, { value, createdAt: Date.now() })
  }

  public delete(key: string): void {
    this.cache.delete(key)
  }

  public clear(): void {
    this.cache.clear()
  }
}

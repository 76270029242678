import type { RouteRecordRaw } from 'vue-router'
import { ChatSettingsRoutes } from '@/routes/types'
import { AppModule } from '@/config/types'

export const getMessengerPreviewRoutes = (): RouteRecordRaw[] => [
  {
    name: `${ChatSettingsRoutes.widgetPreview}.base`,
    path: 'messenger-web-widget-preview',
    component: () => import('@/components/containers/CleanPageContainer.vue'),
    meta: {
      modules: [AppModule.WebWidgets],
      accessControlGroups: ['WebWidgetsStrategy'],
    },
    children: [
      {
        name: ChatSettingsRoutes.widgetPreview,
        path: ':id',
        props: true,
        component: () => import('@/components/views/chats/settings/webWidget/WebWidgetPreviewPage.vue'),
        meta: {
          tabTitle: 'pageTitle.widgetPreview',
        },
      },
    ],
  },
]

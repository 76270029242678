import { inject, injectable } from 'inversify'

import { SERVICE_TYPES } from '@/core/container/types'

import type { Resolvable } from '@/services/resolvers/types'
import type SendReceiveSettingsService from '@/services/domain/sendReceiveSettings/sendReceiveSettingsService'

@injectable()
export default class SendReceiveSettingsResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.SendReceiveSettingsService)
    protected readonly sendReceiveSettingsService: SendReceiveSettingsService,
  ) {}

  public async resolve(): Promise<void> {
    await this.sendReceiveSettingsService.loadSettings()
  }
}

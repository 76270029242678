import type { RouteRecordRaw } from 'vue-router'
import { contactsRoute } from '@/routes/user/contacts/contacts'
import { contactsSettingsRoute } from '@/routes/user/contacts/contactsSettings'
import { getTemplatesRoutes } from '@/routes/user/templates'
import { getServicesRoutes } from '@/routes/user/services'
import { composeMessageRouteName, getComposeRoutes } from '@/routes/user/compose'
import { getScheduledRoutes } from '@/routes/user/scheduled/scheduled'
import { getChatsRoutes } from '@/routes/user/messenger/chats'
import { reportingRoute } from '@/routes/user/reporting/reporting'
import { getGettingStartedRoutes } from '@/routes/user/getting-started'
import { getComingSoonRoutes } from '@/routes/user/coming-soon'
import { getTasksRoutes } from '@/routes/user/tasks/tasks'
import { getTicketsRoutes } from '@/routes/user/tickets/tickets'
import { getDealsRoutes } from '@/routes/user/deals/deals'
import { campaignsRoute } from '@/routes/user/campaigns/campaigns'
import { userAccountsRoute } from '@/routes/user/accounts/accounts'
import { getHistoryRoutes } from '@/routes/user/history'

export const getUserRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.base',
    path: '',
    redirect: { name: composeMessageRouteName },
    component: () => import('@/components/containers/BaseContainer.vue'),
    meta: {
      resolvers: [
        {
          service: 'MainSidebarRoutesResolverService',
        },
      ],
    },
    children: [
      ...getGettingStartedRoutes(),
      ...getComposeRoutes(),
      ...getChatsRoutes(),
      ...getTicketsRoutes(),
      contactsRoute as RouteRecordRaw,
      contactsSettingsRoute as RouteRecordRaw,
      ...getTasksRoutes(),
      ...getScheduledRoutes(),
      ...getHistoryRoutes(),
      ...getTemplatesRoutes(),
      ...getDealsRoutes(),
      ...getServicesRoutes(),
      userAccountsRoute,
      reportingRoute as RouteRecordRaw,
      ...getComingSoonRoutes(),
      campaignsRoute as RouteRecordRaw,
    ],
  },
]

import type { RouteRecordRaw } from 'vue-router'
import type { DomainTitlerParams } from '@/services/route/titlers/types'
import type { SingleModelResolverParams } from '@/services/resolvers/types'
import User from '@/data/models/domain/User'
import SubAccountsImportDetails from '@/data/models/domain/subAccounts/import/SubAccountsImportDetails'
import AccountSsoSettings from '@/data/models/domain/account/AccountSsoSettings'
import { AppModule } from '@/config/types'
import PassThrough from '@/components/layout/PassThrough.vue'
import {
  userAccountsDataRetentionRoute,
  userAccountsMyDataRoute,
  userAccountsSubAccountsRoute,
  userAccountsUsersRoute,
} from '@/routes/user/accounts/workspace'
import {
  userAccountsBillingOverviewRoute,
  userAccountsInvoicesRoute,
  userAccountsMonthlyPlansRoute,
  userAccountsStatementsRoute,
} from '@/routes/user/accounts/billing'
import {
  userAccountsDetailsRoute,
  userAccountsNotificationsRoute,
  userAccountsSecurityRoute,
} from '@/routes/user/accounts/personal'

export const userAccountsSettingsRoute: RouteRecordRaw = {
  name: 'user.accounts.settings',
  path: 'settings',
  meta: {
    tags: ['ACCOUNTS_BAR_LIST'],
    permission: 'accountSettings',
  },
  component: PassThrough,
  redirect: { name: 'user.accounts.settings.profile' },
  children: [
    {
      name: 'user.accounts.settings.profile',
      path: 'profile',
      redirect: { name: 'user.accounts.details' },
    },
    {
      name: 'user.accounts.settings.sso',
      path: 'sso',
      redirect: { name: 'user.accounts.sso' },
    },
    {
      name: 'user.accounts.settings.security',
      path: 'security',
      redirect: { name: 'user.accounts.security' },
    },
    {
      name: 'user.accounts.settings.closeAccount',
      path: 'close',
      redirect: { name: 'user.accounts.details' },
    },
  ],
}

export const userAccountsSsoRoute: RouteRecordRaw = {
  name: 'user.accounts.sso',
  path: 'sso',
  component: () => import('@/components/views/accounts/AccountSso.vue'),
  props: { tab: true },
  meta: {
    accessControlGroups: ['SuperAdminRoleStrategy'],
    resolvers: [
      {
        service: 'ModelSettingsResolverService',
        params: {
          model: AccountSsoSettings,
          redirect: { name: 'user.accounts.settings' },
        },
      },
    ],
    tabTitle: 'pageTitle.user.accounts.sso',
    tags: ['ACCOUNTS_BAR_LIST'],
    permission: 'accountSettings',
  },
}

export const getAccountsRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.accounts.subAccounts.import.flow',
    path: 'sub-accounts/import/flow',
    component: () => import('@/components/views/accounts/subAccounts/SubAccountImportFlow.vue'),
    meta: {
      parent: 'user.accounts',
      permission: 'subaccountSettings',
      modules: [AppModule.SubAccountsImportDetails, AppModule.SubAccountsImportFlow],
      accessControlGroups: ['SubAccountsImportFlowStrategy'],
      tabTitle: 'pageTabTitle.user.accounts.subAccounts.importFlow',
    },
  },
  {
    name: 'user.accounts.subAccountDetails',
    path: 'sub-accounts/:subAccountId',
    meta: {
      parent: 'user.accounts',
      tags: ['ACCOUNTS_BAR_LIST'],
      resolverComponent: () => import('@/components/resolvers/DetailsHeroPageResolver.vue'),
      detailsPage: {
        source: 'router',
        requestParam: 'subAccountId',
        model: User,
      } as SingleModelResolverParams,
      titler: {
        service: 'DomainTitlerService',
        params: {
          model: User,
          field: 'fullName',
          idParamName: 'subAccountId',
        } as DomainTitlerParams,
      },
      redirectToIfNotFound: { name: 'user.accounts.subAccounts' },
      modules: [AppModule.SubAccountsDetails, AppModule.SubAccountsForm, AppModule.AccountSettings],
      accessControlGroups: ['SubAccountDetailsStrategy'],
    },
    component: () => import('@/components/views/accounts/subAccountDetails/SubAccountDetails.vue'),
    redirect: { name: 'user.accounts.subAccountDetails.overview' },
    children: [
      {
        name: 'user.accounts.subAccountDetails.overview',
        path: '',
        meta: {
          modules: [AppModule.AccountSettings, AppModule.AuditLogs],
        },
        component: () => import('@/components/views/accounts/subAccountDetails/SubAccountDetailsOverview.vue'),
        props: (route) => ({
          subAccountId: route.params.subAccountId,
        }),
      },
      {
        name: 'user.accounts.subAccountDetails.permissions',
        path: 'permissions',
        meta: {
          modules: [AppModule.AccountSettings],
        },
        component: () => import('@/components/views/accounts/subAccountDetails/SubAccountDetailsPermissions.vue'),
        props: (route) => ({
          subAccountId: route.params.subAccountId,
        }),
      },
      {
        name: 'user.accounts.subAccountDetails.sent',
        path: 'sent',
        component: () => import('@/components/views/accounts/subAccountDetails/SubAccountDetailsSentSms.vue'),
        props: (route) => ({
          subAccountId: route.params.subAccountId,
        }),
        meta: {
          modules: [AppModule.SentSmsBase, AppModule.HistorySentSms, AppModule.SenderSettingsCountries],
        },
      },
      {
        name: 'user.accounts.subAccountDetails.received',
        path: 'received',
        component: () => import('@/components/views/accounts/subAccountDetails/SubAccountDetailsReceivedSms.vue'),
        props: (route) => ({
          subAccountId: route.params.subAccountId,
        }),
        meta: {
          modules: [AppModule.HistoryReceivedSms],
        },
      },
    ],
  },
  {
    name: 'user.accounts.userDetails',
    path: 'users/:subAccountId',
    meta: {
      parent: 'user.accounts',
      permission: 'subaccountSettings',
      tags: ['ACCOUNTS_BAR_LIST'],
      resolverComponent: () => import('@/components/resolvers/DetailsHeroPageResolver.vue'),
      detailsPage: {
        source: 'router',
        requestParam: 'subAccountId',
        model: User,
      } as SingleModelResolverParams,
      titler: {
        service: 'DomainTitlerService',
        params: {
          model: User,
          field: 'fullName',
          idParamName: 'subAccountId',
        } as DomainTitlerParams,
      },
      redirectToIfNotFound: { name: 'user.accounts.subAccounts' },
      modules: [AppModule.SubAccountsDetails, AppModule.SubAccountsForm, AppModule.AccountSettings],
      accessControlGroups: ['UserWorkflowStrategy'],
    },
    component: () => import('@/components/views/accounts/userDetails/UserDetails.vue'),
    props: (route) => ({
      userId: route.params.subAccountId,
    }),
    redirect: { name: 'user.accounts.userDetails.overview' },
    children: [
      {
        name: 'user.accounts.userDetails.overview',
        path: '',
        meta: {
          modules: [AppModule.AccountSettings, AppModule.AuditLogs],
        },
        component: () => import('@/components/views/accounts/userDetails/UserDetailsOverview.vue'),
        props: (route) => ({
          accountId: route.params.subAccountId,
        }),
      },
      {
        name: 'user.accounts.userDetails.permissions',
        path: 'permissions',
        meta: {
          modules: [AppModule.AccountSettings],
        },
        component: () => import('@/components/views/accounts/subAccountDetails/SubAccountDetailsPermissions.vue'),
        props: (route) => ({
          subAccountId: route.params.subAccountId,
          isUsersWorkflow: true,
        }),
      },
    ],
  },
  {
    name: 'user.accounts.subAccounts.import.details',
    path: 'sub-accounts/import/:subAccountImportId',
    component: () => import('@/components/views/accounts/subAccounts/import/details/SubAccountsImportDetails.vue'),
    redirect: { name: 'user.accounts.subAccounts.import.details.invited' },
    meta: {
      parent: 'user.accounts',
      tabTitle: 'pageTabTitle.user.accounts.subAccounts.importDetails',
      resolvers: [
        { service: 'SubAccountsImportResolverService' },
        {
          service: 'FacetResolver',
          params: {
            model: SubAccountsImportDetails,
            source: 'router',
            requestParam: 'subAccountImportId',
            subscriptionModels: [SubAccountsImportDetails],
          },
        },
      ],
      permission: 'subaccountSettings',
      modules: [AppModule.SubAccountsImportDetails],
    },
    children: [
      {
        name: 'user.accounts.subAccounts.import.details.invited',
        path: 'invited',
        component: () =>
          import('@/components/views/accounts/subAccounts/import/details/SubAccountsImportDetailsTableWrapper.vue'),
        meta: { type: 'invited' },
      },
      {
        name: 'user.accounts.subAccounts.import.details.duplicated',
        path: 'duplicated',
        component: () =>
          import('@/components/views/accounts/subAccounts/import/details/SubAccountsImportDetailsTableWrapper.vue'),
        meta: { type: 'duplicated' },
      },
      {
        name: 'user.accounts.subAccounts.import.details.failed',
        path: 'failed',
        component: () =>
          import('@/components/views/accounts/subAccounts/import/details/SubAccountsImportDetailsTableWrapper.vue'),
        meta: { type: 'failed' },
      },
    ],
  },
]

export const userAccountsRoute: RouteRecordRaw = {
  name: 'user.accounts',
  path: 'accounts',
  meta: {
    tags: ['USER_TOP_MENU'],
    title: 'pageTitle.user.accounts.accounts', // main sidebar title
    menu: () => import('@/components/domain/accounts/AccountsMenu.vue'),
    modules: [AppModule.AccountSettings, AppModule.TeamWorkflow],
    resolvers: [
      {
        service: 'AdminSectionRedirectResolver',
      },
      {
        service: 'SubscriptionPriceResolverService',
      },
    ],
  },
  component: () => import('@/components/views/accounts/Accounts.vue'),
  props: (route) => ({
    isBackgroundGray: route.name === 'user.accounts.teamMembers' || route.name === 'user.accounts.plans',
  }),
  children: [
    userAccountsSubAccountsRoute,
    userAccountsUsersRoute,
    userAccountsDataRetentionRoute,
    userAccountsMyDataRoute,
    userAccountsBillingOverviewRoute,
    userAccountsMonthlyPlansRoute,
    userAccountsInvoicesRoute,
    userAccountsStatementsRoute,
    userAccountsDetailsRoute,
    userAccountsSecurityRoute,
    userAccountsNotificationsRoute,
    userAccountsSettingsRoute,
    userAccountsSsoRoute,
    ...getAccountsRoutes(),
  ],
}

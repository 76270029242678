import { computed, ref } from '@/composition/vue/compositionApi'
import { getAuthLoaderService } from '@/core/container/ioc'

export const useAuthLoader = () => {
  const isInitialized = ref<boolean>(false)

  const authLoaderService = getAuthLoaderService()

  const shouldDisplayAuthLoader = computed(() => authLoaderService.getLoaderState())

  const showAuthLoader = async () => {
    await authLoaderService.showLoader()
  }

  const hideAuthLoader = async () => {
    await authLoaderService.hideLoader()
  }

  if (!isInitialized.value) {
    authLoaderService.fetchLoaderState().then(() => {
      isInitialized.value = true
    })
  }

  return {
    shouldDisplayAuthLoader,
    showAuthLoader,
    hideAuthLoader,
  }
}

<template>
  <details-page-resolver
    v-bind="$props"
    full-width
  >
    <template v-slot:preloader>
      <details-page-preload full-width />
    </template>
  </details-page-resolver>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from '@/composition/vue/compositionApi'
import DetailsPagePreload from '@/components/preloaders/DetailsPagePreload.vue'
import { resolversBaseProps } from '@/composition/resolvers'

const DetailsPageResolver = defineAsyncComponent(() => import('@/components/resolvers/DetailsPageResolver.vue'))

export default defineComponent({
  components: {
    DetailsPageResolver,
    DetailsPagePreload,
  },
  props: {
    ...resolversBaseProps,
  },
})
</script>

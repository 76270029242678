import { inject, injectable } from 'inversify'
import { getWindowService } from '@/core/container/ioc'

import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type { IServerEventName } from '@/services/transport/serverEvents'

import type ServerSubscriptionService from '@/services/transport/serverSubscriptionService'

@injectable()
export default class SystemRefreshResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.ServerSubscriptionService)
    protected readonly serverSubscriptionService: ServerSubscriptionService,
  ) {}

  private eventName: IServerEventName = 'forceRefreshWebApp'

  private handler() {
    const windowService = getWindowService()
    windowService.getLocation().reload()
  }

  public async resolve() {
    this.serverSubscriptionService.subscribe(this.eventName, () => {
      this.handler()
    })
  }

  public async unresolve() {
    this.serverSubscriptionService.unsubscribeAll(this.eventName)
  }
}

import type { SanitizeAttributeHookEvent } from 'dompurify'
import type { PurifierConfig } from '@/utils/string/types'

import { emojiAttributeName, emojiTagName } from '@/services/forms/tmTiptap/const'

export const defaultPurifierConfig = {
  ALLOWED_TAGS: ['div', 'span', 'a', 'mark', 'media', 'audio', 'img', 'br'],
  ALLOWED_ATTR: ['class', 'href', 'src', 'alt', 'height', 'width', 'target'],
} satisfies PurifierConfig

export const strictPurifierConfig = {
  KEEP_CONTENT: false,
} satisfies PurifierConfig

export const richPurifierConfig: PurifierConfig = {
  ALLOWED_TAGS: [
    ...defaultPurifierConfig.ALLOWED_TAGS,
    'strong',
    'u',
    'b',
    'i',
    'strike',
    'em',
    's',
    'pre',
    'code',
    'ul',
    'ol',
    'li',
    'p',
    'font',
    'div',
    'span',
    'blockquote',
    'table',
    'tbody',
    'thead',
    'th',
    'tr',
    'td',
    'style',
  ],
  ALLOWED_ATTR: [...defaultPurifierConfig.ALLOWED_ATTR, 'class', 'style', 'color', 'valign'],
}

export const isEmojiNode = (node: Element) => {
  return node instanceof HTMLElement && node.tagName === emojiTagName && node.dataset.type === emojiAttributeName
}

export const canKeepClassAttribute = (node: Element, evt: SanitizeAttributeHookEvent) => {
  if (evt.allowedAttributes.class) {
    return true
  }

  return isEmojiNode(node)
}

export const canKeepStyleAttribute = (node: Element, evt: SanitizeAttributeHookEvent) => {
  if (evt.allowedAttributes.style) {
    return true
  }

  return isEmojiNode(node)
}

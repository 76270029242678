import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import GlobalState from '@/data/models/GlobalState'
import type GlobalStateRepository from '@/data/repositories/GlobalStateRepository'
import type { ModelRaw } from '@/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'

@injectable()
export default class GlobalStateService {
  constructor(@inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService) {}

  public init() {
    return this.getGlobalStateRepo().init()
  }

  public getLoadedState() {
    return this.getGlobalStateRepo().getLoadedState()
  }

  public getState() {
    return this.getGlobalStateRepo().getState()
  }

  public setFieldValue(fieldName: keyof ModelRaw<GlobalState>, value: unknown) {
    return this.getGlobalStateRepo().setFieldValue(fieldName, value)
  }

  public getFieldValue(fieldName: keyof ModelRaw<GlobalState>) {
    return this.getGlobalStateRepo().getFieldValue(fieldName)
  }

  public getFieldValueSync(fieldName: keyof ModelRaw<GlobalState>) {
    return this.getGlobalStateRepo().getFieldValueSync(fieldName)
  }

  public setFieldValueIfChanged(fieldName: keyof ModelRaw<GlobalState>, value: unknown) {
    if (this.getFieldValue(fieldName) === value) return null
    return this.setFieldValue(fieldName, value)
  }

  protected getGlobalStateRepo() {
    return this.em.getRepository<GlobalStateRepository>(GlobalState)
  }
}

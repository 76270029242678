import { inject, injectable } from 'inversify'
import type { RegisteredServices } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import { TmResolverError } from '@/core/error/tmResolverError'
import type { Service } from '@/config/types'
import type { GetLocator } from '@/core/container/container'
import type { IServiceManager } from '@/core/middlewares/types'

@injectable()
export default class ResolverManager implements IServiceManager {
  protected resolvers = new Set<RegisteredServices>()

  constructor(@inject('GetLocator') private readonly get: GetLocator) {}

  public addService(service: Service) {
    this.resolvers.add(service.id as RegisteredServices)
  }

  public getResolver<T extends Resolvable<unknown>>(name: RegisteredServices) {
    if (!this.resolvers.has(name)) {
      throw new TmResolverError(`Resolver ${name} not found`)
    }
    return this.get(name) as T
  }
}

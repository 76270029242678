import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import { ByocMessengerRoutePaths, ByocRouteNames } from '@/services/domain/byoc/types'
import { ServicesRouteName } from '@/routes/types'

export const getByocDetailsRoutes = (): RouteRecordRaw[] => [
  {
    name: ByocRouteNames.edit,
    path: 'byoc/:provider/:carrierId',
    alias: ByocMessengerRoutePaths.edit,
    props: true,
    meta: {
      parent: (route) => (route.path.startsWith(ByocMessengerRoutePaths.edit) ? 'user.chats' : ServicesRouteName),
      tabTitle: 'pageTabTitle.user.services.byoc.details',
      resolvers: [{ service: 'ByocCarrierResolver' }],
      modules: [AppModule.Byoc, AppModule.SenderSettings],
      permission: 'byoc',
    },
    component: () => import('@/components/views/services/byoc/ByocCarrierDetails.vue'),
  },
  {
    name: ByocRouteNames.callForwarding,
    path: 'byoc/:provider/:carrierId/:phoneNumberId/call-forwarding',
    alias: ByocMessengerRoutePaths.callForwarding,
    props: true,
    meta: {
      parent: (route) =>
        route.path.startsWith(ByocMessengerRoutePaths.callForwarding) ? 'user.chats' : ServicesRouteName,
      tabTitle: 'pageTabTitle.user.services.byoc.callForwarding',
      resolvers: [{ service: 'ByocCarrierResolver' }],
      modules: [AppModule.Byoc],
      permission: 'byoc',
    },
    component: () => import('@/components/views/services/byoc/ByocCallForwarding.vue'),
  },
]

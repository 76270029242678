import { inject, injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import UserPresence, { UserPresenceStatus } from '@/data/models/domain/UserPresence'
import type UserPresenceRepository from '@/data/repositories/domain/users/userPresenceRepository'
import { SERVICE_TYPES } from '@/core/container/types'
import type TranslateService from '@/services/translateService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type { UserPresenceBody } from '@/services/domain/user/types'

@DomainSettings({
  model: UserPresence,
})
@injectable()
export default class UserPresenceService extends DomainBaseService<UserPresenceRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public getUserPresence() {
    return this.getDomainRepository().findPresence()
  }

  public fetchUserPresence() {
    return this.getDomainRepository().fetchPresence()
  }

  public async updateUserPresence(userPresence: UserPresenceBody) {
    return this.getDomainRepository().updatePresence(userPresence)
  }

  public getPresenceStatusOptions() {
    return [
      {
        text: this.translateService.t('userPresence.online'),
        value: UserPresenceStatus.ONLINE,
      },
      {
        text: this.translateService.t('userPresence.offline'),
        value: UserPresenceStatus.OFFLINE,
      },
    ]
  }
}

import { inject, injectable } from 'inversify'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import type { TypedGroupFormBuilderService } from '@/services/forms/baseForm/typedFormBuilder/TypedGroupFormBuilderService'
import type { AbstractFieldConfig } from '@/services/forms/types'
import type {
  PropertyBuilderInterface,
  TypedFieldConfig,
  TypeGroupConfig,
  TypedFromField,
  TypeArrayConfig,
} from '@/services/forms/baseForm/typedFormBuilder/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { TypedArrayFormBuilderService } from '@/services/forms/baseForm/typedFormBuilder/TypedArrayFormBuilderService'

@injectable()
export class PropertyBuilder<T> implements PropertyBuilderInterface<T> {
  private formBuilder!: FormBuilderInterface

  private fieldName!: string

  private readonly defaultParentName = ''

  constructor(
    @inject(SERVICE_TYPES.TypedGroupFormBuilderFactoryService)
    protected readonly typedGroupFormBuilderFactoryService: <S>() => TypedGroupFormBuilderService<S>,
    @inject(SERVICE_TYPES.TypedArrayFormBuilderService)
    protected readonly typedArrayFormBuilderFactoryService: <S extends any[]>() => TypedArrayFormBuilderService<S>,
  ) {}

  public setDependencys(formBuilder: FormBuilderInterface, fieldName: string) {
    this.formBuilder = formBuilder
    this.fieldName = fieldName
  }

  public control<TConfig extends AbstractFieldConfig<T>>(fieldConfig: TypedFieldConfig<TConfig>) {
    this.formBuilder.control(
      {
        ...fieldConfig,
        name: this.fieldName,
      } as any,
      this.defaultParentName,
    )
  }

  public getField(): TypedFromField<T> {
    const field = this.formBuilder.getField(this.fieldName)
    return field.field as TypedFromField<T>
  }

  public hasFieldTypeGroup(config?: TypeGroupConfig) {
    this.formBuilder.group(
      {
        name: this.fieldName,
        validators: config?.validators,
      },
      this.defaultParentName,
    )
    const typedGroupFormBuilderService = this.typedGroupFormBuilderFactoryService<T>()
    typedGroupFormBuilderService.setDependencys(this.formBuilder, this.fieldName)
    return typedGroupFormBuilderService
  }

  public hasFieldTypeArray(config?: TypeArrayConfig) {
    this.formBuilder.array(
      {
        name: this.fieldName,
        validators: config?.validators,
      },
      this.defaultParentName,
    )
    const typedArrayFormBuilderService = this.typedArrayFormBuilderFactoryService<any>()
    typedArrayFormBuilderService.setDependencys(this.formBuilder, this.fieldName)
    return typedArrayFormBuilderService as any
  }

  public asFieldTypeArray(config?: TypeArrayConfig) {
    const typedArrayFormBuilderService = this.typedArrayFormBuilderFactoryService<any>()
    typedArrayFormBuilderService.setDependencys(this.formBuilder, this.fieldName)
    return typedArrayFormBuilderService
  }

  public getFieldName(): string {
    return this.fieldName
  }
}

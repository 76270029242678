import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  SubAccountsClosedColumnServiceFactory,
  SubAccountsActiveColumnServiceFactory,
  ActiveSubAccountsExportService,
  ClosedSubAccountsExportService,
  SubAccountsImportColumnServiceFactory,
  InviteSubAccountFormService,
  SubAccountsClosedGridable,
  SubAccountsActiveGridable,
  SubAccountsEnabledGridable,
}

export type SubAccountsServices = keyof typeof SERVICES

export const SUB_ACCOUNTS_SERVICE_TYPES: Record<SubAccountsServices, symbol> = makeServicesTypes<SubAccountsServices>(
  enumKeysToArray<SubAccountsServices>(SERVICES),
)

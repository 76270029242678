import type { StringRange } from '@/services/tables/filters/types'
import { APP_DEFAULT_LOCALE, type SupportedLocales } from '@/services/translateService.types'
import type { Currency } from '@/types'

export { default as _getCurrencySymbol } from 'currency-symbol-map'

export const castCurrencyRange = (range: StringRange): StringRange =>
  range
    .map((item) => Math.abs(Number(item)))
    .map((number) => (Number.isNaN(number) ? 0 : number))
    .map((r) => r.toString()) as StringRange

export const inflectCurrencyRange = (range: StringRange): StringRange => {
  // eslint-disable-next-line prefer-const
  let [rN0, rN1] = castCurrencyRange(range).map((r) => Number(r))
  if (rN0 > rN1) {
    rN1 = rN0
  }
  return [rN0, rN1].map((r) => r.toString()) as StringRange
}

// if number is more than 1 use 2 digits after comma
// if number is less then 1 use 3 digits after comma
const decimalPlaces = (x: number): number => (x >= 1 ? 2 : 3)

/**
 * convert value of amount to string according to our rules
 * if the "currency" argument is present, the resulting string will be with a currency symbol
 * if number is less then 1 use 3 digits after comma, but not for values with 0 at the end - for these values - 2 digits
 * @param value amount
 * @param currency currency code ('EUR', 'USD')
 * @param locale name of locale ('en-US', 'de-DE')
 * @param dp - minimumFractionDigits / maximumFractionDigits
 * @returns
 */
export const formatMoney = (
  value: number,
  currency?: string,
  locale: SupportedLocales = APP_DEFAULT_LOCALE,
  dp?: number,
) => {
  const fractionDigits = Number.isInteger(dp) ? (dp as number) : decimalPlaces(value)
  const maximumFractionDigits = prepareFractionDigits(fractionDigits)

  const config: Intl.NumberFormatOptions = {
    style: currency ? 'currency' : 'decimal',
    currency,
    maximumFractionDigits,
  }
  try {
    return new Intl.NumberFormat(locale, config).format(value)
  } catch (e) {
    if (e instanceof RangeError) {
      return new Intl.NumberFormat(locale, {
        ...config,
        /**
         * for Firefox Version 78(without minimumFractionDigits throw RangeError)
         * {@link https://textmagic-pm.sentry.io/issues/4849191972/?project=4505946402717696&referrer=jira_integration}
         */
        minimumFractionDigits: 0,
      }).format(value)
    }
    throw e
  }
}

const prepareFractionDigits = (fractionDigits: number) => {
  const maxFractionDigits = 20 // For Safari
  return Math.max(Math.min(fractionDigits, maxFractionDigits), 0)
}

export const getCurrencySymbol = (currency: Currency, locale: SupportedLocales = APP_DEFAULT_LOCALE) => {
  // Create a NumberFormat instance for the given locale and currency
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })

  // Format a number to extract the currency symbol
  const formatted = formatter.format(0)

  // Extract the currency symbol (remove the number from the formatted string)
  const symbol = formatted.replace(/\d|\s|\.|,/g, '').trim()

  return symbol
}

export const isValidMonetaryPartialInput = (value: string): boolean => {
  if (value === '') {
    return true
  }

  if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
    return true
  }

  const groups = value.split(',')
  if (groups.length > 4) {
    return false
  }

  const lastGroup = groups[groups.length - 1]
  const firstGroup = groups[0]
  const allButFirstGroups = groups.slice(1)

  // if last group is empty, it means that user is typing a comma
  if (lastGroup === '') {
    // when entered only comma
    if (allButFirstGroups.length === 0) {
      return false
    }

    // check if previous is valid
    const allButLast = groups.slice(0, -1)
    // first group allowed to be "1," "12,", "122,"
    const isAllButLastValid = allButLast.every((group, idx) =>
      idx === 0 ? /^\d{1,3}$/.test(group) : /^\d{3}$/.test(group),
    )
    if (!isAllButLastValid) {
      return false
    }
  }

  if (groups.length === 1) {
    const isSingleGroupValid = /^\d{1,3}$/.test(firstGroup)
    if (!isSingleGroupValid) {
      return false
    }
  }

  // all but first groups should have 3 digits
  // cases like 123,123, | 12,123,123 | 12,123,123. | etc
  const areAllButFirstGroupsValid = allButFirstGroups.every((group, idx) =>
    idx === allButFirstGroups.length - 1
      ? /^\d{3}(\.\d{0,2})?$/.test(group) || /^\d{1,3}?$/.test(group) || group === ''
      : /^\d{3}$/.test(group),
  )

  if (!areAllButFirstGroupsValid) {
    return false
  }

  return true
}

export const convertMoneyToCent = (amount: number) => amount * 100

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  // Domain services
  ByocCarrierPhoneService,
  ByocCarrierSenderIdService,
  ByocCarrierService,
  ByocProviderPhoneService,
  ByocProviderSenderIdService,

  // Repositories
  ByocCarrierPhoneRepository,
  ByocCarrierRepository,
  ByocCarrierSenderIdRepository,
  ByocProviderPhoneRepository,
  ByocProviderSenderIdRepository,

  // Resolvers
  ByocCarrierResolver,
  ByocResolverService,
  ByocPhonesResolver,
  ByocSenderIdsResolver,
  ByocCarrierLinkModalResolver,

  // Forms
  ByocPhoneLabelFormService,
  ByocCarrierLabelFormService,
  ByocCredentialsFormService,
  ByocCredentialsSinchFormService,
  ByocFlowCommonCredentialsFormService,
  ByocFlowTwilioCredentialsFormService,
  ByocFlowVonageCredentialsFormService,
  ByocFlowSinchCredentialsFormService,
  ByocFlowBandwidthCredentialsFormService,
  ByocCallForwardingFormService,

  // Tables - columns
  ByocProvidersColumnService,
  ByocPhonesColumnService,
  ByocSenderIdsColumnService,
  ByocPhonesColumnHelperService,
  ByocFlowPhonesColumnService,
  ByocFlowSenderIdsColumnService,
  ByocDetailsPhonesColumnService,
  ByocDetailsSenderIdsColumnService,
  ByocCarrierLinkModalPhonesColumnService,
  ByocCarrierLinkModalSenderIdsColumnService,

  // Tables - grids
  ByocDetailsPhonesGridable,
  ByocDetailsSenderIdsGridable,
  ByocFlowPhonesGridable,
  ByocFlowSenderIdsGridable,
  ByocCarrierLinkModalPhonesGridable,
  ByocCarrierLinkModalSenderIdsGridable,
  ByocPhonesGridable,
  ByocSenderIdsGridable,
}

export type ByocServices = keyof typeof SERVICES

export const BYOC_SERVICE_TYPES: Record<ByocServices, symbol> = makeServicesTypes<ByocServices>(
  enumKeysToArray<ByocServices>(SERVICES),
)

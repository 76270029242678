import { OrmModel, StringField, NumberField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import { getTimezoneOffset } from '@/utils/timezoneOffset'

@OrmModel(modelNames.TIMEZONE)
export default class Timezone extends BaseModel {
  constructor(record: Timezone) {
    super({
      ...record,
      offset: getTimezoneOffset(record.timezone),
    })
  }

  @StringField()
  public id: string

  @StringField()
  public timezone: string

  @StringField()
  public offset: string

  @NumberField(0)
  public dst: number

  @StringField()
  public area: string
}

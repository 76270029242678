import { OrmModel } from 'vuex-orm-decorators'
import type { FormFieldInterface } from '@/services/forms/types'
import BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import { modelNames } from '@/data/models/types'

@OrmModel(modelNames.FIELD, modelNames.BASE_FIELD, {
  BASE_FIELD: BaseFieldModel,
  FIELD: Field,
})
export default class Field extends BaseFieldModel implements FormFieldInterface {
  public id: string
}

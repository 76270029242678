import { isNumber, isString } from 'lodash-es'
import { where } from '@/utils/object/where'
import type { Dict, Nullable } from '@/types'
import type { TmCountryCode } from '@/services/types'

export type ContactShortDTO = {
  id: string
  firstName: string
  lastName: string
}

export type SmsSurveyDetailsContactRecipient = SmsSurveyDetailsRawRecipient & {
  contactId: number
  name: string
}

export type SmsSurveyDetailsRawRecipient = {
  phone: string
}

export type SmsSurveyDetailsListRecipient = {
  groupId: number
  name: string
  count: number
}

export type SmsSurveyDetailsRecipient =
  | SmsSurveyDetailsListRecipient
  | SmsSurveyDetailsContactRecipient
  | SmsSurveyDetailsRawRecipient

export enum SmsSurveyNodeType {
  ANSWER = 'a',
  QUESTION = 'q',
  FINAL_MESSAGE = 'f',
}

export const isContactRecipient = (
  recipient: SmsSurveyDetailsRecipient,
): recipient is SmsSurveyDetailsContactRecipient =>
  isRawRecipient(recipient) &&
  where({
    contactId: isNumber,
    name: isString,
  })(recipient)

export const isListRecipient = (recipient: SmsSurveyDetailsRecipient): recipient is SmsSurveyDetailsListRecipient =>
  where({
    groupId: isNumber,
    name: isString,
    count: isNumber,
  })(recipient)

export const isRawRecipient = (recipient: SmsSurveyDetailsRecipient): recipient is SmsSurveyDetailsRawRecipient =>
  where({
    phone: (input) => typeof input === 'string',
  })(recipient)

export type SmsSurveyDetailsStatistics = {
  from: Array<{
    countryId: TmCountryCode
    phone: string
  }>
  repliesStat: Dict<SmsSurveyDetailsStatisticsReply>
  initialRecipientsStat: {
    recipientsCount: number
    messageOutId: number
    sessionId: Nullable<number>
  }
  deliveredCount: number
  currentCost: number
}

export type SmsSurveyDetailsStatisticsReply = {
  id: string
  label: string
  body: string
  repliesCount: number
}

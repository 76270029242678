import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type LoggerService from '@/services/loggerService'
import type { GrouperInterface } from '@/core/tables/types'
import type { Factory } from '@/types'
import type BaseGrouperServiceFactory from '@/services/tables/baseGrouperServiceFactory'
import type TableGrouperManagerInterface from '@/services/tables/managers/tableGrouperManagerInterface'
import type GroupingRepository from '@/data/repositories/table/groupingRepository'
import type { LoggerChannels } from '@/config/configDev'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import { TmTableElementManagerFactoryNotFoundError } from '@/core/error/table/tableManager/tmTableElementManagerFactoryNotFoundError'
import TableElementManagerWithEndpoint from '@/services/tables/managers/tableElementManagerWithEndpoint'
import Grouping from '@/data/models/tables/Grouping'

@injectable()
export default class TableGrouperManager
  extends TableElementManagerWithEndpoint<GrouperInterface>
  implements TableGrouperManagerInterface
{
  protected readonly managerId = 'TableGrouperManager'

  protected readonly loggerChannel: LoggerChannels = 'grouping'

  private constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.BaseGrouperServiceFactory)
    protected readonly baseGrouperServiceFactory: Factory<BaseGrouperServiceFactory>,
  ) {
    super(em, loggerService)
  }

  public getFactoryForTable(tableId: string): Factory<GrouperInterface> {
    try {
      return super.getFactoryForTable(tableId)
    } catch (e) {
      if (e instanceof TmTableElementManagerFactoryNotFoundError) {
        this.log(`No explicitly set factory found for the "${tableId}" table. Falling back to default one.`)
        return this.baseGrouperServiceFactory
      }

      throw e
    }
  }

  public addServiceForTable(tableId: string) {
    const { serviceId } = super.addServiceForTable(tableId)

    this.services[serviceId].setTableId(tableId)
    this.services[serviceId].setEndpoint(this.getEndpointForTable(tableId))

    return {
      serviceId,
      instance: this.services[serviceId],
    }
  }

  protected removeServiceById(tableId: string, serviceId: string) {
    const instance = this.getServiceByServiceId(serviceId)
    this.getGroupingRepo().deleteByCondition((model) => model.tableModelId === instance.getTableId())

    super.removeServiceById(tableId, serviceId)
  }

  protected getGroupingRepo() {
    return this.em.getRepository<GroupingRepository>(Grouping)
  }
}

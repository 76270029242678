import type { InternalErrorResponse, TmAxiosResponse } from '@/services/transport/types'
import { TmApiClientError } from '@/core/error/transport/tmApiClientError'
import type { TmTransportHttpErrorCode } from '@/core/error/transport/types'

export class TmApiRetryAfterError extends TmApiClientError {
  public name = 'TmApiRetryAfterError'

  protected override code: TmTransportHttpErrorCode = 429

  public retryValue = 0

  constructor(msg?: string, data?: TmAxiosResponse<InternalErrorResponse>, stack?: string) {
    super(msg, data, stack)
    if (data && data?.headers['retry-after']) {
      this.retryValue = data.headers['retry-after']
    }
  }
}

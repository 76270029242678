import type { MiddlewareInterface } from '@/core/middlewares/middlewareInterface'
import { type GetLocator, getGroupedServices } from '@/core/container/container'
import type { ServiceGroups } from '@/config/types'
import { ServiceManagerMap, type IServiceManager } from '@/core/middlewares/types'

export default class ServiceManagerMiddleware implements MiddlewareInterface {
  constructor(private readonly serviceGroup: ServiceGroups) {}

  public make(get: GetLocator): void {
    const manager = get<IServiceManager>(ServiceManagerMap[this.serviceGroup])
    getGroupedServices(this.serviceGroup).forEach((service) => {
      manager.addService(service, this.serviceGroup)
    })
  }
}

import { injectable } from 'inversify'
import { isEqual } from 'lodash-es'
import type { FilterStrategyInterface } from '@/services/tables/filters/strategies/types/filterStrategyInterface'
import type BaseFilterModel from '@/data/models/filters/BaseFilterModel'
import type { FilterOperationValue } from '@/services/types'
import type { PaginationUrlFilterScalarValueType, ParsedFilterType } from '@/services/tables/types'
import type { FilterStrategyGetPopulateDataResult } from '@/services/tables/filters/strategies/types/filterStrategyGetPopulateDataResult'
import type { ToFilterQueryPart } from '@/services/tables/filters/types'
import { isBoolFilterOperation } from '@/services/types'
import { coersceBoolean } from '@/utils/boolean/coersceBoolean'

@injectable()
export class BaseFilterStrategy implements FilterStrategyInterface {
  public toQueryPart(filter: BaseFilterModel): Partial<FilterOperationValue> {
    return this.fieldDataToQueryPart({
      operation: filter.getCurrentOperation(),
      relationField: filter.getRelationField(),
      value: filter.getApiValue(),
    })
  }

  public getPopulateData(filter: BaseFilterModel, payload: ParsedFilterType[]): FilterStrategyGetPopulateDataResult {
    const filteredPayload = payload.filter((item) => item.name === filter.getName())

    const appliedPayload =
      filteredPayload.find((item) => {
        if (!!item.value && Array.isArray(item.value)) {
          return item.value.length !== 0
        }

        if (isBoolFilterOperation(item.operation)) {
          return typeof item.value === 'boolean'
        }

        return !!item.value
      }) ?? filteredPayload[0]

    if (!appliedPayload) {
      return null
    }

    return {
      isApplied: true,
      operation: appliedPayload.operation,
      innerOperation: appliedPayload.operation,
      value: appliedPayload.value,
      innerValue: appliedPayload.value,
      relatedField: appliedPayload.relatedField ?? '',
    }
  }

  public fieldDataToQueryPart(data: ToFilterQueryPart): PaginationUrlFilterScalarValueType {
    const value = isBoolFilterOperation(data.operation) ? coersceBoolean(data.value, '1', '0') : data.value

    if (data.relationField) {
      return { [data.relationField]: { [data.operation]: value } }
    }

    return { [data.operation]: value }
  }

  public checkFilterValue(filter: BaseFilterModel, valueToCheck: unknown): boolean {
    const value = filter.getApiValue()
    if (filter.operation === 'isEmpty') {
      return !valueToCheck && value === valueToCheck
    }
    if (filter.operation === 'isNull') {
      return valueToCheck === null && value === valueToCheck
    }
    if (filter.operation === 'in') {
      if (Array.isArray(value) && !Array.isArray(valueToCheck)) {
        return value.some((item) => isEqual(item, valueToCheck))
      }
      if (Array.isArray(value) && Array.isArray(valueToCheck)) {
        return value.some((item) => valueToCheck.some((checkItem) => isEqual(item, checkItem)))
      }
      return isEqual(value, valueToCheck)
    }
    if (filter.operation === 'eq') {
      return isEqual(value, valueToCheck)
    }
    if (filter.operation === 'lt') {
      return (valueToCheck as any) < (value as any)
    }
    if (filter.operation === 'lte') {
      return (valueToCheck as any) <= (value as any)
    }
    if (filter.operation === 'gt') {
      return (valueToCheck as any) > (value as any)
    }
    if (filter.operation === 'gte') {
      return (valueToCheck as any) >= (value as any)
    }
    return false
  }
}

import type { TmNamedRouteRecord } from '@/services/route/types'
import { campaignsSmsRoute } from '@/routes/user/campaigns/campaignsSms'
import { campaignsEmailDetailsRoute, campaignsEmailRoute } from '@/routes/user/campaigns/campaignsEmail'

export const campaignsAllTabsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.all.tabs',
  path: '',
  component: () => import('@/components/views/campaigns/all/CampaignsAll.vue'),
  children: [campaignsSmsRoute, campaignsEmailRoute],
  redirect: { name: campaignsSmsRoute.name },
  meta: {
    tabTitle: 'pageTabTitle.user.campaigns.all.tabs',
  },
}

export const campaignsAllRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.all',
  path: 'all',
  children: [campaignsAllTabsRoute, campaignsEmailDetailsRoute],
  redirect: { name: campaignsAllTabsRoute.name },
}

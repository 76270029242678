import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  FacebookConfigRepository,
  FacebookConfigService,

  FacebookPageRepository,
  FacebookPageService,

  FacebookColumnService,
}

export type FacebookServices = keyof typeof SERVICES

export const FACEBOOK_SERVICE_TYPES: Record<FacebookServices, symbol> = makeServicesTypes<FacebookServices>(
  enumKeysToArray<FacebookServices>(SERVICES),
)

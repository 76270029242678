import { inject, injectable } from 'inversify'
import type BaseFormService from '@/services/forms/baseFormService'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import { TmFormError } from '@/core/error/tmFormError'
import { type RegisteredServices, SERVICE_TYPES } from '@/core/container/types'
import type { GetLocator } from '@/core/container/container'
import type { Service } from '@/config/types'
import type { Forms } from '@/services/forms/formTypes'
import type { IServiceManager } from '@/core/middlewares/types'
import type FormFactoryManager from '@/services/forms/baseForm/formFactoryManager'

@injectable()
export default class FormManager implements IServiceManager {
  protected formServices: Set<RegisteredServices> = new Set()

  constructor(
    @inject('GetLocator') private readonly get: GetLocator,
    @inject(SERVICE_TYPES.FormFactoryManager) protected readonly formFactoryManager: FormFactoryManager,
  ) {}

  public addService(service: Service) {
    this.formServices.add(service.id as RegisteredServices)
  }

  public getForm<T extends BaseFormService<FormBuilderInterface>>(formId: Forms): T {
    if (this.formFactoryManager.hasForm(formId)) {
      return this.formFactoryManager.getForm(formId) as T
    }

    if (!this.formServices.has(formId)) {
      throw new TmFormError(`Not exist form ${formId}`)
    }
    return this.get(formId!) as T
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { PhoneWithCountryCode } from '@/services/forms/types'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import BaseFormService from '@/services/forms/baseFormService'
import type FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type LoggerService from '@/services/loggerService'
import type UserService from '@/services/domain/user/userService'
import type { ModelRaw, ParamsPartial } from '@/types'
import type { CallForwardingFormScheme, ForwardCallsTo } from '@/services/domain/callForwarding/types'
import { forwardCallsToVariants } from '@/services/domain/callForwarding/types'
import type PhoneService from '@/services/phoneService'
import type PhoneNumbersService from '@/services/domain/phoneNumbers/phoneNumbersService'
import type { ChangeForwardingBody } from '@/data/repositories/domain/phoneNumbersRepository'
import type PhoneNumber from '@/data/models/domain/PhoneNumber'
import { ForwardCallsToVariant } from '@/services/domain/phoneNumbers/types'
import type CallForwardingFormBuilderService from '@/services/forms/services/callForwardingFormBuilderService'

@injectable()
export default class BuyNumberCallForwardingFormService extends BaseFormService<
  FormBuilderService,
  ParamsPartial,
  CallForwardingFormScheme
> {
  constructor(
    @inject(SERVICE_TYPES.FormBuilderFactoryService) formBuilderFactoryService: () => FormBuilderService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.UserService) private readonly usersService: UserService,
    @inject(SERVICE_TYPES.PhoneService) private readonly phoneService: PhoneService,
    @inject(SERVICE_TYPES.PhoneNumbersService) private readonly phoneNumbersService: PhoneNumbersService,
    @inject(SERVICE_TYPES.CallForwardingFormBuilderService)
    private readonly callForwardingFormBuilderService: CallForwardingFormBuilderService,
  ) {
    super(formBuilderFactoryService, em, validationRulesBuilderService, logger)
  }

  private phoneNumberId: string | null = null

  public setPhoneNumberId(phoneNumberId: string) {
    this.phoneNumberId = phoneNumberId
  }

  public async buildForm() {
    const typedBuilder = this.getTypedBuilder()
    const currentUser = this.usersService.currentUser()

    if (!this.phoneNumberId) {
      return
    }

    const numberInfo = this.phoneNumbersService.findEntityByIdOrNull(this.phoneNumberId)

    if (!numberInfo) {
      return
    }

    const forwardCallsTo = this.getForwardCallsTo(numberInfo)
    let forwardCallsToYourNumber: PhoneWithCountryCode = this.phoneService.getPhoneWithCountryCodeByCountryCode(
      currentUser.countryId,
    )
    if (forwardCallsTo === 'phone') {
      forwardCallsToYourNumber = {
        phone: this.phoneService.getPhoneWithLeadingPlus(numberInfo.forwardCallsTo),
        countryCode: this.phoneService.getCountryByPhone(numberInfo.forwardCallsTo)!,
      }
    }

    const formData: CallForwardingFormScheme = {
      forwardCallsTo,
      forwardCallsToYourNumber,
      greeting: numberInfo.forwardAudioId ?? '',
    }

    this.callForwardingFormBuilderService.buildForm(typedBuilder, formData, [...forwardCallsToVariants])
  }

  public getForwardCallsTo(numberInfo: ModelRaw<PhoneNumber>): ForwardCallsTo {
    if (this.phoneService.isValidPhoneNumber(numberInfo.forwardCallsTo)) {
      return 'phone'
    }
    if (numberInfo.forwardCallsTo === ForwardCallsToVariant.smsChat) {
      return 'chat'
    }
    return 'off'
  }

  public async submit(formData: CallForwardingFormScheme) {
    const body: ChangeForwardingBody = {
      numberId: this.phoneNumberId!,
      forwardingDirection: formData.forwardCallsTo,
    }
    if (formData.forwardCallsTo === 'phone') {
      body.forwardingNumber = formData.forwardCallsToYourNumber.phone
    }
    if (formData.greeting) {
      body.audioId = formData.greeting
    }
    return this.phoneNumbersService.changeForwardingNumber(body)
  }
}

import type { SchemaValueType } from '@/services/types'
import { makeSchema } from '@/services/resolvers/domain/filterSchema/schemaGenerator'
import { dateFilterSchemaFilters } from '@/components/shared/tables/topFilter/dateFilter/types'
import { EndpointsEnum } from '@/services/endpoints'
import { reportingCallsSchemaFilters } from '@/services/domain/reporting/calls/types'
import { reportingMessagesSchemaFilters } from '@/services/domain/reporting/messages/types'
import { smsSurveyRepliesTableSchemaFilters } from '@/services/domain/services/smsSurveys/details/replies/types'
import { smsSurveyRecipientsCountriesSchemaFilters } from '@/services/domain/services/smsSurveys/flow/recipients/types'

export const filterSchema: SchemaValueType[] = [
  makeSchema({
    name: EndpointsEnum.notes,
  }),
  makeSchema({
    name: EndpointsEnum.sentSmsShort,
    sort: ['messageTime'],
    filter: [
      {
        name: 'onlyWithoutInbound',
        constraints: {
          type: 'choice',
          value: ['false', 'true'],
        },
      },
      'userId',
      ...dateFilterSchemaFilters,
    ],
  }),
  makeSchema({
    name: EndpointsEnum.emailCampaignsFetch,
    sort: ['startAt'],
  }),
  makeSchema({
    name: EndpointsEnum.bulkSentSms,
    sort: ['to', 'text', 'status', 'messageTime'],
    filter: [
      {
        name: 'onlyWithoutInbound',
        constraints: {
          type: 'choice',
          value: ['false', 'true'],
        },
      },
      {
        name: 'statuses',
        constraints: {
          type: 'choice',
          value: ['d', 'j'],
        },
      },
    ],
  }),
  makeSchema({
    name: EndpointsEnum.blockedContacts,
    sort: ['fullName', 'dateBlocked'],
  }),
  makeSchema({
    name: EndpointsEnum.contacts,
    sort: [
      'id',
      'email',
      'fullName',
      'firstName',
      'lastName',
      'companyName',
      'creationDate',
      'phone',
      'whatsappPhone',
      'country',
      'owner',
    ],
    filter: [
      'shared',
      {
        name: 'letter',
        constraints: {
          type: 'length',
        },
      },
    ],
  }),
  makeSchema({
    name: EndpointsEnum.unsubscribedContacts,
    sort: ['fullName', 'phone', 'reason', 'unsubscribeText', 'unsubscribeTime'],
  }),
  makeSchema({
    name: EndpointsEnum.unsubscribedEmailContacts,
    sort: ['fullName', 'email', 'reasonType', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.contactImports,
    sort: ['fileName', 'countImported', 'status', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.contactImportResults,
    sort: ['firstName', 'lastName', 'phone', 'email'],
  }),
  makeSchema({
    name: EndpointsEnum.lists,
    sort: ['name', 'membersCount', 'shared', 'createdAt', 'lastUpdatedAt', 'isHidden'],
  }),
  makeSchema({
    name: EndpointsEnum.receivedSms,
    sort: ['messageTime'],
    filter: [
      {
        name: 'onlyWithoutOutbound',
        constraints: {
          type: 'choice',
          value: ['false', 'true'],
        },
      },
      'userId',
      ...dateFilterSchemaFilters,
    ],
  }),
  makeSchema({
    name: EndpointsEnum.templates,
    sort: ['name', { name: 'category', fields: ['name'] }, 'content', 'emailContent', 'lastModified'],
    filter: [
      {
        name: 'category',
        constraints: {
          type: 'length',
        },
      },
    ],
  }),
  makeSchema({
    name: EndpointsEnum.surveys,
    sort: ['name', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.inboundCalls,
    sort: ['fromNumber', 'toNumber', 'length', 'callTime'],
    filter: dateFilterSchemaFilters,
  }),
  makeSchema({
    name: EndpointsEnum.outboundCalls,
    sort: ['fromNumber', 'toNumber', 'length', 'callTime'],
    filter: dateFilterSchemaFilters,
  }),
  makeSchema({
    name: EndpointsEnum.forwardedCalls,
    sort: ['fromNumber', 'usersInboundNumber', 'toNumber', 'length', 'callTime'],
    filter: dateFilterSchemaFilters,
  }),
  makeSchema({
    name: EndpointsEnum.audios,
    sort: ['originalName', 'length', 'fileSize', 'uploadedAt'],
  }),
  makeSchema({
    name: EndpointsEnum.schedulesFetchLight,
    sort: ['nextSend', 'lastSent'],
  }),
  makeSchema({
    name: EndpointsEnum.scheduledFetchNotSent,
    sort: ['failedAt'],
  }),
  makeSchema({
    name: EndpointsEnum.userCustomFields,
    sort: ['fieldName', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.customFieldSchemaAll,
    sort: ['id', 'title', 'createdAt', 'updatedAt'],
  }),
  makeSchema({
    name: EndpointsEnum.phoneNumbers,
    sort: ['phone', 'label', 'user', 'price', 'expireAt', 'forwardCallsTo'],
  }),
  makeSchema({
    name: EndpointsEnum.contactListDetails,
    sort: ['fullName', 'firstName', 'lastName', 'phone', 'whatsappPhone', 'companyName', 'country', 'owner'],
  }),
  makeSchema({
    name: EndpointsEnum.invoices,
    sort: ['id', 'amount', 'paymentMethod', 'date', 'status'],
  }),
  makeSchema({
    name: EndpointsEnum.senderIdsFetch,
    sort: [{ name: 'user', fields: ['firstName'] }, 'senderId', 'status', 'explanation'],
  }),
  makeSchema({
    name: EndpointsEnum.activityCalls,
    sort: ['id', 'callTime'],
  }),
  makeSchema({
    name: EndpointsEnum.activitySchedules,
    sort: ['id', 'lastSent', 'nextSend', 'createdAt', 'syncState', 'lastSync'],
  }),
  makeSchema({
    name: EndpointsEnum.messengerContactThread,
    sort: ['id', 'messageTime'],
  }),
  makeSchema({
    name: EndpointsEnum.allowedEmails,
    sort: ['email', 'dateAdded', 'dateActive'],
  }),
  makeSchema({
    name: EndpointsEnum.subAccountImportSessionsFetch,
    sort: ['fileName', 'countImported', 'status', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.subAccountImportDetails,
    sort: ['firstName', 'email', 'role'],
  }),
  makeSchema({
    name: EndpointsEnum.subaccounts,
    sort: ['name', 'email', 'subaccountType', 'status'],
  }),
  makeSchema({
    name: EndpointsEnum.auditLogs,
    filter: ['userId', 'userIds', ...dateFilterSchemaFilters],
  }),
  makeSchema({
    name: EndpointsEnum.senderSettingsCountries,
  }),
  makeSchema({
    name: EndpointsEnum.reportingNumbers,
    sort: ['phone', 'messagesSent', 'messagesReceived', 'callsForwarded'],
    filter: ['userId', ...dateFilterSchemaFilters],
  }),
  makeSchema({
    name: EndpointsEnum.statementsFetch,
    filter: ['userId', ...dateFilterSchemaFilters],
  }),
  makeSchema({
    name: EndpointsEnum.reportingSubAccounts,
    sort: ['fullName'],
    filter: dateFilterSchemaFilters,
  }),
  makeSchema({
    name: EndpointsEnum.reportingUsers,
    sort: ['fullName'],
  }),
  makeSchema({
    name: EndpointsEnum.distributions,
    sort: ['name', 'email', 'membersCount'],
  }),
  makeSchema({
    name: EndpointsEnum.loginHistory,
    sort: ['country', 'ip', 'active', 'loginTime'],
  }),
  makeSchema({
    name: EndpointsEnum.apiV2TokensFetch,
    sort: ['appName', 'apiKey'],
  }),
  makeSchema({
    name: EndpointsEnum.reportingCalls,
    filter: reportingCallsSchemaFilters,
  }),
  makeSchema({
    name: EndpointsEnum.reportingMessages,
    filter: reportingMessagesSchemaFilters,
  }),
  makeSchema({
    name: EndpointsEnum.automationRuleLogs,
    sort: ['sender', 'receiver', 'status', 'executedAt'],
    filter: [...dateFilterSchemaFilters],
  }),
  makeSchema({
    name: EndpointsEnum.emailLookupFetch,
    sort: ['email', 'isValid', 'deliverability', 'isDisposable', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.carrierLookupBulkFetch,
    sort: ['fileName', 'status', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.emailLookupBulkFetch,
    sort: ['fileName', 'fileSize', 'status', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.carrierLookupFetch,
    sort: ['phone', 'valid', 'type', 'country', 'carrier', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.formClickToTextFetch,
    sort: ['name', 'clicks', 'submits', 'phone'],
  }),
  makeSchema({
    name: EndpointsEnum.formSubscribeFetch,
    sort: ['name', 'submits'],
  }),
  makeSchema({
    name: EndpointsEnum.automationRules,
    sort: ['user', 'name', 'responseType', 'status'],
  }),
  makeSchema({
    name: EndpointsEnum.automationRulesKeywords,
    sort: ['keyword', 'status', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.tenDlcPhoneNumbers,
    sort: ['purchased'],
  }),
  makeSchema({
    name: EndpointsEnum.tollFreePhoneNumbers,
    sort: ['purchasedAt'],
  }),
  makeSchema({
    name: EndpointsEnum.tenDlcCampaign,
    sort: ['id'],
  }),
  makeSchema({
    name: EndpointsEnum.surveyReplies,
    filter: smsSurveyRepliesTableSchemaFilters,
  }),
  makeSchema({
    name: EndpointsEnum.surveyRecipientCountries,
    filter: smsSurveyRecipientsCountriesSchemaFilters,
  }),
  makeSchema({
    name: EndpointsEnum.byocCarrierFetch,
    sort: ['id', 'label', 'provider', 'phonesCount', 'senderIdsCount', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.byocCarrierPhoneFetch,
    sort: ['phone', 'label', 'voice', 'sms', 'mms', 'provider'],
  }),
  makeSchema({
    name: EndpointsEnum.byocCarrierSenderIdFetch,
    sort: ['senderId', 'provider'],
  }),
  makeSchema({
    name: EndpointsEnum.whatsappAccountFetch,
    sort: ['id', 'name', 'accountId', 'status'],
  }),
  makeSchema({
    name: EndpointsEnum.whatsappPhonesFetch,
    sort: ['id', 'name', 'phone', 'qualityRating', 'status', 'messagingLimitTier'],
  }),
  makeSchema({
    name: EndpointsEnum.byocProviderPhoneIndex,
    sort: [],
  }),
  makeSchema({
    name: EndpointsEnum.byocProviderSenderIdIndex,
    sort: [],
  }),
  makeSchema({
    name: EndpointsEnum.userAdmins,
  }),
  makeSchema({
    name: EndpointsEnum.webWidgets,
    sort: ['name', 'lastUpdatedAt', 'status'],
  }),
  makeSchema({
    name: EndpointsEnum.tasksFetch,
    sort: [
      'id',
      'priority',
      'title',
      { name: 'stage', fields: ['name'] },
      { name: 'assignee', fields: ['fullName'] },
      'dueDate',
      'lastUpdated',
    ],
  }),
  makeSchema({
    name: EndpointsEnum.activityCalls,
    filter: [...dateFilterSchemaFilters],
  }),
  makeSchema({
    name: EndpointsEnum.messengerContactThread,
    filter: [...dateFilterSchemaFilters],
  }),
  makeSchema({
    name: EndpointsEnum.ticketsFetch,
    sort: [
      'id',
      'priority',
      'subject',
      'createdAt',
      'updatedAt',
      'dueDate',
      { name: 'status', fields: ['name'] },
      { name: 'requester', fields: ['email'] },
      { name: 'assignee', fields: ['fullName'] },
      { name: 'inbox', fields: ['email'] },
    ],
    filter: [
      'inbox',
      'status',
      'assignee',
      'requester',
      'priority',
      'createdAt',
      'updatedAt',
      'solvedAt',
      'subject',
      'dueDate',
    ],
  }),
  makeSchema({
    name: EndpointsEnum.filteredViewsFetch,
    sort: ['name', 'ticketsCount', 'sharingType', 'updatedAt'],
  }),
  makeSchema({
    name: EndpointsEnum.deals,
    sort: [],
  }),
  makeSchema({
    name: EndpointsEnum.dealsFetch,
    sort: ['createdAt', 'title', 'amount'],
  }),
  makeSchema({
    name: EndpointsEnum.pointAiWebsite,
    sort: ['url', 'synchronizedAt'],
  }),
  makeSchema({
    name: EndpointsEnum.files,
    sort: ['originalName', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.dealTimelineItemFetch,
    sort: ['id'],
  }),
  makeSchema({
    name: EndpointsEnum.pointAiWebsiteDownloaded,
    sort: ['url', 'createdAt'],
  }),
  makeSchema({
    name: EndpointsEnum.filteredViewsCreate,
    sort: ['name', 'createdAt', 'updatedAt', 'type'],
  }),
  makeSchema({
    name: EndpointsEnum.smsCampaignsFetch,
    sort: [],
  }),
  makeSchema({
    name: EndpointsEnum.facebookMessengerPagesFetch,
    sort: ['id', 'name', 'platformPageId', 'status'],
  }),
  makeSchema({
    name: EndpointsEnum.instagramAccountsFetch,
    sort: ['id', 'name', 'platformAccountName', 'status'],
  }),
  makeSchema({
    name: EndpointsEnum.emailCampaignsMessages,
    sort: ['recipientFullName'],
  }),
  makeSchema({
    name: EndpointsEnum.emailCampaignsOpen,
    sort: ['createdAt', 'recipientFullName', 'recipientEmail'],
  }),
  makeSchema({
    name: EndpointsEnum.emailCampaignsUnsubscribe,
    sort: ['createdAt', 'recipientFullName', 'recipientEmail'],
  }),
  makeSchema({
    name: EndpointsEnum.emailCampaignsClickSummary,
    sort: ['link', 'clicks'],
  }),
  makeSchema({
    name: EndpointsEnum.emailCampaignsClick,
    sort: ['createdAt', 'recipientFullName', 'recipientEmail', 'link'],
  }),
]

<template>
  <tm-angle-wrap-tooltip
    :color="color"
    :offset="offset"
    :direction="direction"
    :viewport-width="viewportWidth"
    :viewport-height="viewportHeight"
    :anchor-bounding="anchorBounding"
    :tooltip-position="tooltipPosition"
  >
    <div
      v-if="html"
      v-safe-html="{ html: text, config: safeHtmlConfig }"
      class="pre-line"
      :class="`text-${textAlign}`"
    />
    <div
      v-else
      class="pre-line"
      :class="`text-${textAlign}`"
    >
      {{ text }}
    </div>
  </tm-angle-wrap-tooltip>
</template>

<script setup lang="ts">
import {
  type TmTextTooltipPropsType,
  tooltipDefaultColor,
  tooltipDefaultDirection,
} from '@/components/shared/tooltip/types'
import TmAngleWrapTooltip from '@/components/shared/tooltip/TmAngleWrapTooltip.vue'
import type { PurifierConfig } from '@/utils/string/types'

withDefaults(defineProps<TmTextTooltipPropsType>(), {
  direction: tooltipDefaultDirection,
  color: tooltipDefaultColor,
  text: '',
  textAlign: 'center',
  html: false,
})
const safeHtmlConfig: PurifierConfig = {
  ALLOWED_TAGS: ['a', 'b', 'br', 'em', 'i', 'p', 'strong', 'u'],
  ALLOWED_ATTR: ['href', 'target'],
}
</script>

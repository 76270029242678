import type { Ref } from '@/composition/vue/compositionApi'

export const isDev = () => import.meta.env.DEV
export const isProd = () => import.meta.env.PROD
export const isTest = () => import.meta.env.MODE === 'test'
export const isSentryOn = () => import.meta.env.VUE_APP_SENTRY_ENABLED === 'true'
export const showSandbox = () => import.meta.env.VUE_APP_SHOW_SANDBOX === 'true'
export const delay = (seconds?: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, seconds ? seconds * 1000 : seconds)
  })
export const countDownChanger = (ref: Ref<number>, from = 3): Promise<number> =>
  new Promise((resolve) => {
    ref.value = from
    const updater = () => {
      ref.value -= 1
      if (ref.value <= 0) {
        clearInterval(id)
        resolve(id)
      }
    }
    const id: number = setInterval(updater, 1000) as unknown as number
  })

export const asyncChecker = (cb: () => void | boolean, count = 3, interval = 50) =>
  new Promise((resolve) => {
    let counter = count
    const updater = () => {
      try {
        const result = cb()
        if (result) {
          clearInterval(id)
          resolve(result)
        }
      } catch {}
      counter -= 1
      if (counter === 0) {
        clearInterval(id)
        resolve(false)
      }
    }
    const id = setInterval(updater, interval)
  })

import { computed, onMounted, type ComputedRef } from '@/composition/vue/compositionApi'
import { getWrapperManagerService } from '@/core/container/ioc'
import type { BulkWrapperParams, WrapperParams, WrapperTypesServicesKeys } from '@/services/wrappers/types'
import type { UseWrapper } from '@/composition/types'
import type BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import type { TmWrappers } from '@/wrappers'
import { useAsyncFuncStatus } from '@/composition/useAsyncFuncStatus'

export const createWrapper = <T extends WrapperParams = WrapperParams>(
  id: string,
  wrapperType: WrapperTypesServicesKeys,
) => {
  const wrapperManagerService = getWrapperManagerService()
  wrapperManagerService.setWrapper(id, wrapperType)

  return useWrapper<T>(id, wrapperType)
}

export const useBulkWrapper = <T extends BulkWrapperParams>(id: TmWrappers): UseWrapper<T, BaseWrapper> => {
  const wrapper = useWrapper<T>(id)
  return {
    ...wrapper,
    open: (params?: T) => wrapper.open(params, 'modal'),
  }
}

export const useWrapper = <T extends WrapperParams = WrapperParams>(
  id: string,
  wrapperType?: WrapperTypesServicesKeys,
): UseWrapper<T, BaseWrapper> => {
  const wrapperManagerService = getWrapperManagerService()

  const open = (params?: T, type?: WrapperTypesServicesKeys) => wrapperManagerService.open(id, params, type)
  const close = () => wrapperManagerService.close(id)
  const build = () => wrapperManagerService.build(id, wrapperType)
  const destroy = () => wrapperManagerService.destroy(id)
  const getWrapperData = () => wrapperManagerService.getWrapperData(id)
  const getParams = () => wrapperManagerService.getParams<T>(id)
  const setParams = (params?: T) => wrapperManagerService.setParams(id, params)
  const patchParams = (params?: T) => wrapperManagerService.patchParams(id, params)
  const removeParams = (paramsToDelete: string[]) => wrapperManagerService.removeParams(id, paramsToDelete)
  const clearParams = () => wrapperManagerService.clearParams(id)

  const wrapperParams: ComputedRef<T> = computed(() => wrapperManagerService.getWrapperData(id).params as T)
  const wrapperData: ComputedRef = computed(() => getWrapperData())
  const isOpened = computed<boolean>(() => wrapperManagerService.isOpened(id))

  return {
    wrapperId: id as TmWrappers,
    wrapperParams,
    wrapperData,
    isOpened,
    open,
    close,
    build,
    destroy,
    getWrapperData,
    getParams,
    setParams,
    patchParams,
    removeParams,
    clearParams,
  }
}

export const useTypedWrapper = <T extends WrapperParams = WrapperParams>(modalId: TmWrappers) => {
  const open = (params: T) => useWrapper(modalId).open(params as WrapperParams)
  const close = () => useWrapper(modalId).close()

  const init = () => {
    const wrapper = useWrapper<T>(modalId)
    const params = wrapper.getParams()
    return {
      modalId,
      ...wrapper,
      ...params,
    }
  }

  return {
    open,
    close,
    init,
  }
}

export const useModalContentPreloader = (callback: () => Promise<unknown>) => {
  const { status, run } = useAsyncFuncStatus(callback)

  const retryHandler = () => run()

  onMounted(() => run())
  // @todo add cancel request on before destroy hook

  return {
    status,
    run,
    retryHandler,
  }
}

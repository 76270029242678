<template>
  <suspense-resolver
    :route-name="routeName"
    :resolvers="resolvers"
  >
    <template v-slot>
      <slot name="default" />
    </template>

    <template v-slot:preloader>
      <slot name="preloader" />
    </template>
  </suspense-resolver>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import SuspenseResolver from '@/components/resolvers/SuspenseResolver.vue'
import { getRouterService } from '@/core/container/ioc'
import type { Resolvers } from '@/services/types'

export default defineComponent({
  components: {
    SuspenseResolver,
  },
  setup() {
    const routerService = getRouterService()
    const currentRoute = routerService.getCurrentRoute()
    const routeName = currentRoute.name?.toString() || ''
    const resolvers: Resolvers = (currentRoute.meta?.suspenseResolvers as Resolvers) ?? []

    return {
      routeName,
      resolvers,
    }
  },
})
</script>

import { AttrField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.USER_METADATA)
export default class UserMetadata extends BaseModel {
  @StringField()
  public id: string

  @AttrField(null)
  public openAIConsent: boolean | null
}

import { AttrField, OrmModel, UidField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'

export const TICKET_SPAM_FILTER_ID = 'ticketSpamFilter'

@OrmModel(modelNames.TICKET_SPAM_FILTER)
export default class TicketSpamFilter extends BaseModel {
  @UidField(() => TICKET_SPAM_FILTER_ID)
  public id: string

  @AttrField([])
  public blacklistedEmailsAndDomains: string[]

  @AttrField([])
  public blacklistedPhrases: string[]

  @AttrField([])
  public whitelistedEmailsAndDomains: string[]
}

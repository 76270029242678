import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum EMAIL_CONFIRMATION_SERVICES {
  ConfirmEmailResolver,
}

export type EmailConfirmationServices = keyof typeof EMAIL_CONFIRMATION_SERVICES

export const EMAIL_CONFIRMATION_SERVICE_TYPES =
  makeServicesTypesFromEnum<EmailConfirmationServices>(EMAIL_CONFIRMATION_SERVICES)

<template>
  <div
    ref="root"
    class="tm-auto-size"
    :class="{ 'tm-auto-size--extra-offset': extraOffsetForFocus }"
  >
    <div
      ref="listRef"
      :class="[scrollClass, { 'tm-auto-size__scroll--full-width': fullWidth }]"
      class="tm-auto-size__scroll"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
  nextTick,
  computed,
  onUpdated,
  watch,
} from '@/composition/vue/compositionApi'
import { tmAutoSizeIgnoreClass, prepareData, tmAutoSizeHiddenClass } from '@/components/shared/autoSize/types'
import TmLogicError from '@/core/error/tmLogicError'

const useResizeObserver = (callback: ResizeObserverCallback) => {
  let resizeObserver: ResizeObserver | null = null

  const getResizeObserver = (): ResizeObserver => {
    if (resizeObserver === null) {
      resizeObserver = new ResizeObserver(callback)
    }
    return resizeObserver
  }

  const observe = (target: Element, options?: ResizeObserverOptions) => getResizeObserver().observe(target, options)

  onBeforeUnmount(() => {
    resizeObserver?.disconnect()
  })

  return {
    observe,
  }
}

export default defineComponent({
  props: {
    scrollClass: {
      type: String,
    },
    hiddenItems: {
      type: Number,
      default: 0,
    },
    visibleItems: {
      type: Number,
      default: 0,
    },
    maxVisibleItems: {
      type: Number,
      default: 0,
    },
    minVisibleItems: {
      type: Number,
      default: 0,
    },
    minHiddenItems: {
      type: Number,
      default: 0,
    },
    fullWidth: {
      type: Boolean,
    },
    extraOffsetForFocus: {
      type: Boolean,
    },
  },
  emits: {
    'update:hidden-items': (_val: number) => true,
    'update:visible-items': (_val: number) => true,
  },
  setup(props, context) {
    const root = ref<HTMLElement>()
    const listRef = ref<HTMLElement>()
    const children = ref()
    const mutableHiddenItems = ref(0)
    const ignoreHiddenItems = ref(0)
    const hiddenItemsExist = computed(() => mutableHiddenItems.value > 0)
    const visibleItemsCount = computed(() => children.value.length - mutableHiddenItems.value)

    const shouldHideOnInit = (): boolean => {
      if (props.maxVisibleItems) {
        return children.value.length - props.maxVisibleItems - ignoreHiddenItems.value > 0
      }
      return false
    }

    const initialItemsHiding = () => {
      if (shouldHideOnInit()) {
        // hide items if them more than maxVisibleItems
        // and ignoreHiddenItems because they will be visible anyway
        children.value
          .slice(0, children.value.length - props.maxVisibleItems + ignoreHiddenItems.value)
          .forEach((e: HTMLElement) => {
            e.classList.add(tmAutoSizeHiddenClass)
          })
      }
    }

    const updateHiddenItems = () => {
      const hiddenItemsCount = root.value?.querySelectorAll(`.${tmAutoSizeHiddenClass}`)?.length || 0

      const mutableHiddenItemsCount = hiddenItemsCount - ignoreHiddenItems.value
      if (mutableHiddenItemsCount === mutableHiddenItems.value) return

      mutableHiddenItems.value = mutableHiddenItemsCount
      context.emit('update:hidden-items', mutableHiddenItems.value)
      context.emit('update:visible-items', visibleItemsCount.value)
    }

    let itemsCount = 0
    onUpdated(() => {
      const prevItemsCount = itemsCount
      itemsCount = listRef.value?.children.length || 0
      if (itemsCount === prevItemsCount) return
      updateHiddenItems()
    })

    const cutVisibleItem = () => {
      if (!root.value || !listRef.value) {
        return
      }
      const extraOffset = props.extraOffsetForFocus ? 8 : 0
      const listWidth = listRef.value.scrollWidth + extraOffset
      const parentWidth = root.value.offsetWidth

      if (listWidth > parentWidth || (hiddenItemsExist.value && mutableHiddenItems.value < props.minHiddenItems)) {
        const itemToHide = children.value.find((e: HTMLElement) => !e.classList.contains(tmAutoSizeHiddenClass))
        if (itemToHide) {
          if (visibleItemsCount.value <= props.minVisibleItems) {
            return
          }
          itemToHide.classList.add(tmAutoSizeHiddenClass)
          updateHiddenItems()
          nextTick(() => cutVisibleItem())
        }
      }
    }

    const updateVisibleChildren = async () => {
      if (!listRef.value) {
        return
      }
      const childrens = [...listRef.value.children].reverse().map((t) => {
        if (t instanceof HTMLElement) {
          return t
        }
        throw new TmLogicError('children should be instanceof HTMLElement')
      })
      children.value = prepareData(childrens)
      ignoreHiddenItems.value = children.value.filter((el: HTMLElement) =>
        el.classList.contains(tmAutoSizeIgnoreClass),
      ).length
      children.value.forEach((e: HTMLElement) => {
        e.classList.remove(tmAutoSizeHiddenClass)
      })
      initialItemsHiding()
      updateHiddenItems()
      await nextTick(() => cutVisibleItem())
    }

    const { observe } = useResizeObserver(updateVisibleChildren)
    const addResizeObservers = () => {
      if (root.value instanceof Element) {
        observe(root.value)
      }
      if (listRef.value instanceof Element) {
        observe(listRef.value)
      }
    }

    onMounted(() => {
      addResizeObservers()
    })

    watch(
      () => [root.value, listRef.value],
      () => {
        addResizeObservers()
      },
      { immediate: true },
    )

    watch(
      () => props.maxVisibleItems,
      () => {
        updateVisibleChildren()
      },
    )

    return {
      root,
      listRef,
      children,
      // public
      updateVisibleChildren,
    }
  },
})
</script>

<style scoped lang="scss">
// tmAutoSizeIgnoreClass === .ignore-hidden
// tmAutoSizeVisibleClass === always visible, but not ignored, uses for view change
.tm-auto-size {
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 100%;

  &--extra-offset {
    width: calc(100% + 8px);
    max-width: calc(100% + 8px);
    margin: -4px;
    padding: 4px;
    & > .tm-auto-size__scroll {
      max-width: calc(100% + 8px);
    }
  }

  &__scroll {
    flex-shrink: 0;
    display: flex;
    white-space: nowrap;
    max-width: 100%;
    &--full-width {
      width: 100%;
    }
  }

  :deep(.hidden-item:not(.ignore-hidden):not(.visible-item)) {
    display: none !important;
  }
}
</style>

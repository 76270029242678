import { AttrField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { MessagesPriceCountries, MessagesPriceSpinners } from '@/services/domain/messages/types'
import type { ModelRaw } from '@/types'

@OrmModel(modelNames.MESSAGES_PRICE)
export default class MessagesPrice extends BaseModel {
  @StringField()
  public id: string

  @AttrField([])
  public loading: MessagesPriceSpinners[]

  @NumberField(0)
  public total: number

  @AttrField(null)
  public countries: MessagesPriceCountries

  public get recipientsCount(): number {
    return MessagesPrice.getRecipientsCount(this)
  }

  public get canadianRecipientsCount(): number {
    return MessagesPrice.getCanadianRecipientsCount(this)
  }

  public get americanRecipientsCount(): number {
    return MessagesPrice.getAmericanRecipientsCount(this)
  }

  public static getRecipientsCount(messagesPrice: { countries: MessagesPriceCountries }): number {
    if (!messagesPrice.countries) return 0
    return Object.keys(messagesPrice.countries).reduce((acc, country) => {
      acc += messagesPrice.countries![country].count
      return acc
    }, 0)
  }

  public static getCanadianRecipientsCount(messagesPrice: MessagesPrice | ModelRaw<MessagesPrice>): number {
    return messagesPrice.countries?.CA?.count || 0
  }

  public static getAmericanRecipientsCount(messagesPrice: MessagesPrice | ModelRaw<MessagesPrice>): number {
    return messagesPrice.countries?.US?.count || 0
  }
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import Autocomplete from '@/data/models/Autocomplete'

@RepoSettings<Endpoint>({
  model: Autocomplete,
})
@injectable()
export default class AutocompleteRepository extends OrmApiRepository<Autocomplete> {}

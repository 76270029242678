import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  MessageSendScheduleService,
  MessageSendFormSchemeService,
  MessagesSendFormService,
  MessagesSendEditFormService,

  ComposeCampaignToFormService,
  ComposeCampaignFromFormService,
  ComposeCampaignMessageFormService,
  ComposeCampaignSendTimeFormService,
}

export type MessageSendServices = keyof typeof SERVICES

export const MESSAGE_SEND_TYPES: Record<MessageSendServices, symbol> = makeServicesTypes<MessageSendServices>(
  enumKeysToArray<MessageSendServices>(SERVICES),
)

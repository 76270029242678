export enum ScheduledRoutes {
  scheduled = 'user.scheduled',
  scheduledIndex = 'user.scheduled.index',
  details = 'user.scheduled.details',
  upcoming = 'user.scheduled.upcoming',
  upcomingOverview = 'user.scheduled.upcoming.overview',
  paused = 'user.scheduled.paused',
  pausedOverview = 'user.scheduled.paused.overview',
  notSent = 'user.scheduled.notSent',
  notSentOverview = 'user.scheduled.notSent.overview',
  completed = 'user.scheduled.completed',
  completedOverview = 'user.scheduled.completed.overview',
  calendar = 'user.scheduled.calendar',
  edit = 'user.scheduled.edit',
}

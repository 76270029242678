import { onMounted, onUnmounted, ref } from '@/composition/vue/compositionApi'

const onBeforeHide = ref<(() => boolean) | undefined>()

export const useExpansionHooks = () => {
  const setOnBeforeHide = (fn?: () => boolean) => {
    onBeforeHide.value = fn
  }

  return {
    onBeforeHide,
    setOnBeforeHide,
  }
}

export const useExpansionItemOnBeforeHide = (fn: () => boolean) => {
  const { setOnBeforeHide } = useExpansionHooks()

  onMounted(() => setOnBeforeHide(fn))
  onUnmounted(() => setOnBeforeHide())
}

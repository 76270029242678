import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {}

export type WizardServices = keyof typeof SERVICES

export const WIZARD_SERVICE_TYPES: Record<WizardServices, symbol> = makeServicesTypes<WizardServices>(
  enumKeysToArray<WizardServices>(SERVICES),
)

<template>
  <tbody>
    <tm-skeleton-table-body-row-layout
      v-for="r in rows"
      :key="r"
      :columns="columns"
    />
  </tbody>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import type { ColumnLoader } from '@/core/tables/types'
import TmSkeletonTableBodyRowLayout from '@/components/shared/skeletons/TmSkeletonTableBodyRowLayout.vue'

export default defineComponent({
  components: { TmSkeletonTableBodyRowLayout },
  props: {
    rows: {
      type: Number,
      default: 10,
    },
    columns: {
      type: Array as PropType<ColumnLoader[]>,
      default: () => [],
    },
  },
})
</script>

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import PageMapPreload from '@/data/models/PageMapPreload'
import type BaseModel from '@/data/models/BaseModel'
import type { ModelRaw } from '@/types'
import type { PreloaderType } from '@/services/preloaders/types'

interface UpdatePayload {
  model: typeof BaseModel
  key: string
  pageKey: string
  accessoryId: string
  ids: string[]
  updatedAt: string
  preloaderType: PreloaderType
}

type MarkAsFailedPayload = Omit<UpdatePayload, 'ids'>

@RepoSettings<Endpoint>({
  model: PageMapPreload,
})
@injectable()
export default class PageMapPreloadRepository extends OrmApiRepository<PageMapPreload> {
  public updatePreload({ model, key, pageKey, accessoryId, ids, updatedAt, preloaderType }: UpdatePayload) {
    this.add({
      id: key,
      map: ids,
      pageKey,
      modelName: model.entity,
      accessoryId,
      isPreloaded: true,
      isFailed: false,
      isLoading: false,
      preloaderType,
      updatedAt,
      payload: undefined,
    })
  }

  public markAsFailed({ model, key, pageKey, accessoryId, updatedAt, preloaderType }: MarkAsFailedPayload) {
    this.add({
      id: key,
      map: [],
      pageKey,
      modelName: model.entity,
      accessoryId,
      isPreloaded: false,
      isFailed: true,
      isLoading: false,
      preloaderType,
      updatedAt,
      payload: undefined,
    })
  }

  public cleanUp(preloaderType: PreloaderType, accessoryId: string) {
    this.lowLevelDeleteByCondition((rec: any) => rec.preloaderType === preloaderType && rec.accessoryId === accessoryId)
  }

  protected add(model: ModelRaw<PageMapPreload>) {
    this.insertOrUpdateRaw([model], PageMapPreload)
  }
}

import { inject, injectable } from 'inversify'
import type { WrapperOpenableInterface } from '@/services/wrappers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import GroupedPartialUIWrapperService from '@/services/wrappers/groupedPartialUIWrapperService'

@injectable()
export default class GroupedQueryPartialUIWrapperService extends GroupedPartialUIWrapperService {
  constructor(
    @inject(SERVICE_TYPES.PartialQueryUIWrapperService)
    protected readonly partialUIWrapperService: WrapperOpenableInterface,
  ) {
    super(partialUIWrapperService)
  }
}

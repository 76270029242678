import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type { WeekDaysTimeRangeList, WeekDaysTimeRangeListsFieldConfig } from '@/services/forms/types'
import type { TypedFieldConfig } from '@/services/forms/baseForm/typedFormBuilder/types'
import { getRangeOverlappingIndexes } from '@/utils/rangeOverlapping'
import { DATE_FORMATES } from '@/services/types'
import type DateTimeService from '@/services/dateTimeService'
import type { DateRange } from '@/services/tables/filters/types'
import type { WeekDaysTimeRangeFieldParams } from '@/services/forms/fieldBuilders/types'

export type StartEndRangeErrorPayload = {
  ranges: {
    dayIndex: number
    rangeIndex: number
  }[]
}

export type DayRangeOverlapErrorPayload = {
  ranges: {
    dayIndex: number
    rangeIndex: number
  }[]
}

export const startEndRangeRuleName = 'weekDaysTimeRangeStartEndRange'
export const dayRangeOverlapRuleName = 'weekDaysTimeRangeDayRangeOverlap'

@injectable()
export default class WeekDaysTimeRangeFieldBuilder {
  constructor(
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.DateTimeService) protected readonly dateTimeService: DateTimeService,
  ) {}

  public getWeekDaysTimeRangeFieldConfig(
    params?: WeekDaysTimeRangeFieldParams,
  ): TypedFieldConfig<WeekDaysTimeRangeListsFieldConfig> {
    return {
      fieldType: 'weekDaysTimeRangeLists',
      value: [] as WeekDaysTimeRangeList[],
      componentProps: {
        canSelectAnyRange: () => true,
        defaultTimeRange: () => this.getDefaultRange(),
        ...(params?.maxRanges ? { maxRanges: () => params.maxRanges! } : {}),
      },
      validators: this.getWeekDaysTimeRangeFieldValidators(),
    }
  }

  protected getWeekDaysTimeRangeFieldValidators() {
    return this.validationRulesBuilderService
      .createRules<WeekDaysTimeRangeList[]>()
      .custom(startEndRangeRuleName, (dayList) => {
        const payload: StartEndRangeErrorPayload = {
          ranges: [],
        }
        dayList.forEach((day, dayIndex) => {
          day?.ranges.forEach((range, rangeIndex) => {
            if (range[1] <= range[0]) {
              payload.ranges.push({
                dayIndex,
                rangeIndex,
              })
            }
          })
        })
        if (payload.ranges.length) {
          return {
            message: {
              path: 'fieldWeekDaysTimeRangeLists.errors.endTimeBeforeStartTime',
            },
            payload,
          }
        }
        return true
      })
      .custom(dayRangeOverlapRuleName, (dayList) => {
        const payload: DayRangeOverlapErrorPayload = {
          ranges: [],
        }
        dayList.forEach((day, dayIndex) => {
          if (!day) {
            return
          }
          getRangeOverlappingIndexes(day.ranges).forEach((rangeIndex) => {
            payload.ranges.push({
              dayIndex,
              rangeIndex,
            })
          })
        })
        if (payload.ranges.length) {
          return {
            message: {
              path: 'fieldWeekDaysTimeRangeLists.errors.intervalsOverlap',
            },
            payload,
          }
        }
        return true
      })
  }

  protected getDefaultRange() {
    return ['09:00', '17:00'].map((time) =>
      this.dateTimeService.getDateFromStringFormat(time, DATE_FORMATES.DATEPICKER_TIME_FORMAT),
    ) as DateRange
  }
}

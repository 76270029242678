import { computed, ref, type PropType, type Ref } from '@/composition/vue/compositionApi'
import type { Resolvers } from '@/services/types'
import {
  getLoggerService,
  getResolver,
  getRouterService,
  getTitlerManager,
  getWindowService,
} from '@/core/container/ioc'
import type { ResolverState } from '@/components/resolvers/types'
import type { Dict } from '@/types'
import { isAllDepsAreResolved } from '@/core/container/deps'
import type { Route } from '@/services/route/types'

export type RESOLVER_BASE_PROPS = {
  resolvers: Resolvers
  routeName: string
}
export const resolversBaseProps = {
  resolvers: {
    type: Array as PropType<Resolvers>,
    required: true,
  },
  routeName: {
    type: String,
    required: true,
  },
} as const

export const useResolver = (routeName: Ref<string>) => {
  const routerService = getRouterService()

  const compKey = computed(
    () =>
      routerService.resolve({
        name: routeName.value,
      }).path,
  )

  return {
    compKey,
  }
}

export const useResolvers = (routeName: string, resolverConfig: Resolvers) => {
  const resolverService = getResolver()
  const logger = getLoggerService()
  const titlerManager = getTitlerManager()

  const resolvingState = ref<ResolverState>('INITIAL')
  const resolveResult = ref<Dict>()
  const routerService = getRouterService()
  const currentRoute = computed(() => routerService.getCurrentRoute())
  let isModulesFailed = false
  let isFailed = false

  const resolvingRoute = routerService.resolve({
    name: routeName,
    params: currentRoute.value?.params,
  }) as Route

  const resolve = async (fullReloadWhenFailed = false) => {
    if (isModulesFailed) {
      resolvingState.value = 'FAILED'
      return
    }
    resolvingState.value = 'INITIAL'
    try {
      resolveResult.value = await resolverService.resolveComponent(resolverConfig, resolvingRoute)
      isFailed = resolverService.isFailed(resolverConfig, routerService.getCurrentRoute())
    } catch (e) {
      logger.error('resolver', 'Failed to resolve')
      logger.raw('resolver', e)
      resolveResult.value = {}
      isFailed = true
    }

    titlerManager.updateTitle(currentRoute.value, currentRoute.value.meta?.titler?.params)

    if (isFailed && fullReloadWhenFailed) {
      getWindowService().reload()
    } else {
      resolvingState.value = isFailed ? 'FAILED' : 'RESOLVED'
    }
  }

  const unresolve = async () => {
    stopBeforeEach()
    await resolverService.unresolveComponent(resolverConfig, resolvingRoute)
  }

  const stopBeforeEach = routerService.getRouter().beforeEach(async (to) => {
    if (isAllDepsAreResolved(String(to.name), to.meta?.modules)) {
      return
    }
    try {
      // @todo - move this code to "resolve" method after fixed https://textmagic.atlassian.net/browse/CAR-3165
      await resolverService.resolveDeps(String(to.name), to.meta?.modules ?? [])
    } catch (e) {
      isModulesFailed = true
      throw e
    }
  })

  const retry = async (fullReloadWhenFailed = true) => {
    resolve(fullReloadWhenFailed)
  }

  return {
    resolvingState,
    resolveResult,
    resolve,
    unresolve,
    retry,
  }
}

import { TmApiClientError } from '@/core/error/transport/tmApiClientError'
import type { TmTransportHttpErrorCode } from '@/core/error/transport/types'

export class TmApiAuthentificationError extends TmApiClientError {
  public name = 'TmApiAuthentificationError'

  protected isMonitored = false

  protected override code: TmTransportHttpErrorCode = 401
}

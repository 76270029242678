import { inject, injectable } from 'inversify'
import { isString, clone } from 'lodash-es'
import type { Query, Record } from '@vuex-orm/core'
import type * as Contracts from '@vuex-orm/core/dist/src/query/contracts'
import type { AxiosRequestConfig } from 'axios'
import type BaseModel from '@/data/models/BaseModel'
import { SERVICE_TYPES } from '@/core/container/types'
import type { ModelRaw } from '@/types'
import type { RepositorySettings } from '@/decorators/repositoryDecorators'
import type {
  CreatedEntityData,
  EmptyResponse,
  Transport,
  Transportable,
  TransportConfig,
  UpdatedEntityResponse,
} from '@/services/transport/types'
import ModelMapper from '@/data/utils/modelMapper'
import type LoggerService from '@/services/loggerService'
import type { LowLevelInsertBody, LowLevelUpdateBody, LowLevelUpdateByConditionBody } from '@/services/vuex/types'
import type { BaseMutationPayload, RecordUpdateClosure } from '@/services/vuex/mutators/baseMutations'
import { TmRepositoryError } from '@/core/error/tmRepositoryError'
import type { ModelRelationArray } from '@/services/domain/types'
import type { IDataProvider } from '@/services/types'
import { TmEntityNotFoundError } from '@/core/error/tmEntityNotFoundError'

@injectable()
export default abstract class ORMBaseRepository<T extends BaseModel, R = string>
  implements Transportable<T>, IDataProvider<T>
{
  protected transport: Transport

  protected constructor(@inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService) {}

  /**
   * Repo Settings
   */
  public settings(): RepositorySettings<R> {
    throw new TmRepositoryError('Please use decorator @RepoSettings<Endpoint>')
  }

  public isPublic() {
    return false
  }

  protected model() {
    return this.settings().model
  }

  public getApiSource() {
    return this.transport
  }

  public findEntityByIdOrFail(t: string, withRelations?: ModelRelationArray<T>): T {
    const entity = this.findEntityByIdOrNull(t, withRelations)
    if (entity === null) {
      throw new TmEntityNotFoundError(t)
    }
    return entity
  }

  public findEntityByIdOrNull(t: string, withRelations: ModelRelationArray<T> = []): T | null {
    const model = withRelations.length ? this.query().with(withRelations).find(t) : (this.model().find(t) as T)
    return model ?? null
  }

  public findEntityOrFail(t: (entity: T) => boolean, withRelations?: ModelRelationArray<T>): T {
    const entity = this.findEntityOrNull(t)
    if (entity === null) {
      throw new TmEntityNotFoundError(t)
    }
    return entity
  }

  public findEntityOrNull(t: (entity: T) => boolean, withRelations: ModelRelationArray<T> = []): T | null {
    const model = withRelations.length
      ? this.query().with(withRelations).where(t).first()
      : (this.query().withAll().where(t).first() as T)
    return model ?? null
  }

  public firstOrFail(): T {
    const entity = this.firstOrNull()
    if (entity === null) {
      throw new TmEntityNotFoundError()
    }
    return entity
  }

  public firstOrNull(): T | null {
    const entity = this.model().all()[0] as T | undefined
    return entity ?? null
  }

  /**
   * ORM methods
   */
  public all() {
    return this.model().all() as unknown as Array<T>
  }

  /**
   * @deprecated use findEntityByIdOrFail/findEntityByIdOrNull
   */
  public find(id: string, withRelations: ModelRelationArray<T> = []) {
    const model = withRelations.length ? this.query().with(withRelations).find(id) : this.model().find(id)
    return model as unknown as T
  }

  public query() {
    return this.model().query() as Query<T>
  }

  public findIn(ids: string[], withRelations: ModelRelationArray<T> = []): T[] {
    return this.query().with(withRelations).findIn(ids) as unknown as T[]
  }

  public insert(newRecords: LowLevelInsertBody<T>[], stiModel?: typeof BaseModel) {
    this.log('insert')
    this.logArgs(JSON.stringify(clone(newRecords), null, 2))
    this.lowLevelInsert(newRecords, stiModel)
    return newRecords
  }

  public fillResponseSerializer(data: any) {
    return data
  }

  public async fill(id: string, withRelations: ModelRelationArray<T> = []): Promise<T> {
    this.logGroupStart('fill')
    this.logArgs(id)
    const model = this.fillResponseSerializer(await this.getRequest<any>(id))
    await this.insertOrUpdateItem(model)
    this.logGroupEnd()
    return this.find(id, withRelations)
  }

  public abstract fillAll(isReset?: boolean): Promise<T[]>

  public async findOrFill(id: string): Promise<T> {
    const item = this.model().find(id)
    if (item) {
      return item as unknown as T
    }
    this.log('findOrFill')
    this.logArgs(id)
    return (await this.fill(id)) as unknown as T
  }

  public update(changeSet: LowLevelUpdateBody<T>[], stiModel?: typeof BaseModel) {
    this.logGroupStart('update')
    this.logArgs(JSON.stringify(changeSet, null, 2))
    this.log('update')
    this.lowLevelUpdate(changeSet, stiModel)
    this.logGroupEnd()
  }

  public updateItem(changeSet: LowLevelUpdateBody<T>, stiModel?: typeof BaseModel) {
    this.update([changeSet], stiModel)
  }

  public updateItemIfExists(changeSet: LowLevelUpdateBody<T>, stiModel?: typeof BaseModel) {
    if (!this.findEntityByIdOrNull(changeSet.id)) return
    this.updateItem(changeSet, stiModel)
  }

  public updateIds(ids: string[], changeSet: Partial<ModelRaw<T>>, stiModel?: typeof BaseModel) {
    const idsChangeSet: LowLevelUpdateBody<T>[] = ids.map((id) => ({
      ...changeSet,
      id,
    }))
    return this.update(idsChangeSet, stiModel)
  }

  public updateByCondition(
    updateData: LowLevelUpdateByConditionBody<T> | RecordUpdateClosure<T>,
    condition: Contracts.Predicate<T>,
    stiModel?: typeof BaseModel,
  ) {
    this.logGroupStart('updateByCondition')
    this.logArgs(JSON.stringify(updateData, null, 2))
    this.log('updateByCondition')
    this.lowLevelUpdateByCondition<LowLevelUpdateByConditionBody<T> | RecordUpdateClosure<T>>(
      updateData,
      condition,
      stiModel,
    )
    this.logGroupEnd()
  }

  public async insertItems(payload: LowLevelUpdateBody<T>[]) {
    this.log('insertOrUpdate')
    this.logArgs(JSON.stringify(payload, null, 2))
    return this.model().insert({ data: payload })
  }

  public async insertOrUpdate(payload: LowLevelUpdateBody<T>[]) {
    this.log('insertOrUpdate')
    this.logArgs(JSON.stringify(payload, null, 2))
    return this.model().insertOrUpdate({ data: payload })
  }

  public insertOrUpdateItem(payload: LowLevelUpdateBody<T>) {
    return this.insertOrUpdate([payload])
  }

  /**
   * Insert or update (if exists) record
   * Pay attention. This method cannot hidrate data to child models
   * @param payload Data array
   * @param stiModel Children model name for STI
   */
  public insertOrUpdateRaw(payload: LowLevelUpdateBody<T>[], stiModel?: typeof BaseModel) {
    this.log('insertOrUpdateRaw')
    this.logArgs(JSON.stringify(payload, null, 2))
    this.lowLevelInsertOrUpdate(payload, stiModel)
  }

  public create(payload: Record | Record[]) {
    this.log('create')
    this.logArgs(JSON.stringify(payload))
    return this.model().create({ data: payload })
  }

  public delete(ids: string[], stiModel?: typeof BaseModel) {
    // this.log('delete')
    // this.logArgs(JSON.stringify(ids))
    this.lowLevelDelete(ids, stiModel)
  }

  public async deleteAll() {
    this.log('deleteAll')
    return this.model().deleteAll()
  }

  public deleteByCondition(condition: Contracts.Predicate<T>, stiModel?: typeof BaseModel) {
    // this.log('deleteByCondition')
    // this.logArgs(JSON.stringify(condition))
    this.lowLevelDeleteByCondition(condition, stiModel)
  }

  public getRaw(id: string): ModelRaw<T> {
    const { entity } = this.getBaseEntity()
    const { state } = this.getBaseEntity().store()
    const raws = state.entities[entity].data as { [k: string]: ModelRaw<T> }

    if (raws) {
      return raws[id]
    }

    throw new TmRepositoryError('Not found')
  }

  public getRaws(ids: string[], localKey = 'id'): ModelRaw<T>[] {
    const { state } = this.getBaseEntity().store()
    const raws = state.entities[this.getBaseEntity().entity].data as { [k: string]: ModelRaw<T> }
    if (raws) {
      return Object.values(raws).filter((item: ModelRaw<T>) => ids.indexOf(item[localKey]) >= 0)
    }

    throw new TmRepositoryError('Not found')
  }

  public getAllRaw(returnArray = true): ModelRaw<T>[] {
    const { state } = this.getBaseEntity().store()
    // eslint-disable-next-line
    // @ts-ignore
    const raws = state.entities[this.getBaseEntity().entity].data as Record<string, ModelRaw<T>>
    if (returnArray) {
      return Object.values(raws)
    }
    return raws
  }

  protected lowLevelInsert<M = T>(records: M[], stiModel?: typeof BaseModel) {
    this.log('lowLevelInsert')
    this.logArgs(JSON.stringify(records, null, 2))
    this.model()
      .store()
      .commit(this.getMutationName('LOW_LEVEL_INSERT'), {
        stiModel: stiModel || this.model(),
        data: ModelMapper.mappingWithDefault<M>(stiModel || this.model(), records),
      } as BaseMutationPayload)
  }

  protected lowLevelUpdate<M = T>(records: M[], stiModel?: typeof BaseModel) {
    this.log('lowLevelUpdate')
    this.logArgs(JSON.stringify(records, null, 2))
    this.model()
      .store()
      .commit(this.getMutationName('LOW_LEVEL_UPDATE'), {
        stiModel,
        data: records,
      } as BaseMutationPayload)
  }

  protected lowLevelUpdateByCondition<M = T>(
    updateTo: M | RecordUpdateClosure<T>,
    condition: Contracts.Predicate<T>,
    stiModel?: typeof BaseModel,
  ) {
    this.model()
      .store()
      .commit(this.getMutationName('LOW_LEVEL_UPDATE_CONDITION'), {
        stiModel,
        condition,
        data: updateTo,
      } as BaseMutationPayload)
  }

  protected lowLevelInsertOrUpdate<P = R>(records: P[], stiModel?: typeof BaseModel) {
    this.log('lowLevelInsertOrUpdate')
    this.logArgs(JSON.stringify(records, null, 2))
    this.model()
      .store()
      .commit(this.getMutationName('LOW_LEVEL_INSERT_OR_UPDATE'), {
        stiModel,
        data: records.map((r: any) =>
          this.find(r.id) ? r : ModelMapper.mappingWithDefault<R>(stiModel || this.model(), [r])[0],
        ),
      } as BaseMutationPayload)
  }

  protected lowLevelDelete(ids: (string | number)[], stiModel?: typeof BaseModel) {
    this.model().store().commit(this.getMutationName('LOW_LEVEL_DELETE'), { ids, stiModel })
  }

  protected lowLevelDeleteByCondition(condition: Contracts.Predicate<T>, stiModel?: typeof BaseModel) {
    // this.log('lowLevelDeleteByCondition')
    this.model().store().commit(this.getMutationName('LOW_LEVEL_DELETE_CONDITION'), { condition, stiModel })
  }

  public importRequest(body: any) {
    if (!this.settings().importEndpoint) {
      throw new TmRepositoryError('There is no importEndpoint!')
    }
    return this.doPost(this.settings().importEndpoint!, body)
  }

  protected getMutationName(name: string) {
    return [name, this.model().entity].join('.')
  }

  protected getBaseEntity() {
    return this.model().store().$db().baseModel(this.model().entity)
  }

  /**
   * Transport
   */
  public async getRequest<M = unknown>(id: string) {
    if (!this.hasSingleSetting()) {
      throw new TmRepositoryError(`You must define \`single\` property in settings() for entity ${this.model().entity}`)
    }
    const response = (await this.doGet(this.settings().single!, id)) as { data: M }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    response.data = ModelMapper.normalizePayload<M>(response.data, this.model()) as unknown as M
    return response.data
  }

  public putRequest(toUpdate: any): Promise<UpdatedEntityResponse> {
    if (!this.hasSingleSetting()) {
      throw new TmRepositoryError(`You must define \`single\` property in settings() for entity ${this.model().entity}`)
    }
    return this.doPut(this.settings().single!, toUpdate)
  }

  public postRequest<ResponseType = CreatedEntityData>(toCreate: any, config?: AxiosRequestConfig) {
    if (!this.settings().create) {
      throw new TmRepositoryError(`You must define \`create\` property in settings() for entity ${this.model().entity}`)
    }
    return this.doPost<ResponseType>(this.settings().create!, toCreate, config)
  }

  public deleteRequest(id: string, config?: TransportConfig): Promise<EmptyResponse> {
    if (!this.hasSingleSetting()) {
      throw new TmRepositoryError(`You must define \`single\` property in settings() for entity ${this.model().entity}`)
    }

    return this.doDelete(this.settings().single!, id, config)
  }

  public isEmptyStore() {
    return this.query().first() === null
  }

  public hasSingleSetting() {
    return !!this.settings().single
  }

  protected async doGet<M>(key: R, id: string) {
    return this.getApiSource().get<M>(this.getPath(key, id))
  }

  protected async doDelete(key: R, id: string, config?: TransportConfig) {
    return this.getApiSource().delete(this.getPath(key, id), config || {})
  }

  protected doPost<ResponseType = CreatedEntityData>(key: R, toCreate: any, config?: TransportConfig) {
    const apiSource = this.getApiSource()
    const path = this.getUpdatePath(key, toCreate)
    return apiSource.post<ResponseType>(path, toCreate, config)
  }

  protected async doPut(key: R, updated: any): Promise<UpdatedEntityResponse> {
    return (await this.getApiSource().put(
      this.getUpdatePath(key, updated),
      this.serializeData(updated),
    )) as unknown as Promise<UpdatedEntityResponse>
  }

  protected abstract getPath(key: R, id: string): string
  protected abstract getUpdatePath(key: R, data: T): string
  protected abstract serializeData(data: T): { [key: string]: any }

  /**
   * Logger
   */
  protected log(message: any) {
    if (this.loggerService.shouldLogByChannel('orm', [this.model().entity])) {
      this.loggerService.log('orm', isString(message) ? message : JSON.stringify(message), this.model().entity)
    }
  }

  protected logGroupStart(message: string) {
    if (this.loggerService.shouldLogByChannel('orm', [this.model().entity])) {
      this.loggerService.groupStart('orm', message, this.model().entity)
    }
  }

  protected logArgs(args: string) {
    if (this.loggerService.shouldLogByChannel('orm', [this.model().entity])) {
      this.loggerService.args('orm', args, this.model().entity)
    }
  }

  protected logGroupEnd() {
    if (this.loggerService.shouldLogByChannel('orm', [this.model().entity])) {
      this.loggerService.groupEnd('orm')
    }
  }
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import TenDlcTwilioBrand from '@/data/models/domain/services/regulations/TenDlcTwilioBrand'
import { TmApiNotFoundError } from '@/core/error/transport/tmApiNotFoundError'
import type { Nullable } from '@/types'

@RepoSettings<Endpoint>({
  model: TenDlcTwilioBrand,
})
@injectable()
export default class TenDlcTwilioBrandRepository extends OrmApiRepository<TenDlcTwilioBrand> {
  public async getBrand(): Promise<Nullable<TenDlcTwilioBrand>> {
    try {
      const brandPath = this.endpointsService.getPath('tenDlcTwilioBrand')
      const response = await this.getApiSource().get<TenDlcTwilioBrand>(brandPath)
      // one brand per branch/workspace, clean up for case of re-login through branches
      await this.deleteAll()
      await this.insertOrUpdate([response.data])

      return response.data
    } catch (e) {
      if (e instanceof TmApiNotFoundError) {
        return null
      }
      throw e
    }
  }

  public findBrand(): Nullable<TenDlcTwilioBrand> {
    return this.query().first()
  }
}

import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import { ByocRouteNames } from '@/services/domain/byoc/types'
import { getByocDetailsFlowRoutes } from '@/routes/user/services/byocFlow'

export type ByocTabsRouteNames = { providers: string; phones: string; senderIds: string }

export const getByocTabsRoutes = (routeNames: ByocTabsRouteNames): RouteRecordRaw[] => [
  {
    name: routeNames.providers,
    path: 'providers',
    component: () => import('@/components/views/services/byoc/ByocProviders.vue'),
    meta: {
      tabTitle: 'pageTabTitle.user.services.byoc.providers',
    },
  },
  {
    name: routeNames.phones,
    path: 'numbers',
    component: () => import('@/components/views/services/byoc/ByocPhones.vue'),
    meta: {
      tabTitle: 'pageTabTitle.user.services.byoc.phones',
      resolvers: [{ service: 'ByocPhonesResolver' }],
    },
  },
  {
    name: routeNames.senderIds,
    path: 'sender-ids',
    component: () => import('@/components/views/services/byoc/ByocSenderIds.vue'),
    meta: {
      tabTitle: 'pageTabTitle.user.services.byoc.senderIds',
      resolvers: [{ service: 'ByocSenderIdsResolver' }],
    },
  },
]

export const getByocRoutes = (): RouteRecordRaw[] => [
  {
    name: ByocRouteNames.main,
    path: 'byoc',
    redirect: { name: ByocRouteNames.providers },
    children: [
      {
        name: ByocRouteNames.base,
        path: '',
        meta: {
          tags: ['SERVICES_BAR_LIST'],
          modules: [AppModule.Byoc],
          resolvers: [{ service: 'ByocResolverService' }],
          resolverComponent: () => import('@/components/resolvers/skeleton/services/ByocContentResolver.vue'),
          permission: 'byoc',
          accessControlGroups: ['ByocStrategy'],
        },
        component: () => import('@/components/views/services/byoc/Byoc.vue'),
        redirect: { name: ByocRouteNames.providers },
        children: getByocTabsRoutes(ByocRouteNames),
      },
      ...getByocDetailsFlowRoutes(ByocRouteNames.flow),
    ],
  },
]

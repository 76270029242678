import { AttrField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.API_V1_SETTINGS)
export default class ApiV1Settings extends BaseModel {
  @StringField(null)
  public callbackOutUrl: string | null

  @StringField(null)
  public callbackInUrl: string | null

  @AttrField()
  public ipList: string[]
}

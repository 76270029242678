<template>
  <div class="tm-skeleton-table">
    <tm-table-layout is-loading>
      <template v-slot:table-header>
        <slot name="table-header">
          <tr>
            <th
              v-for="(col, index) in headerColumns"
              :key="index"
              :class="col.loaderClass"
            >
              <tm-simple-skeleton
                v-if="!col.hideLoader"
                :type="col.loaderType ?? 'chip'"
                :height="col.loaderHeight || '18px'"
                :width="getColWidth(col.loaderWidth)"
              />
            </th>
          </tr>
        </slot>
      </template>
      <template v-slot:table-body>
        <tm-skeleton-table-body
          :rows="rows"
          :columns="columns"
        />
      </template>
    </tm-table-layout>
    <div
      v-if="!hideGradient"
      class="tm-skeleton-table__gradient"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import TmTableLayout from '@/components/shared/TmTableLayout.vue'
import TmSkeletonTableBody from '@/components/shared/skeletons/TmSkeletonTableBody.vue'
import type { ColumnLoader } from '@/core/tables/types'
import { getDefaultLoaderColumnsConfig } from '@/composition/tables'
import { addPxToNumber } from '@/utils/string/addPxToNumber'
import type { StylesheetSizeProp } from '@/types'
import TmSimpleSkeleton from '@/components/shared/skeletons/TmSimpleSkeleton.vue'

export default defineComponent({
  components: {
    TmSimpleSkeleton,
    TmSkeletonTableBody,
    TmTableLayout,
  },
  props: {
    rows: {
      type: Number,
      default: 10,
    },
    columns: {
      type: Array as PropType<ColumnLoader[]>,
      default: getDefaultLoaderColumnsConfig,
    },
    headerColumns: {
      type: Array as PropType<ColumnLoader[]>,
      default: getDefaultLoaderColumnsConfig,
    },
    hideGradient: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const getColWidth = (width: number | undefined): StylesheetSizeProp | undefined => {
      if (typeof width === 'number') {
        return addPxToNumber(width)
      }

      return width
    }

    return {
      getColWidth,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.tm-skeleton-table {
  position: relative;
  overflow: auto;

  &__gradient {
    @include skeleton-section-color-gradient;
  }
}
</style>

import { inject, injectable } from 'inversify'
import BaseFormService from '@/services/forms/baseFormService'
import type FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type LoggerService from '@/services/loggerService'
import type { ParamsPartial } from '@/types'
import type CountryService from '@/services/domain/countryService'
import type UserService from '@/services/domain/user/userService'
import type { ImportMatchingCountryFormat } from '@/services/forms/flow/types'

@injectable()
export default class ImportMatchingCountryFormService extends BaseFormService<FormBuilderService, ParamsPartial> {
  constructor(
    @inject(SERVICE_TYPES.FormBuilderFactoryService) formBuilderFactoryService: () => FormBuilderService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.CountryService) protected readonly countryService: CountryService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {
    super(formBuilderFactoryService, em, validationRulesBuilderService, logger)
  }

  public async buildForm() {
    const builder = this.getBuilder()

    const countryOptions = this.countryService.getSelectOptionCountries()

    const formatValue: ImportMatchingCountryFormat = 'country'
    builder.control({
      name: 'format',
      fieldType: 'radio',
      value: formatValue,
    })

    builder.control({
      name: 'country',
      fieldType: 'selectSearchCountry',
      value: this.userService.currentUser().countryId,
      options: () => countryOptions,
    })
  }

  public async submit() {}
}

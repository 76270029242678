import type { CustomErrorRule, CustomErrorRulePredicateFn } from '@/core/error/transport/types'
import { TmApiTaskChecklistNotFoundError } from '@/core/error/transport/tmApiTaskChecklistNotFoundError'
import { EndpointsEnum } from '@/services/endpoints'

export const taskChecklistNotFoundErrorRule: CustomErrorRule = {
  klass: TmApiTaskChecklistNotFoundError,
  methods: ['put', 'post'],
  predicateFn: (error) => {
    return isChecklistUpdate(error) || isNewChecklistItem(error)
  },
}

const isChecklistUpdate: CustomErrorRulePredicateFn = (error) => {
  const endpointUrl = error.config.url

  if (!endpointUrl) {
    return false
  }

  const regexSingle = new RegExp(`${EndpointsEnum.taskChecklistSingle.replace('{0}', '([0-9]+)')}`)
  const regexBulk = new RegExp(`${EndpointsEnum.taskChecklistBulkMark.replace('{0}', '([0-9]+)')}`)
  return (error.config.method === 'put' && regexSingle.test(endpointUrl)) || regexBulk.test(endpointUrl)
}

const isNewChecklistItem: CustomErrorRulePredicateFn = (error) => {
  const endpointUrl = error.config.url

  if (!endpointUrl) {
    return false
  }

  const regex = new RegExp(`${EndpointsEnum.taskChecklistItems.replace('{0}', '([0-9]+)')}`)
  return error.config.method === 'post' && regex.test(endpointUrl)
}

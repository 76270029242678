import type { RegisteredServices } from '@/core/container/types'

export enum TenDlcPhoneNumberStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
}

export type TenDlcPhoneNumbersLinkToCampaignBody = {
  userInboundId: string
  campaignId: string
}

export const tenDlcPhoneNumbersTableId = 'tenDlcPhoneNumbersTable' as RegisteredServices
export const tenDlcPhoneNumbersDetailsTable = 'tenDlcPhoneNumbersDetailsTable' as RegisteredServices

export const minAmountRequiredTenDlc = 100

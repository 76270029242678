import { inject, injectable } from 'inversify'
import type { RouteRecordName } from 'vue-router'
import type { Resolvable, FacetResolverParams } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type FacetManager from '@/services/facets/facetManager'
import type LoggerService from '@/services/loggerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type { Facetable } from '@/services/facets/types'
import type { EndpointParams } from '@/services/endpoints'
import type RouterService from '@/services/route/routerService'
import type NewQueryParamsService from '@/services/route/newQueryParamsService'
import type UserService from '@/services/domain/user/userService'
import type { NumbersObject } from '@/types'
import type { Route } from '@/services/route/types'

@injectable()
export default class FacetResolver implements Resolvable<FacetResolverParams> {
  constructor(
    @inject(SERVICE_TYPES.FacetManager) protected readonly facetManager: FacetManager,
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService)
    protected readonly modelSubscriptionService: ModelSubscriptionService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.NewQueryParamsService) protected readonly newQueryParamsService: NewQueryParamsService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {}

  private faceter: Facetable<NumbersObject>

  private subscriptionKeys: Record<Exclude<RouteRecordName, undefined>, string[]> = {}

  public async resolve(config: FacetResolverParams, route?: Route) {
    const routeName = this.getRouteName(route)

    try {
      this.faceter = this.facetManager.getFaceter(config.model)
      this.faceter.setSettings({ entityName: config.model.entity, endpointParams: this.getEndpointParams(config) })

      if (config.wait) {
        await this.faceter.fetchFacets(config.types)
      } else {
        // we are not waiting counters from BE. We will show it as soon as it loaded
        this.faceter.fetchFacets(config.types)
      }

      if (config.subscriptionModels && config.subscriptionModels.length > 0) {
        this.subscriptionKeys[routeName] = this.modelSubscriptionService.subscribeByModels(
          config.subscriptionModels,
          () => {
            this.faceter.fetchFacets()
          },
        )
      }
    } catch (e) {
      this.logger.log('facet', `Facets for ${config.model} is not loaded`)
    }
  }

  public async unresolve(config: FacetResolverParams, route?: Route) {
    this.faceter = this.facetManager.getFaceter(config.model)
    if (config.clearable) {
      this.faceter.clearFacets()
    }

    const routeName = this.getRouteName(route)
    this.modelSubscriptionService.unsubscribe(this.subscriptionKeys[routeName] || [])
  }

  protected getEndpointParams(config: FacetResolverParams): EndpointParams {
    if (config.endpointParams?.length) {
      return config.endpointParams
    }

    if (!config.source || !config.requestParam) return []

    switch (config.source) {
      case 'router':
        return [this.routerService.getCurrentRoute().params[config.requestParam] as string]
      case 'query':
        return [this.newQueryParamsService.getQueryParams()[config.requestParam] as string]
      case 'currentUser':
        return [this.userService.currentUser().id]
      default:
        return []
    }
  }

  protected getRouteName(route?: Route) {
    return route?.name || this.routerService.getCurrentRoute().name || 'root'
  }
}

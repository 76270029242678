import { BooleanField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.UI_STATE)
export class UiState extends BaseModel {
  @StringField()
  public id: string

  @BooleanField(false)
  public isNotificationRequestWasClosed: boolean

  @BooleanField(false)
  public chatShowMobileChat: boolean

  @BooleanField(true)
  public chatIsChatsLoading: boolean
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  SmartBannerService,
}

export type SmartBannerServices = keyof typeof SERVICES

export const SMART_BANNER_SERVICE_TYPES: Record<SmartBannerServices, symbol> = makeServicesTypes<SmartBannerServices>(
  enumKeysToArray<SmartBannerServices>(SERVICES),
)

import { OrmModel, StringField, AttrField, NumberField, BooleanField } from 'vuex-orm-decorators'
import type { CountryCode } from 'libphonenumber-js'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type {
  TendDlcTwilioBrandOrganizationType,
  TendDlcTwilioBrandLegalStructure,
  TenDlcTwilioBrandContactJobPosition,
  TenDlcTwilioTaxNumberIdentifier,
  TenDlcTwilioBusinessRegion,
  TenDlcTwilioBrandStatus,
} from '@/services/domain/services/regulations/tenDlcBrand/types'
import type { Nullable } from '@/types'
import type { TenDlcVertical } from '@/services/domain/services/regulations/tenDlcRequest/types'

@OrmModel(modelNames.TEN_DLC_TWILIO_BRAND)
export default class TenDlcTwilioBrand extends BaseModel {
  @StringField()
  public id: string

  @NumberField()
  public userId: number

  @AttrField()
  public legalName: Nullable<string>

  @AttrField()
  public brandName: Nullable<string>

  @AttrField()
  public organizationType: Nullable<TendDlcTwilioBrandOrganizationType>

  @AttrField()
  public legalStructure: Nullable<TendDlcTwilioBrandLegalStructure>

  @AttrField()
  public taxNumber: Nullable<string>

  @AttrField()
  public taxNumberIdentifier: Nullable<TenDlcTwilioTaxNumberIdentifier>

  @StringField()
  public status: TenDlcTwilioBrandStatus

  @AttrField()
  public industry: Nullable<TenDlcVertical>

  @AttrField()
  public website: Nullable<string>

  @AttrField([])
  public businessRegionsOfOperations: TenDlcTwilioBusinessRegion[]

  @AttrField([])
  public socialProfileLinks: string[]

  @AttrField()
  public street: Nullable<string>

  @AttrField()
  public city: Nullable<string>

  @AttrField()
  public zipCode: Nullable<string>

  @AttrField()
  public state: Nullable<string>

  @AttrField()
  public countryId: Nullable<CountryCode>

  @AttrField()
  public contactFirstName: Nullable<string>

  @AttrField()
  public contactLastName: Nullable<string>

  @AttrField()
  public contactJobPosition: Nullable<TenDlcTwilioBrandContactJobPosition>

  @AttrField()
  public contactExactJobTitle: Nullable<string>

  @AttrField()
  public contactPhone: Nullable<string>

  @AttrField()
  public contactEmail: Nullable<string>

  @AttrField()
  public companyStockSymbol: Nullable<string>

  @AttrField()
  public companyStockExchange: Nullable<string>

  @StringField()
  public createdAt: string

  @StringField()
  public updatedAt: string

  @AttrField([])
  public errorFields: string[]

  @AttrField()
  public draftRequestId: string

  @AttrField()
  public actionRequiredRequestId: string | null

  @BooleanField()
  public isBrandOrgDetailsFilled: boolean

  @BooleanField()
  public isBrandOrgAddressFilled: boolean

  @BooleanField()
  public isBrandContactFilled: boolean
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  AutomationRuleService,
  AutomationRuleRepository,
}

export type AutomationRuleServices = keyof typeof SERVICES

export const AUTOMATION_RULE_SERVICE_TYPES: Record<AutomationRuleServices, symbol> =
  makeServicesTypes<AutomationRuleServices>(enumKeysToArray<AutomationRuleServices>(SERVICES))

<template>
  <top-filter-margin-wrapper
    class="tm-skeleton-filter"
    :class="{
      'tm-skeleton-filter_column': directionColumn,
    }"
  >
    <div class="d-flex">
      <tm-skeleton
        v-for="width in leftControls"
        :key="width"
        type="QChip"
        :width="width"
        height="36px"
        class="mr-2"
      />
    </div>

    <div class="d-flex">
      <tm-skeleton
        v-for="width in rightControls"
        :key="width"
        type="QChip"
        :width="width"
        height="36px"
        class="ml-2"
      />
    </div>
  </top-filter-margin-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import TopFilterMarginWrapper from '@/components/shared/tables/topFilter/TopFilterMarginWrapper.vue'

export default defineComponent({
  components: {
    TopFilterMarginWrapper,
    TmSkeleton,
  },
  props: {
    leftControls: {
      type: Array as PropType<string[]>,
      default: () => ['108px', '128px', '88px', '78px'],
    },
    rightControls: {
      type: Array as PropType<string[]>,
      default: () => ['170px', '36px'],
    },
    directionColumn: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
.tm-skeleton-filter {
  display: flex;
  align-content: center;
  justify-content: space-between;
  &_column {
    flex-direction: column;
    .top-filter-line {
      &__left {
        flex-basis: initial;
      }
    }
  }
}
</style>

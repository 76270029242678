import { inject, injectable } from 'inversify'
import { isBoolean, isObject } from 'lodash-es'
import type {
  FieldValidationResult,
  RawFieldValidationResult,
  RuleConfig,
  ValidationOptions,
  ValidationParams,
  ValidationRule,
  ValidationRuleErrorWithRuleName,
  ValidationRuleFunction,
  ValidationRulesMap,
  ValidationRuleError,
} from '@/services/validation/types'
import { SERVICE_TYPES, type RegisteredServices } from '@/core/container/types'
import type TranslateService from '@/services/translateService'
import defaultRules from '@/services/validation/defaultValidators'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import { isDev } from '@/utils/system'
import { TmFormValidationError } from '@/core/error/tmFormValidationError'
import type LoggerService from '@/services/loggerService'
import type { TranslationKey } from '@/services/types'
import type { IServiceManager } from '@/core/middlewares/types'
import type { Service } from '@/config/types'
import type { GetLocator } from '@/core/container/container'
import type { ValidationRegistratorInterface } from '@/services/validation/validationRegistratorInterface'

@injectable()
export default class ValidationService implements IServiceManager {
  private registeredRules: ValidationRulesMap = new Map()

  @inject('GetLocator') private readonly get: GetLocator

  constructor(
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {
    this.extend()
  }

  public addService(service: Service) {
    const instance = this.get<ValidationRegistratorInterface>(service.id as RegisteredServices)
    instance.register((name, func) => this.addRule(name, func))
  }

  public async validate(params: ValidationParams): Promise<FieldValidationResult> {
    const results = await this.validateFields(params)
    return this.formatFieldValidationResults(results, params.validators)
  }

  public validateFields({
    value,
    validators,
    localValidators,
    formValues,
    crossFieldValues,
  }: ValidationParams): Promise<RawFieldValidationResult[]> {
    return Promise.all(
      validators.map((rule) =>
        this.validateField(
          value,
          rule,
          {
            crossFieldValues,
            values: formValues,
          },
          localValidators,
        ),
      ),
    )
  }

  public formatFieldValidationResults(
    results: RawFieldValidationResult[],
    validators: ValidationRule[],
  ): FieldValidationResult {
    return {
      valid: results.every(({ valid }) => valid),
      errors: results.reduce((result, { errors }) => {
        result = result.concat(errors)
        return result
      }, [] as ValidationRuleErrorWithRuleName[]),
      rulesResults: validators.reduce(
        (rulesResults, rule, index) => {
          rulesResults[this.parseRule(rule.name).ruleName] = results[index].valid
          return rulesResults
        },
        {} as Record<string, boolean>,
      ),
    }
  }

  public validateFieldsList({
    value: values,
    ...validationParams
  }: ValidationParams<any[]>): Promise<RawFieldValidationResult[][]> {
    return Promise.all(values.map((value) => this.validateFields({ value, ...validationParams })))
  }

  public validateList = async (params: ValidationParams<any[]>): Promise<FieldValidationResult[]> => {
    const results = await this.validateFieldsList(params)
    return results.map((result) => this.formatFieldValidationResults(result, params.validators))
  }

  public addRule(name: string, func: ValidationRuleFunction) {
    this.registeredRules.set(name, func)
  }

  public extend() {
    Object.keys(defaultRules).forEach((rule) => {
      this.addRule(rule, defaultRules[rule])
    })
  }

  public async isPhone(value: string) {
    if (!value.toString().startsWith('+')) {
      value = `+${value}`
    }
    const result = await this.validate({
      value,
      validators: this.validationRulesBuilderService.createRules().phone().getRules(),
      formValues: {},
    })
    return result.valid
  }

  public async isEmail(value: string) {
    const result = await this.validate({
      value,
      validators: this.validationRulesBuilderService.createRules().email().getRules(),
      formValues: {},
    })
    return result.valid
  }

  public getValidationMessage(rule: ValidationRule, ruleParams: unknown[] = []): ValidationRuleErrorWithRuleName {
    if (rule.messagePath) {
      const error = this.generateErrorWithParams(rule.name, rule.messagePath, ruleParams)
      if (rule.messageParams) {
        error.message.params = rule.messageParams
      }
      return error
    }
    return this.getDefaultError(rule.name, ruleParams)
  }

  public composeErrorMessageWithRule(
    defaultMessage: ValidationRuleError['message'],
    rule?: RuleConfig,
  ): ValidationRuleError['message'] {
    const message = { ...defaultMessage }
    if (rule?.messagePath) {
      message.path = rule.messagePath
    }
    if (rule?.messageParams) {
      message.params = rule.messageParams
    }
    return message
  }

  protected getRuleParams(params: unknown[]): Record<string, unknown> {
    return params.reduce<Record<string, unknown>>((paramsObj, param, index) => {
      paramsObj[`params${index}`] = param
      return paramsObj
    }, {})
  }

  private getDefaultError(ruleName: string, ruleParams: unknown[]): ValidationRuleErrorWithRuleName {
    if (!this.translateService.exists(`commonValidationRegistrator.${ruleName}` as TranslationKey)) {
      return {
        ruleName,
        message: { path: 'commonValidationRegistrator.defaultMessage' },
      }
    }
    return this.generateErrorWithParams(ruleName, `commonValidationRegistrator.${ruleName}`, ruleParams)
  }

  private generateErrorWithParams(
    ruleName: string,
    path: string,
    ruleParams: unknown[],
  ): ValidationRuleErrorWithRuleName {
    return {
      ruleName,
      message: {
        path,
        params: { ...this.getRuleParams(ruleParams) },
      },
    }
  }

  /**
   * Validates field against a registered rule
   */
  private async validateField(
    value: any,
    rule: ValidationRule,
    options: ValidationOptions,
    localValidators?: ValidationRulesMap,
  ) {
    const { ruleName, ruleParams } = this.parseRule(rule.name, options.crossFieldValues)

    const validator = localValidators?.get(ruleName) || this.registeredRules.get(ruleName)
    const errors: ValidationRuleErrorWithRuleName[] = []

    if (validator) {
      const validationResult = await validator(value, ruleParams, options.values, rule)
      if (isObject(validationResult)) {
        const resultList = Array.isArray(validationResult) ? validationResult : [validationResult]
        resultList.forEach((result) => {
          result.message = this.composeErrorMessageWithRule(result.message, rule)
          errors.push({ ruleName, ...result })
        })
      }

      if (isBoolean(validationResult)) {
        if (!validationResult) {
          const error = this.getValidationMessage(rule, ruleParams)
          errors.push(error)
        }
      }
    } else if (isDev()) {
      throw new TmFormValidationError(`Rule not found: ${ruleName}`)
    } else {
      this.loggerService.error('formValidation', `ValidationService: Rule not found: ${ruleName}`)
    }

    return {
      errors,
      valid: !errors.length,
    }
  }

  /**
   * Parses rule that is in format 'ruleName:param1:param2'
   */
  private parseRule(rule: string, crossFieldValues?: Record<string, unknown>) {
    let ruleParams: unknown[] = []
    const ruleName = rule.split(':')[0]
    if (rule.includes(':')) {
      ruleParams = rule
        .split(':')
        .slice(1)
        .join(':')
        .split(',')
        .map((param) => {
          if (param[0] === '@') {
            return crossFieldValues?.[param.substring(1)] ?? ''
          }

          return param
        })
    }
    return { ruleName, ruleParams }
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { LoggerChannels } from '@/config/configDev'
import { LogLevel } from '@/core/logger/types'
import type { MonitoringLoggerServiceInterface, MonitoringServiceInterface } from '@/services/monitoring/types'
import type LoggerService from '@/services/loggerService'
import { TmBaseError } from '@/core/error/tmBaseError'

@injectable()
export class MonitoringLoggerService implements MonitoringLoggerServiceInterface {
  constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.MonitoringService) protected readonly monitoringService: MonitoringServiceInterface,
  ) {}

  public logAndCapture(message: string | Error, logLevel: Exclude<LogLevel, LogLevel.NULL>, subchannel?: string): void {
    const logChannel = this.getChannel(logLevel)

    if (typeof message === 'string') {
      this.loggerService.log(logChannel, message, subchannel)
      this.monitoringService.logInfo(message, logLevel, { subchannel })
    } else {
      if (message instanceof TmBaseError && !message.shouldBeMonitored()) {
        return
      }

      this.loggerService.log(logChannel, subchannel ? `Got exception for ${subchannel}` : 'Got exception')
      this.loggerService.raw(logChannel, message)
      this.monitoringService.logError(message, logLevel, { subchannel })
    }
  }

  protected getChannel(logLevel: Exclude<LogLevel, LogLevel.NULL>): LoggerChannels {
    const logLevelChannelMap: Record<Exclude<LogLevel, LogLevel.NULL>, LoggerChannels> = {
      [LogLevel.INFO]: 'info',
      [LogLevel.WARNING]: 'warn',
      [LogLevel.ERROR]: 'error',
      [LogLevel.DEBUG]: 'debug',
      [LogLevel.LOG]: 'debug',
    }

    return logLevelChannelMap[logLevel]
  }
}

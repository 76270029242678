import type { Nullable, RecordAsConstValuesType } from '@/types'
import type AttachmentFile from '@/data/models/domain/attachmentFile/AttachmentFile'

export type TicketParticipantPersonalInfo = {
  id: number
  firstName: Nullable<string>
  lastName: Nullable<string>
  email: string
  phone: Nullable<string>
  avatar: Nullable<{ id: number; href: string }>
}

enum TicketSystemStatusIds {
  DRAFT = '1',
  NEW = '2',
  OPEN = '3',
  SOLVED = '4',
  CLOSED = '5',
  SPAM = '97',
}

export const ticketCloseDateInterval = {
  DAYS_1: 'P1D',
  DAYS_3: 'P3D',
  DAYS_7: 'P7D',
  DAYS_14: 'P14D',
  DAYS_30: 'P30D',
  DAYS_90: 'P90D',
} as const
export type TicketCloseDataInterval = RecordAsConstValuesType<typeof ticketCloseDateInterval>

// eslint-disable-next-line
export type TicketStatusIdType = (typeof TicketSystemStatusIds)[keyof typeof TicketSystemStatusIds] | (string & {})

export enum TicketInboxType {
  FORWARDING = 'forwarding',
  MANAGED = 'managed',
}

export enum TicketInboxForwardingStatus {
  IDLE = 'idle',
  WAITING_FOR_VERIFICATION_EMAIL = 'waiting_for_verification_email',
  WAITING_FOR_CHECK_EMAIL_FORWARDING = 'waiting_for_check_email_forwarding',
  FORWARDING_CHECK_FAILED = 'forwarding_check_failed',
  VERIFIED = 'verified',
}

export enum TicketMessageType {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
  NOTE = 'note',
}

export interface TicketMessageBase<T extends TicketMessageType> {
  type: T
  id: string
  message: string
  createdAt: string
  hasTrimmedContent: boolean
  files: AttachmentFile[]
}

export interface TicketInboundMessage extends TicketMessageBase<TicketMessageType.INBOUND> {
  createdByContactId: string
  createdByContact: TicketParticipantPersonalInfo
  createdByUserId?: never
  createdByUser?: never
}

export interface TicketOutboundMessage extends TicketMessageBase<TicketMessageType.OUTBOUND> {
  type: TicketMessageType.OUTBOUND
  createdByUserId: string
  createdByUser: TicketParticipantPersonalInfo
  createdByContactId?: never
  createdByContact?: never
}

export interface TicketInternalNote extends Omit<TicketOutboundMessage, 'type'> {
  type: TicketMessageType.NOTE
}

export interface TicketInternalSystemNote extends TicketMessageBase<TicketMessageType.NOTE> {
  createdByContact: null
  createdByContactId: null
  createdByUser: null
  createdByUserId: null
}

export interface TicketInboundBotMessage extends TicketMessageBase<TicketMessageType.INBOUND> {
  createdByContact: null
  createdByContactId: null
  createdByUser: null
  createdByUserId: null
}

export type TicketMessageInterface =
  | TicketInboundMessage
  | TicketOutboundMessage
  | TicketInternalNote
  | TicketInternalSystemNote
  | TicketInboundBotMessage

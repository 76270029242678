import ContactList from '@/data/models/domain/ContactList'
import Contact from '@/data/models/domain/Contact'
import type {
  NotFoundResolverParams,
  RedirectableSingleResolverParams,
  SingleModelResolverParams,
} from '@/services/resolvers/types'
import { ListIdRouteParam } from '@/components/views/contacts/types'
import ContactImport from '@/data/models/domain/contactImport/ContactImport'
import type { DomainTitlerParams } from '@/services/route/titlers/types'
import { CustomFieldSchema } from '@/data/models/domain/CustomFieldSchema'
import UnsubscribedContact from '@/data/models/domain/UnsubscribedContact'
import BlockedContact from '@/data/models/domain/BlockedContact'
import ContactImportResult from '@/data/models/domain/contactImport/ContactImportResult'
import { AppModule } from '@/config/types'
import ContactsMenu from '@/components/domain/contacts/ContactsMenu.vue'
import type { TmNamedRouteRecord } from '@/services/route/types'
import { contactsMyRoute } from '@/routes/user/contacts/contactsMy'
import FilteredView from '@/data/models/domain/filteredViews/FilteredView'
import { Segment } from '@/data/models/domain/filteredViews/Segment'
import { SEGMENT_DRAFT_NAME_PARAM_NAME, SEGMENT_ID_PARAM_NAME } from '@/composition/domain/contacts/segments/types'

export const contactsUnsubscribedSmsRouteName = 'user.contacts.unsubscribed.sms'

export const contactsUnsubscribedEmailRouteName = 'user.contacts.unsubscribed.email'

export const contactListDetailsRouteName = 'user.contacts.lists.contactListDetails'

export const contactsListsRoute: TmNamedRouteRecord = {
  name: 'user.contacts.lists.index',
  path: 'lists',
  meta: {
    tags: ['CONTACTS_BAR_LIST'],
  },
  redirect: { name: 'user.contacts.lists' },
  children: [
    {
      name: 'user.contacts.lists',
      path: '',
      meta: {
        tabTitle: 'pageTabTitle.user.contacts.lists',
      },
      component: () => import('@/components/views/contacts/contactLists/ContactLists.vue'),
    },
    {
      name: contactListDetailsRouteName,
      path: `:${ListIdRouteParam}`,
      props: (route) => {
        const id = route.params[ListIdRouteParam]
        return {
          /**
           * Needed for update list, after changing route https://textmagic.atlassian.net/browse/CAR-9215
           */
          key: id,
          contactListId: id,
        }
      },
      meta: {
        resolverComponent: () => import('@/components/resolvers/AsyncResolver.vue'),
        resolvers: [
          {
            service: 'ContactListResolver',
            params: {
              source: 'router',
              requestParam: ListIdRouteParam,
              model: ContactList,
              redirectToIfNotFound: { name: 'user.contacts.lists' },
              cache: false,
            } as NotFoundResolverParams,
          },
        ],
        titler: {
          service: 'DomainTitlerService',
          params: {
            model: ContactList,
            field: 'name',
            idParamName: ListIdRouteParam,
          } as DomainTitlerParams,
        },
        redirectToIfNotFound: { name: 'user.contacts.lists' },
        modules: [AppModule.ContactListCounter],
        fullHeight: true,
      },
      component: () => import('@/components/views/contacts/contactLists/ContactListDetails.vue'),
    },
  ],
}

export const contactsSegmentDetailsRoute: TmNamedRouteRecord = {
  name: 'user.contacts.segments.details',
  path: `:${SEGMENT_ID_PARAM_NAME}`,
  props: (route) => {
    const segmentId = route.params[SEGMENT_ID_PARAM_NAME]

    return {
      segmentId,
      key: segmentId,
    }
  },
  meta: {
    titler: {
      service: 'DomainTitlerService',
      params: {
        model: Segment,
        field: 'name',
        idParamName: SEGMENT_ID_PARAM_NAME,
      } as DomainTitlerParams,
    },
    modules: [AppModule.FilteredViews, AppModule.Segments],
    resolverComponent: () => import('@/components/resolvers/AsyncResolver.vue'),
    resolvers: [
      {
        service: 'RedirectableSingleModelResolverService',
        params: {
          model: Segment,
          source: 'router',
          requestParam: SEGMENT_ID_PARAM_NAME,
          redirectToIfNotFound: { name: 'user.contacts.segments.index' },
          cache: false,
        } satisfies RedirectableSingleResolverParams,
      },
    ],
  },
  component: () => import('@/components/views/contacts/segments/SegmentDetails.vue'),
}

export const contactsSegmentCreateRoute: TmNamedRouteRecord = {
  name: 'user.contacts.segments.new',
  path: 'new',
  props: (route) => ({
    name: route.query[SEGMENT_DRAFT_NAME_PARAM_NAME],
  }),
  meta: {
    modules: [AppModule.FilteredViews, AppModule.Segments],
    accessControlGroups: ['SegmentsStrategy'],
    resolvers: [{ service: 'SegmentCreateParamsResolverService' }],
  },
  component: () => import('@/components/views/contacts/segments/SegmentCreate.vue'),
}

export const contactsSegmentsRoute: TmNamedRouteRecord = {
  name: 'user.contacts.segments.index',
  path: 'segments',
  meta: {
    tabTitle: 'pageTitle.user.contacts.segments',
    tags: ['CONTACTS_BAR_LIST'],
    modules: [AppModule.FilteredViews, AppModule.Segments],
    accessControlGroups: ['SegmentsStrategy'],
  },
  redirect: { name: 'user.contacts.segments' },
  children: [
    {
      name: 'user.contacts.segments',
      path: '',
      component: () => import('@/components/views/contacts/segments/Segments.vue'),
    },
    contactsSegmentDetailsRoute,
    contactsSegmentCreateRoute,
  ],
}

export const contactsImportsRoute: TmNamedRouteRecord = {
  name: 'user.contacts.imports.index',
  path: 'imports',
  meta: {
    tags: ['CONTACTS_BAR_LIST'],
    permission: 'importContacts',
  },
  redirect: { name: 'user.contacts.imports' },
  children: [
    {
      name: 'user.contacts.imports',
      path: '',
      meta: {
        modules: [AppModule.ContactsImportList, AppModule.ContactsImportDetails],
        tabTitle: 'pageTabTitle.user.contacts.imports',
      },
      component: () => import('@/components/views/contacts/imports/Imports.vue'),
    },
    {
      name: 'user.contacts.imports.details',
      path: 'details/:importId',
      props: true,
      meta: {
        tabTitle: 'pageTabTitle.user.contacts.importDetails',
        detailsPage: {
          source: 'router',
          requestParam: 'importId',
          model: ContactImport,
        } as SingleModelResolverParams,
        redirectToIfNotFound: { name: 'user.contacts.imports' },
        resolvers: [
          { service: 'ContactsImportDetailsResolverService' },
          {
            service: 'FacetResolver',
            params: {
              model: ContactImportResult,
              source: 'router',
              requestParam: 'importId',
              subscriptionModels: [ContactImportResult],
              clearable: true,
            },
          },
        ],
        modules: [AppModule.ContactsImportDetails],
        parent: 'user.contacts.imports',
      },
      component: () => import('@/components/views/contacts/imports/ImportDetails.vue'),
      redirect: { name: 'user.contacts.imports.details.imported' },
      children: [
        {
          name: 'user.contacts.imports.details.imported',
          path: 'imported',
          component: () => import('@/components/views/contacts/imports/results/ContactsImported.vue'),
        },
        {
          name: 'user.contacts.imports.details.duplicates',
          path: 'duplicates',
          component: () => import('@/components/views/contacts/imports/results/ContactsDuplicates.vue'),
        },
        {
          name: 'user.contacts.imports.details.failed',
          path: 'failed',
          component: () => import('@/components/views/contacts/imports/results/ContactsFailed.vue'),
        },
        {
          name: 'user.contacts.imports.details.excluded',
          path: 'excluded',
          component: () => import('@/components/views/contacts/imports/results/ContactsExcluded.vue'),
        },
      ],
    },
    {
      name: 'user.contacts.import',
      path: 'import-contacts',
      meta: {
        resolvers: [{ service: 'ContactImportResolver' }],
        resolverComponent: () => import('@/components/resolvers/ContactImportResolver.vue'),
        modules: [AppModule.ContactsImportDetails, AppModule.ContactsImportFlow, AppModule.CarrierLookup],
        tabTitle: 'pageTabTitle.user.contacts.import',
      },
      component: () => import('@/components/views/contacts/import/ContactImport.vue'),
    },
  ],
}

export const contactsUnsubscribedSmsRoute: TmNamedRouteRecord = {
  name: contactsUnsubscribedSmsRouteName,
  path: '',
  component: () => import('@/components/views/contacts/unsubscribed/UnsubscribedSms.vue'),
}

export const contactsUnsubscribedEmailRoute: TmNamedRouteRecord = {
  name: contactsUnsubscribedEmailRouteName,
  path: 'email',
  meta: {
    accessControlGroups: ['UnsubscribedEmailStrategy'],
  },
  component: () => import('@/components/views/contacts/unsubscribed/UnsubscribedEmail.vue'),
}

export const contactsUnsubscribedRoute: TmNamedRouteRecord = {
  name: 'user.contacts.unsubscribed',
  path: 'unsubscribed',
  meta: {
    tabTitle: 'pageTabTitle.user.contacts.unsubscribed',
    tags: ['CONTACTS_BAR_LIST'],
  },
  component: () => import('@/components/views/contacts/unsubscribed/Unsubscribed.vue'),
  redirect: { name: contactsUnsubscribedSmsRoute.name },
  children: [contactsUnsubscribedSmsRoute, contactsUnsubscribedEmailRoute],
}

export const contactsBlockedRoute: TmNamedRouteRecord = {
  name: 'user.contacts.blockedContacts',
  path: 'blocked',
  meta: {
    tabTitle: 'pageTabTitle.user.contacts.blocked',
    tags: ['CONTACTS_BAR_LIST'],
  },
  component: () => import('@/components/views/contacts/blockedContacts/BlockedContacts.vue'),
}

export const contactsRoute: TmNamedRouteRecord = {
  name: 'user.contacts',
  path: 'contacts',
  meta: {
    tags: ['USER_TOP_MENU'],
    tabTitle: 'pageTitle.user.contacts.contacts',
    menu: ContactsMenu,
    permission: 'contacts',
    redirectToFirstChildren: false,
    resolvers: [
      {
        service: 'ContactsResolver',
      },
      {
        service: 'FacetResolver',
        params: {
          model: Contact,
          subscriptionModels: [
            ContactList,
            Contact,
            CustomFieldSchema,
            ContactImport,
            UnsubscribedContact,
            BlockedContact,
            FilteredView,
            Segment,
          ],
        },
      },
      {
        service: 'FilteredViewServerEventsResolverService',
      },
    ],
    resolverComponent: () => import('@/components/resolvers/AsyncResolver.vue'),
    modules: [
      AppModule.Contacts,
      AppModule.ContactList,
      AppModule.ContactCounter,
      AppModule.ContactsUnsubscribed,
      AppModule.ContactsBlocked,
      AppModule.FilteredViews,
      AppModule.Segments,
      AppModule.PinnedContacts,
      AppModule.Tags,
    ],
  },
  component: () => import('@/components/views/contacts/Contacts.vue'),
  children: [
    contactsMyRoute,
    contactsListsRoute,
    contactsSegmentsRoute,
    contactsImportsRoute,
    contactsUnsubscribedRoute,
    contactsBlockedRoute,
  ],
}

import TmLogicError from '@/core/error/tmLogicError'

export interface IQueue<T> {
  next(condition?: (item: T) => boolean): IteratorResult<T>
  add(item: T): void
  remove(item: T)
  filter(condition: (item: T) => boolean): T[]
  cutSubQueue(condition: (item: T) => boolean): IQueue<T>
  search(uid: string | number): T | null
  size: number
  [Symbol.iterator](): IQueue<T>
}

export class Queue<T> implements IQueue<T> {
  private array: T[] = []

  private map: Map<string | number, T>

  constructor(private readonly idPropName?: string) {
    if (this.idPropName) {
      this.map = new Map()
    }
  }

  public next(condition?: (item: T) => boolean): IteratorResult<T> {
    let result: T | undefined
    if (condition) {
      const i = this.array.findIndex(condition)
      if (i >= 0) {
        result = this.array[i]
        this.removeByIndex(i)
      }
    } else {
      result = this.array.shift()
      if (result && this.idPropName) {
        this.map.delete(result[this.idPropName])
      }
    }
    if (!result) {
      return {
        value: null,
        done: true,
      }
    }
    return {
      value: result,
      done: false,
    }
  }

  public add(item: T): void {
    this.array.push(item)
    if (this.idPropName) {
      this.map.set(item[this.idPropName], item)
    }
  }

  public remove(item: T) {
    const i = this.array.findIndex((el) => el === item)
    this.removeByIndex(i)
  }

  public filter(condition: (item: T) => boolean): T[] {
    return this.array.filter(condition)
  }

  public cutSubQueue(condition: (item: T) => boolean): IQueue<T> {
    const result = new Queue<T>(this.idPropName)
    const arr = this.array
    this.array = []
    if (this.map) {
      this.map.clear()
    }
    for (const item of arr) {
      if (condition(item)) {
        result.add(item)
      } else {
        this.add(item)
      }
    }
    return result
  }

  public get size(): number {
    return this.array.length
  }

  public search(uid: string | number): T | null {
    if (!this.size) {
      return null
    }
    let result: T | undefined
    if (this.isPrimitive(this.array[0])) {
      result = this.array.find((item: T) => item === uid)
    } else {
      if (!this.idPropName) {
        throw new TmLogicError('This method requires "idPropName" parameter in constructor')
      }
      result = this.map.get(uid)
    }
    if (!result) {
      return null
    }
    return result
  }

  public [Symbol.iterator]() {
    return this
  }

  private removeByIndex(index: number) {
    if (index >= 0) {
      if (this.idPropName) {
        this.map.delete(this.array[index][this.idPropName])
      }
      this.array.splice(index, 1)
    }
  }

  private isPrimitive(value: unknown): boolean {
    return typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean'
  }
}

<template>
  <i
    class="tm-icon"
    :class="[
      iconClass,
      {
        'tm-icon--bold': bold,
        [`tm-icon--rotate${rotate}`]: rotate,
        'tm-icon--mirror': mirror,
        'tm-icon--disabled': disabled,
        [`color--${color}`]: !!color,
        'tm-icon--left': left,
        'tm-icon--right': right,
      },
    ]"
  />
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, type ComponentPropsOptions } from '@/composition/vue/compositionApi'
import type { SizeProp, IconSize, IconRotate } from '@/components/shared/types'
import { getIconSizePx } from '@/composition/icon'
import type { IconName } from '@/assets/icons/icons'
import type { StyleColors } from '@/constants/styleColors'

export const tmIconProps = {
  name: {
    type: String as PropType<IconName>,
    required: true,
  },
  size: {
    type: String as SizeProp<IconSize>,
    default: 'medium',
  },
  rotate: {
    type: Number as PropType<IconRotate>,
  },
  mirror: {
    type: Boolean,
  },
  bold: {
    type: Boolean,
  },
  customSize: {
    type: String,
    default: '',
  },
  color: {
    type: String as PropType<StyleColors>,
  },
  disabled: {
    type: Boolean,
  },
  left: {
    type: Boolean,
  },
  right: {
    type: Boolean,
  },
} satisfies ComponentPropsOptions

export type TmIconPropsType = {
  name: IconName
  size?: IconSize
  rotate?: IconRotate
  mirror?: boolean
  bold?: boolean
  customSize?: string
  color?: StyleColors
  disabled?: boolean
  left?: boolean
  right?: boolean
}

export default defineComponent({
  props: {
    ...tmIconProps,
  },
  setup(props) {
    const iconClass = computed(() => {
      if (!props.name) {
        return ''
      }
      if (props.name.startsWith('tmi-')) {
        return `tmi ${props.name}`
      }
      return `mdi-r mdi-${props.name}`
    })

    const iconSize = computed(() => props.customSize || getIconSizePx(props.size))

    return {
      iconClass,
      iconSize,
    }
  },
})
</script>

<style lang="scss" scoped>
.tm-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  user-select: none;
  line-height: 1;
  font-size: v-bind(iconSize) !important;
  width: v-bind(iconSize);
  height: v-bind(iconSize);
  position: relative;
  box-sizing: content-box;

  &::before,
  &::after {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--rotate45 {
    transform: rotate(45deg);
  }
  &--rotate90 {
    transform: rotate(90deg);
  }
  &--rotate180 {
    transform: rotate(180deg);
  }
  &--rotate295 {
    transform: rotate(295deg);
  }
  &--mirror {
    transform: scale(-1, 1);
  }
  &--bold {
    font-weight: $bold-font;
  }
  &--disabled {
    color: $gray5;
  }
  &--left {
    margin-right: 6px;
  }
}
// fix horizontal alignment
.tm-icon.tmi:before {
  display: inline !important;
}
</style>

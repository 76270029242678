import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import PrimaryBrowserTab from '@/data/models/PrimaryBrowserTab'
import OrmLocalStorageRepository from '@/data/repositories/ormLocalStorageRepository'

export type PrimaryBrowserTabPayload = {
  tabId: string
  timestamp: number
}

@RepoSettings({
  model: PrimaryBrowserTab,
  fetch: PrimaryBrowserTab.entity,
  single: PrimaryBrowserTab.entity,
  create: PrimaryBrowserTab.entity,
})
@injectable()
export default class PrimaryBrowserTabRepository extends OrmLocalStorageRepository<PrimaryBrowserTab> {
  protected id = '1'

  public saveTabData(payload: PrimaryBrowserTabPayload) {
    return this.postRequest({
      id: this.id,
      ...payload,
    })
  }

  public getTabData() {
    return this.getRequest<Partial<PrimaryBrowserTab>>(this.id)
  }

  public removeTabData() {
    return this.deleteRequest(this.id)
  }
}

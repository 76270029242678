import { AttrField, HasManyByField, NumberField } from 'vuex-orm-decorators'
import TaskStage from '@/data/models/domain/tasks/TaskStage'
import KanbanBoardBase from '@/data/models/domain/kanban/kanbanBoardBase'
import type { Nullable } from '@/types'

export default class TaskBoardBase extends KanbanBoardBase {
  @NumberField(null)
  public taskCount: Nullable<number>

  @AttrField([], (value: number[]) => value?.map((item) => item.toString()))
  public stageIds: string[]

  @HasManyByField(TaskStage, 'stageIds')
  public stages: TaskStage[]
}

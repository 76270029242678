<template>
  <tm-auto-size-panel class="mb-4">
    <template v-slot:dropdown-activator="{ visibleItemsCount }">
      <tm-auto-size-panel-mobile-activator :visible-items-count="visibleItemsCount" />
    </template>
    <tm-auto-size-panel-item-button
      v-for="i in 4"
      :key="i"
      :loading="true"
    />
  </tm-auto-size-panel>
  <tm-skeleton-details-table />
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmSkeletonDetailsTable from '@/components/shared/skeletons/TmSkeletonDetailsTable.vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelMobileActivator from '@/components/shared/autoSize/panel/TmAutoSizePanelMobileActivator.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'

export default defineComponent({
  components: {
    TmAutoSizePanelItemButton,
    TmAutoSizePanelMobileActivator,
    TmAutoSizePanel,
    TmSkeletonDetailsTable,
  },
})
</script>

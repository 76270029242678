import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type { CustomFieldSchemaService } from '@/services/domain/user/customFieldSchemaService'

@injectable()
export default class UserCustomFieldsEventResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.CustomFieldSchemaService)
    protected readonly customFieldSchemaService: CustomFieldSchemaService,
  ) {}

  public async resolve() {
    this.customFieldSchemaService.subscribe()
  }

  public async unresolve() {
    this.customFieldSchemaService.unsubscribe()
  }
}

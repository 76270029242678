import { defineAsyncComponent } from '@/composition/vue/compositionApi'
import { defineAsyncComponentRetryable } from '@/composition/vue/defineAsyncComponentRetryable'
import type { WrapperConfig, WrapperConfigItemModal, Wrappers } from '@/services/wrappers/types'
import TmSkeletonDetailsTable from '@/components/shared/skeletons/TmSkeletonDetailsTable.vue'
import {
  SelectingContactsListsTable,
  SelectingContactsTable,
  SelectingSegmentsTable,
} from '@/components/views/contacts/types'
import { ConfiguredSkeleton } from '@/components/shared/skeletons/configuredSkeleton/utils'
import TmConfiguredSkeleton from '@/components/shared/skeletons/configuredSkeleton/TmConfiguredSkeleton.vue'
import type { ConfiguredSkeletonConfig } from '@/components/shared/skeletons/configuredSkeleton/types'
import SentSmsFull from '@/data/models/domain/SentSmsFull'
import ReceivedSms from '@/data/models/domain/ReceivedSms'
import InboundCall from '@/data/models/domain/InboundCall'
import OutboundCall from '@/data/models/domain/OutboundCall'
import type { TranslationKey } from '@/services/types'
import type { NavigationOrderResolverParams, NotFoundResolverParams } from '@/services/resolvers/types'
import Billing from '@/data/models/domain/billing/Billing'
import type { Maybe } from '@/types'
import type SmsSurveyNode from '@/data/models/domain/services/smsSurveys/SmsSurveyNode'
import { getSmsSurveyNodesService, getTranslateService } from '@/core/container/ioc'
import UsersWorkflowTeamPlanModalPreloader from '@/components/domain/accounts/usersWorkflow/UsersWorkflowTeamPlanModalPreloader.vue'
import { AppModule } from '@/config/types'
import type { ScheduledRetryModalParams } from '@/composition/domain/scheduled'
import TenDlcSelectCampaignDetailsModalPreloader from '@/components/domain/tenDlcTollFree/TenDlcSelectCampaignDetailsModalPreloader.vue'
import TenDlcBrandDetailsModalPreloader from '@/components/domain/services/tenDlc/TenDlcBrandDetailsModalPreloader.vue'
import type { MessagesAttachmentFileModalParams } from '@/composition/domain/messages'
import StripePaygPrice from '@/data/models/domain/billing/stripe/StripePaygPrice'
import TaskBoard from '@/data/models/domain/tasks/TaskBoard'
import { TasksRoutes } from '@/routes/user/tasks/types'
import TicketInbox from '@/data/models/domain/tickets/TicketInbox'
import TicketStatus from '@/data/models/domain/tickets/TicketStatus'
import type { ChatAssignModalModalParams } from '@/composition/domain/chats/chats'
import { FilteredViewsSupportedEntities } from '@/data/models/domain/filteredViews/types'
import type { DowngradeToEssentialModalParams } from '@/composition/domain/plans'
import TicketSpamFilter, { TICKET_SPAM_FILTER_ID } from '@/data/models/domain/tickets/TicketSpamFilter'
import TicketWorkspace, { TICKET_WORKSPACE_ID } from '@/data/models/domain/tickets/TicketWorkspace'
import { ticketFilteredViewPreviewModalId, ticketSaveViewAsModalId } from '@/services/domain/tickets/modals/const'
import DealPipeline from '@/data/models/domain/deals/DealPipeline'
import { DealsRoutes } from '@/routes/user/deals/types'
import type { ModalDialogConfig } from '@/components/shared/modals/tmModal/types'
import {
  segmentCreateModalId,
  segmentDeleteModalId,
  segmentRenameModalId,
  segmentSaveAsModalId,
} from '@/composition/domain/contacts/segments/types'
import { contactTagsBulkModalId } from '@/composition/domain/contacts'

export const wrappersConfig: WrapperConfig<TmWrappers> = {
  confirmation: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/TmConfirmationModal.vue')),
    modalConfig: {
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow('200px'),
      ]),
    },
  },
  confirmationDelete: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/TmConfirmationDeleteModal.vue')),
    modalConfig: {
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.textRow(), ConfiguredSkeleton.textRow('200px')]),
    },
  },
  confirmationPassword: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/TmConfirmationPasswordModal.vue')),
    modalConfig: {
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow('100px', 'mb-3'),
        ConfiguredSkeleton.labeledInput('80px'),
      ]),
    },
  },
  prompt: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/TmPromptModal.vue')),
    modalConfig: {
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow('200px'),
      ]),
    },
  },
  export: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/shared/modals/TmExportModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'exportModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow('250px', 'mb-3'),
        ConfiguredSkeleton.labeledInput('96px'),
      ]),
    },
  },
  smsSurveys: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/smsSurveys/flow/CreateSmsSurveysModal.vue'),
    ),
    modalConfig: {
      size: 'large',
      titleMessagePath: 'createSmsSurveyModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('150px'),
        ConfiguredSkeleton.input('100%', 'mb-2'),
        ConfiguredSkeleton.textRow('150px'),
        [
          ConfiguredSkeleton.box('mb-2 ', '30%', '160px'),
          ConfiguredSkeleton.box('mb-2 mx-auto', '30%', '160px'),
          ConfiguredSkeleton.box('mb-2 ', '30%', '160px'),
        ],
        [
          ConfiguredSkeleton.box('mb-2 ', '30%', '160px'),
          ConfiguredSkeleton.box('mb-2 mx-auto', '30%', '160px'),
          ConfiguredSkeleton.box('mb-2 ', '30%', '160px'),
        ],
      ] satisfies ConfiguredSkeletonConfig),
    },
  },
  smsSurveyRecipients: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/smsSurveys/flow/recipients/SmsSurveyRecipientsModal.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
      titleMessagePath: 'smsSurveyAddRecipientsModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('100px', 'mb-2'),
        ConfiguredSkeleton.box('', '600px', '120px'),
        ConfiguredSkeleton.textRow('600px', 'mt-1'),
      ] satisfies ConfiguredSkeletonConfig),
    },
  },
  editSmsSurveyName: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/smsSurveys/flow/header/SmsSurveyEditNameModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'smsSurveyEditNameModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput()]),
    },
  },
  scheduleSmsSurvey: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/smsSurveys/flow/footer/ScheduleSmsSurveyModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'smsSurveyScheduleModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('70px'),
        ConfiguredSkeleton.input('436px', 'mt-1'),
        ConfiguredSkeleton.textRow('70px', 'mt-2'),
        ConfiguredSkeleton.input('436px', 'mt-1'),
        ConfiguredSkeleton.textRow('100%', 'mt-1'),
        ConfiguredSkeleton.textRow('50px', 'mt-1'),
      ] satisfies ConfiguredSkeletonConfig),
    },
  },
  previewSmsSurvey: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/smsSurveys/flow/preview/SmsSurveyPreviewModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: 'smsSurveyPreviewModal.title' satisfies TranslationKey,
      size: 'large',
      noFooter: true,
      preloader: {
        content: TmConfiguredSkeleton,
        props: {
          config: [{ width: '362px', height: '466px', classes: 'mx-auto' }] satisfies ConfiguredSkeletonConfig,
        },
      },
    },
  },
  smsSurveyQuestion: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/smsSurveys/flow/SmsSurveyQuestionModal.vue'),
    ),
    modalConfig: {
      size: 'large',
      titleMessagePath: (params) =>
        params.editingId ? 'smsSurveyQuestionModal.title.edit' : 'smsSurveyQuestionModal.title.add',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.input('490px'),
        ConfiguredSkeleton.box('mt-1', '490px', '150px'),
        ConfiguredSkeleton.textRow('490px', 'mt-1'),
      ] satisfies ConfiguredSkeletonConfig),
    },
  },
  smsSurveyQuestionDelay: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/smsSurveys/flow/SmsSurveyQuestionDelayModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: (params) => {
        const node: Maybe<SmsSurveyNode> =
          'editingId' in params && params.editingId
            ? getSmsSurveyNodesService().findEntityByIdOrNull(params.editingId)
            : undefined
        return node?.sendDelay ? 'smsSurveyQuestionDelayModal.title.edit' : 'smsSurveyQuestionDelayModal.title.add'
      },
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('50%'),
        [ConfiguredSkeleton.input('132px'), ConfiguredSkeleton.input('132px', 'ml-2')],
      ]),
    },
  },
  smsSurveyReply: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/smsSurveys/flow/SmsSurveyReplyModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: (params) =>
        params.editingId ? 'smsSurveyReplyModal.title.edit' : 'smsSurveyReplyModal.title.add',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.textRows(2, '20%'),
      ]),
    },
  },
  smsSurveyFinalMessage: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/smsSurveys/flow/SmsSurveyFinalMessageModal.vue'),
    ),
    modalConfig: {
      size: 'large',
      titleMessagePath: (params) =>
        params.editingId ? 'smsSurveyFinalMessageModal.title.edit' : 'smsSurveyFinalMessageModal.title.add',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput('490px'),
        ConfiguredSkeleton.box('mt-1', '100px', '20px'),
        ConfiguredSkeleton.box('mt-1', '490px', '150px'),
        ConfiguredSkeleton.textRow('490px', 'mt-1'),
      ]),
    },
  },
  phoneNumberLabel: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/phoneNumbers/phoneNumbersLabelModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: ({ editingId }) =>
        editingId ? 'tmNumberLabelModal.editTitle' : 'tmNumberLabelModal.createTitle',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput(), ConfiguredSkeleton.labeledInput()]),
    },
  },
  templates: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/templates/modals/TemplateModal.vue')),
    modules: [AppModule.Templates, AppModule.Compose],
    modalConfig: {
      size: 'large',
      titleMessagePath: (params) => {
        return params.editingId ? 'entities.template.editTemplate' : 'entities.template.newTemplate'
      },
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput('110px'),
        [ConfiguredSkeleton.textRow('80px', 'mt-1'), ConfiguredSkeleton.textRow('85px', 'ml-auto mt-1')],
        ConfiguredSkeleton.box('mt-2', '100%', '150px'),
        ConfiguredSkeleton.textRow('200px', 'ml-auto mt-1 mb-4'),
        ConfiguredSkeleton.labeledInput('80px'),
      ]),
    },
  },
  templateShare: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/templates/modals/TemplateShareModal.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
      noFooter: true,
      titleMessagePath: 'templateShareModal.title',
      preloader: ConfiguredSkeleton.forAbstractTable(),
    },
  },
  templateSelect: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/templates/modals/templateSelectModal/TemplateSelectModal.vue'),
    ),
    modules: [AppModule.Templates],
    modalConfig: {
      size: 'xLarge',
      noFooter: true,
      titleMessagePath: 'entities.template.modalTitle',
      preloader: ConfiguredSkeleton.forAbstractTable(),
    },
  },
  templatesEditCategory: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/templates/modals/TemplatesEditCategoryModal.vue'),
    ),
    modules: [AppModule.Templates],
    modalConfig: {
      titleMessagePath: 'entities.templateCategory.editCategory',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('80%', 'mb-5'),
        ConfiguredSkeleton.labeledInput('222px'),
      ]),
    },
  },
  templateCategory: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/templates/modals/TemplateCategoryModal.vue'),
    ),
    modules: [AppModule.Templates],
    modalConfig: {
      size: 'small',
      titleMessagePath: (params) => {
        return params.editingId ? 'templateCategoryModal.renameTitle' : 'templateCategoryModal.createTitle'
      },
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput('115px')]),
    },
  },
  templateCategoryDelete: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/templates/modals/TemplateCategoryDeleteModal.vue'),
    ),
    modules: [AppModule.Templates],
    modalConfig: {
      size: 'small',
      titleMessagePath: 'templateCategoryDeleteModal.title',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.textRows(2, '50%')]),
    },
  },
  UnsubscribeContactModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/contacts/unsubscribed/UnsubsribeContactModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'UnsubsribeContactModal.modalTitle',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow('250px', 'mb-3'),
        ConfiguredSkeleton.checkboxRow(),
      ]),
    },
  },
  addContactsChooseSourceModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/contacts/contactLists/AddContactsChooseSourceModal.vue'),
    ),
    modalConfig: {
      size: 'medium',
      noFooter: true,
      titleMessagePath: 'addContactsChooseSourceModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        [ConfiguredSkeleton.box('', '260px', '180px'), ConfiguredSkeleton.box('ml-auto', '260px', '180px')],
      ]),
    },
  },
  accountDetails: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/settings/AccountDetailsEditModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'accountDetailsEditModal.title',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInputs(8)]),
    },
  },
  subAccountDetails: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/accounts/SubAccountEditModal.vue')),
    modules: [AppModule.SubAccountsForm],
    modalConfig: {
      size: 'small',
      titleMessagePath: ({ titleMessagePath }) => titleMessagePath,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInputs(5),
        ConfiguredSkeleton.textRow('30px', 'mt-1 mb-3'),
        ConfiguredSkeleton.radioTitleAndText(1, '50%', 'mb-5'),
        ConfiguredSkeleton.radioTitleAndText(1),
      ]),
    },
  },
  contactList: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/contacts/contactLists/ContactListModal.vue')),
    modalConfig: {
      titleMessagePath: ({ editingId }) => (editingId ? 'ContactListForm.editTitle' : 'ContactListForm.addTitle'),
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.horizontalLabeledInput(),
        ConfiguredSkeleton.checkboxRow(2, '50%', 'ml-155px mb-4', 'ml-135px mr-2'),
        ConfiguredSkeleton.checkboxRow(1, '100%', 'mb-1', 'ml-135px mr-2'),
        ConfiguredSkeleton.button('120px', 'ml-auto'),
      ]),
    },
  },
  contactListDeleteModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/contacts/lists/DeleteListModal.vue')),
    modalConfig: {
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.checkboxRow(),
        ConfiguredSkeleton.button('120px', 'ml-auto'),
      ]),
    },
  },
  contactListBulkDeleteModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/contacts/lists/BulkDeleteListsModal.vue')),
    modalConfig: {
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.checkboxRow(),
        ConfiguredSkeleton.button('120px', 'ml-auto'),
      ]),
    },
  },
  contactListTempDetails: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/contacts/contactLists/ContactListTempDetailsModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: ({ name }) => name,
      size: 'xLarge',
      noFooter: true,
    },
  },
  contact: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/contacts/myContacts/modal/ContactModal.vue')),
    modules: [AppModule.Tags],
    modalConfig: {
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInputs(4),
        ConfiguredSkeleton.button('120px', 'ml-auto'),
      ]),
    },
  },
  contactListMoveCopyModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/contacts/contactLists/ContactListMoveCopyModal.vue'),
    ),
    modalConfig: {
      // title is dynamic (doesn't work now)
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('100%', 'mb-4'),
        ConfiguredSkeleton.radioTitleAndText(2),
        ConfiguredSkeleton.radioTitleAndText(2),
      ]),
    },
  },
  blockedContact: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/contacts/blocked/BlockedContactModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'blockedContactForm.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.button('120px', 'ml-auto'),
      ]),
    },
  },
  [contactTagsBulkModalId]: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/contacts/myContacts/modal/ContactBulkModal.vue')),
    modules: [AppModule.Tags],
    modalConfig: {
      size: 'large',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInputs(1),
        ConfiguredSkeleton.button('120px', 'ml-auto'),
      ]),
    },
  },
  [segmentCreateModalId]: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/contacts/segments/modal/SegmentCreateModal.vue')),
    modules: [AppModule.FilteredViews, AppModule.Segments],
    modalConfig: {
      size: 'small',
      titleMessagePath: 'segmentCreateModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.button('120px', 'ml-auto'),
      ]),
    },
  },
  [segmentDeleteModalId]: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/contacts/segments/modal/SegmentDeleteModal.vue')),
    modules: [AppModule.FilteredViews, AppModule.Segments],
    modalConfig: {
      size: 'small',
      titleMessagePath: 'segmentDeleteModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.button('120px', 'ml-auto'),
      ]),
    },
  },
  [segmentRenameModalId]: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/contacts/segments/modal/SegmentRenameModal.vue')),
    modules: [AppModule.FilteredViews, AppModule.Segments],
    modalConfig: {
      size: 'small',
      titleMessagePath: 'segmentRenameModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.button('120px', 'ml-auto'),
      ]),
    },
  },
  [segmentSaveAsModalId]: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/contacts/segments/modal/SegmentSaveViewAsModal.vue'),
    ),
    modules: [AppModule.FilteredViews, AppModule.Segments],
    modalConfig: {
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.button('120px', 'ml-auto'),
      ]),
    } satisfies ModalDialogConfig,
  },
  receivedSmsFilterModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/history/sms/received/ReceivedSmsFilterModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'filters.receivedSmsModalTitle' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow('100px'),
      ]),
    },
  },
  sentSmsFilterModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/history/sms/sent/SentSmsFilterModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'filters.sentSmsModalTitle' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.textRow(), ConfiguredSkeleton.textRow()]),
    },
  },
  chooseAudioModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/forwardAudio/ChooseAudioModal.vue')),
    modalConfig: {
      size: 'xLarge',
      preloader: ConfiguredSkeleton.forAbstractTable(),
      getTitle: (params) => params.translations.title,
    },
  },
  allContactListModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/contacts/contactLists/AllContactListModal.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
      titleMessagePath: 'entities.contactList.filterLists' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.forAbstractTable(),
    },
  },
  selectingContacts: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/contacts/selectingContactsModal/SelectingContactsModalContent.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
      leftAsideWidth: '28px',
      minHeight: '702px',
      maxHeight: '702px',
      scrollable: true,
      noFooter: true,
      titleMessagePath: 'selectingContactsModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.forTableWithServiceId(SelectingContactsTable, {
        leftControls: ['100px', '120px'],
        rightControls: ['240px'],
      }),
    },
  },
  selectingContactsProgress: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/contacts/selectingContactsModal/SelectingContactsProgressModal.vue'),
    ),
    modalConfig: {
      size: 'large',
      titleMessagePath: 'selectingContactsProgressModal.modalTitle' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.box('', '100%', '146px')]),
    },
  },
  selectingContactsLists: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/contacts/selectingContactsListsModal/SelectingContactsListsModalContent.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
      titleMessagePath: 'selectingContactsListsModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.forTableWithServiceId(SelectingContactsListsTable, {
        leftControls: ['100px', '150px'],
        rightControls: ['240px'],
      }),
    },
  },
  selectingSegments: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/contacts/selectingSegmentsModal/SelectingSegmentsModalContent.vue'),
    ),
    modules: [AppModule.FilteredViews, AppModule.Segments],
    modalConfig: {
      size: 'xLarge',
      titleMessagePath: 'selectingSegmentsModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.forTableWithServiceId(SelectingSegmentsTable, {
        leftControls: ['100px', '150px'],
        rightControls: ['240px'],
      }),
    },
  },
  customFieldsSchemaModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/contacts/customFields/CustomFieldSchemaModal.vue'),
    ),
    modules: [AppModule.CustomFields],
    modalConfig: {
      size: 'small',
      titleMessagePath: ({ editingId }) =>
        editingId ? 'customFieldModalUpdate.title' : 'customFieldModalCreate.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.button('120px', 'ml-auto'),
      ]),
    },
  },
  previewModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/shared/TmPreviewModal.vue')),
    modalConfig: {
      size: 'medium',
      titleMessagePath: 'attachmentPreview.title' satisfies TranslationKey,
    },
  },
  senderIdApplyModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/services/senderSettings/SenderIdApplyModal.vue')),
    modalConfig: {
      size: 'large',
      titleMessagePath: 'senderIdApplyModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        [ConfiguredSkeleton.textRow('150px', 'ml-auto mr-4 mt-3'), ConfiguredSkeleton.input('400px')],
        ConfiguredSkeleton.textRow('400px', 'ml-auto mt-1'),
        ConfiguredSkeleton.textRow('400px', 'ml-auto mt-1'),
        [ConfiguredSkeleton.textRow('200px', 'ml-auto mr-4 mt-5'), { width: '400px', height: '80px', classes: 'mt-3' }],
        ConfiguredSkeleton.textRow('400px', 'ml-auto mt-1'),
        ConfiguredSkeleton.textRow('400px', 'ml-auto mt-1'),
        [ConfiguredSkeleton.textRow('50px', 'ml-auto mr-4 mt-3'), ConfiguredSkeleton.textRow('400px', ' mt-3')],
        ConfiguredSkeleton.textRow('400px', 'ml-auto mt-1'),
        [ConfiguredSkeleton.box('mt-8', '50px', '14px'), ConfiguredSkeleton.button('120px', 'mt-5 ml-auto')],
      ]),
    },
  },
  allowedEmailsAddModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/allowedEmails/AllowedEmailsAddModalContent.vue'),
    ),
    modalConfig: {
      size: 'medium',
      titleMessagePath: 'allowedEmailsAddModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        { width: '100%', height: '110px', classes: 'mb-1' },
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow('50px', ''),
      ]),
    },
  },
  allowedEmailsEditSettingsModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/services/allowedEmails/AllowedEmailsEditSettingsModal.vue'),
    ),
    modalConfig: {
      size: 'medium',
      titleMessagePath: 'allowedEmailsEditSettingsModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.textRow('90px', 'my-4'),
        ConfiguredSkeleton.checkboxRow(1, '50%', 'mb-5', 'mb-4 mr-2'),
        ConfiguredSkeleton.checkboxRow(1, '50%', 'mb-5', 'mb-4 mr-2'),
        ConfiguredSkeleton.checkboxRow(1, '50%', 'mb-5', 'mb-4 mr-2'),
        [ConfiguredSkeleton.box('mt-3', '50px', '14px'), ConfiguredSkeleton.button('120px', 'ml-auto')],
      ]),
    },
  },
  messagesAttachmentFileModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/shared/modals/TmMessagesAttachmentFileModal.vue'),
    ),
    modalConfig: {
      size: 'medium',
      getTitle: (params: MessagesAttachmentFileModalParams) => {
        const translateService = getTranslateService()
        return params.title || translateService.t('messagesAttachmentFileModal.attachFile')
      },
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.box('mb-6', '100%', '240px'),
        ConfiguredSkeleton.textRow('350px', 'mb-2'),
        ConfiguredSkeleton.textRow('100%', 'mb-2'),
        ConfiguredSkeleton.textRow('370px', ''),
      ]),
    },
  },
  messagesAttachmentFilePreviewModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/shared/modals/TmMessagesAttachmentFilePreviewModal.vue'),
    ),
    modalConfig: {
      size: 'medium',
      titleMessagePath: 'messagesAttachmentFilePreviewModal.fileUploaded',
    },
  },
  messagesAttachmentSelectModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/shared/modals/TmMessagesAttachmentSelectModal.vue'),
    ),
    modalConfig: {
      size: 'medium',
      titleMessagePath: 'messagesAttachmentFileModal.attachFile' satisfies TranslationKey,
      noFooter: true,
      preloader: ConfiguredSkeleton.fromConfig([
        [ConfiguredSkeleton.box('', '48%', '200px'), ConfiguredSkeleton.box('ml-auto', '48%', '200px')],
      ]),
    },
  },
  pointAiAttachmentModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/services/pointAi/PointAiAttachmentModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'pointAiAttachmentModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        [ConfiguredSkeleton.box('', '48%', '200px'), ConfiguredSkeleton.box('ml-auto', '48%', '200px')],
      ]),
    },
  },
  pointAiAttachmentModalWebsite: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/services/pointAi/PointAiAttachmentModalWebsite.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'pointAiAttachmentModal.websiteTitle' satisfies TranslationKey,
    },
  },
  pointAiAttachmentModalDocument: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/services/pointAi/PointAiAttachmentModalDocument.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'pointAiAttachmentModal.documentTitle' satisfies TranslationKey,
    },
  },
  calendarModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/shared/calendar/modals/TmCalendarModal.vue')),
    modalConfig: {
      size: 'xLarge',
      titleMessagePath: 'tmCalendarModal.title',
      preloader: ConfiguredSkeleton.forAbstractTable(),
    },
  },
  calendarGroupModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/shared/calendar/modals/TmCalendarGroupModal.vue')),
    modalConfig: {
      size: 'medium',
      noFooter: true,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.box('', undefined, '48px'),
        ConfiguredSkeleton.box('mt-3', undefined, '48px'),
        ConfiguredSkeleton.box('mt-3', undefined, '48px'),
        ConfiguredSkeleton.box('mt-3', undefined, '48px'),
        ConfiguredSkeleton.box('mt-3', undefined, '48px'),
      ]),
    },
  },
  messageDetailsModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/shared/modals/messages/TmMessageDetailsModal.vue'),
    ),
    modules: [AppModule.HistoryInboundCalls, AppModule.HistoryReceivedSms],
    resolvers: [
      {
        service: 'ChatMessageResolverService',
        params: {
          source: 'messageDetailsModal',
        },
      },
    ],
    modalConfig: {
      size: 'large',
      titleMessagePath: (params): TranslationKey => {
        switch (params.modelType) {
          case SentSmsFull:
            return 'pageTitle.user.history.sentSmsDetails'
          case ReceivedSms:
            return 'pageTitle.user.history.receivedSmsDetails'
          case InboundCall:
            return 'pageTitle.user.history.inboundCallDetails'
          case OutboundCall:
            return 'pageTitle.user.history.outboundCallDetails'
          default:
            return 'pageTabTitle.user.history.messageDetails'
        }
      },
      preloader: {
        content: TmSkeletonDetailsTable,
      },
      noFooter: true,
    },
  } satisfies WrapperConfigItemModal,
  contactNotesModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/contacts/notes/ContactNotesModalContent.vue'),
    ),
    modalConfig: {
      size: 'large',
      titleMessagePath: 'contactNotes.notesFor' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        { width: '150px', height: '36px', classes: 'mb-2' },
        { width: '100%', height: '125px' },
      ]),
    },
  },
  chatsNotificationsModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/chats/ChatNotificationsModal.vue')),
    modalConfig: {
      size: 'large',
      titleMessagePath: 'entities.chat.message.actions.chatNotifications' satisfies TranslationKey,
      preloader: {
        content: TmConfiguredSkeleton,
        props: {
          config: [
            { width: '100%', height: '20px', classes: 'mb-5' },
            { width: '100%', height: '20px', classes: 'mb-5' },
            { width: '100%', height: '20px', classes: 'mb-5' },
            { width: '100%', height: '20px', classes: 'mb-5' },
            { width: '100%', height: '20px' },
          ] satisfies ConfiguredSkeletonConfig,
        },
      },
    },
  },
  chatConversationSenderSettingsModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/chats/ChatSenderSettingsModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'entities.chat.message.actions.senderSettings' satisfies TranslationKey,
      preloader: {
        content: TmConfiguredSkeleton,
        props: {
          config: [{ width: '100%', height: '36px', classes: 'mb-2' }] satisfies ConfiguredSkeletonConfig,
        },
      },
    },
  },
  chatFiltersSet: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/chats/ChatFiltersModal.vue')),
    modalConfig: {
      size: 'small',
      noFooter: true,
      titleMessagePath: 'entities.chat.filtersSet.filterChats',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.button('436px', 'mt-1'),
        ConfiguredSkeleton.button('436px', 'mt-1'),
        ConfiguredSkeleton.button('436px', 'mt-1'),
        ConfiguredSkeleton.button('436px', 'mt-1'),
      ]),
    },
  },
  scheduledMessageModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/scheduled/modals/ScheduledMessageModal.vue')),
    modules: [AppModule.SenderSettingsCountries],
    modalConfig: {
      size: 'xLarge',
      titleMessagePath: 'tmCalendarEventModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.forAbstractTable(),
    },
  },
  composePreviewModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/compose/modals/ComposePreviewModal.vue')),
    modalConfig: {
      size: 'large',
      preloader: ConfiguredSkeleton.detailsTableSkeleton(),
    },
  },
  composePhonePreviewModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/compose/modals/ComposePhonePreviewModal.vue')),
    modalConfig: {
      size: 'small',
    },
  },
  importMatchingFullscreenModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/shared/import/matching/ImportMatchingFullscreenModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: 'importMatchingFullscreenModal.title',
      size: 'full',
      preloader: ConfiguredSkeleton.forAbstractTable(),
    },
  },
  importMatchingCountryModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/shared/import/matching/ImportMatchingCountryModal.vue')),
    modalConfig: {
      titleMessagePath: 'importMatchingCountryModal.title',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.box('mb-5', undefined, '100px'),
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.labeledInput(),
      ]),
    },
  },
  twoFaEditIpAddressesModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/accounts/settings/twoFa/AccountSecurityTwoFaEditIpAddressesModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: 'accountSecurityTwoFaEditIpAddressesModal.title' satisfies TranslationKey,
      size: 'medium',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.box('mt-2', '100%', '112px'),
        ConfiguredSkeleton.textRow('90%', 'mt-1'),
        ConfiguredSkeleton.textRow('90%', 'mt-1'),
        ConfiguredSkeleton.button('120px', 'mt-4 ml-auto'),
      ]),
    },
  },
  passwordResetModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/accounts/settings/password/AccountSecurityPasswordResetModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'accountSecurityPasswordResetModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('100%'),
        ConfiguredSkeleton.button('120px', 'mt-4 ml-auto'),
      ]),
    },
  },
  passwordChangeModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/accounts/settings/password/AccountSecurityPasswordChangeModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'accountSecurityPasswordChangeModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInputs(4),
        ConfiguredSkeleton.button('120px', 'mt-4 ml-auto'),
      ]),
    },
  },
  phoneVerificationModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/settings/PhoneVerificationModal.vue'),
    ),
    modalConfig: {
      size: 'large',
      noFooter: true,
      noHeader: true,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.headlineXlFontRow('80%', 'ml-auto mr-auto mt-14'),
        ConfiguredSkeleton.headlineXlFontRow('35%', 'ml-auto mr-auto mt-7'),
        ConfiguredSkeleton.textRow('55%', 'mt-6 ml-auto mr-auto'),
        ConfiguredSkeleton.textRow('30%', 'mt-6 ml-auto mr-auto mb-3'),
        [
          ConfiguredSkeleton.box('ml-auto mr-4', '56px', '56px'),
          ConfiguredSkeleton.box('mr-4', '56px', '56px'),
          ConfiguredSkeleton.box('mr-4', '56px', '56px'),
          ConfiguredSkeleton.box('mr-auto', '56px', '56px'),
        ],
        ConfiguredSkeleton.textRow('25%', 'mt-4 ml-auto mr-auto'),
        ConfiguredSkeleton.textRow('45%', 'mt-2 ml-auto mr-auto'),
      ]),
    },
  },
  inviteSubAccountModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/subAccounts/InviteSubAccountModal.vue'),
    ),
    modalConfig: {
      size: 'medium',
      titleMessagePath: ({ titleMessagePath }) => titleMessagePath,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput('150px', 'mt-1 mb-1'),
        ConfiguredSkeleton.box('mb-3', '70%', '14px'),
        ConfiguredSkeleton.textRow('100px', 'mb-5'),
        ConfiguredSkeleton.textRow('40px', 'mb-3'),
        ConfiguredSkeleton.radioTitleAndText(1, '50%', 'mb-5'),
        ConfiguredSkeleton.radioTitleAndText(1),
      ]),
    },
  },
  resendInvitationSubAccountModal: {
    type: 'modal',
    modules: [AppModule.SubAccountsForm],
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/subAccounts/ResendInvitationSubAccountModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'resendInvitationSubAccountModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRows(2, '90%', 'mb-4'),
        ConfiguredSkeleton.labeledInput('100px'),
      ]),
    },
  },
  closeAccountModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/settings/CloseAccountModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'closeAccountModal.title',
      preloader: ConfiguredSkeleton.confirmationCheckboxSkeleton(6),
    },
  },
  closeSubAccountModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/subAccounts/CloseSubAccountModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'closeSubAccountModal.title',
      preloader: ConfiguredSkeleton.confirmationCheckboxSkeleton(1),
    },
  },
  switchSubAccountModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/subAccounts/SwitchSubAccountModal.vue'),
    ),
    modules: [AppModule.AccountSettings, AppModule.SubAccounts],
    modalConfig: {
      size: 'xLarge',
      titleMessagePath: 'switchSubAccountModal.title' satisfies TranslationKey,
      noFooter: true,
    },
  },
  billingDetailsModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/settings/BillingDetailsEditModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'billingDetailsEditModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInputs(8)]),
    },
  },
  scheduledRetryModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/scheduled/modals/ScheduledRetryModal.vue')),
    modalConfig: {
      size: 'small',
      getTitle: ({ messageIds }: ScheduledRetryModalParams) => {
        const translateService = getTranslateService()
        return translateService.tc('scheduledRetryModal.title', messageIds.length)
      },
      preloader: ConfiguredSkeleton.fromConfig([[ConfiguredSkeleton.box(undefined, undefined, '60px')]]),
    },
  },
  sentSmsBulkDetailsModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/history/sms/sent/bulk/SentSmsBulkDetailsModal.vue'),
    ),
    modalConfig: {
      size: 'large',
      noFooter: true,
      titleMessagePath: 'sentSmsBulkDetailsModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.detailsTableSkeleton(),
    },
    resolvers: [
      {
        service: 'SentSmsBulkDetailsModalResolverService',
        params: {
          source: 'sentSmsBulkDetailsModal',
        },
      },
    ],
  },
  auditLogsEventSummaryModal: {
    type: 'modal',
    modules: [AppModule.AuditLogs],
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/subAccounts/details/AuditLogEventSummaryModal.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
      noFooter: true,
      titleMessagePath: 'auditLogsEventSummaryModal.title',
      preloader: ConfiguredSkeleton.detailsTableSkeleton(),
    },
  },
  subAccountsImportDetailsFailedModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/subAccounts/import/details/SubAccountsImportDetailsFailedModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: 'subAccountsImportDetailsFailedModal.title',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInputs(4),
        ConfiguredSkeleton.textRow('50px', 'mt-5 mb-3'),
        ConfiguredSkeleton.radioTitleAndText(1, '75%', 'mb-5'),
        ConfiguredSkeleton.radioTitleAndText(1, '80%'),
      ]),
    },
  },
  senderSettingsCountriesEditDefaultSenderModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () =>
        import(
          '@/components/domain/services/senderSettingsCountries/SenderSettingsCountriesEditDefaultSenderModal.vue'
        ),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'senderSettingsCountriesEditDefaultSenderModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.input(),
        ConfiguredSkeleton.textRow('250px', 'my-7'),
        ConfiguredSkeleton.textRow('135px', 'mb-1'),
        ConfiguredSkeleton.textRow('200px', 'my-2'),
        ConfiguredSkeleton.textRow('235px', 'my-2'),
        ConfiguredSkeleton.textRow('210px', 'my-2'),
        ConfiguredSkeleton.textRow('2260px', 'my-2'),
        ConfiguredSkeleton.textRow('220px', 'my-2'),
        ConfiguredSkeleton.textRow('205px', 'my-2'),
        ConfiguredSkeleton.textRow('235px', 'my-2'),
      ]),
    },
  },
  accountSsoDetailsModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/settings/sso/AccountSsoDetailsModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: 'accountSsoDetailsModal.title',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInputs(),
        ConfiguredSkeleton.textRow('150px'),
        ConfiguredSkeleton.box('mt-1', '100%', '150px'),
        ConfiguredSkeleton.textRow('100px', 'mt-7 mb-3'),
        ConfiguredSkeleton.radioTitleAndText(1, '60%', 'mb-5'),
        ConfiguredSkeleton.radioTitleAndText(2, '60px'),
      ]),
    },
  },
  accountSecurityLoginsHistoryModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/settings/logins/AccountSecurityLoginsHistoryModal.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
      noFooter: true,
      titleMessagePath: 'accountSecurityLoginsHistoryModal.title',
      preloader: ConfiguredSkeleton.forAbstractTable({ showHeader: false, hideCheckboxes: true }),
    },
  },
  apiV2KeysCreateModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/api/v2/keys/ApiV2KeysCreateModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'apiV2KeysCreateModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        { width: '50px', height: '16px', classes: 'mb-1' },
        { width: '100%', height: '36px' },
      ]),
    },
  },
  apiV1KeyModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/services/api/v1/ApiV1KeyModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'apiV1KeyModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        { width: '150px', height: '16px', classes: 'mb-1' },
        { width: '100%', height: '36px' },
      ]),
    },
  },
  apiV1UrlTestModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/services/api/v1/ApiV1UrlTestModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'apiUrlTestModal.loadingTitle' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRows(4),
        ConfiguredSkeleton.textRow('100%', ''),
      ]),
    },
  },
  apiV2UrlTestModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/api/v2/settings/ApiV2UrlTestModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'apiUrlTestModal.loadingTitle' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRows(4),
        ConfiguredSkeleton.textRow('100%', ''),
      ]),
    },
  },
  billingAutoRechargeSettingsEditModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/billing/BillingAutoRechargeSettingsEditModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'billingAutoRechargeSettingsEditModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.labeledInput('180px'),
      ]),
    },
  },
  billingConfirmPlanDowngradeModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/billing/BillingConfirmPlanDowngradeModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'billingConfirmPlanDowngradeModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.confirmationCheckboxSkeleton(3),
    },
  },
  billingConfirmDowngradeToPayAsYouGoModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/billing/BillingConfirmDowngradeToPayAsYouGoModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'billingConfirmDowngradeToPayAsYouGoModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.confirmationCheckboxSkeleton(4),
    },
  },
  keywordsAddModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/keywords/KeywordsAddModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'keywordsAddModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput('150px', 'mb-1'),
        ConfiguredSkeleton.textRow('60%', ''),
      ]),
    },
  },
  emailLookupDetailsModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/lookup/email/EmailLookupDetailsModal.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
      titleMessagePath: 'emailLookupDetailsModal.title',
      noFooter: true,
      preloader: {
        content: TmSkeletonDetailsTable,
      },
    },
  },
  carrierLookupDetailsModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/lookup/carrier/CarrierLookupDetailsModal.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
      titleMessagePath: 'carrierLookupDetailsModal.title',
      noFooter: true,
      preloader: {
        content: TmSkeletonDetailsTable,
      },
    },
  },
  tenDlcSwitchTollFreeModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/tenDlcTollFree/TenDlcSwitchTollFreeModal.vue')),
    modalConfig: {
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('90%'),
        ConfiguredSkeleton.textRow('100%'),
        ConfiguredSkeleton.textRow('70%'),
        [ConfiguredSkeleton.box('mt-8', '50px', '14px'), ConfiguredSkeleton.button('120px', 'mt-5 ml-auto')],
      ]),
    },
  },
  tenDlcTollFreeConsentDisclosureModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/tenDlcTollFree/TenDlcTollFreeConsentDisclosureModal.vue'),
    ),
    modalConfig: {
      size: 'large',
      titleMessagePath: 'tenDlcTollFreeConsentDisclosureModal.title',
      noFooter: true,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('100%'),
        ConfiguredSkeleton.textRow('40%', 'mt-6 mb-1'),
        ConfiguredSkeleton.textRow('80%', 'ml-6'),
        ConfiguredSkeleton.textRow('100%', 'ml-6 mt-1'),
        ConfiguredSkeleton.textRow('90%', 'ml-6 mt-1'),

        ConfiguredSkeleton.textRow('40%', 'mt-6 mb-1'),
        ConfiguredSkeleton.textRow('80%', 'ml-6'),
        ConfiguredSkeleton.textRow('100%', 'ml-6 mt-1'),
        ConfiguredSkeleton.textRow('90%', 'ml-6 mt-1'),
        ConfiguredSkeleton.textRow('90%', 'ml-6 mt-1'),

        ConfiguredSkeleton.textRow('40%', 'mt-6 mb-1'),
        ConfiguredSkeleton.textRow('80%', 'ml-6'),
        ConfiguredSkeleton.textRow('100%', 'ml-6 mt-1'),

        ConfiguredSkeleton.textRow('40%', 'mt-6 mb-1'),
        ConfiguredSkeleton.textRow('80%', 'ml-6'),
        ConfiguredSkeleton.textRow('100%', 'ml-6 mt-1'),
        ConfiguredSkeleton.textRow('90%', 'ml-6 mt-1'),
      ]),
    },
  },
  tenDlcSelectCampaignDetailsModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/tenDlcTollFree/TenDlcSelectCampaignDetailsModal.vue'),
    ),
    modalConfig: {
      size: 'large',
      titleMessagePath: 'tenDlcSelectCampaignDetailsModal.title',
      preloader: {
        content: TenDlcSelectCampaignDetailsModalPreloader,
      },
    },
  },
  tenDlcBrandDetailsModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/services/tenDlc/TenDlcBrandDetailsModal.vue')),
    modalConfig: {
      noFooter: true,
      size: 'large',
      titleMessagePath: 'tenDlcBrandDetailsModal.title',
      preloader: {
        content: TenDlcBrandDetailsModalPreloader,
      },
    },
  },
  tenDlcLinkNumberModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/tenDlcTollFree/TenDlcLinkNumberModal.vue')),
    modalConfig: {
      size: 'large',
      titleMessagePath: 'tenDlcLinkNumberModal.title',
    },
  },
  tollFreeFormModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/services/tollFree/TollFreeFormModal.vue')),
    modalConfig: {
      size: 'large',
      titleMessagePath: 'tollFreeFormModal.title',
      noFooter: true,
      preloader: {
        content: TenDlcSelectCampaignDetailsModalPreloader,
      },
    },
  },
  paymentPricingCalculatorModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/payment/PaymentPricingCalculatorModal.vue'),
    ),
    modalConfig: {
      exactSize: '800px',
      titleMessagePath: 'paymentPricingCalculatorModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow(),
        ConfiguredSkeleton.textRow('200px', 'mb-8'),
        [
          ConfiguredSkeleton.textRow('150px', 'mr-auto'),
          ConfiguredSkeleton.textRow('100px', 'mr-auto ml-16'),
          ConfiguredSkeleton.textRow('80px', 'mr-auto'),
          ConfiguredSkeleton.textRow('40px', 'mr-auto mb-1'),
        ],
        ConfiguredSkeleton.horizontalLine(),
        [
          ConfiguredSkeleton.input('215px', 'ml-4'),
          ConfiguredSkeleton.textRow('50px', 'ml-8 mt-3 mr-16'),
          ConfiguredSkeleton.input('120px', 'ml-2'),
          ConfiguredSkeleton.textRow('60px', 'ml-8 mt-3 mr-2'),
        ],
        ConfiguredSkeleton.horizontalLine(),
        [
          ConfiguredSkeleton.textRow('180px', 'ml-4 mr-auto mt-1'),
          ConfiguredSkeleton.textRow('70px', 'mt-1 mr-5'),
          ConfiguredSkeleton.textRow('80px', 'mt-1 mr-8'),
        ],
      ]),
    },
  },
  byocPhoneLabelModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/byoc/modals/ByocPhoneLabelModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: ({ editingId }) =>
        editingId ? 'tmNumberLabelModal.editTitle' : 'tmNumberLabelModal.createTitle',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput(), ConfiguredSkeleton.labeledInput()]),
    },
  },
  byocCarrierLabelModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/byoc/modals/ByocCarrierLabelModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'byocCarrierLabelModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput(), ConfiguredSkeleton.labeledInput()]),
    },
  },
  byocCarrierLinkModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/byoc/modals/ByocCarrierLinkModal.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
      titleMessagePath: 'byocCarrierLinkModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.forAbstractTable(),
    },
    resolvers: [{ service: 'ByocCarrierLinkModalResolver' }],
  },
  byocCredentialsModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/byoc/modals/ByocCredentialsModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'byocCredentialsModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput(), ConfiguredSkeleton.labeledInput()]),
    },
  },
  byocCredentialsSinchModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/byoc/modals/ByocCredentialsSinchModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'byocCredentialsSinchModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput(), ConfiguredSkeleton.labeledInput()]),
    },
  },
  byocSinchCallbackModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/byoc/modals/ByocSinchCallbackModal.vue'),
    ),
    modalConfig: {
      size: 'large',
      titleMessagePath: 'byocSinchCallbackModal.title' satisfies TranslationKey,
    },
  },
  messagesBulksProgressModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/compose/modals/ComposeMessagesBulksProgressModal.vue'),
    ),
    modalConfig: {
      exactSize: '800px',
    },
  },
  contactImportResultModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/contacts/imports/ContactImportResultModal.vue')),
    modalConfig: {
      size: 'medium',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.labeledInput(),
      ]),
    },
  },
  editVerifyingEmailModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/auth/emailVerification/EditVerifyingEmailModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: 'editVerifyingEmailModal.title',
      size: 'small',
      preloader: ConfiguredSkeleton.textWithLabeledInput(),
    },
  },
  emailVerificationModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/auth/modals/EmailVerificationModal.vue'),
    ),
    modules: [AppModule.Auth],
    modalConfig: {
      titleMessagePath: 'emailVerificationModal.title',
      size: 'small',
      noFooter: true,
    },
  },
  passwordVerificationModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/auth/modals/PasswordVerificationModal.vue'),
    ),
    modules: [AppModule.Auth],
    modalConfig: {
      titleMessagePath: 'passwordVerificationModal.title',
      size: 'small',
      noFooter: true,
    },
  },
  accountBalanceCriticallyLowModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/AccountBalanceCriticallyLowModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'accountBalanceCriticallyLowModal.title' satisfies TranslationKey,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRows(3, '50%'),
        ConfiguredSkeleton.button('60%', 'mt-4'),
      ]),
    },
  },
  upgradeAccountModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/subAccounts/UpgradeAccountModal.vue'),
    ),
    modalConfig: {
      size: 'large',
      noHeader: true,
      noFooter: true,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.box('mx-auto mt-4', '70px', '70px'),
        ConfiguredSkeleton.headlineXlFontRow('80%', 'mt-5 mb-6 mx-auto'),
        ConfiguredSkeleton.textRow('60%', 'mx-auto mb-2'),
        ConfiguredSkeleton.textRow('50%', 'mx-auto mb-6'),
        ConfiguredSkeleton.button('120px', 'mx-auto'),
      ]),
    },
  },
  subscribeCreateModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/services/forms/subscribe/modals/SubscribeCreateModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'subscribeCreateModal.title' satisfies TranslationKey,
    },
  },
  subscribeRenameModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/services/forms/subscribe/modals/SubscribeRenameModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'subscribeRenameModal.title' satisfies TranslationKey,
    },
  },
  monthlyPlansSection: {
    type: 'section',
    resolvers: [
      {
        service: 'ModelSettingsResolverService',
        params: {
          model: Billing,
          cache: false,
        },
      },
    ],
  },
  selectTaskBoardTemplateModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/tasks/modals/SelectTaskBoardTemplateModal.vue'),
    ),
    modalConfig: {
      size: 'medium',
      titleMessagePath: 'tasksModals.selectTaskBoardTemplate.title',
    },
  },
  previewKanbanBoardTemplateModal: {
    type: 'modal',
    modules: [AppModule.Kanban],
    component: defineAsyncComponentRetryable(
      () => import('@/components/shared/kanban/PreviewKanbanBoardTemplateModal.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
    },
  },
  createTaskBoardTemplateModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/tasks/modals/TaskBoardModal.vue')),
    modalConfig: {
      titleMessagePath: (params) =>
        params.editingId
          ? 'tasksModals.createTaskBoardTemplate.editTitle'
          : 'tasksModals.createTaskBoardTemplate.createTitle',
      size: 'small',
    },
  },
  taskStageModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/tasks/modals/TaskStageModal.vue')),
    modalConfig: {
      titleMessagePath: (params) =>
        params.editingId ? 'tasksModals.editTaskStage.editTitle' : 'tasksModals.editTaskStage.createTitle',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput(), ConfiguredSkeleton.labeledInput()]),
    },
  },
  createTaskModal: {
    type: 'modal',
    modules: [AppModule.Tasks],
    component: defineAsyncComponentRetryable(() => import('@/components/domain/tasks/modals/CreateTaskModal.vue')),
    modalConfig: {
      titleMessagePath: 'createTaskModal.title' satisfies TranslationKey,
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.textRow('100%', 'mt-2 mb-1'),
        ConfiguredSkeleton.box('mb-4', '100%', '80px'),
        ConfiguredSkeleton.labeledInputs(2),
        ConfiguredSkeleton.button('120px', 'mt-4 ml-auto'),
      ]),
    },
  },
  taskViewModal: {
    type: 'modal',
    modules: [AppModule.Kanban],
    component: defineAsyncComponentRetryable(() => import('@/components/domain/tasks/modals/TaskViewModal.vue')),
    modalConfig: {
      size: 'xLarge',
      noFooter: true,
      preloader: false,
    },
    resolvers: [
      {
        service: 'TaskViewModalResolverService',
      },
      {
        service: 'TaskCommentSubscriptionResolverService',
      },
      {
        service: 'TaskChecklistResolverService',
      },
    ],
  },
  taskMoveAllModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/tasks/modals/TaskMoveAllModal.vue')),
    modalConfig: {
      titleMessagePath: 'tasksModals.moveAllTasks.title' satisfies TranslationKey,
      size: 'small',
    },
  },
  taskBoardsArchiveModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/tasks/modals/TaskBoardArchiveModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: ({ isArchived }): string =>
        isArchived ? 'tasksModals.archiveBoardModal.unarchive' : 'tasksModals.archiveBoardModal.archive',
      size: 'small',
    },
  },
  taskStageDeleteModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/tasks/modals/TaskStageDeleteModal.vue')),
    modalConfig: {
      titleMessagePath: 'tasksModals.deleteTaskStage.title',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput()]),
    },
  },
  taskBoardsMenuSection: {
    type: 'section',
    resolvers: [{ service: 'TaskBoardsSidebarResolver' }],
    modules: [AppModule.TasksBase],
  },
  taskBoardKanbanSection: {
    type: 'section',
    resolvers: [
      {
        service: 'RedirectableSingleModelResolverService',
        params: {
          model: TaskBoard,
          source: 'router',
          requestParam: 'boardId',
          redirectToIfNotFound: { name: TasksRoutes.base },
        } as NotFoundResolverParams,
      },
    ],
  },
  contactsNavigationOrderSection: {
    type: 'section',
    resolvers: [
      {
        service: 'NavigationOrderResolver',
        params: {
          serviceId: 'ContactNavigationOrderService',
        } satisfies NavigationOrderResolverParams,
      },
    ],
  },
  selectYourTeamPlanModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/usersWorkflow/UsersWorkflowTeamPlanModal.vue'),
    ),
    modalConfig: {
      size: 'xLarge',
      titleMessagePath: 'usersWorkflowTeamPlanModal.title',
      noFooter: true,
      preloader: {
        content: UsersWorkflowTeamPlanModalPreloader,
      },
    },
  },
  closeUserAccountModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/users/UsersCloseAccountModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'usersCloseAccountModal.title',
    },
  },
  pinnedContactsSection: {
    type: 'section',
    modules: [AppModule.PinnedContacts],
    resolvers: [{ service: 'PinnedContactsResolverService' }],
  },
  addNewPinnedContactsModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/contacts/pinned/AddPinnedItemModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'contacts.addPinnedItemModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('130px', 'mb-2'),
        ConfiguredSkeleton.box('', '100%', '120px'),
        ConfiguredSkeleton.button('120px', 'mt-6 ml-auto'),
      ]),
    },
  },
  webWidgetModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/messages/settings/webWidget/WebWidgetCreateEditModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: ({ editingId }) => (editingId ? 'addWebWidgetModal.editTitle' : 'addWebWidgetModal.title'),
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput('80px'),
        ConfiguredSkeleton.button('120px', 'mt-6 ml-auto'),
      ]),
    },
  },
  copyWebWidgetModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/messages/settings/webWidget/WebWidgetCopyModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'webWidgetDuplicateModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput('80px'),
        ConfiguredSkeleton.button('120px', 'mt-6 ml-auto'),
      ]),
    },
  },
  sendWidgetInstructionsModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/messages/settings/webWidget/WebWidgetSendInstructionsModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'webWidgetSendInstructionsModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRows(2, '70%', 'mb-4'),
        ConfiguredSkeleton.labeledInput('80px'),
        ConfiguredSkeleton.button('120px', 'mt-6 ml-auto'),
      ]),
    },
  },
  billingBuyPrepaidCreditModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/accounts/billing/stripe/BillingBuyPrepaidCreditModal.vue'),
    ),
    modules: [AppModule.StripeBilling, AppModule.Billing, AppModule.BillingBase],
    modalConfig: {
      size: 'small',
      titleMessagePath: 'buyPrepaidCreditModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('65%', 'mt-7 mb-5'),
        ConfiguredSkeleton.labeledInput('150px', 'mb-3'),
        ConfiguredSkeleton.textRow('70%', 'mb-15'),
        ConfiguredSkeleton.checkboxRow(1, '70%', 'mb-3', 'mr-2 border-radius-100'),
        ConfiguredSkeleton.checkboxRow(1, '50%', 'mb-3', 'mr-2 border-radius-100'),
        ConfiguredSkeleton.checkboxRow(2, '45%', '', 'mr-2 border-radius-100'),
      ]),
    },
  },
  stripeBillingFetchPaygPrices: {
    type: 'section',
    resolvers: [
      {
        service: 'ModelResolver',
        params: [StripePaygPrice],
      },
      {
        service: 'LastInvoiceResolverService',
      },
    ],
  },
  whatsAppViewQrCodeModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/services/whatsapp/WhatsAppViewQRCodeModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'whatsapp.viewQRCodeModal.title',
    },
  },
  taskChecklistItemEdit: {
    type: 'section',
  },
  taskChecklistNameEdit: {
    type: 'section',
  },
  tenDlcTollFreeOptInScreenshotExampleModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/tenDlcTollFree/TenDlcTollFreeOptInScreenshotExampleModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'tenDlcTollFreeOptInScreenshotExampleModal.title',
      noFooter: true,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('100%'),
        ConfiguredSkeleton.textRow('95%'),

        ConfiguredSkeleton.box('mt-6 mb-1', '100%', '453px'),
      ]),
    },
  },
  taskViewModalTopBarDueDateModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/tasks/modals/TaskViewModalTopBarDueDateModal.vue'),
    ),
    modalConfig: {
      exactSize: '290px',
      titleMessagePath: 'taskViewModalTopBarDueDateModal.title',
    },
  },
  chatAssignModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/chats/ChatAssignModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: (_params) => {
        const params = _params as ChatAssignModalModalParams
        return params.isSingleChat ? 'chatAssignModal.titleForSingleChat' : 'chatAssignModal.title'
      },
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('100%'),
        ConfiguredSkeleton.textRow('95%'),

        [[ConfiguredSkeleton.textRow('150px', 'mt-4')], [ConfiguredSkeleton.input('100%', 'mt-1')]],
      ]),
    },
  },
  chatRejectModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/chats/ChatRejectModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'chatRejectModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('100%'),
        ConfiguredSkeleton.textRow('30%'),
        [[ConfiguredSkeleton.textRow('209px', 'mt-4')], [ConfiguredSkeleton.box('mt-2 mb-5', '100%', '80px')]],
        [ConfiguredSkeleton.box('mr-2 mb-5', '20px', '19px'), ConfiguredSkeleton.textRow('100%', 'mt-1')],
        [ConfiguredSkeleton.box('mr-2 mb-5', '20px', '19px'), ConfiguredSkeleton.textRow('70%', 'mt-1')],
      ]),
    },
  },
  chatAddNoteModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/chats/ChatAddNoteModal.vue')),
    modalConfig: {
      size: 'medium',
      titleMessagePath: 'chatAddNoteModal.title',
      noHeader: true,
      noFooter: true,
      overrideModalStyles: {
        backgroundColor: ' #FFF7DB', // $note
      },
      preloader: ConfiguredSkeleton.fromConfig([
        [
          ConfiguredSkeleton.textRow('86px', 'mt-1 ml-n4 mb-7'),
          ConfiguredSkeleton.box('ml-auto mt-1 mr-n2', '16px', '16px'),
        ],
        ConfiguredSkeleton.textRow('190px', 'mt-4 ml-n4'),
        ConfiguredSkeleton.textRow('80%', 'mt-3 ml-n4'),
        ConfiguredSkeleton.textRow('82%', 'mt-3 ml-n4'),
        ConfiguredSkeleton.textRow('78%', 'mt-3 ml-n4 mb-11'),
        [
          ConfiguredSkeleton.textRow('22px', 'mt-9 ml-n3'),
          ConfiguredSkeleton.box('ml-auto mt-7 mr-n4', '94px', '36px'),
        ],
      ]),
    },
  },
  chatRatingModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/chats/ChatRatingModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'chatRatingModal.title',
      noFooter: true,
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.box('mb-4', '100%', '120px')]),
    },
  },
  chatBlockIPConfirmation: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/chats/ChatBlockIPConfirmation.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'chatBlockIPConfirmation.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('100%'),
        ConfiguredSkeleton.textRow('30%'),
        ConfiguredSkeleton.textRow('80%'),
        ConfiguredSkeleton.textRow('70%'),
      ]),
    },
  },
  tenDlcOptInFlowDescriptionRequirementsModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/tenDlcTollFree/TenDlcOptInFlowDescriptionRequirementsModal.vue'),
    ),
    modalConfig: {
      size: 'large',
      titleMessagePath: 'tenDlcOptInFlowDescriptionRequirementsModal.title',
      noFooter: true,
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('100%'),
        ConfiguredSkeleton.textRow('30%'),
        ConfiguredSkeleton.textRow('70%', 'mt-4'),
        ConfiguredSkeleton.textRow('40%', 'mt-4 mb-1'),
        ConfiguredSkeleton.textRow('50%'),

        ConfiguredSkeleton.textRow('80%', 'mt-5'),
        ConfiguredSkeleton.textRow('40%', 'mt-1 ml-4'),
        ConfiguredSkeleton.textRow('50%', 'mt-1 ml-4'),
        ConfiguredSkeleton.textRow('30%', 'mt-1 ml-4'),
        ConfiguredSkeleton.textRow('70%', 'mt-1 ml-4'),

        ConfiguredSkeleton.textRow('70%', 'mt-5'),
        ConfiguredSkeleton.textRow('40%', 'mt-1 ml-4'),
        ConfiguredSkeleton.textRow('50%', 'mt-1 ml-4'),
        ConfiguredSkeleton.textRow('30%', 'mt-1 ml-4'),
        ConfiguredSkeleton.textRow('70%', 'mt-1 ml-4'),
      ]),
    },
  },
  ticketCreateModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/tickets/modals/TicketCreateModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'tickets.createTicketModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput('80px'),
        ConfiguredSkeleton.labeledInput('90px'),
        ConfiguredSkeleton.labeledInput('60px'),
        ConfiguredSkeleton.labeledInput('80px'),
        ConfiguredSkeleton.labeledInput('70px'),
      ]),
    },
    modules: [AppModule.Tickets],
    resolvers: [
      {
        service: 'FetchAllAccountsResolver',
      },
      {
        service: 'ModelResolver',
        params: [TicketInbox, TicketStatus],
      },
    ],
  },
  ticketCreateWorkspaceModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/tickets/modals/TicketCreateWorkspaceModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'tickets.createWorkspaceModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRows(2, '80%', 'mb-4'),
        ConfiguredSkeleton.labeledInput('110px'),
        ConfiguredSkeleton.textRow('55%', 'mt-1 mb-2'),
        ConfiguredSkeleton.textRow('40%'),
      ]),
    },
  },
  ticketAssignModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/tickets/modals/TicketAssignModal.vue')),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'tickets.assignModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('90%', 'mb-4'),
        ConfiguredSkeleton.labeledInput('110px'),
      ]),
    },
    resolvers: [
      {
        service: 'FetchAllAccountsResolver',
      },
    ],
  },
  ticketFetchInboxes: {
    type: 'section',
    resolvers: [
      {
        service: 'ModelResolver',
        params: [TicketInbox],
      },
    ],
  },
  ticketFetchInboxesAndFv: {
    type: 'section',
    resolvers: [
      {
        service: 'FilteredViewsResolverService',
        params: [FilteredViewsSupportedEntities.TICKETS],
      },
      {
        service: 'ModelResolver',
        params: [TicketInbox],
      },
    ],
  },
  ticketFetchSpamFilter: {
    type: 'section',
    resolvers: [
      {
        service: 'SingleModelResolverService',
        params: { model: TicketSpamFilter, id: TICKET_SPAM_FILTER_ID, cache: false },
      },
    ],
  },
  templatesMenu: {
    type: 'section',
    modules: [AppModule.Templates],
  },
  plansCardConnectModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/accounts/plans/PlansCardConnectModal.vue')),
    modules: [AppModule.ByocHelper],
    modalConfig: {
      size: 'small',
      titleMessagePath: 'plansCardConnectModal.title',
    },
  },
  plansChangeWithComparisonTableModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/accounts/plans/PlansChangeWithComparisonTableModal.vue'),
    ),
    modules: [AppModule.ByocHelper],
    modalConfig: {
      size: 'large',
      getTitle: (params: DowngradeToEssentialModalParams) => params.title,
    },
  },
  plansUpgradeModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/accounts/plans/PlansUpgradeModal.vue')),
    modules: [],
    modalConfig: {
      size: 'medium',
      titleMessagePath: 'plansUpgradeModal.title',
    },
  },
  [ticketSaveViewAsModalId]: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/tickets/modals/TicketSaveViewAsModal.vue')),
    modules: [AppModule.Tickets],
    modalConfig: {
      size: 'small',
    } satisfies ModalDialogConfig,
  },
  [ticketFilteredViewPreviewModalId]: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/tickets/modals/TicketFilteredViewPreviewModal.vue'),
    ),
    modules: [AppModule.Tickets],
    modalConfig: {
      exactSize: '1100px',
    } satisfies ModalDialogConfig,
  },
  ticketMarkAsSpamModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/tickets/modals/TicketMarkAsSpamModal.vue')),
    modules: [],
    modalConfig: {
      exactSize: '540px',
      titleMessagePath: 'tickets.ticketMarkAsSpamModal.title',
    },
  },
  ticketMarkAsNotSpamModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/tickets/modals/TicketMarkAsNotSpamModal.vue')),
    modules: [],
    modalConfig: {
      exactSize: '540px',
      titleMessagePath: 'tickets.ticketMarkAsNotSpamModal.title',
    },
  },
  ticketBulkMarkAsSpamModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/tickets/modals/TicketBulkMarkAsSpamModal.vue')),
    modules: [],
    modalConfig: {
      exactSize: '540px',
      titleMessagePath: 'tickets.ticketBulkMarkAsSpamModal.title',
    },
  },
  ticketBulkAssignModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/tickets/modals/TicketBulkAssignModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'tickets.bulkAssignModal.title',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.textRow('90%', 'mb-4'),
        ConfiguredSkeleton.labeledInput('110px'),
      ]),
    },
    resolvers: [
      {
        service: 'FetchAllAccountsResolver',
      },
    ],
  },
  contactDeleteModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/contacts/ContactDeleteModal.vue')),
    modules: [],
    modalConfig: {
      size: 'small',
      titleMessagePath: 'contactDeleteModal.title',
    },
  },
  ticketsForwardingInstructionsModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () =>
        import(
          '@/components/domain/tickets/inboxes/connect/forwardingSteps/TicketInboxForwardingInstructionsModal.vue'
        ),
    ),
    modalConfig: {
      size: 'medium',
    },
  },
  ticketsFetchWorkspace: {
    type: 'section',
    resolvers: [
      {
        service: 'SingleModelResolverService',
        params: { model: TicketWorkspace, id: TICKET_WORKSPACE_ID },
      },
    ],
  },
  dealsPipelinesMenuSection: {
    type: 'section',
    resolvers: [{ service: 'DealPipelineResolverService' }],
    modules: [AppModule.Deals],
  },
  selectDealPipelineTemplateModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/deals/modals/SelectDealPipelineTemplateModal.vue'),
    ),
    modalConfig: {
      size: 'medium',
      titleMessagePath: 'deals.dealsModals.selectDealPipelineTemplateModal.title',
    },
  },
  createOrUpdateDealPipelineTemplateModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/deals/modals/DealPipelineModal.vue')),
    modalConfig: {
      titleMessagePath: (params) =>
        params.editingId
          ? 'deals.dealsModals.createOrUpdateDealPipelineTemplateModal.title.edit'
          : 'deals.dealsModals.createOrUpdateDealPipelineTemplateModal.title.create',
      size: 'small',
    },
  },
  archiveDealPipelineModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/deals/modals/DealPipelineArchiveModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'deals.archive',
    },
  },
  ticketsEditorModal: {
    type: 'modal',
    component: defineAsyncComponent(
      () => import('@/components/domain/tickets/details/main/messageInput/TicketsEditorModal.vue'),
    ),
    modules: [],
    modalConfig: {
      exactSize: '1236px',
      noFooter: true,
      noHeader: true,
      maxHeight: '100%',
    },
  },
  facebookQrModal: {
    type: 'modal',
    component: defineAsyncComponent(() => import('@/components/domain/messenger/facebook/modals/FacebookQrModal.vue')),
    modalConfig: {
      size: 'medium',
      titleMessagePath: (params) => `${params.chatType}.qrModal.title`,
    },
  },
  dealsPipelineKanbanSection: {
    type: 'section',
    resolvers: [
      {
        service: 'RedirectableSingleModelResolverService',
        params: {
          model: DealPipeline,
          source: 'router',
          requestParam: 'pipelineId',
          redirectToIfNotFound: { name: DealsRoutes.base },
        } as NotFoundResolverParams,
      },
    ],
  },
  dealMoveAllModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/deals/modals/DealMoveAllModal.vue')),
    modalConfig: {
      titleMessagePath: 'deals.dealsModals.moveAllDeals.title' satisfies TranslationKey,
      size: 'small',
    },
  },
  dealCustomStageModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/deals/modals/DealCustomStageModal.vue')),
    modalConfig: {
      titleMessagePath: (params) =>
        params.editingId ? 'deals.dealsModals.stageModal.title.edit' : 'deals.dealsModals.stageModal.title.create',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput(), ConfiguredSkeleton.labeledInput()]),
    },
  },
  dealSystemStageModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/deals/modals/DealSystemStageModal.vue')),
    modalConfig: {
      titleMessagePath: (params) =>
        params.editingId ? 'deals.dealsModals.stageModal.title.edit' : 'deals.dealsModals.stageModal.title.create',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput(), ConfiguredSkeleton.labeledInput()]),
    },
  },
  dealCardAddContactModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/deals/modals/DealCardAddContactModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: 'deals.dealsModals.dealCardAddContactModal.title',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput()]),
    },
  },
  dealTransferOwnershipModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/deals/modals/DealTransferOwnershipModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: 'deals.dealsModals.dealTransferOwnershipModal.title',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput()]),
    },
  },
  createDealModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/deals/modals/CreateDealModal.vue')),
    modules: [AppModule.Deals],
    modalConfig: {
      titleMessagePath: 'deals.dealsModals.createDealModal.title',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([
        ConfiguredSkeleton.labeledInput('150px', 'mt-1 mb-4'),
        ConfiguredSkeleton.labeledInput(),
        ConfiguredSkeleton.textRow('100%', 'mb-1 mt-2'),
        ConfiguredSkeleton.box('mb-2', '100%', '130px'),
        [ConfiguredSkeleton.textRow('48%', 'mt-3'), ConfiguredSkeleton.textRow('48%', 'mt-3 ml-4')],
        [ConfiguredSkeleton.input('48%', 'mt-1'), ConfiguredSkeleton.input('48%', 'mt-1 ml-4 mb-4')],
        ConfiguredSkeleton.labeledInput('150px', 'mt-1 mb-4'),
        ConfiguredSkeleton.labeledInput(),
        [ConfiguredSkeleton.textRow('48%', 'mt-2'), ConfiguredSkeleton.textRow('48%', 'mt-2 ml-4')],
        [ConfiguredSkeleton.input('48%', 'mt-1'), ConfiguredSkeleton.input('48%', 'mt-1 ml-4')],
      ]),
    },
  },
  pipelineDefaultOwnerModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/deals/modals/DealPipelineDefaultOwnerModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: 'deals.dealsModals.pipelineDefaultOwnerModal.title',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput()]),
    },
  },
  dealCustomStageDeleteModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/deals/modals/DealCustomStageDeleteModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: 'deals.dealsModals.deleteDealModal.title',
      size: 'small',
      preloader: ConfiguredSkeleton.fromConfig([ConfiguredSkeleton.labeledInput()]),
    },
  },
  campaignsSendersVerifyDomainSendModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () =>
        import(
          '@/components/domain/campaigns/email/settings/flow/verifyDomain/CampaignsSendersVerifyDomainSendModal.vue'
        ),
    ),
    modalConfig: {
      titleMessagePath: 'campaigns.senders.flow.verifyDomain.sendModal.title',
      size: 'small',
      preloader: false,
    },
  },
  campaignsRenameModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(() => import('@/components/domain/campaigns/CampaignsRenameModal.vue')),
    modalConfig: {
      titleMessagePath: 'campaigns.renameModal.title',
      size: 'small',
      preloader: false,
    },
  },
  campaignsEmailSenderModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/campaigns/email/emailSender/CampaignsEmailSenderModal.vue'),
    ),
    modalConfig: {
      size: 'medium',
      preloader: false,
    },
  },
  campaignsEmailSettingsDnsModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/campaigns/email/settings/dns/CampaignsEmailSettingsDnsModal.vue'),
    ),
    modalConfig: {
      titleMessagePath: 'campaigns.email.settings.dns.title',
      size: 'xLarge',
      preloader: false,
    },
  },
  dealViewModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/deals/modals/viewModal/DealViewModal.vue'),
    ),
    resolvers: [
      {
        service: 'DealActivityItemsResolverService',
      },
    ],
    modalConfig: {
      exactSize: '1400px',
      noFooter: true,
      noHeader: true,
      preloader: false,
      keepAliveOnRouteChange: true,
      navigationComponent: defineAsyncComponentRetryable(
        () => import('@/components/domain/deals/modals/viewModal/DealViewModalNav.vue'),
      ),
    },
  },
  dealTimelineSection: {
    type: 'section',
    resolvers: [{ service: 'DealTimelineItemsResolverService' }],
  },
  dealActivityItemEdit: {
    type: 'section',
  },
  unsubscribeContactWithEmailModal: {
    type: 'modal',
    component: defineAsyncComponentRetryable(
      () => import('@/components/domain/contacts/unsubscribed/UnsubscribeContactWithEmailModal.vue'),
    ),
    modalConfig: {
      size: 'small',
      titleMessagePath: 'unsubscribeContactWithEmailModal.title',
    },
  },
} as const
export type TmWrappers = keyof typeof Wrappers

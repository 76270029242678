import { injectable } from 'inversify'
import Timezone from '@/data/models/domain/Timezone'
import { PublicRepo, RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { Endpoint } from '@/services/endpoints'
import type { ReadByFilterPayload } from '@/data/repositories/types'
import ModelMapper from '@/data/utils/modelMapper'

@RepoSettings<Endpoint>({
  model: Timezone,
  fetch: 'timezones',
})
@PublicRepo()
@injectable()
export default class TimezoneRepository extends OrmApiRepository<Timezone> {
  protected fetchByFilter<T>(endpoint: Endpoint) {
    return this.getApiSource().get<T>(`${this.endpointsService.getPath(endpoint)}?full=1`)
  }

  public async readByFilter<T>(): Promise<T[]> {
    const response = await this.fetchByFilter<ReadByFilterPayload<Timezone>>(this.getSettingsFetch())
    const items = this.getItemsFromReadByFilterResponseData(response.data)

    return ModelMapper.normalizePayload(items as T[], this.model())
  }
}

import type { Resolvable } from '@/services/resolvers/types'
import TmLogicError from '@/core/error/tmLogicError'
import type { Route } from '@/services/route/types'

export function withUniqueKeys(description?: string) {
  return function <T extends { new (...args: any[]): Resolvable<any> }>(constructor: T) {
    return class extends constructor {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      public static get name(): string {
        return constructor.name
      }

      private resolverUid = Symbol(description)

      constructor(...args: any[]) {
        super(...args)

        if (typeof super.getResolverKeys !== 'function') {
          throw new TmLogicError('"withUniqueKeys" requires "getResolverKeys" method to be implemented')
        }
      }

      public getResolverKeys(params: unknown, route?: Route, fromRoute?: Route) {
        return [this.resolverUid, ...super.getResolverKeys!.call(this, params, route, fromRoute)]
      }
    }
  }
}

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum TICKETS_NOTIFICATIONS_SERVICES {
  TicketNativeNotificationService,
  TicketMessageNotificationResolver,
}

export type TicketsNotificationsServices = keyof typeof TICKETS_NOTIFICATIONS_SERVICES

export const TICKETS_NOTIFICATIONS_SERVICES_TYPES =
  makeServicesTypesFromEnum<TicketsNotificationsServices>(TICKETS_NOTIFICATIONS_SERVICES)

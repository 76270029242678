import { TmBaseError } from './tmBaseError'

export class TmIncorrectContractError extends TmBaseError {
  public name = 'TmIncorrectContractError'

  constructor(
    message = 'The response received from backend is different than expected. It may be a backend bug or you must fix this if the contract was changed.',
  ) {
    super(message)
  }
}

import { AttrField, BelongsToField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import Contact from '@/data/models/domain/Contact'
import User from '@/data/models/domain/User'
import Country from '@/data/models/domain/Country'
import SenderSource from '@/data/models/domain/SenderSource'
import type { CallDirection } from '@/types'
import type { TmCountryCode } from '@/services/types'

@OrmModel(modelNames.SIP_CALL)
export default class SipCall extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public fromNumber: string

  @StringField()
  public toNumber: string

  @NumberField()
  public length: number

  @StringField()
  public callTime: string

  @StringField()
  public direction: CallDirection

  @NumberField()
  public cost: number

  @NumberField()
  public price: number

  @NumberField()
  public userId: number

  @BelongsToField(User, 'userId')
  public user: User

  @AttrField()
  public contactId: string

  @BelongsToField(Contact, 'contactId')
  public contact: Contact

  @AttrField()
  public countryId: TmCountryCode

  @BelongsToField(Country, 'countryId')
  public country: Country

  @AttrField()
  public senderSourcePhone: string

  @BelongsToField(SenderSource, 'senderSourcePhone')
  public senderSource: SenderSource
}

import { injectable } from 'inversify'
import type { MessageSendRepeatMonthlyFormScheme, OrdinalDay } from '@/services/domain/messages/types'
import type { SelectFieldConfig } from '@/services/forms/types'
import type {
  FormGroupConfigurationInterface,
  TypedFormBuilderInterface,
} from '@/services/forms/baseForm/typedFormBuilder/types'
import type { WeekDaysFull } from '@/services/types'
import { AbstractFormGroupConfiguration } from '@/services/forms/messages/formGroupConfiguration/AbstractFormGroupConfiguration'

@injectable()
export class MonthlyFormGroupConfiguration
  extends AbstractFormGroupConfiguration
  implements FormGroupConfigurationInterface<MessageSendRepeatMonthlyFormScheme>
{
  public configure(builder: TypedFormBuilderInterface<MessageSendRepeatMonthlyFormScheme>): void {
    builder
      .property((t) => t.count)
      .control({
        fieldType: 'number',
        value: 1,
      })
    builder
      .property((t) => t.repeat)
      .control({
        fieldType: 'radio',
        value: 'month-day',
      })
    builder
      .property((t) => t.monthDay)
      .control<SelectFieldConfig>({
        fieldType: 'select',
        value: '1',
        options: () => this.getSelectMonthDayOptions(),
      })
    builder
      .property((t) => t.firstLastDay)
      .control<SelectFieldConfig<OrdinalDay>>({
        fieldType: 'select',
        value: 'First',
        options: () => this.getSelectOrdinalDaysOptions(),
      })
    builder
      .property((t) => t.weekDay)
      .control<SelectFieldConfig<WeekDaysFull>>({
        fieldType: 'select',
        value: 'Sunday',
        options: () => this.getSelectWeekDaysOptions(),
      })
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type { WizardApplyBody } from '@/services/domain/wizard/types'
import { wizardChangeStepEvent } from '@/services/domain/wizard/wizardService'
import type { Resolvable } from '@/services/resolvers/types'
import type LoggedInStatusService from '@/services/auth/loggedInStatusService'

@injectable()
export default class WizardPrivateUseCaseResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.LoggedInStatusService)
    protected readonly loggedInStatusService: LoggedInStatusService,
  ) {}

  private subscriptionKey: string | null = null

  private changeStepHandler = (e: WizardApplyBody) => {
    if (e.type !== 'from_use_case_to_private_user') {
      return
    }
    this.loggedInStatusService.setUserIsLoggedIn(false, null)
  }

  public async resolve() {
    this.subscriptionKey = this.subscription.subscribe(wizardChangeStepEvent, this.changeStepHandler)
  }

  public async unresolve() {
    if (!this.subscriptionKey) {
      return
    }
    this.subscription.unsubscribe([this.subscriptionKey])
  }
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import Byoc from '@/data/models/domain/services/byoc/Byoc'
import OrmApiSettingsRepository from '@/data/repositories/ormApiSettingsRepository'

@injectable()
@RepoSettings<Endpoint>({
  model: Byoc,
  single: 'byoc',
})
export default class ByocRepository extends OrmApiSettingsRepository<Byoc> {}

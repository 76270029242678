import { AttrField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

export type WizardFlow = 'au' | 'gb' | 'us' | 'ca' | 'other' | 'none'

export const wizardCurrentStep = [
  'use_case',
  'private_user',
  'configure_analytics',
  'success_payment',
  'finish',
  'getting_started',
] as const

export type WizardCurrentStep = (typeof wizardCurrentStep)[number]

@OrmModel(modelNames.WIZARD)
export default class Wizard extends BaseModel {
  @StringField()
  public flow: WizardFlow

  @AttrField()
  public currentStep: WizardCurrentStep | null
}

import { inject, injectable } from 'inversify'
import type BaseModel from '@/data/models/BaseModel'
import type LoggerService from '@/services/loggerService'
import { SERVICE_TYPES } from '@/core/container/types'
import OrmStorageRepository from '@/data/repositories/ormStorageRepository'
import type LocalStorageTransport from '@/services/transport/storage/localStorageTransport'
import type LocalStorageAdapter from '@/services/transport/storage/localStorageAdapter'

@injectable()
export default abstract class OrmLocalStorageRepository<T extends BaseModel> extends OrmStorageRepository<T> {
  constructor(
    @inject(SERVICE_TYPES.LocalStorageTransport) protected readonly transport: LocalStorageTransport,
    @inject(SERVICE_TYPES.LocalStorageAdapter) protected readonly localStorageAdapter: LocalStorageAdapter,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {
    super(transport, loggerService)
  }

  public getRequestSync<S>(id: string) {
    const lsKey = this.settings().single!
    return this.localStorageAdapter.getJSON(lsKey)?.[id] as S
  }
}

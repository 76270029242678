const mobileRE =
  /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|samsungbrowser.*mobile|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i
const notMobileRE = /CrOS/

export function isMobile(): boolean {
  let result = false
  if ('userAgentData' in navigator && 'mobile' in (navigator.userAgentData as { mobile: boolean })) {
    return (navigator.userAgentData as { mobile: boolean }).mobile
  }
  if ('maxTouchPoints' in navigator) {
    result = navigator.maxTouchPoints > 0
  } else if ('msMaxTouchPoints' in navigator) {
    result = (navigator as { msMaxTouchPoints: number }).msMaxTouchPoints > 0
  } else if (typeof window.matchMedia === 'function') {
    const mQ = matchMedia('(pointer:coarse)')
    if (mQ && mQ.media === '(pointer:coarse)') {
      result = Boolean(mQ.matches)
    } else if ('orientation' in window) {
      result = true // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      let ua
      if ('userAgent' in navigator) {
        ua = (navigator as { userAgent: string }).userAgent
      }
      if (ua?.headers && typeof ua.headers['user-agent'] === 'string') {
        ua = ua.headers['user-agent']
      }
      if (typeof ua !== 'string') {
        return false
      }
      result = mobileRE.test(ua) && !notMobileRE.test(ua)
    }
  }
  return result
}

export function getSafariVersion(): string {
  const userAgent = navigator.userAgent
  const versionIndex = userAgent.indexOf('Version/')

  if (versionIndex === -1) {
    return '' // Safari version not found
  }
  const versionStr = userAgent.substring(versionIndex + 8)
  const endIndex = versionStr.indexOf(' ')
  const safariVersion = endIndex === -1 ? versionStr : versionStr.substring(0, endIndex)

  return safariVersion
}

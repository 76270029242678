import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

export enum SEGMENT_SERVICES {
  SegmentsRepository,
  SegmentsService,
  SegmentsCompareService,
  SegmentsColumnServiceFactory,
  SegmentContactsColumnServiceFactory,
  SegmentsFilteredViewsGridable,
  SegmentBlankGridable,
  SegmentCreateFormService,
  SegmentRenameFormService,
  SegmentSaveAsModalFormService,
  SegmentCreateParamsResolverService,
  SegmentsForContactResolverService,
  SegmentsForContactChatResolverService,
  SegmentTableApplyResolverService,
  SegmentContactCounterService,
  SegmentsBulkService,
  SegmentsBulkManager,
}

export type SegmentServices = keyof typeof SEGMENT_SERVICES

export const SEGMENT_SERVICES_TYPES: Record<SegmentServices, symbol> = makeServicesTypes<SegmentServices>(
  enumKeysToArray<SegmentServices>(SEGMENT_SERVICES),
)

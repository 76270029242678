import { inject, injectable } from 'inversify'
import { UserRole } from '@/services/domain/user/types'
import type { MapStatusColor } from '@/utils/status'
import { getStatusColor } from '@/utils/status'
import { SERVICE_TYPES } from '@/core/container/types'
import type TranslateService from '@/services/translateService'
import type { SelectStatusOption } from '@/services/forms/types'
import type { StatusData } from '@/types'
import type { TranslationKey } from '@/services/types'
import type { StatusColor } from '@/components/shared/types'
import type TeamWorkflowService from '@/services/domain/accounts/teamWorkflowService'

@injectable()
export default class UserRoleLabelDataService {
  constructor(
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    @inject(SERVICE_TYPES.TeamWorkflowService) protected readonly teamWorkflowService: TeamWorkflowService,
  ) {}

  public getUserRoleOptions(): SelectStatusOption[] {
    const userRole = [UserRole.ADMIN, UserRole.USER]

    return userRole.map((value) => {
      const { value: text, color } = this.getUserRoleLabelData(value)

      return {
        color,
        text,
        value,
        outline: true,
      }
    })
  }

  public getColorForUserRoleLabelData(role: UserRole) {
    const colors: Record<UserRole, StatusColor> = {
      [UserRole.USER]: 'gray',
      [UserRole.ADMIN]: 'blue',
      [UserRole.SUPER_ADMIN]: 'blue',
    }

    return colors[role]
  }

  public getUserRoleLabelData(role: UserRole): StatusData {
    return {
      value: this.getSubaccountTypeLabel(role),
      color: this.getUserRoleColor(role),
    }
  }

  public getUserRoleColor(role: UserRole) {
    if (this.teamWorkflowService.isUsersWorkflow()) {
      return this.getColorForUserRoleLabelData(role)
    }

    const map: MapStatusColor<UserRole> = {
      gray: [UserRole.USER],
      blue: [UserRole.ADMIN],
      green: [UserRole.SUPER_ADMIN],
    }
    return getStatusColor(map, role, 'blue')
  }

  public getSubaccountTypeLabel(role: UserRole): string {
    if (this.teamWorkflowService.isUsersWorkflow()) {
      return this.getUserTypeLabel(role)
    }
    const map: Record<UserRole, TranslationKey> = {
      [UserRole.USER]: 'status.account.user',
      [UserRole.ADMIN]: 'status.account.admin',
      [UserRole.SUPER_ADMIN]: 'status.account.superAdmin',
    }

    return this.translateService.t(map[role]) ?? this.translateService.t('status.undefined')
  }

  public getUserTypeLabel(role: UserRole): string {
    const map: Record<UserRole, TranslationKey> = {
      [UserRole.USER]: 'status.account.user',
      [UserRole.ADMIN]: 'status.account.admin',
      [UserRole.SUPER_ADMIN]: 'status.account.accountOwner',
    }

    return this.translateService.t(map[role]) ?? this.translateService.t('status.undefined')
  }
}

<template>
  <tm-sidebar-note
    :title="$t('contacts.pinned.emptyState.title')"
    :description="$t('contacts.pinned.emptyState.description')"
    :button-text="$t('contacts.pinned.emptyState.button')"
    :dark="dark"
    :padding="padding"
    is-button-shown
    @submit="handleAddContact"
  />
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import { openAddNewPinnedItemModal } from '@/composition/domain/pinnedContacts/addNewPinnedItemModal'
import type { StylesheetSizeProp } from '@/types'
import TmSidebarNote from '@/components/shared/sidebar/TmSidebarNote.vue'

export default defineComponent({
  components: { TmSidebarNote },
  props: {
    dark: {
      type: Boolean,
    },
    padding: {
      type: String as PropType<`${StylesheetSizeProp} ${StylesheetSizeProp}`>,
      default: '24px 20px',
    },
  },
  setup() {
    const handleAddContact = () => {
      openAddNewPinnedItemModal()
    }

    return {
      handleAddContact,
    }
  },
})
</script>

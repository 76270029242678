/* eslint-disable consistent-return */ // This is false-positive in this file
import { injectable } from 'inversify'
import { isProd } from '@/utils/system'

@injectable()
export class PerformanceMarkService {
  private supportsPerformanceMark = false

  constructor() {
    this.supportsPerformanceMark = typeof performance !== 'undefined'
  }

  public mark(name: string) {
    if (this.isDisabled()) {
      return
    }

    return performance.mark(name)
  }

  public now(): number {
    if (this.isDisabled()) {
      return 0
    }

    return performance.now()
  }

  public measure(name: string, startMark: number | string, endMark?: number | string) {
    if (this.isDisabled()) {
      return
    }

    return performance.measure(name, { start: startMark, end: endMark })
  }

  public clearMarks(name?: string) {
    if (this.isDisabled()) {
      return
    }

    return performance.clearMarks(name)
  }

  public clearMeasures(name?: string) {
    if (this.isDisabled()) {
      return
    }

    return performance.clearMeasures(name)
  }

  public getEntries() {
    if (this.isDisabled()) {
      return []
    }

    return performance.getEntries()
  }

  public startMeasure(name: string) {
    const startMark = this.now()
    return () => this.measure(name, startMark)
  }

  public isDisabled() {
    return isProd() || !this.supportsPerformanceMark
  }
}

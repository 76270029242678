import { AttrField, OrmModel, StringField, NumberField, BelongsToField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'
import type { ServiceDistributionIncludeOptions } from '@/services/domain/services/distribution/types'
import SenderSource from '@/data/models/domain/SenderSource'
import type { ModelRaw } from '@/types'

export type DistributionRecipients = {
  contactIds: number[]
  groupIds: number[]
  segmentIds: number[]
  numbers: string[]
}

export type DistributionDetailsFields = Omit<ModelRaw<DistributionDetails>, 'senderSource'>

@OrmModel(modelNames.DISTRIBUTION_DETAILS)
export default class DistributionDetails extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @StringField()
  public email: string

  @NumberField()
  public maxLength: number

  @StringField()
  public includeOptions: ServiceDistributionIncludeOptions

  @AttrField()
  public senderSourcePhone: string | undefined

  @BelongsToField(SenderSource, 'senderSourcePhone')
  public senderSource: SenderSource

  @AttrField()
  public recipients: DistributionRecipients
}

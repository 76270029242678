import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type TranslateService from '@/services/translateService'
import { NumberFormat } from '@/services/types'
import { formatMoney } from '@/utils/currency'
import { APP_DEFAULT_LOCALE, type SupportedLocales } from '@/services/translateService.types'
import type UserService from './domain/user/userService'

@injectable()
export default class NumericService {
  constructor(
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {}

  public formatInteger(x: number): string {
    return this.translateService.n(x, NumberFormat.INTEGER)
  }

  public formatDecimalWithFractional(n: number): string {
    return this.translateService.n(n, NumberFormat.DECIMAL)
  }

  public formatDecimal(n: number): string {
    if (Number.isInteger(n)) {
      return this.formatInteger(n)
    }

    return this.formatDecimalWithFractional(n)
  }

  public formatNumber(value: number): string {
    if (value < 0) {
      return `-${this.formatNumber(Math.abs(value))}`
    }
    if (value < 1000) {
      return value.toString()
    }
    const thousand = +(value / 1000).toFixed(1)
    if (thousand < 1000) {
      return `${thousand}K`
    }
    return `${+(value / 1000000).toFixed(1)}M`
  }

  /**
   * convert value of amount to string in current user's currency
   * @param amount
   * @param locale name of locale ('en-US', 'de-DE')
   * @param dp - minimumFractionDigits / maximumFractionDigits
   * @returns $1.23 or €0.234
   */
  public formatMoneyForCurrentUser(amount: number, locale: SupportedLocales = APP_DEFAULT_LOCALE, dp?: number): string {
    return formatMoney(amount, this.userService.currentUser().currency?.id ?? 'USD', locale, dp)
  }

  public getOrdinalSuffix(num: number) {
    const suffixes = {
      one: 'st',
      two: 'nd',
      few: 'rd',
      other: 'th',
    }

    const pr = new Intl.PluralRules('en', { type: 'ordinal' })
    return suffixes[pr.select(num)]
  }

  public formatOrdinal(num: number) {
    const suffix = this.getOrdinalSuffix(num)
    return `${num}${suffix}`
  }
}

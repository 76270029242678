import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import UserAnalyticsTraits from '@/data/models/domain/UserAnalyticsTraits'
import type { ModelRaw } from '@/types'

@RepoSettings<Endpoint>({
  model: UserAnalyticsTraits,
})
@injectable()
export default class UserAnalyticsTraitsRepository extends OrmApiRepository<UserAnalyticsTraits> {
  private id = '1'

  public async storeAnalyticsTraits(analyticsTraits: ModelRaw<UserAnalyticsTraits>): Promise<void> {
    await this.insertOrUpdate([{ ...analyticsTraits, id: this.id }])
  }

  public findAnalyticsTraits(): UserAnalyticsTraits {
    return this.find(this.id)
  }
}

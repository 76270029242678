import { inject, injectable } from 'inversify'
import { cloneDeep } from 'lodash-es'
import { SERVICE_TYPES } from '@/core/container/types'
import type { PaginationUrlFilterSingleType } from '@/services/tables/types'
import TmLogicError from '@/core/error/tmLogicError'
import { dateFilterValueVariant, type DateFilterValueType } from '@/components/shared/tables/topFilter/dateFilter/types'
import type DateTimeService from '@/services/dateTimeService'
import { DATE_FORMATES } from '@/services/types'
import type UserService from '@/services/domain/user/userService'

@injectable()
export default class DateFilterMapper {
  constructor(
    @inject(SERVICE_TYPES.DateTimeService) protected readonly dateTimeService: DateTimeService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {}

  public mapDateFilter(_filter: PaginationUrlFilterSingleType, inputFilterName: string, outputFilterName: string) {
    const filter = cloneDeep(_filter)
    const type = (filter[inputFilterName].id as any).eq

    if (!dateFilterValueVariant.includes(type)) {
      throw new TmLogicError('incorrect date filter type')
    }

    const newDate = () => {
      const startDate = new Date()
      return new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()))
    }

    const getFilterValue = (count: number) => {
      return `-${count} days midnight`
    }

    const formatDate = (_date: Date) => {
      const currentUser = this.userService.currentUser(['timezone'])
      const date = this.dateTimeService.zonedTimeToUtc(_date, currentUser.timezone.timezone)
      const force24format = currentUser.displayTimeFormat === '24h'
      const normalizedDate = this.dateTimeService.toDate(date)

      return this.dateTimeService.formatDateForTimezone(
        normalizedDate,
        DATE_FORMATES.ISO_FORMAT_WITH_TIMEZONE,
        undefined,
        false,
        force24format,
        false,
      )
    }

    const handlers: Record<Exclude<DateFilterValueType, 'All'>, () => void> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Last 30 days': () => {
        filter[outputFilterName] = {
          gte: getFilterValue(29),
        }
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Last 60 days': () => {
        filter[outputFilterName] = {
          gte: getFilterValue(59),
        }
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Last week': () => {
        filter[outputFilterName] = {
          gte: getFilterValue(6),
        }
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Pick date range': () => {
        const from = (filter.from.id as any).eq
        if (typeof from !== 'string') {
          throw new TmLogicError('"from" should be type string')
        }
        const to = (filter.to.id as any).eq
        if (typeof to !== 'string') {
          throw new TmLogicError('"to" should be type string')
        }
        filter[outputFilterName] = {
          gte: formatDate(new Date(from)),
          lte: formatDate(new Date(to)),
        }
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Previous month': () => {
        const lastDatePrevMonth = this.dateTimeService.setDate(newDate(), 0)
        const firstDatePrevMonth = this.dateTimeService.setDate(lastDatePrevMonth, 1)
        filter[outputFilterName] = {
          gte: formatDate(firstDatePrevMonth),
          lte: formatDate(lastDatePrevMonth),
        }
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'This month': () => {
        filter[outputFilterName] = {
          gte: formatDate(this.dateTimeService.setDate(newDate(), 1)),
        }
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'This week': () => {
        filter[outputFilterName] = {
          gte: formatDate(this.dateTimeService.setDay(newDate(), 1)),
        }
      },
      Today: () => {
        filter[outputFilterName] = {
          gte: 'today midnight',
          lt: 'tomorrow midnight',
        }
      },
      Yesterday: () => {
        filter[outputFilterName] = {
          gte: 'yesterday midnight',
          lt: 'today midnight',
        }
      },
    }
    handlers[type]()
    delete filter[inputFilterName]
    delete filter.from
    delete filter.to
    return filter
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { tooltipAttributeName } from '@/directives/tooltip.directive'

import type {
  AnchorBoundingRect,
  EdgePositionStyle,
  ElementBoundingRect,
  TooltipDirection,
  TooltipPositionStyle,
} from '@/components/shared/tooltip/types'

export const getAnchorTooltipElement = (el?: HTMLElement): HTMLElement | null => {
  if (!el) return null
  if (el.hasAttribute(tooltipAttributeName)) return el
  return el.closest(`[${tooltipAttributeName}]`)
}

export const isElementInsideContainer = (element?: Node | null, container?: HTMLElement | null) => {
  return element && container?.contains(element)
}

export const getScrollbarWraps = (el?: HTMLElement | null, acc: HTMLElement[] = []) => {
  if (el?.parentElement && el.parentElement !== document.body) {
    const parent = el.parentElement
    const hasVerticalScrollbar = parent.scrollHeight > parent.clientHeight
    const hasHorizontalScrollbar = parent.scrollWidth > parent.clientWidth

    if (hasVerticalScrollbar || hasHorizontalScrollbar) {
      acc.push(parent)
    }

    getScrollbarWraps(parent, acc)
  }

  return acc
}

export const getElementPosition = (element: HTMLElement | AnchorBoundingRect): ElementBoundingRect => {
  let rect: DOMRect | AnchorBoundingRect
  if (element instanceof HTMLElement) {
    rect = element.getBoundingClientRect()
  } else {
    rect = element
  }
  const scrollTop = window.scrollY || document.documentElement.scrollTop
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft
  const top = rect.top + scrollTop
  const left = rect.left + scrollLeft

  return {
    top,
    left,
    right: left + rect.width,
    bottom: top + rect.height,
    centerX: left + rect.width / 2,
    centerY: top + rect.height / 2,
    width: rect.width,
    height: rect.height,
  }
}

const isOutOfBounds = (
  position: { top?: number; right?: number; bottom?: number; left?: number },
  tooltipBoundingRect: ElementBoundingRect,
  viewportWidth: number,
  viewportHeight: number,
) => {
  const { top, right, bottom, left } = position

  const outOfBoundsY =
    (typeof top === 'number' && top + tooltipBoundingRect.height > viewportHeight) ||
    (typeof bottom === 'number' && bottom + tooltipBoundingRect.height > viewportHeight)

  const outOfBoundsX =
    (typeof right === 'number' && right + tooltipBoundingRect.width > viewportWidth) ||
    (typeof left === 'number' && left + tooltipBoundingRect.width > viewportWidth)

  return {
    outOfBoundsX,
    outOfBoundsY,
  }
}

const calcTooltipPositionStyle = (
  anchorBoundingRect: ElementBoundingRect,
  tooltipBoundingRect: ElementBoundingRect,
  viewportWidth: number,
  viewportHeight: number,
  offset: number[],
): TooltipPositionStyle => {
  return {
    'top-left': {
      right: viewportWidth - anchorBoundingRect.right + offset[0],
      bottom: viewportHeight - anchorBoundingRect.top + offset[1],
    },
    top: {
      left: anchorBoundingRect.centerX - tooltipBoundingRect.width / 2 + offset[0],
      bottom: viewportHeight - anchorBoundingRect.top + offset[1],
    },
    'top-right': {
      left: anchorBoundingRect.left + offset[0],
      bottom: viewportHeight - anchorBoundingRect.top + offset[1],
    },

    'left-top': {
      right: viewportWidth + anchorBoundingRect.width - anchorBoundingRect.right + offset[0],
      bottom: viewportHeight - anchorBoundingRect.height - anchorBoundingRect.top + offset[1],
    },
    left: {
      right: viewportWidth + anchorBoundingRect.width - anchorBoundingRect.right + offset[0],
      top: anchorBoundingRect.centerY - tooltipBoundingRect.height / 2 - offset[1],
    },
    'left-bottom': {
      right: viewportWidth + anchorBoundingRect.width - anchorBoundingRect.right + offset[0],
      top: anchorBoundingRect.top + offset[1],
    },

    'right-top': {
      left: anchorBoundingRect.right + offset[0],
      bottom: viewportHeight - anchorBoundingRect.height - anchorBoundingRect.top + offset[1],
    },
    right: {
      left: anchorBoundingRect.right + offset[0],
      top: anchorBoundingRect.centerY - tooltipBoundingRect.height / 2 - offset[1],
    },
    'right-bottom': {
      left: anchorBoundingRect.right + offset[0],
      top: anchorBoundingRect.top + offset[1],
    },

    'bottom-left': {
      right: viewportWidth - anchorBoundingRect.right + offset[0],
      top: anchorBoundingRect.top + anchorBoundingRect.height + offset[1],
    },
    bottom: {
      left: anchorBoundingRect.centerX - tooltipBoundingRect.width / 2 + offset[0],
      top: anchorBoundingRect.top + anchorBoundingRect.height + offset[1],
    },
    'bottom-right': {
      left: anchorBoundingRect.left + offset[0],
      top: anchorBoundingRect.top + anchorBoundingRect.height + offset[1],
    },
  }
}

const getReversedPosition = (position: TooltipDirection, xReversed: boolean, yReversed: boolean): TooltipDirection => {
  const reversedPositions: Record<TooltipDirection, TooltipDirection> = {
    top: 'bottom',
    'top-left': 'bottom-right',
    'top-right': 'bottom-left',
    left: 'right',
    'left-top': 'right-bottom',
    'left-bottom': 'right-top',
    right: 'left',
    'right-top': 'left-bottom',
    'right-bottom': 'left-top',
    bottom: 'top',
    'bottom-left': 'top-right',
    'bottom-right': 'top-left',
  }

  const xReversedPositions: Record<TooltipDirection, TooltipDirection> = {
    top: 'top',
    'top-left': 'top-right',
    'top-right': 'top-left',
    left: 'right',
    'left-top': 'right-top',
    'left-bottom': 'right-bottom',
    right: 'left',
    'right-top': 'left-top',
    'right-bottom': 'left-bottom',
    bottom: 'bottom',
    'bottom-left': 'bottom-right',
    'bottom-right': 'bottom-left',
  }

  const yReversedPositions: Record<TooltipDirection, TooltipDirection> = {
    top: 'bottom',
    'top-left': 'bottom-left',
    'top-right': 'bottom-right',
    left: 'left',
    'left-top': 'left-bottom',
    'left-bottom': 'left-top',
    right: 'right',
    'right-top': 'right-bottom',
    'right-bottom': 'right-top',
    bottom: 'top',
    'bottom-left': 'top-left',
    'bottom-right': 'top-right',
  }

  if (xReversed && yReversed) return reversedPositions[position]
  if (xReversed) return xReversedPositions[position] || position
  if (yReversed) return yReversedPositions[position] || position
  return position
}

const fitTooltipWithinBounds = (
  positionStyle: EdgePositionStyle,
  tooltipBoundingRect: ElementBoundingRect,
  viewportWidth: number,
  viewportHeight: number,
) => {
  const check = (bound: number, tooltipBreadth: number, value?: number) => {
    return typeof value === 'number' ? Math.max(0, Math.min(value, bound - tooltipBreadth)) : undefined
  }

  return {
    top: check(viewportHeight, tooltipBoundingRect.height, positionStyle.top),
    right: check(viewportWidth, tooltipBoundingRect.width, positionStyle.right),
    bottom: check(viewportHeight, tooltipBoundingRect.height, positionStyle.bottom),
    left: check(viewportWidth, tooltipBoundingRect.width, positionStyle.left),
  }
}

export const getTooltipPositionStyle = (
  position: TooltipDirection,
  anchor: HTMLElement | AnchorBoundingRect,
  tooltipEl: HTMLElement,
  offset = [0, 0],
) => {
  const viewportWidth = document.body.clientWidth
  const viewportHeight = document.body.clientHeight
  const anchorBoundingRect = getElementPosition(anchor)
  const tooltipBoundingRect = getElementPosition(tooltipEl)

  const positionStyles = calcTooltipPositionStyle(
    anchorBoundingRect,
    tooltipBoundingRect,
    viewportWidth,
    viewportHeight,
    offset,
  )
  let positionStyle = positionStyles[position]

  const { outOfBoundsX, outOfBoundsY } = isOutOfBounds(
    positionStyle,
    tooltipBoundingRect,
    viewportWidth,
    viewportHeight,
  )

  if (outOfBoundsX || outOfBoundsY) {
    const reversedPosition = getReversedPosition(position, outOfBoundsX, outOfBoundsY)
    positionStyle = positionStyles[reversedPosition]
    position = reversedPosition
  }

  const positionsWithinBounds = fitTooltipWithinBounds(
    positionStyle,
    tooltipBoundingRect,
    viewportWidth,
    viewportHeight,
  )

  return {
    viewportWidth,
    viewportHeight,
    position,
    anchorBoundingRect,
    top: positionsWithinBounds.top,
    right: positionsWithinBounds.right,
    left: positionsWithinBounds.left,
    bottom: positionsWithinBounds.bottom,
  }
}

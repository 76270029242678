// modified https://github.com/selfrefactor/rambda/blob/master/src/where.js
import type { Dict } from '@/types'

export const where = (conditions: Dict<(input: unknown) => boolean>) => (input: unknown) => _where(conditions, input)

function _where(conditions, input) {
  let flag = true
  for (const prop in conditions) {
    if (!flag) continue
    const result = conditions[prop](input[prop])
    if (flag && result === false) {
      flag = false
    }
  }

  return flag
}

import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/container'
import { withUniqueKeys } from '@/decorators/resolvers/withUniqueKeys'
import type { UniqueKeys } from '@/types'
import type InstagramAccountService from '@/services/domain/messenger/instagram/instagramAccountService'
import type RouterService from '@/services/route/routerService'
import { ChatSettingsRoutes } from '@/routes/types'

@withUniqueKeys('ChatInstagramResolver')
@injectable()
export default class ChatInstagramResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.InstagramAccountService) protected readonly instagramAccountService: InstagramAccountService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
  ) {}

  public async resolve() {
    if (this.routerService.currentRoute.value.name !== ChatSettingsRoutes.instagramSettings) {
      // ignore the ChatSettingsRoutes.instagramSettings route because we have request table data there
      const account = this.instagramAccountService.firstOrNull()
      if (!account) {
        await this.instagramAccountService.fillAll()
      }
    }
  }

  public async unresolve() {}

  public getResolverKeys(): UniqueKeys {
    return []
  }
}

import type { SelectOption } from '@/services/forms/types'
import type { TmCountryCode } from '@/services/types'

export type CountryPhoneData = SelectOption & {
  searchString: string
  value: TmCountryCode
  code: string
  text: string
  mask: string | string[]
}

export const countryPhoneData: CountryPhoneData[] = [
  {
    searchString: 'af93Afghanistan',
    value: 'AF',
    code: '93',
    text: 'Afghanistan',
    mask: '##-###-####',
  },
  {
    searchString: 'al355Albania',
    value: 'AL',
    code: '355',
    text: 'Albania',
    mask: '(###) ###-###',
  },
  {
    searchString: 'dz213Algeria',
    value: 'DZ',
    code: '213',
    text: 'Algeria',
    mask: '##-###-####',
  },
  {
    searchString: 'as1AmericanSamoa',
    value: 'AS',
    code: '1',
    text: 'American Samoa',
    mask: '(###) ###-####',
  },
  {
    searchString: 'ad376Andorra',
    value: 'AD',
    code: '376',
    text: 'Andorra',
    mask: '###-###',
  },
  {
    searchString: 'ao244Angola',
    value: 'AO',
    code: '244',
    text: 'Angola',
    mask: '(###) ###-###',
  },
  {
    searchString: 'ai1Anguilla',
    value: 'AI',
    code: '1',
    text: 'Anguilla',
    mask: '(###) ###-####',
  },
  /* {
    searchString: 'aq672Antarctica',
    value: 'AQ',
    code: '672',
    text: 'Antarctica',
    mask: '###-###',
  }, */
  {
    searchString: 'ag1AntiguaandBarbuda',
    value: 'AG',
    code: '1',
    text: 'Antigua and Barbuda',
    mask: '(###) ###-####',
  },
  {
    searchString: 'ar54Argentina',
    value: 'AR',
    code: '54',
    text: 'Argentina',
    mask: '(###) ###-####',
  },
  {
    searchString: 'am374Armenia',
    value: 'AM',
    code: '374',
    text: 'Armenia',
    mask: '##-###-###',
  },
  {
    searchString: 'aw297Aruba',
    value: 'AW',
    code: '297',
    text: 'Aruba',
    mask: '###-####',
  },
  {
    searchString: 'au61Australia',
    value: 'AU',
    code: '61',
    text: 'Australia',
    mask: '#-####-####',
  },
  {
    searchString: 'at43Austria',
    value: 'AT',
    code: '43',
    text: 'Austria',
    mask: '(###) ###-####',
  },
  {
    searchString: 'az994Azerbaijan',
    value: 'AZ',
    code: '994',
    text: 'Azerbaijan',
    mask: '##-###-##-##',
  },
  {
    searchString: 'bs1Bahamas',
    value: 'BS',
    code: '1',
    text: 'Bahamas',
    mask: '(###) ###-####',
  },
  {
    searchString: 'bh973Bahrain',
    value: 'BH',
    code: '973',
    text: 'Bahrain',
    mask: '####-####',
  },
  {
    searchString: 'bd880Bangladesh',
    value: 'BD',
    code: '880',
    text: 'Bangladesh',
    mask: '####-######',
  },
  {
    searchString: 'bb1Barbados',
    value: 'BB',
    code: '1',
    text: 'Barbados',
    mask: '(###) ###-####',
  },
  {
    searchString: 'by375Belarus',
    value: 'BY',
    code: '375',
    text: 'Belarus',
    mask: '(##) ###-##-##',
  },
  {
    searchString: 'be32Belgium',
    value: 'BE',
    code: '32',
    text: 'Belgium',
    mask: '(###) ###-###',
  },
  {
    searchString: 'bz501Belize',
    value: 'BZ',
    code: '501',
    text: 'Belize',
    mask: '###-####',
  },
  {
    searchString: 'bj229Benin',
    value: 'BJ',
    code: '229',
    text: 'Benin',
    mask: '##-##-####',
  },
  {
    searchString: 'bm1Bermuda',
    value: 'BM',
    code: '1',
    text: 'Bermuda',
    mask: '(###) ###-####',
  },
  {
    searchString: 'bt975Bhutan',
    value: 'BT',
    code: '975',
    text: 'Bhutan',
    mask: ['##-###-###', '##-###-###', '#-###-###'],
  },
  {
    searchString: 'bo591Bolivia',
    value: 'BO',
    code: '591',
    text: 'Bolivia',
    mask: '#-###-####',
  },
  {
    searchString: 'ba387BosniaandHerzegovina',
    value: 'BA',
    code: '387',
    text: 'Bosnia and Herzegovina',
    mask: ['##-####', '##-#####'],
  },
  {
    searchString: 'bw267Botswana',
    value: 'BW',
    code: '267',
    text: 'Botswana',
    mask: '##-###-###',
  },
  {
    searchString: 'br55Brazil',
    value: 'BR',
    code: '55',
    text: 'Brazil',
    mask: ['(##) ####-####', '(##) #####-####'],
  },
  {
    searchString: 'io246BritishIndianOceanTerrito',
    value: 'IO',
    code: '246',
    text: 'British Indian Ocean Territory',
    mask: '###-####',
  },
  {
    searchString: 'vg1BritishVirginIslands',
    value: 'VG',
    code: '1',
    text: 'British Virgin Islands',
    mask: '(###) ###-####',
  },
  {
    searchString: 'bn673Brunei',
    value: 'BN',
    code: '673',
    text: 'Brunei',
    mask: '###-####',
  },
  {
    searchString: 'bg359Bulgaria',
    value: 'BG',
    code: '359',
    text: 'Bulgaria',
    mask: '(###) ###-###',
  },
  {
    searchString: 'bf226BurkinaFaso',
    value: 'BF',
    code: '226',
    text: 'Burkina Faso',
    mask: '##-##-####',
  },
  {
    searchString: 'bi257Burundi',
    value: 'BI',
    code: '257',
    text: 'Burundi',
    mask: '##-##-####',
  },
  {
    searchString: 'kh855Cambodia',
    value: 'KH',
    code: '855',
    text: 'Cambodia',
    mask: '##-###-###',
  },
  {
    searchString: 'cm237Cameroon',
    value: 'CM',
    code: '237',
    text: 'Cameroon',
    mask: '####-####',
  },
  {
    searchString: 'ca1Canada',
    value: 'CA',
    code: '1',
    text: 'Canada',
    mask: '(###) ###-####',
  },
  {
    searchString: 'cv238CapeVerde',
    value: 'CV',
    code: '238',
    text: 'Cape Verde',
    mask: '(###) ##-##',
  },
  {
    searchString: 'ky1CaymanIslands',
    value: 'KY',
    code: '1',
    text: 'Cayman Islands',
    mask: '(###) ###-####',
  },
  {
    searchString: 'cf236CentralAfricanRepublic',
    value: 'CF',
    code: '236',
    text: 'Central African Republic',
    mask: '##-##-####',
  },
  {
    searchString: 'td235Chad',
    value: 'TD',
    code: '235',
    text: 'Chad',
    mask: '##-##-##-##',
  },
  {
    searchString: 'cl56Chile',
    value: 'CL',
    code: '56',
    text: 'Chile',
    mask: '#-####-####',
  },
  {
    searchString: 'cn86China',
    value: 'CN',
    code: '86',
    text: 'China',
    mask: ['(###) ####-###', '(###) ####-####', '##-#####-#####'],
  },
  {
    searchString: 'cx61ChristmasIsland',
    value: 'CX',
    code: '61',
    text: 'Christmas Island',
    mask: '#-####-####',
  },
  {
    searchString: 'cc61CocosIslands',
    value: 'CC',
    code: '61',
    text: 'Cocos Islands',
    mask: '#-####-####',
  },
  {
    searchString: 'co57Colombia',
    value: 'CO',
    code: '57',
    text: 'Colombia',
    mask: '(###) ###-####',
  },
  {
    searchString: 'km269Comoros',
    value: 'KM',
    code: '269',
    text: 'Comoros',
    mask: '##-#####',
  },
  {
    searchString: 'ck682CookIslands',
    value: 'CK',
    code: '682',
    text: 'Cook Islands',
    mask: '##-###',
  },
  {
    searchString: 'cr506CostaRica',
    value: 'CR',
    code: '506',
    text: 'Costa Rica',
    mask: '####-####',
  },
  {
    searchString: 'hr385Croatia',
    value: 'HR',
    code: '385',
    text: 'Croatia',
    mask: '##-###-###',
  },
  {
    searchString: 'cu53Cuba',
    value: 'CU',
    code: '53',
    text: 'Cuba',
    mask: '#-###-####',
  },
  {
    searchString: 'cy357Cyprus',
    value: 'CY',
    code: '357',
    text: 'Cyprus',
    mask: '##-###-###',
  },
  {
    searchString: 'cz420CzechRepublic',
    value: 'CZ',
    code: '420',
    text: 'Czech Republic',
    mask: '(###) ###-###',
  },
  {
    searchString: 'cd243DemocraticRepublicoftheCo',
    value: 'CD',
    code: '243',
    text: 'Democratic Republic of the Congo',
    mask: '(###) ###-###',
  },
  {
    searchString: 'dk45Denmark',
    value: 'DK',
    code: '45',
    text: 'Denmark',
    mask: '##-##-##-##',
  },
  {
    searchString: 'dj253Djibouti',
    value: 'DJ',
    code: '253',
    text: 'Djibouti',
    mask: '##-##-##-##',
  },
  {
    searchString: 'dm1Dominica',
    value: 'DM',
    code: '1',
    text: 'Dominica',
    mask: '(###) ###-####',
  },
  {
    searchString: 'do1DominicanRepublic',
    value: 'DO',
    code: '1',
    text: 'Dominican Republic',
    mask: ['(###) ###-####', '(###) ###-####', '(###) ###-####'],
  },
  {
    searchString: 'tl670EastTimor',
    value: 'TL',
    code: '670',
    text: 'East Timor',
    mask: ['###-####', '###-#####', '###-#####'],
  },
  {
    searchString: 'ec593Ecuador',
    value: 'EC',
    code: '593',
    text: 'Ecuador',
    mask: ['#-###-####', '##-###-####'],
  },
  {
    searchString: 'eg20Egypt',
    value: 'EG',
    code: '20',
    text: 'Egypt',
    mask: '(###) ###-####',
  },
  {
    searchString: 'sv503ElSalvador',
    value: 'SV',
    code: '503',
    text: 'El Salvador',
    mask: '##-##-####',
  },
  {
    searchString: 'gq240EquatorialGuinea',
    value: 'GQ',
    code: '240',
    text: 'Equatorial Guinea',
    mask: '##-###-####',
  },
  {
    searchString: 'er291Eritrea',
    value: 'ER',
    code: '291',
    text: 'Eritrea',
    mask: '#-###-###',
  },
  {
    searchString: 'ee372Estonia',
    value: 'EE',
    code: '372',
    text: 'Estonia',
    mask: ['###-####', '####-####'],
  },
  {
    searchString: 'et251Ethiopia',
    value: 'ET',
    code: '251',
    text: 'Ethiopia',
    mask: '##-###-####',
  },
  {
    searchString: 'fk500FalklandIslands',
    value: 'FK',
    code: '500',
    text: 'Falkland Islands',
    mask: '#####',
  },
  {
    searchString: 'fo298FaroeIslands',
    value: 'FO',
    code: '298',
    text: 'Faroe Islands',
    mask: '###-###',
  },
  {
    searchString: 'fj679Fiji',
    value: 'FJ',
    code: '679',
    text: 'Fiji',
    mask: '##-#####',
  },
  {
    searchString: 'fi358Finland',
    value: 'FI',
    code: '358',
    text: 'Finland',
    mask: '(###) ###-##-##',
  },
  {
    searchString: 'fr33France',
    value: 'FR',
    code: '33',
    text: 'France',
    mask: '(###) ###-###',
  },
  {
    searchString: 'pf689FrenchPolynesia',
    value: 'PF',
    code: '689',
    text: 'French Polynesia',
    mask: '##-##-##',
  },
  {
    searchString: 'ga241Gabon',
    value: 'GA',
    code: '241',
    text: 'Gabon',
    mask: '#-##-##-##',
  },
  {
    searchString: 'gm220Gambia',
    value: 'GM',
    code: '220',
    text: 'Gambia',
    mask: '(###) ##-##',
  },
  {
    searchString: 'ge995Georgia',
    value: 'GE',
    code: '995',
    text: 'Georgia',
    mask: '(###) ###-###',
  },
  {
    searchString: 'de49Germany',
    value: 'DE',
    code: '49',
    text: 'Germany',
    mask: ['###-###', '(###) ##-##', '(###) ##-###', '(###) ##-####', '(###) ###-####', '(####) ###-####'],
  },
  {
    searchString: 'gh233Ghana',
    value: 'GH',
    code: '233',
    text: 'Ghana',
    mask: '(###) ###-###',
  },
  {
    searchString: 'gi350Gibraltar',
    value: 'GI',
    code: '350',
    text: 'Gibraltar',
    mask: '###-#####',
  },
  {
    searchString: 'gr30Greece',
    value: 'GR',
    code: '30',
    text: 'Greece',
    mask: '(###) ###-####',
  },
  {
    searchString: 'gl299Greenland',
    value: 'GL',
    code: '299',
    text: 'Greenland',
    mask: '##-##-##',
  },
  {
    searchString: 'gd1Grenada',
    value: 'GD',
    code: '1',
    text: 'Grenada',
    mask: '(###) ###-####',
  },
  {
    searchString: 'gu1Guam',
    value: 'GU',
    code: '1',
    text: 'Guam',
    mask: '(###) ###-####',
  },
  {
    searchString: 'gt502Guatemala',
    value: 'GT',
    code: '502',
    text: 'Guatemala',
    mask: '#-###-####',
  },
  {
    searchString: 'gg44Guernsey',
    value: 'GG',
    code: '44',
    text: 'Guernsey',
    mask: '(####) ######',
  },
  {
    searchString: 'gn224Guinea',
    value: 'GN',
    code: '224',
    text: 'Guinea',
    mask: '##-###-###',
  },
  {
    searchString: 'gw245Guinea-Bissau',
    value: 'GW',
    code: '245',
    text: 'Guinea-Bissau',
    mask: '#-######',
  },
  {
    searchString: 'gy592Guyana',
    value: 'GY',
    code: '592',
    text: 'Guyana',
    mask: '###-####',
  },
  {
    searchString: 'ht509Haiti',
    value: 'HT',
    code: '509',
    text: 'Haiti',
    mask: '##-##-####',
  },
  {
    searchString: 'hn504Honduras',
    value: 'HN',
    code: '504',
    text: 'Honduras',
    mask: '####-####',
  },
  {
    searchString: 'hk852HongKong',
    value: 'HK',
    code: '852',
    text: 'Hong Kong',
    mask: '####-####',
  },
  {
    searchString: 'hu36Hungary',
    value: 'HU',
    code: '36',
    text: 'Hungary',
    mask: '(###) ###-###',
  },
  {
    searchString: 'is354Iceland',
    value: 'IS',
    code: '354',
    text: 'Iceland',
    mask: '###-####',
  },
  {
    searchString: 'in91India',
    value: 'IN',
    code: '91',
    text: 'India',
    mask: '(####) ###-###',
  },
  {
    searchString: 'id62Indonesia',
    value: 'ID',
    code: '62',
    text: 'Indonesia',
    mask: ['##-###-##', '##-###-###', '##-###-####', '(###) ###-###', '(###) ###-##-###'],
  },
  {
    searchString: 'ir98Iran',
    value: 'IR',
    code: '98',
    text: 'Iran',
    mask: '(###) ###-####',
  },
  {
    searchString: 'iq964Iraq',
    value: 'IQ',
    code: '964',
    text: 'Iraq',
    mask: '(###) ###-####',
  },
  {
    searchString: 'ie353Ireland',
    value: 'IE',
    code: '353',
    text: 'Ireland',
    mask: '(###) ###-###',
  },
  {
    searchString: 'im44IsleofMan',
    value: 'IM',
    code: '44',
    text: 'Isle of Man',
    mask: '(####) ######',
  },
  {
    searchString: 'il972Israel',
    value: 'IL',
    code: '972',
    text: 'Israel',
    mask: ['#-###-####', '##-###-####'],
  },
  {
    searchString: 'it39Italy',
    value: 'IT',
    code: '39',
    text: 'Italy',
    mask: '(###) ####-###',
  },
  {
    searchString: 'ci225IvoryCoast',
    value: 'CI',
    code: '225',
    text: 'Ivory Coast',
    mask: '##-###-###',
  },
  {
    searchString: 'jm1Jamaica',
    value: 'JM',
    code: '1',
    text: 'Jamaica',
    mask: '(###) ###-####',
  },
  {
    searchString: 'jp81Japan',
    value: 'JP',
    code: '81',
    text: 'Japan',
    mask: ['(###) ###-###', '##-####-####'],
  },
  {
    searchString: 'je44Jersey',
    value: 'JE',
    code: '44',
    text: 'Jersey',
    mask: '(####) ####-######',
  },
  {
    searchString: 'jo962Jordan',
    value: 'JO',
    code: '962',
    text: 'Jordan',
    mask: '#-####-####',
  },
  {
    searchString: 'kz7Kazakhstan',
    value: 'KZ',
    code: '7',
    text: 'Kazakhstan',
    mask: ['(###) ###-##-##', '(###) ###-##-##'],
  },
  {
    searchString: 'ke254Kenya',
    value: 'KE',
    code: '254',
    text: 'Kenya',
    mask: '###-######',
  },
  {
    searchString: 'ki686Kiribati',
    value: 'KI',
    code: '686',
    text: 'Kiribati',
    mask: '##-###',
  },
  {
    searchString: 'xk383Kosovo',
    value: 'XK',
    code: '383',
    text: 'Kosovo',
    mask: ['##-###-###', '###-###-###'],
  },
  {
    searchString: 'kw965Kuwait',
    value: 'KW',
    code: '965',
    text: 'Kuwait',
    mask: '####-####',
  },
  {
    searchString: 'kg996Kyrgyzstan',
    value: 'KG',
    code: '996',
    text: 'Kyrgyzstan',
    mask: '(###) ###-###',
  },
  {
    searchString: 'la856Laos',
    value: 'LA',
    code: '856',
    text: 'Laos',
    mask: ['##-###-###', '(####) ###-###'],
  },
  {
    searchString: 'lv371Latvia',
    value: 'LV',
    code: '371',
    text: 'Latvia',
    mask: '##-###-###',
  },
  {
    searchString: 'lb961Lebanon',
    value: 'LB',
    code: '961',
    text: 'Lebanon',
    mask: ['#-###-###', '##-###-###'],
  },
  {
    searchString: 'ls266Lesotho',
    value: 'LS',
    code: '266',
    text: 'Lesotho',
    mask: '#-###-####',
  },
  {
    searchString: 'lr231Liberia',
    value: 'LR',
    code: '231',
    text: 'Liberia',
    mask: '##-###-###',
  },
  {
    searchString: 'ly218Libya',
    value: 'LY',
    code: '218',
    text: 'Libya',
    mask: ['##-###-###', '##-###-####'],
  },
  {
    searchString: 'li423Liechtenstein',
    value: 'LI',
    code: '423',
    text: 'Liechtenstein',
    mask: '(###) ###-####',
  },
  {
    searchString: 'lt370Lithuania',
    value: 'LT',
    code: '370',
    text: 'Lithuania',
    mask: '(###) ##-###',
  },
  {
    searchString: 'lu352Luxembourg',
    value: 'LU',
    code: '352',
    text: 'Luxembourg',
    mask: '(###) ###-###',
  },
  {
    searchString: 'mo853Macau',
    value: 'MO',
    code: '853',
    text: 'Macau',
    mask: '####-####',
  },
  {
    searchString: 'mk389Macedonia',
    value: 'MK',
    code: '389',
    text: 'Macedonia',
    mask: '##-###-###',
  },
  {
    searchString: 'mg261Madagascar',
    value: 'MG',
    code: '261',
    text: 'Madagascar',
    mask: '##-##-#####',
  },
  {
    searchString: 'mw265Malawi',
    value: 'MW',
    code: '265',
    text: 'Malawi',
    mask: ['#-###-###', '#-####-####'],
  },
  {
    searchString: 'my60Malaysia',
    value: 'MY',
    code: '60',
    text: 'Malaysia',
    mask: ['#-###-###', '##-###-###', '(###) ###-###', '##-###-####'],
  },
  {
    searchString: 'mv960Maldives',
    value: 'MV',
    code: '960',
    text: 'Maldives',
    mask: '###-####',
  },
  {
    searchString: 'ml223Mali',
    value: 'ML',
    code: '223',
    text: 'Mali',
    mask: '##-##-####',
  },
  {
    searchString: 'mt356Malta',
    value: 'MT',
    code: '356',
    text: 'Malta',
    mask: '####-####',
  },
  {
    searchString: 'mh692MarshallIslands',
    value: 'MH',
    code: '692',
    text: 'Marshall Islands',
    mask: '###-####',
  },
  {
    searchString: 'mr222Mauritania',
    value: 'MR',
    code: '222',
    text: 'Mauritania',
    mask: '##-##-####',
  },
  {
    searchString: 'mu230Mauritius',
    value: 'MU',
    code: '230',
    text: 'Mauritius',
    mask: '###-####',
  },
  {
    searchString: 'yt262Mayotte',
    value: 'YT',
    code: '262',
    text: 'Mayotte',
    mask: '#####-####',
  },
  {
    searchString: 'mx52Mexico',
    value: 'MX',
    code: '52',
    text: 'Mexico',
    mask: ['##-##-####', '(###) ###-####'],
  },
  {
    searchString: 'fm691Micronesia',
    value: 'FM',
    code: '691',
    text: 'Micronesia',
    mask: '###-####',
  },
  {
    searchString: 'md373Moldova',
    value: 'MD',
    code: '373',
    text: 'Moldova',
    mask: '####-####',
  },
  {
    searchString: 'mc377Monaco',
    value: 'MC',
    code: '377',
    text: 'Monaco',
    mask: ['##-###-###', '(###) ###-###'],
  },
  {
    searchString: 'mn976Mongolia',
    value: 'MN',
    code: '976',
    text: 'Mongolia',
    mask: '##-##-####',
  },
  {
    searchString: 'me382Montenegro',
    value: 'ME',
    code: '382',
    text: 'Montenegro',
    mask: '##-###-###',
  },
  {
    searchString: 'ms1Montserrat',
    value: 'MS',
    code: '1',
    text: 'Montserrat',
    mask: '(###) ###-####',
  },
  {
    searchString: 'ma212Morocco',
    value: 'MA',
    code: '212',
    text: 'Morocco',
    mask: '##-####-###',
  },
  {
    searchString: 'mz258Mozambique',
    value: 'MZ',
    code: '258',
    text: 'Mozambique',
    mask: '##-###-###',
  },
  {
    searchString: 'mm95Myanmar',
    value: 'MM',
    code: '95',
    text: 'Myanmar',
    mask: ['###-###', '#-###-###', '##-###-###'],
  },
  {
    searchString: 'na264Namibia',
    value: 'NA',
    code: '264',
    text: 'Namibia',
    mask: '##-###-####',
  },
  {
    searchString: 'nr674Nauru',
    value: 'NR',
    code: '674',
    text: 'Nauru',
    mask: '###-####',
  },
  {
    searchString: 'np977Nepal',
    value: 'NP',
    code: '977',
    text: 'Nepal',
    mask: '##-###-###',
  },
  {
    searchString: 'nl31Netherlands',
    value: 'NL',
    code: '31',
    text: 'Netherlands',
    mask: '##-###-####',
  },
  /* {
    searchString: 'an599NetherlandsAntilles',
    value: 'AN',
    code: '599',
    text: 'Netherlands Antilles',
    mask: ['###-####', '####-####'],
  }, */
  {
    searchString: 'nc687NewCaledonia',
    value: 'NC',
    code: '687',
    text: 'New Caledonia',
    mask: '##-####',
  },
  {
    searchString: 'nz64NewZealand',
    value: 'NZ',
    code: '64',
    text: 'New Zealand',
    mask: ['#-###-###', '(###) ###-###', '(###) ###-####'],
  },
  {
    searchString: 'ni505Nicaragua',
    value: 'NI',
    code: '505',
    text: 'Nicaragua',
    mask: '####-####',
  },
  {
    searchString: 'ne227Niger',
    value: 'NE',
    code: '227',
    text: 'Niger',
    mask: '##-##-####',
  },
  {
    searchString: 'ng234Nigeria',
    value: 'NG',
    code: '234',
    text: 'Nigeria',
    mask: ['##-###-##', '##-###-###', '(###) ###-####'],
  },
  {
    searchString: 'nu683Niue',
    value: 'NU',
    code: '683',
    text: 'Niue',
    mask: '####',
  },
  {
    searchString: 'nf672NorfolkIsland',
    value: 'NF',
    code: '672',
    text: 'Norfolk Island',
    mask: '####',
  },
  {
    searchString: 'kp850NorthKorea',
    value: 'KP',
    code: '850',
    text: 'North Korea',
    mask: ['###-###', '####-####', '##-###-###', '###-####-###', '###-###-####', '####-#############'],
  },
  {
    searchString: 'mp1NorthernMarianaIslands',
    value: 'MP',
    code: '1',
    text: 'Northern Mariana Islands',
    mask: '(###) ###-####',
  },
  {
    searchString: 'no47Norway',
    value: 'NO',
    code: '47',
    text: 'Norway',
    mask: '(###) ##-###',
  },
  {
    searchString: 'om968Oman',
    value: 'OM',
    code: '968',
    text: 'Oman',
    mask: '##-###-###',
  },
  {
    searchString: 'pk92Pakistan',
    value: 'PK',
    code: '92',
    text: 'Pakistan',
    mask: '(###) ###-####',
  },
  {
    searchString: 'pw680Palau',
    value: 'PW',
    code: '680',
    text: 'Palau',
    mask: '###-####',
  },
  {
    searchString: 'ps970Palestine',
    value: 'PS',
    code: '970',
    text: 'Palestine',
    mask: '##-###-####',
  },
  {
    searchString: 'pa507Panama',
    value: 'PA',
    code: '507',
    text: 'Panama',
    mask: '###-####',
  },
  {
    searchString: 'pg675PapuaNewGuinea',
    value: 'PG',
    code: '675',
    text: 'Papua New Guinea',
    mask: '(###) ##-###',
  },
  {
    searchString: 'py595Paraguay',
    value: 'PY',
    code: '595',
    text: 'Paraguay',
    mask: '(###) ###-###',
  },
  {
    searchString: 'pe51Peru',
    value: 'PE',
    code: '51',
    text: 'Peru',
    mask: '(###) ###-###',
  },
  {
    searchString: 'ph63Philippines',
    value: 'PH',
    code: '63',
    text: 'Philippines',
    mask: '(###) ###-####',
  },
  /* {
    searchString: 'pn64Pitcairn',
    value: 'PN',
    code: '64',
    text: 'Pitcairn',
    mask: '###-###-###',
  }, */
  {
    searchString: 'pl48Poland',
    value: 'PL',
    code: '48',
    text: 'Poland',
    mask: '(###) ###-###',
  },
  {
    searchString: 'pt351Portugal',
    value: 'PT',
    code: '351',
    text: 'Portugal',
    mask: '##-###-####',
  },
  {
    searchString: 'pr1PuertoRico',
    value: 'PR',
    code: '1',
    text: 'Puerto Rico',
    mask: ['(###) ### ####', '(###) ### ####'],
  },
  {
    searchString: 'qa974Qatar',
    value: 'QA',
    code: '974',
    text: 'Qatar',
    mask: '####-####',
  },
  {
    searchString: 'cg242RepublicoftheCongo',
    value: 'CG',
    code: '242',
    text: 'Republic of the Congo',
    mask: '##-#####',
  },
  {
    searchString: 're262Reunion',
    value: 'RE',
    code: '262',
    text: 'Reunion',
    mask: '#####-####',
  },
  {
    searchString: 'ro40Romania',
    value: 'RO',
    code: '40',
    text: 'Romania',
    mask: '##-###-####',
  },
  {
    searchString: 'ru7Russia',
    value: 'RU',
    code: '7',
    text: 'Russia',
    mask: '(###) ###-##-##',
  },
  {
    searchString: 'rw250Rwanda',
    value: 'RW',
    code: '250',
    text: 'Rwanda',
    mask: '(###) ###-###',
  },
  {
    searchString: 'bl590SaintBarthelemy',
    value: 'BL',
    code: '590',
    text: 'Saint Barthelemy',
    mask: '###-##-##-##',
  },
  {
    searchString: 'sh290SaintHelena',
    value: 'SH',
    code: '290',
    text: 'Saint Helena',
    mask: '####',
  },
  {
    searchString: 'kn1SaintKittsandNevis',
    value: 'KN',
    code: '1',
    text: 'Saint Kitts and Nevis',
    mask: '(###) ###-####',
  },
  {
    searchString: 'lc1SaintLucia',
    value: 'LC',
    code: '1',
    text: 'Saint Lucia',
    mask: '(###) ###-####',
  },
  {
    searchString: 'mf590SaintMartin',
    value: 'MF',
    code: '590',
    text: 'Saint Martin',
    mask: '(###) ###-###',
  },
  {
    searchString: 'pm508SaintPierreandMiquelon',
    value: 'PM',
    code: '508',
    text: 'Saint Pierre and Miquelon',
    mask: '##-####',
  },
  {
    searchString: 'vc1SaintVincentandtheGrenadi',
    value: 'VC',
    code: '1',
    text: 'Saint Vincent and the Grenadines',
    mask: '(###) ###-####',
  },
  {
    searchString: 'ws685Samoa',
    value: 'WS',
    code: '685',
    text: 'Samoa',
    mask: '##-####',
  },
  {
    searchString: 'sm378SanMarino',
    value: 'SM',
    code: '378',
    text: 'San Marino',
    mask: '####-######',
  },
  {
    searchString: 'st239SaoTomeandPrincipe',
    value: 'ST',
    code: '239',
    text: 'Sao Tome and Principe',
    mask: '##-#####',
  },
  {
    searchString: 'sa966SaudiArabia',
    value: 'SA',
    code: '966',
    text: 'Saudi Arabia',
    mask: ['#-###-####', '##-####-####'],
  },
  {
    searchString: 'sn221Senegal',
    value: 'SN',
    code: '221',
    text: 'Senegal',
    mask: '##-###-####',
  },
  {
    searchString: 'rs381Serbia',
    value: 'RS',
    code: '381',
    text: 'Serbia',
    mask: '##-###-####',
  },
  {
    searchString: 'sc248Seychelles',
    value: 'SC',
    code: '248',
    text: 'Seychelles',
    mask: '#-###-###',
  },
  {
    searchString: 'sl232SierraLeone',
    value: 'SL',
    code: '232',
    text: 'Sierra Leone',
    mask: '##-######',
  },
  {
    searchString: 'sg65Singapore',
    value: 'SG',
    code: '65',
    text: 'Singapore',
    mask: '####-####',
  },
  {
    searchString: 'sx1SintMaarten',
    value: 'SX',
    code: '1',
    text: 'Sint Maarten',
    mask: '(###) ###-####',
  },
  {
    searchString: 'sk421Slovakia',
    value: 'SK',
    code: '421',
    text: 'Slovakia',
    mask: '(###) ###-###',
  },
  {
    searchString: 'si386Slovenia',
    value: 'SI',
    code: '386',
    text: 'Slovenia',
    mask: '##-###-###',
  },
  {
    searchString: 'sb677SolomonIslands',
    value: 'SB',
    code: '677',
    text: 'Solomon Islands',
    mask: ['#####', '###-####'],
  },
  {
    searchString: 'so252Somalia',
    value: 'SO',
    code: '252',
    text: 'Somalia',
    mask: ['#-###-###', '##-###-###'],
  },
  {
    searchString: 'za27SouthAfrica',
    value: 'ZA',
    code: '27',
    text: 'South Africa',
    mask: '##-###-####',
  },
  {
    searchString: 'kr82SouthKorea',
    value: 'KR',
    code: '82',
    text: 'South Korea',
    mask: '##-###-####',
  },
  {
    searchString: 'ss211SouthSudan',
    value: 'SS',
    code: '211',
    text: 'South Sudan',
    mask: '##-###-####',
  },
  {
    searchString: 'es34Spain',
    value: 'ES',
    code: '34',
    text: 'Spain',
    mask: '(###) ###-###',
  },
  {
    searchString: 'lk94SriLanka',
    value: 'LK',
    code: '94',
    text: 'Sri Lanka',
    mask: '##-###-####',
  },
  {
    searchString: 'sd249Sudan',
    value: 'SD',
    code: '249',
    text: 'Sudan',
    mask: '##-###-####',
  },
  {
    searchString: 'sr597Suriname',
    value: 'SR',
    code: '597',
    text: 'Suriname',
    mask: ['###-###', '###-####'],
  },
  {
    searchString: 'sj47SvalbardandJanMayen',
    value: 'SJ',
    code: '47',
    text: 'Svalbard and Jan Mayen',
    mask: '(###) ##-###',
  },
  {
    searchString: 'sz268Swaziland',
    value: 'SZ',
    code: '268',
    text: 'Swaziland',
    mask: '##-##-####',
  },
  {
    searchString: 'se46Sweden',
    value: 'SE',
    code: '46',
    text: 'Sweden',
    mask: '##-###-####',
  },
  {
    searchString: 'ch41Switzerland',
    value: 'CH',
    code: '41',
    text: 'Switzerland',
    mask: '##-###-####',
  },
  {
    searchString: 'sy963Syria',
    value: 'SY',
    code: '963',
    text: 'Syria',
    mask: '##-####-###',
  },
  {
    searchString: 'tw886Taiwan',
    value: 'TW',
    code: '886',
    text: 'Taiwan',
    mask: ['####-####', '#-####-####'],
  },
  {
    searchString: 'tj992Tajikistan',
    value: 'TJ',
    code: '992',
    text: 'Tajikistan',
    mask: '##-###-####',
  },
  {
    searchString: 'tz255Tanzania',
    value: 'TZ',
    code: '255',
    text: 'Tanzania',
    mask: '##-###-####',
  },
  {
    searchString: 'th66Thailand',
    value: 'TH',
    code: '66',
    text: 'Thailand',
    mask: ['##-###-###', '##-###-####'],
  },
  {
    searchString: 'tg228Togo',
    value: 'TG',
    code: '228',
    text: 'Togo',
    mask: '##-###-###',
  },
  {
    searchString: 'tk690Tokelau',
    value: 'TK',
    code: '690',
    text: 'Tokelau',
    mask: '####',
  },
  {
    searchString: 'to676Tonga',
    value: 'TO',
    code: '676',
    text: 'Tonga',
    mask: '#####',
  },
  {
    searchString: 'tt1TrinidadandTobago',
    value: 'TT',
    code: '1',
    text: 'Trinidad and Tobago',
    mask: '(###) ###-####',
  },
  {
    searchString: 'tn216Tunisia',
    value: 'TN',
    code: '216',
    text: 'Tunisia',
    mask: '##-###-###',
  },
  {
    searchString: 'tr90Turkey',
    value: 'TR',
    code: '90',
    text: 'Turkey',
    mask: '(###) ###-####',
  },
  {
    searchString: 'tm993Turkmenistan',
    value: 'TM',
    code: '993',
    text: 'Turkmenistan',
    mask: '#-###-####',
  },
  {
    searchString: 'tc1TurksandCaicosIslands',
    value: 'TC',
    code: '1',
    text: 'Turks and Caicos Islands',
    mask: '(###) ###-###',
  },
  {
    searchString: 'tv688Tuvalu',
    value: 'TV',
    code: '688',
    text: 'Tuvalu',
    mask: ['#####', '######'],
  },
  {
    searchString: 'vi1USVirginIslands',
    value: 'VI',
    code: '1',
    text: 'U.S. Virgin Islands',
    mask: '(###) ###-####',
  },
  {
    searchString: 'ug256Uganda',
    value: 'UG',
    code: '256',
    text: 'Uganda',
    mask: '(###) ###-###',
  },
  {
    searchString: 'ua380Ukraine',
    value: 'UA',
    code: '380',
    text: 'Ukraine',
    mask: '(##) ###-##-##',
  },
  {
    searchString: 'ae971UnitedArabEmirates',
    value: 'AE',
    code: '971',
    text: 'United Arab Emirates',
    mask: ['#-###-####', '##-###-####'],
  },
  {
    searchString: 'gb44UnitedKingdom',
    value: 'GB',
    code: '44',
    text: 'United Kingdom',
    mask: '##-####-####',
  },
  {
    searchString: 'us1UnitedStates',
    value: 'US',
    code: '1',
    text: 'United States',
    mask: '(###) ###-####',
  },
  {
    searchString: 'uy598Uruguay',
    value: 'UY',
    code: '598',
    text: 'Uruguay',
    mask: '#-###-##-##',
  },
  {
    searchString: 'uz998Uzbekistan',
    value: 'UZ',
    code: '998',
    text: 'Uzbekistan',
    mask: '##-###-####',
  },
  {
    searchString: 'vu678Vanuatu',
    value: 'VU',
    code: '678',
    text: 'Vanuatu',
    mask: ['#####', '##-#####'],
  },
  {
    searchString: 'va379Vatican',
    value: 'VA',
    code: '379',
    text: 'Vatican',
    mask: '## ########',
  },
  {
    searchString: 've58Venezuela',
    value: 'VE',
    code: '58',
    text: 'Venezuela',
    mask: '(###) ###-####',
  },
  {
    searchString: 'vn84Vietnam',
    value: 'VN',
    code: '84',
    text: 'Vietnam',
    mask: ['##-####-###', '(###) ####-###'],
  },
  {
    searchString: 'wf681WallisandFutuna',
    value: 'WF',
    code: '681',
    text: 'Wallis and Futuna',
    mask: '##-####',
  },
  {
    searchString: 'ye967Yemen',
    value: 'YE',
    code: '967',
    text: 'Yemen',
    mask: ['#-###-###', '##-###-###', '###-###-###'],
  },
  {
    searchString: 'zm260Zambia',
    value: 'ZM',
    code: '260',
    text: 'Zambia',
    mask: '##-###-####',
  },
  {
    searchString: 'zw263Zimbabwe',
    value: 'ZW',
    code: '263',
    text: 'Zimbabwe',
    mask: '#-######',
  },
  {
    searchString: 'zz999TextMagic',
    value: 'ZZ',
    code: '999',
    text: 'Textmagic',
    mask: '#####',
  },
]

import { injectable } from 'inversify'
import Facet from '@/data/models/facets/Facet'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import { RepoSettings } from '@/decorators/repositoryDecorators'

@RepoSettings({
  model: Facet,
})
@injectable()
export default class FacetRepository extends OrmApiRepository<Facet> {}

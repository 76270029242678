import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ContactImportResolver,
  ContactImportUploadFormService,
  ContactImportSettingsFormService,
}

export type ContactsImportFlowServices = keyof typeof SERVICES

export const CONTACTS_IMPORT_FLOW_SERVICE_TYPES: Record<ContactsImportFlowServices, symbol> =
  makeServicesTypes<ContactsImportFlowServices>(enumKeysToArray<ContactsImportFlowServices>(SERVICES))

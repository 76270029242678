import { inject, injectable } from 'inversify'
import type { Factory } from '@/types'
import type FilterStateRepository from '@/data/repositories/filters/filterStateRepository'
import FilterState from '@/data/models/filters/FilterState'
import type { FilterInterface } from '@/services/forms/types'
import type { LoggerChannels } from '@/config/configDev'
import { TmEntityNotFoundError } from '@/core/error/tmEntityNotFoundError'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type LoggerService from '@/services/loggerService'
import type { PaginationUrlFilterNullableType, PaginationUrlFilterType } from '@/services/tables/types'

@injectable()
export class FilterServiceManager {
  protected readonly loggerChannel: LoggerChannels = 'filters'

  protected services: Map<string, FilterInterface> = new Map()

  private readonly managerId = 'FilterServiceManager'

  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {}

  public getAllFilterServicesForServiceId(serviceId: string): FilterInterface[] {
    return this.getAllFilterServiceIdsByServiceId(serviceId).map(
      (filterServiceId) => this.services.get(filterServiceId) as FilterInterface,
    )
  }

  public getFilterServiceForServiceId(serviceId: string, fsKey: string): FilterInterface {
    const fsId = this.getFilterServiceId(serviceId, fsKey)
    if (this.services.has(fsId)) {
      this.log(`Service "${fsId}" exists. Returning its instance.`, 'getFilterServiceForServiceId')
      return this.services.get(fsId) as FilterInterface
    }

    this.log(`Service "${fsId}" does not exists`, 'getFilterServiceForServiceId')
    throw new TmEntityNotFoundError(`Service "${fsId}" does not exists`)
  }

  public getFilterServiceForServiceIdOrNull(serviceId: string, fsKey: string): FilterInterface | null {
    try {
      return this.getFilterServiceForServiceId(serviceId, fsKey)
    } catch (e) {
      if (e instanceof TmEntityNotFoundError) {
        return null
      }

      throw e
    }
  }

  public getFirstFilterServiceForServiceId(serviceId: string): FilterInterface {
    const filterServiceIds = this.getAllFilterServiceIdsByServiceId(serviceId)
    if (!filterServiceIds.length) {
      throw new TmEntityNotFoundError(`No filter services for service "${serviceId}"`)
    }

    if (!this.services.has(filterServiceIds[0])) {
      throw new TmEntityNotFoundError(`Service "${filterServiceIds[0]}" does not exists`)
    }

    return this.services.get(filterServiceIds[0])!
  }

  public getFilterServiceById(fsKey: string) {
    if (!this.services.has(fsKey)) {
      throw new TmEntityNotFoundError(`Service "${fsKey}" does not exists`)
    }

    return this.services.get(fsKey)! // TODO: Get rid of type assertion after upgrade to TypeScript 5.5+
  }

  public resetAllFilterServicesForServiceId(serviceId: string, toSkip: string[] = []) {
    this.getAllFilterServicesForServiceId(serviceId).forEach((fs) => {
      fs.reset(toSkip)
    })
  }

  public addFilterServiceForServiceId(serviceId: string, filterFactory: Factory<FilterInterface>) {
    this.log(`Add new service "${serviceId}"`, 'addServiceForServiceId')
    const instance = filterFactory()

    const nextIndex = this.getNextFilterServiceIndexByServiceId(serviceId)
    const filterServiceId = this.getFilterServiceId(serviceId, nextIndex)

    instance.setId(filterServiceId)
    this.services.set(filterServiceId, instance)

    const form = instance.initForm()
    const rawForm = form.getRawForm()

    const visibleFields: string[] = []
    for (const name in rawForm) {
      if (rawForm[name].isDefault && !rawForm[name].isHidden) {
        visibleFields.push(name)
      }
    }

    this.getManagerStateRepository().insertOrUpdateItem({
      id: filterServiceId,
      visibleFields,
    })

    return {
      serviceId: filterServiceId,
      instance,
    }
  }

  public removeFilterServiceForServiceId(serviceId: string, fsKey: string) {
    const fsId = this.getFilterServiceId(serviceId, fsKey)
    this.log(`Removing "${fsId}" filter service`, 'removeFilterServiceForServiceId')

    const instance = this.getFilterServiceForServiceIdOrNull(serviceId, fsKey)
    if (instance) {
      instance.getForm().destroy()
    }
    this.getManagerStateRepository().delete([fsId])

    this.services.delete(fsId)
  }

  public removeAllFilterServicesForServiceId(serviceId: string) {
    this.log(`Removing filter services for service "${serviceId}"`, 'removeAllFilterServicesForServiceId')
    this.getAllFilterServiceIdsByServiceId(serviceId).forEach((filterServiceId) => {
      this.services.delete(filterServiceId)
    })
  }

  public removeFirstFilterServiceForServiceId(serviceId: string) {
    this.log(`Removing first filter service for service "${serviceId}"`, 'removeFirstFilterServiceForServiceId')
    const filterServiceIds = this.getAllFilterServiceIdsByServiceId(serviceId)
    if (!filterServiceIds.length) {
      throw new TmEntityNotFoundError(`No filter services for service "${serviceId}"`)
    }

    this.services.delete(filterServiceIds[0])
  }

  public hasFilterServicesForServiceId(tableId: string): boolean {
    return this.getAllFilterServiceIdsByServiceId(tableId).length > 0
  }

  public hasFilterServiceForServiceId(tableId: string, serviceKey: string): boolean {
    const fsId = this.getFilterServiceId(tableId, serviceKey)
    return (
      this.getManagerStateRepository().findEntityByIdOrNull(fsId) !== null &&
      this.services.has(this.getFilterServiceId(tableId, serviceKey))
    )
  }

  public cleanup() {
    this.log(`Cleanup`, 'cleanup')
    this.services.clear()
    this.getManagerStateRepository().deleteAll()
  }

  public getFieldVisibility(serviceId: string, fsKey: string, fieldName: string): boolean {
    const fsId = this.getFilterServiceId(serviceId, fsKey)

    try {
      const { visibleFields } = this.getManagerStateRepository().findEntityByIdOrFail(fsId)
      return visibleFields.includes(fieldName)
    } catch (e) {
      if (e instanceof TmEntityNotFoundError) {
        return false
      }

      throw e
    }
  }

  public setFieldVisibility(serviceId: string, fsKey: string, fieldName: string, newState: boolean) {
    const fsId = this.getFilterServiceId(serviceId, fsKey)

    this.log(`Set field "${fieldName}" visibility to "${newState}" inside "${fsId}" filter service`)

    this.getManagerStateRepository().updateByCondition(
      (filterState) => {
        let visibleFields: string[]

        if (newState) {
          if (!filterState.visibleFields.includes(fieldName)) {
            visibleFields = [...filterState.visibleFields, fieldName]
          } else {
            visibleFields = [...filterState.visibleFields]
          }
        } else {
          visibleFields = filterState.visibleFields.filter((name) => name !== fieldName)
        }

        return { ...filterState, visibleFields } as FilterState
      },
      ({ id }) => id === fsId,
    )
  }

  public resetFieldVisibility(serviceId: string, fsKey: string): void {
    const fsId = this.getFilterServiceId(serviceId, fsKey)
    this.log(`Reset field visibility inside "${fsId}" filter service`)

    // Get all existing fields and set the "false" state for all of them
    this.getManagerStateRepository().updateByCondition(
      {
        visibleFields: [],
      },
      (record) => record.id === fsId,
    )
  }

  public parseFilterServiceId(filterServiceId: string) {
    const [, serviceId, index] = filterServiceId.split('.')
    return {
      serviceId,
      index: Number(index),
    }
  }

  public getQuery(serviceId: string, exportHidden = true): PaginationUrlFilterType {
    const services = this.getAllFilterServicesForServiceId(serviceId)
    return services.map((fs) => fs.toQuery(exportHidden)).filter((fs) => Object.keys(fs).length)
  }

  public populateFromUrl(
    serviceId: string,
    filterFactory: Factory<FilterInterface>,
    params: PaginationUrlFilterNullableType,
    setInitial = false,
  ): void {
    this.log(`Populate "${serviceId}" with following params: ${JSON.stringify(params)}`)

    const existing = Object.keys(this.getAllFilterServicesForServiceId(serviceId))

    // Remove excessive filter services
    for (let i = existing.length; i > params.length; i--) {
      const fsKey = String(i - 1)
      this.removeFilterServiceForServiceId(serviceId, fsKey)
    }

    // Populate existing filter services
    params.forEach((param, index) => {
      const fsInstance = existing[index]
        ? this.getFilterServiceForServiceId(serviceId, existing[index])
        : this.addFilterServiceForServiceId(serviceId, filterFactory).instance

      fsInstance.populateFromUrl(param, setInitial)
    })
  }

  protected getNextFilterServiceIndexByServiceId(serviceId: string): string {
    const filterServiceIds = this.getAllFilterServiceIdsByServiceId(serviceId)
    if (filterServiceIds.length) {
      const lastFilterServiceId = filterServiceIds[filterServiceIds.length - 1]
      const { index } = this.parseFilterServiceId(lastFilterServiceId)
      return String(index + 1)
    }

    return '0'
  }

  protected getAllFilterServiceIdsByServiceId(serviceId: string): string[] {
    return Array.from(this.services.keys()).filter((key) => key.startsWith(`${this.managerId}.${serviceId}.`))
  }

  protected getFilterServiceId(serviceId: string, fsKey: string) {
    return `${this.managerId}.${serviceId}.${fsKey}`
  }

  protected getManagerStateRepository() {
    return this.em.getRepository<FilterStateRepository>(FilterState)
  }

  protected log(message: string, methodName?: string) {
    const subchannel = methodName ? `manager:${methodName}` : 'manager'

    if (this.loggerService.shouldLogByChannel(this.loggerChannel, [subchannel])) {
      this.loggerService.log(this.loggerChannel, message, subchannel)
    }
  }
}

<template>
  <div
    :class="['tm-sidebar-note', { dark }]"
    :style="{ padding }"
  >
    <div
      v-if="title"
      :class="['tm-sidebar-note__title', { dark }]"
    >
      {{ title }}
    </div>
    <div
      v-if="description"
      :class="['tm-sidebar-note__description', { dark }]"
    >
      {{ description }}
    </div>
    <slot name="content" />

    <tm-button
      v-if="isButtonShown"
      text-link
      class="tm-sidebar-note__button"
      :class="{ 'tm-sidebar-note__button--dark': dark }"
      @click="$emit('submit')"
    >
      <tm-icon
        class="tm-sidebar-note__button__icon"
        name="add"
        size="small"
        left
      />
      {{ buttonText }}
    </tm-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import TmButton from '@/components/shared/TmButton.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import type { StylesheetSizeProp } from '@/types'

export default defineComponent({
  components: { TmIcon, TmButton },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    dark: {
      type: Boolean,
    },
    isButtonShown: {
      type: Boolean,
    },
    buttonText: {
      type: String,
    },
    padding: {
      type: String as PropType<`${StylesheetSizeProp} ${StylesheetSizeProp}`>,
      default: '24px 20px',
    },
  },
  emits: {
    submit: () => true,
  },
})
</script>

<style lang="scss" scoped>
.tm-sidebar-note {
  text-align: center;
  background-color: $white;
  border: 1px solid rgba(227, 228, 238, 1);
  border-radius: 4px;
  padding: 20px 24px;
  margin-left: 16px;
  margin-right: 16px;

  &__title {
    &.dark {
      color: $white !important;
      background-color: $transparent !important;
    }
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  &__description {
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;

    &.dark {
      color: $gray3 !important;
      background-color: $transparent !important;
    }
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $gray6;
    margin-top: 4px;
  }

  &__button {
    margin-top: 4px;
    font-size: 12px;
    color: $primary !important;

    &:hover:not([disabled]) {
      color: $gray8 !important;
    }
    &--dark {
      background-color: transparent !important;
      margin-top: 8px;
      color: $white !important;
      &:hover:not([disabled]) {
        color: $gray3 !important;
      }
    }
    &__icon {
      margin-right: 2px !important;
    }
  }

  &.dark {
    background-color: $transparent !important;
    border-color: $gray6 !important;

    margin-left: 16px;
    margin-right: 16px;
  }
}
</style>

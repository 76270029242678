import type { JSONContent } from '@tiptap/core'

export const tmRichEditorCodeBlockClass = 'tm-editor-code-block'

export const tagNodeName = 'tag' as const
export const tagAttributeValue = 'data-tag' as const
export const tagClass = 'editor-tag' as const
export const tagClassError = `${tagClass}--error` as const

export const linkMarkName = 'customLink' as const
export const linkClass = 'editor-link' as const

export const emojiTagName = 'IMG' as const
export const emojiAttributeName = 'emoji' as const
export const EMOJI_TEXT_ATTRIBUTE = 'data-text' as const
export const EMOJI_COLONS_ATTRIBUTE = 'data-emoji-colons' as const
export const HTML_DATA_ATTRIBUTE = 'data-type' as const

export const htmlTagNotOfDataTypes = (tag: string, notOfDataType: string[]) => {
  return tag + notOfDataType.map((type) => `:not([${HTML_DATA_ATTRIBUTE}="${type}"])`).join('')
}

export const getWysiwygEmailEditorDefaultContent = () => `
<!DOCTYPE html>
<html>
  <head>
    <style>
      p {
        margin-top: 0;
        margin-bottom: 0.5em;
      }
    </style>
  </head>
  <body>
    <p></p>
  </body>
</html>
`
export const emptyEditorValue: JSONContent = {
  type: 'doc',
  content: [{ type: 'paragraph' }],
} as const

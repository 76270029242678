import { NumberField, StringField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import type { Currency } from '@/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.STRIPE_PAYG_PRICE)
export default class StripePaygPrice extends BaseModel {
  @StringField()
  public id: string

  @NumberField()
  public cost: number

  @StringField()
  public currency: Currency

  @NumberField()
  public bonus: number

  public get bonusPercent() {
    return (this.bonus / this.cost) * 100
  }
}

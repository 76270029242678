import { OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseFilterModel from '@/data/models/filters/BaseFilterModel'
import { FILTER_TYPE_RANGE } from '@/data/models/filters/types'

@OrmModel(modelNames.RANGE_FILTER, modelNames.BASE_FILTER, {
  BASE_FILTER: BaseFilterModel,
  RANGE_FILTER: RangeFilterModel,
})
export default class RangeFilterModel extends BaseFilterModel {
  public get filterType() {
    return FILTER_TYPE_RANGE
  }
}

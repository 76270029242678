import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type LocalStorageAdapter from '@/services/transport/storage/localStorageAdapter'
import BaseStorageTransport from '@/services/transport/storage/baseStorageTransport'

@injectable()
export default class LocalStorageTransport extends BaseStorageTransport {
  constructor(@inject(SERVICE_TYPES.LocalStorageAdapter) protected readonly localStorageAdapter: LocalStorageAdapter) {
    super(localStorageAdapter)
  }
}

import { AttrField } from 'vuex-orm-decorators'
import type { StringField } from 'vuex-orm-decorators/dist/types/attributes'
import TmLogicError from '@/core/error/tmLogicError'

export type NormalizeRule<T = any> = {
  in: any[]
  out: T
}

// Apply this decorator for nullable string properties
export const TmStringField = (value?: string | null): ReturnType<typeof StringField> =>
  AttrField(value, (val) => val || '')

export const TmArrayStringField = () =>
  AttrField([], (value: unknown) => {
    if (!Array.isArray(value)) {
      throw new TmLogicError(`value should be type "Array"`)
    }
    return value.map((t) => {
      return t.toString()
    })
  })

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type { BulkFieldListBuilder } from '@/services/bulk/types'
import TmLogicError from '@/core/error/tmLogicError'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import type { SelectValue } from '@/services/forms/types'

@injectable()
export default class BulkFieldBuilderService {
  constructor(
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
  ) {}

  public getContactTagsFieldBuilder(builder: FormBuilderInterface, fieldName: string): BulkFieldListBuilder {
    const requiredConfig = {
      messagePath: 'bulkEditForm.tagsRequired',
    }
    return {
      // name - full field name, example: tags or tags.attach
      build: (name) => {
        switch (name) {
          case fieldName:
            builder.group(
              {
                name,
              },
              '',
            )
            break
          case `${fieldName}.detachAll`:
            builder.getGroupModifier(fieldName).addControl({
              name: 'detachAll',
              fieldType: 'checkbox',
              value: true,
            })
            break
          case `${fieldName}.detach`:
            builder.getGroupModifier(fieldName).addControl({
              name: 'detach',
              fieldType: 'contactTags',
              value: [] as SelectValue[],
              validators: this.validationRulesBuilderService.createRules().required(requiredConfig),
            })
            break
          case `${fieldName}.attach`:
            builder.getGroupModifier(fieldName).addControl({
              name: 'attach',
              fieldType: 'contactTags',
              value: [] as SelectValue[],
              validators: this.validationRulesBuilderService.createRules().required(requiredConfig),
            })
            break
          case `${fieldName}.replace`:
            builder.getGroupModifier(fieldName).addControl({
              name: 'replace',
              fieldType: 'contactTags',
              value: [] as SelectValue[],
              validators: this.validationRulesBuilderService.createRules().required(requiredConfig),
            })
            break
          default:
            throw new TmLogicError(`There isn't nested tags field with the name ${name}`)
        }
      },
      transformToRequest: (value) => {
        if (value.replace) {
          return {
            detachAll: true,
            attach: value.replace,
          }
        }
        if (value.attach) {
          return {
            attach: value.attach,
          }
        }
        if (value.detach) {
          return {
            detach: value.detach,
          }
        }
        if (value.detachAll) {
          return {
            detachAll: true,
          }
        }
        throw new TmLogicError('Contact tags value is empty')
      },
    }
  }
}

<template>
  <details-table left-class="text-right">
    <details-table-item
      v-for="(item, i) in items"
      :key="i"
      label-align-center
    >
      <tm-skeleton
        :width="item.valueSize"
        height="14px"
      />
      <template v-slot:label>
        <tm-skeleton
          :width="item.labelSize"
          height="14px"
        />
      </template>
    </details-table-item>
  </details-table>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'

interface Item {
  labelSize: string
  valueSize: string
}

export default defineComponent({
  components: { DetailsTableItem, TmSkeleton, DetailsTable },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  setup() {
    const items: Item[] = [
      {
        labelSize: '20%',
        valueSize: '70%',
      },
      {
        labelSize: '30%',
        valueSize: '60%',
      },
      {
        labelSize: '50%',
        valueSize: '65%',
      },
      {
        labelSize: '60%',
        valueSize: '70%',
      },
      {
        labelSize: '60%',
        valueSize: '40%',
      },
      {
        labelSize: '20%',
        valueSize: '70%',
      },
      {
        labelSize: '30%',
        valueSize: '60%',
      },
      {
        labelSize: '50%',
        valueSize: '65%',
      },
      {
        labelSize: '60%',
        valueSize: '70%',
      },
      {
        labelSize: '60%',
        valueSize: '40%',
      },
      {
        labelSize: '30%',
        valueSize: '60%',
      },
      {
        labelSize: '50%',
        valueSize: '65%',
      },
    ]

    return {
      items,
    }
  },
})
</script>

import { injectable } from 'inversify'
import type AttachmentFileRepository from '@/data/repositories/domain/attachmentFile/attachmentFileRepository'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import AttachmentFile from '@/data/models/domain/attachmentFile/AttachmentFile'
import { ModelEventType } from '@/services/transport/types'
import type { AttachmentFileEntityType } from '@/data/models/domain/attachmentFile/types'
import type { PaginationUrlType } from '@/services/tables/types'
import type { PaginationParams } from '@/services/tables/pagination/types'
import type { EndpointParams } from '@/services/endpoints'
import type { UploadFileParams } from '@/services/domain/attachmentFile/types'

@injectable()
@DomainSettings({
  model: AttachmentFile,
})
export default class AttachmentFileService extends DomainBaseService<AttachmentFileRepository> {
  public async uploadFile(params: UploadFileParams) {
    const fileId = await this.getDomainRepository().uploadFile(params)
    if (params.entityType) {
      this.notify([], ModelEventType.CREATE)
    }
    return fileId
  }

  public attachFiles(fileIds: string[], entityId: string, entityType: AttachmentFileEntityType) {
    return this.getDomainRepository().attachFiles(fileIds, entityId, entityType)
  }

  public uploadFileCancellable(params: UploadFileParams) {
    return this.getDomainRepository().uploadFileCancellable(params)
  }

  public getDownloadLink(fileId: string, entityType: AttachmentFileEntityType): string {
    return this.getDomainRepository().getDownloadLink(fileId, entityType)
  }

  public async detachFile(entityId: string, entityType: AttachmentFileEntityType, fileId: string) {
    await this.getDomainRepository().detachFile(entityId, entityType, fileId)
    this.notify([entityId], ModelEventType.DELETE)
  }

  public getFetchEndpointParams(): EndpointParams {
    return []
  }

  public gridRequestWithEntityType(
    entityId: string,
    entityType: AttachmentFileEntityType,
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
  ) {
    return this.getDomainRepository().fetchListGridRequest(
      { ...queryParameterBag, other: { entityType, entityId } },
      this.getFetchEndpointParams(),
      paginationParamsBag,
      searchQuery,
    )
  }
}

import type { NavigationGuardWithThis, RouteLocationRaw } from 'vue-router'
import { onBeforeRouteLeave } from 'vue-router'
import { type MaybeRef, type Ref, ref, watch } from '@/composition/vue/compositionApi'
import { getHistoryService, getRouterService, getTranslateService } from '@/core/container/ioc'
import { computed, onUnmounted, unref } from '@/composition/vue/compositionApi'
import type { TranslationKey } from '@/services/types'
import { useCurrentRoute } from '@/composition/useCurrentRoute'
import type { Route } from '@/services/route/types'

export const redirect = (location: RouteLocationRaw) => {
  const routerService = getRouterService()
  return routerService.push(location)
}

export const usePreviousRoute = (): RouteLocationRaw | undefined => {
  const routerService = getRouterService()
  const historyService = getHistoryService()

  const previousUrl = historyService.getRouterHistory().state.back

  if (typeof previousUrl !== 'string' || !previousUrl) {
    return undefined
  }

  const previousRoute = routerService.resolve(previousUrl)

  return previousRoute?.name ? previousRoute : undefined
}

export const useGoToBackAction = (fallbackRouteRef: MaybeRef<RouteLocationRaw | undefined>) => {
  const routerService = getRouterService()
  const previousRoute = usePreviousRoute()

  return () => {
    const fallbackRoute = unref(fallbackRouteRef)

    if (previousRoute) {
      routerService.back()
    } else if (fallbackRoute) {
      routerService.replace(fallbackRoute)
    }
  }
}

export const usePageTitle = () => {
  const routerService = getRouterService()
  const translateService = getTranslateService()

  const title = computed(() => {
    const key = routerService.getCurrentRouteTitle()
    if (!key) return ''
    return translateService.t(key as TranslationKey)
  })

  return {
    title,
  }
}

export const useRouterBeforeEach = (guard: NavigationGuardWithThis<any>) => {
  const cancel = getRouterService().getRouter().beforeEach(guard)
  onUnmounted(() => {
    cancel()
  })
}

export const useExactPreviousRoute = () => {
  const currentRoute = useCurrentRoute()
  const previousRoute = ref<Route | undefined>(undefined)

  watch(
    currentRoute,
    () => {
      previousRoute.value = getRouterService().getPreviousRoute()
    },
    { immediate: true },
  )

  return previousRoute
}

export const useUnsavedFormRouteLeave = (formChanged: Ref<boolean>) => {
  const translateService = getTranslateService()

  onBeforeRouteLeave(async () => {
    if (!formChanged.value) return true
    const message = translateService.t('unsavedFormRouteLeave')
    // eslint-disable-next-line no-alert
    return window.confirm(message)
  })
}

import { inject, injectable } from 'inversify'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type SearchRepository from '@/data/repositories/searchRepository'
import { SERVICE_TYPES } from '@/core/container/types'
import Search from '@/data/models/tables/Search'
import type { DomainSearchable, ModelSearcherSettings, SearcherInterface, SearchParams } from '@/services/search/types'
import type DomainManagerService from '@/services/domain/domainManagerService'

@injectable()
export default class ModelSearcherServiceFactory implements SearcherInterface<ModelSearcherSettings> {
  protected defaultValue: string

  protected settings: ModelSearcherSettings

  private key: string

  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.DomainManagerService) protected readonly domainManagerService: DomainManagerService,
  ) {}

  public setSettings(settings: ModelSearcherSettings) {
    this.settings = settings
    this.defaultValue = settings.defaultValue || ''
  }

  public init(params?: SearchParams) {
    this.key = `${this.settings.entity.entity}`
    const domainSettings = this.getDomainServiceSearchable().getSearchSettings()

    this.getRepo().init(this.key, domainSettings.supportedSearchFields)
    this.getDomainServiceSearchable().setIsEmptyState(false)
    if (params) {
      this.applyByParams(params)
    }
  }

  public apply(value?: string, queryValue?: any) {
    if (typeof value === 'string') {
      this.getDomainServiceSearchable().search(value.trim(), queryValue)
      this.getRepo().setValue(this.key, value.trim())
    }
  }

  public applyByParams(params: SearchParams) {
    this.apply(params.search)
  }

  public reset() {
    this.apply(this.defaultValue)
  }

  public getSearchFields() {
    return this.getRepo().getSearchFields(this.key)
  }

  public getSearchQuery() {
    return this.getRepo().getValue(this.key, this.defaultValue)
  }

  public isDefaultState(): boolean {
    return this.getSearchQuery() === this.defaultValue
  }

  public isEmptyState(): boolean {
    return this.getDomainServiceSearchable().isEmptyState() && !this.isDefaultState()
  }

  protected getRepo() {
    return this.em.getRepository<SearchRepository>(Search)
  }

  protected getDomainServiceSearchable(): DomainSearchable {
    return this.domainManagerService.getService<DomainSearchable>(this.settings.entity)
  }
}

import { inject, injectable } from 'inversify'
import type { CreatedEntityResponse, FormSubscriptionPayload } from '@/services/transport/types'
import type { TpFormData } from '@/services/forms/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type SubscriptionService from '@/services/transport/subscriptionService'
import type NewQueryParamsService from '@/services/route/newQueryParamsService'

@injectable()
export default class FormSubscriptionService {
  constructor(
    @inject(SERVICE_TYPES.SubscriptionService) protected subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.NewQueryParamsService) protected newQueryParamsService: NewQueryParamsService,
  ) {}

  public notifyCreated(
    formId: string,
    data: TpFormData,
    response: CreatedEntityResponse,
    queryParamsKey = 'modalParams',
  ) {
    this.subscriptionService.emit(`/form-submitted/${formId}`, {
      data,
      response,
      queryParams: this.newQueryParamsService.getQueryParams()[queryParamsKey],
    })
  }

  public subscribe(formId: string, callback: (payload: FormSubscriptionPayload) => any) {
    this.subscriptionService.subscribe<FormSubscriptionPayload>(`/form-submitted/${formId}`, callback)
  }

  public unsubscribe(formId: string) {
    this.subscriptionService.unsubscribe(`/form-submitted/${formId}`)
  }
}

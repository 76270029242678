import type { Collection } from '@vuex-orm/core'
import type Sorter from '@/data/models/tables/Sorter'
import type { FormFieldFilterType, SelectOption } from '@/services/forms/types'
import type { ComputedRef, Ref } from '@/composition/vue/compositionApi'
import type { ModelRaw, PartialBy, Dict, StylesheetSizeProp } from '@/types'
import type Columns from '@/data/models/tables/Columns'
import type Table from '@/data/models/tables/Table'
import type {
  ParsedFilterType,
  FilteredViewsParsedBodySortType,
  PaginationUrlType,
  PaginationUrlFilterScalarValueType,
  PaginationUrlFilterSingleType,
  PaginationUrlFilterNullableSingleType,
  PaginationUrlParametersGroupingType,
  PaginationUrlParametersSortType,
  PaginationUrlColumnType,
  SorterSettings,
  PaginationUrlFilterNullableType,
  UiStateUrlParams,
} from '@/services/tables/types'
import type { Gridable, Operation } from '@/services/types'
import type { Endpoint } from '@/services/endpoints'
import type Grouping from '@/data/models/tables/Grouping'
import type BaseModel from '@/data/models/BaseModel'
import type BaseFilterForm from '@/services/tables/filters/baseFilterForm'
import type { SimpleSkeletonTypes } from '@/components/shared/types'
import type { PaginationUrlParams } from '@/services/tables/pagination/types'
import type { PartialUIConfig } from '@/services/wrappers/types'
import type { BaseSearcherSettings, SearcherInterface, SearchParams } from '@/services/search/types'
import { isRecordUnknown } from '@/utils/typeGuards'
import type BaseFilterModel from '@/data/models/filters/BaseFilterModel'

export type SortDirection = 'asc' | 'desc' | ''
export type ExplicitSortDirection = Exclude<SortDirection, ''>
export type GroupingDirection = SortDirection
export type ExplicitGroupingDirection = ExplicitSortDirection

export type RowIdType = string
export type RowKey = 'all' | 'pageAll' | RowIdType

export type Column<TName extends string = string> = {
  columnName: TName
  columnOrder: number
  columnStyles?: Record<string, string>
  columnClass?: string /* very handy to use to customize visibility breakpoints, works well with cells for which "growRatio: 0"
  and they do not participate in width calculation, example: "send" cell "columnClass: 'gt-md'" // templatesColumnService.ts
  @todo: It is necessary to update the getVisibleColumns method to take into account the current breakpoints, this will avoid the error if growRatio > 0. */
  visible: boolean
  visibilityBehaviour?: VisibilityBehaviourColumns
}

export type ApiColumn<T extends string = string> = Pick<Column<T>, 'columnName' | 'columnOrder' | 'visible'>

export const isApiColumn = (column: unknown): column is ApiColumn => {
  return isRecordUnknown(column) && 'columnName' in column && 'columnOrder' in column && 'visible' in column
}

export type StoredColumn = {
  name: string
  isVisible: boolean
}

type VisibilityBehaviourColumns = {
  columnsToOppose?: string[]
  columnsToRepeat?: string[]
}

export type SaveColumnsBody = {
  entity: string
  columns: Column[]
}

export const defaultGrowRatio = 1

export const defaultMinWidth = 100

export type RawDefaultColumn = PartialBy<Column, 'columnName'> &
  ColumnLoader & {
    required?: boolean
    label?: string
    labelShort?: string
    minWidth?: number // DefaultMinWidth
    maxWidth?: number // DefaultMinWidth
    growRatio?: number // DefaultGrowRatio
    // Is <td> itself. so no need to wrap it
    hideHeaderName?: boolean
    isCustomFieldColumn?: boolean
    colspan?: number | ((row: BaseModel) => number | undefined)
    visibleInPreview?: boolean // Considered as true if not set to false explicitly. Is used in table skeleton before actual columns are loaded from API.
  }

export type DefaultColumn = RawDefaultColumn & {
  columnName: string
  label: string
}

export type ColumnLoader = {
  loaderClass?: string
  loaderType?: SimpleSkeletonTypes
  loaderWidth?: number
  loaderHeight?: StylesheetSizeProp
  hideLoader?: boolean
  dynamicLoaderWidth?: boolean
  columnStyles?: Record<string, string>
}

export type DefaultColumns<T extends DefaultColumn = DefaultColumn> = { [key: string]: T }
export type RawDefaultColumns<T extends RawDefaultColumn = RawDefaultColumn> = { [key: string]: T }
export type TypedRawDefaultColumns<C extends string, T extends RawDefaultColumn = RawDefaultColumn> = { [key in C]: T }
export type TableComputedPropsMap<T extends string, M extends BaseModel> = { [key in T]: (props: CellProps<M>) => Dict }

export type ColumnEntity = Omit<PartialBy<RawDefaultColumn, 'columnOrder' | 'visible'>, 'cell' | 'cellName'>

export type BaseFilterPartialParams = {
  filterKey: string
}

export interface BaseFilterInterface {
  setId(id: string): void
  getServiceId(): string
  getData(exportHidden?: boolean): Dict
  toQuery(exportHidden?: boolean): PaginationUrlFilterSingleType
  makeFieldVisible(filter: FormFieldFilterType, openOnAdd?: boolean): void
  setFieldsVisibility(fields: Record<string, boolean>): void
  setFieldVisibility(fieldName: string, newState: boolean): void
  setOpenOnAddFlag(filterId: string, flag: boolean): void
  resetField(name: string): void
  reset(toSkip: string[]): void
  getForm(): BaseFilterForm<FormFieldFilterType>
  getActiveFilters(): FormFieldFilterType[]
  getAppliedFilters(): FormFieldFilterType[]
  getVisibleFilters(): FormFieldFilterType[]
  getInvisibleFilters(): FormFieldFilterType[]
  populate(filters: ParsedFilterType[], setInitial?: boolean): void
  populateFromUrl(params: PaginationUrlFilterNullableSingleType, setInitial?: boolean): void
  makeFilterReadOnly(name: string): void
  getObservableFilters(): FormFieldFilterType[]
  getFieldVisibility(fieldName: string): boolean
  isFilterChanged(filterName: string): boolean
  isVisibleFilter(filterName: string): boolean
  isDefaultFilter(filterName: string): boolean
  isHiddenFilter(name: string): boolean
  isObservableFilter(name: string): boolean
  setInnerValue(name: string, value: unknown): void
  setInnerSelectedItems(name: string, selectedItemsValue: SelectOption[]): void
  setValue(name: string, value: string): void
  setSelectedItems(name: string, selectedItemsValue: SelectOption[]): void
  setInnerOperation(name: string, operation: Operation): void
  applyFilterValue(name: string): void
  getFilterInnerValue(name: string): unknown
  getFilterInnerSelectedItems(name: string): SelectOption[]
  getFilterValue<T>(name: string): T
  getFilterSelectedItems(name: string): SelectOption[]
  setApplied(to: boolean): void
  isDefaultState(includeHiddenFilters?: boolean): boolean
  toIdFilter(ids: string[]): PaginationUrlFilterScalarValueType
  getPartial(): PartialUIConfig<BaseFilterPartialParams>
  getFieldOrder(): string[]
  checkFilterValue(filter: BaseFilterModel, valueToCheck: unknown): boolean
  checkFilterValueByName(filterName: string, valueToCheck: unknown, filterValue?: unknown): boolean
}

export interface TableKeyBuilderInterface {
  key(key: string, tableId: string): string
}

export interface ColumnsInterface {
  getColumn(columnName: string): DefaultColumn
  getColumnEditorColumns(): Array<DefaultColumn>
  setTableId(tableId: string): void
  getTableId(): string
  saveColumns(columns: Column[], entity: string, disabledSync?: boolean): Promise<PaginationUrlColumnType>
  loadColumns(tableId: string): Promise<Array<Column>>
  makeColumns(
    tableId: string,
    columns?: PaginationUrlColumnType,
    callback?: (columns: Column[]) => Promise<void>,
  ): Promise<StoredColumn[]>
  recoveryColumns(
    tableId: string,
    columns?: PaginationUrlColumnType,
    callback?: (columns: Column[]) => Promise<void>,
  ): Promise<StoredColumn[]>
  getColumns(): Columns
  toQuery(): PaginationUrlColumnType
  hasColumn(name: string): boolean
  getInitColumns(): RawDefaultColumns
  getDefaultColumns(): DefaultColumns
  getVisibleColumns(): Array<DefaultColumn>
  getInvisibleColumns(): Array<DefaultColumn>
  columnsToPaginationUrlColumn(columns: DefaultColumn[]): PaginationUrlColumnType
  init(columns?: DefaultColumns): void
  reset(): void
  reload(): Promise<void>
  isEditable(): boolean
  isSortable(): boolean
  isDefaultState(ignoreOrder?: boolean): boolean
  isRowDisabled(props: IsRowDisabledProps): boolean
}

export type IsRowDisabledProps<T extends BaseModel = any> = {
  row: ModelRaw<T>
}

export interface ColumnsServiceInterface extends ColumnsInterface {}

export interface SorterInterface {
  init(params?: PaginationUrlParametersSortType): void
  getAllSorters(): Collection<Sorter>
  getCurrentSorter(): Sorter
  applySort(name: string, direction?: SortDirection): void
  applySortByUrlParams(params: PaginationUrlParametersSortType): void
  applySorts(toApply: Array<FilteredViewsParsedBodySortType>): void
  toQuery(): PaginationUrlParametersSortType
  reset(): void
  getActiveSorter(sorters: Collection<Sorter>): Array<Sorter>
  setDefaultSorter(settings?: SorterSettings): void
  setTableId(tableId: string): void
  getTableId(): string
  setEntityName(tableId: string): void
  getEntityName(): string
  setEndpoint(endpoint: Endpoint): void
  getDefaultSorter(): PaginationUrlParametersSortType
  isDefaultState(): boolean
}

export interface GrouperInterface {
  init(params?: PaginationUrlParametersGroupingType): void
  applyGroupingByUrlParams(params: PaginationUrlParametersSortType): void
  getAllGroupings(): Collection<Grouping>
  applyGrouping(name: string, direction?: SortDirection): void
  getCurrentGrouping(): Grouping
  toQuery(): PaginationUrlParametersGroupingType
  reset(): void
  getActiveGrouping(): Array<Grouping>
  setTableId(tableId: string): void
  getTableId(): string
  setEndpoint(endpoint: Endpoint): void
  loadGroupingFacets(
    model: typeof BaseModel,
    queryParametersBag: PaginationUrlType,
    searchQuery?: string,
    searchFields?: string[],
  ): Promise<void>
}

export interface SearcherTableInterface extends SearcherInterface<BaseSearcherSettings> {
  setTableId(tableId: string): void
  getTableId(): string
  setEndpoint(endpoint: Endpoint): void
  applyByUrlParams(params: SearchUrlParams): void
  toQuery(): SearchUrlParams
}

export type SearchUrlParams = SearchParams

export type CellPropsSearch = {
  searchFields: string[]
  searchQuery: string
}

export type CellProps<T> = CellPropsSearch & {
  row: ModelRaw<T>
  entity: Ref<ModelRaw<Table>>
  tableId: string
  column: DefaultColumn
  errorMessages?: string[]
}

export type ComputedCellProps = Record<string, any>
export type ErrorableComputedCellProps = ComputedCellProps & {
  errorMessages: string[]
}

export const DEFAULT_PER_PAGE = 10
export const FILTERS_URL_QUERY_PARAM = 'filters'
export const SORTS_URL_QUERY_PARAM = 'sorts'
export const GROUPING_URL_QUERY_PARAM = 'grouping'
export const PAGING_URL_QUERY_PARAM = 'paging'
export const SEARCH_URL_QUERY_PARAM = 'search'
export const COLUMNS_URL_QUERY_PARAM = 'columns'
export const UI_STATE_URL_QUERY_PARAM = 'UI'
export const EMPTY_STATE_URL_QUERY_PARAM = 'empty'

export type StorageQueryParams = {
  [FILTERS_URL_QUERY_PARAM]: PaginationUrlFilterNullableType
  [SORTS_URL_QUERY_PARAM]: PaginationUrlParametersSortType
  [GROUPING_URL_QUERY_PARAM]: PaginationUrlParametersSortType
  [PAGING_URL_QUERY_PARAM]: PaginationUrlParams
  [SEARCH_URL_QUERY_PARAM]: SearchUrlParams
  [COLUMNS_URL_QUERY_PARAM]: PaginationUrlColumnType
  [UI_STATE_URL_QUERY_PARAM]: UiStateUrlParams
}

export type BaseTableProps = {
  entity: typeof BaseModel
  sorters: ComputedRef<Collection<Sorter>>
  groupings: ComputedRef<Collection<Grouping>>
  tableModel: ComputedRef<ModelRaw<Table>>
  tableModelId: string
  searchFields: string[]
  isInit: ComputedRef<boolean>
  searchQuery: Ref<string>
  isExportInProgress: Ref<boolean>
  rowsLoading: ComputedRef<boolean>
}
export type BaseTablePropsRefs = keyof {
  [P in keyof BaseTableProps as BaseTableProps[P] extends ComputedRef
    ? never
    : BaseTableProps[P] extends Ref
      ? P
      : never]: any
}
export type BaseTableSettings = {
  tableModelId: string
  tableWrapperId: string
  gridService?: Gridable<BaseModel>
}
export const tableCellLoaderWidth = {
  default: 40,
  tags: 150,
  date: 90,
  country: 120,
  number: 20,
  checkbox: 18,
  actions: 22,
  person: 120,
}

export type BaseTableIsDefaultStateOptions = {
  includeHiddenFilters: boolean
}

export type TablePageMoveDirection = 'next' | 'prev'

export type CustomFiltersDefaultState = (filter: BaseFilterInterface) => boolean

export type RefreshRowsParams = {
  isSilent?: boolean
  isReset?: boolean
  isInit?: boolean
  isDisabled?: boolean
}

export type TableActionsParams = {
  isEmpty: boolean
}

export type TableRowActionsParams<T extends BaseModel> = {
  row: T
  searchQuery: string
}

export type TableCheckboxParams<T extends BaseModel> = {
  row: T
}

export type TableCellParams<T extends BaseModel> = {
  row: T
  column: DefaultColumn
  searchQuery: string
  isExpanded: boolean
  disabled?: boolean
}

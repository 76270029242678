import type { Component, ExtractPropTypes, PropType } from '@/composition/vue/compositionApi'
import type { ModalSize, WrapperConfigItemModal } from '@/services/wrappers/types'
import type { SizeProp } from '@/components/shared/types'
import type { IconName } from '@/assets/icons/icons'
import { isRecordUnknown } from '@/utils/typeGuards'
import { TestIdAttrName } from '@/composition/testing/types'

export const DIALOG_CONTEXT_NAME = Symbol('tmDialogContext')
export const DIALOG_WRAPPER_MODAL_CONFIG = Symbol('dialogWrapperModalConfig')

export const TmModalSizesMap: Record<ModalSize, string> = {
  small: '500px',
  medium: '600px',
  large: '700px',
  xLarge: '900px',
  full: '90%',
}

export const baseDialogProps = {
  scrollable: {
    type: Boolean,
  },
  leftAsideWidth: {
    type: String,
    default: '',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as SizeProp<ModalSize>,
    default: 'medium',
  },
  exactSize: {
    type: String,
  },
  maxHeight: {
    type: String,
  },
  minHeight: {
    type: String,
  },
  overrideModalStyles: {
    type: Object as PropType<Partial<CSSStyleDeclaration>>,
  },
  noBackdropDismiss: {
    type: Boolean,
    default: true,
  },
  keepAliveOnRouteChange: {
    type: Boolean,
    default: false,
  },
  navigationComponent: {
    type: Object as PropType<Component>,
    default: undefined,
  },
  modalClass: {
    type: String,
  },
  [TestIdAttrName]: {
    type: String,
    default: '',
  },
}

export const baseModalContentProps = {
  title: {
    type: String,
    required: false,
  },
  noFooter: {
    type: Boolean,
    default: undefined,
  },
  noHeader: {
    type: Boolean,
    default: undefined,
  },
  hideCancelButton: {
    type: Boolean,
  },
  showCreateAnother: {
    type: Boolean,
  },
  cancelButtonText: {
    type: String,
    default: 'Cancel',
  },
  withoutContentPadding: {
    type: Boolean,
  },
  titleIcon: {
    type: String as PropType<IconName>,
  },
  titleIconClass: {
    type: String,
  },
  truncateTitle: {
    type: Boolean,
  },
  scrollbarId: {
    type: String,
  },
  canCloseCallback: {
    type: Function as PropType<() => boolean | Promise<boolean>>,
  },
}
export type BaseModalContentProps = ExtractPropTypes<typeof baseModalContentProps>

export const baseDialogEmits = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'update:model-value': (_value: boolean) => true,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'before-hide': () => true,
  open: () => true,
  close: () => true,
}
export const isWrapperConfigItemModal = (value: unknown): value is WrapperConfigItemModal =>
  isRecordUnknown(value) && 'modalConfig' in value && isRecordUnknown(value.modalConfig)

<template>
  <div
    ref="root"
    class="chat-sidebar-loading"
  >
    <div
      v-for="i in countItems"
      :key="i"
      class="chat-sidebar-loading__preview"
    >
      <tm-skeleton
        type="circle"
        width="40px"
        height="40px"
        class="mr-3"
      />
      <div class="flex-grow-1">
        <tm-skeleton
          type="QChip"
          class="mb-2"
          width="80%"
          height="15px"
        />
        <tm-skeleton
          type="QChip"
          :width="i % 2 === 0 ? '40%' : '60%'"
          height="8px"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import type { Ref } from '@/composition/vue/compositionApi'
import { defineComponent, onMounted, ref } from '@/composition/vue/compositionApi'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'

const ITEM_HEIGHT = 60

export default defineComponent({
  components: {
    TmSkeleton,
  },
  setup() {
    const root: Ref<HTMLElement | null> = ref(null)
    const countItems = ref(10)

    onMounted(() => {
      if (root.value && root.value.clientHeight) {
        countItems.value = Math.ceil(root.value.clientHeight / ITEM_HEIGHT)
      }
    })

    return {
      root,
      countItems,
    }
  },
})
</script>
<style lang="scss" scoped>
.chat-sidebar-loading {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  min-height: 0;
  overflow: hidden;

  &__preview {
    height: 60px;
    border-bottom: 1px solid $input;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
}

.q-skeleton--type-circle {
  flex-shrink: 0;
}
</style>

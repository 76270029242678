import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

enum TFA_SERVICES {
  TfaResolver,

  SmsCodeFormService,
  EmailCodeFormService,
  BackupCodeFormService,
  AccountSecurity2faPhoneFormService,
  AccountSecurity2faVerifyFormService,
}

export type TFAServices = keyof typeof TFA_SERVICES

export const TFA_SERVICE_TYPES: Record<TFAServices, symbol> = makeServicesTypes<TFAServices>(
  enumKeysToArray<TFAServices>(TFA_SERVICES),
)

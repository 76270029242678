import { isObject, merge, mergeWith } from 'lodash-es'
import qs from 'qs'
import TmLogicError from '../core/error/tmLogicError'
import { fromDotNotation } from '@/utils/object/fromDotNotation'
import { toDotNotation } from '@/utils/object/toDotNotation'
import {
  FILTERS_URL_QUERY_PARAM,
  GROUPING_URL_QUERY_PARAM,
  PAGING_URL_QUERY_PARAM,
  SORTS_URL_QUERY_PARAM,
} from '@/core/tables/types'

export const queryDotNotationKeyToStructure = <T = any>(key: string, data: T): Record<string, any> => {
  const splitterIndex = key.indexOf('.')

  if (splitterIndex > 0) {
    const firstPart = key.substring(0, splitterIndex)
    const rest = key.substring(splitterIndex + 1)

    return {
      [firstPart]: queryDotNotationKeyToStructure(rest, data),
    }
  }

  return {
    [key]: data,
  }
}
export const getKeyForQueryToDotNotation = (key: string, subKey?: string) => (subKey ? `${key}.${subKey}` : key)
export const queryToDotNotation = (key: string, subKey: string, data: any) => ({
  [getKeyForQueryToDotNotation(key, subKey)]: JSON.stringify(toDotNotation(data)),
})

export const parseDotNotation = (query: Record<string, string>) => {
  let result: Record<string, any> = {}

  for (const key in query) {
    let parsedValue = query[key]
    try {
      parsedValue = JSON.parse(query[key])
    } catch {
      throw new TmLogicError(`"${query[key]}" is not a valid dot notation`)
    }

    result = merge(result, queryDotNotationKeyToStructure(key, fromDotNotation(parsedValue)))
  }
  return result
}

export const mergeDotNotationQueries = (...args: Record<string, any>[]): Record<string, any> => {
  const mergeCustomizer = (to: any, from: any) => {
    // Concat arrays if it consists of a primitive types
    if (Array.isArray(to) && to.every((item) => typeof item !== 'object')) {
      const filteredFrom = from.filter((item: unknown) => !to.includes(item))
      return to.concat(filteredFrom)
    }

    return undefined
  }

  const merged = mergeWith.apply(this, [{}, ...args.map(parseDotNotation), mergeCustomizer])

  let result: Record<string, any> = {}
  for (const key in merged) {
    for (const subKey in merged[key]) {
      result = {
        ...result,
        ...queryToDotNotation(key, subKey, merged[key][subKey]),
      }
    }
  }

  return result
}

export const isDotNotatedKey = (key: string) => {
  const dotNotatedKeys = [
    FILTERS_URL_QUERY_PARAM,
    SORTS_URL_QUERY_PARAM,
    GROUPING_URL_QUERY_PARAM,
    PAGING_URL_QUERY_PARAM,
  ]

  return dotNotatedKeys.some((dotNotatedKey) => key.startsWith(`${dotNotatedKey}.`))
}

export const parseQuery = (query: string): Record<string, any> => {
  const res: Record<string, any> = {}

  query = query.trim().replace(/^(\?|#|&)/, '')

  if (!query) {
    return res
  }

  return qs.parse(query)
}

export const resolveQuery = (
  query: string,
  extraQuery: Record<string, any> = {},
  _parseQuery?: (...args: any[]) => any,
): Record<string, any> => {
  const parse = _parseQuery || parseQuery
  let parsedQuery
  try {
    parsedQuery = parse(query || '')
  } catch (e) {
    parsedQuery = {}
  }

  for (const key in extraQuery) {
    const value = extraQuery[key]
    parsedQuery[key] = Array.isArray(value) ? value.map(castQueryParamValue) : castQueryParamValue(value)
  }
  return parsedQuery
}

const castQueryParamValue = (value: string) => (value == null || typeof value === 'object' ? value : String(value))

export const stringifyQuery = (obj: Record<string, any>): string => {
  const res = obj
    ? Object.keys(obj)
        .map((key) => {
          const val = obj[key]

          if (val === undefined) {
            return ''
          }

          if (val === null) {
            return encode(key)
          }

          if (isObject(val) && !Array.isArray(val)) {
            return Object.keys(val)
              .map((objectKey: string) => {
                const root = `${key}[${objectKey}]`
                return stringifyQuery({ [root]: (val as any)[objectKey] })
              }, '')
              .join('&')
          }

          if (Array.isArray(val)) {
            const result: string[] = []
            val.forEach((val2, index) => {
              if (val2 === undefined) {
                return
              }
              if (val2 === null) {
                result.push(encode(key))
              } else if (isObject(val2)) {
                Object.keys(val2).forEach((key2) => {
                  result.push(stringifyQuery({ [`${key}[${index + 1}][${key2}]`]: (val2 as any)[key2] }))
                })
              } else {
                result.push(`${encode(key)}=${encode(val2)}`)
              }
            })
            return result.join('&')
          }

          return `${encode(key)}=${encode(val)}`
        })
        .filter((x) => x.length > 0)
        .join('&')
    : null

  return res || ''
}

const encodeReserveRE = /[!'()*]/g
const encodeReserveReplacer = (c: string) => `%${c.charCodeAt(0).toString(16)}`
const commaRE = /%2C/g

const encode = (str: string) =>
  encodeURIComponent(str).replace(encodeReserveRE, encodeReserveReplacer).replace(commaRE, ',')

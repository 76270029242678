import { inject, injectable } from 'inversify'
import type { Router } from 'vue-router'
// TODO: Fix ignored tp/forbid-import-composable-to-service
// eslint-disable-next-line tp/forbid-import-composable-to-service,tp/using-vue-in-services-restriction
import type { Plugin } from '@/composition/vue/compositionApi'
import {
  type ExternalTrackerAsPlugin,
  type TrackableConfig,
  zendeskCountries,
  zendeskVisibleRoutes,
  hideZendeskWidgetBreakpoint,
} from '@/services/tracking/types'
import ConfigReader from '@/core/configReader'
import { SERVICE_TYPES } from '@/core/container/types'
import type RouterService from '@/services/route/routerService'
import type SubscriptionService from '@/services/transport/subscriptionService'
import { type Route, ROUTER_AFTER_EACH } from '@/services/route/types'
import type UserService from '@/services/domain/user/userService'
import type User from '@/data/models/domain/User'
import { UserStatus } from '@/services/domain/user/types'
import ExternalTrackerService from '@/services/tracking/externalTrackerService'
import type UnauthenticatedCountryService from '@/services/domain/unauthenticatedCountryService'
import type UnauthenticatedCountry from '@/data/models/domain/UnauthenticatedCountry'
import type LoggedInStatusService from '@/services/auth/loggedInStatusService'
import type WindowService from '@/services/browser/windowService'
import type LoggerService from '@/services/loggerService'
import TmLogicError from '@/core/error/tmLogicError'

@injectable()
export default class ZendeskService extends ExternalTrackerService {
  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.UnauthenticatedCountryService)
    protected readonly unauthenticatedCountryService: UnauthenticatedCountryService,
    @inject(SERVICE_TYPES.LoggedInStatusService) protected readonly loggedInStatusService: LoggedInStatusService,
  ) {
    super()
  }

  private widgetPromise: Promise<void>

  private async getWidget() {
    await this.widgetPromise
    const widget = this.windowService.get<((...args: any[]) => any) | undefined>('zE')

    if (!widget) {
      this.loggerService.error('error', 'Zendesk widget is not loaded')
      return null
    }

    return widget
  }

  public getConfig(): TrackableConfig {
    const config = ConfigReader.config()

    return {
      id: config.tracking.zendeskKey,
    }
  }

  public getPlugin(): Plugin {
    return {
      install: () => this.initialize(),
    } as Plugin
  }

  public async initialize(): Promise<void> {
    this.setUpOptions()
    const key = this.getConfig().id
    const router: Router = this.routerService.getRouter()
    let isInitialized = false
    const track = async () => {
      if (await this.shouldLoadWidget(router.currentRoute.value)) {
        if (!isInitialized) {
          this.widgetPromise = this.initializeTrackerAsPlugin(trackerAsPlugin)
          isInitialized = true
        }
        if (this.loggedInStatusService.isUserLoggedIn()) {
          await this.trackAuthenticated()
        } else {
          await this.trackUnauthenticated()
        }
      } else if (isInitialized) {
        await this.hideWidget()
      }
    }
    const trackingHandler = async () => {
      await router.isReady()
      await track()
      this.subscriptionService.subscribe(ROUTER_AFTER_EACH, track, true)
    }

    const trackerAsPlugin = {
      src: `https://static.zdassets.com/ekr/snippet.js?key=${key}`,
      id: 'ze-snippet',
      trackingHandler,
    } as ExternalTrackerAsPlugin

    await trackingHandler()
  }

  protected async trackAuthenticated(): Promise<void> {
    const user: User = this.userService.currentUser()

    await this.identifyZendeskUser(user)
    return this.showWidget()
  }

  protected trackUnauthenticated(): Promise<void> {
    return this.showWidget()
  }

  protected async shouldLoadWidget(route: Route): Promise<boolean> {
    const isVisibleRoute: boolean = !!route.name && zendeskVisibleRoutes.includes(String(route.name))
    if (this.windowService.self().screen.width < hideZendeskWidgetBreakpoint) {
      return false
    }
    if (this.loggedInStatusService.isUserLoggedIn()) {
      try {
        const user: User = this.userService.currentUser()
        const isUnsupportedUserCountry = !zendeskCountries.includes(user.countryId)
        const isUserWizard = user.status === UserStatus.ST_WIZARD
        if (isUnsupportedUserCountry || isUserWizard) {
          return false
        }

        return isVisibleRoute
      } catch (e) {
        if (e instanceof TmLogicError) {
          return false
        }
        throw e
      }
    }
    const country: UnauthenticatedCountry | null = await this.unauthenticatedCountryService.loadCountry()

    return !!country?.id && zendeskCountries.includes(country.id) && isVisibleRoute
  }

  /* eslint-disable */
  protected setUpOptions(): void {
    window['zESettings'] = {
      analytics: false,
    }
  }

  protected async identifyZendeskUser(user: User): Promise<void> {
    const widget = await this.getWidget()
    widget?.('webWidget', 'identify', {
      name: user.fullName,
      email: user.email,
    })
  }

  private async showWidget(): Promise<void> {
    const widget = await this.getWidget()
    widget?.('webWidget', 'show')
  }

  private async hideWidget(): Promise<void> {
    const widget = await this.getWidget()
    widget?.('webWidget', 'hide')
  }

  private async logout(): Promise<void> {
    const widget = await this.getWidget()
    widget?.('webWidget', 'logout')
  }
  /* eslint-disable */

  public cleanUp(): void {
    this.logout()
  }
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import TenDlcBrand from '@/data/models/domain/services/regulations/TenDlcBrand'
import type { ModelRaw } from '@/types'

@RepoSettings<Endpoint>({
  model: TenDlcBrand,
})
@injectable()
export default class TenDlcBrandRepository extends OrmApiRepository<TenDlcBrand> {
  public async getBrand() {
    const response = await this.getApiSource().get<ModelRaw<TenDlcBrand>>(this.endpointsService.getPath('tenDlcBrand'))
    const model = response.data as TenDlcBrand
    await this.insertOrUpdate([model])

    return this.find(model.id)
  }
}

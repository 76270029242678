import type { LoggerChannels } from '@/config/configDev'

export enum LogLevel {
  LOG = 'LOG',
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
  NULL = 'NULL',
  DEBUG = 'DEBUG',
}
export type LogChannel = {
  channel?: LoggerChannels
  format: LogFormat
  level: LogLevel
}
export type LogRule = {
  include?: (string | RegExp)[]
  exclude?: (string | RegExp)[]
}
export type LogRules = Partial<Record<LoggerChannels, LogRule>>
export enum LogFormat {
  GENERAL = 'GENERAL',
}
export interface Loggable {
  raw(raw: any, level: LogLevel): void
  log(channel: string, message: string, format: LogFormat, level: LogLevel): void
  table(channel: string, data: Record<string, any>, format: LogFormat, level: LogLevel): void
  groupStart(channel: string, groupName: string, format: LogFormat, level: LogLevel): void
  groupEnd(level: LogLevel): void
  stack(stack: string, level: LogLevel): void
  time(label: string, level: LogLevel): void
  timeEnd(label: string, level: LogLevel): void
  timeLog(label: string, level: LogLevel): void
  toFormat(channel: string, message: string, format: LogFormat): any
}
export interface Channelable {
  setLogger(logger: Loggable): this
  setChannel(channel: LogChannel): this
  log(message: string, subchannel: string): void
  toChannel(message: string, subchannel: string): void
}
export type LogItem = {
  datetime: string
  channel: string
  message: string
}

import { injectable } from 'inversify'
import type { GroupingDirection } from '@/core/tables/types'
import type { PaginationUrlParametersGroupingType } from '@/services/tables/types'
import BaseGrouperServiceFactory from '@/services/tables/baseGrouperServiceFactory'
import type Grouping from '@/data/models/tables/Grouping'

@injectable()
export default class NoopGroupingServiceFactory extends BaseGrouperServiceFactory {
  public init(params?: PaginationUrlParametersGroupingType) {}

  public reset(excludeNames: string[] = []) {}

  public applyGrouping(name: string, direction?: GroupingDirection) {}

  public getAllGroupings() {
    return []
  }

  public getActiveGrouping(): Grouping[] {
    return []
  }

  public toQuery(): PaginationUrlParametersGroupingType {
    return {} as PaginationUrlParametersGroupingType
  }

  public loadGroupingFacets(): Promise<void> {
    return Promise.resolve()
  }
}

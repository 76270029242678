import { AttrField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { Nullable } from '@/types'
import type { AttachmentFileEntityType, AttachmentFileMetadata } from '@/data/models/domain/attachmentFile/types'

@OrmModel(modelNames.ATTACHMENT_FILE)
export default class AttachmentFile extends BaseModel {
  @StringField()
  public name: string

  @StringField()
  public originalName: string

  @StringField()
  public mimeType: string

  @NumberField()
  public size: number

  @StringField()
  public type: AttachmentFileEntityType

  @StringField()
  public url: string

  @StringField(null)
  public previewUrl: Nullable<string>

  @AttrField(null)
  public metadata: Nullable<AttachmentFileMetadata>

  @StringField()
  public createdAt: string
}

import { injectable } from 'inversify'
// eslint-disable-next-line tp/forbid-import-composable-to-service,tp/using-vue-in-services-restriction
import type { App } from '@/composition/vue/compositionApi'
import type { MonitoringServiceInterface } from '@/services/monitoring/types'
import type User from '@/data/models/domain/User'

@injectable()
export class NoopMonitoringService implements MonitoringServiceInterface {
  public init(app: App): void {
    // Do nothing
  }

  public setUser(user: User | null) {
    // Do nothing
  }

  public logInfo(): void {
    // Do nothing
  }

  public logError(): void {
    // Do nothing
  }

  public destroy() {
    // Do nothing
  }
}

import parseNumber, {
  getCountryCallingCode,
  type CountryCode,
  parseIncompletePhoneNumber,
  getExampleNumber,
  type PhoneNumber,
  AsYouType,
} from 'libphonenumber-js'
import { inject, injectable } from 'inversify'
import parsePhoneNumber from 'libphonenumber-js/max'
import examples from 'libphonenumber-js/examples.mobile.json'
import {
  allowedPhoneChars,
  countriesWithNationalFormat,
  groupingPhoneRegExp,
  maxPhoneNumberLength,
  minPhoneNumberLength,
  PhoneNumberFormat,
  type PotentialPhonesData,
  type TmCountryCode,
  TmFakeCountryCode,
  type TmRealCountryCode,
  USRegulationsCountries,
} from '@/services/types'
import { addPlusToPhoneNumber } from '@/utils/string/addPlusToPhoneNumber'
import TmLogicError from '@/core/error/tmLogicError'
import type { PhoneWithCountryCode } from '@/services/forms/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { CountryPhoneDataServiceInterface, CurrentUserCountryIdInterface } from '@/services/domain/user/types'
import { hasIntersection } from '@/utils/array'

@injectable()
export default class PhoneService {
  constructor(
    @inject(SERVICE_TYPES.UserService) protected readonly userService: CurrentUserCountryIdInterface,
    @inject(SERVICE_TYPES.CountryService) protected readonly countryService: CountryPhoneDataServiceInterface,
  ) {}

  public readonly tmFakePhoneNumberPrefix = '+999'

  public asYouTypeFormatters = new Map<TmCountryCode, AsYouType>()

  public isValidTextMagicPhone(phone: string) {
    const clearFakePhoneNumber = this.clearPhone(this.tmFakePhoneNumberPrefix)
    return new RegExp(`^\\+?${clearFakePhoneNumber}\\d{1,12}$`).test(phone)
  }

  public isFakePhoneNumber(phone: string): boolean {
    return phone.startsWith(this.tmFakePhoneNumberPrefix)
  }

  public getPhoneWithLeadingPlus(phone: string): string {
    if (!phone) {
      return ''
    }
    if (phone.startsWith('00')) {
      return addPlusToPhoneNumber(phone.slice(2))
    }
    return addPlusToPhoneNumber(phone)
  }

  public replaceLeadingZerosWithPlus(phone: string) {
    return phone.replace(/^00/, '+')
  }

  public isLeadingCountryCallingCode(phone: string, countryCallingCode?: string) {
    return countryCallingCode ? phone.startsWith(countryCallingCode) : false
  }

  public getPhoneWithCountryCode(phone: string, countryCode: TmCountryCode) {
    if (countryCode !== TmFakeCountryCode && this.isLocalPhoneFormat(phone)) {
      return parsePhoneNumber(phone, countryCode)?.number || phone
    }
    return phone
  }

  public format(phone: string, countryId: CountryCode | TmCountryCode = 'US'): string {
    if (!this.canBeParsed(phone)) {
      return phone
    }

    return this.formatPhone(this.getPhoneWithLeadingPlus(phone), countryId)
  }

  public canBeParsed(phone: string): boolean {
    return !!parseIncompletePhoneNumber(phone).length
  }

  public isValidPhoneNumber(phone: string, countryCode?: TmCountryCode): boolean {
    const phoneNumber = this.getPhoneWithLeadingPlus(phone)

    if (this.isFakePhoneNumber(phoneNumber) || countryCode === TmFakeCountryCode) {
      return phoneNumber.length > 4 && phoneNumber.length < 17
    }

    return this.isValidRealPhoneNumber(phone, countryCode)
  }

  public cleanPhoneNumber(rawPhoneNumber) {
    return rawPhoneNumber.replace(/\D/g, '')
  }

  public getExceptionalPhoneCountryCode(phoneNumber: string): CountryCode | null {
    const preparedPhone = this.cleanPhoneNumber(phoneNumber)
    const phoneLength = preparedPhone.length

    if (
      preparedPhone.startsWith('49') &&
      ['13', '55', '99', '00', '50', '33'].includes(preparedPhone.slice(5, 7)) &&
      phoneLength === 14
    ) {
      return 'DE' // Germany
    }

    if (preparedPhone.startsWith('315') && phoneLength === 10) {
      return 'NL' // Netherlands
    }

    if ((preparedPhone.startsWith('46719') || preparedPhone.startsWith('46710')) && phoneLength === 15) {
      return 'SE' // Sweden
    }

    if (preparedPhone.startsWith('62') && phoneLength === 11) {
      return 'ID' // Indonesia
    }

    if (preparedPhone.startsWith('475800') && phoneLength === 14) {
      return 'NO' // Norway
    }

    if (preparedPhone.startsWith('4759') && phoneLength === 10) {
      return 'NO' // Norway
    }

    if (preparedPhone.startsWith('230') && phoneLength >= 10 && phoneLength <= 11) {
      return 'MU' // Mauritius
    }

    if (preparedPhone.startsWith('337') && phoneLength >= 14 && phoneLength <= 16) {
      return 'FR' // France
    }

    if (preparedPhone.startsWith('34590') && phoneLength === 15) {
      return 'ES' // Spain
    }

    if (phoneLength === 11) {
      const usCodes = ['1645', '1728', '1945']
      const phoneKey = preparedPhone.slice(0, 4)
      if (usCodes.includes(phoneKey)) return 'US' // USA
    }

    return null
  }

  public isValidRealPhoneNumber(phone: string, countryCode?: CountryCode): boolean {
    const phoneNumber = this.getPhoneWithLeadingPlus(phone)

    if (this.getExceptionalPhoneCountryCode(phoneNumber)) {
      return true
    }

    try {
      if (!this.canBeParsed(phoneNumber)) {
        return false
      }

      const phoneParsed = parsePhoneNumber(countryCode ? phone : phoneNumber, countryCode)
      if (countryCode && phoneParsed && phoneParsed.country !== countryCode) {
        return false
      }
      if (phoneParsed) {
        return phoneParsed.isValid()
      }

      return false
    } catch {
      return false
    }
  }

  public getCountryByPhone(phone: string | null): TmCountryCode | null {
    if (!phone) return null
    if (this.isFakePhoneNumber(this.getPhoneWithLeadingPlus(phone))) {
      return TmFakeCountryCode
    }
    return this.getPhoneDataCountry(phone)
  }

  public getPhoneData(phone: string) {
    try {
      const preparePhone = this.getPhoneWithLeadingPlus(phone)
      return parsePhoneNumber(preparePhone) ?? null
    } catch (e: any) {
      // do nothing - there are mistakes like "too short number" etc
      return null
    }
  }

  public getPhoneDataCountry(phone: string) {
    const phoneData = this.getPhoneData(phone)
    if (phoneData?.country) return phoneData?.country
    return this.getExceptionalPhoneCountryCode(phone)
  }

  public formatPhoneNumberIfPossible(phone: string, format: PhoneNumberFormat) {
    try {
      const phoneNumber = parsePhoneNumber(this.getPhoneWithLeadingPlus(phone))

      if (!phoneNumber) {
        return phone
      }

      switch (format) {
        case PhoneNumberFormat.INTERNATIONAL:
          return phoneNumber.formatInternational()
        case PhoneNumberFormat.NATIONAL:
          return phoneNumber.formatNational()
        case PhoneNumberFormat.RFC3966:
          return phoneNumber.getURI()
        default:
          return phone
      }
    } catch {
      return phone
    }
  }

  public getPhoneWithCountryCodeByPhoneNumber(
    phoneNumber: string,
    defaultCountryCode?: TmCountryCode,
  ): PhoneWithCountryCode {
    const countryCode = this.getCountryByPhone(phoneNumber) || (defaultCountryCode as TmCountryCode)
    if (!countryCode) {
      throw new TmLogicError('Country code not found')
    }
    return {
      phone: phoneNumber,
      countryCode,
    }
  }

  public getPhoneWithoutCountryCodeByPhoneNumber(phoneNumber: string, countryCode?: TmCountryCode | null): string {
    const phoneCountryCode = countryCode ?? this.getCountryByPhone(phoneNumber)

    if (!phoneCountryCode) {
      return phoneNumber
    }

    const phoneLeadCode = this.getPhoneLeadCodeByCountryCode(phoneCountryCode)

    if (phoneNumber.startsWith(phoneLeadCode)) {
      return phoneNumber.replace(phoneLeadCode, '')
    }

    if (phoneNumber.startsWith('+')) {
      return phoneNumber.replace('+', '')
    }

    return phoneNumber
  }

  public clearPhone(phone: string) {
    return phone.replaceAll(/\D/g, '')
  }

  public clearPhoneAndSavePlus(phone: string) {
    return phone.replace(/[^\d+]/g, '')
  }

  public clearPhoneAndAddPlus(phone: string) {
    return addPlusToPhoneNumber(this.clearPhone(phone))
  }

  /**
   * Check whether the text contains only characters allowed for the phone
   * @param {string} text
   * @returns {boolean}
   *
   * @example
   * isLikelyPhone('+1 907 531 12-34') // true
   * isLikelyPhone('1907531') // true
   * isLikelyPhone('Street: 1907 531 Home') // false
   */
  public isLikelyPhone(text: string) {
    return !new RegExp(`[^${allowedPhoneChars}]`).test(text)
  }

  public isLocalPhoneFormat(phone: string) {
    return !phone.startsWith('+') && !phone.startsWith('00')
  }

  public getExamplePhoneNational(countryCode: TmCountryCode) {
    if (this.isFakeCountry(countryCode)) {
      return '99999999999'
    }
    return getExampleNumber(countryCode as TmRealCountryCode, examples)?.nationalNumber.toString()
  }

  /**
   * Note that this format cannot be considered as fully valid but acceptable for further conversion to E.164
   */
  public getExamplePhoneForITU(countryCode: TmCountryCode): string {
    if (this.isFakeCountry(countryCode)) {
      return '+9 (999) 999-99-99'
    }
    const number: PhoneNumber | undefined = getExampleNumber(countryCode as TmRealCountryCode, examples)
    if (!number) {
      return ''
    }

    return `+${number.countryCallingCode} ${this.formatPhone(String(number.nationalNumber), countryCode)}`
  }

  public formatPhone(phone: string, countryId: TmCountryCode): string {
    if (this.isFakeCountry(countryId)) {
      return phone
    }

    return this.getAsYouTypeFormatterForCountry(countryId).input(phone)
  }

  public isSenderId(phone: string): boolean {
    return /[a-zA-Z]/.test(phone)
  }

  public isNationalFormatCountry(countryId: TmCountryCode) {
    return countriesWithNationalFormat.includes(countryId)
  }

  public isFakeCountry(countryCode: TmCountryCode): boolean {
    return countryCode === TmFakeCountryCode
  }

  public isEmptyPhoneNumber(phone: string, countryCode?: TmCountryCode | null) {
    return !this.getPhoneWithoutCountryCodeByPhoneNumber(phone, countryCode)
  }

  private getCountryCallingCode(countryId: CountryCode) {
    return getCountryCallingCode(countryId)
  }

  private formatFakePhoneNumber(phone: string): string {
    return phone.replace(/(.999)(\d{3})(\d+)/, '$1 $2 $3')
  }

  public getPhoneNumberFormat(phoneCountryId: TmCountryCode | null): PhoneNumberFormat {
    const currentUserCountryId = this.userService.getCurrentUserCountryId()
    const isNationalFormat =
      phoneCountryId &&
      this.isNationalFormatCountry(phoneCountryId) &&
      this.isNationalFormatCountry(currentUserCountryId)

    return isNationalFormat ? PhoneNumberFormat.NATIONAL : PhoneNumberFormat.INTERNATIONAL
  }

  public preparePhoneForDisplay(phone: string, countryId?: TmCountryCode | null): string {
    const phoneWithLeadingPlus = this.getPhoneWithLeadingPlus(phone)

    if (this.isFakePhoneNumber(phoneWithLeadingPlus)) {
      return this.formatFakePhoneNumber(phoneWithLeadingPlus)
    }

    const phoneCountryId = countryId ?? this.getCountryByPhone(phoneWithLeadingPlus)
    const format = this.getPhoneNumberFormat(phoneCountryId)

    return this.formatPhoneNumberIfPossible(phoneWithLeadingPlus, format)
  }

  public getPhoneLeadCodeByCountryCode(countryCode: TmCountryCode): string {
    const foundedCountry = this.countryService.getCountryPhone(countryCode)

    if (!foundedCountry) {
      throw new TmLogicError(`Country with code "${countryCode}" not found in "countryService"`)
    }
    return addPlusToPhoneNumber(foundedCountry.code)
  }

  public getPhoneWithCountryCodeByCountryCode(countryCode: TmCountryCode): PhoneWithCountryCode {
    return {
      phone: this.getPhoneLeadCodeByCountryCode(countryCode),
      countryCode,
    }
  }

  public isTollFreePhone(phone: string, countryCode: CountryCode): boolean {
    if (!USRegulationsCountries.includes(countryCode) || !this.isValidRealPhoneNumber(phone, countryCode)) {
      return false
    }

    return parsePhoneNumber(phone, countryCode)?.getType() === 'TOLL_FREE'
  }

  public prepareFormDataPhoneNumber({ phone, countryCode }: PhoneWithCountryCode): string {
    const trimmedPhone = phone.trim()
    const phonePrefix = this.getPhoneLeadCodeByCountryCode(countryCode)
    if (trimmedPhone === phonePrefix) return ''

    const preparedNumber = this.getPhoneWithCountryCode(phone, countryCode)
    return this.clearPhoneAndAddPlus(preparedNumber)
  }

  /**
   * Splits the input text into an array of numbers. Empty strings are filtered out from the result.
   * @param {string} text
   * @returns {string[]}
   *
   * @example
   * const text = '999, 9991 (999) 123456789012 999 1234 56789 0123 9991234567890123, 999 1234 56789 012'
   * splitTextIntoPhoneChunks(text) // ['999', '9991', '999', '123456789012', '999', '1234', '56789', '0123', '9991234567890123', '999', '1234', '56789', '012']
   */
  private splitTextIntoPhoneChunks(text: string) {
    return text.split(/[^\d+]/).filter(Boolean)
  }

  /**
   * Checking phone length, and also checking the correctness of the position + and
   * as well as checking the correct position "+" if there is one
   * @param {string} phone
   * @returns {string[]}
   *
   * @example
   * simpleValidPhone('5912 2130') // valid
   * simpleValidPhone('5912+2130') // invalid
   * simpleValidPhone('+5912 2130') // valid
   */
  private simpleValidPhone(phone: string) {
    const hasPlus = phone.includes('+')
    const phoneLength = phone.length - +hasPlus
    const isLengthValid = phoneLength > minPhoneNumberLength && phoneLength <= maxPhoneNumberLength

    return (!hasPlus || phone.startsWith('+')) && isLengthValid ? phone : null
  }

  /**
   * Processes an array of string chunks to identify potential phone numbers.
   * It checks each chunk for phone number criteria, including length and presence of a plus sign.
   * Additionally, it combines consecutive chunks to form longer phone numbers,
   * validating them against length constraints.
   * Replacing leading zeros with a plus sign.
   * @param {string[]} chunks
   * @return {PotentialPhonesData[]}
   *
   * @example
   * const chunks = ['5912', '2130', '5912', '2131', '5912', '2132', '5912', '2133']
   * getPotentialPhonesFromArray(chunks) // [{ indexes: [0], phone: '5912' }, { indexes: [0,1], phone: '59122130' },{ indexes: [0,1,2], phone: '591221305912' }, ...]
   */
  private getPotentialPhonesFromArray(chunks: string[]): PotentialPhonesData[] {
    const data: PotentialPhonesData[] = []

    chunks.forEach((chunk, i, arr) => {
      const hasPlus = chunk.includes('+')
      const chunkLength = chunk.length - +hasPlus

      if (chunkLength >= minPhoneNumberLength && chunkLength < maxPhoneNumberLength) {
        data.push({
          indexes: [i],
          phone: this.replaceLeadingZerosWithPlus(chunk),
        })
      }

      if (chunkLength < maxPhoneNumberLength) {
        let combinedVal = chunk
        const indexes = [i]

        for (
          let nextIndex = i + 1;
          nextIndex < arr.length && combinedVal.length < maxPhoneNumberLength;
          nextIndex += 1
        ) {
          combinedVal += arr[nextIndex]
          indexes.push(nextIndex)

          if (this.simpleValidPhone(combinedVal)) {
            data.push({
              indexes: indexes.slice(),
              phone: this.replaceLeadingZerosWithPlus(combinedVal),
            })
          }
        }
      }
    })

    return data
  }

  /**
   * Check if the phone is a valid number or a textmagic number, for correct
   * validation of phones in local format, you need to pass countryCode
   * @param {string} phone
   * @param {CountryCode | undefined} countryCode
   * @returns {string | null}
   *
   * @example
   * getValidOrTextmagicPhone('5912 2130') // invalid
   * getValidOrTextmagicPhone('5912 2130', 'EE') // valid (Estonian phone number in local format)
   * getValidOrTextmagicPhone('001 334 352 9551') // valid (American phone number)
   * getValidOrTextmagicPhone('011 1 334 352 9551') // valid (American phone number)
   * getValidOrTextmagicPhone('+1-514-623-2660', 'EE') // valid (Canada phone number)
   */
  private getValidOrTextmagicPhone(phone: string, countryCode?: CountryCode): string | null {
    const countryCallingCode = countryCode ? this.getCountryCallingCode(countryCode) : undefined
    let phoneData: PhoneNumber | undefined

    if (
      !this.isValidTextMagicPhone(phone) &&
      (this.isLocalPhoneFormat(phone) || this.isLeadingCountryCallingCode(phone, countryCallingCode))
    ) {
      phoneData = parseNumber(phone, countryCode)
    }

    phoneData = phoneData || parseNumber(addPlusToPhoneNumber(phone))

    if ((phoneData && phoneData.isValid()) || this.isValidTextMagicPhone(phone)) {
      return (phoneData?.number || addPlusToPhoneNumber(phone)) as string
    }

    return null
  }

  /**
   * Extracts phone numbers from text and converts them to international format
   * @param {string} text
   * @param {CountryCode | undefined} countryCode - necessary to correctly identify phones in local format
   * @returns {string[]}
   *
   * @example
   * const text = '5912 2130 5912 2131 5912 2132 5912 2133'
   * const countryCode = 'EE' // Estonia
   * getPhonesFromText(text, countryCode) // ['+37259122130', '+37259122131', '+37259122132', '+37259122133']
   */
  public getPhonesFromText(text: string, countryCode?: CountryCode): string[] {
    const validPhones: string[] = []
    const phoneGroups = text.match(groupingPhoneRegExp)

    phoneGroups?.forEach((group) => {
      let isGroupFullyValid = false
      const clearGroup = this.replaceLeadingZerosWithPlus(this.clearPhoneAndSavePlus(group))
      if (this.simpleValidPhone(clearGroup)) {
        const validPhone = this.getValidOrTextmagicPhone(clearGroup, countryCode)
        if (validPhone) {
          validPhones.push(validPhone)
          isGroupFullyValid = true
        }
      }
      if (!isGroupFullyValid) {
        let prevValidIndexes: number[] = []
        const chunks = this.splitTextIntoPhoneChunks(group)
        const potentialPhoneNumbers = this.getPotentialPhonesFromArray(chunks)

        potentialPhoneNumbers.forEach(({ indexes, phone }) => {
          if (!hasIntersection(prevValidIndexes, indexes)) {
            const validPhone = this.getValidOrTextmagicPhone(phone, countryCode)

            if (validPhone) {
              validPhones.push(validPhone)
              prevValidIndexes = indexes
            }
          }
        })
      }
    })

    return validPhones.filter((t) => this.isValidPhoneNumber(t))
  }

  public validateContactPhone({ phone, countryCode }: PhoneWithCountryCode) {
    const trimmedPhone = phone?.trim()

    if (!trimmedPhone) return true

    if (this.isEmptyPhoneNumber(trimmedPhone, countryCode)) {
      return true
    }

    return this.validatePhone({ phone, countryCode })
  }

  public validatePhone({ phone, countryCode }: PhoneWithCountryCode) {
    const trimmedPhone = phone?.trim()

    if (!trimmedPhone) return true

    let countryId: TmCountryCode | null = countryCode
    if (!countryId) {
      countryId = this.getCountryByPhone(phone)
      if (!countryId) {
        return false
      }
    }
    const phonePrefix = this.getPhoneLeadCodeByCountryCode(countryId)
    if (trimmedPhone === phonePrefix) return true

    const phoneInputValue = this.getPhoneWithCountryCode(phone, countryId)
    if (!phoneInputValue) return false

    const clearedPhone = this.clearPhoneAndAddPlus(phoneInputValue)
    return this.isValidPhoneNumber(clearedPhone, countryId)
  }

  protected getAsYouTypeFormatterForCountry(countryId: TmCountryCode) {
    if (!this.asYouTypeFormatters.has(countryId)) {
      const formatter = new AsYouType(countryId as TmRealCountryCode)
      this.asYouTypeFormatters.set(countryId, formatter)
    }

    const formatter = this.asYouTypeFormatters.get(countryId)!
    formatter.reset()
    return formatter
  }
}

import { inject, injectable } from 'inversify'
import type { TypedFieldConfig } from '@/services/forms/baseForm/typedFormBuilder/types'
import {
  type SelectOption,
  type SelectSearchInstagramAccountSubfieldNames,
  type SelectSearchInstagramAccountFieldConfig,
  SelectSearchInstagramAccountType,
  type SelectInstagramAccountOption,
  type SelectInstagramAccountSubheaderOption,
  type SelectInstagramAccountOverrideOption,
} from '@/services/forms/types'
import { fieldInstagramAccountRulePrefix } from '@/components/forms/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type TranslateService from '@/services/translateService'
import { instagramFlowRoute, instagramSettingsRoute } from '@/routes/user/messenger/instagram'
import type InstagramAccountService from '@/services/domain/messenger/instagram/instagramAccountService'

type InstagramAccountFieldParams = {
  withCustomInstagramAccount?: boolean
}

@injectable()
export default class SelectInstagramAccountFieldBuilderService {
  constructor(
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    @inject(SERVICE_TYPES.InstagramAccountService) protected readonly instagramAccountService: InstagramAccountService,
  ) {}

  public getSubfieldValidationRuleName(fieldName: SelectSearchInstagramAccountSubfieldNames) {
    return `${fieldInstagramAccountRulePrefix}-${fieldName}`
  }

  public getFieldConfig({
    withCustomInstagramAccount,
  }: InstagramAccountFieldParams): TypedFieldConfig<SelectSearchInstagramAccountFieldConfig> {
    return {
      fieldType: 'selectSearchInstagramAccount',
      value: {
        valueType: SelectSearchInstagramAccountType.DEFAULT,
      },
      componentProps: {
        withCustomInstagramAccount: () => withCustomInstagramAccount,
      },
    }
  }

  public extendOptions(options: SelectOption[]): SelectInstagramAccountOption[] {
    const subheaderOption: SelectInstagramAccountSubheaderOption = {
      text: 'connectedInstagramAccount',
      value: 'connectedInstagramAccount',
      subheader: this.translateService.t('fieldSelectSearchInstagramAccount.accounts'),
      disabled: true,
    }

    const newInstagramAccountOption: SelectInstagramAccountOverrideOption = {
      text: this.translateService.t('fieldSelectSearchInstagramAccount.connectNew'),
      value: 'newInstagramAccountValue',
      overrideOption: true,
      type: 'button',
      to: {
        name: this.instagramAccountService.hasItems() ? instagramSettingsRoute.name : instagramFlowRoute.name,
      },
    }

    const resultOptions: SelectInstagramAccountOption[] = [subheaderOption]

    if (options.length) {
      resultOptions.push(...options)
    } else {
      resultOptions.push(newInstagramAccountOption)
    }

    return resultOptions
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  // Resolvers
  ScheduledEditResolverService,

  // Other
  ScheduledEditService,
}

export type ScheduledEditServices = keyof typeof SERVICES

export const SCHEDULED_EDIT_SERVICE_TYPES: Record<ScheduledEditServices, symbol> =
  makeServicesTypes<ScheduledEditServices>(enumKeysToArray<ScheduledEditServices>(SERVICES))

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum CONTACTS_UNSUBSCRIBED_SERVICES {
  UnsubscribedContactService,
  UnsubscribedContactColumnServiceFactory,
  UnsubscribedEmailContactColumnServiceFactory,
  UnsubscribedContactRepository,
  UnsubscribedSettingsRepository,
  UnsubscribedSettingsService,
  UnsubscribeContactFormService,
  UnsubscribeContactWithEmailFormService,
  UnsubscribedSettingsFormService,
  UnsubscribedEmailContactRepository,
  UnsubscribedEmailContactService,
}

export type ContactsUnsubscribedServices = keyof typeof CONTACTS_UNSUBSCRIBED_SERVICES

export const CONTACTS_UNSUBSCRIBED_SERVICES_TYPES =
  makeServicesTypesFromEnum<ContactsUnsubscribedServices>(CONTACTS_UNSUBSCRIBED_SERVICES)

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { AbstractEndpointsInterface } from '@/services/endpointsService'
import { intersect } from '@/utils/array'
import type { SchemaValueType } from '@/services/types'
import type { Endpoint, EndpointParams } from '@/services/endpoints'
import type LoggerService from '@/services/loggerService'
import type FilterSchemaRepository from '@/data/repositories/filters/filterSchemaRepository'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import FilterSchema from '@/data/models/tables/FilterSchema'

@injectable()
export default class FilterSchemaService {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.EndpointsService) protected readonly endpointsService: AbstractEndpointsInterface,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {
    this.endpointsService = endpointsService
    this.loggerService = loggerService
  }

  public findByName(name: string): SchemaValueType | null {
    const filterSchema = this.getFilterSchemaRepo().findEntityByIdOrNull(name)

    if (!filterSchema) {
      return null
    }

    return filterSchema.schema
  }

  public getFiltersSchema(endpoint: Endpoint, endpointParams?: EndpointParams): SchemaValueType {
    const name = this.endpointsService.getPath(endpoint, endpointParams)
    const result = this.findByName(name) as SchemaValueType

    if (!result) {
      this.loggerService.log('warn', `No schema for ${name}`)
      return {
        name,
        schema: {
          filter: [],
          sort: [],
          groupBy: [],
          searchFields: [],
        },
      }
    }

    return result
  }

  public getSearchFilters(endpoint: Endpoint, supportedSearchFilters: string[]): string[] {
    return intersect<string>(this.getFiltersSchema(endpoint).schema.searchFields, supportedSearchFilters)
  }

  public addSchemas(schemas: SchemaValueType[]) {
    const filterSchemaRepository = this.getFilterSchemaRepo()

    const schemaModels = schemas.map((schema) => ({
      id: schema.name,
      schema,
    }))

    filterSchemaRepository.insert(schemaModels)
  }

  protected getFilterSchemaRepo() {
    return this.em.getRepository<FilterSchemaRepository>(FilterSchema)
  }
}

import { inject, injectable } from 'inversify'
import type { AppModuleResolverParams, Resolvable } from '@/services/resolvers/types'
import type { Route } from '@/services/route/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type ResolverService from '@/services/resolvers/resolverService'

@injectable()
export default class AppModuleResolverService implements Resolvable<AppModuleResolverParams> {
  constructor(@inject(SERVICE_TYPES.ResolverService) protected readonly resolverService: ResolverService) {}

  public async resolve(params: AppModuleResolverParams, route?: Route): Promise<void> {
    await this.resolverService.resolveDeps(String(route?.name), params.modules)
  }
}

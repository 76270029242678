import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ScheduledUpcomingColumnServiceFactory,
  ScheduledUpcomingGridable,
  ScheduledUpcomingExportService,
}

export type ScheduledUpcomingServices = keyof typeof SERVICES

export const SCHEDULED_UPCOMING_SERVICE_TYPES: Record<ScheduledUpcomingServices, symbol> =
  makeServicesTypes<ScheduledUpcomingServices>(enumKeysToArray<ScheduledUpcomingServices>(SERVICES))

import { injectable } from 'inversify'
import type { ColumnEntity, RawDefaultColumns } from '@/core/tables/types'
import ColumnsFromApiFactory from '@/services/tables/columnsFromApiFactory'
import type { Dict } from '@/types'

@injectable()
export default abstract class ColumnsFromApiStructuredFactory extends ColumnsFromApiFactory {
  public getInitColumns(): RawDefaultColumns {
    const columns = this.getStructuredColumns()
    return Object.entries(columns).reduce(
      (result, [name, column], currentIndex) => ({
        ...result,
        [name]: {
          columnOrder: currentIndex,
          visible: column.visible !== undefined ? column.visible : true,
          required: true,
          ...column,
        },
      }),
      {},
    )
  }

  protected abstract getStructuredColumns(): Dict<ColumnEntity>
}

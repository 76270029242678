<template>
  <div class="breadcrumbs">
    <ul class="breadcrumbs__list">
      <li
        v-if="titledBreadcrumbs.length > 1"
        class="breadcrumbs__list-item"
      >
        <tm-button
          icon-only
          class="breadcrumbs__go-to-back-button"
          size="medium"
          @click="goToBack"
        >
          <tm-icon-chevron-left-svg />
        </tm-button>
      </li>
      <li
        v-for="(breadcrumb, index) in titledBreadcrumbs"
        :key="breadcrumb.label"
        class="breadcrumbs__list-item"
        :class="{
          'breadcrumbs__list-item--no-truncate': breadcrumb.noTruncate,
        }"
      >
        <tm-conditional-link
          :to="breadcrumb.route"
          tag="span"
        >
          <slot
            name="label"
            :index="index"
            :label="breadcrumb.label"
          >
            {{ breadcrumb.label }}
          </slot>
        </tm-conditional-link>

        <tm-icon
          v-if="index + 1 < titledBreadcrumbs.length"
          name="tmi-chevron-right"
          size="xSmall"
          class="breadcrumbs--divider distinct--color pl-1"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { get as lodashGet } from 'lodash-es'
import { defineComponent, computed, type PropType } from '@/composition/vue/compositionApi'
import TmButton from '@/components/shared/TmButton.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import type { Breadcrumb, Breadcrumbs } from '@/components/shared/types'
import { get as containerGet } from '@/core/container/container'
import type DefaultTitlerService from '@/services/route/titlers/defaultTitlerService'
import TmConditionalLink from '@/components/shared/TmConditionalLink.vue'
import type { TranslationKey } from '@/services/types'
import { useGoToBackAction } from '@/composition/router'
import TmIconChevronLeftSvg from '@/components/shared/svgIcons/TmIconChevronLeftSvg.vue'

export default defineComponent({
  components: {
    TmIconChevronLeftSvg,
    TmConditionalLink,
    TmButton,
    TmIcon,
  },
  props: {
    breadcrumbs: {
      type: Array as PropType<Breadcrumbs>,
      required: true,
    },
  },
  setup(props) {
    const titlerService = containerGet<DefaultTitlerService>('DefaultTitlerService')

    const getLabel = (breadcrumb: Breadcrumb): string => {
      if (breadcrumb.label) {
        return breadcrumb.label
      }
      const routeName = lodashGet(breadcrumb, ['route', 'name']) as TranslationKey | undefined
      return routeName ? titlerService.getPageTitle(routeName) : ''
    }

    const titledBreadcrumbs = computed(() =>
      props.breadcrumbs.map(
        (link) =>
          ({
            label: getLabel(link),
            route: link.route,
            noTruncate: link.noTruncate,
          }) as Breadcrumb,
      ),
    )

    const goToBack = computed(() => useGoToBackAction(props.breadcrumbs.at(-2)?.route))

    return {
      titledBreadcrumbs,
      goToBack,
    }
  },
})
</script>

<style lang="scss" scoped>
.w28 {
  width: 28px;
}
.breadcrumbs {
  max-width: 100%;
  &--divider {
    margin: 0 6px;
  }

  &__go-to-back-button {
    margin-right: 12px;
    border-radius: 4px !important;
  }

  &__list {
    display: flex;
    padding: 4px;
    margin: -4px;
    overflow: hidden;
  }
  &__list-item {
    display: flex;
    align-items: center;
    flex-shrink: 10;
    margin: -4px;
    padding: 4px;
    font-size: 12px;
    overflow: hidden;
    list-style-type: none;

    &:nth-child(1),
    &:nth-child(2) {
      flex-shrink: 0;
    }

    > a,
    > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    a,
    span {
      line-height: 1.5;
      font-weight: $medium-font;
      color: $neutral;
    }
    a {
      &:hover {
        color: $emphasize;
      }
    }
    .tm-icon {
      color: $distinct;
    }
  }

  .breadcrumbs__list-item.breadcrumbs__list-item--no-truncate {
    @include media-md-min {
      overflow: initial;

      > a,
      > span {
        overflow: initial;
      }
    }
  }
}
</style>

import { inject, injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import TollFreePhoneNumber from '@/data/models/domain/services/regulations/TollFreePhoneNumber'
import type TollFreePhoneNumbersRepository from '@/data/repositories/domain/services/regulations/tollFreePhoneNumbersRepository'
import { TollFreeRequestStatus } from '@/services/domain/services/regulations/tollFreeRequest/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type TranslateService from '@/services/translateService'
import type { TmRoutes } from '@/services/route/routerTypes'
import type { ModelRaw } from '@/types'
import type TollFreeRouteHelperService from '@/services/domain/services/regulations/tollFreeRouteHelperService'
import { ModelEventType } from '@/services/transport/types'
import type PhoneNumbersService from '@/services/domain/phoneNumbers/phoneNumbersService'

@DomainSettings({
  model: TollFreePhoneNumber,
})
@injectable()
export default class TollFreePhoneNumbersService extends DomainBaseService<TollFreePhoneNumbersRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    @inject(SERVICE_TYPES.TollFreeRouteHelperService)
    protected readonly tollFreeRouteHelperService: TollFreeRouteHelperService,
    @inject(SERVICE_TYPES.PhoneNumbersService) protected readonly phoneNumbersService: PhoneNumbersService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public getTollFreeNumberActionLabel(
    number: Pick<ModelRaw<TollFreePhoneNumber>, 'requestId' | 'tollFreeStatus'>,
  ): string | null {
    const map: Record<TollFreeRequestStatus, string> = {
      [TollFreeRequestStatus.IN_REVIEW]: this.translateService.t('tollFreePhoneNumbersTable.viewForm'),
      [TollFreeRequestStatus.PENDING]: this.translateService.t('tollFreePhoneNumbersTable.viewForm'),
      [TollFreeRequestStatus.ACTION_REQUIRED]: this.translateService.t('tollFreePhoneNumbersTable.fixForm'),
      [TollFreeRequestStatus.DRAFT]: this.translateService.t('tollFreePhoneNumbersTable.editForm'),
      [TollFreeRequestStatus.VERIFIED]: this.translateService.t('tollFreePhoneNumbersTable.viewForm'),
    }
    if (number.tollFreeStatus) {
      return map[number.tollFreeStatus]
    }
    if (!number.requestId && !number.tollFreeStatus) {
      return this.translateService.t('tollFreePhoneNumbersTable.editForm')
    }

    return null
  }

  public getTollFreeNumberActionRoute(
    number: Pick<ModelRaw<TollFreePhoneNumber>, 'requestId' | 'tollFreeStatus'>,
  ): TmRoutes | null {
    const firstStep = this.tollFreeRouteHelperService.getRouteForStep(number.requestId || '', 'tollfree_brand_creation')

    const map: Record<TollFreeRequestStatus, TmRoutes | null> = {
      [TollFreeRequestStatus.VERIFIED]: null,
      [TollFreeRequestStatus.IN_REVIEW]: null,
      [TollFreeRequestStatus.PENDING]: null,
      [TollFreeRequestStatus.ACTION_REQUIRED]: firstStep,
      [TollFreeRequestStatus.DRAFT]: firstStep,
    }

    return number.tollFreeStatus ? map[number.tollFreeStatus] : null
  }

  public async restoreNumber(id: string) {
    await this.phoneNumbersService.restoreNumber(id)
    this.notify([id], ModelEventType.UPDATE)
  }

  public async cancelNumber(id: string) {
    await this.phoneNumbersService.cancelNumber(id)
    this.notify([id], ModelEventType.UPDATE)
  }
}

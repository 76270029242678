import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'

export const getAutomationRulesRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.services.automationRules',
    path: 'automation-rules',
    meta: {
      tags: ['SERVICES_BAR_LIST'],
      permission: 'automationRules',
      modules: [AppModule.AutomationRule, AppModule.AutomationRules, AppModule.AutomationRulesLog],
    },
    component: () => import('@/components/views/services/automationRules/AutomationRules.vue'),
    redirect: { name: 'user.services.automationRules.rules' },
    children: [
      {
        name: 'user.services.automationRules.rules',
        path: '',
        component: () => import('@/components/views/services/automationRules/AutomationRulesListView.vue'),
        props: { tab: true },
        meta: {
          tabTitle: 'pageTabTitle.user.services.automationRules.rules',
        },
      },
      {
        name: 'user.services.automationRules.keywords',
        path: 'keywords',
        component: () => import('@/components/views/services/automationRules/AutomationRulesKeywords.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.automationRules.keywords',
          modules: [AppModule.Keywords],
        },
      },
      {
        name: 'user.services.automationRules.logs',
        path: 'logs',
        meta: {
          tabTitle: 'pageTabTitle.user.services.automationRules.logs',
        },
        component: () => import('@/components/views/services/automationRules/AutomationRulesLogs.vue'),
      },
    ],
  },
]

import { injectable } from 'inversify'
import type { PaginationUrlParametersSortType } from '@/services/tables/types'
import type { Dict } from '@/types'
import type { SortDirection } from '@/core/tables/types'

@injectable()
export default class StaticSortService {
  public sortRaw<T extends Dict<any>>(items: T[], rawParams: PaginationUrlParametersSortType): T[] {
    const sortParams = this.parseSortParams(rawParams)
    if (!sortParams) return items
    return this.sort(items, sortParams.fieldName, sortParams.reverse)
  }

  public sort<T extends Dict<any>>(items: T[], fieldName: string, reverse?: boolean): T[] {
    return items.sort((a, b) => {
      const aValue = a[fieldName]
      const bValue = b[fieldName]

      if (aValue === bValue) return 0

      if (aValue === null) return reverse ? 1 : -1
      if (bValue === null) return reverse ? -1 : 1
      return aValue > bValue === reverse ? 1 : -1
    })
  }

  public sortByString<T extends Dict<any>>(items: T[], fieldName: string, reverse?: boolean): T[] {
    return items.sort((a, b) => {
      const aValue = a[fieldName]
      const bValue = b[fieldName]

      if (aValue === bValue) return 0

      if (aValue === null) return reverse ? 1 : -1
      if (bValue === null) return reverse ? -1 : 1

      if (reverse) {
        return aValue.localeCompare(bValue)
      }
      return bValue.localeCompare(aValue)
    })
  }

  public parseSortParams(sortParams: PaginationUrlParametersSortType) {
    const param = Object.values(sortParams)[0]
    if (!param) return null
    const keyValue = typeof param === 'string' ? sortParams : param
    const [fieldName, direction] = Object.entries(keyValue)[0] as [string, SortDirection]
    return {
      fieldName,
      reverse: direction === 'asc',
    }
  }
}

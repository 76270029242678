import type { Record } from '@vuex-orm/core'
import type { ModelRaw } from '@/types'
import type ORMBaseRepository from '@/data/repositories/ormBaseRepository'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import OrmStorageRepository from '@/data/repositories/ormStorageRepository'
import type BaseModel from '@/data/models/BaseModel'
import { isRecordUnknown } from '@/utils/typeGuards'
import { ServiceGroups } from '@/config/types'

export type BaseCondition = (record: Record) => boolean | string | number
export type UpdateCondition<T> = {
  where: BaseCondition
  data: Partial<ModelRaw<T>>
}
export type Repositories = { [key: string]: ORMBaseRepository<any> }

export const REPO_TYPES = {
  [ServiceGroups.REPOSITORIES]: OrmApiRepository,
  [ServiceGroups.REPOSITORIES_STORE]: OrmStorageRepository,
  [ServiceGroups.REPOSITORIES_MOCK]: OrmStorageRepository,
}

export type RepoType = keyof typeof REPO_TYPES

export type ReadByFilterPayload<R extends Record> = { items: R[] } | { resources: R[] } | R[]

export interface ByCustomParamsFillable<T, M extends BaseModel> {
  fillByCustomParams(params: T): Promise<M>
  findByCustomParams(params: T): M
}

export const isByCustomParamsFillable = <Params, Model extends BaseModel>(
  value: unknown,
): value is ByCustomParamsFillable<Params, Model> => {
  return (
    isRecordUnknown(value) &&
    typeof value.fillByCustomParams === 'function' &&
    typeof value.findByCustomParams === 'function'
  )
}

export type ApiBulkQuery = {
  ids: string[]
  all: number
}

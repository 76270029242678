import { injectable } from 'inversify'
// TODO: Fix ignored tp/forbid-import-composable-to-service
// eslint-disable-next-line tp/using-vue-in-services-restriction,tp/forbid-import-composable-to-service
import type { Plugin } from '@/composition/vue/compositionApi'
import type { ExternalTrackerAsPlugin, Trackable, TrackableConfig } from '@/services/tracking/types'
import TmLogicError from '@/core/error/tmLogicError'

@injectable()
export default abstract class ExternalTrackerService implements Trackable {
  public async initializeTrackerAsPlugin(trackerAsPlugin: ExternalTrackerAsPlugin): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      if (trackerAsPlugin.isDeferred) {
        script.defer = true
      } else {
        script.async = true
      }
      if (trackerAsPlugin.id) {
        script.id = trackerAsPlugin.id
      }
      if (trackerAsPlugin.src) {
        script.src = trackerAsPlugin.src
      }
      const heads = document.getElementsByTagName('head')
      if (!heads.length) {
        throw new TmLogicError('No head html element found')
      }
      heads[0].appendChild(script)
      if (trackerAsPlugin.src) {
        script.onload = () => {
          trackerAsPlugin.trackingHandler()
          resolve()
        }
        script.onerror = () => {
          reject()
        }
      } else if (trackerAsPlugin.loadOnCustomEvent) {
        script.addEventListener('onExternalTrackerLoad', trackerAsPlugin.trackingHandler)
        script.dispatchEvent(new CustomEvent('onExternalTrackerLoad'))
        resolve()
      } else {
        resolve()
      }
    })
  }

  public cleanUp(): void {}

  public abstract getConfig(): TrackableConfig

  public abstract getPlugin(): Plugin
}

import { inject, injectable } from 'inversify'
import type { Scrollbars, ScrollPosition, ScrollSize } from '@/services/browser/types'
import { TmBaseError } from '@/core/error/tmBaseError'
import { SERVICE_TYPES } from '@/core/container/container'
import type WindowService from '@/services/browser/windowService'
import { scrollbarIdAttr, scrollbarSelector } from '@/components/shared/types'

@injectable()
export class ScrollbarService {
  constructor(@inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService) {}

  public has(id: Scrollbars): boolean {
    return !!this.get(id)
  }

  public get(id: Scrollbars): HTMLElement | null {
    return this.windowService.self().document.querySelector(`${scrollbarSelector}[${scrollbarIdAttr}="${id}"]`)
  }

  public getOrFail(id: Scrollbars): HTMLElement {
    const scrollbarEl = this.get(id)

    if (!(scrollbarEl instanceof HTMLElement)) {
      throw new TmBaseError(`There is no scrollbar ${id}`)
    }

    return scrollbarEl
  }

  public scroll(id: Scrollbars, options: ScrollToOptions = { top: 0, left: 0 }) {
    this.getOrFail(id).scroll(options)
  }

  public getPosition(id: Scrollbars): ScrollPosition {
    const { scrollTop, scrollLeft, offsetTop, offsetLeft } = this.getOrFail(id)

    return {
      scrollTop,
      scrollLeft,
      offsetTop,
      offsetLeft,
    }
  }

  public getSize(id: Scrollbars): ScrollSize {
    const { scrollWidth, scrollHeight, offsetWidth, offsetHeight } = this.getOrFail(id)
    return {
      scrollWidth,
      scrollHeight,
      offsetWidth,
      offsetHeight,
    }
  }
}

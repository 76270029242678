import type BaseFormService from '@/services/forms/baseFormService'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import type { FormSubmitStrategyBuilder } from '@/services/forms/types'
import { getFormValidationService } from '@/core/container/ioc'
import { TmFormValidationError } from '@/core/error/tmFormValidationError'
import { processServerError } from '@/composition/forms'
import type BaseModel from '@/data/models/BaseModel'
import { getSubmitMethod } from '@/composition/formStrategy/helper'
import type { GetFormSubmitConfigType } from '@/types'

export const makeGeneralSubmit: FormSubmitStrategyBuilder =
  <T extends BaseFormService<FormBuilderInterface>>(shouldClear = true, byModel?: typeof BaseModel) =>
  async (formService: T, config: GetFormSubmitConfigType<T>) => {
    try {
      const submitMethod = getSubmitMethod<T>(formService, byModel)
      const formValidationService = getFormValidationService()
      formValidationService.resetFormValidator(formService.getFormId())
      formService.clearFormErrorMessage()
      const formData = formService.getFormData('object')
      formService.setIsLoading(true)
      const isValid = await formValidationService.validateForm(formService.getFormId())
      if (!isValid) {
        throw new TmFormValidationError('Invalid form')
      }
      const result = await submitMethod(formData, config)
      if (!shouldClear) {
        return result
      }
      formService.setIsFormSent(true)
      return result
    } catch (error) {
      processServerError(formService.getFormId(), error)
      throw error
    } finally {
      formService.setIsLoading(false)
    }
  }

import { TmBaseError } from './tmBaseError'

/**
 * Throws an error when created.
 *
 * Should be used to check exhaustiveness of switch constructions and in other similar cases.
 */
export default class TmAbsurdError extends TmBaseError {
  constructor(value: never) {
    super(`Unreachable statement: ${JSON.stringify(value)}`)
  }
}

import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import ForwardAudio from '@/data/models/domain/ForwardAudio'
import DomainBaseService from '@/services/domain/domainBaseService'
import type ForwardAudioRepository from '@/data/repositories/domain/forwardAudioRepository'

@DomainSettings({
  model: ForwardAudio,
})
@injectable()
export default class ForwardAudioService extends DomainBaseService<ForwardAudioRepository> {
  public async uploadFile(file: File) {
    return this.getDomainRepository().uploadFile(file)
  }

  public downloadFile(audioId: string) {
    return this.getDomainRepository().downloadFile(audioId)
  }
}

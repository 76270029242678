import { cloneDeep, debounce, isEmpty, isEqual, pick, set } from 'lodash-es'
import {
  shallowRef,
  computed,
  onMounted,
  onUnmounted,
  ref,
  watch,
  onBeforeUnmount,
} from '@/composition/vue/compositionApi'
import type {
  FormHook,
  FormHookType,
  FormState,
  FormSubmitStrategy,
  SelectOptionWithIcon,
  TpFormData,
} from '@/services/forms/types'
import {
  getFormFactoryManager,
  getFormManager,
  getFormValidationService,
  getLoggerService,
  getNotificationService,
  getOrderService,
  getTableManager,
  getTranslateService,
  getVisibilityService,
} from '@/core/container/ioc'
import type { ComputedRef, Ref, UnwrapRef } from '@/composition/vue/compositionApi'
import type BaseFormService from '@/services/forms/baseFormService'
import type { PartialUIConfig, PartialUIConfigItem, WrapperParams } from '@/services/wrappers/types'
import { TmFormError } from '@/core/error/tmFormError'
import { TmFormValidationError } from '@/core/error/tmFormValidationError'
import { TmNonExistFormError } from '@/core/error/tmNonExistFormError'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import type { TmWrappers } from '@/wrappers'
import { useBulkWrapper, useWrapper } from '@/composition/modal'
import { TmBaseError } from '@/core/error/tmBaseError'
import type { UsePartial, UseWrapper } from '@/composition/types'
import { DiscardingStrategy } from '@/composition/types'
import type BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import type { BulkBaseBody, BulkSchemaItem } from '@/services/bulk/types'
import type { PaginationUrlFilterType } from '@/services/tables/types'
import { TmApiClientError } from '@/core/error/transport/tmApiClientError'
import { createPartial, usePartial } from '@/composition/partial'
import type { ModalFormQueryParams, TranslationKey } from '@/services/types'
import type BaseBulkFormService from '@/services/forms/BaseBulkFormService'
import type BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import type { FormPartialableInterface } from '@/decorators/types'
import type { ParamsPartial, Dict, GetFormSubmitConfigType } from '@/types'
import type OrderService from '@/services/orderService'
import type VisibilityService from '@/services/visibilityService'
import type Visibility from '@/data/models/Visibility'
import type BaseModel from '@/data/models/BaseModel'
import { wait } from '@/utils/async'
import { useOrder } from '@/composition/order'
import { makeGeneralSubmit } from '@/composition/formStrategy/makeGeneralSubmit'
import type { FormDisabled, FormInputRef, FormLeftLabel, FormLeftLabelWidth } from '@/components/forms/types'
import {
  formDisabledSymbol,
  formInputRef,
  formLeftLabelSymbol,
  formLeftLabelWidthSymbol,
} from '@/components/forms/types'
import { useProvideInject } from '@/composition/provideInject'
import { type ItemValidationPayload, itemValidationRuleName } from '@/services/validation/subValidationService'
import type { ValidationRuleError, ValidationRuleErrorWithRuleName } from '@/services/validation/types'
import type { Forms } from '@/services/forms/formTypes'
import { TmApiValidationError } from '@/core/error/transport/tmApiValidationError'
import type { InternalErrorResponse, TmAxiosResponse } from '@/services/transport/types'
import { TmIncorrectContractError } from '@/core/error/tmIncorrectContractError'
import { TmApiRetryAfterError } from '@/core/error/transport/tmApiRetryAfterError'
import type { RegisteredServices } from '@/core/container/types'

export type UseFormDataSubmit<T extends BaseFormService<FormBuilderInterface>> = {
  (): Promise<ReturnType<T['submit']>>
  (strategy: undefined, config: GetFormSubmitConfigType<T>): Promise<ReturnType<T['submit']>>
  (strategy: FormSubmitStrategy<T>): Promise<unknown>
  (strategy: FormSubmitStrategy<T>, config: GetFormSubmitConfigType<T>): Promise<unknown>
}

export type UseFormData<T extends BaseFormService<FormBuilderInterface>> = {
  getFieldId(fieldName: string, parentName?: string): string
  getNameById(fieldId: string): string
  setFocusedField(fieldId: string): void
  setHook(hookName: FormHookType, hook: FormHook): void
  formService: T
  formId: Forms
  submit: UseFormDataSubmit<T>
  validate: () => Promise<boolean>
  formState: ComputedRef<FormState>
  discard: (strategy: DiscardingStrategy) => void
  onFormLoaded: (callback: () => void | Promise<void>) => void
}

type BaseWrapperFormTypeSubmit<T extends BaseFormService<FormBuilderInterface>> = {
  (shouldCloseModal?: boolean): Promise<ReturnType<T['submit']>>
  (shouldCloseModal?: boolean, strategy?: FormSubmitStrategy<T>): Promise<unknown>
}

type BaseWrapperFormTypeSubmitAndCreateAnother<T extends BaseFormService<FormBuilderInterface>> = {
  (): Promise<ReturnType<T['submit']>>
  (strategy?: FormSubmitStrategy<T>): Promise<unknown>
}

type BaseWrapperFormType<T extends BaseFormService<FormBuilderInterface>> = {
  formState: ComputedRef<FormState>
  formService: T
  // isEditing: Ref<string>;
  // editingId: Ref<string>;
  buttonClicked: Ref<string>
  submit: BaseWrapperFormTypeSubmit<T>
  submitAndCreateAnother: BaseWrapperFormTypeSubmitAndCreateAnother<T>
}
type CreateWrapperFormType<P extends WrapperParams, T extends BaseFormService<FormBuilderInterface>> = {
  // wrapperId: TmWrappers;
  // wrapperParams: P;
  formId: Forms
  formState: ComputedRef<FormState>
  discard: (strategy: DiscardingStrategy) => void
  wrapperParamsPatcher?: (params: Partial<P>) => void
} & BaseWrapperFormType<T> &
  UseWrapper<P, BaseWrapper>
type FormHooks = {
  onMounted?: () => any
  onUnmounted?: () => any
  onAfterSuccessBuild?: () => any
  onAfterPopulate?: () => any
}
export type FormSettings<T extends WrapperParams> = {
  shouldDestroy?: boolean
  withoutHooks?: boolean
  shouldClear?: boolean

  /**
   * Do not call init if it was already inited.
   * Might be usefull in case if you have one form instance used in modal and on the page at the same time.
   */
  keepInitForm?: boolean
  params?: T
  hooks?: FormHooks
}
type UsePartialFormData<
  T extends BaseFormService<FormBuilderInterface, P> & FormPartialableInterface<P>,
  P extends ParamsPartial,
> = UseFormData<T> &
  UsePartial<P> & {
    onRemoveField: (name: string) => void
    onRemoveAllFields: () => void
    buildPartial: (name: string) => void
    onReplaceField: (oldName: string, name: string) => void
    onSelectField: (name: string) => void
    onSelectAllFields: () => void
    partial: PartialUIConfig<P>
    selectedFields: () => SelectOptionWithIcon[]
    nonSelectedFields: () => SelectOptionWithIcon[]
    enforceIsInitiallyOpen: (items: Array<PartialUIConfigItem<P>>) => void
    fields: () => SelectOptionWithIcon[]
  }
type UseInlinePartialFormData<
  T extends BaseFormService<FormBuilderInterface, P> & FormPartialableInterface<P>,
  P extends ParamsPartial,
> = UsePartialFormData<T, P> & {
  order: ComputedRef<string[]>
  onReorder: (order: string[]) => void
}
const defaultFormSettings: FormSettings<WrapperParams> = {
  shouldDestroy: true,
  withoutHooks: false,
  shouldClear: true,
}

export const createForm = <T extends BaseFormService<FormBuilderInterface>>(
  formId: Forms,
  formSettings = defaultFormSettings,
): UseFormData<T> => {
  getLoggerService().log('forms', 'createForm with params', formId)
  getLoggerService().raw('forms', formSettings)
  const formService = getFormManager().getForm<T>(formId)

  if (formSettings && formSettings.keepInitForm && formService.getBuilder().isInit()) {
    getLoggerService().log('forms', 'Keep inited')
  } else {
    formService.initForm()
  }

  const { submit, discard, formState } = useForm<T>(formId)

  if (!formSettings.withoutHooks) {
    onMounted(async () => {
      if (formSettings.hooks && formSettings.hooks.onMounted) {
        return formSettings.hooks.onMounted()
      }
      await formService.doBuild()
      formSettings.hooks?.onAfterSuccessBuild?.()
      return undefined
    })

    onUnmounted(() => {
      if (formSettings.hooks && formSettings.hooks.onUnmounted) {
        return formSettings.hooks.onUnmounted()
      }
      if (formSettings.shouldDestroy) {
        formService.destroy()
      }
      return undefined
    })
  }

  return {
    ...useForm(formId),
    setHook: formService.setHook.bind(formService),
    formService,
    submit,
    discard,
    formState,
  }
}

export const createPartialBuildlessForm = <T extends BaseFormService<FormBuilderInterface>>(
  formId: Forms,
  formSettings = defaultFormSettings,
): UsePartialFormData<T, ParamsPartial> => {
  const { formService } = createForm<T>(formId, formSettings)
  createPartial(formService.getPartial())
  const usedPartialForm = usePartialForm(formId)
  usedPartialForm.enforceIsInitiallyOpen(usedPartialForm.partial.items)
  return usePartialBuildlessForm(formId)
}

export const usePartialBuildlessForm = <
  T extends BaseFormService<FormBuilderInterface, P> & FormPartialableInterface<P>,
  P extends ParamsPartial,
>(
  formId: Forms,
): UsePartialFormData<T, P> => {
  const translateService = getTranslateService()
  const usedForm = useForm<T>(formId)
  const partial = usedForm.formService.getPartial()
  const { open, openAll, closeAll, openedInGroup, close, slots, destroy, isOpened } = usePartial<P, PartialUIConfig<P>>(
    partial,
  )
  const toSelectOption =
    (filter: (openedInGroup: Record<string, boolean>, field: { name: string }) => boolean) =>
    (): SelectOptionWithIcon[] =>
      partial.items
        .filter((field) => filter(openedInGroup(), field))
        .map((field) => ({
          text: translateService.t(`partialForm.fields.${field.name}` as TranslationKey),
          value: field.name,
          icon: field.icon || 'tmi-text-box',
        }))
  const selectedFields = toSelectOption((group, field) => group[field.name])
  const nonSelectedFields = toSelectOption((group, field) => !group[field.name])
  const fields = toSelectOption(() => true)
  const onSelectField = (name: string) => {
    open(name)
  }
  const onSelectAllFields = () => {
    openAll()
  }
  const onRemoveField = (name: string) => {
    close(name)
  }
  const onRemoveAllFields = () => {
    closeAll()
  }
  const onReplaceField = (oldName: string, name: string) => {
    close(oldName)
    open(name)
  }

  const enforceIsInitiallyOpen = <PP extends ParamsPartial>(items: PartialUIConfigItem<PP>[]) => {
    items.forEach((item) => {
      if (item.isInitiallyOpen) {
        onSelectField(item.name)
      }
    })
  }

  return {
    ...usedForm,
    onRemoveField,
    onRemoveAllFields,
    onSelectField,
    onSelectAllFields,
    onReplaceField,
    slots,
    selectedFields,
    fields,
    nonSelectedFields,
    partial,
    open,
    openAll,
    close,
    closeAll,
    destroy,
    isOpened,
    openedInGroup,
    enforceIsInitiallyOpen,
    buildPartial: () => {},
  }
}

export const useInlinePartialFrom = <
  T extends BaseFormService<FormBuilderInterface, P> & FormPartialableInterface<P>,
  P extends ParamsPartial,
>(
  formId: Forms,
): UseInlinePartialFormData<T, P> => {
  const orderService = getOrderService() as OrderService
  const visibilityService = getVisibilityService() as VisibilityService
  const {
    fields,
    onRemoveField,
    onRemoveAllFields,
    onSelectField,
    onSelectAllFields,
    ...data
  }: UsePartialFormData<T, P> = usePartialBuildlessForm(formId)

  const getOrderedFields = () => {
    const order = orderService.getOrder(formId)?.order || []
    const fieldsMap = fields().reduce((acc: Record<string, SelectOptionWithIcon>, field) => {
      acc[field.value] = field
      return acc
    }, {})

    const orderedFields: SelectOptionWithIcon[] = []
    for (const fieldName of order) {
      if (fieldsMap[fieldName]) {
        orderedFields.push(fieldsMap[fieldName])
        delete fieldsMap[fieldName]
      }
    }

    return orderedFields.concat(Object.values(fieldsMap))
  }

  const order = computed(() => getOrderedFields().map((field) => field.value))

  const withVisibilitySync =
    <A extends any[], B>(func: (...args: A) => B) =>
    (...args: A): B => {
      const res = func(...args)

      const newVisibility: Visibility['visibility'] = {}
      data.selectedFields().forEach((field) => {
        newVisibility[field.value] = true
      })
      data.nonSelectedFields().forEach((field) => {
        newVisibility[field.value] = false
      })
      visibilityService.setVisibility(formId, newVisibility)

      return res
    }

  return {
    ...data,
    onRemoveField: withVisibilitySync(onRemoveField),
    onRemoveAllFields: withVisibilitySync(onRemoveAllFields),
    onSelectField: withVisibilitySync(onSelectField),
    onSelectAllFields: withVisibilitySync(onSelectAllFields),
    fields: getOrderedFields,
    order,
    onReorder: (newOrder: string[]) => orderService.setOrder(formId, newOrder),
  }
}

export const usePartialForm = <
  T extends BaseFormService<FormBuilderInterface> & FormPartialableInterface<P>,
  P extends ParamsPartial,
>(
  formId: Forms,
): UsePartialFormData<T, P> => {
  const partialRow = usePartialBuildlessForm<T, P>(formId)
  const onSelectField = (name: string) => {
    buildPartial(name)
    partialRow.onSelectField(name)
  }
  const buildPartial = (name: string) => partialRow.formService.buildField(name)
  const unbuildPartial = (name: string) => partialRow.formService.removeField(name)
  const onRemoveField = (name: string) => {
    partialRow.onRemoveField(name)
    unbuildPartial(name)
  }
  const onReplaceField = (oldName: string, newName: string) => {
    onSelectField(newName)
    onRemoveField(oldName)
  }
  const onSelectAllFields = () => {
    partialRow.fields().forEach((f) => onSelectField(f.value))
    partialRow.onSelectAllFields()
  }
  const enforceIsInitiallyOpen = (items: PartialUIConfigItem<P>[]) => {
    items.forEach((item) => {
      if (item.isInitiallyOpen) {
        onSelectField(item.name)
      }
    })
  }

  return {
    ...partialRow,
    formService: partialRow.formService,
    buildPartial,
    onRemoveField,
    onReplaceField,
    onSelectAllFields,
    onSelectField,
    enforceIsInitiallyOpen,
  }
}

export const createPartialForm = <
  T extends BaseFormService<FormBuilderInterface> & FormPartialableInterface<P>,
  P extends ParamsPartial,
>(
  formId: Forms,
  formSettings = defaultFormSettings,
): UsePartialFormData<T, P> => {
  createPartialBuildlessForm<T>(formId, formSettings)
  return usePartialForm(formId)
}

export const createPartialWrapperForm = <
  P extends ParamsPartial = ParamsPartial,
  W extends WrapperParams = WrapperParams,
  T extends BaseFormService<FormBuilderInterface> &
    FormPartialableInterface<P> = BaseFormService<FormBuilderInterface> & FormPartialableInterface<P>,
>(
  formId: Forms,
  wrapperId: TmWrappers,
  formSettings?: FormSettings<W>,
) => {
  const created = createWrapperForm<W>(formId, wrapperId, formSettings)
  createPartial(created.formService.getPartial())
  const {
    nonSelectedFields,
    selectedFields,
    partial,
    onSelectField,
    onReplaceField,
    onRemoveField,
    slots,
    fields,
    openedInGroup,
    setHook,
    getNameById,
    getFieldId,
    enforceIsInitiallyOpen,
  } = usePartialForm<T, P>(created.formId)
  enforceIsInitiallyOpen(partial.items)
  return {
    ...created,
    setHook,
    getNameById,
    getFieldId,
    selectedFields,
    nonSelectedFields,
    partial,
    slots,
    fields,
    openedInGroup,
    onSelectField,
    onReplaceField,
    onRemoveField,
  }
}

export const createPartialEditableWrapperForm = <
  P extends ParamsPartial = ParamsPartial,
  W extends WrapperParams = WrapperParams,
  T extends BaseFormService<FormBuilderInterface> &
    FormPartialableInterface<P> = BaseFormService<FormBuilderInterface> & FormPartialableInterface<P>,
>(
  formId: Forms,
  wrapperId: TmWrappers,
  formSettings?: FormSettings<W>,
  isUsePartialFormBuildless = true,
) => {
  const created = createEditableWrapperForm<W>(formId, wrapperId, formSettings)
  createPartial(created.formService.getPartial())
  const { submit, ...restPartialProps } = isUsePartialFormBuildless
    ? usePartialBuildlessForm<T, P>(created.formId)
    : usePartialForm<T, P>(created.formId)
  restPartialProps.enforceIsInitiallyOpen(restPartialProps.partial.items)
  return {
    ...created,
    ...restPartialProps,
  }
}

export const useBulkForm = <T extends BaseBulkFormService<BulkBaseBody, FormBuilderInterface>>(formId: Forms) => {
  const {
    formService,
    onSelectField,
    onReplaceField,
    onRemoveField,
    nonSelectedFields,
    selectedFields,
    fields,
    setFocusedField,
    slots,
  } = usePartialForm<T, BulkSchemaItem>(formId)
  const { addToOrder, removeFromOrder, replaceInOrder, order } = useOrder(formId)

  const bulkSchema = useBulkSchema<T>(formId)

  const onSelectFieldLocal = async (name: string) => {
    setFocusedField(name)
    await wait(0)
    onSelectField(name)
  }

  const onRemoveFieldWithOrderPreserve = (name: string) => {
    removeFromOrder(name)
    onRemoveField(name)
  }

  const onSelectFieldWithOrderPreserve = async (name: string) => {
    addToOrder(name)
    await onSelectFieldLocal(name)
  }

  const onReplaceFieldWithOrderPreserve = (oldName: string, name: string) => {
    replaceInOrder(oldName, name)
    onReplaceField(oldName, name)
  }

  return {
    bulkSchema,
    fields,
    formService,
    onRemoveField,
    onSelectField: onSelectFieldLocal,
    onReplaceField,
    onRemoveFieldWithOrderPreserve,
    onSelectFieldWithOrderPreserve,
    onReplaceFieldWithOrderPreserve,
    selectedFields,
    nonSelectedFields,
    slots,
    order,
  }
}

export const useForm = <T extends BaseFormService<FormBuilderInterface>>(formId: Forms): UseFormData<T> => {
  const formValidationService = getFormValidationService()

  const formService = getFormManager().getForm(formId)
  if (!formService.isInit()) {
    throw new TmNonExistFormError('Trying to use form before created')
  }

  const { addOnLoadCallback } = useFormOnLoad(formService)

  return {
    getFieldId(fieldName: string, parentName = '') {
      return formService.getBuilder().getFieldId(fieldName, parentName)
    },
    getNameById(fieldId: string) {
      return formService.getBuilder().getNameById(fieldId)
    },
    setFocusedField(name: string) {
      formService.setFocusedField(name)
    },
    formId,
    setHook: formService.setHook.bind(formService),
    formService: formService as T,
    submit: submit(formService) as any,
    validate: () => formValidationService.validateForm(formId),
    discard: discard(formService as any),
    formState: computed(() => formService.getFormState()),
    onFormLoaded: addOnLoadCallback,
  }
}

export const useFormOnLoad = (formService: BaseFormService<FormBuilderInterface>) => {
  let callbacks: (() => void | Promise<void>)[] = []

  const addOnLoadCallback = async (callback: () => void | Promise<void>) => {
    if (formState.value.isFormLoading !== false) callbacks.push(callback)
    else await callback()
  }

  const formState = computed(() => formService.getFormEntity() && formService.getFormState())

  watch(
    () => formState.value?.isFormLoading,
    (isFormLoading) => {
      if (isFormLoading !== false) return
      callbacks.forEach((callback) => callback())
      callbacks = []
    },
  )

  return {
    addOnLoadCallback,
  }
}

export const createBulkWrappedForm = <T extends BaseBulkFormService<BulkBaseBody, FormBuilderInterface>>(
  formId: Forms,
  wrapperId: TmWrappers,
  formSettings = defaultFormSettings,
) => {
  const { wrapperParams } = useBulkWrapper(wrapperId)
  const { partial } = createPartialForm<T, BulkSchemaItem>(formId, formSettings)
  const { formService, bulkSubmit, selectedFields, nonSelectedFields, bulkSchema } = useBulkWrappedForm(
    formId,
    wrapperId,
  )
  if (wrapperParams.value.selectedIds) {
    formService.setEditingId(wrapperParams.value.isAllSelected ? 'all' : wrapperParams.value.selectedIds.join(','))
  }
  const isInitiallyOpenFieldNames = partial.items.filter((item) => item.isInitiallyOpen).map((item) => item.name)
  useOrder(formId).setOrder(isInitiallyOpenFieldNames)

  return {
    selectedFields,
    nonSelectedFields,
    bulkSchema,
    bulkSubmit,
    wrapperId,
    formService,
    ...partial,
  }
}

export const useBulkSchema = <T extends BaseBulkFormService<BulkBaseBody, FormBuilderInterface>>(
  formId: Forms,
): PartialUIConfig<BulkSchemaItem> => {
  const formService = getFormManager().getForm<T>(formId)
  return formService.getPartial()
}
export const useBulkWrappedForm = <T extends BaseBulkFormService<BulkBaseBody, FormBuilderInterface>>(
  formId: Forms,
  wrapperId: TmWrappers,
) => {
  checkWrapperIdVSFormId(formId, wrapperId)
  const {
    formService,
    onSelectField,
    onReplaceField,
    onRemoveField,
    nonSelectedFields,
    selectedFields,
    fields,
    setFocusedField,
    slots,
  } = usePartialForm<T, BulkSchemaItem>(formId)
  const { close, wrapperParams } = useBulkWrapper(wrapperId)
  const { addToOrder, removeFromOrder, replaceInOrder, order } = useOrder(formId)

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const bulkSubmit_ = async () => {
    const baseTable = getTableManager().getTable(wrapperParams.value.tableId)
    if (!baseTable) {
      throw new TmFormError(`There is no table with id ${wrapperParams.value.tableId}`)
    }
    try {
      await bulkSubmit(
        formService,
        wrapperParams.value.selectedIds,
        wrapperParams.value.isAllSelected,
        wrapperParams.value.filters,
      )
      close()
    } catch (error) {
      if (error instanceof TmBaseError) {
        getLoggerService().log('error', error.message, 'formBulk')
      }
      throw error
    }
  }
  const bulkSchema = useBulkSchema<T>(formId)

  const onSelectFieldLocal = async (name: string) => {
    setFocusedField(name)
    await wait(0)
    onSelectField(name)
  }

  const onRemoveFieldWithOrderPreserve = (name: string) => {
    removeFromOrder(name)
    onRemoveField(name)
  }

  const onSelectFieldWithOrderPreserve = async (name: string) => {
    addToOrder(name)
    await onSelectFieldLocal(name)
  }

  const onReplaceFieldWithOrderPreserve = (oldName: string, name: string) => {
    replaceInOrder(oldName, name)
    onReplaceField(oldName, name)
  }

  return {
    bulkSubmit: bulkSubmit_,
    bulkSchema,
    wrapperId,
    fields,
    formService,
    onRemoveField,
    onSelectField: onSelectFieldLocal,
    onReplaceField,
    onRemoveFieldWithOrderPreserve,
    onSelectFieldWithOrderPreserve,
    onReplaceFieldWithOrderPreserve,
    selectedFields,
    nonSelectedFields,
    slots,
    order,
  }
}

export const submit =
  <T extends BaseFormService<FormBuilderInterface>>(formService: T) =>
  (strategy: FormSubmitStrategy<T> = makeGeneralSubmit(), config: GetFormSubmitConfigType<T> = undefined) =>
    strategy(formService, config)

export const discard =
  (formService: BaseFormService<FormBuilderInterface>) =>
  (strategy = DiscardingStrategy.POPULATE) => {
    if (strategy === DiscardingStrategy.SET_TO_INITIAL || strategy === DiscardingStrategy.BOTH) {
      formService.discard()
    }

    if (strategy === DiscardingStrategy.POPULATE || strategy === DiscardingStrategy.BOTH) {
      formService.populateFromEntityId(formService.getEditingId())
    }
  }

const bulkSubmit = async (
  formService: BaseBulkFormService<BulkBaseBody, FormBuilderInterface>,
  ids: string[],
  isAll: boolean,
  filters: PaginationUrlFilterType,
) => {
  const formId = formService.getFormId()
  const formValidationService = getFormValidationService()
  formValidationService.resetFormValidator(formService.getFormId())
  formService.clearFormErrorMessage()
  const formData = formService.getFormData('object')

  formService.setIsLoading(true)
  const isValid = await formValidationService.validateForm(formId)
  if (isValid) {
    if (!formService.bulkSubmit) {
      throw new TmFormError(`Please implement \`submit\` method for ${formId}`)
    }
    try {
      const result = await formService.bulkSubmit(formData, ids, isAll, filters)
      formService.setIsFormSent(true)
      return result
    } catch (error) {
      processServerError(formService.getFormId(), error)
      throw error
    } finally {
      formService.setIsLoading(false)
    }
  } else {
    formService.setIsLoading(false)
    throw new TmFormValidationError('Invalid form')
  }
}

export const openWrapperFormWithTabs = <T extends ModalFormQueryParams = ModalFormQueryParams>(
  wrapperId: TmWrappers,
  tabKey: keyof T,
  tabValue: string,
  modalParams?: T,
) => useWrapper<T>(wrapperId).open({ ...(modalParams || ({} as T)), [tabKey]: tabValue })

export const processServerError = (formId: Forms, error: unknown) => {
  const formValidationService = getFormValidationService()
  if (error instanceof TmApiClientError) {
    const errors = error.getErrors()
    if (errors) {
      const { formService } = useForm(formId)
      formValidationService.setServerErrors(formService.serverErrorSerializer(errors), formId)
    }
  }
}

/*
  This masterpiece of code was created in CAR-5642 to resolve problems we created in CAR-4876 (We throw errors if the
  server sends extra fields in error message. Should we do that? Who knows?) If somebody will decide to sever this knot,
  just replace this method with processServerError
 */
export const processServerErrorByFieldNames = (formId: Forms, serverErrors: unknown, fieldNames: string[] = []) => {
  try {
    if (serverErrors instanceof TmApiValidationError) {
      const copiedFieldErrors = cloneDeep(serverErrors.getErrors()?.errors?.fields)
      const filteredErrors = pick(copiedFieldErrors, fieldNames)
      const newServerErrors = new TmApiValidationError(
        serverErrors.message,
        pick(serverErrors, ['status', 'headers', 'data']) as TmAxiosResponse<InternalErrorResponse>,
      )
      const updatedNewServerErrors = set(newServerErrors, 'errors.errors.fields', filteredErrors)
      processServerError(formId, updatedNewServerErrors)
      return
    }

    processServerError(formId, serverErrors)
  } catch (error) {
    if (error instanceof TmIncorrectContractError) {
      return
    }

    throw error
  }
}

export const createWrapperForm = <
  R extends WrapperParams = WrapperParams,
  T extends BaseFormService<FormBuilderInterface> = BaseFormService<FormBuilderInterface>,
>(
  formId: Forms,
  wrapperId: TmWrappers,
  formSettings?: FormSettings<R>,
): CreateWrapperFormType<R, T> => {
  createForm<T>(formId, {
    ...formSettings,
    hooks: {
      onMounted: async () => {
        const { formService } = useForm(formId)
        await formService.doBuild()

        return formSettings?.hooks?.onMounted?.()
      },
    },
  })
  return useWrapperForm(formId, wrapperId)
}

const createSubmitModalCallback = <T extends BaseFormService<FormBuilderInterface>>(
  formId: Forms,
  wrapperId: TmWrappers,
  shouldClear = true,
  byModel?: typeof BaseModel,
) => {
  const { formService } = useForm<T>(formId)
  const buttonClicked = ref('')
  const { close } = useWrapper(wrapperId)
  const closeWrapper = () => {
    close()
  }
  const submitModal = async (
    shouldCloseModal = true,
    strategy: FormSubmitStrategy<T> = makeGeneralSubmit(shouldClear, byModel),
  ) => {
    buttonClicked.value = shouldCloseModal ? 'save' : 'saveAndCreate'
    try {
      const result = await submit(formService)(strategy)
      if (shouldCloseModal) {
        closeWrapper()
      }

      return result
    } catch (error) {
      if (error instanceof TmBaseError) {
        getLoggerService().log('error', error.message, 'forms')
      }
      throw error
    }
  }

  return {
    submitModal,
    buttonClicked,
  }
}

export const useWrapperForm = <
  T extends BaseFormService<FormBuilderInterface>,
  P extends WrapperParams = WrapperParams,
>(
  formId: Forms,
  wrapperId: TmWrappers,
  shouldClear = true,
): CreateWrapperFormType<P, T> => {
  checkWrapperIdVSFormId(formId, wrapperId)
  const { formService } = useForm<T>(formId)
  const { patchParams } = useWrapper(wrapperId)
  const { submitModal, buttonClicked } = createSubmitModalCallback<T>(formId, wrapperId, shouldClear)
  const submitAndCreateAnother = async (strategy: FormSubmitStrategy<T> = makeGeneralSubmit(shouldClear)) => {
    const result = await submitModal(false, strategy)
    discard(formService)(DiscardingStrategy.SET_TO_INITIAL)
    return result
  }
  return {
    ...useForm<T>(formId),
    buttonClicked,
    submit: submitModal as any,
    submitAndCreateAnother: submitAndCreateAnother as any,
    wrapperParamsPatcher: patchParams,
    ...useWrapper<P>(wrapperId),
  }
}

export const createEditableForm = <
  T extends BaseFormService<FormBuilderInterface>,
  R extends WrapperParams = WrapperParams,
>(
  formId: Forms,
  formSettings?: FormSettings<R>,
) => {
  createForm<T>(formId, {
    ...formSettings,
    hooks: {
      onMounted: async () => {
        if (formSettings?.hooks?.onMounted) {
          return formSettings.hooks.onMounted()
        }
        const { formService } = useForm(formId)
        formService.setEditingId(formSettings?.params?.editingId)
        await formService.doBuild()
        formSettings?.hooks?.onAfterSuccessBuild?.()
        if (formSettings?.params?.editingId) {
          await useEditableForm(formId).populate(formSettings?.params?.editingId)
          if (formSettings?.hooks?.onAfterPopulate) {
            formSettings?.hooks?.onAfterPopulate()
          }
        }
        return undefined
      },
    },
  })
  return {
    ...useEditableForm<T>(formId),
  }
}

export const createEditableFormByService = <
  R extends WrapperParams = WrapperParams,
  T extends BaseFormService<FormBuilderInterface> = BaseFormService<FormBuilderInterface>,
>(
  formId: Forms,
  formSettings?: FormSettings<R>,
) => createEditableForm<T, R>(formId, formSettings)

export const useEditableForm = <T extends BaseFormService<FormBuilderInterface>>(formId: Forms) => {
  const { formService, formState } = useForm<T>(formId)
  const populate = async (editingId: string) => {
    if (!editingId) {
      return
    }
    await formService.populateFromEntityId(editingId)
    formService.setEditingId(editingId)
  }

  return {
    ...useForm<T>(formId),
    editingId: computed(() => formState.value.editingId),
    isEditing: computed(() => !!formState.value.editingId),
    populate,
  }
}

export const useEditableFormByService = <
  T extends BaseFormService<FormBuilderInterface> = BaseFormService<FormBuilderInterface>,
>(
  formId: Forms,
) => {
  return {
    ...useEditableForm<T>(formId),
  }
}

export const createEditableWrapperForm = <
  R extends WrapperParams = WrapperParams,
  T extends BaseFormService<FormBuilderInterface> = BaseFormService<FormBuilderInterface>,
>(
  formId: Forms,
  wrapperId: TmWrappers,
  formSettings?: FormSettings<R>,
) => {
  checkWrapperIdVSFormId(formId, wrapperId)
  createEditableFormByService(formId, {
    ...formSettings,
    hooks: {
      onMounted: async () => {
        const wrapperEditingId = wrapperParams.value.editingId
        if (formSettings && formSettings.params) {
          setParams(formSettings.params)
        }

        if (wrapperEditingId) {
          formService.setEditingId(wrapperEditingId)
        }

        formSettings?.hooks?.onMounted?.()

        await formService.doBuild()
        formSettings?.hooks?.onAfterSuccessBuild?.()

        if (wrapperEditingId) {
          populate(wrapperEditingId)
        }

        watch(
          () => wrapperParams.value,
          (newValue, oldValue) => {
            if (newValue.editingId !== oldValue.editingId) {
              populate(wrapperParams.value.editingId)
            }
          },
        )
      },

      onUnmounted: () => {
        if (formSettings?.hooks?.onUnmounted) {
          formSettings.hooks.onUnmounted()
        }

        if (!formSettings || (formSettings && formSettings.shouldDestroy)) {
          formService.destroy()
        }
      },
    },
  })
  const { populate, formService } = useEditableFormByService(formId)
  const { wrapperParams, setParams } = useEditableWrapperForm<R, T>(formId, wrapperId)

  return {
    ...useEditableWrapperForm<R, T>(formId, wrapperId),
    wrapperParams,
  }
}

export const useEditableWrapperForm = <
  R extends WrapperParams = WrapperParams,
  T extends BaseFormService<FormBuilderInterface> = BaseFormService<FormBuilderInterface>,
>(
  formId: Forms,
  wrapperId: TmWrappers,
) => ({
  ...useEditableFormByService(formId),
  ...useWrapperForm<T, R>(formId, wrapperId),
})

export const createInlineEditableForm = <
  R extends WrapperParams = WrapperParams,
  T extends BaseFormService<FormBuilderInterface> = BaseFormService<FormBuilderInterface>,
>(
  formId: Forms,
  formSettings?: FormSettings<R>,
  submitSuccessCallback?: (field: BaseFieldModel) => void,
  submitErrorCallback?: (field: BaseFieldModel, error: unknown) => void,
) => {
  const form = createEditableFormByService<R, T>(formId, formSettings)

  form.setHook('change', async (field) => {
    form.formService.setFieldLoading(field.name, true)
    try {
      await form.submit(makeGeneralSubmit(false))
      if (submitSuccessCallback && typeof submitSuccessCallback === 'function') {
        submitSuccessCallback(field)
      }
    } catch (e) {
      if (submitErrorCallback && typeof submitErrorCallback === 'function') {
        submitErrorCallback(field, e)
      }
    } finally {
      form.formService.setFieldLoading(field.name, false)
    }
  })

  return form
}

export const createInlineEditablePartialForm = <
  R extends WrapperParams = WrapperParams,
  T extends BaseFormService<FormBuilderInterface> = BaseFormService<FormBuilderInterface>,
>(
  formId: Forms,
  formSettings?: FormSettings<R>,
  submitSuccessCallback?: (field: BaseFieldModel) => void,
  submitErrorCallback?: (field: BaseFieldModel, error: unknown) => void,
) => {
  const form = createInlineEditableForm<R, T>(formId, formSettings, submitSuccessCallback, submitErrorCallback)

  // close hidden fields
  const visibilityService = getVisibilityService() as VisibilityService
  const partialData = createPartial(form.formService.getPartial())
  const visibility = visibilityService.getVisibility(formId)?.visibility || {}
  Object.entries(visibility).forEach(([field, isVisible]) => {
    if (isVisible === false && partialData.slots.find((slot) => slot.canonicalName === field)) {
      partialData.close(field)
    }
  })

  return form
}

export const createEditableWrapperFormForModel = <
  R extends WrapperParams = WrapperParams,
  T extends BaseFormService<FormBuilderInterface> = BaseFormService<FormBuilderInterface>,
>(
  formId: Forms,
  wrapperId: TmWrappers,
  model: typeof BaseModel,
  formSettings?: FormSettings<R>,
) => {
  createEditableWrapperForm<R, T>(formId, wrapperId, formSettings)
  return useEditableWrapperFormForModel<R, T>(formId, wrapperId, model, formSettings)
}

export const useEditableWrapperFormForModel = <
  R extends WrapperParams = WrapperParams,
  T extends BaseFormService<FormBuilderInterface> = BaseFormService<FormBuilderInterface>,
>(
  formId: Forms,
  wrapperId: TmWrappers,
  model?: typeof BaseModel,
  formSettings?: FormSettings<R>,
) => {
  const form = useEditableWrapperForm<R, T>(formId, wrapperId)
  const { submitModal, buttonClicked } = createSubmitModalCallback<T>(
    formId,
    wrapperId,
    formSettings?.shouldClear,
    model,
  )

  return {
    ...form,
    submit: submitModal,
    buttonClicked,
  }
}

export const handleFormError = (e: unknown) => {
  const notificationService = getNotificationService()
  notificationService.notifyFromError(e)
}

export const handleFormRetryError = (e: unknown) => {
  if (!(e instanceof TmApiRetryAfterError)) return false

  const translateService = getTranslateService()
  const notificationService = getNotificationService()

  const message = translateService.t('errors.tooManyRequests')
  notificationService.notifyError(message)
  return true
}

export const checkWrapperIdVSFormId = (formId: Forms, wrapperId: TmWrappers) => {
  // eslint-disable-next-line
  // @ts-ignore
  if (formId === wrapperId) {
    throw new TmFormError('Shouldnt use formId === wrapperId. It will break: bulk, <I_trust_that_new_cases>')
  }
}

/**
 * Use this sort of dirty hacks to prevent form shifting.
 * after validation. so this timeout gives a time to click event to be handled just before validation happens
 * @param callback
 */
export const useFieldBlur = (callback: (e: FocusEvent) => void) => (e: FocusEvent) => {
  setTimeout(() => {
    callback(e)
  }, 200)
}

type CreateFormResponse<T extends BaseFormService<FormBuilderInterface>, R extends WrapperParams> = ReturnType<
  typeof createEditableWrapperForm<R, T>
> & {
  wrapperId: TmWrappers
  formId: Forms
  params: R
  getParams(): R
}

interface UseWrapperWithFormResultBase {
  wrapperId: TmWrappers
  close: () => void
}

export interface UseWrapperWithFormResultWithParams<
  T extends BaseFormService<FormBuilderInterface>,
  R extends WrapperParams,
> extends UseWrapperWithFormResultBase {
  create: (settings?: { hooks?: FormHooks }) => CreateFormResponse<T, R>
  open(params: R): void
  getParams(): R
}

type UseWrapperWithForm = {
  <R extends WrapperParams, T extends BaseFormService<FormBuilderInterface> = BaseFormService<FormBuilderInterface>>(
    wrapperId: TmWrappers,
    formId: Forms,
  ): UseWrapperWithFormResultWithParams<T, R>
}

export const useWrapperWithForm: UseWrapperWithForm = <
  R extends WrapperParams,
  T extends BaseFormService<FormBuilderInterface> = BaseFormService<FormBuilderInterface>,
>(
  wrapperId: TmWrappers,
  formId: Forms,
) => {
  const create = (settings?: { hooks?: FormHooks }) => {
    const formProps = createEditableWrapperForm<R, T>(formId, wrapperId, {
      hooks: settings?.hooks,
    })

    const params = getParams()

    return {
      ...formProps,
      wrapperId,
      params,
    }
  }

  const open: any = (params: R) => {
    useWrapper<R>(wrapperId).open(params)
  }

  const close = () => {
    useWrapper(wrapperId).close()
  }

  const getParams = () => useWrapper<R>(wrapperId).getParams()

  return {
    create,
    open,
    close,
    getParams,
    wrapperId,
  }
}

export const useFormLeftLabel = () => useProvideInject<FormLeftLabel>(formLeftLabelSymbol)
export const useFormLeftLabelWidth = () => useProvideInject<FormLeftLabelWidth>(formLeftLabelWidthSymbol)
export const useFormDisabled = () => useProvideInject<FormDisabled>(formDisabledSymbol)
export const useFormInputRef = () => useProvideInject<Ref<FormInputRef | null>>(formInputRef)
export const useFormInputCallback = (inputRef: FormInputRef) => {
  const injected = useFormInputRef().inject()
  if (!injected || injected.value) return
  injected.value = inputRef
  onUnmounted(() => {
    injected.value = null
  })
}

export const useSubfieldInvalidItems = (
  props: UnwrapRef<{
    modelValue: any[]
    errors: ValidationRuleErrorWithRuleName[]
    subfieldServerErrors: Dict<string[]>
  }>,
) => {
  type ItemError = ValidationRuleError<ItemValidationPayload>
  const itemError = computed(
    () => props.errors?.find(({ ruleName }) => ruleName === itemValidationRuleName) as ItemError | undefined,
  )

  const invalidItems = computed<boolean[]>(() =>
    props.modelValue.map((_, index) => {
      const clientInvalid = itemError.value?.payload?.invalidItems[index]
      const serverInvalid = !!props.subfieldServerErrors[index]?.length
      return clientInvalid || serverInvalid
    }),
  )

  const itemErrors = computed(() => itemError.value?.payload?.itemErrors || [])
  const notItemErrors = computed(
    () => props.errors?.filter(({ ruleName }) => ruleName !== itemValidationRuleName) || [],
  )

  return {
    invalidItems,
    itemErrors,
    notItemErrors,
  }
}

export const useOnFormLoading = (
  formService: BaseFormService<FormBuilderInterface>,
  callback: () => Promise<void> | void,
) => {
  const isFormLoading = computed(() => formService.getFormState().isFormLoading)

  watch(
    isFormLoading,
    async (loading) => {
      if (loading) return
      await callback()
    },
    { immediate: true },
  )
}

export const useActualFormData = <FD>(formId: Forms, debounceTimeout = 500) => {
  const { formService, onFormLoaded } = useForm(formId)
  const formDataValue = shallowRef(formService.getFormData() as FD)

  const debouncedWatchCallback = debounce((newValue: FD) => {
    if (isEqual(newValue, formDataValue.value)) {
      return
    }
    formDataValue.value = newValue as FD
  }, debounceTimeout)

  onFormLoaded(() => {
    watch(() => (formService.isInit() ? (formService.getFormData() as FD) : ({} as FD)), debouncedWatchCallback, {
      deep: true,
    })
  })

  onBeforeUnmount(() => {
    debouncedWatchCallback.cancel()
  })

  return formDataValue
}

export const useFormDataChanged = (
  formId: Forms,
  comparator?: (actualData: TpFormData, initialData?: TpFormData) => boolean,
) => {
  const { formService, onFormLoaded } = useForm(formId)
  const initialData = ref<TpFormData | undefined>()
  const actualData = useActualFormData(formId, 100)

  const reset = () => {
    initialData.value = formService.getFormData()
  }

  onFormLoaded(reset)

  return {
    isChanged: computed(() => {
      if (typeof comparator === 'function') {
        return comparator(actualData.value as Record<string, unknown>, initialData.value as Record<string, unknown>)
      }

      if (isEmpty(actualData.value)) {
        return false
      }

      return !isEqual(initialData.value, actualData.value)
    }),
    reset,
    initialData,
  }
}

type FormFactorySettings<T extends WrapperParams> = FormSettings<T> & {
  factoryParams?: Dict
}

export const useFormFactory = (
  factoryId: RegisteredServices,
  formSettings: FormFactorySettings<any> = defaultFormSettings,
) => {
  const formFactoryManager = getFormFactoryManager()
  const formId = formFactoryManager.createFormFromFactoryAndGetId(factoryId, formSettings?.factoryParams)

  if (formSettings?.shouldDestroy !== false || formSettings?.withoutHooks !== true) {
    onUnmounted(() => {
      formFactoryManager.removeForm(formId)
    })
  }

  return { formId }
}

export const createEditableFormFromFactory = <
  T extends BaseFormService<FormBuilderInterface>,
  R extends WrapperParams = WrapperParams,
>(
  factoryId: RegisteredServices,
  formSettings?: FormFactorySettings<R>,
) => {
  const { formId } = useFormFactory(factoryId, formSettings)
  return createEditableForm<T, R>(formId, formSettings)
}

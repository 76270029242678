import { injectable } from 'inversify'
import BaseFilterModel from '@/data/models/filters/BaseFilterModel'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { Endpoint } from '@/services/endpoints'

@RepoSettings<Endpoint>({
  model: BaseFilterModel,
})
@injectable()
export default class BaseFilterRepository extends OrmApiRepository<BaseFilterModel> {}

import type { CountryCode } from 'libphonenumber-js'
import type {
  TenDlcAttributesFormScheme,
  TenDlcCampaignDetailsFormScheme,
  TenDlcRequestServiceApplyBrandUpsertionBusinessRegionsOfOperationsBody,
  TenDlcRequestServiceApplyBrandUpsertionContactJobPositionBody,
  TenDlcTwilioRequestServiceApplyBrandUpsertionEntityTypeBody,
  TenDlcTwilioRequestServiceApplyBrandUpsertionLegalStructureBody,
  TenDlcVertical,
} from '@/services/domain/services/regulations/tenDlcRequest/types'
import type TollFreeRequest from '@/data/models/domain/services/regulations/TollFreeRequest'
import type TenDlcRequest from '@/data/models/domain/services/regulations/TenDlcRequest'
import type { TenDlcCampaignsUseCaseFormScheme } from '@/services/domain/services/regulations/tenDlcCampaigns/types'
import type {
  TollFreeCampaignDetailsFormScheme,
  TollFreeOptInFlowFormScheme,
} from '@/services/domain/services/regulations/tollFreeRequest/types'
import type TenDlcCampaign from '@/data/models/domain/services/regulations/TenDlcCampaign'
import type BaseFormService from '@/services/forms/baseFormService'
import type FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import type { Nullable, ParamsPartial } from '@/types'
import type TenDlcTwilioBrand from '@/data/models/domain/services/regulations/TenDlcTwilioBrand'

export interface FormServiceWithRequestId {
  requestId: string | null
  setRequestId(id: string): void
}

export interface FormServiceWithCampaignId {
  setCampaignId(id: Nullable<string>): void
}

export interface FormServiceWithGetRequestInfoSetter<
  T extends TollFreeRequest | TenDlcRequest | [TenDlcRequest, TenDlcTwilioBrand],
> {
  setGetRequestInfo(cb: () => T): void
}

export interface FormServiceWithRejectedFields {
  getRejectedFormFields(): string[][]
}

export interface FormDataProviderParams<T> {
  requestId: string
  initFormData: T
}

export interface FormDataProvider<T> {
  getFormData(params: FormDataProviderParams<T>): Promise<T>
}

export interface FormDataFromStorageProviderRequest {
  id: string
  updatedAt: string
}

interface TenDlcForms {
  UseCase: TenDlcCampaignsUseCaseFormScheme
  CampaignDetails: TenDlcCampaignDetailsFormScheme
  SampleMessages: TenDlcTollFreeSampleMessagesFormScheme
  Attributes: TenDlcAttributesFormScheme
  BrandDetails: TenDlcCreateBranDetailsFormScheme
  BrandAddress: TenDlcCreateBranAddressFormScheme
  BrandContact: TenDlcCreateBranContactFormScheme
}

interface TollFreeForms {
  CampaignDetails: TollFreeCampaignDetailsFormScheme
  OptIn: TollFreeOptInFlowFormScheme
  SampleMessages: TenDlcTollFreeSampleMessagesFormScheme
  BrandAddress: TollFreeCreateBranAddressFormScheme
  BrandContact: TollFreeCreateBranContactFormScheme
  BrandDetails: TollFreeCreateBranDetailsFormScheme
}

export type TenDlcBrowserStorageServiceFormName = keyof TenDlcForms

export type TollFreeBrowserStorageServiceFormName = keyof TollFreeForms

export interface TenDlcFormDataFromStorageProvider {
  getSavedFormData<T extends keyof TenDlcForms>(
    request: FormDataFromStorageProviderRequest,
    formName: T,
  ): TenDlcForms[T] | null
}

export interface TollFreeFormDataFromStorageProvider {
  getSavedFormData<T extends keyof TollFreeForms>(
    request: FormDataFromStorageProviderRequest,
    formName: T,
  ): TollFreeForms[T] | null
}

export interface TenDlcTollFreeRequestWithHasBackStep {
  id: string
  hasBackStep: boolean
}

export interface TenDlcTollFreeBrandInfoForStep {
  legalName: string | null
  brandName: string | null
  contactFirstName: string
  contactLastName: string
  contactPhone: string | null
  contactEmail: string
  street: string
  city: string
  zipCode: string
  state: string
  countryId: CountryCode
}

export interface CreateBrandFormScheme {
  companyNameLegal: string
  companyNameDisplay: string
  website: string
  verticalTypeOfCompany: string

  // Organization address
  street: string
  city: string
  postalCode: string
  /**
   * state only for country !== US
   */
  stateForAnotherCountry: string
  /**
   * Select for state only for country === US
   */
  stateForUS: string
  country: CountryCode | ''
  //--------

  // Organization contact details
  contactFirstName: string
  contactLastName: string
  contactPhone: string
  contactEmail: string
  //--------
}

export interface TollFreeCreateBrandFormScheme extends CreateBrandFormScheme {
  entityType: string
}

export interface TollFreeCreateBranDetailsFormScheme {
  companyNameLegal: string
  companyNameDisplay: string
  website: string
  entityType: string
  verticalTypeOfCompany: string
}

export interface TollFreeCreateBranAddressFormScheme {
  street: string
  city: string
  postalCode: string
  stateForAnotherCountry: string
  stateForUS: string
  country: CountryCode | ''
}

export interface TollFreeCreateBranContactFormScheme {
  contactFirstName: string
  contactLastName: string
  contactPhone: string
  contactEmail: string
}

export interface TenDlcCreateBranDetailsFormScheme {
  companyNameLegal: string
  companyNameDisplay: string
  entityType: TenDlcTwilioRequestServiceApplyBrandUpsertionEntityTypeBody | ''
  legalStructure: TenDlcTwilioRequestServiceApplyBrandUpsertionLegalStructureBody | ''
  verticalTypeOfCompany: TenDlcVertical
  tax: string

  /**
   * Only if active public profit
   */
  companyStockExchange: string
  companyStockSymbol: string

  website: string
  socialProfileLinks: string[]
  businessRegionsOfOperations: Record<TenDlcRequestServiceApplyBrandUpsertionBusinessRegionsOfOperationsBody, boolean>
}

export interface TenDlcCreateBranAddressFormScheme {
  street: string
  city: string
  postalCode: string
  /**
   * state only for country !== US
   */
  stateForAnotherCountry: string
  /**
   * Select for state only for country === US
   */
  stateForUS: string
  country: CountryCode | ''
}

export interface TenDlcCreateBranContactFormScheme {
  contactFirstName: string
  contactLastName: string
  contactPhone: string
  contactEmail: string
  contactJobPosition: TenDlcRequestServiceApplyBrandUpsertionContactJobPositionBody
  contactExactJobTitle: string
}

export interface TenDlcCreateBrandFormScheme extends CreateBrandFormScheme {
  // Organization contact details
  contactJobPosition: TenDlcRequestServiceApplyBrandUpsertionContactJobPositionBody
  contactExactJobTitle: string
  //-----------

  entityType: TenDlcTwilioRequestServiceApplyBrandUpsertionEntityTypeBody | ''
  legalStructure: TenDlcTwilioRequestServiceApplyBrandUpsertionLegalStructureBody | ''
  tax: string
  /**
   * Only if active public profit
   */
  companyStockExchange: string
  /**
   * Only if active public profit
   */
  companyStockSymbol: string
  businessRegionsOfOperations: Record<TenDlcRequestServiceApplyBrandUpsertionBusinessRegionsOfOperationsBody, boolean>
  socialProfileLinks: string[]
  verticalTypeOfCompany: TenDlcVertical
}

export interface TenDlcTollFreeSampleMessagesFormScheme {
  sampleMessages: string[]
  includesLinkInMessageContent: boolean
  includesPhoneNumberInMessageContent: boolean
}

export enum TenDlcRegulationState {
  HAS_10DLC_REQUEST_IN_PROGRESS = 'hasInProgress',
  ACTION_REQUIRED = 'hasActionRequired',
  HAS_UNSENDED_10DLC_REQUEST = 'hasUnsent',
}

export enum TollFreeRegulationState {
  HAS_TOLLFREE_REQUEST_IN_PROGRESS = 'hasInProgress',
  ACTION_REQUIRED = 'hasActionRequired',
  HAS_UNSENDED_TOLLFREE_REQUEST = 'hasUnsent',
  HAS_TOLLFREE_NUMBER_WITHOUT_REQUEST = 'hasNumberWithoutRequest',
  ENOUGH_MONEY = 'hasEnoughMoney',
  NO_ENOUGH_MONEY = 'notEnoughMoney',
}

export interface TenDlcRegulationStatus {
  state: TenDlcRegulationState
  requestId: string | null
}

export interface TollFreeRegulationStatus {
  state: TollFreeRegulationState
  requestId: string | null
  numberWithoutRequest: string | null
}

export enum RegulationsFaqTab {
  tenDlc = '10DLC',
  tollFree = 'tollFree',
}

export const tenDlcLearnMoreLink = 'https://support.textmagic.com/article/10dlc-messaging/'

export type CampaignDetails = Pick<
  TenDlcCampaign,
  'name' | 'useCase' | 'sampleMessages' | 'subscriberOptIn' | 'description'
>

export type CampaignContentAttributes = Pick<
  TenDlcCampaign,
  | 'includesLinkInMessageContent'
  | 'includesPhoneNumberInMessageContent'
  | 'subscriberOptIn'
  | 'subscriberOptOut'
  | 'subscriberHelp'
>

export interface IFormServiceWithScreenshotField {
  optInScreenshotFieldName: string
}

interface ScreenshotFormSchema {
  optInScreenshotLink: string | null
  optInScreenshot: File | null
}

export type FormServiceWithScreenshotField = BaseFormService<FormBuilderService, ParamsPartial, ScreenshotFormSchema> &
  IFormServiceWithScreenshotField

export const supportedOptInScreenshotFormat = ['jpg', 'png', 'gif', 'pdf'] as const

export interface NumberPrice {
  currentPrice: number
  monthlyPrice: number
  giftType: NumberGiftType
}

export type NumberGiftType = 'none' | 'registration' | 'first_month' | 'include_in_plan'

export type TenDlcTollFreeFormFieldSize = 'xSmall' | 'small' | 'medium' | 'large'

export type TollFreeBrandStepName = 'BrandDetails' | 'BrandAddress' | 'BrandContact'
export type TenDlcBrandStepName = 'BrandDetails' | 'BrandAddress' | 'BrandContact'

export interface TollFreeBrandSteps {
  name: TollFreeBrandStepName
  component: any
}

export interface TenDlcBrandSteps {
  name: TenDlcBrandStepName
  component: any
}

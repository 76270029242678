import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  // Domain services
  ScheduledService,
  ScheduledParametersService,

  // Repositories
  ScheduledRepository,
  ScheduledShortRepository,
  ScheduledParametersRepository,
}

export type ScheduledServices = keyof typeof SERVICES

export const SCHEDULED_SERVICE_TYPES: Record<ScheduledServices, symbol> = makeServicesTypes<ScheduledServices>(
  enumKeysToArray<ScheduledServices>(SERVICES),
)

import { kebabCase } from 'lodash-es'
import type { PartialUIConfig } from '@/services/wrappers/types'
import { getLoggerService, getWrapperManagerService } from '@/core/container/ioc'
import type { UsePartial } from '@/composition/types'
import type { ParamsPartial } from '@/types'
import { TmWrapperError } from '@/core/error/tmWrapperError'
import { onUnmounted } from '@/composition/vue/compositionApi'

export const createPartial = <T extends ParamsPartial, B extends PartialUIConfig<T>>(partial: B): UsePartial<T> => {
  const wrapperManagerService = getWrapperManagerService()
  wrapperManagerService.buildGroup(partial)
  const usedPartial: UsePartial<T> = usePartial(partial)
  getLoggerService().log('partialUI', 'Create slots', partial.name)
  getLoggerService().raw(
    'partialUI',
    usedPartial.slots.map((s) => ({
      name: s.name,
      params: s.params,
    })),
  )

  onUnmounted(usedPartial.destroy)

  return usedPartial
}
export const slotToCanonicalName = (rawName: string) => kebabCase(rawName)
export const usePartial = <T extends ParamsPartial, B extends PartialUIConfig<T>>(partial: B): UsePartial<T> => {
  const wrapperManagerService = getWrapperManagerService()
  if (!wrapperManagerService.isExistGroup(partial)) {
    throw new TmWrapperError('Partial should be created before usePartial is called.')
  }
  const isExist = (part: string) => partial.items.filter((item) => item.name === part).length > 0
  const openedInGroup = () => wrapperManagerService.openedInGroup(partial)
  return {
    slots: partial.items.map((part, index) => ({
      index,
      canonicalName: part.name,
      name: slotToCanonicalName(part.name),
      params: part.params,
      required: part.required || false,
    })),
    openedInGroup,
    openAll: () => {
      wrapperManagerService.openGroup(
        partial.items.map((i) => i.name),
        partial,
      )
    },
    closeAll: () => {
      wrapperManagerService.closeGroup(
        partial.items.map((i) => i.name),
        partial,
      )
    },
    open: (part: string) => {
      if (!isExist(part)) {
        throw new TmWrapperError('Non-exist part')
      }
      wrapperManagerService.openGroup([part], partial)
    },
    isOpened: (part: string) => {
      if (!isExist(part)) {
        throw new TmWrapperError('Non-exist part')
      }

      return wrapperManagerService.openedInGroup(partial)[part]
    },
    close: (part: string) => {
      if (!isExist(part)) {
        throw new TmWrapperError('Non-exist part')
      }
      wrapperManagerService.closeGroup([part], partial)
    },
    destroy: () => {
      partial.items.forEach((i) => {
        if (!isExist(i.name)) {
          throw new TmWrapperError('Non-exist part')
        }
      })
      wrapperManagerService.destroyGroup(partial)
    },
  }
}

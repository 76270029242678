import { injectable } from 'inversify'

@injectable()
export default class SyncQueueService {
  private eventsQueue: unknown[][] = []

  private isProcessing = false

  public enqueue(...args: unknown[]) {
    this.eventsQueue.push(args)
  }

  public processEvents(cb: (...args: unknown[]) => void) {
    if (this.isProcessing) {
      return
    }

    this.isProcessing = true

    while (this.eventsQueue.length > 0) {
      const event = this.eventsQueue.shift()
      if (event) {
        cb(event)
      }
    }

    this.isProcessing = false
  }
}

import { getNativeNotificationService, getUiStateService } from '@/core/container/ioc'
import { computed, useMediaQuery } from '@/composition/vue/compositionApi'

export const useChatUiState = () => {
  const nativeNotificationService = getNativeNotificationService()
  const uiStateService = getUiStateService()

  const isShowChatFilterInDropdown = useMediaQuery('(max-width: 1260px)')

  const showMobileChat = computed({
    get() {
      return uiStateService.getChatShowMobileChat()
    },
    set(value: boolean) {
      return uiStateService.setChatShowMobileChat(value)
    },
  })

  const isChatsLoading = computed({
    get() {
      return uiStateService.getChatIsChatsLoading()
    },
    set(value: boolean) {
      return uiStateService.setChatIsChatsLoading(value)
    },
  })

  const isNotificationRequestWasClosed = computed({
    get() {
      return uiStateService.getIsNotificationRequestWasClosed()
    },
    set(value: boolean) {
      return uiStateService.setIsNotificationRequestWasClosed(value)
    },
  })

  const handleSwipe = () => {
    showMobileChat.value = false
  }

  const requestNotificationPermission = () => {
    if (!isNotificationRequestWasClosed.value) {
      nativeNotificationService.notificationRequest((_result) => {
        isNotificationRequestWasClosed.value = true
      })
    }
  }

  return {
    showMobileChat,
    isChatsLoading,
    handleSwipe,
    requestNotificationPermission,
    isShowChatFilterInDropdown,
  }
}

import { inject, injectable } from 'inversify'
import { pick } from 'lodash-es'
import type {
  ModalsInQuery,
  WrapperOpenableInterface,
  WrapperParams,
  WrapperTypesServicesKeys,
} from '@/services/wrappers/types'
import type Modal from '@/data/models/wrappers/Modal'
import { SERVICE_TYPES } from '@/core/container/types'
import type ModalRepository from '@/data/repositories/wrappers/modalRepository'
import type ModalService from '@/services/wrappers/modalService'
import type { ModelRaw } from '@/types'
import { isArrayOfModalsInQuery } from '@/services/wrappers/types'
import type NewQueryParamsService from '@/services/route/newQueryParamsService'

@injectable()
export default class NewQueryModalService implements WrapperOpenableInterface<Modal> {
  protected wrapperType: WrapperTypesServicesKeys = 'newQueryModal'

  constructor(
    @inject(SERVICE_TYPES.NewQueryParamsService) protected readonly queryParamsService: NewQueryParamsService,
    @inject(SERVICE_TYPES.ModalService) protected readonly modalService: ModalService,
    @inject(SERVICE_TYPES.ModalRepository) protected readonly modalRepository: ModalRepository,
  ) {}

  public build(wrapperId: string) {
    const modalData = {
      id: wrapperId,
      params: {},
      isOpen: false,
    }

    return this.modalRepository.insertOrUpdate([modalData])
  }

  public completeBuilds() {
    this.openModalsByQuery()
  }

  public async open(wrapperId: string, params?: WrapperParams) {
    const modals = this.getModalsQuery()
    await this.queryParamsService.addQuery(
      {
        modals: [
          ...(modals || []),
          {
            id: wrapperId,
            params,
          },
        ],
      },
      true,
    )
    return this.modalService.open(wrapperId, params)
  }

  public async close(wrapperId: string) {
    const query = this.queryParamsService.getQueryParams()

    const modals = this.isModals(query.modals) ? query.modals.filter(({ id }) => id !== wrapperId) : undefined

    const newQuery = {
      ...query,
      modals,
    }
    await this.queryParamsService.replaceQuery(newQuery)
    return this.modalService.close(wrapperId)
  }

  public closeOrOpenByQuery(openedInStore: ModelRaw<Modal>[]) {
    const query = this.queryParamsService.getQueryParams()
    const modals = query.modals || []
    if (this.isModals(modals)) {
      const opennedInQuery: Record<string, WrapperParams> = modals.reduce(
        (acc, modal) => {
          acc[modal.id] = modal.params
          return acc
        },
        {} as Record<string, WrapperParams>,
      )
      const opennedIdsInQuery = Object.keys(opennedInQuery)
      openedInStore.forEach((modal) => {
        if (modal.wrapperType === 'newQueryModal' && !opennedIdsInQuery.includes(modal.id) && this.isOpen(modal.id)) {
          this.close(modal.id)
        }
      })
      opennedIdsInQuery.forEach((id: string) => {
        if (!this.isOpen(id)) {
          this.modalService.open(id, opennedInQuery[id])
        }
      })
    }
  }

  public getParams(wrapperId: string) {
    return this.modalService.getParams(wrapperId)
  }

  public getWrapperData(wrapperId: string): ModelRaw<Modal> {
    return this.modalService.getWrapperData(wrapperId)
  }

  public setParams(wrapperId: string, params?: WrapperParams): void {
    this.updateQueryModals(wrapperId, params || {})
    this.modalService.setParams(wrapperId, params)
  }

  public patchParams(wrapperId: string, params?: WrapperParams): WrapperParams {
    const mergedParams = this.modalService.patchParams(wrapperId, params)
    this.updateQueryModals(wrapperId, mergedParams)

    return mergedParams
  }

  public removeParams(wrapperId: string, paramsToDelete: string[]) {
    this.updateQueryModals(wrapperId, pick(this.getParams(wrapperId), paramsToDelete))
    this.modalService.removeParams(wrapperId, paramsToDelete)
  }

  public clearParams(wrapperId: string) {
    this.updateQueryModals(wrapperId, {})
    this.modalService.clearParams(wrapperId)
  }

  public destroy(wrapperId: string) {
    this.modalService.destroy(wrapperId)
  }

  public isOpen(wrapperId: string): boolean {
    return this.modalService.isOpen(wrapperId)
  }

  public isExist(wrapperId: string): boolean {
    return this.modalService.isExist(wrapperId)
  }

  protected async openModalsByQuery() {
    const query = this.queryParamsService.getQueryParams()

    if (!this.isModals(query.modals)) return

    for (const modal of query.modals) {
      await this.modalService.open(modal.id, modal.params)
    }
  }

  protected updateQueryModals(wrapperId: string, params: WrapperParams) {
    const modals = this.getModalsQuery()
    const modalQueryIndex = this.getModalIndex(wrapperId)
    if (modalQueryIndex >= 0) {
      modals[modalQueryIndex].params = params
      this.queryParamsService.extendQuery({ modals }, true, true)
    }
  }

  protected getModalsQuery(): ModalsInQuery[] {
    const query = this.queryParamsService.getQueryParams()
    let modals = query.modals || []
    if (Array.isArray(modals)) {
      modals = modals.filter(Boolean)
      if (this.isModals(modals)) {
        return modals
      }
      return []
    }
    return []
  }

  protected getModalIndex(wrapperId: string): number {
    return this.getModalsQuery() ? this.getModalsQuery().findIndex((modal) => modal && modal.id === wrapperId) : -1
  }

  protected isModals(modals: unknown): modals is ModalsInQuery[] {
    return isArrayOfModalsInQuery(modals)
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type ServerSubscriptionService from '@/services/transport/serverSubscriptionService'
import type { TmRoutes } from '@/services/route/routerTypes'
import type RouterService from '@/services/route/routerService'
import type WindowService from '@/services/browser/windowService'
import type { TServerEvent } from '@/services/transport/serverEvents'
import type UserSettingsService from '@/services/domain/user/userSettingsService'

@injectable()
export default class StopImpersonateResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.ServerSubscriptionService)
    protected readonly serverSubscriptionService: ServerSubscriptionService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.UserSettingsService) protected readonly userSettingsService: UserSettingsService,
  ) {}

  public async resolve() {
    this.serverSubscriptionService.subscribe('userImpersonationEnd', this.handler)
  }

  public async unresolve() {
    this.serverSubscriptionService.unsubscribeAll('userImpersonationEnd')
  }

  private handler = async (event: TServerEvent<'userImpersonationEnd'>) => {
    const userSettings = this.userSettingsService.currentUserSettingsOrFail()
    const impersonatorUserId = event.payload.impersonatorId
    const impersonatedByTMAdmin = event.payload.isImpersonatedByTMAdmin

    if (
      impersonatedByTMAdmin ||
      !userSettings.isImpersonated ||
      `${userSettings.impersonatedBy}` !== `${impersonatorUserId}`
    ) {
      return
    }

    const routeResolved = this.routerService.resolve({
      name: 'user.accounts.subAccounts.active',
    } satisfies TmRoutes)
    this.windowService.replace(routeResolved.fullPath)
  }
}

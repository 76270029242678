import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  TenDlcBuyNumberFormService,
  TenDlcAttributesFormService,
  TenDlcCampaignDetailsFormService,
  TenDlcCreateBrandDetailsFormService,
  TenDlcCreateBrandAddressFormService,
  TenDlcCreateBrandContactFormService,
  TenDlcSampleMessagesFormService,
  TenDlcUseCaseFormService,
  TollFreeCampaignDetailsFormService,
  TollFreeCreateBrandAddressFormService,
  TollFreeCreateBrandContactFormService,
  TollFreeCreateBrandDetailsFormService,
  TollFreeOptInFlowFormService,
  TollFreeSampleMessagesFormService,
  TenDlcSelectCampaignServiceFactory,
  TenDlcBrandFormDataProviderService,
  TollFreeBrandFormDataProviderService,
  TenDlcBrowserStorageService,
  TollFreeBrowserStorageService,
  TenDlcCampaignFormDataFromStorageProvider,
  TollFreeCampaignFormDataFromStorageProvider,
  TenDlcTollFreeRequestResolver,
  TenDlcRouteHelperService,
  TollFreeRouteHelperService,
  TenDlcCampaignsGridable,
  TenDlcCampaignsToLinkGridable,
}

export type TenDlcTollFreeServices = keyof typeof SERVICES

export const TEN_DLC_TOLL_FREE_SERVICE_TYPES: Record<TenDlcTollFreeServices, symbol> =
  makeServicesTypes<TenDlcTollFreeServices>(enumKeysToArray<TenDlcTollFreeServices>(SERVICES))

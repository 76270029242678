import { inject, injectable } from 'inversify'
import type { TypedFieldConfig } from '@/services/forms/baseForm/typedFormBuilder/types'
import type {
  SelectSearchInboxSubfieldNames,
  SelectSearchInboxFieldConfig,
  SelectOption,
  SelectInboxOption,
  SelectInboxSubheaderOption,
  SelectInboxOverrideOption,
} from '@/services/forms/types'
import { fieldInboxRulePrefix } from '@/components/forms/types'
import { SelectSearchInboxType } from '@/services/forms/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type TranslateService from '@/services/translateService'
import { ticketsRouteNames } from '@/routes/user/tickets/types'
import type TicketInboxService from '@/services/domain/tickets/inboxes/ticketInboxService'
import type { FeatureFlagsService } from '@/services/featureFlagsService'
import { FeatureFlags } from '@/services/types'

type InboxFieldParams = {
  defaultValueType?: SelectSearchInboxType
  withCustomEmail?: boolean
}

@injectable()
export default class SelectInboxFieldBuilderService {
  constructor(
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    @inject(SERVICE_TYPES.TicketInboxService) protected readonly ticketInboxService: TicketInboxService,
    @inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagService: FeatureFlagsService,
  ) {}

  public getSubfieldValidationRuleName(fieldName: SelectSearchInboxSubfieldNames) {
    return `${fieldInboxRulePrefix}-${fieldName}`
  }

  public getFieldConfig({
    withCustomEmail,
    defaultValueType,
  }: InboxFieldParams): TypedFieldConfig<SelectSearchInboxFieldConfig> {
    return {
      fieldType: 'selectSearchInbox',
      value: {
        valueType: defaultValueType ?? SelectSearchInboxType.CUSTOM,
      },
      componentProps: {
        withCustomEmail: () => withCustomEmail,
      },
    }
  }

  public extendOptions(options: SelectOption[]): SelectInboxOption[] {
    const subheaderOption: SelectInboxSubheaderOption = {
      text: 'connectedInbox',
      value: 'connectedInbox',
      subheader: this.translateService.t('fieldSelectSearchInbox.connected'),
      disabled: true,
    }

    const newInboxOption: SelectInboxOverrideOption = {
      text: this.translateService.t('fieldSelectSearchInbox.connectNew'),
      value: 'newInboxValue',
      overrideOption: true,
      type: 'button',
      to: {
        name: this.ticketInboxService.hasInboxes()
          ? ticketsRouteNames.settingsInboxesConnect
          : ticketsRouteNames.gettingStarted,
      },
    }

    const resultOptions: SelectInboxOption[] = [subheaderOption]

    if (options.length) {
      resultOptions.push(...options)
    } else if (this.featureFlagService.isFeatureEnabled(FeatureFlags.Tickets)) {
      resultOptions.push(newInboxOption)
    }

    return resultOptions
  }
}

import { get } from '@/core/container/ioc'
import type TableColumnManagerInterface from '@/services/tables/managers/tableColumnManagerInterface'
import type { UseTableBuilderSettings } from '@/composition/tables'

type UseColumnsSettings = Pick<UseTableBuilderSettings, 'tableModelId' | 'tableColumnManager' | 'columnFactory'>

export const useColumns = (settings: Pick<UseColumnsSettings, 'tableColumnManager'>) =>
  get<TableColumnManagerInterface>(settings.tableColumnManager ?? 'TableColumnManager')

export const createColumns = (settings: UseColumnsSettings) => {
  const manager = get<TableColumnManagerInterface>(settings.tableColumnManager ?? 'TableColumnManager')

  if (settings.columnFactory) {
    manager.setFactoryForTable(settings.tableModelId, settings.columnFactory)
  }

  return useColumns(settings)
}

import type { RouteLocationRaw } from 'vue-router'
import type { PropType } from '@/composition/vue/compositionApi'
import type { TmWrappers } from '@/wrappers'
import type { WrapperParams } from '@/services/wrappers/types'
import type Country from '@/data/models/domain/Country'
import type { TmCountryCode } from '@/services/types'
import type { ParticipantContact, ParticipantPhone } from '@/services/domain/messages/types'
import type { IconName } from '@/assets/icons/icons'
import type { StylesheetSizeProp } from '@/types'
import type { TmNamedRoute } from '@/services/route/types'

export const previewModalId: TmWrappers = 'previewModal'

export type Tab<T extends string = string> = {
  name: T
  counter?: number | string
  label?: string
  to?: RouteLocationRaw
  error?: boolean
  counterClass?: string
  wrapperId?: TmWrappers
  wrapperParams?: WrapperParams
  rightIcon?: TmIconType
}

export type ScrollbarStyle =
  | 'thumb-with-track'
  | 'thumb-with-track-hover'
  | 'thumb-without-track'
  | 'thumb-without-track-hover'
  | 'thumb-without-track-white'

export const observedScrollbarQuasarClass = 'scroll' as const
export const rootScrollClassName = 'root-scroll' as const
export const scrollbarClassName = 'tm-scrollbar' as const
export const scrollbarIdAttr = 'data-scrollbar-id' as const
export const scrollbarSelector = `.${scrollbarClassName}` as const
export const rootScrollbarId = 'ROOT_SCROLL' as const
export const dropdownClassName = 'tm-dropdown' as const
export const dropdownSizes = {
  maxHeight: {
    sm: '300px' satisfies StylesheetSizeProp,
    md: '460px' satisfies StylesheetSizeProp,
    lg: '620px' satisfies StylesheetSizeProp,
  },
}
export const delayOptions = {
  default: 250,
  long: 1000,
}

export type Breadcrumb = {
  label?: string
  route?: RouteLocationRaw
  noTruncate?: boolean
}

export type Breadcrumbs = Breadcrumb[]

export type IconRotate = 0 | 45 | 90 | 180 | 295

export type Size =
  | ''
  | 'xxSmall'
  | 'xSmall'
  | 'size13'
  | 'small'
  | 'almostSmall'
  | 'size15'
  | 'medium'
  | 'xMedium'
  | 'large'
  | 'xLarge'
  | 'xxLarge'
  | 'xxxLarge'
  | 'xxxxLarge'
  | 'size26'
  | 'xxxxxLarge'
  | 'huge'
  | 'xHuge'
  | 'full'
  | 'fit-field'
export type SizeProp<S extends Size> = PropType<S>

export const iconSizeVariant = [
  'xxSmall',
  'xSmall',
  'size13',
  'small',
  'size15',
  'medium',
  'xMedium',
  'large',
  'xLarge',
  'xxLarge',
  'xxxLarge',
  'xxxxLarge',
  'size26',
  'xxxxxLarge',
  'huge',
  'xHuge',
] as const
export type IconSize = (typeof iconSizeVariant)[number]
export type ChipSize = 'xSmall' | 'small' | 'large' | 'xLarge'
export type FlagSize = '' | 'xxSmall' | 'xSmall' | 'small' | 'large'
export type ButtonSize = '' | 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'xMedium' | 'large' | 'xLarge' | 'xxLarge'
export type AvatarSize = 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'xMedium' | 'large' | 'xLarge'

export type TmIconType = {
  name: IconName
  size?: IconSize
  class?: string
  rotate?: IconRotate
  mirror?: boolean
  bold?: boolean
  customSize?: string
  customColor?: string
  disabled?: boolean
  style?: string | Partial<CSSStyleDeclaration>
}

export type ButtonColor =
  | 'default'
  | 'blue'
  | 'primary'
  | 'blue-outline'
  | 'secondary'
  | 'red'
  | 'error'
  | 'orange'
  | 'warning'
  | 'green'
  | 'success'
  | 'orange500'
  | 'shade'
  | 'shade-light'
  | 'yellow'
  | 'transparent'
  | 'transparent-light'
  | 'blueish'
  | 'text-link'

export type TabStyle = 'pills' | 'default'

export type FontWeight = 'normal' | 'medium' | 'semi-bold' | 'bold' | 'extra-bold'

export type TextColors =
  | 'emphasize'
  | 'strong'
  | 'neutral'
  | 'distinct'
  | 'light'
  | 'subtle'
  | 'weak'
  | 'red'
  | 'warning'

export const colorMap = {
  blue: '#008cff',
  orange: '#f0ac4c',
  green: '#87c461',
  red: '#ED6A61',
  vermilion: '#d57070',
  gray: '#8392a5',
  error: '#ea7f7f',
  success: '#87c461',
  purpur: '#c67193',
  brown: '#cc8f7f',
  cyan: '#75bdbf',
  violet: '#8f78bb',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'blue-fill': '#008cff',
} as const

// If you change it, please update $widget-color-map in mixins.scss
export const widgetColorMap = {
  blue: '#0080EA',
  orange: '#E39E3B',
  green: '#79B057',
  red: '#D57070',
  cyan: '#69AAAC',
  violet: '#816CA8',
  purpur: '#B26684',
  brown: '#B88172',
} as const

export type StatusColor = keyof typeof colorMap

export const filesVariant = [
  'audio',
  'contact',
  'doc',
  'json',
  'mail',
  'pdf',
  'png',
  'ppt',
  'svg',
  'text',
  'unknown',
  'unknown-text',
  'video',
  'xls',
  'xlsx',
  'zip',
  'csv',
] as const
export type FileCellFileType = (typeof filesVariant)[number]
export const isFileCellFileType = (value: any): value is FileCellFileType => filesVariant.includes(value)
export type TmFileExtension = FileCellFileType | string

export interface DraggableEvent<E = number | string> {
  draggedContext: {
    element: E
    index: number
    futureIndex: number
  }
  relatedContext: {
    element: E
    index: number
  }
  originalEvent: DragEvent
}

export interface DraggableMoveEvent<E = string> {
  moved?: {
    element: E
    newIndex: number
    oldIndex: number
  }
  added?: {
    element: E
    newIndex: number
  }
  removed?: {
    element: E
    oldIndex: number
  }
}

export interface DraggableSortEvent<T = unknown> {
  movedIndex: number
  afterIndex: number
  movedItem: T
  afterItem: T | undefined
  sortedItems: T[]
}

export interface DraggableAddedEvent<T = unknown> {
  afterIndex: number
  addedItem: T
  sortedItems: T[]
}

export interface DraggableRemovedEvent<T = unknown> {
  removedIndex: number
  removedItem: T
  sortedItems: T[]
}

export interface DraggableStartEndEvent {
  item: HTMLElement
}

export type DraggableRowType = 'default' | 'group'

export type DraggableRow = {
  id: string
  index: number
  type: DraggableRowType
}

export type MoveRowPayload<T = { id: string }> = Pick<DraggableSortEvent<T>, 'movedItem' | 'afterItem' | 'sortedItems'>

export const ROW_HANDLER_CLASS = 'row-handler'

export type CallParticipantBase = {
  phone: string
  countryId: TmCountryCode
  label?: string
}

export type CallParticipantContact = CallParticipantBase & ParticipantContact

export type CallParticipantPhone = CallParticipantBase & ParticipantPhone

export type CallParticipantInfo = CallParticipantContact | CallParticipantPhone

export type CallParticipant = {
  phone: string
  label?: string
}
export type DetailsTableItemType = {
  label: string
  value: any
  slot?: string
  rightClass?: string
  leftClass?: string
  preserveWhitespace?: boolean
  options?: Record<string, unknown>
  fixedHeight?: boolean
}

export type SkeletonTypes =
  | 'text'
  | 'rect'
  | 'circle'
  | 'QBtn'
  | 'QBadge'
  | 'QChip'
  | 'QToolbar'
  | 'QCheckbox'
  | 'QRadio'
  | 'QToggle'
  | 'QSlider'
  | 'QRange'
  | 'QInput'
  | 'QAvatar'

export type SimpleSkeletonTypes = 'rect' | 'chip' | 'circle'

export type WeekDays = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday'

export type StepperStep = {
  name: string
  to?: string
}

export interface TmOptionUserWithEmail {
  text: string
  value: string
  userName: string
  email: string
}

export interface TmOptionCountryWithCodeProp {
  text: string
  value: TmCountryCode
  country: Pick<Country, 'id' | 'name'>
  code: string
}

export type TmAvatarPlaceholder = 'icon' | 'symbol'

export type HTMLElementPosition = {
  top?: string
  left: string
  bottom?: string
  maxHeight?: string
}

export type HTMLElementBoundaries = {
  topLeft: number
  bottomLeft: number
}

export type InteractiveOption = {
  uid: string
  disabled?: boolean
  [key: string]: unknown
}

export type ButtonAlign = 'left' | 'right' | 'center' | 'around' | 'between' | 'evenly' | undefined
export type TmButtonXPadding = '12px'

export const TmButtonProps = {
  title: {
    type: String,
  },
  textLink: {
    type: Boolean,
  },
  iconOnly: {
    type: Boolean,
  },
  size: {
    type: String as SizeProp<ButtonSize>,
    default: '',
  },
  fullLoader: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
  skeleton: {
    type: Boolean,
  },
  loadingColor: {
    type: String,
    default: 'gray',
  },
  loadingTitle: {
    type: String,
  },
  disable: {
    type: Boolean,
  },
  color: {
    type: String as PropType<ButtonColor>,
    default: 'default',
  },
  outline: {
    type: Boolean,
  },
  href: {
    type: String,
  },
  tabindex: {
    type: String,
  },
  textCaretEnabled: {
    type: Boolean,
  },
  align: {
    type: String as PropType<ButtonAlign>,
  },
  hovered: {
    type: Boolean,
  },
  clicked: {
    type: Boolean,
  },
  focused: {
    type: Boolean,
  },
  flat: {
    type: Boolean,
  },
  flatClicked: {
    type: Boolean,
  },
  noBorder: {
    type: Boolean,
  },
  rounded: {
    type: Boolean,
  },
  ignoreExpanded: {
    type: Boolean,
  },
  xPadding: {
    type: String as PropType<TmButtonXPadding>,
  },
}

export type TmPreviewPhoneMessage = {
  timestamp: number
  direction: 'inbound' | 'outbound'
  text: string
}

export type ScrollXYPosition = {
  up: number
  down: number
  left: number
  right: number
}

export type TmRadioTabInterface<T extends string = string> = {
  name: T
  label?: string
  logos?: string[]
  text?: string
  hideRadio?: boolean
}

export const avatarSizesMap: Partial<Record<AvatarSize, number>> = {
  xxSmall: 16,
  xSmall: 20,
  small: 24,
  medium: 28,
  large: 36,
  xLarge: 40,
}

export type TextAlign = 'left' | 'right' | 'center'

export interface ITmFaqTab<T extends string> {
  name: T
  label: string
  component: any
}

export type TmFormLineVerticalAlign = 'top' | 'center'

export interface TmDirectoryItem<N extends string> {
  name: N
  icon: IconName
  title: string
  hasWarning?: boolean
}

export type TmButtonToggleOption<T extends string = string> = {
  value: T
  label?: string
  icon?: string
  to?: TmNamedRoute
  slot?: string
}

export type TmMenuItemBadge = 'new' | 'beta'

export type TmTemplateProps = {
  content: string
  search?: string
  ellipsis?: boolean
  withLinks?: boolean
  multiline?: boolean
  whiteSpace?: 'pre-wrap'
}

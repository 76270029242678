import { inject, injectable } from 'inversify'
import type { DomainTitlerParams, TitlerInterface } from '@/services/route/titlers/types'
import type { Route } from '@/services/route/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type TranslateService from '@/services/translateService'
import { SERVICE_TYPES } from '@/core/container/types'
import type DefaultTitlerService from '@/services/route/titlers/defaultTitlerService'
import { isRecordUnknown } from '@/utils/typeGuards'
import { TmTitlerError } from '@/core/error/tmTitlerError'

@injectable()
export default class DomainTitlerService implements TitlerInterface<DomainTitlerParams> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.DefaultTitlerService) protected readonly defaultTitlerService: DefaultTitlerService,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
  ) {}

  public getTitle(to: Route, params: DomainTitlerParams): string {
    const id = this.getId(to, params)

    const repository = this.em.getRepository(params.model)
    const clRec = repository.findEntityByIdOrNull(id)

    if (!clRec) {
      return this.defaultTitlerService.baseTitle(false, '')
    }

    try {
      return this.generateByEntity(clRec, params)
    } catch {
      return this.defaultTitlerService.baseTitle(false, '')
    }
  }

  public generateByEntity(entity: unknown, params: DomainTitlerParams) {
    const fieldName = params.field || 'name'
    if (isRecordUnknown(entity) && entity[fieldName] && typeof entity[fieldName] === 'string') {
      const field: string = (
        params.translateKey
          ? this.translateService.t(params.translateKey, { field: (entity[fieldName] as string).toString() })
          : entity[fieldName]
      ) as string
      return this.defaultTitlerService.baseTitle(!!entity, field)
    }
    throw new TmTitlerError('Wrong entity to generate title')
  }

  public getId(to: Route, params: DomainTitlerParams) {
    return to.params[params.idParamName] as string
  }
}

import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'

export const getCarrierLookupRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.services.carrierLookup',
    path: 'carrier-lookup',
    meta: {
      tags: ['SERVICES_BAR_LIST'],
      permission: 'carrierLookup',
      modules: [AppModule.CarrierLookup, AppModule.CarrierLookupSession],
    },
    component: () => import('@/components/views/services/carrierLookup/CarrierLookup.vue'),
    redirect: { name: 'user.services.carrierLookup.singleLookup' },
    children: [
      {
        name: 'user.services.carrierLookup.singleLookup',
        path: '',
        component: () => import('@/components/views/services/carrierLookup/CarrierLookupSingle.vue'),
        props: { tab: true },
        meta: {
          tabTitle: 'pageTabTitle.user.services.carrierLookup.singleLookup',
        },
      },
      {
        name: 'user.services.carrierLookup.bulkLookup',
        path: 'bulk-lookup',
        component: () => import('@/components/views/services/carrierLookup/CarrierLookupBulk.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.carrierLookup.bulkLookup',
        },
      },
      {
        name: 'user.services.carrierLookup.history',
        path: 'history',
        meta: {
          tabTitle: 'pageTabTitle.user.services.carrierLookup.history',
        },
        component: () => import('@/components/views/services/carrierLookup/CarrierLookupHistory.vue'),
      },
    ],
  },
]

import { computed } from '@/composition/vue/compositionApi'
import {
  getSegmentsService,
  getPinnedContactsService,
  getRouterService,
  getTranslateService,
} from '@/core/container/ioc'
import type {
  PageContentSidebarItem,
  PageContentSidebarItemAction,
} from '@/components/layout/pageContent/sidebar/types'
import type { TmRoutes } from '@/services/route/routerTypes'
import type PinnedContact from '@/data/models/domain/pinnedContact'
import { TmComponentError } from '@/core/error/TmComponentError'
import type { DraggableSortEvent } from '@/components/shared/types'
import type { ICancelablePromise } from '@/services/types'
import type { Nullable } from '@/types'
import { unpinItemAction } from '@/composition/domain/pinnedContacts/unpinItemModal'
import { contactListDetailsRouteName } from '@/routes/user/contacts/contacts'

const getRouteForItem = (pinnedEntity: PinnedContact): TmRoutes => {
  if (pinnedEntity.entityType === 'contact') {
    return {
      name: 'user.contacts.myContact.contactDetails',
      params: {
        contactId: pinnedEntity.entityId,
      },
    } satisfies TmRoutes
  }

  if (pinnedEntity.entityType === 'filtered_view') {
    return {
      name: 'user.contacts.segments.details',
      params: {
        segmentId: pinnedEntity.entityId,
      },
    } satisfies TmRoutes
  }

  return {
    name: contactListDetailsRouteName,
    params: {
      contactListId: pinnedEntity.entityId,
    },
  }
}

export const sidebarPinnedContactItems = () => {
  let updateOrderPromise: Nullable<ICancelablePromise<unknown>> = null

  const pinnedContactItems = computed(() => getPinnedContactsService().getItems())

  const pinnedSegmentsIds = computed(() =>
    pinnedContactItems.value.reduce((acc, item) => {
      if (item.entityType === 'filtered_view') {
        acc.add(item.entityId)
      }
      return acc
    }, new Set<string>()),
  )

  const pinnedContactListsIds = computed(() =>
    pinnedContactItems.value.reduce((acc, item) => {
      if (item.entityType === 'list') {
        acc.add(item.entityId)
      }
      return acc
    }, new Set<string>()),
  )

  const pinnedContactsIds = computed(() =>
    pinnedContactItems.value.reduce((acc, item) => {
      if (item.entityType === 'contact') {
        acc.add(item.entityId)
      }
      return acc
    }, new Set<string>()),
  )

  const pinnedContactsSidebarItems = computed(() =>
    pinnedContactItems.value.map(
      (item) =>
        ({
          id: item.id,
          itemType: 'link',
          title: getTitle(item.entityId, item.title),
          number: item.entityType !== 'contact' ? item.count : undefined,
          actions: getActions(item.id),
          icon: getIcon(item.entityId),
          route: getRouteForItem(item)!,
        }) satisfies PageContentSidebarItem,
    ),
  )

  const getActions = (id: string) => [
    {
      id,
      name: 'view',
      text: getTranslateService().t('contacts.draggableList.actions.view'),
      icon: 'visibility',
    } satisfies PageContentSidebarItemAction,
    {
      id,
      name: 'unpin',
      text: getTranslateService().t('contacts.draggableList.actions.unpin'),
      icon: 'tmi-unpin',
    } satisfies PageContentSidebarItemAction,
  ]

  const getIcon = (id: string) => {
    if (pinnedSegmentsIds.value.has(id)) {
      return 'pie_chart'
    }
    if (pinnedContactListsIds.value.has(id)) {
      return 'tmi-people'
    }
    if (pinnedContactsIds.value.has(id)) {
      return 'person'
    }
    return ''
  }

  const getTitle = (id: string, fallbackValue: string) => {
    if (pinnedSegmentsIds.value.has(id)) {
      return getSegmentsService().findEntityByIdOrNull(id)?.name ?? fallbackValue
    }

    return fallbackValue
  }

  return () => {
    const onActionClick = async ({ name, id }: PageContentSidebarItemAction) => {
      if (!id) {
        throw new TmComponentError('Action id is not defined')
      }

      const pinnedEntity = await getPinnedContactsService().getEntityById(id)

      if (!pinnedEntity) {
        return
      }

      if (name === 'view') {
        await getRouterService().push(getRouteForItem(pinnedEntity)!)
      }

      if (name === 'unpin') {
        await unpinItemAction(pinnedEntity.id, pinnedEntity.entityType, pinnedEntity.title)
      }
    }

    const onSort = (event: DraggableSortEvent) => {
      const orderedArray = event.sortedItems.map((item, idx) => ({
        id: (item as { id: string }).id,
        order: idx + 1,
      }))

      updateOrderPromise?.cancel()
      updateOrderPromise = getPinnedContactsService().updateOrder(orderedArray)
    }

    return {
      pinnedContacts: pinnedContactsSidebarItems,
      pinnedContactListsIds,
      pinnedSegmentsIds,
      pinnedContactsIds,
      onActionClick,
      onSort,
    }
  }
}

export const useSidebarPinnedContactItems = sidebarPinnedContactItems()

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable, NotFoundResolverParams } from '@/services/resolvers/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type BaseResolverService from '@/services/resolvers/baseResolverService'
import type RouterService from '@/services/route/routerService'

@injectable()
export default class NotFoundResolverService implements Resolvable<NotFoundResolverParams> {
  constructor(
    @inject(SERVICE_TYPES.BaseResolverService) protected readonly baseResolverService: BaseResolverService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
  ) {}

  public async resolve() {}

  public isFailed(params: NotFoundResolverParams) {
    const id = this.baseResolverService.getId(params)
    const repo = this.entityManager.getRepository(params.model)
    const result = !repo.find(id)
    if (result && params.redirectToIfNotFound) {
      this.routerService.push(params.redirectToIfNotFound)
      return true
    }
    return result
  }
}

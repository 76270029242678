import { NumberField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.SESSION_STAT)
export default class SessionStat extends BaseModel {
  @NumberField()
  public failed: number

  @NumberField()
  public accepted: number

  @NumberField()
  public rejected: number

  @NumberField()
  public delivered: number

  @NumberField()
  public scheduled: number

  @NumberField()
  public all: number

  @NumberField()
  public sent: number
}

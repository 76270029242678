import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable, SingleModelResolverParams } from '@/services/resolvers/types'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type LoggerService from '@/services/loggerService'
import type ModelPreloaderService from '@/services/preloaders/modelPreloaderService'
import { isServicePreloadable } from '@/services/serviceGuards'
import type BaseResolverService from '@/services/resolvers/baseResolverService'

@injectable()
export default class SingleModelResolverService implements Resolvable<SingleModelResolverParams> {
  constructor(
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.BaseResolverService) protected readonly baseResolverService: BaseResolverService,
  ) {}

  public async resolve(params: SingleModelResolverParams) {
    const id = this.baseResolverService.getId(params)
    if (!id) {
      return this.loggerService.log('resolver', `Not found id for ${params.source}`)
    }

    const service = this.baseResolverService.getDomainService(params.model)

    if (isServicePreloadable(service)) {
      return params.cache === false ? service.reloadById(id) : service.preloadById(id)
    }
    const preloader = this.preloaderManager.getPreloader<ModelPreloaderService>(params.model)
    return params.cache === false ? preloader.reloadById(params.model, id) : preloader.preloadById(params.model, id)
  }

  public isFailed(params: SingleModelResolverParams) {
    const id = this.baseResolverService.getId(params)
    if (!id) {
      return false
    }
    return this.preloaderManager.getPreloader<ModelPreloaderService>(params.model).isFailedById(params.model, id)
  }
}

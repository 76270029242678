import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  // Resolvers
  ScheduledDetailsResolverService,
}

export type ScheduledDetailsServices = keyof typeof SERVICES

export const SCHEDULED_DETAILS_SERVICE_TYPES: Record<ScheduledDetailsServices, symbol> =
  makeServicesTypes<ScheduledDetailsServices>(enumKeysToArray<ScheduledDetailsServices>(SERVICES))

import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import type TenDlcPricingRepository from '@/data/repositories/domain/services/regulations/tenDlcPricingRepository'
import TenDlcPricing from '@/data/models/domain/services/regulations/TenDlcPricing'
import type { Nullable } from '@/types'

@injectable()
@DomainSettings({
  model: TenDlcPricing,
})
export default class TenDlcPricingService extends DomainBaseService<TenDlcPricingRepository> {
  private id = '1'

  public readonly minimumRequiredCreditForCa = 20

  public async fetchPrices(): Promise<TenDlcPricing> {
    const response = await this.getDomainRepository().fetchPrices()
    const prices: TenDlcPricing = response.data as TenDlcPricing
    await this.getDomainRepository().insertOrUpdate([{ ...prices, id: this.id }])

    return prices
  }

  public getPrices(): Nullable<TenDlcPricing> {
    return this.getDomainRepository().findEntityByIdOrNull(this.id)
  }

  public getAppliedPrices() {
    return this.getPrices()
  }
}

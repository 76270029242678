import { omit } from 'lodash-es'
import type { ComponentObjectPropsOptions, PropType } from '@/composition/vue/compositionApi'
import type { RegisteredServices } from '@/core/container/types'
import type { DefaultColumn } from '@/core/tables/types'

export const TmTableProps = {
  hideCheckboxes: {
    type: Boolean,
    default: false,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  hidePerPage: {
    type: Boolean,
    default: false,
  },
  itemsPerPageOptions: {
    type: Array as PropType<number[]>,
    default: () => [10, 25, 50, 100, 250],
  },
  paginationLink: {
    type: String,
    default: '',
  },
  withScroll: {
    type: Boolean,
    default: true,
  },
  wrapComponent: {
    type: String,
    default: '',
  },
  actionsWidth: {
    type: Number,
    default: 40,
  },
  bottomLoader: {
    type: Boolean,
    default: false,
  },
  hideActionsCol: {
    type: Boolean,
    default: false,
  },
  hideSelectedRows: {
    type: Boolean,
    default: false,
  },
  singleSelection: {
    type: Boolean,
    default: false,
  },
  disableScrollToTop: {
    type: Boolean,
  },
  fixedLayout: {
    type: Boolean,
    default: false,
  },
} as const satisfies ComponentObjectPropsOptions

export const TmRowProps = {
  rowId: {
    type: String,
    required: true,
  },
  serviceId: {
    type: String as PropType<RegisteredServices>,
    required: true,
  },
  hideCheckbox: {
    type: Boolean,
    default: false,
  },
  singleSelection: {
    type: Boolean,
    default: false,
  },
  hideActionsCol: {
    type: Boolean,
    default: false,
  },
  scrolledToRight: {
    type: Boolean,
  },
  isTableDisabled: {
    type: Boolean,
    default: false,
  },
  isExpanded: {
    type: Boolean,
    default: false,
  },
  searchFields: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  searchQuery: {
    type: String,
    default: '',
  },
  visibleColumns: {
    type: Array as PropType<DefaultColumn[]>,
    required: true,
  },
  selectedRowIds: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
} as const satisfies ComponentObjectPropsOptions

export const TmTableSkeletonProps = {
  serviceId: {
    type: String as PropType<RegisteredServices>,
    required: true,
  },
  bodyOnly: {
    type: Boolean,
  },
  hasActions: {
    type: Boolean,
    default: true,
  },
  hideCheckboxes: {
    type: Boolean,
    default: false,
  },
  hideGradient: {
    type: Boolean,
    default: false,
  },
  rowsToDisplay: {
    type: Number,
  },
} as const satisfies ComponentObjectPropsOptions

export const TmTableAbstractSkeletonProps = omit(TmTableSkeletonProps, ['serviceId', 'bodyOnly'])

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum CONTACT_LIST_SERVICES {
  ContactListService,
  ContactListTempService,
  ContactListRepository,
  ContactListTempRepository,
  ListColumnServiceFactory,
  ListFormService,
  ContactListDetailsGridable,
  ContactListExportable,
  AllContactListColumnServiceFactory,
  ContactListGridable,
  ContactListMoveCopyFormService,
  ContactListTempDetailsGridableService,
  SelectingContactsListsColumnServiceFactory,
  ContactsInListBulkManager,
  ContactsInListBulkService,
  ContactListResolver,
}

export type ContactListServices = keyof typeof CONTACT_LIST_SERVICES

export const CONTACT_LIST_SERVICES_TYPES = makeServicesTypesFromEnum<ContactListServices>(CONTACT_LIST_SERVICES)

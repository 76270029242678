import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type AuthService from '@/services/auth/authService'
import type { AuthByOAuthProviderResolverParams } from '@/services/resolvers/domain/auth/types'
import type RouterService from '@/services/route/routerService'
import { TmApiError } from '@/core/error/transport/tmApiError'
import { TmApiAccessError } from '@/core/error/transport/tmApiAccessError'
import { TmApiUserBlockedError } from '@/core/error/transport/tmApiUserBlockedError'
import type RestrictionPagesRouterService from '@/services/auth/restrictionPagesRouterService'
import type WindowService from '@/services/browser/windowService'
import type { Dict } from '@/types'
import { TM_STAGING_HOST } from '@/constants/constants'
import { TmAuthSsoOnlyAvailableError } from '@/core/error/transport/auth/TmAuthSsoOnlyAvailableError'

@injectable()
export default class AuthByProviderResolver implements Resolvable<AuthByOAuthProviderResolverParams> {
  constructor(
    @inject(SERVICE_TYPES.AuthService) protected readonly authService: AuthService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.RestrictionPagesRouterService)
    protected readonly restrictionPagesRouterService: RestrictionPagesRouterService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
  ) {}

  public async resolve(params: AuthByOAuthProviderResolverParams) {
    // https://textmagic.atlassian.net/wiki/spaces/~712020e2e7113a5db44788b7472047508b179c/pages/2327314452/OAuth+on+staging+and+feature+stands
    if (this.needToRedirect()) {
      const query = this.routerService.getCurrentRoute().query as Dict<string>
      const redirectUrl = `${query.state}?${new URLSearchParams(query).toString()}`
      this.windowService.redirect(redirectUrl)
      return
    }

    const route = this.routerService.getCurrentRoute()
    try {
      await this.authService.authByOAuthProvider(params.provider, route.query)
    } catch (error) {
      if (error instanceof TmAuthSsoOnlyAvailableError) {
        await this.routerService.replace({ name: 'auth.loginSSO', query: { errorMessage: error.message } })
        return
      }

      if (error instanceof TmApiAccessError) {
        await this.restrictionPagesRouterService.goToUnavailableLocationRoute('replace')
        return
      }

      if (error instanceof TmApiUserBlockedError) {
        await this.restrictionPagesRouterService.goToAccountSuspendedRoute()
        return
      }

      if (error instanceof TmApiError) {
        const errorMessage = error.message
        await this.routerService.push({ name: 'auth.login', query: { errorMessage } })
      }
    }
  }

  private needToRedirect(): boolean {
    return (
      this.windowService.getLocation().href.startsWith(TM_STAGING_HOST) &&
      !!this.routerService.getCurrentRoute().query.state
    )
  }
}

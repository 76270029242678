/* eslint-disable tp/import-from-vue-composition-only */
import { defineAsyncComponent, type AsyncComponentLoader, type Component } from 'vue'
import type {
  AsyncComponentDefinition,
  ReloadableAsyncComponentContext,
  ReloadableAsyncComponentDefinition,
} from '@/composition/vue/types'
import { makeOfflineRetryableLoader } from '@/utils/import'
import { getLoggerService } from '@/core/container/ioc'
import { isReloadableAsyncComponentDefinition } from '@/composition/vue/types'
import { TmBaseError } from '@/core/error/tmBaseError'

/* eslint-disable @typescript-eslint/naming-convention */
export function defineAsyncComponentRetryable<T extends Component>(
  loader: AsyncComponentLoader<T>,
): ReloadableAsyncComponentDefinition<T> {
  let retryHandler: (() => void) | null = null

  const asyncComponent = defineAsyncComponent({
    loader: makeOfflineRetryableLoader(loader),
    onError(error, retry, fail, attempts) {
      getLoggerService().raw('debug', error)
      if (!isReloadableAsyncComponentDefinition(asyncComponent)) {
        return fail()
      }
      retryHandler = retry
      asyncComponent.__stateChangeListeners.forEach((listener) => listener('error'))
      asyncComponent.__previousLoadError = error
      return true
    },
  }) as AsyncComponentDefinition<T>

  const originalAsyncLoader = asyncComponent.__asyncLoader

  return Object.assign<AsyncComponentDefinition<T>, ReloadableAsyncComponentContext<T>>(asyncComponent, {
    __stateChangeListeners: [],
    __retry() {
      if (!retryHandler) {
        throw new TmBaseError('Retry handler not set')
      }
      return retryHandler()
    },
    __onStateChange(cb: (state: 'resolved' | 'error') => void) {
      this.__stateChangeListeners.push(cb)
    },
    __asyncLoader(): Promise<T> {
      return new Promise<T>((resolve) => {
        originalAsyncLoader().then((res) => {
          this.__previousLoadError = null
          resolve(res)
          this.__stateChangeListeners.forEach((listener) => listener('resolved'))
        })
      })
    },
    __clearListeners() {
      this.__stateChangeListeners = []
    },
    __previousLoadError: null,
  })
}

import { injectable } from 'inversify'
import BaseFilterRepository from '@/data/repositories/filters/baseFilterRepository'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import { ArrayFilterModel } from '@/data/models/filters/ArrayFilterModel'

@RepoSettings<Endpoint>({
  model: ArrayFilterModel,
})
@injectable()
export class ArrayFilterRepository extends BaseFilterRepository {}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import SessionShort from '@/data/models/domain/session/SessionShort'

@RepoSettings<Endpoint>({
  model: SessionShort,
})
@injectable()
export default class SessionShortRepository extends OrmApiRepository<SessionShort> {}

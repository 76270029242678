import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'

export const gettingStartedRoutName = 'user.gettingStarted'

export const getGettingStartedRoutes = (): RouteRecordRaw[] => [
  {
    name: gettingStartedRoutName,
    path: 'getting-started',
    props: true,
    meta: {
      tags: ['USER_TOP_MENU'],
      tabTitle: 'pageTabTitle.user.gettingStarted',
      accessControlGroups: ['GettingStartedStrategy'],
      modules: [
        AppModule.TenDlcTollFreeRequest,
        AppModule.ByocHelper,
        AppModule.Byoc,
        AppModule.StripeBilling,
        AppModule.Compose,
        AppModule.WhatsApp,
        AppModule.Facebook,
      ],
    },
    component: () => import('@/components/views/gettingStarted/GettingStarted.vue'),
  },
]

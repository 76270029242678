import { inject, injectable } from 'inversify'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import { getTreePathForObjectField } from '@/utils/getTreePathForObjectField'
import type { PropertyBuilder } from '@/services/forms/baseForm/typedFormBuilder/PropertyBuilder'
import type { GroupPropertyBuilder } from '@/services/forms/baseForm/typedFormBuilder/GroupPropertyBuilder'
import { formNameBuilder } from '@/services/forms/baseForm/typedFormBuilder/helpers'
import type {
  PropertyBuilderInterface,
  TypedFormBuilderInterface,
} from '@/services/forms/baseForm/typedFormBuilder/types'
import { SERVICE_TYPES } from '@/core/container/types'

@injectable()
export class TypedFormBuilderService<TFormScheme> implements TypedFormBuilderInterface<TFormScheme> {
  private formBuilder: FormBuilderInterface

  constructor(
    @inject(SERVICE_TYPES.GroupPropertyBuilderFactory)
    protected readonly groupPropertyBuilderFactory: <T>() => GroupPropertyBuilder<T>,
    @inject(SERVICE_TYPES.PropertyBuilderFactory)
    protected readonly propertyBuilderFactory: <T>() => PropertyBuilder<T>,
  ) {}

  public setDependencys(formBuilder: FormBuilderInterface) {
    this.formBuilder = formBuilder
  }

  public property<R>(callback: (t: TFormScheme) => R): PropertyBuilderInterface<R> {
    const pathKeys = getTreePathForObjectField<TFormScheme>(callback)
    if (pathKeys.length > 1) {
      const pathForGroup = formNameBuilder(pathKeys.filter((_, index) => index !== pathKeys.length - 1))
      const groupPropertyBuilder = this.groupPropertyBuilderFactory<R>()
      groupPropertyBuilder.setDependencys(this.formBuilder, pathForGroup, pathKeys[pathKeys.length - 1])
      return groupPropertyBuilder
    }
    const path = formNameBuilder(pathKeys)
    const propertyBuilder = this.propertyBuilderFactory<R>()
    propertyBuilder.setDependencys(this.formBuilder, path)
    return propertyBuilder
  }
}

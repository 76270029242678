import { inject, injectable } from 'inversify'
import BaseFormService from '@/services/forms/baseFormService'
import type FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import type { ParamsPartial } from '@/types'
import type { ServiceDistributionRecipientsFormSchema } from '@/services/domain/services/distribution/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type LoggerService from '@/services/loggerService'
import type RecipientService from '@/services/domain/messages/recipientService'
import type DistributionDetailsService from '@/services/domain/services/distribution/distributionDetailsService'

@injectable()
export default class ServiceDistributionRecipientsFormService extends BaseFormService<
  FormBuilderService,
  ParamsPartial,
  ServiceDistributionRecipientsFormSchema
> {
  constructor(
    @inject(SERVICE_TYPES.FormBuilderFactoryService) formBuilderFactoryService: () => FormBuilderService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.DistributionDetailsService)
    protected readonly distributionDetailsService: DistributionDetailsService,
    @inject(SERVICE_TYPES.RecipientService) protected readonly recipientService: RecipientService,
  ) {
    super(formBuilderFactoryService, em, validationRulesBuilderService, logger)
  }

  public async buildForm() {
    const typedBuilder = this.getTypedBuilder()

    typedBuilder
      .property((t) => t.name)
      .control({
        fieldType: 'text',
        value: '',
        validators: this.validationRulesBuilderService.createRules().required(),
      })

    typedBuilder
      .property((t) => t.recipients)
      .control({
        fieldType: 'tagsRecipient',
        value: [],
        validators: this.validationRulesBuilderService.createRules().required(),
      })
  }

  public async populateFromEntityId(entityId: string) {
    const distribution = this.distributionDetailsService.findEntityById(entityId)

    const recipients = await this.recipientService.makeRecipientOptions(distribution.recipients)

    this.populate({
      name: distribution.name,
      recipients,
    })
  }

  public async submit() {}
}

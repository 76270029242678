import { OrmModel, StringField, AttrField, BelongsToField, BooleanField, NumberField } from 'vuex-orm-decorators'
import type { CountryCode } from 'libphonenumber-js'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import Country from '@/data/models/domain/Country'
import Timezone from '@/data/models/domain/Timezone'
import { type UserCurrency, type UserTimeFormat, UserStatus, UserRole } from '@/services/domain/user/types'
import { getFullName } from '@/utils/fullName'

@OrmModel(modelNames.USER)
export default class User extends BaseModel {
  @StringField('')
  public id: string

  @AttrField()
  public firstName: string | null

  @AttrField()
  public lastName: string | null

  @StringField()
  public username: string

  @StringField()
  public email: string

  @AttrField(null)
  public phone: string | null

  @AttrField(null)
  public company: string | null

  @NumberField(0)
  public balance: number

  @NumberField(0)
  public pendingAmount: number

  @StringField()
  public avatarId: string

  @AttrField(null)
  public countryId: CountryCode

  @BelongsToField(Country, 'countryId')
  public country: Country

  @StringField('')
  public timezoneId: string

  @BelongsToField(Timezone, 'timezoneId')
  public timezone: Timezone

  @StringField()
  public displayTimeFormat: UserTimeFormat

  @AttrField()
  public currency: UserCurrency

  @StringField()
  public status: UserStatus

  @StringField()
  public subaccountType: UserRole

  @BooleanField()
  public emailAccepted: boolean

  @BooleanField()
  public isShowShared: boolean

  @BooleanField()
  public phoneAccepted: boolean

  @StringField()
  public lastActive: string

  @StringField()
  public createdAt: string

  @BooleanField()
  public isRemoved: boolean

  @BooleanField()
  public hasClickToTextForms: boolean

  public get fullName(): string {
    return User.getFullName(this)
  }

  public static getFullName(user: Pick<User, 'firstName' | 'lastName' | 'email'>, withoutEmail?: boolean): string {
    if (!user.firstName && !user.lastName) {
      return withoutEmail ? '' : user.email
    }
    return getFullName(user.firstName, user.lastName)
  }

  public get isAllowEditSubAccount(): boolean {
    return this.subaccountType !== UserRole.SUPER_ADMIN
  }

  public get isAllowDetailsPage(): boolean {
    return this.status !== UserStatus.ST_SUSPEND && this.status !== UserStatus.ST_FRAUD
  }

  public get isCriticallyLowBalance() {
    return User.checkCriticallyLowBalance(this)
  }

  public static checkCriticallyLowBalance(user: Pick<User, 'status' | 'balance'>) {
    return (
      (user.status === UserStatus.ST_TRIAL && user.balance < 0.2) ||
      (user.status === UserStatus.ST_ACTIVE && user.balance < 5)
    )
  }
}

import { NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import KanbanStageBase from '@/data/models/domain/kanban/kanbanStageBase'
import type { DealStageType } from '@/data/models/domain/deals/types'

@OrmModel(modelNames.DEAL_STAGE)
export default class DealStage extends KanbanStageBase {
  @StringField(null, (value) => `${value}`)
  public dealPipelineId: string

  @NumberField()
  public probability: number

  @StringField()
  public type: DealStageType

  public get isSystemStage(): boolean {
    const systemStages: DealStageType[] = ['system_fail', 'system_success']
    return systemStages.includes(this.type)
  }
}

import { get } from 'lodash-es'
import type { CustomErrorRule } from '@/core/error/transport/types'
import { EndpointsEnum } from '@/services/endpoints'
import { TmFileUploadMaxSizeError } from '@/core/error/transport/customErrors/tmFileUploadMaxSizeError'

export const fileUploadMaxFileSizeErrorRule: CustomErrorRule = {
  endpoint: EndpointsEnum.uploadFile,
  methods: ['post'],
  klass: TmFileUploadMaxSizeError,
  predicateFn: (error) => {
    const fileSizeErrorMessage = get(error, 'response.data.errors.fields.file.0') || ''
    return fileSizeErrorMessage.toLowerCase().indexOf('file size') !== -1
  },
}

<template>
  <div
    class="chat-content-empty"
    :class="{
      'chat-content-empty__channels': showChannels,
      'chat-content-empty__channels--bg-color': !settings?.themeColor,
      'chat-content-empty__channels--bg-pattern': !settings?.showThemePattern,
    }"
  >
    <chat-content-channels v-if="showChannels" />
    <div
      v-else
      class="chat-content-empty__block"
    >
      <tm-icon
        :name="icon"
        custom-size="72px"
        class="mb-4"
        color="gray4"
      />

      <h3 class="lh-24 mb-2">
        {{ title }}
      </h3>

      <div class="body-text-regular-14 lh-20 distinct--text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TmIcon from '@/components/shared/TmIcon.vue'
import type { IconName } from '@/assets/icons/icons'
import ChatContentChannels from '@/components/domain/chats/content/contentEmpty/ChatContentChannels.vue'
import { getChatSettingsService } from '@/core/container/ioc'

defineProps<{
  icon: IconName
  title?: string
  text?: string
  showChannels?: boolean
}>()

const chatSettingsService = getChatSettingsService()
const settings = chatSettingsService.getChatSettings()
</script>

<style lang="scss" scoped>
.chat-content-empty {
  height: 100%;
  overflow-y: auto;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 $chatContentPaddingX;

  &__block {
    position: relative;
    padding: 40px;
    background-color: rgba($white, 0.5);
    border-radius: $border-radius-lg;
    max-width: 400px;
  }

  &__channels {
    padding: 32px 0;
    justify-content: start;

    @include media-xl-max {
      padding: 20px 20px;
    }
  }

  &__channels--bg-color {
    background: linear-gradient(233.56deg, #cbdae5 14.58%, #e3eaef 64.59%);
  }

  &__channels--bg-pattern::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url('@/assets/images/chat-pattern.webp');
    opacity: 0.05;
  }
}
</style>

import {
  AttrField,
  BelongsToField,
  BooleanField,
  HasManyByField,
  NumberField,
  OrmModel,
  StringField,
} from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { TicketParticipantPersonalInfo, TicketStatusIdType } from '@/data/models/domain/tickets/types'
import type { PriorityEnum } from '@/data/models/domain/types'
import type { Nullable } from '@/types'
import TicketStatus from '@/data/models/domain/tickets/TicketStatus'
import { getFullName } from '@/utils/fullName'
import Tag from '@/data/models/domain/Tag'

@OrmModel(modelNames.TICKET)
export default class Ticket extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public subject: string

  @StringField()
  public priority: PriorityEnum

  @StringField()
  public statusId: TicketStatusIdType

  @BelongsToField(TicketStatus, 'statusId')
  public status: TicketStatus

  @BooleanField()
  public isRead: boolean

  @NumberField()
  public assigneeId: Nullable<number>

  @AttrField(null)
  public assignee: Nullable<TicketParticipantPersonalInfo>

  @AttrField()
  public requesterId: number | null

  @AttrField()
  public requester: TicketParticipantPersonalInfo | null

  @StringField()
  public requesterEmail: string

  @StringField()
  public inboxId: number

  @AttrField()
  public inbox: { id: number; email: string }

  @StringField(null)
  public solvedAt: string | null

  @StringField(null)
  public dueDate: string | null

  @StringField()
  public createdAt: string

  @StringField()
  public updatedAt: string

  @AttrField([])
  public carbonCopyIds: number[]

  @AttrField([])
  public carbonCopies: TicketParticipantPersonalInfo[]

  @NumberField()
  public createdById: number

  @AttrField(null)
  public createdBy: TicketParticipantPersonalInfo | null

  @NumberField()
  public updatedById: number

  @AttrField(null)
  public updatedBy: TicketParticipantPersonalInfo | null

  @BooleanField()
  public isDemo: boolean

  @StringField()
  public workspaceTicketId: string

  @AttrField([], (value: number[]) => value?.map((item) => item.toString()))
  public tagIds: string[]

  @HasManyByField(Tag, 'tagIds')
  public tags: Tag[]

  public get isSpam() {
    return this.status.isSpam
  }

  public get isSolved() {
    return this.status.isSolved
  }

  public get isClosed() {
    return this.status.isClosed
  }

  public get isDraft() {
    return this.status.isDraft
  }

  public get canBeMarkedAsSpam() {
    return !this.isClosed && !this.isDraft && !this.isSpam && !this.isDemo
  }

  public get canBeMarkedAsNotSpam() {
    return this.isSpam
  }

  public get canBeSolved() {
    return !this.isDraft && !this.isSolved && !this.isClosed
  }

  public get canBeRead() {
    return !this.isRead && !this.isSpam
  }

  public get canBeUnread() {
    return this.isRead && !this.isSpam && !this.isClosed
  }

  public get canBeCreateTask() {
    return !this.isSpam
  }

  public get canBeEditStatus() {
    return !this.isDraft && !this.isClosed && !this.isSpam
  }

  public get canBeEditAssignee() {
    return !this.isClosed && !this.isSpam
  }

  public get canBeEditPriority() {
    return !this.isClosed && !this.isSpam
  }

  public get canBeEditCarbonCopies() {
    return !this.isClosed && !this.isSpam
  }

  public get canBeEditDueDate() {
    return !this.isClosed && !this.isSpam
  }

  public get canBeEditTag() {
    return !this.isClosed && !this.isSpam
  }

  public get canBeEditContact() {
    return this.requesterId !== null
  }

  public getRequesterFullName() {
    return (
      getFullName(this.requester?.firstName, this.requester?.lastName) || this.requester?.email || this.requesterEmail
    )
  }

  public getUpdatedByFullName() {
    return getFullName(this.updatedBy?.firstName, this.updatedBy?.lastName) || this.updatedBy?.email
  }
}

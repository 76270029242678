import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import CounterRepository from '@/data/repositories/table/counterRepository'
import DeliveredSmsCounter from '@/data/models/domain/counters/DeliveredSmsCounter'

@RepoSettings<Endpoint>({
  model: DeliveredSmsCounter,
})
@injectable()
export default class DeliveredSmsCounterRepository extends CounterRepository<DeliveredSmsCounter> {}

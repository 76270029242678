import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import AreaCodes from '@/data/models/domain/AreaCodes'
import type AreaCodesRepository from '@/data/repositories/domain/areaCodesRepository'

@DomainSettings({
  model: AreaCodes,
})
@injectable()
export default class AreaCodesService extends DomainBaseService<AreaCodesRepository> {}

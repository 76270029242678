import { inject, injectable } from 'inversify'
import { BrowserMicroSentryClient } from '@micro-sentry/browser'
import type { User as MicrosentryUser } from '@micro-sentry/core'
// eslint-disable-next-line tp/forbid-import-composable-to-service,tp/using-vue-in-services-restriction
import type { App } from '@/composition/vue/compositionApi'
import type { Config } from '@/core/types'
import type { MonitoringServiceInterface } from '@/services/monitoring/types'
import type User from '@/data/models/domain/User'
import { SERVICE_TYPES } from '@/core/container/types'
import { LogLevel } from '@/core/logger/types'

@injectable()
export class MicroSentryMonitoringService implements MonitoringServiceInterface {
  private microSentryClient: BrowserMicroSentryClient | null

  private errorEventListener: (evt: ErrorEvent) => unknown

  private unhandledRejectionEventListener: (evt: PromiseRejectionEvent) => unknown

  private ignoreErrors = [
    'ResizeObserver loop limit exceeded', // We can safely ignore this error: see https://github.com/WICG/resize-observer/issues/38 for details
    'ResizeObserver loop completed with undelivered notifications.', // A variant of the above error, see https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
    'Non-Error promise rejection captured', // https://github.com/getsentry/sentry-javascript/issues/3440
  ]

  constructor(@inject(SERVICE_TYPES.Config) protected readonly config: Config) {}

  public init(_app: App): void {
    this.microSentryClient = new BrowserMicroSentryClient({
      dsn: this.config.sentry.dsn,
      environment: APP_BUILD_ENV,
      release: [APP_NAME, APP_VERSION].join('@'),
      ignoreErrors: this.ignoreErrors,
    })
    this.errorEventListener = this.errorEventHandler.bind(this)
    this.unhandledRejectionEventListener = this.unhandledRejectionEventHandler.bind(this)
    window.addEventListener('error', this.errorEventListener)
    window.addEventListener('unhandledrejection', this.unhandledRejectionEventListener)
  }

  public setUser(user: User | null) {
    this.microSentryClient?.setUser(user ? (user as unknown as MicrosentryUser) : {})
  }

  public logInfo(message: string, logLevel: LogLevel = LogLevel.INFO) {
    if (logLevel === LogLevel.NULL) return
    this.microSentryClient?.captureMessage(message)
  }

  public logError(error: Error, logLevel: LogLevel = LogLevel.ERROR) {
    if (!error || logLevel === LogLevel.NULL) {
      return
    }
    this.microSentryClient?.report(error)
  }

  public destroy() {
    window.removeEventListener('error', this.errorEventListener)
    window.removeEventListener('unhandledrejection', this.unhandledRejectionEventListener)
    this.microSentryClient?.destroy()
  }

  private errorEventHandler(evt: ErrorEvent) {
    let err: any = evt // special cast to any. MicroSentryClient can report objects too
    if (!evt?.message) {
      err = { ...evt, message: "The error was caused due to an 'error' event", stack: evt.error }
    }
    this.logError(err)
    return false
  }

  private unhandledRejectionEventHandler(evt: PromiseRejectionEvent) {
    let err: any = evt // special cast to any. MicroSentryClient can report objects too
    if (!evt?.reason) {
      err = { ...evt, reason: "The error was caused due to an 'unhandledrejection' event", stack: evt }
    }
    this.logError(err)
  }
}

import { inject, injectable } from 'inversify'
import { difference } from 'lodash-es'
import { SERVICE_TYPES } from '@/core/container/types'
import type OrmApiRepository from '@/data/repositories/ormApiRepository'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type { Preloadable } from '@/services/preloaders/types'
import type { DomainServiceSettings } from '@/decorators/types'
import { TmDomainError } from '@/core/error/tmDomainError'
import type { ModelRelationArray, RepoModel } from '@/services/domain/types'
import type BaseModel from '@/data/models/BaseModel'
import type { TransportConfig } from '@/services/transport/types'
import { ModelEventType } from '@/services/transport/types'
import type { LowLevelUpdateBody } from '@/services/vuex/types'
import type { IDataProvider } from '@/services/types'
import type { Dict } from '@/types'

@injectable()
export default abstract class DomainBaseService<T extends OrmApiRepository> implements IDataProvider<RepoModel<T>> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
  ) {}

  public getDomainSettings(): DomainServiceSettings {
    throw new TmDomainError('Please use decorator @DomainSettings')
  }

  public getModel(): typeof BaseModel {
    if (!this.getDomainSettings().model) {
      throw new TmDomainError('Composite domain service must be without model')
    }

    return this.getDomainSettings().model!
  }

  public getDomainRepository(): T {
    if (!this.getDomainSettings().model) {
      throw new TmDomainError('Composite domain service must be without repository')
    }

    return this.entityManager.getRepository<T>(this.getDomainSettings().model!)
  }

  public async delete(id: string, config?: TransportConfig, beforeStoreUpdate?: () => Promise<any> | any) {
    await this.getDomainRepository().deleteRequest(id, config)
    await beforeStoreUpdate?.()
    this.deleteFromStore([id])
  }

  public deleteFromStore(ids: string[]) {
    this.getDomainRepository().delete(ids)
    this.notify(ids, ModelEventType.DELETE)
  }

  public deleteAllFromStore() {
    this.getDomainRepository().deleteAll()
    this.notify([], ModelEventType.BULK_DELETE)
  }

  public getPreloader<R extends Preloadable>(): R {
    return this.preloaderManager.getPreloader(this.getModel()) as R
  }

  protected notify(
    ids: string[],
    eventType: ModelEventType = ModelEventType.UPDATE,
    model: typeof BaseModel = this.getModel(),
  ) {
    this.subscription.emitByModel(model, { ids, eventType })
  }

  /**
   * @deprecated use findEntityByIdOrFail/findEntityByIdOrNull
   */
  public findEntityById(id: string, withRelations: ModelRelationArray<RepoModel<T>> = []): RepoModel<T> {
    return this.getDomainRepository().query().with(withRelations).find(id) as RepoModel<T>
  }

  public findEntitiesByIds(ids: string[], withRelations: ModelRelationArray<RepoModel<T>> = []) {
    return this.getDomainRepository().query().with(withRelations).findIn(ids) as RepoModel<T>[]
  }

  public async getEntitiesByIds(
    ids: string[],
    withRelations: ModelRelationArray<RepoModel<T>> = [],
    saveFetched?: boolean,
  ): Promise<RepoModel<T>[]> {
    const repoEntities = this.getDomainRepository().findIn(ids, withRelations)
    if (ids.length === repoEntities.length) {
      return repoEntities as RepoModel<T>[]
    }

    const notFoundIds = difference(
      ids,
      repoEntities.map(({ id }) => id),
    )
    const fetchedEntities =
      notFoundIds.length === 1 && this.getDomainRepository().settings().single
        ? [await this.requestEntityById(notFoundIds[0])]
        : await this.getDomainRepository().readByFilter<RepoModel<T>>([
            {
              ids: { in: notFoundIds },
            },
          ])

    if (saveFetched) {
      await this.saveEntitiesToStore(fetchedEntities)
      return this.getDomainRepository().findIn(ids, withRelations) as RepoModel<T>[]
    }

    return [...repoEntities, ...fetchedEntities] as RepoModel<T>[]
  }

  public async getEntityById(
    id: string,
    withRelations: ModelRelationArray<RepoModel<T>> = [],
    saveFetched?: boolean,
  ): Promise<RepoModel<T> | null> {
    const entities = await this.getEntitiesByIds([id], withRelations, saveFetched)
    if (entities.length) {
      return entities[0]
    }

    return null
  }

  // Used for cases when BE sends ids list that it doesn't have (it sends error on fetch entities request)
  public async getEntitiesByIdsFailSafe(
    ids: string[],
    withRelations: ModelRelationArray<RepoModel<T>> = [],
    saveFetched?: boolean,
  ): Promise<RepoModel<T>[]> {
    try {
      return await this.getEntitiesByIds(ids, withRelations, saveFetched)
    } catch {
      return []
    }
  }

  public requestEntityById(id: string) {
    return this.getDomainRepository().getRequest<RepoModel<T>>(id)
  }

  public saveEntitiesToStore(entities: RepoModel<T>[]) {
    return this.getDomainRepository().insertOrUpdate(entities)
  }

  public saveEntityToStore(entity: RepoModel<T>) {
    return this.saveEntitiesToStore([entity])
  }

  public fill(id: string, withRelations: ModelRelationArray<RepoModel<T>> = []) {
    return this.getDomainRepository().fill(id, withRelations) as Promise<RepoModel<T>>
  }

  public async fillAll() {
    await this.getDomainRepository().fillAll()
    return this.findAll()
  }

  public findAll() {
    return this.getDomainRepository().all() as RepoModel<T>[]
  }

  public fetchAllItems() {
    return this.getDomainRepository().fetchAllItems() as Promise<RepoModel<T>[]>
  }

  public insertOrUpdate(payload: LowLevelUpdateBody<T extends OrmApiRepository<infer R> ? R : never>[]) {
    return this.getDomainRepository().insertOrUpdate(payload)
  }

  public insertOrUpdateItem(item: LowLevelUpdateBody<T extends OrmApiRepository<infer R> ? R : never>) {
    return this.insertOrUpdate([item])
  }

  public updateItemIfExists(item: LowLevelUpdateBody<T extends OrmApiRepository<infer R> ? R : never>) {
    return this.getDomainRepository().updateItemIfExists(item)
  }

  public async fetchAndInsertOrUpdateItem(id: string) {
    const item = await this.requestEntityById(id)
    return this.insertOrUpdateItem(item)
  }

  public isEmptyStore() {
    return this.getDomainRepository().isEmptyStore()
  }

  public findEntityByIdOrFail(t: string, withRelations?: ModelRelationArray<RepoModel<T>>): RepoModel<T> {
    return this.getDomainRepository().findEntityByIdOrFail(t, withRelations) as RepoModel<T>
  }

  public findEntityByIdOrNull(t: string, withRelations?: ModelRelationArray<RepoModel<T>>): RepoModel<T> | null {
    return this.getDomainRepository().findEntityByIdOrNull(t, withRelations) as RepoModel<T>
  }

  public findEntityOrFail(
    t: (entity: RepoModel<T>) => boolean,
    withRelations?: ModelRelationArray<RepoModel<T>>,
  ): RepoModel<T> {
    return this.getDomainRepository().findEntityOrFail(t as any, withRelations) as RepoModel<T>
  }

  public findEntityOrNull(
    t: (entity: RepoModel<T>) => boolean,
    withRelations?: ModelRelationArray<RepoModel<T>>,
  ): RepoModel<T> | null {
    return this.getDomainRepository().findEntityOrNull(t as any, withRelations) as RepoModel<T>
  }

  public firstOrFail(): RepoModel<T> {
    return this.getDomainRepository().firstOrFail() as RepoModel<T>
  }

  public firstOrNull(): RepoModel<T> | null {
    return this.getDomainRepository().firstOrNull() as RepoModel<T>
  }

  public hasItems() {
    return !!this.firstOrNull()
  }

  public findOrFill(id: string) {
    return this.getDomainRepository().findOrFill(id)
  }

  protected addModelToStore(data: Dict<any>): Promise<RepoModel<T>> {
    return this.getDomainRepository().addModelToStore(data) as Promise<RepoModel<T>>
  }
}

import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import { SmsSurveyRoutes } from '@/routes/user/services/types'
import { getSmsSurveysDetailsRoutes } from '@/routes/user/services/smsSurveysDetails'

export const getSmsSurveysRoutes = (): RouteRecordRaw[] => [
  {
    name: SmsSurveyRoutes.index,
    path: 'sms-surveys',
    redirect: { name: SmsSurveyRoutes.overview },
    children: [
      {
        name: SmsSurveyRoutes.overview,
        path: '',
        meta: {
          tabTitle: 'pageTabTitle.user.services.smsSurveys',
          tags: ['SERVICES_BAR_LIST'],
          modules: [AppModule.SmsSurveys],
          accessControlGroups: ['NotCampaignsSmsSurveysStrategy'],
        },
        component: () => import('@/components/views/services/smsSurveys/SmsSurveys.vue'),
      },
      ...getSmsSurveysDetailsRoutes(),
    ],
  },
]

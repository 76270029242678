import { OrmModel, StringField, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { CustomFieldOption, CustomFieldType } from '@/services/domain/cusomFields/types'

@OrmModel(modelNames.CUSTOM_FIELD_SCHEMA)
export class CustomFieldSchema extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public title: string

  @AttrField()
  public fieldType: CustomFieldType

  @AttrField()
  public options: CustomFieldOption[]

  @StringField()
  public createdAt: string

  @StringField()
  public updatedAt: string
}

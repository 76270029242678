import { TmApiClientError } from '@/core/error/transport/tmApiClientError'

export class TmWhatsAppFlowError extends TmApiClientError {
  public get errorCode(): string {
    return this.data?.data?.errorCode ?? ''
  }

  public get message(): string {
    return this.data?.data?.message ?? this.message
  }
}

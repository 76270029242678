import { inject, injectable } from 'inversify'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type FilterSchemaService from '@/services/filterSchemaService'
import type SearchRepository from '@/data/repositories/searchRepository'
import type { Endpoint } from '@/services/endpoints'
import TmLogicError from '@/core/error/tmLogicError'
import { SERVICE_TYPES } from '@/core/container/types'
import Search from '@/data/models/tables/Search'
import type { BaseSearcherSettings } from '@/services/search/types'
import type { SearcherTableInterface, SearchUrlParams } from '@/core/tables/types'

@injectable()
export default class BaseSearcherServiceFactory implements SearcherTableInterface {
  protected defaultValue: string

  protected settings: BaseSearcherSettings

  private _tableId: string | null = null

  private _endpoint: Endpoint | null = null

  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.FilterSchemaService) protected readonly schema: FilterSchemaService,
  ) {}

  public setSettings(settings: BaseSearcherSettings) {
    this.settings = settings
    this.defaultValue = settings.defaultValue || ''
  }

  public init(params?: SearchUrlParams) {
    this.getRepo().init(this.tableId, this.schema.getSearchFilters(this.endpoint, this.settings.supportedSearchFields))
    if (params) {
      this.applyByUrlParams(params)
    }
  }

  public apply(value?: string) {
    if (typeof value === 'string') {
      this.getRepo().setValue(this.tableId, value.trim())
    }
  }

  public applyByUrlParams(params: SearchUrlParams) {
    this.apply(params.search)
  }

  public toQuery(): SearchUrlParams {
    return {
      search: this.getRepo().getValue(this.tableId, this.defaultValue),
    }
  }

  public reset() {
    this.apply(this.defaultValue)
  }

  public getSearchFields() {
    return this.getRepo().getSearchFields(this.tableId)
  }

  public getSearchQuery() {
    return this.getRepo().getValue(this.tableId, this.defaultValue)
  }

  public isDefaultState() {
    return this.getSearchQuery() === this.defaultValue
  }

  public isEmptyState(): boolean {
    // area of table control over empty state
    return false
  }

  public setTableId(tableId: string) {
    this._tableId = tableId
  }

  public getTableId(): string {
    if (!this._tableId) {
      throw new TmLogicError('No tableId is set for service')
    }

    return this._tableId
  }

  public setEndpoint(endpoint: Endpoint) {
    this._endpoint = endpoint
  }

  protected get endpoint(): Endpoint {
    if (!this._endpoint) {
      throw new TmLogicError('No endpoint is set for service')
    }

    return this._endpoint
  }

  protected get tableId(): string {
    if (!this._tableId) {
      throw new TmLogicError('No tableId is set for service')
    }

    return this._tableId
  }

  protected getRepo() {
    return this.em.getRepository<SearchRepository>(Search)
  }
}

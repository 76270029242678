import { inject, injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import type MessagesPriceRepository from '@/data/repositories/domain/messagesPriceRepository'
import MessagesPrice from '@/data/models/domain/MessagesPrice'
import type {
  MessagesPricePostBody,
  MessagesPriceSpinners,
  MessageTypeSerialized,
} from '@/services/domain/messages/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type SerializerService from '@/services/serializerService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import { useLastPromise } from '@/utils/lastPromise'
import type { Dict } from '@/types'
import ScheduledMessage from '@/data/models/domain/scheduled/ScheduledMessage'
import { ModelEventType } from '@/services/transport/types'

@DomainSettings({
  model: MessagesPrice,
})
@injectable()
export default class MessagesPriceService extends DomainBaseService<MessagesPriceRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.SerializerService) protected readonly serializerService: SerializerService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  private id = 'MessagesPriceRepository'

  private initialState: MessageTypeSerialized = {
    total: 0,
    countries: null,
    loading: [],
  }

  private lastPromise = useLastPromise<Dict>()

  private async getMessagesPrice(params: MessagesPricePostBody) {
    if (!params.contacts && !params.lists && !params.phones && !params.filteredViews) {
      return this.initialState
    }

    const response = await this.getDomainRepository().getMessagesPrice(params)
    return this.serializerService.serialize(this.getModel(), response.data || {}) as MessageTypeSerialized
  }

  public init() {
    return this.getDomainRepository().create({ id: this.id })
  }

  public getState() {
    return this.getDomainRepository().find(this.id) || {}
  }

  public getProperty<T>(property: keyof MessagesPrice) {
    return this.getState()[property] as T
  }

  public updateState(payload: any) {
    this.getDomainRepository().update([
      {
        ...this.getState(),
        ...payload,
      },
    ])
  }

  public resetState() {
    this.updateState(this.initialState)
  }

  public setLoading(value: MessagesPriceSpinners[]) {
    this.getDomainRepository().update([
      {
        ...this.getState(),
        loading: value,
      },
    ])
  }

  public async updateMessagesPrice(params: MessagesPricePostBody, spinners: MessagesPriceSpinners[]) {
    try {
      this.setLoading(spinners)

      const result = await this.lastPromise(this.getMessagesPrice(params))

      this.updateState({
        ...result,
        loading: [],
        total: !params.text && params.destination !== 'mms' ? 0 : result.total,
      })
    } catch (e) {
      this.setLoading([])
      throw e
    }
  }

  public async sendMessageFromFormData(data: MessagesPricePostBody) {
    const isScheduledMessage = !!data.sendingDateTime
    const res = await this.getDomainRepository().createMessageFromFormData(data)
    if (isScheduledMessage) {
      this.subscription.emitByModel(ScheduledMessage, { ids: [], eventType: ModelEventType.CREATE })
    }
    return res
  }

  public async fetchRecipientsCount(params: MessagesPricePostBody): Promise<number> {
    const messagesPrice = await this.getMessagesPrice(params)
    return MessagesPrice.getRecipientsCount(messagesPrice)
  }
}

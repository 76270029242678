import { injectable } from 'inversify'
import { trim } from 'lodash-es'
import type { FeatureFlags } from '@/services/types'
import ConfigReader from '@/core/configReader'

@injectable()
export class FeatureFlagsService {
  public isFeatureEnabled(featureFlag: FeatureFlags): boolean {
    const config = ConfigReader.config()

    const featureFlagValueFromConfig = config.featureFlags[featureFlag]
    if (typeof featureFlagValueFromConfig === 'boolean') {
      return featureFlagValueFromConfig
    }

    const ffValue = localStorage.getItem(`tm::features::${featureFlag}`)
    return Boolean(ffValue) && !['disabled', 'false', '0'].includes(trim(ffValue?.toLowerCase()))
  }
}

import { injectable } from 'inversify'
import {
  isWorkerGenerateExportChartPdfSuccessPayload,
  W_PDF_EXPORT_CHART_GENERATE_MSG,
  type WorkerGenerateExportChartPdfPayload,
} from '@/services/workers/pdf/types'
import { TmPdfWorkerError } from '@/core/error/worker/TmPdfWorkerError'
import PdfWorkerUrl from '@/workers/pdf.worker.ts?worker&url'
import { createWorkerCrossoriginWorkaround } from '@/workers/utils/createWorkerCrossoriginWorkaround'

@injectable()
export class PdfWorkerService {
  private worker: Worker

  public async generatePdfFromCanvas(canvas: HTMLCanvasElement, width: number, height: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new TmPdfWorkerError('Canvas blob is empty'))
          return
        }

        // convert blob to Uint8Array
        const reader = new FileReader()
        reader.readAsArrayBuffer(blob)
        reader.onloadend = () => {
          const arrayBuffer = reader.result as ArrayBuffer
          const uint8Array = new Uint8Array(arrayBuffer)

          resolve(this.generatePdf(uint8Array, width, height))
        }
      })
    })
  }

  public async generatePdf(imageData: Uint8Array, width: number, height: number): Promise<Blob> {
    // Generate random nonce to make sure that we are listening to the correct event
    const nonce = Math.random()

    return new Promise((resolve, reject) => {
      // Acquire the worker instance
      const worker = this.getWorker()

      const eventHandler = (event: MessageEvent<unknown>) => {
        if (isWorkerGenerateExportChartPdfSuccessPayload(event.data) && event.data.nonce === nonce) {
          resolve(event.data.blob)
        }

        worker.removeEventListener('message', eventHandler)
      }

      // Subscribe to events
      worker.addEventListener('message', eventHandler)

      // Send a message to the worker
      worker.postMessage({
        type: W_PDF_EXPORT_CHART_GENERATE_MSG,
        nonce,
        imageData,
        width,
        height,
      } satisfies WorkerGenerateExportChartPdfPayload)
    })
  }

  private getWorker(): Worker {
    if (!this.worker) {
      this.worker = createWorkerCrossoriginWorkaround(PdfWorkerUrl)

      this.worker.addEventListener('messageerror', () => {
        throw new TmPdfWorkerError('Worker received a message that could not be deserialized')
      })

      this.worker.addEventListener('error', (error) => {
        throw new TmPdfWorkerError(error.message)
      })
    }

    return this.worker
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type { TenDlcPricesPreloaderService } from '@/services/resolvers/domain/regulations/tenDlcPricesPreloaderService'

@injectable()
export default class TenDlcPricesResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.TenDlcPricesPreloader)
    protected readonly tenDlcPricingPreloader: TenDlcPricesPreloaderService,
  ) {}

  public async resolve(): Promise<void> {
    await this.tenDlcPricingPreloader.preload()
  }
}

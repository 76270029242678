import { inject, injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import type RegulationsStatusRepository from '@/data/repositories/domain/services/regulations/regulationsStatusRepository'
import RegulationsStatus from '@/data/models/domain/services/regulations/RegulationsStatus'
import {
  TenDlcRegulationState,
  type TenDlcRegulationStatus,
  TollFreeRegulationState,
  type TollFreeRegulationStatus,
} from '@/services/domain/services/regulations/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type { Translatable } from '@/services/types'

@DomainSettings({
  model: RegulationsStatus,
})
@injectable()
export default class RegulationsStatusService extends DomainBaseService<RegulationsStatusRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.TranslateService) private readonly translateService: Translatable,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public fetchStatus(): Promise<RegulationsStatus> {
    return this.getDomainRepository().fetchStatus()
  }

  public async loadStatus(): Promise<RegulationsStatus> {
    return this.getDomainRepository().loadStatus()
  }

  public getStatus(): RegulationsStatus | null {
    return this.getDomainRepository().findStatus()
  }

  public getTenDlcStatus(): TenDlcRegulationStatus | null {
    return this.getStatus()?.tenDlcState || null
  }

  public getTenDlcRequestId(): string | null {
    return this.getStatus()?.tenDlcState?.requestId || null
  }

  public getTollFreeRequestId(): string | null {
    return this.getStatus()?.tollfreeState?.requestId || null
  }

  public getTollFreeStatus(): TollFreeRegulationStatus | null {
    return this.getStatus()?.tollfreeState || null
  }

  public getTenDlcState() {
    return this.getTenDlcStatus()?.state
  }

  public getTollFreeState(): TollFreeRegulationState {
    return this.getTollFreeStatus()?.state || TollFreeRegulationState.ENOUGH_MONEY
  }

  public isNotEnoughMoneyForTollFree(): boolean {
    return this.getTollFreeState() === TollFreeRegulationState.NO_ENOUGH_MONEY
  }

  public isEnoughMoneyForTollFree(): boolean {
    return this.getTollFreeState() === TollFreeRegulationState.ENOUGH_MONEY
  }

  public hasNumberAndNoTollFree(): boolean {
    return this.getTollFreeState() === TollFreeRegulationState.HAS_TOLLFREE_NUMBER_WITHOUT_REQUEST
  }

  public hasUnfinishedTenDlc(): boolean {
    return this.getTenDlcState() === TenDlcRegulationState.HAS_UNSENDED_10DLC_REQUEST
  }

  public hasUnfinishedTollFree(): boolean {
    return this.getTollFreeState() === TollFreeRegulationState.HAS_UNSENDED_TOLLFREE_REQUEST
  }

  public hasTenDlcInProgress(): boolean {
    return this.getTenDlcState() === TenDlcRegulationState.HAS_10DLC_REQUEST_IN_PROGRESS
  }

  public hasTollFreeInProgress(): boolean {
    return this.getTollFreeState() === TollFreeRegulationState.HAS_TOLLFREE_REQUEST_IN_PROGRESS
  }

  public hasTenDlcActionRequired(): boolean {
    return this.getTenDlcState() === TenDlcRegulationState.ACTION_REQUIRED
  }

  public hasTollFreeActionRequired(): boolean {
    return this.getTollFreeState() === TollFreeRegulationState.ACTION_REQUIRED
  }

  public getTollFreeEmptyButtonText(status: TollFreeRegulationState): string {
    const map: Partial<Record<TollFreeRegulationState, string>> = {
      [TollFreeRegulationState.NO_ENOUGH_MONEY]: this.translateService.t('tollFreeEmptyArea.buyCredit'),
      [TollFreeRegulationState.ENOUGH_MONEY]: this.translateService.t('tollFreeEmptyArea.getNumber'),
      [TollFreeRegulationState.HAS_TOLLFREE_NUMBER_WITHOUT_REQUEST]: this.translateService.t(
        'tollFreeEmptyArea.registerBrand',
      ),
      [TollFreeRegulationState.HAS_UNSENDED_TOLLFREE_REQUEST]: this.translateService.t(
        'tollFreeEmptyArea.completeRegistration',
      ),
    }

    return map[status] || this.translateService.t('tollFreeEmptyArea.getNumber')
  }
}

<template>
  <div
    :class="[
      'tm-auto-size-panel-mobile-activator',
      {
        'tm-auto-size-panel-mobile-activator--disabled': disabled,
      },
    ]"
  >
    <tm-button
      v-if="onlyIcon"
      :color="btnColor"
      :no-border="btnNoBorder"
      :disable="disabled"
      :size="size"
      :flat="flat"
      icon-only
    >
      <tm-icon name="more_vert" />
    </tm-button>
    <tm-button
      v-else-if="visibleItemsCount > 1"
      :color="btnColor"
      :no-border="btnNoBorder"
      :disable="disabled"
      :size="size"
      :flat="flat"
    >
      <tm-icon
        name="more_vert"
        left
      />
      <span>{{ $t('common.more') }}</span>
    </tm-button>
    <tm-button
      v-else
      :color="btnColor"
      :disable="disabled"
      :size="size"
      :flat="flat"
    >
      <span>{{ $t('common.actions') }}</span>
      <tm-icon
        name="tmi-arrow-down"
        right
      />
    </tm-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import TmButton from '@/components/shared/TmButton.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import type { ButtonColor, ButtonSize, SizeProp } from '@/components/shared/types'

export default defineComponent({
  components: {
    TmIcon,
    TmButton,
  },
  props: {
    visibleItemsCount: {
      type: Number,
      default: 0,
    },
    btnColor: {
      type: String as PropType<ButtonColor>,
    },
    btnNoBorder: {
      type: Boolean,
    },
    onlyIcon: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    flat: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<ButtonSize>,
      default: '',
    },
  },
})
</script>

<style scoped lang="scss">
.tm-auto-size-panel-mobile-activator {
  &--disabled {
    opacity: 0.6;
  }
}
</style>

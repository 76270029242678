import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { InstagramAccountStatus } from '@/data/models/domain/messenger/instagram/types'

@OrmModel(modelNames.INSTAGRAM_ACCOUNT)
export default class InstagramAccount extends BaseModel {
  @StringField()
  public name: string

  @StringField()
  public status: InstagramAccountStatus

  @StringField()
  public picture: string

  @StringField()
  public link: string

  @StringField()
  public chatLink: string

  @StringField()
  public platformAccountId: string

  @StringField()
  public platformAccountName: string

  @StringField()
  public createdAt: string

  @StringField()
  public updatedAt: string
}

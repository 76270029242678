import type { DirectiveBinding, ObjectDirective } from '@/composition/vue/compositionApi'
import { getUuid } from '@/utils/uuid'
import { type TooltipData, useTooltipManager } from '@/composition/tooltip/useTooltipManager'
import type { Optional } from '@/types'

export const tooltipAttributeName = 'data-tooltip'
export const tooltipDataMap = new WeakMap<HTMLElement, Optional<TooltipData>>()

const { addTooltip, removeTooltip } = useTooltipManager()

export const tooltipDirective: ObjectDirective<HTMLElement, TooltipData> = {
  beforeMount(el: HTMLElement, binding: DirectiveBinding<Optional<TooltipData>>): void {
    if (!el) return
    el.setAttribute(tooltipAttributeName, getUuid())
    tooltipDataMap.set(el, binding.value)

    if (binding.value?.visible) {
      const newTooltip = {
        id: el.dataset.tooltip as string,
        anchorEl: el,
        tooltipData: binding.value,
      }
      addTooltip(newTooltip.id, newTooltip)
    }
  },
  updated(el: HTMLElement, binding: DirectiveBinding<TooltipData>): void {
    if (!el) return
    tooltipDataMap.set(el, binding.value)
  },
  beforeUnmount(el: HTMLElement) {
    removeTooltip(el.dataset.tooltip as string)
    tooltipDataMap.delete(el)
  },
}

<template>
  <component
    :is="childComponent"
    :highlight-exact-active="highlightExactActive"
  />
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from '@/composition/vue/compositionApi'
import MainSidebarItemChildTitle from '@/components/layout/mainSidebar/mainSidebarItemChild/MainSidebarItemChildTitle.vue'
import MainSidebarItemChildLink from '@/components/layout/mainSidebar/mainSidebarItemChild/MainSidebarItemChildLink.vue'
import MainSidebarItemChildGroupCollapsible from '@/components/layout/mainSidebar/mainSidebarItemChild/MainSidebarItemChildGroupCollapsible.vue'
import type { PageContentSidebarItemTypes } from '@/components/layout/pageContent/sidebar/types'

export default defineComponent({
  components: {
    MainSidebarItemChildTitle,
    MainSidebarItemChildLink,
    MainSidebarItemChildGroupCollapsible,
  },
  props: {
    itemType: {
      type: String as PropType<PageContentSidebarItemTypes>,
    },
    highlightExactActive: {
      type: Boolean,
    },
  },
  setup(props) {
    const childComponent = computed(() => {
      switch (props.itemType) {
        case 'group':
          return 'main-sidebar-item-child-title'
        case 'groupCollapsible':
          return 'main-sidebar-item-child-group-collapsible'
        default:
          return 'main-sidebar-item-child-link'
      }
    })
    return {
      childComponent,
    }
  },
})
</script>

import type BaseModel from '@/data/models/BaseModel'
import { REFLECT_METADATA } from '@/decorators/types'

export type RepositorySettings<T = string> = {
  model: typeof BaseModel
  fetch?: T
  create?: T
  bulkDeleteEndpoint?: T
  bulkUpdateEndpoint?: T
  single?: T
  update?: T
  exportEndpoint?: T
  facetEndpoint?: T
  schemaEndpoint?: T
  importEndpoint?: T
  reorderEndpoint?: T
  bulkReorderEndpoint?: T
  pdfDownloadEndpoint?: T
  countEndpoint?: T
}

export function RepoSettings<T>(settings: RepositorySettings<T>) {
  return function <R extends { new (...args: any[]): any }>(constructor: R) {
    Reflect.defineMetadata(REFLECT_METADATA.RepoSettings, settings, constructor)
    return class extends constructor {
      public settings() {
        return settings
      }
    }
  }
}

export function PublicRepo() {
  return function <T extends { new (...args: any[]): any }>(constructor: T) {
    return class extends constructor {
      public isPublic() {
        return true
      }
    }
  }
}

import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'
import { ServicesRouteName } from '@/routes/types'
import SmsSurveyDetails from '@/data/models/domain/services/smsSurveys/SmsSurveyDetails'
import type { SingleModelResolverParams } from '@/services/resolvers/types'
import { SmsSurveyRoutes } from '@/routes/user/services/types'

export const campaignsSurveysDetailsOverviewRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.surveys.details.overview',
  path: 'overview',
  props: true,
  component: () => import('@/components/views/campaigns/surveys/CampaignsSurveysDetailsOverview.vue'),
}

export const campaignsSurveysDetailsRepliesRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.surveys.details.replies',
  path: 'replies/:questionId?',
  props: true,
  component: () => import('@/components/views/campaigns/surveys/CampaignsSurveysDetailsReplies.vue'),
  meta: {
    resolvers: [{ service: 'SmsSurveyDetailsStatusGuardResolver' }],
    modules: [AppModule.HistoryReceivedSms],
  },
}

export const campaignsSurveysDetailsAnalyticsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.surveys.details.analytics',
  path: 'analytics',
  props: true,
  component: () => import('@/components/views/campaigns/surveys/CampaignsSurveysDetailsAnalytics.vue'),
  meta: {
    resolvers: [{ service: 'SmsSurveyDetailsStatusGuardResolver' }],
  },
}

export const campaignsSurveysDetailsSummaryRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.surveys.details.summary',
  path: '',
  props: true,
  component: () => import('@/components/views/campaigns/surveys/CampaignsSurveysDetailsSummary.vue'),
  children: [
    campaignsSurveysDetailsOverviewRoute,
    campaignsSurveysDetailsRepliesRoute,
    campaignsSurveysDetailsAnalyticsRoute,
  ],
  redirect: { name: campaignsSurveysDetailsOverviewRoute.name },
  meta: {
    parent: ServicesRouteName,
    tabTitle: 'pageTabTitle.user.services.smsSurveyDetails',
    detailsPage: {
      source: 'router',
      requestParam: 'smsSurveyId',
      model: SmsSurveyDetails,
      cache: false,
    } as SingleModelResolverParams,
    resolvers: [{ service: 'SmsSurveyNodeTreeResolver', params: { skipSubscription: true } }],
    redirectToIfNotFound: { name: SmsSurveyRoutes.index },
    modules: [AppModule.SentSmsBase],
  },
}

import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

enum SERVICES {
  UserSessionService,
  UserSessionRepository,
  UserSessionColumnService,
}

export type UserSessionServices = keyof typeof SERVICES

export const USER_SESSION_SERVICE_TYPES: Record<UserSessionServices, symbol> = makeServicesTypes<UserSessionServices>(
  enumKeysToArray<UserSessionServices>(SERVICES),
)

import { injectable } from 'inversify'
// TODO: Fix ignored tp/forbid-import-composable-to-service
// eslint-disable-next-line tp/using-vue-in-services-restriction,tp/forbid-import-composable-to-service
import type { Plugin } from '@/composition/vue/compositionApi'
import type { ExternalTrackerAsPlugin, TrackableConfig } from '@/services/tracking/types'
import ExternalTrackerService from '@/services/tracking/externalTrackerService'

@injectable()
export default class ShareASaleService extends ExternalTrackerService {
  public getConfig(): TrackableConfig {
    return {
      id: String(null),
    }
  }

  public getPlugin(): Plugin {
    return {
      install: () => this.initialize(),
    } as Plugin
  }

  protected initialize(): void {
    const trackerAsPlugin = {
      src: 'https://www.dwin1.com/19038.js',
      isDeferred: true,
      trackingHandler: () => {},
    } as ExternalTrackerAsPlugin

    this.initializeTrackerAsPlugin(trackerAsPlugin)
  }
}

import { injectable } from 'inversify'
import Columns from '@/data/models/tables/Columns'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { Endpoint } from '@/services/endpoints'
import type { Column } from '@/core/tables/types'

@RepoSettings<Endpoint>({
  model: Columns,
})
@injectable()
export default class ColumnRepository extends OrmApiRepository<Columns> {
  public async loadColumns(tableId: string): Promise<Array<Column>> {
    const endpoint = this.endpointsService.getPath('columnsEntity', [tableId])
    const response = await this.api.get<Array<Column>>(endpoint)
    return response.data as unknown as Column[]
  }

  public async saveColumns(columns: Column[], tableId: string) {
    const endpoint = this.endpointsService.getPath('columns')
    const requestBody = {
      entity: tableId,
      columns,
    }

    return this.api.post(endpoint, requestBody)
  }
}

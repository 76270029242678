import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'

export const campaignsArchivedSmsRoute = {
  name: 'user.campaigns.archived.sms',
  path: 'sms',
  component: () => import('@/components/views/campaigns/sms/CampaignsArchivedSms.vue'),
  meta: {
    modules: [AppModule.SmsCampaigns],
  },
}

export const campaignsArchivedEmailRoute = {
  name: 'user.campaigns.archived.email',
  path: 'email',
  component: () => import('@/components/views/campaigns/email/CampaignsEmailStub.vue'),
}

export const campaignsArchivedTabsRoute = {
  name: 'user.campaigns.archived.tabs',
  path: '',
  component: () => import('@/components/views/campaigns/CampaignsArchived.vue'),
  redirect: { name: campaignsArchivedSmsRoute.name },
  children: [campaignsArchivedSmsRoute, campaignsArchivedEmailRoute],
}

export const campaignsArchivedRoute = {
  name: 'user.campaigns.archived',
  path: 'archived',
  redirect: { name: campaignsArchivedTabsRoute.name },
  children: [campaignsArchivedTabsRoute],
  meta: {
    tabTitle: 'pageTabTitle.campaigns.archived',
  },
} as const satisfies TmNamedRouteRecord

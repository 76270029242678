<template>
  <tm-translate
    v-if="provider"
    keypath="status.smsRejection.byocProviderReason.text"
  >
    <template v-slot:label>{{ label }}</template>
    <template v-slot:logs>
      <external-link
        :href="logsLink"
        :text="$t('status.smsRejection.byocProviderReason.logs')"
      />
    </template>
  </tm-translate>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from '@/composition/vue/compositionApi'
import TmTranslate from '@/components/TmTranslate.vue'
import type { ByocProvider } from '@/data/models/domain/services/byoc/types'
import { getByocHelperService } from '@/core/container/ioc'
import ExternalLink from '@/components/shared/ExternalLink.vue'

export default defineComponent({
  components: {
    ExternalLink,
    TmTranslate,
  },
  props: {
    provider: {
      type: String as PropType<ByocProvider>,
      required: true,
    },
  },
  setup(props) {
    const byocHelperService = getByocHelperService()

    const label = computed(() => byocHelperService.getProviderLabel(props.provider))
    const logsLink = computed(() => byocHelperService.getProviderLogsLink(props.provider) || '')

    return {
      label,
      logsLink,
    }
  },
})
</script>

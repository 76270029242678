import { inject, injectable } from 'inversify'
import { get, isEmpty, lowerFirst, merge } from 'lodash-es'
import type { Record as VuexOrmRecord } from '@vuex-orm/core'
import type { CancelToken } from 'axios'
import type BaseModel from '@/data/models/BaseModel'
import ORMBaseRepository from '@/data/repositories/ormBaseRepository'
import type { HttpService } from '@/services/transport/httpService'
import { SERVICE_TYPES } from '@/core/container/types'
import type { AbstractEndpointsInterface } from '@/services/endpointsService'
import type LoggerService from '@/services/loggerService'
import { httpBuildQuery } from '@/utils/url'
import type {
  PaginationUrlFilterType,
  PaginationUrlParametersSortType,
  PaginationUrlType,
  PaginationUrlOtherType,
} from '@/services/tables/types'
import type { Endpoint, EndpointParams } from '@/services/endpoints'
import type {
  ExportResultResponse,
  PdfDownloadLinkResultResponse,
  ExportToPdfResultResponse,
  Transport,
  UpdatedEntityResponse,
} from '@/services/transport/types'
import type {
  OptionalPaginationOffsetResponse,
  PaginationOffsetResponse,
  PaginationParams,
} from '@/services/tables/pagination/types'
import ModelMapper from '@/data/utils/modelMapper'
import type { ExportBody, Gridable, ICancelablePromise } from '@/services/types'
import type SerializerService from '@/services/serializerService'
import { TmRepositoryError } from '@/core/error/tmRepositoryError'
import type { BulkBaseBody, BulkDeleteParams, BulkDeleteParamsApiV2, BulkParams } from '@/services/bulk/types'
import TmLogicError from '@/core/error/tmLogicError'
import type FilterQueryService from '@/services/filterQueryService'
import type { Dict, NumbersObjectNullable } from '@/types'
import type { ApiBulkQuery, ReadByFilterPayload } from '@/data/repositories/types'
import type { BulkQuery } from '@/services/wrappers/types'
import type { TicketsBulkBasePayload } from '@/data/repositories/domain/tickets/types'

export type DoGrid<R> = (
  endpoint: Endpoint,
  endpointParams: EndpointParams,
  queryParameterBag: PaginationUrlType,
  paginationParamsBag?: PaginationParams,
  searchQuery?: string,
  searchFields?: string[],
  cancelToken?: CancelToken,
) => Promise<PaginationOffsetResponse<R>>

@injectable()
export default class OrmApiRepository<T extends BaseModel = BaseModel, B extends BulkBaseBody = BulkBaseBody>
  extends ORMBaseRepository<T, Endpoint>
  implements Gridable<T>
{
  protected transport: Transport

  constructor(
    @inject(SERVICE_TYPES.SerializerService) protected readonly serializerService: SerializerService,
    @inject(SERVICE_TYPES.Api) protected readonly api: HttpService,
    @inject(SERVICE_TYPES.EndpointsService) protected readonly endpointsService: AbstractEndpointsInterface,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.FilterQueryService) protected readonly filterQueryService: FilterQueryService,
  ) {
    super(loggerService)
    this.transport = api
  }

  /**
   * Update if record exist. Create otherwise
   * @param toUpdate
   */
  public async upsertRequest(toUpdate: Record<string, any>) {
    return toUpdate.id && this.find(toUpdate.id) ? this.putRequest(toUpdate) : this.postRequest(toUpdate)
  }

  protected makeCancelableRequest = <TRes>(fn: (cancelToken: CancelToken) => Promise<TRes>) => {
    const cancelToken = this.api.getCancelToken()
    const promise: ICancelablePromise<TRes> = fn(cancelToken.token) as any
    promise.cancel = () => cancelToken.cancel()
    return promise
  }

  public async fillByFilter(filters: PaginationUrlFilterType, isReset = false): Promise<T[]> {
    this.logGroupStart('fillByFilter')
    this.logArgs(JSON.stringify(filters))
    const items = await this.readByFilter(filters)
    if (isReset) {
      await this.create(items)
    } else {
      await this.insertOrUpdate(items)
    }
    this.logGroupEnd()
    return items
  }

  public async readByFilter<R extends VuexOrmRecord = T>(
    filters: PaginationUrlFilterType,
    searchQuery?: string,
    searchFields: string[] = [],
  ): Promise<R[]> {
    const response = await this.fetchByFilter<ReadByFilterPayload<R>>(
      this.getSettingsFetch(),
      filters,
      searchQuery,
      searchFields,
    )
    const items = this.getItemsFromReadByFilterResponseData(response.data)

    return ModelMapper.normalizePayload<R>(items as R[], this.model())
  }

  public async fillAll(isReset = false): Promise<T[]> {
    this.logGroupStart('fillAll')
    const result = this.fillByFilter([], isReset)
    this.logGroupEnd()
    return result
  }

  public async fillByCursor(
    endpoint: Endpoint,
    endpointParams: EndpointParams = [],
    searchQuery = '',
    searchFields: string[] = [],
    page = 1,
    isReset = false,
  ) {
    this.logGroupStart('fillByCursor')
    const { items } = await this.doCursor(endpoint, endpointParams, searchQuery, searchFields, [], page)
    if (isReset) {
      await this.create(items)
    } else {
      await this.insertOrUpdate(items)
    }
    this.logGroupEnd()
    return items
  }

  public getFetchEndpointParams(): EndpointParams {
    return []
  }

  protected getPath(key: Endpoint, id: string) {
    return this.endpointsService.getPath(key, [id])
  }

  protected getUpdatePath(key: Endpoint, data: T): string {
    return this.endpointsService.getPath(key, [data.id])
  }

  protected serializeData(data: T): Record<string, any> {
    return this.serializerService.serialize<T>(this.model(), data)
  }

  public autocompleteRequest<R = T>(
    search: string,
    searchFields: string[],
    endpointParams: EndpointParams,
    page?: number,
    filters?: PaginationUrlFilterType,
    perPage?: number,
    sort?: PaginationUrlParametersSortType,
    other?: PaginationUrlOtherType,
  ) {
    return this.doAutocomplete<R>(search, searchFields, endpointParams, page, filters, perPage, sort, other)
  }

  protected doAutocomplete<M>(
    search: string,
    searchFields: string[],
    endpointParams: EndpointParams,
    page?: number,
    filters?: PaginationUrlFilterType,
    perPage?: number,
    sort?: PaginationUrlParametersSortType,
    other?: PaginationUrlOtherType,
  ): Promise<OptionalPaginationOffsetResponse<M>> {
    // @todo replace with cursor pagination
    return this.doCursor(
      this.getSettingsFetch(),
      endpointParams,
      search,
      searchFields,
      filters,
      page,
      perPage,
      sort,
      other,
    )
  }

  protected fetchByFilter<R>(
    endpoint: Endpoint,
    filters: PaginationUrlFilterType,
    searchQuery?: string,
    searchFields: string[] = [],
  ) {
    return this.getApiSource().get<R>(this.endpointsService.getPath(endpoint), {
      params: {
        perPage: 100,
        ...this.filterQueryService.decorateFilterToStrictQueryPart(filters),
        ...(searchQuery && !isEmpty(searchQuery) ? { query: searchQuery, searchFields } : {}),
      },
      paramsSerializer: httpBuildQuery,
    })
  }

  protected async doCursor<M>(
    endpoint: Endpoint,
    endpointParams: EndpointParams,
    searchQuery: string,
    searchFields: string[],
    filters: PaginationUrlFilterType = [],
    page = 1,
    perPage = 25,
    sort?: PaginationUrlParametersSortType,
    other?: PaginationUrlOtherType,
  ): Promise<PaginationOffsetResponse<M>> {
    return this.doGrid<M>(
      endpoint,
      endpointParams,
      {
        filter: filters,
        sort,
        other,
      },
      {
        // not used
        pageKey: `${page}-${perPage}`,
        requestParams: {
          page,
          perPage,
        },
      },
      searchQuery,
      searchFields,
    )
  }

  protected async doGrid<R = T>(...args: Parameters<DoGrid<R>>): ReturnType<DoGrid<R>> {
    const response = await this.doRawGridTyped<R>(...args)
    response.items = ModelMapper.normalizePayload(response.items, this.model())
    return response
  }

  public createGridRequestParams(
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
    searchFields?: string[],
  ): Record<string, unknown> {
    const requestParams = paginationParamsBag ? paginationParamsBag.requestParams : []
    let params: any = { ...requestParams }
    if (Object.keys(queryParameterBag.filter).length > 0) {
      params = { ...params, ...this.filterQueryService.decorateFilterToStrictQueryPart(queryParameterBag.filter) }
    }

    if (queryParameterBag.sort && Object.keys(queryParameterBag.sort).length > 0) {
      params = { ...params, sort: { ...queryParameterBag.sort } }
    }

    if (queryParameterBag.groupBy && Object.keys(queryParameterBag.groupBy).length > 0) {
      params = { ...params, groupBy: { ...queryParameterBag.groupBy } }
    }

    if (searchQuery && searchQuery.length > 0) {
      // @todo: remove query after getting rid of api v2
      params = { ...params, query: searchQuery, searchQuery }
    }

    if (searchFields && searchFields.length > 0) {
      params = { ...params, searchFields }
    }

    if (queryParameterBag.other && Object.keys(queryParameterBag.other).length > 0) {
      params = { ...params, ...queryParameterBag.other }
    }

    return params
  }

  protected async doRawGrid<R>(
    endpoint: Endpoint,
    endpointParams: EndpointParams,
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
    searchFields?: string[],
    cancelToken?: CancelToken,
  ) {
    const endpointPath = this.endpointsService.getPath(endpoint, endpointParams)
    const isApiV2 = endpointPath.includes('v2')
    if (isApiV2 && paginationParamsBag) {
      paginationParamsBag.requestParams.limit = paginationParamsBag.requestParams.perPage
    }
    const params = this.createGridRequestParams(queryParameterBag, paginationParamsBag, searchQuery, searchFields)
    return this.getApiSource().get<R>(endpointPath, {
      params,
      paramsSerializer: (formData: Dict) => httpBuildQuery(formData),
      cancelToken,
    })
  }

  protected async doRawGridTyped<R = T>(
    endpoint: Endpoint,
    endpointParams: EndpointParams,
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
    searchFields?: string[],
    cancelToken?: CancelToken,
  ): Promise<PaginationOffsetResponse<R>> {
    const response = await this.doRawGrid(
      endpoint,
      endpointParams,
      queryParameterBag,
      paginationParamsBag,
      searchQuery,
      searchFields,
      cancelToken,
    )
    return this.prepareGridResponse(response.data) as PaginationOffsetResponse<R>
  }

  public prepareGridResponse(response: any) {
    if (response.resources) {
      response.items = response.resources
      delete response.resources
    }
    response.items = response.items.map((item: BaseModel) => ({ ...item, id: item.id?.toString() || '' }))
    return response
  }

  public async putForFormRequest<R = UpdatedEntityResponse>(toUpdate: any) {
    const { id, ...body } = toUpdate
    const url = this.endpointsService.getPath(this.settings().single!, [id])
    return (await this.getApiSource().put(url, body)) as unknown as Promise<R>
  }

  public async exportRequest(
    params: ExportBody,
    endpointParams?: EndpointParams,
    customUrl?: string,
  ): Promise<ExportResultResponse> {
    if (!this.settings().exportEndpoint && !customUrl) {
      throw new TmRepositoryError(
        'Please define export endpoint in settings() or provide customUrl to exportRequest method',
      )
    }
    const url = customUrl || this.endpointsService.getPath(this.settings().exportEndpoint!, endpointParams)

    const data = {
      params,
      paramsSerializer: (formData: Dict) => httpBuildQuery(formData),
      responseType: 'blob',
    }

    return (await this.getApiSource().get(url, data)) as unknown as Promise<ExportResultResponse>
  }

  public exportToPdfRequest(id: string): Promise<ExportToPdfResultResponse> {
    const url = this.getExportToPdfRequestUrl(id)
    return this.getApiSource().getFile(url) as Promise<ExportToPdfResultResponse>
  }

  public getPdfDownloadLink(id: string): Promise<PdfDownloadLinkResultResponse> {
    const url = this.getExportToPdfRequestUrl(id)
    return this.getApiSource().get(url) as Promise<PdfDownloadLinkResultResponse>
  }

  public gridRequest<R = T>(
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
    searchFields?: string[],
    cancelToken?: CancelToken,
    endpoindParams?: EndpointParams,
  ): Promise<PaginationOffsetResponse<R>> {
    return this.doGrid<R>(
      this.getSettingsFetch(),
      endpoindParams ?? this.getFetchEndpointParams(),
      queryParameterBag,
      paginationParamsBag,
      searchQuery,
      searchFields,
      cancelToken,
    )
  }

  public async fetchAllItems() {
    const queryParameterBag: PaginationUrlType = { filter: [] }
    const { items } = await this.gridRequest(queryParameterBag)
    await this.insertOrUpdate(items)
    return items
  }

  public bulkDelete<K = B>(
    _data: K,
    filters: PaginationUrlFilterType,
    deleteParams: BulkDeleteParams,
    endpoindParams?: EndpointParams,
  ) {
    const endpoint = this.settings().bulkDeleteEndpoint as Endpoint
    if (!endpoint) {
      throw new TmLogicError('Specify `bulkDeleteEndpoint`')
    }

    // @todo: get rid of it after API v3 full coverage
    const endpointPath = this.endpointsService.getPath(endpoint, endpoindParams)
    const isApiV2 = endpointPath.includes('v2')
    if (!endpoint) {
      throw new TmLogicError('Specify `bulkDeleteEndpoint`')
    }

    const url = `${endpointPath}?${httpBuildQuery({ filter: filters })}`
    const preparedParams = this.prepareBulkDeleteParams(deleteParams, isApiV2)

    return this.api.post(url, preparedParams)
  }

  // eslint-disable-next-line @typescript-eslint/default-param-last
  public bulkUpdate<K = B>(updateParameters: K, ids: string[], all: boolean, filters: PaginationUrlFilterType) {
    const endpoint = this.settings().bulkUpdateEndpoint as Endpoint
    if (!endpoint) {
      throw new TmLogicError('Specify `bulkUpdateEndpoint`')
    }
    const params = this.getBulkBasePayload(filters, { ids, all })

    return this.api.post(this.endpointsService.getPath(endpoint), {
      ...params,
      updateParameters,
    })
  }

  public reorder(moveId: string, afterId: string | null) {
    const endpoint = this.settings().reorderEndpoint as Endpoint
    if (!endpoint) {
      throw new TmLogicError('Specify `reorderEndpoint`')
    }

    return this.getApiSource().put(this.getPath(endpoint, moveId), {
      afterId,
    }) as unknown as Promise<{ data: T[] }>
  }

  public async facetsRequest(endpointParams: EndpointParams = [], types?: string[]) {
    const endpoint = this.settings().facetEndpoint
    if (endpoint) {
      return this.fillFacetsByEndpoint<NumbersObjectNullable>(endpoint, endpointParams, types ? { types } : {})
    }
    throw new TmRepositoryError('Please specify `facetEndpoint`')
  }

  protected getExportToPdfRequestUrl(id: string) {
    if (!this.settings().pdfDownloadEndpoint) {
      throw new TmRepositoryError('Please define pdf download endpoint in settings()')
    }

    return this.endpointsService.getPath(this.settings().pdfDownloadEndpoint!, [id])
  }

  protected async fillFacetsByEndpoint<M>(
    endpoint: Endpoint,
    endpointParams: EndpointParams,
    params?: Record<string, unknown>,
  ): Promise<M> {
    const baseUrl = this.endpointsService.getPath(endpoint, endpointParams)
    const response = await this.getApiSource().get(params ? `${baseUrl}?${httpBuildQuery(params)}` : baseUrl)
    return response.data as M
  }

  protected prepareBulkDeleteParams(
    deleteParams: BulkDeleteParams,
    isApiV2: boolean,
  ): BulkParams | BulkDeleteParamsApiV2 {
    return isApiV2
      ? {
          ids: deleteParams.ids.join(','),
          all: deleteParams.all,
        }
      : {
          ...deleteParams,
          all: deleteParams.all ? 1 : 0,
        }
  }

  // @todo: get rid of this after APIv2 excluding
  protected getItemsFromReadByFilterResponseData<R extends VuexOrmRecord = T>(
    responseData: ReadByFilterPayload<R>,
  ): R[] {
    if ('items' in responseData) return responseData.items
    if ('resources' in responseData) return responseData.resources
    return responseData
  }

  public getFetchPath(params?: EndpointParams) {
    const fetch = this.getSettingsFetch()
    return this.endpointsService.getPath(fetch, params)
  }

  public prepareBulkQuery(bulkQuery: BulkQuery): ApiBulkQuery {
    return {
      ids: bulkQuery.selectedIds,
      all: Number(bulkQuery.isAllSelected),
    }
  }

  protected getSettingsFetch() {
    const { fetch } = this.settings()
    if (!fetch) throw new TmRepositoryError('You must define `fetch` property in settings()')

    return fetch
  }

  public async addModelToStore(data: Dict<any>): Promise<T> {
    const result = await this.model().insert({ data })
    const key = lowerFirst(this.model().entity)
    return get(result, [key, 0]) as T
  }

  protected getBulkBasePayload(
    paramFilters: PaginationUrlFilterType,
    bulkParams: { ids: string[]; all: boolean },
  ): TicketsBulkBasePayload {
    if (bulkParams.all) {
      return {
        filters: paramFilters,
        searchQuery: '',
      }
    }

    const mergedFilters = merge(paramFilters, [{ id: { in: bulkParams.ids } }])
    return {
      filters: mergedFilters,
      searchQuery: '',
    }
  }
}

import { injectable } from 'inversify'
import type { AxiosRequestConfig } from 'axios'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import MessagesAttachmentFile from '@/data/models/domain/MessagesAttachmentFile'
import type { TpFormData } from '@/services/forms/types'

@RepoSettings<Endpoint>({
  model: MessagesAttachmentFile,
})
@injectable()
export default class MessagesAttachmentFileRepository extends OrmApiRepository<MessagesAttachmentFile> {
  public attachSmsFile(formData: TpFormData, onUploadProgressCallback?: (progressEvent: ProgressEvent) => void) {
    const path = this.endpointsService.getPath('messagesAttachmentFileSms')
    return this.attachFile(path, formData, onUploadProgressCallback)
  }

  public attachMmsFile(formData: TpFormData, onUploadProgressCallback?: (progressEvent: ProgressEvent) => void) {
    const path = this.endpointsService.getPath('messagesAttachmentFileMms')
    return this.attachFile(path, formData, onUploadProgressCallback)
  }

  public attachMessengerFile(formData: TpFormData, onUploadProgressCallback?: (progressEvent: ProgressEvent) => void) {
    const path = this.endpointsService.getPath('messagesAttachmentFileMessenger')
    return this.attachFile(path, formData, onUploadProgressCallback)
  }

  public attachFacebookFile(formData: TpFormData, onUploadProgressCallback?: (progressEvent: ProgressEvent) => void) {
    const path = this.endpointsService.getPath('messagesAttachmentFileFacebook')
    return this.attachFile(path, formData, onUploadProgressCallback)
  }

  public attachInstagramFile(formData: TpFormData, onUploadProgressCallback?: (progressEvent: ProgressEvent) => void) {
    const path = this.endpointsService.getPath('messagesAttachmentFileInstagram')
    return this.attachFile(path, formData, onUploadProgressCallback)
  }

  private attachFile(
    path: string,
    formData: TpFormData,
    onUploadProgressCallback?: (progressEvent: ProgressEvent) => void,
  ) {
    const config: AxiosRequestConfig = this.getConfig(onUploadProgressCallback)
    return this.getApiSource().post(path, formData, config)
  }

  private getConfig(onUploadProgressCallback: ((progressEvent: ProgressEvent) => void) | undefined) {
    const config: AxiosRequestConfig = {}
    if (onUploadProgressCallback) {
      config.onUploadProgress = (progressEvent: ProgressEvent) => {
        onUploadProgressCallback(progressEvent)
      }
    }
    return config
  }
}

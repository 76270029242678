<template>
  <tm-button
    class="tm-icon-button"
    :text-link="textLink"
    :size="size"
    :color="color"
    :icon-only="iconOnly"
    :disable="disabled"
    :to="to"
    :loading="loading"
    :loading-title="loadingTitle || $t('common.loading')"
    :x-padding="xPadding"
  >
    <tm-icon
      v-if="hasIcon"
      :class="iconClass"
      :name="iconName!"
      :size="cIconSize"
      :color="iconColor"
      :left="hasContent"
      :disabled="iconDisabled"
    />
    <slot v-if="!iconOnly">{{ title }}</slot>
  </tm-button>
</template>

<script lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import { computed, defineComponent, type PropType } from '@/composition/vue/compositionApi'
import TmButton from '@/components/shared/TmButton.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import type { ButtonSize, SizeProp, IconSize, ButtonColor, TmButtonXPadding } from '@/components/shared/types'
import { useBreakpoints } from '@/composition/breakpoints'
import type { IconName } from '@/assets/icons/icons'
import { VueLikeClassProp } from '@/components/types'
import type { StyleColors } from '@/constants/styleColors'

export default defineComponent({
  components: {
    TmButton,
    TmIcon,
  },
  props: {
    title: {
      type: String,
    },
    textLink: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<ButtonSize>,
      default: 'large',
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
    },
    iconName: {
      type: String as PropType<IconName>,
    },
    iconColor: {
      type: String as PropType<StyleColors>,
    },
    iconClass: VueLikeClassProp,
    iconOnly: {
      type: Boolean,
    },
    iconDisabled: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    hideIconMobile: {
      type: Boolean,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
    },
    loading: {
      type: Boolean,
    },
    loadingTitle: {
      type: String,
    },
    color: {
      type: String as PropType<ButtonColor>,
    },
    xPadding: {
      type: String as PropType<TmButtonXPadding>,
    },
  },
  setup(props, context) {
    const { isMobile } = useBreakpoints()

    const sizesMap: Record<ButtonSize, IconSize> = {
      xxSmall: 'xxSmall',
      xSmall: 'xxSmall',
      small: 'xxSmall',
      medium: 'xSmall',
      xMedium: 'small',
      large: 'small',
      '': 'medium',
      xLarge: 'large',
      xxLarge: 'xLarge',
    }

    const hasContent = computed(() => (!!props.title || !!context.slots.default) && !props.iconOnly)
    const hasIcon = computed(() => props.iconName && !(isMobile.value && props.hideIconMobile))
    const cIconSize = computed(() => props.iconSize || sizesMap[props.size])

    return {
      hasContent,
      hasIcon,
      cIconSize,
    }
  },
})
</script>

<style lang="scss" scoped>
.tm-icon-button {
  justify-content: center;
}

:deep(.tm-btn:not([disabled]):not(.q-btn--outline):not(.q-btn--flat)) {
  &:hover:before {
    background-color: $input !important;
  }
}
</style>

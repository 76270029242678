import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum VOICE_CALL_SERVICES {
  MediaDeviceService,
  MediaDeviceRepository,
  VoiceCallService,
  VoiceCallConfigService,
}

export type VoiceCallServices = keyof typeof VOICE_CALL_SERVICES

export const VOICE_CALL_SERVICES_TYPES = makeServicesTypesFromEnum<VoiceCallServices>(VOICE_CALL_SERVICES)

import type { CustomErrorRule } from '@/core/error/transport/types'
import { TmApiTasksStageNotFoundError } from '@/core/error/transport/tmApiTasksStageNotFoundError'

export const tasksStageNotFoundErrorRule: CustomErrorRule = {
  klass: TmApiTasksStageNotFoundError,
  predicateFn: (error) => {
    return (
      error.response.data.message.includes('TaskStage object not found') ||
      error.response.data.errorCode === 'tasks.stage_not_found'
    )
  },
}

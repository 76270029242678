import type {
  AbstractFieldConfig,
  ExportHandlerFunction,
  FieldConfig,
  FieldType,
  FormHook,
  FormHookType,
  ValidationModes,
  InputHandlerFunction,
  FieldDiscardStrategy,
} from '@/services/forms/types'
import type BaseForm from '@/services/forms/baseForm'
import type BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import type FieldArray from '@/data/models/forms/FieldArray'
import type FieldGroup from '@/data/models/forms/FieldGroup'
import type { BaseValidationRules } from '@/services/validation/validationRulesBuilderService'
import type { Forms } from '@/services/forms/formTypes'
import type RootBaseForm from '@/services/forms/rootBaseForm'

export type ActionType = 'input' | 'focus' | 'blur'
export type ActionsMap = Record<ActionType, (...args: any[]) => any>

type RemoveFieldFromUnion<T, K extends keyof any> = T extends unknown ? Omit<T, K> : never

export interface FormBuilderInterface {
  init(formId: string): this
  array(groupConfig: ArrayConfig, parentName: string): ArrayModifierType
  group(groupConfig: GroupConfig, parentName: string): GroupModifierType
  control(fieldConfig: FieldConfig, parentName: string): BaseForm<BaseFieldModel>
  getGroupModifier(name: string): GroupModifierType
  getArrayModifier(name: string): ArrayModifierType
  extendArray(name: string): BaseForm<BaseFieldModel>
  removeField(name: string): this
  removeFieldById(id: string): this
  reorderArrayItems(newIdsOrder: string[]): this
  getFormId(): Forms
  getForm(): RootBaseForm
  getField(name: string): BaseForm<BaseFieldModel>
  getFieldById(id: string): BaseForm<BaseFieldModel>
  getFieldId(name: string, parentName: string): string
  getErrorFieldName(errorFieldName: string): string
  getInvalidFieldNames(): string[]
  getNameById(id: string): string
  getFieldPath(name: string): string[]
  isArrayField(instance: BaseForm<BaseFieldModel>): instance is BaseForm<FieldArray>
  isGroupField(field: BaseForm<BaseFieldModel>): field is BaseForm<FieldGroup>
  buildByData(flatMap: Record<string, any>, parentName?: string): void
  hasField(id: string): boolean
  hasErrorField(id: string, errorFieldName: string): boolean
  hasFieldByName(name: string): boolean
  destroy(): void
  isInit(): boolean
  setHook(hookName: FormHookType, hook: FormHook): void
  getHook(hookName: FormHookType): FormHook[]
  createField<T extends AbstractFieldConfig>(
    name: string,
    fieldType: FieldType,
    overrideConfig: Partial<T>,
    parentInstance?: BaseForm<BaseFieldModel>,
  ): BaseForm<BaseFieldModel>
}
export interface GroupModifierType {
  instance: BaseForm<BaseFieldModel>
  addArray(fieldConfig: ArrayConfig): ArrayModifierType
  addControl(fieldConfig: FieldConfig): GroupModifierType
  addControl<TName extends string = string>(fieldConfig: FieldConfig & { name: TName }): GroupModifierType
  addGroup(fieldConfig: GroupConfig): GroupModifierType
}
export interface ArrayModifierType {
  initControl(fieldConfig: ControlInArrayConfig): BaseForm<BaseFieldModel>
  initGroup(groupConfig?: GroupInArrayConfig): GroupModifierType
  initArray(arrayConfig: ArrayConfig): ArrayModifierType
  clone(): BaseForm<BaseFieldModel>
  instance: BaseForm<BaseFieldModel>
}

export type ControlInArrayConfig = RemoveFieldFromUnion<FieldConfig, 'name'>

export type GroupInArrayConfig = {
  validators?: BaseValidationRules
  exportHandler?: ExportHandlerFunction<Record<string, any>>
}
export type GroupConfig = GroupInArrayConfig & {
  name: string
}

export type ArrayConfig = {
  name: string
  fieldType?: FieldType
  validators?: BaseValidationRules
  validationMode?: ValidationModes
  inputHandler?: InputHandlerFunction<any[]>
  exportHandler?: ExportHandlerFunction<any[]>
  /**
   * @deprecated Should not be used, please consider messagePath param in validation config instead
   */
  errorCustomMessage?: string
  discardStrategy?: FieldDiscardStrategy
}

export const ARRAY_PROTO_FIELD = 'ARRAY_PROTO_FIELD'

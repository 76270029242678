import { inject, injectable } from 'inversify'
import type { RegisteredServices } from '@/core/container/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { TitlerInterface } from '@/services/route/titlers/types'
import type { Route } from '@/services/route/types'
import type LoggerService from '@/services/loggerService'
import { TmBaseError } from '@/core/error/tmBaseError'
import type { IServiceManager } from '@/core/middlewares/types'
import type { Service } from '@/config/types'
import type { GetLocator } from '@/core/container/container'
import type WindowService from '@/services/browser/windowService'
import type { Dict } from '@/types'

// https://textmagic.atlassian.net/wiki/spaces/TM30/pages/1901723663/Page+titles
@injectable()
export default class TitlerManager implements IServiceManager {
  private titler: Set<RegisteredServices> = new Set()

  constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject('GetLocator') private readonly get: GetLocator,
  ) {}

  public addService(service: Service) {
    this.titler.add(service.id as RegisteredServices)
  }

  public getTitler<T extends TitlerInterface>(id: RegisteredServices) {
    if (!this.titler.has(id)) {
      throw new TmBaseError(`Titler ${id} not found`)
    }
    return this.get(id) as T
  }

  public getTitle(to: Route, params?: Record<string, string>) {
    const { matched, meta } = to
    this.logger.raw('titler', matched)

    const titler = meta?.titler || matched.findLast((route) => !!route.meta?.titler)?.meta?.titler

    if (!titler) {
      return ''
    }

    return this.getTitler(titler.service).getTitle(to, titler.params || params || {})
  }

  public updateTitle(to: Route, params?: Dict<string>) {
    const title = this.getTitle(to, params)
    this.windowService.setDocumentTitle(title)
  }
}

import {
  getUnsubscribedContactService,
  getNotificationService,
  getTranslateService,
  getFeatureFlagsService,
  getContactService,
} from '@/core/container/ioc'
import type UnsubscribedContact from '@/data/models/domain/UnsubscribedContact'
import { useConfirmation } from '@/composition/confirmation'
import type Contact from '@/data/models/domain/Contact'
import { UnsubscribeContactModal } from '@/components/views/contacts/types'
import { useWrapperWithForm } from '@/composition/forms'
import type UnsubscribeContactFormService from '@/services/forms/unsubscribed/unsubscribeContactFormService'
import { FeatureFlags } from '@/services/types'
import type UnsubscribeContactWithEmailFormService from '@/services/forms/unsubscribed/unsubscribeContactWithEmailFormService'
import { useUnsubscribeEmailContactModal } from '@/composition/domain/unsubscribedEmailContact'
import TmLogicError from '@/core/error/tmLogicError'
import { UnsubscribeEmailReasonType } from '@/data/models/domain/UnsubscribedEmailContact'

function toggleBlockIncomingMessages(toggle: boolean) {
  const translateService = getTranslateService()
  const unsubscribedContactService = getUnsubscribedContactService()
  const notificationService = getNotificationService()
  return async (unsubscribedContact: UnsubscribedContact) => {
    try {
      await unsubscribedContactService.toggleBlockIncomingMessages(unsubscribedContact, toggle)
      notificationService.success(translateService.t('UnsubscribeTableDropdown.toggleBlockToastMessage'))
    } catch (e) {
      notificationService.contactSupport(e as Error)
    }
  }
}

export const blockIncomingMessagesAction = () => toggleBlockIncomingMessages(true)

export const allowIncomingMessagesAction = () => toggleBlockIncomingMessages(false)

interface BaseContactUnsubscribeContactModalParams<T extends string> {
  type: T
  onSuccess?: () => void
}

interface ContactUnsubscribeContactModalParams extends BaseContactUnsubscribeContactModalParams<'contact'> {
  editingId: string
}

interface PhoneUnsubscribeContactModalParams extends BaseContactUnsubscribeContactModalParams<'phone'> {
  phone: string
}

type UnsubscribeContactModalParams = ContactUnsubscribeContactModalParams | PhoneUnsubscribeContactModalParams

interface UnsubscribeContactWithEmailModalParams {
  contactId: string
  contactName: string
  isUnsubscribedByEmail: boolean
  phone: string
  email: string
  onSuccess?: (isUnsubscribePhone: boolean) => void
}

export const useUnsubscribeContactModal = () =>
  useWrapperWithForm<UnsubscribeContactModalParams, UnsubscribeContactFormService>(
    UnsubscribeContactModal,
    'UnsubscribeContactFormService',
  )

export const useUnsubscribeContactWithEmailModal = () =>
  useWrapperWithForm<UnsubscribeContactWithEmailModalParams, UnsubscribeContactWithEmailFormService>(
    'unsubscribeContactWithEmailModal',
    'UnsubscribeContactWithEmailFormService',
  )

export const useUnsubscribeContactAction = (withEmail?: boolean) => {
  const featureFlagsService = getFeatureFlagsService()
  const contactService = getContactService()

  const { open: openUnsubscribeContactModal } = useUnsubscribeContactModal()
  const { open: openUnsubscribeContactWithEmailModal } = useUnsubscribeContactWithEmailModal()
  const { open: openUnsubscribeEmailContact } = useUnsubscribeEmailContactModal()

  return {
    open: (contact: Contact, onSuccess?: (isUnsubscribePhone: boolean) => void) => {
      if (!withEmail || !featureFlagsService.isFeatureEnabled(FeatureFlags.EmailUnsubscribers)) {
        openUnsubscribeContactModal({
          type: 'contact',
          editingId: contact.id,
          onSuccess: () => onSuccess?.(true),
        })
        return
      }
      const { email, phone } = contact
      if (email && !phone) {
        openUnsubscribeEmailContact({ email, onSuccess: () => onSuccess?.(false), contactId: contact.id })
        return
      }
      if (phone && !email) {
        openUnsubscribeContactModal({
          type: 'contact',
          editingId: contact.id,
          onSuccess: () => onSuccess?.(true),
        })
        return
      }
      if (phone && email) {
        openUnsubscribeContactWithEmailModal({
          contactId: contact.id,
          contactName: contact.fullName,
          isUnsubscribedByEmail: contactService.isUnsubscribedByEmail(contact),
          phone,
          email,
          onSuccess,
        })
        return
      }
      throw new TmLogicError("Can't unsubscribe contact without phone and email")
    },
  }
}

export const unsubscribeByPhone = (onSuccess?: () => void) => (phone: string) => {
  const { open } = useUnsubscribeContactModal()
  return open({
    type: 'phone',
    phone,
    onSuccess,
  })
}

export const resubscribeContact = (callback?: () => Promise<void>) => {
  const translateService = getTranslateService()
  const unsubscribedContactService = getUnsubscribedContactService()
  const notificationService = getNotificationService()
  return (phone: string) => {
    useConfirmation().confirm(
      async () => {
        await unsubscribedContactService.resubscribe({ phone })
        notificationService.success(translateService.t('ResubscribeAction.successText'))

        if (callback) {
          await callback()
        }
      },
      {
        submitText: translateService.t('ResubscribeAction.submitText'),
        submitLoadingText: translateService.t('ResubscribeAction.submitLoadingText'),
        title: translateService.t('ResubscribeAction.title'),
        text: translateService.t('ResubscribeAction.text'),
      },
    )
  }
}

export const resubscribeContactById = (callback?: () => Promise<void>) => {
  const translateService = getTranslateService()
  const unsubscribedContactService = getUnsubscribedContactService()
  const notificationService = getNotificationService()
  return (contactId: string) => {
    useConfirmation().confirm(
      async () => {
        await unsubscribedContactService.resubscribeById(contactId)
        notificationService.success(translateService.t('ResubscribeAction.successText'))

        if (callback) {
          await callback()
        }
      },
      {
        submitText: translateService.t('ResubscribeAction.submitText'),
        submitLoadingText: translateService.t('ResubscribeAction.submitLoadingText'),
        title: translateService.t('ResubscribeAction.title'),
        text: translateService.t('ResubscribeAction.text'),
      },
    )
  }
}

export const useUnsubscribedContactReason = () => {
  const translateService = getTranslateService()

  const getReasonText = (reason: UnsubscribeEmailReasonType) => {
    const keys: Record<UnsubscribeEmailReasonType, string> = {
      [UnsubscribeEmailReasonType.MANUAL]: 'manual',
      [UnsubscribeEmailReasonType.LINK_CLICK]: 'linkClick',
    }
    return translateService.t(`unsubscribedEmailReason.${keys[reason]}`)
  }

  return {
    getReasonText,
  }
}

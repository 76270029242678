import type { MiddlewareInterface } from '@/core/middlewares/middlewareInterface'
import ConfigReader from '@/core/configReader'
import type LoggerService from '@/services/loggerService'
import { NULL_CHANNEL } from '@/services/loggerService'
import { LogFormat, LogLevel } from '@/core/logger/types'
import BaseLogger from '@/core/logger/baseLogger'
import NullLogger from '@/core/logger/nullLogger'
import type { GetLocator } from '@/core/container/container'
import type { LoggerChannels } from '@/config/configDev'
import TmLogicError from '../error/tmLogicError'

export default class LoggerMiddleware implements MiddlewareInterface {
  public make(get: GetLocator) {
    const config = ConfigReader.config()
    const logger = get<LoggerService>('LoggerService')
    if (!config.logger) {
      return
    }

    logger.addChannel(
      NULL_CHANNEL,
      logger.createBaseChannel(
        {
          channel: NULL_CHANNEL,
          level: LogLevel.NULL,
          instance: NullLogger,
          format: LogFormat.GENERAL,
        },
        {},
      ),
    )

    for (const name in config.logger.loggerRules) {
      const rule = config.logger.loggerRules[name as LoggerChannels]
      if (!rule) {
        continue
      }
      if (!logger.isCorrectRule(rule)) {
        throw new TmLogicError('There is rule with both `include` and `exclude`')
      }
      const ruleFormatted: { include: string[]; exclude: string[] } = {
        exclude: [],
        include: [],
      }

      ruleFormatted.exclude = rule.exclude
        ? rule.exclude.map((r) => (r instanceof RegExp ? r.source : r.toString()))
        : []
      ruleFormatted.include = rule.include
        ? rule.include.map((r) => (r instanceof RegExp ? r.source : r.toString()))
        : []

      logger.log('info', `For channel ${name} rule ${JSON.stringify(ruleFormatted)}`, 'middleware')
    }
    for (const channelName in config.logger.channels) {
      const rule = config.logger.loggerRules[channelName as LoggerChannels]
      const channel = config.logger.channels[channelName]

      if (logger.isDisabled()) {
        channel.instance = NullLogger
      } else if (!channel.instance) {
        channel.instance = BaseLogger
      } else {
        channel.instance = typeof channel.instance === 'string' ? get(channel.instance) : channel.instance
      }

      logger.addChannel(
        channelName,
        logger.createBaseChannel({ ...channel, channel: channelName as LoggerChannels }, rule || {}),
      )
    }
  }
}

<template>
  <div
    class="tm-error-state"
    :class="{
      [`tm-error-state--${kebabCase(size)}`]: size,
      'tm-error-state--full-height': fullHeight,
      'tm-error-state--full-page-height': fullPageHeight,
    }"
  >
    <div class="text-center">
      <div class="d-flex justify-center">
        <tm-icon
          class="mb-4 gray4--text"
          name="error_outline"
          custom-size="80px"
        />
      </div>
      <h2 class="page-title-20 lh-24">
        {{ $t('errors.itLooksLikeSomethingWentWrong') }}
      </h2>
      <div class="mb-6 lh-18 distinct--text">
        {{ $t('errors.pleaseTryAgainInAFewMinutesOrRefreshThePageNow') }}
      </div>
      <div class="d-flex justify-center">
        <tm-button
          color="primary"
          class="min-w150 min-h40"
          @click="$emit('on-retry')"
        >
          <tm-icon
            name="refresh"
            left
            size="small"
          />
          {{ $t('common.tryAgain') }}
        </tm-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { kebabCase } from 'lodash-es'
import TmButton from '@/components/shared/TmButton.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import { defineComponent } from '@/composition/vue/compositionApi'
import type { SizeProp } from '@/components/shared/types'

export default defineComponent({
  components: { TmButton, TmIcon },
  props: {
    fullHeight: {
      type: Boolean,
    },
    fullPageHeight: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<'' | 'small' | 'medium'>,
      default: '',
    },
  },
  emits: {
    'on-retry': () => true,
  },
  setup() {
    return { kebabCase }
  },
})
</script>

<style lang="scss" scoped>
.min-w150 {
  min-width: 150px;
}
.min-h40 {
  min-height: 40px;
}
.gray4--text {
  color: $gray4;
}
.tm-error-state {
  display: flex;
  justify-content: center;
  align-items: center;

  &--small {
    min-height: 230px;
  }
  &--medium {
    min-height: 480px;
  }
  &--full-height {
    min-height: 100%;
  }
  &--full-page-height {
    min-height: 100vh;
  }
}
</style>

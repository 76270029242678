import { BelongsToField, OrmModel, StringField, NumberField, AttrField, BooleanField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import User from '@/data/models/domain/User'
import { SenderIdStatus } from '@/data/models/domain/types'
import type { UserCurrency } from '@/services/domain/user/types'
import type { NumberGiftType } from '@/services/domain/services/regulations/types'

@OrmModel(modelNames.SENDER_ID)
export default class SenderId<T extends SenderIdStatus = SenderIdStatus> extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public senderId: string

  @StringField()
  public userId: string

  @BelongsToField(User, 'userId')
  public user: User

  @StringField()
  public explanation: string

  @StringField()
  public status: T

  @NumberField()
  public price: number

  @AttrField()
  public currency: UserCurrency

  @AttrField(null)
  public nextRenewalAt: T extends 'A' ? string : null

  @BooleanField()
  public isCanceled: boolean

  @StringField()
  public giftType: NumberGiftType

  public get cancellationDate() {
    if (!this.isCanceled) {
      return null
    }
    return this.nextRenewalAt
  }

  public get isCancellable() {
    return this.status === SenderIdStatus.accepted
  }
}

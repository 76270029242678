import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/container'
import type { Resolvable } from '@/services/resolvers/types'
import type ServerSubscriptionService from '@/services/transport/serverSubscriptionService'
import type { TServerEvent } from '@/services/transport/serverEvents'
import { getUserSettingsService, getAuthService, getRouterService } from '@/core/container/ioc'

@injectable()
export default class UserPolicyPrivacyChangedResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.ServerSubscriptionService)
    private readonly serverSubscriptionService: ServerSubscriptionService,
  ) {}

  public async resolve() {
    this.serverSubscriptionService.subscribe('systemAccountAcceptTermsUpdate', this.userPolicyPrivacyChangedHandler)
  }

  private async userPolicyPrivacyChangedHandler(e: TServerEvent<'systemAccountAcceptTermsUpdate'>) {
    if ('showTermsUpdate' in e.payload) {
      await getUserSettingsService().updateCurrentUserSettingsInStore({
        showTermsUpdate: e.payload.showTermsUpdate,
      })

      if (getRouterService().isCurrentRoute({ name: 'privacyPolicy.base' })) {
        await getAuthService().redirectAfterSuccessLogin()
      }
    }
  }

  public async unresolve() {
    this.serverSubscriptionService.unsubscribe('systemAccountAcceptTermsUpdate', this.userPolicyPrivacyChangedHandler)
  }
}

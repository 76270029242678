import { inject, injectable } from 'inversify'
import axios from 'axios'
import type { HttpInterceptor, InternalErrorResponse, HttpInterceptorResponseError } from '@/services/transport/types'
import { TmBaseError } from '@/core/error/tmBaseError'
import { TmLostConnectionError } from '@/core/error/transport/tmLostConnectionError'
import { TmCancelRequestError } from '@/core/error/transport/tmCancelRequestError'
import { SERVICE_TYPES } from '@/core/container/types'
import type ErrorSerializerService from '@/services/transport/errorSerializer/errorSerializerService'

@injectable()
export default class ApiErrorInterceptor implements HttpInterceptor<any, InternalErrorResponse> {
  constructor(
    @inject(SERVICE_TYPES.ErrorSerializerService) protected readonly errorSerializerService: ErrorSerializerService,
  ) {}

  public responseError(error: HttpInterceptorResponseError<InternalErrorResponse>) {
    if (error.response) {
      const ErrorInMap = this.errorSerializerService.getErrorClass(error)
      if (ErrorInMap) {
        return Promise.reject(new ErrorInMap(error.message, error.response, error.stack))
      }
    }

    if (axios.isCancel(error)) {
      return Promise.reject(new TmCancelRequestError())
    }

    let e: Error
    if (error?.message.toLowerCase() === 'network error') {
      e = new TmLostConnectionError(error.message, undefined, error.stack)
    } else {
      const msg = 'Unknown ApiErrorInterceptor error'
      e = new TmBaseError(error?.message ? `${msg}: ${error.message}` : msg, error?.response || {}, error.stack)
    }

    return Promise.reject(e)
  }
}

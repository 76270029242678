import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import { withAsyncWrapper } from '@/components/resolvers/wrappers/withAsyncWrapper'
import ChatsAsyncComponentWrapper from '@/components/views/chats/ChatsAsyncComponentWrapper.vue'
import ChatSkeleton from '@/components/domain/chats/content/ChatSkeleton.vue'
import { getMessengerSettingsRoutes } from '@/routes/user/messenger/messengerSettings'
import { START_CHAT_QUERY_PARAM_NAME } from '@/data/models/domain/chats/types'
import { defineAsyncComponent } from '@/composition/vue/compositionApi'
import type { FeatureTooltipConfig } from '@/composition/featureTooltip'

const messengerFeatureTooltip: FeatureTooltipConfig = {
  key: 'messenger-instagram-feature-tooltip',
  component: defineAsyncComponent(() => import('@/components/domain/messenger/instagram/InstagramFeatureTooltip.vue')),
  openDelay: 5000,
  remindDays: 3,
}

export const getChatsRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.chats',
    path: 'messenger',
    redirect({ params, query }) {
      return {
        name: 'user.chats.index',
        params: params.phone ? { phone: params.phone } : {},
        query: {
          id: query.id,
          dt1: query.dt1,
          dt2: query.dt2,
          [START_CHAT_QUERY_PARAM_NAME]: query[START_CHAT_QUERY_PARAM_NAME],
        },
      }
    },
    meta: {
      tags: ['USER_TOP_MENU'],
      menu: () => import('@/components/domain/chats/MessengerMenu.vue'),
      modules: [
        AppModule.SenderSettingsCountries,
        AppModule.Chat,
        AppModule.ContactsUnsubscribed,
        AppModule.ContactsBlocked,
        AppModule.Contacts,
        AppModule.ContactList,
        AppModule.Compose,
        AppModule.RecentRecipient,
        AppModule.SentSmsBase,
        AppModule.Tasks,
        AppModule.HistoryReceivedSms,
        AppModule.Tags,
        AppModule.Facebook,
      ],
      featureTooltip: messengerFeatureTooltip,
    },
    children: [
      {
        name: 'user.chats.index',
        path: 'chat/:phone?',
        props: (route) => ({
          dtStart: route.query.dt1,
          dtEnd: route.query.dt2,
          filter: route.query.filter,
          phone: route.params.phone,
        }),
        meta: {
          tabTitle: 'pageTabTitle.user.chats.index',
          menu: () => import('@/components/views/chats/ChatsMenu.vue'),
          modules: [
            AppModule.SenderSettingsCountries,
            AppModule.Chat,
            AppModule.Typing,
            AppModule.ContactsUnsubscribed,
            AppModule.ContactsBlocked,
            AppModule.Contacts,
            AppModule.ContactList,
            AppModule.FilteredViews,
            AppModule.Segments,
            AppModule.Compose,
            AppModule.RecentRecipient,
            AppModule.SentSmsBase,
            AppModule.Tasks,
            AppModule.ChannelPresence,
            AppModule.AlsoHere,
            AppModule.WebWidgets,
            AppModule.PointAi,
          ],
          suspenseResolvers: [
            {
              service: 'FetchAllAccountsResolver',
            },
            {
              service: 'TypingResolver',
            },
            {
              service: 'ChatResolver',
            },
            {
              service: 'PointAiChatResolver',
            },
            {
              service: 'AlsoHereResolver',
            },
            {
              service: 'SegmentsForContactChatResolverService',
            },
          ],
        },
        component: withAsyncWrapper(() => import('@/components/views/chats/Chats.vue'), ChatSkeleton, {
          wrapper: ChatsAsyncComponentWrapper,
          delay: 0,
          withFallback: true,
        }),
      },
      ...getMessengerSettingsRoutes(),
    ],
  },
]

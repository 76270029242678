import { isNil } from 'lodash-es'
import type { CustomFieldType } from '@/services/domain/cusomFields/types'
import type { PaginationUrlFilterScalarValueType, PaginationUrlFilterType } from '@/services/tables/types'
import type { CustomFieldPaginationUrlFilterScalarValueType } from '@/utils/custom-fields/types'
import type { Nullable, Optional } from '@/types'

export const CUSTOM_FIELD_DATE_VALUE = 'dateValue' as const
export const CUSTOM_FIELD_TEXT_VALUE = 'textValue' as const
export const CUSTOM_FIELD_NUMERIC_VALUE = 'numericValue' as const
export const CUSTOM_FIELD_MONETARY_VALUE = 'monetaryValue' as const
export const CUSTOM_FIELD_SINGLE_VALUE = 'singleValue' as const
export const CUSTOM_FIELD_MULTI_VALUE = 'multiValue' as const

export const CUSTOM_FIELD_SUPPORTED_VALUES = [
  CUSTOM_FIELD_DATE_VALUE,
  CUSTOM_FIELD_TEXT_VALUE,
  CUSTOM_FIELD_NUMERIC_VALUE,
  CUSTOM_FIELD_MONETARY_VALUE,
  CUSTOM_FIELD_SINGLE_VALUE,
  CUSTOM_FIELD_MULTI_VALUE,
] as const

export type CustomFieldSupportedValues = (typeof CUSTOM_FIELD_SUPPORTED_VALUES)[number]

export const customFieldFilterValueKeyMap: Record<CustomFieldType, CustomFieldSupportedValues> = {
  date: CUSTOM_FIELD_DATE_VALUE,
  text: CUSTOM_FIELD_TEXT_VALUE,
  numeric: CUSTOM_FIELD_NUMERIC_VALUE,
  monetary: CUSTOM_FIELD_MONETARY_VALUE,
  singleOption: CUSTOM_FIELD_SINGLE_VALUE,
  multiOption: CUSTOM_FIELD_MULTI_VALUE,
}

const getCustomFieldTypeByValueType = (valueType: CustomFieldSupportedValues): CustomFieldType => {
  return Object.entries(customFieldFilterValueKeyMap).reduce<CustomFieldType>((acc, [key, value]) => {
    if (value === valueType) {
      return key as CustomFieldType
    }

    return acc
  }, 'text')
}

export const customFieldFilterNamePrefix = 'customFields'
const customFieldFilterNameSeparator = ':'

export const buildCustomFieldFilterName = (customFieldId: string, customFieldType: CustomFieldType) => {
  return [customFieldFilterNamePrefix, customFieldType, customFieldId].join(customFieldFilterNameSeparator)
}

export const parseCustomFieldFilterName = (filterName: string) => {
  const filterNameParts = filterName.split(customFieldFilterNameSeparator)

  if (filterNameParts.length < 3) {
    return null
  }

  const [prefix, customFieldType, customFieldId] = filterNameParts

  if (prefix !== customFieldFilterNamePrefix) {
    return null
  }

  return {
    prefix,
    customFieldId,
    customFieldType: customFieldType as CustomFieldType,
  } as const
}

export const prepareCustomFieldFilters = (filters: Optional<Nullable<PaginationUrlFilterType>>) => {
  if (!filters) {
    return []
  }
  return filters.map((filter) => {
    return Object.entries(filter).reduce((acc, [key, value]) => {
      const parsedCustomFieldFilterName = parseCustomFieldFilterName(key)

      if (!parsedCustomFieldFilterName) {
        acc[key] = value
        return acc
      }

      if (!acc[parsedCustomFieldFilterName.prefix]) {
        acc[parsedCustomFieldFilterName.prefix] = []
      }

      acc[parsedCustomFieldFilterName.prefix].push(
        buildCustomFieldFilter(
          parsedCustomFieldFilterName.customFieldId,
          parsedCustomFieldFilterName.customFieldType,
          value,
        ),
      )

      return acc
    }, {})
  }, [])
}

/**
 * Opposite to {@link prepareCustomFieldFilters}
 */
export const parsePaginationUrlFilterType = (filters: PaginationUrlFilterType): PaginationUrlFilterType => {
  return filters.map((filterLine) => {
    const buf = { ...filterLine }

    if (customFieldFilterNamePrefix in filterLine) {
      delete buf[customFieldFilterNamePrefix]

      const customFields = Object.values(
        filterLine[customFieldFilterNamePrefix],
      ) as unknown as CustomFieldPaginationUrlFilterScalarValueType[]

      customFields.forEach((customField) => {
        const customFieldValueType = Object.keys(customField).find((key) => {
          return (CUSTOM_FIELD_SUPPORTED_VALUES as readonly string[]).includes(key) && !isNil(customField[key])
        }) as CustomFieldSupportedValues

        buf[buildCustomFieldFilterName(customField.id, getCustomFieldTypeByValueType(customFieldValueType))] =
          customField[customFieldValueType] as PaginationUrlFilterScalarValueType
      })
    }

    return buf
  })
}

export const buildCustomFieldFilter = (
  customFieldId: string,
  customFieldType: CustomFieldType,
  value: PaginationUrlFilterScalarValueType,
): CustomFieldPaginationUrlFilterScalarValueType => {
  return {
    id: customFieldId,
    [customFieldFilterValueKeyMap[customFieldType]]: value,
  }
}

import { AttrField, BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import type { AppModule } from '@/config/types'

@OrmModel(modelNames.MODAL, modelNames.BASE_WRAPPER, {
  BASE_WRAPPER: BaseWrapper,
  MODAL: Modal,
})
export default class Modal extends BaseWrapper {
  @BooleanField(false)
  public isOpen: boolean

  @StringField()
  public title: string

  @NumberField()
  public openTimestamp: number

  @AttrField()
  public modules?: AppModule[]

  public $name: string
}

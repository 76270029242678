import type { ExportBody } from '@/services/types'
import ExportService from '@/services/exportService'
import type BaseModel from '@/data/models/BaseModel'
import type { EndpointParams } from '@/services/endpoints'
import type { ExportResultResponse } from '@/services/transport/types'
import TmLogicError from '@/core/error/tmLogicError'
import type { RegisteredServices } from '@/core/container/types'

export default class EntityExportService extends ExportService {
  protected id: string

  public setId(id: string): void {
    this.id = id
  }

  public override async exportByEntity(
    entity: typeof BaseModel,
    params: ExportBody,
    tableServiceId?: RegisteredServices,
  ): Promise<ExportResultResponse> {
    if (!this.id) {
      throw new TmLogicError('Entity id is not set for export')
    }

    return super.exportByEntity(entity, params, tableServiceId, [this.id] as EndpointParams)
  }
}

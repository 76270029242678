import {
  isTFilterRangeEnd,
  isTFilterRangeStart,
  type TFilterRange,
  type TFilterRangeEnd,
  type TFilterRangeFull,
  type TFilterRangeStart,
} from '@/services/filters/types'
import { getDateTimeService } from '@/core/container/ioc'
import type { Operation } from '@/services/types'

export const MIN_DATE = -8640000000000000
export const MAX_DATE = 8640000000000000

type TYear = `${number}${number}${number}${number}`
type TMonth = `${number}${number}`
type TDay = `${number}${number}`
type THours = `${number}${number}`
type TMinutes = `${number}${number}`
type TSeconds = `${number}${number}`

type TDateISODate = `${TYear}-${TMonth}-${TDay}`
type TDateISOTimeShort = `${THours}:${TMinutes}:${TSeconds}`

export type TDateISOShort = `${TDateISODate}T${TDateISOTimeShort}Z`
const dateISOShortRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/
export const isTDateISOShort = (value: unknown): value is TDateISOShort => {
  return typeof value === 'string' && value.match(dateISOShortRegex) !== null
}
export const RANGE_LAST_PERIOD = ['days', 'hours', 'minutes', 'weeks', 'months'] as const
export type TRangeLastPeriod = (typeof RANGE_LAST_PERIOD)[number]
type TRelativePeriod = `${'-' | '+'}${number} ${TRangeLastPeriod}`
type TMidnightable<T> = T | (T extends string ? `${T} ${'midnight'}` : never)

export type TRelativeDateTime =
  | 'now'
  | TMidnightable<'today' | 'yesterday' | 'tomorrow'>
  | TMidnightable<TRelativePeriod>
  | TDateISOShort

export const isTRelativeDateTime = (value: unknown): value is TRelativeDateTime => {
  return (
    typeof value === 'string' &&
    (getDateTimeService().isValidDateString(value) ||
      value === 'now' ||
      value.match(/^(today|yesterday|tomorrow)( midnight)?$/) !== null ||
      value.match(new RegExp(`^([+-])\\d+ (${RANGE_LAST_PERIOD.join('|')})( midnight)?$`)) !== null)
  )
}

export type TRelativeDateTimeRangeFull = TFilterRangeFull<TRelativeDateTime>
export type TRelativeDateTimeRangeStart = TFilterRangeStart<TRelativeDateTime>
export type TRelativeDateTimeRangeEnd = TFilterRangeEnd<TRelativeDateTime>

export const isTRangeLastPeriod = (value: unknown) => {
  return typeof value === 'string' && RANGE_LAST_PERIOD.includes(value as TRangeLastPeriod)
}

export type TRelativeDateTimeRange = TFilterRange<TRelativeDateTime>

export type TDateTimeRange = {
  start: Date
  startOperation: Operation
  end: Date
  endOperation: Operation
}

export const isTRelativeDateTimeRangeStart = (value: unknown): value is TRelativeDateTimeRangeStart => {
  return isTFilterRangeStart(value) && isTRelativeDateTime(value.start)
}

export const isTRelativeDateTimeRangeEnd = (value: unknown): value is TRelativeDateTimeRangeEnd => {
  return isTFilterRangeEnd(value) && isTRelativeDateTime(value.end)
}

export const isTRelativeDateTimeRangeFull = (value: unknown): value is TRelativeDateTimeRangeFull => {
  return isTRelativeDateTimeRangeStart(value) && isTRelativeDateTimeRangeEnd(value)
}

export const isTRelativeDateTimeRange = (value: unknown): value is TRelativeDateTimeRange => {
  return isTRelativeDateTimeRangeStart(value) || isTRelativeDateTimeRangeEnd(value)
}

<template>
  <div
    class="page-content-main-wrap"
    :class="{
      'page-content-main-wrap--sidebar': hasSidebarSlot,
      'page-content-main-wrap--sidebar-collapsed': sidebarCollapsed,
      'page-content-main-wrap--transition': transition,
      'page-content-main-wrap--background-gray': isBackgroundGray,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'

export default defineComponent({
  props: {
    hasSidebarSlot: {
      type: Boolean,
    },
    sidebarCollapsed: {
      type: Boolean,
    },
    transition: {
      type: Boolean,
    },
    isBackgroundGray: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
.page-content-main-wrap {
  position: relative;
  display: flex;
  height: 100%;

  &--sidebar {
    width: calc(100% - var(--page-content-sidebar-width));
    margin-left: auto;
  }

  &--sidebar-collapsed {
    @include media-lg-min {
      width: calc(100% - 32px);
      margin-left: auto;
    }
  }

  &--transition {
    transition: all $transition-300ms-ease-in-out;
  }

  &--background-gray {
    background-color: $lift;
  }
}
</style>

import { get, unbind } from '@/core/container/container'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type RouterService from '@/services/route/routerService'
import type { RouteLeaveService } from '@/services/route/routeLeaveService'
import type ResolverService from '@/services/resolvers/resolverService'
import type DateTimeService from '@/services/dateTimeService'
import type TableBuilderService from '@/services/tables/tableBuilderService'
import type { BulkServiceInterface, RouterPermissionInterface } from '@/services/types'
import type NotificationService from '@/services/notificationService'
import type ExportService from '@/services/exportService'
import type TableManager from '@/services/tables/tableManager'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type LoggerService from '@/services/loggerService'
import type ConfirmationService from '@/services/confirmationService'
import type AuthService from '@/services/auth/authService'
import type ContactService from '@/services/domain/contact/contactService'
import type { BaseFiltersType } from '@/services/tables/filters/types'
import type { RegisteredServices } from '@/core/container/types'
import type ValidationService from '@/services/validation/validationService'
import type VuexService from '@/services/vuex/vuexService'
import type { HttpService } from '@/services/transport/httpService'
import type SubscriptionService from '@/services/transport/subscriptionService'
import type TranslateService from '@/services/translateService'
import type DomainManagerService from '@/services/domain/domainManagerService'
import type WindowService from '@/services/browser/windowService'
import type { ScrollbarService } from '@/services/browser/scrollbarService'
import type { TableScrollbarService } from '@/services/browser/tableScrollbarService'
import type UserService from '@/services/domain/user/userService'
import type DateTimeRangerService from '@/services/dateTimeRangerService'
import type WrapperManagerService from '@/services/wrappers/wrapperManagerService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type CleanUpManager from '@/services/cleanUp/cleanUpManager'
import type FacetManager from '@/services/facets/facetManager'
import type { IWebSocketService, Transport } from '@/services/transport/types'
import type PhoneService from '@/services/phoneService'
import type BulkManager from '@/services/bulk/bulkManager'
import type { BulkBaseBody } from '@/services/bulk/types'
import type BulkService from '@/services/bulk/bulkService'
import type FormManager from '@/services/forms/baseForm/formManager'
import type FormValidationService from '@/services/forms/formValidationService'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type { Historyable } from '@/services/route/types'
import type { FilterServiceManager } from '@/services/tables/managers/filterServiceManager'
import type { TableFilterServiceManager } from '@/services/tables/managers/tableFilterServiceManager'
import type BaseFilters from '@/services/tables/filters/baseFilters'
import type OrderService from '@/services/orderService'
import type VisibilityService from '@/services/visibilityService'
import type TableSorterManager from '@/services/tables/managers/tableSorterManager'
import type TableGrouperManager from '@/services/tables/managers/tableGrouperManager'
import type TablePaginatorManager from '@/services/tables/managers/tablePaginatorManager'
import type TableSearcherManager from '@/services/tables/managers/tableSearcherManager'
import type TableColumnManager from '@/services/tables/managers/tableColumnManager'
import type ContactListService from '@/services/domain/contactList/contactListService'
import type TemplatesService from '@/services/domain/templates/templatesService'
import type UnsubscribedContactService from '@/services/domain/unsubscribed/unsubscribedContactService'
import type SipCallsService from '@/services/domain/sipCalls/sipCallsService'
import type OutboundCallsService from '@/services/domain/sipCalls/outboundCallsService'
import type ReceivedSmsService from '@/services/domain/receivedSms/receivedSmsService'
import type InboundCallsService from '@/services/domain/sipCalls/inboundCallsService'
import type AccountsService from '@/services/domain/accounts/accountsService'
import type CountryService from '@/services/domain/countryService'
import type { AbstractEndpointsInterface } from '@/services/endpointsService'
import type ForwardedCallsService from '@/services/domain/sipCalls/forwardedCallsService'
import type SentSmsFullService from '@/services/domain/sentSms/sentSmsFullService'
import type SmsSurveysService from '@/services/domain/services/smsSurveys/smsSurveysService'
import type NoteService from '@/services/domain/note/noteService'
import type ContactImportsService from '@/services/domain/contactImports/ContactImportsService'
import type SessionService from '@/services/domain/session/sessionService'
import type BulkSentSmsCounterService from '@/services/domain/counters/bulkSentSmsCounterService'
import type MessagesPriceService from '@/services/domain/messages/messagesPriceService'
import type UserNumbersService from '@/services/domain/user/userNumbersService'
import type BulkSentSmsService from '@/services/domain/sentSms/bulkSentSmsService'
import type ForwardAudioService from '@/services/domain/forwardAudio/forwardAudioService'
import type ScheduledService from '@/services/domain/scheduled/scheduledService'
import type BlockedContactService from '@/services/domain/blockedContact/blockedContactService'
import type ChatService from '@/services/domain/chats/chatService'
import type ChatMessageService from '@/services/domain/chats/chatMessageService'
import type RecentRecipientService from '@/services/domain/recentRecipient/recentRecipientService'
import type NumericService from '@/services/numericService'
import type TemplateCategoriesService from '@/services/domain/templates/templateCategoriesService'
import type SenderIdService from '@/services/domain/senderId/senderIdService'
import type NewQueryModalService from '@/services/wrappers/newQueryModalService'
import type NewQueryParamsService from '@/services/route/newQueryParamsService'
import type ChatStatisticsService from '@/services/domain/chats/chatStatisticsService'
import type ReportingOverviewService from '@/services/domain/reporting/reportingOverviewService'
import type ActivityCallService from '@/services/domain/activity/activityCallService'
import type CalendarEventService from '@/services/calendar/calendarEventService'
import type ScheduledNotSentService from '@/services/domain/scheduled/scheduledNotSentService'
import type AllowedEmailsService from '@/services/domain/allowedEmails/allowedEmailsService'
import type SendReceiveSettingsService from '@/services/domain/sendReceiveSettings/sendReceiveSettingsService'
import type ComposeLocalService from '@/services/composeLocalService'
import type CalendarViewManagerService from '@/services/calendar/views/calendarViewManagerService'
import type CalendarMonthlyService from '@/services/calendar/views/calendarMonthlyService'
import type TimezoneService from '@/services/domain/timezone/timezoneService'
import type BaseSmsHelperService from '@/services/domain/baseSmsHelperService'
import type SentSmsShortService from '@/services/domain/sentSms/sentSmsShortService'
import type SentSmsLocalStorageService from '@/services/domain/sentSms/sentSmsLocalStorageService'
import type SentSmsColumnPropsService from '@/services/domain/sentSms/sentSmsColumnPropsService'
import type PhoneNumbersService from '@/services/domain/phoneNumbers/phoneNumbersService'
import type GlobalStateService from '@/services/GlobalStateService'
import type ComposeSuccessSendService from '@/services/domain/compose/ComposeSuccessSendService'
import type MessagesPreviewService from '@/services/domain/messages/messagesPreviewService'
import type ChatSettingsService from '@/services/domain/chats/chatSettingsService'
import type ChatMessageContentService from '@/services/domain/chats/chatMessageContentService'
import type AccountSettingsSecurityService from '@/services/domain/accounts/accountSettingsSecurityService'
import type ContactListTempService from '@/services/domain/contactListTemp/contactListTempService'
import type BillingDetailsService from '@/services/domain/billing/billingDetailsService'
import type SubAccountsImportService from '@/services/domain/accounts/subAccounts/import/subAccountsImportService'
import type SubAccountsImportDetailsService from '@/services/domain/accounts/subAccounts/import/details/subAccountsImportDetailsService'
import type NativeNotificationService from '@/services/nativeNotificationService'
import type ServerSubscriptionService from '@/services/transport/serverSubscriptionService'
import type MyDataExportService from '@/services/domain/accounts/myDataExportService'
import type VoiceCallPriceService from '@/services/domain/voiceCallPriceService'
import type SubAccountImportSessionService from '@/services/domain/accounts/SubAccountImportSessionService'
import type VoiceCallConfigService from '@/services/domain/voiceCallConfigService'
import type SubAccountsGridable from '@/services/domain/accounts/subAccounts/subAccountsGridable'
import type VoiceCallService from '@/services/voiceCallService'
import type AuditLogService from '@/services/domain/accounts/subAccounts/auditLogService'
import type SenderSettingsCountriesService from '@/services/domain/senderSettings/senderSettingsCountriesService'
import type LoginHistoryService from '@/services/domain/loginHistory/loginHistoryService'
import type AccountSettingsLoginHistoryGridable from '@/services/domain/accounts/accountSettingsLoginHistoryGridable'
import type AccountSsoSettingsService from '@/services/domain/accounts/accountSsoSettingsService'
import type DistributionService from '@/services/domain/services/distribution/distributionService'
import type CarrierLookupService from '@/services/domain/carrierLookup/CarrierLookupService'
import type EmailLookupService from '@/services/domain/emailLookup/EmailLookupService'
import type ApiV2EntryService from '@/services/domain/services/api/apiV2EntryService'
import type KeywordService from '@/services/domain/keyword/keywordService'
import type ApiV1SettingsService from '@/services/domain/services/api/apiV1SettingsService'
import type ApiV2SettingsService from '@/services/domain/services/api/apiV2SettingsService'
import type PermissionsService from '@/services/permissionsService'
import type ScheduledParametersService from '@/services/domain/scheduled/scheduledParametersService'
import type ReportingCallsGridable from '@/services/domain/reporting/calls/reportingCallsGridable'
import type FormClickToTextService from '@/services/domain/services/forms/clickToText/FormClickToTextService'
import type FormsSubscribesService from '@/services/domain/services/forms/subscribe/FormSubscribeService'
import type AutomationRuleLogService from '@/services/domain/services/automationRule/automationRuleLogService'
import type AutomationRuleService from '@/services/domain/services/automationRule/automationRuleService'
import type CarrierLookupSessionService from '@/services/domain/carrierLookup/carrierLookupSessionService'
import type EmailLookupSessionService from '@/services/domain/emailLookup/emailLookupSessionService'
import type BillingService from '@/services/domain/billing/billingService'
import type SenderIdPriceService from '@/services/domain/senderId/senderIdPriceService'
import type ReportingMessagesGridable from '@/services/domain/reporting/messages/reportingMessagesGridable'
import type ReportingHistoricalTableQueryParamsService from '@/services/domain/reporting/common/reportingHistoricalTableQueryParamsService'
import type ReportingCallsChartService from '@/services/domain/reporting/calls/reportingCallsChartService'
import type ReportingMessagesChartService from '@/services/domain/reporting/messages/reportingMessagesChartService'
import type MediaDeviceService from '@/services/mediaDeviceService'
import type NumbersPriceService from '@/services/domain/phoneNumbers/numbersPriceService'
import type TenDlcRequestService from '@/services/domain/services/regulations/tenDlcRequest/tenDlcRequestService'
import type TollFreeRequestService from '@/services/domain/services/regulations/tollFreeRequest/tollFreeRequestService'
import type TenDlcPricingService from '@/services/domain/services/regulations/tenDlcPricingService'
import type TenDlcBrandService from '@/services/domain/services/regulations/tenDlcBrand/tenDlcBrandService'
import type TenDlcCampaignsService from '@/services/domain/services/regulations/tenDlcCampaigns/tenDlcCampaignsService'
import type SmsSurveyDetailsService from '@/services/domain/services/smsSurveys/smsSurveyDetailsService'
import type SmsSurveyRepliesGridable from '@/services/domain/services/smsSurveys/smsSurveyRepliesGridable'
import type SmsSurveyRepliesService from '@/services/domain/services/smsSurveys/smsSurveyRepliesService'
import type RegulationsStatusService from '@/services/domain/services/regulations/regulationsStatusService'
import type FormClickToTextDetailsService from '@/services/domain/services/forms/clickToText/FormClickToTextDetailsService'
import type FormSubscribeDetailsService from '@/services/domain/services/forms/subscribe/FormSubscribeDetailsService'
import type TollFreePhoneNumbersService from '@/services/domain/services/regulations/tollFreePhoneNumbers/tollFreePhoneNumbersService'
import type SmsSurveyRecipientsCountriesGridable from '@/services/domain/services/smsSurveys/flow/recipients/smsSurveyRecipientsCountriesGridable'
import type SubAccountsPermissionsService from '@/services/domain/accounts/subAccounts/subAccountsPermissionsService'
import type RecipientService from '@/services/domain/messages/recipientService'
import type DistributionDetailsService from '@/services/domain/services/distribution/distributionDetailsService'
import type SmsSurveyNodesService from '@/services/domain/services/smsSurveys/smsSurveyNodesService'
import type AutomationRuleDetailsService from '@/services/domain/services/automationRule/automationRuleDetailsService'
import type TenDlcPhoneNumbersService from '@/services/domain/services/regulations/tenDlcPhoneNumbers/tenDlcPhoneNumbersService'
import type SmsSurveyRecipientCountryService from '@/services/domain/services/smsSurveys/smsSurveyRecipientCountryService'
import type ModalService from '@/services/wrappers/modalService'
import type InvoicesService from '@/services/domain/accounts/invoicesService'
import type ByocCarrierPhoneService from '@/services/domain/byoc/byocCarrierPhoneService'
import type TitlerManager from '@/services/route/titlers/titlerManager'
import type MessagesBulksProgressService from '@/services/domain/messages/messagesBulksProgressService'
import type TrackingService from '@/services/tracking/trackingService'
import type ContactImportResultService from '@/services/domain/contactImports/ContactImportResultService'
import type { MonitoringServiceInterface } from '@/services/monitoring/types'
import type AttachmentFileService from '@/services/domain/attachmentFile/attachmentFileService'
import type MessagesPriceForCountryService from '@/services/domain/messages/messagesPriceForCountryService'
import type UserRoleLabelDataService from '@/services/domain/user/userRoleLabelDataService'
import type EntityExportService from '@/services/entityExportService'
import type ByocProviderPhoneService from '@/services/domain/byoc/byocProviderPhoneService'
import type ByocDetailsPhonesGridable from '@/services/domain/byoc/gridable/byocDetailsPhonesGridable'
import type OAuthSignUpService from '@/services/signUp/oAuthSignUpService'
import type WizardService from '@/services/domain/wizard/wizardService'
import type AutoDetectedCountryService from '@/services/signUp/autoDetectedCountryService'
import type ByocCarrierService from '@/services/domain/byoc/byocCarrierService'
import type ByocHelperService from '@/services/domain/byoc/byocHelperService'
import type ContactsInListBulkManager from '@/services/domain/contact/contactsInListBulkManager'
import type ByocFlowPhonesGridable from '@/services/domain/byoc/gridable/byocFlowPhonesGridable'
import type ByocCarrierLinkModalPhonesGridable from '@/services/domain/byoc/gridable/byocCarrierLinkModalPhonesGridable'
import type ByocService from '@/services/domain/byoc/byocService'
import type ChatConversationSenderSettingsService from '@/services/domain/chats/chatConversationSenderSettingsService'
import type { AuthLoaderService } from '@/services/authLoaderService'
import type ChatPageInfoService from '@/services/domain/chats/chatPageInfoService'
import type {
  TenDlcBrowserStorageService,
  TollFreeBrowserStorageService,
} from '@/services/domain/services/regulations/tenDlcTollFreeBrowserStorageService'
import type { SentSmsAppearanceService } from '@/services/domain/sentSms/sentSmsAppearanceService'
import type { UiStateService } from '@/services/uiStateService'
import type { PdfWorkerService } from '@/services/workers/pdf/PdfWorkerService'
import type GettingStartedInfoService from '@/services/domain/gettingStartedInfoService'
import type { MonitoringLoggerService } from '@/services/monitoring/monitoringLoggerService'
import type { PerformanceMarkService } from '@/services/performanceMarkService'
import type MessageFromUserBulkManager from '@/services/domain/sentSms/messageFromUserBulkManager'
import type SenderSettingsCountriesGridable from '@/services/domain/senderSettings/senderSettingsCountriesGridable'
import type { FeatureFlagsService } from '@/services/featureFlagsService'
import type ReopenAccountService from '@/services/signUp/reopenAccountService'
import type SubAccountImportBulkManager from '@/services/domain/accounts/subAccounts/import/subAccountImportBulkManager'
import type { SmsContentService } from '@/services/smsContentService'
import type UserMetadataService from '@/services/domain/user/userMetadataService'
import type ChatMmsInfoService from '@/services/domain/chats/chatMmsInfoService'
import type RestrictionPagesRouterService from '@/services/auth/restrictionPagesRouterService'
import type { ScheduledEditService } from '@/services/domain/scheduled/scheduledEditService'
import type { MessageSendScheduleService } from '@/services/domain/messages/messageSendScheduleService'
import type { ReportingHistoricalTablePagerService } from '@/services/domain/reporting/common/reportingHistoricalTablePagerService'
import type { ReportingHistoricalTableStateService } from '@/services/domain/reporting/common/reportingHistoricalTableStateService'
import type { CacheService } from '@/services/cache/cacheService'
import type BaseModel from '@/data/models/BaseModel'
import type { TableRecoveryServiceInterface } from '@/services/tables/recovery/tableRecoveryServiceInterface'
import type TeamWorkflowService from '@/services/domain/accounts/teamWorkflowService'
import type LoggedInStatusService from '@/services/auth/loggedInStatusService'
import type UsersGridable from '@/services/domain/accounts/subAccounts/usersGridable'
import type SmartBannerService from '@/services/smartBannerService'
import type ByocPhonesGridable from '@/services/domain/byoc/gridable/byocPhonesGridable'
import type TaskBoardService from '@/services/domain/tasks/TaskBoardService'
import type { PostHogService } from '@/services/tracking/postHogService'
import type TeamSubscriptionService from '@/services/domain/accounts/teamSubscriptionService'
import type PlanExpiredResolverService from '@/services/resolvers/containers/planExpiredResolverService'
import type TaskStageService from '@/services/domain/tasks/TaskStageService'
import type { EmailUnsubscribeService } from '@/services/domain/emailUnsubscribe/EmailUnsubscribeService'
import type PriorityHelperService from '@/services/priority/priorityHelperService'
import type TeamSubscriptionPriceService from '@/services/domain/accounts/teamSubscriptionPriceService'
import type TeamSubscriptionInvoicesGridable from '@/services/domain/accounts/teamSubscriptionInvoicesGridable'
import type UserWorkflowInvitedTabResolverService from '@/services/resolvers/domain/account/userWorkflowInvitedTabResolverService'
import type UsersWorkflowFacetsProvider from '@/services/domain/accounts/usersWorkflowFacetsProvider'
import type { PinnedContactsService } from '@/services/domain/pinnedContact/pinnedContactService'
import type TypingService from '@/services/domain/typing/typingService'
import type WorkspaceServerSubscriptionService from '@/services/transport/workspaceServerSubscriptionService'
import type { LocalStorageServiceInterface } from '@/services/storage/types'
import type TaskService from '@/services/domain/tasks/TaskService'
import type WebWidgetsService from '@/services/domain/messages/settings/webWidgets/widgetsService'
import type ByocRouteHelperService from '@/services/domain/byoc/byocRouteHelperService'
import type TenDlcRouteHelperService from '@/services/domain/services/regulations/tenDlcRouteHelperService'
import type TollFreeRouteHelperService from '@/services/domain/services/regulations/tollFreeRouteHelperService'
import type SenderSettingsRouteHelperService from '@/services/domain/senderSettings/senderSettingsRouteHelperService'
import type WhatsAppConfigService from '@/services/domain/whatsApp/whatsAppConfigService'
import type WhatsAppAccountService from '@/services/domain/whatsApp/whatsAppAccountService'
import type TaskChecklistService from '@/services/domain/tasks/checklist/TaskChecklistService'
import type WebWidgetDetailsService from '@/services/domain/messages/settings/webWidgets/webWidgetDetailsService'
import type StripeBillingProviderService from '@/services/domain/billing/providers/stripeBillingProviderService'
import type BillingPlansToProviderAdapter from '@/services/domain/billing/providers/billingPlansToProviderAdapter'
import type WhatsAppAccountPhonesGridable from '@/services/domain/whatsApp/whatsAppAccountPhonesGridable'
import type WhatsAppBusinessPhoneService from '@/services/domain/whatsApp/whatsAppBusinessPhoneService'
import type TaskChecklistItemService from '@/services/domain/tasks/checklist/TaskChecklistItemService'
import type UserSessionService from '@/services/domain/user/userSessionService'
import type GoogleAnalyticsService from '@/services/tracking/googleAnalyticsService'
import type WrappersConfigService from '@/services/wrappers/wrappersConfigService'
import type KanbanService from '@/services/kanban/KanbanService'
import type KanbanPreloaderService from '@/services/kanban/KanbanPreloaderService'
import type AvailableToBuyNumbersService from '@/services/domain/phoneNumbers/availableToBuyNumbersService'
import type UiSettingsService from '@/services/domain/uiSettingsService'
import type UserSettingsService from '@/services/domain/user/userSettingsService'
import type TaskEditorService from '@/services/domain/tasks/taskEditorService'
import type TaskCommentService from '@/services/domain/tasks/comments/taskCommentService'
import type ChatMessageThreadService from '@/services/domain/chats/chatMessageThreadService'
import type TaskBoardArchivedService from '@/services/domain/tasks/TaskBoardArchivedService'
import type TaskBoardArchivatorService from '@/services/domain/tasks/TaskBoardArchivatorService'
import type AuditLogGridable from '@/services/domain/accounts/subAccounts/auditLogGridable'
import type TaskBoardViewSwitcherService from '@/services/domain/tasks/TaskBoardViewSwitcherService'
import type TaskBoardPreloaderService from '@/services/preloaders/taskBoardPreloaderService'
import type NoteGridable from '@/services/domain/note/noteGridable'
import type TaskBoardStagesPreloaderService from '@/services/preloaders/taskBoardStagesPreloaderService'
import type ContactNavigationOrderService from '@/services/domain/contact/contactNavigationOrderService'
import type { PosthogRecordingService } from '@/services/tracking/posthogRecordingService'
import type SmsSurveyNodesTreeService from '@/services/domain/services/smsSurveys/smsSurveyNodesTreeService'
import type TicketsService from '@/services/domain/tickets/ticketsService'
import type TicketStatusService from '@/services/domain/tickets/ticketStatus/ticketStatusService'
import type TicketInboxService from '@/services/domain/tickets/inboxes/ticketInboxService'
import type ConvertService from '@/services/tracking/convertService'
import type ByocFlowSenderIdsGridable from '@/services/domain/byoc/gridable/byocFlowSenderIdsGridable'
import type ByocProviderSenderIdService from '@/services/domain/byoc/byocProviderSenderIdService'
import type ByocCarrierSenderIdService from '@/services/domain/byoc/byocCarrierSenderIdService'
import type ByocSenderIdsGridable from '@/services/domain/byoc/gridable/byocSenderIdsGridable'
import type { CustomFieldSchemaService } from '@/services/domain/user/customFieldSchemaService'
import type { CustomFieldService } from '@/services/domain/cusomFields/customFieldsService'
import type { CustomFieldValueService } from '@/services/domain/contact/customFieldValueService'
import type TicketsRouteHelperService from '@/services/domain/tickets/ticketsRouteHelperService'
import type ByocDetailsSenderIdsGridable from '@/services/domain/byoc/gridable/byocDetailsSenderIdsGridable'
import type ByocCarrierLinkModalSenderIdsGridable from '@/services/domain/byoc/gridable/byocCarrierLinkModalSenderIdsGridable'
import type StatementsSummaryService from '@/services/domain/accounts/statementsSummaryService'
import type TicketMessageService from '@/services/domain/tickets/ticketMessage/ticketMessageService'
import type TenDlcTwilioBrandService from '@/services/domain/services/regulations/tenDlcBrand/tenDlcTwilioBrandService'
import type FormFactoryManager from '@/services/forms/baseForm/formFactoryManager'
import type { HotJarService } from '@/services/tracking/hotJarService'
import type TagService from '@/services/domain/tag/tagService'
import type FilteredViewsService from '@/services/domain/filteredViews/filteredViewsService'
import type { SegmentsService } from '@/services/domain/contact/segments/segmentsService'
import type { FilteredViewsForEntityService } from '@/services/domain/filteredViews/filteredViewsForEntityService'
import type TenDlcCampaignsGridable from '@/services/domain/services/regulations/tenDlcCampaigns/TenDlcCampaignsGridable'
import type TicketWorkspaceService from '@/services/domain/tickets/inboxes/ticketWorkspaceService'
import type BillingPlansInfoService from '@/services/domain/billing/billingPlansInfoService'
import type TicketSpamFilterService from '@/services/domain/tickets/spamFilter/ticketSpamFilterService'
import type { TicketFilterHelperService } from '@/services/domain/tickets/ticketFilterHelperService'
import type { RelativeDateTimeService } from '@/services/datetime/relativeDateTimeService'
import type TicketCountService from '@/services/domain/tickets/ticketCountService'
import type TicketMessageTrimmedContentService from '@/services/domain/tickets/ticketMessage/ticketMessageTrimmedContentService'
import type TicketsRefreshService from '@/services/domain/tickets/ticketsRefreshService'
import type { RangeFilterService } from '@/services/tables/filters/strategies/rangeFilterService'
import type TicketNativeNotificationService from '@/services/domain/tickets/ticketNativeNotificationService'
import type WidgetChatServerSubscriptionService from '@/services/transport/widgetChatServerSubscriptionService'
import type DealPipelinesPreloaderService from '@/services/domain/deals/dealPipelinesPreloaderService'
import type DealPipelineService from '@/services/domain/deals/dealPipelineService'
import type { Factory } from '@/types'
import type { FilterInterface } from '@/services/forms/types'
import type TicketMessageTextProcessingService from '@/services/domain/tickets/ticketMessage/ticketMessageTextProcessingService'
import type FacebookConfigService from '@/services/domain/messenger/facebook/facebookConfigService'
import type { FilteredViewsCompareService } from '@/services/domain/filteredViews/filteredViewsCompareService'
import type { SegmentsCompareService } from '@/services/domain/contact/segments/segmentsCompareService'
import type { FilterCompareService } from '@/services/tables/filters/filterCompareService'
import type { FilterCleanupService } from '@/services/tables/filters/filterCleanupService'
import type { SorterSerializerService } from '@/services/tables/sort/sorterSerializerService'
import type TenDlcCampaignsToLinkGridable from '@/services/domain/services/regulations/tenDlcCampaigns/TenDlcCampaignsToLinkGridable'
import type DealsKanbanService from '@/services/domain/deals/dealsKanbanService'
import type FacebookPageService from '@/services/domain/messenger/facebook/facebookPageService'
import type TicketFirstLastMessageService from '@/services/domain/tickets/ticketMessage/ticketFirstLastMessageService'
import type DealService from '@/services/domain/deals/dealService'
import type DealPipelineStagesPreloaderService from '@/services/preloaders/dealPipelineStagesPreloaderService'
import type AlsoHereService from '@/services/domain/alsoHere/alsoHereService'
import type ChannelPresenceService from '@/services/domain/channelPresence/channelPresenceService'
import type TmTiptapEditorExtensionsManager from '@/services/forms/tmTiptap/tmTiptapEditorExtensionsManager'
import type TicketFilterGridable from '@/services/domain/tickets/table/ticketFilterGridable'
import type ComposeScheduledTextFormatterService from '@/services/domain/compose/composeScheduledTextFormatterService'
import type RRuleTextFormatterService from '@/services/rrule/rRuleTextFormatterService'
import type MessageSendFormSchemeService from '@/services/domain/messages/messageSendFormSchemeService'
import type DealStageService from '@/services/domain/deals/dealStageService'
import type LiveChatActivityService from '@/services/domain/chats/liveChatActivityService'
import type DealPipelineArchivatorService from '@/services/domain/deals/dealPipelineArchivatorService'
import type DealPipelineArchivedService from '@/services/domain/deals/dealPipelineArchivedService'
import type { TicketsBulkService } from '@/services/domain/tickets/ticketsBulkService'
import type ChatDetailsService from '@/services/domain/chats/chatDetailsService'
import type FacebookActivityService from '@/services/domain/chats/facebookActivityService'
import type TicketsFilteredViewsGridable from '@/services/domain/tickets/table/ticketsFilteredViewsGridable'
import type DealPipelinePreloaderService from '@/services/preloaders/dealPipelinePreloaderService'
import type StripePaymentMethodService from '@/services/domain/billing/stripePaymentMethodService'
import type AdminSectionRedirectResolver from '@/services/resolvers/adminSectionRedirectResolver'
import type PointAiWebsitesService from '@/services/domain/services/pointAi/pointAiWebsitesService'
import type PointAiAssistantService from '@/services/domain/services/pointAi/pointAiAssistantService'
import type PointAiDocumentsService from '@/services/domain/services/pointAi/pointAiDocumentsService'
import type PointAiWebsocketService from '@/services/domain/services/pointAi/pointAiWebsocketService'
import type PrimaryBrowserTabService from '@/services/browser/primaryBrowserTabService'
import type EmailCampaignDomainService from '@/services/domain/campaign/emailCampaignDomainService'
import type DealTimelineItemService from '@/services/domain/deals/dealTimelineItemService'
import type FilteredViewsPreloaderService from '@/services/domain/filteredViews/filteredViewsPreloaderService'
import type DealActivityService from '@/services/domain/deals/activities/dealActivityService'
import type DealActivityItemService from '@/services/domain/deals/activities/dealActivityItemService'
import type AccountNotificationsSettingsService from '@/services/domain/accounts/accountNotificationsSettingsService'
import type SelectInboxFieldBuilderService from '@/services/forms/fieldBuilders/SelectInboxFieldBuilderService'
import type SenderPhoneFieldBuilderService from '@/services/forms/fieldBuilders/SenderPhoneFieldBuilderService'
import type KanbanItemAttachmentService from '@/services/kanban/KanbanItemAttachmentService'
import type MainSidebarRoutesResolverService from '@/services/resolvers/containers/mainSidebarRoutesResolverService'
import type ContactsBulkManager from '@/services/domain/contact/contactsBulkManager'
import type SmsCampaignService from '@/services/domain/campaign/smsCampaignService'
import type EmailCampaignEmailSenderService from '@/services/domain/campaign/emailCampaignEmailSenderService'
import type CampaignsSendersDomainGridable from '@/services/tables/columns/campaigns/campaignsSendersDomainGridable'
import type PointAiWebsitesGridable from '@/services/domain/services/pointAi/pointAiWebsitesGridable'
import type PointAiDocumentsGridable from '@/services/domain/services/pointAi/pointAiDocumentsGridable'
import type SessionStatService from '@/services/domain/session/sessionStatService'
import type { SegmentsFilteredViewsGridable } from '@/services/domain/contact/segments/table/segmentsFilteredViewsGridable'
import type PointAiWebsitesDownloadedService from '@/services/domain/services/pointAi/pointAiWebsitesDownloadedService'
import type ChatPreviewNextService from '@/services/domain/chats/chatPreviewNextService'
import type SelectFacebookPageFieldBuilderService from '@/services/forms/fieldBuilders/SelectFacebookPageFieldBuilderService'
import type SelectInstagramAccountFieldBuilderService from '@/services/forms/fieldBuilders/SelectInstagramAccountFieldBuilderService'
import type UserPresenceService from '@/services/domain/user/userPresenceService'
import type CampaignSmsGridable from '@/services/domain/campaign/campaignSmsGridable'
import type ModelSearcherManager from '@/services/search/modelSearcherManager'
import type EmailCampaignService from '@/services/domain/campaign/emailCampaignService'
import type SegmentsBulkManager from '@/services/domain/contact/segments/segmentsBulkManager'
import type { SegmentBlankGridable } from '@/services/domain/contact/segments/table/segmentBlankGridable'
import type InstagramAccountService from '@/services/domain/messenger/instagram/instagramAccountService'
import type TmTiptapEditorsManager from '@/services/forms/tmTiptap/tmTiptapEditorsManager'
import type TmTiptapEditorContentService from '@/services/forms/tmTiptap/tmTiptapEditorContentService'
import type TemplatesTableExpandService from '@/services/domain/templates/templatesTableExpandService'
import type BrowserIdentifierService from '@/services/browser/browserIdentifierService'
import type InstagramActivityService from '@/services/domain/chats/instagramActivityService'
import type HtmlService from '@/services/html/htmlService'
import type OutboundEmailsGridable from '@/services/domain/outboundEmails/outboundEmailsGridable'
import type OutboundEmailsBulkManager from '@/services/domain/outboundEmails/outboundEmailsBulkManager'
import type EmailCampaignRecipientsService from '@/services/domain/campaign/emailCampaignRecipientsService'
import type OutboundEmailsDetailsService from '@/services/domain/outboundEmails/outboundEmailsDetailsService'
import type OutboundEmailsBulkDetailsGridable from '@/services/domain/outboundEmails/outboundEmailsBulkDetailsGridable'
import type EmailCampaignMessageService from '@/services/domain/campaign/emailCampaignMessageService'
import type OutboundEmailsBulkDetailsRecipientsExportService from '@/services/domain/outboundEmails/outboundEmailsBulkDetailsRecipientsExportService'
import type EmailCampaignRecipientService from '@/services/domain/campaign/emailCampaignRecipientService'
import type OutboundEmailsBulkDetailsExportService from '@/services/domain/outboundEmails/outboundEmailsBulkDetailsExportService'
import type { SendingDateTimeFormGroupConfiguration } from '@/services/forms/messages/formGroupConfiguration/SendingDateTimeFormGroupConfiguration'
import type SmsSessionRecipientsService from '@/services/domain/session/smsSessionRecipientsService'

export const getFeatureFlagsService = () => get<FeatureFlagsService>('FeatureFlagsService')

export const getMonitoringService = () => get<MonitoringServiceInterface>('MonitoringService')

export const getHttpService = () => get<HttpService>('Api')

export const getEM = () => get<EntityManagerService>('EntityManager')

export const getSmartBannerService = () => get<SmartBannerService>('SmartBannerService')

export const getRouterService = () => get<RouterService>('RouterService')

export const getRouteLeaveService = () => get<RouteLeaveService>('RouteLeaveService')

export const getApiV2EntryService = () => get<ApiV2EntryService>('ApiV2EntryService')

export const getResolver = () => get<ResolverService>('ResolverService')

export const getDateTimeService = (): DateTimeService => get<DateTimeService>('DateTimeService')

export const getRelativeDateTimeService = (): RelativeDateTimeService =>
  get<RelativeDateTimeService>('RelativeDateTimeService')

export const getDateTimeRangerService = (): DateTimeRangerService => get<DateTimeRangerService>('DateTimeRangerService')

export const getTableBuilder = (): TableBuilderService => get<TableBuilderService>('TableBuilderService')

export const getBulkService = (): BulkServiceInterface<BulkBaseBody> => get<BulkService>('BulkService')

export const getUnsubscribedContactService = (): UnsubscribedContactService =>
  get<UnsubscribedContactService>('UnsubscribedContactService')

export const getNotificationService = (): NotificationService => get<NotificationService>('NotificationService')

export const getNativeNotificationService = (): NativeNotificationService =>
  get<NativeNotificationService>('NativeNotificationService')

export const getExportService = (): ExportService => get<ExportService>('ExportService')

export const getEntityExportService = (): EntityExportService => get('EntityExportService')

export const getTableManager = (): TableManager => get<TableManager>('TableManager')

export const getModelSubscriptionService = (): ModelSubscriptionService =>
  get<ModelSubscriptionService>('ModelSubscriptionService')

export const getLoggerService = (): LoggerService => get<LoggerService>('LoggerService')

export const getMonitoringLoggerService = () => get<MonitoringLoggerService>('MonitoringLoggerService')

export const getPerformanceMarkService = () => get<PerformanceMarkService>('PerformanceMarkService')

export const getConfirmationService = (): ConfirmationService => get<ConfirmationService>('ConfirmationService')

export const getAuthService = (): AuthService => get('AuthService')

export const getContactService = (): ContactService => get('ContactService')

export const getDistributionService = (): DistributionService => get('DistributionService')

export const getDistributionDetailsService = (): DistributionDetailsService => get('DistributionDetailsService')

export const getAutomationRuleService = (): AutomationRuleService => get('AutomationRuleService')

export const getAutomationRuleDetailsService = (): AutomationRuleDetailsService => get('AutomationRuleDetailsService')

export const getAutomationRuleLogService = (): AutomationRuleLogService => get('AutomationRuleLogService')

export const getBlockedContactService = (): BlockedContactService => get('BlockedContactService')

export const getContactListService = (): ContactListService => get('ContactListService')

export const getAccountsService = (): AccountsService => get('AccountsService')

export const getAccountNotificationsSettingsService = () =>
  get<AccountNotificationsSettingsService>('AccountNotificationsSettingsService')

export const getTemplatesService = (): TemplatesService => get('TemplatesService')

export const getTemplateCategoriesService = (): TemplateCategoriesService => get('TemplateCategoriesService')

export const getUserNumbersService = (): UserNumbersService => get('UserNumbersService')

export const getUserMetadataService = (): UserMetadataService => get('UserMetadataService')

export const getSipCallsService = (): SipCallsService => get('SipCallsService')

export const getOrderService = (): OrderService => get('OrderService')

export const getVisibilityService = (): VisibilityService => get('VisibilityService')

export const getVoiceCallService = (): VoiceCallService => get('VoiceCallService')

export const getValidationService = (): ValidationService => get<ValidationService>('ValidationService')

export const getValidationRulesBuilderService = (): ValidationRulesBuilderService =>
  get<ValidationRulesBuilderService>('ValidationRulesBuilderService')

export const getFormValidationService = (): FormValidationService => get<FormValidationService>('FormValidationService')

export const getNewQueryParamsService = () => get<NewQueryParamsService>('NewQueryParamsService')

export const getFormManager = () => get<FormManager>('FormManager')

export const getFormFactoryManager = () => get<FormFactoryManager>('FormFactoryManager')

export const getBaseFilters = () => get<BaseFilters>('BaseFilters')

export const getFilterService = (service: RegisteredServices) => get<BaseFiltersType>(service)

export const getRangeFilterService = () => get<RangeFilterService>('RangeFilterService')

export const getVuexService = (): VuexService => get<VuexService>('VuexService')

export const getSubscriptionService = (): SubscriptionService => get<SubscriptionService>('SubscriptionService')

export const getServerSubscriptionService = (): ServerSubscriptionService =>
  get<ServerSubscriptionService>('ServerSubscriptionService')

export const getTranslateService = (): TranslateService => get<TranslateService>('TranslateService')

export const getPointAiAssistantService = (): PointAiAssistantService =>
  get<PointAiAssistantService>('PointAiAssistantService')

export const getPointAiWebsitesService = (): PointAiWebsitesService =>
  get<PointAiWebsitesService>('PointAiWebsitesService')

export const getPointAiWebsitesDownloadedService = (): PointAiWebsitesDownloadedService =>
  get<PointAiWebsitesDownloadedService>('PointAiWebsitesDownloadedService')

export const getPointAiWebsitesGridable = (): PointAiWebsitesGridable =>
  get<PointAiWebsitesGridable>('PointAiWebsitesGridable')

export const getPointAiDocumentsGridable = (): PointAiDocumentsGridable =>
  get<PointAiDocumentsGridable>('PointAiDocumentsGridable')

export const getPointAiDocumentsService = (): PointAiDocumentsService =>
  get<PointAiDocumentsService>('PointAiDocumentsService')

export const getPointAiWebsocketService = (): PointAiWebsocketService =>
  get<PointAiWebsocketService>('PointAiWebsocketService')

export const getDomainManagerService = () => get<DomainManagerService>('DomainManagerService')

export const getWindowService = () => get<WindowService>('WindowService')

export const getScrollbarService = () => get<ScrollbarService>('ScrollbarService')

export const getTableScrollbarService = () => get<TableScrollbarService>('TableScrollbarService')

export const getPrimaryBrowserTabService = () => get<PrimaryBrowserTabService>('PrimaryBrowserTabService')

export const getBrowserIdentifierService = () => get<BrowserIdentifierService>('BrowserIdentifierService')

export const getUserService = () => get<UserService>('UserService')

export const getNewQueryModalService = (): NewQueryModalService => get<NewQueryModalService>('NewQueryModalService')

export const getModalService = (): ModalService => get<ModalService>('ModalService')

export const getWrapperManagerService = () => get<WrapperManagerService>('WrapperManagerService')

export const getPreloaderManager = () => get<PreloaderManager>('PreloaderManager')

export const getCleanUpManager = () => get<CleanUpManager>('CleanUpManager')

export const getFacetManager = () => get<FacetManager>('FacetManager')

export const getBulkManager = () => get<BulkManager>('BulkManager')

export const getPhoneService = () => get<PhoneService>('PhoneService')

export const getLocalStorageTransport = () => get<Transport>('LocalStorageTransport')

export const getHistoryService = () => get<Historyable>('HistoryService')

export const getTableBaseRecoveryService = () => get<TableRecoveryServiceInterface>('TableBaseRecoveryService')

export const getLRUCacheService = <T extends BaseModel = BaseModel>() => get<CacheService<T>>('LRUCacheService')

export const getFilterServiceManager = () => get<FilterServiceManager>('FilterServiceManager')

export const getTableFilterServiceManager = () => get<TableFilterServiceManager>('TableFilterServiceManager')

export const getSorterSerializerService = () => get<SorterSerializerService>('SorterSerializerService')

export const getTableSorterManager = () => get<TableSorterManager>('TableSorterManager')

export const getTableGroupingManager = () => get<TableGrouperManager>('TableGrouperManager')

export const getTableSearcherManager = () => get<TableSearcherManager>('TableSearcherManager')

export const getTableColumnManager = () => get<TableColumnManager>('TableColumnManager')

export const getTablePaginationManager = () => get<TablePaginatorManager>('TablePaginatorManager')

export const getOutboundCallService = () => get<OutboundCallsService>('OutboundCallsService')

export const getSentSmsAppearanceService = () => get<SentSmsAppearanceService>('SentSmsAppearanceService')

export const getSentSmsFullService = () => get<SentSmsFullService>('SentSmsFullService')

export const getSentSmsShortService = () => get<SentSmsShortService>('SentSmsShortService')

export const getSentSmsLocalStorageService = () => get<SentSmsLocalStorageService>('SentSmsLocalStorageService')

export const getSentSmsColumnPropsService = () => get<SentSmsColumnPropsService>('SentSmsColumnPropsService')

export const getRecentRecipientService = () => get<RecentRecipientService>('RecentRecipientService')

export const getReceivedSmsService = () => get<ReceivedSmsService>('ReceivedSmsService')

export const getInboundCallService = () => get<InboundCallsService>('InboundCallsService')

export const getForwardedCallService = () => get<ForwardedCallsService>('ForwardedCallsService')

export const getCountryService = () => get<CountryService>('CountryService')

export const getSessionService = () => get<SessionService>('SessionService')

export const getSessionStatService = () => get<SessionStatService>('SessionStatService')

export const getEndpointsService = () => get<AbstractEndpointsInterface>('EndpointsService')

export const getCarrierLookupService = () => get<CarrierLookupService>('CarrierLookupService')

export const getEmailLookupService = () => get<EmailLookupService>('EmailLookupService')

export const getSmsSurveysService = () => get<SmsSurveysService>('SmsSurveysService')

export const getSmsSurveyDetailsService = () => get<SmsSurveyDetailsService>('SmsSurveyDetailsService')

export const getSmsSurveyRepliesGridable = () => get<SmsSurveyRepliesGridable>('SmsSurveyRepliesGridable')

export const getSmsSurveyRepliesService = () => get<SmsSurveyRepliesService>('SmsSurveyRepliesService')

export const getSmsSurveyRecipientCountryService = () =>
  get<SmsSurveyRecipientCountryService>('SmsSurveyRecipientCountryService')

export const getSmsSurveyRecipientsCountriesGridable = () =>
  get<SmsSurveyRecipientsCountriesGridable>('SmsSurveyRecipientsCountriesGridable')

export const getSmsSurveyNodesService = () => get<SmsSurveyNodesService>('SmsSurveyNodesService')

export const getSmsSurveyNodesTreeService = () => get<SmsSurveyNodesTreeService>('SmsSurveyNodesTreeService')

export const getNoteService = (): NoteService => get<NoteService>('NoteService')

export const getContactImportsService = () => get<ContactImportsService>('ContactImportsService')

export const getBulkSentSmsService = () => get<BulkSentSmsService>('BulkSentSmsService')

export const getBulkSentSmsCounterService = () => get<BulkSentSmsCounterService>('BulkSentSmsCounterService')

export const getMessagesPriceService = () => get<MessagesPriceService>('MessagesPriceService')

export const getMessagesPriceForCountryService = () =>
  get<MessagesPriceForCountryService>('MessagesPriceForCountryService')

export const getMessagesBulksProgressService = () => get<MessagesBulksProgressService>('MessagesBulksProgressService')

export const getRecipientService = () => get<RecipientService>('RecipientService')

export const getContactListTempService = () => get<ContactListTempService>('ContactListTempService')

export const getMessagesPreviewService = () => get<MessagesPreviewService>('MessagesPreviewService')

export const getForwardAudioService = () => get<ForwardAudioService>('ForwardAudioService')

export const getScheduledService = () => get<ScheduledService>('ScheduledService')

export const getScheduledParametersService = () => get<ScheduledParametersService>('ScheduledParametersService')

export const getScheduledNotSentService = () => get<ScheduledNotSentService>('ScheduledNotSentService')

export const getCustomFieldSchemaService = () => get<CustomFieldSchemaService>('CustomFieldSchemaService')

export const getCustomFieldsService = (): CustomFieldService => get('CustomFieldService')

export const getChatService = (): ChatService => get('ChatService')

export const getChatPageInfoService = (): ChatPageInfoService => get('ChatPageInfoService')

export const getWidgetChatServerSubscriptionService = (): WidgetChatServerSubscriptionService =>
  get('WidgetChatServerSubscriptionService')

export const getNumericService = (): NumericService => get('NumericService')

export const getChatMessageService = (): ChatMessageService => get('ChatMessageService')

export const getChatMessageContentService = (): ChatMessageContentService => get('ChatMessageContentService')

export const getChatConversationSenderSettingsService = (): ChatConversationSenderSettingsService =>
  get('ChatConversationSenderSettingsService')

export const getBaseSmsHelperService = (): BaseSmsHelperService => get('BaseSmsHelperService')

export const getSenderIdService = (): SenderIdService => get('SenderIdService')

export const getSenderIdPriceService = (): SenderIdPriceService => get('SenderIdPriceService')

export const getChatStatisticsService = (): ChatStatisticsService => get('ChatStatisticsService')

export const getReportingOverviewService = () => get<ReportingOverviewService>('ReportingOverviewService')

export const getActivityCallService = (): ActivityCallService => get('ActivityCallService')

export const getLiveChatActivityService = (): LiveChatActivityService => get('LiveChatActivityService')

export const getFacebookActivityService = (): FacebookActivityService => get('FacebookActivityService')

export const getInstagramActivityService = (): InstagramActivityService => get('InstagramActivityService')

export const getCustomFieldValueService = (): CustomFieldValueService => get('CustomFieldValueService')

export const getGlobalStateService = (): GlobalStateService => get('GlobalStateService')

export const getChatSettingsService = (): ChatSettingsService => get('ChatSettingsService')

export const getCalendarEventService = (): CalendarEventService => get('CalendarEventService')

export const getAllowedEmailsService = (): AllowedEmailsService => get('AllowedEmailsService')

export const getSendReceiveSettingsService = (): SendReceiveSettingsService => get('SendReceiveSettingsService')

export const getComposeLocalService = (): ComposeLocalService => get('ComposeLocalService')

export const getCalendarViewManagerService = (): CalendarViewManagerService => get('CalendarViewManagerService')

export const getCalendarMonthlyService = (): CalendarMonthlyService => get('CalendarMonthlyService')

export const getTimezoneService = () => get<TimezoneService>('TimezoneService')

export const getComposeSuccessSendService = (): ComposeSuccessSendService => get('ComposeSuccessSendService')

export const getPhoneNumbersService = (): PhoneNumbersService => get('PhoneNumbersService')

export const getBillingDetailsService = (): BillingDetailsService => get('BillingDetailsService')

export const getMyDataExportService = (): MyDataExportService => get('MyDataExportService')

export const getAccountSettingsSecurityService = (): AccountSettingsSecurityService =>
  get('AccountSettingsSecurityService')

export const getApiV1SettingsService = () => get<ApiV1SettingsService>('ApiV1SettingsService')

export const getApiV2SettingsService = () => get<ApiV2SettingsService>('ApiV2SettingsService')

export const getSubAccountsImportService = (): SubAccountsImportService => get('SubAccountsImportService')

export const getSubAccountsImportDetailsService = (): SubAccountsImportDetailsService =>
  get('SubAccountsImportDetailsService')

export const getVoiceCallPriceService = (): VoiceCallPriceService => get('VoiceCallPriceService')

export const getSubAccountImportSessionService = () =>
  get<SubAccountImportSessionService>('SubAccountImportSessionService')

export const getVoiceCallConfigService = (): VoiceCallConfigService => get('VoiceCallConfigService')

export const getLoginHistoryService = (): LoginHistoryService => get('LoginHistoryService')

export const getSubAccountsActiveGridable = () => get<SubAccountsGridable>('SubAccountsActiveGridable')

export const getSubAccountsClosedGridable = () => get<SubAccountsGridable>('SubAccountsClosedGridable')

export const getUsersActiveGridable = () => get<UsersGridable>('UsersActiveGridable')

export const getUsersClosedGridable = () => get<UsersGridable>('UsersClosedGridable')

export const getUsersInvitedGridable = () => get<UsersGridable>('UsersInvitedGridable')

export const getSubAccountsEnabledGridable = () => get<SubAccountsGridable>('SubAccountsEnabledGridable')

export const getTemplatesSubAccountsActiveGridable = () =>
  get<SubAccountsGridable>('TemplatesSubAccountsActiveGridable')

export const getAuditLogService = (): AuditLogService => get('AuditLogService')

export const getSenderSettingsCountriesService = () =>
  get<SenderSettingsCountriesService>('SenderSettingsCountriesService')

export const getAccountSsoSettingsService = () => get<AccountSsoSettingsService>('AccountSsoSettingsService')

export const getAccountSettingsLoginHistoryGridable = () =>
  get<AccountSettingsLoginHistoryGridable>('AccountSettingsLoginHistoryGridable')

export const getPermissionsService = () => get<PermissionsService>('PermissionsService')

export const getRouterPermissionsService = () => get<RouterPermissionInterface>('RouterPermissionsService')

export const getKeywordService = () => get<KeywordService>('KeywordService')

export const getReportingCallsGridable = () => get<ReportingCallsGridable>('ReportingCallsGridable')

export const getReportingHistoricalTableStateService = () =>
  get<ReportingHistoricalTableStateService>('ReportingHistoricalTableStateService')

export const getReportingHistoricalTablePagerService = () =>
  get<ReportingHistoricalTablePagerService>('ReportingHistoricalTablePagerService')

export const getReportingCallsChartService = () => get<ReportingCallsChartService>('ReportingCallsChartService')

export const getReportingMessagesGridable = () => get<ReportingMessagesGridable>('ReportingMessagesGridable')

export const getReportingHistoricalTableQueryParamsService = () =>
  get<ReportingHistoricalTableQueryParamsService>('ReportingHistoricalTableQueryParamsService')

export const getReportingMessagesChartService = () =>
  get<ReportingMessagesChartService>('ReportingMessagesChartService')

export const getFormClickToTextService = () => get<FormClickToTextService>('FormClickToTextService')

export const getFormClickToTextDetailsService = () =>
  get<FormClickToTextDetailsService>('FormClickToTextDetailsService')

export const getFormSubscribeService = () => get<FormsSubscribesService>('FormSubscribeService')

export const getFormSubscribeDetailsService = () => get<FormSubscribeDetailsService>('FormSubscribeDetailsService')

export const getCarrierLookupSessionService = () => get<CarrierLookupSessionService>('CarrierLookupSessionService')

export const getEmailLookupSessionService = () => get<EmailLookupSessionService>('EmailLookupSessionService')

export const getBillingService = () => get<BillingService>('BillingService')

export const getNumbersPriceService = () => get<NumbersPriceService>('NumbersPriceService')

export const getMediaDeviceService = () => get<MediaDeviceService>('MediaDeviceService')

export const getTenDlcRequestService = () => get<TenDlcRequestService>('TenDlcRequestService')

export const getTenDlcPricingService = () => get<TenDlcPricingService>('TenDlcPricingService')

export const getTollFreeRequestService = () => get<TollFreeRequestService>('TollFreeRequestService')

export const getTollFreePhoneNumbersService = () => get<TollFreePhoneNumbersService>('TollFreePhoneNumbersService')

export const getTenDlcBrandService = () => get<TenDlcBrandService>('TenDlcBrandService')

export const getTenDlcTwilioBrandService = () => get<TenDlcTwilioBrandService>('TenDlcTwilioBrandService')

export const getRegulationsStatusService = () => get<RegulationsStatusService>('RegulationsStatusService')

export const getTenDlcCampaignsService = () => get<TenDlcCampaignsService>('TenDlcCampaignsService')

export const getTenDlcCampaignsGridable = () => get<TenDlcCampaignsGridable>('TenDlcCampaignsGridable')
export const getTenDlcCampaignsToLinkGridable = () =>
  get<TenDlcCampaignsToLinkGridable>('TenDlcCampaignsToLinkGridable')

export const getTenDlcPhoneNumbersService = () => get<TenDlcPhoneNumbersService>('TenDlcPhoneNumbersService')

export const getTenDlcBrowserStorageService = () => get<TenDlcBrowserStorageService>('TenDlcBrowserStorageService')

export const getTollFreeBrowserStorageService = () =>
  get<TollFreeBrowserStorageService>('TollFreeBrowserStorageService')

export const getTrackingService = () => get<TrackingService>('TrackingService')

export const getSubAccountsPermissionsService = () =>
  get<SubAccountsPermissionsService>('SubAccountsPermissionsService')

export const getInvoicesService = () => get<InvoicesService>('InvoicesService')

export const getByocService = () => get<ByocService>('ByocService')

export const getByocCarrierPhoneService = () => get<ByocCarrierPhoneService>('ByocCarrierPhoneService')

export const getByocCarrierSenderIdService = () => get<ByocCarrierSenderIdService>('ByocCarrierSenderIdService')

export const getByocCarrierService = () => get<ByocCarrierService>('ByocCarrierService')

export const getByocHelperService = () => get<ByocHelperService>('ByocHelperService')

export const getByocRouteHelperService = () => get<ByocRouteHelperService>('ByocRouteHelperService')

export const getByocProviderSenderIdService = () => get<ByocProviderSenderIdService>('ByocProviderSenderIdService')

export const getWebSocketService = (): IWebSocketService => get('WebSocketService')

export const getTitlerManager = (): TitlerManager => get('TitlerManager')

export const getContactImportResultService = () => get<ContactImportResultService>('ContactImportResultService')

export const getAttachmentFileService = (): AttachmentFileService => get('AttachmentFileService')

export const getUserRoleLabelDataService = (): UserRoleLabelDataService => get('UserRoleLabelDataService')

export const getByocProviderPhoneService = () => get<ByocProviderPhoneService>('ByocProviderPhoneService')

export const getByocDetailsPhonesGridable = () => get<ByocDetailsPhonesGridable>('ByocDetailsPhonesGridable')

export const getByocDetailsSenderIdsGridable = () => get<ByocDetailsSenderIdsGridable>('ByocDetailsSenderIdsGridable')

export const getByocCarrierLinkModalPhonesGridable = () =>
  get<ByocCarrierLinkModalPhonesGridable>('ByocCarrierLinkModalPhonesGridable')

export const getByocCarrierLinkModalSenderIdsGridable = () =>
  get<ByocCarrierLinkModalSenderIdsGridable>('ByocCarrierLinkModalSenderIdsGridable')

export const getByocFlowPhonesGridable = () => get<ByocFlowPhonesGridable>('ByocFlowPhonesGridable')

export const getByocFlowSenderIdsGridable = () => get<ByocFlowSenderIdsGridable>('ByocFlowSenderIdsGridable')

export const getByocPhonesGridable = () => get<ByocPhonesGridable>('ByocPhonesGridable')

export const getByocSenderIdsGridable = () => get<ByocSenderIdsGridable>('ByocSenderIdsGridable')

export const getOAuthSignUpService = (): OAuthSignUpService => get('OAuthSignUpService')

export const getWizardService = (): WizardService => get('WizardService')

export const getAutoDetectedCountryService = (): AutoDetectedCountryService => get('AutoDetectedCountryService')

export const getGoogleAnalyticsService = () => get<GoogleAnalyticsService>('GoogleAnalyticsService')

export const getHotJarService = () => get<HotJarService>('HotJarService')

export const getPostHogService = () => get<PostHogService>('PostHogService')

export const getPosthogRecordingService = () => get<PosthogRecordingService>('PosthogRecordingService')

export const getConvertService = () => get<ConvertService>('ConvertService')

export const getContactsBulkManager = () => get<ContactsBulkManager>('ContactsBulkManager')

export const getContactsInListBulkManager = () => get<ContactsInListBulkManager>('ContactsInListBulkManager')

export const getSubAccountImportBulkManager = () => get<SubAccountImportBulkManager>('SubAccountImportBulkManager')

export const getMessageFromUserBulkManager = () => get<MessageFromUserBulkManager>('MessageFromUserBulkManager')

export const getAuthLoaderService = () => get<AuthLoaderService>('AuthLoaderService')

export const getUiStateService = () => get<UiStateService>('UiStateService')

export const getPdfWorkerService = () => get<PdfWorkerService>('PdfWorkerService')

export const getGettingStartedInfoService = () => get<GettingStartedInfoService>('GettingStartedInfoService')

export const getSenderSettingsCountriesGridable = () =>
  get<() => SenderSettingsCountriesGridable>('SenderSettingsCountriesGridable')()

export const getSmsContentService = () => get<SmsContentService>('SmsContentService')

export const getChatMmsInfoService = () => get<ChatMmsInfoService>('ChatMmsInfoService')

export const getReopenAccountService = () => get<ReopenAccountService>('ReopenAccountService')

export const getRestrictionPagesRouterService = () =>
  get<RestrictionPagesRouterService>('RestrictionPagesRouterService')

export const getScheduledEditService = () => get<ScheduledEditService>('ScheduledEditService')

export const getMessageSendScheduleService = () => get<MessageSendScheduleService>('MessageSendScheduleService')

export const getTeamWorkflowService = () => get<TeamWorkflowService>('TeamWorkflowService')

export const getLoggedInStatusService = () => get<LoggedInStatusService>('LoggedInStatusService')

export const getKanbanService = () => get<KanbanService>('KanbanService')

export const getKanbanPreloaderService = () => get<KanbanPreloaderService>('KanbanPreloaderService')

export const getTaskService = () => get<TaskService>('TaskService')

export const getTaskBoardService = () => get<TaskBoardService>('TaskBoardService')

export const getTaskBoardPreloaderService = () => get<TaskBoardPreloaderService>('TaskBoardPreloaderService')

export const getTaskBoardViewSwitcherService = () => get<TaskBoardViewSwitcherService>('TaskBoardViewSwitcherService')

export const getTaskBoardArchivedService = () => get<TaskBoardArchivedService>('TaskBoardArchivedService')

export const getTaskStageService = () => get<TaskStageService>('TaskStageService')

export const getPriorityHelperService = () => get<PriorityHelperService>('PriorityHelperService')

export const getTaskBoardArchivatorService = () => get<TaskBoardArchivatorService>('TaskBoardArchivatorService')

export const getTaskBoardStagesPreloaderService = () =>
  get<TaskBoardStagesPreloaderService>('TaskBoardStagesPreloaderService')

export const getTeamSubscriptionService = () => get<TeamSubscriptionService>('TeamSubscriptionService')

export const getPlanExpiredResolverService = () => get<PlanExpiredResolverService>('PlanExpiredResolverService')

export const getEmailUnsubscribeService = () => get<EmailUnsubscribeService>('EmailUnsubscribeService')

export const getTeamSubscriptionPriceService = () => get<TeamSubscriptionPriceService>('TeamSubscriptionPriceService')

export const getTeamSubscriptionInvoicesGridable = () =>
  get<TeamSubscriptionInvoicesGridable>('TeamSubscriptionInvoicesGridable')

export const getUserWorkflowInvitedTabResolverService = () =>
  get<UserWorkflowInvitedTabResolverService>('UserWorkflowInvitedTabResolverService')

export const getUsersWorkflowFacetsProvider = () => get<UsersWorkflowFacetsProvider>('UsersWorkflowFacetsProvider')

export const getPinnedContactsService = () => get<PinnedContactsService>('PinnedContactsService')

export const getTypingService = () => get<TypingService>('TypingService')

export const getWorkspaceServerSubscriptionService = () =>
  get<WorkspaceServerSubscriptionService>('WorkspaceServerSubscriptionService')

export const getLocalStorageService = () => get<LocalStorageServiceInterface>('LocalStorageService')

export const getWebWidgetsService = () => get<WebWidgetsService>('WebWidgetsService')

export const getWebWidgetDetailsService = () => get<WebWidgetDetailsService>('WebWidgetDetailsService')

export const getTenDlcRouteHelperService = () => get<TenDlcRouteHelperService>('TenDlcRouteHelperService')

export const getTollFreeRouteHelperService = () => get<TollFreeRouteHelperService>('TollFreeRouteHelperService')

export const getSenderSettingsRouteHelperService = () =>
  get<SenderSettingsRouteHelperService>('SenderSettingsRouteHelperService')
export const getWhatsAppConfigService = () => get<WhatsAppConfigService>('WhatsAppConfigService')

export const getWhatsAppAccountService = () => get<WhatsAppAccountService>('WhatsAppAccountService')

export const getFacebookConfigService = () => get<FacebookConfigService>('FacebookConfigService')

export const getFacebookPageService = () => get<FacebookPageService>('FacebookPageService')

export const getBillingProviderService = () => get<StripeBillingProviderService>('StripeBillingProviderService')

export const getBillingPlansToProviderAdapter = () =>
  get<BillingPlansToProviderAdapter>('BillingPlansToProviderAdapter')

export const getWhatsAppBusinessPhoneService = () => get<WhatsAppBusinessPhoneService>('WhatsAppBusinessPhoneService')

export const getWhatsAppAccountPhonesGridable = () =>
  get<WhatsAppAccountPhonesGridable>('WhatsAppAccountPhonesGridable')

export const getUserSessionService = () => get<UserSessionService>('UserSessionService')

export const getTaskChecklistService = () => get<TaskChecklistService>('TaskChecklistService')
export const getTaskChecklistItemService = () => get<TaskChecklistItemService>('TaskChecklistItemService')

export const getWrappersConfigService = () => get<WrappersConfigService>('WrappersConfigService')

export const getAvailableToBuyNumbersService = () => get<AvailableToBuyNumbersService>('AvailableToBuyNumbersService')

export const getUiSettingsService = () => get<UiSettingsService>('UiSettingsService')

export const getUserSettingsService = () => get<UserSettingsService>('UserSettingsService')

export const getUserPresenceService = () => get<UserPresenceService>('UserPresenceService')

export const getTaskCommentService = () => get<TaskCommentService>('TaskCommentService')

export const getTaskEditorService = () => get<TaskEditorService>('TaskEditorService')

export const getTaskAttachmentsService = () => get<KanbanItemAttachmentService>('TaskAttachmentsService')

export const getChatMessageThreadService = () => get<ChatMessageThreadService>('ChatMessageThreadService')

export const getAuditLogGridable = () => get<AuditLogGridable>('AuditLogGridable')

export const getNoteGridable = () => get<NoteGridable>('NoteGridable')

export const getContactNavigationOrderService = () =>
  get<ContactNavigationOrderService>('ContactNavigationOrderService')

export const getTicketsService = () => get<TicketsService>('TicketsService')

export const getTicketInboxService = () => get<TicketInboxService>('TicketInboxService')

export const getTicketStatusService = () => get<TicketStatusService>('TicketStatusService')

export const getTicketsRouteHelperService = () => get<TicketsRouteHelperService>('TicketsRouteHelperService')

export const getStatementsSummaryService = () => get<StatementsSummaryService>('StatementsSummaryService')

export const getTicketMessageService = () => get<TicketMessageService>('TicketMessageService')

export const getTicketWorkspaceService = () => get<TicketWorkspaceService>('TicketWorkspaceService')

export const getTicketSpamFilterService = () => get<TicketSpamFilterService>('TicketSpamFilterService')

export const getTagService = () => get<TagService>('TagService')

export const getSegmentsService = () => get<SegmentsService>('SegmentsService')

export const getFilteredViewsService = () => get<FilteredViewsService>('FilteredViewsService')

export const getFilteredViewsForEntityService = () =>
  get<FilteredViewsForEntityService>('FilteredViewsForEntityService')

export const getFilterCompareService = () => get<FilterCompareService>('FilterCompareService')

export const getFilterCleanupService = () => get<FilterCleanupService>('FilterCleanupService')

export const getFilteredViewsCompareService = () => get<FilteredViewsCompareService>('FilteredViewsCompareService')

export const getSegmentsCompareService = () => get<SegmentsCompareService>('SegmentsCompareService')

export const getBillingPlansInfoService = () => get<BillingPlansInfoService>('BillingPlansInfoService')

export const getTicketFilterHelperService = () => get<TicketFilterHelperService>('TicketFilterHelperService')

export const getTicketCountService = () => get<TicketCountService>('TicketCountService')

export const getTicketMessageTrimmedContentService = () =>
  get<TicketMessageTrimmedContentService>('TicketMessageTrimmedContentService')

export const getTicketsRefreshService = () => get<TicketsRefreshService>('TicketsRefreshService')

export const getTicketNativeNotificationService = () =>
  get<TicketNativeNotificationService>('TicketNativeNotificationService')

export const getDealPipelineService = () => get<DealPipelineService>('DealPipelineService')

export const getDealPipelinesPreloaderService = () =>
  get<DealPipelinesPreloaderService>('DealPipelinesPreloaderService')

export const getDealPipelinePreloaderService = () => get<DealPipelinePreloaderService>('DealPipelinePreloaderService')

export const getDealsKanbanService = () => get<DealsKanbanService>('DealsKanbanService')

export const getChatFilterServiceFactory = () => get<Factory<FilterInterface>>('ChatFilterServiceFactory')

export const getChatDetailsService = () => get<ChatDetailsService>('ChatDetailsService')

export const getChatPreviewNextService = (): ChatPreviewNextService => get('ChatPreviewNextService')

export const getTicketMessageTextProcessingService = () =>
  get<TicketMessageTextProcessingService>('TicketMessageTextProcessingService')

export const getTicketFirstLastMessageService = () =>
  get<TicketFirstLastMessageService>('TicketFirstLastMessageService')

export const getDealService = () => get<DealService>('DealService')

export const getDealTimelineItemsService = () => get<DealTimelineItemService>('DealTimelineItemService')

export const getDealPipelineStagesPreloaderService = () =>
  get<DealPipelineStagesPreloaderService>('DealPipelineStagesPreloaderService')

export const getAlsoHereService = () => get<AlsoHereService>('AlsoHereService')

export const getChannelPresenceService = () => get<ChannelPresenceService>('ChannelPresenceService')

export const getTmTiptapEditorExtensionsManager = () =>
  get<TmTiptapEditorExtensionsManager>('TmTiptapEditorExtensionsManager')

export const getTicketFilterGridable = () => get<TicketFilterGridable>('TicketFilterGridable')

export const getComposeScheduledTextFormatterService = () =>
  get<ComposeScheduledTextFormatterService>('ComposeScheduledTextFormatterService')

export const getRRuleTextFormatterService = () => get<RRuleTextFormatterService>('RRuleTextFormatterService')

export const getMessageSendFormSchemeService = () => get<MessageSendFormSchemeService>('MessageSendFormSchemeService')

export const getDealStageService = () => get<DealStageService>('DealStageService')

export const getDealPipelineArchivatorService = () =>
  get<DealPipelineArchivatorService>('DealPipelineArchivatorService')

export const getDealPipelineArchivedService = () => get<DealPipelineArchivedService>('DealPipelineArchivedService')

export const getTicketsBulkService = () => get<TicketsBulkService>('TicketsBulkService')

export const getTicketsFilteredViewsGridable = () => get<TicketsFilteredViewsGridable>('TicketsFilteredViewsGridable')

export const getStripePaymentMethodService = () => get<StripePaymentMethodService>('StripePaymentMethodService')

export const getEmailCampaignDomainService = () => get<EmailCampaignDomainService>('EmailCampaignDomainService')

export const getEmailCampaignEmailSenderService = () =>
  get<EmailCampaignEmailSenderService>('EmailCampaignEmailSenderService')

export const getAdminSectionRedirectResolver = () => get<AdminSectionRedirectResolver>('AdminSectionRedirectResolver')

export const getDealActivityService = () => get<DealActivityService>('DealActivityService')

export const getDealActivityItemService = () => get<DealActivityItemService>('DealActivityItemService')

export const getFilteredViewsPreloaderService = () =>
  get<FilteredViewsPreloaderService>('FilteredViewsPreloaderService')

export const getSegmentsFilteredViewsGridable = () =>
  get<SegmentsFilteredViewsGridable>('SegmentsFilteredViewsGridable')

export const getSegmentBlankGridable = () => get<SegmentBlankGridable>('SegmentBlankGridable')

export const getSegmentsBulkManager = () => get<SegmentsBulkManager>('SegmentsBulkManager')

export const getSenderPhoneFieldBuilderService = () =>
  get<SenderPhoneFieldBuilderService>('SenderPhoneFieldBuilderService')

export const getSelectInboxFieldBuilderService = () =>
  get<SelectInboxFieldBuilderService>('SelectInboxFieldBuilderService')

export const getSelectFacebookPageFieldBuilderService = () =>
  get<SelectFacebookPageFieldBuilderService>('SelectFacebookPageFieldBuilderService')

export const getSelectInstagramAccountFieldBuilderService = () =>
  get<SelectInstagramAccountFieldBuilderService>('SelectInstagramAccountFieldBuilderService')

export const getSmsCampaignService = () => get<SmsCampaignService>('SmsCampaignService')

export const getEmailCampaignService = () => get<EmailCampaignService>('EmailCampaignService')

export const getCampaignsSendersDomainGridable = () =>
  get<() => CampaignsSendersDomainGridable>('CampaignsSendersDomainGridable')

export const getDealAttachmentsService = () => get<KanbanItemAttachmentService>('DealAttachmentsService')

export const getModelSearcherManager = () => get<ModelSearcherManager>('ModelSearcherManager')

export const getMainSidebarRoutesResolverService = () =>
  get<MainSidebarRoutesResolverService>('MainSidebarRoutesResolverService')

export const getCampaignArchivedSmsGridable = () => get<CampaignSmsGridable>('CampaignArchivedSmsGridable')

export const getCampaignSentSmsGridable = () => get<CampaignSmsGridable>('CampaignSentSmsGridable')

export const getInstagramAccountService = () => get<InstagramAccountService>('InstagramAccountService')

export const getTiptapEditorsManager = () => get<TmTiptapEditorsManager>('TmTiptapEditorsManager')

export const getTiptapEditorContentService = () => get<TmTiptapEditorContentService>('TmTiptapEditorContentService')

export const getTemplatesTableExpandService = () => get<TemplatesTableExpandService>('TemplatesTableExpandService')

export const getHtmlService = () => get<HtmlService>('HtmlService')

export const getOutboundEmailsGridable = () => get<OutboundEmailsGridable>('OutboundEmailsGridable')

export const getOutboundEmailsBulkManager = () => get<OutboundEmailsBulkManager>('OutboundEmailsBulkManager')

export const getEmailCampaignRecipientsService = () =>
  get<EmailCampaignRecipientsService>('EmailCampaignRecipientsService')

export const getOutboundEmailsDetailsService = () => get<OutboundEmailsDetailsService>('OutboundEmailsDetailsService')

export const getOutboundEmailsBulkDetailsRecipientsGridable = () =>
  get<OutboundEmailsBulkDetailsGridable<unknown>>('OutboundEmailsBulkDetailsRecipientsGridable')

export const getEmailCampaignMessageService = () => get<EmailCampaignMessageService>('EmailCampaignMessageService')

export const getOutboundEmailsBulkDetailsRecipientsExportService = () =>
  get<OutboundEmailsBulkDetailsRecipientsExportService>('OutboundEmailsBulkDetailsRecipientsExportService')

export const getEmailCampaignRecipientService = () =>
  get<EmailCampaignRecipientService>('EmailCampaignRecipientService')

export const getOutboundEmailsBulkDetailsOpensExportService = () =>
  get<OutboundEmailsBulkDetailsExportService>('OutboundEmailsBulkDetailsOpensExportService')

export const getOutboundEmailsBulkDetailsOpensGridable = () =>
  get<OutboundEmailsBulkDetailsGridable<unknown>>('OutboundEmailsBulkDetailsOpensGridable')

export const getOutboundEmailsBulkDetailsUnsubscribersGridable = () =>
  get<OutboundEmailsBulkDetailsGridable<unknown>>('OutboundEmailsBulkDetailsUnsubscribersGridable')

export const getOutboundEmailsBulkDetailsUnsubscribeExportService = () =>
  get<OutboundEmailsBulkDetailsExportService>('OutboundEmailsBulkDetailsUnsubscribeExportService')

export const getOutboundEmailsBulkDetailsSpamReportsGridable = () =>
  get<OutboundEmailsBulkDetailsGridable<unknown>>('OutboundEmailsBulkDetailsSpamReportsGridable')

export const getOutboundEmailsBulkDetailsSpamReportsExportService = () =>
  get<OutboundEmailsBulkDetailsExportService>('OutboundEmailsBulkDetailsSpamReportsExportService')

export const getOutboundEmailsBulkDetailsClicksSummaryGridable = () =>
  get<OutboundEmailsBulkDetailsGridable<unknown>>('OutboundEmailsBulkDetailsClicksSummaryGridable')

export const getOutboundEmailsBulkDetailsClicksSummaryExportService = () =>
  get<OutboundEmailsBulkDetailsExportService>('OutboundEmailsBulkDetailsClicksSummaryExportService')

export const getOutboundEmailsBulkDetailsClicksGridable = () =>
  get<OutboundEmailsBulkDetailsGridable<unknown>>('OutboundEmailsBulkDetailsClicksGridable')

export const getOutboundEmailsBulkDetailsClicksExportService = () =>
  get<OutboundEmailsBulkDetailsExportService>('OutboundEmailsBulkDetailsClicksExportService')

export const getSendingDateTimeFormGroupConfiguration = () =>
  get<SendingDateTimeFormGroupConfiguration>('SendingDateTimeFormGroupConfiguration')

export const getSmsSessionRecipientsService = () => get<SmsSessionRecipientsService>('SmsSessionRecipientsService')

export { get, unbind }

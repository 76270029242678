import { inject, injectable } from 'inversify'
import { isValidNumberForRegion } from 'libphonenumber-js'
import type { CountryCode } from 'libphonenumber-js'
import type { AddRuleFunc, ValidationRegistratorInterface } from '@/services/validation/validationRegistratorInterface'
import { SERVICE_TYPES } from '@/core/container/types'
import type PhoneService from '@/services/phoneService'
import type { PhoneWithCountryCode } from '@/services/forms/types'
import type { TmCountryCode } from '@/services/types'

@injectable()
export default class PhoneValidationRegistrator implements ValidationRegistratorInterface {
  constructor(@inject(SERVICE_TYPES.PhoneService) protected readonly phoneService: PhoneService) {}

  public register(addRule: AddRuleFunc) {
    /**
     * Would return true if it's valid number for any region
     */
    addRule('phoneNumber', (number: string, [target]: CountryCode[]) => {
      if (!number) {
        return true
      }

      return this.phoneService.isValidPhoneNumber(number, target)
        ? true
        : { message: { path: 'commonValidationRegistrator.phoneNumber' } }
    })

    /**
     * Would return false if number invalid for selected region
     */
    addRule('phoneNumberForRegion', (number: string, [target]: string[]) =>
      isValidNumberForRegion(number, target as CountryCode)
        ? true
        : { message: { path: 'commonValidationRegistrator.phoneNumberForRegion' } },
    )

    addRule('phoneRequired', ({ phone, countryCode }: PhoneWithCountryCode) => {
      const trimmedPhone = phone.trim()
      if (!trimmedPhone) return false
      let countryId: TmCountryCode | null = countryCode
      if (!countryId) {
        countryId = this.phoneService.getCountryByPhone(phone)
        if (!countryId) {
          return false
        }
      }
      const phonePrefix = this.phoneService.getPhoneLeadCodeByCountryCode(countryId)
      return trimmedPhone !== phonePrefix
    })

    addRule('contactPhoneValid', ({ phone, countryCode }: PhoneWithCountryCode) => {
      return this.phoneService.validateContactPhone({ phone, countryCode })
    })

    addRule('phoneValid', ({ phone, countryCode }: PhoneWithCountryCode) => {
      return this.phoneService.validatePhone({ phone, countryCode })
    })
  }
}

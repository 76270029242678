import type { PhoneWithCountryCode } from '@/services/forms/types'

export const forwardCallsToVariants = ['off', 'chat', 'phone'] as const

export type ForwardCallsTo = (typeof forwardCallsToVariants)[number]
export interface CallForwardingFormScheme {
  forwardCallsTo: ForwardCallsTo
  forwardCallsToYourNumber: PhoneWithCountryCode
  greeting: string
}

import type { RouteRecordRaw } from 'vue-router'
import { ScheduledRoutes } from '@/routes/user/scheduled/types'
import { AppModule } from '@/config/types'

export const scheduledCalenderRoutes: RouteRecordRaw = {
  name: ScheduledRoutes.calendar,
  path: 'calendar/:viewType?/:date?',
  meta: {
    tags: ['SCHEDULED_BAR_LIST'],
    title: 'pageTitle.user.scheduled.calendar', // page content title @todo: get rid of it
    tabTitle: 'pageTabTitle.user.scheduled.calendar',
    modules: [AppModule.ScheduledCalendar],
    accessControlGroups: ['NotCampaignsCalendarStrategy'],
  },
  component: () => import('@/components/views/scheduled/ScheduledCalendar.vue'),
}

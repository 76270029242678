import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseFilterModel from '@/data/models/filters/BaseFilterModel'
import { FILTER_TYPE_ARRAY } from '@/data/models/filters/types'

@OrmModel(modelNames.ARRAY_FILTER, modelNames.BASE_FILTER, {
  BASE_FILTER: BaseFilterModel,
  ARRAY_FILTER: ArrayFilterModel,
})
export class ArrayFilterModel extends BaseFilterModel {
  public get filterType() {
    return FILTER_TYPE_ARRAY
  }

  @StringField(',')
  public valueDelimiter: string
}

import { injectable } from 'inversify'
import type { Collection } from '@vuex-orm/core'
import Sorter from '@/data/models/tables/Sorter'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'

@RepoSettings<Endpoint>({
  model: Sorter,
})
@injectable()
export default class SorterRepository extends OrmApiRepository<Sorter> {
  public getSortersByTableId(tableId: string) {
    return this.query()
      .where((sorter: Sorter) => sorter.tableModelId === tableId)
      .get() as Collection<Sorter>
  }
}

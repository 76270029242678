import { injectable, inject } from 'inversify'
import { isEmpty } from 'lodash-es'
import type {
  DefaultColumn,
  DefaultColumns,
  RawDefaultColumns,
  Column,
  ColumnsInterface,
  IsRowDisabledProps,
  StoredColumn,
} from '@/core/tables/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type Columns from '@/data/models/tables/Columns'
import type ColumnsFromStoreFactory from '@/services/tables/columnsFromStoreFactory'
import { TmTableColumnError } from '@/core/error/table/tmTableColumnError'
import type { ColumnsSettings, PaginationUrlColumnType } from '@/services/tables/types'
import { defaultGrowRatio, defaultMinWidth } from '@/core/tables/types'
import type { ILogger } from '@/services/types'

@injectable()
export default abstract class ColumnsFromApiFactory implements ColumnsInterface {
  protected readonly columnsFromStore: ColumnsFromStoreFactory

  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ColumnsFromStoreFactory) columnsFromStoreFactory: () => ColumnsFromStoreFactory,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: ILogger,
  ) {
    this.columnsFromStore = columnsFromStoreFactory()
  }

  private applyDefaultValuesToColumns(columns: RawDefaultColumns): RawDefaultColumns {
    return Object.keys(columns).reduce((acc, key) => {
      acc[key] = {
        growRatio: defaultGrowRatio,
        minWidth: defaultMinWidth,
        ...columns[key],
      }
      return acc
    }, {} as RawDefaultColumns)
  }

  public async init(columns?: DefaultColumns) {
    const items = this.applyDefaultValuesToColumns(columns || this.getInitColumns())
    await this.columnsFromStore.init(items)
  }

  public async reload(): Promise<void> {
    await this.init()
  }

  public getInitColumns(): RawDefaultColumns {
    return this.columnsFromStore.getInitColumns()
  }

  public getVisibleColumns(): Array<DefaultColumn> {
    return this.columnsFromStore.getVisibleColumns()
  }

  public getInvisibleColumns(): Array<DefaultColumn> {
    return this.columnsFromStore.getInvisibleColumns()
  }

  public getColumnEditorColumns(): Array<DefaultColumn> {
    return this.columnsFromStore.getColumnEditorColumns()
  }

  public getColumns(): Columns {
    return this.columnsFromStore.getColumns()
  }

  public toQuery(): PaginationUrlColumnType {
    return this.columnsFromStore.toQuery()
  }

  public async makeColumns(
    tableId: string,
    columns?: PaginationUrlColumnType,
    callback?: (columns: Column[]) => Promise<void>,
  ): Promise<StoredColumn[]> {
    try {
      const data: Array<Column> = await this.loadColumns(tableId)

      if (isEmpty(data)) {
        this.log('empty data from back', `makeColumns:${tableId}`)
        return await this.columnsFromStore.makeColumns(tableId, columns)
      }

      const storedColumns = data.map((col) => {
        return {
          name: col.columnName,
          isVisible: col.visible,
        }
      })

      this.columnsFromStore.updateStore(storedColumns)
      callback?.(data)

      return storedColumns
    } catch (e) {
      this.log('error on load columns', `makeColumns:${tableId}`)
      return this.columnsFromStore.makeColumns(tableId, columns)
    }
  }

  public async recoveryColumns(
    tableId: string,
    columns?: PaginationUrlColumnType,
    callback?: (columns: Column[]) => Promise<void>,
  ): Promise<StoredColumn[]> {
    const columnsQuery = await this.columnsFromStore.recoveryColumns(tableId, columns)
    this.makeColumns(tableId, columns, callback)
    return columnsQuery
  }

  public async loadColumns(tableId: string): Promise<Array<Column>> {
    if (this.isEditable()) {
      return this.columnsFromStore.getColumnRepository().loadColumns(tableId)
    }

    const columns = this.columnsFromStore.getColumns()
    if (columns.columns.length > 0) {
      return columns.columns.map(
        (col, index): Column => ({
          columnName: col.name,
          columnOrder: index,
          visible: col.isVisible,
        }),
      )
    }

    return []
  }

  public async saveColumns(columns: Column[], entity: string, disabledSync: boolean) {
    try {
      this.log('To save', `saveColumns${this.getSettings().tableId}`)
      this.logRaw(columns)
      if (!disabledSync) {
        await this.columnsFromStore.getColumnRepository().saveColumns(columns, entity)
      }
      return await this.columnsFromStore.saveColumns(columns, entity)
    } catch (e) {
      if (e instanceof Error) {
        throw new TmTableColumnError(e.toString())
      }
    }

    return []
  }

  public getDefaultColumns() {
    return this.columnsFromStore.getDefaultColumns()
  }

  public getColumn(columnName: string): DefaultColumn {
    return this.columnsFromStore.getColumn(columnName)
  }

  public hasColumn(name: string): boolean {
    return this.columnsFromStore.hasColumn(name)
  }

  public setTableId(tableId: string) {
    this.columnsFromStore.setTableId(tableId)
  }

  public getTableId(): string {
    return this.columnsFromStore.getTableId()
  }

  public getSettings(): ColumnsSettings {
    return this.columnsFromStore.getSettings()
  }

  public columnsToPaginationUrlColumn(columns: DefaultColumn[]): PaginationUrlColumnType {
    return this.columnsFromStore.columnsToPaginationUrlColumn(columns)
  }

  public reset() {
    this.columnsFromStore.reset()
  }

  public isEditable(): boolean {
    return false
  }

  public isSortable(): boolean {
    return false
  }

  public isDefaultState(ignoreOrder = true): boolean {
    return this.columnsFromStore.isDefaultState(ignoreOrder)
  }

  public isRowDisabled(props: IsRowDisabledProps): boolean {
    return false
  }

  protected filterVisibleColumns(data: Column[]): Column[] {
    return data.filter((column: Column) => {
      if (!column || !column.columnName || !this.hasColumn(column.columnName)) {
        return false
      }
      const columnDefinition = this.getColumn(column.columnName)
      if (typeof column.columnOrder === 'number') {
        columnDefinition.columnOrder = column.columnOrder
      }
      return columnDefinition && column.visible
    })
  }

  protected log(message: string, subchannel?: string) {
    this.loggerService.log('columns', message, subchannel)
  }

  protected logRaw(raw: unknown) {
    this.loggerService.raw('columns', raw)
  }
}

import { enumKeysToArray } from '@/utils/enum'
import type { SandboxServices } from '@/core/container/sandbox/sandbox.types'
import { SANDBOX_SERVICE_TYPES } from '@/core/container/sandbox/sandbox.types'
import { CHAT_SERVICE_TYPES, type ChatServices } from '@/core/container/chat.types'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { REPORTING_SERVICE_TYPES, type ReportingServices } from '@/core/container/reporting.types'
import { EMAIL_TO_SMS_SERVICE_TYPES, type EmailToSmsServices } from '@/core/container/emailToSms.types'
import { CARRIER_LOOKUP_SERVICE_TYPES, type CarrierLookupServices } from '@/core/container/lookup/carrierLookup.types'
import { SMS_SURVEYS_SERVICE_TYPES, type SmsSurveysServices } from '@/core/container/smsSurveys.types'
import {
  SENDER_SETTINGS_SERVICE_TYPES,
  type SenderSettingsServices,
} from '@/core/container/senderSettings/senderSettings.types'
import { EMAIL_LOOKUP_SERVICE_TYPES, type EmailLookupServices } from '@/core/container/lookup/emailLookup.types'
import { AUTOMATION_RULES_SERVICE_TYPES, type AutomationRulesServices } from '@/core/container/automationRules.types'
import {
  SIGNUP_BY_INVITE_SERVICE_TYPES,
  type SignupByInviteServices,
} from '@/core/container/unauthorized/signupByInvite.types'
import { VERIFY_EMAIL_SERVICE_TYPES, type VerifyEmailService } from '@/core/container/unauthorized/verifyEmail.types'
import {
  SIGNUP_BY_OAUTH_SERVICE_TYPES,
  type SignUpByOAuthServices,
} from '@/core/container/unauthorized/signupByOAuth.types'
import { TFA_SERVICE_TYPES, type TFAServices } from '@/core/container/unauthorized/tfaPages.types'
import { NOTIFICATIONS_SERVICE_TYPES, type NotificationsServices } from '@/core/container/notifications.types'
import { ACCOUNT_SETTINGS_SERVICE_TYPES, type AccountSettingsServices } from '@/core/container/accountSettings.types'
import {
  SIGNUP_CONFIRMATION_SERVICE_TYPES,
  type SignupConfirmationServices,
} from '@/core/container/unauthorized/signupConfirmation.types'
import { LOGOUT_SERVICE_TYPES, type LogoutServices } from '@/core/container/unauthorized/logout.types'
import {
  EMAIL_CONFIRMATION_SERVICE_TYPES,
  type EmailConfirmationServices,
} from '@/core/container/unauthorized/emailConfirmation.types'
import { LOGIN_SSO_SERVICE_TYPES, type LoginSSOServices } from '@/core/container/unauthorized/loginSSO.types'
import {
  FORGOT_PASSWORD_SERVICE_TYPES,
  type ForgotPasswordServices,
} from '@/core/container/unauthorized/forgotPassword.types'
import {
  RESET_PASSWORD_SERVICE_TYPES,
  type ResetPasswordServices,
} from '@/core/container/unauthorized/resetPassword.types'
import { API_SERVICE_TYPES, type ApiServices } from '@/core/container/api.types'
import { SUB_ACCOUNTS_SERVICE_TYPES, type SubAccountsServices } from '@/core/container/subAccounts/subAccounts.types'
import { objectWithUniqueFieldBuilder } from '@/utils/objectWithUniqueFieldBuilder'
import {
  AUTO_DETECTED_COUNTRY_TYPES,
  type AutoDetectedCountryServices,
} from '@/core/container/autoDetectedCountry.types'
import { BILLING_SERVICE_TYPES, type BillingServices } from '@/core/container/billing.types'
import {
  CHECK_EMAIL_FOR_SIGNUP_SERVICE_TYPES,
  type CheckEmailForSignupServices,
} from '@/core/container/unauthorized/checkEmailForSignup.types'
import { INVOICES_SERVICE_TYPES, type InvoicesServices } from '@/core/container/invoices.types'
import {
  HISTORY_SENT_SMS_SERVICE_TYPES,
  type HistorySentSmsServices,
} from '@/core/container/history/historySentSms.types'
import { TEMPLATES_SERVICE_TYPES, type TemplatesServices } from '@/core/container/templates.types'
import { MY_DATA_SERVICE_TYPES, type MyDataServices } from '@/core/container/myData.types'
import {
  HISTORY_RECEIVED_SMS_SERVICE_TYPES,
  type HistoryReceivedSmsServices,
} from '@/core/container/history/historyReceivedSms.types'
import {
  HISTORY_FORWARDED_CALLS_SERVICE_TYPES,
  type HistoryForwardedCallsServices,
} from '@/core/container/history/historyForwardedCalls.types'
import {
  HISTORY_OUTBOUND_CALLS_SERVICE_TYPES,
  type HistoryOutboundCallsServices,
} from '@/core/container/history/historyOutboundCalls.types'
import {
  HISTORY_INBOUND_CALLS_SERVICE_TYPES,
  type HistoryInboundCallsServices,
} from '@/core/container/history/historyInboundCalls.types'
import {
  SCHEDULED_UPCOMING_SERVICE_TYPES,
  type ScheduledUpcomingServices,
} from '@/core/container/scheduled/scheduledUpcoming.types'
import {
  SCHEDULED_PAUSED_SERVICE_TYPES,
  type ScheduledPausedServices,
} from '@/core/container/scheduled/scheduledPaused.types'
import {
  SCHEDULED_NOT_SENT_SERVICE_TYPES,
  type ScheduledNotSentServices,
} from '@/core/container/scheduled/scheduledNotSent.types'
import {
  SCHEDULED_COMPLETED_SERVICE_TYPES,
  type ScheduledCompletedServices,
} from '@/core/container/scheduled/scheduledCompleted.types'
import {
  SCHEDULED_CALENDAR_SERVICE_TYPES,
  type ScheduledCalendarServices,
} from '@/core/container/scheduled/scheduledCalendar.types'
import { BUY_NUMBER_SERVICE_TYPES, type BuyNumberServices } from '@/core/container/buyNumber.types'
import { TEN_DLC_TOLL_FREE_SERVICE_TYPES, type TenDlcTollFreeServices } from '@/core/container/tenDlcTollFree.types'
import { WIZARD_SERVICE_TYPES, type WizardServices } from '@/core/container/wizard.types'
import {
  CONTACTS_UNSUBSCRIBED_SERVICES_TYPES,
  type ContactsUnsubscribedServices,
} from '@/core/container/contacts/unsubscribed.types'
import { CONTACTS_BLOCKED_SERVICES_TYPES, type ContactsBlockedServices } from '@/core/container/contacts/blocked.types'
import { BYOC_SERVICE_TYPES, type ByocServices } from '@/core/container/byoc/byoc.types'
import { VOICE_CALL_SERVICES_TYPES, type VoiceCallServices } from '@/core/container/voiceCall.types'
import { CONTACT_SERVICES_TYPES, type ContactServices } from '@/core/container/contacts.types'
import { CONTACTS_BASE_SERVICES_TYPES, type ContactsBaseServices } from '@/core/container/contactsBase.types'
import { COMPOSE_SERVICE_TYPES, type ComposeServices } from '@/core/container/compose.types'
import type { ContactsImportFlowServices } from '@/core/container/contacts/import/contactsImportFlow.types'
import { CONTACTS_IMPORT_FLOW_SERVICE_TYPES } from '@/core/container/contacts/import/contactsImportFlow.types'
import type { ContactsImportDetailsServices } from '@/core/container/contacts/import/contactsImportDetails.types'
import { CONTACTS_IMPORT_DETAILS_SERVICE_TYPES } from '@/core/container/contacts/import/contactsImportDetails.types'
import type { ContactsImportListServices } from '@/core/container/contacts/import/contactsImportList.types'
import { CONTACTS_IMPORT_LIST_SERVICE_TYPES } from '@/core/container/contacts/import/contactsImportList.types'
import type { SubAccountsImportFlowServices } from '@/core/container/subAccounts/import/subAccountsImportFlow.types'
import { SUB_ACCOUNTS_IMPORT_FLOW_SERVICE_TYPES } from '@/core/container/subAccounts/import/subAccountsImportFlow.types'
import {
  SUB_ACCOUNTS_IMPORT_DETAILS_SERVICE_TYPES,
  type SubAccountsImportDetailsServices,
} from '@/core/container/subAccounts/import/subAccountsImportDetails.types'
import type { SubAccountsImportListServices } from '@/core/container/subAccounts/import/subAccountsImportList.types'
import { SUB_ACCOUNTS_IMPORT_LIST_SERVICE_TYPES } from '@/core/container/subAccounts/import/subAccountsImportList.types'
import type { EmailLookupImportServices } from '@/core/container/lookup/emailLookupImport.types'
import { EMAIL_LOOKUP_IMPORT_SERVICE_TYPES } from '@/core/container/lookup/emailLookupImport.types'
import type { EmailLookupSessionServices } from '@/core/container/lookup/emailLookupSession.types'
import { EMAIL_LOOKUP_SESSION_SERVICE_TYPES } from '@/core/container/lookup/emailLookupSession.types'
import type { CarrierLookupImportServices } from '@/core/container/lookup/carrierLookupImport.types'
import { CARRIER_LOOKUP_IMPORT_SERVICE_TYPES } from '@/core/container/lookup/carrierLookupImport.types'
import type { CarrierLookupSessionServices } from '@/core/container/lookup/carrierLookupSession.types'
import { CARRIER_LOOKUP_SESSION_SERVICE_TYPES } from '@/core/container/lookup/carrierLookupSession.types'
import type { ContactListServices } from '@/core/container/contactList.types'
import { CONTACT_LIST_SERVICES_TYPES } from '@/core/container/contactList.types'
import type { DateTimeServices } from '@/core/container/dateTime.types'
import { DATE_TIME_SERVICE_TYPES } from '@/core/container/dateTime.types'
import { INVOICES_BASE_SERVICE_TYPES, type InvoicesBaseServices } from '@/core/container/invoicesBase.types'
import type { TenDlcTollFreeRequestServices } from '@/core/container/tenDlcTollFreeRequest.types'
import { TEN_DLC_TOLL_FREE_REQUEST_SERVICE_TYPES } from '@/core/container/tenDlcTollFreeRequest.types'
import { CHAT_STATISTICS_SERVICE_TYPES, type ChatStatisticsServices } from '@/core/container/chatStatistics.types'
import {
  SUB_ACCOUNTS_BASE_SERVICE_TYPES,
  type SubAccountsBaseServices,
} from '@/core/container/subAccounts/subAccountsBase.types'
import {
  SUB_ACCOUNTS_DETAILS_SERVICE_TYPES,
  type SubAccountsDetailsServices,
} from '@/core/container/subAccounts/subAccountsDetails.types'
import {
  SUB_ACCOUNTS_FORM_SERVICE_TYPES,
  type SubAccountsFormServices,
} from '@/core/container/subAccounts/subAccountsFormServices.types'
import { RRULE_SERVICE_TYPES, type RRuleServices } from '@/core/container/rrule.types'
import {
  SCHEDULED_EDIT_SERVICE_TYPES,
  type ScheduledEditServices,
} from '@/core/container/scheduled/scheduledEdit.types'
import { type MessageSendServices, MESSAGE_SEND_TYPES } from '@/core/container/messageSend.types'
import {
  MESSAGES_BULKS_PROGRESS_SERVICES_TYPES,
  type MessagesBulksProgressServices,
} from '@/core/container/messagesBulksProgress.types'
import type { BillingBaseServices } from '@/core/container/billingBase.types'
import { BILLING_BASE_SERVICE_TYPES } from '@/core/container/billingBase.types'
import type { ByocHelperServices } from '@/core/container/byoc/byocHelper.types'
import { BYOC_HELPER_SERVICE_TYPES } from '@/core/container/byoc/byocHelper.types'
import {
  SENDER_SETTINGS_COUNTRIES_SERVICE_TYPES,
  type SenderSettingsCountriesServices,
} from '@/core/container/senderSettings/senderSettingsCountries.types'
import { CONTACT_COUNTER_SERVICES_TYPES, type ContactCounterServices } from '@/core/container/contactCounter.types'
import {
  CONTACT_LIST_COUNTER_SERVICES_TYPES,
  type ContactListCounterServices,
} from '@/core/container/contactListCounter.types'
import type { ActivityServices } from '@/core/container/activity.types'
import { ACTIVITY_SERVICES_TYPES } from '@/core/container/activity.types'
import type { CustomFieldServices } from '@/core/container/customField.types'
import { CUSTOM_FIELD_SERVICES } from '@/core/container/customField.types'
import { STATEMENTS_SERVICE_TYPES, type StatementsServices } from '@/core/container/accounts/statements.types'
import type { ComingSoonServices } from '@/core/container/comingSoon.types'
import { COMING_SOON_SERVICE_TYPES } from '@/core/container/comingSoon.types'
import { TEAM_WORKFLOW_SERVICE_TYPES, type TeamWorkflowServices } from '@/core/container/teamWorkflow.types'
import {
  AUTOMATION_RULES_DETAILS_SERVICE_TYPES,
  type AutomationRulesDetailsServices,
} from '@/core/container/automationRulesDetails.types'
import {
  AUTOMATION_RULES_LOG_SERVICE_TYPES,
  type AutomationRulesLogServices,
} from '@/core/container/automationRulesLog.types'
import { AUTOMATION_RULE_SERVICE_TYPES, type AutomationRuleServices } from '@/core/container/automationRule.types'
import { PAYMENT_SERVICE_TYPES, type PaymentServices } from '@/core/container/payment.types'
import type { SmartBannerServices } from '@/core/container/smartBanner.types'
import { SMART_BANNER_SERVICE_TYPES } from '@/core/container/smartBanner.types'
import type { WidgetSubscribeServices } from '@/core/container/widget/widgetSubscribe.types'
import { WIDGET_SUBSCRIBE_SERVICE_TYPES } from '@/core/container/widget/widgetSubscribe.types'
import type { WidgetClickToTextServices } from '@/core/container/widget/widgetClickToText.types'
import { WIDGET_CLICK_TO_TEXT_SERVICE_TYPES } from '@/core/container/widget/widgetClickToText.types'
import type { TasksBaseServices } from '@/core/container/tasks/tasksBase.types'
import { TASKS_BASE_SERVICES_TYPES } from '@/core/container/tasks/tasksBase.types'
import type { TasksServices } from '@/core/container/tasks/tasks.types'
import { TASKS_SERVICES_TYPES } from '@/core/container/tasks/tasks.types'
import {
  FORWARDED_CALLS_SERVICES_TYPES,
  type ForwardedCallsServices,
} from '@/core/container/history/forwardedCalls.types'
import { NAVIGATION_ORDER_SERVICES_TYPES, type NavigationOrderServices } from '@/core/container/navigationOrder.types'
import { RECENT_RECIPIENT_SERVICE_TYPES, type RecentRecipientServices } from '@/core/container/recentRecipient.types'
import type { KeywordsServices } from '@/core/container/automationRules/keywords.types'
import { KEYWORDS_SERVICE_TYPES } from '@/core/container/automationRules/keywords.types'
import { SENT_SMS_BASE_SERVICE_TYPES, type SentSmsBaseServices } from '@/core/container/history/sentSmsBase.types'
import type { ScheduledServices } from '@/core/container/scheduled/scheduled.types'
import { SCHEDULED_SERVICE_TYPES } from '@/core/container/scheduled/scheduled.types'
import type { ScheduledDetailsServices } from '@/core/container/scheduled/scheduledDetails.types'
import { SCHEDULED_DETAILS_SERVICE_TYPES } from '@/core/container/scheduled/scheduledDetails.types'
import {
  EMAIL_UNSUBSCRIBE_SERVICES_TYPES,
  type EmailUnsubscribeServices,
} from '@/core/container/emailUnsubscribe.types'
import { PINNED_CONTACTS_SERVICE_TYPES, type PinnedContactsServices } from '@/core/container/contacts/pinned.types'
import { WEB_WIDGET_TYPES, type WebWidgetsServices } from '@/core/container/messages/settings/webWidgets.types'
import { WHATSAPP_SERVICE_TYPES, type WhatsAppServices } from '@/core/container/messenger/whatsapp.types'
import { STRIPE_BILLING_SERVICES_TYPES, type StripeBillingServices } from '@/core/container/stripeBilling.types'
import type { UserSessionServices } from '@/core/container/user/userSession'
import { AUTH_SERVICE_TYPES, type AuthServices } from '@/core/container/unauthorized/auth.types'
import { AUDIT_LOGS_SERVICES_TYPES, type AuditLogsServices } from '@/core/container/auditLogs.types'
import { TICKETS_SERVICES_TYPES, type TicketsServices } from '@/core/container/tickets/tickets.types'
import type { TagsServices } from '@/core/container/tags.types'
import { TAGS_SERVICES_TYPES } from '@/core/container/tags.types'
import { FILTERED_VIEWS_SERVICES_TYPES, type FilteredViewsServices } from '@/core/container/filteredViews.types'
import { TYPING_SERVICE_TYPES, type TypingServices } from '@/core/container/typing.types'
import {
  TICKETS_NOTIFICATIONS_SERVICES_TYPES,
  type TicketsNotificationsServices,
} from '@/core/container/tickets/ticketsNotifications.types'
import { DEALS_SERVICES_TYPES, type DealsServices } from '@/core/container/deals/deals.types'
import { KANBAN_SERVICE_TYPES } from '@/core/container/kanban.types'
import { FACEBOOK_SERVICE_TYPES, type FacebookServices } from '@/core/container/messenger/facebook.types'
import { ALSO_HERE_SERVICE_TYPES, type AlsoHereServices } from '@/core/container/alsoHere.types'
import { CHANNEL_PRESENCE_SERVICE_TYPES, type ChannelPresenceServices } from '@/core/container/channelPresence.types'
import type { SmsCampaignsServices } from '@/core/container/campaigns/smsCampaigns.types'
import {
  EMAIL_CAMPAIGNS_SERVICE_TYPES,
  type EmailCampaignsServices,
} from '@/core/container/campaigns/emailCampaigns.types'
import { POINT_AI_SERVICES_TYPES, type PointAiServices } from '@/core/container/pointAi.types'
import { SMS_CAMPAIGNS_SERVICE_TYPES } from '@/core/container/campaigns/smsCampaigns.types'
import { SEGMENT_SERVICES_TYPES, type SegmentServices } from '@/core/container/segments.types'
import { CHAT_PREVIEW_NEXT_SERVICE_TYPES, type ChatPreviewNextServices } from '@/core/container/chatPreviewNext.types'
import { INSTAGRAM_SERVICE_TYPES, type InstagramServices } from '@/core/container/messenger/instagram.types'
import { TM_TIPTAP_EDITOR_SERVICES_TYPES, type TmTiptapEditorServices } from '@/core/container/tmTiptapEditor.types'
import {
  OUTBOUND_EMAILS_SERVICES_TYPES,
  type OutboundEmailsServices,
} from '@/core/container/history/outboundEmails.types'
import type { RecipientServices } from '@/core/container/recipient.types'
import { RECIPIENT_SERVICE_TYPES } from '@/core/container/recipient.types'

enum SERVICE_NAMES_WEB {
  MonitoringService,
  MonitoringLoggerService,
  TelemetryService,
  MicroSentryMonitoringService,
  VueService,
  Config,
  FieldMapper,
  Api,
  WindowService,
  ScrollbarService,
  TableScrollbarService,
  PrimaryBrowserTabService,
  BrowserIdentifierService,
  AuthService,
  SsoAuthService,
  LoggedInStatusService,
  OAuthSignUpService,
  AccessControlService,
  LoggerService,
  RouterService,
  RouteLeaveService,
  RouteLeaveResolver,
  RouterBuilderService,
  RouteEntitiesService,
  BaseRouterService,
  HistoryService,
  NewQueryParamsService,
  ResolverService,
  ResolverExecutorService,
  PromiseExecutorPoolManager,
  ResolverProxyFactory,
  ResolverProxy,
  EndpointsService,
  VuexService,
  EntityManager,
  DomainTitlerService,
  RouteTitlerService,
  DefaultTitlerService,
  TitlerManager,
  TranslateService,
  NotificationService,
  NativeNotificationService,
  ExportService,
  FilterBuilderService,
  FilterSchemaService,
  FilterQueryService,
  FilterBracketNotationQueryService,
  NumericService,
  LocalStorageService,
  SessionStorageService,
  QueryStorageService,
  GlobalStateService,
  FeatureFlagsService,
  AuthLoaderService,
  SmsContentService,
  PerformanceMarkService,
  RestrictionPagesRouterService,
  DateFilterMapper,

  // bulk
  BulkService,
  BulkManager,
  // bulk

  // preloaders
  ModelPreloaderService,
  TablePreloaderService,
  UserPreloaderService,
  PreloaderManager,
  AutocompletePreloaderService,
  // preloaders

  AutocompleteKeyBuilder,

  // wrappers
  WrapperManagerService,
  BaseWrapperService,
  ModalService,
  NewQueryModalService,
  TableWrapperService,
  TableQueryWrapperService,
  SectionService,
  PartialUIWrapperService,
  PartialQueryUIWrapperService,
  GroupedPartialUIWrapperService,
  GroupedQueryPartialUIWrapperService,
  TableLocalStorageWrapperService,
  // wrappers

  // facets
  FacetManager,
  FaceterService,
  FromPaginationFaceterService,
  // facets
  ConfirmationService,
  UserService,
  UserSettingsService,
  UserPresenceService,
  UserRoleLabelDataService,
  ResolverManager,
  ResolversRouterBuilderMiddleware,
  ModulesRouterBuilderMiddleware,
  RoutesGatheringMiddleware,
  DetailsPageRouterBuilderMiddleware,
  TitlersRouterBuilderMiddleware,
  SerializerService,
  SubscriptionService,
  ModelSubscriptionService,
  ServerSubscriptionService,
  WorkspaceServerSubscriptionService,
  FormSubscriptionService,
  LocalStorageTransport,
  BaseStorageTransport,
  LocalStorageAdapter,
  ValidationService,
  ValidationRulesBuilderService,
  CommonValidationRegistrator,
  SpecialValidationRegistrator,
  FileValidationRegistrator,
  PhoneValidationRegistrator,
  FormValidationService,
  SubValidationService,
  FormBuilderFactoryService,
  StatusFieldBuilderService,
  FormManager,
  FormFactoryManager,
  WebSocketService,
  WebSocketChannelsServiceFactory,
  PhoneService,
  SipCallsService,
  UserNumbersService,
  UserAnalyticsFieldsService,
  UserAnalyticsTraitsService,
  UserMetadataService,

  // import
  OrderService,
  VisibilityService,
  PermissionsService,
  RouterPermissionsService,
  PasswordFieldBuilderService,
  TypedFormBuilderFactoryService,
  GroupPropertyBuilderFactory,
  PropertyBuilderFactory,
  TypedGroupFormBuilderFactoryService,
  TypedArrayFormBuilderService,
  FileUploadFieldBuilderService,
  EditableBadgesFieldBuilderService,
  SenderPhoneFieldBuilderService,
  CountrySenderPhoneListFieldBuilderService,
  SelectInboxFieldBuilderService,
  SelectFacebookPageFieldBuilderService,
  SelectInstagramAccountFieldBuilderService,
  WeekDaysTimeRangeFieldBuilder,

  // Form services
  MessagesAttachmentFileFormService,
  ExportFormService,
  VatInformationFormService,
  ForwardAudioFormService,
  PhoneNumbersService,
  NumberAvailableCountriesService,
  AvailableToBuyNumbersService,
  NumbersPriceService,
  ServiceDistributionRecipientsFormService,
  ServiceDistributionAdditionalSettingsFormService,
  EndFormGroupConfiguration,
  MonthlyFormGroupConfiguration,
  SendingDateTimeFormGroupConfiguration,
  WeeklyFormGroupConfiguration,
  YearlyFormGroupConfiguration,
  CallForwardingFormBuilderService,
  BuyNumberCallForwardingFormService,
  ImportMatchingCountryFormService,
  UserAdminGridable,
  BulkFieldBuilderService,

  // Domains
  DomainBaseService,
  DomainManagerService,
  DistributionDetailsService,
  TenDlcPhoneNumbersGridable,
  NoteGridable,
  EntityExportService,
  CountryService,
  UnauthenticatedCountryService,
  NoteService,

  SessionService,
  SessionShortService,
  SessionStatService,

  MessagesPriceService,
  MessagesPriceForCountryService,
  RecipientService,
  MessagesAttachmentFileService,
  ForwardAudioService,
  SenderIdPriceService,
  TimezoneService,
  AreaCodesService,
  PhoneValidateAutocompleteService,
  PhoneAutocompleteService,
  VoiceCallPriceService,
  TenDlcPricingService,
  TollFreePhoneNumbersService,
  TenDlcPhoneNumbersService,
  TenDlcCampaignsService,
  TenDlcBrandService,
  TenDlcTwilioBrandService,
  RegulationsStatusService,
  ByocService,
  AttachmentFileService,
  WizardService,
  SendReceiveSettingsService,
  GettingStartedInfoService,
  UiSettingsService,
  TeamWorkflowService,
  TeamSubscriptionPriceService,
  TeamSubscriptionPriceRepository,
  CentrifugeService,
  CentrifugeRepository,
  SenderSettingsCountriesGridable,
  TicketsCountRepository,
  TicketCountService,
  UserRefusalSurveyService,
  // Domains

  // Forms
  NoteCreateFormService,
  NoteEditFormService,

  // table related
  ForwardAudioColumnService,
  SubAccountsGridable,
  BaseFilters,
  FilterCompareService,
  FilterCleanupService,
  FilterStrategyManager,
  RangeFilterService,
  BaseFilterStrategy,
  RangeFilterStrategy,
  ArrayFilterStrategy,
  ColumnsFromStoreFactory,
  BaseGrouperServiceFactory,
  SorterSerializerService,
  BaseSorterServiceFactory,
  BasePaginatorServiceFactory,
  CursorPaginationServiceFactory,
  TokenPaginationServiceFactory,
  NoopGroupingServiceFactory,
  NoopSorterServiceFactory,
  NoColumnsServiceFactory,
  TableBuilderService,
  TableGrouperManager,
  ColumnsSizeService,
  TableManager,
  TablePaginatorManager,
  TableSearcherManager,
  BaseSearcherServiceFactory,
  TableSorterManager,
  TableColumnManager,
  TableKeyBuilder,
  TableExpandService,
  TenDlcPhoneNumbersDetailsColumnServiceFactory,
  UserAdminsColumnServiceFactory,
  StaticPaginationService,
  StaticSortService,
  TemplatesTableExpandLocalStorageService,
  ContactListTempColumnServiceFactory,
  SubAccountsSwitchColumnService,
  TableBaseRecoveryService,

  // cleanup
  TableCleanUper,
  CleanUpManager,
  AutocompleteCleanUper,
  // cleanup

  // search
  ModelSearcherManager,
  ModelSearcherServiceFactory,
  // search

  // Resolvers
  FilterSchemaResolver,
  RootResolver,
  WizardPrivateUseCaseResolver,
  UserResolver,
  SubAccountsResolver,
  WizardResolverService,
  PlanExpiredResolverService,
  ModelResolver,
  FacetResolver,
  ScriptResolverService,
  SingleModelResolverService,
  SingleSettingsModelResolverService,
  NotFoundResolverService,
  SendReceiveSettingsResolver,
  BaseResolverService,
  RedirectableSingleModelResolverService,
  NonBlockedRedirectableSingleModelResolverService,
  AuthByProviderResolver,
  VoiceCallConfigResolverService,
  ModelSettingsResolverService,
  TenDlcPricesResolver,
  TenDlcPricesPreloader,
  TenDlcNumbersCountResolver,
  ByocAccessResolver,
  ByocSubscribeResolver,
  SystemExitResolver,
  StopImpersonateResolver,
  UserCustomFieldsEventResolver,
  SystemAccountClosedResolver,
  SystemRefreshResolver,
  SettingStartedInfoResolverService,
  UiSettingsResolverService,
  MonthlyPlansResolverService,
  SubscriptionEventsResolverService,
  AppModuleResolverService,
  SubscriptionBillingSettingsResolverService,
  UserWorkflowAccountChangedResolverService,
  UserPolicyPrivacyChangedResolverService,
  ContactServerEventsResolverService,
  WidgetEmbedCodeResolver,
  RedirectByPrevRouteResolverService,
  RedirectIfNotFeatureFlagEnabledResolverService,
  FetchAllAccountsResolver,
  FieldsDataCacheClearByModelEventResolverService,
  FieldAssigneeCacheClearResolverService,
  TicketCountResolverService,
  AdminSectionRedirectResolver,
  MainSidebarRoutesResolverService,
  UserPresenceResolver,
  ChatFacebookResolver,
  ChatInstagramResolver,
  // Resolvers

  // repository
  AuthStateRepository,
  OAuthSignUpRepository,
  AllSelectedTablesRepository,
  BaseFormRepository,
  BaseFieldRepository,
  BaseFilterRepository,
  TableRepository,
  TableRecoveryDataRepository,
  SelectedRowRepository,
  PageMapPreloadRepository,
  PaginationRepository,
  CursorPaginationPageRepository,
  CounterRepository,
  DeliveredSmsCounterRepository,
  FailedSmsCounterRepository,
  RejectedSmsCounterRepository,
  SearchRepository,
  CountryRepository,
  UnauthenticatedCountryRepository,
  FilterStateRepository,
  FilterSchemaRepository,
  ModalRepository,
  BaseWrapperRepository,
  NoteRepository,
  SorterRepository,
  GroupingRepository,
  TimezoneRepository,
  UserRepository,
  ColumnRepository,
  PreloadRepository,
  RangeFilterRepository,
  ArrayFilterRepository,
  WizardStepStateRepository,
  OrderRepository,
  VisibilityRepository,
  FacetRepository,
  AutocompleteRepository,
  SessionRepository,
  SessionShortRepository,
  SessionStatRepository,
  PhoneNumbersRepository,
  SipCallsRepository,
  MessagesPriceRepository,
  MessagesPriceForCountryRepository,
  MessagesAttachmentFileRepository,
  ForwardAudioRepository,
  UserNumbersRepository,
  UserMetadataRepository,
  UserSettingsRepository,
  UserAnalyticsFieldsRepository,
  UserAnalyticsTraitsRepository,
  UserPresenceRepository,
  SenderIdPriceRepository,
  AvailableToBuyNumbersRepository,
  AreaCodesRepository,
  NumbersPriceRepository,
  NumberAvailableCountriesRepository,
  GlobalStateRepository,
  VoiceCallPriceRepository,
  SenderSettingsCountriesRepository,
  VoiceCallConfigRepository,
  DistributionDetailsRepository,
  TollFreePhoneNumbersRepository,
  TenDlcPricingRepository,
  TenDlcPhoneNumbersRepository,
  TenDlcCampaignsRepository,
  TenDlcBrandRepository,
  TenDlcTwilioBrandRepository,
  RegulationsStatusRepository,
  ByocRepository,
  AttachmentFileRepository,
  WizardRepository,
  SendReceiveSettingsRepository,
  GettingStartedInfoRepository,
  TeamWorkflowRepository,
  UiSettingsRepository,
  PrimaryBrowserTabRepository,
  PrimaryBrowserTabLockRepository,

  // filters
  FilterServiceManager,
  TableFilterServiceManager,
  // filters

  // interceptors
  HttpInterceptorManager,
  ApiErrorInterceptor,
  AuthInterceptor,
  OldVersionInterceptor,
  ImpersonationModeInterceptor,
  PlanExpiredInterceptor,
  TokenIsLockedInterceptor,
  StripeInvalidBillingDetailsInterceptor,
  // interceptors

  // workers
  PdfWorkerService,
  // workers

  // tracking
  TrackingService,
  ZendeskService,
  SegmentAnalyticsService,
  ShareASaleService,
  FirstPromoterService,
  HotJarService,
  PostHogService,
  PosthogRecordingService,
  PosthogRecordingServiceResolver,
  GoogleAnalyticsService,
  ZukoAnalyticsService,
  ConvertService,
  SyncQueueService,
  // tracking
  // accessControlStrategies
  GettingStartedStrategy,
  TenDlcStrategy,
  TollFreeStrategy,
  SenderIdsStrategy,
  SubAccountsImportFlowStrategy,
  UsersWorkflowNotUserRoleStrategy,
  UsersWorkflowSuperAdminRoleStrategy,
  TasksReadOnlyStrategy,
  SuperAdminRoleStrategy,
  WizardAnalyticsResolverService,
  UserWorkflowStrategy,
  SubAccountsWorkflowStrategy,
  SubAccountDetailsStrategy,
  WebWidgetsStrategy,
  TicketsStrategy,
  CampaignsStrategy,
  NotCampaignsCalendarStrategy,
  NotCampaignsScheduledStrategy,
  NotCampaignsScheduledUpcomingStrategy,
  NotCampaignsScheduledPausedStrategy,
  NotCampaignsScheduledNotSentStrategy,
  NotCampaignsScheduledCompletedStrategy,
  NotCampaignsScheduledDetailsStrategy,
  NotCampaignsScheduledEditStrategy,
  NotCampaignsComposeMessageStrategy,
  NotCampaignsComposeSuccessMessageStrategy,
  NotCampaignsComposeSuccessMessagesStrategy,
  NotCampaignsComposeSuccessScheduledStrategy,
  NotCampaignsComposeCampaignStrategy,
  NotCampaignsComposeEmailStrategy,
  NotCampaignsTemplatesAllStrategy,
  NotCampaignsTemplatesCategoryStrategy,
  NotCampaignsSmsSurveysStrategy,
  NotCampaignsSmsSurveysDetailsStrategy,
  NotCampaignsSmsSurveysViewFlowStrategy,
  NotCampaignsSmsSurveysEditStrategy,
  CampaignsEmailStrategy,
  DealsReadOnlyStrategy,
  SegmentsStrategy,
  MonthlyPlansStrategy,
  ByocStrategy,
  PaymentCongratsStrategy,
  DealsStrategy,
  PaymentStrategy,
  InstagramStrategy,
  PointAiManagementStrategy,
  UnsubscribedEmailStrategy,
  HistoryOutboundEmailsStrategy,
  // accessControlStrategies

  UiStateService,
  UiStateRepository,

  // here because we need ReopenAccountRepository in interceptor, modules load after loading interceptors
  ReopenAccountRepository,
  ReopenAccountService,
  ReopenAccountResolver,
  ReopenAccountFormService,
  CloseAccountRepository,
  CloseAccountService,
  LRUCacheService,

  // errors processing
  ErrorSerializerService,
  ErrorsCustomRulesByStatusesMap,

  OptimisticUpdateService,
  FieldDataCacheService,

  PriorityHelperService,

  WrappersConfigService,
}

export type RegisteredServices =
  | keyof typeof SERVICE_NAMES_WEB
  | SandboxServices
  | DateTimeServices
  | SmartBannerServices
  | ChatServices
  | ChatStatisticsServices
  | ChatPreviewNextServices
  | ReportingServices
  | EmailToSmsServices
  | CarrierLookupServices
  | CarrierLookupImportServices
  | CarrierLookupSessionServices
  | SenderSettingsServices
  | SenderSettingsCountriesServices
  | EmailLookupServices
  | EmailLookupImportServices
  | EmailLookupSessionServices
  | SmsSurveysServices
  | AutomationRulesServices
  | AutomationRuleServices
  | AutomationRulesDetailsServices
  | AutomationRulesLogServices
  | KeywordsServices
  | LoginSSOServices
  | LogoutServices
  | AuthServices
  | SignupByInviteServices
  | SignUpByOAuthServices
  | TFAServices
  | VerifyEmailService
  | SignupConfirmationServices
  | EmailConfirmationServices
  | ForgotPasswordServices
  | ResetPasswordServices
  | CheckEmailForSignupServices
  | NotificationsServices
  | AccountSettingsServices
  | ApiServices
  | SubAccountsServices
  | SubAccountsBaseServices
  | SubAccountsDetailsServices
  | SubAccountsFormServices
  | SubAccountsImportFlowServices
  | SubAccountsImportDetailsServices
  | SubAccountsImportListServices
  | AutoDetectedCountryServices
  | BillingServices
  | BillingBaseServices
  | InvoicesServices
  | InvoicesBaseServices
  | HistorySentSmsServices
  | TemplatesServices
  | MyDataServices
  | HistoryReceivedSmsServices
  | HistoryForwardedCallsServices
  | HistoryOutboundCallsServices
  | HistoryInboundCallsServices
  | ScheduledServices
  | ScheduledUpcomingServices
  | ScheduledPausedServices
  | ScheduledNotSentServices
  | ScheduledCompletedServices
  | ScheduledCalendarServices
  | ScheduledEditServices
  | ScheduledDetailsServices
  | BuyNumberServices
  | ComposeServices
  | MessagesBulksProgressServices
  | TenDlcTollFreeServices
  | TenDlcTollFreeRequestServices
  | ContactsUnsubscribedServices
  | ContactsBlockedServices
  | ContactsImportFlowServices
  | ContactsImportDetailsServices
  | ContactsImportListServices
  | ByocServices
  | ByocHelperServices
  | WidgetSubscribeServices
  | WidgetClickToTextServices
  | VoiceCallServices
  | WizardServices
  | ContactsBaseServices
  | ContactServices
  | ContactListServices
  | SegmentServices
  | RRuleServices
  | MessageSendServices
  | ContactCounterServices
  | ContactListCounterServices
  | ActivityServices
  | CustomFieldServices
  | StatementsServices
  | ComingSoonServices
  | TeamWorkflowServices
  | PaymentServices
  | TasksBaseServices
  | TasksServices
  | ForwardedCallsServices
  | NavigationOrderServices
  | RecentRecipientServices
  | SentSmsBaseServices
  | EmailUnsubscribeServices
  | PinnedContactsServices
  | WebWidgetsServices
  | WhatsAppServices
  | FacebookServices
  | StripeBillingServices
  | UserSessionServices
  | AuditLogsServices
  | TicketsServices
  | TagsServices
  | FilteredViewsServices
  | TypingServices
  | TicketsNotificationsServices
  | DealsServices
  | AlsoHereServices
  | SmsCampaignsServices
  | EmailCampaignsServices
  | ChannelPresenceServices
  | PointAiServices
  | InstagramServices
  | TmTiptapEditorServices
  | OutboundEmailsServices
  | RecipientServices

const SERVICE_TYPES = objectWithUniqueFieldBuilder({})
  .join(
    makeServicesTypes<keyof typeof SERVICE_NAMES_WEB>(
      enumKeysToArray<keyof typeof SERVICE_NAMES_WEB>(SERVICE_NAMES_WEB),
    ),
  )
  .join(DATE_TIME_SERVICE_TYPES)
  .join(SMART_BANNER_SERVICE_TYPES)
  .join(CHAT_SERVICE_TYPES)
  .join(CHAT_STATISTICS_SERVICE_TYPES)
  .join(CHAT_PREVIEW_NEXT_SERVICE_TYPES)
  .join(REPORTING_SERVICE_TYPES)
  .join(EMAIL_TO_SMS_SERVICE_TYPES)
  .join(CARRIER_LOOKUP_SERVICE_TYPES)
  .join(CARRIER_LOOKUP_IMPORT_SERVICE_TYPES)
  .join(CARRIER_LOOKUP_SESSION_SERVICE_TYPES)
  .join(SENDER_SETTINGS_SERVICE_TYPES)
  .join(SENDER_SETTINGS_COUNTRIES_SERVICE_TYPES)
  .join(EMAIL_LOOKUP_SERVICE_TYPES)
  .join(EMAIL_LOOKUP_IMPORT_SERVICE_TYPES)
  .join(EMAIL_LOOKUP_SESSION_SERVICE_TYPES)
  .join(SMS_SURVEYS_SERVICE_TYPES)
  .join(AUTOMATION_RULES_SERVICE_TYPES)
  .join(AUTOMATION_RULE_SERVICE_TYPES)
  .join(AUTOMATION_RULES_DETAILS_SERVICE_TYPES)
  .join(AUTOMATION_RULES_LOG_SERVICE_TYPES)
  .join(KEYWORDS_SERVICE_TYPES)
  .join(AUTH_SERVICE_TYPES)
  .join(SIGNUP_BY_INVITE_SERVICE_TYPES)
  .join(SIGNUP_BY_OAUTH_SERVICE_TYPES)
  .join(TFA_SERVICE_TYPES)
  .join(VERIFY_EMAIL_SERVICE_TYPES)
  .join(SIGNUP_CONFIRMATION_SERVICE_TYPES)
  .join(LOGOUT_SERVICE_TYPES)
  .join(AUTO_DETECTED_COUNTRY_TYPES)
  .join(EMAIL_CONFIRMATION_SERVICE_TYPES)
  .join(LOGIN_SSO_SERVICE_TYPES)
  .join(FORGOT_PASSWORD_SERVICE_TYPES)
  .join(RESET_PASSWORD_SERVICE_TYPES)
  .join(CHECK_EMAIL_FOR_SIGNUP_SERVICE_TYPES)
  .join(NOTIFICATIONS_SERVICE_TYPES)
  .join(ACCOUNT_SETTINGS_SERVICE_TYPES)
  .join(API_SERVICE_TYPES)
  .join(SUB_ACCOUNTS_SERVICE_TYPES)
  .join(SUB_ACCOUNTS_BASE_SERVICE_TYPES)
  .join(SUB_ACCOUNTS_DETAILS_SERVICE_TYPES)
  .join(SUB_ACCOUNTS_FORM_SERVICE_TYPES)
  .join(SUB_ACCOUNTS_IMPORT_FLOW_SERVICE_TYPES)
  .join(SUB_ACCOUNTS_IMPORT_DETAILS_SERVICE_TYPES)
  .join(SUB_ACCOUNTS_IMPORT_LIST_SERVICE_TYPES)
  .join(BILLING_SERVICE_TYPES)
  .join(BILLING_BASE_SERVICE_TYPES)
  .join(INVOICES_SERVICE_TYPES)
  .join(INVOICES_BASE_SERVICE_TYPES)
  .join(HISTORY_SENT_SMS_SERVICE_TYPES)
  .join(TEMPLATES_SERVICE_TYPES)
  .join(MY_DATA_SERVICE_TYPES)
  .join(HISTORY_RECEIVED_SMS_SERVICE_TYPES)
  .join(HISTORY_FORWARDED_CALLS_SERVICE_TYPES)
  .join(HISTORY_OUTBOUND_CALLS_SERVICE_TYPES)
  .join(HISTORY_INBOUND_CALLS_SERVICE_TYPES)
  .join(SCHEDULED_SERVICE_TYPES)
  .join(SCHEDULED_UPCOMING_SERVICE_TYPES)
  .join(SCHEDULED_PAUSED_SERVICE_TYPES)
  .join(SCHEDULED_NOT_SENT_SERVICE_TYPES)
  .join(SCHEDULED_COMPLETED_SERVICE_TYPES)
  .join(SCHEDULED_CALENDAR_SERVICE_TYPES)
  .join(SCHEDULED_EDIT_SERVICE_TYPES)
  .join(SCHEDULED_DETAILS_SERVICE_TYPES)
  .join(BUY_NUMBER_SERVICE_TYPES)
  .join(COMPOSE_SERVICE_TYPES)
  .join(MESSAGES_BULKS_PROGRESS_SERVICES_TYPES)
  .join(TEN_DLC_TOLL_FREE_SERVICE_TYPES)
  .join(TEN_DLC_TOLL_FREE_REQUEST_SERVICE_TYPES)
  .join(WIZARD_SERVICE_TYPES)
  .join(CONTACTS_UNSUBSCRIBED_SERVICES_TYPES)
  .join(CONTACTS_BLOCKED_SERVICES_TYPES)
  .join(CONTACTS_IMPORT_FLOW_SERVICE_TYPES)
  .join(CONTACTS_IMPORT_DETAILS_SERVICE_TYPES)
  .join(CONTACTS_IMPORT_LIST_SERVICE_TYPES)
  .join(BYOC_SERVICE_TYPES)
  .join(BYOC_HELPER_SERVICE_TYPES)
  .join(WIDGET_SUBSCRIBE_SERVICE_TYPES)
  .join(WIDGET_CLICK_TO_TEXT_SERVICE_TYPES)
  .join(VOICE_CALL_SERVICES_TYPES)
  .join(CONTACTS_BASE_SERVICES_TYPES)
  .join(CONTACT_SERVICES_TYPES)
  .join(CONTACT_LIST_SERVICES_TYPES)
  .join(SEGMENT_SERVICES_TYPES)
  .join(RRULE_SERVICE_TYPES)
  .join(MESSAGE_SEND_TYPES)
  .join(CONTACT_COUNTER_SERVICES_TYPES)
  .join(CONTACT_LIST_COUNTER_SERVICES_TYPES)
  .join(ACTIVITY_SERVICES_TYPES)
  .join(CUSTOM_FIELD_SERVICES)
  .join(STATEMENTS_SERVICE_TYPES)
  .join(COMING_SOON_SERVICE_TYPES)
  .join(TEAM_WORKFLOW_SERVICE_TYPES)
  .join(PAYMENT_SERVICE_TYPES)
  .join(TASKS_BASE_SERVICES_TYPES)
  .join(TASKS_SERVICES_TYPES)
  .join(FORWARDED_CALLS_SERVICES_TYPES)
  .join(NAVIGATION_ORDER_SERVICES_TYPES)
  .join(RECENT_RECIPIENT_SERVICE_TYPES)
  .join(SENT_SMS_BASE_SERVICE_TYPES)
  .join(EMAIL_UNSUBSCRIBE_SERVICES_TYPES)
  .join(SANDBOX_SERVICE_TYPES)
  .join(PINNED_CONTACTS_SERVICE_TYPES)
  .join(WEB_WIDGET_TYPES)
  .join(WHATSAPP_SERVICE_TYPES)
  .join(STRIPE_BILLING_SERVICES_TYPES)
  .join(AUDIT_LOGS_SERVICES_TYPES)
  .join(TICKETS_SERVICES_TYPES)
  .join(TAGS_SERVICES_TYPES)
  .join(FILTERED_VIEWS_SERVICES_TYPES)
  .join(TYPING_SERVICE_TYPES)
  .join(TICKETS_NOTIFICATIONS_SERVICES_TYPES)
  .join(DEALS_SERVICES_TYPES)
  .join(KANBAN_SERVICE_TYPES)
  .join(ALSO_HERE_SERVICE_TYPES)
  .join(CHANNEL_PRESENCE_SERVICE_TYPES)
  .join(EMAIL_CAMPAIGNS_SERVICE_TYPES)
  .join(POINT_AI_SERVICES_TYPES)
  .join(FACEBOOK_SERVICE_TYPES)
  .join(SMS_CAMPAIGNS_SERVICE_TYPES)
  .join(INSTAGRAM_SERVICE_TYPES)
  .join(TM_TIPTAP_EDITOR_SERVICES_TYPES)
  .join(OUTBOUND_EMAILS_SERVICES_TYPES)
  .join(RECIPIENT_SERVICE_TYPES)
  .getObject()

export { SERVICE_TYPES }

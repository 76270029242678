import type { MiddlewareInterface } from '@/core/middlewares/middlewareInterface'
import type { GetLocator } from '@/core/container/container'
import type { RouterBuilderInterface } from '@/services/route/types'
import type { AppModule } from '@/config/types'

export default class RouterMiddleware implements MiddlewareInterface {
  constructor(private readonly modules: AppModule[]) {}

  public make(get: GetLocator) {
    const routerBuilder = get<RouterBuilderInterface>('RouterBuilderService')
    // collecting all routes into a list for UI tests
    // routerBuilder.addMiddleware(get('RoutesGatheringMiddleware'))
    routerBuilder.addMiddleware(get('ModulesRouterBuilderMiddleware'))
    routerBuilder.addMiddleware(get('DetailsPageRouterBuilderMiddleware'))
    routerBuilder.addMiddleware(get('ResolversRouterBuilderMiddleware'))
    routerBuilder.build(this.modules)
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ForwardedCallsColumnServiceFactory,
  ForwardedCallsFilterServiceFactory,
}

export type HistoryForwardedCallsServices = keyof typeof SERVICES

export const HISTORY_FORWARDED_CALLS_SERVICE_TYPES: Record<HistoryForwardedCallsServices, symbol> =
  makeServicesTypes<HistoryForwardedCallsServices>(enumKeysToArray<HistoryForwardedCallsServices>(SERVICES))

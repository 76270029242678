import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  CarrierLookupSessionService,
  CarrierLookupSessionRepository,
}

export type CarrierLookupSessionServices = keyof typeof SERVICES

export const CARRIER_LOOKUP_SESSION_SERVICE_TYPES: Record<CarrierLookupSessionServices, symbol> =
  makeServicesTypes<CarrierLookupSessionServices>(enumKeysToArray<CarrierLookupSessionServices>(SERVICES))

import { OrmModel, PrimaryKey, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { SenderSourceType } from '@/services/domain/senderSource/types'
import type { TmCountryCode } from '@/services/types'
import type { ByocCarrierStatus } from '@/services/domain/byoc/types'
import type { Nullable } from '@/types'

@OrmModel(modelNames.SENDER_SOURCE)
export default class SenderSource extends BaseModel {
  @PrimaryKey()
  @StringField()
  public phone: string

  @StringField()
  public type: SenderSourceType

  @StringField(null)
  public countryId: Nullable<TmCountryCode>

  @StringField(null)
  public label: Nullable<string>

  @StringField()
  public status: string

  @StringField()
  public carrierStatus: ByocCarrierStatus
}

import type { RouteRecordRaw } from 'vue-router'
import PassThrough from '@/components/layout/PassThrough.vue'
import { getUserRoutes } from '@/routes/user/user'
import Country from '@/data/models/domain/Country'
import Timezone from '@/data/models/domain/Timezone'
import { getAuthRoutes } from '@/routes/auth'
import { getSandboxRoutes } from '@/routes/sandbox'
import { getTenDlcTollFreeRoutes } from '@/routes/tenDlcTollFree'
import { getPaymentRoutes } from '@/routes/payment'
import { getWizardRoutes } from '@/routes/wizard'
import { PageAvailableFor } from '@/types'
import LoggedInPageResolver from '@/components/resolvers/LoggedInPageResolver.vue'
import { AppModule } from '@/config/types'
import { getByocTopRoutes } from '@/routes/user/services/byocTop'
import { getPlanExpiredRoutes } from '@/routes/planExpired'
import type { NavigationOrderResolverParams } from '@/services/resolvers/types'
import { getTokenIsLockedRoutes } from '@/routes/tokenIsLocked'
import { getMessengerPreviewRoutes } from '@/routes/user/messenger/messengerPreview'
import User from '@/data/models/domain/User'
import type { FieldDataCacheClearResolverParams } from '@/services/resolvers/fieldDataCache/types'
import { CacheableFields } from '@/services/forms/fieldCache/types'
import Contact from '@/data/models/domain/Contact'
import { ticketsRouteNames } from '@/routes/user/tickets/types'
import TicketStatus from '@/data/models/domain/tickets/TicketStatus'
import Ticket from '@/data/models/domain/tickets/Ticket'
import { ticketIdViewParam } from '@/services/domain/tickets/const'
import type { DomainTitlerParams } from '@/services/route/titlers/types'
import TicketInbox from '@/data/models/domain/tickets/TicketInbox'
import type { TicketByWorkspaceIdResolverParams } from '@/services/domain/tickets/resolvers/ticketByWorkspaceIdResolverService'
import { getPrivacyPolicyRoutes } from '@/routes/privacyPolicy'

const routes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'root',
    component: PassThrough,
    meta: {
      titler: {
        service: 'DefaultTitlerService',
      },
      resolvers: [
        [
          {
            service: 'RootResolver',
          },
        ],
        {
          service: 'WizardPrivateUseCaseResolver',
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'loggedInRootTrial',
        component: () => import('@/components/layout/LoggedInRootTrial.vue'),
        meta: {
          resolvers: [
            {
              service: 'WizardResolverService',
            },
            {
              service: 'WizardAnalyticsResolverService',
            },
            {
              service: 'PlanExpiredResolverService',
            },
            {
              service: 'SubscriptionEventsResolverService',
            },
            {
              service: 'ContactServerEventsResolverService',
            },
            {
              service: 'FieldsDataCacheClearByModelEventResolverService',
              params: [
                {
                  model: User,
                  fields: [CacheableFields.FIELD_ASSIGNEE],
                },
                {
                  model: Contact,
                  fields: [CacheableFields.FIELD_CONTACT_SELECT],
                },
              ] satisfies FieldDataCacheClearResolverParams,
            },
            {
              service: 'PosthogRecordingServiceResolver',
            },
            {
              service: 'CustomFieldSchemaResolver',
            },
            {
              service: 'UserPresenceResolver',
            },
            {
              service: 'FieldAssigneeCacheClearResolverService',
            },
            {
              service: 'TicketCountResolverService',
            },
          ],
          modules: [
            AppModule.CustomFields,
            AppModule.SubAccountsBase,
            AppModule.DateTime,
            AppModule.InvoicesBase,
            AppModule.BillingBase,
            AppModule.VoiceCall,
            AppModule.ContactsBase,
            AppModule.ChatStatistics,
            AppModule.ChatPreviewNext,
            AppModule.MessagesBulksProgress,
            AppModule.NavigationOrder,
            AppModule.WhatsApp,
            AppModule.TicketsNotifications,
            AppModule.TmTiptapEditor,
          ],
        },
        children: [
          {
            path: '',
            name: 'loggedInRoot',
            component: () => import('@/components/layout/LoggedInRoot.vue'),
            meta: {
              resolverComponent: LoggedInPageResolver,
              resolvers: [
                {
                  service: 'FilterSchemaResolver',
                },
                {
                  service: 'ModelResolver',
                  params: [Country, Timezone],
                },
                [
                  {
                    service: 'UserResolver',
                  },
                  {
                    service: 'PermissionsResolverService',
                  },
                  {
                    service: 'PermissionsSubscriptionResolverService',
                  },
                  {
                    service: 'SubAccountsResolver',
                  },
                ],
                {
                  service: 'ChatStatisticsResolver',
                },
                {
                  service: 'TicketMessageNotificationResolver',
                },
                {
                  service: 'VoiceCallConfigResolverService',
                },
                {
                  service: 'ByocAccessResolver',
                },
                {
                  service: 'SystemExitResolver',
                },
                {
                  service: 'StopImpersonateResolver',
                },
                {
                  service: 'UserCustomFieldsEventResolver',
                },
                {
                  service: 'SystemRefreshResolver',
                },
                {
                  service: 'SettingStartedInfoResolverService',
                },
                {
                  service: 'UiSettingsResolverService',
                },
                {
                  service: 'UserWorkflowAccountChangedResolverService',
                },
                {
                  service: 'UserPolicyPrivacyChangedResolverService',
                },
                {
                  service: 'NavigationOrderResolver',
                  params: {
                    serviceId: 'ContactNavigationOrderService',
                  } satisfies NavigationOrderResolverParams,
                },
                {
                  service: 'RouteLeaveResolver',
                },
              ],
            },
            children: [
              {
                name: 'user.top',
                path: '',
                component: PassThrough,
                children: [
                  ...getTenDlcTollFreeRoutes(),
                  ...getPaymentRoutes(),
                  ...getPrivacyPolicyRoutes(),
                  ...getUserRoutes(),
                  ...getSandboxRoutes(),
                  ...getByocTopRoutes(),
                  {
                    name: 'ticketsDetailsWindow',
                    path: 'ticket-details-window/:ticketId',
                    component: () => import('@/components/views/tickets/TicketDetailsWindow.vue'),
                    meta: {
                      modules: [AppModule.Typing],
                      resolvers: [
                        {
                          service: 'ModelResolver',
                          params: [TicketStatus, TicketInbox],
                        },
                        {
                          service: 'TicketServerEventsResolverService',
                        },
                        {
                          service: 'TicketDetailsServerEventsResolverService',
                        },
                        {
                          service: 'TypingResolver',
                        },
                        [
                          {
                            service: 'TicketByWorkspaceIdResolverService',
                            params: {
                              redirectToIfNotFound: { name: ticketsRouteNames.root },
                            } satisfies TicketByWorkspaceIdResolverParams,
                          },
                          {
                            service: 'TicketMarkAsReadResolverService',
                          },
                        ],
                      ],
                      resolverComponent: () =>
                        import('@/components/domain/tickets/resolvers/TicketDetailsWindowResolver.vue'),
                      titler: {
                        service: 'DomainTitlerService',
                        params: {
                          model: Ticket,
                          field: 'subject',
                          idParamName: ticketIdViewParam,
                        } as DomainTitlerParams,
                      },
                    },
                  },
                ],
              },
            ],
          },
          ...getPlanExpiredRoutes(),
          ...getWizardRoutes(),
          ...getTokenIsLockedRoutes(),
          ...getMessengerPreviewRoutes(),
        ],
      },
      {
        name: 'auth.top',
        path: '',
        component: PassThrough,
        children: getAuthRoutes(),
      },
      {
        name: 'emailUnsubscribe',
        path: '/unsubscribe/:userId/:token/:templateId?/:email?',
        props: (route) => ({
          userId: route.params.userId,
          token: route.params.token,
          templateId: route.params.templateId,
          email: route.params.email,
        }),
        component: () => import('@/components/views/external/EmailUnsubscribe.vue'),
        meta: {
          pageAvailableFor: PageAvailableFor.all,
          modules: [AppModule.EmailUnsubscribe],
        },
      },
      {
        name: 'subscriptionBillingSettings',
        path: '/subscription/billing/settings',
        component: PassThrough,
        meta: {
          pageAvailableFor: PageAvailableFor.authenticated,
          resolvers: [
            {
              service: 'SubscriptionBillingSettingsResolverService',
            },
          ],
          accessControlGroups: ['UserWorkflowStrategy'],
        },
      },
      {
        name: 'widget',
        path: 'widget',
        component: PassThrough,
        redirect: { name: '' },
        meta: {
          pageAvailableFor: PageAvailableFor.all,
        },
        children: [
          {
            name: 'widget.subscribe',
            path: 'subscribe/:code',
            props: true,
            component: () => import('@/components/views/services/forms/FormSubscribeLanding.vue'),
            meta: {
              resolvers: [{ service: 'WidgetEmbedCodeResolver' }],
              sentryWidgetButtonHidden: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/components/views/Error.vue'),
    meta: {
      pageAvailableFor: PageAvailableFor.all,
    },
  },
  {
    path: '/:catchAll(.*)*',
    redirect: '/',
  },
]

export default routes

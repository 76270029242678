import type { ModelRaw } from '@/types'
import type ByocProviderPhone from '@/data/models/domain/services/byoc/ByocProviderPhone'
import type ByocPhone from '@/data/models/domain/services/byoc/ByocPhone'
import type { ByocProvider, ByocPlanStatus } from '@/data/models/domain/services/byoc/types'
import type { TmCountryCode } from '@/services/types'
import type { ForwardingDirection } from '@/data/repositories/domain/phoneNumbersRepository'
import type ByocFlowSinchCredentialsFormService from '@/services/forms/services/byoc/byocFlowSinchCredentialsFormService'
import type ByocFlowTwilioCredentialsFormService from '@/services/forms/services/byoc/byocFlowTwilioCredentialsFormService'
import type ByocFlowVonageCredentialsFormService from '@/services/forms/services/byoc/byocFlowVonageCredentialsFormService'
import type SenderSource from '@/data/models/domain/SenderSource'
import type ByocFlowBandwidthCredentialsFormService from '@/services/forms/services/byoc/byocFlowBandwidthCredentialsFormService'
import type ByocProviderSenderId from '@/data/models/domain/services/byoc/ByocProviderSenderId'
import type { ByocSenderIdStatus } from '@/data/models/domain/services/byoc/ByocSenderId'

export const byocFlowStepVariant = ['provider', 'credentials', 'numbers'] as const
export type ByocFlowStep = (typeof byocFlowStepVariant)[number]

export type ByocFlowSharedData = ByocCredentials & { label: string; hasSenderIds: boolean; hasPhones: boolean }

export type ByocFlowCredentialsFormSchema = {
  accountSid: string
  accountToken: string
  label: string
}

export type ByocFlowSinchCredentialsFormSchema = {
  projectId: string
  accessKeyId: string
  keySecret: string
  servicePlanId: string
  apiToken: string
  label: string
}

export type ByocFlowBandwidthCredentialsFormSchema = {
  accountId: string
  username: string
  password: string
  label: string
}

export enum ByocCarrierStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export type ByocCarrierLabelFormSchema = {
  label: string
}

export type ByocCredentialsFormSchema = {
  accountSid: string
  accountToken: string
}

export type ByocCredentialsTwilio = {
  provider: ByocProvider.TWILIO
} & ByocCredentialsFormSchema

export type ByocCredentialsVonage = {
  provider: ByocProvider.VONAGE
} & ByocCredentialsFormSchema

export type ByocCredentialsSinch = {
  provider: ByocProvider.SINCH
  projectId: string
  servicePlanId: string
  servicePlanToken: string
} & ByocCredentialsFormSchema

export type ByocCredentialsBandwidth = {
  provider: ByocProvider.BANDWIDTH
  projectId: string
} & ByocCredentialsFormSchema

export type ByocCredentials =
  | ByocCredentialsTwilio
  | ByocCredentialsVonage
  | ByocCredentialsSinch
  | ByocCredentialsBandwidth

export type ByocCredentialsShortened = Omit<ByocCredentials, 'servicePlanId' | 'servicePlanToken'>

export interface ByocFlowCredentialsForm {
  getCredentials(): ByocCredentials
}

export type ByocProviderPhonesResponse = {
  providerPhones: ModelRaw<ByocProviderPhone>[]
  pagination: {
    nextId: string
  }
}

export type ByocProviderPhonesResponseRaw = {
  providerPhones: ModelRaw<ByocProviderPhone>[]
  pagination: {
    nextPageToken: string | null
  }
}

export type ByocProviderSenderIdsResponse = {
  providerSenderIds: ModelRaw<ByocProviderSenderId>[]
  pagination: {
    nextId: string
  }
}

export type ByocProviderSenderIdsRaw = {
  senderId: string
  status: ByocSenderIdStatus
}

export type ByocProviderSenderIdsResponseRaw = {
  providerSenderIds: ByocProviderSenderIdsRaw[]
  pagination: {
    nextPageToken: string | null
  }
}

export type ByocPhoneLabelModalParams = {
  editingId: string
  model: typeof ByocPhone
  byocCarrierId?: string
}

export type ByocCarrierLinkModalParams = {
  editingId: string
  onSuccess: () => void
}

export type UpdateByocCarrierPayload = Partial<{
  label: string
  accountToken: string
}>

export type AddByocCarrierPhonesPayloadItem = {
  phoneNumber: string
  label: string
}

export type AddByocCarrierSenderIdsPayloadItem = {
  senderId: string
}

export type ByocUserCarrier = {
  accountSid: string
  id: number
  provider: ByocProvider
  providerStatus?: ByocCarrierStatus
}

export type ByocCreateCarrierPayloadPhone = {
  phoneNumber: string
  label: string
}
export type ByocCreateCarrierPayloadSenderId = {
  senderId: string
}

export type ByocCreateCarrierPayload = ByocCredentials & {
  label: string
  carrierPhones: ByocCreateCarrierPayloadPhone[]
  carrierSenderIds: ByocCreateCarrierPayloadSenderId[]
}

export type AvailableByocPlan = {
  id: number
  currency: TmCountryCode
  cost: number
  messagesLimit: number
}

export type ByocPlan = {
  plan: AvailableByocPlan
  messagesSent: number
  status: ByocPlanStatus
  renewalAt: string
  startedAt: string
  expiredAt: string | null
  renewalPlan: AvailableByocPlan
}

export const ByocMessageCost = 0.01

export type ByocAvailableProvider =
  | ByocProvider.TWILIO
  | ByocProvider.VONAGE
  | ByocProvider.SINCH
  | ByocProvider.BANDWIDTH

export type ByocFlowCredentialsService =
  | ByocFlowTwilioCredentialsFormService
  | ByocFlowVonageCredentialsFormService
  | ByocFlowSinchCredentialsFormService
  | ByocFlowBandwidthCredentialsFormService

export type ByocCarrierLinkModalGridableCredentials = {
  provider: ByocProvider
  carrierId: string
}

export type ByocCarrierPhoneCallForwardingPayload = {
  forwardingType: ForwardingDirection
  forwardTo?: string
  forwardAudioId?: string
}

export type ByocRouteType =
  | 'main'
  | 'base'
  | 'subscribe'
  | 'providers'
  | 'phones'
  | 'senderIds'
  | 'flow'
  | 'edit'
  | 'callForwarding'

export const ByocRouteNames = {
  main: 'user.services.byoc',
  base: 'user.services.byoc.base',
  subscribe: 'user.services.byoc.subscribe',
  providers: 'user.services.byoc.providers',
  phones: 'user.services.byoc.numbers',
  senderIds: 'user.services.byoc.senderIds',
  flow: 'user.services.byoc.flow',
  edit: 'user.services.byoc.edit',
  callForwarding: 'user.services.byoc.callForwarding',
} as const satisfies Record<ByocRouteType, string>

export const ByocMessengerRouteNames: Record<
  'main' | 'byocProvidersBase' | 'providers' | 'phones' | 'senderIds' | 'flow',
  string
> = {
  main: 'user.chats.settings.byoc',
  byocProvidersBase: 'user.chats.settings.byocProviders.base',
  providers: 'user.chats.settings.byoc.providers',
  phones: 'user.chats.settings.byoc.numbers',
  senderIds: 'user.chats.settings.byoc.senderIds',
  flow: 'user.chats.settings.byoc.flow',
}

export const ByocMessengerRoutePaths: Record<'subscribe' | 'flow' | 'edit' | 'callForwarding', string> = {
  subscribe: '/messenger/settings/byoc/subscribe',
  flow: '/messenger/settings/byoc/flow',
  edit: '/messenger/settings/byoc/:provider/:carrierId',
  callForwarding: '/messenger/settings/byoc/:provider/:carrierId/:phoneNumberId/call-forwarding',
}

export type RawByocNumbers = {
  userCarrierTwilio?: SenderSource[]
  userCarrierMessageBird?: SenderSource[]
  userCarrierVonage?: SenderSource[]
  userCarrierSinch?: SenderSource[]
  userCarrierBandwidth?: SenderSource[]
  userCarrierTelnyx?: SenderSource[]
  userCarrierInfobip?: SenderSource[] // presumed name
  userCarrierAws?: SenderSource[] // presumed name

  userCarrierTwilioSenderId?: SenderSource[]
  userCarrierMessageBirdSenderId?: SenderSource[] // presumed name
  userCarrierVonageSenderId?: SenderSource[] // presumed name
  userCarrierSinchSenderId?: SenderSource[] // presumed name
  userCarrierBandwidthSenderId?: SenderSource[] // presumed name
  userCarrierTelnyxSenderId?: SenderSource[] // presumed name
}

// temporary type
export type GetUserNumbersOptions = {
  userId?: string
  enableByocSenderId?: boolean
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  OutboundCallsColumnServiceFactory,
  OutboundCallsFilterServiceFactory,
  OutboundCallsRepository,
  OutboundCallsService,
}

export type HistoryOutboundCallsServices = keyof typeof SERVICES

export const HISTORY_OUTBOUND_CALLS_SERVICE_TYPES: Record<HistoryOutboundCallsServices, symbol> =
  makeServicesTypes<HistoryOutboundCallsServices>(enumKeysToArray<HistoryOutboundCallsServices>(SERVICES))

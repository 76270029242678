import { AttrField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { TenDlcRegulationStatus, TollFreeRegulationStatus } from '@/services/domain/services/regulations/types'

@OrmModel(modelNames.REGULATIONS_STATUS)
export default class RegulationsStatus extends BaseModel {
  @AttrField()
  public tenDlcState: TenDlcRegulationStatus

  @AttrField()
  public tollfreeState: TollFreeRegulationStatus
}

import { onBeforeUnmount, watch, toValue, type MaybeRefOrGetter } from '@/composition/vue/compositionApi'

export const useResizeObserver = (
  target: MaybeRefOrGetter<HTMLElement | undefined | null>,
  callback: ResizeObserverCallback,
) => {
  const resizeObserver = new ResizeObserver(callback)

  const reconnectObserver = (value: HTMLElement | undefined | null) => {
    if (!value) return
    resizeObserver.observe(value)
  }

  const cancelWatcher = watch(
    () => toValue(target),
    (newValue) => reconnectObserver(newValue),
    { immediate: true },
  )

  onBeforeUnmount(() => {
    resizeObserver?.disconnect()
  })

  return () => {
    cancelWatcher()
    resizeObserver?.disconnect()
  }
}

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum FILTERED_VIEWS_SERVICES {
  FilteredViewsRepository,
  FilteredViewsService,
  FilteredViewsValidationRegistrator,
  EditableFilteredViewsResolverService,
  FilteredViewsResolverService,
  FilteredViewTableApplyResolverService,
  FilteredViewServerEventsResolverService,
  FilteredViewsFaceterService,
  FilteredViewsCompareService,
  FilteredViewsPreloaderService,
  FilteredViewsForEntityRepository,
  FilteredViewsForEntityService,
}

export type FilteredViewsServices = keyof typeof FILTERED_VIEWS_SERVICES

export const FILTERED_VIEWS_SERVICES_TYPES = makeServicesTypesFromEnum<FilteredViewsServices>(FILTERED_VIEWS_SERVICES)

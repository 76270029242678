if (__SHOULD_POLYFILL_BROADCAST_CHANNEL_API__) {
  // https://github.com/JSmith01/broadcastchannel-polyfill/blob/master/index.js
  const _channels: Array<Array<BroadcastChannelPolyfill>> = []

  class BroadcastChannelPolyfill {
    private id

    private _name = ''

    private _closed = false

    private _mc: MessageChannel

    private _onStorageHandler: (e: StorageEvent) => void

    constructor(channel: string) {
      this.id = `$BroadcastChannel$${channel}$`
      _channels[this.id] = _channels[this.id] || []
      _channels[this.id].push(this)
      this._name = channel
      this._mc = new MessageChannel()
      this._mc.port1.start()
      this._mc.port2.start()

      this._onStorageHandler = this.onStorageHandler.bind(this)
      window.addEventListener('storage', this._onStorageHandler)
    }

    public get name() {
      return this._name
    }

    public postMessage(message: unknown) {
      if (this._closed) {
        const e = new Error()
        e.name = 'InvalidStateError'
        throw e
      }
      const value = message ? JSON.stringify(message) : ''

      // Broadcast to other contexts via storage events...
      const key = `${this.id + String(Date.now())}$${String(Math.random())}`
      window.localStorage.setItem(key, value)
      setTimeout(() => {
        window.localStorage.removeItem(key)
      }, 500)

      // Broadcast to current context via ports
      _channels[this.id].forEach((bc) => {
        if (bc === this) {
          return
        }
        bc._mc.port2.postMessage(JSON.parse(value))
      })
    }

    public close() {
      if (this._closed) {
        return
      }
      this._closed = true
      window.removeEventListener('storage', this._onStorageHandler)
      this._mc.port1.close()
      this._mc.port2.close()

      const index = _channels[this.id].indexOf(this)
      _channels[this.id].splice(index, 1)
    }

    public get onmessage() {
      return this._mc.port1.onmessage
    }

    public set onmessage(value: ((ev: MessageEvent<any>) => any) | null) {
      this._mc.port1.onmessage = value
    }

    public onmessageerror() {
      //
    }

    public addEventListener(
      type: keyof MessagePortEventMap,
      listener: EventListenerOrEventListenerObject,
      useCapture?: boolean,
    ) {
      return this._mc.port1.addEventListener.call(this._mc.port1, type, listener, useCapture)
    }

    public removeEventListener(
      type: keyof MessagePortEventMap,
      listener: EventListenerOrEventListenerObject,
      useCapture?: boolean,
    ) {
      return this._mc.port1.removeEventListener.call(this._mc.port1, type, listener, useCapture)
    }

    public dispatchEvent(event: Event) {
      return this._mc.port1.dispatchEvent.call(this._mc.port1, event)
    }

    private onStorageHandler(e: StorageEvent) {
      if (e.storageArea !== window.localStorage || e.newValue == null) {
        return
      }
      if (e.key?.substring(0, this.id.length) !== this.id) {
        return
      }
      const data = e.newValue === '' ? null : JSON.parse(e.newValue)
      this._mc.port2.postMessage(data)
    }
  }

  window.BroadcastChannel = window.BroadcastChannel || BroadcastChannelPolyfill
}

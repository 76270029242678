import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type ModelPreloaderService from '@/services/preloaders/modelPreloaderService'
import type BaseModel from '@/data/models/BaseModel'
import type { TablePreloadable } from '@/services/preloaders/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import PageMapPreload from '@/data/models/PageMapPreload'
import type PageMapPreloadRepository from '@/data/repositories/pageMapPreloadRepository'
import type { TableKeyBuilder } from '@/core/tables/tableKeyBuilder'
import { ServiceGroups } from '@/config/types'

@injectable()
export default class TablePreloaderService implements TablePreloadable {
  /* final */ private constructor(
    @inject(SERVICE_TYPES.ModelPreloaderService) protected readonly modelPreloaderService: ModelPreloaderService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.TableKeyBuilder) protected readonly tableKeyBuilder: TableKeyBuilder,
  ) {}

  public deleteTablePreloadersByCondition(tableModelId: string) {
    this.getRepository().deleteByCondition((rec) => rec.accessoryId === tableModelId)
  }

  public updateTablePreloaders(pageKey: string, tableModelId: string, ids: string[], model: typeof BaseModel) {
    this.getRepository().updatePreload({
      model,
      key: this.tableKeyBuilder.key(pageKey, tableModelId),
      pageKey,
      accessoryId: tableModelId,
      ids,
      updatedAt: new Date().toISOString(),
      preloaderType: ServiceGroups.PRELOADERS_TABLE,
    })
    this.modelPreloaderService.createAndMarkAsPreloaded(model.entity, model)
  }

  public findTablePreloader(pageKeyId: string) {
    return this.getRepository().find(pageKeyId)
  }

  public isPreloaded(pageKeyId: string): boolean {
    const preloader = this.findTablePreloader(pageKeyId)
    return preloader && preloader.isPreloaded && preloader.preloaderType === ServiceGroups.PRELOADERS_TABLE
  }

  public allTablePreloaders(model: typeof BaseModel): PageMapPreload[] {
    return this.getRepository()
      .query()
      .where('modelName', model.entity)
      .where('preloaderType', ServiceGroups.PRELOADERS_TABLE)
      .all()
  }

  public isFailed(pageKeyId: string, model: typeof BaseModel): boolean {
    return this.modelPreloaderService.isFailed(pageKeyId, model, ServiceGroups.PRELOADERS_TABLE)
  }

  public markAsFailed(pageKey: string, tableModelId: string, model: typeof BaseModel): void {
    this.getRepository().markAsFailed({
      model,
      key: this.tableKeyBuilder.key(pageKey, tableModelId),
      pageKey,
      accessoryId: tableModelId,
      updatedAt: new Date().toISOString(),
      preloaderType: ServiceGroups.PRELOADERS_TABLE,
    })
  }

  protected getRepository() {
    return this.em.getRepository<PageMapPreloadRepository>(PageMapPreload)
  }
}

import type { Component } from '@/composition/vue/compositionApi'
import { isRecordUnknown } from '@/utils/typeGuards'
import type { Callback } from '@/types'

/* eslint-disable @typescript-eslint/naming-convention */

export type AsyncComponentDefinition<T extends Component> = T & {
  __asyncLoader(): Promise<T>
}

export interface ReloadableAsyncComponentContext<T extends Component> {
  __asyncLoader(): Promise<T>

  __retry(): void

  __stateChangeListeners: Callback<void, [state: 'resolved' | 'error']>[]

  __onStateChange(cb: Callback<void, [state: 'resolved' | 'error']>): void

  __clearListeners(): void

  __previousLoadError: Error | null
}

export type ReloadableAsyncComponentDefinition<T extends Component> = AsyncComponentDefinition<T> &
  ReloadableAsyncComponentContext<T>
export const isReloadableAsyncComponentDefinition = <T extends Component>(
  value: unknown,
): value is ReloadableAsyncComponentDefinition<T> => {
  return isRecordUnknown(value) && typeof value.__retry === 'function' && typeof value.__onStateChange === 'function'
}

import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { SortDirection } from '@/core/tables/types'

@OrmModel(modelNames.SORTER)
export default class Sorter extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @StringField('')
  public direction: SortDirection

  @StringField('')
  public relation: string

  @StringField('')
  public entityName: string

  @StringField()
  public tableModelId: string

  public toString() {
    return [this.name, this.direction].join(':')
  }
}

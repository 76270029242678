import { TmTransportError } from '@/core/error/transport/tmTransportError'
import type { TmAxiosResponse } from '@/services/transport/types'
import type { TmTransportHttpErrorCode } from '@/core/error/transport/types'

export class TmRedirectError extends TmTransportError {
  public name = 'TmRedirectError'

  protected code: TmTransportHttpErrorCode = 303

  public readonly redirectUrl?: string

  constructor(message: string, response: TmAxiosResponse<{ redirectUrl?: string }>, stack?: string) {
    super(message, response, stack)

    this.redirectUrl = response.data?.redirectUrl
  }
}

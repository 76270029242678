import { computed } from '@/composition/vue/compositionApi'
import { getOrderService } from '@/core/container/ioc'

export const useOrder = (key: string, initialOrder?: string[]) => {
  const service = getOrderService()

  const order = computed(() => service.getOrder(key)?.order ?? [])

  const setOrder = (orderValue: string[]) => service.setOrder(key, orderValue)

  const addToOrder = (element: string) => {
    if (order.value.includes(element)) return
    setOrder([...order.value, element])
  }

  const removeFromOrder = (element: string) => {
    setOrder(order.value.filter((orderElement) => orderElement !== element))
  }

  const replaceInOrder = (oldElement: string, newElement: string) => {
    const index = order.value.indexOf(oldElement)
    if (index === -1) return
    const orderCopy = [...order.value]
    orderCopy[index] = newElement
    setOrder(orderCopy)
  }

  if (initialOrder) {
    setOrder(initialOrder)
  }

  return {
    order,
    addToOrder,
    removeFromOrder,
    replaceInOrder,
    setOrder,
  }
}

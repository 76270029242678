<template>
  <div class="tm-breadcrumbs-wrapper">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.tm-breadcrumbs-wrapper {
  padding: 6px 0;
  max-width: 100%;
  overflow: hidden;
}
</style>

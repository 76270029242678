import type { ObjectDirective, DirectiveBinding } from '@/composition/vue/compositionApi'

type OuterClick = (event: MouseEvent) => void
const ignoreClass = 'ignore-outer-click'

interface ExtendedHTMLElement extends HTMLElement {
  outerClick: OuterClick
}

function outerClick(this: ExtendedHTMLElement, bind: DirectiveBinding<(event: Event) => any>): OuterClick {
  return (event) => {
    if (event.target instanceof Element) {
      if (event.target.classList.contains(ignoreClass) || event.target.parentElement?.classList.contains(ignoreClass)) {
        return
      }
      if (!(this === event.target || this.contains(event.target))) {
        bind.value(event)
      }
    }
  }
}

export const outerClickDirective: ObjectDirective<ExtendedHTMLElement, (event: Event) => any> = {
  beforeMount(element, bind, vn): void {
    const isDirectiveValueFunction = bind?.value?.constructor.name === 'Function'

    if (!isDirectiveValueFunction) {
      throw Error('On outer click method should be specified')
    }

    element.outerClick = outerClick.bind(element)(bind)

    window.addEventListener('click', element.outerClick)
  },
  beforeUnmount(element): void {
    window.removeEventListener('click', element.outerClick)
  },
}

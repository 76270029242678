import { inject, injectable } from 'inversify'
import { SERVICE_TYPES, type RegisteredServices } from '@/core/container/types'
import type { HttpService } from '@/services/transport/httpService'
import type { HttpInterceptor, InternalErrorResponse } from '@/services/transport/types'
import type { IServiceManager } from '@/core/middlewares/types'
import type { Service } from '@/config/types'
import type { GetLocator } from '@/core/container/container'

@injectable()
export default class HttpInterceptorManager implements IServiceManager {
  constructor(
    @inject(SERVICE_TYPES.Api) protected readonly api: HttpService,
    @inject('GetLocator') private readonly get: GetLocator,
  ) {}

  public addService(service: Service) {
    this.register(this.get(service.id as RegisteredServices))
  }

  private register(interceptor: HttpInterceptor<any, InternalErrorResponse>) {
    this.api.getAxios().interceptors.request.use(
      async (config) => {
        if (interceptor.request) {
          return interceptor.request(config)
        }
        return config
      },
      async (error: any) => {
        if (interceptor.requestError) {
          return interceptor.requestError(error)
        }

        return undefined
      },
    )
    this.api.getAxios().interceptors.response.use(
      async (response) => {
        if (interceptor.response) {
          return interceptor.response(response)
        }
        return response
      },
      async (error) => {
        if (interceptor.responseError) {
          return interceptor.responseError(error)
        }
        return Promise.reject(error)
      },
    )
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { IPermissionsService, TmPermissions } from '@/services/types'
import type SubAccountsPermissionsService from '@/services/domain/accounts/subAccounts/subAccountsPermissionsService'
import type UserService from '@/services/domain/user/userService'

@injectable()
export default class PermissionsService implements IPermissionsService {
  constructor(
    @inject(SERVICE_TYPES.SubAccountsPermissionsService)
    private readonly subAccountsPermissionsService: SubAccountsPermissionsService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {}

  public async getPermissions() {
    await this.subAccountsPermissionsService.getPermissions(this.userService.currentUser().id)
  }

  public hasPermissions() {
    return !!this.subAccountsPermissionsService.findEntityByIdOrNull(this.userService.currentUser().id)
  }

  public async getPermissionsIfNotLoaded() {
    if (!this.hasPermissions()) {
      await this.getPermissions()
    }
  }

  public isActivePermission(permission: TmPermissions): boolean {
    const userId = this.userService.currentUserId()
    if (userId === null) {
      return false
    }
    // @todo - temporary, delete after update permissions on BE
    let preparePermission = permission
    const permissions = this.subAccountsPermissionsService.findEntityByIdOrFail(userId)
    const oldPermissionsMap: Partial<Record<TmPermissions, TmPermissions>> = {
      autorechargeSettings: 'billing',
      invoices: 'billing',
      reportingStatements: 'billing',
    }
    if (permissions.accountPermissions.billing) {
      preparePermission = oldPermissionsMap[permission] ?? preparePermission
    } else if (preparePermission === 'billing') {
      return true
    }
    //----------------------
    const option = this.subAccountsPermissionsService.findUserPermissionsInAnyGroupByName(userId, preparePermission)
    return option.value
  }
}

import type { Options } from '@/retrying-dynamic-import/types'
import { retryToLoadCSS } from '@/retrying-dynamic-import/retryingCSS'

const RETRY_COUNT = 3
const MAX_DELAY_MS = 1000
const DELAY_BASE_MS = 100
// match a module url
// eg: import('hello.js') => 'hello.js'
const uriOrRelativePathRegex = /import\(["']([^)]+)['"]\)/
// match a module name
// eg: './hello.js') => 'hello.js'
const moduleNameRegex = /(\w+.(js|css))/i

/**
 * Records the number of retrying times of a module.
 * key: module url
 * value: number
 */
const moduleRetryCount: Record<string, number> = {}

const options: Options = {
  offlineMessage: 'No internet connection',
  disableRetryingCSS: false,
}

const isOffline = async () => {
  if (!options.checkOnlineUrl) {
    return false
  }
  try {
    const response = await fetch(options.checkOnlineUrl)
    if (response.ok) {
      return false
    }
    return true
  } catch {
    return true
  }
}

const handleOffline = (reject: (e: Error) => void) => {
  if (options.offlineCallback) {
    options.offlineCallback()
  }
  reject(new Error(options.offlineMessage))
}

const fetchModule = async (url: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const retry = async (res: (value: unknown) => void, rej: (reason?: unknown) => void) => {
      const online = window.navigator.onLine
      if (!online) {
        // Check the network status again.
        if (options.checkOnlineUrl !== null) {
          const offline = await isOffline()
          if (offline) {
            handleOffline(rej)
            return
          }
        } else {
          handleOffline(rej)
          return
        }
      }

      let importUrl = url
      if (moduleRetryCount[url]) {
        importUrl = url.includes('?') ? url + '&t=' + Date.now() : url + '?t=' + Date.now()
      }

      import(/* @vite-ignore */ importUrl)
        .then((mod) => {
          if (moduleRetryCount[url]) {
            moduleRetryCount[url] = 1
          }
          res(mod)
        })
        .catch((err) => {
          if (!moduleRetryCount[url]) {
            moduleRetryCount[url] = 1
          } else {
            moduleRetryCount[url] += 1
          }

          if (moduleRetryCount[url] <= RETRY_COUNT) {
            // exponential backoff algorithm
            const delay = Math.min(DELAY_BASE_MS * 2 ** moduleRetryCount[url], MAX_DELAY_MS)
            setTimeout(() => retry(res, rej), delay)
          } else {
            moduleRetryCount[url] = 1
            let name = url
            const match = moduleNameRegex.exec(url)
            if (match) {
              name = match[0]
            }
            if (typeof err === 'string' && err.indexOf(name) === -1) {
              err += ` url=${url}`
            } else if (err instanceof Error && err.message.indexOf(name) === -1) {
              err.message = `${err.message} url=${url}`
            }
            rej(err)
          }
        })
    }
    retry(resolve, reject)
  })
}

const getValidUrl = (originalImport: () => Promise<unknown>): string | null => {
  try {
    const fnString = originalImport.toString()
    const m = uriOrRelativePathRegex.exec(fnString)
    return m ? m[1] : null
  } catch (e) {
    return null
  }
}

const mergeOptions = (userOptions: Options) => {
  Object.assign(options, userOptions)
}

const errors: Error[] = []
let timerId: ReturnType<typeof setTimeout>

const throwError = (reject: (e: unknown) => void, e: Error) => {
  clearTimeout(timerId)
  errors.push(e)
  timerId = setTimeout(() => {
    const err = errors.length === 1 ? errors[0] : new AggregateError(errors, 'Retry dynamic import')
    if (err instanceof AggregateError) {
      // for the micro-sentry client - it cannot send AggregateError
      err.stack += errors.join('\n')
    }
    reject(err)
    errors.length = 0
  }, MAX_DELAY_MS * 3)
}

const retryingDynamicImport = (opts: Options = {}) => {
  mergeOptions(opts)
  window.__rdl__ = (originalImport: () => Promise<unknown>) => {
    if (!opts.disableRetryingCSS) {
      retryToLoadCSS()
    }
    return new Promise((resolve, reject) => {
      const url = getValidUrl(originalImport)
      if (!url) {
        originalImport()
          .then(resolve)
          .catch((e) => {
            throwError(reject, new Error('OriginalImport error: ' + e.message, e.stack))
          })
      } else {
        fetchModule(url)
          .then(resolve)
          .catch((e) => {
            fetch(url, {
              method: 'GET',
              mode: 'cors',
            })
              .then((res) => {
                if (!res.ok) {
                  return throwError(
                    reject,
                    new Error(`Error during chunk loading. Url "${url}". Response status=${res.status}`),
                  )
                }
                return throwError(
                  reject,
                  new Error(`FetchModule error: ${e.message}. Response status=${res.status}`, e.stack),
                )
              })
              .catch((err) => {
                return throwError(reject, new Error(`Network error during chunk loading. Url "${url}"`, err.stack))
              })
          })
      }
    })
  }
}

export default retryingDynamicImport

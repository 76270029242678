<template>
  <div class="chat-content-channels">
    <h1 class="page-title-20 emphasize--text mb-2">{{ $t('entities.chat.empty.channels.title') }}</h1>
    <div class="body-text-regular-14 emphasize--text lh-20 mb-6 chat-content-channels__subtitle">
      {{ $t('entities.chat.empty.channels.subtitle') }}
    </div>
    <info-card-rows>
      <info-card-route-row
        class="info-card-route-row"
        :title="$t('entities.chat.empty.channels.sms.title')"
        :text="$t('entities.chat.empty.channels.sms.subtitle')"
        :to="{ name: ChatSettingsRoutes.smsMmsCalls }"
      >
        <template v-slot:image>
          <info-card-row-icon
            icon="chat"
            color="blue"
          />
        </template>
      </info-card-route-row>

      <info-card-route-row
        class="info-card-route-row"
        :title="$t('entities.chat.empty.channels.whatsapp.title')"
        :text="$t('entities.chat.empty.channels.whatsapp.subtitle')"
        :to="{ name: ChatSettingsRoutes.whatsapp }"
      >
        <template v-slot:image>
          <info-card-row-image :src="whatsAppLogo" />
        </template>
      </info-card-route-row>

      <info-card-route-row
        class="info-card-route-row"
        :title="$t('entities.chat.empty.channels.livechat.title')"
        :text="$t('entities.chat.empty.channels.livechat.subtitle')"
        :to="{ name: ChatSettingsRoutes.widgets }"
      >
        <template v-slot:image>
          <info-card-row-image :src="liveChatLogo" />
        </template>
      </info-card-route-row>

      <info-card-route-row
        class="info-card-route-row"
        :title="$t('entities.chat.empty.channels.facebook.title')"
        :text="$t('entities.chat.empty.channels.facebook.subtitle')"
        :to="{ name: ChatSettingsRoutes.facebook }"
      >
        <template v-slot:image>
          <info-card-row-image :src="facebookLogo" />
        </template>
      </info-card-route-row>

      <info-card-route-row
        v-if="instagramEnabled"
        class="info-card-route-row"
        :title="$t('entities.chat.empty.channels.instagram.title')"
        :text="$t('entities.chat.empty.channels.instagram.subtitle')"
        :to="{ name: ChatSettingsRoutes.instagramIndex }"
      >
        <template v-slot:image>
          <info-card-row-image :src="instagramLogo" />
        </template>
      </info-card-route-row>
    </info-card-rows>

    <tm-translate
      class="body-text-regular-14 emphasize--text mt-6"
      keypath="entities.chat.empty.channels.bottomText"
      tag="div"
    >
      <template v-slot:link-text>
        <tm-button
          color="primary"
          size="xMedium"
          text-link
          @click="onSolved"
        >
          <span class="solved-btn">{{ $t('entities.chat.empty.channels.linkText') }}</span>
        </tm-button>
      </template>
    </tm-translate>
  </div>
</template>

<script setup lang="ts">
import InfoCardRouteRow from '@/components/shared/templates/infoCard/InfoCardRouteRow.vue'
import InfoCardRowIcon from '@/components/shared/templates/infoCard/InfoCardRowIcon.vue'
import InfoCardRows from '@/components/shared/templates/infoCard/InfoCardRows.vue'
import InfoCardRowImage from '@/components/shared/templates/infoCard/InfoCardRowImage.vue'
import TmTranslate from '@/components/TmTranslate.vue'
import { ChatSettingsRoutes } from '@/routes/types'
import whatsAppLogo from '@/assets/images/social/whatsapp.svg'
import facebookLogo from '@/assets/images/social/facebook.svg'
import instagramLogo from '@/assets/images/social/instagram.svg'
import liveChatLogo from '@/assets/images/live-chat.svg'
import { isFeatureEnabled } from '@/composition/featureFlags'
import { FeatureFlags } from '@/services/types'
import { setCookieByName } from '@/utils/cookies'
import TmButton from '@/components/shared/TmButton.vue'
import { useActiveChat } from '@/composition/domain/chats/chatActive'
import { PromoChatCookieName, PromoChatState } from '@/data/models/domain/chats/types'

const instagramEnabled = isFeatureEnabled(FeatureFlags.Instagram)
const onSolved = () => {
  setCookieByName(PromoChatCookieName, PromoChatState.Solved, 400)
  const { isPromoChatSolved } = useActiveChat()
  isPromoChatSolved.value = true
}
</script>

<style lang="scss" scoped>
.chat-content-channels {
  width: 100%;
  max-width: 720px;
  text-align: center;
  gap: 24px;
  padding: 40px;
  border-radius: $border-radius-lg;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;

  @include media-md-max {
    padding: 20px;
  }

  &__subtitle {
    padding: 0 90px;

    @include media-md-max {
      padding: 0;
    }
  }
}

:deep {
  .info-card-rows {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 12px;

    & > :not(:last-child) {
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
      border-radius: $border-radius-md !important;
    }
  }
  .info-card-row {
    width: 640px;
    height: 68px;
    padding: 12px;
    background: $white;
    border-radius: $border-radius-md !important;
    box-shadow: 0px 0px 16px 0px #0000000d;

    @include media-xl-max {
      width: 100%;
      min-height: 68px;
      height: auto;
    }

    &__image {
      margin-right: 16px;
    }
  }

  .info-card-row__description {
    display: flex;
  }

  .info-card-row__text > div {
    margin-bottom: 0 !important;
    text-align: left;
  }
}

.info-card-route-row {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  width: 100%;
}
.solved-btn {
  color: $blue !important;
}
</style>

import type BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import type RootBaseForm from '@/services/forms/rootBaseForm'
import type { FieldConfig } from '@/services/forms/types'
import type { ValidationRulesMap } from '@/services/validation/types'

type ToObject = {
  name: string
  field: BaseFieldModel
  childrens: ToObject[]
}

export default class BaseForm<T extends BaseFieldModel> {
  private readonly localValidators: ValidationRulesMap = new Map()

  public config: FieldConfig

  public field: T

  public parent: RootBaseForm | BaseForm<BaseFieldModel> | null = null

  public id: string

  public itemPrototype: BaseForm<BaseFieldModel>

  public childrens: BaseForm<BaseFieldModel>[] = []

  public hash: number

  constructor(config: FieldConfig, field: T, parent?: BaseForm<BaseFieldModel>, localValidators?: ValidationRulesMap) {
    this.config = config
    this.field = field
    if (parent) {
      this.parent = parent
    }
    if (localValidators) {
      this.localValidators = localValidators
    }
    this.hash = Math.random()
  }

  public setField(field: T) {
    this.field = field
  }

  public addItemPrototype(itemPrototype: BaseForm<BaseFieldModel>) {
    this.itemPrototype = itemPrototype
  }

  public addChild(child: BaseForm<BaseFieldModel>, id: string) {
    this.childrens = this.childrens.filter((c) => c.id !== id)
    child.parent = this
    child.id = id
    this.childrens.push(child)
  }

  public toObject(): ToObject {
    return {
      name: this.config.name,
      field: this.field,
      childrens: this.childrens.map((c) => c.toObject()),
    }
  }

  public toArray(): BaseForm<BaseFieldModel>[] {
    return this.flatten(this.childrens)
  }

  public getLocalValidators() {
    return this.localValidators
  }

  protected flatten(childrens: BaseForm<BaseFieldModel>[]): BaseForm<BaseFieldModel>[] {
    if (childrens.length === 0) {
      return []
    }
    return [
      ...childrens,
      ...childrens.reduce((acc: BaseForm<BaseFieldModel>[], c) => {
        acc = [...acc, ...this.flatten(c.childrens)]
        return acc
      }, []),
    ]
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type { DomainServiceSettings } from '@/decorators/types'
import { TmDomainError } from '@/core/error/tmDomainError'
import type OrmApiSettingsRepository from '@/data/repositories/ormApiSettingsRepository'
import type BaseModel from '@/data/models/BaseModel'
import { ModelEventType } from '@/services/transport/types'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type { ModelRelationArray } from '@/services/domain/types'
import TmLogicError from '@/core/error/tmLogicError'

@injectable()
export default abstract class DomainSettingsService<
  T extends OrmApiSettingsRepository<M, U>,
  M extends BaseModel = BaseModel,
  U extends Record<string, any> = Record<string, any>,
> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService)
    protected readonly modelSubscriptionService: ModelSubscriptionService,
  ) {}

  public getDomainSettings(): DomainServiceSettings {
    throw new TmDomainError('Please use decorator @DomainSettings')
  }

  public getModel(): typeof BaseModel {
    if (!this.getDomainSettings().model) {
      throw new TmDomainError('Composite domain service must be without model')
    }

    return this.getDomainSettings().model!
  }

  public getDomainRepository(): T {
    if (!this.getDomainSettings().model) {
      throw new TmDomainError('Composite domain service must be without repository')
    }

    return this.entityManager.getRepository<T>(this.getDomainSettings().model!)
  }

  public findSettings(withRelations: ModelRelationArray<M> = []) {
    return this.getDomainRepository().findSettings(withRelations)
  }

  public findSettingsOrFail() {
    const settings = this.findSettings()
    if (!settings) {
      throw new TmLogicError('setting is null')
    }
    return settings
  }

  public async updateSettings(settings: U) {
    const result = await this.getDomainRepository().fetchUpdateSettings(settings)
    this.notify(ModelEventType.UPDATE)
    return result
  }

  public async patchRepoSettings(settings: U) {
    this.getDomainRepository().patchRepoSettings(settings)
  }

  public async getSettings() {
    const settings = this.findSettings()
    if (settings) return settings
    return this.getDomainRepository().getSettings()
  }

  public hasSettings() {
    return !!this.findSettings()
  }

  public async refreshSettings() {
    return this.getDomainRepository().getSettings()
  }

  protected notify(eventType: ModelEventType) {
    const id = this.getDomainRepository().getId()
    const payload = { ids: [id], eventType }
    this.modelSubscriptionService.emitByModel(this.getModel(), payload)
  }
}

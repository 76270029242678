import type { RegisteredServices } from '@/core/container/types'
import type { TmWrappers } from '@/wrappers'

export const maxLabelLength = 100

export const labelModalId: TmWrappers = 'phoneNumberLabel'
export const phoneNumbersTable = 'phoneNumbersTable' as RegisteredServices

export type ForwardCallsToType = ForwardCallsToVariant | string
export enum ForwardCallsToVariant {
  smsChat = 'SMS Chat',
  notSupported = 'NOT SUPPORTED',
  activateForwarding = 'Activate forwarding',
  activating = 'Activating...',
  externalDevice = 'EXTERNAL DEVICE',
}

export type DedicatedNumbersQueryParams = {
  voiceable?: boolean
  userId?: string
}

export enum ForwardDirection {
  forwardDirectionOff = 'off',
  forwardDirectionChat = 'chat',
  forwardDirectionPhone = 'phone',
  forwardDirectionStub = 'chat_to_stub',
}

export type GetAvailableToBuyNumbersForCountry = {
  tollfree?: boolean
  prefix?: number
}

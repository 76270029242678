<template>
  <div
    class="details-table-left"
    :class="{
      [`details-table-left--${sizeCalculated}`]: sizeCalculated,
      'details-table-left--align-center': alignCenter,
      'details-table-left--mobile-align-center': mobileAlignCenter,
    }"
  >
    <slot>
      <tm-skeleton
        v-if="loading"
        type="QChip"
        random-width
        height="12px"
      />

      <div
        v-else
        :class="{
          'details-table-left__content--disabled': disabled,
        }"
      >
        {{ label }}

        <tm-tooltip
          v-if="tooltip"
          position="bottom"
          max-width="218px"
        >
          <div class="text-center">
            {{ tooltip }}
          </div>
        </tm-tooltip>
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from '@/composition/vue/compositionApi'
import TmTooltip from '@/components/shared/tooltip/TmTooltip.vue'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import { useDetailsTableLabelSize } from '@/composition/detailsTable'

const props = defineProps<{
  label?: string
  size?: '' | 'large' | 'xLarge'
  alignCenter?: boolean
  mobileAlignCenter?: boolean
  tooltip?: string
  disabled?: boolean
  loading?: boolean
}>()

const sizeInjected = useDetailsTableLabelSize().inject()
const sizeCalculated = computed(() => props.size || sizeInjected?.value)
</script>

<style lang="scss" scoped>
.details-table-left {
  display: flex;
  flex-shrink: 0;
  padding: 10px 16px;
  width: 220px;
  background-color: $lift;
  font-weight: $semi-bold-font;
  justify-content: flex-end;
  align-items: flex-start;
  border-right: 1px solid $border-color;
  text-align: right;

  &--align-center {
    align-items: center;
  }
  &--large {
    width: 260px;
  }
  &--xLarge {
    width: 300px;
  }

  @include media-sm-x-max {
    padding: 12px 12px 0;
    background-color: unset;
    width: auto;
    place-content: center left;
    border-right: none;

    &--align-center {
      place-content: center left;
    }

    &--mobile-align-center {
      padding: 12px;
    }
  }

  &__content {
    &--disabled {
      opacity: 0.5;
    }
  }
}
</style>

import { cloneDeep } from 'lodash-es'
import { inject, injectable } from 'inversify'
import type { RouteRecordRaw } from 'vue-router'
import type RouterBuilderMiddlewareInterface from '@/services/route/routerBuilderMiddleware/types'
import TmLogicError from '@/core/error/tmLogicError'
import type { ResolverConfig } from '@/services/types'
import PassThrough from '@/components/layout/PassThrough.vue'
import { SERVICE_TYPES } from '@/core/container/types'
import type { RouteEntitiesService } from '@/services/route/routeEntitiesService'

@injectable()
export default class DetailsPageRouterBuilderMiddleware implements RouterBuilderMiddlewareInterface {
  constructor(
    @inject(SERVICE_TYPES.RouteEntitiesService) protected readonly routeEntitiesService: RouteEntitiesService,
  ) {}

  public pipe(routes: RouteRecordRaw[]): RouteRecordRaw[] {
    return routes.map((route: RouteRecordRaw) => {
      if (route.children?.length) {
        ;(route as any).children = this.pipe(route.children)
      }

      if (!route.meta?.detailsPage) return route

      const detailsPageRoute = cloneDeep(route)
      const resolverParams = {
        ...route.meta?.detailsPage,
        redirectToIfNotFound: route.meta?.redirectToIfNotFound,
      }
      if (!resolverParams) throw new TmLogicError('Detailed paged without resolvers')
      const notFoundResolver = {
        params: resolverParams,
        service: 'NotFoundResolverService',
      }

      const resolverRouteName = `${detailsPageRoute.name as string}.notFoundResolver`

      detailsPageRoute.children = [
        {
          name: resolverRouteName,
          path: '',
          component: route.component,
          children: route.children,
          redirect: route.redirect,
          meta: {
            resolverComponent: () =>
              route.meta?.redirectToIfNotFound
                ? import('@/components/resolvers/RedirectToIfNotFoundResolver.vue')
                : import('@/components/resolvers/NotFoundPageResolver.vue'),
            resolvers: [notFoundResolver],
            tabTitle: route.meta?.tabTitle,
          },
        } as any,
      ]
      detailsPageRoute.component = PassThrough
      detailsPageRoute.redirect = { name: resolverRouteName }

      if (!detailsPageRoute.meta!.resolvers) {
        detailsPageRoute.meta!.resolvers = []
      }
      detailsPageRoute.meta!.resolvers.push({
        params: resolverParams,
        service: route.meta?.detailsPageResolver ?? 'RedirectableSingleModelResolverService',
      } as ResolverConfig)

      if (!detailsPageRoute.meta!.resolverComponent) {
        detailsPageRoute.meta!.resolverComponent = () => import('@/components/resolvers/DetailsPageResolver.vue')
      }

      this.routeEntitiesService.addRouteToEntityMap({
        model: resolverParams.model,
        paramsKey: resolverParams.requestParam,
        detailsPage: detailsPageRoute.name || '',
      })

      return detailsPageRoute
    })
  }
}

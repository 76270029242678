import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  // Domain services
  FormClickToTextService,
  FormClickToTextDetailsService,

  // Repositories
  FormClickToTextRepository,
  FormClickToTextDetailsRepository,

  // Forms
  ClickToTextAppearanceFormService,
  ClickToTextSettingsFormService,

  // Table
  FormClickToTextColumnServiceFactory,
}

export type WidgetClickToTextServices = keyof typeof SERVICES

export const WIDGET_CLICK_TO_TEXT_SERVICE_TYPES: Record<WidgetClickToTextServices, symbol> =
  makeServicesTypes<WidgetClickToTextServices>(enumKeysToArray<WidgetClickToTextServices>(SERVICES))

import { AttrField, NumberField, StringField } from 'vuex-orm-decorators'
import type { Nullable } from '@/types'
import BaseModel from '@/data/models/BaseModel'

export default class KanbanBoardBase extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @StringField()
  public description: string

  @StringField(null)
  public icon: Nullable<string>

  @NumberField(0)
  public stageCount: number

  @NumberField(0)
  public position: number

  @AttrField(null)
  public archivedAt: Nullable<string>
}

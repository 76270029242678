/* eslint-disable @typescript-eslint/naming-convention */

export const instagramAttachmentMediaFileFormats: Record<string, string | string[]> = {
  // aac, m4a, wav, mp4, png, jpeg, gif, mp4, ogg, avi, mov, webm
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.webp': 'image/webp',
  '.mp4': ['video/mp4', 'audio/mp4'],
  '.aac': ['audio/aac', 'audio/x-aac', 'audio/x-hx-aac-adts', 'audio/aacp'],
  '.m4a': ['audio/m4a', 'audio/x-m4a'],
  '.ogg': 'video/ogg',
  '.wav': ['audio/wav', 'audio/x-wav'],
  '.gif': 'image/gif',
  '.avi': 'video/avi',
  '.mov': 'video/quicktime',
  '.webm': 'video/webm',
}

export const instagramAttachmentFileFormats: Record<string, string | string[]> = {
  ...instagramAttachmentMediaFileFormats,
  '.pdf': 'application/pdf',
  '.txt': 'text/plain',
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

import { get } from '@/core/container/ioc'
import type TableSearcherManagerInterface from '@/services/tables/managers/tableSearcherManagerInterface'
import type { UseTableBuilderSettings } from '@/composition/tables'
import type { Endpoint } from '@/services/endpoints'

type UseSearchersSettings = Pick<
  UseTableBuilderSettings,
  'tableModelId' | 'tableSearcherManager' | 'searcherFactory'
> & { endpoint: Endpoint }

export const useSearchers = (settings: Pick<UseSearchersSettings, 'tableSearcherManager'>) =>
  get<TableSearcherManagerInterface>(settings.tableSearcherManager ?? 'TableSearcherManager')

export const createSearchers = (settings: UseSearchersSettings) => {
  const manager = get<TableSearcherManagerInterface>(settings.tableSearcherManager ?? 'TableSearcherManager')

  manager.setEndpointForTable(settings.tableModelId, settings.endpoint)
  if (settings.searcherFactory) {
    manager.setFactoryForTable(settings.tableModelId, settings.searcherFactory)
  }

  return useSearchers(settings)
}

import { TmApiClientError } from '@/core/error/transport/tmApiClientError'
import type { TmTransportHttpErrorCode } from '@/core/error/transport/types'

export class TmApiAuthenticationSilencedError extends TmApiClientError {
  public name = 'TmApiAuthenticationSilencedError'

  protected isMonitored = false

  protected isNotified = false

  protected override code: TmTransportHttpErrorCode = 401

  public shouldBeNotified(): boolean {
    return false
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  UsersWorkflowEmptyPageResolverService,
  UsersWorkflowTabResolverService,
  UserWorkflowInvitedTabResolverService,
  UsersWorkflowColumnService,
  UsersClosedGridable,
  UsersActiveGridable,
  UsersInvitedGridable,
  UsersInvitedExportService,
  TeamPlanFormService,
  SubscriptionPriceResolverService,
  UsersWorkflowFacetsProvider,
  TeamSubscriptionService,
  TeamSubscriptionRepository,
}

export type TeamWorkflowServices = keyof typeof SERVICES

export const TEAM_WORKFLOW_SERVICE_TYPES: Record<TeamWorkflowServices, symbol> =
  makeServicesTypes<TeamWorkflowServices>(enumKeysToArray<TeamWorkflowServices>(SERVICES))

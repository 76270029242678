<template>
  <div
    class="tm-file"
    :class="size ? `tm-file--${kebabSize}` : ''"
    :style="{ background: colors.background }"
  >
    <tm-img
      v-if="showPreview"
      class="tm-file__preview"
      :src="url"
    />
    <tm-icon
      v-else
      :style="{ color: colors.color }"
      :name="iconName"
    />
  </div>
</template>

<script lang="ts">
import { kebabCase } from 'lodash-es'
import { defineComponent, computed, type PropType } from '@/composition/vue/compositionApi'
import type { IconSize, SizeProp, TmFileExtension } from '@/components/shared/types'
import TmIcon from '@/components/shared/TmIcon.vue'
import { styleVars } from '@/constants/styleVariables'
import TmImg from '@/components/shared/TmImg.vue'
import type { IconName } from '@/assets/icons/icons'

type FileColors = {
  background: string
  color: string
}

export default defineComponent({
  components: {
    TmImg,
    TmIcon,
  },
  props: {
    file: {
      type: String as PropType<TmFileExtension>,
      required: true,
    },
    url: {
      type: String,
    },
    size: {
      type: String as SizeProp<IconSize>,
      default: '',
    },
  },
  setup(props) {
    const imageExtensions = ['png', 'jpeg', 'jpg']
    const defaultColors: FileColors = { color: styleVars.orange700, background: styleVars.orange100 }
    const colorsMap: Record<TmFileExtension, FileColors> = {
      audio: { color: styleVars.red700, background: styleVars.red100 },
      contact: { color: styleVars.brown700, background: styleVars.brown100 },
      doc: { color: styleVars.blue700, background: styleVars.blue100 },
      json: { color: styleVars.purple700, background: styleVars.purple100 },
      mail: { color: styleVars.mint700, background: styleVars.mint100 },
      pdf: { color: styleVars.red700, background: styleVars.red100 },
      png: { color: styleVars.purple700, background: styleVars.purple100 },
      ppt: { color: styleVars.crimson700, background: styleVars.red100 },
      svg: { color: styleVars.orange700, background: styleVars.orange100 },
      text: { color: styleVars.gray700, background: styleVars.gray100 },
      unknown: { color: styleVars.purple700, background: styleVars.purple100 },
      'unknown-text': { color: styleVars.gray6, background: styleVars.gray50 },
      video: { color: styleVars.red700, background: styleVars.red100 },
      xls: { color: styleVars.green700, background: styleVars.green100 },
      xlsx: { color: styleVars.green700, background: styleVars.green100 },
      zip: { color: styleVars.orange700, background: styleVars.orange100 },
      csv: { color: styleVars.purple800, background: styleVars.purple100 },
    }
    const colors = computed<FileColors>(() => colorsMap[props.file] || defaultColors)
    const showPreview = computed(() => imageExtensions.includes(props.file) && props.url)

    const kebabSize = computed(() => kebabCase(props.size))
    const iconName = computed(() => `tmi-${props.file}` as IconName)

    return {
      iconName,
      colors,
      showPreview,
      kebabSize,
    }
  },
})
</script>

<style lang="scss" scoped>
.tm-file {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-md;
  width: 60px;
  height: 60px;
  &:deep(.tm-icon) {
    font-size: 48px !important;
    width: 48px;
    height: 48px;
  }
  &__preview {
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    object-fit: cover;
  }
  &--x-large {
    width: 80px;
    height: 80px;
    &:deep(.tm-icon) {
      font-size: 64px !important;
      width: 64px;
      height: 64px;
    }
  }
  &--large {
    width: 40px;
    height: 40px;
    &:deep(.tm-icon) {
      font-size: 32px !important;
      width: 32px;
      height: 32px;
    }
  }
  &--medium {
    width: 32px;
    height: 32px;
    &:deep(.tm-icon) {
      font-size: 24px !important;
      width: 24px;
      height: 24px;
    }
  }
  &--small {
    width: 24px;
    height: 24px;
    border-radius: $border-radius;
    &:deep(.tm-icon) {
      font-size: 16px !important;
      width: 16px;
      height: 16px;
    }
  }
  &--x-small {
    width: 20px;
    height: 20px;
    border-radius: $border-radius;
    &:deep(.tm-icon) {
      font-size: 14px !important;
      width: 14px;
      height: 14px;
    }
  }
  &--xx-small {
    width: 16px;
    height: 16px;
    border-radius: $border-radius;
    &:deep(.tm-icon) {
      font-size: 12px !important;
      width: 12px;
      height: 12px;
    }
  }
}
</style>

import type { FaceterSettingsType } from '@/services/facets/types'
import { REFLECT_METADATA } from '@/decorators/types'

export function FaceterSettings(faceterSettings: FaceterSettingsType) {
  return function _<T extends { new (...args: any[]): Record<string, any> }>(constructor: T) {
    Reflect.defineMetadata(REFLECT_METADATA.FaceterSettings, faceterSettings, constructor)
    return class extends constructor {
      public getFaceterSettings() {
        return faceterSettings
      }
    }
  }
}

<template>
  <div class="contact-change-email-confirmation">
    <div>
      <tm-translate keypath="contactsChangeEmailConfirmation.text.0">
        <template v-slot:name>
          <span class="font-weight-semi-bold">
            {{ fullName }}
          </span>
        </template>
      </tm-translate>
    </div>
    <div>
      <tm-translate keypath="contactsChangeEmailConfirmation.text.1">
        <template v-slot:tickets>
          <span class="font-weight-semi-bold">
            {{ `${quantityRelatedTickets} ${$tc('contactsChangeEmailConfirmation.tickets', quantityRelatedTickets)}` }}
          </span>
        </template>
        <template v-slot:closed>
          <span class="font-weight-semi-bold">
            {{ $t('contactsChangeEmailConfirmation.closed') }}
          </span>
        </template>
      </tm-translate>
    </div>
    <div>{{ $t('contactsChangeEmailConfirmation.text.2') }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmTranslate from '@/components/TmTranslate.vue'

export default defineComponent({
  components: { TmTranslate },
  props: {
    fullName: {
      type: String,
      required: true,
    },
    quantityRelatedTickets: {
      type: Number,
      required: true,
    },
  },
})
</script>
<style lang="scss" scoped>
.contact-change-email-confirmation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  color: $emphasize;
  font-size: $font-size-root;
  font-weight: $regular-font;
  line-height: 20px;
}
</style>

import { StringField, OrmModel, BooleanField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'
import type { StatusColor } from '@/components/shared/types'

@OrmModel(modelNames.TICKET_STATUS)
export default class TicketStatus extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @StringField()
  public color: StatusColor

  @BooleanField()
  public isSystem: boolean

  @BooleanField()
  public outline: boolean

  public get isSpam() {
    return this.isSystem && this.name === 'Spam'
  }

  public get isSolved() {
    return this.isSystem && this.name === 'Solved'
  }

  public get isClosed() {
    return this.isSystem && this.name === 'Closed'
  }

  public get isDraft() {
    return this.isSystem && this.name === 'Draft'
  }

  public get isNew() {
    return this.isSystem && this.name === 'New'
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type UserService from '@/services/domain/user/userService'
import type { PostHogService } from '@/services/tracking/postHogService'
import { UserStatus } from '@/services/domain/user/types'
import type LoggerService from '@/services/loggerService'
import type { MonitoringLoggerServiceInterface } from '@/services/monitoring/types'
import { LogLevel } from '@/core/logger/types'

@injectable()
export class PosthogRecordingService {
  constructor(
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
    @inject(SERVICE_TYPES.PostHogService) protected readonly postHogService: PostHogService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.MonitoringLoggerService)
    protected readonly monitoringLoggerService: MonitoringLoggerServiceInterface,
  ) {}

  public init() {
    this.log('Init Posthog recording tracker…')

    if (!this.postHogService.isInit) {
      this.log('Posthog is not initialized, skipping initialization.')
      return
    }

    try {
      const user = this.userService.currentUser()
      const isTrialUser = user.status === UserStatus.ST_TRIAL
      if (isTrialUser) {
        this.log('Current user is in TRIAL status, start session recording.')
        this.postHogService.getPosthog().startSessionRecording()
      } else {
        this.log('Current user is NOT in TRIAL status, skipping initialization.')
      }
    } catch (e) {
      if (e instanceof Error) {
        this.monitoringLoggerService.logAndCapture(e, LogLevel.ERROR, 'posthogRecording')
      } else {
        this.monitoringLoggerService.logAndCapture(
          'An unknown error occurred while initializing Posthog recording tracker.',
          LogLevel.ERROR,
          'posthogRecording',
        )
      }
    }
  }

  public stop(): void {
    this.log('Stop session recording')
    this.postHogService.getPosthog().stopSessionRecording()
  }

  private log(message: string) {
    this.loggerService.log('tracking', message, 'posthogRecording')
  }
}

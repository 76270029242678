import { inject, injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import TenDlcBrand from '@/data/models/domain/services/regulations/TenDlcBrand'
import type TenDlcBrandRepository from '@/data/repositories/domain/services/regulations/tenDlcBrandRepository'
import { getStatusColor, type MapStatusColor } from '@/utils/status'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type TranslateService from '@/services/translateService'
import type { StatusCellProps } from '@/services/domain/types'
import {
  TendDlcBrandEntityType,
  TendDlcBrandVerticalType,
  TenDlcBrandStatus,
} from '@/services/domain/services/regulations/tenDlcBrand/types'
import type { TollFreeRequestStatus } from '@/services/domain/services/regulations/tollFreeRequest/types'

@DomainSettings({
  model: TenDlcBrand,
})
@injectable()
export default class TenDlcBrandService extends DomainBaseService<TenDlcBrandRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public async getBrand() {
    return this.getDomainRepository().getBrand()
  }

  public getStatus(status: TenDlcBrandStatus | TollFreeRequestStatus): StatusCellProps {
    const labelMap: Record<TenDlcBrandStatus, string> = {
      [TenDlcBrandStatus.DRAFT]: this.translateService.t('status.tenDlcBrandStatus.draft'),
      [TenDlcBrandStatus.PENDING]: this.translateService.t('status.tenDlcBrandStatus.pending'),
      [TenDlcBrandStatus.IN_REVIEW]: this.translateService.t('status.tenDlcBrandStatus.inReview'),
      [TenDlcBrandStatus.VERIFIED]: this.translateService.t('status.tenDlcBrandStatus.verified'),
      [TenDlcBrandStatus.ACTION_REQUIRED]: this.translateService.t('status.tenDlcBrandStatus.actionRequired'),
    }
    const colorMap: MapStatusColor<TenDlcBrandStatus> = {
      gray: [TenDlcBrandStatus.DRAFT],
      green: [TenDlcBrandStatus.VERIFIED],
      blue: [TenDlcBrandStatus.PENDING, TenDlcBrandStatus.IN_REVIEW],
      orange: [TenDlcBrandStatus.ACTION_REQUIRED],
    }

    return {
      value: labelMap[status] || '',
      color: getStatusColor<TenDlcBrandStatus | TollFreeRequestStatus>(colorMap, status),
      outline: status !== TenDlcBrandStatus.ACTION_REQUIRED,
    }
  }

  public getEntityTypeLabel(entityType: TendDlcBrandEntityType) {
    const map: Record<TendDlcBrandEntityType, string> = {
      [TendDlcBrandEntityType.PUBLIC]: this.translateService.t('tenDlcBrandEntityType.public'),
      [TendDlcBrandEntityType.PRIVATE]: this.translateService.t('tenDlcBrandEntityType.private'),
      [TendDlcBrandEntityType.SOLE]: this.translateService.t('tenDlcBrandEntityType.sole'),
      [TendDlcBrandEntityType.NON_PROFIT]: this.translateService.t('tenDlcBrandEntityType.nonProfit'),
    }

    return map[entityType]
  }

  public getVerticalTypeLabel(verticalType: TendDlcBrandVerticalType) {
    const map: Record<TendDlcBrandVerticalType, string> = {
      [TendDlcBrandVerticalType.UNKNOWN]: this.translateService.t('common.unknown'),
      [TendDlcBrandVerticalType.REAL_ESTATE]: this.translateService.t('tenDlcVerticalType.realEstate'),
      [TendDlcBrandVerticalType.HEALTHCARE]: this.translateService.t('tenDlcVerticalType.healthcare'),
      [TendDlcBrandVerticalType.ENERGY]: this.translateService.t('tenDlcVerticalType.energy'),
      [TendDlcBrandVerticalType.ENTERTAINMENT]: this.translateService.t('tenDlcVerticalType.entertainment'),
      [TendDlcBrandVerticalType.RETAIL]: this.translateService.t('tenDlcVerticalType.retail'),
      [TendDlcBrandVerticalType.AGRICULTURE]: this.translateService.t('tenDlcVerticalType.agriculture'),
      [TendDlcBrandVerticalType.INSURANCE]: this.translateService.t('tenDlcVerticalType.insurance'),
      [TendDlcBrandVerticalType.EDUCATION]: this.translateService.t('tenDlcVerticalType.education'),
      [TendDlcBrandVerticalType.HOSPITALITY]: this.translateService.t('tenDlcVerticalType.hospitality'),
      [TendDlcBrandVerticalType.FINANCIAL]: this.translateService.t('tenDlcVerticalType.financial'),
      [TendDlcBrandVerticalType.GAMBLING]: this.translateService.t('tenDlcVerticalType.gambling'),
      [TendDlcBrandVerticalType.CONSTRUCTION]: this.translateService.t('tenDlcVerticalType.construction'),
      [TendDlcBrandVerticalType.NGO]: this.translateService.t('tenDlcVerticalType.ngo'),
      [TendDlcBrandVerticalType.MANUFACTURING]: this.translateService.t('tenDlcVerticalType.manufacturing'),
      [TendDlcBrandVerticalType.GOVERNMENT]: this.translateService.t('tenDlcVerticalType.government'),
      [TendDlcBrandVerticalType.TECHNOLOGY]: this.translateService.t('tenDlcVerticalType.technology'),
      [TendDlcBrandVerticalType.COMMUNICATION]: this.translateService.t('tenDlcVerticalType.communication'),
      [TendDlcBrandVerticalType.POSTAL]: this.translateService.t('tenDlcVerticalType.postal'),
      [TendDlcBrandVerticalType.TRANSPORTATION]: this.translateService.t('tenDlcVerticalType.transportation'),
    }

    return map[verticalType]
  }
}

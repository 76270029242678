import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  DateTimeService,
  DateTimeRangerService,
  RelativeDateTimeService,
  DateTimeValidationRegistrator,
  DateFilterServiceFactory,
}

export type DateTimeServices = keyof typeof SERVICES

export const DATE_TIME_SERVICE_TYPES: Record<DateTimeServices, symbol> = makeServicesTypes<DateTimeServices>(
  enumKeysToArray<DateTimeServices>(SERVICES),
)

import { AttrField, BooleanField, StringField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { TmCountryCode } from '@/services/types'
import type TenDlcRequest from '@/data/models/domain/services/regulations/TenDlcRequest'
import type TollFreeRequest from '@/data/models/domain/services/regulations/TollFreeRequest'
import type { ForwardCallsToType } from '@/services/domain/phoneNumbers/types'
import type { NumberGiftType } from '@/services/domain/services/regulations/types'

@OrmModel(modelNames.GETTING_STARTED_INFO)
export default class GettingStartedInfo extends BaseModel {
  @BooleanField()
  public showGettingStartedPage: boolean

  @AttrField()
  public virtualNumbers: IGettingStartedInfoPhone[]

  @AttrField()
  public senderIds: unknown[]

  @AttrField()
  public tenDlcNumbers: IGettingStartedInfoTenDlc<TenDlcRequest>[]

  @AttrField()
  public tollFreeNumbers: IGettingStartedInfoTollFree<TollFreeRequest>[]

  @AttrField()
  public additionalInfo: IGettingStartedInfoAdditionalInfo

  @StringField()
  public giftType: NumberGiftType
}

export interface IGettingStartedInfoTollFree<T> {
  phone: IGettingStartedInfoPhone
  request: T | null
}

export interface IGettingStartedInfoTenDlc<T> {
  phone: IGettingStartedInfoPhone
  request: T
}

export interface IGettingStartedInfoPhone {
  id: number
  countryId: TmCountryCode
  phone: string
  voiceAvailable: boolean
  forwardCallsTo: ForwardCallsToType
  expiresAt: string
  isOnTrial: boolean
}

export interface IGettingStartedInfoAdditionalInfo {
  localNumberPrice: number | null
}

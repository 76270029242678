import { injectable } from 'inversify'
import Pagination from '@/data/models/tables/Pagination'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'

@RepoSettings<Endpoint>({
  model: Pagination,
})
@injectable()
export default class PaginationRepository extends OrmApiRepository<Pagination> {
  public cleanUpByTableId(tableModelId: string) {
    this.deleteByCondition((record) => record.id === tableModelId)
  }

  public getPageMapKey(page: number, perPage: number) {
    return [page, perPage].join('-')
  }
}

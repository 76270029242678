import type { Config } from '@/core/types'
import { LogFormat, LogLevel } from '@/core/logger/types'
import type { NULL_CHANNEL } from '@/services/loggerService'

const channels = {
  // log when first render
  vueComponentTracked: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  // log when re-render
  vueComponentTriggered: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  auth: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  orm: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  router: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  resolver: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  preloader: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  filteredView: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  facet: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  bulk: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  cleanUp: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  routerBuilder: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  titler: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  queryParams: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  bus: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  table: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  tableWrapper: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  tableQueryWrapper: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  columns: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  forms: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  formBuilder: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  fieldBuilder: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  filters: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  pagination: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  formValidation: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  exception: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  globalException: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  debugInterceptor: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  partialUI: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  profiling: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  sorter: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  grouping: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  search: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  telemetry: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  debug: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  error: {
    format: LogFormat.GENERAL,
    level: LogLevel.ERROR,
  },
  warn: {
    format: LogFormat.GENERAL,
    level: LogLevel.WARNING,
  },
  info: {
    format: LogFormat.GENERAL,
    level: LogLevel.WARNING,
  },
  sandbox: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
  tracking: {
    format: LogFormat.GENERAL,
    level: LogLevel.NULL,
  },
}
const config: Partial<Config> = {
  env: 'dev',
  apiUrl: '',
  socketUrl: 'ws://localhost:8080/wire/connection/websocket',
  // for fallback to Socket.IO
  // socketUrl: 'ws://localhost:8080/socket.io',
  logger: {
    enabled: true,
    loggerRules: {},
    channels,
  },
  toolbar: {
    hide: true,
    logAggregator: {
      format: 'json',
    },
  },
  featureFlags: {},
}

export type LoggerChannels = keyof typeof channels | typeof NULL_CHANNEL

export default config

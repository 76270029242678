import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

export enum RECIPIENT_SERVICES {
  RecipientContactService,
  RecipientGroupService,
  RecipientFilteredViewService,
  RecipientContactRepository,
  RecipientGroupRepository,
  RecipientFilteredViewRepository,
}

export type RecipientServices = keyof typeof RECIPIENT_SERVICES

export const RECIPIENT_SERVICE_TYPES: Record<RecipientServices, symbol> = makeServicesTypes<RecipientServices>(
  enumKeysToArray<RecipientServices>(RECIPIENT_SERVICES),
)

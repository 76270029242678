import type { RouteRecordRaw } from 'vue-router'
import { ScheduledRoutes } from '@/routes/user/scheduled/types'
import { AppModule } from '@/config/types'

export const scheduledNotSentRoutes: RouteRecordRaw = {
  name: ScheduledRoutes.notSent,
  path: 'not-sent',
  redirect: { name: ScheduledRoutes.notSentOverview },
  children: [
    {
      name: ScheduledRoutes.notSentOverview,
      path: '',
      meta: {
        tags: ['SCHEDULED_BAR_LIST'],
        title: 'pageTitle.user.scheduled.notSent', // page content title @todo: get rid of it
        tabTitle: 'pageTabTitle.user.scheduled.notSent',
        resolvers: [
          {
            service: 'ScheduledNotSentWebsocketResolverService',
          },
        ],
        modules: [AppModule.ScheduledNotSent],
        accessControlGroups: ['NotCampaignsScheduledNotSentStrategy'],
      },
      component: () => import('@/components/views/scheduled/ScheduledNotSent.vue'),
    },
  ],
}

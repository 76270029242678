import type { CustomErrorRule } from '@/core/error/transport/types'
import { EndpointsEnum } from '@/services/endpoints'
import { TmAuthNeedVerifyEmailError } from '@/core/error/transport/auth/TmAuthNeedVerifyEmailError'
import { TmAuthNeedEnterCaptchaError } from '@/core/error/transport/auth/TmAuthNeedEnterCaptchaError'
import { TmAuthEmailIsForbiddenError } from '@/core/error/transport/auth/TmAuthEmailIsForbiddenError'

export const emailIsAvailableForRegistrationCaptchaIsInvalidErrorRule: CustomErrorRule = {
  endpoint: EndpointsEnum.registrationEmailIsAvailable,
  klass: TmAuthNeedEnterCaptchaError,
}

export const emailIsAvailableForRegistrationCaptchaIsInvalidErrorRule400: CustomErrorRule = {
  endpoint: EndpointsEnum.registrationEmailIsAvailable,
  klass: TmAuthNeedEnterCaptchaError,
  subCodes: ['request.too_many_requests'],
}

export const emailIsForbiddenForRegistrationErrorRule: CustomErrorRule = {
  endpoint: EndpointsEnum.registrationEmailIsAvailable,
  klass: TmAuthEmailIsForbiddenError,
}

export const emailIsAvailableForRegistrationNeedVerifyEmail: CustomErrorRule = {
  endpoint: EndpointsEnum.registrationEmailIsAvailable,
  klass: TmAuthNeedVerifyEmailError,
}

import { OrmModel, StringField, NumberField, BelongsToField, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import Contact from '@/data/models/domain/Contact'
import SenderSource from '@/data/models/domain/SenderSource'
import type { ReceivedSmsSource } from '@/types'

@OrmModel(modelNames.RECEIVED_SMS)
export default class ReceivedSms extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public sender: string

  @StringField()
  public messageTime: string

  @StringField()
  public text: string

  @StringField()
  public receiver: string

  @NumberField()
  public price: number

  @StringField()
  public contactId: string

  @BelongsToField(Contact, 'contactId')
  public contact: Contact

  @AttrField()
  public senderSourcePhone: string

  @BelongsToField(SenderSource, 'senderSourcePhone')
  public senderSource: SenderSource

  @StringField()
  public source: ReceivedSmsSource
}

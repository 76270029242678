export enum EndpointsEnum {
  countries = '/api/v3/countries/fetch',
  unauthenticatedCountry = '/api/v3/countries/detect-country',
  timezones = '/api/v3/timezones',

  // Blocked Contacts
  blockedContacts = '/api/v3/contacts/blocked/fetch',
  blockedContact = '/api/v3/contacts/blocked/{0}',
  blockContact = '/api/v3/contacts/block',
  blockContactById = '/api/v3/contacts/{0}/block',
  updateShowSharedContacts = '/api/v3/user/show/shared',
  unblockContactById = '/api/v3/contacts/{0}/unblock',
  bulkUnblockContact = '/api/v3/contacts/unblock/bulk',
  blockedContactBulkDelete = '/api/v3/contacts/blocked/delete',
  bulkBlockedContactExport = '/api/v3/contacts/blocked/export',
  // Sent SMS
  sentSmsShort = '/api/v3/history/messages/fetch',
  sentSmsSingle = '/api/v3/history/messages/{0}',
  sentSmsBulkDelete = '/api/v3/history/messages/delete',
  sentSmsPdfDownload = '/api/v3/history/messages/{0}/pdf',
  historyFacet = '/api/v3/history/messages/facets/count',
  sentSmsCounter = '/api/v3/history/count',
  bulkSentSms = '/api/v3/sessions/{0}/messages/fetch',
  bulkSentSmsExport = '/api/v3/sessions/{0}/messages/export',

  // Session
  sessionSingle = '/api/v3/sessions/{0}',
  sessionArchive = '/api/v3/sessions/{0}/archive',
  sessionUnarchive = '/api/v3/sessions/{0}/unarchive',
  sessionsRecipients = '/api/v3/sessions/{0}/recipients',

  sessionBulkArchive = '/api/v3/sms-campaigns/archive',
  sessionBulkUnarchive = '/api/v3/sms-campaigns/unarchive',
  sessionBulkDelete = '/api/v3/sessions/delete',
  sessionsIndexStat = '/api/v3/sessions/{0}/stat',
  sessionsIndexUpdateTitle = '/api/v3/sessions/{0}/update-title',

  sentSmsExport = '/api/v3/history/messages/export',
  // Recent Recipient
  sentSmsRecently = '/api/v3/history/messages/recently',
  // Received SMS
  receivedSms = '/api/v3/history/replies/fetch',
  receivedSmsSingle = '/api/v3/history/replies/{0}',
  receivedSmsBulkDelete = '/api/v3/history/replies/delete',
  receivedSmsPdfDownload = '/api/v3/history/replies/{0}/pdf',
  receivedSmsExport = '/api/v3/history/replies/export',
  // Lists
  list = '/api/v3/lists/{0}',
  listCreate = '/api/v3/lists',
  lists = '/api/v3/lists/fetch',
  allLists = '/api/v3/lists/fetch/all',
  listsBulkDelete = '/api/v3/lists/delete',
  removeContactFromContactList = '/api/v3/lists/{0}/contacts/delete',
  listExport = '/api/v3/lists/export',
  contactListDetails = '/api/v3/lists/{0}/contacts',
  bulkShareContactList = '/api/v3/contacts/share',
  hideContactList = '/api/v3/lists/hide/bulk',
  unhideContactList = '/api/v3/lists/unhide/bulk',
  // Lists Temp
  listCreateTemp = '/api/v3/lists/temp/contacts',
  listCreateTempExpand = '/api/v3/lists/temp/{0}/contacts',
  // Contacts
  contact = '/api/v3/contacts/{0}',
  contacts = '/api/v3/contacts/fetch/new',
  contactCreate = '/api/v3/contacts',
  contactsBulkDelete = '/api/v3/contacts/delete/new',
  contactsBulkUpdate = '/api/v3/contact/bulk-update',
  contactsExport = '/api/v3/contacts/export',
  contactsAutocomplete = '/api/v3/contacts/autocomplete',
  contactsFacet = '/api/v3/contacts/facets/count',
  contactUploadAvatar = '/api/v3/image/contact/upload',
  contactCounter = '/api/v3/contacts/count/new',
  contactByPhone = '/api/v3/contacts/phone/{0}',
  contactDownloadPdf = '/api/v3/contacts/{0}/pdf',
  // Pinned contacts
  pinnedContacts = '/api/v3/pinned/contact/items',
  contactsForPinningAutocomplete = '/api/v3/pinned/contact/autocomplete',
  deletePinnedContact = '/api/v3/pinned/contact/{0}',
  updatePinnedContactOrder = '/api/v3/pinned/contact/items/reorder',
  // Contact custom fields
  contactCustomFieldsSingle = '/api/v3/contact/{0}/customfields',
  contactCustomFieldsSingleNew = '/api/v3/contact/{0}/custom-contact-field_values/fetch',
  contactCustomFieldsUpdateNew = '/api/v3/contact/{0}/custom-contact-fields-values',
  // User custom fields (OLD)
  userCustomFields = '/api/v3/customfields/fetch',
  userCustomFieldsAll = '/api/v3/customfields/fetch/all',
  userCustomFieldSingle = '/api/v3/customfields/{0}',
  userCustomFieldCreate = '/api/v3/customfields',
  userCustomFieldsBulkDelete = '/api/v3/customfields/delete',
  // User custom fields (NEW)
  customFieldSchemaAll = '/api/v3/custom-fields/fetch',
  customFieldSchemaSingle = '/api/v3/custom-fields/{0}',
  customFieldSchemaCreate = '/api/v3/custom-fields',
  customFieldSchemaBulkDelete = '/api/v3/custom-fields/delete',
  // tags
  tags = '/api/v3/tags',
  // User analytics
  userAnalyticsFieldsUpdate = '/api/v3/user/analytics-fields',
  // User assignee
  assigneeFetch = '/api/v3/assignee/fetch',
  // Messages Price
  messagesPrice = '/api/v3/messages/price',
  messagesPriceForCountries = '/api/v3/messages/countries/price',
  messagesPriceCreate = '/api/v3/messages',
  // Messages Preview
  messagesPreview = '/api/v3/messages/preview',
  // Sending text
  messagesBulksProgress = '/api/v3/bulks/{0}',
  // Messages Attachment
  messagesAttachmentFileSms = '/api/v3/messages/attachment',
  messagesAttachmentFileMms = '/api/v3/messages/mms/attachment',
  messagesAttachmentFileMessenger = '/api/v3/messages/messenger/attachment',
  messagesAttachmentFileFacebook = '/api/v3/messages/facebook/attachment',
  messagesAttachmentFileInstagram = '/api/v3/messages/instagram/attachment',
  // Contact Imports
  contactImport = '/api/v3/import/contacts/sessions/{0}',
  contactImports = '/api/v3/import/contacts/sessions/fetch',
  contactImportDownload = '/api/v3/import/contacts/sessions/{0}/file/original',
  contactImportLookupDownload = '/api/v3/import/contacts/sessions/{0}/file/lookup',
  contactImportBulkDelete = '/api/v3/import/contacts/sessions/delete',
  contactImportResults = '/api/v3/import/contacts/sessions/{0}/details/{1}/fetch',
  contactImportResultsFacet = '/api/v3/import/contacts/sessions/{0}/facets/count',
  contactImportSessionsFileUpload = '/api/v3/import/contacts/sessions/file/upload',
  contactImportSessionsFileUploadCarrierLookup = '/api/v3/import/contacts/sessions/file/upload-carrier-lookup',
  contactImportSessions = '/api/v3/import/contacts/sessions',
  contactImportFields = '/api/v3/import/contacts/fields',
  contactImportSessionPreview = '/api/v3/import/contacts/sessions/{0}/preview',
  contactImportSessionNumbersFormat = '/api/v3/import/contacts/sessions/{0}/numbers-format/{1}',
  contactImportSessionProcess = '/api/v3/import/contacts/sessions/{0}/process',
  importContact = '/api/v3/import/contacts/{0}/new',
  importContactsIndexValidate = '/api/v3/import/contacts/{0}/validate',
  // distribution
  distributions = '/api/v3/emailtosms/distribution-lists',
  distribution = '/api/v3/emailtosms/distribution-lists/{0}',
  distributionsBulkDelete = '/api/v3/emailtosms/distribution-lists/delete',

  // Unsubscribed Contacts
  unsubscribedContacts = '/api/v3/contacts/unsubscribers/fetch',
  unsubscribeContact = '/api/v3/contacts/unsubscribe',
  resubscribeContact = '/api/v3/contacts/resubscribe',
  resubscribeContactById = '/api/v3/contacts/resubscribe/id',
  checkUnsubscribedContact = '/api/v3/contacts/subscription/info',
  toggleBlockIncomingMessages = '/api/v3/contacts/unsubscribers/{0}/block',
  bulkUnsubscribersExport = '/api/v3/contacts/unsubscribers/export',
  // Unsubscribed email Contacts
  unsubscribedEmailContacts = '/api/v3/contacts/email/unsubscribes/fetch',
  unsubscribedEmailContactsExport = '/api/v3/contacts/email/unsubscribes/export',
  // Templates
  templates = '/api/v3/templates/fetch',
  templateCreate = '/api/v3/templates',
  templatesSingle = '/api/v3/templates/{0}',
  templatesBulkDelete = '/api/v3/templates/delete',
  templatesExport = '/api/v3/templates/export',
  templatesEditCategory = '/api/v3/templates/categories/{0}/edit',
  templatesShare = '/api/v3/templates/share',
  templateSort = '/api/v3/templates/categories/sort',
  // Template Categories
  templateCategories = '/api/v3/templates/categories/fetch',
  templateCategoriesCreate = '/api/v3/templates/categories',
  templateCategoriesSingle = '/api/v3/templates/categories/{0}',
  // Allowed emails
  allowedEmails = '/api/v3/allowed-email/fetch',
  allowedEmailsBulkDelete = '/api/v3/allowed-email/delete',
  allowedEmailsCreate = '/api/v3/allowed-email/list',
  allowedEmailsSingle = '/api/v3/allowed-email/{0}',
  // Send and receive setting
  sendReceiveSetting = '/api/v3/send-and-receive-setting',

  // SMS Surveys
  surveys = '/api/v3/surveys',
  surveysBulkDelete = '/api/v3/surveys/delete',
  surveySingle = '/api/v3/surveys/{0}',
  surveyCancel = '/api/v3/surveys/{0}/cancel',
  surveyDuplicate = '/api/v3/surveys/{0}/duplicate',
  surveyReplies = '/api/v3/surveys/nodes/{0}/reply/fetch',
  surveyReset = '/api/v3/surveys/{0}/reset',
  surveyStart = '/api/v3/surveys/{0}/start',
  surveyExport = '/api/v3/surveys/{0}/replies/export',
  surveyRecipientCountries = '/api/v3/surveys/{0}/country-recipients',
  surveyAnalytics = '/api/v3/surveys/{0}/analytics',
  surveyRepliesCounts = '/api/v3/surveys/{0}/replies/stats',

  // SMS Survey nodes
  surveyNodes = '/api/v3/surveys/{0}/nodes',
  surveyNode = '/api/v3/surveys/nodes/{0}',
  surveyNodesTree = '/api/v3/surveys/{0}/nodes/tree',

  // Form
  formDelete = '/api/v3/form/{0}',

  // Form - clickToText
  formClickToText = '/api/v3/form/click-to-text',
  formClickToTextItem = '/api/v3/form/click-to-text/{0}',
  formClickToTextFetch = '/api/v3/form/click-to-text/fetch',
  formClickToTextDelete = '/api/v3/form/click-to-text/delete',

  // Form - subscribe
  formSubscribe = '/api/v3/form/subscribe',
  formSubscribeItem = '/api/v3/form/subscribe/{0}',
  formSubscribeIndexEmbed = '/api/v3/form/subscribe/{0}/embed',
  formSubscribeFetch = '/api/v3/form/subscribe/fetch',
  formSubscribeDelete = '/api/v3/form/subscribe/delete',

  // Automation rules
  // Rules
  automationRule = '/api/v3/automation-rule',
  automationRules = '/api/v3/automation-rule/fetch',
  automationRulesSingle = '/api/v3/automation-rule/{0}',
  automationRulesChangeStatus = '/api/v3/automation-rule/status/{0}',
  // Logs
  automationRuleLogs = '/api/v3/automation-rule/log/fetch',
  automationRuleLogsSingle = '/api/v3/automation-rule/log/{0}',
  automationRuleLogsExport = '/api/v3/automation-rule/log/export',
  automationRuleLogsDownloadPdf = '/api/v3/automation-rule/log/{0}/pdf',
  // Keywords
  automationRulesKeywords = '/api/v3/automation-rule/keyword/fetch',
  automationRulesKeywordsSingle = '/api/v3/automation-rule/keyword/{0}',
  automationRulesKeywordsCreate = '/api/v3/automation-rule/keyword',

  // Phone numbers
  phoneNumbers = '/api/v3/numbers/fetch',
  phoneNumbersSingle = '/api/v3/numbers/{0}',
  phoneNumberLabelUpdate = '/api/v3/numbers/{0}/label',
  phoneNumberBuy = '/api/v3/numbers/buy',
  phoneNumberChangeForwarding = '/api/v3/numbers/forwarding',
  phoneNumberByPhone = '/api/v3/numbers/phone/{0}',
  phoneNumbersRestore = '/api/v3/numbers/{0}/restore',
  // Available To Buy Numbers
  availableToBuyNumbers = '/api/v3/numbers/available',
  twilioAvailableToBuyNumbers = '/api/v3/verifications/ten-dlc/numbers',
  numberAvailableCountries = '/api/v3/numbers/countries',

  // Area codes
  areaCodes = '/api/v3/numbers/codes',
  // Number price
  numberPrice = '/api/v3/numbers/forwarding/price',
  // Sip Calls
  sipCallSingle = '/api/v3/calls/{0}',
  sipCallsBulkDelete = '/api/v3/calls/delete',
  sipCallExport = '/api/v3/calls/export',
  sipCallPdf = '/api/v3/calls/{0}/export',
  inboundCalls = '/api/v3/calls/inbound/fetch',
  outboundCalls = '/api/v3/calls/outbound/fetch',
  forwardedCalls = '/api/v3/forwarded-calls/fetch',
  forwardedCallSingle = '/api/v3/forwarded-calls/{0}',
  forwardedCallsBulkDelete = '/api/v3/forwarded-calls/delete',
  forwardedCallsExport = '/api/v3/forwarded-calls/export',
  forwardedCallsPdfExport = '/api/v3/forwarded-calls/{0}/export',
  // auth
  login = '/api/v3/auth',
  authLogout = '/api/v3/auth/logout',
  resendEmailCode = '/api/v3/auth/resend-email-code/{0}',
  resendPhoneCode = '/api/v3/auth/resend-phone-code/{0}',
  reopenAccount = '/api/v3/accounts/reopen',

  // auth services
  googleAuth = '/api/v3/oauth/google',
  googleAuthLink = '/api/v3/oauth/google-auth-url',
  appleAuth = '/api/v3/oauth/apple',
  appleAuthLink = '/api/v3/oauth/apple-auth-url',
  microsoftAuth = '/api/v3/oauth/microsoft',
  microsoftAuthLink = '/api/v3/oauth/microsoft-auth-url',

  // sso
  ssoAuthUrl = '/api/v3/oauth/sso-url',

  // accounts
  accountsNotifications = '/api/v3/account/notifications/settings',
  accountsResetPasswordById = '/api/v3/accounts/{0}/password/reset',
  closeAccount = '/api/v3/accounts/close',
  accountSsoSettings = '/api/v3/account/sso/settings',
  closeSubAccount = '/api/v3/accounts/close-subaccounts',
  accountTokenUnlock = '/api/v3/account/token/unlock',
  accountTokenFetch = '/api/v3/account/token/fetch',
  pointAiAssistant = '/api/v3/ai/assistant',
  pointAiAssistantDelete = '/api/v3/ai/assistant/{0}',
  pointAiSyncKnowledgeBase = '/api/v3/ai/sync-knowledge-base',
  pointAiWebsite = '/api/v3/ai/assistant/{0}/links',
  pointAiWebsiteDelete = '/api/v3/ai/assistant/{0}/links/delete',
  pointAiWebsiteDownloaded = '/api/v3/ai/assistant/{0}/links/{1}/downloaded',

  // users
  currentUser = '/api/v3/user',
  changePassword = '/api/v3/user/password/change',
  userPasswordVerify = '/api/v3/user/password/verify',
  userPasswordResendCode = '/api/v3/user/password/resend-code',
  userEmailVerify = '/api/v3/user/email/verify',
  userEmailResendCode = '/api/v3/user/email/resend-code',
  userVoiceCallSettings = '/api/v3/voice-call-settings',
  userMetadataFetch = '/api/v3/user/metadata/fetch',
  userMetadata = '/api/v3/user/metadata',
  userAcceptPrivacyPolicy = '/api/v3/user/accept-terms-update',
  userPresence = '/api/v3/users/presence',
  // userNumbers
  userNumbers = '/api/v3/numbers/all',
  // Accounts
  myDataExport = '/api/v3/accounts/my-data/export',
  myDataDeleteAllMessages = '/api/v3/history/messages/delete/all',
  myDataDeleteAllContacts = '/api/v3/contact/all',
  myDataFacets = '/api/v3/accounts/my-data/{0}/facets/count',
  accountMessageRetentionPeriod = '/api/v3/accounts/my-data/data-messages-lifetime',
  subAccountPermissions = '/api/v3/account/sub-accounts/{0}/permissions',
  // Subaccounts
  subaccounts = '/api/v3/subaccounts/fetch',
  subaccountsAll = '/api/v3/subaccounts/fetch/all',
  subaccount = '/api/v3/subaccounts/{0}',
  inviteSubAccount = '/api/v3/accounts/sub-account',
  reinviteSubAccount = '/api/v3/accounts/sub-account/resend',
  subaccountsFacet = '/api/v3/subaccounts/facets/count',
  usersFacet = '/api/v3/users/facets/count',
  userVatNumber = '/api/v3/accounts/uservat',
  userBillingDetails = '/api/v3/accounts/billing-details',
  userAdmins = '/api/v3/user/administrators/fetch',
  subAccountReopen = '/api/v3/accounts/sub-account/{0}/reopen',
  subAccountImportSessions = '/api/v3/import/subaccounts/sessions',
  subAccountImportSessionsFileUpload = '/api/v3/import/subaccounts/sessions/file/upload',
  subAccountImportSessionsFetch = '/api/v3/import/subaccounts/sessions/fetch',
  subAccountImportFields = '/api/v3/import/subaccounts/fields',
  subAccountImportSessionPreview = '/api/v3/import/subaccounts/sessions/{0}/preview',
  subAccountImportSessionProcess = '/api/v3/import/subaccounts/sessions/{0}/process',
  importSubAccountsIndexValidate = '/api/v3/import/subaccounts/{0}/validate',
  statementsFetch = '/api/v3/reporting/statements/fetch',
  statementsExport = '/api/v3/reporting/statements/export',
  statementsSummary = '/api/v3/reporting/statements/summary',

  // Unsubscribed settings
  unsubscribedSettings = '/api/v3/contacts/unsubscribers/settings/fetch',
  unsubscribedSettingsUpdate = '/api/v3/contacts/unsubscribers/settings',
  // Invoices
  invoices = '/api/v3/invoices/fetch',
  invoiceSingle = '/api/v3/invoices/{0}',
  invoicesSend = '/api/v3/invoices/send',
  invoiceDownload = '/api/v3/invoices/{0}/download',
  bulkInvoicesExport = '/api/v3/invoices/export',
  // Note
  notes = '/api/v3/contacts/{0}/notes/fetch',
  note = '/api/v3/notes/{0}',
  noteCreate = '/api/v3/contacts/{0}/notes',
  noteBulkDelete = '/api/v3/contacts/{0}/notes/delete',
  // Tests
  baseFiltersTest = '/api/test/filters',
  // Scheduled
  scheduledSingleMessage = '/api/v3/schedules/{0}',
  scheduledFetchMessages = '/api/v3/schedules/fetch',
  schedulesFetchLight = '/api/v3/schedules/fetch/light',
  schedulesCalendarFetch = '/api/v3/schedules/calendar/fetch',
  scheduledBulkDeleteMessages = '/api/v3/schedules/delete',
  scheduledExport = '/api/v3/schedules/export',
  scheduledPause = '/api/v3/schedules/pause',
  scheduledResume = '/api/v3/schedules/resume',
  scheduledDownloadPdf = '/api/v3/schedules/{0}/pdf',
  scheduledEdit = '/api/v3/schedules/{0}/edit',
  schedulesIndexUpdateTitle = '/api/v3/schedules/{0}/update-title',

  scheduledFacet = '/api/v3/scheduled/facets/count',
  scheduledFetchNotSent = '/api/v3/scheduled/not-sent/fetch',
  scheduledDeleteNotSent = '/api/v3/scheduled/not-sent/delete/{0}',
  scheduledExportNotSent = '/api/v3/scheduled/not-sent/export',
  scheduledBulkDeleteNotSent = '/api/v3/scheduled/not-sent/delete',
  scheduledBulkResent = '/api/v3/scheduled/not-sent/bulk-resent',

  // reporting
  reportingOverview = '/api/v3/reporting/overview',
  reportingOverviewPdf = '/api/v3/reporting/overview/pdf',
  auditLogs = '/api/v3/reporting/audit-logs',
  auditLogDownloadPdf = '/api/v3/reporting/audit-logs/{0}/pdf',
  reportingAuditLogsExport = '/api/v3/reporting/audit-logs/export',
  reportingNumbers = '/api/v3/reporting/numbers/fetch',
  reportingNumberExport = '/api/v3/reporting/numbers/export',

  reportingSubAccounts = '/api/v3/reporting/sub-accounts/fetch',
  reportingSubAccountsExport = '/api/v3/reporting/sub-accounts/export',

  reportingUsers = '/api/v3/reporting/users/fetch',
  reportingUsersExport = '/api/v3/reporting/users/export',

  reportingCalls = '/api/v3/reporting/calls/fetch',
  reportingCallsExport = '/api/v3/reporting/calls/export',
  reportingMessages = '/api/v3/reporting/messages/fetch',
  reportingMessagesExport = '/api/v3/reporting/messages/export',

  // Chat settings
  chatSettings = '/api/v3/chats/settings/fetch',
  chatSettingsUpdate = '/api/v3/chats/settings',

  // chats
  chats = '/api/v3/chats/fetch',
  chatSingle = '/api/v3/chats/{0}',
  chatBulk = '/api/v3/chats/bulk',
  chatMute = '/api/v3/chats/bulk/mute',
  chatAssignee = '/api/v3/chats/bulk/assignee',
  chatStatistics = '/api/v3/chats/unread/count',
  chatFacets = '/api/v3/chats/facets-count',
  chatDelete = '/api/v3/chats/bulk/delete',
  chatCloseRead = '/api/v3/chats/close/read',
  chatConversationSenderSettings = '/api/v3/sender-settings/chat/{0}',
  chatCreate = '/api/v3/chats',
  chatByPhone = '/api/v3/chats/phone/{0}',
  chatUp = '/api/v3/chats/{0}/up',
  chatCallsPrice = '/api/v3/calls/price',
  chatAISummary = '/api/v3/chats/{0}/summary',
  chatAISuggestion = '/api/v3/chats/{0}/suggested-reply',
  chatMmsInfo = '/api/v3/chats/{0}/mms-info',
  chatTakeNextRequest = '/api/v3/chats/take-next',
  chatPreviewNext = '/api/v3/chats/preview-next',
  chatReject = '/api/v3/chats/{0}/reject',
  chatBlockIP = '/api/v3/chats/{0}/block-ip',
  chatDetails = '/api/v3/chats/{0}/details',

  // chatMessage
  chatMessages = '/api/v3/chats/{0}/messages',
  chatMessageDelete = '/api/v3/chats/{0}/messages/delete',
  chatMessageExport = '/api/v3/chats/{0}/export',
  chatMessageExportPdf = '/api/v3/chats/{0}/pdf',
  chatMessageInitialMessage = '/api/v3/chats/{0}/initial-message',

  // Messenger messages
  messengerChatMessages = '/api/v3/messenger/chat/{0}/message/fetch',
  messengerChatMessage = '/api/v3/messenger/chat/{0}/message',
  messengerSingleMessage = '/api/v3/messenger/message/{0}',
  messengerMessageDelete = '/api/v3/messenger/chat/{0}/message/delete',
  messengerChatMessageThread = '/api/v3/messenger/chat/{0}/thread/fetch',
  messengerMessagePreview = '/api/v3/messenger/chat/{0}/message/preview',
  messengerContactThread = '/api/v3/messenger/chat/contact/{0}/thread/fetch',

  // Live chat messages
  messengerLiveChatMessage = '/api/v3/messenger/live-chat-message/{0}',

  // Messenger notes
  messengerNoteMessage = '/api/v3/messenger/note/{0}',

  // attachment
  attachment = '/api/v3/messages/attachments/{0}',
  attachmentDownload = '/api/v3/messages/attachments/{0}/download',

  // Audio
  audio = '/api/v3/audio/{0}',
  audios = '/api/v3/audio/fetch',
  audioUpload = '/api/v3/audio/upload',
  audioDownload = '/api/v3/audio/{0}/download',

  // senderId
  senderIds = '/api/v3/senderids',
  senderIdsFetch = '/api/v3/senderids/fetch',
  senderIdsItem = '/api/v3/senderids/{0}',
  senderIdPrice = '/api/v3/senderids/price',
  senderIdRestore = '/api/v3/senderids/{0}/restore',

  // Activity
  activitySentSms = '/api/v3/contact/{0}/messages/fetch',
  activitySchedules = '/api/v3/contact/{0}/schedules/fetch',
  activityCalls = '/api/v3/contact/{0}/calls/fetch',
  activityReplies = '/api/v3/contact/{0}/replies/fetch',
  activityLiveChat = '/api/v3/messenger/live-chat/contact/{0}/fetch',
  activityFacebook = '/api/v3/messenger/chat/contact/{0}/facebook/fetch',
  activityInstagram = '/api/v3/messenger/chat/contact/{0}/instagram/fetch',

  // Registration
  registrationAllow = '/api/v3/registration/form',
  registration = '/api/v3/registration',
  registrationByInvite = '/api/v3/registration/invite/complete',
  registrationByOAuth = '/api/v3/registration/oauth/complete',
  registrationConfirm = '/api/v3/registration/confirm',
  registrationEmailResend = '/api/v3/registration/email/resend',
  registrationByInviteTokenCheck = '/api/v3/registration/invite/check',
  registrationEmailIsAvailable = '/api/v3/registration/email/is-available',
  registrationResendAvailability = '/api/v3/registration/resend-availability',
  registrationConfirmCodeFromEmail = '/api/v3/registration/confirm-code',
  registrationBySSOInvitationTokenCheck = '/api/v3/registration/sso/invite/check',
  registrationBySSOInvitation = '/api/v3/registration/sso/invite/complete',

  // Reset password
  resetPasswordByEmail = '/api/v3/password/reset/send-email',
  passwordResetValidateToken = '/api/v3/password/reset/validate-token',
  passwordResetSetPassword = '/api/v3/password/reset/set-password',

  // Columns
  columns = '/api/v3/columns',
  columnsEntity = '/api/v3/columns/{0}',

  // Account: Security
  accountSettingsSecurity = '/api/v3/account/settings/security',
  accountSettingsSecurity2fa = '/api/v3/account/settings/security/2fa',
  accountSettingsSecurity2faIpWhiteList = '/api/v3/account/settings/security/2fa/ip-white-list',

  // verify
  sendVerifyCodeOnEmail = '/api/v3/verify/email/send',
  sendVerifyCodeOnPhone = '/api/v3/user/verify/phone/send',
  checkVerifyCodeOnPhone = '/api/v3/user/verify/phone/check',
  userVerifyPhoneCancel = '/api/v3/user/verify/phone/cancel',
  verifyEmailVerify = '/api/v3/verify/email/verify',
  accountSettingsSecurityTotpQr = '/api/v3/user/verify/totp/qr',
  accountSettingsSecurityTotpSetupKey = '/api/v3/user/verify/totp/setup-key',
  accountSettingsSecurityDisableTotp2fa = '/api/v3/user/verify/totp/cancel',
  accountSettingsSecurityApplyTotpCode = '/api/v3/user/verify/totp/apply',
  accountSettingsSecurityGetBackupCodes = '/api/v3/user/verify/get-auth-backup-codes',

  // Impersonate
  runImpersonate = '/api/v3/user/impersonate/{0}',
  stopImpersonate = '/api/v3/user/impersonation/stop',

  // Subaccount: Import
  subAccountImportSession = '/api/v3/import/subaccounts/sessions/{0}',
  subAccountExport = '/api/v3/subaccounts/export',
  subAccountImportsBulkDelete = '/api/v3/import/subaccounts/sessions/delete',
  subAccountImportDetails = '/api/v3/import/subaccounts/sessions/{0}/details/{1}/fetch',
  subAccountImportDetailsFacets = '/api/v3/import/subaccounts/sessions/{0}/facets/count',
  subAccountImport = '/api/v3/import/subaccounts/{0}',

  // Lookup
  // Carrier
  carrierLookupPhone = '/api/v3/carrier-lookup/phone/{0}',
  carrierLookupPrice = '/api/v3/carrier-lookup/price',
  carrierLookupFetch = '/api/v3/carrier-lookup/history/fetch',
  carrierLookupSingle = '/api/v3/carrier-lookup/history/{0}',
  carrierLookupExport = '/api/v3/carrier-lookup/history/export',
  carrierLookupBulkDelete = '/api/v3/carrier-lookup/history/delete',
  // Email
  emailLookupEmail = '/api/v3/email-lookup/email/{0}',
  emailLookupPrice = '/api/v3/email-lookup/price',
  emailLookupFetch = '/api/v3/email-lookup/history/fetch',
  emailLookupSingle = '/api/v3/email-lookup/history/{0}',
  emailLookupBulkDelete = '/api/v3/email-lookup/history/delete',
  emailLookupExport = '/api/v3/email-lookup/history/export',
  // Email Bulk
  emailLookupBulkSingle = '/api/v3/email-lookup/bulk/{0}',
  emailLookupBulkFetch = '/api/v3/email-lookup/bulk/fetch',
  emailLookupBulkBulkDelete = '/api/v3/email-lookup/bulk/delete',
  emailLookupBulkUpload = '/api/v3/email-lookup/bulk/upload',
  emailLookupBulkUploadFile = '/api/v3/email-lookup/bulk/upload/file',
  emailLookupBulkIndexPreview = '/api/v3/email-lookup/bulk/{0}/preview',
  emailLookupBulkUploadFields = '/api/v3/email-lookup/bulk/upload/fields',
  emailLookupBulkIndexProcess = '/api/v3/email-lookup/bulk/{0}/process',
  emailLookupBulkIndexResult = '/api/v3/email-lookup/bulk/{0}/result',
  emailLookupBulkIndexOriginal = '/api/v3/email-lookup/bulk/{0}/original',

  // sender settings: countries
  senderSettingsCountries = '/api/v3/sender-settings/countries',
  senderSettingsRestore = '/api/v3/sender-settings/restore',
  senderSettingsRestoreForCountry = '/api/v3/sender-settings/countries/restore',

  // loginHistory
  loginHistory = '/api/v3/accounts/login-history/fetch',
  logoutAll = '/api/v3/account/logout-from-all-devices',

  // api service
  apiV2TokenCreate = '/api/v3/tokens',
  apiV2TokenSingle = '/api/v3/tokens/{0}',
  apiV2TokensFetch = '/api/v3/tokens/fetch',
  apiV2TokensFlushCache = '/api/v3/tokens/flush-cache',

  // API Settings V1
  apiV1Settings = '/api/v3/api-settings',
  apiV1SettingsPassword = '/api/v3/api-settings/password',

  // API Settings V2
  apiV2Settings = '/api/v3/api-settings-v2',

  // API Settings TestCallbackUrl
  apiSettingsTestCallbackUrl = '/api/v3/api-settings/test-callback-url',

  // carrier lookup bulk
  carrierLookupBulk = '/api/v3/carrier-lookup/bulk',
  carrierLookupSessionBulkDelete = '/api/v3/carrier-lookup/bulk/delete',
  carrierLookupBulkFetch = '/api/v3/carrier-lookup/bulk/fetch',
  carrierLookupBulkFields = '/api/v3/carrier-lookup/bulk/fields',
  carrierLookupBulkFileUpload = '/api/v3/carrier-lookup/bulk/file/upload',
  carrierLookupBulkIndex = '/api/v3/carrier-lookup/bulk/{0}',
  carrierLookupBulkIndexPreview = '/api/v3/carrier-lookup/bulk/{0}/preview',
  carrierLookupBulkIndexProcess = '/api/v3/carrier-lookup/bulk/{0}/process',
  carrierLookupBulkIndexNumbersFormatIndex = '/api/v3/carrier-lookup/bulk/{0}/numbers-format/{1}',
  carrierLookupBulkIndexResult = '/api/v3/carrier-lookup/bulk/{0}/result',
  carrierLookupBulkIndexOriginal = '/api/v3/carrier-lookup/bulk/{0}/original',

  // Billing
  billingInfo = '/api/v3/billing/info',
  billingPayAsYouGoAutoRecharge = '/api/v3/billing/pay-as-you-go/auto-recharge',
  billingVerifyIdentity = '/api/v3/billing/verify-identity',
  billingPlansByMessagesCount = '/api/v3/billing/plans/by-messages-count',

  // regulations
  regulationsStatus = '/api/v3/getting-started/verifications/state',

  // getting started
  gettingStartedInfo = '/api/v3/getting-started/info',

  // ui settings
  uiSettings = '/api/v3/ui-settings',

  // ten dlc
  tenDlcRequestsApply = '/api/v3/verifications/ten-dlc/requests/apply',
  tenDlcRequestsGetSingle = '/api/v3/verifications/ten-dlc/requests/{0}',
  tenDlcGetPricing = '/api/v3/verifications/ten-dlc/prices',
  tenDlcGetVerticalTypes = '/api/v3/ten-dlc/enum/vertical',
  tenDlcGetStockExchange = '/api/v3/ten-dlc/enum/stock-exchange',
  tenDlcGetUseCase = '/api/v3/ten-dlc/enum/use-case',
  tenDlcValidateTaxNumber = '/api/v3/ten-dlc/utils/validate-tax-number',
  tenDlcPhoneNumbers = '/api/v3/verifications/ten-dlc/inbound/numbers/fetch',
  tenDlcCampaign = '/api/v3/verifications/ten-dlc/campaigns/fetch',
  tenDlcCampaignSingle = '/api/v3/verifications/ten-dlc/campaign/{0}',
  tenDlcBrand = '/api/v3/ten-dlc/brand',
  tenDlcTwilioBrand = '/api/v3/verifications/ten-dlc/brand',
  tenDlcBrandInfoForStep = '/api/v3/verifications/ten-dlc/info/brand',
  tenDlcCampaignByRequest = '/api/v3/verifications/ten-dlc/requests/{0}/campaign',
  tenDlcCampaignRestore = '/api/v3/verifications/ten-dlc/campaign/{0}/restore',
  tenDlcSummary = '/api/v3/verifications/ten-dlc/requests/{0}/summary',
  tenDlcCost = '/api/v3/ten-dlc/requests/{0}/cost',
  tenDlcRequestsSubscribeFormScreenshot = '/api/v3/verifications/ten-dlc/screenshot',
  tenDlcNumberLinkToCampaign = '/api/v3/verifications/ten-dlc/campaign/number/link',

  // toll-free
  tollFreeRequestsApply = '/api/v3/toll-free/requests/apply',
  tollFreeRequestsGetSingle = '/api/v3/toll-free/requests/{0}',
  tollFreeBrandInfoForStep = '/api/v3/info/brand/tollfree',
  tollFreeRequestsOptInScreenshot = '/api/v3/toll-free/requests/opt-in-screenshot',
  tollFreeSummary = '/api/v3/toll-free/requests/{0}/summary',
  tollFreePhoneNumbers = '/api/v3/toll-free/numbers/fetch',

  // byoc
  byoc = '/api/v3/byoc',
  byocCarrierIndex = '/api/v3/byoc/carrier/{0}',
  byocCarrierIndexIndex = '/api/v3/byoc/carrier/{0}/{1}',
  byocCarrierIndexCheckCredentials = '/api/v3/byoc/carrier/{0}/check-credentials',
  byocCarrierIndexGet = '/api/v3/byoc/carrier/{0}/get',
  byocCarrierIndexPhoneIndex = '/api/v3/byoc/carrier/{0}/phone/{1}',
  byocCarrierIndexPhoneIndexIndex = '/api/v3/byoc/carrier/{0}/phone/{1}/{2}',
  byocCarrierIndexPhoneIndexIndexSync = '/api/v3/byoc/carrier/{0}/phone/{1}/{2}/sync',
  byocCarrierIndexSenderIdIndex = '/api/v3/byoc/carrier/{0}/sender-id/{1}',
  byocCarrierFetch = '/api/v3/byoc/carrier/fetch',
  byocCarrierPhoneFetch = '/api/v3/byoc/carrier/phone/fetch',
  byocCarrierSenderIdIndex = '/api/v3/byoc/carrier/sender-id/{0}',
  byocCarrierSenderIdFetch = '/api/v3/byoc/carrier/sender-id/fetch',
  byocProviderCarrierIndexPhoneIndex = '/api/v3/byoc/provider/carrier/{0}/phone/{1}',
  byocProviderCarrierIndexPhoneCountIndex = '/api/v3/byoc/provider/carrier/{0}/phone/count/{1}',
  byocProviderCarrierIndexSenderIdIndex = '/api/v3/byoc/provider/carrier/{0}/sender-id/{1}',
  byocProviderCarrierIndexSenderIdCountIndex = '/api/v3/byoc/provider/carrier/{0}/sender-id/count/{1}',
  byocProviderPhoneIndex = '/api/v3/byoc/provider/phone/{0}',
  byocProviderPhoneCountIndex = '/api/v3/byoc/provider/phone/count/{0}',
  byocProviderSenderIdIndex = '/api/v3/byoc/provider/sender-id/{0}',
  byocProviderSenderIdCountIndex = '/api/v3/byoc/provider/sender-id/count/{0}',
  byocUserCarrierPhoneIndexForwarding = '/api/v3/byoc/user-carrier-phone/{0}/forwarding',

  // trial
  userTrialByType = '/api/v3/trial/user/{0}',

  // wizard
  wizardState = '/api/v3/wizard-new/state',
  wizardApply = '/api/v3/wizard-new/apply',

  wizardPrices = '/api/v3/wizard/sender-prices',

  // Tasks
  taskBoard = '/api/v3/task-boards',
  taskBoardSingle = '/api/v3/task-boards/{0}',
  taskBoardStages = '/api/v3/task-boards/{0}/stages',
  taskBoardReorder = '/api/v3/task-boards/{0}/reorder',
  taskBoardStagesSingle = '/api/v3/task-boards/stages/{0}',
  taskBoardStagesReorder = '/api/v3/task-boards/stages/{0}/reorder',
  tasks = '/api/v3/task-boards/{0}/tasks',
  tasksFetch = '/api/v3/task-boards/{0}/tasks/fetch',
  tasksSingle = '/api/v3/task-boards/tasks/{0}',
  tasksReorder = '/api/v3/task-boards/tasks/{0}/reorder',
  tasksMoveAll = '/api/v3/task-boards/stages/{0}/move-tasks',
  taskDuplicate = '/api/v3/task-boards/tasks/{0}/duplicate',
  taskArchive = '/api/v3/task-boards/{0}/archive',
  taskUnarchive = '/api/v3/task-boards/{0}/unarchive',
  taskGenerateAITitle = '/api/v3/task-boards/tasks/generate-title',

  // Task checklists
  taskChecklists = '/api/v3/task-boards/tasks/{0}/checklists',
  taskChecklistSingle = '/api/v3/task-boards/checklists/{0}',
  taskChecklistBulkMark = '/api/v3/task-boards/checklists/{0}/bulk-mark-items',
  taskChecklistItems = '/api/v3/task-boards/checklists/{0}/checklist-items',
  taskChecklistItemsSingle = '/api/v3/task-boards/checklist-items/{0}',

  // Task comment
  taskCommentCreate = '/api/v3/task-boards/tasks/{0}/comments',
  taskCommentDelete = '/api/v3/task-boards/comments/{0}',
  taskCommentFetch = '/api/v3/task-boards/tasks/{0}/comments/fetch',

  // Deals
  dealPipelines = '/api/v3/deal-pipelines',
  dealPipelineSingle = '/api/v3/deal-pipelines/{0}',
  dealPipelineReorder = '/api/v3/deal-pipelines/{0}/reorder',
  dealPipelineArchive = '/api/v3/deal-pipelines/{0}/archive',
  dealPipelineUnarchive = '/api/v3/deal-pipelines/{0}/unarchive',
  dealPipelineDefaultOwner = '/api/v3/deal-pipelines/{0}/change-assignee',
  dealPipelineStageSingle = '/api/v3/deal-pipelines/stages/{0}',
  dealPipelineStages = '/api/v3/deal-pipelines/{0}/stages',
  dealPipelineStagesReorder = '/api/v3/deal-pipelines/stages/{0}/reorder',
  deals = '/api/v3/deal-pipelines/{0}/deals',
  dealsFetch = '/api/v3/deal-pipelines/{0}/deals/fetch',
  dealsMove = '/api/v3/deal-pipelines/stages/{0}/move-deals',
  dealStageUpdate = '/api/v3/deal-pipelines/stages/custom/{0}',
  dealSystemStageUpdate = '/api/v3/deal-pipelines/stages/system/{0}',
  dealDuplicate = '/api/v3/deal-pipelines/deals/{0}/duplicate',
  dealSingle = '/api/v3/deal-pipelines/deals/{0}',
  dealTimelineItemCreate = '/api/v3/deal-pipelines/deals/{0}/timeline-items',
  dealTimelineItemDelete = '/api/v3/deal-pipelines/timeline-items/{0}',
  dealTimelineItemFetch = '/api/v3/deal-pipelines/deals/{0}/timeline-items/fetch',

  // Deal activities
  dealActivity = '/api/v3/deal-pipelines/deals/{0}/activity',
  dealActivitySingle = '/api/v3/deal-pipelines/activities/{0}',
  dealActivityItems = '/api/v3/deal-pipelines/activities/{0}/activity-items',
  dealActivityItem = '/api/v3/deal-pipelines/activity-items/{0}',
  dealActivityItemBulkMark = '/api/v3/deal-pipelines/activities/{0}/bulk-mark',

  // Navigation order
  loadNavigationOrder = '/api/v3/navigation/{0}/orders',
  updateNavigationOrder = '/api/v3/navigation/{0}/settings',

  // Team subscription
  teamSubscription = '/api/v3/team-subscription',
  teamSubscriptionPaymentLink = '/api/v3/team-subscription/payment-link',
  teamSubscriptionPrices = '/api/v3/team-subscription/prices/fetch',
  teamSubscriptionCustomerPortal = '/api/v3/team-subscription/customer-portal',
  teamSubscriptionInvoices = '/api/v3/team-subscription/invoices/fetch',

  // Email unsubscribe page
  emailUnsubscribeAction = '/api/v3/unsubscribe',
  emailUnsubscribeDataBase = '/api/v3/unsubscribe/{0}/{1}',
  emailUnsubscribeDataWithTemplate = '/api/v3/unsubscribe/{0}/{1}/{2}',
  emailUnsubscribeDataWithTemplateAndEmail = '/api/v3/unsubscribe/{0}/{1}/{2}/{3}',

  // Messages Settings
  webWidgets = '/api/v3/web-widgets/fetch',
  webWidgetSingle = '/api/v3/web-widgets/{0}',
  webWidgetCreate = '/api/v3/web-widgets',
  webWidgetDetailsUpdate = '/api/v3/web-widgets/{0}/settings',
  webWidgetsBulkDelete = '/api/v3/web-widgets/delete',
  webWidgetDuplicate = '/api/v3/web-widgets/{0}/clone',
  webWidgetSendInstructions = '/api/v3/web-widgets/{0}/email/instructions',

  // Stripe billing
  stripeBillingPaygPricesFetch = '/api/v3/billing/stripe/pay-as-you-go/prices/fetch',
  stripeBillingMonthlyPlanPricesFetch = '/api/v3/billing/stripe/monthly-billing/prices/fetch',
  stripeBillingMonthlyPlanChange = '/api/v3/billing/stripe/monthly-billing/change',
  stripeBillingDowngradeToPayg = '/api/v3/billing/stripe/monthly-billing/downgrade',
  stripeBillingPaygGenerateLink = '/api/v3/billing/stripe/pay-as-you-go/payment',
  stripeBillingGetInvoiceBySessionId = '/api/v3/billing/stripe/invoice/{0}',
  stripeBillingGenerateCustomerPortalLink = '/api/v3/billing/stripe/customer-portal',
  stripeBillingPaymentMethods = '/api/v3/billing/stripe/payment-methods/fetch',

  // centrifuge
  centrifugeToken = '/api/v3/centrifugo/tokens',

  // whatsapp
  whatsappConfig = '/api/v3/whatsapp/business/config',
  whatsappAccountFetch = '/api/v3/whatsapp/business/account/fetch',
  whatsappAccountSingle = '/api/v3/whatsapp/business/account/{0}',
  whatsappAccountCreate = '/api/v3/whatsapp/business/account',
  whatsappPhonesFetch = '/api/v3/whatsapp/business/platform/{0}/phones',
  whatsappPhoneSingle = '/api/v3/whatsapp/business/phone/{0}',

  // facebook
  facebookMessengerConnectPages = '/api/v3/facebook/messenger/connect-pages',
  facebookMessengerLoginConfig = '/api/v3/facebook/messenger/login-config',
  facebookMessengerPagesFetch = '/api/v3/facebook/messenger/pages/fetch',
  facebookMessengerPagesSingle = '/api/v3/facebook/messenger/pages/{0}',
  facebookMessengerPageByChat = '/api/v3/facebook/messenger/pages/by-chat/{0}',
  facebookMessengerPageByPlatformPageId = '/api/v3/facebook/messenger/pages/by-platform-page-id/{0}',

  // instagram
  instagramLoginLink = '/api/v3/instagram/login-link',
  instagramConnectAccount = '/api/v3/instagram/connect-account',
  instagramAccountsFetch = '/api/v3/instagram/accounts/fetch',
  instagramAccountSingle = '/api/v3/instagram/accounts/{0}',
  instagramAccountByPlatformId = '/api/v3/instagram/accounts/by-platform-account-id/{0}',

  // tickets
  ticketsFetch = '/api/v3/tickets',
  ticketsWithLasMessage = '/api/v3/tickets/with-last-message',
  ticketsSingle = '/api/v3/tickets/{0}',
  ticketsSingleByWorkspaceId = '/api/v3/tickets/workspace/{0}',
  ticketsMarkSpam = '/api/v3/tickets/{0}/mark-spam',
  ticketsUnmarkSpam = '/api/v3/tickets/{0}/unmark-spam',
  ticketsCount = '/api/v3/tickets/count',
  ticketsSimulateNew = '/api/v3/demo-tickets',
  ticketsFetchMessagesCount = '/api/v3/tickets/message-count',
  ticketsBulkDelete = '/api/v3/tickets/bulk-delete',
  ticketsBulkUpdate = '/api/v3/tickets/bulk-update',

  // ticket inboxes
  ticketInboxesFetch = '/api/v3/inboxes',
  ticketInboxesSingle = '/api/v3/inboxes/{0}',
  ticketInboxCreateWorkspace = '/api/v3/workspace',
  ticketInboxChangeVerificationStatus = '/api/v3/inboxes/{0}/change-verification-status',
  ticketInboxCheckForwarding = '/api/v3/inboxes/{0}/check-forwarding',

  // ticket statuses
  ticketStatusesFetch = '/api/v3/ticket-statuses',

  // ticket messages
  ticketMessagesFetch = '/api/v3/tickets/{0}/messages',
  ticketInternalNoteSingle = '/api/v3/ticket-messages/{0}',

  // ticket messages trimmed content
  ticketMessageTrimmedContent = '/api/v3/tickets/messages/{0}/show-trimmed-content',

  // ticket spam filter
  ticketSpamFilter = '/api/v3/spam-filters',

  // filtered views
  filteredViewsCreate = '/api/v3/filtered-views',
  filteredViewsIndex = '/api/v3/filtered-views/{0}',
  filteredViewsFetch = '/api/v3/filtered-views/{0}/fetch',
  filteredViewsBulkDelete = '/api/v3/filtered-views/{0}/bulk-delete',
  filteredViewsCounters = '/api/v3/filtered-views/{0}/counters',
  filteredViewsIndexReorder = '/api/v3/filtered-views/{0}/reorder',
  filteredViewsContainsEntry = '/api/v3/filtered-views/{0}/contains/{1}',

  // image
  imageFormUpload = '/api/v3/image/form/upload',

  // upload files
  uploadFile = '/api/v3/files',
  attachFiles = '/api/v3/files/attach',

  // uploaded files list
  files = '/api/v3/files/fetch',

  // download files
  downloadFile = '/api/v3/files/{0}/{1}/download', // /api/v3/files/{id}/{entityType}/download

  // detach file
  detachFile = '/api/v3/files/detach',

  channelsPresence = '/api/v3/channels/presence',

  // old endpoints
  schemaFilters = '/api/schema/filters',

  loginHistoryExport = '/api/login-history/export',

  // web widget public api
  widgetChatTokens = '/api/webwidgets/chat/tokens/{0}', // {0} - chat token

  // Tests
  baseTest = '/api/test',
  baseTestSingle = '/api/test/{0}',
  childTest = '/api/child/test',

  // Campaigns
  smsCampaignsFetch = '/api/v3/sms-campaigns/fetch',

  emailCampaigns = '/api/v3/email-campaigns',
  emailCampaignsFetch = '/api/v3/email-campaigns/fetch',
  emailCampaignsIndex = '/api/v3/email-campaigns/{0}',
  emailCampaignsSingleIndex = '/api/v3/email-campaigns/{0}/emails/{1}',
  emailCampaignsArchive = '/api/v3/email-campaigns/archive',
  emailCampaignsRecipients = '/api/v3/email-campaigns/{0}/recipients',

  emailCampaignsMessages = '/api/v3/email-campaigns/{0}/emails/fetch',
  emailCampaignsOpen = '/api/v3/email-campaigns/{0}/open/emails/fetch',
  emailCampaignsUnsubscribe = '/api/v3/email-campaigns/{0}/unsubscribe/emails/fetch',
  emailCampaignsSpamReport = '/api/v3/email-campaigns/{0}/spam-report/emails/fetch',
  emailCampaignsClickSummary = '/api/v3/email-campaigns/{0}/click/emails/summary',
  emailCampaignsClick = '/api/v3/email-campaigns/{0}/click/emails/fetch',
  emailCampaignsExport = '/api/v3/email-campaigns/{0}/engagement/emails/export',
  emailCampaignsClickSummaryExport = '/api/v3/email-campaigns/{0}/engagement/click/summary/export',

  emailCampaignsEmailSenders = '/api/v3/email-campaigns/email-senders',
  emailCampaignsEmailSendersIndex = '/api/v3/email-campaigns/email-senders/{0}',
  emailCampaignsEmailSendersIndexResendConfirmation = '/api/v3/email-campaigns/email-senders/{0}/resend-confirmation',
  emailCampaignsEmailSendersIndexVerify = '/api/v3/email-campaigns/email-senders/{0}/verify',

  // Email campaigns domains
  emailCampaignsDomain = '/api/v3/email-campaigns/domains/{0}',
  emailCampaignsDomains = '/api/v3/email-campaigns/domains',
  emailCampaignsDomainsIndexBulkEmailSenders = '/api/v3/email-campaigns/domains/{0}/bulk/email-senders',
  emailCampaignsDomainsIndexEmailSenders = '/api/v3/email-campaigns/domains/{0}/email-senders',
  emailCampaignsDomainsIndexSendInstructions = '/api/v3/email-campaigns/domains/{0}/send-instructions',
  emailCampaignsDomainsIndexVerifyDkim = '/api/v3/email-campaigns/domains/{0}/verify-dkim',
  emailCampaignsDomainsIndexVerifyReturnPath = '/api/v3/email-campaigns/domains/{0}/verify-return-path',
}

export type Endpoint = keyof typeof EndpointsEnum
export type EndpointParams = Array<string>

import type { RouteRecordRaw } from 'vue-router'
import { getSenderSettingsRoutes } from '@/routes/user/services/senderSettings'
import { getEmailToSmsRoutes } from './services/emailToSms'
import { getApiRoutes } from './services/api'
import { getAutomationRulesRoutes } from './services/automationRules'
import { getFormsRoutes } from './services/forms'
import { getCarrierLookupRoutes } from './services/carrierLookup'
import { getEmailLookupRoutes } from './services/emailLookup'
import { getByocRoutes } from './services/byoc'
import { getSmsSurveysRoutes } from '@/routes/user/services/smsSurveys'
import { getEmailToSmsDetailsRoutes } from './services/emailToSmsDetails'
import { getAutomationRuleDetailsRoutes } from './services/automationRuleDetails'
import { getFormsDetailsRoutes } from './services/formsDetails'
import { getEmailLookupDetailsRoutes } from './services/emailLookupDetails'
import { getCarrierLookupDetailsRoutes } from './services/carrierLookupDetails'
import { getDownloadsRoutes } from './services/downloads'
import { getByocDetailsRoutes } from './services/byocDetails'
import { ServicesRouteName } from '@/routes/types'
import { getPointAIRoutes } from '@/routes/user/services/pointAi'
import { SenderSettingsRoutes } from '@/routes/user/services/types'
import { getSmsSurveysBuilderRoutes } from '@/routes/user/services/smsSurveysDetails'

export const getServicesRoutes = (): RouteRecordRaw[] => [
  {
    name: ServicesRouteName,
    path: 'services',
    meta: {
      tags: ['USER_TOP_MENU'],
      menu: () => import('@/components/domain/services/ServicesMenu.vue'),
    },
    component: () => import('@/components/views/services/Services.vue'),
    children: [
      ...getSenderSettingsRoutes(),
      ...getByocRoutes(),
      ...getEmailToSmsRoutes(),
      ...getApiRoutes(),
      ...getAutomationRulesRoutes(),
      ...getSmsSurveysRoutes(),
      ...getFormsRoutes(),
      ...getFormsDetailsRoutes(),
      ...getDownloadsRoutes(),
      ...getCarrierLookupRoutes(),
      ...getEmailLookupRoutes(),
      ...getPointAIRoutes(),
      ...getByocDetailsRoutes(),
      ...getAutomationRuleDetailsRoutes(),
      ...getEmailToSmsDetailsRoutes(),
      ...getEmailLookupDetailsRoutes(),
      ...getCarrierLookupDetailsRoutes(),
    ],
    redirect: { name: SenderSettingsRoutes.numbers },
  },
  ...getSmsSurveysBuilderRoutes(),
]

import { inject, injectable } from 'inversify'
import type { Integration, ErrorEvent as SentryErrorEvent, IntegrationFnResult } from '@sentry/types'
import type { Options, TracingOptions } from '@sentry/vue/types/types'
import type { EventHint, SeverityLevel } from '@sentry/vue'
import type { Router } from 'vue-router'
// eslint-disable-next-line tp/forbid-import-composable-to-service,tp/using-vue-in-services-restriction
import type { App } from '@/composition/vue/compositionApi'
import { LogLevel } from '@/core/logger/types'
import type {
  HttpClientOptions,
  ISentry,
  MonitoringServiceInterface,
  VueRouterInstrumationOptions,
} from '@/services/monitoring/types'
import type { Config } from '@/core/types'
import { SERVICE_TYPES } from '@/core/container/types'
import TmAbsurdError from '@/core/error/tmAbsurdError'
import type { Dict } from '@/types'
import { TmBaseError } from '@/core/error/tmBaseError'
import { getTranslateService } from '@/core/container/ioc'
import { getSafariVersion, isMobile } from '@/utils/isMobile'
import type User from '@/data/models/domain/User'
import type { MicroSentryMonitoringService } from '@/services/monitoring/microSentryMonitoringService'

@injectable()
export default class SentryMonitoringService implements MonitoringServiceInterface {
  private Sentry: ISentry

  private httpClientIntegration: (options?: Partial<HttpClientOptions> | undefined) => IntegrationFnResult

  private user: User | null

  private isInited = false

  private ignoreErrors = [
    'ResizeObserver loop limit exceeded', // We can safely ignore this error: see https://github.com/WICG/resize-observer/issues/38 for details
    'ResizeObserver loop completed with undelivered notifications.', // A variant of the above error, see https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
    'Non-Error promise rejection captured', // https://github.com/getsentry/sentry-javascript/issues/3440
  ]

  constructor(
    @inject(SERVICE_TYPES.Config) protected readonly config: Config,
    @inject(SERVICE_TYPES.MicroSentryMonitoringService)
    protected readonly microSentryService: MicroSentryMonitoringService,
  ) {}

  public init(app: App, router?: Router): void {
    if (this.isInited) {
      return
    }
    // This is a temporary micro-client (~30KB) while the main client (Sentry, ~700KB) is loaded dynamically
    this.microSentryService.init(app)

    requestIdleCallback(() => {
      Promise.all([import('@sentry/vue'), import('@sentry/integrations')]).then(([sentry, intgr]) => {
        this.Sentry = sentry
        this.httpClientIntegration = intgr.httpClientIntegration
        this.microSentryService.destroy()
        const integrations = this.getIntegrations(router)
        this.initSentry(app, integrations)
        this.isInited = true
        this.customizeWidgetButton()
        this.setUser(this.user)

        this.addTracingMixin(app)
        if (router) {
          this.attachRouter(app, router)
        }
      })
    })
  }

  public setUser(user: User | null) {
    this.user = user
    if (this.Sentry) {
      this.Sentry.setUser(user)
    } else {
      this.microSentryService.setUser(user)
    }
  }

  public logInfo(message: string, logLevel: LogLevel = LogLevel.INFO, data?: Dict) {
    if (logLevel === LogLevel.NULL) return
    if (this.Sentry) {
      this.Sentry.captureMessage(message, {
        level: this.mapLogLevelToSentryLogLevel(logLevel),
        extra: data,
      })
    } else {
      this.microSentryService.logInfo(message, logLevel)
    }
  }

  public logError(error: Error, logLevel: LogLevel = LogLevel.ERROR, data?: Dict) {
    if (logLevel === LogLevel.NULL) return
    if (this.Sentry) {
      this.Sentry.captureException(error, {
        level: this.mapLogLevelToSentryLogLevel(logLevel),
        extra: data,
      })
    } else {
      this.microSentryService.logError(error, logLevel)
    }
  }

  public destroy() {
    if (this.Sentry) {
      this.Sentry.close()
    } else {
      this.microSentryService.destroy()
    }
  }

  public toggleWidgetButton(display: boolean) {
    const element = this.getWidgetButtonElement()
    if (!element) return
    element.style.display = display ? 'flex' : 'none'
  }

  private getIntegrations(router?: Router) {
    const integrations: Integration[] = []

    integrations.push(
      this.Sentry.breadcrumbsIntegration({
        // This integration is producing a TON of warnings
        console: false,
      }),
    )

    if (router) {
      const browserTracing = this.traceBrowser(router, { routeLabel: 'name' })
      integrations.push(browserTracing)
    }

    integrations.push(
      this.httpClientIntegration({
        // This array can contain tuples of `[begin, end]` (both inclusive),
        // single status codes, or a combination of both.
        // default: [[500, 599]]
        failedRequestStatusCodes: [[500, 599]],
        failedRequestTargets: [/.*/], // default value but required in type
      }),
    )

    // We need to check env variables directly for proper tree-shaking
    // See https://bitbucket.org/textmagicadmin/tp-frontend/pull-requests/2701 for details
    if (import.meta.env.VUE_APP_SENTRY_ENABLE_REPLAY === 'true') {
      integrations.push(
        this.Sentry.replayIntegration({
          mask: this.config.sentry.maskSelectors,
          unmask: this.config.sentry.unMaskSelectors,
        }),
      )
    }

    // not injected from the constructor due to the use of this service in the click and feedback widgets
    const translateService = getTranslateService()

    if (!isMobile()) {
      integrations.push(
        this.Sentry.feedbackIntegration({
          colorScheme: 'light',
          showBranding: false,
          showEmail: true,
          buttonLabel: translateService.t('sentry.feedback'),
          emailLabel: translateService.t('sentry.emailLabel'),
          showName: false,
          submitButtonLabel: translateService.t('sentry.sendFeedback'),
          formTitle: translateService.t('sentry.feedbackAndIdeas'),
          messagePlaceholder: translateService.t('sentry.messagePlaceholder'),
          themeLight: {
            submitBackground: '#008CFF',
            submitBackgroundHover: '#008CFF',
            fontFamily: 'inherit',
          },
        }),
      )
    }

    return integrations
  }

  private initSentry(app: App, integrations: Integration[]) {
    this.Sentry.init({
      integrations,
      debug: import.meta.env.VUE_APP_SENTRY_DEBUG === 'true',
      app: [app],
      dsn: this.config.sentry.dsn,
      environment: APP_BUILD_ENV,
      release: [APP_NAME, APP_VERSION].join('@'),

      // Enabling this feature provides you with spans in your transactions that represent the component life cycle events and durations.
      // https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking
      trackComponents: false,
      timeout: this.config.sentry.timeout,

      // Capture Replay for 0% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 1.0,

      // This option is required for capturing headers and cookies.
      sendDefaultPii: false,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5,

      // temporary increasing sampleRate for investigation a Web Vitals feature
      sampleRate: 0.3,

      logErrors: true,

      ignoreErrors: this.ignoreErrors,

      beforeSend: (event: SentryErrorEvent, hint: EventHint) => {
        if (
          hint.originalException &&
          hint.originalException instanceof TmBaseError &&
          !hint.originalException.shouldBeMonitored()
        ) {
          // Will NOT be logged into Sentry

          // TODO: Add logging to the app logger
          // this.loggerService.log('warn', `An "${hint.originalException.name}" error was occurred. It was not sent to Sentry because it is marked as non-monitored.`)
          return null
        }

        // Will be logged to Sentry
        return event
      },

      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
    })
  }

  private addTracingMixin(app: App) {
    app.mixin(
      this.Sentry.createTracingMixins({ trackComponents: false } satisfies Partial<TracingOptions> as TracingOptions),
    )
  }

  private attachRouter(app: App, router: Router) {
    this.Sentry.attachErrorHandler(app, {
      integrations: (defaultIntegrations) => [
        ...defaultIntegrations.filter(({ name }) => name !== 'BrowserTracing'),
        this.traceBrowser(router),
      ],
    } satisfies Partial<Options> as Options)
  }

  private traceBrowser(router: Router, options?: VueRouterInstrumationOptions) {
    return this.Sentry.browserTracingIntegration({
      tracePropagationTargets: ['localhost', this.config.appUrl],
      router,
      routeLabel: options?.routeLabel,
      enableInp: true,
    })
  }

  private mapLogLevelToSentryLogLevel(logLevel: Exclude<LogLevel, LogLevel.NULL>): SeverityLevel {
    switch (logLevel) {
      case LogLevel.DEBUG:
        return 'debug'
      case LogLevel.LOG:
        return 'log'
      case LogLevel.WARNING:
        return 'warning'
      case LogLevel.ERROR:
        return 'error'
      case LogLevel.INFO:
        return 'info'
      default:
        throw new TmAbsurdError(logLevel)
    }
  }

  private customizeWidgetButton() {
    const host = this.getWidgetButtonElement()
    if (!host) return
    host.classList.add('no-print')
    const widthThreshold = 1000
    const ver = getSafariVersion()
    const ua = navigator.userAgent.toLowerCase()
    if ((ua.indexOf('safari/') > -1 && ver && ver < '15.6') || ua.indexOf('firefox/') > -1) {
      // for old Safari & Firefox
      window.addEventListener('resize', () => {
        const display = document.body.offsetWidth > widthThreshold
        this.toggleWidgetButton(display)
      })
    }
    try {
      const styleSheet = this.getStyleSheetForWidgetButton(host)
      if (!styleSheet) return
      this.setRulesForWidgetButton(styleSheet, widthThreshold)
    } catch {
      /* CAR-6039 */
    }
    this.toggleWidgetButton(false)
  }

  private setRulesForWidgetButton(styleSheet: CSSStyleSheet, widthThreshold: number) {
    const rules = [
      `.feedback-icon {
          display: none !important;
      }`,
      `.widget__actor {
        @media screen and (max-width: ${widthThreshold}px) {
          display: none !important;
        }
        display: flex;
        justify-content: center;
        position: fixed !important;
        top: 50% !important;
        bottom: auto !important;
        left: auto !important;
        right: 0 !important;
        padding: 6px 12px !important;
        transform: translateY(-50%) translateX(50%) rotate(-90deg);
        transform-origin: bottom center;
        color: #fff !important;
        background-color: #D23201 !important;
        border-radius: 4px 4px 0 0 !important;
        transition: all 0.3s ease;
      }`,
      `.widget__actor:hover {
        color: #2b2233 !important;
        background-color: #f6f6f7 !important;
      }`,
      `.widget__actor__text {
        font-size: 13px;
        line-height: normal;
        word-break: normal !important;
        word-wrap: normal !important;
        white-space: nowrap !important;
        font-weight: 400;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
        -webkit-font-smoothing: auto;
      }`,
      `.form {
        display: flex;
        flex-direction: column-reverse;
      }`,
      `.form label {
        order: 1;
      }`,
      `.form btn-group {
        order: 0;
      }`,
    ]
    rules.forEach((rule) => styleSheet?.insertRule(rule))
  }

  private getStyleSheetForWidgetButton(host: Element) {
    const styleSheets = host.shadowRoot?.styleSheets
    if (!styleSheets) return null
    return styleSheets.item(styleSheets.length - 1)
  }

  private getWidgetButtonElement(): HTMLElement | null {
    return document.querySelector('#sentry-feedback')
  }
}

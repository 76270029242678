import { inject, injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import type TenDlcPhoneNumbersRepository from '@/data/repositories/domain/services/regulations/tenDlcPhoneNumbersRepository'
import DomainBaseService from '@/services/domain/domainBaseService'
import TenDlcPhoneNumber from '@/data/models/domain/services/regulations/TenDlcPhoneNumber'
import { getStatusColor, type MapStatusColor } from '@/utils/status'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type TranslateService from '@/services/translateService'
import type { StatusCellProps } from '@/services/domain/types'
import { TenDlcPhoneNumberStatus } from '@/services/domain/services/regulations/tenDlcPhoneNumbers/types'
import type PhoneNumbersService from '@/services/domain/phoneNumbers/phoneNumbersService'
import { ModelEventType } from '@/services/transport/types'
import { TenDlcTwilioRequestStatus } from '@/services/domain/services/regulations/tenDlcRequest/types'

@DomainSettings({
  model: TenDlcPhoneNumber,
})
@injectable()
export default class TenDlcPhoneNumbersService extends DomainBaseService<TenDlcPhoneNumbersRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    @inject(SERVICE_TYPES.PhoneNumbersService) protected readonly phoneNumbersService: PhoneNumbersService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  /** @deprecated */
  public getStatus(status: TenDlcPhoneNumberStatus): StatusCellProps {
    const labelMap: Record<TenDlcPhoneNumberStatus, string> = {
      [TenDlcPhoneNumberStatus.APPROVED]: this.translateService.t('status.tenDlcPhoneNumber.approved'),
      [TenDlcPhoneNumberStatus.PENDING]: this.translateService.t('status.tenDlcPhoneNumber.pending'),
      [TenDlcPhoneNumberStatus.DECLINED]: this.translateService.t('status.tenDlcPhoneNumber.declined'),
    }
    const colorMap: MapStatusColor<TenDlcPhoneNumberStatus> = {
      green: [TenDlcPhoneNumberStatus.APPROVED],
      blue: [TenDlcPhoneNumberStatus.PENDING],
      error: [TenDlcPhoneNumberStatus.DECLINED],
    }

    return {
      value: labelMap[status] || '',
      color: getStatusColor<TenDlcPhoneNumberStatus>(colorMap, status),
    }
  }

  public async linkToCampaign(id: string, campaignId: string, userInboundId: string): Promise<void> {
    await this.getDomainRepository().linkToCampaign({ campaignId, userInboundId })
    this.notify([id], ModelEventType.UPDATE)
  }

  public getFirstRequestId() {
    const number = this.getDomainRepository()
      .query()
      .where((raw) => raw.requestId !== null)
      .first()
    return number?.requestId || null
  }

  public async restoreNumber(id: string) {
    await this.phoneNumbersService.restoreNumber(id)
    this.notify([id], ModelEventType.UPDATE)
  }

  public async cancelNumber(id: string) {
    await this.phoneNumbersService.cancelNumber(id)
    this.notify([id], ModelEventType.UPDATE)
  }

  public async hasApprovedNumber() {
    const { items } = await this.getDomainRepository().getNumbersByStatus(TenDlcTwilioRequestStatus.verified)
    return !!items.length
  }

  public async getNumbersByCampaignId(campaignId: string): Promise<TenDlcPhoneNumber[]> {
    return this.getDomainRepository().getNumbersByCampaignId(campaignId)
  }

  public findNumbersByCampaignId(campaignId: string): TenDlcPhoneNumber[] {
    return this.getDomainRepository().findNumbersByCampaignId(campaignId)
  }
}

import { format } from 'date-fns-tz'
import { DATE_FORMATES } from '@/services/types'

export const getTimezoneOffset = (timezone: string) => {
  try {
    return format(new Date(), DATE_FORMATES.TIMEZONE_OFFSET_FORMAT, { timeZone: timezone })
  } catch (error) {
    /**
     * Note: In older browsers, the specified timezone may not be recognized by the Intl.DateTimeFormat method.
     * A current list of timezones supported can be found at https://en.wikipedia.org/wiki/List_of_tz_database_time_zones.
     * If necessary, check this list to find an appropriate alternative timezone and update the link accordingly.
     */
    if (timezone === 'Australia/LHI') {
      return format(new Date(), DATE_FORMATES.TIMEZONE_OFFSET_FORMAT, { timeZone: 'Australia/Lord_Howe' })
    }

    throw error
  }
}

import { inject, injectable } from 'inversify'
import { onCLS, onFCP, onINP, onLCP, onTTFB, type Metric } from 'web-vitals'
import { getUuid } from '@/utils/uuid'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Config } from '@/core/types'
import type { ILogger } from '@/services/types'
import type { PerformanceMarkService } from '@/services/performanceMarkService'

type MetricName = Exclude<Metric['name'], 'FID'>

/**
 * This service is using modified parts of the https://github.com/treosh/web-vitals-reporter library
 */
@injectable()
export class TelemetryService {
  private readonly endpoint: string

  private isSent = false

  private metrics: Record<MetricName, number | null> = {
    CLS: null,
    FCP: null,
    LCP: null,
    TTFB: null,
    INP: null,
  }

  constructor(
    @inject(SERVICE_TYPES.PerformanceMarkService) protected readonly performanceMarkService: PerformanceMarkService,
    @inject(SERVICE_TYPES.Config) protected readonly config: Config,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: ILogger,
  ) {
    this.endpoint = this.config.telemetryUrl
  }

  public init() {
    this.subscribeToWebVitals()
  }

  protected collectMetric(metric: Metric) {
    this.metrics[metric.name] = this.round(metric.value, metric.name === 'CLS' ? 4 : 0)

    // This will send the metrics to backend when last metric was collected
    this.sendTelemetryIfAllMetricsAreCollected()
  }

  protected sendTelemetryIfAllMetricsAreCollected() {
    if (Object.values(this.metrics).every((value) => value !== null)) {
      this.sendTelemetry({
        id: getUuid(),
        duration: this.getTimeFromSessionStart(),
        ...this.getDeviceInfo(),
        ...this.metrics,
      })
    }
  }

  protected sendTelemetry(payload: Record<string, unknown>) {
    if (this.isSent) {
      // data is already sent
      return
    }

    this.loggerService.log('telemetry', 'Sending telemetry data', 'TelemetryService.sendTelemetry')
    this.loggerService.raw('telemetry', payload)
    if (typeof navigator === 'undefined') {
      return
    }

    if (typeof navigator.sendBeacon === 'function') {
      navigator.sendBeacon(this.endpoint, JSON.stringify(payload))
    } else {
      const client = new XMLHttpRequest()
      client.open('POST', this.endpoint, false) // third parameter indicates sync xhr
      client.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
      client.send(JSON.stringify(payload))
    }

    this.isSent = true
  }

  protected getDeviceInfo(): Record<string, unknown> {
    return {
      url: window.location?.href ?? null,
      userAgent: navigator?.userAgent ?? null,
      memory: navigator?.deviceMemory ?? undefined,
      cpus: navigator?.hardwareConcurrency ?? undefined,
      connection: navigator?.connection
        ? {
            effectiveType: navigator.connection.effectiveType,
            rtt: navigator.connection.rtt,
            downlink: navigator.connection.downlink,
          }
        : undefined,
    }
  }

  protected subscribeToWebVitals() {
    onCLS((metric) => {
      this.logMetric(metric)
      this.collectMetric(metric)
    })

    onTTFB((metric) => {
      this.logMetric(metric)
      this.collectMetric(metric)
    })

    onFCP((metric) => {
      this.logMetric(metric)
      this.collectMetric(metric)
    })

    onLCP((metric) => {
      this.logMetric(metric)
      this.collectMetric(metric)
    })

    onINP((metric) => {
      this.logMetric(metric)
      this.collectMetric(metric)
    })
  }

  private getTimeFromSessionStart() {
    return this.round(this.performanceMarkService.now())
  }

  private round(val: number, precision: number = 0): number {
    return +(Math.round(`${val}e+${precision}` as unknown as number) + `e-${precision}`)
  }

  private logMetric(metric: Metric): void {
    this.loggerService.log(
      'telemetry',
      `${metric.name}: rating: ${metric.rating}; value: ${metric.value}; delta: ${metric.delta}`,
      'TelemetryService.logMetric',
    )
  }
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import Search from '@/data/models/tables/Search'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'

@RepoSettings<Endpoint>({
  model: Search,
})
@injectable()
export default class SearchRepository extends OrmApiRepository<Search> {
  public init(id: string, searchFields: string[]) {
    this.lowLevelInsert([{ id, searchFields }])
  }

  public setValue(id: string, value: string) {
    const data = this.findEntityByIdOrFail(id)
    data.history.push(value)
    data.value = value
    this.lowLevelUpdate([data])
  }

  public getValue(id: string, defaultValue = '') {
    const data = this.findEntityByIdOrNull(id)
    if (!data) {
      return defaultValue
    }
    return data.value || defaultValue
  }

  public getSearchFields(id: string, defaultValue = []) {
    const data = this.findEntityByIdOrNull(id)
    if (!data) {
      return defaultValue
    }
    return data.searchFields
  }

  public getHistory(id: string) {
    const data = this.findEntityByIdOrNull(id)
    if (!data) {
      return []
    }
    return data.history
  }
}

import { AttrField, HasManyByField, OrmModel, UidField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import Contact from '@/data/models/domain/Contact'
import ContactList from '@/data/models/domain/ContactList'
import FilteredView from '@/data/models/domain/filteredViews/FilteredView'

@OrmModel(modelNames.SESSION_RECIPIENTS)
export default class SessionRecipients extends BaseModel {
  @UidField()
  public id: string

  @AttrField([])
  public contactIds: string[]

  @HasManyByField(Contact, 'contactIds')
  public contacts: Contact[]

  @AttrField([])
  public groupIds: string[]

  @HasManyByField(ContactList, 'groupIds')
  public groups: ContactList[]

  @AttrField([])
  public filteredViewIds: string[]

  @HasManyByField(FilteredView, 'filteredViewIds')
  public filteredViews: FilteredView[]

  @AttrField([])
  public numbers: string[]
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

export enum TYPING_SERVICES {
  TypingRepository,
  TypingService,
  TypingResolver,
}

export type TypingServices = keyof typeof TYPING_SERVICES

export const TYPING_SERVICE_TYPES: Record<TypingServices, symbol> = makeServicesTypes<TypingServices>(
  enumKeysToArray<TypingServices>(TYPING_SERVICES),
)

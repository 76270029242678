import { inject, injectable } from 'inversify'
import type { Storable } from '@/services/transport/storage/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { LocalStorageServiceInterface } from '@/services/storage/types'

@injectable()
export default class LocalStorageAdapter implements Storable {
  constructor(
    @inject(SERVICE_TYPES.LocalStorageService) protected readonly localStorageService: LocalStorageServiceInterface,
  ) {}

  public get(key: string) {
    return this.localStorageService.get(key)
  }

  public set(key: string, value: string) {
    this.localStorageService.set(key, value)
  }

  public delete(key: string) {
    this.localStorageService.delete(key)
  }

  public getJSON(key: string) {
    return this.localStorageService.getJSON(key)
  }

  public setJSON(key: string, value: Record<string, any>) {
    this.localStorageService.setJSON(key, value)
  }
}

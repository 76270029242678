import { injectable } from 'inversify'
import type { TFilterRange, TRangeFilterValueString } from '@/services/filters/types'
import { RANGE_DELIMITER } from '@/services/filters/types'
import type { Operation } from '@/services/types'
import { TmTableFilterError } from '@/core/error/table/tmTableFilterError'

@injectable()
export class RangeFilterService {
  public toRangeString(range: TFilterRange): TRangeFilterValueString {
    const serializedRange = [range.startOperation, range.start, range.endOperation, range.end].map(
      (value) => value || '',
    )
    return serializedRange.join(RANGE_DELIMITER) as TRangeFilterValueString
  }

  public fromRangeString(range: string): TFilterRange {
    const split = range.split(RANGE_DELIMITER)
    if (split.length === 4) {
      return {
        startOperation: split[0] as Operation,
        start: split[1],
        endOperation: split[2] as Operation,
        end: split[3],
      }
    }

    throw new TmTableFilterError(`String "${range}" is an incorrect range value`)
  }
}

<template>
  <teleport to="body">
    <tm-tooltip-wrapper
      v-for="tooltip in tooltips"
      :id="tooltip.id"
      :key="tooltip.id"
      :data="tooltip.tooltipData"
      :anchor-el="tooltip.anchorEl"
    />
  </teleport>
</template>

<script setup lang="ts">
import TmTooltipWrapper from '@/components/shared/tooltip/TmTooltipWrapper.vue'
import { onMounted, onUnmounted } from '@/composition/vue/compositionApi'
import { getAnchorTooltipElement } from '@/composition/tooltip/positionEngineTooltip'
import { tooltipDataMap } from '@/directives/tooltip.directive'
import { useTooltipManager } from '@/composition/tooltip/useTooltipManager'
import { tooltipWrapperClassName } from '@/components/shared/tooltip/types'

const { tooltips, addTooltip } = useTooltipManager()

const handleMouseEnter = (event: MouseEvent) => {
  const target = event.target as HTMLElement
  const anchorEl = getAnchorTooltipElement(target)
  if (!anchorEl) return

  const relatedTarget = event.relatedTarget as HTMLElement
  if (relatedTarget?.closest(`.${tooltipWrapperClassName}`)) {
    return
  }

  const tooltipData = tooltipDataMap.get(anchorEl)
  if (!tooltipData || tooltipData.disabled || tooltips.value[anchorEl.dataset.tooltip as string]) return

  const newTooltip = {
    id: anchorEl.dataset.tooltip as string,
    anchorEl,
    tooltipData,
  }
  addTooltip(newTooltip.id, newTooltip)
}

onMounted(() => {
  document.body.addEventListener('mouseover', handleMouseEnter)
})

onUnmounted(() => {
  document.body.removeEventListener('mouseover', handleMouseEnter)
})
</script>

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/container'
import type { Resolvable } from '@/services/resolvers/types'
import type TeamSubscriptionService from '@/services/domain/accounts/teamSubscriptionService'
import type WindowService from '@/services/browser/windowService'
import type { TmRoutes } from '@/services/route/routerTypes'
import type RouterService from '@/services/route/routerService'

@injectable()
export default class SubscriptionBillingSettingsResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.TeamSubscriptionService)
    private readonly teamSubscriptionService: TeamSubscriptionService,
    @inject(SERVICE_TYPES.WindowService)
    private readonly windowService: WindowService,
    @inject(SERVICE_TYPES.RouterService)
    private readonly routerService: RouterService,
  ) {}

  public async resolve() {
    const billingRoute: TmRoutes = {
      name: 'user.accounts.billingOverview',
    }
    const billingResolvedRoute = this.routerService.resolve(billingRoute)
    const returnUrl = `${this.windowService.getLocation().origin}${billingResolvedRoute.href}`
    const url = await this.teamSubscriptionService.getCustomerPortalLink(returnUrl)
    this.windowService.self().location.href = url
  }
}

import { AppModule } from '@/config/types'
import TemplateCategory from '@/data/models/domain/TemplateCategory'
import type { NotFoundResolverParams } from '@/services/resolvers/types'
import type { TmNamedRouteRecord } from '@/services/route/types'

const CATEGORY_ID_ROUTE_PARAM = 'categoryId' as const

export const campaignsTemplatesAllRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.templates.all',
  path: 'all',
  component: () => import('@/components/views/campaigns/templates/CampaignsTemplatesAll.vue'),
  meta: {
    tabTitle: 'pageTabTitle.user.templates',
  },
}

export const campaignsTemplatesCategoryRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.templates.category',
  path: `categories/:${CATEGORY_ID_ROUTE_PARAM}`,
  props: (route) => ({
    categoryId: route.params[CATEGORY_ID_ROUTE_PARAM],
  }),
  component: () => import('@/components/views/campaigns/templates/CampaignsTemplatesCategory.vue'),
  meta: {
    tabTitle: 'pageTabTitle.user.templates',
    resolvers: [
      {
        service: 'NonBlockedRedirectableSingleModelResolverService',
        params: {
          requestParam: CATEGORY_ID_ROUTE_PARAM,
          model: TemplateCategory,
          source: 'router',
          redirectToIfNotFound: { name: 'user.templates' },
        } as NotFoundResolverParams,
      },
    ],
  },
}

export const campaignsTemplatesRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.templates',
  path: 'templates',
  props: (route) => ({
    categoryId: route.params[CATEGORY_ID_ROUTE_PARAM],
  }),
  children: [campaignsTemplatesAllRoute, campaignsTemplatesCategoryRoute],
  redirect: { name: 'user.templates.all' },
  meta: {
    modules: [AppModule.Templates],
  },
}

import { isFunction, omitBy } from 'lodash-es'
import { computed, ref, useAttrs, type ComputedRef } from '@/composition/vue/compositionApi'
import { DefaultInputEvent } from '@/services/forms/types'
import type { SetupContext } from '@/composition/types'
import type { Callback } from '@/types'

export type UseFieldResult<V> = {
  propsAndAttrs: ComputedRef<Record<string, any>>
  emitInput: Callback<void, [V]>
  emitChange: Callback<void, [V]>
  emitChangeAndInput: Callback<void, [V]>
}

type UseFieldFunc = {
  <V>(props: Record<string | number, unknown>, context: SetupContext, pullKeys?: string[]): UseFieldResult<V>
  <V>(props: Record<string | number, unknown>, emit: Callback<any>, pullKeys?: string[]): UseFieldResult<V>
}

const useFieldLegacy = <V>(
  props: Record<string | number, unknown>,
  context: SetupContext,
  pullKeys: string[] = [],
): UseFieldResult<V> => {
  const propsAndAttrs = computed(() => {
    // disable error state on quasar component, remove size prop
    const { error, errors, errorMessages, invalid, valid, validators, hint, size, ...otherProps } = props

    const keysSet = new Set(pullKeys)

    const filteredProps = omitBy(otherProps, (value, key) => keysSet.has(key))
    const filteredAttrs = omitBy(context.attrs, (value, key) => keysSet.has(key))

    return { ...filteredAttrs, ...filteredProps }
  })

  const emitInput = (value: V) => {
    context.emit(DefaultInputEvent, value)
  }

  const emitChange = (value: V) => {
    context.emit('change', value)
  }

  const emitChangeAndInput = (value: V) => {
    emitInput(value)
    emitChange(value)
  }

  return {
    propsAndAttrs,
    emitInput,
    emitChange,
    emitChangeAndInput,
  }
}

export const useField: UseFieldFunc = <V>(
  props: Record<string | number, unknown>,
  contextOrEmit: SetupContext | Callback<any>,
  pullKeys: string[] = [],
): UseFieldResult<V> => {
  if (!isFunction(contextOrEmit)) {
    return useFieldLegacy<V>(props, contextOrEmit, pullKeys)
  }

  const context: SetupContext = { emit: contextOrEmit, attrs: useAttrs() }
  return useFieldLegacy<V>(props, context, pullKeys)
}

export const useRadio = (initialValue: string) => {
  const radioValue = ref(initialValue)
  return {
    onInput: (v: string) => {
      radioValue.value = v
    },
    radioValue,
  }
}

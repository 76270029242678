import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  // Domain services
  ScheduledNotSentService,

  // Repositories
  ScheduledNotSentRepository,

  // Resolvers
  ScheduledNotSentWebsocketResolverService,

  // Tables - columns
  ScheduledNotSentColumnServiceFactory,
}

export type ScheduledNotSentServices = keyof typeof SERVICES

export const SCHEDULED_NOT_SENT_SERVICE_TYPES: Record<ScheduledNotSentServices, symbol> =
  makeServicesTypes<ScheduledNotSentServices>(enumKeysToArray<ScheduledNotSentServices>(SERVICES))

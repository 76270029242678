type TranslationDataValue = string | TranslationData | TranslationDataObject | TranslationDataArray
type TranslationDataObject = { [K in string]: TranslationDataValue | undefined }
type TranslationDataArray = TranslationDataValue[]
export type LocaleTranslationData = { [K in string]: TranslationDataValue | TranslationDataArray }
export type TranslationData = Record<SupportedLocales, LocaleTranslationData>

const APP_SUPPORTED_LOCALES = ['en-US']
export type SupportedLocales = (typeof APP_SUPPORTED_LOCALES)[number]
export const isSupportedLocale = (value: unknown): value is SupportedLocales => {
  return typeof value === 'string' && APP_SUPPORTED_LOCALES.includes(value)
}
export const APP_DEFAULT_LOCALE = 'en-US' as const satisfies SupportedLocales

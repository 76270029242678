import { TmApiError } from '@/core/error/transport/tmApiError'
import type { TmTransportHttpErrorCode } from '@/core/error/transport/types'

export class TmAuthCaptchaNotProvidedError extends TmApiError {
  protected override code: TmTransportHttpErrorCode = 429

  public shouldBeMonitored(): boolean {
    return false
  }
}

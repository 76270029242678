import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type UiSettingsService from '@/services/domain/uiSettingsService'

@injectable()
export default class UiSettingsResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.UiSettingsService)
    protected readonly uiSettingsService: UiSettingsService,
  ) {}

  public async resolve() {
    await this.uiSettingsService.fetchSettings()
  }

  public async unresolve() {}

  public isFailed() {
    return false
  }
}

import { inject, injectable } from 'inversify'
import type { PaginationInterface, PaginationSettings } from '@/services/tables/pagination/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Factory } from '@/types'
import type LoggerService from '@/services/loggerService'
import type BasePaginatorServiceFactory from '@/services/tables/pagination/basePaginatorServiceFactory'
import type TablePaginatorManagerInterface from '@/services/tables/managers/tablePaginatorManagerInterface'
import type PaginationRepository from '@/data/repositories/table/paginationRepository'
import TableElementManagerBase from '@/services/tables/managers/tableElementManagerBase'
import type { LoggerChannels } from '@/config/configDev'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import { TmTableElementManagerFactoryNotFoundError } from '@/core/error/table/tableManager/tmTableElementManagerFactoryNotFoundError'
import Pagination from '@/data/models/tables/Pagination'

@injectable()
export default class TablePaginatorManager
  extends TableElementManagerBase<PaginationInterface, PaginationSettings>
  implements TablePaginatorManagerInterface
{
  protected readonly managerId = 'TablePaginatorManager'

  protected readonly loggerChannel: LoggerChannels = 'pagination'

  private constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.BasePaginatorServiceFactory)
    protected readonly basePaginatorServiceFactory: Factory<BasePaginatorServiceFactory>,
  ) {
    super(em, loggerService)
  }

  public getFactoryForTable(tableId: string): Factory<PaginationInterface> {
    try {
      return super.getFactoryForTable(tableId)
    } catch (e) {
      if (e instanceof TmTableElementManagerFactoryNotFoundError) {
        this.log(`No explicitly set factory found for the "${tableId}" table. Falling back to default one.`)
        return this.basePaginatorServiceFactory
      }

      throw e
    }
  }

  public addServiceForTable(tableId: string, settings: PaginationSettings) {
    const { serviceId } = super.addServiceForTable(tableId)
    this.services[serviceId].setSettings(settings)
    this.services[serviceId].setTableId(tableId)

    return {
      serviceId,
      instance: this.services[serviceId],
    }
  }

  protected removeServiceById(tableId: string, serviceId: string) {
    const instance = this.getServiceByServiceId(serviceId)
    this.getPaginationRepository().deleteByCondition((model) => model.id === instance.getTableId())

    super.removeServiceById(tableId, serviceId)
  }

  protected getPaginationRepository() {
    return this.em.getRepository<PaginationRepository>(Pagination)
  }
}

import { AttrField, BooleanField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import {
  type SubscriberHelp,
  type SubscriberOptIn,
  type SubscriberOptOut,
  type TenDlcCampaignsBaseUseCasesType,
  TenDlcTwilioCampaignsStatus,
} from '@/services/domain/services/regulations/tenDlcCampaigns/types'

@OrmModel(modelNames.TEN_DLC_CAMPAIGN)
export default class TenDlcCampaign extends BaseModel {
  @StringField()
  public id: string

  @StringField('', (val: string) => val.toUpperCase())
  public shortId: string

  @BooleanField()
  public isUseCaseFilled: boolean

  @BooleanField()
  public isDetailsFilled: boolean

  @BooleanField()
  public isSampleMessagesFilled: boolean

  @BooleanField()
  public isContentAttributesFilled: boolean

  @BooleanField()
  public isDelayedCancellation: boolean

  @AttrField()
  public requestId: string | null

  @AttrField()
  public name: string | null

  @AttrField()
  public description: string | null

  @AttrField()
  public vonageIdentifier: string | null

  @AttrField()
  public vonageStatus: string | null

  @AttrField()
  public useCase: TenDlcCampaignsBaseUseCasesType[] | null

  @StringField()
  public status: TenDlcTwilioCampaignsStatus

  @AttrField()
  public registeredOn: string | null

  @AttrField()
  public renewOn: string | null

  @AttrField()
  public subscribersInfo: string | null

  @AttrField()
  public contentInfo: string | null

  @AttrField()
  public campaignNumbers: unknown[]

  @AttrField()
  public collectedConsentInfo: string | null

  @AttrField()
  public estimatedMonthlyMessageVolume: string | null

  @AttrField()
  public sampleMessages: string[]

  @AttrField()
  public subscriberOptIn: SubscriberOptIn

  @AttrField()
  public subscriberOptOut: SubscriberOptOut

  @AttrField()
  public subscriberHelp: SubscriberHelp

  @AttrField()
  public includesLinkInMessageContent: boolean | null

  @AttrField()
  public includesPhoneNumberInMessageContent: boolean | null

  @AttrField()
  public errorFields: string[]

  @AttrField()
  public termsConditionsUrl: string | null

  @AttrField()
  public privacyPolicyUrl: string | null

  @AttrField()
  public subscribeFormLink: string | null

  @AttrField()
  public subscribeFormScreenshotLink: string | null

  public get isAllowCancelCampaign() {
    return (
      (this.status === TenDlcTwilioCampaignsStatus.verified ||
        this.status === TenDlcTwilioCampaignsStatus.draft ||
        this.status === TenDlcTwilioCampaignsStatus.actionRequired) &&
      !this.isDelayedCancellation
    )
  }

  public get isDraft(): boolean {
    return this.status === TenDlcTwilioCampaignsStatus.draft
  }

  public get isVerified(): boolean {
    return this.status === TenDlcTwilioCampaignsStatus.verified
  }
}

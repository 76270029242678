import { inject, injectable } from 'inversify'
import BaseTable from '@/core/tables/baseTable'
import type { TableBuilderSettings } from '@/services/tables/types'
import type { BaseTableSettings } from '@/core/tables/types'
import type { BaseTableInterface } from '@/core/tables/baseTableInterface'
import { SERVICE_TYPES } from '@/core/container/types'
import type BaseFilters from '@/services/tables/filters/baseFilters'
import type { TableExpandable } from '@/services/route/types'

@injectable()
export default class TableBuilderService {
  constructor(
    @inject(SERVICE_TYPES.BaseFilters) protected readonly baseFilters: BaseFilters,
    @inject(SERVICE_TYPES.TableExpandService) protected readonly tableExpandService: TableExpandable,
  ) {}

  public build(settings: TableBuilderSettings): BaseTableInterface {
    const TableConstructor = settings.tableConstructor || BaseTable

    const tableBuilderSettings: BaseTableSettings = {
      tableModelId: settings.tableModelId,
      tableWrapperId: settings.tableWrapperId,
    }

    if (settings.gridService) {
      settings.gridService.init?.()
      tableBuilderSettings.gridService = settings.gridService
    }

    const tableExpandService = settings.tableExpandService ?? this.tableExpandService
    tableExpandService.init(tableBuilderSettings.tableModelId)

    const builded = new TableConstructor(tableBuilderSettings)
    builded.setFilterServiceManager(settings.filterServiceManager)
    builded.setSorterManager(settings.sorterManager)
    builded.setGrouperManager(settings.grouperManager)
    builded.setPaginatorManager(settings.paginatorManager)
    builded.setSearcherManager(settings.searcherManager)
    builded.setColumnManager(settings.columnManager)
    builded.setBaseFilterService(this.baseFilters)
    builded.setBulkService(settings.bulk)
    builded.setEntity(settings.entity)
    builded.setEm(settings.em)
    builded.setDomainManager(settings.domainManager)
    builded.setExportService(settings.export)
    builded.setSubscriptionService(settings.subscription)
    builded.setPreloaderManager(settings.preloaderManager)
    builded.setFilterCompareService(settings.filterCompareService)
    builded.setExpandService(tableExpandService)
    builded.setSubscriptions(settings.subscriptions)
    builded.setNotificationService(settings.notification)
    builded.setHistoryService(settings.history)
    builded.setTableWrapperService(settings.tableWrapperService)
    builded.setLogger(settings.logger)
    builded.setKeyBuilder(settings.keyBuilder)
    builded.setCounterService(settings.counter)
    builded.setFacetSettings(settings.facetSettings)
    builded.setTableWrapperParamsToSave(settings.tableWrapperParamsToSave)
    builded.setCustomFiltersDefaultState(settings.customFiltersDefaultState)
    builded.setCanBeRestored(settings.canBeRestored)
    builded.setRecoveryService(settings.recoveryService)
    builded.setFastInsert(settings.fastInsert)
    builded.setNoSelectAll(settings.noSelectAll)
    builded.setAllowSelectAllWithNonDefaultFilters(settings.allowSelectAllWithNonDefaultFilters)
    builded.setShouldResetOnCreateOrDelete(settings.shouldResetOnCreateOrDelete)
    builded.initFilter()

    return builded
  }
}

export enum modelNames {
  // system
  MODAL = 'modals',
  FORM = 'forms',
  AUTH_STATE = 'authState',
  OAUTH_SIGN_UP = 'oAuthSignUp',
  AUTO_DETECTED_COUNTRY = 'autoDetectedCountry',
  REOPEN_ACCOUNT = 'reopenAccount',

  // Domains
  SENT_SMS = 'sentSms',
  SENT_SMS_SHORT = 'sentSmsShort',
  SENT_SMS_FULL = 'sentSmsFull',
  BULK_SENT_SMS = 'bulkSentSms',
  RECEIVED_SMS = 'receivedSms',
  BYOC = 'byoc',
  BYOC_PHONE = 'byocPhone',
  BYOC_CARRIER_PHONE = 'byocCarrierPhone',
  BYOC_PROVIDER_PHONE = 'byocProviderPhone',
  BYOC_SENDER_ID = 'byocSenderId',
  BYOC_CARRIER_SENDER_ID = 'byocCarrierSenderId',
  BYOC_PROVIDER_SENDER_ID = 'byocProviderSenderId',
  BYOC_CARRIER = 'byocCarrier',
  CONTACT = 'contacts',
  TAG = 'tag',
  SCHEDULED_MESSAGE = 'scheduledMessage',
  SCHEDULED_SHORT_MESSAGE = 'scheduledShortMessage',
  SCHEDULED_MESSAGE_PARAMETERS = 'scheduledMessageParameters',
  SCHEDULED_NOT_SENT_MESSAGE = 'scheduledNotSentMessage',
  AUDIT_LOG = 'auditLog',
  REPORTING_OVERVIEW = 'reportingOverview',
  REPORTING_NUMBER = 'reportingNumber',
  REPORTING_SUBACCOUNT = 'reportingSubAccount',
  REPORTING_USER = 'reportingUser',
  REPORTING_CALL = 'reportingCall',
  REPORTING_MESSAGE = 'reportingMessage',
  BLOCKED_CONTACT = 'blockedContacts',
  PINNED_CONTACT = 'pinnedContacts',
  CONTACT_LIST = 'contactList',
  CONTACT_LIST_TEMP = 'contactListTemp',
  CONTACT_IMPORT = 'contactImport',
  CONTACT_IMPORT_RESULT = 'contactImportResult',
  UNSUBSCRIBED_CONTACT = 'unsubscribedContact',
  UNSUBSCRIBED_EMAIL_CONTACT = 'unsubscribedEmailContact',
  UNSUBSCRIBED_SETTINGS = 'unsubscribedSettings',
  TEMPLATE = 'template',
  TEMPLATE_CATEGORY = 'templateCategory',
  SIP_CALL = 'sipCall',
  INBOUND_CALL = 'inboundCall',
  OUTBOUND_CALL = 'outboundCall',
  FORWARDED_CALL = 'forwardedCall',
  FORWARD_AUDIO = 'forwardAudio',
  SENDER_SOURCE = 'senderSource',

  // session
  SESSION = 'session',
  SESSION_SHORT = 'sessionShort',
  SESSION_RECIPIENTS = 'sessionRecipients',
  SESSION_STAT = 'sessionStat',
  SMS_SESSION_RECIPIENTS = 'smsSessionRecipients',

  ALLOWED_EMAIL = 'allowedEmail',
  ACCOUNT_NOTIFICATIONS_SETTINGS = 'accountNotificationsSettings',
  SEND_RECEIVE_SETTINGS = 'sendReceiveSettings',
  SMS_SURVEY = 'smsSurvey',
  SMS_SURVEY_NODES_TREE = 'smsSurveyNodesTree',
  SMS_SURVEY_DETAILS = 'smsSurveyDetails',
  SMS_SURVEY_NODE = 'smsSurveyNode',
  SMS_SURVEY_NODE_WITH_CHILDREN = 'smsSurveyNodeWithChildren',
  SMS_SURVEY_NODE_WITH_ROWS = 'smsSurveyNodeWithRows',
  SMS_SURVEY_REPLY = 'smsSurveyReply',
  SMS_SURVEY_RECIPIENT_COUNTRY = 'smsSurveyRecipientCountry',
  FORM_CLICK_TO_TEXT = 'formClickToText',
  FORM_CLICK_TO_TEXT_DETAILS = 'formClickToTextDetails',
  FORM_SUBSCRIBE = 'formSubscribe',
  FORM_SUBSCRIBE_DETAILS = 'formSubscribeDetails',
  MESSAGES_PRICE = 'messagesPrice',
  MESSAGES_PRICE_FOR_COUNTRY = 'MessagesPriceForCountry',
  MESSAGES_PREVIEW = 'messagesPreview',
  MESSAGES_BULKS_PROGRESS = 'messagesBulksProgress',
  MESSAGES_ATTACHMENT_FILE = 'messagesAttachmentFile',
  RECENT_RECIPIENT = 'recentRecipient',
  CUSTOM_FIELD_SCHEMA = 'customFieldSchema',
  CUSTOM_FIELD_VALUE = 'customFieldValue',
  USER_ANALYTICS_FIELD = 'userAnalyticsField',
  USER_ANALYTICS_TRAIT = 'userAnalyticsTrait',
  USER_PRESENCE = 'userPresence',
  USER_NUMBERS = 'userNumbers',
  PHONE_NUMBER = 'phoneNumber',
  AVAILABLE_TO_BUY_NUMBERS = 'availableToBuyNumbers',
  NUMBER_AVAILABLE_COUNTRY = 'numberAvailableCountry',
  AREA_CODES = 'areaCodes',
  ACCOUNT_SETTINGS_SECURITY = 'accountSettingsSecurity',
  NUMBER_PRICE = 'numberPrice',
  CHAT_SETTINGS = 'chatSettings',
  CHAT = 'chat',
  CHAT_PAGE_INFO = 'chatPageInfo',
  CHAT_MESSAGE = 'chatMessage',
  CHAT_MESSAGE_THREAD = 'chatMessageThread',
  CHAT_MESSAGE_CONTENT = 'chatMessageContent',
  CHAT_MMS_INFO = 'chatMmsInfo',
  CHAT_DETAILS = 'chatDetails',
  TYPING = 'typing',
  ALSO_HERE = 'alsoHere',
  CHANNEL_PRESENCE = 'channelPresence',
  CHAT_EXTRA_FILTERS = 'chatExtraFilters',
  CHAT_STATISTICS = 'chatStatistics',
  CHAT_CONVERSATION_SENDER_SETTINGS = 'chatConversationSenderSettings',
  CHAT_PREVIEW_NEXT = 'chatPreviewNext',
  WIDGET_CHAT_CENTRIFUGE_TOKEN = 'widgetChatCentrifugeToken',
  WHATSAPPACCOUNT = 'whatsAppAccount',
  WHATSAPPBUSINESSPHONE = 'whatsAppBusinessPhone',
  WHATSAPPCONFIG = 'whatsAppConfig',
  WHATSAPPACTIVITY = 'whatsAppActivity',
  FACEBOOK_CONFIG = 'facebookConfig',
  FACEBOOK_PAGE = 'facebookPage',
  INSTAGRAM_ACCOUNT = 'instagramAccount',
  VOICE_CALL_PRICE = 'voiceCallPrice',
  VOICE_CALL_CONFIG = 'voiceCallConfig',
  BILLING_DETAILS = 'billingDetails',
  CARRIER_LOOKUP = 'carrierLookup',
  CARRIER_LOOKUP_SESSION = 'carrierLookupSession',
  EMAIL_LOOKUP = 'emailLookup',
  ACCOUNT_SSO_SETTINGS = 'accountSsoSettings',
  POINT_AI_ASSISTANT = 'pointAiAssistant',
  POINT_AI_WEBSITE = 'pointAiWebsite',
  POINT_AI_WEBSITE_DOWNLOADED = 'pointAiWebsiteDownloaded',
  DISTRIBUTION = 'distribution',
  DISTRIBUTION_DETAILS = 'distributionDetails',
  API_V2_ENTRY = 'apiV2Entry',
  API_V1_SETTINGS = 'apiV1Settings',
  API_V2_SETTINGS = 'apiV2Settings',
  BILLING = 'billing',
  BILLING_PLAN_INFO = 'billingPlanInfo',
  AUTOMATION_RULE = 'automationRule',
  AUTOMATION_RULE_DETAILS = 'automationRuleDetails',
  AUTOMATION_RULE_LOGS = 'automationRuleLogs',
  AUTOMATION_RULE_LOGS_SHORT = 'automationRuleLogsShort',
  AUTOMATION_RULE_ACTION = 'automationRuleAction',
  TEN_DLC_PHONE_NUMBER = 'tenDlcPhoneNumber',
  TEN_DLC_CAMPAIGN = 'tenDlcCampaign',
  TEN_DLC_BRAND = 'tenDlcBrand',
  TEN_DLC_TWILIO_BRAND = 'tenDlcTwilioBrand',
  WIZARD = 'wizard',
  STATEMENT = 'statement',
  STATEMENT_SUMMARY = 'statementsSummary',
  ATTACHMENT_FILE = 'attachmentFile',

  ACTIVITY_CALL = 'activityCall',
  ACTIVITY_LIVE_CHAT = 'activityLiveChat',
  ACTIVITY_FACEBOOK = 'activityFacebook',
  ACTIVITY_INSTAGRAM = 'activityInstagram',

  /* to avoid circular references:
  Contact -> Organization -> Contact (primaryContact)
  */
  // to avoid circular references:
  // Contact -> Phone -> Unsubscribers -> Contact
  // Contact -> Email -> Unsubscribers -> Contact
  // Actually it`s looks like:
  // Contact -> Phone -> UnsubscriberChannel (reference to Contact is not exists)
  // Contact -> Email -> UnsubscriberChannel (reference to Contact is not exists)
  EXPORT_FILE = 'exportFile',
  BASE_WRAPPER = 'baseWrapper',
  USER = 'users',
  USER_METADATA = 'userMetadata',
  USER_SETTINGS = 'userSettings',
  NOTE = 'notes',
  TIMEZONE = 'timezone',
  COUNTRY = 'country',
  UNAUTHENTICATED_COUNTRY = 'unauthenticatedCountry',
  PRELOAD = 'preload',
  PAGE_MAP_PRELOAD = 'pageMapPreload',
  SENDER_ID = 'senderId',
  SENDER_ID_PRICE = 'senderIdPrice',
  SUB_ACCOUNT_IMPORT_SESSION = 'subAccountImportSession',
  USER_SESSION = 'userSession',

  // subaccounts import
  SUBACCOUNTS_IMPORT = 'subAccountsImport',
  SUBACCOUNTS_IMPORT_DETAILS = 'subAccountsImportDetails',
  SUB_ACCOUNT_PERMISSIONS = 'subAccountPermissions',
  TEAM_WORKFLOW = 'teamWorkflow',
  TEAM_SUBSCRIPTION = 'teamSubscription',
  CENTRIFUGE = 'Centrifuge',
  TEAM_SUBSCRIPTION_PRICE = 'teamSubscriptionPrice',
  TEAM_SUBSCRIPTION_INVOICE = 'teamSubscriptionInvoices',

  // filters
  BASE_FILTER = 'baseFilter',
  RANGE_FILTER = 'rangeFilter',
  ARRAY_FILTER = 'arrayFilter',
  FIELD_ID_FILTER = 'fieldIdFilter',
  FILTER_STATE = 'filterState',

  // counters
  SENT_SMS_COUNTER = 'sentSmsCounter',
  DELIVERED_SMS_COUNTER = 'deliveredSmsCounter',
  FAILED_SMS_COUNTER = 'failedSmsCounter',
  REJECTED_SMS_COUNTER = 'rejectedSmsCounter',
  BULK_SENT_SMS_COUNTER = 'bulkSentSmsCounter',
  CONTACT_COUNTER = 'contactCounter',
  LIST_CONTACTS_COUNTER = 'listContactsCounter',

  // sender settings: countries
  SENDER_SETTINGS_COUNTRY = 'SenderSettingsCountry',

  // fields
  BASE_FIELD = 'baseField',
  FIELD = 'field',
  FIELD_ARRAY = 'fieldArray',
  FIELD_GROUP = 'FieldGroup',
  FIELD_MULTI_ENTITY = 'FieldMultiEntity',

  // billing
  INVOICE = 'invoices',

  // table
  ALL_SELECTED_TABLES = 'allSelectedTables',
  FILTERS_SCHEMA = 'filtersSchema',
  COLUMNS = 'columns',
  SELECTED_ROWS = 'selectedRows',
  TABLE = 'tables',
  TABLE_RECOVERY_DATA = 'tableRecoveryData',
  SORTER = 'sorters',
  GROUPING = 'grouping',
  PAGINATION = 'paginations',
  CURSOR_PAGINATION_PAGE = 'cursorPaginationPage',
  SEARCH = 'search',
  COUNTER = 'counter',
  VIEWER = 'viewer',

  // facets
  FACETS = 'facets',

  // UI
  AUTOCOMPLETE = 'autocomplete',
  ORDER = 'order',
  VISIBILITY = 'visibility',
  COMPOSE_LOCAL = 'composeLocal',
  GLOBAL_STATE = 'globalState',
  MEDIA_DEVICE = 'MediaDevice',
  UI_STATE = 'uiState',
  PRIMARY_BROWSER_TAB = 'primaryBrowserTab',
  PRIMARY_BROWSER_TAB_LOCK = 'primaryBrowserTabLock',

  // FOR FUNCTIONAL TESTS PURPOSE
  TEST = 'tests',
  SINGLE_TEST = 'singleTests',
  WITH_RELATION_TEST = 'withRelationTests',
  RELATION_TEST = 'relationTests',
  CHILD_TEST = 'childTests',
  COUNTER_TEST = 'counterTest',

  LOGIN_HISTORY = 'loginHistory',
  KEYWORD = 'keyword',
  EMAIL_LOOKUP_SESSION = 'emailLookupSession',
  TEN_DLC_REQUEST = 'tenDlcRequest',
  TEN_DLC_PRICING = 'tenDlcPricing',
  TOLL_FREE_REQUEST = 'tollFreeRequest',
  TOLL_FREE_PHONE_NUMBER = 'tollFreePhoneNumber',
  REGULATIONS_STATUS = 'regulationsStatus',
  GETTING_STARTED_INFO = 'gettingStartedInfo',
  UI_SETTINGS = 'uiSettings',

  USER_TRIAL = 'userTrial',

  TASK = 'task',
  TASK_EDITOR = 'taskEditor',
  TASK_STAGE = 'taskStage',
  TASK_BOARD = 'taskBoard',
  TASK_BOARD_ARCHIVED = 'taskBoardArchived',
  TASK_CHECKLIST = 'taskChecklist',
  TASK_CHECKLIST_ITEM = 'taskChecklistItem',
  TASK_COMMENT = 'taskComment',

  NAVIGATION_ORDER = 'navigationOrder',

  EMAIL_UNSUBSCRIBE_DATA = 'emailUnsubscribeData',

  // Messages Settings
  WEB_WIDGET = 'webWidget',
  WEB_WIDGET_DETAILS = 'webWidgetDetails',

  // Stripe billing
  STRIPE_PAYG_PRICE = 'stripePaygPrice',
  STRIPE_MONTHLY_PLAN_PRICE = 'stripeMonthlyPlanPrice',
  STRIPE_PAYMENT_METHOD = 'stripePaymentMethod',

  // Tickets
  TICKET = 'ticket',
  TICKET_STATUS = 'ticketStatus',
  TICKET_INBOX = 'ticketInbox',
  TICKET_MESSAGE = 'ticketMessage',
  TICKET_FIRST_LAST_MESSAGE = 'ticketFirstLastMessage',
  TICKET_COUNT = 'ticketCount',
  TICKET_MESSAGE_TRIMMED_CONTENT = 'ticketMessageTrimmedContent',
  TICKET_MESSAGE_CONTENT = 'ticketMessageContent',
  TICKET_WORKSPACE = 'ticketWorkspace',
  TICKET_SPAM_FILTER = 'ticketSpamFilter',

  // Filtered views
  FILTERED_VIEW = 'filteredView',
  SEGMENT = 'segment',
  FILTERED_VIEW_FOR_ENTITY = 'filteredViewForEntity',

  // Deals
  DEAL_PIPELINE = 'dealPipeline',
  DEAL_PIPELINE_ARCHIVED = 'dealPipelineArchived',
  DEAL_STAGE = 'dealStage',
  DEAL = 'deal',
  DEAL_USER_PROFILE = 'dealUserProfile',
  DEAL_TIMELINE_ITEM = 'deaTimelineItem',
  DEAL_ACTIVITY = 'dealActivity',
  DEAL_ACTIVITY_ITEM = 'dealActivityItem',

  // Campaigns
  SMS_CAMPAIGN = 'smsCampaign',
  EMAIL_CAMPAIGN = 'emailCampaign',
  EMAIL_CAMPAIGN_RECIPIENTS = 'emailCampaignRecipients',
  EMAIL_CAMPAIGN_MESSAGE = 'emailCampaignMessage',
  EMAIL_CAMPAIGN_OPEN = 'emailCampaignOpen',
  EMAIL_CAMPAIGN_UNSUBSCRIBE = 'emailCampaignUnsubscribe',
  EMAIL_CAMPAIGN_CLICK_SUMMARY = 'emailCampaignClickSummary',
  EMAIL_CAMPAIGN_CLICK_SUMMARY_STATISTICS = 'emailCampaignClickSummaryStatistics',
  EMAIL_CAMPAIGN_SPAM_REPORT = 'emailCampaignSpamReport',
  EMAIL_CAMPAIGN_CLICK = 'emailCampaignClick',
  EMAIL_CAMPAIGN_DOMAIN = 'emailCampaignDomain',
  EMAIL_CAMPAIGN_EMAIL_SENDER = 'emailCampaignEmailSender',

  OUTBOUND_EMAIL_DETAILS = 'OutboundEmailDetails',

  RECIPIENT_CONTACT = 'recipientContact',
  RECIPIENT_GROUP = 'recipientGroup',
  RECIPIENT_FILTERED_VIEW = 'recipientFilteredView',
}

export const STI_TYPE_KEY = 'eff8680e-0424-45d5-85b5-f2e113ee3f64'

export type BaseStateType = Record<string, any>

import { isRecordUnknown } from '@/utils/typeGuards'

export const W_PDF_EXPORT_CHART_GENERATE_MSG = 'W_PDF_EXPORT_CHART_GEN' as const
export type WorkerGenerateExportChartPdfPayload = {
  nonce: number
  type: typeof W_PDF_EXPORT_CHART_GENERATE_MSG
  imageData: Uint8Array
  width: number
  height: number
}
export const isWorkerGenerateExportChartPdfPayload = (
  payload: unknown,
): payload is WorkerGenerateExportChartPdfPayload => {
  return isRecordUnknown(payload) && payload.type === W_PDF_EXPORT_CHART_GENERATE_MSG
}

export const W_PDF_EXPORT_CHART_SUCCESS_MSG = 'W_PDF_EXPORT_CHART_SUCCESS' as const
export type WorkerGenerateExportChartPdfSuccessPayload = {
  nonce: number
  type: typeof W_PDF_EXPORT_CHART_SUCCESS_MSG
  blob: Blob
}
export const isWorkerGenerateExportChartPdfSuccessPayload = (
  payload: unknown,
): payload is WorkerGenerateExportChartPdfSuccessPayload => {
  return isRecordUnknown(payload) && payload.type === W_PDF_EXPORT_CHART_SUCCESS_MSG
}

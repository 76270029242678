<template>
  <div class="chat-sidebar">
    <chat-sidebar-header-loading />
    <chat-sidebar-loading />
  </div>

  <div class="chat-content">
    <chat-content-header-empty-or-loading class="chat-content-header" />
    <chat-background
      class="d-flex column flex-grow-1"
      :theme-color="settings?.themeColor"
      :show-theme-pattern="settings?.showThemePattern"
    >
      <div class="flex-grow-1 align-content-center">
        <chat-content-not-selected />
      </div>
      <chat-message-editor-loading />
    </chat-background>
  </div>
</template>

<script setup lang="ts">
import ChatSidebarHeaderLoading from '@/components/domain/chats/sidebar/header/ChatSidebarHeaderLoading.vue'
import ChatSidebarLoading from '@/components/domain/chats/sidebar/ChatSidebarLoading.vue'
import ChatMessageEditorLoading from '@/components/domain/chats/content/ChatMessageEditorLoading.vue'
import ChatContentNotSelected from '@/components/domain/chats/content/contentEmpty/ChatContentNotSelected.vue'
import ChatContentHeaderEmptyOrLoading from '@/components/domain/chats/content/header/ChatContentHeaderEmptyOrLoading.vue'
import ChatBackground from '@/components/domain/chats/content/ChatBackground.vue'
import { getChatSettingsService } from '@/core/container/ioc'

defineOptions({
  inheritAttrs: false,
})

const chatSettingsService = getChatSettingsService()
const settings = chatSettingsService.getChatSettings()
</script>

import { AttrField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import PhoneNumber from '@/data/models/domain/PhoneNumber'
import type { TollFreeRequestStatus } from '@/services/domain/services/regulations/tollFreeRequest/types'

@OrmModel(modelNames.TOLL_FREE_PHONE_NUMBER)
export default class TollFreePhoneNumber extends PhoneNumber {
  @AttrField()
  public requestId: string | null

  @AttrField()
  public tollFreeStatus: TollFreeRequestStatus | null
}

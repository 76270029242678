import { TM_URL } from '@/constants/constants'

export const termsOfService = 'https://www.textmagic.com/terms-of-service/'
export const privacyPolicy = 'https://www.textmagic.com/privacy-policy/'
export const textMagicLink = 'https://www.textmagic.com/'
export const textMagicSupport = 'https://support.textmagic.com/contact-us/'
export const openAIPrivacyPolicy = 'https://openai.com/enterprise-privacy/'
export const whatsAppBusinessAccount = 'https://business.facebook.com/wa/manage/home/'
export const superAdminLink = `${TM_URL}/admin`
export const supportEmail = 'support@textmagic.com'

export const googlePlayUrl =
  'https://play.google.com/store/apps/details?id=textmagic.com.textmagicmessenger&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
export const appStoreUrl = 'https://itunes.apple.com/us/app/textmagic/id1107866161'

export const usersWorkflowLink = 'https://support.textmagic.com/article/add-users-and-share-access-for-better-teamwork/'

export const tollFreeNumberMessagingLink = 'https://support.textmagic.com/article/toll-free-number-messaging/'

export const tenDlcMessagingLink = 'https://support.textmagic.com/article/10dlc-messaging/'
export const tenDlcBrandRegistrationLink =
  'https://support.textmagic.com/article/10dlc-registration-guide/#brand-registration'

export const whatsAppBusinessUrl = 'https://business.whatsapp.com/'

export const whatsAppSupportArticleLink = 'https://support.textmagic.com/article/whatsapp-integration/'

export const whatsAppBusinessPricingUrl = 'https://business.whatsapp.com/products/platform-pricing'

export const facebookSupportArticleLink = 'https://support.textmagic.com/article/facebook-integration/'

export const instagramSupportArticleLink = 'https://support.textmagic.com/article/instagram-integration/'

export const textTransliterationTool = 'https://freetools.textmagic.com/text-transliteration-tool'

export const faqMaximumLengthOfTextMessage = 'https://support.textmagic.com/faq/maximum-length-of-a-text-message/'

export const faqSmsCharges = 'https://support.textmagic.com/faq/how-am-i-charged-when-i-send-text-messages/'

export const webWidgetSupportArticleLink = 'https://support.textmagic.com/article/web-widget'

export const tenDlcCasesArticleLink = ' https://support.textmagic.com/article/10dlc-registration-guide/#cases'

export const businessTextMessagingLink = 'https://www.textmagic.com/business-text-messaging/'

export const byocLink = 'https://www.textmagic.com/bring-your-own-cpaas/'

export const stripeLink = 'https://stripe.com/'

export const notificationsTasks = 'https://app.textmagic.com/accounts/notifications/tasks' // todo: update to route

export const howAmChargedWhenISendTextMessagesLink =
  'https://support.textmagic.com/faq/how-am-i-charged-when-i-send-text-messages/'

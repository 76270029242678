import type { SmsRejectReason } from '@/types'

export type TooltipDirection =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'left-top'
  | 'left'
  | 'left-bottom'
  | 'right-top'
  | 'right'
  | 'right-bottom'
  | 'bottom-left'
  | 'bottom'
  | 'bottom-right'
export type TooltipVariants = 'text' | 'sentSmsStatusTooltip'
export type TooltipColors = 'dark' | 'white'

export type AnchorBoundingRect = {
  top: number
  left: number
  width: number
  height: number
}

export type ElementBoundingRect = {
  top: number
  left: number
  right: number
  bottom: number
  centerX: number
  centerY: number
  width: number
  height: number
}

export type EdgePositionStyle = {
  top?: number
  left?: number
  right?: number
  bottom?: number
}

export type TooltipPositionStyle = Record<TooltipDirection, EdgePositionStyle>

export const tooltipWrapperClassName = 'tm-tooltip-wrapper'
export const tooltipDefaultDirection: TooltipDirection = 'top'
export const tooltipDefaultVariant: TooltipVariants = 'text'
export const tooltipDefaultColor: TooltipColors = 'dark'

export type TmAngleWrapTooltipPropsType = {
  direction?: TooltipDirection
  color?: TooltipColors
  offset?: number[]
  viewportWidth?: number
  viewportHeight?: number
  anchorBounding?: ElementBoundingRect
  tooltipPosition?: Record<string, number | undefined>
}

export type TmTextTooltipPropsType = TmAngleWrapTooltipPropsType & {
  text?: string
  textAlign?: 'left' | 'center' | 'right'
  html?: boolean
}

export type TmTranslateTooltipPropsType = TmAngleWrapTooltipPropsType & {
  keypath: string | Record<string, any>
}

export type SentSmsStatusTooltipPropsType = TmAngleWrapTooltipPropsType & {
  tooltip?: string
  rejectReason?: SmsRejectReason
}

export const TmAngleWrapTooltipPropsKeys: (keyof TmAngleWrapTooltipPropsType)[] = [
  'direction',
  'color',
  'offset',
  'viewportWidth',
  'viewportHeight',
  'anchorBounding',
  'tooltipPosition',
]
export const TmTextTooltipPropsKeys: (keyof TmTextTooltipPropsType)[] = [
  ...TmAngleWrapTooltipPropsKeys,
  'text',
  'textAlign',
  'html',
]

export const TmTranslateTooltipPropsKeys: (keyof TmTranslateTooltipPropsType)[] = [
  ...TmAngleWrapTooltipPropsKeys,
  'keypath',
]

export const SentSmsStatusTooltipPropsKeys: (keyof SentSmsStatusTooltipPropsType)[] = [
  ...TmAngleWrapTooltipPropsKeys,
  'tooltip',
  'rejectReason',
]

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ScheduledCompletedColumnServiceFactory,
  ScheduledCompletedGridable,
  ScheduledCompletedExportService,
}

export type ScheduledCompletedServices = keyof typeof SERVICES

export const SCHEDULED_COMPLETED_SERVICE_TYPES: Record<ScheduledCompletedServices, symbol> =
  makeServicesTypes<ScheduledCompletedServices>(enumKeysToArray<ScheduledCompletedServices>(SERVICES))

import { inject, injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { SERVICE_TYPES } from '@/core/container/types'
import { DomainSettings } from '@/decorators/domainDecorators'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import Wizard, { type WizardCurrentStep, wizardCurrentStep } from '@/data/models/domain/Wizard'
import type WizardRepository from '@/data/repositories/domain/WizardRepository'
import type { WizardApplyBody, WizardState } from '@/services/domain/wizard/types'
import TmLogicError from '@/core/error/tmLogicError'

export const getWizardPricesEvent = 'getWizardPricesEvent'
export const wizardChangeStepEvent = 'wizardChangeStepEvent'
export const wizardChangeStepErrorEvent = 'wizardChangeStepErrorEvent'

@DomainSettings({
  model: Wizard,
})
@injectable()
export default class WizardService extends DomainBaseService<WizardRepository> {
  private countriesWithNewWizard: string[] = ['US', 'CA', 'PR', 'GB', 'AU']

  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public getWizardState() {
    return this.getDomainRepository().getWizardState()
  }

  public findWizardState() {
    const state = this.getDomainRepository().findWizardState()
    if (!state) {
      throw new TmLogicError('The model has not been loaded to the storage')
    }
    return state
  }

  public findWizardStateOrNull() {
    return this.getDomainRepository().findWizardState()
  }

  public async apply(body: WizardApplyBody) {
    try {
      const res = await this.getDomainRepository().apply(body)
      this.subscription.emit(wizardChangeStepEvent, body)
      return res
    } catch (e) {
      this.subscription.emit(wizardChangeStepErrorEvent, e)
      throw e
    }
  }

  public async getWizardPrices() {
    const res = await this.getDomainRepository().getWizardPrices()
    this.subscription.emit(getWizardPricesEvent, res)
    return res
  }

  /**
   * @todo temporary, delete after fix be
   */
  public hasWizardStep(step: string) {
    return (wizardCurrentStep as Readonly<string[]>).includes(step)
  }

  public isWizardMode(wizard: WizardState) {
    return wizard.flow !== 'none'
  }

  public isAllowUsageMainApplication(wizard: WizardState) {
    const steps: WizardCurrentStep[] = ['success_payment', 'finish', 'getting_started']
    return !this.isWizardMode(wizard) || wizard.currentStep === null || steps.includes(wizard.currentStep)
  }

  public getStepAfterConfigureAnalytics(countryId: string): WizardApplyBody {
    const isNewWizard = this.countriesWithNewWizard.includes(countryId)
    return {
      type: isNewWizard ? 'from_configure_analytics_to_getting_started' : 'from_configure_analytics_to_finish',
    }
  }
}

import { isEmpty, isObject, isEqual } from 'lodash-es'
import { inject, injectable } from 'inversify'
import type { Collection } from '@vuex-orm/core'
import type { ExplicitSortDirection, SortDirection, SorterInterface } from '@/core/tables/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { ModelRaw } from '@/types'
import type {
  FilteredViewsParsedBodySortType,
  PaginationUrlParametersSortType,
  PaginationUrlParametersSortTypeWithoutDirection,
  SorterSettings,
} from '@/services/tables/types'
import type { SchemaValueType } from '@/services/types'
import type FilterSchemaService from '@/services/filterSchemaService'
import type SorterRepository from '@/data/repositories/table/sorterRepository'
import Sorter from '@/data/models/tables/Sorter'
import { TmTableSortError } from '@/core/error/table/tmTableSortError'
import type LoggerService from '@/services/loggerService'
import type { Endpoint } from '@/services/endpoints'
import TmLogicError from '@/core/error/tmLogicError'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type { SorterSerializerService } from '@/services/tables/sort/sorterSerializerService'

const defaultSorterName = 'createdAt'
const defaultSorterDirection: SortDirection = 'desc'

// we trust - we will use only sort by one column
@injectable()
export default class BaseSorterServiceFactory implements SorterInterface {
  protected defaultSorter: Required<SorterSettings>

  protected _tableId: string

  protected _entityName: string

  protected _endpoint: Endpoint

  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.FilterSchemaService) protected readonly filterSchemaService: FilterSchemaService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.SorterSerializerService) protected readonly sorterSerializerService: SorterSerializerService,
  ) {}

  public setDefaultSorter(defaultSorter?: SorterSettings) {
    const schema = this.getSchema()
    const sorterNames = this.getSorterNames(schema)

    if (defaultSorter && sorterNames.includes(defaultSorter.name)) {
      this.defaultSorter = {
        name: defaultSorter.name,
        direction: defaultSorter.direction || defaultSorterDirection,
      }
    } else if (sorterNames.length) {
      this.defaultSorter = {
        name: sorterNames.includes(defaultSorterName) ? defaultSorterName : sorterNames[0],
        direction: defaultSorterDirection,
      }
    }
  }

  public convertSorterSettings(sorterSettings: SorterSettings): PaginationUrlParametersSortType {
    const sortValue = Object.values(this.getSchema().schema.sort).find((sort) => {
      const { name, type, fields } = sort
      return sorterSettings.name === name && type === 'relation' && fields?.length && fields[0].name
    })
    const direction = sorterSettings.direction || defaultSorterDirection
    const sortDirection = sortValue ? { [sortValue.fields![0].name]: direction } : direction
    return { [sorterSettings.name]: sortDirection }
  }

  public getDefaultSorter(): PaginationUrlParametersSortType {
    return this.defaultSorter && this.convertSorterSettings(this.defaultSorter)
  }

  public getSchema() {
    return this.filterSchemaService.getFiltersSchema(this.endpoint)
  }

  public getSorterNames(schema: SchemaValueType) {
    return schema.schema.sort.map(({ name }) => name)
  }

  protected correctSortType(
    schema: SchemaValueType,
    sortType: PaginationUrlParametersSortType = {},
  ): PaginationUrlParametersSortType {
    if (isEmpty(sortType)) {
      return this.defaultSorter && this.convertSorterSettings(this.defaultSorter)
    }

    const sorterNames = this.getSorterNames(schema)
    const firstSorterName = Object.keys(sortType)[0]
    const currentSorterName =
      firstSorterName === this._entityName ? Object.keys(sortType[firstSorterName])[0] : firstSorterName
    if (!sorterNames.includes(currentSorterName)) {
      return this.defaultSorter && this.convertSorterSettings(this.defaultSorter)
    }

    return sortType
  }

  public init(params?: PaginationUrlParametersSortType) {
    const data: ModelRaw<Sorter>[] = []
    const schema = this.getSchema()

    const sortType = this.correctSortType(schema, params)

    this.getSorterRepo().insert(this.initDataTransformer(data, schema))
    this.applySortByUrlParams(sortType!)
    this.log(`Sorter initialized with following params: "${JSON.stringify(sortType)}"`)
  }

  public reset(excludeNames: string[] = []) {
    Object.values(this.getSchema().schema.sort).forEach((sort) => {
      if (!excludeNames.includes(sort.name)) {
        this.getSorterRepo().update([{ id: this.getSorterId(sort.name), direction: '' }])
      }
    })

    if (!excludeNames.length) {
      this.getSorterRepo().update([
        {
          id: this.getSorterId(this.defaultSorter.name),
          direction: this.defaultSorter.direction,
        },
      ])
    }
  }

  public applySort(name: string, direction?: SortDirection) {
    const id = this.getSorterId(name)
    this.reset([name])
    const sorter = this.getSorterRepo().find(id)
    if (!sorter) {
      throw new TmTableSortError(`There is no available sort for the "${name}" column`)
    }

    let sortDirection: SortDirection = 'asc'
    if (direction) {
      sortDirection = direction
    } else if (sorter.direction) {
      sortDirection = this.getOppositeSortDirection(sorter.direction)
    }

    this.log(`Apply sort: "${id} ${sortDirection}"`)
    this.getSorterRepo().update([{ id, direction: sortDirection }])
  }

  public applySortByUrlParams(params: PaginationUrlParametersSortType) {
    if (isEmpty(params)) {
      return undefined
    }

    const [key, value] = Object.entries(params)[0]
    if (!isObject(value)) {
      return this.applySort(key, value)
    }
    const [subKey, paramValue] = Object.entries(value)[0]

    this.log(`Apply sort by URL: ${key} ${paramValue}`)
    if (key === this._entityName) {
      this.applySort(subKey, paramValue)
    } else {
      this.applySort(key, paramValue)
    }

    return undefined
  }

  public applySorts(toApply: Array<FilteredViewsParsedBodySortType>): void {
    toApply.forEach(({ name }) => this.applySort(name))
  }

  public getAllSorters(): Collection<Sorter> {
    return this.getSorterRepo().getSortersByTableId(this.getTableId())
  }

  public getActiveSorter(sorters: Collection<Sorter>): Array<Sorter> {
    return sorters.filter((sorter) => sorter.direction.length > 0)
  }

  public toQuery(): PaginationUrlParametersSortType {
    return this.toQueryBySorters(this.getActiveSorter(this.getAllSorters()))
  }

  public getCurrentSorter(): Sorter {
    const activeSorters = this.getActiveSorter(this.getAllSorters())
    if (activeSorters.length > 1) {
      throw new TmTableSortError('we trust - we will use only sort by one column')
    }
    return activeSorters[0]
  }

  public toQueryBySorters(sorters: FilteredViewsParsedBodySortType[]) {
    return sorters.reduce<PaginationUrlParametersSortType>(
      (acc, sorter) => ({
        ...acc,
        ...this.sorterSerializerService.getQueryForSorter(this._entityName, sorter),
      }),
      {},
    )
  }

  public isDefaultState() {
    let defaultSorter = this.getDefaultSorter()
    const toQueryResult = this.toQuery()

    if (!defaultSorter) return true

    if (Object.keys(toQueryResult)[0] === this._entityName) {
      defaultSorter = {
        [this._entityName]: defaultSorter as PaginationUrlParametersSortTypeWithoutDirection,
      }
    }
    return isEqual(defaultSorter, toQueryResult)
  }

  public setTableId(tableId: string) {
    this._tableId = tableId
  }

  public getTableId(): string {
    if (!this._tableId) {
      throw new TmLogicError('No tableId is set for service')
    }

    return this._tableId
  }

  public setEntityName(entityName: string) {
    this._entityName = entityName
  }

  public getEntityName(): string {
    if (!this._entityName) {
      throw new TmLogicError('No entityName is set for service')
    }

    return this._entityName
  }

  public setEndpoint(endpoint: Endpoint) {
    this._endpoint = endpoint
  }

  protected get endpoint(): Endpoint {
    if (!this._endpoint) {
      throw new TmLogicError('No endpoint is set for service')
    }

    return this._endpoint
  }

  protected initDataTransformer(data: ModelRaw<Sorter>[], schema: SchemaValueType): ModelRaw<Sorter>[] {
    const newData: ModelRaw<Sorter>[] = [...data]

    Object.values(schema.schema.sort).forEach((sort) => {
      newData.push({
        id: this.getSorterId(sort.name),
        direction: '',
        name: sort.name,
        entityName: this._entityName,
        relation: sort.type === 'relation' ? sort.fields![0].name : '',
        tableModelId: this.getTableId(),
      })
    })

    return newData
  }

  protected getSorterId(name: string) {
    return [this.getTableId(), name].join('.')
  }

  protected getOppositeSortDirection(direction: ExplicitSortDirection): ExplicitSortDirection {
    return direction === 'asc' ? 'desc' : 'asc'
  }

  protected log(message: any, subchannel = 'internal') {
    if (this.loggerService.shouldLogByChannel('sorter', [subchannel])) {
      this.loggerService.log('sorter', typeof message === 'string' ? message : JSON.stringify(message), subchannel)
    }
  }

  protected getSorterRepo() {
    return this.em.getRepository<SorterRepository>(Sorter)
  }
}

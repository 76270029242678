import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import AreaCodes from '@/data/models/domain/AreaCodes'
import type { TmCountryCode } from '@/services/types'

@RepoSettings<Endpoint>({
  model: AreaCodes,
})
@injectable()
export default class AreaCodesRepository extends OrmApiRepository<AreaCodes> {
  public async getAreaCodesForCountry(countryId: TmCountryCode): Promise<AreaCodes> {
    const result = await this.getApiSource().get<AreaCodes>(this.endpointsService.getPath('areaCodes'), {
      params: {
        country: countryId,
      },
    })
    result.data.id = countryId
    // @todo remove after be implementation
    if (countryId === 'US') {
      const firstToDisplay = result.data.areaCodes.find((areaCode) => areaCode.code === 571)
      if (firstToDisplay) {
        result.data.areaCodes = result.data.areaCodes.filter((areaCode) => areaCode.code !== 571)
        result.data.areaCodes[0] = firstToDisplay
      }
    }

    await this.create(result.data)
    return this.find(countryId)
  }
}

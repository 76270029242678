import { NumberField, StringField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import type { Nullable } from '@/types'

export default class KanbanStageBase extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @StringField()
  public color: string

  @NumberField(null)
  public previousId: Nullable<number>
}

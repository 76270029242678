import type { RouteRecordRaw } from 'vue-router'
import { DealsRoutes } from '@/routes/user/deals/types'
import { getDealsPipelineRoutes } from '@/routes/user/deals/dealsPipelines'
import { AppModule } from '@/config/types'
import { getDealsSettingsRoutes } from '@/routes/user/deals/dealsSettings'

export const getDealsRoutes = (): RouteRecordRaw[] => [
  {
    name: DealsRoutes.index,
    path: 'deals',
    props: true,
    meta: {
      tags: ['USER_TOP_MENU'],
      tabTitle: 'pageTitle.user.deals.deals',
      menu: () => import('@/components/domain/deals/DealsMenu.vue'),
      accessControlGroups: ['DealsStrategy'],
      modules: [AppModule.Deals],
    },
    redirect: { name: DealsRoutes.base },
    component: () => import('@/components/views/deals/Deals.vue'),
    children: [...getDealsPipelineRoutes()],
  },
  {
    name: DealsRoutes.settingsIndex,
    path: 'deals/settings',
    props: true,
    meta: {
      parent: DealsRoutes.index,
      tags: ['USER_TOP_MENU'],
      tabTitle: 'pageTitle.user.deals.settings',
      menu: () => import('@/components/domain/deals/settings/DealsSettingsMenu.vue'),
      accessControlGroups: ['DealsStrategy'],
      modules: [AppModule.Deals],
    },
    redirect: { name: DealsRoutes.settingsBase },
    component: () => import('@/components/views/deals/DealsSettings.vue'),
    children: [...getDealsSettingsRoutes()],
  },
]

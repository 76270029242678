import { NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { Currency } from '@/types'
import type { TeamSubscriptionPeriod, TeamSubscriptionType } from '@/services/domain/accounts/types'

@OrmModel(modelNames.TEAM_SUBSCRIPTION_PRICE)
export default class TeamSubscriptionPrice extends BaseModel {
  @StringField()
  public subscriptionType: TeamSubscriptionType

  @StringField()
  public subscriptionPeriod: TeamSubscriptionPeriod

  @StringField()
  public currency: Currency

  @NumberField()
  public cost: number
}

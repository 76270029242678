import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import CounterRepository from '@/data/repositories/table/counterRepository'
import RejectedSmsCounter from '@/data/models/domain/counters/RejectedSmsCounter'

@RepoSettings<Endpoint>({
  model: RejectedSmsCounter,
})
@injectable()
export default class RejectedSmsCounterRepository extends CounterRepository<RejectedSmsCounter> {}

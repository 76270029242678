import { AttrField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.FILTER_STATE)
export default class FilterState extends BaseModel {
  @StringField()
  public id: string

  @AttrField([])
  public visibleFields: string[]
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import UserSettings from '@/data/models/domain/UserSettings'

@RepoSettings<Endpoint>({
  model: UserSettings,
})
@injectable()
export default class UserSettingsRepository extends OrmApiRepository<UserSettings> {}

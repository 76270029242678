import { AttrField, OrmModel, StringField, BooleanField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { Operation } from '@/services/types'
import { type BaseFilterOption, FILTER_TYPE_BASE } from '@/data/models/filters/types'
import type { IconName } from '@/assets/icons/icons'
import type { SelectOption } from '@/services/forms/types'

@OrmModel(modelNames.BASE_FILTER)
export default class BaseFilterModel extends BaseModel {
  public get filterType() {
    return FILTER_TYPE_BASE
  }

  @StringField()
  public id: string

  @StringField()
  public formId: string

  @StringField()
  public name: string

  @StringField()
  public label: string

  @StringField('id')
  public relatedFieldName: string

  @BooleanField(false)
  public isHidden: boolean

  @AttrField('')
  public value: unknown

  @AttrField([])
  public selectedItemsValue: SelectOption[]

  @AttrField('')
  public innerValue: unknown

  @AttrField([])
  public innerSelectedItemsValue: SelectOption[]

  @AttrField()
  public initialValue: unknown

  @AttrField([])
  public availableOperations: Operation[]

  @StringField('in')
  public operation: Operation

  @StringField('in')
  public innerOperation: Operation

  @StringField('in')
  public initialOperation: Operation

  @BooleanField(true)
  public isApplied: boolean

  @AttrField([])
  public options: BaseFilterOption[]

  @BooleanField(false)
  public readonly: boolean

  @BooleanField(false)
  public isDefault: boolean

  @BooleanField(false)
  public openOnAdd: boolean

  @StringField('')
  public icon: IconName

  public type: string

  public setValue() {
    return ''
  }

  public getAvailableOperations(): Operation[] {
    return this.availableOperations
  }

  public getApiValue(): unknown {
    return this.value ?? ''
  }

  public reset() {
    this.value = ''
  }

  public getName(): string {
    return this.name
  }

  public getFieldType(): string {
    return 'text'
  }

  public getCurrentOperation(): Operation {
    return this.operation
  }

  public getRelationField(): string {
    return this.relatedFieldName
  }

  public isChangedAndApplied(): boolean {
    return this.isApplied && (!!this.initialValue || this.isChanged())
  }

  public isChanged(): boolean {
    return this.value !== this.initialValue
  }

  public isAppliedFilter(): boolean {
    return this.isApplied
  }

  public getComponent() {
    return 'Field'
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type { TypedFieldConfig } from '@/services/forms/baseForm/typedFormBuilder/types'
import type { CountrySenderPhoneListFieldConfig, CountrySenderPhoneValue } from '@/services/forms/types'
import type { CountryPhoneData } from '@/constants/countryPhoneData'
import type SubValidationService from '@/services/validation/subValidationService'
import type { BaseValidationRules } from '@/services/validation/validationRulesBuilderService'

type CountrySenderPhoneFieldParams = {
  validationItemRules: BaseValidationRules
  phoneCountryOptions: CountryPhoneData[]
  withCustomNumber?: boolean
}

@injectable()
export class CountrySenderPhoneListFieldBuilderService {
  constructor(
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.SubValidationService) private readonly subValidationService: SubValidationService,
  ) {}

  public getFieldConfig({
    validationItemRules,
    phoneCountryOptions,
    withCustomNumber,
  }: CountrySenderPhoneFieldParams): TypedFieldConfig<CountrySenderPhoneListFieldConfig> {
    return {
      fieldType: 'countrySenderPhoneList',
      value: [],
      componentProps: {
        phoneCountryOptions: () => phoneCountryOptions,
        withCustomNumber: () => withCustomNumber,
      },
      validators: this.validationRulesBuilderService
        .createRules<CountrySenderPhoneValue[]>()
        .custom(...this.subValidationService.forEachItem(validationItemRules)),
    }
  }
}

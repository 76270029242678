import { inject, injectable } from 'inversify'
import type { WrapperOpenableInterface, WrapperParams, WrapperTypesServicesKeys } from '@/services/wrappers/types'
import type BaseWrapperService from '@/services/wrappers/baseWrapperService'
import Modal from '@/data/models/wrappers/Modal'
import { SERVICE_TYPES } from '@/core/container/types'
import type ModalRepository from '@/data/repositories/wrappers/modalRepository'
import type { ModelRaw } from '@/types'
import BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import type { AppModule } from '@/config/types'

@injectable()
export default class ModalService implements WrapperOpenableInterface<Modal> {
  protected wrapperType: WrapperTypesServicesKeys = 'modal'

  protected model = Modal

  constructor(
    @inject(SERVICE_TYPES.BaseWrapperService) protected readonly baseWrapperService: BaseWrapperService<Modal>,
    @inject(SERVICE_TYPES.ModalRepository) protected readonly modalRepository: ModalRepository,
  ) {}

  public async open<T extends WrapperParams = WrapperParams>(wrapperId: string, params?: T) {
    return this.modalRepository.update([
      {
        id: wrapperId,
        isOpen: true,
        params: params || {},
        openTimestamp: Date.now(),
      },
    ])
  }

  public async close(wrapperId: string) {
    this.modalRepository.update([
      {
        id: wrapperId,
        isOpen: false,
        params: {},
      },
    ])
  }

  public closeAllModals() {
    this.modalRepository.getAllRaw().forEach((modal) => {
      if (modal.isOpen) {
        this.close(modal.id)
      }
    })
  }

  public build(wrapperId: string, wrapperType?: WrapperTypesServicesKeys) {
    this.baseWrapperService.build(wrapperId, wrapperType, this.model)
  }

  public getWrapperData(wrapperId: string): ModelRaw<Modal> {
    return this.baseWrapperService.getWrapperData(wrapperId, this.model)
  }

  public setParams(wrapperId: string, params?: WrapperParams): void {
    this.baseWrapperService.setParams(wrapperId, params, this.model)
  }

  public patchParams(wrapperId: string, params?: WrapperParams): WrapperParams {
    return this.baseWrapperService.patchParams(wrapperId, params, this.model)
  }

  public getParams(wrapperId: string) {
    return this.baseWrapperService.getParams(wrapperId, this.model)
  }

  public removeParams(wrapperId: string, paramsToDelete: string[]) {
    this.baseWrapperService.removeParams(wrapperId, paramsToDelete, this.model)
  }

  public clearParams(wrapperId: string) {
    this.baseWrapperService.clearParams(wrapperId, this.model)
  }

  public destroy(wrapperId: string) {
    this.baseWrapperService.destroy(wrapperId, this.model)
  }

  public isOpen(wrapperId: string): boolean {
    return this.modalRepository.find(wrapperId).isOpen
  }

  public isExist(wrapperId: string, model: typeof BaseWrapper = BaseWrapper): boolean {
    return this.baseWrapperService.isExist(wrapperId, model || this.model)
  }

  public setModules(wrapperId: string, modules: AppModule[]) {
    return this.modalRepository.update([
      {
        id: wrapperId,
        modules,
      },
    ])
  }
}

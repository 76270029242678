import { inject, injectable } from 'inversify'
import type { HttpInterceptor, InternalErrorResponse, HttpInterceptorResponseError } from '@/services/transport/types'
import { SERVICE_TYPES } from '@/core/container/types'
import { TmApiStripeInvalidBillingDetailsError } from '@/core/error/transport/tmApiStripeInvalidBillingDetailsError'
import type SubscriptionService from '@/services/transport/subscriptionService'

export const stripeInvalidBillingDetailsEventName = 'stripeInvalidBillingDetailsEventName'

@injectable()
export default class StripeInvalidBillingDetailsInterceptor implements HttpInterceptor<any, InternalErrorResponse> {
  constructor(@inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService) {}

  public async responseError(error: HttpInterceptorResponseError<InternalErrorResponse>) {
    if (error instanceof TmApiStripeInvalidBillingDetailsError) {
      this.subscriptionService.emit(stripeInvalidBillingDetailsEventName, {})
    }
    return Promise.reject(error)
  }
}

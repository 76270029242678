<template>
  <div>
    <template
      v-for="config in wrappersConfigs"
      :key="config.id"
    >
      <tm-wrapper-dialog
        :id="config.id"
        v-bind="config.modalConfig"
      >
        <template v-slot:navigation-buttons>
          <component
            :is="config.modalConfig.navigationComponent"
            v-if="config.modalConfig.navigationComponent"
          />
        </template>

        <div class="w100pr">
          <suspense-resolver
            :resolvers="config.resolvers || []"
            :route-name="currentRouteName"
          >
            <template v-slot="{ resolverProps }">
              <component
                :is="config.component"
                :ref="onComponentMount"
                :resolver-props="resolverProps"
              />
            </template>

            <template
              v-if="config.modalConfig.preloader !== false"
              v-slot:preloader
            >
              <tm-preloader-modal-content v-bind="config.modalConfig">
                <template
                  v-if="config.modalConfig.preloader?.content"
                  v-slot
                >
                  <component
                    :is="config.modalConfig.preloader?.content"
                    v-bind="config.modalConfig.preloader.props"
                  />
                </template>
              </tm-preloader-modal-content>
            </template>
            <template v-slot:failed="{ onRetry }">
              <tm-base-modal-content
                hide-cancel-button
                :size="config.modalConfig.size"
              >
                <template v-slot:modal-content>
                  <tm-error-state
                    class="pb-12"
                    size="small"
                    @on-retry="onRetry"
                  />
                </template>
              </tm-base-modal-content>
            </template>
          </suspense-resolver>
        </div>
      </tm-wrapper-dialog>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from '@/composition/vue/compositionApi'
import {
  getNewQueryModalService,
  getRouterService,
  getWrapperManagerService,
  getWrappersConfigService,
} from '@/core/container/ioc'
import type { TmWrappers } from '@/wrappers'
import type Modal from '@/data/models/wrappers/Modal'
import type { ModelRaw } from '@/types'
import TmErrorState from '@/components/shared/TmErrorState.vue'
import TmWrapperDialog from '@/components/shared/modals/tmModal/TmWrapperDialog.vue'
import SuspenseResolver from '@/components/resolvers/SuspenseResolver.vue'
import TmPreloaderModalContent from '@/components/shared/modals/tmModal/TmPreloaderModalContent.vue'
import TmBaseModalContent from '@/components/shared/modals/tmModal/TmBaseModalContent.vue'
import { TmComponentError } from '@/core/error/TmComponentError'
import { isWrapperConfigItemModal } from '@/components/shared/modals/tmModal/const'

export default defineComponent({
  components: {
    TmBaseModalContent,
    TmPreloaderModalContent,
    SuspenseResolver,
    TmWrapperDialog,
    TmErrorState,
  },
  setup() {
    const wrappersConfigService = getWrappersConfigService()
    const wrapperManagerService = getWrapperManagerService()

    const openedWrappers = computed(() =>
      wrapperManagerService
        .getWrappersByTypes<Modal>(['modal'])
        .filter((wrapper) => wrapper.isOpen)
        .sort((a, b) => a.openTimestamp - b.openTimestamp),
    )
    const openedWrappersQueue = ref<ModelRaw<Modal>[]>([])
    watch(openedWrappers, () => {
      // open first modal
      if (!openedWrappersQueue.value.length && openedWrappers.value.length) {
        openedWrappersQueue.value = [openedWrappers.value[0]]
      }
      // hide closed modals
      const openedIds = openedWrappers.value.map(({ id }) => id)
      openedWrappersQueue.value = openedWrappersQueue.value.filter(({ id }) => openedIds.includes(id))
    })

    const onComponentMount = () => {
      const queueIds = openedWrappersQueue.value.map(({ id }) => id)
      const nextWrapper = openedWrappers.value.find(({ id }) => !queueIds.includes(id))
      if (nextWrapper) {
        openedWrappersQueue.value.push(nextWrapper)
      }
    }

    const wrappersConfigs = computed(() => {
      return openedWrappersQueue.value.map((wrapper) => {
        const wrapperConfig = wrappersConfigService.getWrapperConfig(wrapper.id as TmWrappers)
        if (!isWrapperConfigItemModal(wrapperConfig)) {
          throw new TmComponentError('Config is not WrapperConfigItemModal')
        }
        return wrapperConfig
      })
    })

    const routerService = getRouterService()
    const currentRoute = computed(() => routerService.getCurrentRoute())
    const currentRouteName = computed(() => currentRoute.value.name?.toString() || '')
    watch(currentRoute, () => {
      getNewQueryModalService().closeOrOpenByQuery(openedWrappers.value)
    })

    return {
      currentRouteName,
      wrappersConfigs,
      onComponentMount,
    }
  },
})
</script>

import type { RouteRecordName, RouteRecordRaw } from 'vue-router'
import PassThrough from '@/components/layout/PassThrough.vue'
import { PageAvailableFor } from '@/types'
import type { OAuthProvider } from '@/services/domain/user/types'
import type { AuthByOAuthProviderResolverParams } from '@/services/resolvers/domain/auth/types'
import type { VerifyEmail } from '@/services/route/routerTypes'
import { AppModule } from '@/config/types'

const authByProviders: {
  name: RouteRecordName
  path: string
  provider: OAuthProvider
}[] = [
  {
    name: 'auth.googleAuthCheck',
    path: 'google-auth-check',
    provider: 'google',
  },
  {
    name: 'auth.appleAuthCheck',
    path: 'apple-auth-check',
    provider: 'apple',
  },
  {
    name: 'auth.microsoftAuthCheck',
    path: 'microsoft-auth-check',
    provider: 'microsoft',
  },
]

const verifyEmailRoute: VerifyEmail = { name: 'auth.signup.verifyEmail' }

export const getAuthRoutes = (): RouteRecordRaw[] => [
  {
    name: 'auth',
    path: '',
    component: () => import('@/components/containers/AuthContainer.vue'),
    meta: {
      modules: [AppModule.Auth],
    },
    children: [
      {
        name: 'auth.signup',
        path: 'register',
        component: () => import('@/components/views/auth/SignupPromotion.vue'),
        meta: {
          tabTitle: 'pageTabTitle.auth.signup',
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          modules: [AppModule.CheckEmailForSignup, AppModule.AutoDetectedCountry],
        },
      },
      {
        name: 'auth.signup.oauth',
        path: 'register/oauth',
        component: () => import('@/components/views/auth/SignUpByOAuth.vue'),
        meta: {
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          modules: [AppModule.SignupByOAuthPage, AppModule.AutoDetectedCountry],
        },
      },
      {
        name: 'auth.signup.invitationSubaccount',
        path: 'register/subaccount/token/:token',
        component: () => import('@/components/views/auth/signupByInvitation/SignupByInvitation.vue'),
        meta: {
          tabTitle: 'pageTabTitle.auth.invite',
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          resolvers: [{ service: 'SignUpByInviteTokenCheckResolver' }],
          modules: [AppModule.SignupByInvitePage],
        },
      },
      {
        name: 'auth.signup.invitationUser',
        path: 'register/user/token/:token',
        component: () => import('@/components/views/auth/SignupByInvitationUsers.vue'),
        meta: {
          tabTitle: 'pageTabTitle.auth.invite',
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          resolvers: [{ service: 'SignUpByInviteTokenCheckResolver' }],
          modules: [AppModule.SignupByInvitePage],
        },
      },
      {
        name: 'auth.signup.unavailable',
        path: 'register/unavailable',
        component: () => import('@/components/views/auth/Unavailable.vue'),
        meta: {
          tabTitle: 'pageTabTitle.auth.signupUnavailable',
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          modules: [AppModule.CheckEmailForSignup, AppModule.AutoDetectedCountry],
        },
      },
      {
        ...verifyEmailRoute,
        path: 'verify-email',
        component: () => import('@/components/views/auth/VerifyEmail.vue'),
        meta: {
          tabTitle: 'pageTabTitle.auth.verifyEmail',
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          resolvers: [{ service: 'VerifyEmailResolver' }],
          modules: [AppModule.CheckEmailForSignup, AppModule.VerifyEmail],
        },
      },
      {
        name: 'auth.signup.confirmRegistration',
        path: 'registration/confirm/:token',
        component: PassThrough,
        meta: {
          tabTitle: 'pageTabTitle.auth.signupConfirmRegistration',
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          resolvers: [{ service: 'ConfirmRegistrationResolver' }],
          modules: [AppModule.SignupConfirmation],
        },
      },
      {
        name: 'auth.confirmEmail',
        path: 'confirmation/email/token/:token',
        component: PassThrough,
        meta: {
          tabTitle: 'pageTabTitle.auth.signupConfirmEmail',
          pageAvailableFor: PageAvailableFor.all,
          resolvers: [{ service: 'ConfirmEmailResolver' }],
          modules: [AppModule.EmailConfirmation],
        },
      },
      {
        name: 'auth.logout',
        path: 'logout',
        component: PassThrough,
        meta: {
          resolvers: [{ service: 'LogoutResolver' }],
          modules: [AppModule.Logout],
        },
      },
      {
        name: 'auth.login',
        path: 'login',
        component: () => import('@/components/views/auth/LoginPromotion.vue'),
        meta: {
          tabTitle: 'pageTabTitle.auth.login',
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          modules: [AppModule.AutoDetectedCountry, AppModule.ForgotPassword],
        },
      },
      {
        name: 'auth.loginSSO',
        path: 'login-sso',
        component: () => import('@/components/views/auth/LoginSSO.vue'),
        meta: {
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          modules: [AppModule.LoginSSO],
          resolvers: [{ service: 'LoginSSOQueryErrorResolver' }],
        },
      },
      {
        name: 'auth.login.personal',
        path: 'login/personal',
        component: () => import('@/components/views/auth/Personal.vue'),
        meta: {
          tabTitle: 'pageTabTitle.auth.loginPersonal',
          pageAvailableFor: PageAvailableFor.all,
        },
      },
      {
        name: 'auth.login.suspended',
        path: 'login/suspended',
        component: () => import('@/components/views/auth/Suspended.vue'),
        meta: {
          tabTitle: 'pageTabTitle.auth.accountSuspended',
          pageAvailableFor: PageAvailableFor.notAuthenticated,
        },
      },
      {
        name: 'auth.login.reopenAccount',
        path: 'reopen-account',
        component: () => import('@/components/views/auth/ClosedAccount.vue'),
        meta: {
          tabTitle: 'pageTabTitle.auth.accountClosed',
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          resolvers: [{ service: 'ReopenAccountResolver' }],
        },
      },
      {
        name: 'auth.forgotPassword',
        path: 'forgot-password',
        component: () => import('@/components/views/auth/ForgotPassword.vue'),
        meta: {
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          tabTitle: 'pageTabTitle.auth.forgotPassword',
          modules: [AppModule.ForgotPassword],
        },
      },
      {
        name: 'auth.resetPassword',
        path: 'reset-password/token/:token',
        component: () => import('@/components/views/auth/ResetPassword.vue'),
        meta: {
          tabTitle: 'pageTabTitle.auth.resetPassword',
          pageAvailableFor: PageAvailableFor.all,
          resolvers: [{ service: 'ResetPasswordResolver' }],
          modules: [AppModule.ResetPassword],
        },
      },
      {
        name: 'auth.tfaCommon',
        path: '',
        component: () => import('@/components/views/auth/TfaCommon.vue'),
        meta: {
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          resolvers: [{ service: 'TfaResolver' }],
          modules: [AppModule.TFAPages],
        },
        children: [
          {
            name: 'auth.smsCode',
            path: 'sms-code',
            meta: {
              tabTitle: 'pageTabTitle.auth.smsCode',
            },
            component: () => import('@/components/views/auth/otp/SmsCode.vue'),
          },
          {
            name: 'auth.totpCode',
            path: 'totp-code',
            meta: {
              tabTitle: 'pageTabTitle.auth.smsCode',
            },
            component: () => import('@/components/views/auth/otp/TotpCode.vue'),
          },
          {
            name: 'auth.backupCode',
            path: 'backup-code',
            meta: {
              tabTitle: 'pageTabTitle.auth.smsCode',
            },
            component: () => import('@/components/views/auth/otp/BackupCode.vue'),
          },
          {
            name: 'auth.emailCode',
            path: 'email-code',
            meta: {
              tabTitle: 'pageTabTitle.auth.emailCode',
            },
            component: () => import('@/components/views/auth/EmailCode.vue'),
          },
        ],
      },
      ...authByProviders.map<RouteRecordRaw>(({ name, path, provider }) => ({
        name,
        path,
        component: {
          template: '<div />',
        },
        meta: {
          title: 'common.loading',
          pageAvailableFor: PageAvailableFor.notAuthenticated,
          resolvers: [
            {
              service: 'AuthByProviderResolver',
              params: {
                provider,
              } as AuthByOAuthProviderResolverParams,
            },
          ],
        },
      })),
    ],
  },
]

import { inject, injectable } from 'inversify'
import { pick } from 'lodash-es'
import BaseFormService from '@/services/forms/baseFormService'
import type FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import type { ParamsPartial } from '@/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type ReopenAccountService from '@/services/signUp/reopenAccountService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type LoggerService from '@/services/loggerService'
import type { ReopenAccountFormSchema } from '@/services/forms/auth/types'
import type NotificationService from '@/services/notificationService'
import type TranslateService from '@/services/translateService'
import type WindowService from '@/services/browser/windowService'
import type { TfaAdditionalData } from '@/services/auth/types'
import type AuthService from '@/services/auth/authService'
import { TmApiNeedSmsTfaError } from '@/core/error/transport/customErrors/tmApiNeedSmsTfaError'
import { TmApiNeedEmailTfaError } from '@/core/error/transport/customErrors/tmApiNeedEmailTfaError'

@injectable()
export default class ReopenAccountFormService extends BaseFormService<
  FormBuilderService,
  ParamsPartial,
  ReopenAccountFormSchema
> {
  constructor(
    @inject(SERVICE_TYPES.FormBuilderFactoryService) formBuilderFactoryService: () => FormBuilderService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.ReopenAccountService) protected readonly reopenAccountService: ReopenAccountService,
    @inject(SERVICE_TYPES.NotificationService) protected readonly notificationService: NotificationService,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translationService: TranslateService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.AuthService) protected readonly authService: AuthService,
  ) {
    super(formBuilderFactoryService, em, validationRulesBuilderService, logger)
  }

  public async buildForm(): Promise<void> {
    const builder = this.getBuilder()

    builder.control({
      fieldType: 'password',
      name: 'password' satisfies keyof ReopenAccountFormSchema,
      value: '',
      validators: this.validationRulesBuilderService.createRules().required({
        messagePath: 'auth.closedAccount.form.password.errors.required',
      }),
    })
  }

  public async submit(formData: ReopenAccountFormSchema) {
    try {
      await this.reopenAccountService.reopenAccount(this.reopenAccountService.getLoginOrEmail()!, formData.password)
    } catch (error) {
      if (error instanceof TmApiNeedSmsTfaError) {
        await this.authService.openSmsTfaPage(this.reopenAccountService.getLoginOrEmail()!, error.credentials)
        return
      }
      if (error instanceof TmApiNeedEmailTfaError) {
        const credentials = pick(error.credentials, ['email', 'tfaToken'])
        await this.authService.openEmailTfaPage(
          this.reopenAccountService.getLoginOrEmail()!,
          credentials as TfaAdditionalData,
        )
        return
      }

      throw error
    }

    this.notificationService.success(this.translationService.t('auth.closedAccount.notifications.success'))

    this.windowService.redirect('/')
  }
}

import { inject, injectable } from 'inversify'
import type { WrapperOpenableInterface, WrapperParams, WrapperTypesServicesKeys } from '@/services/wrappers/types'
import type { ModelRaw } from '@/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type BaseWrapperService from '@/services/wrappers/baseWrapperService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import { TmWrapperError } from '@/core/error/tmWrapperError'
import type LoggerService from '@/services/loggerService'
import BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import type BaseWrapperRepository from '@/data/repositories/wrappers/baseWrapperRepository'

@injectable()
export default class PartialUIWrapperService implements WrapperOpenableInterface {
  constructor(
    @inject(SERVICE_TYPES.BaseWrapperService) protected readonly baseWrapperService: BaseWrapperService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {}

  public build(wrapperId: string, wrapperType?: WrapperTypesServicesKeys) {
    this.loggerService.log('partialUI', `Build wrapper - ${wrapperId} with type ${wrapperType}`)
    if (this.isExist(wrapperId)) {
      return
    }
    this.baseWrapperService.build(wrapperId, wrapperType)
  }

  public open(wrapperId: string) {
    this.loggerService.log('partialUI', `Open partial ${wrapperId}`, 'open')
    this.trigger(wrapperId, true)
  }

  public close(wrapperId: string) {
    this.loggerService.log('partialUI', `Close partial ${wrapperId}`, 'close')
    this.trigger(wrapperId, false)
  }

  public getWrapperData(wrapperId: string): ModelRaw<BaseWrapper> {
    return this.baseWrapperService.getWrapperData(wrapperId)
  }

  public setParams(wrapperId: string, params?: WrapperParams): void {
    this.loggerService.log('partialUI', `Set params partial ${wrapperId}`, 'setParams')
    this.loggerService.raw('partialUI', params)
    this.baseWrapperService.setParams(wrapperId, params)
  }

  public patchParams(wrapperId: string, params?: WrapperParams): WrapperParams {
    this.loggerService.log('partialUI', `Patch params partial ${wrapperId}`, 'patchParams')
    this.loggerService.raw('partialUI', params)
    return this.baseWrapperService.patchParams(wrapperId, params)
  }

  public getParams(wrapperId: string) {
    return this.baseWrapperService.getParams(wrapperId)
  }

  public removeParams(wrapperId: string, paramsToDelete: string[]) {
    this.loggerService.log('partialUI', `Remove params partial ${wrapperId}`, 'removeParams')
    this.baseWrapperService.removeParams(wrapperId, paramsToDelete)
  }

  public clearParams(wrapperId: string) {
    this.loggerService.log('partialUI', `Clear params partial ${wrapperId}`, 'clearParams')
    this.baseWrapperService.clearParams(wrapperId)
  }

  public destroy(wrapperId: string) {
    this.loggerService.log('partialUI', `Destroy partial ${wrapperId}`, 'destroy')
    this.getRepository().delete([wrapperId])
  }

  public isOpen(wrapperId: string) {
    const wrapper = this.getWrapperData(wrapperId)
    return wrapper && wrapper.isOpen
  }

  public isExist(wrapperId: string): boolean {
    const wrapper = this.getRepository().find(wrapperId)
    if (!wrapper) {
      this.loggerService.log('partialUI', `Non-exists partial ${wrapperId}`, 'isExists')
    }
    return !!wrapper
  }

  protected getRepository() {
    return this.em.getRepository<BaseWrapperRepository>(BaseWrapper)
  }

  protected trigger(wrapperId: string, isOpen: boolean) {
    if (!this.isExist(wrapperId)) {
      throw new TmWrapperError(`Partial wrapper doesn't exist: ${wrapperId}`)
    }
    this.getRepository().update([
      {
        id: wrapperId,
        isOpen,
      },
    ])
  }
}

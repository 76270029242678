import { injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import GettingStartedInfo from '@/data/models/domain/GettingStartedInfo'
import type GettingStartedInfoRepository from '@/data/repositories/domain/gettingStartedInfoRepository'

@DomainSettings({
  model: GettingStartedInfo,
})
@injectable()
export default class GettingStartedInfoService extends DomainBaseService<GettingStartedInfoRepository> {
  public getInfo() {
    return this.getDomainRepository().getInfo()
  }

  public findInfo() {
    return this.getDomainRepository().findInfo()
  }

  public findInfoOrFail() {
    return this.getDomainRepository().findInfoOrFail()
  }
}

import { injectable } from 'inversify'
import type { AxiosResponse } from 'axios'
import FilterSchema from '@/data/models/tables/FilterSchema'
import { PublicRepo, RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { Endpoint } from '@/services/endpoints'

@RepoSettings<Endpoint>({
  model: FilterSchema,
  fetch: 'schemaFilters',
})
@PublicRepo()
@injectable()
export default class FilterSchemaRepository extends OrmApiRepository<FilterSchema> {
  public async load() {
    this.logGroupStart('load')
    const path = this.getFetchPath()
    const response: AxiosResponse = await this.api.get(path)
    this.log(response)
    this.logGroupEnd()
    return this.model().create({ data: { id: 1, schema: JSON.stringify(response.data) } })
  }

  public async fillAll(isReset = false): Promise<FilterSchema[]> {
    const result = await this.load()
    return result.filtersSchema as FilterSchema[]
  }

  public async fillByFilter() {
    return this.fillAll()
  }
}

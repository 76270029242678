import type { RouteRecordName } from 'vue-router'
// TODO: Fix ignored tp/forbid-import-composable-to-service
// eslint-disable-next-line tp/using-vue-in-services-restriction,tp/forbid-import-composable-to-service
import type { Plugin } from '@/composition/vue/compositionApi'
import { gettingStartedRoutName } from '@/routes/user/getting-started'
import { tenDlcTollFreeRouteNames, TenDlcTollFreeRoutes } from '@/routes/types'
import { regulationsSettingsRouteNames } from '@/routes/user/services/types'
import type { TmCountryCode } from '@/services/types'
import type { OAuthProvider } from '@/services/domain/user/types'
import { ByocMessengerRouteNames, ByocMessengerRoutePaths, ByocRouteNames } from '@/services/domain/byoc/types'

export interface Trackable {
  getPlugin(): Plugin
  getConfig(): TrackableConfig
  initializeTrackerAsPlugin(trackerAsPlugin: ExternalTrackerAsPlugin): Promise<void>
  cleanUp(): void
}

export interface TrackableConfig {
  id: string
  key?: string
  snippetVersion?: number | string
  host?: string
}

export interface ExternalTrackerAsPlugin {
  trackingHandler: () => Promise<void>
  src?: string
  id?: string
  loadOnCustomEvent?: boolean
  isDeferred?: boolean
}

export type InternalTrackingPayload = {
  eventName: string
  properties: Record<string, string>
}

// avoid reserved name https://developer.mozilla.org/en-US/docs/Web/API/TrackEvent
export type TrackingEvent = {
  name: string
  category: TrackingCategory
  properties?: Record<string, unknown>
}

export enum TrackingCategory {
  templates = 'Templates',
  messenger = 'Messenger',
  numbers = 'Numbers',
  payment = 'Payment',
  account = 'Account',
  contacts = 'Contacts',
  settings = 'Settings',
  backOffice = 'Back Office',
  api = 'API',
}

export const zendeskCountries: TmCountryCode[] = ['US', 'CA', 'PR', 'NZ', 'AU', 'GB', 'EE']
export const zendeskVisibleRoutes: string[] = [
  gettingStartedRoutName,
  ...tenDlcTollFreeRouteNames,
  ...regulationsSettingsRouteNames,
  'user.contacts.imports',
  'user.contacts.import',
  'user.contacts.imports.details.imported',
  'user.contacts.imports.details.duplicates',
  'user.contacts.imports.details.failed',
  'user.contacts.imports.details.excluded',
  'auth.forgotPassword',
  'auth.smsCode',
  'auth.emailCode',
  'user.accounts.billingOverview',
  'user.accounts.monthlyPlans.default',
]
export const hotJarRoutes: RouteRecordName[] = [
  'usaFlow.tollFree.info',
  TenDlcTollFreeRoutes.tollFreeRegisterBrand,
  TenDlcTollFreeRoutes.tollFreeRegisterCampaign,
  TenDlcTollFreeRoutes.tollFreeSummary,
  TenDlcTollFreeRoutes.tollFreeSuccess,
  ByocMessengerRouteNames.providers,
  ByocRouteNames.providers,
  ByocRouteNames.edit,
  ByocMessengerRoutePaths.edit,
]
export const hideZendeskWidgetBreakpoint = 1024

export const zukoRoutes: RouteRecordName[] = [
  'usaFlow.tollFree.info',
  TenDlcTollFreeRoutes.tollFreeRegisterBrand,
  TenDlcTollFreeRoutes.tollFreeRegisterCampaign,
  TenDlcTollFreeRoutes.tollFreeSummary,
  TenDlcTollFreeRoutes.tollFreeSuccess,
]
export const zukoTollFreeFormId = '94f45d3c0ef65f89'
export const convertRoutes: RouteRecordName[] = [
  gettingStartedRoutName,
  'user.compose.message',
  'payment.congratsNew',
  'payment.congrats',
  'auth.signup',
  'auth.signup.oauth',
]

export enum GoogleAnalyticsEvent {
  signUp = 'OOLMCOvGwokBENfhgcgD',
  firstPayment = 'u1DxCLi8i4oBENfhgcgD',
  byocNumbersLink = 'zU0uCNve7LQZENfhgcgD',
}

export enum PostHogEvent {
  pageView = '$pageview',
  signUp = 'sign_up',
  byocVisited = 'byoc_clicked',
  signUpFormCompleted = 'Completed Sign up form',
  signUpForFree = `Clicked "Sign up for free"`,
  signUpWithApple = `Clicked "Sign up with Apple"`,
  signUpWithMicrosoft = `Clicked "Sign up with Microsoft"`,
  signUpWithGoogle = `Clicked "Sign up with Google"`,
  tenDlcBrandVisited = `10DLC: Visited "Brand" registration`,
  tenDlcBrandSubmitted = `10DLC: Submitted "Brand" registration`,
  tenDlcCampaignVisited = `10DLC: Visited "Campaign" registration`,
  tenDlcCampaignSubmitted = `10DLC: Submitted "Campaign" registration`,
  tenDlcSummaryVisited = `10DLC: Visited "Summary" page`,
  tenDlcFormSubmitted = `10DLC: Submitted 10DLC registration form`,
  tollFreeBrandVisited = `Toll-free: Visited "Brand" registration`,
  tollFreeBrandSubmitted = `Toll-free: Submitted "Brand" registration`,
  tollFreeCampaignVisited = `Toll-free: Visited "Campaign" registration`,
  tollFreeCampaignSubmitted = `Toll-free: Submitted "Campaign" registration`,
  tollFreeSummaryVisited = `Toll-free: Visited "Summary" page`,
  tollFreeFormSubmitted = `Toll-free: Submitted toll-free registration form`,
  tollFreeVisitedInfoPage = 'Toll-free: Visited “Info” page',
  tollFreeClickedProceedToVerification = 'Toll-free: Clicked “Proceed to verification”',
  stripeBillingBuyCreditModalOpened = `Viewed "Buy credit" modal`,
  viewedAddBillingDetailsModal = `Viewed "Add billing details" modal`,
  clickedContinueWorking = `Clicked "Continue working"`,
  clickedCreateButton = `Clicked "Create" button`,
  pointAiEnabled = 'Point AI enabled',
}

export type PostHogRouteEvent = {
  routeName: RouteRecordName
  event: PostHogEvent
}

export const postHogRoutes: RouteRecordName[] = [ByocRouteNames.flow, ...tenDlcTollFreeRouteNames]

export const postHogRouteEvents: PostHogRouteEvent[] = [
  {
    routeName: ByocRouteNames.flow,
    event: PostHogEvent.byocVisited,
  },
  {
    routeName: TenDlcTollFreeRoutes.tenDlcRegisterBrand,
    event: PostHogEvent.tenDlcBrandVisited,
  },
  {
    routeName: TenDlcTollFreeRoutes.tenDlcRegisterCampaign,
    event: PostHogEvent.tenDlcCampaignVisited,
  },
  {
    routeName: TenDlcTollFreeRoutes.tenDlcSummary,
    event: PostHogEvent.tenDlcSummaryVisited,
  },
  {
    routeName: TenDlcTollFreeRoutes.tollFreeRegisterBrand,
    event: PostHogEvent.tollFreeBrandVisited,
  },
  {
    routeName: TenDlcTollFreeRoutes.tollFreeRegisterCampaign,
    event: PostHogEvent.tollFreeCampaignVisited,
  },
  {
    routeName: TenDlcTollFreeRoutes.tollFreeSummary,
    event: PostHogEvent.tollFreeSummaryVisited,
  },
]

export const postHogOAuthProviderEvents: Record<OAuthProvider, PostHogEvent> = {
  google: PostHogEvent.signUpWithGoogle,
  apple: PostHogEvent.signUpWithApple,
  microsoft: PostHogEvent.signUpWithMicrosoft,
}

export const postHogRegulationsEvents: Record<string, PostHogEvent> = {
  brand_upsertion: PostHogEvent.tenDlcBrandSubmitted,
  brand_submit: PostHogEvent.tenDlcBrandSubmitted,
  campaign_submission: PostHogEvent.tenDlcCampaignSubmitted,
  submission_for_review: PostHogEvent.tenDlcFormSubmitted,
  toll_free_brand_upsertion: PostHogEvent.tollFreeBrandSubmitted,
  toll_free_brand_submission: PostHogEvent.tollFreeBrandSubmitted,
  toll_free_campaign_submission: PostHogEvent.tollFreeCampaignSubmitted,
  toll_free_submission_for_review: PostHogEvent.tollFreeFormSubmitted,
}

export enum ConvertEvent {
  signUpFormCompleted = '100032585',
  signUp = '100032586',
  paymentCompleted = '100032587',
}

import { inject, injectable } from 'inversify'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import { SERVICE_TYPES } from '@/core/container/types'
import { colorMap } from '@/components/shared/types'
import { StatusStyle } from '@/types'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import { StatusBaseEditFieldNames } from '@/services/forms/types'

@injectable()
export default class StatusFieldBuilderService {
  constructor(
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
  ) {}

  public createBaseEditFields(builder: FormBuilderInterface) {
    builder.createField(StatusBaseEditFieldNames.name, 'text', {
      validators: this.validationRulesBuilderService.createRules().required(),
      value: '',
    })
    builder.createField(StatusBaseEditFieldNames.color, 'text', {
      value: colorMap.orange,
    })
    builder.createField(StatusBaseEditFieldNames.style, 'text', {
      value: StatusStyle.filled,
    })
  }
}

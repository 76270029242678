export default class NullLogger {
  public static log(): void {}

  public static groupStart() {}

  public static groupEnd() {}

  public static stack() {}

  public static table() {}

  public static raw() {}

  public static toFormat() {}

  public static time() {}

  public static timeLog() {}

  public static timeEnd() {}
}

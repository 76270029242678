import type { NavigationOrderable } from '@/services/domain/types'
import { isRecordUnknown } from '@/utils/typeGuards'

export const isNavigationOrderable = <ItemKey extends string = string>(
  service: unknown,
): service is NavigationOrderable<ItemKey> => {
  return (
    isRecordUnknown(service) &&
    typeof service.loadNavigationOrder === 'function' &&
    typeof service.updateNavigationOrder === 'function' &&
    typeof service.getNavigationOrder === 'function' &&
    typeof service.getRouteLocationByItemKey === 'function'
  )
}

import { AttrField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.USER_ANALYTICS_FIELD)
export default class UserAnalyticsFields extends BaseModel {
  @AttrField()
  public ajsAnonymousId: string | null

  @AttrField()
  public gaClientId: string | null

  @AttrField()
  public gaSessionId: string | null

  @AttrField()
  public gaSessionNumber: string | null
}

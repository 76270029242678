import { OrmModel, StringField, BooleanField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { TmCountryCode } from '@/services/types'

@OrmModel(modelNames.COUNTRY)
export default class Country extends BaseModel {
  @StringField()
  public id: TmCountryCode

  @StringField()
  public name: string

  @BooleanField(null)
  public isEu: boolean | null

  @BooleanField(false)
  public isVatAllowed: boolean
}

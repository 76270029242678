// src/Textmagic/ServiceBundle/Enum/TenDlc/Brand/BrandStatus.php
import type { TmWrappers } from '@/wrappers'

export enum TenDlcBrandStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  IN_REVIEW = 'IN_REVIEW',
  VERIFIED = 'VERIFIED',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
}

// src/Textmagic/ServiceBundle/Enum/TenDlc/Brand/BrandEntityType.php
export enum TendDlcBrandEntityType {
  PUBLIC = 'PUBLIC_PROFIT',
  PRIVATE = 'PRIVATE_PROFIT',
  SOLE = 'SOLE_PROPRIETOR',
  NON_PROFIT = 'NON_PROFIT',
}

// src/Textmagic/ServiceBundle/Enum/TenDlc/Brand/BrandVerticalType.php
export enum TendDlcBrandVerticalType {
  UNKNOWN = 'UNKNOWN',
  REAL_ESTATE = 'REAL_ESTATE',
  HEALTHCARE = 'HEALTHCARE',
  ENERGY = 'ENERGY',
  ENTERTAINMENT = 'ENTERTAINMENT',
  RETAIL = 'RETAIL',
  AGRICULTURE = 'AGRICULTURE',
  INSURANCE = 'INSURANCE',
  EDUCATION = 'EDUCATION',
  HOSPITALITY = 'HOSPITALITY',
  FINANCIAL = 'FINANCIAL',
  GAMBLING = 'GAMBLING',
  CONSTRUCTION = 'CONSTRUCTION',
  NGO = 'NGO',
  MANUFACTURING = 'MANUFACTURING',
  GOVERNMENT = 'GOVERNMENT',
  TECHNOLOGY = 'TECHNOLOGY',
  COMMUNICATION = 'COMMUNICATION',
  POSTAL = 'POSTAL',
  TRANSPORTATION = 'TRANSPORTATION',
}

export interface TenDlcBrandDetailsModalParams {
  brandId: string
}

export const tenDlcBrandDetailsModalId: TmWrappers = 'tenDlcBrandDetailsModal'

export enum TenDlcTwilioBrandStatus {
  draft = 'draft',
  supportReview = 'support_review',
  providerReview = 'provider_review',
  verified = 'verified',
  actionRequired = 'action_required',
}

export enum TendDlcTwilioBrandOrganizationType {
  public = 'public',
  private = 'private',
  government = 'government',
  nonProfit = 'non-profit',
}

export enum TendDlcTwilioBrandLegalStructure {
  coOperative = 'Co-operative',
  corporation = 'Corporation',
  limitedLiability = 'Limited Liability Corporation',
  nonProfitCorporation = 'Non-profit Corporation',
  partnership = 'Partnership',
}

export enum TenDlcTwilioBrandContactJobPosition {
  director = 'Director',
  gm = 'GM',
  vp = 'VP',
  ceo = 'CEO',
  cfo = 'CFO',
  counsel = 'General Counsel',
  other = 'Other',
}

export enum TenDlcTwilioTaxNumberIdentifier {
  ein = 'EIN',
  duns = 'DUNS',
  cbn = 'CBN',
  cn = 'CN',
  acn = 'ACN',
}

export enum TenDlcTwilioBusinessRegion {
  usAndCa = 'USA_AND_CANADA',
  africa = 'AFRICA',
  asia = 'ASIA',
  europe = 'EUROPE',
  latinAmerica = 'LATIN_AMERICA',
}

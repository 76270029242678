import { get } from '@/core/container/ioc'
import type { UseTableBuilderSettings } from '@/composition/tables'
import type TableGrouperManagerInterface from '@/services/tables/managers/tableGrouperManagerInterface'
import type { Endpoint } from '@/services/endpoints'

type UseGrouperSettings = Pick<UseTableBuilderSettings, 'tableModelId' | 'tableGrouperManager' | 'grouperFactory'> & {
  endpoint: Endpoint
}

export const useGroupers = (settings: Pick<UseGrouperSettings, 'tableGrouperManager'>) =>
  get<TableGrouperManagerInterface>(settings.tableGrouperManager ?? 'TableGrouperManager')

export const createGroupers = (settings: UseGrouperSettings) => {
  const manager = get<TableGrouperManagerInterface>(settings.tableGrouperManager ?? 'TableGrouperManager')

  manager.setEndpointForTable(settings.tableModelId, settings.endpoint)
  if (settings.grouperFactory) {
    manager.setFactoryForTable(settings.tableModelId, settings.grouperFactory)
  }

  return useGroupers(settings)
}

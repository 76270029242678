import { inject, injectable } from 'inversify'
import type BaseModel from '@/data/models/BaseModel'
import type { Repositories, RepoType } from '@/data/repositories/types'
import { REPO_TYPES } from '@/data/repositories/types'
import type ORMBaseRepository from '@/data/repositories/ormBaseRepository'
import { TmRepositoryError } from '@/core/error/tmRepositoryError'
import { SERVICE_TYPES, type GetLocator } from '@/core/container/container'
import type VuexService from '@/services/vuex/vuexService'
import { ServiceGroups, type Service } from '@/config/types'
import type { RegisteredServices } from '@/core/container/types'
import type { Dict } from '@/types'
import { REFLECT_METADATA } from '@/decorators/types'
import type { IServiceManager } from '@/core/middlewares/types'
import { getLoggerService } from '@/core/container/ioc'
import TmLogicError from '@/core/error/tmLogicError'

@injectable()
export default class EntityManagerService implements IServiceManager {
  private repos: Record<RepoType, Dict<{ id: RegisteredServices; model: typeof BaseModel }>>

  private cache: Record<RepoType, Repositories>

  constructor(
    @inject(SERVICE_TYPES.VuexService) private readonly vuexService: VuexService,
    @inject('GetLocator') private readonly get: GetLocator,
  ) {
    const repos: Record<string, Repositories> = {}
    this.repos = {} as Record<RepoType, Dict<{ id: RegisteredServices; model: typeof BaseModel }>>

    for (const value of Object.keys(REPO_TYPES)) {
      repos[value as RepoType] = {}
      this.repos[value as RepoType] = {}
    }
    this.cache = repos
  }

  public addService(repository: Service, group?: RepoType) {
    const settings = Reflect.getMetadata(REFLECT_METADATA.RepoSettings, repository.bindingValue)
    if (!settings) {
      getLoggerService().raw('error', repository)
      throw new TmLogicError('Please use @RepositorySettings decorator')
    }
    const model = settings.model
    const groupName = this.getRepoGroupByName(group)
    this.repos[groupName][model.entity] = {
      id: repository.id! as RegisteredServices,
      model,
    }
    const db = this.vuexService.getDB()
    if (db) {
      if (!db.schemas[model.entity]) {
        db.register(model)
      }
    }
  }

  public getRepository<T extends ORMBaseRepository<any, any>>(model: typeof BaseModel, group?: RepoType): T {
    return this.getRepositoryByModelNameNew(model.entity, group)
  }

  public getRepositoryByModelNameNew<T extends ORMBaseRepository<any, any>>(entity: string, group?: RepoType): T {
    const groupName = this.getRepoGroupByName(group)
    const repo = this.cache[groupName][entity]
    if (repo) {
      return repo as T
    }
    if (!this.repos[groupName][entity]) {
      throw new TmRepositoryError(`Not found repository for ${entity}`)
    }
    const info = this.repos[groupName][entity]
    const result = this.get(info.id) as T
    this.cache[groupName][entity] = result
    return result
  }

  public all(group: RepoType): Repositories {
    return this.cache[group]
  }

  private getRepoGroupByName(group?: RepoType) {
    return group || ServiceGroups.REPOSITORIES
  }
}

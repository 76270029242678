import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/container'
import type ReopenAccountRepository from '@/data/repositories/reopenAccountRepository'
import type { Optional } from '@/types'
import ReopenAccount from '@/data/models/ReopenAccount'
import type EntityManagerService from '@/data/repositories/entityManagerService'

@injectable()
export default class ReopenAccountService {
  constructor(@inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService) {}

  public async reopenAccount(loginOrEmail: string, password: string) {
    await this.getRepository().reopenAccount(loginOrEmail, password)
    await this.getRepository().clearReopenAccountData()
  }

  public fetchReopenAccountData(): Promise<Optional<ReopenAccount>> {
    return this.getRepository().fetchReopenAccountData()
  }

  public async storeReopenAccountData(signUpData: Pick<ReopenAccount, 'loginOrEmail'>) {
    await this.getRepository().storeReopenAccountData(signUpData)
  }

  public getLoginOrEmail(): Optional<string> {
    return this.getRepository().getReopenAccountData()?.loginOrEmail
  }

  protected getRepository(): ReopenAccountRepository {
    return this.em.getRepository<ReopenAccountRepository>(ReopenAccount)
  }
}

import { injectable, inject } from 'inversify'
import Notifications from '@kyvg/vue3-notification'
import { Quasar } from 'quasar'
import { SERVICE_TYPES } from '@/core/container/types'
// eslint-disable-next-line
import type { App, Component } from '@/composition/vue/compositionApi'
// eslint-disable-next-line
import { createApp } from '@/composition/vue/compositionApi'
import type VuexService from '@/services/vuex/vuexService'
import type RouterService from '@/services/route/routerService'
import type TranslateService from '@/services/translateService'
import quasarOptions from '@/quasarOptions'
import type TrackingService from '@/services/tracking/trackingService'
import type AuthService from '@/services/auth/authService'
import type { MonitoringServiceInterface } from '@/services/monitoring/types'
import { isDev } from '@/utils/system'
import type WindowService from '@/services/browser/windowService'
import { safeHtmlDirective } from '@/directives/safe-html/safe-html.directive'
import { outerClickDirective } from '@/directives/outer-click.directive'
import { tooltipDirective } from '@/directives/tooltip.directive'

@injectable()
class VueService {
  constructor(
    @inject(SERVICE_TYPES.VuexService) protected readonly vuexService: VuexService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    @inject(SERVICE_TYPES.MonitoringService)
    protected readonly monitoringService: MonitoringServiceInterface,
    @inject(SERVICE_TYPES.TrackingService) protected readonly trackingService: TrackingService,
    @inject(SERVICE_TYPES.AuthService) protected readonly authService: AuthService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
  ) {}

  public createVueApp(rootComponent: Component, props?: Record<string, unknown> | null) {
    return createApp(rootComponent, props)
  }

  public async create(selector: string, rootComponent: Component): Promise<App> {
    const app = this.createVueApp(rootComponent)
    app.config.performance = isDev()
    const router = this.routerService.getRouter()
    this.monitoringService.init(app, router)
    this.trackingService.registerApp(app)
    app.use(Quasar, quasarOptions)
    app.use(this.vuexService.getStore())

    app.config.globalProperties.$t = this.translateService.t as typeof app.config.globalProperties.$t
    app.config.globalProperties.$tc = this.translateService.tc as typeof app.config.globalProperties.$tc
    app.use(this.translateService.getPlugin())

    const getCurrentUser: (() => Promise<Response>) | null = (this.windowService.self() as any).__getCurrentUser

    if (getCurrentUser) {
      await this.authService.tryFillCurrentUser(() => {
        app.use(router)
      }, true)
    } else {
      app.use(router)
    }
    app.use(Notifications)
    app.directive('safe-html', safeHtmlDirective)
    app.directive('outer-click', outerClickDirective)
    app.directive('tooltip', tooltipDirective)

    app.mount(selector)

    return app
  }
}

export default VueService

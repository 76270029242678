import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.FACEBOOK_CONFIG)
export default class FacebookConfig extends BaseModel {
  @StringField()
  public appId: string

  @StringField()
  public configId: string
}

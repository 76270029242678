import 'reflect-metadata'
import { isFunction } from 'lodash-es'
import { getLoggerService } from '@/core/container/ioc'
import { TmBaseError } from '@/core/error/tmBaseError'
import type { LoggerChannels } from '@/config/configDev'
import { isDev } from '@/utils/system'

export type ErrorHandler<T extends Error = Error> = (error: T, context: any) => void | never

export function copyMetadata(from: any, to: any) {
  const metadataKeys = Reflect.getMetadataKeys(from)
  metadataKeys.forEach((key) => {
    const value = Reflect.getMetadata(key, from)
    Reflect.defineMetadata(key, value, to)
  })
}

export const Catch =
  <T extends Error = Error>(
    errorType: any,
    handler: ErrorHandler<T>,
    inverse = false,
    exclude: (string | symbol)[] = [],
    devOnly = true,
  ): any =>
  (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    if (descriptor) {
      return _generateDescriptor<T>(descriptor, errorType, handler, inverse, devOnly)
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const propertyName of Reflect.ownKeys(target.prototype).filter((prop) => prop !== 'constructor')) {
      if (exclude?.includes(propertyName)) {
        continue
      }
      const desc = Object.getOwnPropertyDescriptor(target.prototype, propertyName)!
      const isMethod = desc.value instanceof Function
      if (!isMethod) {
        continue
      }
      Object.defineProperty(
        target.prototype,
        propertyName,
        _generateDescriptor(desc, errorType, handler, inverse, devOnly),
      )
    }
    return undefined
  }

function _generateDescriptor<T extends Error = Error>(
  descriptor: PropertyDescriptor,
  errorType: T,
  handler: ErrorHandler<T>,
  inverse = false,
  devOnly = true,
): PropertyDescriptor {
  const originalMethod = descriptor.value
  descriptor.value = function (...args: any[]) {
    if (!devOnly && !isDev()) {
      return originalMethod.apply(this, args)
    }
    try {
      const result = originalMethod.apply(this, args)
      if (result && result instanceof Promise) {
        return result.catch((error: any) =>
          (inverse ? _handleErrorInversion : _handleError)(this, errorType, handler, error),
        )
      }
      return result
    } catch (error) {
      if (error instanceof Error) {
        ;(inverse ? _handleErrorInversion : _handleError)<T>(this, errorType, handler, error as T)
      }
      return undefined
    }
  }
  if (originalMethod !== descriptor.value) {
    copyMetadata(originalMethod, descriptor.value)
  }
  return descriptor
}

function _handleError<T extends Error>(context: any, errorType: any, handler: ErrorHandler<T>, error: T) {
  if (isFunction(handler) && error instanceof errorType) {
    handler(error, context)
  } else {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw error
  }
}

function _handleErrorInversion<T extends Error>(context: any, errorType: any, handler: ErrorHandler<T>, error: T) {
  if (!isFunction(handler) || error instanceof errorType) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw error
  } else if (isFunction(handler)) {
    handler(error, context)
  }
}

export const CatchAll = (handler: ErrorHandler) => Catch(Error, handler)

export const TransformError = (ErrorToTransform: any) =>
  Catch(
    ErrorToTransform,
    (err: Error | TmBaseError) => {
      logErrorTransform(err, ErrorToTransform)
      if (TmBaseError.is(err)) {
        throw new ErrorToTransform(err.message, (err as TmBaseError).getData(), err.stack)
      }
      throw new ErrorToTransform(err.message, {}, err.stack)
    },
    true,
  )

export const logError = (ErrorToLog: any, channel: LoggerChannels = 'error', subChannel?: string, devOnly = true) =>
  Catch(
    ErrorToLog,
    (err: Error, ctx: any) => {
      getLoggerService().log(
        channel,
        `${err.name}:${err.message}`,
        subChannel || ctx.constructor?.name || ctx.prototype?.constructor?.name || '',
      )
      throw err
    },
    false,
    [],
    devOnly,
  )

const logErrorTransform = (sourceError: Error, destError: any) => {
  getLoggerService().log(
    'exception',
    `Exception "${sourceError.constructor.name}" converted to "${destError.name}"`,
    'forDebug',
  )
}

import { injectable } from 'inversify'
import type { WrapperConfigItem, WrapperTypesServicesKeys } from '@/services/wrappers/types'
import { type TmWrappers, wrappersConfig } from '@/wrappers'
import { TmWrapperError } from '@/core/error/tmWrapperError'
import type { ResolverConfig, ResolverItem } from '@/services/types'
import type { AppModuleResolverParams } from '@/services/resolvers/types'

export type WrapperConfigItemWithId = WrapperConfigItem & {
  id: TmWrappers
}

@injectable()
export default class WrappersConfigService {
  public hasWrapperConfig(id: TmWrappers): boolean {
    return id in wrappersConfig
  }

  public getWrapperConfig(id: TmWrappers): WrapperConfigItemWithId {
    if (!this.hasWrapperConfig(id)) {
      throw new TmWrapperError(`Wrapper config with id "${id}" does not exist`)
    }
    return {
      ...this.modulesToModuleResolver(wrappersConfig[id]),
      id,
    }
  }

  public getWrapperType(id: TmWrappers): WrapperTypesServicesKeys | undefined {
    if (!this.hasWrapperConfig(id)) {
      return undefined
    }
    return this.getWrapperConfig(id).type
  }

  protected modulesToModuleResolver(config: Readonly<WrapperConfigItem>): WrapperConfigItem {
    if (!config.modules?.length) {
      return config
    }

    const sequentialResolvers: ResolverItem = config.resolvers ? [...(config.resolvers as ResolverItem)] : []
    sequentialResolvers.unshift({
      service: 'AppModuleResolverService',
      params: {
        modules: config.modules,
      },
    } satisfies ResolverConfig<AppModuleResolverParams>)

    return {
      ...config,
      resolvers: [sequentialResolvers],
    }
  }
}

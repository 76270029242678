<template>
  <div
    class="page-content-sidebar-draggable-list"
    :class="{
      'page-content-sidebar-draggable-list--move': showMoveClass,
    }"
  >
    <tm-draggable
      v-model="internalItems"
      item-key="id"
      :dragging-cursor="'grabbing'"
      @start="onStart"
      @end="onEnd"
      @sort="$emit('sort', $event)"
    >
      <template v-slot:item="{ element }">
        <page-content-sidebar-list-item
          v-bind="element as any"
          :title="element.title"
          show-drag-indicator
          :in-group="inGroup"
          @action-click="$emit('action-click', $event)"
          @lock-sidebar="$emit('lock-sidebar', $event)"
        >
          <template v-slot:icon>
            <slot
              name="icon"
              v-bind="{ item: element }"
            />
          </template>
          <template v-slot:after-link-content>
            <slot
              name="after-link-content"
              v-bind="{ item: element }"
            />
          </template>
        </page-content-sidebar-list-item>
      </template>
    </tm-draggable>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from '@/composition/vue/compositionApi'
import PageContentSidebarListItem from '@/components/layout/pageContent/sidebar/sidebarItem/PageContentSidebarListItem.vue'
import TmDraggable from '@/components/shared/TmDraggable.vue'
import type { DraggableSortEvent, DraggableStartEndEvent } from '@/components/shared/types'
import type {
  PageContentSidebarItem,
  PageContentSidebarItemAction,
} from '@/components/layout/pageContent/sidebar/types'

const props = defineProps<{
  items: PageContentSidebarItem[]
  inGroup?: boolean
}>()

defineEmits<{
  'action-click': [_action: PageContentSidebarItemAction]
  sort: [_e: DraggableSortEvent] // Event when you move an item in the list
  'lock-sidebar': [_lock: boolean]
}>()

const moveClass = 'draggable-item--moved'
const item = ref<HTMLElement>()
const isMove = ref(false)
const showMoveClass = ref(false)
const internalItems = ref([...props.items])

const onStart = (e: DraggableStartEndEvent) => {
  e.item.classList.add(moveClass)
  isMove.value = true
  showMoveClass.value = true
}

const onEnd = (e: DraggableStartEndEvent) => {
  item.value = e.item
  isMove.value = false
}

const onMouseMove = () => {
  if (!isMove.value && showMoveClass.value) {
    showMoveClass.value = false
    item.value?.classList.remove(moveClass)
  }
}

onMounted(() => {
  document.addEventListener('mousemove', onMouseMove)
})

onUnmounted(() => {
  document.removeEventListener('mousemove', onMouseMove)
})

watch(
  () => props.items,
  () => {
    internalItems.value = [...props.items]
  },
)
</script>

<style lang="scss" scoped>
.page-content-sidebar-draggable-list :deep(.page-content-sidebar-list-link) {
  color: $emphasize;
}
:deep {
  .draggable-item + .draggable-item {
    margin-top: 2px;
  }
}
.page-content-sidebar-draggable-list--move {
  &:deep(.draggable-item--moved) {
    .menu-item {
      background: #eeeff4 !important;

      .show-on-hover {
        opacity: 1 !important;
      }

      .hide-on-hover {
        display: none !important;
      }
    }
  }

  &:deep(.draggable-item) {
    .menu-item {
      .show-on-hover {
        opacity: 0;
      }

      .hide-on-hover {
        display: flex;
      }

      &:hover {
        background: unset;
      }
    }
  }
}
</style>

import { inject, injectable } from 'inversify'
import type { Gridable } from '@/services/types'
import type { PaginationParams } from '@/services/tables/pagination/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { EndpointParams } from '@/services/endpoints'
import type { PaginationUrlType } from '@/services/tables/types'
import type TenDlcPhoneNumbersRepository from '@/data/repositories/domain/services/regulations/tenDlcPhoneNumbersRepository'
import TenDlcPhoneNumber from '@/data/models/domain/services/regulations/TenDlcPhoneNumber'
import type RouterService from '@/services/route/routerService'
import type EntityManagerService from '@/data/repositories/entityManagerService'

@injectable()
export default class TenDlcPhoneNumbersGridable implements Gridable<TenDlcPhoneNumber> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
  ) {}

  public getFetchEndpointParams(): EndpointParams {
    return []
  }

  public async gridRequest(
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
    searchFields?: string[],
  ) {
    return this.getRepo().gridRequest(
      {
        ...queryParameterBag,
        other: {
          campaignId: this.routerService.getCurrentRoute().params.campaignId,
        },
      },
      paginationParamsBag,
      searchQuery,
      searchFields,
    )
  }

  protected getRepo() {
    return this.em.getRepository<TenDlcPhoneNumbersRepository>(TenDlcPhoneNumber)
  }
}

import { AttrField, BelongsToField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { CreatedBy } from '@/data/models/domain/types'
import EmailCampaignMessage from '@/data/models/domain/campaign/EmailCampaignMessage'

export enum EmailCampaignStatus {
  Sent = 'sent',
  Failed = 'failed',
  Delivered = 'delivered',
  Rejected = 'rejected',
  Error = 'error',
  InProgress = 'in_progress',
}

export type EmailCampaignTotals = {
  bounced: number
  failed: number
  clicked: number
  delivered: number
  opened: number
  recipients: number
  rejected: number
  spamReports: number
  unsubscribed: number
}

@OrmModel(modelNames.EMAIL_CAMPAIGN)
export default class EmailCampaign extends BaseModel {
  /**
   * @deprecated - will be removed
   */
  @StringField()
  public title: string

  @StringField()
  public subject: string

  @StringField()
  public html: string

  @StringField()
  public startAt: string

  @StringField()
  public status: EmailCampaignStatus

  @StringField()
  public fromName: string

  @StringField()
  public fromEmail: string

  @NumberField()
  public cost: number

  @AttrField()
  public createdBy: CreatedBy

  @AttrField()
  public totals: EmailCampaignTotals

  @AttrField()
  public outboundEmailId: unknown

  @BelongsToField(EmailCampaignMessage, 'outboundEmailId')
  public outboundEmail: EmailCampaignMessage | null

  public get isBulk() {
    return this.totals.recipients > 1
  }
}

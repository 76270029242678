import { inject, injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import PhoneNumber from '@/data/models/domain/PhoneNumber'
import type PhoneNumbersRepository from '@/data/repositories/domain/phoneNumbersRepository'
import type { BuyNumberBody, ChangeForwardingBody } from '@/data/repositories/domain/phoneNumbersRepository'
import type { DedicatedNumbersQueryParams } from './types'
import { ModelEventType } from '@/services/transport/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type UserService from '@/services/domain/user/userService'
import type { PaginationParams } from '@/services/tables/pagination/types'
import type { PaginationUrlType } from '@/services/tables/types'
import { ForwardCallsToVariant } from './types'
import type TeamWorkflowService from '@/services/domain/accounts/teamWorkflowService'
import type UserSettingsService from '@/services/domain/user/userSettingsService'

@DomainSettings({
  model: PhoneNumber,
})
@injectable()
export default class PhoneNumbersService extends DomainBaseService<PhoneNumbersRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
    @inject(SERVICE_TYPES.TeamWorkflowService) protected readonly teamWorkflowService: TeamWorkflowService,
    @inject(SERVICE_TYPES.UserSettingsService) protected readonly userSettingsService: UserSettingsService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public isAllowBuyCaLocalNumber() {
    return true
  }

  public async updatePhoneLabel(id: string, label: string) {
    const repository = this.getDomainRepository()
    await repository.updatePhoneLabel(id, label)
    this.notify([id], ModelEventType.UPDATE)
  }

  public async buyNumber(body: BuyNumberBody) {
    return this.getDomainRepository().buyNumber(body)
  }

  public getNumberById(id: string) {
    return this.getDomainRepository().getNumberById(id)
  }

  public getNumberByPhone(phone: string) {
    return this.getDomainRepository().getNumberByPhone(phone)
  }

  public fill(id: string) {
    return this.getDomainRepository().fill(id)
  }

  public findEntityWithAll(id: string) {
    return this.getDomainRepository().query().withAll().find(id)
  }

  public async changeForwardingNumber(body: ChangeForwardingBody) {
    await this.getDomainRepository().changeForwardingNumber(body)
    this.notify([body.numberId], ModelEventType.UPDATE)
  }

  public async fetchPhoneNumbersByUserId(userId: string) {
    const params: DedicatedNumbersQueryParams = {
      userId,
    }

    const pagination: PaginationParams = {
      requestParams: {
        page: 1,
        perPage: 1e3,
      },
      pageKey: '',
    }

    const { items } = await this.getDomainRepository().fetchPhoneNumbers(params, pagination)
    return items
  }

  public getVoiceableNumbers() {
    const currentUser = this.userService.currentUser()
    const parentId = this.userSettingsService.currentUserSettingsOrFail().parentId
    const userId = this.teamWorkflowService.isUsersWorkflow() ? parentId : currentUser.id
    return this.getNumbersByFilter({
      filter: [],
      other: {
        userId,
        voiceable: true,
      },
    })
  }

  public getNumbersByFilter(queryParameterBag: PaginationUrlType, paginationParamsBag?: PaginationParams) {
    return this.getDomainRepository().gridRequest(queryParameterBag, paginationParamsBag)
  }

  public isAllowSetUpCallForwarding(phoneNumber: Pick<PhoneNumber, 'voiceAvailable' | 'forwardCallsTo'>) {
    return phoneNumber.voiceAvailable && phoneNumber.forwardCallsTo !== ForwardCallsToVariant.notSupported
  }

  public async restoreNumber(id: string) {
    await this.getDomainRepository().restoreNumber(id)
    this.notify([id], ModelEventType.UPDATE)
  }

  public async cancelNumber(id: string) {
    await this.getDomainRepository().cancelNumber(id)
    this.notify([id], ModelEventType.UPDATE)
  }
}

import { inject, injectable } from 'inversify'
import type {
  WrapperOpenableInterface,
  GroupedWrapperInterface,
  PartialUIConfig,
  WrapperParams,
  WrapperTypesServicesKeys,
} from '@/services/wrappers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { ParamsPartial } from '@/types'
import { TmWrapperError } from '@/core/error/tmWrapperError'

@injectable()
export default class GroupedPartialUIWrapperService implements GroupedWrapperInterface<ParamsPartial> {
  constructor(
    @inject(SERVICE_TYPES.PartialUIWrapperService) protected readonly partialUIWrapperService: WrapperOpenableInterface,
  ) {}

  public buildGroup<T extends ParamsPartial>(config: PartialUIConfig<T>) {
    this.checkUniqueness(config)
    this.build(config.name, config.type)
    config.items.forEach((part) => {
      this.build(this.getName(config.name, part.name), config.type)
      if (part.isInitiallyOpen) {
        this.openGroup([part.name], config)
      }
    })
  }

  public openGroup<T extends ParamsPartial>(parts: string[], config: PartialUIConfig<T>) {
    parts.forEach((part) => {
      this.partialUIWrapperService.open(this.getName(config.name, part))
    })
  }

  public closeGroup<T extends ParamsPartial>(parts: string[], config: PartialUIConfig<T>) {
    parts.forEach((part) => {
      this.partialUIWrapperService.close(this.getName(config.name, part))
    })
  }

  public destroyGroup<T extends ParamsPartial>(config: PartialUIConfig<T>) {
    this.partialUIWrapperService.destroy(config.name)
    config.items.forEach((part) => {
      this.partialUIWrapperService.destroy(this.getName(config.name, part.name))
    })
  }

  public groupAction<T extends ParamsPartial>(
    config: PartialUIConfig<T>,
    parts: Array<{ name: string; isOpen: boolean }>,
  ) {
    this.openGroup(
      parts.filter((p) => p.isOpen).map((p) => p.name),
      config,
    )
    this.closeGroup(
      parts.filter((p) => !p.isOpen).map((p) => p.name),
      config,
    )
  }

  public build(wrapperId: string, wrapperType: WrapperTypesServicesKeys) {
    this.partialUIWrapperService.build(wrapperId, wrapperType)
  }

  public clearParams(wrapperId: string) {
    this.partialUIWrapperService.clearParams(wrapperId)
  }

  public destroy(wrapperId: string) {
    this.partialUIWrapperService.destroy(wrapperId)
  }

  public getParams(wrapperId: string): WrapperParams {
    return this.partialUIWrapperService.getParams(wrapperId)
  }

  public getWrapperData(wrapperId: string) {
    return this.partialUIWrapperService.getWrapperData(wrapperId)
  }

  public patchParams(wrapperId: string, params?: WrapperParams): WrapperParams {
    return this.partialUIWrapperService.patchParams(wrapperId, params)
  }

  public removeParams(wrapperId: string, paramsToDelete: string[]): void {
    return this.partialUIWrapperService.removeParams(wrapperId, paramsToDelete)
  }

  public setParams(wrapperId: string, params?: WrapperParams): void {
    return this.partialUIWrapperService.setParams(wrapperId, params)
  }

  public openedInGroup<T extends ParamsPartial>(config: PartialUIConfig<T>): Record<string, boolean> {
    return config.items.reduce((acc: Record<string, boolean>, item) => {
      acc[item.name] = this.partialUIWrapperService.isOpen(this.getName(config.name, item.name))
      return acc
    }, {})
  }

  public isExistGroup<T extends ParamsPartial = ParamsPartial>(config: PartialUIConfig<T>): boolean {
    return (
      this.partialUIWrapperService.isExist(config.name) &&
      config.items.every((part) => this.partialUIWrapperService.isExist(this.getName(config.name, part.name)))
    )
  }

  protected checkUniqueness<T extends ParamsPartial = ParamsPartial>(config: PartialUIConfig<T>): void {
    const uniqueNames = config.items.reduce((acc: Record<string, string>, item) => {
      acc[item.name] = item.name
      return acc
    }, {})

    if (Object.values(uniqueNames).length !== config.items.length) {
      throw new TmWrapperError(`Duplicate items in partial ${config.name}`)
    }
  }

  protected getName(name: string, part: string) {
    return `${name}.${part}`
  }
}

<template>
  <div class="page-content-main-block">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'

export default defineComponent({})
</script>
<style lang="scss" scoped>
.page-content-main-block {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}
</style>

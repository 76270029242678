import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum PINNED_CONTACTS_SERVICES {
  PinnedContactsRepository,
  PinnedContactsService,
  PinnedContactsResolverService,
  AddPinnedItemFormService,
  PinnedContactsPreloaderService,
}

export type PinnedContactsServices = keyof typeof PINNED_CONTACTS_SERVICES

export const PINNED_CONTACTS_SERVICE_TYPES = makeServicesTypesFromEnum<PinnedContactsServices>(PINNED_CONTACTS_SERVICES)

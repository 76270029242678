<template>
  <div
    class="page-content-sidebar-list-title"
    :class="{ 'page-content-sidebar-list-title_margin-top': !resetMarginTop }"
    :style="{ paddingLeft: paddingLeft || paddingX, paddingRight: paddingRight || paddingX }"
  >
    <slot name="title">
      <span>{{ title }}</span>
    </slot>
    <tm-button
      v-if="showAddButton"
      icon-only
      size="small"
      class="page-content-sidebar-list-title__add-btn"
      :class="{ 'page-content-sidebar-list-title__add-btn--dark': dark }"
      color="transparent"
      flat
      @click="$emit('add-btn-click')"
    >
      <tm-icon name="add" />
    </tm-button>
  </div>
</template>

<script lang="ts">
import type { PropType } from '@/composition/vue/compositionApi'
import { defineComponent } from '@/composition/vue/compositionApi'
import TmButton from '@/components/shared/TmButton.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import type { StylesheetSizeProp } from '@/types'

export default defineComponent({
  components: { TmIcon, TmButton },
  props: {
    dark: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    showAddButton: {
      type: Boolean,
    },
    paddingX: {
      type: String as PropType<StylesheetSizeProp>,
      default: '16px',
    },
    paddingLeft: {
      type: String as PropType<StylesheetSizeProp>,
    },
    paddingRight: {
      type: String as PropType<StylesheetSizeProp>,
    },
    resetMarginTop: {
      type: Boolean,
    },
  },
  emits: {
    'add-btn-click': () => true,
  },
})
</script>

<style lang="scss" scoped>
.page-content-sidebar-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 18px;
  font-size: $sm-font;
  font-weight: $bold-font;
  color: $gray5;
  text-transform: uppercase;
  cursor: default;
  &_margin-top {
    margin-top: 8px;
  }
  &__add-btn {
    margin: -6px -8px -6px 0;
    &:not(:hover) {
      background-color: transparent !important;
    }
    &--dark {
      color: $light !important;
      &:hover:not([disabled]) {
        background-color: $neutral !important;
      }
    }
  }
  .tm-icon {
    font-weight: $semi-bold-font;
  }
}
</style>

<template>
  <component
    :is="disabled ? 'span' : 'router-link'"
    v-bind="$props"
    :disabled="disabled || undefined"
    @click="clickHandler"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import {
  type RouteLocationNormalizedGeneric,
  type RouteRecordRedirectOption,
  type RouterLinkProps,
  useLink,
} from 'vue-router'
import { isEqual } from 'lodash-es'
import { computed } from '@/composition/vue/compositionApi'
import { useRouteReloader } from '@/composition/routeReloader'
import { getRouterService } from '@/core/container/ioc'
import TmLogicError from '@/core/error/tmLogicError'

const props = defineProps<
  RouterLinkProps & {
    disabled?: boolean
  }
>()

const emit = defineEmits<{
  click: []
}>()

const routerService = getRouterService()
const { reload } = useRouteReloader()
const { isExactActive, route } = useLink(props as any)

const currentRoute = computed(() => routerService.getCurrentRoute())

const getRedirectRouteName = (routeName: string): string => {
  const { redirect } = routerService.getRouteByName(routeName) || {}

  if (!redirect) {
    return routeName
  }

  return getRedirectRouteName(resolveRedirect(redirect))
}

const resolveRedirect = (redirectOption: RouteRecordRedirectOption): string => {
  const redirect = typeof redirectOption === 'function' ? redirectOption(route.value) : redirectOption

  const name = typeof redirect === 'object' ? (redirect as any)?.name : null

  if (typeof name !== 'string') {
    throw new TmLogicError('Invalid redirect route name')
  }

  return name
}

const clickHandler = () => {
  emit('click')

  const to = props.to as RouteLocationNormalizedGeneric

  const isRedirectOnCurrentRoute =
    getRedirectRouteName(to.name as string) === currentRoute.value.name && isEqual(currentRoute.value.params, to.params)
  if (isExactActive.value || isRedirectOnCurrentRoute) {
    reload()
  }
}
</script>

import type { TmWrappers } from '@/wrappers'
import type { FiltersToCreate } from '@/services/tables/filters/types'
import type { RawSchemaFilter } from '@/services/resolvers/domain/filterSchema/schemaGenerator'

export const addSmsSurveyRecipientsModalId: TmWrappers = 'smsSurveyRecipients'

export const smsSurveyRecipientsCountriesFilterFields = {
  phones: 'phones',
  contacts: 'contacts',
  lists: 'lists',
}

export const smsSurveyRecipientsCountriesFilters: FiltersToCreate = [
  {
    name: smsSurveyRecipientsCountriesFilterFields.phones,
    operation: 'eq',
  },
  {
    name: smsSurveyRecipientsCountriesFilterFields.contacts,
    operation: 'eq',
  },
  {
    name: smsSurveyRecipientsCountriesFilterFields.lists,
    operation: 'eq',
  },
]

export const smsSurveyRecipientsCountriesSchemaFilters: RawSchemaFilter[] = [
  smsSurveyRecipientsCountriesFilterFields.phones,
  smsSurveyRecipientsCountriesFilterFields.contacts,
  smsSurveyRecipientsCountriesFilterFields.lists,
]

import { inject, injectable } from 'inversify'
import { merge, cloneDeep } from 'lodash-es'
import type {
  PaginationInterface,
  PaginationParams,
  PaginationSettings,
  PaginationUrlParams,
  PaginationData,
  PaginationNextPageData,
} from '@/services/tables/pagination/types'
import type { DeepPartial, ModelRaw } from '@/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type PaginationRepository from '@/data/repositories/table/paginationRepository'
import Pagination from '@/data/models/tables/Pagination'
import type { LowLevelUpdateBody } from '@/services/vuex/types'
import { SERVICE_TYPES } from '@/core/container/types'
import { TmTableElementManagerError } from '@/core/error/table/tableManager/tmTableElementManagerError'
import TmLogicError from '@/core/error/tmLogicError'
import type CursorPaginationPageRepository from '@/data/repositories/table/cursorPaginationPageRepository'
import CursorPaginationPage from '@/data/models/tables/CursorPaginationPage'

type ModelData = LowLevelUpdateBody<Pagination>

@injectable()
export default abstract class AbstractPaginationServiceFactory<PUP extends PaginationUrlParams>
  implements PaginationInterface<PUP>
{
  protected tableModelId: string

  protected settings: PaginationSettings

  protected repo: PaginationRepository

  protected cursorPageRepo: CursorPaginationPageRepository

  constructor(@inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService) {
    this.repo = this.em.getRepository<PaginationRepository>(Pagination)
    this.cursorPageRepo = this.em.getRepository<CursorPaginationPageRepository>(CursorPaginationPage)
  }

  protected abstract getDefaultModelData(): ModelData

  protected abstract buildModelData(paginationParams: PUP): ModelData

  public abstract init(params?: PUP): boolean

  public abstract isFirstPage(): boolean

  public abstract toQuery(): PUP

  public abstract getFirstPage(): PaginationParams<PUP>

  public abstract pageKey(data: PUP): string

  public abstract pageRequestParams(): PaginationParams<PUP>

  public abstract moveToFirstPage(): PaginationParams<PUP>

  public abstract moveToNextPage(data: PaginationNextPageData): PaginationParams<PUP>

  public abstract onDataLoad(data: PaginationData): void

  public abstract getTotal(): number

  public abstract setTotalCount(count: number): void

  public abstract isDefaultState(): boolean

  public moveToPrevPage(): PaginationParams<PUP> {
    throw new TmTableElementManagerError("moveToPrevPage method doesn't implemented")
  }

  public changePerPage(perPage: number) {
    throw new TmTableElementManagerError("changePerPage method doesn't implemented")
  }

  public setTableId(tableId: string) {
    this.tableModelId = tableId
  }

  public getTableId(): string {
    if (!this.tableModelId) {
      throw new TmLogicError('No tableId is set for service')
    }

    return this.tableModelId
  }

  public setSettings(settings: PaginationSettings) {
    this.settings = settings
  }

  public setData(data: ModelData) {
    this.repo.update([data])
  }

  public getData(): ModelRaw<Pagination> {
    return this.repo.find(this.tableModelId)
  }

  public setLastId(_lastId: string) {}

  public reset() {
    this.setData(this.getDefaultModelData())
  }

  public applyUrlParams(params: PUP, recalculate = true) {
    this.setData({
      ...params,
      id: this.tableModelId,
      pageKey: this.pageKey(params),
    })

    const currData = this.getData()

    if (recalculate && currData.totalCount && params.perPage > 0) {
      currData.pageCount = Math.ceil(currData.totalCount / params.perPage)
      this.setData({
        ...currData,
        id: this.tableModelId,
      })
    }
  }

  public getPerPage(): number {
    return this.getData()?.perPage ?? this.settings.perPage
  }

  public moveToPage(params: PaginationParams<PUP>) {
    this.repo.update([this.buildModelData(params.requestParams)])
    return params
  }

  public getCurrentPage(): PaginationParams<PUP> {
    return this.pageRequestParams()
  }

  public clonePagination(
    overrider?: DeepPartial<PaginationParams<PUP>> | ((params: PaginationParams<PUP>) => void),
  ): PaginationParams<PUP> {
    if (typeof overrider === 'function') {
      const newParams = cloneDeep(this.getCurrentPage())
      overrider(newParams)
      return newParams
    }
    return merge({}, this.getCurrentPage(), overrider)
  }

  public cleanUp() {
    this.repo.cleanUpByTableId(this.tableModelId)
  }
}

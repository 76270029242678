import { get } from 'lodash-es'
import { isEmpty } from './utils'
import { ipRegexp, ipRegexpWithSubnetMask, ipV6Reqexp, ipV6ReqexpWithSubnetMask } from '@/utils/regexp'

const ipValidator = (value: string, _ruleParams: unknown, _options: unknown, config?: unknown) => {
  if (isEmpty(value)) {
    return true
  }

  if (get(config, ['data', 'withSubnetMask'])) {
    return ipRegexpWithSubnetMask.test(value) || ipV6ReqexpWithSubnetMask.test(value)
  }

  return ipRegexp.test(value) || ipV6Reqexp.test(value)
}

export default ipValidator

import { inject, injectable } from 'inversify'
import type { RegisteredServices } from '@/core/container/types'
import { SERVICE_TYPES } from '@/core/container/types'
import BaseFormService from '@/services/forms/baseFormService'
import type FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import type { ExportAvailableExtensions, Exportable } from '@/services/types'
import { exportModalId } from '@/services/types'
import type { TpFormData } from '@/services/forms/types'
import type BaseModel from '@/data/models/BaseModel'
import type { EndpointParams } from '@/services/endpoints'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type LoggerService from '@/services/loggerService'
import type WrapperManagerService from '@/services/wrappers/wrapperManagerService'
import type UserService from '@/services/domain/user/userService'

@injectable()
export default class ExportFormService extends BaseFormService<FormBuilderService> {
  protected exportService: Exportable

  protected tableServiceId?: RegisteredServices

  protected entityType: typeof BaseModel

  protected extension: ExportAvailableExtensions

  protected totalCount?: number

  protected endpointParams?: EndpointParams

  constructor(
    @inject(SERVICE_TYPES.FormBuilderFactoryService) formBuilderFactoryService: () => FormBuilderService,
    @inject(SERVICE_TYPES.EntityManager) em: EntityManagerService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService) validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) logger: LoggerService,
    @inject(SERVICE_TYPES.WrapperManagerService) protected readonly wrapperManagerService: WrapperManagerService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {
    super(formBuilderFactoryService, em, validationRulesBuilderService, logger)
  }

  public async buildForm() {
    const builder = this.getBuilder()
    const { email } = this.userService.currentUser()
    const { defaultEmail } = this.wrapperManagerService.getParams(exportModalId)

    builder.control({
      fieldType: 'text',
      name: 'email',
      value: defaultEmail || email,
      validators: this.validationRulesBuilderService
        .createRules()
        .required({
          messagePath: 'exportModal.emailErrorRequired',
        })
        .email({
          messagePath: 'exportModal.emailErrorRequired',
        }),
    })
  }

  public setExportService(exportService: Exportable): void {
    this.exportService = exportService
  }

  public setTableServiceId(serviceId: RegisteredServices): void {
    this.tableServiceId = serviceId
  }

  public setExtension(extension: ExportAvailableExtensions): void {
    this.extension = extension
  }

  public setEntityType(entityType: typeof BaseModel): void {
    this.entityType = entityType
  }

  public setEndpointParams(params: EndpointParams) {
    this.endpointParams = params
  }

  public async submit(formData: TpFormData) {
    return this.exportService.export(
      this.extension,
      this.entityType,
      this.tableServiceId,
      this.endpointParams ?? ([] as EndpointParams),
      formData.email,
    )
  }
}

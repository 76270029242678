import type { StatusColor } from '@/components/shared/types'

export type MapStatusColor<Status> = Partial<Record<StatusColor, Status[]>>

export function getStatusColor<Status>(
  map: MapStatusColor<Status>,
  status: Status,
  defaultColor: StatusColor = 'gray',
): StatusColor {
  for (const [index, mapValue] of Object.entries(map)) {
    if (mapValue.includes(status)) {
      return index as StatusColor
    }
  }

  return defaultColor
}

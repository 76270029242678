import type { RouteLocationNamedRaw, RouteLocationRaw } from 'vue-router'
import { cloneDeep } from 'lodash-es'
import { useTypedWrapper, useWrapper } from '@/composition/modal'
import {
  get,
  getContactNavigationOrderService,
  getContactService,
  getFeatureFlagsService,
  getPhoneService,
  getRouterPermissionsService,
  getTableManager,
  getTeamWorkflowService,
  getTicketCountService,
  getTranslateService,
  getUserService,
} from '@/core/container/ioc'
import { createTable, useTableFilterServiceManager } from '@/composition/tables'
import {
  addContactsChooseSourceModal,
  ContactsTable,
  selectingContactsId,
  selectingContactsListsId,
  SelectingContactsListsTable,
  selectingContactsProgressId,
  SelectingContactsTable,
} from '@/components/views/contacts/types'
import type { RegisteredServices } from '@/core/container/types'
import Contact from '@/data/models/domain/Contact'
import ContactList from '@/data/models/domain/ContactList'
import type ContactListTemp from '@/data/models/domain/ContactListTemp'
import { addPlusToPhoneNumber } from '@/utils/string/addPlusToPhoneNumber'
import type { PhoneWithCountryCode } from '@/services/forms/types'
import type { TmWrappers } from '@/wrappers'
import type {
  BeforeChangeEditingIdPreparer,
  ContactFacet,
  ContactNavigationOrderKey,
} from '@/services/domain/contact/types'
import type ContactModelFormService from '@/services/forms/contact/contactModelFormService'
import { createEditableWrapperForm, handleFormError, useForm, useWrapperWithForm } from '@/composition/forms'
import type { BulkWrapperParams, WrapperParams } from '@/services/wrappers/types'
import type ContactListGridable from '@/services/domain/contactList/contactListGridable'
import { setFilterValue } from '@/composition/filters'
import type ContactBaseFormService from '@/services/forms/contact/contactBaseFormService'
import { boundaryForContactListTemp } from '@/services/domain/messages/types'
import { redirect } from '@/composition/router'
import { getComposeMessageRoute } from '@/routes/user/compose'
import type { BaseTableInterface } from '@/core/tables/baseTableInterface'
import type { CampaignsComposeSmsRouteParams } from '@/components/views/compose/types'
import type { Forms } from '@/services/forms/formTypes'
import { computed, ref } from '@/composition/vue/compositionApi'
import type { PageContentSidebarLink } from '@/components/layout/pageContent/sidebar/types'
import { prepareLayoutMenuItems, useLayoutMenuFaceter } from '@/composition/layoutMenu'
import { useBreakpoints } from '@/composition/breakpoints'
import type Chat from '@/data/models/domain/chats/Chat'
import { ChatType } from '@/data/models/domain/chats/types'
import { useConfirmation } from '@/composition/confirmation'
import { FeatureFlags } from '@/services/types'
import ContactChangeEmailConfirmation from '@/components/domain/contacts/ContactChangeEmailConfirmation.vue'
import { resubscribeContactById } from '@/composition/domain/unsubscribedContact'
import { useServerSubscription } from '@/composition/subscription'
import type { IFormCustomFieldable } from '@/decorators/formCustomFieldable'
import type BaseFormService from '@/services/forms/baseFormService'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'

interface AddContactsChooseSourceModalParams {
  selectingContactsServiceId: RegisteredServices
  listId: string
}

interface ContactsDeleteModalParams {
  id: string
  fullName: string
  phone: string
  email: string | null
  actionHandler: () => Promise<void>
}

interface ContactImportResultModalParams {
  editingId: string
}

export const contactTagsBulkModalId = 'contactTagsBulkModal' as const
export const contactTagsBulkFormId = 'ContactBulkFormService' as const

export const useContactsDeleteModal = (onSuccess?: () => void) => {
  const contactService = getContactService()

  const wrapperId: TmWrappers = 'contactDeleteModal'

  const open = (contact: Contact) =>
    useWrapper<ContactsDeleteModalParams>(wrapperId).open({
      id: contact.id,
      fullName: contact.fullName,
      phone: contact.phone,
      email: contact.email,
      actionHandler: async () => {
        await contactService.delete(contact.id)
        onSuccess?.()
      },
    })

  const close = () => useWrapper<ContactsDeleteModalParams>(wrapperId).close()

  const getParams = () => useWrapper<ContactsDeleteModalParams>(wrapperId).getParams()

  return {
    open,
    close,
    getParams,
  }
}

export const useContactsChangeEmailConfirmation = () => {
  const featureFlagsService = getFeatureFlagsService()
  const ticketCountService = getTicketCountService()

  const confirmation = useConfirmation()

  const confirm = async (contactId: string, contactFullName: string) => {
    const { promise, resolve } = Promise.withResolvers<boolean>()

    if (!featureFlagsService.isFeatureEnabled(FeatureFlags.Tickets)) {
      resolve(true)
      return promise
    }

    const count = await ticketCountService.getCountClosedTicketAfterDeleteContact(contactId)
    if (count === 0) {
      resolve(true)
      return promise
    }
    const translationService = getTranslateService()
    const res = await confirmation.confirm(() => resolve(true), {
      title: translationService.t('contactsChangeEmailConfirmation.title'),
      textComponent: ContactChangeEmailConfirmation as any,
      textComponentProps: {
        fullName: contactFullName,
        quantityRelatedTickets: count,
      },
      submitColor: 'primary',
      submitText: translationService.t('common.continue'),
    })
    if (!res) {
      resolve(false)
    }
    return promise
  }

  return {
    confirm,
  }
}

export const refreshContactTable = () => {
  const table = getTableManager().getTable(ContactsTable)
  table.refreshRows({ isSilent: false })
}

export const useAddContactsChooseSourceModal = () => {
  const wrapperId: TmWrappers = addContactsChooseSourceModal
  const open = (listId: string, selectingContactsServiceId: RegisteredServices) => {
    useWrapper<AddContactsChooseSourceModalParams>(wrapperId).open({ listId, selectingContactsServiceId })
  }

  const close = () => useWrapper<AddContactsChooseSourceModalParams>(wrapperId).close()

  const getParams = () => useWrapper<AddContactsChooseSourceModalParams>(wrapperId).getParams()

  return {
    wrapperId,
    getParams,
    open,
    close,
  }
}

export type ContactFormDefaults = {
  firstName?: string
  lastName?: string
  phone?: PhoneWithCountryCode
  whatsappPhone?: PhoneWithCountryCode
  listIds?: string[]
  readonlyListIds?: string[]
  customFields?: Record<string, string>
  successCallback?: () => void | Promise<any>
}
export type ContactFormParams<T extends ContactModelFormService = ContactModelFormService> = {
  formService?: T
  defaults?: ContactFormDefaults
  editingId?: string
  isShared?: boolean
  beforeChangeEditingIdPreparer?: BeforeChangeEditingIdPreparer
  afterCreateForm?: (formService: T) => void
  afterBuildForm?: (formService: T) => void
  withAutocompleteForPrimaryPhone?: boolean
}

export interface ContactModalParams<T extends ContactModelFormService = ContactModelFormService> {
  beforeChangeEditingIdPreparer?: BeforeChangeEditingIdPreparer
  readonlyListIds: string[]
  successSubmitCallback: () => Promise<unknown>
  editingId?: string
  withAutocompleteForPrimaryPhone?: boolean
  contactFormParams: ContactFormParams<T>
}

export const useContactModalBase = <T extends ContactModelFormService = ContactModelFormService>() => {
  const wrapperId: TmWrappers = 'contact'
  const { open, getParams } = useWrapper<ContactModalParams<T>>(wrapperId)

  return {
    wrapperId,
    open: (params: ContactModalParams<T>) => open(params),
    getParams,
  }
}

export const createContactModalForm = <T extends ContactModelFormService = ContactModelFormService>(
  formId: Forms,
  params: ContactFormParams<T>,
) => {
  const contactModalBase = useContactModalBase()

  const res = createEditableWrapperForm<WrapperParams, T>(formId, contactModalBase.wrapperId, {
    hooks: {
      onMounted: () => {
        params.afterBuildForm?.(res.formService)
      },
    },
  })
  params.afterCreateForm?.(res.formService)
  res.formService.setInitFormData(params.defaults ?? {})
  res.formService.changeSetDefaultLists(!params.defaults?.listIds)
  res.formService.setIsAllowEditListIds(!params.isShared)
  return res
}

export const useContactModal = <T extends ContactModelFormService = ContactModelFormService>() => {
  const contactModalBase = useContactModalBase<T>()

  const open = (p: ContactFormParams<T> = {}) => {
    contactModalBase.open({
      withAutocompleteForPrimaryPhone: p.withAutocompleteForPrimaryPhone,
      editingId: p.editingId,
      beforeChangeEditingIdPreparer: p?.beforeChangeEditingIdPreparer,
      readonlyListIds: p?.defaults?.readonlyListIds ?? [],
      successSubmitCallback: async () => p?.defaults?.successCallback?.(),
      contactFormParams: p,
    })
  }

  return {
    open,
    getParams: contactModalBase.getParams,
  }
}

export const useContactModalFromChat = () => {
  const { open } = useContactModal()
  const openModal = (chat: Chat, successCallback: () => void | Promise<any>) => {
    if (!chat) {
      open({
        defaults: {
          successCallback,
        },
      })
      return
    }
    const contact: ContactFormParams<ContactModelFormService> = {
      defaults: {
        phone: {
          phone: chat.phone,
          countryCode: chat.countryId,
        },
        successCallback,
      },
    }
    if (chat.type === ChatType.WhatsAppBusiness && contact.defaults) {
      contact.defaults.whatsappPhone = {
        phone: chat.phone,
        countryCode: chat.countryId,
      }
    }
    open(contact)
  }

  return {
    open: openModal,
  }
}

export const useContactModalAndSetPhone = () => {
  const res = useContactModal()
  const phoneService = getPhoneService()
  const open = (phoneNumber: string) => {
    res.open({
      defaults: {
        phone: {
          countryCode: phoneService.getCountryByPhone(phoneNumber)!,
          phone: addPlusToPhoneNumber(phoneNumber),
        },
      },
    })
  }

  return {
    open,
  }
}

export const ContactImportResultModal = useWrapperWithForm<
  ContactImportResultModalParams,
  IFormCustomFieldable & BaseFormService<FormBuilderInterface>
>('contactImportResultModal', 'ContactImportResultFormService')

export const openNewContactInListModal = (listId: string) => () => {
  useContactModal().open({
    defaults: {
      listIds: [listId],
    },
    beforeChangeEditingIdPreparer: (e) => {
      const cloneVal = cloneDeep(e)
      if (!cloneVal.listIds.includes(listId)) {
        cloneVal.listIds.push(listId)
      }
      return cloneVal
    },
  })
}

export const openEditContactForm = (isShared?: boolean) => (contactId: string) =>
  useContactModal().open({ editingId: contactId, isShared })

export const openEditContactFormByContactId = () => {
  return (
    contactId: string,
    params: Omit<ContactFormParams<ContactModelFormService>, 'editingId' | 'isShared'> = {},
  ) => {
    const teamWorkflowService = getTeamWorkflowService()
    const contactService = getContactService()

    const isShared = teamWorkflowService.isSubaccountsWorkflow() && contactService.isSharedById(contactId)

    return useContactModal().open({
      ...params,
      editingId: contactId,
      isShared,
    })
  }
}

export const getContactDetailsLocation = (contactId: string): RouteLocationRaw =>
  ({
    name: 'user.contacts.myContact.contactDetails',
    params: { contactId },
  }) as RouteLocationRaw

export const useGetContactByPhone = (phone: string) => {
  const contactService = getContactService()
  const phoneService = getPhoneService()
  const clearedPhone = phoneService.clearPhone(phone)

  return contactService.getContactByPhone(clearedPhone)
}

export type SelectingRecipientsSubmitCallback = (
  recipients: Contact[] | ContactList[],
  contactsCount?: number,
  isAll?: boolean,
) => Promise<boolean>

export interface SelectingContactsWrapperParams {
  serviceId: RegisteredServices
  submitCallback: SelectingRecipientsSubmitCallback
  showImport: boolean
}

export interface UseSelectingContactsListsParams {
  serviceId: RegisteredServices
  submitCallback: SelectingRecipientsSubmitCallback
  filterCondition?: (list: ContactList) => boolean
}

export const openSelectingContactsModal = (params: SelectingContactsWrapperParams) => () =>
  useWrapper<SelectingContactsWrapperParams>(selectingContactsId).open(params)

export const useSelectingContactsTable = (shared?: boolean) => {
  const createTableResult = createTable({
    tableModelId: SelectingContactsTable,
    tableWrapperId: selectingContactsId,
    entity: Contact,
    supportedSearchFilters: [],
    columnFactory: 'SelectingContactsColumnServiceFactory',
    filterFactory: 'ContactFilterServiceFactory',
    sortEntityName: 'contact',
    counter: 'ContactCounterService',
    defaultSorter: {
      name: 'firstName',
      direction: 'asc',
    },
  })

  if (shared !== undefined) {
    setFilterValue({
      serviceId: createTableResult.id,
      name: 'shared',
      value: `${shared}`,
      shouldReplaceState: true,
    })
  }

  return createTableResult
}

export type SelectingContactsProgressModalParams = {
  isAll: boolean
  contactIds: string[]
  completedCallback: (list: ContactListTemp) => void
}

export const useSelectingContactsProgressModal = () =>
  useTypedWrapper<SelectingContactsProgressModalParams>(selectingContactsProgressId)

export const useSelectingContactsLists = (params: UseSelectingContactsListsParams) => () =>
  useWrapper<UseSelectingContactsListsParams>(selectingContactsListsId).open(params)

export const useSelectingContactsListsTable = () =>
  createTable({
    tableModelId: SelectingContactsListsTable,
    tableWrapperId: selectingContactsListsId,
    entity: ContactList,
    gridService: get<ContactListGridable>('ContactListGridable'),
    supportedSearchFilters: [],
    columnFactory: 'SelectingContactsListsColumnServiceFactory',
    sortEntityName: 'list',
    defaultSorter: {
      name: 'name',
      direction: 'asc',
    },
  })

export const useFilterByLetter = (serviceId: RegisteredServices) => {
  const table = getTableManager().getTable(serviceId)
  const filterService = useTableFilterServiceManager(serviceId).getServiceForTable(serviceId, String(0))

  const latterFilterKey = 'letter'

  const selectedLetter = computed(() => filterService.getFilterInnerValue(latterFilterKey) as string)

  const filterByLetter = (letter: string) => {
    filterService.setInnerValue(latterFilterKey, letter)
    filterService.applyFilterValue(latterFilterKey)
    table.getSearch().reset()
    table.onApplyFilter()
  }

  return {
    selectedLetter,
    filterByLetter,
  }
}

export const usePrepareFlatContact = async (formId: Forms) => {
  const { formService, onFormLoaded } = useForm<ContactBaseFormService>(formId)

  try {
    onFormLoaded(async (): Promise<void> => formService.prepareFlatContact())
  } catch (e) {
    handleFormError(e)
  }
}

export const sendSmsByContactsTable = (table: BaseTableInterface, contactListId?: string) => {
  const showProgressModal = () => {
    const isAll = table.isAllSelected()
    const contactIds = isAll ? [] : table.getSelectedRowsIds()
    const completedCallback = ({ id }: ContactListTemp) => redirectToCompose({ listIds: [id.toString()] })

    useSelectingContactsProgressModal().open({ isAll, contactIds, completedCallback })
  }

  const getComposeRouteParams = () => {
    const isAllSelected = table.isAllSelected()
    const isNoneSelected = table.isNoneSelected()
    const contactIds = table.getSelectedRowsIds()

    if (contactListId && (isAllSelected || isNoneSelected)) return { listIds: [contactListId] }
    if (contactIds.length <= boundaryForContactListTemp && !isAllSelected) return { contactIds }
    return undefined
  }

  const redirectToCompose = (params: CampaignsComposeSmsRouteParams) => redirect(getComposeMessageRoute({ params }))

  const params = getComposeRouteParams()
  if (params) redirectToCompose(params)
  else showProgressModal()
}

export const useContactsSidebarMenu = () => {
  const featureFlagsService = getFeatureFlagsService()
  const contactNavigationOrderService = getContactNavigationOrderService()
  const { getNumber, updateFacets } = useLayoutMenuFaceter<ContactFacet>(Contact)

  const { isTablet } = useBreakpoints()

  const userService = getUserService()
  const teamWorkflowService = getTeamWorkflowService()
  const translateService = getTranslateService()

  const isUsersWorkflow = computed<boolean>(() => teamWorkflowService.isUsersWorkflow())

  const myContactTitle = computed(() => {
    const currentUser = userService.currentUser()
    return currentUser.isShowShared ? 'entities.contacts.allContacts' : 'entities.contacts.myContacts'
  })

  const myContactQuantity = computed(() => {
    const currentUser = userService.currentUser()
    const facetKey: keyof ContactFacet = currentUser.isShowShared ? 'allContact' : 'contact'
    return getNumber(facetKey)
  })

  const unsubscriberQuantity = computed(() => {
    const unsubscriber = getNumber('unsubscriber')
    const unsubscriberEmail = getNumber('unsubscriberEmail')
    if (unsubscriber === null && unsubscriberEmail === null) {
      return null
    }
    const total = (unsubscriber ?? 0) + (unsubscriberEmail ?? 0)
    return total
  })

  const itemsMap = computed<Record<ContactNavigationOrderKey, PageContentSidebarLink<ContactNavigationOrderKey>>>(
    () => ({
      contact: {
        itemType: 'link',
        title: getTranslateService().t(myContactTitle.value),
        route: contactNavigationOrderService.getRouteLocationByItemKey('contact'),
        number: myContactQuantity.value,
        icon: 'person',
        tooltip: isUsersWorkflow.value
          ? translateService.t('tooltips.contact.myContacts.menuUsersWorkflow')
          : translateService.t('tooltips.contact.myContacts.menu'),
        id: 'contact',
      },
      list: {
        itemType: 'link',
        title: getTranslateService().t('entities.contactList.title'),
        route: contactNavigationOrderService.getRouteLocationByItemKey('list'),
        number: getNumber('list'),
        icon: 'tmi-people',
        tooltip: isUsersWorkflow.value
          ? translateService.t('tooltips.contact.list.menuUsersWorkflow')
          : translateService.t('tooltips.contact.list.menu'),
        onClick: () => {
          updateFacets(['hiddenList', 'list'])
        },
        id: 'list',
      },
      filteredView: {
        itemType: 'link',
        title: getTranslateService().t('entities.segment.title'),
        route: contactNavigationOrderService.getRouteLocationByItemKey('filteredView'),
        number: getNumber('filteredView'),
        icon: 'pie_chart',
        id: 'filteredView',
        hidden: !featureFlagsService.isFeatureEnabled(FeatureFlags.Segments),
      },
      import: {
        itemType: 'link',
        title: getTranslateService().t('entities.contacts.imports'),
        route: contactNavigationOrderService.getRouteLocationByItemKey('import'),
        number: getNumber('import'),
        icon: 'file_upload',
        tooltip: getTranslateService().t('tooltips.contact.imports.menu'),
        id: 'import',
      },
      unsubscriber: {
        itemType: 'link',
        title: getTranslateService().t('entities.unsubscribedContact.menuTitle'),
        route: contactNavigationOrderService.getRouteLocationByItemKey('unsubscriber'),
        number: unsubscriberQuantity.value,
        icon: 'do_not_disturb_on',
        tooltip: getTranslateService().t('tooltips.contact.unsubscribed.menu'),
        id: 'unsubscriber',
      },
      blockedContact: {
        itemType: 'link',
        title: getTranslateService().t('entities.contacts.blockedContacts'),
        route: contactNavigationOrderService.getRouteLocationByItemKey('blockedContact'),
        number: getNumber('blockedContact'),
        icon: 'block',
        tooltip: getTranslateService().t('tooltips.contact.blockedContacts.menu'),
        id: 'blockedContact',
      },
    }),
  )

  return computed<PageContentSidebarLink<ContactNavigationOrderKey>[]>(() => {
    const sortedNavigationKeys = contactNavigationOrderService.getSortedNavigationKeys()
    const allKeys = Object.keys(itemsMap.value).sort((a, b) => {
      const indexOfA = sortedNavigationKeys.indexOf(a as ContactNavigationOrderKey)
      const indexOfB = sortedNavigationKeys.indexOf(b as ContactNavigationOrderKey)

      if (indexOfA === -1) {
        return 1
      }

      if (indexOfB === -1) {
        return -1
      }

      return indexOfA - indexOfB
    })

    const routesBase = allKeys.map<PageContentSidebarLink<ContactNavigationOrderKey>>((key) => itemsMap.value[key])

    if (isTablet.value) {
      routesBase.push({
        itemType: 'link',
        title: translateService.t('common.settings'),
        route: { name: 'user.contacts.settings' },
        number: null,
        tooltip: '',
      })
    }

    return prepareLayoutMenuItems(routesBase) as PageContentSidebarLink<ContactNavigationOrderKey>[]
  })
}

export const useContactSubscribed = (contactId: string, onResubscribe?: () => Promise<void>) => {
  const contactUnsubscribed = ref(false)
  const contactService = getContactService()

  const resubscribe = () =>
    resubscribeContactById(async () => {
      await contactService.fill(contactId)
      await onResubscribe?.()
      contactUnsubscribed.value = false
    })(contactId)

  useServerSubscription('contactUnsubscribed', (e) => {
    if (e.payload.ids.includes(+contactId)) {
      contactService.deleteFromStore([contactId])
      contactUnsubscribed.value = true
    }
  })

  return {
    contactUnsubscribed,
    resubscribe,
  }
}

export const useContactStateInfo = (contactId: string) => {
  const contactService = getContactService()

  const contact = computed(() => contactService.findEntityByIdOrNull(contactId))
  const contactIsShared = computed(() =>
    contact.value ? getTeamWorkflowService().isSubaccountsWorkflow() && contactService.isSharedById(contactId) : false,
  )
  const contactIsBlocked = computed(() => (contact.value ? contact.value.blocked : false))
  const contactIsUnsubscribed = computed(() => (contact.value ? contact.value.unsubscribed : false))

  return {
    contact,
    contactIsShared,
    contactIsBlocked,
    contactIsUnsubscribed,
  }
}

export const useContactDetailsRoute = () => {
  const routerPermissionsService = getRouterPermissionsService()
  const contactDetailsRouteName = 'user.contacts.myContact.contactDetails'

  return (contactId: string): RouteLocationNamedRaw | undefined => {
    if (!routerPermissionsService.isAllowAccessToRoute(contactDetailsRouteName)) {
      return undefined
    }

    return { name: 'user.contacts.myContact.contactDetails', params: { contactId } }
  }
}

const getContactBulkParams = (tableId: RegisteredServices): BulkWrapperParams => {
  const table = getTableManager().getTable(tableId)

  return {
    filters: table.getFiltersQuery(true),
    tableId,
    total: table.getPagination().getTotal(),
    selectedIds: table.isAllSelected() ? [] : table.getSelectedRowsIds(),
    isAllSelected: table.isAllSelected(),
  }
}

export const useContactTagsBulkModal = () => {
  const { open: wrapperOpen, close, getParams } = useWrapper<BulkWrapperParams>(contactTagsBulkModalId)
  const open = (tableId: RegisteredServices) => wrapperOpen(getContactBulkParams(tableId))

  return {
    open,
    close,
    getParams,
  }
}

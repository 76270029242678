import type { CustomErrorRule } from '@/core/error/transport/types'
import { EndpointsEnum } from '@/services/endpoints'
import { TmApiStripeInvalidBillingDetailsError } from '@/core/error/transport/tmApiStripeInvalidBillingDetailsError'

export const stripeInvalidBillingDetailsPaymentLink: CustomErrorRule = {
  endpoint: EndpointsEnum.teamSubscriptionPaymentLink,
  klass: TmApiStripeInvalidBillingDetailsError,
}

export const stripeInvalidBillingDetailsGenerateLink: CustomErrorRule = {
  endpoint: EndpointsEnum.stripeBillingPaygGenerateLink,
  klass: TmApiStripeInvalidBillingDetailsError,
}

export const stripeInvalidBillingDetailsPlanChange: CustomErrorRule = {
  endpoint: EndpointsEnum.stripeBillingMonthlyPlanChange,
  klass: TmApiStripeInvalidBillingDetailsError,
}

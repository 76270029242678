import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import PhoneNumber from '@/data/models/domain/PhoneNumber'
import type { TmCountryCode } from '@/services/types'
import type { DedicatedNumbersQueryParams } from '@/services/domain/phoneNumbers/types'
import type { PaginationParams } from '@/services/tables/pagination/types'
import type { ModelRaw } from '@/types'

export interface BuyNumberBody {
  phone: string
  country: TmCountryCode
  userId: string
  description?: string
}

export type ForwardingDirection = 'off' | 'chat' | 'phone'

export interface ChangeForwardingBody {
  numberId: string
  forwardingDirection: ForwardingDirection
  forwardingNumber?: string
  audioId?: string
}

@RepoSettings<Endpoint>({
  model: PhoneNumber,
  fetch: 'phoneNumbers',
  single: 'phoneNumbersSingle',
})
@injectable()
export default class PhoneNumbersRepository extends OrmApiRepository<PhoneNumber> {
  public updatePhoneLabel(id: string, label: string) {
    const path = this.endpointsService.getPath('phoneNumberLabelUpdate', [id])
    return this.getApiSource().put(path, { label })
  }

  public getNumberById(id: string) {
    return this.getRequest<ModelRaw<PhoneNumber>>(id)
  }

  public async getNumberByPhone(phone: string) {
    const path = this.endpointsService.getPath('phoneNumberByPhone', [phone])
    const res = await this.getApiSource().get<PhoneNumber>(path)
    await this.insertOrUpdate([res.data])
    return this.findEntityByIdOrFail(`${res.data.id}`)
  }

  public async buyNumber(body: BuyNumberBody) {
    const path = this.endpointsService.getPath('phoneNumberBuy')
    const res = await this.getApiSource().post(path, body)
    return res.data.id
  }

  public async changeForwardingNumber(body: ChangeForwardingBody) {
    const path = this.endpointsService.getPath('phoneNumberChangeForwarding')
    await this.getApiSource().put(path, body)
  }

  public async fetchPhoneNumbers(params: DedicatedNumbersQueryParams, pagination?: PaginationParams) {
    const response = await this.gridRequest(
      {
        filter: [],
        other: params,
      },
      pagination,
    )

    this.insertOrUpdate(response.items)

    return response
  }

  public restoreNumber(id: string) {
    const path = this.endpointsService.getPath('phoneNumbersRestore', [id])
    return this.getApiSource().put(path)
  }

  public cancelNumber(id: string) {
    const path = this.endpointsService.getPath('phoneNumbersSingle', [id])
    return this.getApiSource().delete(path)
  }
}

import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'

export const getFormsRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.services.forms',
    path: 'forms',
    meta: {
      tags: ['SERVICES_BAR_LIST'],
      permission: 'subscribeForms',
      modules: [AppModule.ContactList, AppModule.WidgetSubscribe, AppModule.WidgetClickToText],
    },
    redirect: { name: 'user.services.forms.subscribe' },
    component: () => import('@/components/views/services/forms/FormView.vue'),
    children: [
      {
        name: 'user.services.forms.subscribe',
        path: 'subscribe',
        component: () => import('@/components/views/services/forms/FormSubscribeView.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.forms.subscribeForms',
        },
      },
      {
        name: 'user.services.forms.clickToText',
        path: 'click-to-text',
        component: () => import('@/components/views/services/forms/FormClickToTextView.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.forms.clickToTextForms',
        },
      },
    ],
  },
]

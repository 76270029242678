import { BelongsToField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import SipCall from '@/data/models/domain/SipCall'
import ForwardAudio from '@/data/models/domain/ForwardAudio'

@OrmModel(modelNames.FORWARDED_CALL)
export default class ForwardedCall extends SipCall {
  @StringField()
  public usersInboundNumber: string

  @StringField()
  public forwardAudioId: string

  @BelongsToField(ForwardAudio, 'forwardAudioId')
  public forwardAudio: ForwardAudio
}

import { inject, injectable } from 'inversify'
import { endTypeVariant } from '@/services/domain/messages/types'
import type { EndType, MessageSendEndFormScheme } from '@/services/domain/messages/types'
import type { SelectFieldConfig, DateFieldConfig } from '@/services/forms/types'
import type {
  FormGroupConfigurationInterface,
  TypedFormBuilderInterface,
} from '@/services/forms/baseForm/typedFormBuilder/types'
import type { Translatable } from '@/services/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type DateTimeService from '@/services/dateTimeService'

@injectable()
export class EndFormGroupConfiguration implements FormGroupConfigurationInterface<MessageSendEndFormScheme> {
  constructor(
    @inject(SERVICE_TYPES.TranslateService) private readonly translateService: Translatable,
    @inject(SERVICE_TYPES.DateTimeService) private readonly dateTimeService: DateTimeService,
  ) {}

  public configure(builder: TypedFormBuilderInterface<MessageSendEndFormScheme>): void {
    builder
      .property((t) => t.type)
      .control<SelectFieldConfig<EndType>>({
        fieldType: 'select',
        value: 'never',
        options: () =>
          endTypeVariant.map((t) => ({
            text: this.translateService.t(`composeEndTypes.${t}`),
            value: t,
          })),
      })

    builder
      .property((t) => t.smsSessions)
      .control({
        fieldType: 'number',
        value: 1,
      })
    builder
      .property((t) => t.date)
      .control<DateFieldConfig>({
        fieldType: 'datepicker',
        value: this.dateTimeService.formatDate(
          this.dateTimeService.addDays(this.dateTimeService.now(), 1),
          'yyyy-MM-dd',
        ),
        min: new Date(),
      })
  }
}

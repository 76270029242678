import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  AutoDetectedCountryService,
  AutoDetectedCountryRepository,
}

export type AutoDetectedCountryServices = keyof typeof SERVICES

export const AUTO_DETECTED_COUNTRY_TYPES: Record<AutoDetectedCountryServices, symbol> =
  makeServicesTypes<AutoDetectedCountryServices>(enumKeysToArray<AutoDetectedCountryServices>(SERVICES))

import { NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.FORWARD_AUDIO)
export default class ForwardAudio extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public path: string

  @StringField()
  public originalName: string

  @StringField()
  public mimeType: string

  @NumberField()
  public length: number

  @NumberField()
  public fileSize: number

  @StringField()
  public uploadedAt: string
}

<script lang="ts">
import { computed, defineComponent, h } from '@/composition/vue/compositionApi'
import type { PageContentSidebarItem } from '@/components/layout/pageContent/sidebar/types'
import LayoutMenu from '@/components/layout/LayoutMenu.vue'
import { getRouterPermissionsService, getTranslateService } from '@/core/container/ioc'
import { useBreakpoints } from '@/composition/breakpoints'

export default defineComponent({
  setup() {
    const { t } = getTranslateService()
    const routerPermissionsService = getRouterPermissionsService()
    const { isTablet } = useBreakpoints()

    const routes = computed<PageContentSidebarItem[]>(() => {
      const routesBase: PageContentSidebarItem[] = [
        {
          itemType: 'link',
          title: t('pageTitle.user.contacts.settings.all.allSettings'),
          route: { name: 'user.contacts.settings.all' },
          icon: 'tune',
        },
        {
          itemType: 'link',
          title: t('pageTitle.user.contacts.settings.all.unsubscribeRules.title'),
          route: { name: 'user.contacts.settings.unsubscribe-rules' },
          icon: 'remove_circle',
        },
        {
          itemType: 'link',
          title: t('pageTitle.user.contacts.settings.all.sharing.title'),
          route: { name: 'user.contacts.settings.sharing' },
          icon: 'tmi-person-check',
        },
        {
          itemType: 'link',
          title: t('pageTitle.user.contacts.settings.all.navMenu.title'),
          route: { name: 'user.contacts.settings.navigation' },
          icon: 'tmi-sorting',
        },
        {
          itemType: 'link',
          title: t('pageTitle.user.contacts.settings.all.customFields.title'),
          route: { name: 'user.contacts.settings.custom-fields' },
          icon: 'tmi-code-json',
        },
      ]

      if (isTablet.value) {
        routesBase.push({
          itemType: 'link',
          title: t('pageTabTitle.user.contacts.contacts'),
          route: { name: 'user.contacts.index' },
        })
      }

      return routesBase.filter((item) => {
        if (item.itemType === 'group' || item.itemType === 'groupCollapsible' || item.itemType === 'simple') {
          return true
        }
        return routerPermissionsService.isAllowAccessToRoute(item.route)
      })
    })

    return () => h(LayoutMenu, { items: routes.value })
  },
})
</script>

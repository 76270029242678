import { injectable } from 'inversify'
import { isEmpty } from 'lodash-es'
import type { LocalStorageServiceInterface } from '@/services/storage/types'
import type { Dict } from '@/types'

@injectable()
export abstract class LocalStorageServiceBase implements LocalStorageServiceInterface {
  protected abstract readonly storage: Storage

  // string methods
  public get(key: string) {
    return this.storage.getItem(key)
  }

  public set(key: string, value: string) {
    try {
      this.storage.setItem(key, value)
    } catch {
      // In case the storage is full
      this.clear()
      this.storage.setItem(key, value)
    }
  }

  public delete(key: string) {
    this.storage.removeItem(key)
  }

  public clear() {
    this.storage.clear()
  }

  // JSON methods
  public getJSON<T = unknown>(key: string): Dict<T> {
    const data = this.get(key)
    if (isEmpty(data)) return {}
    try {
      return JSON.parse(data!)
    } catch {
      return {}
    }
  }

  public setJSON(key: string, value: Dict) {
    if (isEmpty(value)) {
      this.delete(key)
    } else {
      this.set(key, JSON.stringify(value))
    }
  }

  public patchJSON(key: string, patch: Dict) {
    const storedValue = this.getJSON(key)
    this.setJSON(key, { ...storedValue, ...patch })
  }

  public unpatchJSON(key: string, keysToRemove: string[]) {
    const storedValue = this.getJSON(key)
    const value = Object.fromEntries(
      Object.entries(storedValue).filter(([paramKey]) => !keysToRemove.includes(paramKey)),
    )

    this.setJSON(key, value)
  }

  // KeyStorageServiceInterface
  public getParams(key: string) {
    return this.getJSON(key)
  }

  public setParams(key: string, params: Dict, replace?: boolean) {
    if (replace) {
      this.setJSON(key, params)
    } else {
      this.patchJSON(key, params)
    }
  }

  public removeParams(key: string, keysToRemove: string[]) {
    this.unpatchJSON(key, keysToRemove)
  }

  // Parse date saved as string. Ex: "Fri Oct 25 2024 12:02:33 GMT+0400"
  public getDate(key: string) {
    const str = this.get(key)
    if (!str) return null
    const date = new Date(str)
    if (Number.isNaN(date)) return null
    return date
  }
}

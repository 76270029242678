import type {
  PropType,
  ComponentPublicInstance,
  TpDefineComponent,
  SetupContext,
} from '@/composition/vue/compositionApi'

export type AnyContext = Omit<SetupContext<any>, 'emit'> & { emit: (...args: any[]) => any }
export type AnyComponent = TpDefineComponent<any, any, any, any, any, any, any, any, any, any, any, any>
export type AnyAsyncComponent = ComponentPublicInstance<any, any, any, any, any, any, any, any, any, any, any>

export type VueLikeClassAttr = string | Record<string, boolean> | (string | Record<string, boolean>)[]
export const VueLikeClassProp = [String, Object, Array] as PropType<VueLikeClassAttr>

export type AnyTemplateElement = HTMLElement | AnyComponent | AnyAsyncComponent

import { inject, injectable } from 'inversify'
import { notify } from '@kyvg/vue3-notification'
// eslint-disable-next-line tp/using-vue-in-services-restriction, tp/forbid-import-composable-to-service
import { h } from '@/composition/vue/compositionApi'
import { SERVICE_TYPES } from '@/core/container/types'
import { notificationGroups } from '@/services/types'
import type { Notice, Notifiable, TranslationKey } from '@/services/types'
import type LoggerService from '@/services/loggerService'
import type TranslateService from '@/services/translateService'
import type BaseModel from '@/data/models/BaseModel'
import type { TmNamedRoute } from '@/services/route/types'
import type { MonitoringServiceInterface } from '@/services/monitoring/types'
import { TmFormValidationError } from '@/core/error/tmFormValidationError'
import { TmApiAccessError } from '@/core/error/transport/tmApiAccessError'
import { TmApiGoneError } from '@/core/error/transport/tmApiGoneError'
import { TmApiNotFoundError } from '@/core/error/transport/tmApiNotFoundError'
import { TmApiValidationError } from '@/core/error/transport/tmApiValidationError'
import { TmIncorrectContractError } from '@/core/error/tmIncorrectContractError'
import type WindowService from '@/services/browser/windowService'
import { TmBaseError } from '@/core/error/tmBaseError'
import { TmApiByocAuthentificationError } from '@/core/error/transport/tmApiByocAuthentificationError'
import { TmApiStripeInvalidBillingDetailsError } from '@/core/error/transport/tmApiStripeInvalidBillingDetailsError'
import { TmLostConnectionError } from '@/core/error/transport/tmLostConnectionError'
import { TmApiRetryAfterError } from '@/core/error/transport/tmApiRetryAfterError'
import { supportEmail } from '@/constants/links'
import TmEntityCreatedNotification from '@/components/shared/notifications/TmEntityCreatedNotification.vue'
import { TmApiAuthentificationError } from '@/core/error/transport/tmApiAuthentificationError'

@injectable()
export default class NotificationService implements Notifiable {
  constructor(
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.MonitoringService)
    protected readonly monitoringService: MonitoringServiceInterface,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
  ) {}

  public notify(notice: Notice): number {
    if (!notice.group) {
      notice.group = notificationGroups.main
    }

    if (!notice.type) {
      notice.type = 'success'
    }

    const id = Date.now()

    notify({ id, ...notice, data: { component: notice.component } })

    return id
  }

  public close(id: number): void {
    notify.close(id)
  }

  public notifyError(text: string, title?: string, duration?: number) {
    return this.notify({
      type: 'error',
      title,
      text,
      duration,
    })
  }

  public notifyWarning(text: string, title?: string, duration?: number) {
    return this.notify({
      type: 'warning',
      title,
      text,
      duration,
    })
  }

  public notifyInfo(text: string, title?: string, duration?: number) {
    return this.notify({
      type: 'info',
      title,
      text,
      duration,
    })
  }

  public notifySuccess(text: string, title?: string, duration?: number) {
    return this.notify({
      type: 'success',
      title,
      text,
      duration,
    })
  }

  public contactSupport(error?: unknown) {
    let message = 'FOR DEBUG'
    if (error instanceof Error) {
      message = error.message
    }
    this.loggerService.log('warn', message, 'forDebug')
    if (error) {
      this.monitoringService.logError(error as Error)
    }
    if (error instanceof TmBaseError && !error.shouldBeNotified()) {
      return
    }

    const notificationText = this.translateService.t('common.pleaseRetryLaterOrContactSupport', { supportEmail })
    this.notifyError(notificationText, 'Internal error')
  }

  public permissionDenied() {
    return this.notifyWarning(this.translateService.t('common.permissionDenied'))
  }

  public success(text: string) {
    return this.notify({
      type: 'success',
      text,
    })
  }

  public successT(translateKey: TranslationKey) {
    return this.success(this.translateService.t(translateKey))
  }

  public entityCreated(entity: typeof BaseModel, route?: TmNamedRoute, openInNewTab?: boolean) {
    return this.notify({
      component: () => h(TmEntityCreatedNotification, { entity, route, openInNewTab }),
    })
  }

  public entityDuplicated(entity: typeof BaseModel, count = 1) {
    return this.success(
      this.translateService.t('notifications.duplicate.successful', {
        entity: this.translateService.getEntityLabel(entity.entity, count),
      }),
    )
  }

  public entityDeleted(entity: typeof BaseModel, count = 1) {
    return this.success(
      this.translateService.t('notifications.delete.successful', {
        entity: this.translateService.getEntityLabel(entity.entity, count),
      }),
    )
  }

  public entityNotFound(entity: typeof BaseModel, count: number) {
    return this.notify({
      type: 'warning',
      title: '',
      text: this.translateService.t('notFound.entity', {
        entity: this.translateService.getEntityLabel(entity.entity, count),
      }),
    })
  }

  public entityUpdated(entity: typeof BaseModel, count = 1) {
    const entityLabel = this.translateService.getEntityLabel(entity.entity, count)
    const message = this.translateService.t('notifications.update.successful', { entity: entityLabel, count })
    return this.success(message)
  }

  public entityCreatedOrUpdated(entity: typeof BaseModel, count: number, isEditing: boolean) {
    if (isEditing) {
      return this.entityUpdated(entity, count)
    }
    return this.entityCreated(entity)
  }

  public notifyFromError(error: unknown) {
    if (error instanceof TmLostConnectionError) {
      const message = this.translateService.t('common.networkError')
      this.notifyError(message)
      return
    }
    if (error instanceof TmFormValidationError) {
      return
    }
    if (error instanceof TmApiAccessError) {
      this.permissionDenied()
      return
    }
    if (error instanceof TmIncorrectContractError) {
      this.notifyError(error.message)
      return
    }
    if (error instanceof TmApiNotFoundError && error.message) {
      this.notifyError(error.message)
      return
    }
    if (error instanceof TmApiGoneError && error.message) {
      this.notifyError(error.message)
      return
    }
    if (error instanceof TmApiByocAuthentificationError && error.message) {
      this.notifyError(error.message)
      return
    }
    if (error instanceof TmApiStripeInvalidBillingDetailsError && error.message) {
      this.notifyError(error.message)
      return
    }
    if (error instanceof TmApiRetryAfterError) {
      this.notifyError(this.translateService.t('errors.tooManyRequests'))
      return
    }
    if (error instanceof TmApiValidationError) {
      const errors = error.getErrors()?.errors
      if (errors?.fields) {
        // if fields exists we will show proper validation
        return
      }
      const commonErrors = errors?.common
      if (commonErrors && commonErrors.length > 0) {
        commonErrors.forEach((t) => {
          this.notifyError(t)
        })
        return
      }
      if (error.message) {
        this.notifyError(error.message)
        return
      }
    }
    if (error instanceof TmApiAuthentificationError) {
      // mute TmApiAuthentificationError cause it's handled by logout resolver
      return
    }
    this.contactSupport(error as Error)
  }

  public notifyNotImplemented(message = 'Not implemented yet') {
    return this.notify({
      type: 'info',
      text: message,
    })
  }

  public confirmNative(text: string) {
    // eslint-disable-next-line no-alert
    return this.windowService.self().confirm(text)
  }

  public confirmNativeT(key: TranslationKey) {
    return this.confirmNative(this.translateService.t(key))
  }
}

import { OrmModel, StringField, AttrField, BooleanField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { WrapperParams, WrapperTypesServicesKeys } from '@/services/wrappers/types'

@OrmModel(modelNames.BASE_WRAPPER)
export default class BaseWrapper extends BaseModel {
  @StringField()
  public id: string

  @AttrField()
  public params: WrapperParams

  @StringField('wrapper')
  public wrapperType: WrapperTypesServicesKeys

  @BooleanField(false)
  public isOpen: boolean
}

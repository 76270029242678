import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type { WizardApplyBody } from '@/services/domain/wizard/types'
import { wizardChangeStepEvent } from '@/services/domain/wizard/wizardService'
import type { Resolvable } from '@/services/resolvers/types'
import type { PostHogService } from '@/services/tracking/postHogService'
import { ConvertEvent, GoogleAnalyticsEvent } from '@/services/tracking/types'
import type GoogleAnalyticsService from '@/services/tracking/googleAnalyticsService'
import type ConvertService from '@/services/tracking/convertService'

@injectable()
export default class WizardAnalyticsResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PostHogService) protected readonly postHogService: PostHogService,
    @inject(SERVICE_TYPES.GoogleAnalyticsService) protected readonly googleAnalyticsService: GoogleAnalyticsService,
    @inject(SERVICE_TYPES.ConvertService) protected readonly convertService: ConvertService,
  ) {}

  private subscriptionKey: string | null = null

  private changeStepHandler = (e: WizardApplyBody) => {
    if (e.type !== 'from_configure_analytics_to_finish' && e.type !== 'from_configure_analytics_to_getting_started') {
      return
    }
    this.postHogService.trackSignUp(String(e.type))
    this.googleAnalyticsService.trackConversion(GoogleAnalyticsEvent.signUp)
    this.convertService.trackEvent(ConvertEvent.signUp)
  }

  public async resolve() {
    this.subscriptionKey = this.subscription.subscribe(wizardChangeStepEvent, this.changeStepHandler)
  }

  public async unresolve() {
    if (!this.subscriptionKey) {
      return
    }
    this.subscription.unsubscribe([this.subscriptionKey])
  }
}

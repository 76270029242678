import type { RouteRecordRaw } from 'vue-router'
import TenDlcCampaign from '@/data/models/domain/services/regulations/TenDlcCampaign'

import { RegulationsSettingsRoutes, SenderSettingsRoutes } from '@/routes/user/services/types'
import { AppModule } from '@/config/types'
import type { NotFoundResolverParams } from '@/services/resolvers/types'
import { ChatSettingsAliasedRoutes } from '@/routes/types'

export const getSenderSettingsDetailsRoutes = (): RouteRecordRaw[] => [
  {
    name: RegulationsSettingsRoutes.tenDlcCampaignDetails,
    path: 'sender-settings/10dlc/campaign/:campaignId',
    component: () => import('@/components/views/services/tenDlc/campaignDetails/TenDlcCampaignDetails.vue'),
    redirect: { name: 'user.services.senderSettings.tenDlcCampaignDetails.overview' },
    props: true,
    meta: {
      tabTitle: 'pageTabTitle.user.services.tenDlcCampaignDetails',
      resolvers: [
        {
          service: 'RedirectableSingleModelResolverService',
          params: {
            source: 'router',
            requestParam: 'campaignId',
            model: TenDlcCampaign,
            redirectToIfNotFound: { name: 'user.services.senderSettings.tenDlc' },
            cache: false,
          } satisfies NotFoundResolverParams,
        },
        {
          service: 'TenDlcNumbersCountResolver',
          params: {
            source: 'router',
            requestParam: 'campaignId',
            model: TenDlcCampaign,
          },
        },
      ],
      resolverComponent: () => import('@/components/resolvers/DetailsPageResolver.vue'),
      accessControlGroups: ['TenDlcStrategy'],
      modules: [AppModule.TenDlcTollFreeRequest],
    },
    children: [
      {
        name: RegulationsSettingsRoutes.tenDlcCampaignDetailsOverview,
        path: '',
        alias: ChatSettingsAliasedRoutes.complianceTenDlcSenderSettings,
        meta: {
          parent: (route) =>
            route.path.startsWith(ChatSettingsAliasedRoutes.complianceTenDlcSenderSettings)
              ? 'user.chats'
              : SenderSettingsRoutes.senderSettingsIndex,
        },
        component: () => import('@/components/views/services/tenDlc/campaignDetails/TenDlcCampaignDetailsOverview.vue'),
      },
      {
        name: RegulationsSettingsRoutes.tenDlcCampaignDetailsNumbers,
        path: 'numbers',
        alias: ChatSettingsAliasedRoutes.complianceTenDlcSenderSettingsNumbers,
        meta: {
          parent: (route) =>
            route.path.startsWith(ChatSettingsAliasedRoutes.complianceTenDlcSenderSettingsNumbers)
              ? 'user.chats'
              : SenderSettingsRoutes.senderSettingsIndex,
        },
        component: () => import('@/components/views/services/tenDlc/campaignDetails/TenDlcCampaignDetailsNumbers.vue'),
      },
    ],
  },
]

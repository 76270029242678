import { isArray, isFunction } from 'lodash-es'
import { inject, injectable } from 'inversify'
import type {
  RouteLocationNamedRaw,
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
  RouteRecordName,
  RouteRecordRaw,
} from 'vue-router'
import { inject as injectParams } from 'regexparam'
import { SERVICE_TYPES } from '@/core/container/types'
import {
  isRouteWithPathAndParamsOnly,
  isRouteWithStringPathAndName,
  type OnChange,
  type Route,
  type RouteLocationNamedOrPathWithParams,
  type RouterInterface,
  type RouteTag,
} from '@/services/route/types'
import type LoggerService from '@/services/loggerService'
import TmRouterError from '@/core/error/tmRouterError'
import type SubscriptionService from '@/services/transport/subscriptionService'
import type WindowService from '@/services/browser/windowService'
import { ROUTER_BEFORE_EACH } from '@/services/route/types'
import type TitlerManager from '@/services/route/titlers/titlerManager'

@injectable()
export default abstract class BaseRouterService<T extends RouterInterface> {
  protected router: T

  protected routes: RouteRecordRaw[]

  protected previousRoute: Route | undefined = undefined

  protected constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.TitlerManager) protected readonly titlerManager: TitlerManager,
  ) {}

  public async beforeEach(to: Route, from: Route) {
    this.previousRoute = from
    this.loggerService.table(
      'router',
      {
        to: { path: to.fullPath, name: to.name },
        from: { path: from.fullPath, name: from.name },
        empty: { path: '', name: '' },
      },
      'beforeEach',
    )

    this.subscriptionService.emit(ROUTER_BEFORE_EACH, to)
  }

  public async afterEach(to: Route, from: Route) {
    if (!to.meta.titler || to.meta.titler.service === 'DefaultTitlerService') {
      this.setTitleForRoute(to)
    }
  }

  public setTitleForRoute(route: Route) {
    this.titlerManager.updateTitle(route, route.meta?.titler?.params)
  }

  public subscribe(key: string, onChange: OnChange) {
    this.subscriptionService.subscribeWithSubscriptionKey<Route>(ROUTER_BEFORE_EACH, onChange, key)
  }

  public unsubscribe(key: string) {
    this.subscriptionService.unsubscribe(key)
  }

  public get currentRoute() {
    return this.router.currentRoute
  }

  public getCurrentRoute(): Route {
    return this.router.currentRoute.value
  }

  public getCurrentRoutesNames(): Set<RouteRecordName> {
    return this.router.currentRoute.value.matched.reduce<Set<RouteRecordName>>((acc, route) => {
      if (route.name) {
        acc.add(route.name)
      }

      const parent = isFunction(route.meta.parent)
        ? route.meta.parent(this.router.currentRoute.value)
        : route.meta.parent

      if (parent) {
        acc.add(parent)
      }

      return acc
    }, new Set())
  }

  public getPreviousRoute() {
    return this.previousRoute
  }

  public isEqual(routeA: RouteLocationRaw, routeB: RouteLocationRaw): boolean {
    return this.resolve(routeA).path === this.resolve(routeB).path
  }

  public isCurrentRoute(route: RouteLocationRaw) {
    return this.isEqual(route, this.getCurrentRoute())
  }

  public isCurrentRouteByName(name: string) {
    const currentRoute = this.getCurrentRoute()
    return currentRoute.name === name
  }

  public isChildOf(possibleParent: RouteLocationRaw, possibleChild: RouteLocationRaw): boolean {
    const resolvedParent = isRouteWithStringPathAndName(possibleParent) ? possibleParent : this.resolve(possibleParent)
    const resolvedChild = isRouteWithStringPathAndName(possibleChild) ? possibleChild : this.resolve(possibleChild)

    return resolvedChild.path.startsWith(resolvedParent.path) && resolvedChild.name !== resolvedParent.name
  }

  public isChildOfByName(possibleParent: RouteLocationRaw, possibleChild: RouteLocationRaw): boolean {
    const resolvedParent = isRouteWithStringPathAndName(possibleParent) ? possibleParent : this.resolve(possibleParent)
    const resolvedChild = isRouteWithStringPathAndName(possibleChild) ? possibleChild : this.resolve(possibleChild)
    const childName: string | null = resolvedChild.name ? String(resolvedChild.name) : null
    const parentName: string | null = resolvedParent.name ? String(resolvedParent.name) : null

    if (!childName || !parentName) {
      return false
    }

    // return true if child is self childName = parentName
    return childName.startsWith(parentName)
  }

  public isChildOfCurrentRoute(route: RouteLocationRaw) {
    return this.isChildOf(this.getCurrentRoute(), route)
  }

  public isCurrentRouteChildOf(route: RouteLocationRaw) {
    return this.isChildOf(route, this.getCurrentRoute())
  }

  public isCurrentRouteChildOfByName(route: RouteLocationRaw) {
    return this.isChildOfByName(route, this.getCurrentRoute())
  }

  public getCurrentRouteTitle(): string {
    return this.getCurrentRoute().meta.title || ''
  }

  public isActive(item: RouteRecordRaw) {
    if (!item.name) return false
    return this.isActiveRouteByName(item.name)
  }

  public isActiveRouteByName(routeName: RouteRecordName) {
    return this.getCurrentRoutesNames().has(routeName)
  }

  public getRouter(): T {
    return this.router
  }

  public resolve(to: RouteLocationRaw, currentLocation?: RouteLocationNormalizedLoaded) {
    return this.router.resolve(to, currentLocation)
  }

  public getFullUrl(route: RouteLocationRaw) {
    const { href } = this.resolve(route)
    return new URL(href, window.location.origin).href
  }

  public back(isResetModal = false) {
    // @todo make isResetModal workable
    if (isResetModal) {
      // Do nothing
    }
    this.router.back()
  }

  public push(location: RouteLocationRaw) {
    return this.router.push(location)
  }

  public replace(location: RouteLocationRaw) {
    return this.router.replace(location)
  }

  public openRouteInNewTab(location: RouteLocationRaw) {
    const route = this.router.resolve(location)

    this.windowService.open(route.href, '_blank')
  }

  public getRoutes(): RouteRecordRaw[] {
    return this.routes
  }

  public setRoutes(routes: RouteRecordRaw[]) {
    this.routes = routes
  }

  public getRouteFromRoot(groupName: string) {
    return this.getRouteByName(groupName, this.getRoutes())
  }

  public getRouteByName(name: string, routes?: RouteRecordRaw[]) {
    return this.toFlatten(routes ?? this.routes).filter((route: RouteRecordRaw) => route.name === name)[0]
  }

  public getByTagsFromGroup(groupName: string, tags: RouteTag[]) {
    return this.getByTags(this.toFlatten(this.getRouteFromRoot(groupName).children!), tags)
  }

  public getByTags(routes: RouteRecordRaw | RouteRecordRaw[], tags: RouteTag[]) {
    if (!isArray(routes) && !routes.children) {
      return []
    }

    const children: RouteRecordRaw[] = isArray(routes) ? routes : routes.children!
    return children.filter((child: RouteRecordRaw) => {
      if (child.meta && child.meta.tags) {
        return tags.every((tag) => child?.meta?.tags!.includes(tag))
      }

      return false
    })
  }

  public toFlatten(routes: RouteRecordRaw[]) {
    return routes.reduce((acc: RouteRecordRaw[], route: RouteRecordRaw) => {
      acc.push(route)
      if (route.children && route.children.length) {
        acc.push(...this.toFlatten(route.children))
      }
      return acc
    }, [])
  }

  public reset() {
    throw new TmRouterError('Will not call in dev or prod env')
  }

  public matchRoutesByPathContext(map: Record<string, Readonly<RouteLocationNamedOrPathWithParams>>): RouteLocationRaw {
    for (const [name, location] of Object.entries(map)) {
      if (!this.isCurrentRouteChildOf({ name })) {
        continue
      }

      if (isRouteWithPathAndParamsOnly(location)) {
        const { params, ...rest } = location
        rest.path = this.applyParametersToPath(location.path, params)
        return rest
      }

      return location
    }

    throw new TmRouterError('Not found matched location for all contexts')
  }

  public applyParametersToPath(path: string, params: Pick<RouteLocationNamedRaw, 'params'>) {
    return injectParams(path, params)
  }

  public abstract initRouter(): T
}

import dompurify from 'dompurify'
import { merge } from 'lodash-es'
import {
  canKeepClassAttribute,
  canKeepStyleAttribute,
  defaultPurifierConfig,
  richPurifierConfig,
  strictPurifierConfig,
} from '@/utils/string/html/const'
import { SanitizeHtmlMode, type SanitizeHtmlConfig } from '@/utils/string/html/types'
import type { PurifierConfig } from '@/utils/string/types'

const cache = new Map<string, string>()
const purifierInstance = dompurify()

purifierInstance.addHook('uponSanitizeAttribute', (node, evt) => {
  if (evt.attrName === 'class' && canKeepClassAttribute(node, evt)) {
    evt.forceKeepAttr = true
  }

  if (evt.attrName === 'style' && canKeepStyleAttribute(node, evt)) {
    evt.forceKeepAttr = true
  }
})

const getPurifyConfig = (arg?: SanitizeHtmlMode, config?: PurifierConfig | null): PurifierConfig => {
  if (arg === SanitizeHtmlMode.MERGE) {
    return merge(defaultPurifierConfig, config ?? {})
  }
  if (arg === SanitizeHtmlMode.STRICT) {
    return merge(strictPurifierConfig, config ?? {})
  }
  if (arg === SanitizeHtmlMode.RICH) {
    return merge(richPurifierConfig, config ?? {})
  }
  return config ?? defaultPurifierConfig
}

export const sanitizeHtml = (value: string | SanitizeHtmlConfig, arg?: SanitizeHtmlMode) => {
  if (!value) {
    return ''
  }

  const isValuePrimitive = typeof value === 'string'
  const toSanitizeValue = isValuePrimitive ? value : value.html
  const configParameter = isValuePrimitive ? null : value.config ?? null
  const sanitizerConfig = getPurifyConfig(arg, configParameter)

  const cacheKey = JSON.stringify({ toSanitizeValue, sanitizerConfig })

  const sanitizedValue = cache.get(cacheKey) ?? purifierInstance.sanitize(toSanitizeValue, sanitizerConfig)
  if (!cache.has(cacheKey)) {
    cache.set(cacheKey, sanitizedValue)
  }
  return sanitizedValue
}

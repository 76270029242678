import { inject, injectable } from 'inversify'
import ReopenAccount from '@/data/models/ReopenAccount'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmLocalStorageRepository from '@/data/repositories/ormLocalStorageRepository'
import type { Optional } from '@/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { HttpService } from '@/services/transport/httpService'
import type { AbstractEndpointsInterface } from '@/services/endpointsService'
import type LocalStorageTransport from '@/services/transport/storage/localStorageTransport'
import type LocalStorageAdapter from '@/services/transport/storage/localStorageAdapter'
import type LoggerService from '@/services/loggerService'

@RepoSettings({
  model: ReopenAccount,
  fetch: ReopenAccount.entity,
  create: ReopenAccount.entity,
  single: ReopenAccount.entity,
})
@injectable()
export default class ReopenAccountRepository extends OrmLocalStorageRepository<ReopenAccount> {
  protected id = '1'

  constructor(
    @inject(SERVICE_TYPES.LocalStorageTransport) protected readonly transport: LocalStorageTransport,
    @inject(SERVICE_TYPES.LocalStorageAdapter) protected readonly localStorageAdapter: LocalStorageAdapter,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.Api) protected readonly api: HttpService,
    @inject(SERVICE_TYPES.EndpointsService) protected readonly endpointsService: AbstractEndpointsInterface,
  ) {
    super(transport, localStorageAdapter, loggerService)
  }

  public reopenAccount(username: string, password: string) {
    return this.api.post(this.endpointsService.getPath('reopenAccount'), { username, password })
  }

  public async fetchReopenAccountData(): Promise<Optional<ReopenAccount>> {
    const data = await this.getRequest<Optional<ReopenAccount>>(this.id)

    if (!data) {
      return undefined
    }

    await this.storeReopenAccountData(data)
    return this.getReopenAccountData()
  }

  public getReopenAccountData(): Optional<ReopenAccount> {
    return this.find(this.id)
  }

  public async storeReopenAccountData(reopenAccountData: Pick<ReopenAccount, 'loginOrEmail'>) {
    await this.postRequest({
      ...reopenAccountData,
      id: this.id,
    })
    await this.fill(this.id)
  }

  public clearReopenAccountData() {
    return this.deleteRequest(this.id)
  }
}

import { AttrField, NumberField, StringField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import type { TmCountryCode } from '@/services/types'

export default class FormDetails<FC = unknown> extends BaseModel {
  @StringField()
  public uniqueCode: string

  @AttrField()
  public allowedCountryIds: TmCountryCode[]

  @NumberField()
  public widgetGroupId: number

  @AttrField()
  public settings: FC
}

import { injectable } from 'inversify'
import type { Mutation, Store } from 'vuex'
import { createStore } from 'vuex'
import VuexORM from '@vuex-orm/core'
import type { Database } from '@vuex-orm/core'
import { ORMDatabase } from 'vuex-orm-decorators'
import type { VuexOrmStore } from '@/services/vuex/types'
import type BaseModel from '@/data/models/BaseModel'
import { fixUpdatingForeignFieldsPlugin } from '@/services/vuex/plugins/fixUpdatingForeignFieldsPlugin'
import { fixTypeKeyStiPlugin } from '@/services/vuex/plugins/fixTypeKeyStiPlugin'
import { STI_TYPE_KEY } from '@/data/models/types'
import { addBaseMutationsByModel, getInsertMutationName } from '@/services/vuex/mutators/baseMutations'

@injectable()
class VuexService {
  private _store: Store<VuexOrmStore>

  public getStore() {
    if (this._store && this._store.$db && this._store.$db().isStarted) {
      return this._store
    }
    VuexORM.use(fixUpdatingForeignFieldsPlugin)
    VuexORM.use(fixTypeKeyStiPlugin, { stiTypeKey: STI_TYPE_KEY })
    this._store = createStore<VuexOrmStore>({
      plugins: [ORMDatabase.install()],
    })
    return this._store
  }

  public getModelByModelEntity(modelEntity: string): typeof BaseModel {
    return this.getDB()!.baseModel<typeof BaseModel>(modelEntity as any)
  }

  public getDB(): Database | null {
    return this.getStore().$db()
  }

  public addMutations(_mutations: Record<string, unknown>) {
    const store = this.getStore() as unknown as { _mutations: Record<string, Array<Mutation<unknown>>>; state: unknown }
    Object.keys(_mutations).forEach((type) => {
      const entry = store._mutations[type] || (store._mutations[type] = [])
      entry.push((payload) => {
        ;(_mutations[type] as Mutation<unknown>).call(store, store.state, payload)
      })
    })
  }

  public hasMutation(type: string): boolean {
    const store = this.getStore() as unknown as { _mutations: Record<string, Array<Mutation<unknown>>>; state: unknown }
    return Array.isArray(store._mutations[type]) && store._mutations[type].length > 0
  }

  public setDefaultMutation(model: typeof BaseModel) {
    if (!this.hasMutation(getInsertMutationName(model.entity))) {
      const mutations = {}
      addBaseMutationsByModel(model, mutations)
      this.addMutations(mutations)
    }
  }
}

export default VuexService

import type { RouteLocationRaw, RouteMeta, RouteRecordRaw } from 'vue-router'
import type { CampaignsComposeSmsRouteParams, ComposeMessageRouteQueryParams } from '@/components/views/compose/types'
import { scheduleRouteHash } from '@/components/views/compose/types'
import { AppModule } from '@/config/types'
import type { RedirectByPrevRouteResolverParams } from '@/services/resolvers/types'
import type { TmNamedRouteRecord } from '@/services/route/types'

export const composeMessageRouteName = 'user.compose.message'
export const composeSuccessMessageRouteName = 'user.compose.success-message'
export const composeSuccessMessagesRouteName = 'user.compose.success-messages'
export const composeSuccessScheduledRouteName = 'user.compose.success-scheduled'
export const composeMessageRoute: RouteLocationRaw = {
  name: composeMessageRouteName,
}

type ComposeMessageRouteRaw = {
  params?: CampaignsComposeSmsRouteParams
  openSchedule?: boolean
}

export const getComposeMessageRoute = (routeRaw?: ComposeMessageRouteRaw): RouteLocationRaw => ({
  name: composeMessageRouteName,
  state: routeRaw?.params,
  hash: routeRaw?.openSchedule ? scheduleRouteHash : '',
})

export const composeTextMessageRoute: TmNamedRouteRecord = {
  name: composeMessageRouteName,
  path: 'text-message',
  meta: {
    tabTitle: 'pageTabTitle.user.compose.message',
    accessControlGroups: ['NotCampaignsComposeMessageStrategy'],
  },
  props: (route) => ({
    openSchedule: route.hash === scheduleRouteHash,
    openCreditModal: (route.query as ComposeMessageRouteQueryParams).openCreditModal === 'true',
  }),
  component: () => import('@/components/views/compose/ComposeMessage.vue'),
}

export const composeCampaignRoute: TmNamedRouteRecord = {
  name: 'user.compose.campaign',
  path: 'text-message-campaign',
  meta: {
    tabTitle: 'pageTabTitle.user.compose.message',
    accessControlGroups: ['NotCampaignsComposeCampaignStrategy'],
  },
  component: () => import('@/components/views/compose/ComposeCampaign.vue'),
}

export const composeEmailRoute: TmNamedRouteRecord = {
  name: 'user.compose.email',
  path: 'email-coming-soon',
  meta: {
    tabTitle: 'pageTabTitle.user.compose.email',
    accessControlGroups: ['NotCampaignsComposeEmailStrategy'],
  },
  component: () => import('@/components/views/compose/ComposeEmail.vue'),
}

const successRoutesMeta: RouteMeta = {
  resolvers: [
    {
      service: 'RedirectByPrevRouteResolverService',
      params: {
        grantAccessFrom: [composeMessageRouteName, composeCampaignRoute.name],
        redirectTo: composeMessageRouteName,
      } as RedirectByPrevRouteResolverParams,
    },
  ],
}

export const getComposeRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.compose',
    path: 'compose',
    meta: {
      tags: ['USER_TOP_MENU'],
      tabTitle: 'pageTabTitle.user.compose.compose',
      modules: [
        AppModule.SenderSettingsCountries,
        AppModule.Contacts,
        AppModule.ContactList,
        AppModule.ScheduledCalendar,
        AppModule.ScheduledNotSent,
        AppModule.RRule,
        AppModule.MessageSend,
        AppModule.Compose,
        AppModule.RecentRecipient,
        AppModule.ContactCounter,
        AppModule.SentSmsBase,
        AppModule.Templates,
        AppModule.FilteredViews,
        AppModule.Segments,
        AppModule.Recipient,
      ],
    },
    redirect: { name: composeMessageRouteName },
    component: () => import('@/components/views/compose/Compose.vue'),
    children: [
      composeTextMessageRoute as RouteRecordRaw,
      {
        name: composeSuccessMessageRouteName,
        path: 'success-message/:id',
        props: true,
        component: () => import('@/components/views/compose/ComposeSuccessMessage.vue'),
        meta: {
          ...successRoutesMeta,
          tabTitle: 'pageTabTitle.user.compose.sentSmsDetails',
          titler: {
            service: 'RouteTitlerService',
          },
          accessControlGroups: ['NotCampaignsComposeSuccessMessageStrategy'],
        },
      },
      {
        name: composeSuccessMessagesRouteName,
        path: 'success-messages/:id',
        props: true,
        component: () => import('@/components/views/compose/ComposeSuccessMessages.vue'),
        meta: {
          ...successRoutesMeta,
          tabTitle: 'pageTabTitle.user.compose.sentSmsBulkDetailsSummary',
          titler: {
            service: 'RouteTitlerService',
          },
          accessControlGroups: ['NotCampaignsComposeSuccessMessagesStrategy'],
        },
      },
      {
        name: composeSuccessScheduledRouteName,
        path: 'success-scheduled/:id',
        props: true,
        component: () => import('@/components/views/compose/ComposeSuccessSendScheduled.vue'),
        meta: {
          ...successRoutesMeta,
          tabTitle: 'pageTabTitle.user.compose.scheduledDetails',
          titler: {
            service: 'RouteTitlerService',
          },
          accessControlGroups: ['NotCampaignsComposeSuccessScheduledStrategy'],
        },
      },
      composeCampaignRoute as RouteRecordRaw,
      composeEmailRoute as RouteRecordRaw,
    ],
  },
]

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  AutomationRuleDetailsRepository,
  AutomationRuleDetailsService,
  AutomationRulesFlowFiltersFormService,
  AutomationRulesFlowAutoResponderFormService,
  AutomationRulesFlowSettingsFormService,
}

export type AutomationRulesDetailsServices = keyof typeof SERVICES

export const AUTOMATION_RULES_DETAILS_SERVICE_TYPES: Record<AutomationRulesDetailsServices, symbol> =
  makeServicesTypes<AutomationRulesDetailsServices>(enumKeysToArray<AutomationRulesDetailsServices>(SERVICES))

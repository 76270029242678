import { AttrField, BelongsToField, BooleanField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { SmsStatus, SmsRejectReason } from '@/types'
import SessionShort from '@/data/models/domain/session/SessionShort'
import Contact from '@/data/models/domain/Contact'
import SenderSource from '@/data/models/domain/SenderSource'

@OrmModel(modelNames.SENT_SMS)
export default class SentSms extends BaseModel {
  @AttrField()
  public receiver: string | null

  @StringField()
  public messageTime: string

  @StringField()
  public text: string

  @StringField()
  public status: SmsStatus

  @StringField()
  public firstName: string // this firstName contact had when the message was sent

  @StringField()
  public lastName: string // this lastName contact had when the message was sent

  @AttrField()
  public contactId: string

  @BelongsToField(Contact, 'contactId')
  public contact: Contact | null

  @AttrField('', (value: string | number) => `${value}`)
  public sessionId: string

  @BelongsToField(SessionShort, 'sessionId')
  public session: SessionShort

  @AttrField()
  public senderSourcePhone: string

  @BelongsToField(SenderSource, 'senderSourcePhone')
  public senderSource: SenderSource

  @AttrField()
  public rejectReason: SmsRejectReason

  @BooleanField()
  public deleted: boolean

  public get fullName(): string {
    return [this.firstName, this.lastName].join(' ').trim()
  }
}

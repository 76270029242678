import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/container'
import type GlobalStateService from '@/services/GlobalStateService'

@injectable()
export class AuthLoaderService {
  private fieldId = 'showAuthLoader' as const

  constructor(@inject(SERVICE_TYPES.GlobalStateService) protected readonly globalStateService: GlobalStateService) {}

  public async fetchLoaderState(): Promise<boolean> {
    return Boolean(await this.globalStateService.getFieldValue(this.fieldId))
  }

  public getLoaderState(): boolean {
    return Boolean(this.globalStateService.getLoadedState()?.showAuthLoader)
  }

  public async showLoader(): Promise<void> {
    await this.toggleLoader(true)
  }

  public async hideLoader(): Promise<void> {
    await this.toggleLoader(false)
    await this.globalStateService.setFieldValue(this.fieldId, false)
  }

  protected async toggleLoader(visible: boolean): Promise<void> {
    await this.globalStateService.setFieldValue(this.fieldId, visible)
  }
}

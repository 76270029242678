import { inject, injectable } from 'inversify'
import { clone, isArray } from 'lodash-es'
import type {
  AbstractFieldConfig,
  FieldConfig,
  FieldsConfig,
  FieldType,
  FormHook,
  FormHookType,
} from '@/services/forms/types'
import { defaultFormState, ValidationModes } from '@/services/forms/types'
import { SERVICE_TYPES } from '@/core/container/types'
import { TmFormBuilderError } from '@/core/error/tmFormBuilderError'
import type BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import type BaseFieldRepository from '@/data/repositories/form/baseFieldRepository'
import type BaseFormRepository from '@/data/repositories/form/baseFormRepository'
import BaseForm from '@/services/forms/baseForm'
import type {
  ArrayConfig,
  ArrayModifierType,
  GroupConfig,
  GroupInArrayConfig,
  GroupModifierType,
  FormBuilderInterface,
  ControlInArrayConfig,
} from '@/services/forms/baseForm/types'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import FieldGroup from '@/data/models/forms/FieldGroup'
import FieldArray from '@/data/models/forms/FieldArray'
import { TmFormError } from '@/core/error/tmFormError'
import type { FieldMapper } from '@/services/forms/baseForm/fieldMapper'
import type BaseModel from '@/data/models/BaseModel'
import { ARRAY_PROTO_FIELD } from '@/services/forms/baseForm/types'
import type { Forms } from '@/services/forms/formTypes'
import { TmFormBuilderInitError } from '@/core/error/TmFormBuilderInitError'
import { TmFormBuilderFieldNotFoundError } from '@/core/error/tmFormBuilderFieldNotFoundError'
import type { ILogger } from '@/services/types'
import RootBaseForm from '@/services/forms/rootBaseForm'
import { formNameBuilder } from '@/services/forms/baseForm/typedFormBuilder/helpers'
import type { ModelRaw } from '@/types'

const ROOT_NAME = '<ROOT>'

@injectable()
export default class FormBuilderService implements FormBuilderInterface {
  protected formId: Forms

  protected fields: Record<string, BaseForm<BaseFieldModel>> = {}

  protected root: RootBaseForm | null

  constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: ILogger,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.FieldMapper) protected readonly fieldMapper: typeof FieldMapper,
    @inject(SERVICE_TYPES.BaseFormRepository) protected readonly baseFormRepository: BaseFormRepository,
    @inject(SERVICE_TYPES.BaseFieldRepository) protected readonly baseFieldRepository: BaseFieldRepository,
  ) {}

  public init(formId: Forms) {
    this.formId = formId
    this.root = new RootBaseForm({
      name: this.getFieldId(ROOT_NAME),
    })
    this.baseFormRepository.insert([{ id: formId, formState: defaultFormState }])
    this.log(`init for ${formId}`)
    this.logRaw(this.root)
    return this
  }

  public array(groupConfig: ArrayConfig, parentName = '') {
    const array = this.control(
      {
        ...groupConfig,
        modelType: 'fieldArray',
        validators: groupConfig.validators || this.validationRulesBuilderService.createRules().hasSomeValid(),
      },
      parentName,
    )
    this.log(`array() for ${parentName}`)
    this.logRaw(groupConfig)
    this.logRaw(array)
    return this.getArrayModifier(this.toFieldId(parentName, groupConfig.name, ''))
  }

  public group(groupConfig: GroupConfig, parentName = '') {
    const group = this.control(
      {
        ...groupConfig,
        modelType: 'fieldGroup',
        validators: groupConfig.validators || this.validationRulesBuilderService.createRules().hasSomeValid(),
      },
      parentName,
    )
    this.log(`group() for ${parentName}`)
    this.logRaw(groupConfig)
    this.logRaw(group)
    return this.groupModifier(this.toFieldId(parentName, groupConfig.name, ''))
  }

  public control(fieldConfig: FieldConfig, parentName = ''): BaseForm<BaseFieldModel> {
    this.checkInit()
    const id = this.getFieldId(fieldConfig.name, parentName)
    const type = this.fieldMapper.getType(fieldConfig.modelType)
    this.persist(id, fieldConfig, type)
    const field = this.baseFieldRepository.findEntityByIdOrFail(id)
    this.fields[id] = new BaseForm(fieldConfig, field, undefined, fieldConfig.validators?.getLocalValidators())
    this.log(`new ${id}`)
    this.logRaw(this.fields[id])
    if (parentName.length === 0) {
      this.getForm().addChild(this.fields[id], id)
    }
    return this.fields[id]
  }

  public getGroupModifier(name: string) {
    return this.groupModifier(name)
  }

  public extendArray(name: string, parentName = '') {
    return this.getArrayModifier(this.getNameById(this.getFieldId(name, parentName))).clone()
  }

  public removeField(name: string): this {
    this.removeFieldById(this.getFieldId(name))
    return this
  }

  private decreaseArrayIndexInFieldId(fieldId: string) {
    const fieldPath = this.getFieldPath(fieldId)
    const arrayIndex = fieldPath[fieldPath.length - 1]
    const preparedArrayIndex = +arrayIndex
    if (Number.isNaN(preparedArrayIndex)) {
      throw new TmFormBuilderError(`Can't convert array index to Number. String array index = "${arrayIndex}"`)
    }
    fieldPath[fieldPath.length - 1] = (preparedArrayIndex - 1).toString()
    return formNameBuilder(fieldPath)
  }

  private removeItemFromFieldArray(children: string[], idToRemove: string) {
    const preparedChildren: string[] = []
    let isDecreasesIndex = false
    children.forEach((t) => {
      if (isDecreasesIndex) {
        const updatedFieldId = this.decreaseArrayIndexInFieldId(t)
        preparedChildren.push(updatedFieldId)
      } else if (t === idToRemove) {
        isDecreasesIndex = true
      } else {
        preparedChildren.push(t)
      }
    })
    return preparedChildren
  }

  private cloneField(field: BaseFieldModel, newFieldId: string) {
    const fieldPath = this.getFieldPath(newFieldId)
    const newFieldName = fieldPath[fieldPath.length - 1]
    const fieldJson = field.$toJson() as ModelRaw<BaseFieldModel>
    fieldJson.id = newFieldId
    fieldJson.name = newFieldName
    const fieldType = this.fieldMapper.getFieldType(field)

    if (fieldType) {
      this.baseFieldRepository.insert([fieldJson], fieldType)
    } else {
      this.baseFieldRepository.insert([fieldJson])
    }
    return this.baseFieldRepository.findEntityByIdOrFail(fieldJson.id)
  }

  private updateFieldAfterRemoved(field: BaseForm<BaseFieldModel>, updatedFieldId: string, isLastElement: boolean) {
    const oldFieldId = field.id

    this.fields[updatedFieldId] = this.fields[field.id]

    field.id = updatedFieldId
    const clonedField = this.cloneField(field.field, updatedFieldId)
    field.setField(clonedField)

    if (isLastElement) {
      this.baseFieldRepository.delete([oldFieldId])
      delete this.fields[oldFieldId]
    }
  }

  public removeFieldById(id: string): this {
    this.checkInit()
    const field = this.fields[id]
    this.log(`removeFieldById ${id}`)
    if (!field) {
      throw new TmFormBuilderError(`Non-exist field ${id}`)
    }
    this.log('deepRemove')
    this.deepRemove(field)
    delete this.fields[id]

    const { parent } = field
    if (parent) {
      if (parent instanceof BaseForm && this.isArrayFieldById(parent.field.id)) {
        const arrayId: string = parent.field.id
        const idToRemove: string = id
        const children = clone(this.baseFieldRepository.findFieldArrayByIdOrFail(arrayId).children)
        const preparedChildren = this.removeItemFromFieldArray(children, idToRemove)
        this.baseFieldRepository.updateFieldArrayChildren(arrayId, preparedChildren)

        const preparedFieldChildrens: BaseForm<BaseFieldModel>[] = []
        let isDecreasesFieldIndex = false
        const childrensLastElementIndex = this.fields[arrayId].childrens.length - 1
        this.fields[arrayId].childrens.forEach((t, i) => {
          if (isDecreasesFieldIndex) {
            const updatedFieldId = this.decreaseArrayIndexInFieldId(t.id)
            this.updateFieldAfterRemoved(t, updatedFieldId, childrensLastElementIndex === i)

            const arrayChildrensLastElementIndex = t.childrens.length - 1
            t.childrens.forEach((c, index) => {
              this.updateFieldAfterRemoved(
                c,
                formNameBuilder([updatedFieldId, c.field.name]),
                arrayChildrensLastElementIndex === index,
              )
            })
            preparedFieldChildrens.push(t)
          } else if (t.id === idToRemove) {
            isDecreasesFieldIndex = true
          } else {
            preparedFieldChildrens.push(t)
          }
        })
        this.fields[arrayId].childrens = preparedFieldChildrens

        this.log('Actualize parent array')
      } else if (parent instanceof BaseForm) {
        this.removeGroupItem(parent.field.id, id)
        this.log('Actualize parent group')
      } else {
        const form = this.getForm()
        form.childrens = form.childrens.filter((c) => c.id !== id)
      }
      this.logRaw(parent)
    }
    this.logRaw(this.root)
    return this
  }

  public reorderArrayItems(newIdsOrder: string[]) {
    const field = this.fields[newIdsOrder[0]]

    if (!field.parent) {
      throw new TmFormBuilderError("Can't reorder non array field")
    }

    if (field.parent instanceof RootBaseForm) {
      throw new TmFormBuilderError("Can't reorder field if parent instanceof RootBaseForm")
    }

    const groupId = field.parent.field.id

    this.fields[groupId].childrens = newIdsOrder.map((id) => this.fields[id])
    this.baseFieldRepository.updateFieldArrayChildren(groupId, newIdsOrder)

    return this
  }

  public hasField(id: string): boolean {
    this.checkInit()
    return !!this.fields[id]
  }

  public hasFieldByName(name: string): boolean {
    return this.hasField(this.getFieldId(name))
  }

  public hasErrorField(id: string, errorFieldName: string): boolean {
    this.checkInit()
    if (this.hasField(id)) {
      return true
    }

    return !!this.getErrorFieldNameOrNull(errorFieldName)
  }

  public getFieldById(id: string) {
    this.checkInit()
    const field = this.fields[id]
    if (!field) {
      throw new TmFormBuilderFieldNotFoundError(`No field with id ${id}`)
    }
    field.field = this.baseFieldRepository.findEntityByIdOrFail(id)
    return field
  }

  public getErrorFieldName(name: string): string {
    return this.getErrorFieldNameOrNull(name) ?? name
  }

  public getFormId(): Forms {
    return this.formId
  }

  public getForm() {
    if (this.root) {
      return this.root
    }
    throw new TmFormBuilderError('Form is not init')
  }

  public getField(name: string) {
    return this.getFieldById(this.getFieldId(name))
  }

  public destroy() {
    this.log('destroy', this.getFormId())
    this.root = null
    this.fields = {}
  }

  public isInit() {
    return this.baseFormRepository.query().where('id', this.formId).exists() && !!this.root
  }

  public buildByData(data: Record<string, any>, parentName = '') {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const name in data) {
      const fieldId = this.getFieldId(name, parentName)
      if (this.hasField(fieldId)) {
        const { field } = this.getFieldById(fieldId)
        if (field instanceof FieldGroup) {
          this.buildByData(data[name], this.getNameById(field.id))
        } else if (field instanceof FieldArray && isArray(data[name])) {
          let needToExtendTimes: number = data[name].length - field.children.length
          while (needToExtendTimes > 0) {
            this.extendArray(field.name, parentName)
            needToExtendTimes -= 1
          }
        }
      }
    }
  }

  public isArrayField(field: BaseForm<BaseFieldModel>): field is BaseForm<FieldArray> {
    return field.field instanceof FieldArray
  }

  public isGroupField(field: BaseForm<BaseFieldModel>): field is BaseForm<FieldGroup> {
    return field.field instanceof FieldGroup
  }

  public isArrayFieldById(id: string): boolean {
    return this.getFieldById(id).field instanceof FieldArray
  }

  public setHook(hookName: FormHookType, hook: FormHook) {
    if (!this.root) {
      throw new TmFormError('Please init form')
    }
    this.root.hooks[hookName].push(hook)
    return this
  }

  public getHook(hookName: FormHookType): FormHook[] {
    if (!this.root) {
      throw new TmFormError('Please init form')
    }
    return this.root.hooks[hookName]
  }

  public getNameById(id: string) {
    return id.replace(`${this.getFormId()}.`, '')
  }

  public getFieldId(name: string, parentName = '') {
    return this.toFieldId(parentName, name, this.getFormId())
  }

  public getFieldPath(name: string) {
    return name.split('.')
  }

  public getArrayModifier(name: string): ArrayModifierType {
    const instance = this.fields[this.getFieldId(name)]
    return {
      initGroup: (groupConfig: GroupInArrayConfig = {}) => {
        this.log('initGroup')
        const newGroup = this.group(
          {
            ...groupConfig,
            name: ARRAY_PROTO_FIELD,
          },
          name,
        )
        instance.addItemPrototype(newGroup.instance)
        return newGroup
      },
      initArray: (arrayConfig: ArrayConfig) => {
        this.log('initArray')
        const newArray = this.array(
          {
            ...arrayConfig,
            name: ARRAY_PROTO_FIELD,
          },
          name,
        )
        instance.addItemPrototype(newArray.instance)
        return newArray
      },
      initControl: (fieldConfig: ControlInArrayConfig): BaseForm<BaseFieldModel> => {
        this.log('initControl')
        const preparedFieldConfig: FieldConfig = {
          ...fieldConfig,
          name: ARRAY_PROTO_FIELD,
        }
        const control = this.control(preparedFieldConfig, name)
        instance.addItemPrototype(control)
        return control
      },
      clone: (): BaseForm<BaseFieldModel> => {
        if (!this.isArrayField(instance)) {
          throw new TmFormBuilderError('Trying  clone non-array field')
        }

        this.log('clone')
        const toClone = instance.itemPrototype
        if (!toClone) {
          throw new TmFormBuilderError('Nothing to clone')
        }
        const clonedChild = this.deepClone(toClone, name, this.getNextIndex(instance))
        this.newArrayItem(instance, clonedChild)
        return clonedChild
      },
      instance,
    }
  }

  public createField<T extends AbstractFieldConfig>(
    name: string,
    fieldType: FieldType,
    overrideConfig: Partial<T>,
    parentInstance?: BaseForm<BaseFieldModel>,
  ): BaseForm<BaseFieldModel> {
    const config: FieldConfig = {
      name,
      ...overrideConfig,
      fieldType, // to avoid override fieldType
    } as FieldConfig
    if (parentInstance) {
      return this.getGroupModifier(this.getNameById(parentInstance.field.id)).addControl(config).instance
    }
    return this.control(config, '')
  }

  public getInvalidFieldNames(): string[] {
    this.checkInit()
    const fieldIds = Object.keys(this.fields)
    const fields = this.baseFieldRepository.getRaws(fieldIds)

    return fields.filter(({ invalid }) => invalid).map(({ id }) => this.getNameById(id))
  }

  protected toSubtreeConfigByPath(path: string[]): FieldsConfig {
    path = path.filter((item) => item)
    if (path.length === 0) {
      throw new TmFormBuilderError('Empty path')
    }
    const root = path.shift()!
    const rootField = this.getField(root)
    return {
      ...rootField.config,
      fields: path.length > 0 ? [this.toSubtreeConfigByPath(path)] : [],
    }
  }

  protected unpersist(name: string) {
    this.log(`unpersist ${name}`)
    this.baseFieldRepository.delete([this.getFieldId(name, '')])
  }

  protected persist(id: string, fieldConfig: FieldConfig, type: typeof BaseModel) {
    this.log(`persist ${id}`)
    this.baseFieldRepository.insert(
      [
        {
          id,
          formId: this.getFormId(),
          name: fieldConfig.name,
          value: fieldConfig.value,
          initialValue: fieldConfig.value,
          validators: fieldConfig.validators ? fieldConfig.validators.getRules() : [],
          validationMode: fieldConfig.validationMode || ValidationModes.eager,
          options: 'options' in fieldConfig && fieldConfig.options ? fieldConfig.options() : [],
          fieldType: fieldConfig.fieldType,
          crossField: fieldConfig.crossField ?? [],
          serverErrorFields: fieldConfig.serverErrorFields ?? [],
          errorCustomMessage: fieldConfig.errorCustomMessage ? fieldConfig.errorCustomMessage : '',
        },
      ],
      type,
    )
  }

  protected toFieldId(parentName: string, name: string, formId = '') {
    return [formId, parentName, name].filter((n) => n.length > 0).join('.')
  }

  protected deepClone(
    toClone: BaseForm<BaseFieldModel>,
    parentName: string,
    newIndex?: number,
  ): BaseForm<BaseFieldModel> {
    this.log(`deepClone for ${parentName} and index = ${newIndex}`)
    this.logRaw(toClone)
    if (toClone.field instanceof FieldGroup || toClone.field instanceof FieldArray) {
      if (newIndex === undefined) {
        throw new TmFormBuilderError('No index to clone nested array')
      }
      const cloned: BaseForm<BaseFieldModel> = this.control(
        {
          ...toClone.config,
          name: newIndex.toString(),
        },
        parentName,
      )

      toClone.childrens.forEach((child) => {
        const newParentId = this.toFieldId(parentName, newIndex.toString())
        const newChildId = this.toFieldId(newParentId, child.config.name, this.getFormId())

        const clonedChild = this.deepClone(child, newParentId)
        cloned.addChild(clonedChild, newChildId)
      })
      return cloned
    }
    return this.control(
      newIndex !== undefined
        ? {
            ...toClone.config,
            name: newIndex.toString(),
          }
        : toClone.config,
      parentName,
    )
  }

  protected deepRemove(toRemove: BaseForm<BaseFieldModel>) {
    // need to implement array in array?
    if (toRemove.field instanceof FieldGroup) {
      this.log(`deepRemove group ${toRemove.field.id}`)
      toRemove.childrens.forEach((c) => {
        this.deepRemove(c)
      })
      toRemove.childrens = []
      this.baseFieldRepository.delete([toRemove.field.id])
    } else {
      delete this.fields[toRemove.field.id]
      this.baseFieldRepository.delete([toRemove.field.id])
    }
  }

  protected groupModifier(name: string): GroupModifierType {
    const instance = this.fields[this.getFieldId(name)]
    if (!instance) {
      throw new TmFormBuilderError(`Non-exist field: ${name}`)
    }
    if (
      !instance.config.modelType ||
      (instance.config.modelType && !['fieldGroup', 'fieldArray'].includes(instance.config.modelType))
    ) {
      throw new TmFormBuilderError('Control is not modifiable')
    }
    return this.groupChainBuilder(name, instance)
  }

  protected groupChainBuilder(name: string, instance: BaseForm<any>): GroupModifierType {
    return {
      addControl: (fieldConfig: FieldConfig) => {
        this.log('Add control')
        this.logRaw(fieldConfig)
        const control = this.control(fieldConfig, name)
        instance.addChild(control, this.getFieldId(control.config.name, name))
        return this.groupChainBuilder(name, instance)
      },
      addGroup: (fieldConfig: GroupConfig) => {
        this.log('Add group')
        this.logRaw(fieldConfig)
        const newGroup = this.group(fieldConfig, name)
        instance.addChild(newGroup.instance, this.getFieldId(newGroup.instance.config.name, name))
        return this.groupModifier(this.toFieldId(name, fieldConfig.name, ''))
      },
      addArray: (fieldConfig: ArrayConfig) => {
        this.log('Add array')
        this.logRaw(fieldConfig)
        const newArray = this.array(fieldConfig, name)
        instance.addChild(newArray.instance, this.getFieldId(newArray.instance.config.name, name))
        return this.getArrayModifier(this.toFieldId(name, fieldConfig.name, ''))
      },
      instance,
    }
  }

  protected newArrayItem(instance: BaseForm<BaseFieldModel>, cloned: BaseForm<BaseFieldModel>) {
    const field = this.baseFieldRepository.findFieldArrayByIdOrFail(instance.field.id)
    const children = clone(field.children)
    children.push(cloned.field.id)
    this.baseFieldRepository.updateFieldArrayChildren(instance.field.id, children)
    instance.addChild(cloned, cloned.field.id)
    this.log('newArrayItem')
  }

  protected removeGroupItem(groupId: string, idToRemove: string) {
    this.fields[groupId].childrens = this.fields[groupId].childrens.filter((c) => c.id !== idToRemove)
  }

  protected checkInit() {
    if (!this.isInit()) {
      throw new TmFormBuilderInitError(`Not init form for ${this.getFormId()}`)
    }
  }

  protected log(message: string, subchannel?: string) {
    this.logger.log('formBuilder', message, subchannel || '')
  }

  protected logRaw(raw: any) {
    this.logger.raw('formBuilder', raw)
  }

  protected getNextIndex(instance: BaseForm<FieldArray>) {
    if (!this.isArrayFieldById(instance.field.id)) {
      throw new TmFormBuilderError('Cannot get new index for non-array field')
    }
    const allIndexes = instance.field.children.map((id) => parseInt(this.getFieldById(id).field.name, 10))
    return allIndexes.length ? Math.max(...allIndexes) + 1 : 0
  }

  protected getErrorFieldNameOrNull(errorFieldName: string): string | null {
    return (
      Object.entries(this.fields).find((field) => {
        return field[1].config.serverErrorFields?.includes(errorFieldName)
      })?.[1].config.name || null
    )
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type ServerSubscriptionService from '@/services/transport/serverSubscriptionService'
import type { TServerEvent } from '@/services/transport/serverEvents'
import Contact from '@/data/models/domain/Contact'
import { ModelEventType } from '@/services/transport/types'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type ContactService from '@/services/domain/contact/contactService'
import BlockedContact from '@/data/models/domain/BlockedContact'
import UnsubscribedContact from '@/data/models/domain/UnsubscribedContact'
import type NoteService from '@/services/domain/note/noteService'
import ContactList from '@/data/models/domain/ContactList'

@injectable()
export default class ContactServerEventsResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.ServerSubscriptionService)
    private readonly serverSubscriptionService: ServerSubscriptionService,
    @inject(SERVICE_TYPES.ModelSubscriptionService)
    protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.ContactService)
    private readonly contactService: ContactService,
    @inject(SERVICE_TYPES.NoteService)
    private readonly noteService: NoteService,
  ) {}

  public async resolve() {
    this.serverSubscriptionService.subscribe('contactStateChanged', this.contactStateChangedHandler)
    this.serverSubscriptionService.subscribe('contactDeleted', this.contactDeletedHandler)
    this.serverSubscriptionService.subscribe('contactAdded', this.contactAddedHandler)
    this.serverSubscriptionService.subscribe('contactUnsubscribed', this.contactUnsubscribedResubscribedHandler)
    this.serverSubscriptionService.subscribe('contactResubscribed', this.contactUnsubscribedResubscribedHandler)
    this.serverSubscriptionService.subscribe('contactNoteAdded', this.contactNoteAddedHandler)
    this.serverSubscriptionService.subscribe('contactNoteDeleted', this.contactNoteDeletedHandler)
    this.serverSubscriptionService.subscribe('contactNoteStateChanged', this.contactNoteStateChangedHandler)
    this.serverSubscriptionService.subscribe('listCacheClear', this.listCacheClearHandler)
  }

  private contactStateChangedHandler = async (e: TServerEvent<'contactStateChanged'>) => {
    await this.contactService.insertOrUpdate([e.payload])
    this.subscription.emitByModel(Contact, { ids: [e.payload.id], eventType: ModelEventType.UPDATE })
    this.subscription.emitByModel(BlockedContact, { ids: [e.payload.id], eventType: ModelEventType.UPDATE })
    this.subscription.emitByModel(UnsubscribedContact, { ids: [e.payload.id], eventType: ModelEventType.UPDATE })
  }

  private contactDeletedHandler = (e: TServerEvent<'contactDeleted'>) => {
    this.contactService.deleteFromStore(e.payload.ids.map((t) => t.toString()))
  }

  private contactAddedHandler = (e: TServerEvent<'contactAdded'>) => {
    this.subscription.emitByModel(Contact, { ids: [e.payload.id], eventType: ModelEventType.CREATE })
  }

  private contactUnsubscribedResubscribedHandler = () => {
    this.subscription.emitByModel(UnsubscribedContact, {
      ids: [],
      eventType: ModelEventType.UPDATE,
    })
  }

  private contactNoteAddedHandler = (e: TServerEvent<'contactNoteAdded'>) => {
    this.contactService.addNote(e.payload.contactId.toString(), {
      ...e.payload,
      id: e.payload.id.toString(),
      userId: e.payload.userId.toString(),
    })
  }

  private contactNoteDeletedHandler = (e: TServerEvent<'contactNoteDeleted'>) => {
    this.contactService.deleteNote(e.payload.contactId.toString(), e.payload.ids.map(String), e.payload.all)
  }

  private contactNoteStateChangedHandler = (e: TServerEvent<'contactNoteStateChanged'>) => {
    this.noteService.insertOrUpdate([
      {
        ...e.payload,
        id: e.payload.id.toString(),
        userId: e.payload.userId.toString(),
      },
    ])
  }

  private listCacheClearHandler = () => {
    this.subscription.emitByModel(ContactList, {
      ids: [],
      eventType: ModelEventType.UPDATE,
    })
  }

  public async unresolve() {
    this.serverSubscriptionService.unsubscribe('contactStateChanged', this.contactStateChangedHandler)
    this.serverSubscriptionService.unsubscribe('contactDeleted', this.contactDeletedHandler)
    this.serverSubscriptionService.unsubscribe('contactAdded', this.contactAddedHandler)
    this.serverSubscriptionService.unsubscribe('contactUnsubscribed', this.contactUnsubscribedResubscribedHandler)
    this.serverSubscriptionService.unsubscribe('contactResubscribed', this.contactUnsubscribedResubscribedHandler)
    this.serverSubscriptionService.unsubscribe('contactNoteAdded', this.contactNoteAddedHandler)
    this.serverSubscriptionService.unsubscribe('contactNoteDeleted', this.contactNoteDeletedHandler)
    this.serverSubscriptionService.unsubscribe('contactNoteStateChanged', this.contactNoteStateChangedHandler)
    this.serverSubscriptionService.unsubscribe('listCacheClear', this.listCacheClearHandler)
  }
}

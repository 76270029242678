import { inject, injectable } from 'inversify'
import type { Router, RouteRecordName } from 'vue-router'
import posthog from 'posthog-js'
// eslint-disable-next-line tp/forbid-import-composable-to-service,tp/using-vue-in-services-restriction
import type { Plugin } from '@/composition/vue/compositionApi'
import ConfigReader from '@/core/configReader'
import ExternalTrackerService from '@/services/tracking/externalTrackerService'
import { SERVICE_TYPES } from '@/core/container/types'
import type RouterService from '@/services/route/routerService'
import type { PostHogRouteEvent, TrackableConfig } from '@/services/tracking/types'
import {
  PostHogEvent,
  postHogOAuthProviderEvents,
  postHogRegulationsEvents,
  postHogRouteEvents,
  postHogRoutes,
} from '@/services/tracking/types'
import { type Route, ROUTER_AFTER_EACH } from '@/services/route/types'
import type SubscriptionService from '@/services/transport/subscriptionService'
import type LoggerService from '@/services/loggerService'
import { isDev } from '@/utils/system'
import type UserService from '@/services/domain/user/userService'
import type DefaultTitlerService from '@/services/route/titlers/defaultTitlerService'
import type { OAuthProvider } from '@/services/domain/user/types'
import type { Nullable } from '@/types'
import type AccessControlService from '@/services/accessControl/accessControlService'

@injectable()
export class PostHogService extends ExternalTrackerService {
  private _isInit = false

  public get isInit() {
    return this._isInit
  }

  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
    @inject(SERVICE_TYPES.DefaultTitlerService) protected readonly titlerService: DefaultTitlerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.AccessControlService) protected readonly accessControlService: AccessControlService,
  ) {
    super()
  }

  public getConfig(): TrackableConfig {
    const config = ConfigReader.config()

    return {
      id: config.tracking.postHogKey,
      host: config.tracking.postHogUrl,
    }
  }

  public getPlugin(): Plugin {
    return {
      install: () => this.initialize(),
    }
  }

  public cleanUp(): void {
    this.getPosthog().reset()
    this._isInit = false
  }

  public trackSignUp(signUpMethod: string): void {
    this.getPosthog().capture(PostHogEvent.signUp, {
      $set: { signUpMethod },
    })
  }

  public trackEvent(eventName: PostHogEvent, title?: string): void {
    try {
      const router: Router = this.routerService.getRouter()
      const route = router.currentRoute.value
      let routeTitle: string | undefined = title
      if (!routeTitle) {
        routeTitle = this.titlerService.getTitle(route, route.params as Record<string, string>)
      }
      this.getPosthog().capture(eventName, {
        title: routeTitle,
      })
    } catch (e: any) {
      this.loggerService.error('error', e.message)
    }
  }

  public trackByOAuthProvider(provider: OAuthProvider): void {
    const postHogEvent: Nullable<PostHogEvent> = this.getPostHogEventByOAuthProvider(provider)

    this.trackEvent(PostHogEvent.signUpFormCompleted)
    if (postHogEvent) {
      this.trackEvent(postHogEvent)
    }
  }

  public trackRegulationsSubmissionEvent(action: string) {
    // @todo refactor trackRegulationsSubmissionEvent trackByOAuthProvider trackSignUp to scoped services by interface
    const postHogEvent: Nullable<PostHogEvent> = this.getPostHogEventByRegulationAction(action)
    if (postHogEvent) {
      this.trackEvent(postHogEvent)
    }
  }

  public getPosthog() {
    return posthog
  }

  protected async initialize(): Promise<void> {
    const router: Router = this.routerService.getRouter()
    const key: string = this.getConfig().id
    const host: string = String(this.getConfig().host)
    try {
      this.getPosthog().init(key, {
        api_host: host,
        capture_pageview: false,
        capture_pageleave: false,
        disable_session_recording: true, // https://posthog.com/tutorials/limit-session-recordings
        disable_web_experiments: false, // https://posthog.com/docs/experiments/no-code-web-experiments
        loaded: (): void => {
          if (isDev()) {
            this.getPosthog().debug()
          }
        },
      })

      this._isInit = true

      const track = (route: Route) => {
        this.trackPage(route)
      }
      const trackingHandler = async () => {
        await router.isReady()
        await this.accessControlService.waitInstall()
        track(router.currentRoute.value)
        this.subscriptionService.subscribe(ROUTER_AFTER_EACH, track, true)
      }

      await trackingHandler()
    } catch (e: any) {
      this.loggerService.error('error', e.message)
    }
  }

  protected trackPage(route: Route): void {
    const user = this.userService.getCurrentUserOrNull(['timezone'])
    if (user) {
      const isIdentified: boolean = this.getPosthog().get_distinct_id() === user.id
      if (!isIdentified) {
        this.getPosthog().identify(user.id, {
          email: user.email,
          country: user.countryId,
          timezone: user.timezone ? user.timezone.timezone : 'UTC',
        })
      }
    }

    this.trackEvent(PostHogEvent.pageView)
    this.trackRouteEvent(route)
  }

  protected trackRouteEvent(route: Route): void {
    const routeName: RouteRecordName | null = route.name || null
    const hasRouteEvent = routeName && postHogRoutes.includes(routeName)

    if (hasRouteEvent) {
      const event: PostHogEvent | undefined = postHogRouteEvents.find(
        (postHogEvent: PostHogRouteEvent) => postHogEvent.routeName === routeName,
      )?.event
      if (!event) {
        return
      }
      this.trackEvent(event)
    }
  }

  protected getPostHogEventByOAuthProvider(provider: OAuthProvider): Nullable<PostHogEvent> {
    return postHogOAuthProviderEvents[provider] || null
  }

  protected getPostHogEventByRegulationAction(action: string): Nullable<PostHogEvent> {
    return postHogRegulationsEvents[action] || null
  }
}

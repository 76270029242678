import { inject, injectable } from 'inversify'
import type { WrapperOpenableInterface, WrapperParams, WrapperTypesServicesKeys } from '@/services/wrappers/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import { SERVICE_TYPES } from '@/core/container/types'
import type { ModelRaw } from '@/types'
import BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import type BaseWrapperService from '@/services/wrappers/baseWrapperService'
import type BaseWrapperRepository from '@/data/repositories/wrappers/baseWrapperRepository'
import { TmBaseError } from '@/core/error/tmBaseError'

@injectable()
export default class SectionService implements WrapperOpenableInterface {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.BaseWrapperService) protected readonly baseWrapperService: BaseWrapperService,
  ) {}

  public async open<T extends WrapperParams = WrapperParams>(wrapperId: string, params?: T) {
    this.trigger(wrapperId, true, params)
  }

  public async close(wrapperId: string) {
    this.trigger(wrapperId, false)
  }

  public build(wrapperId: string, wrapperType?: WrapperTypesServicesKeys) {
    this.baseWrapperService.build(wrapperId, wrapperType)
  }

  public getWrapperData(wrapperId: string): ModelRaw<BaseWrapper> {
    return this.baseWrapperService.getWrapperData(wrapperId)
  }

  public setParams(wrapperId: string, params?: WrapperParams): void {
    this.baseWrapperService.setParams(wrapperId, params)
  }

  public patchParams(wrapperId: string, params?: WrapperParams): WrapperParams {
    return this.baseWrapperService.patchParams(wrapperId, params)
  }

  public getParams(wrapperId: string) {
    return this.baseWrapperService.getParams(wrapperId)
  }

  public removeParams(wrapperId: string, paramsToDelete: string[]) {
    this.baseWrapperService.removeParams(wrapperId, paramsToDelete)
  }

  public clearParams(wrapperId: string) {
    this.baseWrapperService.clearParams(wrapperId)
  }

  public destroy(wrapperId: string) {
    this.getRepository().delete([wrapperId])
  }

  public isOpen(wrapperId: string) {
    const wrapper = this.getWrapperData(wrapperId)
    return wrapper && wrapper.isOpen
  }

  public isExist(wrapperId: string, model: typeof BaseWrapper = BaseWrapper): boolean {
    const wrapper = this.getRepository().find(wrapperId)
    return !!wrapper
  }

  protected getRepository() {
    return this.em.getRepository<BaseWrapperRepository>(BaseWrapper)
  }

  protected trigger(wrapperId: string, isOpen: boolean, params?: WrapperParams) {
    if (!this.isExist(wrapperId)) {
      throw new TmBaseError(`Section wrapper doesn't exist: ${wrapperId}`)
    }
    this.getRepository().update([
      {
        id: wrapperId,
        params: params ?? {},
        isOpen,
      },
    ])
  }
}

<template>
  <tm-notification
    type="success"
    @close="$emit('close')"
  >
    <div class="body-text-regular-14 lh-20">
      {{ $t('notifications.create.successful', { entity: entityLabel }) }}
    </div>

    <tm-button
      v-if="route"
      class="lh-20 primary--text"
      :to="route"
      text-link
      :target="openInNewTab ? '_blank' : undefined"
    >
      {{ $t('notifications.create.view', { entity: entityLabel }) }}
    </tm-button>
  </tm-notification>
</template>

<script setup lang="ts">
import TmButton from '@/components/shared/TmButton.vue'
import type { TmNamedRoute } from '@/services/route/types'
import { getTranslateService } from '@/core/container/ioc'
import type BaseModel from '@/data/models/BaseModel'
import { computed } from '@/composition/vue/compositionApi'
import TmNotification from '@/components/shared/notifications/TmNotification.vue'

const props = defineProps<{
  entity: typeof BaseModel
  route?: TmNamedRoute
  openInNewTab?: boolean
}>()

defineEmits<{
  close: []
}>()

const translateService = getTranslateService()
const entityLabel = computed(() => translateService.getEntityLabel(props.entity.entity))
</script>

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  TenDlcRequestService,
  TenDlcRequestRepository,
  TollFreeRequestService,
  TollFreeRequestRepository,
}

export type TenDlcTollFreeRequestServices = keyof typeof SERVICES

export const TEN_DLC_TOLL_FREE_REQUEST_SERVICE_TYPES: Record<TenDlcTollFreeRequestServices, symbol> =
  makeServicesTypes<TenDlcTollFreeRequestServices>(enumKeysToArray<TenDlcTollFreeRequestServices>(SERVICES))

import { inject, injectable } from 'inversify'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import { getTreePathForObjectField } from '@/utils/getTreePathForObjectField'
import type { GroupPropertyBuilder } from '@/services/forms/baseForm/typedFormBuilder/GroupPropertyBuilder'
import { formNameBuilder } from '@/services/forms/baseForm/typedFormBuilder/helpers'
import type {
  PropertyBuilderInterface,
  TypedFormBuilderInterface,
} from '@/services/forms/baseForm/typedFormBuilder/types'
import { SERVICE_TYPES } from '@/core/container/types'

@injectable()
export class TypedGroupFormBuilderService<TFormScheme> implements TypedFormBuilderInterface<TFormScheme> {
  private formBuilder!: FormBuilderInterface

  private groupName!: string

  constructor(
    @inject(SERVICE_TYPES.GroupPropertyBuilderFactory)
    protected readonly groupPropertyBuilderFactory: <T>() => GroupPropertyBuilder<T>,
  ) {}

  public setDependencys(formBuilder: FormBuilderInterface, groupName: string) {
    this.formBuilder = formBuilder
    this.groupName = groupName
  }

  public property<R>(callback: (t: TFormScheme) => R): PropertyBuilderInterface<R> {
    const path = getTreePathForObjectField<TFormScheme>(callback)
    const groupPropertyBuilder = this.groupPropertyBuilderFactory<R>()
    groupPropertyBuilder.setDependencys(this.formBuilder, this.groupName, formNameBuilder(path))
    return groupPropertyBuilder
  }
}

import type { TmWrappers } from '@/wrappers'

export const composePreviewModalId: TmWrappers = 'composePreviewModal'
export const composePhonePreviewModalId: TmWrappers = 'composePhonePreviewModal'

export type ComposeMessageRouteQueryParams = {
  openCreditModal?: 'true'
}

export type CampaignsComposeSmsRouteParams = {
  templateId?: string
  sessionId?: string
  listIds?: string[]
  filteredViewIds?: string[]
  rawPhones?: string[]
  contactIds?: string[]
}

export const scheduleRouteHash = '#schedule'

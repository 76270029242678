import { injectable } from 'inversify'
import { getUuid } from '@/utils/uuid'

@injectable()
export default class BrowserIdentifierService {
  private key = 'tm::uid'

  public getUid() {
    let uid = localStorage.getItem(this.key)
    if (uid) {
      return uid
    }
    uid = getUuid()
    localStorage.setItem(this.key, uid)
    return uid
  }
}

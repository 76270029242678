import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type { ValidationRules } from '@/services/validation/validationRulesBuilderService'
import type { AbstractFieldConfig } from '@/services/forms/types'

@injectable()
export default class FileUploadFieldBuilderService {
  constructor(
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
  ) {}

  public createFileUploadFieldConfig<T extends AbstractFieldConfig>(config: T): T {
    return {
      ...config,
      validators: this.getComposedValidationRules(config),
    }
  }

  protected getComposedValidationRules(config: AbstractFieldConfig) {
    const defaultValidators = this.getDefaultValidators()
    if (!config.validators) {
      return defaultValidators
    }

    return this.validationRulesBuilderService.compose(defaultValidators, config.validators as ValidationRules)
  }

  protected getDefaultValidators() {
    return this.validationRulesBuilderService.createRules().fileAllowed().fileMaxSize()
  }
}

import { AttrField, HasManyByField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import ContactList from '@/data/models/domain/ContactList'
import Contact from '@/data/models/domain/Contact'
import FilteredView from '@/data/models/domain/filteredViews/FilteredView'

@OrmModel(modelNames.SCHEDULED_MESSAGE_PARAMETERS)
export default class ScheduledMessageParameters extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public text: string

  @AttrField([])
  public numbers: string[]

  @AttrField([])
  public contactIds: string[]

  @HasManyByField(Contact, 'contactIds')
  public contacts: Contact[]

  @AttrField([])
  public listIds: string[]

  @HasManyByField(ContactList, 'listIds')
  public lists: ContactList[]

  @AttrField([], (value: number[]) => value?.map((item) => item.toString()))
  public filteredViewIds: string[]

  @HasManyByField(FilteredView, 'filteredViewIds')
  public filteredViews: FilteredView[]
}

import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import UserNumbers from '@/data/models/domain/UserNumbers'
import type UserNumberRepository from '@/data/repositories/domain/userNumbersRepository'
import type { GetUserNumbersOptions, RawByocNumbers } from '@/services/domain/byoc/types'
import type { ByocProvider } from '@/data/models/domain/services/byoc/types'
import type SenderSource from '@/data/models/domain/SenderSource'

@DomainSettings({
  model: UserNumbers,
})
@injectable()
export default class UserNumbersService extends DomainBaseService<UserNumberRepository> {
  public async getUserNumbers(params: GetUserNumbersOptions) {
    return this.getDomainRepository().getUserNumbers(params)
  }

  public byocParseNumbers(rawNumbers: RawByocNumbers): Record<ByocProvider, SenderSource[]> {
    return this.getDomainRepository().byocParseNumbers(rawNumbers)
  }

  public getByocSenderSourceByPhone(phone: string) {
    const repository = this.getDomainRepository()
    return repository.getByocSenderSourceByPhone(phone)
  }
}

<template>
  <component
    :is="componentType"
    v-bind="componentBind"
    class="tm-dropdown-item"
    :class="{
      'tm-dropdown-item--no-hover': noHover,
      [`tm-dropdown-item--focused-${focusedColor}`]: focused,
      [`tm-dropdown-item--color-${textColor}`]: textColor,
      'tm-dropdown-item--border-bottom': borderBottom,
      'tm-dropdown-item--border-top': borderTop,
      'tm-dropdown-item--cursor-default': cursorDefault,
      'tm-dropdown-item--disable': disable,
    }"
    @click="clickHandler"
  >
    <slot v-if="!loading">
      <tm-icon
        v-if="icon"
        :name="icon"
        :size="iconSize"
        :mirror="iconMirror"
        :rotate="iconRotate"
        class="mr-2"
        :class="iconColor || (!textColor && 'neutral--text')"
      />

      <tm-file
        v-else-if="file"
        :file="file"
        :size="iconSize"
        class="mr-2"
      />

      <span :class="labelClass">{{ label }}</span>

      <span
        v-if="additionalText"
        class="ml-1"
        :class="additionalTextClass"
      >
        {{ additionalText }}
      </span>

      <div
        v-if="badge"
        class="flex flex-grow-1 justify-end ml-2"
      >
        <tm-gradient-badge-small :text="badge" />
      </div>
    </slot>

    <template v-else>
      <tm-skeleton
        width="20px"
        height="20px"
        type="circle"
        class="mr-10px"
      />

      <tm-skeleton
        type="QChip"
        height="12px"
        random-width
      />
    </template>
  </component>
</template>

<script lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import type { PropType } from '@/composition/vue/compositionApi'
import { computed, defineComponent, toRefs } from '@/composition/vue/compositionApi'
import TmFile from '@/components/shared/TmFile.vue'
import type { IconRotate, IconSize, SizeProp, TmFileExtension } from '@/components/shared/types'
import TmIcon from '@/components/shared/TmIcon.vue'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import type { IconName } from '@/assets/icons/icons'
import TmGradientBadgeSmall from '@/components/shared/badge/TmGradientBadgeSmall.vue'

export default defineComponent({
  name: 'TmDropdownItem',
  components: {
    TmGradientBadgeSmall,
    TmSkeleton,
    TmIcon,
    TmFile,
  },
  props: {
    stopPropagation: {
      type: Boolean,
    },
    additionalText: {
      type: String,
    },
    additionalTextClass: {
      type: String,
      default: 'light--text',
    },
    cursorDefault: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
    },
    noHover: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    focused: {
      type: Boolean,
    },
    focusedColor: {
      type: String as PropType<'shade' | 'lift'>,
      default: 'shade',
    },
    iconMirror: {
      type: Boolean,
    },
    icon: {
      type: String as PropType<IconName>,
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
    },
    iconRotate: {
      type: Number as PropType<IconRotate>,
    },
    file: {
      type: String as PropType<TmFileExtension>,
    },
    textColor: {
      type: String as PropType<'red' | 'blue' | 'emphasize' | ''>,
      default: '',
    },
    borderBottom: {
      type: Boolean,
    },
    borderTop: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    labelClass: {
      type: String,
      default: '',
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
    },
    href: {
      type: String,
    },
    targetBlank: {
      type: Boolean,
    },
    iconColor: {
      type: String,
    },
    badge: {
      type: String,
    },
  },
  setup(props) {
    const { stopPropagation, href, to, targetBlank } = toRefs(props)

    const componentType = computed(() => {
      if (href.value) return 'a'
      if (to.value) return 'router-link'
      return 'button'
    })

    const componentBind = computed(() => {
      if (href.value && targetBlank.value) return { href: href.value, target: '_blank' }
      if (href.value) return { href: href.value }
      if (to.value && targetBlank.value) return { to: to.value, target: '_blank' }
      if (to.value) return { to: to.value }
      return { type: 'button' }
    })

    const clickHandler = (e: Event) => {
      if (stopPropagation.value) e.stopPropagation()
    }

    return {
      componentType,
      componentBind,
      clickHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
.mr-10px {
  margin-right: 10px;
}
.tm-dropdown-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 150px;
  min-height: 36px;
  padding: 0 16px;
  border: 0;
  background: unset;
  font-family: $body-font-family;
  font-size: $font-size-root;
  font-weight: $medium-font;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  color: $emphasize;
  cursor: pointer;
  appearance: none;
  border-radius: unset;

  &--cursor-default {
    cursor: default;
  }

  &--disable {
    opacity: 0.5;
    pointer-events: none;
  }

  &--focused-shade:not(.tm-dropdown-item--disable) {
    background-color: $gray1;
  }
  &:not(.tm-dropdown-item--no-hover):hover,
  &--focused-lift {
    background-color: $gray1;
  }

  &--border-bottom {
    border-bottom: 1px solid $border-color !important;
  }
  &--border-top {
    border-top: 1px solid $border-color !important;
  }

  &--color-blue {
    color: $blue !important;
  }
  &--color-red {
    color: $red !important;
  }
}
</style>

import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { SortDirection } from '@/core/tables/types'

@OrmModel(modelNames.GROUPING)
export default class Grouping extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @StringField('')
  public relation: string

  @StringField()
  public tableModelId: string

  @StringField('')
  public direction: SortDirection
}

import { TmApiClientError } from '@/core/error/transport/tmApiClientError'

export class TmApiTokenIsLockedError extends TmApiClientError {
  public name = 'TmApiTokenIsLockedError'

  public shouldBeMonitored(): boolean {
    return false
  }

  public shouldBeNotified(): boolean {
    return false
  }
}

import { inject, injectable } from 'inversify'
import type { SelectOption } from '@/services/forms/types'
import { weekDaysStartingSunday } from '@/services/types'
import type { WeekDays, WeekDaysFull, TranslationKey, Translatable } from '@/services/types'
import type DateTimeService from '@/services/dateTimeService'
import type { OrdinalDay } from '@/services/domain/messages/types'
import { ordinalDayVariant } from '@/services/domain/messages/types'
import { SERVICE_TYPES } from '@/core/container/types'

@injectable()
export abstract class AbstractFormGroupConfiguration {
  constructor(
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: Translatable,
    @inject(SERVICE_TYPES.DateTimeService) protected readonly dateTimeService: DateTimeService,
  ) {}

  protected getSelectMonthDayOptions(): SelectOption[] {
    const res: SelectOption[] = []
    for (let i = 1; i <= this.dateTimeService.MAX_DAYS_IN_MONTH; i++) {
      const value = i.toString()
      res.push({
        text: value,
        value,
      })
    }
    return res
  }

  protected getSelectOrdinalDaysOptions(): SelectOption<OrdinalDay>[] {
    return ordinalDayVariant.map((t) => ({
      text: this.translateService.t(`composeOrdinalDays.${t.toLowerCase()}` as TranslationKey),
      value: t,
    }))
  }

  protected getSelectWeekDaysOptions(): SelectOption<WeekDaysFull>[] {
    const weekDaysOnlyFull: Exclude<WeekDaysFull, WeekDays>[] = ['Day', 'Weekday', 'WeekendDay']
    const weekDaysTranslate = this.dateTimeService.getWeekDaysNames()
    const options: SelectOption<WeekDaysFull>[] = weekDaysStartingSunday.map((t, index) => ({
      text: weekDaysTranslate[index],
      value: t,
    }))
    weekDaysOnlyFull.forEach((t) =>
      options.push({
        text: this.translateService.t(`weekDaysFull.${t.toLowerCase()}` as TranslationKey),
        value: t,
      }),
    )
    return options
  }
}

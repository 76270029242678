<template>
  <tr
    :class="[
      'tm-table-header',
      {
        'tm-table-header--disabled': disabled,
      },
    ]"
  >
    <slot
      v-if="shouldShowCheckbox"
      name="table-header-checkbox"
    >
      <th class="tm-checkbox">
        <lazy>
          <tm-table-header-checkbox
            v-if="!singleSelection"
            :service-id="serviceId"
            :disabled="disabled"
          />
        </lazy>
      </th>
    </slot>

    <template
      v-for="column in visibleColumns"
      :key="`${column.columnName}:${column.columnOrder}:${visibleColumns.length}:${getSortDirection(column)}`"
    >
      <template v-if="isSortable(column)">
        <tm-table-header-sort-item
          :label="column.hideHeaderName ? '' : $t(column.labelShort ?? column.label)"
          :direction="getSortDirection(column)"
          :style="getColumnStyles(column)"
          @on-sort-changed="onSortChanged(column)"
        />
      </template>
      <template v-else>
        <th
          :class="column.columnClass"
          :style="getColumnStyles(column)"
        >
          <span v-if="!column.hideHeaderName">
            {{ column.isCustomFieldColumn ? column.label : $t(column.labelShort ?? column.label) }}
          </span>
        </th>
      </template>
    </template>
    <template v-if="hasActions">
      <lazy>
        <tm-sticky-cell
          tag="th"
          :style="{
            width: addPxToNumber(actionsWidth),
            minWidth: addPxToNumber(actionsWidth),
          }"
        />
      </lazy>
    </template>
  </tr>
</template>

<script lang="ts">
import { range } from 'lodash-es'
import { computed, defineComponent, type PropType } from '@/composition/vue/compositionApi'
import Lazy from '@/components/shared/Lazy.vue'
import TmTableHeaderSortItem from '@/components/shared/tables/header/TmTableHeaderSortItem.vue'
import type { DefaultColumn, SortDirection } from '@/core/tables/types'
import type { BaseTableInterface } from '@/core/tables/baseTableInterface'
import type Sorter from '@/data/models/tables/Sorter'
import { get } from '@/core/container/ioc'
import type { RegisteredServices } from '@/core/container/types'
import { addPercentageToNumber } from '@/utils/string/addPercentageToNumber'
import { addPxToNumber } from '@/utils/string/addPxToNumber'
import TmStickyCell from '@/components/shared/tables/cells/TmStickyCell.vue'
import TmTableHeaderCheckbox from '@/components/shared/tables/TmTableHeaderCheckbox.vue'

export default defineComponent({
  components: {
    Lazy,
    TmTableHeaderCheckbox,
    TmStickyCell,
    TmTableHeaderSortItem,
  },
  props: {
    serviceId: {
      type: String as PropType<RegisteredServices>,
      required: true,
    },
    visibleColumns: {
      type: Array as PropType<DefaultColumn[]>,
      required: true,
    },
    hasActions: {
      type: Boolean,
      required: true,
    },
    actionsWidth: {
      type: Number,
      default: 52,
    },
    withScroll: {
      type: Boolean,
      default: false,
    },
    hideCheckbox: {
      type: Boolean,
      default: false,
    },
    singleSelection: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const baseTable: BaseTableInterface = get(props.serviceId)

    const shouldShowCheckbox = computed(() => !props.hideCheckbox && props.visibleColumns.length > 0)

    const sorters = computed(() =>
      Object.fromEntries(
        baseTable
          .getSorters()
          .getAllSorters()
          .map((sorter) => [sorter.name, sorter]),
      ),
    )
    const totalColumnGrowRatio = computed(() =>
      props.visibleColumns.reduce((acc, curr) => {
        acc += curr.growRatio!
        return acc
      }, 0),
    )
    const defaultCellWidth = computed(() => 100 / totalColumnGrowRatio.value)
    const getSorterByColumnName = (column: DefaultColumn): Sorter => sorters.value[column.columnName]

    const isSortable = (column: DefaultColumn): boolean => !!getSorterByColumnName(column)

    const getSortDirection = (column: DefaultColumn): SortDirection => {
      const sorter = getSorterByColumnName(column)
      return sorter?.direction || ''
    }

    const getColumnStyles = (column: DefaultColumn) => ({
      ...(column.columnStyles ?? {}),
      width:
        column.growRatio === 0
          ? addPxToNumber(column.minWidth!)
          : addPercentageToNumber(defaultCellWidth.value * column.growRatio!),
      minWidth: addPxToNumber(column.minWidth!),
      maxWidth: column.maxWidth && addPxToNumber(column.maxWidth),
    })

    const onSortChanged = (column: DefaultColumn) => {
      if (props.disabled) {
        return
      }

      baseTable.applySort(column.columnName as string)
    }

    const fakeColumns = (columnsCount: number) => range(columnsCount - 1)

    return {
      shouldShowCheckbox,
      addPxToNumber,
      getColumnStyles,
      fakeColumns,
      isSortable,
      getSortDirection,
      onSortChanged,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.tm-checkbox {
  @include table-checkbox();
}

.tm-table-header {
  &--disabled {
    --tm-table-header-text-color: #{$gray5};
    --tm-table-sort-icon-opacity: 0.5;
    pointer-events: none;
  }
}
</style>

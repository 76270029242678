import { injectable } from 'inversify'
import type BaseModel from '@/data/models/BaseModel'
import TmLogicError from '@/core/error/tmLogicError'
import type { RouteEntityMapItem } from '@/services/route/types'

@injectable()
export class RouteEntitiesService {
  protected entityMap: RouteEntityMapItem[]

  constructor() {
    this.entityMap = []
  }

  public addRouteToEntityMap(route: RouteEntityMapItem) {
    this.entityMap.push(route)
  }

  public getDetailedPageByModel(model: typeof BaseModel) {
    const conf = this.entityMap.find((entity) => entity.model.entity === model.entity)
    if (!conf) {
      throw new TmLogicError(`${model.entity} is not mapped in RouteEntitiesService`)
    }
    return conf
  }
}

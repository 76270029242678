import type { RouteRecordRaw } from 'vue-router'
import { ChatSettingsRoutes } from '@/routes/types'
import { AppModule } from '@/config/types'

export const instagramSettingsRoute: RouteRecordRaw = {
  name: ChatSettingsRoutes.instagramSettings,
  path: '',
  component: () => import('@/components/views/messenger/instagram/InstagramSettings.vue'),
}

export const instagramFlowRoute: RouteRecordRaw = {
  name: ChatSettingsRoutes.instagramFlow,
  path: 'flow',
  component: () => import('@/components/views/messenger/instagram/InstagramFlow.vue'),
  props: (route) => ({
    code: route.query.code,
  }),
}

export const instagramDetailsRoute: RouteRecordRaw = {
  name: ChatSettingsRoutes.instagramDetails,
  path: ':id',
  props: true,
  component: () => import('@/components/views/messenger/instagram/InstagramDetails.vue'),
}

export const instagramRoute: RouteRecordRaw = {
  name: ChatSettingsRoutes.instagramIndex,
  path: 'instagram',
  children: [instagramSettingsRoute, instagramFlowRoute, instagramDetailsRoute],
  redirect: { name: ChatSettingsRoutes.instagramSettings },
  meta: {
    modules: [AppModule.Instagram],
    tabTitle: 'instagram.settings.title',
    accessControlGroups: ['InstagramStrategy'],
    resolvers: [
      {
        service: 'ChatInstagramResolver',
      },
    ],
  },
}

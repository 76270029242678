import { inject, injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import type UserAnalyticsFieldsRepository from '@/data/repositories/domain/users/userAnalyticsFieldsRepository'
import UserAnalyticsFields from '@/data/models/domain/UserAnalyticsFields'
import { TmApiRetryAfterError } from '@/core/error/transport/tmApiRetryAfterError'
import { userAnalyticsFieldsRetryAttemptsLimit, userAnalyticsFieldsRetryTimeout } from '@/services/domain/user/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type { MonitoringServiceInterface } from '@/services/monitoring/types'

@DomainSettings({
  model: UserAnalyticsFields,
})
@injectable()
export default class UserAnalyticsFieldsService extends DomainBaseService<UserAnalyticsFieldsRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.MonitoringService) protected readonly monitoringService: MonitoringServiceInterface,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public async updateFields(analyticsFields: UserAnalyticsFields, attemptsCounter?: number): Promise<void> {
    const initialFields: UserAnalyticsFields = this.getAnalyticsFields()
    try {
      await this.getDomainRepository().updateFields(analyticsFields)
    } catch (e) {
      if (e instanceof TmApiRetryAfterError) {
        this.handleRetryError(analyticsFields, attemptsCounter)
        return
      }
      this.monitoringService.logError(e as Error)
    }

    const updatedFields: UserAnalyticsFields = { ...initialFields, ...analyticsFields } as UserAnalyticsFields
    await this.getDomainRepository().storeAnalyticsFields(updatedFields)
  }

  public getAnalyticsFields(): UserAnalyticsFields {
    return this.getDomainRepository().findAnalyticsFields()
  }

  private handleRetryError(analyticsFields: UserAnalyticsFields, attemptsCounter?: number): void {
    if (!attemptsCounter) {
      attemptsCounter = 0
    }
    attemptsCounter += 1
    if (attemptsCounter <= userAnalyticsFieldsRetryAttemptsLimit) {
      setTimeout(() => this.updateFields(analyticsFields, attemptsCounter), userAnalyticsFieldsRetryTimeout)
    }
  }
}

import type { Ref } from '@/composition/vue/compositionApi'

export const getSymbolFor = (key: string | symbol): symbol => (typeof key === 'symbol' ? key : Symbol.for(key))

export const timeWrapper =
  (wrapped: (...args: any[]) => any, timer: Ref) =>
  async (...args: any[]) => {
    const t = new Date().getTime()
    const result = await wrapped(...args)
    timer.value = new Date().getTime() - t
    return result
  }

import { inject, injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainSettingsService from '@/services/domain/domainSettingsService'
import type ByocRepository from '@/data/repositories/domain/services/byoc/byocRepository'
import Byoc from '@/data/models/domain/services/byoc/Byoc'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type ByocCarrierSenderIdService from '@/services/domain/byoc/byocCarrierSenderIdService'
import type ByocCarrierPhoneService from '@/services/domain/byoc/byocCarrierPhoneService'
import type PermissionsService from '@/services/permissionsService'

@DomainSettings({
  model: Byoc,
})
@injectable()
export default class ByocService extends DomainSettingsService<ByocRepository, Byoc> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService)
    protected readonly modelSubscriptionService: ModelSubscriptionService,
    @inject(SERVICE_TYPES.ByocCarrierSenderIdService)
    protected readonly byocCarrierSenderIdService: ByocCarrierSenderIdService,
    @inject(SERVICE_TYPES.ByocCarrierPhoneService)
    protected readonly byocCarrierPhoneService: ByocCarrierPhoneService,
    @inject(SERVICE_TYPES.PermissionsService) private readonly permissionsService: PermissionsService,
  ) {
    super(entityManager, modelSubscriptionService)
  }

  public fetchSettings() {
    return this.getDomainRepository().getSettings()
  }

  public isAvailable() {
    return this.isAvailableForCountry() && this.permissionsService.isActivePermission('byoc')
  }

  public isAvailableForCountry() {
    const settings = this.findSettings()
    return !!settings?.isAvailableForCountry
  }

  public isActive() {
    const settings = this.findSettings()
    if (!settings) {
      return false
    }
    const access = settings.access
    if (access.accessType === null || access.accessType === 'сountry' || access.accessType === 'plan') {
      return true
    }
    if (access.expiredAt === null) {
      return true
    }

    return access.isActive
  }

  public refreshIsActive() {
    return this.refreshSettings()
  }

  public hasErrors() {
    const settings = this.findSettings()
    if (!settings) return false
    return settings.hasFailedNumbers || settings.hasFailedProvider
  }

  public refreshHasErrors() {
    return this.refreshSettings()
  }

  public hasConnectedProviders() {
    const settings = this.findSettings()
    return !!settings?.hasConnectedProviders
  }

  public setHasConnectedProviders(hasConnectedProviders: boolean) {
    return this.patchRepoSettings({ hasConnectedProviders })
  }

  public refreshHasConnectedProviders() {
    return this.refreshSettings()
  }

  public hasSenderIds() {
    const settings = this.findSettings()
    return !!settings?.hasSenderIds
  }

  public async refreshHasSenderIds() {
    const hasSenderIds = await this.byocCarrierSenderIdService.fetchHasSenderIds()
    await this.setHasSenderIds(hasSenderIds)
    return hasSenderIds
  }

  public setHasSenderIds(hasSenderIds: boolean) {
    return this.patchRepoSettings({ hasSenderIds })
  }

  public hasPhones() {
    const settings = this.findSettings()
    return !!settings?.hasPhones
  }

  public async refreshHasPhones() {
    const hasPhones = await this.byocCarrierPhoneService.fetchHasPhones()
    await this.setHasPhones(hasPhones)
    return hasPhones
  }

  public setHasPhones(hasPhones: boolean) {
    return this.patchRepoSettings({ hasPhones })
  }

  public getAccessOrFail() {
    return this.findSettingsOrFail().access
  }

  public getByocOutboundMessageCost() {
    return 0.01
  }
}

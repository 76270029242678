import { injectable } from 'inversify'
import type { Model } from '@vuex-orm/core'
import type BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'
import type { SerializerInterface } from '@/services/types'
import { TmBaseError } from '@/core/error/tmBaseError'
import type { Dict } from '@/types'
import type { SerializerFn } from '@/services/serializers/types'
import { messagePriceSerializer } from '@/services/serializers/messagePriceSerializer'
import {
  userAnalyticsSerializer,
  userAnalyticsTraitsSerializer,
} from '@/services/serializers/userAnalyticsTraitsSerializer'

@injectable()
export default class SerializerService implements SerializerInterface {
  private serializersMap: Partial<Record<modelNames, SerializerFn>> = {
    [modelNames.TEST]: (input) => input,
    [modelNames.MESSAGES_PRICE]: messagePriceSerializer,
    [modelNames.USER_ANALYTICS_FIELD]: userAnalyticsSerializer,
    [modelNames.USER_ANALYTICS_TRAIT]: userAnalyticsTraitsSerializer,
  }

  public serialize<T extends Dict<any>>(model: typeof BaseModel, toSerialize: T): Dict<any> | never {
    const serializerFn = this.serializersMap[model.entity as modelNames]
    if (!serializerFn || typeof serializerFn !== 'function') {
      throw new TmBaseError(`There are no rules to serialize model ${typeof model}`)
    }

    return serializerFn(toSerialize)
  }

  public unserialize(raw: any, modelInstance: Model): Model {
    if (typeof raw === 'string') {
      raw = JSON.parse(raw)
    }

    throw new TmBaseError('There no model to unserialize')
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  BillingService,
  BillingRepository,
  BillingDetailsService,
  BillingDetailsRepository,
}

export type BillingBaseServices = keyof typeof SERVICES

export const BILLING_BASE_SERVICE_TYPES: Record<BillingBaseServices, symbol> = makeServicesTypes<BillingBaseServices>(
  enumKeysToArray<BillingBaseServices>(SERVICES),
)

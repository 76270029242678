import type { TmCountryCode } from '@/services/types'
import type { TmWrappers } from '@/wrappers'
import type { NumberFeatures } from '@/data/models/domain/AvailableToBuyNumbers'

type IntBool = 0 | 1

interface BaseTollFreeRequestServiceApplyBody<T extends string> {
  type: T
}

interface TollFreeRequestServiceApplyPurchaseBody
  extends BaseTollFreeRequestServiceApplyBody<'toll_free_number_purchase'> {
  phone: string
  country: TmCountryCode
  userId: string
}

interface TollFreeRequestServiceApplyInfoBody extends BaseTollFreeRequestServiceApplyBody<'toll_free_info'> {
  requestId: string
  infoConsent: boolean
}

interface TollFreeRequestServiceApplyBrandUpsertionBody
  extends BaseTollFreeRequestServiceApplyBody<'toll_free_brand_upsertion'> {
  requestId: string
  entityType: string
  companyNameLegal: string
  companyNameDisplay: string
  state: string
  city: string
  street: string
  postalCode: string
  country: string
  verticalTypeOfCompany: string
  website: string
  contactFirstName: string
  contactLastName: string
  contactPhone: string
  contactEmail: string
}

interface TollFreeRequestBrandStepBody<P extends string> extends BaseTollFreeRequestServiceApplyBody<P> {
  requestId: string
}

interface TollFreeRequestBrandDetailsStepBody extends TollFreeRequestBrandStepBody<'toll_free_brand_org_details'> {
  entityType: string
  companyNameLegal: string
  companyNameDisplay: string
  verticalTypeOfCompany: string
  website: string
}

interface TollFreeRequestBrandAddressStepBody extends TollFreeRequestBrandStepBody<'toll_free_brand_org_address'> {
  state: string
  city: string
  street: string
  postalCode: string
  country: string
}

interface TollFreeRequestBrandContactStepBody extends TollFreeRequestBrandStepBody<'toll_free_brand_contact'> {
  contactFirstName: string
  contactLastName: string
  contactPhone: string
  contactEmail: string
}

interface TollFreeRequestBrandSubmissionStepBody extends TollFreeRequestBrandStepBody<'toll_free_brand_submission'> {}

interface TollFreeRequestServiceApplyCampaignDetailsBody
  extends BaseTollFreeRequestServiceApplyBody<'toll_free_campaign_details'> {
  contentInfo: string
  estimatedMonthlyMessageVolume: string
  isCanadianTraffic: IntBool
  requestId: string
  subscribersInfo: string
  useCase: string
}

interface TollFreeRequestServiceApplyCampaignOptInFlowBody
  extends BaseTollFreeRequestServiceApplyBody<'toll_free_campaign_opt_in_flow'> {
  optInDescription: string
  optInScreenshotLink: string
  optInSupportingDocumentation: string
  requestId: string
}

interface TollFreeRequestServiceApplyCampaignSampleMessagesBody
  extends BaseTollFreeRequestServiceApplyBody<'toll_free_campaign_sample_messages'> {
  requestId: string
  sampleMessages: string[]
}

interface TollFreeRequestServiceApplyCampaignContentAttributesBody
  extends BaseTollFreeRequestServiceApplyBody<'toll_free_campaign_content_attributes'> {
  requestId: string
  includesLinkInMessageContent: IntBool
  includesPhoneNumberInMessageContent: IntBool
  subscriberHelp: { keyword: string; autoReplyMessage: string }
  subscriberOptIn: { keyword: string; sampleMessage: string }
  subscriberOptOut: { keyword: string; sampleMessage: string }
}

interface TollFreeRequestServiceApplyCampaignSubmissionBody
  extends BaseTollFreeRequestServiceApplyBody<'toll_free_campaign_submission'> {
  requestId: string
}

interface TollFreeRequestServiceApplyEditRequestBody
  extends BaseTollFreeRequestServiceApplyBody<'toll_free_edit_request'> {
  requestId: string
}

interface TollFreeRequestServiceApplySubmissionForReviewBody
  extends BaseTollFreeRequestServiceApplyBody<'toll_free_submission_for_review'> {
  requestId: string
}

interface TollFreeRequestServiceApplyBackStepActionBody
  extends BaseTollFreeRequestServiceApplyBody<'toll_free_back_step_action'> {
  requestId: string
}

interface TollFreeRequestServiceApplyRegisterBody
  extends BaseTollFreeRequestServiceApplyBody<'toll_free_number_selection'> {
  phone: string
}

export type TollFreeRequestServiceApplyBody =
  | TollFreeRequestServiceApplyPurchaseBody
  | TollFreeRequestServiceApplyInfoBody
  | TollFreeRequestServiceApplyBrandUpsertionBody
  | TollFreeRequestServiceApplyCampaignDetailsBody
  | TollFreeRequestServiceApplyCampaignOptInFlowBody
  | TollFreeRequestServiceApplyCampaignSampleMessagesBody
  | TollFreeRequestServiceApplyCampaignContentAttributesBody
  | TollFreeRequestServiceApplyCampaignSubmissionBody
  | TollFreeRequestServiceApplyEditRequestBody
  | TollFreeRequestServiceApplySubmissionForReviewBody
  | TollFreeRequestServiceApplyBackStepActionBody
  | TollFreeRequestServiceApplyRegisterBody
  | TollFreeRequestBrandDetailsStepBody
  | TollFreeRequestBrandAddressStepBody
  | TollFreeRequestBrandContactStepBody
  | TollFreeRequestBrandSubmissionStepBody

export type TollFreeRequestServiceApplyResponseStep =
  | 'introduced'
  | 'tollfree_number_selection'
  | 'tollfree_number_activation'
  | 'tollfree_info'
  | 'tollfree_brand_creation'
  | 'tollfree_brand_edit'
  | 'tollfree_campaign_creation'
  | 'tollfree_campaign_edit'
  | 'tollfree_submission_for_review'
  | 'tollfree_submitted_for_review'
  | 'tollfree_outgoing_registration'
  | 'tollfree_verified'

export interface TollFreeRequestServiceApplyResponse {
  requestId: string
  nextStep: TollFreeRequestServiceApplyResponseStep
}

export type TollFreeCampaignDetailsFormSchemeCanadianTraffic = 'yes' | 'no'

export interface TollFreeOptInFlowFormScheme {
  optInDescription: string
  optInScreenshotLink: string | null
  optInScreenshot: File | null
  optInSupportingDocumentation: string
}
export interface TollFreeCampaignDetailsFormScheme {
  useCase: string
  subscribersInfo: string
  contentInfo: string
  estimatedMonthlyMessageVolume: string
  canadianTraffic: TollFreeCampaignDetailsFormSchemeCanadianTraffic | ''
}

export interface TollFreeSummary {
  selectedNumber: string | null
  brandName: string
  contactEmail: string
  contactPhone: string
  useCase: string
  campaignDescription: string
  optInFlowDescription: string
  sampleMessages: string
  features: NumberFeatures[]
}

export enum TollFreeRequestStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  IN_REVIEW = 'IN_REVIEW',
  VERIFIED = 'VERIFIED',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
}

export interface TollFreeFormModalParams {
  requestId: string
}

export const tollFreeFormModalId: TmWrappers = 'tollFreeFormModal'

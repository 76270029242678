<template>
  <resolver
    :key="compKey"
    :resolvers="resolvers"
    :route-name="routeName"
    @finish-resolve="$emit('finish-resolve')"
  >
    <template v-slot="defaultProps">
      <router-view
        v-slot="{ Component }"
        v-bind="propsAndAttrs"
      >
        <component
          :is="Component"
          v-bind="removeEmptyProps(defaultProps)"
        >
          <template
            v-for="name in $slots"
            v-slot:[String(name)]="data"
          >
            <slot
              :name="name"
              v-bind="data"
            />
          </template>
        </component>
      </router-view>
    </template>
    <template v-slot:failed="{ onRetry }">
      <slot name="failed">
        <tm-error-state
          size="medium"
          @on-retry="onRetry"
        />
      </slot>
    </template>
    <template v-slot:preloader>
      <slot name="preloader"></slot>
    </template>
  </resolver>
</template>

<script lang="ts">
import { isEmpty as isEmptyObject } from 'lodash-es'
import Resolver from '@/components/resolvers/Resolver.vue'
import { computed, defineComponent, toRef } from '@/composition/vue/compositionApi'
import TmErrorState from '@/components/shared/TmErrorState.vue'
import { resolversBaseProps, useResolver } from '@/composition/resolvers'
import TmAuthLoader from '@/components/shared/TmAuthLoader.vue'
import { isEmpty, isObject } from '@/services/validation/defaultValidators/utils'

export default defineComponent({
  components: {
    Resolver,
    TmAuthLoader,
    TmErrorState,
  },
  inheritAttrs: false,
  props: {
    ...resolversBaseProps,
  },
  emits: {
    'finish-resolve': () => true,
  },
  setup(props, context) {
    const propsAndAttrs = computed(() => {
      const { routeName, resolvers, ...otherProps } = props

      return { ...context.attrs, ...otherProps }
    })

    const { compKey } = useResolver(toRef(props, 'routeName'))

    const removeEmptyProps = (value: object) => {
      const result = {}

      Object.keys(value).forEach((key) => {
        if (isEmpty(value[key]) || (isObject(value[key]) && isEmptyObject(value[key]))) {
          return
        }
        result[key] = value[key]
      })

      return result
    }

    return {
      compKey,
      propsAndAttrs,
      removeEmptyProps,
    }
  },
})
</script>

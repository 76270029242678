import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type AuthService from '@/services/auth/authService'
import type { Resolvable } from '@/services/resolvers/types'
import type TranslateService from '@/services/translateService'
import type NotificationService from '@/services/notificationService'
import type ServerSubscriptionService from '@/services/transport/serverSubscriptionService'
import type UserService from '@/services/domain/user/userService'
import type RouterService from '@/services/route/routerService'
import type { TmRoutes } from '@/services/route/routerTypes'
import type WindowService from '@/services/browser/windowService'
import type ModalService from '@/services/wrappers/modalService'
import type UserSettingsService from '@/services/domain/user/userSettingsService'
import type { IBroadcastSubscriptionService } from '@/services/types'
import { BroadcastEvent } from '@/services/transport/types'
import type { TServerEvent } from '@/services/transport/serverEvents'
import type BrowserIdentifierService from '@/services/browser/browserIdentifierService'

@injectable()
export default class SystemExitResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.AuthService) protected readonly authService: AuthService,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    @inject(SERVICE_TYPES.ServerSubscriptionService)
    protected readonly serverSubscriptionService: ServerSubscriptionService,
    @inject(SERVICE_TYPES.NotificationService) protected readonly notificationService: NotificationService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.ModalService) protected readonly modalService: ModalService,
    @inject(SERVICE_TYPES.UserSettingsService) protected readonly userSettingsService: UserSettingsService,
    @inject(SERVICE_TYPES.SubscriptionService) private readonly subscriptionService: IBroadcastSubscriptionService,
    @inject(SERVICE_TYPES.BrowserIdentifierService) private readonly browserIdentifierService: BrowserIdentifierService,
  ) {}

  public async resolve() {
    this.serverSubscriptionService.subscribe('systemExit', this.systemExitHandler)
    this.subscriptionService.subscribeBroadcast(BroadcastEvent.UserLoggedOut, this.logoutHandler)
  }

  public async unresolve() {
    this.serverSubscriptionService.unsubscribeAll('systemExit')
    this.subscriptionService.unsubscribeBroadcast(BroadcastEvent.UserLoggedOut, this.logoutHandler)
  }

  private systemExitHandler = async (_e?: TServerEvent<'systemExit'>) => {
    if (_e?.payload?.deviceIdentifier?.toString() === this.browserIdentifierService.getUid()) {
      // if the exit is initiated from this browser, do nothing
      return
    }
    this.modalService.closeAllModals()
    const isActiveImpersonate = this.userSettingsService.currentUserSettingsOrNull()?.isImpersonated
    if (isActiveImpersonate) {
      await this.userService.stopImpersonateMode()
      const routeResolved = this.routerService.resolve({
        name: 'user.accounts.subAccounts.active',
      } satisfies TmRoutes)
      this.windowService.replace(routeResolved.fullPath)
      return
    }

    await this.authService.logoutRedirect()
    this.notificationService.notifyWarning(this.translateService.t('systemExitResolver.successNotification'))
  }

  private logoutHandler = () => {
    this.systemExitHandler()
  }
}

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum DEALS_SERVICES {
  CreateOrUpdatePipelineFormService,
  DealPipelineRepository,
  DealPipelineService,
  DealPipelineArchivedRepository,
  DealPipelineArchivedService,
  DealPipelineResolverService,
  DealPipelineSettingsResolverService,
  DealPipelinesPreloaderService,
  DealsRedirectToFirstPipelineResolverService,
  DealStageRepository,
  DealStageService,
  DealsKanbanService,
  DealPipelinePreloaderService,
  DealMoveAllFormService,
  DealRepository,
  DealService,
  DealCustomStageFormService,
  DealSystemStageFormService,
  DealPipelineStagesPreloaderService,
  DealCardAddContactFormService,
  DealTransferOwnershipFormService,
  DealPipelineArchivatorService,
  DealPipelinesArchivedPreloaderService,
  DealPipelineArchivedSinglePreloaderService,
  CreateDealFormService,
  DealViewFormService,
  DealCustomStageDeleteFormService,
  PipelineDefaultOwnerFormService,
  DealTimelineItemRepository,
  DealTimelineItemService,
  DealTimelineItemsResolverService,
  DealTimelineItemsWebsocketService,
  DealTimelineItemGridableService,
  DealTimelineCommentFormService,
  DealsTimelineCommentsFilterService,
  DealDetailsMainInfoFormService,
  DealDetailsContactInfoFormService,
  DealActivityService,
  DealActivityRepository,
  DealActivityItemService,
  DealActivityItemRepository,
  DealActivityItemFormService,
  DealsColumnService,
  DealsGridableService,
  DealsFilterServiceFactory,
  DealActivityItemsResolverService,
  DealCreateContactFromPayloadService,
  DealAttachmentsService,
  DealAttachmentsFormService,
  DealUserProfileRepository,
  DealUserProfileService,
}

export type DealsServices = keyof typeof DEALS_SERVICES

export const DEALS_SERVICES_TYPES = makeServicesTypesFromEnum<DealsServices>(DEALS_SERVICES)

import { BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.VOICE_CALL_CONFIG)
export default class VoiceCallConfig extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public wsServers: string

  @BooleanField()
  public hackIpInContact: boolean

  @StringField()
  public rtcpMuxPolicy: string

  @NumberField()
  public registerExpires: number

  @StringField()
  public aorsDomain: string

  @StringField(null)
  public authorizationUser: string

  @StringField(null)
  public password: string

  @StringField(null)
  public endpoint: string

  @StringField(null)
  public uri: string

  @BooleanField(false)
  public log: boolean
}

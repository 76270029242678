const isInputPending =
  (navigator as unknown as { scheduling: { isInputPending: () => boolean } })?.scheduling?.isInputPending.bind(
    (navigator as unknown as { scheduling: unknown }).scheduling,
  ) ?? (() => false)

export function idleTask<T>(
  iterator: IterableIterator<T>,
  action: (value: T) => Promise<unknown> | unknown,
  catchFn?: (e: Error, item: T) => void,
  timeQuantum = 16,
): Promise<void> {
  function task(resolve: () => void, reject: (e: Error) => void) {
    requestIdleCallback(async () => {
      const targetTime = Date.now() + timeQuantum
      for (const item of iterator) {
        try {
          const result = action(item)
          if (result instanceof Promise) {
            await result
          }
          if (Date.now() > targetTime || isInputPending()) {
            task(resolve, reject)
            return
          }
        } catch (e) {
          if (typeof catchFn === 'function') {
            try {
              catchFn(e as Error, item)
            } catch (err) {
              reject(err as Error)
              return
            }
          } else {
            reject(e as Error)
            return
          }
        }
      }
      resolve()
    })
  }
  return new Promise<void>((resolve, reject) => {
    task(resolve, reject)
  })
}

import { AttrField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { SessionSource } from '@/types'

@OrmModel(modelNames.USER_ANALYTICS_TRAIT)
export default class UserAnalyticsTraits extends BaseModel {
  @AttrField()
  public state: string | null

  @AttrField()
  public postalCode: string | null

  @AttrField()
  public street: string | null

  @AttrField()
  public lifeTimeValue: number | null

  @AttrField()
  public mostUsedProduct: SessionSource | null

  @AttrField()
  public autoRechargeEnabled: boolean | null
}

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum CONTACT_COUNTER_SERVICES {
  ContactCounterRepository,
  ContactCounterService,
}

export type ContactCounterServices = keyof typeof CONTACT_COUNTER_SERVICES

export const CONTACT_COUNTER_SERVICES_TYPES =
  makeServicesTypesFromEnum<ContactCounterServices>(CONTACT_COUNTER_SERVICES)

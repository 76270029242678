import type { CountryCode } from 'libphonenumber-js'
import type Country from '@/data/models/domain/Country'
import type { PutBody, ModelRaw, ExcludeMethods, SessionSource } from '@/types'
import type { ModelRelationArray } from '@/services/domain/types'
import type User from '@/data/models/domain/User'
import type { PaginationUrlParametersSortType } from '@/services/tables/types'
import type { CountryPhoneData } from '@/constants/countryPhoneData'
import type { TmCountryCode } from '@/services/types'
import type Timezone from '@/data/models/domain/Timezone'
import type { ByocProvider } from '@/data/models/domain/services/byoc/types'
import type UserAnalyticsFields from '@/data/models/domain/UserAnalyticsFields'
import type SenderSource from '@/data/models/domain/SenderSource'
import type { RawByocNumbers } from '@/services/domain/byoc/types'
import type TeamSubscription from '@/data/models/domain/TeamSubscription'
import type { UserSettingsVerifyIdentity } from '@/data/models/domain/UserSettings'
import type { UserPresenceStatus } from '@/data/models/domain/UserPresence'

export const maxUserCustomFieldContentLength = 50
export const userAnalyticsFieldsRetryTimeout = 5000
export const userAnalyticsFieldsRetryAttemptsLimit = 3

export type UserCurrency = {
  id: string // 'EUR'
  unicodeSymbol: string // '€'
  htmlSymbol: string // '&euro;'
}

export type UserPostBody = {
  id: string
  username: string
  firstName: string | null
  lastName: string | null
  email: string
  phone: string | null
  displayTimeFormat: string
  timezone: number
}
export type UserPutBody = PutBody<UserPostBody>

export type SubAccountPutBody = PutBody<{
  username: string
  firstName: string
  lastName: string
  phone: string
  email: string
  subaccountType: UserRole
}>

export type UserTimeFormat = '24h' | '12h'

export type MyDataFacet = {
  sentCount: number
  receivedCount: number
  contactsCount: number
  subaccountCount: number
  e2sCount: number
}

export type SubaccountsFacet = {
  active: number
  closed: number
  import: number
}

export type UsersFacet = {
  active: number
  closed: number
  import: number
  invited: number
}

export type BaseCountry = Pick<Country, 'id' | 'name'>

export type AvailableSignUpCountries = {
  detectedCountry: BaseCountry
  countries: Array<BaseCountry>
}

export interface CurrentUserInterface {
  currentUser(withRelations?: ModelRelationArray<User>): ModelRaw<User>
  getCurrentTimezone(): Timezone | undefined
}

export interface CurrentUserCountryIdInterface {
  getCurrentUserCountryId(): CountryCode
}

export interface CountryPhoneDataServiceInterface {
  getCountryPhoneDataOptions(onlyRealCountries: boolean): CountryPhoneData[]
  getCountryPhone(countryCode: TmCountryCode): CountryPhoneData | undefined
  getFirstCountryPhone(): CountryPhoneData
}

export const oAuthProviderVariant = ['google', 'apple', 'microsoft'] as const
export type OAuthProvider = (typeof oAuthProviderVariant)[number]

export enum UserRole {
  USER = 'U',
  ADMIN = 'A',
  SUPER_ADMIN = 'P',
}

export enum UserStatus {
  NONE = '',
  ST_CONFIRM = 'M',
  ST_ACTIVE = 'A',
  ST_CLOSED = 'C',
  ST_FRAUD = 'F',
  ST_PFRAUD = 'P',
  ST_REFUND = 'R',
  ST_SUSPEND = 'S',
  ST_WARDEN = 'W',
  ST_WARDEN_ACTIVE = 'X',
  ST_TRIAL = 'T',
  ST_SUB_INVITED = 'I',
  ST_PENDING = 'G',
  ST_MANUAL_APPROVE = 'Q',
  ST_OAUTH_REQUEST = 'O',
  ST_WIZARD = 'Z',
  ST_PERSONAL_USE = 'U',
}

interface InviteSubAccountUser {
  email: string
  role: UserRole
}

export type InviteSubAccountBody = {
  invites: InviteSubAccountUser[]
}

export type UserDTO = Omit<
  ExcludeMethods<ModelRaw<User>>,
  'fullName' | 'isAllowEditSubAccount' | 'isAllowDetailsPage' | 'isCriticallyLowBalance' | 'timezone'
> & { timezone: ModelRaw<Timezone> }

export interface FetchSubAccountsWithPaginationRequestBody {
  other?: {
    statuses?: UserStatus[]
    includeParent?: boolean
    includeSelf?: boolean
    includeRemoved?: boolean
  }
  sort?: PaginationUrlParametersSortType
}

export type UserNumbersKeys =
  | 'dedicated'
  | 'senderIds'
  | 'userNumbers'
  | 'shared'
  | 'byocNumbers'
  | ByocProvider
  | 'whatsApp'
  | 'widgets'
  | 'facebook'
  | 'instagram'

export type SubAccountInvitationBody = {
  email: string
  newEmail: string | null
}

export type TeamWorkflowType = 'users' | 'subaccounts'

export interface IUserTeamWorkflow {
  role: null
  type: TeamWorkflowType
  /**
   * Can the user change the workflow type
   */
  isFinalChoice: boolean
}

export interface CurrentUserResponse {
  id: string

  firstName: string

  lastName: string

  username: string

  email: string

  phone: string | null

  company: string | null

  balance: number

  pendingAmount: number

  avatarId: string

  countryId: CountryCode

  timezone: ModelRaw<Timezone>

  displayTimeFormat: UserTimeFormat

  currency: UserCurrency

  status: UserStatus

  subaccountType: UserRole

  emailAccepted: boolean

  isShowShared: boolean

  phoneAccepted: boolean

  lastActive: string

  createdAt: string

  isRemoved: boolean

  showSenderIds: boolean

  showTenDlcTab: boolean

  showTollFreeTab: boolean

  showTenDlcAlert: boolean | null

  showTollFreeAlert: boolean | null

  showBackendPanel: boolean

  verifyIdentity: UserSettingsVerifyIdentity | null

  showCompleteNumberRegistrationNotice: boolean

  showTermsUpdate: boolean

  teamWorkflow: IUserTeamWorkflow

  parentId: string

  subscriptions: TeamSubscription[]

  billingDetailsExist: boolean

  isImpersonated: boolean

  impersonatedBy: string | null

  analyticsFields: UserAnalyticsFields

  state: string | null

  postalCode: string | null

  street: string | null

  lifeTimeValue: number | null

  mostUsedProduct: SessionSource | null

  autoRechargeEnabled: boolean | null

  presenceStatus: UserPresenceStatus | null
}

export type RawUserNumbers = RawByocNumbers & {
  dedicated?: SenderSource[]
  shared?: SenderSource[]
  userNumbers?: SenderSource[]
  senderIds?: SenderSource[]
}

export type UserPresenceBody = {
  presenceStatus: UserPresenceStatus
}

interface IUserRefusalSurvey<T, R> {
  reason: T
  description: R
}

export type UserRefusalSurveyReasonWithDescription = 'has_issues_with_platform' | 'found_better_alternative' | 'other'
export type UserRefusalSurveyParams =
  | IUserRefusalSurvey<'not_need_service' | 'pricing_too_high', null>
  | IUserRefusalSurvey<UserRefusalSurveyReasonWithDescription, string>

export type UserRefusalSurveyReason = UserRefusalSurveyParams['reason']

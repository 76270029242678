import type { ServicePreloadable } from '@/services/preloaders/types'
import type { Autocompletable, Gridable } from '@/services/types'
import type { FormServiceForModel } from '@/services/domain/types'
import type BaseModel from '@/data/models/BaseModel'

export const isServicePreloadable = (service: any): service is ServicePreloadable =>
  'preloadById' in service && 'reloadById' in service

export const isServiceAutocompletable = (service: any): service is Autocompletable =>
  'autocomplete' in service && 'getEntitiesByIds' in service

export const isServiceGriddable = (service: any): service is Gridable<any> => 'gridRequest' in service

export const isFormServiceForModel = <T extends BaseModel = BaseModel>(
  service: any,
): service is FormServiceForModel<T> => 'submitByModel' in service

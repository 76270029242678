import { inject, injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import SendReceiveSettings from '@/data/models/domain/SendReceiveSettings'
import type SendReceiveSettingsRepository from '@/data/repositories/domain/sendReceiveSettingsRepository'
import type { SendReceiveSettingsPutBody } from '@/services/domain/sendReceiveSettings/types'

@DomainSettings({
  model: SendReceiveSettings,
})
@injectable()
export default class SendReceiveSettingsService extends DomainBaseService<SendReceiveSettingsRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public getSettings() {
    return this.getDomainRepository().getSettings()
  }

  public async update(data: SendReceiveSettingsPutBody) {
    await this.getDomainRepository().updateSettings(data)
  }

  public async loadSettings() {
    await this.getDomainRepository().fetchSettings()
  }
}

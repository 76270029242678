import { inject, injectable } from 'inversify'
import type { Router } from 'vue-router'
// eslint-disable-next-line tp/forbid-import-composable-to-service,tp/using-vue-in-services-restriction
import type { Plugin } from '@/composition/vue/compositionApi'
import ConfigReader from '@/core/configReader'
import ExternalTrackerService from '@/services/tracking/externalTrackerService'
import { SERVICE_TYPES } from '@/core/container/types'
import type RouterService from '@/services/route/routerService'
import { type ExternalTrackerAsPlugin, type TrackableConfig, hotJarRoutes } from '@/services/tracking/types'
import { ROUTER_AFTER_EACH } from '@/services/route/types'
import type SubscriptionService from '@/services/transport/subscriptionService'
import type WindowService from '@/services/browser/windowService'
import type LoggerService from '@/services/loggerService'
import { gettingStartedRoutName } from '@/routes/user/getting-started'
import type UserService from '@/services/domain/user/userService'
import { countryForGettingStartedPage } from '@/services/types'

@injectable()
export class HotJarService extends ExternalTrackerService {
  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {
    super()
  }

  public getConfig(): TrackableConfig {
    const config = ConfigReader.config()

    return {
      id: config.tracking.hotJarKey,
      snippetVersion: Number(config.tracking.hotJarSnippetVersion),
    }
  }

  public getPlugin(): Plugin {
    return {
      install: () => this.initialize(),
    } satisfies Plugin
  }

  protected async initialize(): Promise<void> {
    const router: Router = this.routerService.getRouter()
    const key: string = this.getConfig().id
    const snippetVersion: number = Number(this.getConfig().snippetVersion)
    let isInitialized: boolean = false
    const track = () => {
      const shouldInitialize: boolean =
        (router.currentRoute.value?.name && hotJarRoutes.includes(router.currentRoute.value?.name) && !isInitialized) ||
        (this.isSupportedGettingStarted(String(router.currentRoute.value?.name)) && !isInitialized)
      if (shouldInitialize) {
        isInitialized = true
        this.setUpOptions(key, snippetVersion)
        this.initializeTrackerAsPlugin(trackerAsPlugin)
      }
    }
    const trackingHandler = async () => {
      await router.isReady()
      track()
      this.subscriptionService.subscribe(ROUTER_AFTER_EACH, track, true)
    }
    const trackerAsPlugin = {
      src: `https://static.hotjar.com/c/hotjar-${key}.js?sv=${snippetVersion}`,
      id: 'hotJar',
      trackingHandler,
    } as ExternalTrackerAsPlugin

    await trackingHandler()
  }

  /* eslint-disable */
  public showDisconnectCPaaSSurvey(): void {
    // @ts-ignore
    hj('event', 'disconnect-cpaas')
  }

  protected setUpOptions(id: string, snippetVersion: number): void {
    const window = this.windowService.self()

    try {
      ;(function () {
        window['hj'] =
          window['hj'] ||
          function () {
            ;(window['hj'].q = window['hj'].q || []).push(arguments)
          }
        window['_hjSettings'] = {
          hjid: id,
          hjsv: snippetVersion,
        }

        return window['hj']
      })()
    } catch (e) {
      this.loggerService.error('error', (e as Error).message)
    }
  }
  /* eslint-disable */

  protected isSupportedGettingStarted(routeName: string): boolean {
    const isGettingStarted = routeName === gettingStartedRoutName
    if (isGettingStarted) {
      const user = this.userService.firstOrNull()
      if (!user) {
        return false
      }
      return countryForGettingStartedPage.includes(user.countryId)
    }

    return false
  }
}

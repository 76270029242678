import type ChatMessage from '@/data/models/domain/chats/ChatMessage'
import type { SmsStatus } from '@/types'
import type PointAiWebsite from '@/data/models/domain/account/PointAiWebsite'
import type AttachmentFile from '@/data/models/domain/attachmentFile/AttachmentFile'

export const CHAT_SET_ACTIVE_EVENT = 'chat:set-active'

export const START_CHAT_QUERY_PARAM_NAME = 'startChat'

export const CHAT_ID_QUERY_PARAM_NAME = 'id'
export const CHAT_FILTER_QUERY_PARAM_NAME = 'filter'
export const CHAT_DT1_QUERY_PARAM_NAME = 'dt1'
export const CHAT_DT2_QUERY_PARAM_NAME = 'dt2'

export enum ChatStatus {
  Opened = 'a',
  Closed = 'c',
  Deleted = 'd',
  Waiting = 'w',
}

export enum ChatMute {
  Unmuted = 0,
  Muted = 1,
}

export enum ChatDirection {
  Incoming = 'i',
  Outcoming = 'o',
  CallInbound = 'ci',
  CallOutbound = 'co',
  CallMissedInbound = 'cim',
}

export enum MessengerMessageType {
  TEXT = 'text',
  DOCUMENT = 'document',
  VIDEO = 'video',
  AUDIO = 'audio',
  IMAGE = 'image',
  VOICE = 'voice',
  INITIAL = 'initial',
  LOG = 'log',
  CALL = 'call',
  NOTE = 'note',
}

export type VoiceCallType = ChatDirection.CallInbound | ChatDirection.CallOutbound

export enum ChatOperation {
  Pin = 'pin',
  Unpin = 'unpin',
  Mute = 'mute',
  Unmute = 'unmute',
  Close = 'close',
  Reopen = 'reopen',
  EditStatus = 'edit-status',
  Read = 'read',
  Unread = 'unread',
  Tags = 'tags',
}

export const LAST_ID_FOR_FIRST_PAGE = 0

export type TFetchMessagesParams = {
  page: number
  lastId?: number | string
  lastMessageTime?: string
  perPage: number
  from?: string | null
  to?: string | null
  searchQuery?: string | null
  voice?: boolean | null
  sort?: 'asc' | 'desc'
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'sort[messages][id]'?: 'asc' | 'desc'
}

export type TFetchMessagesResult = {
  pageCount: number
  lastId: string | number
  lastMessageTime: string
  items: ChatMessage[]
}

export type TFetchChatsParams = {
  page: number
  perPage: number
  statuses?: ChatStatus[]
  searchQuery?: string | null
  unread?: boolean | null
  voice?: boolean | null
  filters?: Record<string, unknown>
}

export type TFetchChatByPhone = {
  upsert?: boolean // Create a new chat if not found
  reopen?: boolean // Reopen chat if found or do not change status (Default value : 1)
  updateMessageTime?: boolean // Update chat message time if found
}

export type TFetchChatParams = {
  /**
   * Create a new chat if not found
   */
  upsert?: boolean
}

export type TChatRejectParams = {
  reason: string | undefined
  blockByIp: boolean
  blockContact: boolean
}

export enum MessageAttachmentType {
  SMS = 'sms',
  MMS = 'mms',
  WhatsAppMedia = 'whatsAppMedia',
  WhatsAppDocument = 'whatsAppDocument',
  FacebookMedia = 'facebookMedia',
  FacebookDocument = 'facebookDocument',
  InstagramMedia = 'instagramMedia',
  InstagramDocument = 'instagramDocument',
}

export type MessagesAttachmentMmsFile = {
  name: string
  resource: string
  size: number
  width: number
  height: number
  isImage: boolean
  isVideo: boolean
  fileExt: string
  resourceS3FullUrl: string
  resourceFullUrl: string
  fileSizeLabel: string
  attachType?: MessageAttachmentType
}

export type TExportMessageParams = {
  messageInIds?: number[]
  messageOutIds?: number[]
  callIds?: number[]
  voice?: boolean
}

export const CHATS_PER_PAGE = 25

export const MSG_PER_PAGE = 20

export type ChatAISummaryItem = {
  emoji: string
  text: string
}

export type ChatAISummary = {
  summary: string
  waysToReply: Record<string, ChatAISummaryItem>
}

export type ChatAISourceWebsite = {
  type: 'link'
  score: number
  resourceName: string
  resource: PointAiWebsite
}

export type ChatAISourceFile = {
  type: 'file'
  score: number
  resourceName: string
  resource: AttachmentFile
}

export type ChatAISuggestion = {
  reply: string
  sources: (ChatAISourceWebsite | ChatAISourceFile)[]
}

export type ChatAISuggestionParams = {
  waysToReply?: string
  customText?: string
}

export const ChatAIEmptyChatError = 'Chat is empty' as const

export enum MessageItemType {
  Text,
  Audio,
  Media,
  Forbidden,
}

export type TMessageItem = {
  outerHTML: string
  innerHTML: string
  plain: string
  type: MessageItemType
  el?: HTMLElement
}

export type ChatLastMessageType = 'sms' | 'call' | 'messenger'
export enum ChatLastMessageSubType {
  NO_RATE = 'no-rate',
  RATE_GOOD = 'good-rate',
  RATE_POOR = 'poor-rate',
  MISSED = 'missed',
}

export type ChatLastMessage = {
  data: {
    direction: ChatDirection.Incoming | ChatDirection.Outcoming
    messageTime: string
    text?: string
    duration?: number
    missed?: boolean // TODO remove after delete this flag in BE
    phone?: string
    subtype: ChatLastMessageSubType | null
  }
  type: ChatLastMessageType
}

export enum ChatType {
  SMS = 'sms',
  WhatsAppBusiness = 'whatsapp_business',
  LiveChat = 'live_chat',
  Facebook = 'facebook_messenger',
  Email = 'email',
  Instagram = 'instagram',
}

export type MessengerPostMessage = {
  text: string
  type: MessengerMessageType
  media?: string | null
  fileIds?: string[]
}

export type MessengerPostNoteMessage = MessengerPostMessage & {
  type: MessengerMessageType.NOTE
}

export type MessengerUpdateMessage = {
  text: string
  fileIds?: string[]
}

export type TFetchMessageThreadParams = {
  page: number
  perPage: number
  searchQuery?: string | null
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'sort[chatThread][createdAt]'?: 'asc' | 'desc'
}

export type ChatMessagePreChatFormPayload = {
  email: string
  name: string
  text: string
}
export type ChatMessageLogType =
  | 'chatCreated'
  | 'statusUpdated'
  | 'userJoined'
  | 'contactLeft'
  | 'chatRated'
  | 'commentLeft'
  | 'transcriptDownloaded'
  | 'assigneeUpdated'
  | 'requestRejected'
  | 'contactBlocked'
  | 'ipBlocked'
  | 'chatConversationEnded'
export type ChatMessagePayloadStatus = Extract<SmsStatus, 'd' | 'l'> // Delivered, read
export type ChatMessageRateType = 'good' | 'poor'
export type ChatMessageLogPayloadData = {
  type?: ChatMessageLogType
  status?: ChatMessagePayloadStatus
  assigneeId?: number
  assigneeFullName?: string
  contactId?: number
  contactFullName?: string
  content?: string
  reason?: string
  rate?: ChatMessageRateType
  ip?: string
  period?: number
  isAutomatically?: false
}
export type ChatMessageCreatedByType = 'contact' | 'user'
export type ChatMessageCreatedBy<T extends ChatMessageCreatedByType = ChatMessageCreatedByType> = {
  id: number
  type: T
  fullName?: string
}
export type ChatMessageLogPayload = {
  payload?: ChatMessageLogPayloadData
  type: MessengerMessageType
  createdBy?: ChatMessageCreatedBy
}
export type ChatMessageTextPayload = {
  direction: ChatDirection
  createdBy: ChatMessageCreatedBy
  replyToMessageId: number | null
}

export enum ChatMessageInstagramType {
  /* eslint-disable @typescript-eslint/naming-convention */
  reel_shared = 'reel_shared',
  post_shared = 'post_shared',
  story_shared = 'story_shared',
  story_reply = 'story_reply',
  /* eslint-enable @typescript-eslint/naming-convention */
}

export type ChatMessageInstagramPayload = {
  type: ChatMessageInstagramType
  storyText?: string
}

export type ChatMessagePayload =
  | ChatMessagePreChatFormPayload
  | ChatMessageTextPayload
  | ChatMessageLogPayload
  | ChatMessageInstagramPayload

export type ChatDetailsBrowser = {
  name: string
  version: string
}

export type ChatDetailsOS = {
  name: string
  version: string
}

export const PromoChatCookieName = 'promo_chat'

export enum PromoChatState {
  Solved = 'solved',
  Opened = 'opened',
}

export type ChannelFilterValue = {
  type: 'default' | 'phone' | 'wa' | 'fb' | 'senderId' | 'ig' | 'widget' | 'email'
  value?: string
}

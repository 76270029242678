import { TestIdAttrName } from '@/composition/testing/types'
import { inject, provide } from '@/composition/vue/compositionApi'

const uniqDataTestIdProvideKey = 'uniqDataTestIdProvideKey'

const joinFn =
  (separator: string) =>
  (...argsJoin: (string | undefined)[]) => {
    return argsJoin.filter((v) => !!v).join(separator)
  }
const buildGetFn = (argsSeparator: string, id: string, tailPrefix?: string) => {
  return (...tails: (string | undefined)[]) => {
    if (tailPrefix) {
      tails.unshift(tailPrefix)
    }
    return {
      [TestIdAttrName]: joinFn(argsSeparator)(id, ...tails),
    }
  }
}
export const useDataTestId =
  (argsSeparator = '-', hierarchySeparator = '_') =>
  (...args: (string | undefined)[]) => {
    const testIdValue = inject(uniqDataTestIdProvideKey, '')
    const newTestIdValue = joinFn(hierarchySeparator)(testIdValue, joinFn(argsSeparator)(...args))
    provide(uniqDataTestIdProvideKey, newTestIdValue)
    return {
      getDataTestId: buildGetFn(argsSeparator, newTestIdValue),
      getDataTestIdForButton: buildGetFn(argsSeparator, newTestIdValue, 'button'),
      getDataTestIdForTab: buildGetFn(argsSeparator, newTestIdValue, 'tab'),
    }
  }

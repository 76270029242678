import { inject, injectable } from 'inversify'
import { type TpFormData, ValidationModes } from '@/services/forms/types'
import BaseFormService from '@/services/forms/baseFormService'
import type FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type LoggerService from '@/services/loggerService'
import type MessagesAttachmentFileService from '@/services/domain/messages/messagesAttachmentFileService'
import type { HttpService } from '@/services/transport/httpService'
import { MessageAttachmentType } from '@/data/models/domain/chats/types'
import { getFileTypeByExt } from '@/utils/file/getFileTypeByExt'
import { getFileExt } from '@/utils/file/getFileExt'

const MMS_IMAGE_FILE_LIMIT = 3_670_016 // 3.5 MB
const MMS_FILE_LIMIT = 537_600 // 525 KB
const SMS_FILE_LIMIT = 10_485_760 // 10 MB
const WA_IMAGE_FILE_LIMIT = 5_242_880 // 5 MB
const WA_VIDEO_AUDIO_FILE_LIMIT = 16_777_216 // 16 MB
const WA_DOC_FILE_LIMIT = 104_857_600 // 100 MB
const FB_IMAGE_FILE_LIMIT = 8_388_608 // 8 MB
const FB_VIDEO_AUDIO_FILE_LIMIT = 26_214_400 // 25 MB
const FB_DOC_FILE_LIMIT = 26_214_400 // 25 MB

@injectable()
export default class MessagesAttachmentFileFormService extends BaseFormService<FormBuilderService> {
  private _attachType: MessageAttachmentType = MessageAttachmentType.WhatsAppMedia

  constructor(
    @inject(SERVICE_TYPES.FormBuilderFactoryService)
    protected readonly formBuilderFactoryService: () => FormBuilderService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.MessagesAttachmentFileService)
    protected readonly messagesAttachmentFileService: MessagesAttachmentFileService,
    @inject(SERVICE_TYPES.Api) protected readonly api: HttpService,
  ) {
    super(formBuilderFactoryService, em, validationRulesBuilderService, logger)
  }

  public onUploadProgressCallback?: (progressEvent: ProgressEvent) => void

  public setUploadProgressCallback(onUploadProgressCallback?: (progressEvent: ProgressEvent) => void) {
    this.onUploadProgressCallback = onUploadProgressCallback
  }

  public set attachType(value: MessageAttachmentType) {
    this._attachType = value
  }

  public get attachType(): MessageAttachmentType {
    return this._attachType
  }

  public async buildForm() {
    const builder = this.getBuilder()

    let validators = this.validationRulesBuilderService
      .createRules()
      .required({ messagePath: 'messagesAttachmentFileModal.fileErrorRequired' })
    const messengerDocFileLimit =
      this.attachType === MessageAttachmentType.WhatsAppDocument ? WA_DOC_FILE_LIMIT : FB_DOC_FILE_LIMIT
    switch (this.attachType) {
      case MessageAttachmentType.MMS:
        // TODO: should get file limit for MMS from server. Temporary set manually (images 3.5 MB and other files up to 525 KB)
        validators = validators
          .fileIsMmsAttachment()
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .fileMaxSize({ data: { image: MMS_IMAGE_FILE_LIMIT, '*': MMS_FILE_LIMIT } })
        break
      case MessageAttachmentType.WhatsAppMedia: {
        validators = validators.fileIsWhatsAppMediaAttachment().fileMaxSize({
          data: { image: WA_IMAGE_FILE_LIMIT, audio: WA_VIDEO_AUDIO_FILE_LIMIT, video: WA_VIDEO_AUDIO_FILE_LIMIT },
        })
        break
      }
      case MessageAttachmentType.FacebookMedia:
      case MessageAttachmentType.InstagramMedia: {
        validators = validators.fileIsInstagramMediaAttachment().fileMaxSize({
          data: { image: FB_IMAGE_FILE_LIMIT, audio: FB_VIDEO_AUDIO_FILE_LIMIT, video: FB_VIDEO_AUDIO_FILE_LIMIT },
        })
        break
      }
      case MessageAttachmentType.FacebookDocument:
      case MessageAttachmentType.InstagramDocument:
      case MessageAttachmentType.WhatsAppDocument: {
        validators = validators.fileIsWhatsAppFileAttachment().fileMaxSize({
          data: {
            image: messengerDocFileLimit,
            audio: messengerDocFileLimit,
            video: messengerDocFileLimit,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '*': messengerDocFileLimit,
          },
        })
        break
      }
      default:
        // TODO: should get file limit for SMS from server. Temporary set manually 10 MB.
        validators = validators
          .fileIsMessagesAttachment()
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .fileMaxSize({ data: { '*': SMS_FILE_LIMIT } })
        break
    }

    builder.control({
      fieldType: 'fileUploadArea',
      name: 'file',
      value: null,
      validationMode: ValidationModes.aggressive,
      validators,
    })
  }

  public cancel() {
    this.api.getCancelToken().cancel()
    this.setFieldValue('file', null)
  }

  public async submit(formData: TpFormData) {
    const fData = new FormData()
    fData.append('file', formData.file, formData.file.name ?? 'file')
    switch (this.attachType) {
      case MessageAttachmentType.MMS:
        return this.messagesAttachmentFileService.attachMmsFile(fData, this.onUploadProgressCallback)
      case MessageAttachmentType.WhatsAppMedia: {
        const type = this.getMediaType(formData.file.name)
        fData.append('type', type)
        return this.messagesAttachmentFileService.attachMessengerFile(fData, this.onUploadProgressCallback)
      }
      case MessageAttachmentType.WhatsAppDocument: {
        fData.append('type', 'document')
        return this.messagesAttachmentFileService.attachMessengerFile(fData, this.onUploadProgressCallback)
      }
      case MessageAttachmentType.FacebookMedia: {
        const type = this.getMediaType(formData.file.name)
        fData.append('type', type)
        return this.messagesAttachmentFileService.attachFacebookFile(fData, this.onUploadProgressCallback)
      }
      case MessageAttachmentType.FacebookDocument: {
        fData.append('type', 'document')
        return this.messagesAttachmentFileService.attachFacebookFile(fData, this.onUploadProgressCallback)
      }
      case MessageAttachmentType.InstagramMedia: {
        const type = this.getMediaType(formData.file.name)
        fData.append('type', type)
        return this.messagesAttachmentFileService.attachInstagramFile(fData, this.onUploadProgressCallback)
      }
      case MessageAttachmentType.InstagramDocument: {
        fData.append('type', 'document')
        return this.messagesAttachmentFileService.attachInstagramFile(fData, this.onUploadProgressCallback)
      }
      default:
        return this.messagesAttachmentFileService.attachSmsFile(fData, this.onUploadProgressCallback)
    }
  }

  private getMediaType(fileName: string) {
    let type = ''
    const fileType = getFileTypeByExt(getFileExt(fileName))
    if (fileType === 'video' || fileType === 'audio' || fileType === 'image') {
      type = fileType
    }
    return type
  }
}

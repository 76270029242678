import type { RouteRecordRaw } from 'vue-router'
import ReceivedSms from '@/data/models/domain/ReceivedSms'
import type { SingleModelResolverParams } from '@/services/resolvers/types'
import InboundCall from '@/data/models/domain/InboundCall'
import OutboundCall from '@/data/models/domain/OutboundCall'
import ForwardedCall from '@/data/models/domain/ForwardedCall'
import BulkSentSms from '@/data/models/domain/BulkSentSms'
import BulkSentSmsCounter from '@/data/models/domain/counters/BulkSentSmsCounter'
import SentSmsShort from '@/data/models/domain/SentSmsShort'
import type { ResolverConfig } from '@/services/types'
import { AppModule } from '@/config/types'
import SentSmsDetailsPageResolver from '@/components/resolvers/SentSmsDetailsPageResolver.vue'
import type { OutboundEmailsTitlerServiceParams } from '@/services/domain/outboundEmails/outboundEmailsTitlerService'

export const userHistoryRouteName = 'user.history'

export const userHistorySentSmsRouteName = 'user.history.sentSms'

export const userHistoryReceivedSmsRouteName = 'user.history.receivedSms'

export const userHistoryOutboundEmailsRouteName = 'user.history.outboundEmails'

export const userHistoryOutboundEmailsDetailsRouteName = 'user.history.outboundEmailsDetails'

export const userHistoryOutboundEmailsBulkDetailsRouteName = 'user.history.outboundEmailsBulkDetails'

export const userHistoryOutboundEmailsBulkDetailsOverviewRouteName = 'user.history.outboundEmails.overview'
export const userHistoryOutboundEmailsBulkDetailsRecipientsRouteName = 'user.history.outboundEmails.recipients'
export const userHistoryOutboundEmailsBulkDetailsOpensRouteName = 'user.history.outboundEmails.opens'
export const userHistoryOutboundEmailsBulkDetailsClicksRouteName = 'user.history.outboundEmails.clicks'
export const userHistoryOutboundEmailsBulkDetailsClicksSummaryRouteName = 'user.history.outboundEmails.clicks.summary'
export const userHistoryOutboundEmailsBulkDetailsClicksAllRouteName = 'user.history.outboundEmails.clicks.all'
export const userHistoryOutboundEmailsBulkDetailsUnsubscribersRouteName = 'user.history.outboundEmails.unsubscribers'
export const userHistoryOutboundEmailsBulkDetailsSpamRouteName = 'user.history.outboundEmails.spam'

export const userHistorySentSmsDetailsRouteName = 'user.history.sentSmsDetails'

export const userHistorySentSmsBulkDetailsSummaryRouteName = 'user.history.sentSmsBulkDetailsSummary'

export const userHistorySentSmsBulkDetailsDeliveredRouteName = 'user.history.sentSmsBulkDetailsDelivered'

export const userHistorySentSmsBulkDetailsSentRouteName = 'user.history.sentSmsBulkDetailsSent'

export const userHistorySentSmsBulkDetailsFailedRouteName = 'user.history.sentSmsBulkDetailsFailed'

export const userHistorySentSmsBulkDetailsRejectedRouteName = 'user.history.sentSmsBulkDetailsRejected'

export const userHistorySentSmsBulkDetailsRouteName = 'user.history.sentSmsBulkDetails'

export const userHistoryReceivedSmsDetailsRouteName = 'user.history.receivedSmsDetails'

export const userHistoryForwardedCallsRouteName = 'user.history.forwardedCalls'

export const userHistoryOutboundCallsRouteName = 'user.history.outboundCalls'

export const userHistoryInboundCallsRouteName = 'user.history.inboundCalls'

export const userHistoryForwardedCallsDetailsRouteName = 'user.history.forwardedCalls.details'

export const userHistoryInboundCallsDetailsRouteName = 'user.history.inboundCalls.details'

export const userHistoryOutboundCallsDetailsRouteName = 'user.history.outboundCalls.details'

const sentSmsCounterResolver: ResolverConfig = {
  service: 'BulkSentSmsCounterResolver',
  params: {
    requestParam: 'sessionId',
    model: BulkSentSmsCounter,
    source: 'bulkSentSmsCounter',
    redirectToIfNotFound: { name: userHistorySentSmsRouteName },
  },
}

export const getHistoryRoutes = (): RouteRecordRaw[] => [
  {
    name: userHistoryRouteName,
    path: 'history',
    redirect: { name: 'user.history.index' },
    meta: {
      tags: ['USER_TOP_MENU'],
      permission: 'history',
      menu: () => import('@/components/domain/history/HistoryMenu.vue'),
      modules: [
        AppModule.SenderSettingsCountries,
        AppModule.ContactsUnsubscribed,
        AppModule.ContactsBlocked,
        AppModule.ContactList,
        AppModule.Compose,
      ],
    },
    children: [
      {
        name: 'user.history.index',
        path: '',
        redirect: { name: userHistorySentSmsRouteName },
        meta: {
          resolvers: [
            {
              service: 'FacetResolver',
              params: {
                model: SentSmsShort,
                subscriptionModels: [SentSmsShort, ReceivedSms, ForwardedCall, OutboundCall, InboundCall],
              },
            },
          ],
        },
        component: () => import('@/components/views/history/History.vue'),
        children: [
          {
            name: 'user.history.sentSms.index',
            redirect: { name: userHistorySentSmsRouteName },
            path: 'sent-sms',
            meta: {
              tags: ['HISTORY_BAR_LIST'],
              modules: [AppModule.SentSmsBase, AppModule.HistorySentSms],
            },
            children: [
              {
                name: userHistorySentSmsRouteName,
                path: '',
                meta: {
                  title: 'pageTitle.user.history.sentSms', // PageContent title @todo: remove
                  tabTitle: 'pageTabTitle.user.history.sentSms',
                },
                component: () => import('@/components/views/reporting/history/sms/sent/SentSms.vue'),
              },
              {
                name: userHistorySentSmsDetailsRouteName,
                path: ':sentSmsId',
                props: true,
                meta: {
                  tabTitle: 'pageTabTitle.user.history.sentSmsDetails',
                  resolvers: [
                    {
                      service: 'OutboundSmsResolverService',
                    },
                  ],
                  resolverComponent: SentSmsDetailsPageResolver,
                  titler: {
                    service: 'RouteTitlerService',
                  },
                  fullHeight: true,
                },
                component: () => import('@/components/views/reporting/history/sms/sent/SentSmsDetails.vue'),
              },
              {
                name: userHistorySentSmsBulkDetailsRouteName,
                path: 'session/:sessionId',
                props: true,
                meta: {
                  tabTitle: 'pageTabTitle.user.history.sentSmsBulkDetailsSummary',
                  titler: {
                    service: 'RouteTitlerService',
                  },
                  fullHeight: true,
                  modules: [AppModule.HistoryReceivedSms],
                },
                component: () => import('@/components/views/reporting/history/sms/sent/SentSmsBulkDetails.vue'),
                redirect: { name: userHistorySentSmsBulkDetailsSummaryRouteName },
                children: [
                  {
                    name: userHistorySentSmsBulkDetailsSummaryRouteName,
                    path: 'summary',
                    meta: {
                      modules: [AppModule.Recipient],
                      resolvers: [
                        {
                          service: 'BulkSentSmsDetailsResolver',
                          params: {
                            requestParam: 'sessionId',
                            model: BulkSentSms,
                            source: 'bulkSentSms',
                            redirectToIfNotFound: { name: userHistorySentSmsRouteName },
                          },
                        },
                        sentSmsCounterResolver,
                      ],
                    },
                    component: () =>
                      import('@/components/views/reporting/history/sms/sent/SentSmsBulkDetailsSummary.vue'),
                  },
                  {
                    name: userHistorySentSmsBulkDetailsDeliveredRouteName,
                    path: 'delivered',
                    props: (route) => {
                      return {
                        sessionId: route.params.sessionId,
                      }
                    },
                    meta: {
                      resolvers: [sentSmsCounterResolver],
                    },
                    component: () =>
                      import('@/components/views/reporting/history/sms/sent/SentSmsBulkDetailsDelivered.vue'),
                  },
                  {
                    name: userHistorySentSmsBulkDetailsSentRouteName,
                    path: 'sent',
                    props: (route) => {
                      return {
                        sessionId: route.params.sessionId,
                      }
                    },
                    meta: {
                      resolvers: [sentSmsCounterResolver],
                    },
                    component: () => import('@/components/views/reporting/history/sms/sent/SentSmsBulkDetailsSent.vue'),
                  },
                  {
                    name: userHistorySentSmsBulkDetailsFailedRouteName,
                    path: 'failed',
                    props: (route) => {
                      return {
                        sessionId: route.params.sessionId,
                      }
                    },
                    meta: {
                      resolvers: [sentSmsCounterResolver],
                    },
                    component: () =>
                      import('@/components/views/reporting/history/sms/sent/SentSmsBulkDetailsFailed.vue'),
                  },
                  {
                    name: userHistorySentSmsBulkDetailsRejectedRouteName,
                    path: 'rejected',
                    props: (route) => {
                      return {
                        sessionId: route.params.sessionId,
                      }
                    },
                    meta: {
                      resolvers: [sentSmsCounterResolver],
                    },
                    component: () =>
                      import('@/components/views/reporting/history/sms/sent/SentSmsBulkDetailsRejected.vue'),
                  },
                ],
              },
            ],
          },
          {
            name: 'user.history.receivedSms.index',
            redirect: { name: userHistoryReceivedSmsRouteName },
            path: 'received-sms',
            meta: {
              tags: ['HISTORY_BAR_LIST'],
              modules: [AppModule.HistoryReceivedSms, AppModule.SentSmsBase],
            },
            children: [
              {
                name: userHistoryReceivedSmsRouteName,
                path: '',
                meta: {
                  title: 'pageTitle.user.history.receivedSms', // PageContent title @todo: remove
                  tabTitle: 'pageTabTitle.user.history.receivedSms',
                  modules: [AppModule.HistoryReceivedSms],
                },
                component: () => import('@/components/views/reporting/history/sms/received/ReceivedSms.vue'),
              },
              {
                name: userHistoryReceivedSmsDetailsRouteName,
                path: ':receivedSmsId',
                props: true,
                meta: {
                  tabTitle: 'pageTabTitle.user.history.receivedSmsDetails',
                  modules: [AppModule.HistoryReceivedSms],
                  detailsPage: {
                    source: 'router',
                    requestParam: 'receivedSmsId',
                    model: ReceivedSms,
                  } as SingleModelResolverParams,
                  titler: {
                    service: 'RouteTitlerService',
                  },
                  redirectToIfNotFound: { name: userHistoryReceivedSmsRouteName },
                  fullHeight: true,
                },
                component: () => import('@/components/views/reporting/history/sms/received/ReceivedSmsDetails.vue'),
              },
            ],
          },
          {
            name: 'user.history.outboundEmails.index',
            redirect: { name: userHistoryOutboundEmailsRouteName },
            path: 'outbound-emails',
            meta: {
              tags: ['HISTORY_BAR_LIST'],
              modules: [
                AppModule.SentSmsBase,
                AppModule.HistorySentSms,
                AppModule.TmTiptapEditor,
                AppModule.OutboundEmails,
              ],
              accessControlGroups: ['HistoryOutboundEmailsStrategy'],
            },
            children: [
              {
                name: userHistoryOutboundEmailsRouteName,
                path: '',
                meta: {
                  title: 'pageTitle.user.history.outboundEmails',
                  tabTitle: 'pageTitle.user.history.outboundEmails',
                },
                component: () => import('@/components/views/reporting/history/OutboundEmails.vue'),
              },
              {
                name: userHistoryOutboundEmailsDetailsRouteName,
                path: ':campaignId/:emailId',
                props: (route) => {
                  return {
                    campaignId: route.params.campaignId,
                    emailId: route.params.emailId,
                  }
                },
                meta: {
                  resolverComponent: () => import('@/components/domain/history/OutboundEmailsDetailsResolver.vue'),
                  resolvers: [
                    {
                      service: 'OutboundEmailsResolverService',
                    },
                  ],
                  tabTitle: 'pageTabTitle.user.history.outboundEmail',
                  titler: {
                    service: 'OutboundEmailsTitlerService',
                    params: {
                      idKey: 'emailId',
                    } satisfies OutboundEmailsTitlerServiceParams<typeof userHistoryOutboundEmailsDetailsRouteName>,
                  },
                  fullHeight: true,
                },
                component: () => import('@/components/views/reporting/history/OutboundEmailsDetails.vue'),
              },
              {
                name: userHistoryOutboundEmailsBulkDetailsRouteName,
                path: 'session/:id',
                props: (route) => {
                  return {
                    id: route.params.id,
                  }
                },
                component: () => import('@/components/views/reporting/history/OutboundEmailsBulkDetails.vue'),
                redirect: { name: userHistoryOutboundEmailsBulkDetailsOverviewRouteName },
                meta: {
                  tabTitle: 'pageTabTitle.user.history.outboundEmail',
                  titler: {
                    service: 'OutboundEmailsTitlerService',
                    params: {
                      idKey: 'id',
                    } satisfies OutboundEmailsTitlerServiceParams<typeof userHistoryOutboundEmailsBulkDetailsRouteName>,
                  },
                  fullHeight: true,
                  resolverComponent: () => import('@/components/domain/history/OutboundEmailsBulkDetailsResolver.vue'),
                  resolvers: [
                    {
                      service: 'OutboundEmailsBulkResolverService',
                    },
                  ],
                },
                children: [
                  {
                    name: userHistoryOutboundEmailsBulkDetailsOverviewRouteName,
                    path: 'overview',
                    props: (route) => {
                      return {
                        id: route.params.id,
                      }
                    },
                    meta: {
                      modules: [AppModule.Recipient],
                    },
                    component: () =>
                      import('@/components/views/reporting/history/OutboundEmailsBulkDetailsOverview.vue'),
                  },
                  {
                    name: userHistoryOutboundEmailsBulkDetailsRecipientsRouteName,
                    path: 'recipients',
                    props: (route) => {
                      return {
                        id: route.params.id,
                      }
                    },
                    component: () =>
                      import('@/components/views/reporting/history/OutboundEmailsBulkDetailsRecipients.vue'),
                  },
                  {
                    name: userHistoryOutboundEmailsBulkDetailsOpensRouteName,
                    path: 'opens',
                    props: (route) => {
                      return {
                        id: route.params.id,
                      }
                    },
                    component: () => import('@/components/views/reporting/history/OutboundEmailsBulkDetailsOpens.vue'),
                  },
                  {
                    name: userHistoryOutboundEmailsBulkDetailsClicksRouteName,
                    path: 'clicks',
                    props: (route) => {
                      return {
                        id: route.params.id,
                      }
                    },
                    component: () => import('@/components/views/reporting/history/OutboundEmailsBulkDetailsClicks.vue'),
                    redirect: { name: userHistoryOutboundEmailsBulkDetailsClicksSummaryRouteName },
                    children: [
                      {
                        name: userHistoryOutboundEmailsBulkDetailsClicksSummaryRouteName,
                        path: 'summary',
                        props: (route) => {
                          return {
                            id: route.params.id,
                          }
                        },
                        component: () =>
                          import('@/components/views/reporting/history/OutboundEmailsBulkDetailsClicksSummary.vue'),
                      },
                      {
                        name: userHistoryOutboundEmailsBulkDetailsClicksAllRouteName,
                        path: 'all',
                        props: (route) => {
                          return {
                            id: route.params.id,
                          }
                        },
                        component: () =>
                          import('@/components/views/reporting/history/OutboundEmailsBulkDetailsClicksAll.vue'),
                      },
                    ],
                  },
                  {
                    name: userHistoryOutboundEmailsBulkDetailsUnsubscribersRouteName,
                    path: 'unsubscribers',
                    props: (route) => {
                      return {
                        id: route.params.id,
                      }
                    },
                    component: () =>
                      import('@/components/views/reporting/history/OutboundEmailsBulkDetailsUnsubscribers.vue'),
                  },
                  {
                    name: userHistoryOutboundEmailsBulkDetailsSpamRouteName,
                    path: 'spam',
                    props: (route) => {
                      return {
                        id: route.params.id,
                      }
                    },
                    component: () =>
                      import('@/components/views/reporting/history/OutboundEmailsBulkDetailsSpamReports.vue'),
                  },
                ],
              },
            ],
          },
          {
            name: 'user.history.forwardedCalls.index',
            redirect: { name: userHistoryForwardedCallsRouteName },
            path: 'forwarded-calls',
            meta: {
              tags: ['HISTORY_BAR_LIST'],
              modules: [AppModule.ForwardedCalls],
            },
            children: [
              {
                name: userHistoryForwardedCallsRouteName,
                path: '',
                meta: {
                  title: 'pageTitle.user.history.forwardedCalls', // PageContent title @todo: remove
                  tabTitle: 'pageTabTitle.user.history.forwardedCalls',
                  modules: [AppModule.HistoryForwardedCalls, AppModule.SentSmsBase],
                },
                component: () => import('@/components/views/reporting/history/calls/forwarded/ForwardedCalls.vue'),
              },
              {
                name: userHistoryForwardedCallsDetailsRouteName,
                path: ':forwardedCallId',
                props: true,
                meta: {
                  tabTitle: 'pageTabTitle.user.history.forwardedCallDetails',
                  detailsPage: {
                    source: 'router',
                    requestParam: 'forwardedCallId',
                    model: ForwardedCall,
                  } as SingleModelResolverParams,
                  titler: {
                    service: 'RouteTitlerService',
                  },
                  redirectToIfNotFound: { name: userHistoryForwardedCallsRouteName },
                  fullHeight: true,
                },
                component: () =>
                  import('@/components/views/reporting/history/calls/forwarded/ForwardedCallsDetails.vue'),
              },
            ],
          },
          {
            name: 'user.history.outboundCalls.index',
            redirect: { name: userHistoryOutboundCallsRouteName },
            path: 'outbound-calls',
            meta: {
              tags: ['HISTORY_BAR_LIST'],
            },
            children: [
              {
                name: userHistoryOutboundCallsRouteName,
                path: '',
                meta: {
                  title: 'pageTitle.user.history.outboundCalls', // PageContent title @todo: remove
                  tabTitle: 'pageTabTitle.user.history.outboundCalls',
                  modules: [AppModule.HistoryOutboundCalls, AppModule.SentSmsBase],
                },
                component: () => import('@/components/views/reporting/history/calls/outbound/OutboundCalls.vue'),
              },
              {
                name: userHistoryOutboundCallsDetailsRouteName,
                path: ':outboundCallId',
                props: true,
                meta: {
                  tabTitle: 'pageTabTitle.user.history.outboundCallDetails',
                  detailsPage: {
                    source: 'router',
                    requestParam: 'outboundCallId',
                    model: OutboundCall,
                  } as SingleModelResolverParams,
                  titler: {
                    service: 'RouteTitlerService',
                  },
                  redirectToIfNotFound: { name: userHistoryOutboundCallsRouteName },
                  fullHeight: true,
                  modules: [AppModule.HistoryOutboundCalls],
                },
                component: () => import('@/components/views/reporting/history/calls/outbound/OutboundCallsDetails.vue'),
              },
            ],
          },
          {
            name: 'user.history.inboundCalls.index',
            redirect: { name: userHistoryInboundCallsRouteName },
            path: 'inbound-calls',
            meta: {
              tags: ['HISTORY_BAR_LIST'],
              modules: [AppModule.HistoryInboundCalls, AppModule.SentSmsBase],
            },
            children: [
              {
                name: userHistoryInboundCallsRouteName,
                path: '',
                meta: {
                  title: 'pageTitle.user.history.inboundCalls', // PageContent title @todo: remove
                  tabTitle: 'pageTabTitle.user.history.inboundCalls',
                },
                component: () => import('@/components/views/reporting/history/calls/inbound/InboundCalls.vue'),
              },
              {
                name: userHistoryInboundCallsDetailsRouteName,
                path: ':inboundCallId',
                props: true,
                meta: {
                  tabTitle: 'pageTabTitle.user.history.inboundCallDetails',
                  detailsPage: {
                    source: 'router',
                    requestParam: 'inboundCallId',
                    model: InboundCall,
                  } as SingleModelResolverParams,
                  titler: {
                    service: 'RouteTitlerService',
                  },
                  redirectToIfNotFound: { name: userHistoryInboundCallsRouteName },
                  fullHeight: true,
                },
                component: () => import('@/components/views/reporting/history/calls/inbound/InboundCallsDetails.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
]

<template>
  <slot
    v-if="resolvingState === 'FAILED'"
    name="failed"
    v-bind="{ onRetry: retry }"
  />

  <template v-if="resolverResolvingState === 'RESOLVED'">
    <suspense
      v-show="isComponentResolved"
      :key="componentKey"
      @resolve="onComponentResolved"
    >
      <slot
        name="default"
        :resolver-props="resolveResult"
      />
    </suspense>
  </template>

  <slot
    v-if="shouldShowPreloader"
    name="preloader"
  />
</template>

<script setup lang="ts">
import { computed, onErrorCaptured, onMounted, onUnmounted, ref } from '@/composition/vue/compositionApi'
import { type RESOLVER_BASE_PROPS, useResolvers } from '@/composition/resolvers'
import type { ResolverState } from '@/components/resolvers/types'
import { getLoggerService } from '@/core/container/ioc'

const props = defineProps<RESOLVER_BASE_PROPS>()

let isNeedToErrorCapture = true
const componentKey = ref<number>(0)

const {
  resolve,
  unresolve,
  retry: _retry,
  resolvingState: resolverResolvingState,
  resolveResult,
} = useResolvers(props.routeName, props.resolvers)

const componentResolvingState = ref<ResolverState>('INITIAL')
const isComponentResolved = computed(() => componentResolvingState.value === 'RESOLVED')
const onComponentResolved = () => {
  componentResolvingState.value = 'RESOLVED'
}

onErrorCaptured((error) => {
  if (!isNeedToErrorCapture) {
    return true
  }
  getLoggerService().raw('debug', error)
  componentResolvingState.value = 'FAILED'
  return true
})

const resolvingState = computed(() => {
  const resolverState = resolverResolvingState.value
  const componentState = componentResolvingState.value

  if (resolverState === componentState && resolverState === 'RESOLVED') {
    isNeedToErrorCapture = false
  }

  if (resolverState === componentState) {
    return resolverState
  }

  if (resolverState === 'FAILED' || componentState === 'FAILED') {
    return 'FAILED'
  }

  return 'INITIAL'
})

const shouldShowPreloader = computed(
  () =>
    resolvingState.value === 'INITIAL' || (resolverResolvingState.value === 'RESOLVED' && !isComponentResolved.value),
)

const retry = () => {
  if (componentResolvingState.value === 'FAILED') {
    componentKey.value += 1
  }

  if (resolverResolvingState.value === 'FAILED') {
    _retry()
  }
}

onMounted(() => {
  resolve()
})

onUnmounted(() => {
  unresolve()
})
</script>

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

export enum CHAT_PREVIEW_NEXT_SERVICES {
  ChatPreviewNextRepository,
  ChatPreviewNextService,
}

export type ChatPreviewNextServices = keyof typeof CHAT_PREVIEW_NEXT_SERVICES

export const CHAT_PREVIEW_NEXT_SERVICE_TYPES: Record<ChatPreviewNextServices, symbol> =
  makeServicesTypes<ChatPreviewNextServices>(enumKeysToArray<ChatPreviewNextServices>(CHAT_PREVIEW_NEXT_SERVICES))

import { computed, nextTick, ref } from '@/composition/vue/compositionApi'
import { getRouterService } from '@/core/container/ioc'

const isShow = ref(true)

export const useRouteReloader = () => {
  const routerService = getRouterService()
  const isShowRoute = computed(() => isShow.value)

  const reload = async () => {
    const { currentRoute } = routerService.getRouter()
    const path = currentRoute.value.fullPath

    // need for call router hooks.
    // replace with argument force, dont call router hooks
    routerService.replace({
      path: '_',
    })
    await routerService.replace({
      path,
    })

    isShow.value = false
    await nextTick()
    isShow.value = true
  }
  return {
    isShowRoute,
    reload,
  }
}

import type { Maybe } from '@/types'

export const getFullName = (firstName: Maybe<string>, lastName: Maybe<string>): string => {
  if (firstName || lastName) {
    return [firstName, lastName]
      .filter(Boolean)
      .map((item) => item?.replace('null', '').trim())
      .join(' ')
      .trim()
  }

  return ''
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type UserRepository from '@/data/repositories/domain/users/userRepository'

@injectable()
export default class SsoAuthService {
  constructor(
    @inject(SERVICE_TYPES.UserRepository)
    protected readonly userRepository: UserRepository,
  ) {}

  public getTokenData(token: string) {
    return this.userRepository.getSSOInvitationDataByToken(token)
  }

  public completeSsoInvitation(token: string, firstName: string, lastName: string) {
    return this.userRepository.completeSsoInvitation(token, firstName, lastName)
  }
}

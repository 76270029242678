import { inject, injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import TenDlcCampaign from '@/data/models/domain/services/regulations/TenDlcCampaign'
import type TenDlcCampaignsRepository from '@/data/repositories/domain/services/regulations/tenDlcCampaignsRepository'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type TranslateService from '@/services/translateService'
import { getStatusColor, type MapStatusColor } from '@/utils/status'
import {
  TenDlcCampaignsStatus,
  type TenDlcCampaignsBaseUseCasesType,
  TenDlcTwilioCampaignsStatus,
} from '@/services/domain/services/regulations/tenDlcCampaigns/types'
import { ModelEventType } from '@/services/transport/types'
import type { Nullable, StatusData } from '@/types'

@DomainSettings({
  model: TenDlcCampaign,
})
@injectable()
export default class TenDlcCampaignsService extends DomainBaseService<TenDlcCampaignsRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public async getCampaignByRequestId(id: string): Promise<Nullable<TenDlcCampaign>> {
    return this.getDomainRepository().getCampaignByRequestId(id)
  }

  public findCampaign(id: Nullable<string>) {
    return id ? this.getDomainRepository().query().withAll().find(id) : null
  }

  /* @todo: will be updated with methods isCampaignActionRequired, getFirstDraftCampaign after task CAR-2057 */
  public isCampaignActionRequired() {
    return this.getDomainRepository()
      .all()
      .some((campaign) => campaign.status === TenDlcTwilioCampaignsStatus.actionRequired)
  }

  public getFirstDraftCampaign() {
    return this.getDomainRepository().query().where('status', TenDlcCampaignsStatus.DRAFT).first()
  }

  public getUseCases(useCases: TenDlcCampaignsBaseUseCasesType[]): string[] {
    const map: Record<TenDlcCampaignsBaseUseCasesType, string> = {
      /* eslint-disable-next-line camelcase,@typescript-eslint/naming-convention */
      '2FA': this.translateService.t('tenDlcCampaignsUseCases.twoFa'),
      CUSTOMER_CARE: this.translateService.t('tenDlcCampaignsUseCases.customerCare'),
      FRAUD_ALERT: this.translateService.t('tenDlcCampaignsUseCases.fraudAlert'),
      MARKETING: this.translateService.t('tenDlcCampaignsUseCases.marketing'),
      POLLING_VOTING: this.translateService.t('tenDlcCampaignsUseCases.pollingVoting'),
      ACCOUNT_NOTIFICATION: this.translateService.t('tenDlcCampaignsUseCases.accountNotification'),
      DELIVERY_NOTIFICATION: this.translateService.t('tenDlcCampaignsUseCases.deliveryNotification'),
      HIGHER_EDUCATION: this.translateService.t('tenDlcCampaignsUseCases.higherEducation'),
      PUBLIC_SERVICE_ANNOUNCEMENT: this.translateService.t('tenDlcCampaignsUseCases.publicServiceAnnouncement'),
      SECURITY_ALERT: this.translateService.t('tenDlcCampaignsUseCases.securityAlert'),
    }

    return useCases.map((el) => map[el])
  }

  public getStatus(status: TenDlcTwilioCampaignsStatus): StatusData {
    const labelMap: Record<TenDlcTwilioCampaignsStatus, string> = {
      [TenDlcTwilioCampaignsStatus.draft]: this.translateService.t('status.tenDlcCampaigns.draft'),
      [TenDlcTwilioCampaignsStatus.supportReview]: this.translateService.t('status.tenDlcCampaigns.pending'),
      [TenDlcTwilioCampaignsStatus.providerReview]: this.translateService.t('status.tenDlcCampaigns.inReview'),
      [TenDlcTwilioCampaignsStatus.actionRequired]: this.translateService.t('status.tenDlcCampaigns.actionRequired'),
      [TenDlcTwilioCampaignsStatus.verified]: this.translateService.t('status.tenDlcCampaigns.active'),
    }
    const colorMap: MapStatusColor<TenDlcTwilioCampaignsStatus> = {
      green: [TenDlcTwilioCampaignsStatus.verified],
      gray: [TenDlcTwilioCampaignsStatus.draft],
      blue: [TenDlcTwilioCampaignsStatus.supportReview, TenDlcTwilioCampaignsStatus.providerReview],
      orange: [TenDlcTwilioCampaignsStatus.actionRequired],
    }

    return {
      value: labelMap[status] || '',
      color: getStatusColor<TenDlcTwilioCampaignsStatus>(colorMap, status),
    }
  }

  public fill(id: string) {
    return this.getDomainRepository().fill(id)
  }

  public async cancelCampaign(id: string) {
    await this.getDomainRepository().cancelCampaign(id)
    this.notify([id], ModelEventType.DELETE)
  }

  public async deleteCampaign(id: string) {
    await this.getDomainRepository().deleteCampaign(id)
    this.notify([id], ModelEventType.DELETE)
  }

  public async restoreCampaign(id: string) {
    await this.getDomainRepository().restoreCampaign(id)
    this.notify([id], ModelEventType.UPDATE)
  }

  public async deleteCampaignErrorFields(id: string): Promise<void> {
    const campaign = this.findCampaign(id)
    if (!campaign) {
      return
    }
    campaign.errorFields = []
    await this.getDomainRepository().insertOrUpdate([campaign])
  }
}

import type { FiltersToCreate } from '@/services/tables/filters/types'
import type { RawSchemaFilter } from '@/services/resolvers/domain/filterSchema/schemaGenerator'

export const dateFilterValueVariant = [
  'All',
  'Today',
  'Yesterday',
  'This week',
  'Last week',
  'This month',
  'Previous month',
  'Last 30 days',
  'Last 60 days',
  'Pick date range',
] as const

export type DateFilterValueType = (typeof dateFilterValueVariant)[number]

export const defaultDateFilterValueVariants: DateFilterValueType[] = [
  'All',
  'Today',
  'Yesterday',
  'This week',
  'Last week',
  'This month',
  'Previous month',
  'Last 60 days',
  'Pick date range',
]

export const defaultDateFilterValue: DateFilterValueType = 'All'
export const customDateFilterValue: DateFilterValueType = 'Pick date range'

export const dateFilterFields = {
  from: 'from',
  to: 'to',
  options: 'dateRange',
}

export const dateFilterServiceFilters: FiltersToCreate = [
  {
    name: dateFilterFields.from,
    operation: 'eq',
  },
  {
    name: dateFilterFields.to,
    operation: 'eq',
  },
  {
    name: dateFilterFields.options,
    operation: 'eq',
  },
]

export const dateFilterSchemaFilters: RawSchemaFilter[] = [
  dateFilterFields.from,
  dateFilterFields.to,
  dateFilterFields.options,
]

import {
  AttrField,
  BelongsToField,
  BooleanField,
  HasManyByField,
  HasManyField,
  NumberField,
  OrmModel,
  StringField,
} from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import Country from '@/data/models/domain/Country'
import User from '@/data/models/domain/User'
import ContactList from '@/data/models/domain/ContactList'
import Note from '@/data/models/domain/Note'
import { TmStringField } from '@/data/utils/types'
import type { TmCountryCode } from '@/services/types'
import { CustomFieldValue } from '@/data/models/domain/CustomFieldValue'
import { CUSTOM_FIELD_FORM_FIELD_NAME } from '@/services/domain/cusomFields/types'
import Tag from '@/data/models/domain/Tag'
import type { UnsubscribeReason } from '@/data/models/domain/UnsubscribedContact'
import type { UnsubscribeEmailReasonType } from '@/data/models/domain/UnsubscribedEmailContact'

export type AvatarFile = {
  id?: string
  href: string
}

type ContactSubscriptionInfoItem<T> = null | {
  reason: T
}

export interface ContactEmailSubscription {
  email: ContactSubscriptionInfoItem<UnsubscribeEmailReasonType>
  phone: ContactSubscriptionInfoItem<UnsubscribeReason>
}

@OrmModel(modelNames.CONTACT)
export default class Contact extends BaseModel {
  @StringField()
  public id: string

  @TmStringField('')
  public firstName: string

  @TmStringField('')
  public lastName: string

  @AttrField(null)
  public countryId: TmCountryCode | null

  @BelongsToField(Country, 'countryId')
  public country: Country | null

  // @todo type should be string | null
  @TmStringField('')
  public phone: string

  @AttrField('')
  public whatsappPhone: string

  @TmStringField()
  public email: string | null

  @AttrField('')
  public companyName: string

  @AttrField()
  public avatar: AvatarFile

  @BooleanField()
  public blocked: boolean

  @BooleanField()
  public unsubscribed: boolean

  @TmStringField()
  public archivedAt: string

  @StringField()
  public userId: string

  @BelongsToField(User, 'userId')
  public user: User

  @AttrField([], (value: number[]) => value?.map((item) => item.toString()))
  public listIds: string[]

  @HasManyByField(ContactList, 'listIds')
  public lists: ContactList[]

  @HasManyField(CustomFieldValue, 'entityId')
  public [CUSTOM_FIELD_FORM_FIELD_NAME]: CustomFieldValue[]

  @AttrField([], (value: number[]) => value?.map((item) => item.toString()))
  public noteIds: string[]

  @HasManyByField(Note, 'noteIds')
  public notes: Note[]

  @NumberField()
  public scheduledCount: number

  @AttrField(null, (value) => (value ? `${value}` : null))
  public ownerId: string | null

  @BelongsToField(User, 'ownerId')
  public owner: User | null

  @AttrField([], (value: number[]) => value?.map((item) => item.toString()))
  public tagIds: string[]

  @HasManyByField(Tag, 'tagIds')
  public tags: Tag[]

  @StringField(null)
  public createdAt: string | null

  @AttrField(null, (value) => (value ? `${value}` : null))
  public creatorId: string | null

  @BelongsToField(User, 'creatorId')
  public createdBy: User | null

  @StringField()
  public updatedAt: string | null

  @AttrField(null, (value) => (value ? `${value}` : null))
  public updaterId: string | null

  @BelongsToField(User, 'updaterId')
  public updatedBy: User | null

  @StringField(null)
  public fbMessengerSenderId: string

  @StringField(null)
  public instagramSenderId: string

  @StringField(null)
  public source: ContactSource | null

  @AttrField()
  public emailSubscription: ContactEmailSubscription

  public get fullName() {
    return [this.firstName, this.lastName].join(' ').trim()
  }

  public get isDeleted() {
    return !!this.archivedAt
  }

  public get canSendMessage() {
    return !!this.phone
  }

  public get canCall() {
    return !!this.phone
  }

  public get canBeBlocked() {
    return !this.unsubscribed && !this.blocked
  }

  public get canBeSubscribedByPhone() {
    return !!this.phone
  }

  public get isUnsubscribedByPhone() {
    return this.emailSubscription.phone !== null
  }
}

export enum ContactSource {
  MANUAL = 'manual',
  IMPORT = 'import',
  API = 'api',
  FORM = 'form',
  LIVE_CHAT = 'liveChat',
  WHATSAPP = 'whatsapp',
  FACEBOOK = 'facebook',
  TICKET = 'ticket',
  INSTAGRAM = 'instagram',
  SMS = 'sms',
}

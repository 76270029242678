import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type UserService from '@/services/domain/user/userService'
import { wizardChangeStepErrorEvent } from '@/services/domain/wizard/wizardService'
import type WizardService from '@/services/domain/wizard/wizardService'
import type RouterService from '@/services/route/routerService'
import type { TmRoutes } from '@/services/route/routerTypes'
import type { RouterBuilderInterface, TmNavigationGuard } from '@/services/route/types'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import { TmApiGoneError } from '@/core/error/transport/tmApiGoneError'
import type WindowService from '@/services/browser/windowService'
import type { WizardState } from '@/services/domain/wizard/types'
import type RestrictionPagesRouterService from '@/services/auth/restrictionPagesRouterService'
import TmLogicError from '@/core/error/tmLogicError'

@injectable()
export default class WizardResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.WizardService)
    private readonly wizardService: WizardService,
    @inject(SERVICE_TYPES.UserService)
    private readonly userService: UserService,
    @inject(SERVICE_TYPES.RouterService)
    private readonly routerService: RouterService,
    @inject(SERVICE_TYPES.RouterBuilderService) private readonly routerBuilderService: RouterBuilderInterface,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.RestrictionPagesRouterService)
    protected readonly restrictionPagesRouterService: RestrictionPagesRouterService,
  ) {}

  private subscriptionKey: string | null = null

  private reloadTimeoutId: number | null = null

  private stepToGettingStartedPage() {
    const route: TmRoutes = {
      name: 'user.gettingStarted',
    }
    return this.routerService.replace(route)
  }

  private stepToWizardPage() {
    const route: TmRoutes = {
      name: 'wizard',
    }
    return this.routerService.replace(route)
  }

  private stepToLoginPersonalPage() {
    return this.restrictionPagesRouterService.goToLoginPersonalRoute()
  }

  private changeStepErrorHandler = (e) => {
    if (!(e instanceof TmApiGoneError)) {
      return
    }
    this.reloadTimeoutId = window.setTimeout(() => this.windowService.getLocation().reload(), 2000)
  }

  private beforeEachHandler: TmNavigationGuard = (to, from, prevent) => {
    const wizardState = this.wizardService.findWizardState()
    const isAllowUsageMainApplication = this.wizardService.isAllowUsageMainApplication(wizardState)
    if (isAllowUsageMainApplication) {
      return
    }
    const paymentRoute: TmRoutes = {
      name: 'payment',
    }
    const logoutRoute: TmRoutes = {
      name: 'auth.logout',
    }
    if (
      to.meta.isWizardPage ||
      to.name === paymentRoute.name ||
      to.name === logoutRoute.name ||
      to.name === this.restrictionPagesRouterService.getPersonalUseRoute().name
    ) {
      return
    }
    prevent()
  }

  public async resolve() {
    const currentRoute = this.routerService.getCurrentRoute()
    const currentUser = this.userService.currentUser()
    if (!this.userService.isAllowWizardForCurrentUser()) {
      if (currentRoute.meta.isWizardPage) {
        await this.stepToGettingStartedPage()
      }
      return
    }
    this.subscriptionKey = this.subscription.subscribe(wizardChangeStepErrorEvent, this.changeStepErrorHandler)

    let wizardState: WizardState = await this.wizardService.getWizardState()

    if (wizardState.currentStep === 'configure_analytics') {
      wizardState = await this.wizardService.apply(
        this.wizardService.getStepAfterConfigureAnalytics(currentUser.countryId),
      )
    }
    if (wizardState.currentStep === 'private_user') {
      await this.stepToLoginPersonalPage()
      return
    }

    const isAllowUsageMainApplication = this.wizardService.isAllowUsageMainApplication(wizardState)
    if (!isAllowUsageMainApplication && !currentRoute.meta.isWizardPage) {
      await this.stepToWizardPage()
    }

    if (isAllowUsageMainApplication && currentRoute.meta.isWizardPage) {
      await this.stepToGettingStartedPage()
    }
    if (!isAllowUsageMainApplication) {
      this.routerBuilderService.addBeforeEachHandler(this.beforeEachHandler)
    }

    if (wizardState.currentStep && !this.wizardService.hasWizardStep(wizardState.currentStep)) {
      throw new TmLogicError(`Not handled wizard step "${wizardState.currentStep}, flow ${wizardState.flow}"`)
    }
  }

  public async unresolve() {
    this.routerBuilderService.removeBeforeEachHandler(this.beforeEachHandler)
    if (this.reloadTimeoutId) {
      clearTimeout(this.reloadTimeoutId)
    }
    if (!this.subscriptionKey) {
      return
    }
    this.subscription.unsubscribe([this.subscriptionKey])
  }
}

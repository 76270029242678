<template>
  <page-content
    :menu-title="$t('pageTitle.user.chats')"
    bg-shade
    no-top-content
    :sidebar-width="sidebarWidth"
    :is-hidden-sidebar="isShowChatFilterInDropdown"
  >
    <template v-slot:after-sidebar-title>
      <tm-sidebar-header-wrapper>
        <tm-sidebar-header-button
          icon="tmi-settings-outline"
          :to="{ name: ChatSettingsRoutes.root }"
        />
        <tm-tooltip>
          {{ $t('pageTabTitle.user.chats.settings.all') }}
        </tm-tooltip>
      </tm-sidebar-header-wrapper>
    </template>
    <div class="chat">
      <div class="chat-container">
        <div
          class="chat-wrapper"
          :class="{
            'chat-wrapper--active': showMobileChat,
          }"
        >
          <router-suspense-resolver>
            <template v-slot>
              <slot v-bind="$attrs" />
            </template>

            <template v-slot:preloader>
              <slot name="preloader" />
            </template>
          </router-suspense-resolver>
        </div>
      </div>
    </div>
  </page-content>
  <router-view />
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from '@/composition/vue/compositionApi'
import RouterSuspenseResolver from '@/components/resolvers/RouterSuspenseResolver.vue'
import { useChatUiState } from '@/composition/domain/chats/chatUiState'
import { useChatDetailsSidebarState } from '@/composition/domain/chats/chatRightSidebar'
import { getGlobalStateService } from '@/core/container/ioc'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import { useBreakpoints } from '@/composition/breakpoints'
import TmTooltip from '@/components/shared/tooltip/TmTooltip.vue'
import { ChatSettingsRoutes } from '@/routes/types'
import TmSidebarHeaderWrapper from '@/components/layout/pageContent/sidebar/sidebarHeaderButtons/TmSidebarHeaderWrapper.vue'
import TmSidebarHeaderButton from '@/components/layout/pageContent/sidebar/sidebarHeaderButtons/TmSidebarHeaderButton.vue'

export default defineComponent({
  components: {
    RouterSuspenseResolver,
    PageContent,
    TmSidebarHeaderWrapper,
    TmSidebarHeaderButton,
    TmTooltip,
  },
  inheritAttrs: false,
  setup() {
    const globalStateService = getGlobalStateService()
    const { showMobileChat, requestNotificationPermission, isShowChatFilterInDropdown } = useChatUiState()
    const { setState } = useChatDetailsSidebarState()
    const { isMobile, is3xlMax } = useBreakpoints()

    const sidebarWidth = computed(() => {
      if (isShowChatFilterInDropdown.value) {
        return 0
      }

      if (is3xlMax.value) {
        return 240
      }

      return 270
    })

    const initDetailsSidebar = () => {
      setState({
        sidebarOpen: globalStateService.getFieldValueSync('isChatDetailsSidebarOpen') !== false,
        visibleCustomFields: globalStateService.getFieldValueSync('isChatDetailsSidebarVisibleCustomFields') === true,
      })
    }

    onMounted(() => {
      requestNotificationPermission()
      initDetailsSidebar()
    })

    return {
      showMobileChat,
      sidebarWidth,
      isMobile,
      ChatSettingsRoutes,
      isShowChatFilterInDropdown,
    }
  },
})
</script>
<style scoped lang="scss">
@import '@/styles/mixins.scss';

@media print {
  :global(.header),
  :global(.main-sidebar) {
    display: none !important;
  }
  :global(.chat),
  :global(.chat-content),
  :global(.chat-container),
  :global(.chat-content__main) {
    display: block !important;
    max-width: 100% !important;
    height: auto !important;
  }
}
:deep {
  .tm-scrollbar__content,
  .page-content-main-block,
  .page-content-default-wrapper__content {
    height: 100%;
  }
  .page-content-main-wrap .page-content-main {
    height: 100%;
    // padding: 16px;

    @include media-sm-x-max {
      padding: 0;
    }
    @include media-md-min {
      padding: 16px;
    }
    @include media-lg-min {
      padding: 16px;
    }
  }
  .tm-scrollbar--stable {
    scrollbar-gutter: unset;
  }
  .tm-sidebar-header-wrapper {
    margin-right: -12px;
  }
}

.chat {
  height: 100%;
  width: 100%;
  background: $shade;

  &-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 1788px;
    display: flex;
    flex-direction: column;

    @include media-sm-x-max {
      background: $white;
    }
  }

  &-wrapper {
    position: relative;
    background: $white;
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;

    &--active {
      @include media-sm-x-max {
        transform: translateX(-100%);
      }
      @media print {
        transform: translateX(0);
      }
    }

    @include media-md-min {
      border: 1px solid $border-color;
      border-radius: $border-radius-md;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
    }

    @include media-sm-x-max {
      background: transparent;
      overflow: visible;
      height: 100%;
      transition: all $transition-300ms-ease-in-out;
    }

    :deep(.chat-sidebar) {
      @include chat-width(left-sidebar);
      position: relative;
      display: flex;
      flex-flow: column;
      flex: 1 0 100%;
      height: 100%;
      border-right: 1px solid $border-color;

      @include media-md-min {
        flex-shrink: 1;
      }

      .chat-sidebar-content {
        position: relative;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        flex-grow: 1;
        flex-shrink: 1;
      }
    }

    :deep(.chat-content) {
      display: flex;
      flex-flow: column;
      flex: 1 0 100%;
      overflow: hidden;
      height: 100%;

      @include media-md-min {
        flex-shrink: 1;
      }
    }

    :deep(.chat-content-header) {
      display: flex;
      align-items: center;
      height: $contentHeaderHeight;
      border-bottom: 1px solid $gray2;
      padding: 0 12px 0 16px;
      flex-shrink: 0;
    }
  }
}
</style>

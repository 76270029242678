import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type AuthService from '@/services/auth/authService'
import type ReopenAccountService from '@/services/signUp/reopenAccountService'

@injectable()
export default class ReopenAccountResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.AuthService) protected readonly authService: AuthService,
    @inject(SERVICE_TYPES.ReopenAccountService) protected readonly reopenAccountService: ReopenAccountService,
  ) {}

  public async resolve() {
    const data = await this.reopenAccountService.fetchReopenAccountData()

    if (!data || !data.loginOrEmail) {
      return this.authService.toInitialRoute()
    }

    return undefined
  }
}

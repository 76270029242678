import type { RouteRecordRaw } from 'vue-router'
import { ScheduledRoutes } from '@/routes/user/scheduled/types'
import ScheduledMessage from '@/data/models/domain/scheduled/ScheduledMessage'
import type { SingleModelResolverParams } from '@/services/resolvers/types'
import { AppModule } from '@/config/types'

export const scheduledDetailsRoutes: RouteRecordRaw = {
  name: ScheduledRoutes.details,
  path: 'details/:messageId',
  props: true,
  meta: {
    tabTitle: 'pageTabTitle.user.scheduled.details',
    fullHeight: true,
    detailsPage: {
      source: 'router',
      requestParam: 'messageId',
      model: ScheduledMessage,
    } as SingleModelResolverParams,
    redirectToIfNotFound: { name: ScheduledRoutes.scheduled },
    titler: {
      service: 'RouteTitlerService',
    },
    resolvers: [
      {
        service: 'ScheduledDetailsResolverService',
      },
    ],
    modules: [AppModule.ScheduledDetails, AppModule.ContactList, AppModule.SentSmsBase, AppModule.RecentRecipient],
    accessControlGroups: ['NotCampaignsScheduledDetailsStrategy'],
  },
  component: () => import('@/components/views/scheduled/ScheduledDetails.vue'),
}

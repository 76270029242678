import { injectable } from 'inversify'
import Modal from '@/data/models/wrappers/Modal'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { Endpoint } from '@/services/endpoints'

@RepoSettings<Endpoint>({
  model: Modal,
})
@injectable()
export default class ModalRepository extends OrmApiRepository<Modal> {}

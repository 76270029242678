/* eslint-disable @typescript-eslint/naming-convention */
import { isEmpty, isFunction } from 'lodash-es'
import type { Predicate } from '@vuex-orm/core/dist/src/query/contracts'
import type * as Data from '@vuex-orm/core/dist/src/data'
import BaseModel from '@/data/models/BaseModel'
import type { StateEntitiesMap, VuexOrmStore } from '@/services/vuex/types'
import { TmRepositoryError } from '@/core/error/tmRepositoryError'

export type RecordUpdateClosure<T extends Data.Record = Data.Record> = (record: T) => void

export interface BaseMutationPayload {
  data?: Record<string, any> | Record<string, any>[] | RecordUpdateClosure
  stiModel?: typeof BaseModel
  condition?: Predicate
}

export const getBaseStiModel = (model: typeof BaseModel) => model.store().$db().baseModel(model.entity)

export const getStiModelType = (stiModel: typeof BaseModel) => {
  let modelType = ''
  if (stiModel && !isEmpty(stiModel.types())) {
    for (const key of Object.keys(stiModel.types())) {
      if (BaseModel.isModelType(stiModel.types()[key], stiModel)) {
        modelType = key
        break
      }
    }
  }
  return modelType
}

const getLowLevelInsertMutationByModel =
  (model: typeof BaseModel) => (state: VuexOrmStore, payload: BaseMutationPayload) => {
    const modelType = payload.stiModel ? getStiModelType(payload.stiModel) : ''
    const model_ = model.baseEntity ? getBaseStiModel(model) : model
    const storeData = state.entities[model_.entity as keyof StateEntitiesMap].data
    ;((payload.data || []) as Record<string, any>[]).forEach((rec: any) => {
      const record = { ...rec, $id: rec.id }
      if (modelType) {
        record[model.typeKey] = modelType
      }
      storeData[record.id] = record
    })
  }

const getLowLevelUpdateMutationByModel =
  (model: typeof BaseModel) => (state: VuexOrmStore, payload: BaseMutationPayload) => {
    const modelType = payload.stiModel ? getStiModelType(payload.stiModel) : ''
    const model_ = model.baseEntity ? getBaseStiModel(model) : model
    const storeData = state.entities[model_.entity as keyof StateEntitiesMap].data
    ;((payload.data || []) as Record<string, any>[]).forEach((rec: any) => {
      const { id, ...recordToInsert } = rec as Record<string, any>
      storeData[id] = { ...storeData[id], ...recordToInsert }
      if (modelType) {
        ;(storeData[id] as any)[model.typeKey] = modelType
      }
    })
  }

const getLowLevelInsertOrUpdateMutationByModel =
  (model: typeof BaseModel) => (state: VuexOrmStore, payload: BaseMutationPayload) => {
    const modelType = payload.stiModel ? getStiModelType(payload.stiModel) : ''
    const model_ = model.baseEntity ? getBaseStiModel(model) : model
    const storeData = state.entities[model_.entity as keyof StateEntitiesMap].data
    ;((payload.data || []) as Record<string, any>[]).forEach((rec: any) => {
      const { id, ...recordToInsert } = rec as Record<string, any>
      if (storeData[id]) {
        storeData[id] = { ...storeData[id], ...recordToInsert }
      } else {
        storeData[id] = { ...rec, $id: rec.id }
      }
      if (modelType) {
        ;(storeData[id] as any)[model.typeKey] = modelType
      }
    })
  }

const getLowLevelUpdateByConditionMutationByModel =
  (model: typeof BaseModel) => (state: VuexOrmStore, payload: BaseMutationPayload) => {
    if (!payload.condition) {
      throw new TmRepositoryError(
        'Payload should contain update condition for "getLowLevelUpdateByConditionMutationByModel"',
      )
    }
    if (!payload.data) {
      payload.data = (rec: any) => rec
    }
    const modelType = payload.stiModel ? getStiModelType(payload.stiModel) : ''
    const model_ = model.baseEntity ? getBaseStiModel(model) : model
    const storeData = state.entities[model_.entity as keyof StateEntitiesMap].data
    for (const [recordKey, value] of Object.entries(storeData)) {
      if (payload.condition(value)) {
        if (isFunction(payload.data)) {
          payload.data(storeData[recordKey])
        } else {
          const { id, ...recordToInsert } = payload.data as Record<string, any>
          storeData[recordKey] = { ...storeData[recordKey], ...recordToInsert }
        }
        if (modelType) {
          ;(storeData[recordKey] as any)[model.typeKey] = modelType
        }
      }
    }
  }

const getLowLevelDeleteMutationByModel =
  (model: typeof BaseModel) =>
  (state: VuexOrmStore, payload: { ids: (string | number)[]; stiModel?: typeof BaseModel }) => {
    const modelType = payload.stiModel ? getStiModelType(payload.stiModel) : ''
    const model_ = model.baseEntity ? getBaseStiModel(model) : model
    const storeData = state.entities[model_.entity as keyof StateEntitiesMap].data
    payload.ids.forEach((id: any) => {
      if (!modelType || modelType === (storeData[id] as any)[model.typeKey]) {
        delete storeData[id]
      }
    })
  }

const getLowLevelDeleteByConditionMutationByModel =
  (model: typeof BaseModel) => (state: VuexOrmStore, payload: BaseMutationPayload) => {
    const modelType = payload.stiModel ? getStiModelType(payload.stiModel) : ''
    const model_ = model.baseEntity ? getBaseStiModel(model) : model
    const storeData = state.entities[model_.entity as keyof StateEntitiesMap].data
    for (const [recordKey, value] of Object.entries(storeData)) {
      if (!modelType || modelType === (value as any)[model.typeKey]) {
        if (payload.condition!(value)) {
          delete storeData[recordKey]
        }
      }
    }
  }

export const getInsertMutationName = (entity: string) => `LOW_LEVEL_INSERT.${entity}`
export const getUpdateMutationName = (entity: string) => `LOW_LEVEL_UPDATE.${entity}`
export const getUpdateConditionMutationName = (entity: string) => `LOW_LEVEL_UPDATE_CONDITION.${entity}`
export const getInsertOrUpdateMutationName = (entity: string) => `LOW_LEVEL_INSERT_OR_UPDATE.${entity}`
export const getDeleteMutationName = (entity: string) => `LOW_LEVEL_DELETE.${entity}`
export const getDeleteConditionMutationName = (entity: string) => `LOW_LEVEL_DELETE_CONDITION.${entity}`

export const addBaseMutationsByModel = (model: typeof BaseModel, mutations: Record<string, unknown>) => {
  ;(mutations as any)[getInsertMutationName(model.entity)] = getLowLevelInsertMutationByModel(model)
  ;(mutations as any)[getUpdateMutationName(model.entity)] = getLowLevelUpdateMutationByModel(model)
  ;(mutations as any)[getUpdateConditionMutationName(model.entity)] = getLowLevelUpdateByConditionMutationByModel(model)
  ;(mutations as any)[getInsertOrUpdateMutationName(model.entity)] = getLowLevelInsertOrUpdateMutationByModel(model)
  ;(mutations as any)[getDeleteMutationName(model.entity)] = getLowLevelDeleteMutationByModel(model)
  ;(mutations as any)[getDeleteConditionMutationName(model.entity)] = getLowLevelDeleteByConditionMutationByModel(model)
}

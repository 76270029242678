import type { RouteRecordRaw } from 'vue-router'
import { ScheduledRoutes } from '@/routes/user/scheduled/types'
import { AppModule } from '@/config/types'

export const scheduledCompletedRoutes: RouteRecordRaw = {
  name: ScheduledRoutes.completed,
  path: 'completed',
  redirect: { name: ScheduledRoutes.completedOverview },
  children: [
    {
      name: ScheduledRoutes.completedOverview,
      path: '',
      meta: {
        tags: ['SCHEDULED_BAR_LIST'],
        title: 'pageTitle.user.scheduled.completed', // page content title @todo: get rid of it
        tabTitle: 'pageTabTitle.user.scheduled.completed',
        modules: [AppModule.ScheduledCompleted],
        accessControlGroups: ['NotCampaignsScheduledCompletedStrategy'],
      },
      component: () => import('@/components/views/scheduled/ScheduledCompleted.vue'),
    },
  ],
}

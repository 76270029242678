import { injectable } from 'inversify'
import type { TypedFieldConfig } from '@/services/forms/baseForm/typedFormBuilder/types'
import type { SelectSenderPhoneFieldConfig, SelectSenderPhoneSubfieldNames } from '@/services/forms/types'
import { SenderPhoneNumberType } from '@/services/forms/types'
import type { CountryPhoneData } from '@/constants/countryPhoneData'
import { fieldSenderPhoneRulePrefix } from '@/components/forms/types'

type SenderPhoneFieldParams = {
  phoneCountryOptions: CountryPhoneData[]
  withCustomNumber?: boolean
}

@injectable()
export default class SenderPhoneFieldBuilderService {
  public getSubfieldValidationRuleName(fieldName: SelectSenderPhoneSubfieldNames) {
    return `${fieldSenderPhoneRulePrefix}-${fieldName}`
  }

  public getFieldConfig({
    phoneCountryOptions,
    withCustomNumber,
  }: SenderPhoneFieldParams): TypedFieldConfig<SelectSenderPhoneFieldConfig> {
    return {
      fieldType: 'selectSenderPhone',
      value: {
        valueType: SenderPhoneNumberType.DEFAULT,
      },
      componentProps: {
        phoneCountryOptions: () => phoneCountryOptions,
        withCustomNumber: () => withCustomNumber,
      },
    }
  }
}

import { inject, injectable } from 'inversify'
import type {
  IRecipientContactProperties,
  IRecipientListProperties,
  Recipient,
  RecipientOption,
  TInteractiveRecipientOption,
} from '@/services/domain/messages/types'
import { addPlusToPhoneNumber } from '@/utils/string/addPlusToPhoneNumber'
import { SERVICE_TYPES } from '@/core/container/types'
import type ContactService from '@/services/domain/contact/contactService'
import type { DistributionRecipients } from '@/data/models/domain/services/distribution/DistributionDetails'
import type ContactListService from '@/services/domain/contactList/contactListService'
import type NumericService from '@/services/numericService'
import type PhoneService from '@/services/phoneService'
import { safeParseInt } from '@/utils/number'
import type FilteredView from '@/data/models/domain/filteredViews/FilteredView'

@injectable()
export default class RecipientService {
  constructor(
    @inject(SERVICE_TYPES.ContactService) protected readonly contactService: ContactService,
    @inject(SERVICE_TYPES.ContactListService) protected readonly contactListService: ContactListService,
    @inject(SERVICE_TYPES.NumericService) protected readonly numericService: NumericService,
    @inject(SERVICE_TYPES.PhoneService) protected readonly phoneService: PhoneService,
  ) {}

  public normalizeRecipients(recipients: Recipient[]) {
    return recipients.map((el: Recipient) => {
      const recipient: Record<string, unknown> = {
        ...el,
        uid: el.entityId,
        entityId: el.entityId || safeParseInt(el.value),
        avatar: this.contactService.getAvatarPropsForContact({
          id: String(el.entityId),
          phone: el.value,
          avatarHref: el.avatarHref,
          fullName: el.entityType === 'reply' ? '' : el.title,
          firstName: el.value,
          isList: el.entityType === 'list',
          isFilteredView: el.entityType === 'filtered_view',
        }),
      }
      if (el.entityType === 'list') {
        recipient.label = el.title
        recipient.hint = `${el.value} recipients`
        recipient.shortHint = el.value.toString()
      }
      if (el.entityType === 'filtered_view') {
        recipient.label = el.title
        recipient.hint = `${el.value} recipients`
        recipient.shortHint = el.value.toString()
      }
      if (el.entityType === 'contact') {
        recipient.nameAsPhone = el.title.trim() === el.value
        recipient.label = el.title
        recipient.hint = this.phoneService.preparePhoneForDisplay(el.value)
      }
      if (el.entityType === 'reply') {
        recipient.nameAsPhone = true
        recipient.label = this.phoneService.preparePhoneForDisplay(el.value)
        recipient.hint = el.countryName
      }
      return recipient as TInteractiveRecipientOption
    })
  }

  public normalizeRecipientOptions(options: RecipientOption[]): DistributionRecipients {
    const groupIds: number[] = []
    const contactIds: number[] = []
    const segmentIds: number[] = []
    const numbers: string[] = []

    options.forEach((el) => {
      if (el.entityType === 'list') {
        groupIds.push(el.entityId!)
      } else if (el.entityType === 'contact') {
        contactIds.push(el.entityId!)
      } else if (el.entityType === 'filtered_view') {
        segmentIds.push(el.entityId!)
      } else {
        numbers.push(addPlusToPhoneNumber(el.value))
      }
    })

    return {
      groupIds,
      contactIds,
      segmentIds,
      numbers,
    }
  }

  public async makeRecipientOptions(recipients: DistributionRecipients): Promise<RecipientOption[]> {
    const contactsIds = recipients.contactIds.map((contactId) => contactId.toString())
    const contactListsIds = recipients.groupIds.map((listId) => listId.toString())

    const [contacts, contactLists] = await Promise.all([
      this.contactService.getEntitiesByIdsFailSafe(contactsIds, [], true),
      this.contactListService.getEntitiesByIdsFailSafe(contactListsIds, [], true),
    ])

    const contactOptions = contacts.map((contact) => this.makeRecipientOptionFromContact(contact))
    const contactListOptions = contactLists.map((contactList) => this.makeRecipientOptionFromContactList(contactList))
    const phoneNumberOptions = recipients.numbers.map((phoneNumber) =>
      this.makeRecipientOptionFromPhoneNumber(phoneNumber),
    )

    return [...contactOptions, ...contactListOptions, ...phoneNumberOptions]
  }

  public makeRecipientOptionFromContactList(list: IRecipientListProperties): RecipientOption {
    return {
      entityType: 'list',
      label: list.name,
      value: `${list.id}`,
      entityId: +list.id,
      isTemporary: list.isTemporary,
      hint: this.numericService.formatInteger(list.membersCount),
    }
  }

  public makeRecipientOptionFromFilteredView(filteredView: FilteredView): RecipientOption {
    return {
      entityType: 'filtered_view',
      label: filteredView.name,
      value: `${filteredView.id}`,
      entityId: +filteredView.id,
      hint: filteredView.count !== undefined ? this.numericService.formatInteger(filteredView.count) : undefined,
    }
  }

  public makeRecipientOptionFromPhoneNumber(phoneNumber: string): RecipientOption {
    return {
      entityId: safeParseInt(phoneNumber),
      entityType: 'raw',
      label: this.phoneService.preparePhoneForDisplay(phoneNumber),
      value: `${phoneNumber}`.replace(/^\+/, ''),
    }
  }

  public makeRecipientOptionFromContact(contact: IRecipientContactProperties): RecipientOption {
    const fullName = (contact.fullName || '').trim()

    return {
      entityType: 'contact',
      label: fullName,
      value: contact.phone,
      entityId: +contact.id,
      hint: fullName !== contact.phone && this.phoneService.preparePhoneForDisplay(contact.phone),
    }
  }
}

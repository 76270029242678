import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import { RegulationsSettingsRoutes, senderIdsRouteName, SenderSettingsRoutes } from '@/routes/user/services/types'
import PhoneNumber from '@/data/models/domain/PhoneNumber'
import type { NotFoundResolverParams } from '@/services/resolvers/types'
import { ChatSettingsAliasedRoutes } from '@/routes/types'
import PassThrough from '@/components/layout/PassThrough.vue'
import type { CallForwardingTitlerServiceParams } from '@/services/domain/senderSettings/callForwardingTitlerService'
import { getSenderSettingsDetailsRoutes } from '@/routes/user/services/senderSettingsDetails'

export const getSenderSettingsRoutes = (): RouteRecordRaw[] => [
  {
    name: SenderSettingsRoutes.index,
    path: '',
    meta: {
      tags: ['SERVICES_BAR_LIST'],
      title: 'pageTitle.user.services.senderSettings',
      modules: [AppModule.SenderSettingsCountries, AppModule.SenderSettings, AppModule.TenDlcTollFreeRequest],
    },
    redirect: { name: SenderSettingsRoutes.numbers },
    children: [
      {
        name: SenderSettingsRoutes.senderSettingsIndex,
        path: '',
        component: PassThrough,
        redirect: { name: SenderSettingsRoutes.numbers },
        children: [
          {
            name: SenderSettingsRoutes.senderSettingsBase,
            path: 'sender-settings',
            component: () => import('@/components/views/services/senderSettings/SenderSettings.vue'),
            redirect: { name: SenderSettingsRoutes.numbers },
            children: [
              {
                name: SenderSettingsRoutes.numbers,
                path: 'numbers',
                component: () => import('@/components/views/services/senderSettings/PhoneNumbersTable.vue'),
                meta: {
                  tabTitle: 'pageTabTitle.user.services.numbers',
                },
              },
              {
                name: SenderSettingsRoutes.countries,
                path: 'countries',
                component: () => import('@/components/views/services/senderSettings/SenderSettingsCountries.vue'),
                meta: {
                  tabTitle: 'pageTabTitle.user.services.countries',
                },
              },
              {
                name: RegulationsSettingsRoutes.tenDlcSettings,
                path: '10dlc',
                component: () => import('@/components/views/services/tenDlc/TenDlc.vue'),
                props: { tab: true },
                meta: {
                  tabTitle: 'pageTabTitle.user.services.tenDlc',
                  accessControlGroups: ['TenDlcStrategy'],
                  modules: [AppModule.TenDlcTollFree],
                },
              },
              {
                name: RegulationsSettingsRoutes.tollFreeSettings,
                path: 'toll-free-verification',
                component: () => import('@/components/views/services/senderSettings/tollFree/TollFreeVerification.vue'),
                props: { tab: true },
                meta: {
                  tabTitle: 'pageTabTitle.user.services.tollFree',
                  accessControlGroups: ['TollFreeStrategy'],
                  modules: [AppModule.TenDlcTollFree],
                },
              },
              {
                name: senderIdsRouteName,
                path: 'sender-ids',
                meta: {
                  tabTitle: 'pageTabTitle.user.services.senderIds',
                  accessControlGroups: ['SenderIdsStrategy'],
                },
                component: () => import('@/components/views/services/senderSettings/SenderIds.vue'),
              },
            ],
          },
          {
            name: SenderSettingsRoutes.buyNumberBase,
            path: 'buy-number',
            component: PassThrough,
            meta: {
              modules: [AppModule.BuyNumber, AppModule.ByocHelper],
              parent: (route) =>
                route.path.startsWith(ChatSettingsAliasedRoutes.buyNumber) ? 'user.chats' : SenderSettingsRoutes.index,
            },
            alias: ChatSettingsAliasedRoutes.buyNumber,
            redirect: { name: SenderSettingsRoutes.buyNumber },
            children: [
              {
                name: SenderSettingsRoutes.buyNumber,
                path: 'number',
                props: (route) => ({
                  initCountryCode: route.query.country,
                  initTypeTollFree: route.query.typeTollFree,
                }),
                meta: {
                  tabTitle: 'pageTabTitle.user.services.buyNumber.number',
                  permission: 'senderSettings',
                },
                component: () => import('@/components/views/services/buyNumber/BuyNumberStep1.vue'),
              },
              {
                name: SenderSettingsRoutes.callForwarding,
                path: 'call-forwarding/:id',
                props: (route) => ({
                  phoneNumberId: route.params.id,
                }),
                meta: {
                  tabTitle: 'pageTabTitle.user.services.buyNumber.callForwarding',
                  titler: {
                    service: 'CallForwardingTitlerService',
                    params: {
                      phoneId: 'id',
                    } satisfies CallForwardingTitlerServiceParams<typeof SenderSettingsRoutes.callForwarding>,
                  },
                  permission: 'senderSettings',
                  resolvers: [
                    {
                      service: 'RedirectableSingleModelResolverService',
                      params: {
                        model: PhoneNumber,
                        source: 'router',
                        requestParam: 'id',
                        redirectToIfNotFound: { name: SenderSettingsRoutes.numbers },
                      } as NotFoundResolverParams,
                    },
                  ],
                },
                component: () => import('@/components/views/services/buyNumber/BuyNumberCallForwarding.vue'),
              },
            ],
          },
          ...getSenderSettingsDetailsRoutes(),
        ],
      },
    ],
  },
]

import { injectable } from 'inversify'
import type TableElementManagerWithEndpointInterface from '@/services/tables/managers/tableElementManagerWithEndpointInterface'
import TableElementManagerBase from '@/services/tables/managers/tableElementManagerBase'
import type { Endpoint } from '@/services/endpoints'

@injectable()
export default abstract class TableElementManagerWithEndpoint<
    T,
    S extends Record<string, unknown> = Record<string, unknown>,
  >
  extends TableElementManagerBase<T, S>
  implements TableElementManagerWithEndpointInterface<T, S>
{
  protected endpoints: Record<string, Endpoint> = {}

  public getEndpointForTable(tableId: string) {
    return this.endpoints[tableId]
  }

  public setEndpointForTable(tableId: string, endpoint: Endpoint) {
    this.log(`Set table "${tableId}" endpoint to "${endpoint}"`, 'setEndpointForTable')

    this.endpoints[tableId] = endpoint
  }
}

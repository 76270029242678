import { inject, injectable } from 'inversify'
import type { WrapperOpenableInterface, WrapperParams, WrapperTypesServicesKeys } from '@/services/wrappers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type PartialUIWrapperService from '@/services/wrappers/partialUIWrapperService'
import type LoggerService from '@/services/loggerService'
import BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import type BaseWrapperRepository from '@/data/repositories/wrappers/baseWrapperRepository'
import type NewQueryParamsService from '@/services/route/newQueryParamsService'

@injectable()
export default class PartialQueryUIWrapperService implements WrapperOpenableInterface {
  constructor(
    @inject(SERVICE_TYPES.PartialUIWrapperService) protected readonly partialUIWrapperService: PartialUIWrapperService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.NewQueryParamsService) protected readonly newQueryParamsService: NewQueryParamsService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {}

  public build(wrapperId: string, wrapperType?: WrapperTypesServicesKeys) {
    this.partialUIWrapperService.build(wrapperId, wrapperType)
    const query = this.newQueryParamsService.getQueryParams()
    if (!query.partial || !Array.isArray(query.partial)) {
      return
    }
    const partials = query.partial as unknown as Record<any, any>[]

    const partialParams = partials.find((modal) => modal.id === wrapperId)
    if (partialParams) {
      this.partialUIWrapperService.patchParams(wrapperId, partialParams.params)
      this.partialUIWrapperService.open(wrapperId)
    }
  }

  public async open(wrapperId: string, params?: WrapperParams) {
    // @todo Should we avoid hardcoded .partial?
    let partial = this.newQueryParamsService.getQueryParams().partial as Array<any>
    if (partial && partial.length > 0) {
      partial = partial.filter((f) => f)
    }
    if (partial && partial.filter((p) => p.id === wrapperId).length > 0) {
      return
    }
    const query = partial
      ? {
          partial: [
            ...partial,
            {
              id: wrapperId,
              params,
            },
          ],
        }
      : {
          partial: [
            {
              id: wrapperId,
              params,
            },
          ],
        }

    query.partial = query.partial.filter((f) => f)
    this.loggerService.log('partialUI', `Open query ${wrapperId}`, 'partialQuery')
    this.loggerService.log('partialUI', 'Query to set', 'partialQuery')
    this.loggerService.raw('partialUI', query)
    await this.newQueryParamsService.extendQuery(query, true)
    this.trigger(wrapperId, true)
  }

  public async close(wrapperId: string) {
    const partial = this.newQueryParamsService.getQueryParams()?.partial as unknown as Record<any, any>[] | undefined
    let partialParamsKey = 'partial'

    if (partial) {
      const index = partial.findIndex((modal: Record<any, any>) => modal?.id === wrapperId)
      partialParamsKey = partial.length === 1 ? partialParamsKey : `${partialParamsKey}.${index}`
    }

    this.loggerService.log('partialUI', `Close query ${wrapperId}`, 'partialQuery')
    this.loggerService.log('partialUI', `Query to remove${partialParamsKey}`, 'partialQuery')
    await this.newQueryParamsService.removeQuery([partialParamsKey], true)
    this.trigger(wrapperId, false)
  }

  public getWrapperData(wrapperId: string) {
    return this.partialUIWrapperService.getWrapperData(wrapperId)
  }

  public setParams(wrapperId: string, params?: WrapperParams): void {
    this.partialUIWrapperService.setParams(wrapperId, params)
  }

  public patchParams(wrapperId: string, params?: WrapperParams): WrapperParams {
    return this.partialUIWrapperService.patchParams(wrapperId, params)
  }

  public getParams(wrapperId: string) {
    return this.partialUIWrapperService.getParams(wrapperId)
  }

  public removeParams(wrapperId: string, paramsToDelete: string[]) {
    this.partialUIWrapperService.removeParams(wrapperId, paramsToDelete)
  }

  public clearParams(wrapperId: string) {
    this.partialUIWrapperService.clearParams(wrapperId)
  }

  public destroy(wrapperId: string) {
    this.partialUIWrapperService.destroy(wrapperId)
  }

  public isOpen(wrapperId: string) {
    const wrapper = this.getWrapperData(wrapperId)
    return wrapper && wrapper.isOpen
  }

  public isExist(wrapperId: string): boolean {
    return this.partialUIWrapperService.isExist(wrapperId)
  }

  protected getRepository() {
    return this.em.getRepository<BaseWrapperRepository>(BaseWrapper)
  }

  protected trigger(wrapperId: string, isOpen: boolean) {
    this.getRepository().update([
      {
        id: wrapperId,
        isOpen,
      },
    ])
  }
}

import type { JSONContent } from '@tiptap/core'
import { getTiptapEditorContentService } from '@/core/container/ioc'

/**
 * @deprecated please use TmTiptapEditorContentService
 */
export const htmlToRichEditorJSON = (html: string): JSONContent => {
  return getTiptapEditorContentService().htmlToRichEditorJSON(html)
}

/**
 * @deprecated please use TmTiptapEditorContentService
 */
export const richEditorJsonToHTML = (json: JSONContent): string => {
  return getTiptapEditorContentService().richEditorJsonToHTML(json)
}

/**
 * @deprecated please use TmTiptapEditorContentService
 */
export const richEditorJsonToText = (json: JSONContent): string => {
  return getTiptapEditorContentService().richEditorJsonToText(json)
}

/**
 * @deprecated please use TmTiptapEditorContentService
 */
export const htmlToText = (html: string): string => {
  return getTiptapEditorContentService().htmlToText(html)
}

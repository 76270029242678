import type { RouteRecordRaw } from 'vue-router'
import TemplateCategory from '@/data/models/domain/TemplateCategory'
import type { NotFoundResolverParams } from '@/services/resolvers/types'
import { AppModule } from '@/config/types'

const CATEGORY_ID_ROUTE_PARAM = 'categoryId' as const

export const getTemplatesRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.templates',
    path: 'templates',
    meta: {
      tags: ['USER_TOP_MENU'],
      menu: () => import('@/components/domain/templates/TemplatesMenu.vue'),
      modules: [AppModule.Templates],
    },
    props: (route) => ({
      categoryId: route.params[CATEGORY_ID_ROUTE_PARAM],
    }),
    component: () => import('@/components/views/templates/Templates.vue'),
    redirect: { name: 'user.templates.all' },
    children: [
      {
        name: 'user.templates.all',
        path: 'all',
        meta: {
          tabTitle: 'pageTabTitle.user.templates',
          accessControlGroups: ['NotCampaignsTemplatesAllStrategy'],
        },
        component: () => import('@/components/domain/templates/TemplateContent.vue'),
      },
      {
        name: 'user.templates.category',
        path: `categories/:${CATEGORY_ID_ROUTE_PARAM}`,
        meta: {
          tabTitle: 'pageTabTitle.user.templates',
          resolvers: [
            {
              service: 'NonBlockedRedirectableSingleModelResolverService',
              params: {
                requestParam: CATEGORY_ID_ROUTE_PARAM,
                model: TemplateCategory,
                source: 'router',
                redirectToIfNotFound: { name: 'user.templates' },
              } as NotFoundResolverParams,
            },
          ],
          accessControlGroups: ['NotCampaignsTemplatesCategoryStrategy'],
        },
        props: (route) => ({
          categoryId: route.params[CATEGORY_ID_ROUTE_PARAM],
        }),
        component: () => import('@/components/domain/templates/TemplateContent.vue'),
      },
    ],
  },
]

import { inject, injectable } from 'inversify'
import type { AxiosResponse } from 'axios'
import type { HttpInterceptor, InternalErrorResponse } from '@/services/transport/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type GlobalStateService from '@/services/GlobalStateService'

const oldVersionHeader = 'X-App-Old-Version'

@injectable()
export default class OldVersionInterceptor implements HttpInterceptor<any, InternalErrorResponse> {
  constructor(@inject(SERVICE_TYPES.GlobalStateService) protected readonly globalStateService: GlobalStateService) {}

  public response(response: AxiosResponse) {
    if (response && response.headers[oldVersionHeader] === 'true') {
      this.globalStateService.setFieldValueIfChanged('oldVersion', true)
    }

    return response
  }
}

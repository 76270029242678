<template>
  <th
    class="tm-table-th"
    :class="{ active: direction }"
    @click="onClick()"
  >
    <div
      v-if="!hideHeaderName"
      class="d-flex align-center"
    >
      <div class="truncate lh-16">
        {{ label }}
      </div>
      <span class="sort-icon">
        <tm-icon
          v-if="direction === 'asc'"
          name="arrow_upward"
          size="medium"
          :class="{ active: direction === 'asc' }"
        />
        <tm-icon
          v-else-if="direction === 'desc'"
          name="arrow_downward"
          size="medium"
          :class="{ active: direction === 'desc' }"
        />
        <tm-icon
          v-else
          name="tmi-sort"
          size="medium"
        />
      </span>
    </div>
  </th>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmIcon from '@/components/shared/TmIcon.vue'

export default defineComponent({
  components: { TmIcon },
  props: {
    label: {
      type: String,
      required: true,
    },
    direction: {
      type: String,
      required: true,
    },
    hideHeaderName: {
      type: Boolean,
    },
  },
  emits: {
    'on-sort-changed': () => true,
  },
  setup(_props, context) {
    const onClick = () => {
      context.emit('on-sort-changed')
    }

    return { onClick }
  },
})
</script>

<style lang="scss" scoped>
.tm-table-th {
  cursor: pointer;
  .sort-icon {
    color: $gray5;
  }
  &:hover {
    .sort-icon .tm-icon {
      color: $gray;
    }
  }
  &.active {
    .tm-icon {
      color: $gray5;
      &.active {
        color: $blue500;
      }
    }
  }
}
.sort-icon {
  opacity: var(--tm-table-sort-icon-opacity, 1);
  margin-left: 4px;
  color: $gray5;
  width: 16px;
  height: 16px;
  position: relative;
  .active > & {
    opacity: 1;
  }
}
</style>

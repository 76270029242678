import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/container'
import type { Resolvable } from '@/services/resolvers/types'
import type UserService from '@/services/domain/user/userService'
import type ServerSubscriptionService from '@/services/transport/serverSubscriptionService'
import type { TServerEvent } from '@/services/transport/serverEvents'

@injectable()
export default class UserWorkflowAccountChangedResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.UserService)
    private readonly userService: UserService,
    @inject(SERVICE_TYPES.ServerSubscriptionService)
    private readonly serverSubscriptionService: ServerSubscriptionService,
  ) {}

  public async resolve() {
    this.serverSubscriptionService.subscribe('userProfileChanged', this.userProfileChangedHandler)
  }

  private userProfileChangedHandler = (e: TServerEvent<'userProfileChanged'>) => {
    const userIds = Object.keys(e.payload)
    userIds.forEach((userId) => {
      this.userService.updateUserInStore(userId, e.payload[userId])
    })
  }

  public async unresolve() {
    this.serverSubscriptionService.unsubscribe('userProfileChanged', this.userProfileChangedHandler)
  }
}

import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

export enum CONTACTS_BASE_SERVICES {
  ContactService,
  ContactRepository,
  ContactNavigationOrderService,
}

export type ContactsBaseServices = keyof typeof CONTACTS_BASE_SERVICES

export const CONTACTS_BASE_SERVICES_TYPES: Record<ContactsBaseServices, symbol> =
  makeServicesTypes<ContactsBaseServices>(enumKeysToArray<ContactsBaseServices>(CONTACTS_BASE_SERVICES))

import { inject, injectable } from 'inversify'

import { SERVICE_TYPES } from '@/core/container/types'

import type EntityManagerService from '@/data/repositories/entityManagerService'
import { ValidationModes } from '@/services/forms/types'
import type LoggerService from '@/services/loggerService'
import BaseFormService from '@/services/forms/baseFormService'
import type { NoteBaseFormScheme, NotePostBody } from '@/services/domain/note/types'
import type NoteService from '@/services/domain/note/noteService'
import type FormValidationService from '@/services/forms/formValidationService'
import type FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type { ParamsPartial } from '@/types'

@injectable()
export default class NoteBaseFormService extends BaseFormService<
  FormBuilderService,
  ParamsPartial,
  NoteBaseFormScheme
> {
  public contactId: string

  constructor(
    @inject(SERVICE_TYPES.FormBuilderFactoryService) formBuilderFactoryService: () => FormBuilderService,
    @inject(SERVICE_TYPES.EntityManager) em: EntityManagerService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService) validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) logger: LoggerService,
    @inject(SERVICE_TYPES.NoteService) protected readonly noteService: NoteService,
    @inject(SERVICE_TYPES.FormValidationService) protected readonly formValidationService: FormValidationService,
  ) {
    super(formBuilderFactoryService, em, validationRulesBuilderService, logger)
  }

  public async buildForm() {
    const builder = this.getBuilder()
    builder.control({
      fieldType: 'textarea',
      name: 'note',
      value: '',
      validators: this.validationRulesBuilderService.createRules().required({
        messagePath: 'noteFormService.formErrors.required',
      }),
      validationMode: ValidationModes.onSubmit,
    })
  }

  public setContactId(contactId: string) {
    this.contactId = contactId
  }

  public async submit(formData: NoteBaseFormScheme) {
    return this.isEditing() ? this.updateNote(formData, this.getEditingId()) : this.createNote(formData)
  }

  public reset() {
    this.setEditingId('')

    this.formValidationService.resetFormValidator(this.getFormId())
  }

  protected async createNote(note: NotePostBody) {
    return this.noteService.createNoteFromFormData(note, this.contactId)
  }

  protected async updateNote(note: NotePostBody, noteId: string) {
    this.setEditingId('')

    return this.noteService.updateNoteFromFormData(note, noteId)
  }

  protected isEditing(): boolean {
    return !!this.getEditingId()
  }
}

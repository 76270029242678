import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import UserMetadata from '@/data/models/domain/UserMetadata'
import type { Dict } from '@/types'

type UserMetadataMap = {
  name: string
  prop: keyof UserMetadata
  convert: (value: string) => any
}

@RepoSettings<Endpoint>({
  model: UserMetadata,
})
@injectable()
export default class UserMetadataRepository extends OrmApiRepository<UserMetadata> {
  private mappings: Array<UserMetadataMap> = [
    {
      name: 'consent_openai_chat_processing',
      prop: 'openAIConsent',
      convert: (value: string) => {
        if (value) {
          return value === 'true' || value === '1'
        }
        return null
      },
    },
  ]

  public findUserMetadata(): UserMetadata | null {
    return this.query().first()
  }

  public async fetchUserMetadata(): Promise<UserMetadata> {
    const path = this.endpointsService.getPath('userMetadataFetch')
    const response = await this.getApiSource().get<Record<string, string>>(path)
    const metadata = this.map(response.data)
    await this.insertOrUpdateItem(metadata)
    return this.findUserMetadata()!
  }

  public async getUserMetadata(): Promise<UserMetadata> {
    let result = this.findUserMetadata()
    if (!result) {
      result = await this.fetchUserMetadata()
    }
    return result
  }

  public saveOpenAIConsent(value: boolean | null) {
    return this.saveMetadataProp(value, 'openAIConsent')
  }

  public async saveMetadataProp(value: unknown, propName: keyof UserMetadata) {
    const path = this.endpointsService.getPath('userMetadata')
    let metadata = this.findUserMetadata()
    if (!metadata) {
      metadata = new UserMetadata({ id: '1' })
    }
    ;(metadata[propName] as unknown) = value
    const item = this.mappings.find((el) => el.prop === propName)
    await this.getApiSource().post(path, { [item!.name]: value ? value.toString() : null })
    return this.insertOrUpdateItem(metadata)
  }

  private map(data: Dict<string> | null): UserMetadata {
    const result = { id: '1' }
    if (!data) {
      return result as UserMetadata
    }
    this.mappings.forEach((item) => {
      if (item.name in data) {
        result[item.prop] = item.convert(data[item.name])
      }
    })
    return result as UserMetadata
  }
}

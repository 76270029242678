import { inject, injectable } from 'inversify'
import BaseFormService from '@/services/forms/baseFormService'
import type FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type LoggerService from '@/services/loggerService'
import type ForwardAudioService from '@/services/domain/forwardAudio/forwardAudioService'

@injectable()
export default class ForwardAudioFormService extends BaseFormService<FormBuilderService> {
  constructor(
    @inject(SERVICE_TYPES.FormBuilderFactoryService) formBuilderFactoryService: () => FormBuilderService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.ForwardAudioService) protected readonly forwardAudioService: ForwardAudioService,
  ) {
    super(formBuilderFactoryService, em, validationRulesBuilderService, logger)
  }

  private ATTACH_VIDEO_AUDIO_FILE_LIMIT = 15_728_640 // 15 MB

  public async buildForm() {
    const builder = this.getBuilder()

    builder.control({
      fieldType: 'file',
      name: 'file',
      value: null,
      validators: this.validationRulesBuilderService.createRules().fileMaxSize({
        data: { audio: this.ATTACH_VIDEO_AUDIO_FILE_LIMIT },
        messagePath: 'common.fileSizeError',
      }),
    })
  }

  public async submit(formData: { file: File }) {
    return this.forwardAudioService.uploadFile(formData.file)
  }
}

import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import { ByocMessengerRoutePaths, ByocRouteNames } from '@/services/domain/byoc/types'
import { ServicesRouteName } from '@/routes/types'

export const getByocTopRoutes = (): RouteRecordRaw[] => [
  {
    name: 'byoc.top.subscribe',
    path: 'services/byoc/subscribe',
    alias: ByocMessengerRoutePaths.subscribe,
    component: () => import('@/components/containers/SinglePageContainer.vue'),
    meta: {
      parent: (route) => (route.path.startsWith(ByocMessengerRoutePaths.subscribe) ? 'user.chats' : ServicesRouteName),
    },
    children: [
      {
        name: ByocRouteNames.subscribe,
        path: '',
        meta: {
          resolvers: [{ service: 'ByocSubscribeResolver' }],
          modules: [AppModule.Byoc],
        },
        component: () => import('@/components/views/services/byoc/ByocSubscribe.vue'),
      },
    ],
  },
]

import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import type UserAnalyticsTraitsRepository from '@/data/repositories/domain/users/userAnalyticsTraitsRepository'
import UserAnalyticsTraits from '@/data/models/domain/UserAnalyticsTraits'

@DomainSettings({
  model: UserAnalyticsTraits,
})
@injectable()
export default class UserAnalyticsTraitsService extends DomainBaseService<UserAnalyticsTraitsRepository> {
  public getAnalyticsTraits(): UserAnalyticsTraits {
    return this.getDomainRepository().findAnalyticsTraits()
  }
}

import { inject, injectable } from 'inversify'
import type { Router } from 'vue-router'
// TODO: Fix ignored tp/forbid-import-composable-to-service
// eslint-disable-next-line tp/forbid-import-composable-to-service,tp/using-vue-in-services-restriction
import type { Plugin } from '@/composition/vue/compositionApi'
import {
  type ExternalTrackerAsPlugin,
  type TrackableConfig,
  type ConvertEvent,
  convertRoutes,
} from '@/services/tracking/types'
import ConfigReader from '@/core/configReader'
import { SERVICE_TYPES } from '@/core/container/types'
import ExternalTrackerService from '@/services/tracking/externalTrackerService'
import type WindowService from '@/services/browser/windowService'
import type LoggerService from '@/services/loggerService'
import type RouterService from '@/services/route/routerService'
import { ROUTER_AFTER_EACH } from '@/services/route/types'
import type SubscriptionService from '@/services/transport/subscriptionService'

@injectable()
export default class ConvertService extends ExternalTrackerService {
  protected libraryId: string = 'convert'

  protected cdnPath: string = '//cdn-4.convertexperiments.com/js/'

  constructor(
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {
    super()
  }

  public getConfig(): TrackableConfig {
    const config = ConfigReader.config()

    return {
      id: config.tracking.convertKey,
    }
  }

  public getPlugin(): Plugin {
    return {
      install: () => this.initialize(),
    } as Plugin
  }

  public async initialize(): Promise<void> {
    const id = this.getConfig().id
    const router: Router = this.routerService.getRouter()
    let isInitialized: boolean = false
    const trackingHandler = async () => {
      await router.isReady()
      track()
      this.subscriptionService.subscribe(ROUTER_AFTER_EACH, track, true)
    }
    const track = () => {
      const shouldInitialize: boolean = convertRoutes.includes(router.currentRoute.value?.name || '') && !isInitialized
      if (shouldInitialize) {
        isInitialized = true
        this.initializeTrackerAsPlugin(trackerAsPlugin)
      }
    }
    const trackerAsPlugin = {
      // @todo refactor to getSrc getLibraryId methods with parent implementation
      src: `${this.cdnPath}${id}.js`,
      id: this.libraryId,
      trackingHandler,
    } as ExternalTrackerAsPlugin

    try {
      await trackingHandler()
    } catch (e) {
      this.loggerService.error('error', (e as Error).message)
    }
  }

  public trackEvent(event: ConvertEvent): void {
    const window = this.windowService.self()

    try {
      // @ts-expect-error window conversion object assignment
      window._conv_q = window._conv_q || []
      // @ts-expect-error library conversion call
      _conv_q.push(['triggerConversion', event])
    } catch (e) {
      this.loggerService.error('error', (e as Error).message)
    }
  }
}

import { inject, injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { SERVICE_TYPES } from '@/core/container/types'
import { DomainSettings } from '@/decorators/domainDecorators'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import { TeamSubscriptionPeriod } from '@/services/domain/accounts/types'
import type TeamSubscriptionPriceRepository from '@/data/repositories/domain/TeamSubscriptionPriceRepository'
import TeamSubscriptionPrice from '@/data/models/domain/TeamSubscriptionPrice'

@DomainSettings({
  model: TeamSubscriptionPrice,
})
@injectable()
export default class TeamSubscriptionPriceService extends DomainBaseService<TeamSubscriptionPriceRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public getPrices() {
    return this.getDomainRepository().getPrices()
  }

  public hasPrices() {
    return this.findAll().length !== 0
  }

  public getSubscriptionPricePerMonth(subscription: TeamSubscriptionPrice) {
    if (subscription.subscriptionPeriod === TeamSubscriptionPeriod.annual) {
      return subscription.cost / 12
    }
    return subscription.cost
  }
}

import { inject, injectable } from 'inversify'
import type { Router } from 'vue-router'
// TODO: Fix ignored tp/forbid-import-composable-to-service
// eslint-disable-next-line tp/using-vue-in-services-restriction,tp/forbid-import-composable-to-service
import type { Plugin } from '@/composition/vue/compositionApi'
import type { ExternalTrackerAsPlugin, TrackableConfig, TrackingEvent } from '@/services/tracking/types'
import { SERVICE_TYPES } from '@/core/container/types'
import ConfigReader from '@/core/configReader'
import type RouterService from '@/services/route/routerService'
import type SubscriptionService from '@/services/transport/subscriptionService'
import type UserAnalyticsFieldsService from '@/services/domain/user/userAnalyticsFieldsService'
import { type Route, ROUTER_AFTER_EACH } from '@/services/route/types'
import ExternalTrackerService from '@/services/tracking/externalTrackerService'
import UserAnalyticsFields from '@/data/models/domain/UserAnalyticsFields'
import type UserService from '@/services/domain/user/userService'
import type SerializerService from '@/services/serializerService'
import type DefaultTitlerService from '@/services/route/titlers/defaultTitlerService'
import UserAnalyticsTraits from '@/data/models/domain/UserAnalyticsTraits'
import type UserAnalyticsTraitsService from '@/services/domain/user/userAnalyticsTraitsService'
import type WindowService from '@/services/browser/windowService'

@injectable()
export default class SegmentAnalyticsService extends ExternalTrackerService {
  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
    @inject(SERVICE_TYPES.UserAnalyticsFieldsService)
    protected readonly userAnalyticsFieldsService: UserAnalyticsFieldsService,
    @inject(SERVICE_TYPES.UserAnalyticsTraitsService)
    protected readonly userAnalyticsTraitsService: UserAnalyticsTraitsService,
    @inject(SERVICE_TYPES.SerializerService) protected readonly serializerService: SerializerService,
    @inject(SERVICE_TYPES.DefaultTitlerService) protected readonly titlerService: DefaultTitlerService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
  ) {
    super()
  }

  public getConfig(): TrackableConfig {
    const config = ConfigReader.config()

    return {
      id: config.tracking.segmentAnalyticsKey,
      snippetVersion: config.tracking.segmentSnippetVersion,
    }
  }

  public getPlugin(): Plugin {
    return {
      install: () => this.initialize(),
    } as Plugin
  }

  protected initialize(): void {
    const trackSegment = (route: Route) => {
      this.trackPage(route)
    }
    const trackingHandler = async () => {
      const router: Router = this.routerService.getRouter()
      await router.isReady()
      trackSegment(router.currentRoute.value)
      this.subscriptionService.subscribe(ROUTER_AFTER_EACH, trackSegment, true)
    }
    const trackerAsPlugin: ExternalTrackerAsPlugin = {
      trackingHandler,
      loadOnCustomEvent: true,
    }

    this.initializeTrackerAsPlugin(trackerAsPlugin)
  }

  public track(event: TrackingEvent, trackingTraits: Record<string, string>): void {
    // eslint-disable-next-line
    const analytics = this.windowService.self()['analytics']
    analytics.track(event.name, trackingTraits)
  }

  /* eslint-disable */
  protected trackPage(route: Route): void {
    const segmentKey = this.getConfig().id
    const snippetVersion = this.getConfig().snippetVersion
    const userService: UserService = this.userService
    const userAnalyticsFieldsService: UserAnalyticsFieldsService = this.userAnalyticsFieldsService
    const userAnalyticsTraitsService: UserAnalyticsTraitsService = this.userAnalyticsTraitsService
    const serializerService: SerializerService = this.serializerService
    const titlerService: DefaultTitlerService = this.titlerService
    const windowService: WindowService = this.windowService
    // @ts-expect-error
    !(function () {
      const trackUser = () => {
        const user = userService.getCurrentUserOrNull(['timezone'])
        if (user) {
          const analyticsFields: UserAnalyticsFields = userAnalyticsFieldsService.getAnalyticsFields()
          const analyticsTraits: UserAnalyticsTraits = userAnalyticsTraitsService.getAnalyticsTraits()
          const ajsAnonymousId = analyticsFields.ajsAnonymousId
          const userAnalyticsFields = serializerService.serialize(UserAnalyticsFields, user)
          const userAnalyticsTraits = serializerService.serialize(UserAnalyticsTraits, analyticsTraits)
          userAnalyticsTraits.address.country = user.countryId
          const userAnalytics = { ...userAnalyticsFields, ...userAnalyticsTraits }

          if (ajsAnonymousId) {
            analytics.setAnonymousId(ajsAnonymousId)
          } else {
            analytics.ready(function () {
              userAnalyticsFieldsService.updateFields({
                // @ts-expect-error
                ajsAnonymousId: windowService.self().analytics.user().anonymousId(),
              } as UserAnalyticsFields)
            })
          }

          analytics.identify(user.id, userAnalytics, {
            context: {
              timezone: user.timezone ? user.timezone.timezone : 'UTC',
            },
          })
        }
        // allow Segment's Bing to trigger one-time page loaded event without page reload when we use Vue router
        if (windowService.self()['uetq']) {
          windowService.self()['uetq'].pageLoadDispatch = false
        }
        analytics.page(titlerService.getTitle(route, route.params as Record<string, string>))
      }
      const analytics = (windowService.self()['analytics'] = windowService.self()['analytics'] || [])

      if (!analytics.initialize) {
        if (analytics.invoked) {
          windowService.self().console && console.error && console.error('Segment snippet included twice.')
        } else {
          analytics.invoked = true
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
            'addIntegrationMiddleware',
            'setAnonymousId',
            'addDestinationMiddleware',
          ]
          analytics.factory = function (e) {
            return function () {
              let t = Array.prototype.slice.call(arguments)
              t.unshift(e)
              analytics.push(t)
              return analytics
            }
          }
          for (let e = 0; e < analytics.methods.length; e++) {
            let key = analytics.methods[e]
            analytics[key] = analytics.factory(key)
          }
          analytics.load = function (key, e) {
            const t = document.createElement('script')
            t.type = 'text/javascript'
            t.async = true
            t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`
            const n = document.getElementsByTagName('script')[0]
            if (n.parentNode) {
              n.parentNode.insertBefore(t, n)
            }
            analytics._loadOptions = e
          }
          analytics._writeKey = segmentKey
          analytics.SNIPPET_VERSION = snippetVersion
          analytics.load(segmentKey)

          trackUser()
        }
      } else {
        trackUser()
      }
    })()
  }

  public cleanUp(): void {
    const analytics = (this.windowService.self()['analytics'] = this.windowService.self()['analytics'] || [])
    if (analytics.initialize) {
      analytics.reset()
    }
  }
  /* eslint-disable */
}

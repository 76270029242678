import { computed, inject } from '@/composition/vue/compositionApi'
import { TmComponentCompositionError } from '@/core/error/composition/tmComponentCompositionError'
import {
  type BaseModalContentProps,
  DIALOG_CONTEXT_NAME,
  DIALOG_WRAPPER_MODAL_CONFIG,
} from '@/components/shared/modals/tmModal/const'
import type { Callback } from '@/types'
import type { ModalDialogConfigWithTitle } from '@/components/shared/modals/tmModal/types'

export type DialogInjectContext = {
  open: () => void
  close: () => void
  onBeforeHide: (callback: () => void) => void
  onOpen: (callback: () => void) => void
  onClose: (callback: () => void) => void
  onOutsideClick: (callback: () => void) => void
  dialogEmit: (event: string, value?: any) => unknown
  canCloseCallback(cb: Callback<boolean | Promise<boolean>>): void
}

export const useDialog = () => {
  const dialogContext = inject<DialogInjectContext>(DIALOG_CONTEXT_NAME)
  if (!dialogContext) {
    throw new TmComponentCompositionError(
      'Dialog context not found. useDialog should be placed in child of TmDialog.vue',
    )
  }

  return dialogContext
}

export const useModalConfig = (): ModalDialogConfigWithTitle => {
  const modalConfig = inject<ModalDialogConfigWithTitle>(DIALOG_WRAPPER_MODAL_CONFIG)
  if (!modalConfig) {
    throw new TmComponentCompositionError(
      'Wrapper dialog context not found. useWrapperDialog should be placed in child of TmWrapperDialog.vue',
    )
  }

  return modalConfig
}

export const useModalConfigMergedWithProps = (props: Partial<BaseModalContentProps>) => {
  const useModalConfigResult = useModalConfig()

  return {
    ...useModalConfigResult,
    title: computed(() => props.title ?? useModalConfigResult.title),
  }
}

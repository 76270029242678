import { AttrField, BelongsToField, BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import User from '@/data/models/domain/User'

@OrmModel(modelNames.CONTACT_LIST)
export default class ContactList extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @StringField()
  public avatar: string

  @NumberField()
  public membersCount: number

  @BooleanField()
  public shared: boolean

  @BooleanField()
  public favorited: boolean

  @BooleanField()
  public service: boolean

  @BooleanField()
  public isDefault: boolean

  @BooleanField()
  public isTemporary: boolean

  @StringField()
  public description: string

  @BooleanField()
  public isHidden: boolean

  @StringField()
  public userId: string

  @StringField()
  public createdAt: string

  @AttrField()
  public lastUpdatedAt: string | null

  @BelongsToField(User, 'userId')
  public user: User

  public isFakeList(): boolean {
    return this.id === '0'
  }
}

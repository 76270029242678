import type { CustomErrorRule } from '@/core/error/transport/types'
import { TmApiTaskChecklistItemNotFoundError } from '@/core/error/transport/tmApiTaskChecklistItemNotFoundError'
import { EndpointsEnum } from '@/services/endpoints'

export const taskChecklistItemNotFoundErrorRule: CustomErrorRule = {
  klass: TmApiTaskChecklistItemNotFoundError,
  methods: ['put'],
  predicateFn: (error) => {
    const endpointUrl = error.config.url

    if (!endpointUrl) {
      return false
    }

    const regex = new RegExp(`${EndpointsEnum.taskChecklistItemsSingle.replace('{0}', '([0-9]+)')}`)
    return regex.test(endpointUrl)
  },
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

export enum CHAT_STATISTICS_SERVICES {
  ChatStatisticsRepository,
  ChatStatisticsService,
  ChatStatisticsResolver,
  ChatSettingsRepository,
  ChatSettingsService,
  ChatSettingsPreloaderService,
}

export type ChatStatisticsServices = keyof typeof CHAT_STATISTICS_SERVICES

export const CHAT_STATISTICS_SERVICE_TYPES: Record<ChatStatisticsServices, symbol> =
  makeServicesTypes<ChatStatisticsServices>(enumKeysToArray<ChatStatisticsServices>(CHAT_STATISTICS_SERVICES))

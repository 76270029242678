import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ResendInvitationSubAccountFormService,
  SubAccountFormService,
  CloseSubAccountFormService,
}

export type SubAccountsFormServices = keyof typeof SERVICES

export const SUB_ACCOUNTS_FORM_SERVICE_TYPES: Record<SubAccountsFormServices, symbol> =
  makeServicesTypes<SubAccountsFormServices>(enumKeysToArray<SubAccountsFormServices>(SERVICES))

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  EmailLookupSessionService,
  EmailLookupSessionRepository,
}

export type EmailLookupSessionServices = keyof typeof SERVICES

export const EMAIL_LOOKUP_SESSION_SERVICE_TYPES: Record<EmailLookupSessionServices, symbol> =
  makeServicesTypes<EmailLookupSessionServices>(enumKeysToArray<EmailLookupSessionServices>(SERVICES))

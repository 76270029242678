import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import Country from '@/data/models/domain/Country'

export const getTokenIsLockedRoutes = (): RouteRecordRaw[] => [
  {
    name: 'tokenIsLocked.base',
    path: 'token-is-locked',
    component: () => import('@/components/containers/PageLockContainer.vue'),
    meta: {
      tabTitle: 'pageTabTitle.tokenIsLocked',
      modules: [AppModule.UserSession],
      resolvers: [
        {
          service: 'FilterSchemaResolver',
        },
        {
          service: 'ModelResolver',
          params: [Country],
        },
      ],
    },
    children: [
      {
        name: 'tokenIsLocked',
        path: '',
        props: (route) => ({
          fromUrl: route.query.fromUrl,
        }),
        component: () => import('@/components/views/TokenIsLocked.vue'),
      },
    ],
  },
]

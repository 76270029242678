<template>
  <div class="tm-configured-skeleton">
    <div
      v-for="(row, idx) in config"
      :key="idx"
      class="tm-configured-skeleton__row"
    >
      <template v-if="Array.isArray(row)">
        <tm-skeleton
          v-for="(col, idx) in row"
          :key="`col-${idx}`"
          :width="col.width"
          :height="col.height"
          :class="col.classes"
          :style="col.style"
        />
      </template>
      <template v-else>
        <tm-skeleton
          :width="row.width"
          :height="row.height"
          :class="row.classes"
          :style="row.style"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import type { ConfiguredSkeletonConfig } from '@/components/shared/skeletons/configuredSkeleton/types'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'

export default defineComponent({
  components: { TmSkeleton },
  props: {
    config: { type: Array as PropType<ConfiguredSkeletonConfig>, required: true },
  },
})
</script>

<style lang="scss" scoped>
.tm-configured-skeleton {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    flex-direction: row;
  }

  // dirty hack
  .ml-135px {
    margin-left: 135px !important;
  }

  .ml-155px {
    margin-left: 155px !important;
  }
}
</style>

import { random, range } from 'lodash-es'
import type {
  ConfiguredSkeletonConfig,
  ConfiguredSkeletonItem,
  ConfiguredSkeletonRowComplex,
  ConfiguredSkeletonRowFlat,
  ConfirmationSkeletonParams,
} from '@/components/shared/skeletons/configuredSkeleton/types'
import type { ModalDialogConfig } from '@/components/shared/modals/tmModal/types'
import TmConfiguredSkeleton from '@/components/shared/skeletons/configuredSkeleton/TmConfiguredSkeleton.vue'
import { defaultConfirmSkeletonParams } from '@/components/shared/skeletons/configuredSkeleton/types'
import TmSkeletonDetailsButtonsTable from '@/components/shared/skeletons/TmSkeletonDetailsButtonsTable.vue'
import type { RegisteredServices } from '@/core/container/types'
import TmTableWithControlsSkeleton from '@/components/shared/tables/TmTableWithControlsSkeleton.vue'
import TmTableAbstractWithControlsSkeleton from '@/components/shared/tables/TmTableAbstractWithControlsSkeleton.vue'

export type PreloaderType = ModalDialogConfig['preloader']

export class ConfiguredSkeleton {
  public static fromConfig(config: ConfiguredSkeletonConfig<ConfiguredSkeletonRowComplex>, dev = false): PreloaderType {
    return {
      content: TmConfiguredSkeleton,
      dev,
      props: {
        config: this.processConfig(config),
      },
    }
  }

  public static forTableWithServiceId(
    serviceId: RegisteredServices,
    props?: Omit<InstanceType<typeof TmTableWithControlsSkeleton>['$props'], 'serviceId'>,
  ): PreloaderType {
    return {
      content: TmTableWithControlsSkeleton,
      props: {
        ...props,
        serviceId,
      },
    }
  }

  public static forAbstractTable(props?: InstanceType<typeof TmTableAbstractWithControlsSkeleton>['$props']) {
    return {
      content: TmTableAbstractWithControlsSkeleton,
      props,
    }
  }

  public static detailsTableSkeleton(dev = false): PreloaderType {
    return {
      content: TmSkeletonDetailsButtonsTable,
      dev,
    }
  }

  public static box(classes = '', width = '100%', height = '300px'): ConfiguredSkeletonItem {
    return {
      classes,
      width,
      height,
    }
  }

  public static textRow(width = '100%', classes = 'mb-1'): ConfiguredSkeletonItem {
    return {
      width,
      height: '16px',
      classes,
    }
  }

  public static headline18Row(width = '100%', classes = 'mb-2'): ConfiguredSkeletonItem {
    return {
      width,
      height: '24px',
      classes,
    }
  }

  public static headlineXlFontRow(width = '100%', classes = 'mt-3 mb-3'): ConfiguredSkeletonItem {
    return {
      width,
      height: '24px',
      classes,
    }
  }

  public static textRows(repeat = 1, lastRowWidth = '100%', lastRowClass = ''): ConfiguredSkeletonRowComplex {
    return range(repeat).map((n) => {
      const isLast = n === repeat - 1
      return [this.textRow(isLast ? lastRowWidth : '100%', isLast ? lastRowClass : undefined)]
    })
  }

  public static radioTitleAndText(textLines = 1, lastLineWidth = '50%', lastLineClasses = 'mb-1') {
    const result: ConfiguredSkeletonConfig = [
      [
        { width: '17px', height: '16px', classes: 'border-radius-100 mr-2' },
        { width: '80px', height: '14px', classes: 'mb-1' },
      ],
      {
        width: textLines === 1 ? lastLineWidth : '100%',
        height: '14px',
        classes: `ml-6 ${textLines === 1 ? lastLineClasses : ''}`,
      },
    ]

    if (textLines > 1) {
      for (let i = 1; i < textLines; i++) {
        const isLast = i === textLines - 1
        result.push(this.textRow(isLast ? lastLineWidth : '100%', `ml-6 mt-1 ${lastLineClasses}`))
      }
    }

    return result
  }

  public static checkboxRow(
    textLines = 1,
    lastLineWidth = '50%',
    lastLineClasses = 'mb-1',
    checkboxClass = 'mr-2',
  ): ConfiguredSkeletonConfig {
    const result: ConfiguredSkeletonConfig = [
      [
        { width: '17px', height: '16px', classes: checkboxClass },
        { width: '100%', height: '14px', classes: textLines === 1 ? lastLineClasses : 'mb-1' },
      ],
    ]

    if (textLines > 1) {
      for (let i = 0; i < textLines - 1; i++) {
        const isLast = i === textLines - 1
        result.push(this.textRow(isLast ? lastLineWidth : '100%', `ml-6 ${lastLineClasses}`))
      }
    }

    return result
  }

  public static confirmationSkeleton(userParams: Partial<ConfirmationSkeletonParams>): PreloaderType {
    const { textLines, itemsTextLines, items, itemClass, itemBlockClass, dev } = {
      ...defaultConfirmSkeletonParams,
      ...userParams,
    }
    const config: ConfiguredSkeletonConfig<ConfiguredSkeletonRowComplex> = []

    range(textLines).forEach((n) => {
      const isLastLine = n === textLines - 1
      config.push(this.textRow(isLastLine ? '50%' : '100%', isLastLine ? 'mt-1 mb-5' : 'mt-1'))
    })

    range(items).forEach((n) => {
      const isLastLine = n === items - 1
      config.push(this.checkboxRow(itemsTextLines, '100%', !isLastLine ? itemBlockClass : '', itemClass))
    })

    return this.fromConfig(config, dev)
  }

  public static confirmationCheckboxSkeleton(textLines = 3, items = 2, itemsTextLines = 2, dev = false) {
    return this.confirmationSkeleton({
      textLines,
      items,
      itemsTextLines,
      dev,
    })
  }

  public static confirmationBulletsSkeleton(textLines = 3, items = 2, itemsTextLines = 1, dev = false) {
    return this.confirmationSkeleton({
      textLines,
      items,
      itemsTextLines,
      dev,
      itemClass: 'configured-skeleton__bullet',
      itemBlockClass: 'mb-4',
    })
  }

  public static horizontalLine(): ConfiguredSkeletonItem {
    return { width: '100%', height: '1px', classes: 'mt-2 mb-2' }
  }

  public static input(width = '100%', classes = ''): ConfiguredSkeletonItem {
    return { width, height: '40px', classes }
  }

  public static button(width = '120px', classes = ''): ConfiguredSkeletonItem {
    return this.box(classes, width, '36px')
  }

  public static labeledInput(labelWidth = '150px', classes = 'mt-1 mb-3'): ConfiguredSkeletonRowComplex {
    return [[this.textRow(labelWidth)], [this.input('100%', classes)]]
  }

  public static horizontalLabeledInput(labelWidth = '100px', classes = 'ml-4 mb-5'): ConfiguredSkeletonRowComplex {
    return [this.textRow(labelWidth, 'ml-10 mt-3'), this.input('100%', classes)]
  }

  public static labeledInputs(repeat = 3): ConfiguredSkeletonRowComplex {
    return range(repeat).flatMap(() => this.labeledInput(`${random(130, 180)}px`))
  }

  public static textWithLabeledInput(lines = 3, dev = false): PreloaderType {
    return this.fromConfig(
      [...range(lines - 1).map(() => this.textRow()), this.textRow('60%', 'mb-5'), this.labeledInput()],
      dev,
    )
  }

  protected static processConfig(
    config: ConfiguredSkeletonConfig<ConfiguredSkeletonRowComplex>,
  ): ConfiguredSkeletonConfig {
    const processed: ConfiguredSkeletonConfig = []

    for (const item of config) {
      if (Array.isArray(item) && item.some(Array.isArray)) {
        processed.push(...item)
        continue
      }

      processed.push(item as ConfiguredSkeletonRowFlat)
    }

    return processed
  }
}

<template>
  <page-resolver
    :resolvers="resolvers"
    :route-name="routeName"
    @finish-resolve="onResolutionFinished"
  >
    <template
      v-for="name in $slots"
      v-slot:[String(name)]="data"
    >
      <slot
        :name="name"
        v-bind="data"
      />
    </template>

    <template v-slot:failed>
      <slot name="failed"></slot>
    </template>

    <template v-slot:preloader>
      <slot name="preloader"></slot>
    </template>
  </page-resolver>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import { resolversBaseProps } from '@/composition/resolvers'
import { useAuthLoader } from '@/composition/useAuthLoader'
import PageResolver from '@/components/resolvers/PageResolver.vue'
import { getLocalStorageService } from '@/core/container/ioc'
import { REDIRECT_AFTER_LOGIN_KEY } from '@/services/auth/types'

export default defineComponent({
  components: {
    PageResolver,
  },
  inheritAttrs: false,
  props: {
    ...resolversBaseProps,
  },
  setup() {
    const { hideAuthLoader } = useAuthLoader()

    getLocalStorageService().delete(REDIRECT_AFTER_LOGIN_KEY)

    const onResolutionFinished = () => {
      hideAuthLoader() // hide loader when all resolvers are finished
    }

    return {
      onResolutionFinished,
    }
  },
})
</script>

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import MessagesPriceForCountry from '@/data/models/domain/MessagesPriceForCountry'

@RepoSettings<Endpoint>({
  model: MessagesPriceForCountry,
  fetch: 'messagesPriceForCountries',
})
@injectable()
export default class MessagesPriceForCountryRepository extends OrmApiRepository<MessagesPriceForCountry> {
  public async fetchMessagesPriceForCountries(): Promise<MessagesPriceForCountry[]> {
    const res = await this.getApiSource().get<MessagesPriceForCountry[]>(
      this.endpointsService.getPath('messagesPriceForCountries'),
    )
    if (res.data) {
      await this.insertOrUpdate(res.data)
    }
    return this.all()
  }

  public async getMessagesPriceForCountries(): Promise<MessagesPriceForCountry[]> {
    const result = this.all()
    if (!result.length) {
      return this.fetchMessagesPriceForCountries()
    }
    return result
  }
}

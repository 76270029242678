// eslint-disable-next-line tp/forbid-import-composable-to-service,tp/using-vue-in-services-restriction
import type { Component } from '@/composition/vue/compositionApi'
import type { ModelRaw, ParamsPartial } from '@/types'
import type { RegisteredServices } from '@/core/container/types'
import type BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import type { PaginationUrlFilterType } from '@/services/tables/types'
import { isRecordUnknown } from '@/utils/typeGuards'
import type { Resolvers } from '@/services/types'
import type { ModalDialogConfig } from '@/components/shared/modals/tmModal/types'
import type { AppModule } from '@/config/types'
import type { IconName } from '@/assets/icons/icons'

export interface WrapperServiceInterface<T extends BaseWrapper = BaseWrapper> {
  build(wrapperId: string, wrapperType?: WrapperTypesServicesKeys, model?: typeof BaseWrapper): any
  completeBuilds?: { (): void }
  getWrapperData(wrapperId: string): ModelRaw<T>
  setParams(wrapperId: string, params?: WrapperParams): void
  getParams(wrapperId: string): WrapperParams
  patchParams(wrapperId: string, params?: WrapperParams): WrapperParams
  removeParams(wrapperId: string, paramsToDelete: string[]): void
  clearParams(wrapperId: string): void
  destroy(wrapperId: string): void
  isExist(wrapperId: string): boolean
}

export interface WrapperOpenableInterface<T extends BaseWrapper = BaseWrapper> extends WrapperServiceInterface<T> {
  open(wrapperId: string, params?: WrapperParams): void
  close(wrapperId: string): void
  isOpen(wrapperId: string): boolean
}

export interface WrapperManagerInterface<T extends BaseWrapper = BaseWrapper> extends WrapperServiceInterface<T> {
  open(wrapperId: string, params?: WrapperParams): void
  close(wrapperId: string): void
  isOpened(wrapperId: string): boolean
}

export interface GroupedWrapperInterface<B extends ParamsPartial> {
  closeGroup<T extends ParamsPartial>(parts: string[], config: PartialUIConfig<T>): void
  openGroup<T extends ParamsPartial>(parts: string[], config: PartialUIConfig<T>): void
  destroyGroup<T extends ParamsPartial>(config: PartialUIConfig<T>): void
  buildGroup(config: PartialUIConfig<B>): void
  openedInGroup(config: PartialUIConfig<B>): Record<string, boolean>
  isExistGroup(config: PartialUIConfig<B>): boolean
}

export type WrapperServices = Record<RegisteredServices, WrapperServiceInterface>
export type WrapperParams = Record<string, any>
export type BulkQuery = {
  selectedIds: string[]
  isAllSelected: boolean
}
export type BulkWrapperParams = BulkQuery & {
  filters: PaginationUrlFilterType
  tableId: string
  total: number
}

enum wrapperTypesServices {
  wrapper,
  modal,
  newQueryModal,
  partial,
  queryPartial,
  groupPartial,
  queryGroupPartial,
  table,
  queryTable,
  lsTable,
  section,
}
export type WrapperTypesServicesKeys = keyof typeof wrapperTypesServices

export const WrapperTypesServices: Record<WrapperTypesServicesKeys, RegisteredServices> = {
  wrapper: 'BaseWrapperService',
  modal: 'ModalService',
  newQueryModal: 'NewQueryModalService',
  partial: 'PartialUIWrapperService',
  queryPartial: 'PartialQueryUIWrapperService',
  groupPartial: 'GroupedPartialUIWrapperService',
  queryGroupPartial: 'GroupedPartialUIWrapperService',
  table: 'TableWrapperService',
  queryTable: 'TableQueryWrapperService',
  lsTable: 'TableLocalStorageWrapperService',
  section: 'SectionService',
}

export enum Wrappers {
  confirmation,
  confirmationDelete,
  confirmationPassword,
  prompt,
  export,

  contact,
  selectingContacts,
  selectingContactsProgress,
  selectingContactsLists,
  selectingSegments,
  customFieldsSchemaModal,
  contactListMoveCopyModal,
  addNewPinnedContactsModal,

  chooseAudioModal,

  smsSurveys,
  smsSurveyRecipients,
  editSmsSurveyName,
  scheduleSmsSurvey,
  previewSmsSurvey,
  smsSurveyQuestion,
  smsSurveyQuestionDelay,
  smsSurveyReply,
  smsSurveyFinalMessage,

  phoneNumberLabel,
  apiV2KeysCreateModal,
  keywordsAddModal,

  templates,
  templateShare,
  templateSelect,
  templatesEditCategory,
  templateCategory,
  templateCategoryDelete,

  contactList,
  contactListDeleteModal,
  contactListBulkDeleteModal,
  contactListTempDetails,
  UnsubscribeContactModal,
  blockedContact,
  contactTagsBulkModal,

  segmentCreateModal,
  segmentDeleteModal,
  segmentRenameModal,
  segmentSaveAsModal,

  accountDetails,
  billingDetailsModal,
  sentSmsBulkDetailsModal,
  senderSettingsCountriesEditDefaultSenderModal,
  phoneVerificationModal,
  inviteSubAccountModal,
  switchSubAccountModal,
  resendInvitationSubAccountModal,
  scheduledRetryModal,

  subAccountDetails,
  closeAccountModal,
  closeSubAccountModal,

  receivedSmsFilterModal,
  sentSmsFilterModal,

  messageDetailsModal,
  contactNotesModal,
  chatsNotificationsModal,
  chatConversationSenderSettingsModal,
  chatFiltersSet,

  allContactListModal,
  previewModal,

  senderIdApplyModal,

  allowedEmailsAddModal,
  allowedEmailsEditSettingsModal,

  messagesAttachmentFileModal,
  messagesAttachmentFilePreviewModal,
  messagesAttachmentSelectModal,

  calendarModal,
  calendarGroupModal,
  scheduledMessageModal,
  addContactsChooseSourceModal,

  composePreviewModal,
  composePhonePreviewModal,

  importMatchingFullscreenModal,
  importMatchingCountryModal,
  twoFaEditIpAddressesModal,
  passwordResetModal,
  passwordChangeModal,

  auditLogsEventSummaryModal,
  subAccountsImportDetailsFailedModal,
  accountSsoDetailsModal,
  accountSecurityLoginsHistoryModal,
  apiV1KeyModal,
  apiV1UrlTestModal,
  apiV2UrlTestModal,

  carrierLookupDetailsModal,
  emailLookupDetailsModal,

  tenDlcSwitchTollFreeModal,
  tenDlcTollFreeConsentDisclosureModal,
  tenDlcSelectCampaignDetailsModal,
  billingAutoRechargeSettingsEditModal,
  billingConfirmPlanDowngradeModal,
  billingConfirmDowngradeToPayAsYouGoModal,
  tenDlcBrandDetailsModal,
  tenDlcLinkNumberModal,
  tollFreeFormModal,
  paymentPricingCalculatorModal,
  byocPhoneLabelModal,
  byocCarrierLabelModal,
  byocCarrierLinkModal,
  byocCredentialsModal,
  byocCredentialsSinchModal,
  byocSinchCallbackModal,
  messagesBulksProgressModal,
  contactImportResultModal,
  emailVerificationModal,
  passwordVerificationModal,
  accountBalanceCriticallyLowModal,
  editVerifyingEmailModal,
  upgradeAccountModal,
  subscribeCreateModal,
  subscribeRenameModal,

  // Section
  monthlyPlansSection,
  pinnedContactsSection,

  // Kanban
  previewKanbanBoardTemplateModal,

  // Tasks
  createTaskBoardTemplateModal,
  selectTaskBoardTemplateModal,
  taskStageModal,
  createTaskModal,
  taskViewModal,
  taskMoveAllModal,
  taskBoardsArchiveModal,
  taskStageDeleteModal,
  taskBoardsMenuSection,
  taskChecklistNameEdit,
  taskChecklistItemEdit,
  taskViewModalTopBarDueDateModal,
  taskBoardKanbanSection,

  contactsNavigationOrderSection,
  selectYourTeamPlanModal,
  closeUserAccountModal,

  // Messages -> Settings
  webWidgetModal,
  copyWebWidgetModal,
  sendWidgetInstructionsModal,

  billingBuyPrepaidCreditModal,
  stripeBillingFetchPaygPrices,

  whatsAppViewQrCodeModal,
  tenDlcTollFreeOptInScreenshotExampleModal,
  chatAssignModal,
  chatRejectModal,
  chatAddNoteModal,
  chatBlockIPConfirmation,
  chatRatingModal,
  tenDlcOptInFlowDescriptionRequirementsModal,

  // tickets
  ticketCreateModal,
  ticketCreateWorkspaceModal,
  ticketAssignModal,
  ticketFetchInboxes,
  ticketFetchInboxesAndFv,
  ticketFetchSpamFilter,
  ticketsForwardingInstructionsModal,
  ticketSaveViewAsModal,
  TicketFilteredViewPreviewModal,
  ticketMarkAsSpamModal,
  ticketMarkAsNotSpamModal,
  ticketsFetchWorkspace,
  ticketsEditorModal,
  ticketBulkMarkAsSpamModal,
  ticketBulkAssignModal,

  templatesMenu,
  plansCardConnectModal,
  plansChangeWithComparisonTableModal,
  plansUpgradeModal,
  contactDeleteModal,

  // deals
  dealsPipelinesMenuSection,
  selectDealPipelineTemplateModal,
  createOrUpdateDealPipelineTemplateModal,
  archiveDealPipelineModal,
  pipelineDefaultOwnerModal,
  dealsPipelineKanbanSection,
  dealMoveAllModal,
  dealCustomStageModal,
  dealSystemStageModal,
  dealCardAddContactModal,
  dealTransferOwnershipModal,
  createDealModal,
  dealCustomStageDeleteModal,
  dealViewModal,
  dealTimelineSection,
  dealActivityItemEdit,
  unsubscribeContactWithEmailModal,

  // facebook
  facebookQrModal,

  // campaigns
  campaignsSendersVerifyDomainSendModal,
  campaignsRenameModal,
  campaignsEmailSenderModal,
  campaignsEmailSettingsDnsModal,

  // point ai
  pointAiAttachmentModal,
  pointAiAttachmentModalWebsite,
  pointAiAttachmentModalDocument,
}

export type WrapperConfigItemBase = {
  component?: Component
  resolvers?: Resolvers
  type: WrapperTypesServicesKeys
  modules?: AppModule[]
}
export type WrapperConfigItemModal = Required<Pick<WrapperConfigItemBase, 'component'>> &
  Omit<WrapperConfigItemBase, 'component' | 'type'> & {
    modalConfig: ModalDialogConfig
    type: Extract<WrapperTypesServicesKeys, 'modal'>
  }

export type WrapperConfigItem = WrapperConfigItemBase | WrapperConfigItemModal
export type WrapperConfig<T extends string> = Record<T, WrapperConfigItem>
export type PartialUIConfigItem<T extends ParamsPartial> = {
  name: string
  isInitiallyOpen?: boolean
  required?: boolean
  icon?: IconName
  params: T
}
export type PartialUIConfig<T extends ParamsPartial> = {
  name: string
  type: WrapperTypesServicesKeys
  items: Array<PartialUIConfigItem<T>>
}

export type ModalSize = 'small' | 'medium' | 'large' | 'xLarge' | 'full'

export type ModalsInQuery = {
  id: string
  params: WrapperParams
}
export const isArrayOfModalsInQuery = (raw: unknown): raw is ModalsInQuery[] =>
  !!(Array.isArray(raw) && raw.every(isModalsInQuery))
export const isModalsInQuery = (raw: unknown): raw is ModalsInQuery =>
  !!(
    (isRecordUnknown(raw) && raw.id && typeof raw.id === 'string')
    // &&
    // raw.params &&
    // isRecordUnknown(raw.params)
  )

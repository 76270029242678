import { inject, injectable } from 'inversify'
import type { AxiosResponse } from 'axios'
import type { Item } from '@vuex-orm/core'
import type { CountryCode } from 'libphonenumber-js'
import User from '@/data/models/domain/User'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { Endpoint, EndpointParams } from '@/services/endpoints'
import type { HttpService } from '@/services/transport/httpService'
import { SERVICE_TYPES } from '@/core/container/types'
import type SerializerService from '@/services/serializerService'
import type { AbstractEndpointsInterface } from '@/services/endpointsService'
import type LoggerService from '@/services/loggerService'
import type {
  SignupApiPostPayload,
  LoginBody,
  LoginRequestBody,
  RawTokens,
  RegistrationConfirmationResponse,
  SignUpViaOAuthPayload,
  UserVat,
  VerificationEmailResendPayload,
  LoginSSOPayload,
  LoginSSOResponse,
  SignupByInvitePayload,
  SignupByInviteSSOPayload,
} from '@/services/auth/types'
import type { ConfirmationResponse, CreatedEntityResponse } from '@/services/transport/types'
import type FilterQueryService from '@/services/filterQueryService'
import type {
  AvailableSignUpCountries,
  CurrentUserResponse,
  FetchSubAccountsWithPaginationRequestBody,
  InviteSubAccountBody,
  MyDataFacet,
  OAuthProvider,
  SubAccountInvitationBody,
  SubaccountsFacet,
  UserDTO,
  UserPutBody,
  UserRefusalSurveyParams,
  UsersFacet,
} from '@/services/domain/user/types'
import { UserRole } from '@/services/domain/user/types'
import { promisifyVoid } from '@/utils/promisifyVoid'
import type { BulkDeleteParams } from '@/services/bulk/types'
import type { MessageRetentionPeriod, MessageRetentionPeriodValue } from '@/services/domain/accounts/types'
import type { Dict, ModelRaw } from '@/types'
import type {
  CheckSSOInvitationTokenPayload,
  SignUpViaOAuthResponse,
  ValidatePasswordResetTokenResponse,
} from '@/data/repositories/domain/users/types'
import ModelMapper from '@/data/utils/modelMapper'
import type { SubAccountFetchFilter } from '@/services/domain/accounts/subAccounts/types'
import type UserAnalyticsFieldsRepository from '@/data/repositories/domain/users/userAnalyticsFieldsRepository'
import type UserAnalyticsTraitsRepository from '@/data/repositories/domain/users/userAnalyticsTraitsRepository'
import type { ModelRelationArray } from '@/services/domain/types'
import type {
  BasePaginationUrlParams,
  PaginationOffsetResponse,
  PaginationParams,
} from '@/services/tables/pagination/types'
import type { PaginationUrlType } from '@/services/tables/types'
import { axiosCreateError } from '@/utils/axiosCreateError'
import type WindowService from '@/services/browser/windowService'
import type { ExportBody } from '@/services/types'
import { TmIncorrectContractError } from '@/core/error/tmIncorrectContractError'
import type UserSettingsRepository from '@/data/repositories/domain/userSettingsRepository'
import type TeamSubscription from '@/data/models/domain/TeamSubscription'
import type { LowLevelUpdateBody } from '@/services/vuex/types'

const oAuthProviderLinkEndpoints: Record<OAuthProvider, Endpoint> = {
  google: 'googleAuthLink',
  apple: 'appleAuthLink',
  microsoft: 'microsoftAuthLink',
}

const oAuthProviderEndpoints: Record<OAuthProvider, Endpoint> = {
  google: 'googleAuth',
  apple: 'appleAuth',
  microsoft: 'microsoftAuth',
}

@RepoSettings<Endpoint>({
  model: User,
  fetch: 'subaccounts',
  single: 'subaccount',
  exportEndpoint: 'subAccountExport',
})
@injectable()
export default class UserRepository extends OrmApiRepository<User> {
  private currentUserId: string

  constructor(
    @inject(SERVICE_TYPES.SerializerService) protected readonly serializerService: SerializerService,
    @inject(SERVICE_TYPES.Api) protected readonly api: HttpService,
    @inject(SERVICE_TYPES.EndpointsService) protected readonly endpointsService: AbstractEndpointsInterface,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.FilterQueryService) protected readonly filterQueryService: FilterQueryService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.UserAnalyticsFieldsRepository)
    protected readonly userAnalyticsFieldsRepository: UserAnalyticsFieldsRepository,
    @inject(SERVICE_TYPES.UserAnalyticsTraitsRepository)
    protected readonly userAnalyticsTraitsRepository: UserAnalyticsTraitsRepository,
    @inject(SERVICE_TYPES.UserSettingsRepository) protected readonly userSettingsRepository: UserSettingsRepository,
  ) {
    super(serializerService, api, endpointsService, loggerService, filterQueryService)
  }

  public getFilteredUsers(filteredUsers: string[]) {
    return this.query()
      .where((user: User) => filteredUsers.includes(user.id))
      .get() as User[]
  }

  public login(body: LoginRequestBody, recaptchaResponse?: string) {
    const loginBody: LoginBody = {
      extendedTfa: true,
      appName: APP_NAME,
      ...body,
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const enrichedBody = recaptchaResponse ? { ...loginBody, 'g-recaptcha-response': recaptchaResponse } : loginBody

    const path = this.endpointsService.getPath('login')
    return this.api.post<RawTokens, LoginBody>(path, enrichedBody)
  }

  public logout() {
    const path = this.endpointsService.getPath('authLogout')
    this.currentUserId = ''
    return this.api.post(path)
  }

  public async resendEmailCode(tfaToken: string) {
    return this.api.get<ConfirmationResponse>(this.endpointsService.getPath('resendEmailCode', [tfaToken]))
  }

  public async resendSmsCode(tfaToken: string) {
    const path = this.endpointsService.getPath('resendPhoneCode', [tfaToken])
    return this.api.get<ConfirmationResponse>(path, {
      params: {
        // @todo: CAR-10096, remove extendedTfa
        extendedTfa: true,
      },
    })
  }

  public async validatePasswordResetToken(token: string) {
    const path = this.endpointsService.getPath('passwordResetValidateToken')
    const response = await this.api.post<ValidatePasswordResetTokenResponse>(path, { token })
    return response.data.email
  }

  public signup(payload: SignupApiPostPayload) {
    return this.api.post<{ confirmationEmail: string; userId: string }>(this.endpointsService.getPath('registration'), {
      ...payload,
    })
  }

  public signupByInvite(payload: SignupByInvitePayload | SignupByInviteSSOPayload) {
    return this.api.post(this.endpointsService.getPath('registrationByInvite'), { ...payload })
  }

  public signupByOAuth(payload: SignUpViaOAuthPayload) {
    return this.api.post(this.endpointsService.getPath('registrationByOAuth'), { ...payload })
  }

  public async updateCurrentUserInStore(user: Partial<UserDTO> & { isImpersonated?: boolean }): Promise<User> {
    await this.insertOrUpdate([{ id: this.currentUserId, ...user }] as LowLevelUpdateBody<User>[])
    return this.find(this.currentUserId)
  }

  public getCurrentUser(withRelations: ModelRelationArray<User> = []): Item<User> | null {
    return this.query().with(withRelations).find(this.currentUserId)
  }

  public getCurrentUserCountryId(): CountryCode {
    return this.getRaw(this.currentUserId).countryId
  }

  private getCurrentUserAdapter = async (config) => {
    const getCurrentUser: () => Promise<Response> = (this.windowService.self() as any).__getCurrentUser
    const response = await getCurrentUser()
    const data = await response.json()
    const validateStatus = config.validateStatus
    const headers: Record<string, string> = {}
    response.headers.forEach((value, key) => {
      headers[key] = value
    })
    if (!response.status || !validateStatus || validateStatus(response.status)) {
      const res: AxiosResponse = {
        data,
        status: response.status,
        statusText: response.statusText,
        headers,
        config,
      }
      return res
    }
    throw axiosCreateError(
      data?.message ?? `Request failed with status code ${response.status}`,
      config,
      '',
      {},
      response,
    )
  }

  public async fillCurrentUser(isCheckPrefetchData = false) {
    const path = this.endpointsService.getPath('currentUser')
    const adapter = isCheckPrefetchData ? this.getCurrentUserAdapter : undefined
    const response = await this.api.get<CurrentUserResponse>(path, { adapter })
    this.checkCurrentUser(response.data)
    this.currentUserId = response.data.id
    response.data.subscriptions = response.data.subscriptions.map((t) => {
      return {
        ...t,
        id: t.name,
      }
    }) as TeamSubscription[]
    this.userSettingsRepository.insertOrUpdate([response.data])
    this.userAnalyticsFieldsRepository.storeAnalyticsFields(response.data.analyticsFields)
    this.userAnalyticsTraitsRepository.storeAnalyticsTraits(response.data)
    return this.updateCurrentUserInStore(response.data)
  }

  public updateCurrentUser(body: UserPutBody, deviceIdentifier: string) {
    return this.api.put(this.endpointsService.getPath('currentUser'), {
      ...body,
      deviceIdentifier,
    })
  }

  public async resendVerification(payload: VerificationEmailResendPayload) {
    return this.api.post<{ availableToResendAt: string }>(this.endpointsService.getPath('registrationEmailResend'), {
      ...payload,
    })
  }

  public async sendVerifyCodeOnEmail() {
    await this.api.get(this.endpointsService.getPath('sendVerifyCodeOnEmail'))
  }

  public async sendVerifyCodeOnPhone() {
    await this.api.post(this.endpointsService.getPath('sendVerifyCodeOnPhone'), {
      // @todo: CAR-10096, remove extendedTfa
      extendedTfa: true,
    })
  }

  public async verifyPhoneByCode(userId: string, confirmationCode: string) {
    await this.api.post(this.endpointsService.getPath('checkVerifyCodeOnPhone'), {
      confirmationCode,
      // @todo: CAR-10096, remove extendedTfa
      extendedTfa: true,
    })
    return this.find(userId).$update({
      phoneAccepted: true,
    })
  }

  public async requestResetPassword(email: string, recaptchaResponse?: string) {
    const path = this.endpointsService.getPath('resetPasswordByEmail')
    const data = {
      email,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'g-recaptcha-response': recaptchaResponse,
    }
    await this.api.post(path, data)
  }

  public async resetPassword(token: string, password: string) {
    const path = this.endpointsService.getPath('passwordResetSetPassword')
    const payload = {
      token,
      newPassword: password,
    }
    await this.api.post(path, payload)
  }

  public resetPasswordByUserId(userId: string) {
    return promisifyVoid(this.api.post(this.endpointsService.getPath('accountsResetPasswordById', [userId])))
  }

  public acceptPrivacyPolicy() {
    return this.api.post(this.endpointsService.getPath('userAcceptPrivacyPolicy'))
  }

  public changePassword(oldPassword: string, newPassword: string) {
    return promisifyVoid(
      this.api.put(this.endpointsService.getPath('changePassword'), {
        oldPassword,
        newPassword,
      }),
    )
  }

  public verifyPasswordCode(confirmationCode: string, deviceIdentifier: string) {
    const path = this.endpointsService.getPath('userPasswordVerify')
    return this.api.post(path, { confirmationCode, deviceIdentifier })
  }

  public resendPasswordVerificationCode() {
    const path = this.endpointsService.getPath('userPasswordResendCode')
    return this.api.post(path)
  }

  public verifyEmailCode(confirmationCode: string, deviceIdentifier: string) {
    const path = this.endpointsService.getPath('userEmailVerify')
    return this.api.post(path, { confirmationCode, deviceIdentifier })
  }

  public resendEmailVerificationCode() {
    const path = this.endpointsService.getPath('userEmailResendCode')
    return this.api.post(path)
  }

  public async fetchMyDataFacets(endpointParams: EndpointParams) {
    const facetResult = await this.api.get<MyDataFacet>(this.endpointsService.getPath('myDataFacets', endpointParams))
    return facetResult.data
  }

  public async getSubAccountsFacets(includeParent?: boolean) {
    const path = this.endpointsService.getPath('subaccountsFacet')
    const facetResult = await this.api.get<SubaccountsFacet>(path, {
      params: {
        includeParent,
      },
    })

    return facetResult.data
  }

  public async getUsersFacets(includeParent?: boolean) {
    const path = this.endpointsService.getPath('usersFacet')
    const facetResult = await this.api.get<UsersFacet>(path, {
      params: {
        includeParent,
      },
    })

    return facetResult.data
  }

  public async fetchSubAccounts(
    filter: SubAccountFetchFilter = { includeSelf: true, includeParent: true },
  ): Promise<ModelRaw<User>[]> {
    const { items } = await this.doGrid('subaccountsAll', [], {
      filter: [],
      sort: {
        subaccount: {
          name: 'asc',
        },
      },
      other: filter,
    })

    const normalizedItems = ModelMapper.normalizePayload(items, this.model())

    await this.insertOrUpdate(normalizedItems)
    return normalizedItems
  }

  public getSubAccounts(filter: SubAccountFetchFilter, userId: string): ModelRaw<User>[] {
    const query = this.query()
    if (filter.statuses?.length) {
      query.where('status', filter.statuses)
    }
    if (!filter.includeParent) {
      query.where('subaccountType', (value) => value !== UserRole.SUPER_ADMIN)
    }
    if (!filter.includeSelf) {
      query.where((subAccount) => subAccount.id !== userId)
    }
    if (!filter.includeRemoved) {
      query.where((subAccount) => !subAccount.isRemoved)
    }
    return query.get()
  }

  public async checkSignUpAvailability() {
    const signUpAvailability = await this.api.get<AvailableSignUpCountries>(
      this.endpointsService.getPath('registrationAllow'),
    )

    return signUpAvailability.data
  }

  public checkEmailForSignupAvailability(email: string, recaptchaResponse?: string) {
    return this.api.post(this.endpointsService.getPath('registrationEmailIsAvailable'), {
      email,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'g-recaptcha-response': recaptchaResponse,
    })
  }

  public async confirmRegistrationCodeFromEmail(input: { code: string; email: string }) {
    const { data } = await this.api.post<{ countryId: string }>(
      this.endpointsService.getPath('registrationConfirmCodeFromEmail'),
      input,
    )

    return data
  }

  public checkEmailCodeResendAvailability(email: string, newEmail?: string) {
    return this.api.post(this.endpointsService.getPath('registrationResendAvailability'), { email, newEmail })
  }

  public async completeRegistration(token: string): Promise<RegistrationConfirmationResponse> {
    const { data } = await this.api.post<RegistrationConfirmationResponse>(
      this.endpointsService.getPath('registrationConfirm'),
      { token },
    )
    return data
  }

  public async getAuthLink(provider: OAuthProvider) {
    const endpoint = oAuthProviderLinkEndpoints[provider]
    const url = this.endpointsService.getPath(endpoint)
    const response = await this.api.get<{ authUrl: string }>(url)
    return response.data.authUrl
  }

  public async getSSOLoginLink(payload: LoginSSOPayload) {
    const {
      data: { authUrl },
    } = await this.api.post<LoginSSOResponse, LoginSSOPayload>(this.endpointsService.getPath('ssoAuthUrl'), {
      ...payload,
    })

    return authUrl
  }

  public async loginByOAuthProvider(provider: OAuthProvider, params: Dict) {
    const endpoint = oAuthProviderEndpoints[provider]
    const url = this.endpointsService.getPath(endpoint)
    const requestParams = {
      ...params,
      appName: APP_NAME,
      extendedTfa: true,
    }
    return this.api.post<RawTokens | SignUpViaOAuthResponse, Dict>(url, requestParams)
  }

  public async getVatNumber() {
    const response = await this.api.get<UserVat>(this.endpointsService.getPath('userVatNumber'))
    return response.data.vatNumber
  }

  public updateVatNumber(vatNumber: string) {
    return this.api.post(this.endpointsService.getPath('userVatNumber'), { vatNumber })
  }

  public async inviteSubAccount(body: InviteSubAccountBody) {
    return this.api.post(this.endpointsService.getPath('inviteSubAccount'), body) as Promise<CreatedEntityResponse>
  }

  public async resendInvitationSubAccount(data: SubAccountInvitationBody) {
    return this.api.post(this.endpointsService.getPath('reinviteSubAccount'), data) as Promise<CreatedEntityResponse>
  }

  public async closeAccount(params: UserRefusalSurveyParams) {
    await this.getApiSource().post(this.endpointsService.getPath('closeAccount'), params)
  }

  public async closeSubAccounts(accountIds: string[], all = false) {
    const payload: BulkDeleteParams = {
      ids: accountIds,
      all,
    }
    await this.getApiSource().post(this.endpointsService.getPath('closeSubAccount'), payload)
  }

  public async getMessagesRetentionPeriod() {
    const response = await this.api.get<MessageRetentionPeriod>(
      this.endpointsService.getPath('accountMessageRetentionPeriod'),
    )
    return response.data.dataMessagesLifetime
  }

  public updateMessagesRetentionPeriod(value: MessageRetentionPeriodValue) {
    const payload: MessageRetentionPeriod = { dataMessagesLifetime: value }
    return this.api.put(this.endpointsService.getPath('accountMessageRetentionPeriod'), payload)
  }

  public deleteAllMessages(password: string) {
    return this.api.delete(this.endpointsService.getPath('myDataDeleteAllMessages'), { data: { password } })
  }

  public deleteAllContacts(password: string) {
    return this.api.delete(this.endpointsService.getPath('myDataDeleteAllContacts'), { data: { password } })
  }

  public async openSubAccount(accountId: string) {
    const path = this.endpointsService.getPath('subAccountReopen', [accountId])
    return this.getApiSource().post(path)
  }

  public async fetchVerifyEmail(token: string) {
    const path = this.endpointsService.getPath('verifyEmailVerify')
    await this.api.post(path, { token })
    await this.updateCurrentUserInStore({ emailAccepted: true })
  }

  public runImpersonateMode(userId: string) {
    const path = this.endpointsService.getPath('runImpersonate', [userId])
    return this.api.post(path)
  }

  public stopImpersonateMode() {
    const path = this.endpointsService.getPath('stopImpersonate')
    return this.api.post(path)
  }

  public async checkInvitationToken(token: string) {
    try {
      const result = await this.api.post(this.endpointsService.getPath('registrationByInviteTokenCheck'), { token })
      return result.status === 204
    } catch {
      return false
    }
  }

  public fetchSubAccountsWithPagination(
    pagination: BasePaginationUrlParams,
    queryParameterBag: FetchSubAccountsWithPaginationRequestBody,
    searchQuery = '',
  ) {
    return this.makeCancelableRequest(async (cancelToken) => {
      const res = await this.gridRequest(
        {
          filter: [],
          ...queryParameterBag,
        },
        {
          pageKey: '',
          requestParams: pagination,
        },
        searchQuery,
        undefined,
        cancelToken,
      )
      await this.insertOrUpdate(res.items)
      return res
    })
  }

  public adminsGridRequest<T>(
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
    searchFields?: string[],
  ): Promise<PaginationOffsetResponse<T>> {
    return this.doGrid<T>(
      'userAdmins',
      this.getFetchEndpointParams(),
      queryParameterBag,
      paginationParamsBag,
      searchQuery,
      searchFields,
    )
  }

  public async cancelPhoneVerification() {
    const path = this.endpointsService.getPath('userVerifyPhoneCancel')
    return this.api.delete(path)
  }

  public exportMyData(params: ExportBody, endpointParams?: EndpointParams) {
    const url = this.endpointsService.getPath('myDataExport')
    return this.exportRequest(params, endpointParams, url)
  }

  public async getSSOInvitationDataByToken(token: string) {
    const path = this.endpointsService.getPath('registrationBySSOInvitationTokenCheck')
    const response = await this.api.post<CheckSSOInvitationTokenPayload>(path, { token })
    return response.data
  }

  public completeSsoInvitation(token: string, firstName: string, lastName: string) {
    return this.api.post(this.endpointsService.getPath('registrationBySSOInvitation'), { token, firstName, lastName })
  }

  protected checkCurrentUser(user: CurrentUserResponse) {
    if (!user) throw new TmIncorrectContractError('User not found')
    if (!user.timezone) throw new TmIncorrectContractError("User's timezone not found")
  }

  public async getAssigneeList(lastId: string, perPage: number, searchQuery?: string) {
    const res = await this.api.get<PaginationOffsetResponse<ModelRaw<User>>>(
      this.endpointsService.getPath('assigneeFetch'),
      {
        params: {
          lastId,
          perPage,
          searchQuery,
        },
      },
    )
    await this.insertOrUpdate(res.data.items)
    return res.data
  }
}

import type { PropType } from '@/composition/vue/compositionApi'
import type { FieldType } from '@/services/forms/types'
import type { ValidationRule, ValidationRuleErrorWithRuleName } from '@/services/validation/types'
import type { Dict } from '@/types'
import type { IconName } from '@/assets/icons/icons'

export const fieldProps = {
  name: {
    type: String,
  },
  id: {
    type: String,
  },
  label: {
    type: String,
  },
  type: {
    type: String as PropType<FieldType>,
    default: 'text',
  },
  placeholder: {
    type: String,
  },

  validators: {
    type: Array as PropType<ValidationRule[]>,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array as PropType<ValidationRuleErrorWithRuleName[]>,
    default: () => [],
  },
  serverErrors: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  subfieldServerErrors: {
    type: Object as PropType<Dict<string[]>>,
    default: () => {},
  },
  /**
   * @deprecated Should not be used, please consider messagePath param in validation config instead
   */
  errorCustomMessage: {
    type: String,
  },
  hint: {
    type: String,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  valid: {
    type: Boolean,
    default: false,
  },
  validationRulesResults: {
    type: Object,
    default: () => ({}),
  },

  hideDetails: {
    type: Boolean,
    default: false,
  },
  highlightValid: {
    type: Boolean,
    default: false,
  },
  darkPlaceholder: {
    type: Boolean,
    default: false,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
  whiteBackground: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  showClear: {
    type: Boolean,
    default: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showTooltip: {
    type: Boolean,
    default: false,
  },
  flat: {
    type: Boolean,
  },
  tabIndex: {
    type: String,
  },
}

export type TpSelectOptionConfig = {
  avatar?: string
  icon?: IconName
  color?: string
  title: string
  subtitle?: string
}

export enum ErrorPositions {
  INSIDE = 'inside',
  OUTSIDE = 'outside',
}

import { isEmptyArray, isNullOrUndefined } from '@/services/validation/defaultValidators/utils'
import FieldArray from '@/data/models/forms/FieldArray'

const requiredValidator = (value: unknown) => {
  if (value instanceof FieldArray) {
    return value.children.length !== 0
  }

  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false) {
    return false
  }

  return !!String(value).trim().length
}

export default requiredValidator

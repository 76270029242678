import { inject, injectable } from 'inversify'
import type { RouteLocationRaw, RouteRecordRaw } from 'vue-router'
import { type RegisteredServices, SERVICE_TYPES } from '@/core/container/types'
import type { TmPermissions, RouterPermissionInterface, IPermissionsService } from '@/services/types'
import type RouterService from '@/services/route/routerService'
import TmLogicError from '@/core/error/tmLogicError'
import type AccessControlService from '@/services/accessControl/accessControlService'

@injectable()
export default class RouterPermissionsService implements RouterPermissionInterface {
  constructor(
    @inject(SERVICE_TYPES.PermissionsService) private readonly permissionsService: IPermissionsService,
    @inject(SERVICE_TYPES.RouterService) private readonly routerService: RouterService,
    @inject(SERVICE_TYPES.AccessControlService) private readonly accessControlService: AccessControlService,
  ) {
    this.fillPermissionsFromRoutes(routerService.getRoutes())
    this.fillAccessControlFromRoutes(routerService.getRoutes())
  }

  private routesPermissions: Record<string, TmPermissions[]> = {}

  private routesAccessControlGroups: Record<string, RegisteredServices[]> = {}

  private isAllowAccessToRouteByAccessGroups(accessControlGroups: RegisteredServices[]) {
    return accessControlGroups.every((registerService) => {
      return this.accessControlService.isAccessAllowed(registerService)
    })
  }

  private getRouteName(route: RouteLocationRaw) {
    if (typeof route === 'string') {
      return route
    }
    return this.routerService.getRawRouteName(route)
  }

  public async resolveStrategiesForRoute(route: RouteLocationRaw) {
    const routeName = this.getRouteName(route)
    if (!routeName) {
      return
    }
    const waitGenerator = async (generator: Generator<RegisteredServices[], boolean, unknown>) => {
      const val = generator.next()
      if (val.done) {
        return
      }
      await this.accessControlService.resolveStrategies(val.value)
      await waitGenerator(generator)
    }
    const generator = this.isAllowAccessToRouteByName(routeName)
    await waitGenerator(generator)
  }

  private *isAllowAccessToRouteByName(routeName: string): Generator<RegisteredServices[], boolean, unknown> {
    if (!this.checkPermissionsByRouteByName(routeName)) {
      return false
    }
    const accessControlGroups = this.routesAccessControlGroups[routeName]
    const preparedAccessControlGroups = accessControlGroups ?? []
    yield preparedAccessControlGroups
    if (!this.isAllowAccessToRouteByAccessGroups(preparedAccessControlGroups)) {
      return false
    }
    const route = this.routerService.getRouteByName(routeName)
    if (!(route.children && route.children.length !== 0) || route.meta?.redirectToFirstChildren === false) {
      return true
    }

    let childrenNamesList = route.children.map((t) => this.routerService.getOriginRoute(t).name)
    const redirect = this.getRedirectForRoute(route)
    if (redirect && redirect.name) {
      childrenNamesList = childrenNamesList.filter((t) => t !== redirect.name)
      childrenNamesList.unshift(redirect.name)
    }

    for (let i = 0; i < childrenNamesList.length; i++) {
      const name = childrenNamesList[i]
      if (typeof name !== 'string') {
        throw new TmLogicError(`route name should be type "string". Route name type "${typeof name}"`)
      }
      let isDone = false
      let isAllowAccess = false
      const generator = this.isAllowAccessToRouteByName(name)
      while (!isDone) {
        const nextVal = generator.next()
        if (nextVal.done) {
          isDone = true
          isAllowAccess = nextVal.value
        } else {
          yield nextVal.value
        }
      }
      if (isAllowAccess) {
        return true
      }
    }
    return false
  }

  public isAllowAccessToRoute(route: RouteLocationRaw) {
    const routeName = this.getRouteName(route)
    if (!routeName) {
      return true
    }
    const generator = this.isAllowAccessToRouteByName(routeName)
    while (true) {
      const nextVal = generator.next()
      if (nextVal.done) {
        return nextVal.value
      }
    }
  }

  public checkPermissionsByRouteByName(routeName: string) {
    const routesPermission: TmPermissions[] = this.routesPermissions[routeName] ?? []
    if (routesPermission.length === 0) {
      return true
    }
    return routesPermission.every((t) => this.permissionsService.isActivePermission(t))
  }

  private fillPermissionsFromRoutes(routes: RouteRecordRaw[], initPermissions: TmPermissions[] = []) {
    routes.forEach((route) => {
      const permissions = [...initPermissions]
      if (route.meta?.permission) {
        permissions.push(route.meta.permission)
      }
      if (typeof route.name === 'string' && permissions.length !== 0) {
        this.routesPermissions[route.name] = permissions
      }
      if (route.children && route.children.length) {
        this.fillPermissionsFromRoutes(route.children, permissions)
      }
    })
  }

  private fillAccessControlFromRoutes(routes: RouteRecordRaw[], initAccessControlGroups: RegisteredServices[] = []) {
    routes.forEach((route) => {
      const accessControlGroups = [...initAccessControlGroups]
      if (route.meta?.accessControlGroups) {
        accessControlGroups.push(...route.meta.accessControlGroups)
      }
      if (typeof route.name === 'string' && accessControlGroups.length !== 0) {
        this.routesAccessControlGroups[route.name] = accessControlGroups
      }
      if (route.children && route.children.length) {
        this.fillAccessControlFromRoutes(route.children, accessControlGroups)
      }
    })
  }

  private getRedirectForRoute(route: RouteRecordRaw): RouteRecordRaw | null {
    const { redirect } = route
    if (!redirect) {
      return null
    }

    const getRoute = (_route: RouteLocationRaw) => {
      const routeName = this.getRouteName(_route)
      if (!routeName) {
        throw new TmLogicError('route without name')
      }
      return this.routerService.getRouteByName(routeName)
    }

    if (typeof redirect === 'function') {
      const resolvedRoute = this.routerService.resolve(route)
      const r = redirect(resolvedRoute)
      const redirectRoute = getRoute(r)
      return this.getRedirectForRoute(redirectRoute)
    }
    if (typeof redirect === 'string') {
      return this.routerService.getRouteByName(redirect)
    }

    return getRoute(redirect)
  }

  public _getFirstAllowedChildrenRoute(route: RouteLocationRaw, isRoot = true): RouteRecordRaw | null {
    const routeName = this.getRouteName(route)
    if (!routeName) {
      return null
    }
    const routeInfo = this.routerService.getRouteByName(routeName)
    if (isRoot && !routeInfo.children) {
      throw new TmLogicError(`route ${routeName} without children`)
    }

    if (!isRoot && !routeInfo.children && this.isAllowAccessToRoute(routeInfo)) {
      return routeInfo
    }

    const redirect = this.getRedirectForRoute(routeInfo)
    if (redirect) {
      const redirectFirstAllowedChildrenRoute = this._getFirstAllowedChildrenRoute(redirect, false)
      if (redirectFirstAllowedChildrenRoute) {
        return redirectFirstAllowedChildrenRoute
      }
    }

    if (!routeInfo.children || routeInfo.children.length === 0) {
      return null
    }

    for (let i = 0; i < routeInfo.children.length; i++) {
      const firstAllowedChildren = this._getFirstAllowedChildrenRoute(routeInfo.children[i], false)
      if (firstAllowedChildren) {
        return firstAllowedChildren
      }
    }

    return null
  }

  public getFirstAllowedChildrenRoute(route: RouteLocationRaw): RouteRecordRaw | null {
    return this._getFirstAllowedChildrenRoute(route, true)
  }
}

import type { TmNamedRouteRecord } from '@/services/route/types'
import EmailCampaign from '@/data/models/domain/campaign/EmailCampaign'

export const campaignsEmailDetailsOverviewRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.email.details.overview',
  path: 'overview',
  props: true,
  component: () => import('@/components/views/campaigns/email/CampaignsEmailDetailsOverview.vue'),
}

export const campaignsEmailDetailsMessagesRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.email.details.messages',
  path: 'messages',
  component: () => import('@/components/views/campaigns/email/CampaignsEmailDetailsMessages.vue'),
}

export const campaignsEmailDetailsOpensRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.email.details.opens',
  path: 'opens',
  component: () => import('@/components/views/campaigns/email/CampaignsEmailDetailsOpens.vue'),
}

export const campaignsEmailDetailsClicksRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.email.details.clicks',
  path: 'clicks',
  component: () => import('@/components/views/campaigns/email/CampaignsEmailDetailsClicks.vue'),
}

export const campaignsEmailDetailsUnsubscribersRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.email.details.unsubscribers',
  path: 'unsubscribers',
  component: () => import('@/components/views/campaigns/email/CampaignsEmailDetailsUnsubscribers.vue'),
}

export const campaignsEmailDetailsSpamRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.email.details.spam',
  path: 'spam',
  component: () => import('@/components/views/campaigns/email/CampaignsEmailDetailsSpam.vue'),
}

export const campaignsEmailDetailsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.email.details',
  path: 'email/:id',
  props: true,
  component: () => import('@/components/views/campaigns/email/CampaignsEmailDetails.vue'),
  children: [
    campaignsEmailDetailsOverviewRoute,
    campaignsEmailDetailsMessagesRoute,
    campaignsEmailDetailsOpensRoute,
    campaignsEmailDetailsClicksRoute,
    campaignsEmailDetailsUnsubscribersRoute,
    campaignsEmailDetailsSpamRoute,
  ],
  redirect: { name: campaignsEmailDetailsOverviewRoute.name },
  meta: {
    tabTitle: 'pageTabTitle.user.campaigns.email.details',
    resolvers: [
      {
        service: 'SingleModelResolverService',
        params: { model: EmailCampaign, source: 'router', requestParam: 'id' },
      },
    ],
  },
}

export const campaignsEmailRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.email',
  path: 'email',
  component: () => import('@/components/views/campaigns/email/CampaignsEmail.vue'),
}

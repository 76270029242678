import { injectable, inject } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { TableExpandable } from '@/services/route/types'
import type TableRepository from '@/data/repositories/table/tableRepository'

@injectable()
export default class TableExpandService implements TableExpandable {
  constructor(@inject(SERVICE_TYPES.TableRepository) protected readonly tableRepository: TableRepository) {}

  public init() {}

  public isExpanded(tableId: string) {
    return this.tableRepository.findEntityByIdOrFail(tableId).isExpand
  }

  public expandAllRows(tableId: string, isExpand: boolean) {
    this.tableRepository.update([{ id: tableId, isExpand }])
  }
}

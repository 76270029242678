import { getContainer, get } from '@/core/container/container'
import type { RegisteredServices } from '@/core/container/types'
import { getSymbolFor } from '@/utils/app'
import type { BaseTableInterface } from '@/core/tables/baseTableInterface'

export const useService = <T>(id: RegisteredServices, instance: T): T => {
  const identifier = getSymbolFor(id)
  if (getContainer().isBound(identifier)) {
    return get<T>(identifier)
  }
  getContainer().bind<T>(identifier).toConstantValue(instance)
  return get<T>(identifier)
}

export const useTableService = <T extends BaseTableInterface>(
  tableModelId: RegisteredServices,
  table: T,
): { id: RegisteredServices; table: T } => ({
  id: tableModelId,
  table: useService<T>(tableModelId, table),
})

import { AttrField, BooleanField, OrmModel } from 'vuex-orm-decorators'
import type { FormFieldInterface } from '@/services/forms/types'
import BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import { modelNames } from '@/data/models/types'
import type { ValidationRule } from '@/services/validation/types'

@OrmModel(modelNames.FIELD_ARRAY, modelNames.BASE_FIELD, {
  BASE_FIELD: BaseFieldModel,
  FIELD_ARRAY: FieldArray,
})
export default class FieldArray extends BaseFieldModel implements FormFieldInterface {
  @AttrField([])
  public value: string[]

  @AttrField([])
  public initialValue: string[]

  @BooleanField(false)
  public hasSomeValid: boolean

  @BooleanField(true)
  public hasEveryValid: boolean

  @AttrField([])
  public fieldsIndexes: number[]

  @AttrField([])
  public children: string[]

  @AttrField([])
  public validators: ValidationRule[]

  public getComponent(): string {
    return 'FieldArray'
  }

  // getApiValue(): number[] {
  //   return this.fieldsIndexes
  // }
}

<template>
  <tm-skeleton-table-body
    v-if="bodyOnly"
    :rows="rows"
    :columns="columns"
  />

  <tm-table-container v-else>
    <tm-table-semantic-skeleton
      :service-id="serviceId"
      :rows="rows"
      :columns="columns"
      :header-columns="headerColumns"
      :hide-gradient="hideGradient"
      :hide-checkboxes="hideCheckboxes"
      :has-actions="hasActions"
    />
  </tm-table-container>
</template>

<script lang="ts">
import { defineComponent, computed } from '@/composition/vue/compositionApi'
import { useTableSkeleton } from '@/composition/table/useTableSkeleton'
import TmSkeletonTableBody from '@/components/shared/skeletons/TmSkeletonTableBody.vue'
import { TmTableSkeletonProps } from '@/components/shared/tables/tableBase'
import { get } from '@/core/container/container'
import type { BaseTableInterface } from '@/core/tables/baseTableInterface'
import type { RegisteredServices } from '@/core/container/types'
import TmTableContainer from '@/components/shared/tables/TmTableContainer.vue'
import TmTableSemanticSkeleton from '@/components/shared/skeletons/TmTableSemanticSkeleton.vue'

export default defineComponent({
  components: {
    TmSkeletonTableBody,
    TmTableSemanticSkeleton,
    TmTableContainer,
  },
  props: {
    ...TmTableSkeletonProps,
  },
  setup(props) {
    const visibleColumnsRef = computed(() => {
      const table = get<BaseTableInterface>(props.serviceId as RegisteredServices)
      return table.getColumns().getVisibleColumns()
    })

    const { headerColumns, columns, rows } = useTableSkeleton(props.serviceId, {
      hideCheckboxes: props.hideCheckboxes,
      hasActions: props.hasActions,
      rowsToDisplay: props.rowsToDisplay,
    })

    return {
      visibleColumnsRef,
      headerColumns,
      columns,
      rows,
    }
  },
})
</script>

<template>
  <tm-skeleton-table
    :rows="rows"
    :columns="columns"
    :header-columns="columns"
    :hide-gradient="hideGradient"
  />
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import { getDefaultLoaderColumnsConfig } from '@/composition/tables'
import { DEFAULT_PER_PAGE } from '@/core/tables/types'
import TmSkeletonTable from '@/components/shared/skeletons/TmSkeletonTable.vue'
import { TmTableAbstractSkeletonProps } from '@/components/shared/tables/tableBase'

export default defineComponent({
  components: { TmSkeletonTable },
  props: {
    ...TmTableAbstractSkeletonProps,
  },
  setup(props) {
    const columns = getDefaultLoaderColumnsConfig()
    const rows = props.rowsToDisplay ?? DEFAULT_PER_PAGE

    return {
      columns,
      rows,
    }
  },
})
</script>

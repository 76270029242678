import { injectable } from 'inversify'
import BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { Endpoint } from '@/services/endpoints'
import TmLogicError from '@/core/error/tmLogicError'
import FieldArray from '@/data/models/forms/FieldArray'

@RepoSettings<Endpoint>({
  model: BaseFieldModel,
})
@injectable()
export default class BaseFieldRepository<T extends BaseFieldModel = BaseFieldModel> extends OrmApiRepository<T> {
  public getFieldsByForm(formId: string) {
    return this.query().where('formId', formId).all()
  }

  public findFieldArrayByIdOrFail(id: string) {
    const entity = this.findEntityByIdOrFail(id)
    if (!(entity instanceof FieldArray)) {
      throw new TmLogicError('field should be instanceof FieldArray')
    }
    return entity
  }

  public updateFieldArrayChildren(id: string, children: string[]) {
    const arrayField: Partial<FieldArray> = {
      id,
      children,
    }
    return this.update([arrayField as any])
  }
}

import type BaseTable from '@/core/tables/baseTable'
import type TableRepository from '@/data/repositories/table/tableRepository'
import Table from '@/data/models/tables/Table'
import type { PaginationInterface } from '@/services/tables/pagination/types'
import type { BaseTableInterface } from '@/core/tables/baseTableInterface'
import { FILTERS_URL_QUERY_PARAM, PAGING_URL_QUERY_PARAM, SORTS_URL_QUERY_PARAM } from '@/core/tables/types'
import AllSelectedTables from '@/data/models/tables/AllSelectedTables'
import SelectedRow from '@/data/models/tables/SelectedRow'
import type { InternalEmitterPayload } from '@/services/transport/types'
import { isModelEventType, ModelEventType } from '@/services/transport/types'
import type AllSelectedTablesRepository from '@/data/repositories/table/allSelectedTablesRepository'
import type { Resolvers } from '@/services/types'
import type ResolverService from '@/services/resolvers/resolverService'
import { getResolver, getRouterService } from '@/core/container/ioc'

export const rowsReloadable = (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
  const originalMethod = descriptor.value
  descriptor.value = async function (...args: any[]) {
    const tableModelId = (this as BaseTable).getTableModelId()
    ;(this as BaseTable).getEm().getRepository<TableRepository>(Table).setIsRowsLoading(tableModelId, true)
    try {
      const result = await originalMethod.apply(this, args)
      return result
    } finally {
      ;(this as BaseTable).getEm().getRepository<TableRepository>(Table).setIsRowsLoading(tableModelId, false)
    }
  }
  return descriptor
}

export interface InfinityTableInterface extends BaseTableInterface {
  prependRecords: (ids: string[]) => void
  getPrependedIds: () => string[]
}
const PAGE_KEY_ID = '--prepended'

export interface ResolvableTableInterface extends BaseTableInterface {
  getResolverService(): ResolverService
  getResolvers(): Resolvers
}

export function ResolvableTable<T extends { new (...args: any[]): BaseTableInterface }>(
  constructor: T,
  settings: {
    resolvers: Resolvers
    requireResolvers: boolean
  },
) {
  return class extends constructor implements ResolvableTableInterface {
    public getResolverService(): ResolverService {
      return getResolver()
    }

    public getResolvers(): Resolvers {
      return settings.resolvers
    }

    protected getRouterService() {
      return getRouterService()
    }

    protected async resolve() {
      const resolvers = this.getResolvers()
      if (resolvers.length) {
        const currentRoute = this.getRouterService().getCurrentRoute()
        await this.getResolverService().resolveParallel(resolvers, currentRoute)
      }
    }

    protected async initialRefreshRows() {
      if (settings.requireResolvers) {
        await this.resolve()
        await constructor.prototype.initialRefreshRows.call(this)
      } else {
        await Promise.all([this.resolve(), constructor.prototype.initialRefreshRows.call(this)])
      }
    }
  }
}

export function InfinityTable<T extends { new (...args: any[]): BaseTableInterface }>(
  constructor: T,
  settings?: {
    reloadOnSubscriptions: boolean
    savePagination: boolean
    beforeRefreshRowsBySubscriptionHook?: () => unknown
    ignoreRefreshRowsOnSubscriptions?: boolean
    isSilentRefreshRowsOnSubscriptions?: boolean
  },
) {
  return class extends constructor implements InfinityTableInterface {
    public async prependRecords(ids: string[]) {
      const prevIds = constructor.prototype.getPreloader
        .call(this)
        .findTablePreloader(this.getTableModelId() + PAGE_KEY_ID)?.map

      const newPrependedIds = prevIds ? [...ids, ...prevIds] : ids
      constructor.prototype.getPreloader
        .call(this)
        .updateTablePreloaders(PAGE_KEY_ID, this.getTableModelId(), newPrependedIds, this.getEntity())

      const repo: AllSelectedTablesRepository = constructor.prototype.getRepository.call(this, AllSelectedTables)

      const { isAllSelected } = repo.find(this.getTableModelId())
      constructor.prototype.getRepository.call(this, SelectedRow).selectRows(
        this.getTableModelId(),
        newPrependedIds.map((id) => ({ id })),
        isAllSelected,
      )
    }

    public getPrependedIds() {
      const pageMap = constructor.prototype.getPreloader
        .call(this)
        .findTablePreloader(this.getTableModelId() + PAGE_KEY_ID)
      if (!pageMap) {
        return []
      }
      return pageMap.map
    }

    public search(searchQuery: string) {
      if (!constructor.prototype.isChangedSearch.call(this, searchQuery)) {
        return
      }

      this.resetPreloaders()
      constructor.prototype.search.call(this, searchQuery)
    }

    protected resetPreloaders() {
      constructor.prototype.resetPreloaders.call(this)
      this.prependRecords([])
    }

    public async load() {
      // Reset pagination from url query params
      this.resetPaginationWithQuery()
      await constructor.prototype.load.call(this)
    }

    public getCurrentPageRowsIds(): string[] {
      const pageMaps = constructor.prototype.getPreloader
        .call(this)
        .allTablePreloaders(this.getEntity())
        .filter((pageMapPreload) => pageMapPreload.accessoryId === this.getTableModelId())
        .sort((a, b) => a.pageKey.localeCompare(b.pageKey))

      return pageMaps.reduce((result, pageMap) => [...result, ...pageMap.map], [])
    }

    public isEmptyState() {
      return constructor.prototype.isEmptyState.call(this)
    }

    protected subscriptionsCallback(event: InternalEmitterPayload) {
      const loggerService = constructor.prototype.getLogger.call(this)
      loggerService.log(
        'table',
        'Infinity table: drop pagination before running the subscription callback',
        constructor.prototype.getTableModelId.call(this),
      )

      settings?.beforeRefreshRowsBySubscriptionHook?.()
      if (settings?.savePagination) {
        if (!settings?.ignoreRefreshRowsOnSubscriptions) {
          constructor.prototype.refreshRows.call(this, false, false)
        }
      } else {
        this.resetPaginationWithQuery()
      }
      if (settings?.isSilentRefreshRowsOnSubscriptions) {
        constructor.prototype.refreshRows.call(this, { isInit: false, isSilent: true })
      }

      if (settings?.reloadOnSubscriptions) {
        constructor.prototype.subscriptionsCallback.call(this)
      }

      if (isModelEventType(event) && event.eventType === ModelEventType.CREATE) {
        this.prependRecords(event.ids)
      }
    }

    protected resetPaginationWithQuery() {
      const pagination: PaginationInterface = constructor.prototype.getPagination.call(this)
      pagination.reset()

      this.cleanQuery([PAGING_URL_QUERY_PARAM])
    }

    protected cleanQuery(keysToRemove = [FILTERS_URL_QUERY_PARAM, SORTS_URL_QUERY_PARAM, PAGING_URL_QUERY_PARAM]) {
      constructor.prototype.cleanQuery.call(this, keysToRemove, true)
    }
  }
}

import { onBeforeUnmount, ref, watch, type Ref } from '@/composition/vue/compositionApi'
import { getWindowService } from '@/core/container/ioc'

export const useOutsideClick = (
  elementRef: Ref<Node | undefined>,
  callback: () => void,
  containerRef: Ref<Node | undefined> = ref(document),
) => {
  const onClickHandler = (event: Event) => {
    const target = event.target
    if (!target) {
      return
    }
    if (!(target instanceof Element)) {
      return
    }
    const windowService = getWindowService()
    const document = windowService.self().document

    // if document not contains target - target has been deleted
    if (!document.contains(target)) {
      return
    }

    if (elementRef.value?.contains(target)) {
      return
    }
    event.stopPropagation()
    callback()
  }

  watch(
    containerRef,
    (newValue, oldValue) => {
      if (oldValue) {
        oldValue.removeEventListener('click', onClickHandler)
      }
      if (newValue) {
        newValue.addEventListener('click', onClickHandler)
      }
    },
    { immediate: true },
  )

  onBeforeUnmount(() => {
    containerRef.value?.removeEventListener('click', onClickHandler)
  })
}

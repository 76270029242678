import { OrmModel, StringField, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.SELECTED_ROWS)
export default class SelectedRow extends BaseModel {
  @StringField()
  public id: string

  @AttrField([])
  public selectedRows: string[]
}

import type { RouteRecordRaw } from 'vue-router'
import AutomationRuleDetails from '@/data/models/domain/services/automationRule/AutomationRuleDetails'
import AutomationRuleLog from '@/data/models/domain/services/automationRule/AutomationRuleLog'
import type { NotFoundResolverParams, SingleModelResolverParams } from '@/services/resolvers/types'
import { AppModule } from '@/config/types'
import PassThrough from '@/components/layout/PassThrough.vue'
import { ServicesRouteName } from '@/routes/types'

export const getAutomationRuleDetailsRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.service.automationRules.details.wrapper',
    path: 'automation-rules',
    component: PassThrough,
    meta: {
      parent: ServicesRouteName,
      modules: [
        AppModule.AutomationRulesDetails,
        AppModule.AutomationRulesLog,
        AppModule.AutomationRule,
        AppModule.AutomationRules,
        AppModule.Compose,
        AppModule.Contacts,
        AppModule.ContactList,
        AppModule.ContactListCounter,
      ],
    },
    children: [
      {
        name: 'user.services.automationRules.newRule',
        path: 'new',
        meta: {
          modules: [AppModule.ContactList],
          tabTitle: 'pageTabTitle.user.services.automationRules.newRule',
        },
        component: () => import('@/components/views/services/automationRules/AutomationRulesFlow.vue'),
      },
      {
        name: 'user.services.automationRules.editRule',
        path: ':ruleId',
        component: () => import('@/components/views/services/automationRules/AutomationRulesFlow.vue'),
        props: true,
        meta: {
          modules: [AppModule.ContactList],
          tabTitle: 'pageTabTitle.user.services.automationRules.details',
          resolvers: [
            {
              service: 'RedirectableSingleModelResolverService',
              params: {
                source: 'router',
                requestParam: 'ruleId',
                model: AutomationRuleDetails,
                redirectToIfNotFound: { name: 'user.services.automationRules' },
              } as NotFoundResolverParams,
            },
          ],
        },
      },
      {
        name: 'user.services.automationRules.logsDetails',
        path: 'logs/:automationRuleLogId',
        props: true,
        meta: {
          tabTitle: 'pageTabTitle.user.services.automationRules.logDetails',
          detailsPage: {
            source: 'router',
            requestParam: 'automationRuleLogId',
            model: AutomationRuleLog,
          } as SingleModelResolverParams,
        },
        component: () => import('@/components/views/services/automationRules/AutomationRulesLogDetails.vue'),
      },
      {
        name: 'user.services.automationRules.details',
        path: 'details/:ruleId',
        props: true,
        meta: {
          tabTitle: 'pageTabTitle.user.services.automationRules.details',
          detailsPage: {
            source: 'router',
            requestParam: 'ruleId',
            model: AutomationRuleDetails,
          } as SingleModelResolverParams,
          redirectToIfNotFound: { name: 'user.services.automationRules.rules' },
        },
        component: () => import('@/components/views/services/automationRules/AutomationRuleDetails.vue'),
      },
    ],
  },
]

<template>
  <div
    class="page-content-sidebar-wrapper"
    :class="{
      'page-content-sidebar-wrapper--sidebar': hasSidebar,
    }"
  >
    <page-content-sidebar
      v-if="hasSidebar"
      :aria-hidden="sidebarCollapsed"
      :title="menuTitle"
      :hidden="isTablet"
      :sidebar-width="calculatedSidebarWidth"
      :sidebar-collapsed="sidebarCollapsed"
      :sidebar-show-transition="sidebarShowTransition"
      :is-hide-collapsed-button="isForceSidebarCollapsed"
      @set-sidebar-width="setSidebarWidth"
      @set-sidebar-collapsed="setSidebarCollapsed"
      @set-sidebar-show-transition="setSidebarShowTransition"
    >
      <template v-slot="{ onLockSidebar }">
        <component
          :is="sidebarMenu"
          @lock-sidebar="onLockSidebar($event)"
        />
      </template>
      <template v-slot:after-title>
        <slot name="after-sidebar-title" />
      </template>
    </page-content-sidebar>

    <page-content-main-wrap
      :has-sidebar-slot="hasSidebar"
      :sidebar-collapsed="sidebarCollapsed"
      :transition="sidebarShowTransition"
      :is-background-gray="isBackgroundGray"
    >
      <div
        v-if="noScroll"
        class="h100pr w100pr"
      >
        <slot />
      </div>
      <page-scroll-container v-else>
        <slot />
      </page-scroll-container>
      <div
        v-if="offScrollZoneClass"
        :class="[offScrollZoneClass, 'absolute-bottom']"
      />
    </page-content-main-wrap>
  </div>
</template>

<script lang="ts">
import { useRoute } from 'vue-router'
import { computed, defineComponent, watch } from '@/composition/vue/compositionApi'
import PageScrollContainer from '@/components/layout/PageScrollContainer.vue'
import { useBreakpoints } from '@/composition/breakpoints'
import PageContentMain from '@/components/layout/pageContent/PageContentMain.vue'
import PageContentSidebar from '@/components/layout/pageContent/sidebar/PageContentSidebar.vue'
import PageContentMainWrap from '@/components/layout/pageContent/PageContentMainWrap.vue'
import PageContentMainBlock from '@/components/layout/pageContent/PageContentMainBlock.vue'
import PageContentTop from '@/components/layout/pageContent/PageContentTop.vue'
import { useLayoutMenuInSidebar } from '@/composition/layoutMenu'
import { useSidebarMenuComponent, useSidebarState } from '@/composition/layout/pageContent/sidebar'

export default defineComponent({
  components: {
    PageContentTop,
    PageContentMainBlock,
    PageContentMainWrap,
    PageContentSidebar,
    PageContentMain,
    PageScrollContainer,
  },
  props: {
    menuTitle: {
      type: String,
    },
    isBackgroundGray: {
      type: Boolean,
    },
    offScrollZoneClass: String,
    isForceSidebarCollapsed: {
      type: Boolean,
      default: false,
    },
    sidebarWidth: {
      type: Number,
    },
    isHiddenSidebar: {
      type: Boolean,
    },
    noScroll: {
      type: Boolean,
    },
  },
  setup(props) {
    useLayoutMenuInSidebar().provide(true)

    const {
      sidebarWidth: calculatedSidebarWidth,
      hasSavedSidebarWidthValue,
      setSidebarWidth,
      sidebarCollapsed: _sidebarCollapsed,
      setSidebarCollapsed,
      sidebarShowTransition,
      setSidebarShowTransition,
    } = useSidebarState(props.sidebarWidth)

    const { isTablet } = useBreakpoints()

    const route = useRoute()

    const sidebarMenu = useSidebarMenuComponent(route)

    const hasSidebar = computed(() => !props.isHiddenSidebar && !!sidebarMenu && !isTablet.value)

    const sidebarCollapsed = computed(() => {
      if (props.isForceSidebarCollapsed) {
        return true
      }
      return _sidebarCollapsed.value
    })

    watch(
      () => props.sidebarWidth,
      (val) => {
        if (hasSavedSidebarWidthValue() || typeof val !== 'number') {
          return
        }
        setSidebarWidth(val, false)
      },
    )

    return {
      isTablet,
      sidebarMenu,
      calculatedSidebarWidth,
      hasSidebar,
      setSidebarWidth,
      sidebarCollapsed,
      setSidebarCollapsed,
      sidebarShowTransition,
      setSidebarShowTransition,
    }
  },
})
</script>

<style lang="scss" scoped>
.page-content-sidebar-wrapper {
  position: relative;
  height: 100%;

  @include media-sm-x-max {
    background: $white;
  }

  &--sidebar {
    display: flex;
  }
}
</style>

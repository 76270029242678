import type { DeepPartial, ModelRaw } from '@/types'
import type Pagination from '@/data/models/tables/Pagination'
import type { LowLevelUpdateBody } from '@/services/vuex/types'
import type Counter from '@/data/models/tables/Counter'

export interface PaginationInterface<T extends PaginationUrlParams = PaginationUrlParams> {
  init(params?: T): boolean
  setSettings(settings: PaginationSettings): void
  setTableId(tableId: string): void
  getTableId(): string
  getCurrentPage(): PaginationParams<T>
  getFirstPage(): PaginationParams<T>
  clonePagination(
    overrider?: DeepPartial<PaginationParams<T>> | ((params: PaginationParams<T>) => void),
  ): PaginationParams<T>
  moveToFirstPage(): PaginationParams<T>
  moveToNextPage(data: PaginationNextPageData): PaginationParams<T>
  moveToPrevPage(): PaginationParams<T>
  getData(): ModelRaw<Pagination>
  setData(data: LowLevelUpdateBody<Pagination>): void
  setLastId(lastId: string): void
  toQuery(): T
  reset(): void
  applyUrlParams(params: T): void
  getPerPage(): number
  changePerPage(perPage: number): void
  pageKey(data: T): string
  moveToPage(params: PaginationParams<T>): PaginationParams<T>
  isDefaultState(): boolean
  isFirstPage(): boolean
  onDataLoad(pagination: PaginationData): void
  getTotal(): number
  setTotalCount(count: number): void
}

export type BasePaginationUrlParams = {
  page: number
  perPage: number
  limit?: number // @todo: get rid of it, after API v3 full covering
}

export type CursorPaginationUrlParams = {
  currentPage: number
  lastId: string
  perPage: number
  limit?: number
}

export type TokenPaginationUrlParams = {
  nextId: string
  lastId?: string
  perPage: number
  limit?: number
}

export type PaginationUrlParams = BasePaginationUrlParams | CursorPaginationUrlParams | TokenPaginationUrlParams
// export type PaginationUrlParams = BasePaginationUrlParams

export type PaginationParams<T extends PaginationUrlParams = PaginationUrlParams> = {
  requestParams: T
  pageKey: string
}

export type PaginationNextPageData = Partial<{
  lastId: string
  nextId: string
  counterServiceData: Counter
}>

export type PaginationType = 'offset' | 'lastId' | 'token'

export type PaginationData = {
  type: PaginationType
  perPage: number
  page: number
  totalCount: number
  pageCount: number
  nextId: string
  prevId: string
}

export type PaginationOffsetResponse<T> = {
  items: Array<ModelRaw<T>>
  pagination: PaginationData
}

export type CursorPaginationOffsetResponse<T> = {
  items: Array<ModelRaw<T>>
  pagination: PaginationData
}

// There isn't a pagination in the response for some domains (example: ticket statuses)
// If it happened then consider that there is one page (we have got all items)
export type OptionalPaginationOffsetResponse<T> = Partial<PaginationOffsetResponse<T>> &
  Pick<PaginationOffsetResponse<T>, 'items'>

export type PaginationSettings = {
  perPage: number
}

export const cursorLoadingText = '…'
export const lastIdForFirstPage = String(0)
export const firstPageNumber = 1
export const SENT_SMS_TOTAL_LIMIT = 30000

export const lastTokenForFirstPage = ''

export const paginationOffsetEmptyResponse = {
  items: [],
  pagination: {
    type: 'offset',
    perPage: 10,
    page: 1,
    totalCount: 0,
    pageCount: 1,
    nextId: '',
    prevId: '',
  },
}

export const cursorPaginationOffsetEmptyResponse = {
  items: [],
  pagination: {
    perPage: 10,
    page: 1,
    totalCount: 0,
    pageCount: 1,
    overLimit: false,
    nextId: '',
    prevId: '',
    type: 'lastId',
  },
}

import { AttrField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.USER_PRESENCE)
export default class UserPresence extends BaseModel {
  @AttrField(null)
  public presenceStatus: UserPresenceStatus | null

  @AttrField(null)
  public showPresence: boolean | null
}

export enum UserPresenceStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

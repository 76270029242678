import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/container'
import { instanceIdentifier } from '@/constants/instanceIdentifier'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type PrimaryBrowserTabRepository from '@/data/repositories/PrimaryBrowserTabRepository'
import type { PrimaryBrowserTabPayload } from '@/data/repositories/PrimaryBrowserTabRepository'
import PrimaryBrowserTab from '@/data/models/PrimaryBrowserTab'
import type PrimaryBrowserTabLockRepository from '@/data/repositories/PrimaryBrowserTabLockRepository'
import PrimaryBrowserTabLock from '@/data/models/PrimaryBrowserTabLock'
import type SubscriptionService from '@/services/transport/subscriptionService'
import { BroadcastEvent } from '@/services/transport/types'

const inactivityThresholdAt = 10 * 60 * 1000 // 10 minutes
const lockTimeout = 100

@injectable()
export default class PrimaryBrowserTabService {
  protected currentTabId: string = instanceIdentifier.toString()

  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
  ) {
    // Clean up to avoid getting stuck
    this.getLockRepository().removeLock()
  }

  public broadcastEmitNewTab() {
    this.subscriptionService.broadcastEmit(BroadcastEvent.NewPrimaryTab)
  }

  public emitPrimaryTabChanged(isPrimaryTab: boolean) {
    this.subscriptionService.emit('change:TmAppPrimaryInstance', isPrimaryTab)

    if (isPrimaryTab) {
      this.broadcastEmitNewTab()
    }
  }

  public async getIsTmAppPrimaryInstance(): Promise<boolean> {
    const { tabId, timestamp } = await this.getStorageData()

    if (!tabId || !timestamp) {
      // Current tab is primary
      return true
    }

    // If the localStorage tab is no primary, then the current tab will become primary
    return this.checkIsExpiredTab(timestamp) || tabId === this.currentTabId
  }

  // Primary tab lifetime adjusted for background timer slowdown. Must be more than update interval.
  public checkIsExpiredTab(timestamp: number): boolean {
    return Date.now() - timestamp > inactivityThresholdAt
  }

  public async checkTabId() {
    const isPrimaryTab = await this.getIsTmAppPrimaryInstance()

    if (isPrimaryTab) {
      await this.setPrimaryTab()
      this.subscriptionService.broadcastEmit(BroadcastEvent.ChangePrimaryTab)
    }

    return isPrimaryTab
  }

  public async removeTabId() {
    const repo = this.getRepository()
    const storedData = await repo.getTabData()
    if (storedData?.tabId === this.currentTabId) {
      await repo.removeTabData()
    }
  }

  public setPrimaryTab() {
    const data: PrimaryBrowserTabPayload = {
      tabId: this.currentTabId,
      timestamp: Date.now(),
    }

    return this.getRepository().saveTabData(data)
  }

  public async raceForPrimaryTab(): Promise<boolean> {
    const { promise, resolve } = Promise.withResolvers<boolean>()
    const lockRepo = this.getLockRepository()
    const lock = await lockRepo.getLock()

    // Lock to prevent race condition between tabs
    if (!lock.tabId || lock.tabId === this.currentTabId) {
      await lockRepo.setLock(this.currentTabId)

      setTimeout(async () => {
        // Repeat lock check. Just one tab can be activated
        const lastLock = await lockRepo.getLock()
        if (lastLock.tabId === this.currentTabId) {
          const isPrimaryTab = await this.checkTabId()
          await lockRepo.removeLock()
          resolve(isPrimaryTab)
        }
      }, lockTimeout)
    }

    return promise
  }

  public subscribeToChangePrimaryTab(callback: () => void) {
    this.subscriptionService.subscribeBroadcast(BroadcastEvent.ChangePrimaryTab, async () => {
      await this.removeTabId()
      callback()
    })
  }

  public subscribeToCloseTab(callback: (isPrimaryTab: boolean) => void) {
    this.subscriptionService.subscribeBroadcast(BroadcastEvent.CloseBrowserTab, async () => {
      const isPrimaryTab = await this.raceForPrimaryTab()
      callback(isPrimaryTab)
    })
  }

  public async closeTabHandler() {
    const isPrimaryTab = await this.getIsTmAppPrimaryInstance()
    if (isPrimaryTab) {
      this.subscriptionService.broadcastEmit(BroadcastEvent.CloseBrowserTab)
    }
    this.removeTabId()
  }

  public getStorageData() {
    return this.getRepository().getTabData()
  }

  public getRepository() {
    return this.entityManager.getRepository<PrimaryBrowserTabRepository>(PrimaryBrowserTab)
  }

  public getLockRepository() {
    return this.entityManager.getRepository<PrimaryBrowserTabLockRepository>(PrimaryBrowserTabLock)
  }
}

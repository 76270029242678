import { OrmModel, StringField, BooleanField, NumberField } from 'vuex-orm-decorators'

import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'

@OrmModel(modelNames.GLOBAL_STATE)
export default class GlobalState extends BaseModel {
  @StringField()
  public id: string

  @BooleanField()
  public showAuthLoader: boolean

  @BooleanField()
  public isMenuOpen: boolean

  @BooleanField()
  public oldVersion: boolean

  @BooleanField()
  public sidebarCollapsed: boolean

  @NumberField()
  public sidebarWidth: number

  @NumberField()
  public contactDetailsSidebarWidth: number

  @NumberField()
  public ticketDetailsSidebarWidth: number

  @BooleanField()
  public hasActiveSubAccounts: boolean

  @BooleanField()
  public isChatDetailsSidebarOpen: boolean

  @BooleanField()
  public isChatDetailsSidebarVisibleCustomFields: boolean
}

<template>
  <div
    class="page-content-sidebar-item-actions"
    @click.stop.prevent
  >
    <tm-dropdown
      :model-value="dropdownState"
      @update:model-value="$emit('update:dropdown-state', $event)"
    >
      <tm-button-more btn-size="xSmall" />
      <template v-slot:menu>
        <tm-dropdown-item
          v-for="(action, i) in actions"
          :key="i"
          :icon="action.icon"
          :label="action.text"
          @click="$emit('action-click', action)"
        />
      </template>
    </tm-dropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import type { PageContentSidebarItemAction } from '@/components/layout/pageContent/sidebar/types'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: {
    TmButtonMore,
    TmDropdownItem,
    TmDropdown,
  },
  props: {
    actions: {
      type: Array as PropType<PageContentSidebarItemAction[]>,
      required: true,
    },
    dropdownState: {
      type: Boolean,
    },
  },
  emits: {
    'action-click': (_action: PageContentSidebarItemAction) => true,
    'update:dropdown-state': (_val: boolean) => true,
  },
})
</script>

<style lang="scss" scoped>
.page-content-sidebar-item-actions {
  position: absolute;
  top: 50%;
  right: 9px;
  transform: translateY(-50%);
  display: flex;
}
</style>

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import TeamSubscriptionPrice from '@/data/models/domain/TeamSubscriptionPrice'

@RepoSettings<Endpoint>({
  model: TeamSubscriptionPrice,
})
@injectable()
export default class TeamSubscriptionPriceRepository extends OrmApiRepository<TeamSubscriptionPrice> {
  public async getPrices() {
    const path = this.endpointsService.getPath('teamSubscriptionPrices')
    const res = await this.getApiSource().get<{ prices: TeamSubscriptionPrice[] }>(path)
    await this.insertOrUpdate(res.data!.prices)
  }
}

<template>
  <draggable
    :model-value="modelValue"
    v-bind="propsAndAttrs"
    :delay="delay"
    :animation="animation"
    easing="cubic-bezier(0,0,1,1)"
    :handle="handle"
    :force-fallback="true"
    :disabled="disabled"
    :filter="filter"
    :group="group"
    :prevent-on-filter="preventFilterEmit"
    @start="onDragStart"
    @end="onDragEnd"
    @change="onChange"
  >
    <template v-slot:header>
      <slot name="header" />
    </template>
    <template v-slot:item="itemProps">
      <div
        :class="['draggable-item', { 'draggable-item_disabled': disabled }, getDraggableItemClass(itemProps.element)]"
      >
        <slot
          name="item"
          v-bind="itemProps"
        />
      </div>
    </template>
    <template v-slot:footer>
      <slot name="footer" />
    </template>
  </draggable>
</template>

<script setup lang="ts" generic="EventItemType = unknown">
import draggable from 'vuedraggable'
import { isFunction } from 'lodash-es'
import { computed, useAttrs, type PropType } from '@/composition/vue/compositionApi'
import type {
  DraggableAddedEvent,
  DraggableEvent,
  DraggableMoveEvent,
  DraggableRemovedEvent,
  DraggableSortEvent,
  DraggableStartEndEvent,
} from '@/components/shared/types'
import { DefaultInputEvent } from '@/services/forms/types'

type CssCursorType = CSSStyleDeclaration['cursor']

const props = defineProps({
  modelValue: {
    type: Array as PropType<EventItemType[]>,
    required: true,
  },
  itemKey: {
    /**
     * The property to be used as the element key.
     * Alternatively a function receiving an element of the list and returning its key.
     */
    type: [String, Function],
    required: true,
  },
  ghostClass: {
    // Class name for the drop placeholder.
    type: String,
    required: false,
  },
  chosenClass: {
    // Class name for the chosen item.
    type: String,
    required: false,
  },
  draggableItemClass: {
    // Class name for the draggable item.
    type: [String, Function] as PropType<string | ((item: EventItemType) => string)>,
  },
  dragClass: {
    // Class name for the dragging item.
    type: String,
    default: 'draggable-item--dragging',
  },
  handle: {
    // Drag handle selector within list items.
    type: String,
    required: false,
  },
  tag: {
    type: String,
    required: false,
  },
  preventFilterEmit: {
    type: Boolean,
  },
  move: {
    /**
     * This function will be called in a similar way as 'change' event callback.
     * Returning false will cancel the drag operation.
     */
    type: Function as PropType<(evt: DraggableEvent<unknown>) => boolean>,
  },
  hoverCursor: {
    type: String as PropType<CssCursorType>,
    default: '' satisfies CssCursorType,
  },
  draggingCursor: {
    type: String as PropType<CssCursorType>,
    default: '' satisfies CssCursorType,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  filter: {
    // Selectors that do not lead to dragging
    type: String,
  },
  group: {
    type: String,
  },
  delay: {
    type: Number,
    default: 50,
  },
  animation: {
    type: Number,
    default: 200,
  },
})

const emit = defineEmits({
  [DefaultInputEvent]: (value: EventItemType[]) => true,
  sort: (e: DraggableSortEvent<EventItemType>) => true, // Event when you move an item in the list
  added: (e: DraggableAddedEvent<EventItemType>) => true, // Event when you add an item in the list
  removed: (e: DraggableRemovedEvent<EventItemType>) => true, // Event when you remove an item from the list
  start: (e: DraggableStartEndEvent) => true,
  end: (e: DraggableStartEndEvent) => true,
})

defineSlots<{
  header(): any
  item(props: { element: EventItemType; index: number }): any
  footer(): any
}>()

const propsAndAttrs = computed(() => {
  const { class: classes, style: styles } = useAttrs()
  const { itemKey, ghostClass, chosenClass, dragClass, handle, move, tag } = props
  return {
    class: classes,
    style: styles,
    itemKey,
    ghostClass,
    chosenClass,
    dragClass,
    handle,
    move,
    tag,
  }
})

const getDraggableItemClass = (item: EventItemType) => {
  if (!props.draggableItemClass) {
    return ''
  }
  if (isFunction(props.draggableItemClass)) {
    return props.draggableItemClass(item)
  }
  return props.draggableItemClass
}

const onChange = (event: DraggableMoveEvent<EventItemType>) => {
  const { moved, added, removed } = event

  if (moved) {
    const movedIndex = moved.oldIndex
    const afterIndex =
      moved.newIndex > moved.oldIndex ? Math.min(moved.newIndex, props.modelValue.length - 1) : moved.newIndex - 1
    const movedItem = props.modelValue[movedIndex]
    const afterItem = props.modelValue[afterIndex]

    const sortedItems = [...props.modelValue]
    sortedItems.splice(moved.newIndex, 0, sortedItems.splice(moved.oldIndex, 1)[0])

    emit(DefaultInputEvent, sortedItems)
    emit('sort', {
      movedIndex,
      afterIndex,
      movedItem,
      afterItem,
      sortedItems,
    })
  }

  if (added) {
    const sortedItems = [...props.modelValue]
    sortedItems.splice(added.newIndex, 0, added.element)

    emit(DefaultInputEvent, sortedItems)
    emit('added', {
      afterIndex: added.newIndex,
      addedItem: added.element,
      sortedItems,
    })
  }

  if (removed) {
    const sortedItems = [...props.modelValue].filter((_, index) => index !== removed.oldIndex)

    emit(DefaultInputEvent, sortedItems)
    emit('removed', {
      removedIndex: removed.oldIndex,
      removedItem: removed.element,
      sortedItems,
    })
  }
}

const onDragStart = (e: DraggableStartEndEvent) => {
  document.documentElement.classList.add('tm-draggable__dragging-zone')
  emit('start', e)
}

const onDragEnd = (e: DraggableStartEndEvent) => {
  document.documentElement.classList.remove('tm-draggable__dragging-zone')
  emit('end', e)
}
</script>

<style lang="scss" scoped>
.draggable-item {
  &--dragging {
    opacity: 0.9;
    box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.15);
    transition:
      opacity $transition-200ms-ease-in-out,
      box-shadow $transition-200ms-ease-in-out;
  }

  cursor: v-bind(hoverCursor);
}
</style>

<!-- eslint-disable-next-line vue/enforce-style-attribute -->
<style lang="scss">
.tm-draggable {
  &__dragging-zone * {
    cursor: v-bind(draggingCursor) !important;
  }
}
</style>

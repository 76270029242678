import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'

export const getWizardRoutes = (): RouteRecordRaw[] => [
  {
    name: 'wizard.base',
    path: 'wizard',
    component: () => import('@/components/containers/WizardContainer.vue'),
    meta: {
      tabTitle: 'pageTabTitle.wizard.base',
      isWizardPage: true,
      modules: [AppModule.Wizard, AppModule.TenDlcTollFreeRequest],
    },
    children: [
      {
        name: 'wizard',
        path: '',
        component: () => import('@/components/views/wizard/Wizard.vue'),
        meta: {
          isWizardPage: true,
        },
      },
    ],
  },
]

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import TicketCount from '@/data/models/domain/tickets/TicketCount'
import type { Dict } from '@/types'
import { httpBuildQuery } from '@/utils/url'
import type { TicketGetCountRequestBody } from '@/services/domain/tickets/types'

@RepoSettings<Endpoint>({
  model: TicketCount,
})
@injectable()
export default class TicketsCountRepository extends OrmApiRepository<TicketCount> {
  private id = '1'

  public async fetchCount(params?: TicketGetCountRequestBody) {
    const endpoint = this.endpointsService.getPath('ticketsCount')
    const { data } = await this.getApiSource().get<{ count: number }>(endpoint, {
      params,
      paramsSerializer: (serializableParams: Dict) => httpBuildQuery(serializableParams),
    })
    return data.count
  }

  public async fetchUnreadTicketsCount() {
    const count = await this.fetchCount({
      filters: [{ isRead: { eq: 0 } }],
    })
    await this.insertOrUpdate([
      {
        id: this.id,
        count,
      },
    ])
    return this.findEntityByIdOrFail(this.id)
  }

  public findUnreadTicketsCount() {
    return this.findEntityByIdOrNull(this.id)?.count ?? null
  }

  public updateUnreadTicketsCount(count: number) {
    this.update([
      {
        id: this.id,
        count,
      },
    ])
  }
}

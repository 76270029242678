import { toPairs } from 'lodash-es'
import type { interfaces, Container } from 'inversify'
import type { RegisteredServices } from '@/core/container/types'
import { SERVICE_TYPES } from '@/core/container/types'
import serviceContainer from '@/core/container/containerInstance'
import type { AppModule, Service, ServiceGroups, ServicesType } from '@/config/types'
import { getSymbolFor } from '@/utils/app'
import type { Dict, Factory } from '@/types'
import type { MonitoringServiceInterface } from '@/services/monitoring/types'
import { isTest } from '@/utils/system'

const groupedServices = {} as Record<ServiceGroups, Service[]>

export const buildContainer = (
  services: ServicesType<string>,
  checkDeps?: (key: string, service: Service) => AppModule[],
): AppModule[] => {
  let result: AppModule[] = []
  for (const [key, service] of toPairs<Service>(services)) {
    if (!checkDeps) {
      bindService(key, service)
    } else {
      const modules = checkDeps(key, service)
      result = result.concat(modules)
      bindService(key, service)
    }
  }
  return Array.from(new Set(result))
}

export const dynamicClassDependsSymbol = Symbol('DynamicClassDependsSymbol')

export const bindService = (key: string, service: Service) => {
  service.id = key
  const { bindingValue, bindingType } = service
  const onActivationHandler: <T>(context: interfaces.Context, injectable: T) => T | Promise<T> = (context, t) => {
    if (service.afterBindingHook) {
      service.afterBindingHook(key, service, t, context)
    }
    return t
  }
  const container = getContainer()
  if (bindingType === 'dynamicClass') {
    container
      .bind(getSymbolFor(key))
      .toDynamicValue((context) => {
        return bindingValue((name: RegisteredServices) => context.container.get(getSymbolFor(name)))
      })
      .inSingletonScope()
      .onActivation(onActivationHandler)
  } else if (!bindingType || bindingType === 'class') {
    container.bind(getSymbolFor(key)).to(bindingValue).inSingletonScope().onActivation(onActivationHandler)
  } else if (bindingType === 'factory') {
    container
      .bind(getSymbolFor(key))
      .toFactory((context: interfaces.Context) => (factoryParam?: Dict) => {
        const createdService = context.container.resolve(service.bindingValue)
        if (!service.afterServiceInstantiateHook) {
          return createdService
        }

        return service.afterServiceInstantiateHook(createdService, key, factoryParam ?? {})
      })
      .onActivation(onActivationHandler)
  } else {
    container.bind(getSymbolFor(key)).toConstantValue(bindingValue).onActivation(onActivationHandler)
  }
  if (service.group) {
    const serviceGroups = Array.isArray(service.group) ? service.group : [service.group]
    for (const serviceGroup of serviceGroups) {
      if (Array.isArray(groupedServices[serviceGroup])) {
        groupedServices[serviceGroup].push(service)
      } else {
        groupedServices[serviceGroup] = [service]
      }
    }
  }
  if (service.autoCreate) {
    get(key as RegisteredServices)
  }
}

export const getContainer = (): Container => serviceContainer

export type GetLocator = <T>(key: RegisteredServices | symbol) => T

export const get: GetLocator = <T>(key: RegisteredServices | symbol): T => {
  try {
    return serviceContainer.get<T>(getSymbolFor(key))
  } catch (e) {
    if (isTest()) {
      throw e
    }
    const monitoringService = get<MonitoringServiceInterface>(getSymbolFor('MonitoringService'))
    const bindingDictionary: interfaces.Lookup<interfaces.Binding<unknown>> = (getContainer() as any)._bindingDictionary

    if (!bindingDictionary.hasKey(getSymbolFor(key))) {
      monitoringService.logInfo(`GetLocator error with info - not found binding for key="${key.toString()}"`)
    } else {
      const bindings = bindingDictionary.get(getSymbolFor(key))
      const binding = bindings[0] as any
      if (binding === null || binding === undefined) {
        monitoringService.logInfo(
          `GetLocator error with info - not found binding(type="${typeof binding}", key="${key.toString()}")`,
        )
      } else {
        monitoringService.logInfo(
          `GetLocator error with info - bindingInfo:${JSON.stringify(
            binding,
          )}, type: ${typeof binding.implementationType}}, key="${key.toString()}"`,
        )
      }
    }

    const err = new AggregateError([e], `GetLocator error with ${key.toString()}`)
    throw err
  }
}

getContainer().bind<GetLocator>('GetLocator').toFunction(get)

export const getFactory = <T>(key: RegisteredServices | symbol): Factory<T> =>
  getContainer().get<Factory<T>>(getSymbolFor(key))

export const getGroupedIds = (group: ServiceGroups, services?: Service[]): RegisteredServices[] => {
  const cb = (srv) => srv.id ?? srv.bindingValue
  if (Array.isArray(services)) {
    return services.filter((srv) => srv.group === group).map(cb)
  }
  return groupedServices[group]?.map(cb) ?? []
}

export const getGroupedInstances = <T>(group: ServiceGroups, services?: Service[]): Array<T> => {
  const grouped = getGroupedIds(group, services)
  return grouped?.map((id: RegisteredServices) => get<T>(id)) ?? []
}

export const getGroupedServices = (group: ServiceGroups, services?: Service[]): Service[] => {
  if (Array.isArray(services)) {
    return services.filter((srv) => srv.group === group)
  }
  return groupedServices[group] ?? []
}

export const unbind = <T>(service: symbol | interfaces.ServiceIdentifier<T>) => {
  if (getContainer().isBound(service)) {
    getContainer().unbind(service)
  }
}

export const bindToConstantValue = <T>(service: symbol | interfaces.ServiceIdentifier<T>, value: T) => {
  if (!getContainer().isBound(service)) {
    getContainer().bind(service).toConstantValue(value)
  }
}

export { SERVICE_TYPES }

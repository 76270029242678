import type { ServiceGroups, Service } from '@/config/types'
import type { MiddlewareInterface } from '@/core/middlewares/middlewareInterface'
import { get } from '@/core/container/container'
import { ServiceManagerMap, type IServiceManager } from './types'
import ServiceManagerMiddleware from '@/core/middlewares/serviceManagerMiddleware'

const servicesMiddlewares = Object.keys(ServiceManagerMap).map((group) => {
  return new ServiceManagerMiddleware(group as ServiceGroups)
})

export const getServicesMiddlewares = () => servicesMiddlewares

export const processMiddlewares = (middlewares: MiddlewareInterface[]) => {
  middlewares.forEach((middleware: MiddlewareInterface) => {
    middleware.make(get)
  })
}

const addService = (service: Service, group: ServiceGroups) => {
  const manager = get<IServiceManager>(ServiceManagerMap[group])
  manager.addService(service, group)
}

export const regisiterInServiceManager = (service: Service) => {
  if (!service.group) {
    return
  }
  if (Array.isArray(service.group)) {
    service.group.forEach((group) => addService(service, group))
  } else {
    addService(service, service.group)
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import BaseFormService from '@/services/forms/baseFormService'
import type FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type LoggerService from '@/services/loggerService'
import type { ParamsPartial } from '@/types'
import type { SelectFieldConfig, SelectSearchSenderSettingsFieldConfig } from '@/services/forms/types'
import type { ServiceDistributionAdditionalSettingsFormSchema } from '@/services/domain/services/distribution/types'
import type DistributionDetailsService from '@/services/domain/services/distribution/distributionDetailsService'
import { getSendReceiveSettingsMaxLengthOptions } from '@/services/domain/sendReceiveSettings/types'
import type { Translatable, TranslationKey } from '@/services/types'
import { clearPlusFromPhoneNumber } from '@/utils/string/clearPlusFromPhoneNumber'

@injectable()
export default class ServiceDistributionAdditionalSettingsFormService extends BaseFormService<
  FormBuilderService,
  ParamsPartial,
  ServiceDistributionAdditionalSettingsFormSchema
> {
  constructor(
    @inject(SERVICE_TYPES.FormBuilderFactoryService) formBuilderFactoryService: () => FormBuilderService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.DistributionDetailsService)
    protected readonly distributionDetailsService: DistributionDetailsService,
    @inject(SERVICE_TYPES.TranslateService)
    protected readonly translateService: Translatable,
  ) {
    super(formBuilderFactoryService, em, validationRulesBuilderService, logger)
  }

  public async buildForm() {
    const typedBuilder = this.getTypedBuilder()

    typedBuilder
      .property((t) => t.includeOptions)
      .control({
        fieldType: 'radio',
        value: 'both',
      })

    const maxLengthOptions = getSendReceiveSettingsMaxLengthOptions((key: TranslationKey) =>
      this.translateService.t(key),
    )
    typedBuilder
      .property((t) => t.maxLength)
      .control<SelectFieldConfig>({
        fieldType: 'select',
        value: maxLengthOptions[0].value,
        options: () => maxLengthOptions,
      })

    typedBuilder
      .property((t) => t.sender)
      .control<SelectSearchSenderSettingsFieldConfig>({
        fieldType: 'selectSearchSenderSettings',
        value: '',
        options: () => [],
      })

    typedBuilder
      .property((t) => t.sourceType)
      .control({
        fieldType: 'text',
        value: '',
      })
  }

  public populateFromEntityId(entityId: string) {
    this.populate(this.getPopulateDataFromEntity(entityId))
  }

  public async submit() {}

  protected getPopulateDataFromEntity(entityId: string): ServiceDistributionAdditionalSettingsFormSchema {
    const distributionDetails = this.distributionDetailsService.findEntityById(entityId)
    const { includeOptions, maxLength, senderSourcePhone } = distributionDetails

    const sender = clearPlusFromPhoneNumber(senderSourcePhone || '')

    return {
      includeOptions,
      maxLength: maxLength.toString(),
      sender,
    }
  }
}

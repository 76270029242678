import { computed, ref } from '@/composition/vue/compositionApi'

export type TooltipData = Record<string, any>

type Tooltip = {
  id: string
  anchorEl: HTMLElement
  tooltipData: TooltipData
  update?: () => void
}

const _tooltips = ref<Record<string, Tooltip>>({})

export function useTooltipManager() {
  const tooltips = computed(() => _tooltips.value)

  const addTooltip = (id: string, tooltip: any) => {
    _tooltips.value[id] = tooltip
  }
  const removeTooltip = (id: string) => {
    delete _tooltips.value[id]
  }
  const setUpdatePositionCallback = (id: string, func: () => void) => {
    if (_tooltips.value[id]) {
      _tooltips.value[id].update = func
    }
  }
  const updatePosition = (id?: string) => {
    if (id && _tooltips.value[id]) {
      _tooltips.value[id].update?.()
    } else {
      Object.values(_tooltips.value).forEach((value: Tooltip) => value.update?.())
    }
  }

  return {
    tooltips,
    addTooltip,
    removeTooltip,
    setUpdatePositionCallback,
    updatePosition,
  }
}

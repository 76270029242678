import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum TM_TIPTAP_EDITOR_SERVICES {
  TmTiptapEditorsManager,
  TmTiptapEditorExtensionsSet,
  TmTiptapEditorExtensionsManager,
  TmTiptapEditorContentService,
  HtmlService,
}

export type TmTiptapEditorServices = keyof typeof TM_TIPTAP_EDITOR_SERVICES

export const TM_TIPTAP_EDITOR_SERVICES_TYPES =
  makeServicesTypesFromEnum<TmTiptapEditorServices>(TM_TIPTAP_EDITOR_SERVICES)

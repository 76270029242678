import { inject, injectable } from 'inversify'
import { pick } from 'lodash-es'
import { SERVICE_TYPES } from '@/core/container/types'
import type UserRepository from '@/data/repositories/domain/users/userRepository'
import User from '@/data/models/domain/User'
import { UserStatus } from '@/services/domain/user/types'
import type {
  CurrentUserCountryIdInterface,
  CurrentUserInterface,
  InviteSubAccountBody,
  SubAccountPutBody,
  UserDTO,
  UserPutBody,
  FetchSubAccountsWithPaginationRequestBody,
  UserRefusalSurveyParams,
} from '@/services/domain/user/types'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type UserPreloaderService from '@/services/preloaders/userPreloaderService'
import { TmDomainError } from '@/core/error/tmDomainError'
import TmLogicError from '@/core/error/tmLogicError'
import type { Autocompletable } from '@/services/types'
import type { EndpointParams } from '@/services/endpoints'
import type { PaginationUrlFilterType } from '@/services/tables/types'
import type { ModelRelationArray } from '@/services/domain/types'
import type CountryService from '@/services/domain/countryService'
import { ModelEventType } from '@/services/transport/types'
import type { MessageRetentionPeriodValue } from '@/services/domain/accounts/types'
import type { EntitySelectOption } from '@/services/forms/types'
import type { ModelRaw } from '@/types'
import type AuthService from '@/services/auth/authService'
import type { SubAccountFetchFilter } from '@/services/domain/accounts/subAccounts/types'
import type { BasePaginationUrlParams } from '@/services/tables/pagination/types'
import type Timezone from '@/data/models/domain/Timezone'
import type LoggerService from '@/services/loggerService'
import { TmRedirectError } from '@/core/error/transport/tmRedirectError'
import { isUrl } from '@/utils/string/isUrl'
import type WindowService from '@/services/browser/windowService'
import type BrowserIdentifierService from '@/services/browser/browserIdentifierService'

export const openAccountDomainEvent = 'openAccountDomainEvent'

@DomainSettings({
  model: User,
})
@injectable()
export default class UserService
  extends DomainBaseService<UserRepository>
  implements Autocompletable, CurrentUserInterface, CurrentUserCountryIdInterface
{
  private readonly statusesOfEnabledUser = [UserStatus.ST_ACTIVE, UserStatus.ST_TRIAL, UserStatus.ST_WIZARD]

  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.CountryService) protected readonly countryService: CountryService,
    @inject(SERVICE_TYPES.AuthService) protected readonly authService: AuthService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.BrowserIdentifierService) private readonly browserIdentifierService: BrowserIdentifierService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public readonly maxQuantityInvitesPerSingleRequest = 25

  public loadUser() {
    const user = this.currentUser()
    if (!user) {
      throw new TmLogicError('No authorized')
    }
  }

  public currentUser(withRelations: ModelRelationArray<User> = []): User {
    const currentUser = this.authService.getCurrentUser(withRelations)
    if (!currentUser) {
      throw new TmLogicError('Current user id not found')
    }
    return currentUser
  }

  public getCurrentUserOrNull(withRelations: ModelRelationArray<User> = []) {
    const currentUser = this.authService.getCurrentUser(withRelations)
    if (!currentUser) {
      return null
    }
    return currentUser
  }

  public currentUserId() {
    return this.authService.getCurrentUser()?.id ?? null
  }

  public getCurrentTimezone(): Timezone | undefined {
    try {
      const currentUser = this.currentUser(['timezone'])
      return currentUser.timezone
    } catch (error) {
      this.loggerService.error('exception', (error as TmLogicError).message)
      return undefined
    }
  }

  public isAllowWizardForCurrentUser() {
    const currentUser = this.currentUser()
    return currentUser.status === UserStatus.ST_WIZARD || currentUser.status === UserStatus.ST_TRIAL
  }

  public getCurrentUserCountryId() {
    return this.getDomainRepository().getCurrentUserCountryId()
  }

  public async refreshCurrentUser() {
    const user = this.currentUser()
    if (!user) {
      throw new TmDomainError('There is no user')
    }
    return this.preloaderManager.getPreloader<UserPreloaderService>(User).reloadCurrentUser(user.id)
  }

  public createPutBody(id: string, data: Partial<UserPutBody>): UserPutBody {
    const entityData = this.getDomainRepository().findEntityByIdOrFail(id)
    return {
      ...pick(entityData, ['username', 'firstName', 'lastName', 'email', 'phone', 'displayTimeFormat']),
      timezone: +entityData.timezoneId,
      ...data,
    }
  }

  public fillCurrentUser() {
    return this.getDomainRepository().fillCurrentUser()
  }

  public async updateCurrentUser(id: string, body: Partial<UserPutBody>) {
    const repo = this.getDomainRepository()
    await repo.updateCurrentUser(this.createPutBody(id, body), this.browserIdentifierService.getUid())
    return this.getDomainRepository().fillCurrentUser()
  }

  public updateCurrentUserInStore(user: Partial<Omit<UserDTO, 'id'>> & { isImpersonated?: boolean }): Promise<User> {
    const currentUserId = this.currentUser().id
    return this.getDomainRepository().updateCurrentUserInStore({ ...user, id: currentUserId })
  }

  public updateUserInStore(id: string, user: Partial<Omit<UserDTO, 'id' | 'timezone'>>) {
    return this.insertOrUpdate([{ ...user, id }])
  }

  public isFailedByToken() {
    return this.preloaderManager.getPreloader<UserPreloaderService>(User).isFailed()
  }

  public async autocomplete(
    search: string,
    endpointParams: EndpointParams,
    page: number,
    filters: PaginationUrlFilterType,
  ) {
    return this.preloaderManager
      .getAutocompletePreloader(User)
      .autocomplete(User, search, ['firstName', 'lastName', 'email'], endpointParams, page, filters)
  }

  public getUserById(userId: string, withRelations: ModelRelationArray<User> = []) {
    return this.getDomainRepository().query().with(withRelations).find(userId) as User
  }

  public isUserCountrySupportsAllSenderSettings() {
    return this.countryService.checkCountrySupportsAllSenderSettings(this.currentUser().countryId)
  }

  public hasUserPhone() {
    return !!this.currentUser().phone
  }

  public async updateSubAccountFromFormData(id: string, subAccount: SubAccountPutBody) {
    const result = await this.getDomainRepository().putForFormRequest({ id, ...subAccount })
    this.notify([id], ModelEventType.UPDATE)
    return result
  }

  public getVatNumber() {
    return this.getDomainRepository().getVatNumber()
  }

  public updateVatNumber(vatNumber: string) {
    return this.getDomainRepository().updateVatNumber(vatNumber)
  }

  public async inviteSubAccount(body: InviteSubAccountBody) {
    const result = await this.getDomainRepository().inviteSubAccount(body)
    this.notify([], ModelEventType.CREATE)
    return result
  }

  public async resendInvitationSubAccount(id: string, initialEmail: string, newEmail: string) {
    const isNewEmail: boolean = newEmail !== initialEmail
    await this.getDomainRepository().resendInvitationSubAccount({
      email: initialEmail,
      newEmail: isNewEmail ? newEmail : null,
    })
    if (isNewEmail) {
      await this.getPreloader().reloadById(User, id)
    }
  }

  public isStatusImpersonatable(status: UserStatus) {
    return (
      this.statusesOfEnabledUser.filter((userStatus) => userStatus !== UserStatus.ST_WIZARD) as UserStatus[]
    ).includes(status)
  }

  public isUserImpersonatable(userId: string): boolean {
    const user = this.getUserById(userId)
    return user && this.isStatusImpersonatable(user.status)
  }

  public isUserStatusEnabled(status: UserStatus) {
    return this.statusesOfEnabledUser.includes(status)
  }

  public getStatusesForActiveSubAccount(): Array<UserStatus> {
    return [...this.statusesOfEnabledUser, UserStatus.ST_SUB_INVITED]
  }

  public getStatusesForEnabledSubAccount(): Array<UserStatus> {
    return [...this.statusesOfEnabledUser]
  }

  public getStatusesForClosedSubAccount(): Array<UserStatus> {
    return [UserStatus.ST_CLOSED, UserStatus.ST_SUSPEND, UserStatus.ST_FRAUD]
  }

  public closeAccount(params: UserRefusalSurveyParams) {
    return this.getDomainRepository().closeAccount(params)
  }

  public async closeSubAccounts(accountIds: string[], all = false) {
    const currentUserId = this.currentUserId()
    if (!currentUserId) {
      throw new TmLogicError('current user id is null')
    }
    const isClosedCurrentUserAccount = accountIds.includes(currentUserId)
    await this.getDomainRepository().closeSubAccounts(accountIds, all)
    if (isClosedCurrentUserAccount) {
      return
    }
    this.notify(accountIds, ModelEventType.UPDATE, User)
  }

  public getMessagesRetentionPeriod() {
    return this.getDomainRepository().getMessagesRetentionPeriod()
  }

  public updateMessagesRetentionPeriod(value: MessageRetentionPeriodValue) {
    return this.getDomainRepository().updateMessagesRetentionPeriod(value)
  }

  public deleteAllMessages(password: string) {
    return this.getDomainRepository().deleteAllMessages(password)
  }

  public deleteAllContacts(password: string) {
    return this.getDomainRepository().deleteAllContacts(password)
  }

  public async openSubAccount(accountId: string) {
    await this.getDomainRepository().openSubAccount(accountId)
    this.notify([accountId], ModelEventType.UPDATE, User)
    this.subscription.emit(openAccountDomainEvent, {})
  }

  public getSubAccountsFacets(includeParent?: boolean) {
    return this.getDomainRepository().getSubAccountsFacets(includeParent)
  }

  public getUsersFacets(includeParent?: boolean) {
    return this.getDomainRepository().getUsersFacets(includeParent)
  }

  public fetchSubAccountsWithPagination(
    pagination: BasePaginationUrlParams,
    queryParameterBag: FetchSubAccountsWithPaginationRequestBody,
    searchQuery = '',
  ) {
    return this.getDomainRepository().fetchSubAccountsWithPagination(pagination, queryParameterBag, searchQuery)
  }

  public async getSubAccountsTotalCountByFilter(
    queryParameterBag: FetchSubAccountsWithPaginationRequestBody,
    searchQuery = '',
  ) {
    const res = await this.fetchSubAccountsWithPagination(
      {
        page: 1,
        perPage: 1,
      },
      queryParameterBag,
      searchQuery,
    )
    return res.pagination.totalCount
  }

  public async fetchSubAccounts(filter: SubAccountFetchFilter) {
    return this.getDomainRepository().fetchSubAccounts(filter)
  }

  public async fetchSubAccountsIfNotFound(userId: string) {
    const user = this.findEntityByIdOrNull(userId)
    if (user) return user
    await this.fetchSubAccounts({})
    return this.findEntityByIdOrNull(userId)
  }

  public async fetchAllSubAccounts(includeRemoved?: boolean) {
    const statuses = Object.values(UserStatus).filter((t) => t !== '')
    return this.fetchSubAccounts({
      statuses,
      includeRemoved,
    })
  }

  public getSubAccounts(filter: SubAccountFetchFilter) {
    const { id } = this.currentUser()
    return this.getDomainRepository().getSubAccounts(filter, id)
  }

  public acceptPrivacyPolicy() {
    return this.getDomainRepository().acceptPrivacyPolicy()
  }

  public preloadSubAccounts(filter: SubAccountFetchFilter) {
    const userPreloaderService = this.preloaderManager.getPreloader<UserPreloaderService>(User)
    return userPreloaderService.preloadSubAccounts(filter)
  }

  public runImpersonateMode(userId: string) {
    return this.getDomainRepository().runImpersonateMode(userId)
  }

  public async stopImpersonateMode() {
    try {
      await this.getDomainRepository().stopImpersonateMode()
    } catch (error) {
      if (error instanceof TmRedirectError && error.redirectUrl && isUrl(error.redirectUrl)) {
        this.windowService.redirect(error.redirectUrl)
      }

      throw error
    }
  }

  public getFullNameAndEmail(user: ModelRaw<User>) {
    const fullName = User.getFullName(user)
    if (fullName !== user.email) {
      return `${fullName} - ${user.email}`
    }

    return fullName
  }

  public castToOption(user: ModelRaw<User>): EntitySelectOption {
    return {
      text: this.getFullNameAndEmail(user),
      value: user.id,
      entity: user,
    }
  }

  public castListToOptions(list: ModelRaw<User>[]) {
    return list.map((item) => this.castToOption(item))
  }

  public isCurrentUser(userId: string) {
    return this.currentUser().id === userId
  }

  // Hom much extra money do we need to pay for something
  public calcNotEnoughSum(sum: number) {
    const currentUser = this.currentUser()
    return Math.max(0, sum - currentUser.balance)
  }

  public cancelPhoneVerification() {
    return this.getDomainRepository().cancelPhoneVerification()
  }

  public isNotEnoughCredit(price: number) {
    const currentUser = this.currentUser()
    const { balance } = currentUser
    return balance < price
  }

  public isAllowInviteSubAccount() {
    return this.currentUser().status !== UserStatus.ST_TRIAL
  }

  public async getQuantityUsers(includeParent: boolean, statuses: UserStatus[]) {
    const users = await this.getDomainRepository().gridRequest({
      filter: [],
      groupBy: {},
      sort: {},
      other: {
        statuses,
        includeParent,
      },
    })
    return users.pagination.totalCount
  }

  public findAssignableUsers() {
    return this.getDomainRepository()
      .all()
      .filter(
        (t) =>
          t.status === UserStatus.ST_ACTIVE ||
          t.status === UserStatus.ST_SUB_INVITED ||
          t.status === UserStatus.ST_TRIAL,
      )
  }

  public hasMinQuantityAssignableUsers() {
    return this.findAssignableUsers().length > 1
  }

  public getAssigneeList(lastId: string, perPage: number, searchQuery?: string) {
    return this.getDomainRepository().getAssigneeList(lastId, perPage, searchQuery)
  }

  public async verifyCurrentUserPhoneByCode(confirmationCode: string) {
    const currentUserId = this.currentUser().id
    return this.getDomainRepository().verifyPhoneByCode(currentUserId, confirmationCode)
  }
}

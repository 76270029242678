import { AttrField, BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'
import type {
  FilteredViewAliases,
  FilteredViewSharingType,
  FilteredViewsSupportedEntities,
} from '@/data/models/domain/filteredViews/types'
import type { PaginationUrlFilterNullableType, PaginationUrlParametersSortType } from '@/services/tables/types'
import type { ApiColumn } from '@/core/tables/types'

@OrmModel(modelNames.FILTERED_VIEW)
export default class FilteredView extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @NumberField(0)
  public count: number

  @AttrField()
  public filters: PaginationUrlFilterNullableType

  @AttrField([])
  public columns: ApiColumn[]

  @AttrField(null)
  public sort: null | PaginationUrlParametersSortType

  @StringField()
  public sharingType: FilteredViewSharingType

  @StringField()
  public entityName: FilteredViewsSupportedEntities

  @StringField()
  public icon: string

  @StringField()
  public alias: FilteredViewAliases | null

  @BooleanField()
  public isSystem: boolean

  @NumberField()
  public order: number

  @StringField()
  public userId: string

  @StringField()
  public updatedAt: string

  public get canBeEdit() {
    return !this.isSystem
  }

  public get isUnread() {
    return this.isSystem && this.name === 'Unread'
  }
}

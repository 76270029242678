import type { TmNamedRouteRecord } from '@/services/route/types'
import { scheduledFacetResolver } from '@/routes/user/scheduled/scheduled'
import { AppModule } from '@/config/types'
import { campaignsScheduledRoute } from '@/routes/user/campaigns/scheduled/campaignsScheduled'
import { campaignsAllRoute } from '@/routes/user/campaigns/campaignsAll'
import { campaignsSentRoute } from '@/routes/user/campaigns/campaignsSent'
import { campaignsArchivedRoute } from '@/routes/user/campaigns/campaignsArchived'
import { campaignsComposeRoute } from '@/routes/user/campaigns/campaignsCompose'
import { campaignsTemplatesRoute } from '@/routes/user/campaigns/campaignsTemplates'
import { campaignsSurveysRoute } from '@/routes/user/campaigns/surveys/campaignsSurveys'
import { campaignsSmsDetailsRoute } from '@/routes/user/campaigns/campaignsSms'

export const campaignsCalendarRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.calendar',
  path: 'calendar',
  component: () => import('@/components/views/campaigns/CampaignsCalendar.vue'),
  meta: {
    modules: [AppModule.SmsCampaigns],
    tabTitle: 'pageTitle.user.campaigns.calendar',
  },
}

export const campaignsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns',
  path: 'campaigns',
  component: () => import('@/components/views/campaigns/Campaigns.vue'),
  children: [
    campaignsComposeRoute,
    campaignsScheduledRoute,
    campaignsAllRoute,
    campaignsArchivedRoute,
    campaignsSentRoute,
    campaignsCalendarRoute,
    campaignsTemplatesRoute,
    campaignsSurveysRoute,
    campaignsSmsDetailsRoute,
  ],
  redirect: { name: campaignsComposeRoute.name },
  meta: {
    tags: ['USER_TOP_MENU'],
    accessControlGroups: ['CampaignsStrategy'],
    modules: [AppModule.Scheduled, AppModule.Templates],
    resolvers: [scheduledFacetResolver],
    menu: () => import('@/components/domain/campaigns/CampaignsMenu.vue'),
  },
}

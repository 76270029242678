import type { RouteLocationRaw } from 'vue-router'
import type { TmIconType, TmMenuItemBadge } from '@/components/shared/types'
import type { IconName } from '@/assets/icons/icons'
import type { Callback } from '@/types'

export type PageContentSidebarItemAction = {
  icon: IconName
  text: string
  name?: string
  id?: string // name and id need for correct event emit on front
}

export type PageContentSidebarLink<IdType = string, MetaType = Record<string, unknown>> = {
  itemType: 'link'
  title: string
  route: RouteLocationRaw
  tooltip?: string
  icon?: IconName
  rightIcon?: TmIconType
  number?: number | null
  numberLimit?: number | null
  id?: IdType
  isLoadingNumber?: boolean
  actions?: PageContentSidebarItemAction[]
  badge?: TmMenuItemBadge
  onClick?: Callback<unknown>
  allowed?: boolean
  disabled?: boolean
  overrideActive?: boolean
  meta?: MetaType
  hidden?: boolean
}

type PageContentSidebarGroupBase<Type extends string> = {
  itemType: Type
  title: string
  links: Array<PageContentSidebarLink | PageContentSidebarSimpleItem>
  icon?: IconName
  hidden?: boolean
}

export type PageContentSidebarGroup = PageContentSidebarGroupBase<'group'>
export type PageContentSidebarGroupCollapsible = PageContentSidebarGroupBase<'groupCollapsible'> & {
  isCollapsingDisabled?: boolean
}
export type PageContentSidebarSimpleItem = {
  id: string
  itemType: 'simple'
  title: string
  isActive?: boolean
  tooltip?: string
  number?: number | null
  redCounter?: boolean
  icon?: IconName
  iconType?: 'icon' | 'img'
  rightIcon?: TmIconType
  isLoadingNumber?: boolean
  badge?: TmMenuItemBadge
  allowed?: boolean
  disabled?: boolean
  hidden?: boolean
  comingSoon?: boolean
}

export type PageContentSidebarItem =
  | PageContentSidebarLink
  | PageContentSidebarGroup
  | PageContentSidebarGroupCollapsible
  | PageContentSidebarSimpleItem

export type PageContentSidebarItemTypes = PageContentSidebarItem['itemType']

export const isPageContentSidebarGroupCollapsible = (
  item: PageContentSidebarItem,
): item is PageContentSidebarGroupCollapsible => {
  return item.itemType === 'groupCollapsible'
}

<template>
  <div class="page-blocks">
    <page-scroll-container>
      <page-block-embed v-bind="$props">
        <template
          v-for="(slotName, index) of Object.keys($slots)"
          :key="index"
          v-slot:[slotName]
        >
          <slot :name="slotName" />
        </template>
      </page-block-embed>
    </page-scroll-container>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import PageScrollContainer from '@/components/layout/PageScrollContainer.vue'
import PageBlockEmbed from '@/components/layout/PageBlocksEmbed.vue'
import type { Breadcrumbs } from '@/components/shared/types'

export default defineComponent({
  components: {
    PageBlockEmbed,
    PageScrollContainer,
  },
  props: {
    fullWidth: {
      type: Boolean,
    },
    wideLeft: {
      type: Boolean,
    },
    wide: {
      type: Boolean,
    },
    noPadding: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    breadcrumbs: {
      type: Array as PropType<Breadcrumbs>,
    },
  },
})
</script>

<style lang="scss" scoped>
.page-blocks {
  background: $shade;
  height: 100%;
  position: relative;
}

.dropdown-btn.tm-btn {
  margin-right: 8px;
  padding-right: 4px;

  &:hover::before {
    background-color: $input;
    opacity: 1;
  }
}
</style>

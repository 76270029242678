import type { Ref, PropType } from '@/composition/vue/compositionApi'
import type { FieldType, SelectOption } from '@/services/forms/types'
import type { PaginationUrlFilterType, PaginationUrlParametersSortType } from '@/services/tables/types'
import type { ValidationRuleError } from '@/services/validation/types'

export type FieldComponent = new (...args: any) => {
  focus?: () => void
  blur?: () => void
}

export type FormFieldProps = {
  /**
   * @deprecated Should not be used, please consider messagePath param in validation config instead
   */
  errorCustomMessage: string
  type: FieldType
  required: boolean
  modelValue: any
  errors: ValidationRuleError[]
  invalid: boolean
  id: string
  name: string
  transparent?: boolean
  hideTransparentPlaceholder?: boolean
}

export type SelectPaginatedProps = {
  entityName: string
  perPage: number
  valueField: string
  textField: string
  preparer: ((item: any) => SelectOption) | undefined
  filter: PaginationUrlFilterType
  sort: PaginationUrlParametersSortType
}
export type AutocompletePaginatedProps = SelectPaginatedProps & {
  searchFields: string[] | undefined
}

export const fieldErrorProps = {
  field: {
    type: String,
    required: true,
  },
  errorCustomMessage: {
    type: String,
  },
  invalid: {
    type: Boolean,
    required: true,
  },
  errors: {
    type: Array as PropType<ValidationRuleError[]>,
    default: () => [],
  },
  serverErrors: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
}

export const formLeftLabelSymbol = Symbol('formLeftLabel')
export type FormLeftLabel = Ref<boolean | undefined>

export const formLeftLabelWidthSymbol = Symbol('formLeftLabelWidth')
export type FormLeftLabelWidth = Ref<string | undefined>

export const formDisabledSymbol = Symbol('formDisabled')
export type FormDisabled = Ref<boolean | undefined>

export const formInputRef = Symbol('formInputRef')
export type FormInputRef = {
  focus?: () => void
  blur?: () => void
}

export const fieldErrorsIncludeRulesPassword = [
  'required',
  'min:8',
  'max:48',
  'hasNumbers',
  'hasSpecialChars',
  'hasLowercaseAndUppercase',
]

export const fieldSenderPhoneRulePrefix = 'senderPhoneRulePrefix'
export const fieldInboxRulePrefix = 'fieldInboxRulePrefix'
export const fieldFacebookPageRulePrefix = 'fieldFacebookPageRulePrefix'
export const fieldInstagramAccountRulePrefix = 'fieldInstagramAccountRulePrefix'

import type { RouteRecordRaw } from 'vue-router'
import { ScheduledRoutes } from '@/routes/user/scheduled/types'
import { AppModule } from '@/config/types'

export const scheduledUpcomingRoutes: RouteRecordRaw = {
  name: ScheduledRoutes.upcoming,
  path: 'upcoming',
  redirect: { name: ScheduledRoutes.upcomingOverview },
  children: [
    {
      name: ScheduledRoutes.upcomingOverview,
      path: '',
      meta: {
        tags: ['SCHEDULED_BAR_LIST'],
        title: 'pageTitle.user.scheduled.upcoming',
        tabTitle: 'pageTabTitle.user.scheduled.upcoming',
        modules: [AppModule.ScheduledUpcoming],
        accessControlGroups: ['NotCampaignsScheduledUpcomingStrategy'],
      },
      component: () => import('@/components/views/scheduled/ScheduledUpcoming.vue'),
    },
  ],
}

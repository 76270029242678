import { injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import VoiceCallPrice from '@/data/models/domain/VoiceCallPrice'
import type { TmCountryCode } from '@/services/types'
import type VoiceCallPriceRepository from '@/data/repositories/domain/voiceCallPriceRepository'

@DomainSettings({
  model: VoiceCallPrice,
})
@injectable()
export default class VoiceCallPriceService extends DomainBaseService<VoiceCallPriceRepository> {
  public fetchPrices(): Promise<VoiceCallPrice[]> {
    return this.getDomainRepository().fillAll()
  }

  public getPrice(country: TmCountryCode): VoiceCallPrice {
    const repo = this.getDomainRepository()
    return repo.find(country)
  }

  public getPriceForDirection(
    country: TmCountryCode,
    dir: keyof Pick<VoiceCallPrice, 'inbound' | 'outbound' | 'forward'>,
  ): number {
    const repo = this.getDomainRepository()
    const price = repo.find(country)
    return price ? price[dir] : 0
  }

  public getPrices(): VoiceCallPrice[] {
    return this.getDomainRepository().all()
  }
}

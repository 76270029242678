import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import type SipCall from '@/data/models/domain/SipCall'
import { sipCallsRepoSettings } from '@/data/repositories/domain/types'
import SmsRepository from '@/data/repositories/domain/smsRepository'

@RepoSettings<Endpoint>(sipCallsRepoSettings)
@injectable()
export default class SipCallsRepository<T extends SipCall = SipCall> extends SmsRepository<T> {}

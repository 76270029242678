import {
  OrmModel,
  AttrField,
  StringField,
  NumberField,
  BooleanField,
  BelongsToField,
  HasManyByField,
} from 'vuex-orm-decorators'

import User from '@/data/models/domain/User'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'
import Contact from '@/data/models/domain/Contact'
import Country from '@/data/models/domain/Country'
import AutomationRule from '@/data/models/domain/services/automationRule/AutomationRule'
import AutomationRuleAction from '@/data/models/domain/services/automationRule/AutomationRuleAction'

import type { TmCountryCode } from '@/services/types'
import type {
  AutomationRuleLogsResponseType,
  AutomationRuleLogsStatus,
} from '@/services/domain/services/automationRule/types'

@OrmModel(modelNames.AUTOMATION_RULE_LOGS)
export default class AutomationRuleLog extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public sender: string

  @StringField()
  public receiver: string

  @StringField()
  public executedAt: string

  @StringField()
  public status: AutomationRuleLogsStatus

  @StringField()
  public affectedKeyword: string

  @AttrField()
  public responseType: AutomationRuleLogsResponseType | null

  @StringField()
  public responseParams: string

  @AttrField([], (value: number[]) => value?.map((item) => item.toString()))
  public actionIds: string[]

  @HasManyByField(AutomationRuleAction, 'actionIds')
  public actions: AutomationRuleAction[]

  @StringField()
  public userId: string

  @BelongsToField(User, 'userId')
  public user: User

  @StringField()
  public contactId: string

  @BelongsToField(Contact, 'contactId')
  public contact: Contact

  @StringField()
  public messageInText: string

  @StringField()
  public messageOutText: string

  @NumberField()
  public messageOutPrice: number

  @StringField()
  public ruleId: string

  @StringField()
  public label: string

  @BelongsToField(AutomationRule, 'ruleId')
  public rule: AutomationRule

  @AttrField()
  public countryId: TmCountryCode

  @BelongsToField(Country, 'countryId')
  public country: Country

  @BooleanField()
  public isDeleted: boolean
}

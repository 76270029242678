import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

export enum CHAT_SERVICES {
  BaseSmsHelperService,
  ChatSettingsFormService,
  ChatConversationSenderSettingsFormService,
  ChatConversationSenderSettingsService,
  ChatService,
  ChatMessageService,
  ChatMessageContentService,
  ChatConversationSenderSettingsRepository,
  ChatRepository,
  ChatMessageRepository,
  ChatMessageContentRepository,
  ChatResolver,
  ChatPageInfoRepository,
  ChatPageInfoService,
  ChatMessageResolverService,
  ChatMmsInfoRepository,
  ChatMmsInfoService,
  ChatInfoSidebarFormService,
  ChatMessageThreadRepository,
  ChatMessageThreadService,
  ChatAssignFormService,
  ChatRejectFormService,
  WidgetChatCentrifugeTokenRepository,
  WidgetChatCentrifugeTokenService,
  WidgetChatServerSubscriptionService,
  ChatFilterServiceFactory,
  ChatDetailsService,
  ChatDetailsRepository,
}

export type ChatServices = keyof typeof CHAT_SERVICES

export const CHAT_SERVICE_TYPES: Record<ChatServices, symbol> = makeServicesTypes<ChatServices>(
  enumKeysToArray<ChatServices>(CHAT_SERVICES),
)

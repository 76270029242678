import { inject, injectable } from 'inversify'
import type { RouteLocationNormalized, Router } from 'vue-router'
// TODO: Fix ignored tp/forbid-import-composable-to-service
// eslint-disable-next-line tp/using-vue-in-services-restriction,tp/forbid-import-composable-to-service
import type { Plugin } from '@/composition/vue/compositionApi'
import type { ExternalTrackerAsPlugin, TrackableConfig } from '@/services/tracking/types'
import { GoogleAnalyticsEvent } from '@/services/tracking/types'
import { SERVICE_TYPES } from '@/core/container/types'
import ConfigReader from '@/core/configReader'
import type RouterService from '@/services/route/routerService'
import type SubscriptionService from '@/services/transport/subscriptionService'
import type UserAnalyticsFieldsService from '@/services/domain/user/userAnalyticsFieldsService'
import type UserAnalyticsFields from '@/data/models/domain/UserAnalyticsFields'
import { ROUTER_AFTER_EACH } from '@/services/route/types'
import ExternalTrackerService from '@/services/tracking/externalTrackerService'
import type SyncQueueService from '@/services/tracking/syncQueueService'
import type LoggedInStatusService from '@/services/auth/loggedInStatusService'
import type LoggerService from '@/services/loggerService'
import type { TmRoutes } from '@/services/route/routerTypes'

@injectable()
export default class GoogleAnalyticsService extends ExternalTrackerService {
  private isTrackerInitialized = false

  private syncQueueService: SyncQueueService

  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.UserAnalyticsFieldsService)
    protected readonly userAnalyticsFieldsService: UserAnalyticsFieldsService,
    @inject(SERVICE_TYPES.SyncQueueService) protected readonly getSyncQueueService: () => SyncQueueService,
    @inject(SERVICE_TYPES.LoggedInStatusService) protected readonly loggedInStatusService: LoggedInStatusService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {
    super()

    this.syncQueueService = getSyncQueueService()
  }

  public getConfig(): TrackableConfig {
    const config = ConfigReader.config()

    return {
      id: config.tracking.googleAnalyticsMeasurementKey,
    }
  }

  public getPlugin(): Plugin {
    return {
      install: () => this.initialize(),
    } as Plugin
  }

  public trackEvent(...args: unknown[]) {
    if (this.isTrackerInitialized) {
      this.pushToDataLayer(...args)
    } else {
      this.syncQueueService.enqueue(args)
    }
  }

  public trackConversion(event: GoogleAnalyticsEvent): void {
    const trackEvent = this.trackEvent.bind(this)
    function gtag() {
      /* eslint-disable */
      trackEvent(arguments)
    }
    const payload = { send_to: this.getSendTo(event) }
    if (event === GoogleAnalyticsEvent.firstPayment) {
      payload['transaction_id'] = ''
    }
    // @ts-ignore
    gtag('event', 'conversion', payload)
  }

  public trackSignUp(signUpMethod: string) {
    const trackEvent = this.trackEvent.bind(this)
    function gtag() {
      /* eslint-disable */
      trackEvent(arguments)
    }
    // @ts-ignore
    gtag('event', 'sign_up', { method: signUpMethod })
  }

  public getSendTo(event: GoogleAnalyticsEvent): string {
    return `${this.getConfig().id}/${event}`
  }

  protected initialize(): void {
    const measurementKey = this.getConfig().id
    const trackUser = (isNotAuthenticatedRoute?: boolean) => {
      this.track(isNotAuthenticatedRoute)
    }

    const trackingHandler = async () => {
      const router: Router = this.routerService.getRouter()
      await router.isReady()
      trackUser()
      this.subscriptionService.subscribe(
        ROUTER_AFTER_EACH,
        (e: RouteLocationNormalized) => {
          const ignoreRoutes: string[] = ['auth.login', 'auth.logout'] satisfies TmRoutes['name'][]
          const isNotAuthenticatedRoute = typeof e.name === 'string' && ignoreRoutes.includes(e.name)
          trackUser(isNotAuthenticatedRoute)
        },
        true,
      )
    }
    const trackerAsPlugin = {
      src: `https://www.googletagmanager.com/gtag/js?id=${measurementKey}`,
      trackingHandler,
    } as ExternalTrackerAsPlugin
    this.initializeTrackerAsPlugin(trackerAsPlugin)
  }

  protected track(isNotAuthenticatedRoute?: boolean): void {
    const measurementKey = this.getConfig().id
    const userAnalyticsFieldsService: UserAnalyticsFieldsService = this.userAnalyticsFieldsService
    const userLoggedIn = () => this.loggedInStatusService.isUserLoggedIn()

    const pushToDataLayer = this.pushToDataLayer.bind(this)

    // Do not change this! Use arguments not ...args because of GA initialization as function() and it's scope of vision
    function gtag() {
      /* eslint-disable */
      pushToDataLayer(arguments)
    }

    const getSyncQueueService = this.getSyncQueueService.bind(this)
    const setInitializedFlag = () => {
      this.isTrackerInitialized = true
    }

    ;(function (w, l) {
      w[l] = w[l] || []
      // @ts-expect-error
      gtag('js', new Date())

      getSyncQueueService().processEvents(gtag)
      setInitializedFlag()

      if (userLoggedIn() && !isNotAuthenticatedRoute) {
        const analyticsFields: UserAnalyticsFields = userAnalyticsFieldsService.getAnalyticsFields()
        const gaClientId = analyticsFields?.gaClientId
        const gaSessionId = analyticsFields?.gaSessionId
        const gaSessionNumber = analyticsFields?.gaSessionNumber
        if (gaClientId) {
          // @ts-expect-error
          gtag('config', measurementKey, { client_id: gaClientId })
        }
        const tagClientId = new Promise((resolve) => {
          // @ts-expect-error
          gtag('get', measurementKey, 'client_id', resolve)
        })
        const tagSessionId = new Promise((resolve) => {
          // @ts-expect-error
          gtag('get', measurementKey, 'session_id', resolve)
        })
        const tagSessionNumber = new Promise((resolve) => {
          // @ts-expect-error
          gtag('get', measurementKey, 'session_number', resolve)
        })
        Promise.all([tagClientId, tagSessionId, tagSessionNumber]).then(async (data) => {
          const gaClientIdUpdated = data[0] ?? null
          // eslint-disable-next-line eqeqeq
          if (
            (gaClientIdUpdated && gaClientId != gaClientIdUpdated) ||
            (data[1] && gaSessionId != data[1]) ||
            (data[2] && gaSessionNumber != data[2])
          ) {
            await userAnalyticsFieldsService.updateFields({
              gaClientId: gaClientIdUpdated,
              gaSessionId: data[1],
              gaSessionNumber: data[2],
            } as UserAnalyticsFields)
          }
        })
      } else {
        // @ts-expect-error
        gtag('config', measurementKey)
      }
    })(window, 'dataLayer')
  }

  private pushToDataLayer(...args: unknown[]) {
    try {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['dataLayer'].push(...args)
    } catch (e) {
      this.loggerService.error('error', (e as Error).message)
    }
  }
}

/* eslint-disable no-else-return */
import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { UniqueKeys } from '@/types'
import type LoggerService from '@/services/loggerService'
import type ResolverManager from '@/services/resolvers/resolverManager'
import type { PromiseExecutorPoolManager } from '@/services/promiseExecutorPoolManager'
import type { ResolverConfig } from '@/services/types'
import type { Route } from '@/services/route/types'
import type { LoggerChannels } from '@/config/configDev'
import type { ResolverExecutorServiceInterface } from '@/services/resolvers/types'

const enum ResolverExecutorPoolItemType {
  Resolve,
  Unresolve,
}

@injectable()
export class ResolverExecutorService implements ResolverExecutorServiceInterface {
  private resolvePromiseKey = Symbol('resolver:resolve')

  private unresolvePromiseKey = Symbol('resolver:unresolve')

  constructor(
    @inject(SERVICE_TYPES.PromiseExecutorPoolManager) protected readonly poolManager: PromiseExecutorPoolManager,
    @inject(SERVICE_TYPES.ResolverManager) protected readonly resolverManager: ResolverManager,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {}

  public async resolve(config: ResolverConfig, route?: Route, fromRoute?: Route): Promise<unknown> {
    this.log('Resolver execution requested…', config)

    const resolver = this.getResolver(config)
    const resolverKeys: UniqueKeys = resolver.getResolverKeys?.(config.params, route, fromRoute) ?? []
    const hasResolverKeys = resolverKeys.length > 0

    if (!hasResolverKeys) {
      this.log('Resolver keys is empty, resolving the default way')
      return resolver.resolve(config.params, route, fromRoute)
    } else {
      const resolvePromiseKeys = this.getPromiseKeys(ResolverExecutorPoolItemType.Resolve, resolverKeys)

      if (this.poolManager.hasPromiseInPool(resolvePromiseKeys)) {
        this.log('Resolver already resolving, returning existing promise')
        return this.poolManager.getPromiseFromPool(resolvePromiseKeys)
      } else {
        this.log('Resolver is not resolving yet, creating new promise')
        try {
          const promise = resolver.resolve(config.params, route, fromRoute)
          this.poolManager.addPromiseToPool(resolvePromiseKeys, promise)
          if (resolver.isPersistent?.()) {
            this.log('Resolver is marked as persistent, keeping promise in the pool', config)
          } else {
            promise.finally(() => {
              this.log('Promise resolve is fulfilled, removing from pool', config)
              this.poolManager.removePromiseFromPool(resolvePromiseKeys)
            })
          }
          return await promise
        } catch (e) {
          if (e instanceof Error) {
            this.log("Can't resolve promise", config)
          }

          return null
        }
      }
    }
  }

  public async unresolve(config: ResolverConfig, route?: Route, fromRoute?: Route): Promise<unknown> {
    this.log('Resolver unresolve requested…', config)
    const resolver = this.getResolver(config)

    const resolverKeys: UniqueKeys = resolver.getResolverKeys?.(config.params, route, fromRoute) ?? []
    const hasResolverKeys = resolverKeys.length > 0

    if (!hasResolverKeys) {
      this.log('Resolver keys is empty, unresolving the default way')
      return resolver.unresolve ? resolver.unresolve(config.params, route, fromRoute) : Promise.resolve()
    } else {
      const unresolvePromiseKeys = this.getPromiseKeys(ResolverExecutorPoolItemType.Unresolve, resolverKeys)

      if (this.poolManager.hasPromiseInPool(unresolvePromiseKeys)) {
        this.log('Resolver already unresolving, returning existing promise')
        return this.poolManager.getPromiseFromPool(unresolvePromiseKeys)
      } else {
        this.log('Resolver is not unresolving yet, creating new promise')

        const promise = resolver.unresolve ? resolver.unresolve(config.params, route, fromRoute) : Promise.resolve()
        this.poolManager.addPromiseToPool(unresolvePromiseKeys, promise)

        promise.finally(() => {
          this.log('Promise unresolve is fulfilled, removing from pool', config)
          this.poolManager.removePromiseFromPool(unresolvePromiseKeys)

          if (resolver.isPersistent?.()) {
            this.log('Persistent promise unresolve is fulfilled, removing resolve record from pool', config)
            this.poolManager.removePromiseFromPool(
              this.getPromiseKeys(ResolverExecutorPoolItemType.Resolve, resolverKeys),
            )
          }
        })

        return promise
      }
    }
  }

  protected getResolver(config: ResolverConfig) {
    return this.resolverManager.getResolver(config.service)
  }

  protected getPromiseKeys(type: ResolverExecutorPoolItemType, resolverKeys: UniqueKeys) {
    return [
      type === ResolverExecutorPoolItemType.Resolve ? this.resolvePromiseKey : this.unresolvePromiseKey,
      ...resolverKeys,
    ]
  }

  private log(message: string, config?: ResolverConfig) {
    const channel: LoggerChannels = 'resolver'
    this.loggerService.log(channel, message, 'ResolverExecutorService')
    if (config) {
      this.loggerService.raw(channel, config)
    }
  }
}

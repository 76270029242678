import type { ComputedRef, Ref } from '@/composition/vue/compositionApi'
import { ref, nextTick, computed } from '@/composition/vue/compositionApi'
import { getChatService } from '@/core/container/ioc'
import type Chat from '@/data/models/domain/chats/Chat'
import { ChatType, PromoChatCookieName, PromoChatState } from '@/data/models/domain/chats/types'
import { getCookieByName } from '@/utils/cookies'

type TActiveChat = {
  onBeforeChatLeave: Ref<(() => boolean) | undefined>
  setOnBeforeChatLeave: (fn?: () => boolean) => void
  setActiveChat: (id: string, scrollToActive?: boolean) => void
  resetActiveChat: () => void
  incScrollToActiveChatCounter: () => void
  activeChat: ComputedRef<Chat | null>
  chatId: Readonly<Ref<string>>
  chatToActivate: Ref<Chat | null>
  activeChatType: ComputedRef<ChatType>
  activeChatName: ComputedRef<string>
  scrollToActiveChatCounter: Readonly<Ref<number>>
  isPromoChatSolved: Ref<boolean>
}
const isPromoChatSolved = ref(getCookieByName(PromoChatCookieName) === PromoChatState.Solved)
const activeChatId = ref('')
const activeChatType = computed(() => getChatService().getChat(activeChatId.value)?.type ?? ChatType.SMS)
const activeChat = computed(() => {
  return getChatService().getChat(activeChatId.value)
})
const activeChatName = computed(() => {
  return (activeChat.value?.contact ? activeChat.value.contact.fullName : activeChat.value?.phone) ?? ''
})
const chatToActivate = ref<Chat | null>(null)
const scrollToActiveChatCounter = ref(0)
const onBeforeChatLeave = ref<(() => boolean) | undefined>()

const setActiveChat = (id: string, scrollToActive = true) => {
  activeChatId.value = id
  if (scrollToActive) {
    incScrollToActiveChatCounter()
  }
}

const incScrollToActiveChatCounter = () => {
  nextTick(() => {
    scrollToActiveChatCounter.value += 1
  })
}

const resetActiveChat = () => {
  activeChatId.value = ''
}

const setOnBeforeChatLeave = (fn?: () => boolean) => {
  onBeforeChatLeave.value = fn
}

export const useActiveChat = (): TActiveChat => ({
  onBeforeChatLeave,
  setOnBeforeChatLeave,
  setActiveChat,
  resetActiveChat,
  incScrollToActiveChatCounter,
  activeChat,
  chatId: activeChatId,
  chatToActivate,
  activeChatType,
  activeChatName,
  scrollToActiveChatCounter,
  isPromoChatSolved,
})

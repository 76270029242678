import { inject, injectable } from 'inversify'
import type { RegisteredServices } from '@/core/container/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { ExportResultResponse, ExportToPdfResultResponse, BlobResponse } from '@/services/transport/types'
import type { ExportBody, Exportable, ExportAvailableExtensions } from '@/services/types'
import type { EndpointParams } from '@/services/endpoints'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type BaseModel from '@/data/models/BaseModel'
import type OrmApiRepository from '@/data/repositories/ormApiRepository'
import { createAndDownloadFile } from '@/utils/file/createAndDownloadFile'
import type TableManager from '@/services/tables/tableManager'

@injectable()
export default class ExportService implements Exportable {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.TableManager) protected readonly tableManager: TableManager,
  ) {}

  public async exportByEntity(
    entity: typeof BaseModel,
    params: ExportBody,
    tableServiceId?: RegisteredServices,
    endpointParams?: EndpointParams,
  ): Promise<ExportResultResponse> {
    const repo = this.em.getRepository(entity) as OrmApiRepository<any, any>
    return repo.exportRequest(params, endpointParams)
  }

  public downloadFile(response: BlobResponse): void {
    const fileName = /filename(?:=|=")([^";\n]+)(?:";?)?/i.exec(response.headers['content-disposition'])![1]
    createAndDownloadFile(response.data, fileName)
  }

  public async exportToPdf(id: string, entity: typeof BaseModel): Promise<ExportToPdfResultResponse> {
    const repo = this.em.getRepository(entity) as OrmApiRepository<any, any>
    return repo.exportToPdfRequest(id)
  }

  public async export(
    extension: ExportAvailableExtensions,
    entity: typeof BaseModel,
    tableServiceId?: RegisteredServices,
    endpointParams?: EndpointParams,
    email?: string,
  ): Promise<ExportResultResponse> {
    if (tableServiceId) {
      return this.tableManager.getTable(tableServiceId).export(extension, email)
    }

    const params: ExportBody = {
      searchQuery: '',
      fileFormat: extension,
    }
    if (email) {
      params.email = email
    }

    return this.exportByEntity(entity, params, tableServiceId, endpointParams)
  }
}

// eslint-disable-next-line tp/forbid-import-composable-to-service,tp/using-vue-in-services-restriction
import type { Ref } from '@/composition/vue/compositionApi'
import type { TmWrappers } from '@/wrappers'
import type MessagesAttachmentFile from '@/data/models/domain/MessagesAttachmentFile'
import type { Months, WeekDays, WeekDaysFull, ServicePhoneType, TmCountryCode } from '@/services/types'
import type { SmsInfo } from '@/utils/smsInfo'
import type MessagesSendFormService from '@/services/forms/messages/messagesSendFormService'
import type { InteractiveOption } from '@/components/shared/types'
import type { Dict, ModelRaw, Nullable } from '@/types'
import type { BaseScheduleFormScheme } from '@/services/forms/baseScheduleFormService'
import type MessagesPrice from '@/data/models/domain/MessagesPrice'
import type { SingleModelResolverParams } from '@/services/resolvers/types'
import type { ChatDirection, ChatType } from '@/data/models/domain/chats/types'
import type BaseModel from '@/data/models/BaseModel'
import type { AllAvatarColors, DefaultAvatarColors } from '@/constants/avatar'
import type ContactList from '@/data/models/domain/ContactList'
import type Contact from '@/data/models/domain/Contact'
import type { IconName } from '@/assets/icons/icons'
import type { TransportResponse } from '@/services/transport/types'
import type FilteredView from '@/data/models/domain/filteredViews/FilteredView'

export enum MessagesPriceCreationSource {
  CHAT = 'C',
}

export type MessagesPricePostBody = {
  contacts: string
  lists: string
  filteredViews: string
  phones: string
  text: string
  from: string | null
  sendingDateTime?: string
  sendingTimezone?: string
  rrule?: string
  templateId?: number
  resources?: string
  destination?: 'tts' | 'mms'
  cutExtra?: number
  includeBlocked?: boolean
  fromType?: ServicePhoneType | null
  sourceType?: string
  creationSource?: MessagesPriceCreationSource
}

export type MessagesPostResponse = {
  id: number
  href: string
  type: string
  sessionId: number
  bulkId: number
  messageId: number
  scheduleId: number
  chatId: number
}

export type MessagesPriceCountry = {
  allowDedicated: boolean
  count: number
  landline: number
  max: number
  countryId: string
  countryName: string
  sum: string
}

export type MessagesPriceCountries = Nullable<Dict<MessagesPriceCountry>>

export type MessagesPriceSendResponseData = {
  id: number
  messageId: number | null
  scheduleId: number | null
  bulkId: number | null
  sessionId: number | null
}
export type MessagesPriceSendResponseBody = TransportResponse<MessagesPriceSendResponseData>

export type MessagesPriceSpinners = 'recipients' | 'message'

export const symbolsLimitForCopying = 5000
export const bulkSessionNumber = 100
export const largeBulkSessionNumber = 1000
export const canadianRecipientsNumber = 500
export const contactListTempSize = 250
export const boundaryForContactListTemp = 100

type AvatarUrl = { url: string }
type AvatarInitials = { initials: string }
type AvatarIcon = {
  customIcon: IconName
  color: AllAvatarColors
}

export type RecipientAvatar = AvatarUrl | AvatarInitials | AvatarIcon

export type RecipientType = 'contact' | 'list' | 'reply' | 'filtered_view'

export type Recipient = {
  entityType: RecipientType
  entityId: number
  title: string
  value: string
  countryName: string
  avatarHref?: string
  sharedBy?: string
}

export type RecipientOption = {
  entityType: RecipientType | 'raw'
  label: string
  title?: string
  value: string
  entityId: number
  avatar?: RecipientAvatar
  hint?: string | boolean
  nameAsPhone?: boolean
  shortHint?: string
  sharedBy?: string
  isTemporary?: boolean
}

export type TInteractiveRecipientOption = InteractiveOption & RecipientOption

export interface IRecipientListProperties {
  id: string
  name: string
  membersCount: number
  isTemporary?: boolean
}

export interface IRecipientContactProperties {
  id: string
  fullName: string
  phone: string
}

export const recipientsFieldName = 'recipients'
export const senderFieldName = 'from'

export const messagesAttachmentFileModalId: TmWrappers = 'messagesAttachmentFileModal'
export const messagesAttachmentFilePreviewModalId: TmWrappers = 'messagesAttachmentFilePreviewModal'
export const messagesAttachmentSelectModalId: TmWrappers = 'messagesAttachmentSelectModal'

export type MessagesAttachmentFileResponseBody = {
  status: 201
  data: MessagesAttachmentFile
}

export const repeatTypeVariant = ['none', 'hourly', 'daily', 'weekly', 'monthly', 'yearly'] as const
export type RepeatType = (typeof repeatTypeVariant)[number]

export const endTypeVariant = ['never', 'after', 'date'] as const
export type EndType = (typeof endTypeVariant)[number]

export const ordinalDayVariant = ['First', 'Second', 'Third', 'Fourth', 'Last'] as const
export type OrdinalDay = (typeof ordinalDayVariant)[number]

export type MessageSendFormScheme = {
  recipients: RecipientOption[]
  text: any
  from: any
} & BaseScheduleFormScheme<{ repeat: MessageSendRepeatFormScheme }>

export interface MessageSendEndFormScheme {
  type: EndType
  smsSessions: number
  date: string
}

export interface MessageSendRepeatFormScheme {
  type: RepeatType
  end: MessageSendEndFormScheme
  hourly: MessageSendRepeatHourlyFormScheme
  daily: MessageSendRepeatDailyFormScheme
  weekly: MessageSendRepeatWeeklyFormScheme

  monthly: MessageSendRepeatMonthlyFormScheme

  yearly: MessageSendRepeatYearlyFormScheme
}

export interface MessageSendRepeatTypeFormScheme {
  count: number
}

export interface MessageSendRepeatHourlyFormScheme extends MessageSendRepeatTypeFormScheme {}

export interface MessageSendRepeatDailyFormScheme extends MessageSendRepeatTypeFormScheme {}

export interface MessageSendRepeatWeeklyFormScheme extends MessageSendRepeatTypeFormScheme {
  days: WeekDays[]
}

export interface MessageSendRepeatMonthlyFormScheme extends MessageSendRepeatTypeFormScheme {
  repeat: 'month-day' | 'month-float-day'
  monthDay: string
  firstLastDay: OrdinalDay
  weekDay: WeekDaysFull
}

export interface MessageSendRepeatYearlyFormScheme extends MessageSendRepeatTypeFormScheme {
  type: 'year-day' | 'year-float-day'
  month: Months
  monthDay: string
  firstLastDay: OrdinalDay
  weekDay: WeekDaysFull
  dayOfMonth: Months
}

export type MessagesInfo = {
  shortLink: Ref<string>
  isMaxLength: Ref<boolean>
  isNotEnoughBalance: Ref<boolean>
  isByocPlanLimitExceed: Ref<boolean>
  isBulkSession: Ref<boolean>
  isLargeBulkSession: Ref<boolean>
  are500canadianNumbersSelected: Ref<boolean>
  hasAmericanRecipients: Ref<boolean>
  deficitCreditAmount: Ref<number>
  smsInfo: Ref<SmsInfo>
}

export type MessagesInfoUpdates = {
  updateTotalCost: () => void
  updateRecipientsCount: (formService: MessagesSendFormService) => void
  updateMessage: (message: string) => void
}

export type TUseMessagesInfo = MessagesInfo &
  MessagesInfoUpdates & {
    watchUpdateRecipientsCount: (formService: MessagesSendFormService) => void
    handleSubmitError: (error: unknown) => void
  }

export type MessagesPreviewRecipient = {
  id: number
  contactId: number
  sessionId: number
  charset: string
  charsetLabel: string
  avatar: string
  receiver: string
  sender: string
  messageTime: string
  deleted: number
  firstName: string
  lastName: string
  countryId: TmCountryCode
  price: number
  partsCount: number
  status: string
  text: string
}

export type TScheduleData = {
  sendingDateTime: string
  sendingTimezone: string
  rrule: string | null
}
export type MessageContactRecipient = {
  id: number
  fullName: string
  phone: string
}
export type MessageListRecipient = {
  id: string
  name: string
  membersCount: number
}
export const messageFieldName = 'text'

export enum MessagesBulksProgressStatus {
  WAITING = 'w',
  COMPLETED = 'c',
  FAILED = 'f',
  NOT_ENOUGH_MONEY = 'l',
}

export type MessageTypeSerialized = Pick<MessagesPrice, 'total' | 'countries' | 'loading'>

export type MessageResolverParams = Pick<SingleModelResolverParams, 'id' | 'source'> & {
  direction?: ChatDirection
  modelType?: typeof BaseModel
  chatType?: ChatType
}

export type ParticipantContact = {
  type: 'contact'
  contact: Omit<ModelRaw<Contact>, 'isUnsubscribedByPhone'>
  avatar?: string
  avatarColor?: DefaultAvatarColors
}
export type ParticipantDeletedContact = {
  type: 'deletedContact'
  fullName: string
  phone: string
  avatarColor: DefaultAvatarColors
}
export type ParticipantSenderId = {
  type: 'senderId'
  name: string
}
export type ParticipantFullName = {
  type: 'fullName'
  fullName: string
}
export type ParticipantContactList = {
  type: 'contactList'
  entity: ContactList
  avatarColor?: DefaultAvatarColors
}
export type ParticipantFilteredView = {
  type: 'filteredView'
  entity: FilteredView
  avatarColor?: DefaultAvatarColors
}
export type ParticipantPhone = {
  type: 'phone'
  phone: string
  avatarColor?: DefaultAvatarColors
}
export type ParticipantRecipients = {
  type: 'recipients'
  name?: string
  count: number
}

export type ParticipantWhatsAppPhone = {
  type: 'whatsAppPhone'
  phone: string
  avatarColor?: DefaultAvatarColors
}

export type ParticipantFacebook = {
  type: 'facebook'
  pageId: string
  avatarColor?: DefaultAvatarColors
}

export type ParticipantInstagram = {
  type: 'instagram'
  accountId: string
  avatarColor?: DefaultAvatarColors
}

export type ParticipantInfo =
  | ParticipantContact
  | ParticipantDeletedContact
  | ParticipantContactList
  | ParticipantFilteredView
  | ParticipantPhone
  | ParticipantRecipients
  | ParticipantSenderId
  | ParticipantFullName
  | ParticipantWhatsAppPhone
  | ParticipantFacebook
  | ParticipantInstagram

export type RecipientPreviewData = {
  value: string
  phone: string | undefined
  fullName: string
  contactId: number
}

export type SenderPreviewData = {
  value: string
  phone: string | undefined
  countryId: TmCountryCode | null | undefined
}

export type PreviewData = {
  sender: SenderPreviewData
  recipient: RecipientPreviewData
  text: string
}

import { inject, injectable } from 'inversify'
import { cloneDeep } from 'lodash-es'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type ServerSubscriptionService from '@/services/transport/serverSubscriptionService'
import type { TServerEvent } from '@/services/transport/serverEvents'
import type UserService from '@/services/domain/user/userService'
import type UserSettingsService from '@/services/domain/user/userSettingsService'

@injectable()
export default class SubscriptionEventsResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.ServerSubscriptionService)
    private readonly serverSubscriptionService: ServerSubscriptionService,
    @inject(SERVICE_TYPES.UserService)
    private readonly userService: UserService,
    @inject(SERVICE_TYPES.UserSettingsService) protected readonly userSettingsService: UserSettingsService,
  ) {}

  private getCurrentUserSubscriptions() {
    return cloneDeep(this.userSettingsService.currentUserSettingsOrFail().subscriptions)
  }

  public async resolve() {
    this.serverSubscriptionService.subscribe('userSubscriptionChanged', this.userSubscriptionChangedEventHandler)
    this.serverSubscriptionService.subscribe('userSubscriptionDeleted', this.userSubscriptionDeletedEventHandler)
  }

  private userSubscriptionChangedEventHandler = (_e: TServerEvent<'userSubscriptionChanged'>) => {
    // @todo temporare, will be removed after fix event payload
    return this.userService.fillCurrentUser()
    // const subscriptions = this.getCurrentUserSubscriptions()
    // const index = subscriptions.findIndex((t) => t.name === e.payload.subscription.name)
    // if (index === -1) {
    //   subscriptions.push(e.payload.subscription)
    // } else {
    //   subscriptions[index] = e.payload.subscription
    // }
    // return this.userService.updateCurrentUserInStore({
    //   subscriptions,
    // })
  }

  private userSubscriptionDeletedEventHandler = (e: TServerEvent<'userSubscriptionDeleted'>) => {
    const subscriptions = this.getCurrentUserSubscriptions()
    const index = subscriptions.findIndex((t) => t.name === e.payload.subscription.name)
    if (index === -1) {
      return
    }
    subscriptions.splice(index, 1)
    this.userSettingsService.updateCurrentUserSettingsInStore({
      subscriptions,
    })
  }

  public async unresolve() {
    this.serverSubscriptionService.unsubscribe('userSubscriptionChanged', this.userSubscriptionChangedEventHandler)
    this.serverSubscriptionService.unsubscribe('userSubscriptionDeleted', this.userSubscriptionDeletedEventHandler)
  }
}

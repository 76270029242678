import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type {
  IWorkspaceServerEventsMap,
  ServerEventBase,
  IWorkspaceServerPubSub,
} from '@/services/transport/serverEvents'
import { getWorkspaceChannelName } from '@/services/transport/types'
import type UserSettingsService from '@/services/domain/user/userSettingsService'
import type WebSocketChannelsService from '@/services/transport/webSocketChannelsService'
import type { CentrifugeTokensResponse } from '@/services/domain/accounts/types'
import type CentrifugeService from '@/services/domain/accounts/CentrifugeService'

type EventListeners<T> = {
  [P in keyof T]: ((event: ServerEventBase<P, T[P]>) => void)[]
}

@injectable()
export default class WorkspaceServerSubscriptionService implements IWorkspaceServerPubSub {
  constructor(
    @inject(SERVICE_TYPES.WebSocketChannelsServiceFactory)
    protected readonly socketServiceFactory: () => WebSocketChannelsService<IWorkspaceServerEventsMap>,
    @inject(SERVICE_TYPES.UserSettingsService) protected readonly userSettingsService: UserSettingsService,
    @inject(SERVICE_TYPES.CentrifugeService) protected readonly centrifugeService: CentrifugeService,
  ) {
    this.socketService = socketServiceFactory()
    this.socketService.setTokenGettingCallback(this.getTokens.bind(this))
  }

  private isInitEventListener = false

  private socketService: WebSocketChannelsService<IWorkspaceServerEventsMap>

  public async getTokens(): Promise<CentrifugeTokensResponse> {
    const channelName = this.getChannelName()
    return this.centrifugeService.getTokens([channelName])
  }

  public getChannelName() {
    const { parentId } = this.userSettingsService.currentUserSettingsOrFail()
    return getWorkspaceChannelName(parentId)
  }

  private eventListeners: EventListeners<IWorkspaceServerEventsMap> = {
    startTyping: [],
    endTyping: [],
    alsoHereJoin: [],
    alsoHereLeave: [],
    alsoHereRefresh: [],
  }

  public subscribe<R extends keyof IWorkspaceServerEventsMap>(
    eventName: R,
    callback: (event: ServerEventBase<R, IWorkspaceServerEventsMap[R]>) => void,
  ) {
    if (this.eventListeners[eventName].findIndex((t) => t === callback) !== -1) {
      return
    }
    this.eventListeners[eventName].push(callback)
    if (this.isInitEventListener) {
      return
    }
    this.socketService.addEventListener(this.getChannelName(), (t) => {
      this.eventListeners[(t as any).type].forEach((handler) => {
        handler(t as any)
      })
    })
    this.isInitEventListener = true
  }

  public unsubscribe<R extends keyof IWorkspaceServerEventsMap>(
    eventName: R,
    callback?: (event: ServerEventBase<R, IWorkspaceServerEventsMap[R]>) => void,
  ) {
    const callbackIndex = this.eventListeners[eventName].findIndex((t) => t === callback)
    if (callbackIndex === -1) {
      return
    }
    this.eventListeners[eventName].splice(callbackIndex, 1)
  }

  public unsubscribeAll<R extends keyof IWorkspaceServerEventsMap>(eventName: R) {
    this.eventListeners[eventName] = []
  }

  public async publish<R extends keyof IWorkspaceServerEventsMap>(eventName: R, payload: IWorkspaceServerEventsMap[R]) {
    await this.socketService.publish(this.getChannelName(), {
      type: eventName,
      payload,
    })
  }

  public disconnect() {
    this.socketService.disconnect()
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  AllowedEmailsColumnServiceFactory,
  AllowedEmailsEditSettingsFormService,
  AllowedEmailsAddFormService,
  AllowedEmailsService,
  AllowedEmailsRepository,
  SendReceiveSettingSaveFormService,
  DistributionColumnServiceFactory,
  DistributionService,
  DistributionRepository,
}

export type EmailToSmsServices = keyof typeof SERVICES

export const EMAIL_TO_SMS_SERVICE_TYPES: Record<EmailToSmsServices, symbol> = makeServicesTypes<EmailToSmsServices>(
  enumKeysToArray<EmailToSmsServices>(SERVICES),
)

import type { RouteRecordRaw } from 'vue-router'
import { ScheduledRoutes } from '@/routes/user/scheduled/types'
import { messageIdRouteParam } from '@/components/views/scheduled/types'
import { AppModule } from '@/config/types'
import ScheduledMessage from '@/data/models/domain/scheduled/ScheduledMessage'
import type { SingleModelResolverParams } from '@/services/resolvers/types'

export const scheduledEditRoutes: RouteRecordRaw = {
  name: ScheduledRoutes.edit,
  path: `edit/:${messageIdRouteParam}`,
  props: true,
  meta: {
    modules: [
      AppModule.RRule,
      AppModule.ScheduledEdit,
      AppModule.MessageSend,
      AppModule.Contacts,
      AppModule.ContactList,
      AppModule.ContactCounter,
      AppModule.SenderSettingsCountries,
      AppModule.RecentRecipient,
      AppModule.Compose,
      AppModule.FilteredViews,
      AppModule.Segments,
    ],
    resolvers: [{ service: 'ScheduledEditResolverService' }],
    resolverComponent: () => import('@/components/resolvers/ScheduledEditResolver.vue'),
    tabTitle: 'pageTabTitle.user.scheduled.edit',
    fullHeight: true,
    detailsPage: {
      source: 'router',
      requestParam: messageIdRouteParam,
      model: ScheduledMessage,
    } as SingleModelResolverParams,
    titler: {
      service: 'RouteTitlerService',
    },
    redirectToIfNotFound: { name: ScheduledRoutes.upcoming },
    permission: 'composePage',
    accessControlGroups: ['NotCampaignsScheduledEditStrategy'],
  },
  component: () => import('@/components/views/scheduled/ScheduledEdit.vue'),
}

import type { FiltersToCreate } from '@/services/tables/filters/types'
import type { RawSchemaFilter } from '@/services/resolvers/domain/filterSchema/schemaGenerator'
import type { Nullable } from '@/types'

export const baseReportingHistoricalTableFilterFields = {
  user: 'userId',
  year: 'year',
  month: 'month',
  groupBy: 'groupBy',
}

export const baseReportingHistoricalTableFilters: FiltersToCreate = [
  {
    name: baseReportingHistoricalTableFilterFields.user,
    operation: 'eq',
  },
  {
    name: baseReportingHistoricalTableFilterFields.year,
    operation: 'eq',
  },
  {
    name: baseReportingHistoricalTableFilterFields.month,
    operation: 'eq',
  },
  {
    name: baseReportingHistoricalTableFilterFields.groupBy,
    operation: 'eq',
  },
]

export const baseReportingHistoricalTableSchemaFilters: RawSchemaFilter[] = [
  baseReportingHistoricalTableFilterFields.user,
  baseReportingHistoricalTableFilterFields.year,
  baseReportingHistoricalTableFilterFields.month,
  baseReportingHistoricalTableFilterFields.groupBy,
]

export const queryParamsNames = {
  user: 'user',
  year: 'year',
  month: 'month',
}

export type ReportingHistoricalTablePagerItem = {
  month: Nullable<string>
  year: string
  title: string
}

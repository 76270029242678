<template>
  <div
    class="tm-notification"
    :class="`tm-notification--${type}`"
  >
    <tm-icon
      class="tm-notification__icon"
      :name="iconName"
      size="xLarge"
    />
    <div class="tm-notification__body">
      <div class="tm-notification__title lh-20">
        {{ title }}
      </div>
      <div class="tm-notification__content body-text-regular-14 lh-20">
        <slot v-if="!!$slots.default" />
        <div
          v-else-if="text"
          v-safe-html="text"
        />
      </div>
    </div>

    <tm-icon
      class="tm-notification__close"
      size="xLarge"
      name="close"
      @click="$emit('close')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from '@/composition/vue/compositionApi'
import type { NoticeType } from '@/services/types'
import TmIcon from '@/components/shared/TmIcon.vue'
import type { IconName } from '@/assets/icons/icons'

const props = defineProps<{
  type: NoticeType
  title?: string
  text?: string
}>()

defineEmits<{
  close: []
}>()

const iconMap: Record<NoticeType, IconName> = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error',
  info: 'info',
}
const iconName = computed(() => iconMap[props.type])
</script>

<style scoped lang="scss">
.tm-notification {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  padding: 16px;
  font-size: $font-size-root;
  color: $gray7;
  background-color: $blue100;
  border-radius: $border-radius-md;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);

  &__content {
    word-break: break-word;
  }

  &__icon {
    margin-right: 12px;
  }

  &__title {
    font-weight: $semi-bold-font;
  }

  &--success {
    background-color: $green100;
  }
  &--success &__icon {
    color: $green;
  }

  &--warning {
    background-color: $orange100;
  }
  &--warning &__icon {
    color: $orange;
  }

  &--error {
    background-color: $red100;
  }
  &--error &__icon {
    color: $red;
  }

  &--info {
    background-color: $blue100;
  }
  &--info &__icon {
    color: $action;
  }

  &__close {
    cursor: pointer;
    justify-self: flex-end;
    margin-left: auto;
    color: $distinct;
  }
}
</style>

import { inject, injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import type TicketsCountRepository from '@/data/repositories/domain/tickets/ticketsCountRepository'
import TicketCount from '@/data/models/domain/tickets/TicketCount'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type TicketStatusService from '@/services/domain/tickets/ticketStatus/ticketStatusService'

@DomainSettings({
  model: TicketCount,
})
@injectable()
export default class TicketCountService extends DomainBaseService<TicketsCountRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.TicketStatusService) protected readonly ticketStatusService: TicketStatusService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public fetchUnreadTicketsCount() {
    return this.getDomainRepository().fetchUnreadTicketsCount()
  }

  public findUnreadTicketsCount() {
    return this.getDomainRepository().findUnreadTicketsCount()
  }

  public updateUnreadTicketsCount(count: number) {
    return this.getDomainRepository().updateUnreadTicketsCount(count)
  }

  public async getCountClosedTicketAfterDeleteContact(contactId: string) {
    let statuses = this.ticketStatusService.getAllStatuses()
    if (statuses.length === 0) {
      await this.ticketStatusService.fillAll()
      statuses = this.ticketStatusService.getAllStatuses()
    }
    statuses = statuses.filter((t) => !t.isClosed)

    return this.getDomainRepository().fetchCount({
      filters: [
        {
          requester: {
            in: [contactId],
          },
          status: {
            in: statuses.map((t) => t.id),
          },
        },
      ],
    })
  }
}

import { OrmModel, StringField, BooleanField, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

import type Pagination from '@/data/models/tables/Pagination'

@OrmModel(modelNames.TABLE_RECOVERY_DATA)
export default class TableRecoveryData extends BaseModel {
  @StringField()
  public id: string

  @AttrField([])
  public items: string[]

  @AttrField([])
  public initialItems: string[]

  @AttrField({})
  public pagination: Pagination

  @AttrField({})
  public initialPagination: Pagination

  @BooleanField(false)
  public canBeRestored: boolean
}

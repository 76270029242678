<template>
  <tr class="tm-skeleton-table-body-row-layout">
    <td
      v-for="(col, i) in columns"
      :key="i"
      class="text-left"
      :class="[
        col.loaderClass,
        {
          'pa-0': col.hideLoader,
          'col-dynamic-width': !!col.dynamicLoaderWidth,
        },
      ]"
      :style="{ ...(col.columnStyles ?? {}), maxWidth: col.loaderWidth }"
    >
      <tm-simple-skeleton
        v-if="!col.hideLoader"
        :type="col.loaderType ?? 'chip'"
        :width="!col.dynamicLoaderWidth && typeof col.loaderWidth === 'number' ? `${col.loaderWidth}px` : undefined"
        :max-width="!col.dynamicLoaderWidth && typeof col.loaderWidth === 'number' ? `${col.loaderWidth}px` : undefined"
        height="18px"
      />
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import type { ColumnLoader } from '@/core/tables/types'
import TmSimpleSkeleton from '@/components/shared/skeletons/TmSimpleSkeleton.vue'

export default defineComponent({
  components: {
    TmSimpleSkeleton,
  },
  props: {
    columns: {
      type: Array as PropType<ColumnLoader[]>,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.tm-skeleton-table-body-row-layout {
  &:nth-child(5n + 1) {
    --x: 12%;
  }

  &:nth-child(5n + 2) {
    --x: -5%;
  }

  &:nth-child(5n + 3) {
    --x: 15%;
  }

  &:nth-child(5n + 4) {
    --x: 7%;
  }

  &:nth-child(10n + 1) {
    --x: 10%;
  }

  &:nth-child(10n + 2) {
    --x: -2%;
  }

  &:nth-child(10n + 3) {
    --x: 18%;
  }

  &:nth-child(10n + 4) {
    --x: 2%;
  }

  td {
    &:nth-child(n + 1) {
      --y: 32%;
    }

    &:nth-child(n + 2) {
      --y: -5%;
    }

    &:nth-child(n + 3) {
      --y: 15%;
    }

    &:nth-child(n + 4) {
      --y: 7%;
    }

    &:nth-child(n + 5) {
      --y: -20%;
    }
  }

  td {
    --tm-skeleton-height: 12px;

    &.col-dynamic-width {
      --tm-skeleton-width: calc(60% - var(--x, 0%) - var(--y, 0%));
    }

    &.tm-checkbox {
      --tm-skeleton-width: 18px;
      --tm-skeleton-height: 18px;
    }
  }
}
</style>

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import UnauthenticatedCountry from '@/data/models/domain/UnauthenticatedCountry'

@RepoSettings<Endpoint>({
  model: UnauthenticatedCountry,
})
@injectable()
export default class UnauthenticatedCountryRepository extends OrmApiRepository<UnauthenticatedCountry> {
  public async detectCountry(): Promise<UnauthenticatedCountry | null> {
    const path = this.endpointsService.getPath('unauthenticatedCountry')
    const response = await this.getApiSource().get<UnauthenticatedCountry>(path)
    const country: UnauthenticatedCountry | null = response.data
    await this.deleteAll()
    if (country) {
      await this.insertOrUpdate([country])
    }

    return this.getCountry()
  }

  public getCountry(): UnauthenticatedCountry | null {
    return this.query().first()
  }
}

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum TICKETS_SERVICES {
  TicketsRepository,
  TicketStatusRepository,
  TicketInboxRepository,
  TicketsService,
  TicketsColumnServiceFactory,
  TicketsFilteredViewsColumnServiceFactory,
  TicketsFilteredViewsGridable,
  TicketCreateFormService,
  TicketInboxService,
  TicketCreateWorkspaceFormService,
  TicketDefaultRouteRedirectResolverService,
  TicketStatusService,
  TicketAssignFormService,
  TicketsRouteHelperService,
  TicketDetailsSidebarFormService,
  TicketMarkAsReadResolverService,
  TicketMessageRepository,
  TicketFirstLastMessageRepository,
  TicketMessageService,
  TicketFirstLastMessageService,
  TicketMessageFormService,
  TicketInternalNoteEditFormServiceFactory,
  TicketMessageContentRepository,
  TicketMessageContentService,
  TicketFilterServiceFactory,
  TicketFormFilterServiceFactory,
  TicketsBasicDataResolverService,
  TicketInboxColumnServiceFactory,
  TicketCreateInboxFormService,
  TicketInboxDetailsFormService,
  TicketWorkspaceRepository,
  TicketWorkspaceService,
  TicketServerEventsResolverService,
  TicketDetailsServerEventsResolverService,
  TicketConnectInboxContinueResolverService,
  TicketInboxForwardingVerificationEventsResolverService,
  TicketSpamFilterRepository,
  TicketSpamFilterService,
  TicketSpamFilterFormService,
  TicketFilteredViewsCreateFormService,
  TicketFilteredViewsEditFormService,
  TicketSaveAsModalFormService,
  TicketFilterResolverService,
  TicketFirstLastMessageResolverService,
  TicketFilterHelperService,
  TicketMessageTrimmedContentRepository,
  TicketMessageTrimmedContentService,
  TicketsRefreshService,
  TicketMessagesFaceterService,
  TicketGeneralSettingsFormService,
  TicketMessageTextProcessingService,
  TicketMessageAttachmentsFormHelper,
  TicketFilterGridable,
  TicketsBulkService,
  TicketByWorkspaceIdResolverService,
}

export type TicketsServices = keyof typeof TICKETS_SERVICES

export const TICKETS_SERVICES_TYPES = makeServicesTypesFromEnum<TicketsServices>(TICKETS_SERVICES)

export enum TasksRoutes {
  index = 'user.tasks',
  base = 'user.tasks.base',
  overview = 'user.tasks.overview',
  boardBase = 'user.tasks.board.base',
  board = 'user.tasks.board',
  settingsIndex = 'user.tasks.settings',
  settingsBase = 'user.tasks.settings.base',
  settings = 'user.tasks.settings.overview',
  settingsBoard = 'user.tasks.settings.board',
  settingsBoardDetails = 'user.tasks.settings.board.details',
  settingsBoardArchivedDetails = 'user.tasks.settings.boardArchived.details',
  settingsStages = 'user.tasks.settings.stage',
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  CalendarViewManagerService,
  CalendarTimelineService,
  CalendarDailyService,
  CalendarWeeklyService,
  CalendarMonthlyService,
  CalendarGroupService,
  CalendarEventService,
}

export type ScheduledCalendarServices = keyof typeof SERVICES

export const SCHEDULED_CALENDAR_SERVICE_TYPES: Record<ScheduledCalendarServices, symbol> =
  makeServicesTypes<ScheduledCalendarServices>(enumKeysToArray<ScheduledCalendarServices>(SERVICES))

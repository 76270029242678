import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  WhatsAppConfigService,
  WhatsAppConfigRepository,
  WhatsAppResolver,
  WhatsAppAccountRepository,
  WhatsAppBusinessPhoneRepository,
  WhatsAppBusinessPhoneService,
  WhatsAppAccountService,
  WhatsAppAccountColumnService,
  WhatsAppAccountPhonesColumnService,
  WhatsAppAccountPhonesGridable,
}

export type WhatsAppServices = keyof typeof SERVICES

export const WHATSAPP_SERVICE_TYPES: Record<WhatsAppServices, symbol> = makeServicesTypes<WhatsAppServices>(
  enumKeysToArray<WhatsAppServices>(SERVICES),
)

import { injectable } from 'inversify'
import type { Nullable } from '@/types'
import { getUuid } from '@/utils/uuid'
import { TmApiNotFoundError } from '@/core/error/transport/tmApiNotFoundError'

@injectable()
export default class OptimisticUpdateService {
  public async optimisticRequest<T>(
    name: string,
    optimisticUpdate: (optimisticId: string) => Promise<Nullable<T>>,
    requestFn: (optimisticId: string) => Promise<Nullable<T>>,
    afterResponse: (optimisticId: string, response: Nullable<T>) => Promise<void>,
    onError: (optimisticId: string, error: unknown) => Promise<void>,
    optimisticId: string = getUuid(),
  ) {
    try {
      await optimisticUpdate(optimisticId)

      requestFn(optimisticId)
        .then((item) => {
          afterResponse(optimisticId, item)
        })
        .catch((e) => {
          onError(optimisticId, e)
        })
    } catch (e) {
      onError(optimisticId, e)
    }
  }

  public async notFoundErrorHandler(fn: () => Promise<any>, onErrorFn?: (error: Error) => Promise<any>) {
    try {
      return await fn()
    } catch (error) {
      if (error instanceof TmApiNotFoundError) {
        if (onErrorFn) {
          await onErrorFn(error)
        }

        return null
      }

      throw error
    }
  }
}

import { isNil } from 'lodash-es'
import { injectable } from 'inversify'
import {
  isPaginationUrlFilterNullableRelationType,
  isPaginationUrlFilterScalarValueType,
  type PaginationUrlFilterSingleType,
  type PaginationUrlFilterNullableSingleType,
  type PaginationUrlFilterType,
  type PaginationUrlFilterNullableType,
} from '@/services/tables/types'
import { FILTER_RESERVED_NAMES } from '@/services/tables/filters/types'
import { isRecordUnknown } from '@/utils/typeGuards'

@injectable()
export class FilterCleanupService {
  public cleanup(filters: PaginationUrlFilterNullableType): PaginationUrlFilterType {
    return filters.map((f) => this.cleanupSingleFilter(f))
  }

  public cleanupSingleFilter(filter: PaginationUrlFilterNullableSingleType): PaginationUrlFilterSingleType {
    const result: PaginationUrlFilterSingleType = {}

    FILTER_RESERVED_NAMES.forEach((name) => {
      // Delete reserved field names because it cannot be filters
      delete filter[name]
    })

    Object.entries(filter).forEach(([filterName, value]) => {
      if (isPaginationUrlFilterNullableRelationType({ [filterName]: value })) {
        Object.entries(value).forEach(([relation, scalar]) => {
          if (!this.isEmptyValue(scalar)) {
            Object.entries(scalar as Array<unknown> | Record<string, unknown>).forEach(([operation, scalarValue]) => {
              if (!this.isEmptyValue(scalarValue)) {
                if (!isRecordUnknown(result[filterName])) {
                  result[filterName] = {}
                  result[filterName][relation] = {}
                }

                if (!isRecordUnknown(result[filterName][relation])) {
                  result[filterName][relation] = {}
                }

                result[filterName][relation][operation] = scalarValue
              }
            })
          }
        })
      } else if (isPaginationUrlFilterScalarValueType(value)) {
        Object.entries(value).forEach(([operation, scalarValue]) => {
          if (!this.isEmptyValue(scalarValue)) {
            if (!isRecordUnknown(result[filterName])) {
              result[filterName] = {}
            }

            result[filterName][operation] = scalarValue
          }
        })
      } else if (value !== null) {
        result[filterName] = value
      }
    })

    return result
  }

  protected isEmptyValue(value: unknown): boolean {
    return isNil(value) || (Array.isArray(value) && value.length === 0)
  }
}

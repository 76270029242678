import { inject, injectable } from 'inversify'
import type { ColumnEntity } from '@/core/tables/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ColumnsFromStoreFactory from '@/services/tables/columnsFromStoreFactory'
import type LoggerService from '@/services/loggerService'
import type ColumnsSizeService from '@/services/tables/columnsSizeService'
import ColumnsFromApiStructuredFactory from '@/services/tables/columnsFromApiStructuredFactory'
import type { Dict } from '@/types'

@injectable()
export default class ForwardAudioColumnService extends ColumnsFromApiStructuredFactory {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ColumnsFromStoreFactory) columnsFromStore: () => ColumnsFromStoreFactory,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.ColumnsSizeService) protected readonly columnsSizeService: ColumnsSizeService,
  ) {
    super(em, columnsFromStore, loggerService)
  }

  public getStructuredColumns(): Dict<ColumnEntity> {
    return {
      originalName: {
        label: 'tableColumns.fileName',
        growRatio: 4,
        minWidth: 200,
      },
      length: {},
      fileSize: {},
      uploadedAt: {
        label: 'tableColumns.uploadTime',
        minWidth: this.columnsSizeService.getDateMinWidth(),
      },
      action: {
        hideHeaderName: true,
        growRatio: 0,
        minWidth: 92,
      },
    }
  }
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmLocalStorageRepository from '@/data/repositories/ormLocalStorageRepository'
import AuthState from '@/data/models/AuthState'
import type { TfaAdditionalData } from '@/services/auth/types'

@RepoSettings({
  model: AuthState,
  fetch: AuthState.entity,
  create: AuthState.entity,
  single: AuthState.entity,
})
@injectable()
export default class AuthStateRepository extends OrmLocalStorageRepository<AuthState> {
  protected id = '1'

  public async getCredentials(): Promise<Pick<AuthState, 'username'>> {
    const state = await this.getRequest<AuthState>(this.id)
    return {
      username: state.username,
    }
  }

  public async getCredentialsWithAdditionalData(): Promise<AuthState> {
    return this.getRequest(this.id)
  }

  public async storeCredentialsWithAdditionalData(username: string, additionalData: TfaAdditionalData) {
    const toCreate = {
      id: this.id,
      username,
      ...additionalData,
    }

    await this.postRequest(toCreate)
    await this.fill(this.id)
  }

  public removeCredentials() {
    this.delete([this.id])
  }
}

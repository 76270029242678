import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/container'
import { withUniqueKeys } from '@/decorators/resolvers/withUniqueKeys'
import type { UniqueKeys } from '@/types'
import type UserPresenceService from '@/services/domain/user/userPresenceService'

@withUniqueKeys('UserPresenceResolver')
@injectable()
export default class UserPresenceResolver implements Resolvable {
  constructor(@inject(SERVICE_TYPES.UserPresenceService) protected readonly userPresenceService: UserPresenceService) {}

  public async resolve() {
    this.userPresenceService.fetchUserPresence()
  }

  public async unresolve() {}

  public getResolverKeys(): UniqueKeys {
    return []
  }
}

<template>
  <field-checkbox
    class="tm-table-header-checkbox"
    :model-value="!!isAllCheckboxEnabled"
    :is-disabled="isDisabled"
    @update:model-value="onSelectAllPageRows"
  />
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from '@/composition/vue/compositionApi'
import type { BaseTableInterface } from '@/core/tables/baseTableInterface'
import { get, getEM } from '@/core/container/ioc'
import type AllSelectedTablesRepository from '@/data/repositories/table/allSelectedTablesRepository'
import AllSelectedTables from '@/data/models/tables/AllSelectedTables'
import type { RegisteredServices } from '@/core/container/types'
import FieldCheckbox from '@/components/forms/fields/FieldCheckbox.vue'

export default defineComponent({
  components: {
    FieldCheckbox,
  },
  props: {
    serviceId: {
      type: String as PropType<RegisteredServices>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const baseTable: BaseTableInterface = get(props.serviceId)
    const pagination = computed(() => baseTable.getPagination().getData())
    const hasCounter = baseTable.hasCounterService()
    const counter = computed(() => baseTable.getCounterService().getCount(props.serviceId as RegisteredServices))

    const selectedRowsCount = computed(() => baseTable.getSelectedRowsInCurrentPage().length)
    const pageRowsCount = computed(() => baseTable.getCurrentPageNonDisabledRowsIds().length)

    const isIndeterminateState = computed(() => {
      const isAllRowsSelectedInCurrentPage = selectedRowsCount.value >= pageRowsCount.value
      const atLeastOneSelected = selectedRowsCount.value > 0

      return !isAllRowsSelectedInCurrentPage && atLeastOneSelected
    })

    const allSelectedTables = computed(
      () => getEM().getRepository<AllSelectedTablesRepository>(AllSelectedTables).find(props.serviceId)?.isAllSelected,
    )

    const isAllCheckboxEnabled = computed<boolean | null>(() => {
      if (isIndeterminateState.value) return null

      if (allSelectedTables.value) return true
      if (baseTable.getCurrentPageRowsIds().length === 0) return false
      if (!selectedRowsCount.value) return false

      const { perPage, totalCount, page } = pagination.value

      if (selectedRowsCount.value === perPage) return true
      if (selectedRowsCount.value === pageRowsCount.value) return true
      if (selectedRowsCount.value === totalCount - (page - 1) * perPage) return true
      if (hasCounter) {
        const total = counter.value?.total
        if (!total) {
          return false
        }
        if (selectedRowsCount.value === total) return true
        if (selectedRowsCount.value === total - (page - 1) * perPage) return true
      }

      return false
    })

    const hasPages = computed<boolean>(() => pagination.value.totalCount > 0)
    const hasCount = computed<boolean>(() => hasCounter && !!counter.value && counter.value.total > 0)
    const isDisabled = computed(() => props.disabled || baseTable.isDisabled() || !(hasPages.value || hasCount.value))

    const onSelectAllPageRows = (checked: unknown) => {
      if (checked) {
        baseTable.selectRows('pageAll')
      } else {
        baseTable.unselectRows('pageAll')
      }
    }

    return {
      isAllCheckboxEnabled,
      isDisabled,
      onSelectAllPageRows,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.tm-table-header-checkbox {
  @include disabled-table-checkbox();
}
</style>

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type {
  UserRefusalSurveyParams,
  UserRefusalSurveyReason,
  UserRefusalSurveyReasonWithDescription,
} from '@/services/domain/user/types'
import type { TypedFormBuilderInterface } from '@/services/forms/baseForm/typedFormBuilder/types'
import type { SelectFieldConfig } from '@/services/forms/types'
import type { BillingDowngradeToPayAsYouGoFormSchema } from '@/services/domain/billing/types'
import type { Translatable } from '@/services/types'
import TmLogicError from '@/core/error/tmLogicError'

export interface UserRefusalFormSchema {
  reason: UserRefusalSurveyReason | ''
  description: string
}

@injectable()
export default class UserRefusalSurveyService {
  constructor(@inject(SERVICE_TYPES.TranslateService) protected translateService: Translatable) {}

  public hasReasonDescription(formData: UserRefusalFormSchema) {
    const reasonsWithDescription: Record<UserRefusalSurveyReasonWithDescription, true> = {
      found_better_alternative: true,
      has_issues_with_platform: true,
      other: true,
    }
    return !!reasonsWithDescription[formData.reason]
  }

  public buildUserRefusalSurveyFields(typedBuilder: TypedFormBuilderInterface<UserRefusalFormSchema>) {
    typedBuilder
      .property((t) => t.reason)
      .control<SelectFieldConfig<BillingDowngradeToPayAsYouGoFormSchema['reason']>>({
        fieldType: 'select',
        value: '',
        options: () => {
          const { t } = this.translateService
          return [
            {
              text: t('cancelReasons.selectReason'),
              value: '',
            },
            {
              text: t('cancelReasons.notNeedService'),
              value: 'not_need_service',
            },
            {
              text: t('cancelReasons.hasIssuesWithPlatform'),
              value: 'has_issues_with_platform',
            },
            {
              text: t('cancelReasons.pricingTooHigh'),
              value: 'pricing_too_high',
            },
            {
              text: t('cancelReasons.foundBetterAlternative'),
              value: 'found_better_alternative',
            },
            {
              text: t('cancelReasons.other'),
              value: 'other',
            },
          ]
        },
      })

    typedBuilder
      .property((t) => t.description)
      .control({
        fieldType: 'textarea',
        value: '',
      })
  }

  public getPayloadFromFormData(formData: UserRefusalFormSchema): UserRefusalSurveyParams {
    if (formData.reason === '') {
      throw new TmLogicError('is empty "reason"')
    }
    let payload: UserRefusalSurveyParams
    if (formData.reason === 'not_need_service' || formData.reason === 'pricing_too_high') {
      payload = {
        reason: formData.reason,
        description: null,
      }
    } else {
      payload = {
        reason: formData.reason,
        description: formData.description,
      }
    }
    return payload
  }
}

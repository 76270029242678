import { inject, injectable } from 'inversify'
import type { MessageSendRepeatWeeklyFormScheme } from '@/services/domain/messages/types'
import type { CheckButtonConfig, CheckButtonOption } from '@/services/forms/types'
import type {
  FormGroupConfigurationInterface,
  TypedFormBuilderInterface,
} from '@/services/forms/baseForm/typedFormBuilder/types'
import type { WeekDays } from '@/services/types'
import { weekDaysStartingSunday } from '@/services/types'
import type DateTimeService from '@/services/dateTimeService'
import { SERVICE_TYPES } from '@/core/container/types'

@injectable()
export class WeeklyFormGroupConfiguration
  implements FormGroupConfigurationInterface<MessageSendRepeatWeeklyFormScheme>
{
  constructor(@inject(SERVICE_TYPES.DateTimeService) private readonly dateTimeService: DateTimeService) {}

  private getCheckButtonWeekDaysOptions(): CheckButtonOption<WeekDays>[] {
    const weekDaysTranslate = this.dateTimeService.getWeekDaysNames('abbreviated')
    return weekDaysStartingSunday.map((t, index) => ({
      text: weekDaysTranslate[index],
      value: t,
    }))
  }

  public configure(builder: TypedFormBuilderInterface<MessageSendRepeatWeeklyFormScheme>): void {
    builder
      .property((t) => t.count)
      .control({
        fieldType: 'number',
        value: 1,
      })
    builder
      .property((t) => t.days)
      .control<CheckButtonConfig<WeekDays>>({
        fieldType: 'checkButton',
        value: [],
        options: () => this.getCheckButtonWeekDaysOptions(),
      })
  }
}

import { AttrField, BelongsToField, BooleanField, OrmModel, StringField, NumberField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import User from '@/data/models/domain/User'
import Country from '@/data/models/domain/Country'
import ForwardAudio from '@/data/models/domain/ForwardAudio'
import type { TmCountryCode } from '@/services/types'
import type { ForwardCallsToType, ForwardDirection } from '@/services/domain/phoneNumbers/types'
import type { NumberGiftType } from '@/services/domain/services/regulations/types'

@OrmModel(modelNames.PHONE_NUMBER)
export default class PhoneNumber extends BaseModel {
  @StringField()
  public id: string

  @AttrField()
  public userId: string

  @BelongsToField(User, 'userId')
  public user: User

  @AttrField()
  public countryId: TmCountryCode

  @BelongsToField(Country, 'countryId')
  public country: Country

  @StringField()
  public purchasedAt: string

  @StringField()
  public expireAt: string

  @StringField()
  public phone: string

  @NumberField()
  public price: number

  @StringField()
  public priceFormatted: string

  @StringField()
  public forwardCallsTo: ForwardCallsToType

  @StringField()
  public status: string

  @StringField()
  public label: string

  @StringField()
  public forwardAudioId: string

  @BelongsToField(ForwardAudio, 'forwardAudioId')
  public forwardAudio: ForwardAudio | null

  @BooleanField()
  public voiceAvailable: boolean

  @AttrField()
  public forwardDirection: ForwardDirection | null

  @BooleanField()
  public isCanceled: boolean

  @StringField()
  public giftType: NumberGiftType
}

import { inject, injectable } from 'inversify'
import Note from '@/data/models/domain/Note'
import type NoteRepository from '@/data/repositories/domain/noteRepository'
import { ModelEventType } from '@/services/transport/types'
import { DomainSettings } from '@/decorators/domainDecorators'
import type { NotePostBody } from '@/services/domain/note/types'
import DomainBaseService from '@/services/domain/domainBaseService'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type UserService from '@/services/domain/user/userService'
import type Contact from '@/data/models/domain/Contact'

@DomainSettings({
  model: Note,
})
@injectable()
export default class NoteService extends DomainBaseService<NoteRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public async createNoteFromFormData(note: NotePostBody, contactId: string) {
    const result = await this.getDomainRepository().createNote(note, contactId)
    this.notify([result.data.id.toString()], ModelEventType.CREATE)
    return result
  }

  public async updateNoteFromFormData(note: NotePostBody, noteId: string) {
    note.id = noteId
    const result = await this.getDomainRepository().putForFormRequest(note)
    this.notify([result.data.id.toString()], ModelEventType.UPDATE)
    return result
  }

  public async createNote(text: string, contactId: string): Promise<Note> {
    const repo = this.getDomainRepository()
    const response = await repo.createNote({ note: text }, contactId)
    let result = (await repo.getRequest(response.data.id)) as any
    await repo.insertOrUpdate(result)
    result = repo.find(result.id)
    return result
  }

  public getNotesByIds(ids: string[], limit?: number): Note[] {
    return this.getDomainRepository().getNotesByIds(ids, limit)
  }

  public getTotalCount(ids: string[]): number {
    return this.getDomainRepository().getTotalCount(ids)
  }

  public isAllowAddNote(contact: Contact) {
    return !contact.blocked
  }

  public isAllowEditNote(authorId: string) {
    return this.userService.currentUserId() === authorId
  }

  public addNote(note: Pick<Note, 'id' | 'note' | 'userId' | 'createdAt'>) {
    return this.getDomainRepository().queueInsertOrUpdate([note])
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type BaseSearcherServiceFactory from '@/services/tables/baseSearcherServiceFactory'
import type TableSearcherManagerInterface from '@/services/tables/managers/tableSearcherManagerInterface'
import type { Factory } from '@/types'
import type LoggerService from '@/services/loggerService'
import type SearchRepository from '@/data/repositories/searchRepository'
import type { LoggerChannels } from '@/config/configDev'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import { TmTableElementManagerFactoryNotFoundError } from '@/core/error/table/tableManager/tmTableElementManagerFactoryNotFoundError'
import TableElementManagerWithEndpoint from '@/services/tables/managers/tableElementManagerWithEndpoint'
import Search from '@/data/models/tables/Search'
import type { BaseSearcherSettings } from '@/services/search/types'
import type { SearcherTableInterface } from '@/core/tables/types'

@injectable()
export default class TableSearcherManager
  extends TableElementManagerWithEndpoint<SearcherTableInterface, BaseSearcherSettings>
  implements TableSearcherManagerInterface
{
  protected readonly managerId = 'TableSearcherManager'

  protected readonly loggerChannel: LoggerChannels = 'search'

  private constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.BaseSearcherServiceFactory)
    protected readonly baseSearcherServiceFactory: Factory<BaseSearcherServiceFactory>,
  ) {
    super(em, loggerService)
  }

  public getFactoryForTable(tableId: string): Factory<SearcherTableInterface> {
    try {
      return super.getFactoryForTable(tableId)
    } catch (e) {
      if (e instanceof TmTableElementManagerFactoryNotFoundError) {
        this.log(`No explicitly set factory found for the "${tableId}" table. Falling back to default one.`)
        return this.baseSearcherServiceFactory
      }

      throw e
    }
  }

  public addServiceForTable(tableId: string, settings: BaseSearcherSettings) {
    const { serviceId } = super.addServiceForTable(tableId, settings)

    this.services[serviceId].setSettings(settings)
    this.services[serviceId].setTableId(tableId)
    this.services[serviceId].setEndpoint(this.getEndpointForTable(tableId))

    return {
      serviceId,
      instance: this.services[serviceId],
    }
  }

  protected removeServiceById(tableId: string, serviceId: string) {
    const instance = this.getServiceByServiceId(serviceId)
    this.getSearcherRepo().deleteByCondition((model) => model.id === instance.getTableId())

    super.removeServiceById(tableId, serviceId)
  }

  protected getSearcherRepo() {
    return this.em.getRepository<SearchRepository>(Search)
  }
}

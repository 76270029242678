import ConfigReader from '@/core/configReader'
import {
  AppModule,
  cleanuperAutocompleteService,
  cleanuperTableService,
  columnService,
  domainService,
  faceterService,
  factoryService,
  formService,
  grouperService,
  interceptorService,
  paginatorService,
  preloaderService,
  pureService,
  repositoryService,
  resolverService,
  searcherService,
  type ServicesType,
  sorterService,
  staticValue,
  titlerService,
  validationRegistratorService,
  wrapperService,
} from '@/config/types'
import VueService from '@/services/vueService'
import VuexService from '@/services/vuex/vuexService'
import EndpointsService from '@/services/endpointsService'
import type { RegisteredServices } from '@/core/container/types'
import { CHAT_SERVICE_TYPES } from '@/core/container/chat.types'
import { CHAT_STATISTICS_SERVICE_TYPES } from '@/core/container/chatStatistics.types'
import EntityManagerService from '@/data/repositories/entityManagerService'
import { HttpService } from '@/services/transport/httpService'
import RouterService from '@/services/route/routerService'
import { RouteLeaveService } from '@/services/route/routeLeaveService'
import RouterBuilderService from '@/services/route/routerBuilderService'
import DefaultTitlerService from '@/services/route/titlers/defaultTitlerService'
import DomainTitlerService from '@/services/route/titlers/domainTitlerService'
import RouteTitlerService from '@/services/route/titlers/routeTitlerService'
import TitlerManager from '@/services/route/titlers/titlerManager'
import { RouteLeaveResolver } from '@/services/resolvers/routeLeaveResolver'
import BaseRouterService from '@/services/route/baseRouterService'
import HistoryService from '@/services/route/historyService'
import ResolverService from '@/services/resolvers/resolverService'
import ResolverManager from '@/services/resolvers/resolverManager'
import ResolversRouterBuilderMiddleware from '@/services/route/routerBuilderMiddleware/resolversRouterBuilderMiddleware'
import ModulesRouterBuilderMiddleware from '@/services/route/routerBuilderMiddleware/modulesRouterBuilderMiddleware'
import TitlersRouterBuilderMiddleware from '@/services/route/routerBuilderMiddleware/titlerRouterBuilderMiddleware'
import TranslateService from '@/services/translateService'
import LoggerService from '@/services/loggerService'
import AuthService from '@/services/auth/authService'
import SerializerService from '@/services/serializerService'
import BaseFormRepository from '@/data/repositories/form/baseFormRepository'
import TableRepository from '@/data/repositories/table/tableRepository'
import TableRecoveryDataRepository from '@/data/repositories/table/tableRecoveryDataRepository'
import SelectedRowRepository from '@/data/repositories/table/selectedRowRepository'
import AllSelectedTablesRepository from '@/data/repositories/table/allSelectedTablesRepository'
import PaginationRepository from '@/data/repositories/table/paginationRepository'
import SearchRepository from '@/data/repositories/searchRepository'
import NoteRepository from '@/data/repositories/domain/noteRepository'
import UserRepository from '@/data/repositories/domain/users/userRepository'
import BaseFieldRepository from '@/data/repositories/form/baseFieldRepository'
import BaseFilterRepository from '@/data/repositories/filters/baseFilterRepository'
import SorterRepository from '@/data/repositories/table/sorterRepository'
import GroupingRepository from '@/data/repositories/table/groupingRepository'
import ModalRepository from '@/data/repositories/wrappers/modalRepository'
import FilterSchemaRepository from '@/data/repositories/filters/filterSchemaRepository'
import CountryRepository from '@/data/repositories/domain/countryRepository'
import TimezoneRepository from '@/data/repositories/domain/timezoneRepository'
import ColumnRepository from '@/data/repositories/table/columnRepository'
import PreloadRepository from '@/data/repositories/preloadRepository'
import BaseFilters from '@/services/tables/filters/baseFilters'
import { FilterStrategyManager } from '@/services/tables/filters/strategies/filterStrategyManager'
import { BaseFilterStrategy } from '@/services/tables/filters/strategies/baseFilterStrategy'
import { RangeFilterStrategy } from '@/services/tables/filters/strategies/rangeFilterStrategy'
import { ArrayFilterStrategy } from '@/services/tables/filters/strategies/arrayFilterStrategy'
import { RangeFilterService } from '@/services/tables/filters/strategies/rangeFilterService'
import BaseGrouperServiceFactory from '@/services/tables/baseGrouperServiceFactory'
import BaseSorterServiceFactory from '@/services/tables/sort/baseSorterServiceFactory'
import NoopGroupingServiceFactory from '@/services/tables/noopGroupingServiceFactory'
import NoopSorterServiceFactory from '@/services/tables/noopSorterServiceFactory'
import NoColumnsServiceFactory from '@/services/tables/noColumnsServiceFactory'
import TableBuilderService from '@/services/tables/tableBuilderService'
import TableGrouperManager from '@/services/tables/managers/tableGrouperManager'
import TableManager from '@/services/tables/tableManager'
import TablePaginatorManager from '@/services/tables/managers/tablePaginatorManager'
import TableSearcherManager from '@/services/tables/managers/tableSearcherManager'
import BaseSearcherServiceFactory from '@/services/tables/baseSearcherServiceFactory'
import TableSorterManager from '@/services/tables/managers/tableSorterManager'
import NotificationService from '@/services/notificationService'
import ExportService from '@/services/exportService'
import SubscriptionService from '@/services/transport/subscriptionService'
import FilterSchemaService from '@/services/filterSchemaService'
import DomainBaseService from '@/services/domain/domainBaseService'
import DomainManagerService from '@/services/domain/domainManagerService'
import { FilterBuilderService } from '@/services/forms/filterBuilderService'
import ModelPreloaderService from '@/services/preloaders/modelPreloaderService'
import ConfirmationService from '@/services/confirmationService'
import ModalService from '@/services/wrappers/modalService'
import ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import UserService from '@/services/domain/user/userService'
import ModelResolver from '@/services/resolvers/modelResolverService'
import UserResolver from '@/services/resolvers/containers/userResolverService'
import SubAccountsResolver from '@/services/resolvers/containers/subAccountsResolverService'
import HttpInterceptorManager from '@/services/transport/httpInterceptorManager'
import ApiErrorInterceptor from '@/services/transport/interceptors/apiErrorInterceptor'
import AuthInterceptor from '@/services/transport/interceptors/authInterceptor'
import OrderService from '@/services/orderService'
import VisibilityService from '@/services/visibilityService'
import ValidationService from '@/services/validation/validationService'
import CommonValidationRegistrator from '@/services/validation/commonValidationRegistrator'
import { RootContainerResolver } from '@/services/resolvers/containers/rootContainerResolver'
import PhoneService from '@/services/phoneService'
import FileValidation from '@/services/validation/fileValidationRegistrator'
import WindowService from '@/services/browser/windowService'
import { ScrollbarService } from '@/services/browser/scrollbarService'
import { TableScrollbarService } from '@/services/browser/tableScrollbarService'
import ScriptResolverService from '@/services/resolvers/misc/scriptResolverService'
import CountryService from '@/services/domain/countryService'
import FormSubscriptionService from '@/services/transport/formSubscriptionService'
import RangeFilterRepository from '@/data/repositories/filters/rangeFilterRepository'
import ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import OrderRepository from '@/data/repositories/OrderRepository'
import VisibilityRepository from '@/data/repositories/VisibilityRepository'
import PreloaderManager from '@/services/preloaders/preloaderManager'
import CleanUpManager from '@/services/cleanUp/cleanUpManager'
import TablePreloaderService from '@/services/preloaders/tablePreloaderService'
import PageMapPreloadRepository from '@/data/repositories/pageMapPreloadRepository'
import UserPreloaderService from '@/services/preloaders/userPreloaderService'
import { SANDBOX_SERVICE_TYPES } from '@/core/container/sandbox/sandbox.types'
import LocalStorageAdapter from '@/services/transport/storage/localStorageAdapter'
import BaseStorageTransport from '@/services/transport/storage/baseStorageTransport'
import LocalStorageTransport from '@/services/transport/storage/localStorageTransport'
import WrapperManagerService from '@/services/wrappers/wrapperManagerService'
import BaseWrapperRepository from '@/data/repositories/wrappers/baseWrapperRepository'
import BaseWrapperService from '@/services/wrappers/baseWrapperService'
import FacetManager from '@/services/facets/facetManager'
import FaceterService from '@/services/facets/faceterService'
import FacetRepository from '@/data/repositories/facets/facetRepository'
import TableCleanUper from '@/services/cleanUp/tableCleanUper'
import SingleModelResolverService from '@/services/resolvers/singleModelResolver'
import BulkManager from '@/services/bulk/bulkManager'
import BulkService from '@/services/bulk/bulkService'
import PartialUIWrapperService from '@/services/wrappers/partialUIWrapperService'
import GroupedPartialUIWrapperService from '@/services/wrappers/groupedPartialUIWrapperService'
import PartialQueryUIWrapperService from '@/services/wrappers/partialQueryUIWrapperService'
import GroupedQueryPartialUIWrapperService from '@/services/wrappers/groupedQueryPartialUIWrapperService'
import FormBuilderService from '@/services/forms/baseForm/formBuilderService'
import FormManager from '@/services/forms/baseForm/formManager'
import FormValidationService from '@/services/forms/formValidationService'
import { FieldMapper } from '@/services/forms/baseForm/fieldMapper'
import { TableKeyBuilder } from '@/core/tables/tableKeyBuilder'
import AutocompleteRepository from '@/data/repositories/autocompleteRepository'
import AutocompletePreloaderService from '@/services/preloaders/autocompletePreloaderService'
import AutocompleteCleanUper from '@/services/cleanUp/autocompleteCleanUper'
import { AutocompleteKeyBuilder } from '@/services/autocompleteKeyBuilder'
import SpecialValidationRegistrator from '@/services/validation/specialValidationRegistrator'
import { FilterServiceManager } from '@/services/tables/managers/filterServiceManager'
import { TableFilterServiceManager } from '@/services/tables/managers/tableFilterServiceManager'
import FilterStateRepository from '@/data/repositories/filters/filterStateRepository'
import ColumnsFromStoreFactory from '@/services/tables/columnsFromStoreFactory'
import StatusFieldBuilderService from '@/services/statusFieldBuilderService'
import BasePaginatorServiceFactory from '@/services/tables/pagination/basePaginatorServiceFactory'
import TableColumnManager from '@/services/tables/managers/tableColumnManager'
import NoteService from '@/services/domain/note/noteService'
import CursorPaginationServiceFactory from '@/services/tables/pagination/cursorPaginatorServiceFactory'
import FilterSchemaResolver from '@/services/resolvers/domain/filterSchema/filterSchemaResolver'
import AuthStateRepository from '@/data/repositories/AuthStateRepository'
import SipCallsRepository from '@/data/repositories/domain/sipCallsRepository'
import SipCallsService from '@/services/domain/sipCalls/sipCallsService'
import NotFoundResolverService from '@/services/resolvers/notFoundResolver'
import { RouteEntitiesService } from '@/services/route/routeEntitiesService'
import DetailsPageRouterBuilderMiddleware from '@/services/route/routerBuilderMiddleware/detailsPageRouterBuilderMiddleware'
import FilterQueryService from '@/services/filterQueryService'
import FilterBracketNotationQueryService from '@/services/filterBracketNotationQueryService'
import VatInformationFormService from '@/services/forms/accounts/settings/vatInformationFormService'
import SessionService from '@/services/domain/session/sessionService'
import SessionRepository from '@/data/repositories/domain/session/sessionRepository'
import SessionShortRepository from '@/data/repositories/domain/session/sessionShortRepository'
import CounterRepository from '@/data/repositories/table/counterRepository'
import CursorPaginationPageRepository from '@/data/repositories/table/cursorPaginationPageRepository'
import MessagesPriceRepository from '@/data/repositories/domain/messagesPriceRepository'
import MessagesPriceService from '@/services/domain/messages/messagesPriceService'
import ForwardAudioRepository from '@/data/repositories/domain/forwardAudioRepository'
import UserNumbersService from '@/services/domain/user/userNumbersService'
import UserNumbersRepository from '@/data/repositories/domain/userNumbersRepository'
import DeliveredSmsCounterRepository from '@/data/repositories/domain/counters/deliveredSmsCounterRepository'
import FailedSmsCounterRepository from '@/data/repositories/domain/counters/failedSmsCounterRepository'
import RejectedSmsCounterRepository from '@/data/repositories/domain/counters/rejectedSmsCounterRepository'
import PhoneNumbersRepository from '@/data/repositories/domain/phoneNumbersRepository'
import ForwardAudioColumnService from '@/services/tables/columns/forwardAudioColumnService'
import ForwardAudioService from '@/services/domain/forwardAudio/forwardAudioService'
import TenDlcPhoneNumbersGridable from '@/services/domain/services/regulations/tenDlcPhoneNumbers/tenDlcPhoneNumbersGridable'
import PhoneNumbersService from '@/services/domain/phoneNumbers/phoneNumbersService'
import NumericService from '@/services/numericService'
import TableWrapperService from '@/services/wrappers/table/tableWrapperService'
import TableQueryWrapperService from '@/services/wrappers/table/tableQueryWrapperService'
import NewQueryParamsService from '@/services/route/newQueryParamsService'
import NewQueryModalService from '@/services/wrappers/newQueryModalService'
import ExportFormService from '@/services/forms/exportFormService'
import FacetResolver from '@/services/resolvers/facetResolver'
import SendReceiveSettingsResolver from '@/services/resolvers/domain/services/sendReceiveSettingsResolver'
import MessagesAttachmentFileFormService from '@/services/forms/messages/messagesAttachmentFileFormService'
import MessagesAttachmentFileService from '@/services/domain/messages/messagesAttachmentFileService'
import MessagesAttachmentFileRepository from '@/data/repositories/domain/messagesAttachmentFileRepository'
import TimezoneService from '@/services/domain/timezone/timezoneService'
import AvailableToBuyNumbersRepository from '@/data/repositories/domain/availableToBuyNumbersRepository'
import AreaCodesRepository from '@/data/repositories/domain/areaCodesRepository'
import { EndFormGroupConfiguration } from '@/services/forms/messages/formGroupConfiguration/EndFormGroupConfiguration'
import { MonthlyFormGroupConfiguration } from '@/services/forms/messages/formGroupConfiguration/MonthlyFormGroupConfiguration'
import { SendingDateTimeFormGroupConfiguration } from '@/services/forms/messages/formGroupConfiguration/SendingDateTimeFormGroupConfiguration'
import { WeeklyFormGroupConfiguration } from '@/services/forms/messages/formGroupConfiguration/WeeklyFormGroupConfiguration'
import { YearlyFormGroupConfiguration } from '@/services/forms/messages/formGroupConfiguration/YearlyFormGroupConfiguration'
import { TypedFormBuilderService } from '@/services/forms/baseForm/typedFormBuilder/TypedFormBuilderService'
import { GroupPropertyBuilder } from '@/services/forms/baseForm/typedFormBuilder/GroupPropertyBuilder'
import { PropertyBuilder } from '@/services/forms/baseForm/typedFormBuilder/PropertyBuilder'
import { TypedGroupFormBuilderService } from '@/services/forms/baseForm/typedFormBuilder/TypedGroupFormBuilderService'
import AreaCodesService from '@/services/domain/phoneNumbers/areaCodesService'
import AvailableToBuyNumbersService from '@/services/domain/phoneNumbers/availableToBuyNumbersService'
import BaseResolverService from '@/services/resolvers/baseResolverService'
import { RedirectableSingleModelResolverService } from '@/services/resolvers/redirectableSingleModelResolverService'
import { LocalStorageService } from '@/services/storage/localStorageService'
import { SessionStorageService } from '@/services/storage/sessionStorageService'
import QueryStorageService from '@/services/storage/queryStorageService'
import TableLocalStorageWrapperService from '@/services/wrappers/table/tableLocalStorageWrapperService'
import CallForwardingFormBuilderService from '@/services/forms/services/callForwardingFormBuilderService'
import BuyNumberCallForwardingFormService from '@/services/forms/services/buyNumber/buyNumberCallForwardingFormService'
import NumbersPriceRepository from '@/data/repositories/domain/numbersPriceRepository'
import NumbersPriceService from '@/services/domain/phoneNumbers/numbersPriceService'
import GlobalStateRepository from '@/data/repositories/GlobalStateRepository'
import GlobalStateService from '@/services/GlobalStateService'
import NumberAvailableCountriesRepository from '@/data/repositories/domain/numberAvailableCountriesRepository'
import NumberAvailableCountriesService from '@/services/domain/phoneNumbers/numberAvailableCountriesService'
import PhoneValidateAutocompleteService from '@/services/phoneValidateAutocompleteService'
import PhoneAutocompleteService from '@/services/phoneAutocompleteService'
import AuthByProviderResolver from '@/services/resolvers/domain/auth/authByProviderResolver'
import SubValidationService from '@/services/validation/subValidationService'
import ImportMatchingCountryFormService from '@/services/forms/flow/importMatchingCountryFormService'
import UserRoleLabelDataService from '@/services/domain/user/userRoleLabelDataService'
import NativeNotificationService from '@/services/nativeNotificationService'
import ServerSubscriptionService from '@/services/transport/serverSubscriptionService'
import VoiceCallPriceService from '@/services/domain/voiceCallPriceService'
import VoiceCallPriceRepository from '@/data/repositories/domain/voiceCallPriceRepository'
import VoiceCallConfigRepository from '@/data/repositories/domain/voiceCallConfigRepository'
import SenderSettingsCountriesRepository from '@/data/repositories/domain/senderSettingsCountriesRepository'
import PhoneValidationRegistrator from '@/services/validation/phoneValidationRegistrator'
import PasswordFieldBuilderService from '@/services/forms/fieldBuilders/PasswordFieldBuilderService'
import ModelSettingsResolverService from '@/services/resolvers/modelSettingsResolver'
import PermissionsService from '@/services/permissionsService'
import RouterPermissionsService from '@/services/routerPermissionsService'
import SenderIdPriceService from '@/services/domain/senderId/senderIdPriceService'
import SenderIdPriceRepository from '@/data/repositories/domain/services/senderIdPriceRepository'
import VoiceCallConfigResolverService from '@/services/resolvers/domain/voiceCallConfig/voiceCallConfigResolver'
import ForwardAudioFormService from '@/services/forms/forwardAudioFormService'
import TenDlcPricingService from '@/services/domain/services/regulations/tenDlcPricingService'
import TenDlcPricingRepository from '@/data/repositories/domain/services/regulations/tenDlcPricingRepository'
import TenDlcPricesResolverService from '@/services/resolvers/domain/regulations/tenDlcPricesResolver'
import { TypedArrayFormBuilderService } from '@/services/forms/baseForm/typedFormBuilder/TypedArrayFormBuilderService'
import TenDlcPhoneNumbersRepository from '@/data/repositories/domain/services/regulations/tenDlcPhoneNumbersRepository'
import TenDlcPhoneNumbersService from '@/services/domain/services/regulations/tenDlcPhoneNumbers/tenDlcPhoneNumbersService'
import TenDlcCampaignsRepository from '@/data/repositories/domain/services/regulations/tenDlcCampaignsRepository'
import TenDlcCampaignsService from '@/services/domain/services/regulations/tenDlcCampaigns/tenDlcCampaignsService'
import TenDlcBrandRepository from '@/data/repositories/domain/services/regulations/tenDlcBrandRepository'
import TenDlcBrandService from '@/services/domain/services/regulations/tenDlcBrand/tenDlcBrandService'
import RegulationsStatusRepository from '@/data/repositories/domain/services/regulations/regulationsStatusRepository'
import RegulationsStatusService from '@/services/domain/services/regulations/regulationsStatusService'
import OldVersionInterceptor from '@/services/transport/interceptors/oldVersionInterceptor'
import TenDlcPhoneNumbersDetailsColumnServiceFactory from '@/services/tables/columns/services/regulations/tenDlcPhoneNumbersDetailsColumnService'
import TollFreePhoneNumbersService from '@/services/domain/services/regulations/tollFreePhoneNumbers/tollFreePhoneNumbersService'
import TollFreePhoneNumbersRepository from '@/data/repositories/domain/services/regulations/tollFreePhoneNumbersRepository'
import ServiceDistributionRecipientsFormService from '@/services/forms/services/distribution/ServiceDistributionRecipientsFormService'
import ServiceDistributionAdditionalSettingsFormService from '@/services/forms/services/distribution/ServiceDistributionAdditionalSettingsFormService'
import RecipientService from '@/services/domain/messages/recipientService'
import DistributionDetailsRepository from '@/data/repositories/domain/services/distribution/distributionDetailsRepository'
import DistributionDetailsService from '@/services/domain/services/distribution/distributionDetailsService'
import TrackingService from '@/services/tracking/trackingService'
import ColumnsSizeService from '@/services/tables/columnsSizeService'
import ByocAccessResolver from '@/services/resolvers/domain/services/byoc/byocAccessResolver'
import SystemExitResolver from '@/services/resolvers/auth/systemExitResolver'
import SystemRefreshResolver from '@/services/resolvers/auth/systemRefreshResolver'
import ZendeskService from '@/services/tracking/zendeskService'
import UserAnalyticsFieldsService from '@/services/domain/user/userAnalyticsFieldsService'
import UserAnalyticsFieldsRepository from '@/data/repositories/domain/users/userAnalyticsFieldsRepository'
import SegmentAnalyticsService from '@/services/tracking/segmentAnalyticsService'
import AccessControlService from '@/services/accessControl/accessControlService'
import {
  DealsReadOnlyStrategy,
  DealsStrategy,
  CampaignsStrategy,
  CampaignsEmailStrategy,
  GettingStartedStrategy,
  MonthlyPlansStrategy,
  SenderIdsStrategy,
  SubAccountDetailsStrategy,
  SubAccountsImportFlowStrategy,
  SubAccountsWorkflowStrategy,
  SuperAdminRoleStrategy,
  TasksReadOnlyStrategy,
  TenDlcStrategy,
  TicketsStrategy,
  TollFreeStrategy,
  UsersWorkflowNotUserRoleStrategy,
  UsersWorkflowSuperAdminRoleStrategy,
  UserWorkflowStrategy,
  WebWidgetsStrategy,
  ByocStrategy,
  PaymentCongratsStrategy,
  SegmentsStrategy,
  NotCampaignsCalendarStrategy,
  NotCampaignsScheduledStrategy,
  NotCampaignsScheduledUpcomingStrategy,
  NotCampaignsScheduledPausedStrategy,
  NotCampaignsScheduledNotSentStrategy,
  NotCampaignsScheduledCompletedStrategy,
  NotCampaignsScheduledDetailsStrategy,
  NotCampaignsScheduledEditStrategy,
  NotCampaignsComposeMessageStrategy,
  NotCampaignsComposeSuccessMessageStrategy,
  NotCampaignsComposeSuccessMessagesStrategy,
  NotCampaignsComposeSuccessScheduledStrategy,
  NotCampaignsComposeCampaignStrategy,
  NotCampaignsComposeEmailStrategy,
  NotCampaignsTemplatesAllStrategy,
  NotCampaignsTemplatesCategoryStrategy,
  NotCampaignsSmsSurveysStrategy,
  NotCampaignsSmsSurveysDetailsStrategy,
  NotCampaignsSmsSurveysViewFlowStrategy,
  NotCampaignsSmsSurveysEditStrategy,
  PaymentStrategy,
  InstagramStrategy,
  PointAiManagementStrategy,
  UnsubscribedEmailStrategy,
  HistoryOutboundEmailsStrategy,
} from '@/services/accessControl/accessControlStrategies'
import UserAnalyticsTraitsService from '@/services/domain/user/userAnalyticsTraitsService'
import UserAnalyticsTraitsRepository from '@/data/repositories/domain/users/userAnalyticsTraitsRepository'
import ShareASaleService from '@/services/tracking/shareASaleService'
import FirstPromoterService from '@/services/tracking/firstPromoterService'
import ImpersonationModeInterceptor from '@/services/transport/interceptors/impersonationModeInterceptor'
import SentryMonitoringService from '@/services/monitoring/sentryMonitoringService'
import { isSentryOn } from '@/utils/system'
import { NoopMonitoringService } from '@/services/monitoring/noopMonitoringService'
import UnauthenticatedCountryRepository from '@/data/repositories/domain/unauthenticatedCountryRepository'
import UnauthenticatedCountryService from '@/services/domain/unauthenticatedCountryService'
import AttachmentFileRepository from '@/data/repositories/domain/attachmentFile/attachmentFileRepository'
import AttachmentFileService from '@/services/domain/attachmentFile/attachmentFileService'
import MessagesPriceForCountryService from '@/services/domain/messages/messagesPriceForCountryService'
import MessagesPriceForCountryRepository from '@/data/repositories/domain/messagesPriceForCountryRepository'
import SocketCentrifugeService from '@/services/transport/socketCentrifugeService'
import EntityExportService from '@/services/entityExportService'
import StaticPaginationService from '@/services/tables/pagination/staticPaginationService'
import OAuthSignUpRepository from '@/data/repositories/oAuthSignUpRepository'
import OAuthSignUpService from '@/services/signUp/oAuthSignUpService'
import { FeatureFlagsService } from '@/services/featureFlagsService'
import WizardRepository from '@/data/repositories/domain/WizardRepository'
import WizardService from '@/services/domain/wizard/wizardService'
import WizardResolverService from '@/services/resolvers/containers/wizardResolverService'
import SyncQueueService from '@/services/tracking/syncQueueService'
import LoggedInStatusService from '@/services/auth/loggedInStatusService'
import WizardAnalyticsResolverService from '@/services/resolvers/containers/wizardAnalyticsResolverService'
import { registerModuleDeclaration } from '@/core/container/deps'
import { REPORTING_SERVICE_TYPES } from '@/core/container/reporting.types'
import { EMAIL_TO_SMS_SERVICE_TYPES } from '@/core/container/emailToSms.types'
import SendReceiveSettingsService from '@/services/domain/sendReceiveSettings/sendReceiveSettingsService'
import SendReceiveSettingsRepository from '@/data/repositories/domain/sendReceiveSettingsRepository'
import { CARRIER_LOOKUP_SERVICE_TYPES } from '@/core/container/lookup/carrierLookup.types'
import { SMS_SURVEYS_SERVICE_TYPES } from '@/core/container/smsSurveys.types'
import FileUploadFieldBuilderService from '@/services/forms/fieldBuilders/fileUploadFieldBuilderService'
import { SENDER_SETTINGS_SERVICE_TYPES } from '@/core/container/senderSettings/senderSettings.types'
import { EMAIL_LOOKUP_SERVICE_TYPES } from '@/core/container/lookup/emailLookup.types'
import { API_SERVICE_TYPES } from '@/core/container/api.types'
import { AUTOMATION_RULES_SERVICE_TYPES } from '@/core/container/automationRules.types'
import { SUB_ACCOUNTS_SERVICE_TYPES } from '@/core/container/subAccounts/subAccounts.types'
import ByocRepository from '@/data/repositories/domain/services/byoc/byocRepository'
import ByocService from '@/services/domain/byoc/byocService'
import EditableBadgesFieldBuilderService from '@/services/forms/fieldBuilders/editableBadgesFieldBuilderService'
import { SIGNUP_BY_INVITE_SERVICE_TYPES } from '@/core/container/unauthorized/signupByInvite.types'
import { SIGNUP_BY_OAUTH_SERVICE_TYPES } from '@/core/container/unauthorized/signupByOAuth.types'
import { TFA_SERVICE_TYPES } from '@/core/container/unauthorized/tfaPages.types'
import { NOTIFICATIONS_SERVICE_TYPES } from '@/core/container/notifications.types'
import { ACCOUNT_SETTINGS_SERVICE_TYPES } from '@/core/container/accountSettings.types'
import { VERIFY_EMAIL_SERVICE_TYPES } from '@/core/container/unauthorized/verifyEmail.types'
import { SIGNUP_CONFIRMATION_SERVICE_TYPES } from '@/core/container/unauthorized/signupConfirmation.types'
import { LOGOUT_SERVICE_TYPES } from '@/core/container/unauthorized/logout.types'
import { LOGIN_SSO_SERVICE_TYPES } from '@/core/container/unauthorized/loginSSO.types'
import { FORGOT_PASSWORD_SERVICE_TYPES } from '@/core/container/unauthorized/forgotPassword.types'
import { RESET_PASSWORD_SERVICE_TYPES } from '@/core/container/unauthorized/resetPassword.types'
import { AUTO_DETECTED_COUNTRY_TYPES } from '@/core/container/autoDetectedCountry.types'
import { BILLING_SERVICE_TYPES } from '@/core/container/billing.types'
import { EMAIL_CONFIRMATION_SERVICE_TYPES } from '@/core/container/unauthorized/emailConfirmation.types'
import { INVOICES_SERVICE_TYPES } from '@/core/container/invoices.types'
import { HISTORY_SENT_SMS_SERVICE_TYPES } from '@/core/container/history/historySentSms.types'
import UserAdminGridable from '@/services/domain/user/userAdminGridable'
import UserAdminsColumnServiceFactory from '@/services/tables/columns/services/user/userAdminsColumnService'
import { TEMPLATES_SERVICE_TYPES } from '@/core/container/templates.types'
import { MY_DATA_SERVICE_TYPES } from '@/core/container/myData.types'
import { HISTORY_RECEIVED_SMS_SERVICE_TYPES } from '@/core/container/history/historyReceivedSms.types'
import { HISTORY_FORWARDED_CALLS_SERVICE_TYPES } from '@/core/container/history/historyForwardedCalls.types'
import { HISTORY_OUTBOUND_CALLS_SERVICE_TYPES } from '@/core/container/history/historyOutboundCalls.types'
import { HISTORY_INBOUND_CALLS_SERVICE_TYPES } from '@/core/container/history/historyInboundCalls.types'
import { SCHEDULED_UPCOMING_SERVICE_TYPES } from '@/core/container/scheduled/scheduledUpcoming.types'
import { SCHEDULED_PAUSED_SERVICE_TYPES } from '@/core/container/scheduled/scheduledPaused.types'
import { SCHEDULED_NOT_SENT_SERVICE_TYPES } from '@/core/container/scheduled/scheduledNotSent.types'
import { SCHEDULED_COMPLETED_SERVICE_TYPES } from '@/core/container/scheduled/scheduledCompleted.types'
import { SCHEDULED_CALENDAR_SERVICE_TYPES } from '@/core/container/scheduled/scheduledCalendar.types'
import { BUY_NUMBER_SERVICE_TYPES } from '@/core/container/buyNumber.types'
import { AuthLoaderService } from '@/services/authLoaderService'
import { CHECK_EMAIL_FOR_SIGNUP_SERVICE_TYPES } from '@/core/container/unauthorized/checkEmailForSignup.types'
import FromPaginationFaceterService from '@/services/facets/fromPaginationFaceterService'
import SectionService from '@/services/wrappers/sectionService'
import { TEN_DLC_TOLL_FREE_SERVICE_TYPES } from '@/core/container/tenDlcTollFree.types'
import { WIZARD_SERVICE_TYPES } from '@/core/container/wizard.types'
import { UiStateService } from '@/services/uiStateService'
import { UiStateRepository } from '@/data/repositories/domain/uiStateRepository'
import { PdfWorkerService } from '@/services/workers/pdf/PdfWorkerService'
import { CONTACTS_UNSUBSCRIBED_SERVICES_TYPES } from '@/core/container/contacts/unsubscribed.types'
import { CONTACTS_BLOCKED_SERVICES_TYPES } from '@/core/container/contacts/blocked.types'
import GettingStartedInfoService from '@/services/domain/gettingStartedInfoService'
import GettingStartedInfoRepository from '@/data/repositories/domain/gettingStartedInfoRepository'
import SettingStartedInfoResolverService from '@/services/resolvers/containers/gettingStartedInfoResolverService'
import { PromiseExecutorPoolManager } from '@/services/promiseExecutorPoolManager'
import { MeasureableResolverExecutorService } from '@/services/resolvers/measureableResolverExecutorService'
import { PerformanceMarkService } from '@/services/performanceMarkService'
import { MonitoringLoggerService } from '@/services/monitoring/monitoringLoggerService'
import RoutesGatheringMiddleware from '@/services/route/routerBuilderMiddleware/routesGatheringMiddleware'
import SenderSettingsCountriesGridable from '@/services/domain/senderSettings/senderSettingsCountriesGridable'
import { BYOC_SERVICE_TYPES } from '@/core/container/byoc/byoc.types'
import { VOICE_CALL_SERVICES_TYPES } from '@/core/container/voiceCall.types'
import ReopenAccountRepository from '@/data/repositories/reopenAccountRepository'
import ReopenAccountService from '@/services/signUp/reopenAccountService'
import ReopenAccountResolver from '@/services/resolvers/auth/reopenAccountResolver'
import { SUB_ACCOUNTS_IMPORT_FLOW_SERVICE_TYPES } from '@/core/container/subAccounts/import/subAccountsImportFlow.types'
import { SUB_ACCOUNTS_IMPORT_DETAILS_SERVICE_TYPES } from '@/core/container/subAccounts/import/subAccountsImportDetails.types'
import { SUB_ACCOUNTS_IMPORT_LIST_SERVICE_TYPES } from '@/core/container/subAccounts/import/subAccountsImportList.types'
import { SmsContentService } from '@/services/smsContentService'
import UserMetadataService from '@/services/domain/user/userMetadataService'
import UserMetadataRepository from '@/data/repositories/domain/userMetadataRepository'
import { CONTACT_SERVICES_TYPES } from '@/core/container/contacts.types'
import { SEGMENT_SERVICES_TYPES } from '@/core/container/segments.types'
import { CONTACTS_BASE_SERVICES_TYPES } from '@/core/container/contactsBase.types'
import { COMPOSE_SERVICE_TYPES } from '@/core/container/compose.types'
import { CONTACTS_IMPORT_FLOW_SERVICE_TYPES } from '@/core/container/contacts/import/contactsImportFlow.types'
import { CONTACTS_IMPORT_DETAILS_SERVICE_TYPES } from '@/core/container/contacts/import/contactsImportDetails.types'
import { CONTACTS_IMPORT_LIST_SERVICE_TYPES } from '@/core/container/contacts/import/contactsImportList.types'
import { MicroSentryMonitoringService } from '@/services/monitoring/microSentryMonitoringService'
import { EMAIL_LOOKUP_IMPORT_SERVICE_TYPES } from '@/core/container/lookup/emailLookupImport.types'
import { EMAIL_LOOKUP_SESSION_SERVICE_TYPES } from '@/core/container/lookup/emailLookupSession.types'
import { CARRIER_LOOKUP_IMPORT_SERVICE_TYPES } from '@/core/container/lookup/carrierLookupImport.types'
import { CARRIER_LOOKUP_SESSION_SERVICE_TYPES } from '@/core/container/lookup/carrierLookupSession.types'
import { CONTACT_LIST_SERVICES_TYPES } from '@/core/container/contactList.types'
import { DATE_TIME_SERVICE_TYPES } from '@/core/container/dateTime.types'
import { INVOICES_BASE_SERVICE_TYPES } from '@/core/container/invoicesBase.types'
import WizardPrivateUseCaseResolver from '@/services/resolvers/containers/wizardPrivateUseCaseResolver'
import { TEN_DLC_TOLL_FREE_REQUEST_SERVICE_TYPES } from '@/core/container/tenDlcTollFreeRequest.types'
import ReopenAccountFormService from '@/services/forms/auth/reopenAccountFormService'
import { SUB_ACCOUNTS_BASE_SERVICE_TYPES } from '@/core/container/subAccounts/subAccountsBase.types'
import { SUB_ACCOUNTS_DETAILS_SERVICE_TYPES } from '@/core/container/subAccounts/subAccountsDetails.types'
import { SUB_ACCOUNTS_FORM_SERVICE_TYPES } from '@/core/container/subAccounts/subAccountsFormServices.types'
import RestrictionPagesRouterService from '@/services/auth/restrictionPagesRouterService'
import { RRULE_SERVICE_TYPES } from '@/core/container/rrule.types'
import { SCHEDULED_EDIT_SERVICE_TYPES } from '@/core/container/scheduled/scheduledEdit.types'
import { MESSAGE_SEND_TYPES } from '@/core/container/messageSend.types'
import SubAccountsSwitchColumnService from '@/services/tables/columns/subAccounts/subAccountsSwitchColumnService'
import { MESSAGES_BULKS_PROGRESS_SERVICES_TYPES } from '@/core/container/messagesBulksProgress.types'
import { BILLING_BASE_SERVICE_TYPES } from '@/core/container/billingBase.types'
import { BYOC_HELPER_SERVICE_TYPES } from '@/core/container/byoc/byocHelper.types'
import { SENDER_SETTINGS_COUNTRIES_SERVICE_TYPES } from '@/core/container/senderSettings/senderSettingsCountries.types'
import { CONTACT_COUNTER_SERVICES_TYPES } from '@/core/container/contactCounter.types'
import { CONTACT_LIST_COUNTER_SERVICES_TYPES } from '@/core/container/contactListCounter.types'
import { ACTIVITY_SERVICES_TYPES } from '@/core/container/activity.types'
import { HotJarService } from '@/services/tracking/hotJarService'
import { CUSTOM_FIELD_SERVICES } from '@/core/container/customField.types'
import MonthlyPlansResolverService from '@/services/resolvers/domain/account/monthlyPlansResolverService'
import { STATEMENTS_SERVICE_TYPES } from '@/core/container/accounts/statements.types'
import { LRUCacheService } from '@/services/cache/lruCacheService'
import { TableBaseRecoveryService } from '@/services/tables/recovery/tableBaseRecoveryService'
import { COMING_SOON_SERVICE_TYPES } from '@/core/container/comingSoon.types'
import { TEAM_WORKFLOW_SERVICE_TYPES } from '@/core/container/teamWorkflow.types'
import TeamWorkflowService from '@/services/domain/accounts/teamWorkflowService'
import TeamWorkflowRepository from '@/data/repositories/domain/TeamWorkflowRepository'
import ByocSubscribeResolver from '@/services/resolvers/domain/services/byoc/byocSubscribeResolver'
import { AUTOMATION_RULES_DETAILS_SERVICE_TYPES } from '@/core/container/automationRulesDetails.types'
import { AUTOMATION_RULES_LOG_SERVICE_TYPES } from '@/core/container/automationRulesLog.types'
import { AUTOMATION_RULE_SERVICE_TYPES } from '@/core/container/automationRule.types'
import { PAYMENT_SERVICE_TYPES } from '@/core/container/payment.types'
import { SMART_BANNER_SERVICE_TYPES } from '@/core/container/smartBanner.types'
import { WIDGET_SUBSCRIBE_SERVICE_TYPES } from '@/core/container/widget/widgetSubscribe.types'
import { WIDGET_CLICK_TO_TEXT_SERVICE_TYPES } from '@/core/container/widget/widgetClickToText.types'
import { TASKS_SERVICES_TYPES } from '@/core/container/tasks/tasks.types'
import { TASKS_BASE_SERVICES_TYPES } from '@/core/container/tasks/tasksBase.types'
import { PostHogService } from '@/services/tracking/postHogService'
import ErrorSerializerService from '@/services/transport/errorSerializer/errorSerializerService'
import { errorsCustomRulesMap } from '@/services/transport/errorSerializer/consts'
import { FORWARDED_CALLS_SERVICES_TYPES } from '@/core/container/history/forwardedCalls.types'
import { NAVIGATION_ORDER_SERVICES_TYPES } from '@/core/container/navigationOrder.types'
import { RECENT_RECIPIENT_SERVICE_TYPES } from '@/core/container/recentRecipient.types'
import StaticSortService from '@/services/tables/sort/staticSortService'
import { KEYWORDS_SERVICE_TYPES } from '@/core/container/automationRules/keywords.types'
import PlanExpiredResolverService from '@/services/resolvers/containers/planExpiredResolverService'
import SubscriptionEventsResolverService from '@/services/resolvers/containers/subscriptionEventsResolverService'
import { SENT_SMS_BASE_SERVICE_TYPES } from '@/core/container/history/sentSmsBase.types'
import { SCHEDULED_SERVICE_TYPES } from '@/core/container/scheduled/scheduled.types'
import { SCHEDULED_DETAILS_SERVICE_TYPES } from '@/core/container/scheduled/scheduledDetails.types'
import { EMAIL_UNSUBSCRIBE_SERVICES_TYPES } from '@/core/container/emailUnsubscribe.types'
import AppModuleResolverService from '@/services/resolvers/appModuleResolverService'
import TeamSubscriptionPriceRepository from '@/data/repositories/domain/TeamSubscriptionPriceRepository'
import TeamSubscriptionPriceService from '@/services/domain/accounts/teamSubscriptionPriceService'
import SubscriptionBillingSettingsResolverService from '@/services/resolvers/domain/account/subscriptionBillingSettingsResolverService'
import StopImpersonateResolver from '@/services/resolvers/stopImpersonateResolver'
import { PINNED_CONTACTS_SERVICE_TYPES } from '@/core/container/contacts/pinned.types'
import CentrifugeService from '@/services/domain/accounts/CentrifugeService'
import CentrifugeRepository from '@/data/repositories/domain/CentrifugeRepository'
import WorkspaceServerSubscriptionService from '@/services/transport/workspaceServerSubscriptionService'
import WebSocketChannelsService from '@/services/transport/webSocketChannelsService'
import UserWorkflowAccountChangedResolverService from '@/services/resolvers/domain/account/userWorkflowAccountChangedResolverService'
import PlanExpiredInterceptor from '@/services/transport/interceptors/planExpiredInterceptor'
import ContactServerEventsResolverService from '@/services/resolvers/containers/contactServerEventsResolverService'
import { WEB_WIDGET_TYPES } from '@/core/container/messages/settings/webWidgets.types'
import { TenDlcPricesPreloaderService } from '@/services/resolvers/domain/regulations/tenDlcPricesPreloaderService'
import GoogleAnalyticsService from '@/services/tracking/googleAnalyticsService'
import SsoAuthService from '@/services/auth/ssoAuthService'
import { WHATSAPP_SERVICE_TYPES } from '@/core/container/messenger/whatsapp.types'
import { WidgetEmbedCodeResolver } from '@/services/resolvers/domain/services/widgetEmbedCodeResolver'
import { STRIPE_BILLING_SERVICES_TYPES } from '@/core/container/stripeBilling.types'
import { USER_SESSION_SERVICE_TYPES } from '@/core/container/user/userSession'
import TokenIsLockedInterceptor from '@/services/transport/interceptors/tokenIsLockedInterceptor'
import RedirectByPrevRouteResolverService from '@/services/resolvers/redirectByPrevRouteResolverService'
import { TelemetryService } from '@/services/telemetry/telemetryService'
import OptimisticUpdateService from '@/services/optimisticUpdateService'
import StripeInvalidBillingDetailsInterceptor from '@/services/transport/interceptors/stripeInvalidBillingDetailsInterceptor'
import WrappersConfigService from '@/services/wrappers/wrappersConfigService'
import FetchAllAccountsResolver from '@/services/resolvers/domain/chat/fetchAllAccountsResolver'
import UiSettingsRepository from '@/data/repositories/domain/uiSettingsRepository'
import UiSettingsService from '@/services/domain/uiSettingsService'
import UiSettingsResolverService from '@/services/resolvers/containers/uiSettingsResolverService'
import UserCustomFieldsEventResolverService from '@/services/resolvers/domain/user/UserCustomFieldsEventResolverService'
import UserSettingsRepository from '@/data/repositories/domain/userSettingsRepository'
import UserSettingsService from '@/services/domain/user/userSettingsService'
import TokenPaginationServiceFactory from '@/services/tables/pagination/tokenPaginationServiceFactory'
import { NonBlockedRedirectableSingleModelResolverService } from '@/services/resolvers/nonBlockedRedirectableSingleModelResolverService'
import { ZukoAnalyticsService } from '@/services/tracking/zukoAnalyticsService'
import { AUTH_SERVICE_TYPES } from '@/core/container/unauthorized/auth.types'
import NoteBaseFormService from '@/services/forms/note/noteBaseFormService'
import NoteGridable from '@/services/domain/note/noteGridable'
import { AUDIT_LOGS_SERVICES_TYPES } from '@/core/container/auditLogs.types'
import { PosthogRecordingService } from '@/services/tracking/posthogRecordingService'
import { TICKETS_SERVICES_TYPES } from '@/core/container/tickets/tickets.types'
import PriorityHelperService from '@/services/priority/priorityHelperService'
import FieldDataCacheService from '@/services/forms/fieldCache/fieldDataCacheService'
import FieldsDataCacheClearByModelEventResolverService from '@/services/resolvers/fieldDataCache/fieldDataCacheClearByModelEventResolverService'
import SenderPhoneFieldBuilderService from '@/services/forms/fieldBuilders/SenderPhoneFieldBuilderService'
import { CountrySenderPhoneListFieldBuilderService } from '@/services/forms/fieldBuilders/CountrySenderPhoneListFieldBuilderService'
import ConvertService from '@/services/tracking/convertService'
import { PosthogRecordingServiceResolver } from '@/services/tracking/resolvers/posthogRecordingServiceResolver'
import TenDlcTwilioBrandRepository from '@/data/repositories/domain/services/regulations/tenDlcTwilioBrandRepository'
import TenDlcTwilioBrandService from '@/services/domain/services/regulations/tenDlcBrand/tenDlcTwilioBrandService'
import WeekDaysTimeRangeFieldBuilder from '@/services/forms/fieldBuilders/weekDaysTimeRangeFieldBuilder'
import FormFactoryManager from '@/services/forms/baseForm/formFactoryManager'
import { TAGS_SERVICES_TYPES } from '@/core/container/tags.types'
import { FILTERED_VIEWS_SERVICES_TYPES } from '@/core/container/filteredViews.types'
import TenDlcNumbersCountResolver from '@/services/resolvers/domain/regulations/TenDlcNumbersCountResolver'
import FieldAssigneeCacheClearResolverService from '@/services/resolvers/fieldAssigneeCacheClearResolverService'
import TicketsCountRepository from '@/data/repositories/domain/tickets/ticketsCountRepository'
import TicketCountService from '@/services/domain/tickets/ticketCountService'
import TicketCountResolverService from '@/services/resolvers/ticketCountResolverService'
import { DEALS_SERVICES_TYPES } from '@/core/container/deals/deals.types'
import { TYPING_SERVICE_TYPES } from '@/core/container/typing.types'
import { ArrayFilterRepository } from '@/data/repositories/filters/arrayFilterRepository'
import { FilterCompareService } from '@/services/tables/filters/filterCompareService'
import { FilterCleanupService } from '@/services/tables/filters/filterCleanupService'
import { SorterSerializerService } from '@/services/tables/sort/sorterSerializerService'
import { TICKETS_NOTIFICATIONS_SERVICES_TYPES } from '@/core/container/tickets/ticketsNotifications.types'
import { FACEBOOK_SERVICE_TYPES } from '@/core/container/messenger/facebook.types'
import { KANBAN_SERVICE_TYPES } from '@/core/container/kanban.types'
import SingleSettingsModelResolverService from '@/services/resolvers/singleSettingsModelResolver'
import { ALSO_HERE_SERVICE_TYPES } from '@/core/container/alsoHere.types'
import { CHANNEL_PRESENCE_SERVICE_TYPES } from '@/core/container/channelPresence.types'
import AdminSectionRedirectResolver from '@/services/resolvers/adminSectionRedirectResolver'
import { SMS_CAMPAIGNS_SERVICE_TYPES } from '@/core/container/campaigns/smsCampaigns.types'
import { EMAIL_CAMPAIGNS_SERVICE_TYPES } from '@/core/container/campaigns/emailCampaigns.types'
import PrimaryBrowserTabService from '@/services/browser/primaryBrowserTabService'
import PrimaryBrowserTabRepository from '@/data/repositories/PrimaryBrowserTabRepository'
import PrimaryBrowserTabLockRepository from '@/data/repositories/PrimaryBrowserTabLockRepository'
import SelectInboxFieldBuilderService from '@/services/forms/fieldBuilders/SelectInboxFieldBuilderService'
import { POINT_AI_SERVICES_TYPES } from '@/core/container/pointAi.types'
import MainSidebarRoutesResolverService from '@/services/resolvers/containers/mainSidebarRoutesResolverService'
import UserPresenceResolver from '@/services/resolvers/userPresenceResolver'
import ChatFacebookResolver from '@/services/resolvers/domain/chat/chatFacebookResolver'
import SessionStatRepository from '@/data/repositories/domain/session/sessionStatRepository'
import SessionStatService from '@/services/domain/session/sessionStatService'
import { CHAT_PREVIEW_NEXT_SERVICE_TYPES } from '@/core/container/chatPreviewNext.types'
import SessionShortService from '@/services/domain/session/sessionShortService'
import SelectFacebookPageFieldBuilderService from '@/services/forms/fieldBuilders/SelectFacebookPageFieldBuilderService'
import SelectInstagramAccountFieldBuilderService from '@/services/forms/fieldBuilders/SelectInstagramAccountFieldBuilderService'
import UserPolicyPrivacyChangedResolverService from '@/services/resolvers/domain/account/userPolicyPrivacyChangedResolverService'
import UserPresenceRepository from '@/data/repositories/domain/users/userPresenceRepository'
import UserPresenceService from '@/services/domain/user/userPresenceService'
import ModelSearcherManager from '@/services/search/modelSearcherManager'
import ModelSearcherServiceFactory from '@/services/search/modelSearcherServiceFactory'
import { INSTAGRAM_SERVICE_TYPES } from '@/core/container/messenger/instagram.types'
import ChatInstagramResolver from '@/services/resolvers/domain/chat/chatInstagramResolver'
import UserRefusalSurveyService from '@/services/domain/user/UserRefusalSurveyService'
import { TM_TIPTAP_EDITOR_SERVICES_TYPES } from '@/core/container/tmTiptapEditor.types'
import TableExpandService from '@/services/tables/tableExpandService'
import TemplatesTableExpandLocalStorageService from '@/services/resolvers/containers/templatesTableExpandLocalStorageService'
import BulkFieldBuilderService from '@/services/forms/bulk/BulkFieldBuilderService'
import BrowserIdentifierService from '@/services/browser/browserIdentifierService'
import { OUTBOUND_EMAILS_SERVICES_TYPES } from '@/core/container/history/outboundEmails.types'
import DateFilterMapper from '@/services/dateFilterMapper'
import { RECIPIENT_SERVICE_TYPES } from '@/core/container/recipient.types'

const servicesList: ServicesType<RegisteredServices> = {
  PerformanceMarkService: pureService(PerformanceMarkService),
  MonitoringService: pureService(isSentryOn() ? SentryMonitoringService : NoopMonitoringService),
  MicroSentryMonitoringService: pureService(isSentryOn() ? MicroSentryMonitoringService : NoopMonitoringService),
  MonitoringLoggerService: pureService(MonitoringLoggerService),
  TelemetryService: pureService(TelemetryService),
  Config: pureService(ConfigReader.config(), 'value'),
  FieldMapper: pureService(FieldMapper, 'value'),
  LoggerService: pureService(LoggerService),
  VueService: pureService(VueService),
  VuexService: pureService(VuexService),
  WindowService: pureService(WindowService),
  ScrollbarService: pureService(ScrollbarService),
  TableScrollbarService: pureService(TableScrollbarService),
  PrimaryBrowserTabService: pureService(PrimaryBrowserTabService),
  BrowserIdentifierService: pureService(BrowserIdentifierService),
  AuthService: pureService(AuthService),
  SsoAuthService: pureService(SsoAuthService),
  LoggedInStatusService: pureService(LoggedInStatusService),
  OAuthSignUpService: pureService(OAuthSignUpService),
  AccessControlService: pureService(AccessControlService),
  LocalStorageAdapter: pureService(LocalStorageAdapter),
  Api: pureService(HttpService),
  EndpointsService: pureService(EndpointsService),
  EntityManager: pureService(EntityManagerService),
  BaseRouterService: pureService(BaseRouterService),
  HistoryService: pureService(HistoryService),
  ResolverService: pureService(ResolverService),
  ResolverExecutorService: pureService(MeasureableResolverExecutorService),
  PromiseExecutorPoolManager: pureService(PromiseExecutorPoolManager),
  NewQueryParamsService: pureService(NewQueryParamsService),
  RouterService: pureService(RouterService),
  RouteLeaveService: pureService(RouteLeaveService),
  RouteLeaveResolver: resolverService(RouteLeaveResolver),
  ResolverManager: pureService(ResolverManager),
  RouterBuilderService: pureService(RouterBuilderService),
  RouteEntitiesService: pureService(RouteEntitiesService),
  SerializerService: pureService(SerializerService),
  BaseStorageTransport: pureService(BaseStorageTransport),
  LocalStorageTransport: pureService(LocalStorageTransport),
  FilterSchemaService: pureService(FilterSchemaService),
  SubscriptionService: pureService(SubscriptionService),
  ModelSubscriptionService: pureService(ModelSubscriptionService),
  ServerSubscriptionService: pureService(ServerSubscriptionService),
  WorkspaceServerSubscriptionService: pureService(WorkspaceServerSubscriptionService),
  FormSubscriptionService: pureService(FormSubscriptionService),
  TranslateService: pureService(TranslateService),
  NotificationService: pureService(NotificationService),
  NativeNotificationService: pureService(NativeNotificationService),
  ExportService: pureService(ExportService),
  FilterQueryService: pureService(FilterQueryService),
  FilterBracketNotationQueryService: pureService(FilterBracketNotationQueryService),
  FilterBuilderService: pureService(FilterBuilderService),
  NumericService: pureService(NumericService),
  LocalStorageService: pureService(LocalStorageService),
  SessionStorageService: pureService(SessionStorageService),
  LRUCacheService: pureService(LRUCacheService),
  QueryStorageService: pureService(QueryStorageService),
  PhoneValidateAutocompleteService: pureService(PhoneValidateAutocompleteService),
  PhoneAutocompleteService: pureService(PhoneAutocompleteService),
  GlobalStateService: pureService(GlobalStateService),
  FeatureFlagsService: pureService(FeatureFlagsService),
  AuthLoaderService: pureService(AuthLoaderService),
  SmsContentService: pureService(SmsContentService),
  RestrictionPagesRouterService: pureService(RestrictionPagesRouterService),
  DateFilterMapper: pureService(DateFilterMapper),

  // bulk
  BulkService: pureService(BulkService),
  BulkManager: pureService(BulkManager),
  // bulk

  // preloaders
  UserPreloaderService: preloaderService(UserPreloaderService),
  ModelPreloaderService: pureService(ModelPreloaderService),
  TablePreloaderService: pureService(TablePreloaderService),
  AutocompletePreloaderService: pureService(AutocompletePreloaderService),
  PreloaderManager: pureService(PreloaderManager),
  // preloaders

  AutocompleteKeyBuilder: pureService(AutocompleteKeyBuilder),

  // facets
  FacetManager: pureService(FacetManager),
  FaceterService: factoryService(FaceterService),
  FromPaginationFaceterService: faceterService(FromPaginationFaceterService),
  // facets

  // wrapper services
  WrapperManagerService: pureService(WrapperManagerService),
  BaseWrapperService: wrapperService(BaseWrapperService),
  ModalService: wrapperService(ModalService),
  TableWrapperService: wrapperService(TableWrapperService),
  TableQueryWrapperService: wrapperService(TableQueryWrapperService),
  NewQueryModalService: wrapperService(NewQueryModalService),
  SectionService: wrapperService(SectionService),
  PartialUIWrapperService: wrapperService(PartialUIWrapperService),
  PartialQueryUIWrapperService: wrapperService(PartialQueryUIWrapperService),
  GroupedPartialUIWrapperService: wrapperService(GroupedPartialUIWrapperService),
  GroupedQueryPartialUIWrapperService: wrapperService(GroupedQueryPartialUIWrapperService),
  TableLocalStorageWrapperService: wrapperService(TableLocalStorageWrapperService),
  // wrappers
  ConfirmationService: pureService(ConfirmationService),
  UserService: domainService(UserService),
  UserSettingsService: domainService(UserSettingsService),
  UserRoleLabelDataService: pureService(UserRoleLabelDataService),
  WrappersConfigService: pureService(WrappersConfigService),

  ValidationService: pureService(ValidationService),
  ValidationRulesBuilderService: pureService(ValidationRulesBuilderService),
  CommonValidationRegistrator: validationRegistratorService(CommonValidationRegistrator),
  SpecialValidationRegistrator: validationRegistratorService(SpecialValidationRegistrator),
  FileValidationRegistrator: validationRegistratorService(FileValidation),
  PhoneValidationRegistrator: validationRegistratorService(PhoneValidationRegistrator),
  FormValidationService: pureService(FormValidationService),
  SubValidationService: pureService(SubValidationService),
  FormBuilderFactoryService: pureService(FormBuilderService, 'factory'),
  StatusFieldBuilderService: pureService(StatusFieldBuilderService),
  FormManager: pureService(FormManager),
  FormFactoryManager: pureService(FormFactoryManager),
  WebSocketService: pureService(SocketCentrifugeService),
  WebSocketChannelsServiceFactory: factoryService(WebSocketChannelsService),
  PhoneService: pureService(PhoneService),
  PasswordFieldBuilderService: pureService(PasswordFieldBuilderService),
  SenderPhoneFieldBuilderService: pureService(SenderPhoneFieldBuilderService),
  CountrySenderPhoneListFieldBuilderService: pureService(CountrySenderPhoneListFieldBuilderService),
  SelectInboxFieldBuilderService: pureService(SelectInboxFieldBuilderService),
  SelectFacebookPageFieldBuilderService: pureService(SelectFacebookPageFieldBuilderService),
  SelectInstagramAccountFieldBuilderService: pureService(SelectInstagramAccountFieldBuilderService),
  TypedFormBuilderFactoryService: pureService(TypedFormBuilderService, 'factory'),
  GroupPropertyBuilderFactory: pureService(GroupPropertyBuilder, 'factory'),
  PropertyBuilderFactory: pureService(PropertyBuilder, 'factory'),
  TypedGroupFormBuilderFactoryService: pureService(TypedGroupFormBuilderService, 'factory'),
  TypedArrayFormBuilderService: pureService(TypedArrayFormBuilderService, 'factory'),
  FileUploadFieldBuilderService: pureService(FileUploadFieldBuilderService),
  EditableBadgesFieldBuilderService: pureService(EditableBadgesFieldBuilderService),
  WeekDaysTimeRangeFieldBuilder: pureService(WeekDaysTimeRangeFieldBuilder),
  BulkFieldBuilderService: pureService(BulkFieldBuilderService),

  // FormServices
  MessagesAttachmentFileFormService: formService(MessagesAttachmentFileFormService),

  ExportFormService: formService(ExportFormService),

  NoteCreateFormService: formService(NoteBaseFormService),
  NoteEditFormService: formService(NoteBaseFormService),
  // FormServices
  VatInformationFormService: formService(VatInformationFormService),
  ForwardAudioFormService: formService(ForwardAudioFormService),
  EntityExportService: pureService(EntityExportService),
  ServiceDistributionRecipientsFormService: formService(ServiceDistributionRecipientsFormService),
  ServiceDistributionAdditionalSettingsFormService: formService(ServiceDistributionAdditionalSettingsFormService),
  EndFormGroupConfiguration: pureService(EndFormGroupConfiguration),
  MonthlyFormGroupConfiguration: pureService(MonthlyFormGroupConfiguration),
  SendingDateTimeFormGroupConfiguration: pureService(SendingDateTimeFormGroupConfiguration),
  WeeklyFormGroupConfiguration: pureService(WeeklyFormGroupConfiguration),
  YearlyFormGroupConfiguration: pureService(YearlyFormGroupConfiguration),
  CallForwardingFormBuilderService: pureService(CallForwardingFormBuilderService),
  BuyNumberCallForwardingFormService: formService(BuyNumberCallForwardingFormService),
  ImportMatchingCountryFormService: formService(ImportMatchingCountryFormService),
  UserAdminGridable: pureService(UserAdminGridable),
  SenderSettingsCountriesGridable: pureService(SenderSettingsCountriesGridable, 'factory'),

  // Domains
  DomainBaseService: pureService(DomainBaseService),
  DomainManagerService: pureService(DomainManagerService),
  DistributionDetailsService: domainService(DistributionDetailsService),
  MessagesPriceService: domainService(MessagesPriceService),
  MessagesPriceForCountryService: domainService(MessagesPriceForCountryService),
  MessagesAttachmentFileService: domainService(MessagesAttachmentFileService),
  NoteService: domainService(NoteService),
  OrderService: pureService(OrderService),
  VisibilityService: pureService(VisibilityService),
  PermissionsService: pureService(PermissionsService),
  RouterPermissionsService: pureService(RouterPermissionsService),
  CountryService: domainService(CountryService),
  UnauthenticatedCountryService: domainService(UnauthenticatedCountryService),
  TenDlcPhoneNumbersService: domainService(TenDlcPhoneNumbersService),
  TenDlcCampaignsService: domainService(TenDlcCampaignsService),
  TenDlcBrandService: domainService(TenDlcBrandService),
  TenDlcTwilioBrandService: domainService(TenDlcTwilioBrandService),
  UserNumbersService: domainService(UserNumbersService),
  UserAnalyticsFieldsService: domainService(UserAnalyticsFieldsService),
  UserAnalyticsTraitsService: domainService(UserAnalyticsTraitsService),
  UserMetadataService: domainService(UserMetadataService),
  UserPresenceService: domainService(UserPresenceService),
  SipCallsService: domainService(SipCallsService),

  SessionService: domainService(SessionService),
  SessionShortService: domainService(SessionShortService),
  SessionStatService: domainService(SessionStatService),

  ForwardAudioService: domainService(ForwardAudioService),

  SenderIdPriceService: domainService(SenderIdPriceService),

  TimezoneService: domainService(TimezoneService),
  AreaCodesService: domainService(AreaCodesService),
  AvailableToBuyNumbersService: domainService(AvailableToBuyNumbersService),
  NumbersPriceService: domainService(NumbersPriceService),
  PhoneNumbersService: domainService(PhoneNumbersService),
  VoiceCallPriceService: domainService(VoiceCallPriceService),
  TenDlcPricingService: domainService(TenDlcPricingService),
  TollFreePhoneNumbersService: domainService(TollFreePhoneNumbersService),
  RegulationsStatusService: domainService(RegulationsStatusService),
  RecipientService: pureService(RecipientService),
  ByocService: domainService(ByocService),
  AttachmentFileService: domainService(AttachmentFileService),
  WizardService: domainService(WizardService),
  SendReceiveSettingsService: domainService(SendReceiveSettingsService),
  GettingStartedInfoService: domainService(GettingStartedInfoService),
  UiSettingsService: domainService(UiSettingsService),
  TeamWorkflowService: domainService(TeamWorkflowService),
  TeamSubscriptionPriceService: domainService(TeamSubscriptionPriceService),
  TeamSubscriptionPriceRepository: repositoryService(TeamSubscriptionPriceRepository),
  CentrifugeService: domainService(CentrifugeService),
  CentrifugeRepository: repositoryService(CentrifugeRepository),
  TicketsCountRepository: repositoryService(TicketsCountRepository),
  TicketCountService: domainService(TicketCountService),
  UserRefusalSurveyService: pureService(UserRefusalSurveyService),
  // domains

  // gridable
  TenDlcPhoneNumbersGridable: pureService(TenDlcPhoneNumbersGridable),
  NoteGridable: pureService(NoteGridable),

  // gridable

  // cleanUper
  CleanUpManager: pureService(CleanUpManager),
  TableCleanUper: cleanuperTableService(TableCleanUper),
  AutocompleteCleanUper: cleanuperAutocompleteService(AutocompleteCleanUper),
  // cleanUper

  // search
  ModelSearcherManager: pureService(ModelSearcherManager),
  ModelSearcherServiceFactory: factoryService(ModelSearcherServiceFactory),
  // search

  // table related
  ForwardAudioColumnService: columnService(ForwardAudioColumnService),
  BaseFilters: pureService(BaseFilters),
  FilterCompareService: pureService(FilterCompareService),
  FilterCleanupService: pureService(FilterCleanupService),
  FilterStrategyManager: pureService(FilterStrategyManager),
  RangeFilterService: pureService(RangeFilterService),
  BaseFilterStrategy: pureService(BaseFilterStrategy),
  RangeFilterStrategy: pureService(RangeFilterStrategy),
  ArrayFilterStrategy: pureService(ArrayFilterStrategy),
  ColumnsFromStoreFactory: columnService(ColumnsFromStoreFactory),
  BaseGrouperServiceFactory: grouperService(BaseGrouperServiceFactory),
  SorterSerializerService: pureService(SorterSerializerService),
  BaseSorterServiceFactory: sorterService(BaseSorterServiceFactory),
  BasePaginatorServiceFactory: paginatorService(BasePaginatorServiceFactory),
  CursorPaginationServiceFactory: paginatorService(CursorPaginationServiceFactory),
  TokenPaginationServiceFactory: paginatorService(TokenPaginationServiceFactory),
  NoopGroupingServiceFactory: grouperService(NoopGroupingServiceFactory),
  NoopSorterServiceFactory: sorterService(NoopSorterServiceFactory),
  NoColumnsServiceFactory: columnService(NoColumnsServiceFactory),
  ColumnsSizeService: pureService(ColumnsSizeService),
  TableBuilderService: pureService(TableBuilderService),
  TableGrouperManager: pureService(TableGrouperManager),
  TableManager: pureService(TableManager),
  TablePaginatorManager: pureService(TablePaginatorManager),
  TableSearcherManager: pureService(TableSearcherManager),
  BaseSearcherServiceFactory: searcherService(BaseSearcherServiceFactory),
  TableSorterManager: pureService(TableSorterManager),
  TableColumnManager: pureService(TableColumnManager),
  TableKeyBuilder: pureService(TableKeyBuilder),
  TableExpandService: pureService(TableExpandService),
  TenDlcPhoneNumbersDetailsColumnServiceFactory: columnService(TenDlcPhoneNumbersDetailsColumnServiceFactory),
  UserAdminsColumnServiceFactory: columnService(UserAdminsColumnServiceFactory),
  SubAccountsSwitchColumnService: columnService(SubAccountsSwitchColumnService),
  TableBaseRecoveryService: pureService(TableBaseRecoveryService),
  // table related

  ResolversRouterBuilderMiddleware: pureService(ResolversRouterBuilderMiddleware),
  ModulesRouterBuilderMiddleware: pureService(ModulesRouterBuilderMiddleware),
  RoutesGatheringMiddleware: pureService(RoutesGatheringMiddleware),
  DetailsPageRouterBuilderMiddleware: pureService(DetailsPageRouterBuilderMiddleware),
  TitlersRouterBuilderMiddleware: pureService(TitlersRouterBuilderMiddleware),
  TitlerManager: pureService(TitlerManager),
  DefaultTitlerService: titlerService(DefaultTitlerService),
  DomainTitlerService: titlerService(DomainTitlerService),
  RouteTitlerService: titlerService(RouteTitlerService),
  StaticPaginationService: pureService(StaticPaginationService),
  StaticSortService: pureService(StaticSortService),
  TemplatesTableExpandLocalStorageService: pureService(TemplatesTableExpandLocalStorageService),

  // repositories
  AuthStateRepository: repositoryService(AuthStateRepository),
  OAuthSignUpRepository: repositoryService(OAuthSignUpRepository),
  BaseFormRepository: repositoryService(BaseFormRepository),
  MessagesPriceRepository: repositoryService(MessagesPriceRepository),
  MessagesPriceForCountryRepository: repositoryService(MessagesPriceForCountryRepository),
  MessagesAttachmentFileRepository: repositoryService(MessagesAttachmentFileRepository),
  TableRepository: repositoryService(TableRepository),
  TableRecoveryDataRepository: repositoryService(TableRecoveryDataRepository),
  SelectedRowRepository: repositoryService(SelectedRowRepository),
  FacetRepository: repositoryService(FacetRepository),
  AllSelectedTablesRepository: repositoryService(AllSelectedTablesRepository),
  PageMapPreloadRepository: repositoryService(PageMapPreloadRepository),
  PaginationRepository: repositoryService(PaginationRepository),
  CursorPaginationPageRepository: repositoryService(CursorPaginationPageRepository),
  CounterRepository: repositoryService(CounterRepository),
  DeliveredSmsCounterRepository: repositoryService(DeliveredSmsCounterRepository),
  FailedSmsCounterRepository: repositoryService(FailedSmsCounterRepository),
  RejectedSmsCounterRepository: repositoryService(RejectedSmsCounterRepository),
  SearchRepository: repositoryService(SearchRepository),
  NoteRepository: repositoryService(NoteRepository),
  UserRepository: repositoryService(UserRepository),
  BaseFieldRepository: repositoryService(BaseFieldRepository),
  BaseFilterRepository: repositoryService(BaseFilterRepository),
  SorterRepository: repositoryService(SorterRepository),
  GroupingRepository: repositoryService(GroupingRepository),
  BaseWrapperRepository: repositoryService(BaseWrapperRepository),
  ModalRepository: repositoryService(ModalRepository),
  FilterSchemaRepository: repositoryService(FilterSchemaRepository),
  FilterStateRepository: repositoryService(FilterStateRepository),
  CountryRepository: repositoryService(CountryRepository),
  UnauthenticatedCountryRepository: repositoryService(UnauthenticatedCountryRepository),
  TimezoneRepository: repositoryService(TimezoneRepository),
  ColumnRepository: repositoryService(ColumnRepository),
  PreloadRepository: repositoryService(PreloadRepository),
  RangeFilterRepository: repositoryService(RangeFilterRepository),
  ArrayFilterRepository: repositoryService(ArrayFilterRepository),
  OrderRepository: repositoryService(OrderRepository),
  VisibilityRepository: repositoryService(VisibilityRepository),
  AutocompleteRepository: repositoryService(AutocompleteRepository),
  SessionRepository: repositoryService(SessionRepository),
  SessionShortRepository: repositoryService(SessionShortRepository),
  SessionStatRepository: repositoryService(SessionStatRepository),
  TenDlcPhoneNumbersRepository: repositoryService(TenDlcPhoneNumbersRepository),
  TenDlcCampaignsRepository: repositoryService(TenDlcCampaignsRepository),
  TenDlcBrandRepository: repositoryService(TenDlcBrandRepository),
  TenDlcTwilioBrandRepository: repositoryService(TenDlcTwilioBrandRepository),

  PhoneNumbersRepository: repositoryService(PhoneNumbersRepository),
  NumberAvailableCountriesService: pureService(NumberAvailableCountriesService),
  SipCallsRepository: repositoryService(SipCallsRepository),

  ForwardAudioRepository: repositoryService(ForwardAudioRepository),
  UserNumbersRepository: repositoryService(UserNumbersRepository),
  UserAnalyticsFieldsRepository: repositoryService(UserAnalyticsFieldsRepository),
  UserAnalyticsTraitsRepository: repositoryService(UserAnalyticsTraitsRepository),
  UserMetadataRepository: repositoryService(UserMetadataRepository),
  UserSettingsRepository: repositoryService(UserSettingsRepository),
  UserPresenceRepository: repositoryService(UserPresenceRepository),

  SenderIdPriceRepository: repositoryService(SenderIdPriceRepository),
  AvailableToBuyNumbersRepository: repositoryService(AvailableToBuyNumbersRepository),
  AreaCodesRepository: repositoryService(AreaCodesRepository),
  NumbersPriceRepository: repositoryService(NumbersPriceRepository),
  NumberAvailableCountriesRepository: repositoryService(NumberAvailableCountriesRepository),
  GlobalStateRepository: repositoryService(GlobalStateRepository),
  VoiceCallPriceRepository: repositoryService(VoiceCallPriceRepository),
  SenderSettingsCountriesRepository: repositoryService(SenderSettingsCountriesRepository),
  VoiceCallConfigRepository: repositoryService(VoiceCallConfigRepository),
  DistributionDetailsRepository: repositoryService(DistributionDetailsRepository),

  TenDlcPricingRepository: repositoryService(TenDlcPricingRepository),
  TollFreePhoneNumbersRepository: repositoryService(TollFreePhoneNumbersRepository),
  RegulationsStatusRepository: repositoryService(RegulationsStatusRepository),
  ByocRepository: repositoryService(ByocRepository),
  AttachmentFileRepository: repositoryService(AttachmentFileRepository),
  WizardRepository: repositoryService(WizardRepository),
  SendReceiveSettingsRepository: repositoryService(SendReceiveSettingsRepository),
  GettingStartedInfoRepository: repositoryService(GettingStartedInfoRepository),
  TeamWorkflowRepository: repositoryService(TeamWorkflowRepository),
  UiSettingsRepository: repositoryService(UiSettingsRepository),
  PrimaryBrowserTabRepository: repositoryService(PrimaryBrowserTabRepository),
  PrimaryBrowserTabLockRepository: repositoryService(PrimaryBrowserTabLockRepository),
  // repositories

  // filters
  FilterServiceManager: pureService(FilterServiceManager),
  TableFilterServiceManager: pureService(TableFilterServiceManager),
  // filters

  // resolvers
  FilterSchemaResolver: resolverService(FilterSchemaResolver),
  RootResolver: resolverService(RootContainerResolver),
  WizardPrivateUseCaseResolver: resolverService(WizardPrivateUseCaseResolver),
  UserResolver: resolverService(UserResolver),
  SubAccountsResolver: resolverService(SubAccountsResolver),
  WizardResolverService: resolverService(WizardResolverService),
  PlanExpiredResolverService: resolverService(PlanExpiredResolverService),
  ModelResolver: resolverService(ModelResolver),
  FacetResolver: resolverService(FacetResolver),
  ScriptResolverService: resolverService(ScriptResolverService),
  SingleModelResolverService: resolverService(SingleModelResolverService),
  SingleSettingsModelResolverService: resolverService(SingleSettingsModelResolverService),
  NotFoundResolverService: resolverService(NotFoundResolverService),
  SendReceiveSettingsResolver: resolverService(SendReceiveSettingsResolver),
  BaseResolverService: pureService(BaseResolverService),
  RedirectableSingleModelResolverService: resolverService(RedirectableSingleModelResolverService),
  NonBlockedRedirectableSingleModelResolverService: resolverService(NonBlockedRedirectableSingleModelResolverService),
  AuthByProviderResolver: resolverService(AuthByProviderResolver),
  VoiceCallConfigResolverService: resolverService(VoiceCallConfigResolverService),
  ModelSettingsResolverService: resolverService(ModelSettingsResolverService),
  TenDlcPricesResolver: resolverService(TenDlcPricesResolverService),
  TenDlcPricesPreloader: pureService(TenDlcPricesPreloaderService),
  TenDlcNumbersCountResolver: resolverService(TenDlcNumbersCountResolver),
  ByocAccessResolver: resolverService(ByocAccessResolver),
  ByocSubscribeResolver: resolverService(ByocSubscribeResolver),
  SystemExitResolver: resolverService(SystemExitResolver),
  StopImpersonateResolver: resolverService(StopImpersonateResolver),
  UserCustomFieldsEventResolver: resolverService(UserCustomFieldsEventResolverService),
  SystemRefreshResolver: resolverService(SystemRefreshResolver),
  WizardAnalyticsResolverService: resolverService(WizardAnalyticsResolverService),
  SettingStartedInfoResolverService: resolverService(SettingStartedInfoResolverService),
  UiSettingsResolverService: resolverService(UiSettingsResolverService),
  MonthlyPlansResolverService: resolverService(MonthlyPlansResolverService),
  SubscriptionEventsResolverService: resolverService(SubscriptionEventsResolverService),
  AppModuleResolverService: resolverService(AppModuleResolverService),
  SubscriptionBillingSettingsResolverService: resolverService(SubscriptionBillingSettingsResolverService),
  UserWorkflowAccountChangedResolverService: resolverService(UserWorkflowAccountChangedResolverService),
  UserPolicyPrivacyChangedResolverService: resolverService(UserPolicyPrivacyChangedResolverService),
  ContactServerEventsResolverService: resolverService(ContactServerEventsResolverService),
  WidgetEmbedCodeResolver: resolverService(WidgetEmbedCodeResolver),
  RedirectByPrevRouteResolverService: resolverService(RedirectByPrevRouteResolverService),
  FetchAllAccountsResolver: resolverService(FetchAllAccountsResolver),
  FieldsDataCacheClearByModelEventResolverService: resolverService(FieldsDataCacheClearByModelEventResolverService),
  FieldAssigneeCacheClearResolverService: resolverService(FieldAssigneeCacheClearResolverService),
  TicketCountResolverService: resolverService(TicketCountResolverService),
  AdminSectionRedirectResolver: resolverService(AdminSectionRedirectResolver),
  MainSidebarRoutesResolverService: resolverService(MainSidebarRoutesResolverService),
  UserPresenceResolver: resolverService(UserPresenceResolver),
  ChatFacebookResolver: resolverService(ChatFacebookResolver),
  ChatInstagramResolver: resolverService(ChatInstagramResolver),
  // resolvers

  // interceptors. the first two interceptors should be always in this order
  HttpInterceptorManager: pureService(HttpInterceptorManager),
  ApiErrorInterceptor: interceptorService(ApiErrorInterceptor),
  AuthInterceptor: interceptorService(AuthInterceptor),
  OldVersionInterceptor: interceptorService(OldVersionInterceptor),
  ImpersonationModeInterceptor: interceptorService(ImpersonationModeInterceptor),
  PlanExpiredInterceptor: interceptorService(PlanExpiredInterceptor),
  TokenIsLockedInterceptor: interceptorService(TokenIsLockedInterceptor),
  StripeInvalidBillingDetailsInterceptor: interceptorService(StripeInvalidBillingDetailsInterceptor),
  // interceptors

  // workers
  PdfWorkerService: pureService(PdfWorkerService),
  // workers

  // tracking
  TrackingService: pureService(TrackingService),
  ZendeskService: pureService(ZendeskService),
  SegmentAnalyticsService: pureService(SegmentAnalyticsService),
  ShareASaleService: pureService(ShareASaleService),
  FirstPromoterService: pureService(FirstPromoterService),
  HotJarService: pureService(HotJarService),
  PostHogService: pureService(PostHogService),
  PosthogRecordingService: pureService(PosthogRecordingService),
  PosthogRecordingServiceResolver: resolverService(PosthogRecordingServiceResolver),
  GoogleAnalyticsService: pureService(GoogleAnalyticsService),
  ZukoAnalyticsService: pureService(ZukoAnalyticsService),
  ConvertService: pureService(ConvertService),
  SyncQueueService: pureService(SyncQueueService, 'factory'),
  // tracking
  // accessControlStrategies
  GettingStartedStrategy: pureService(GettingStartedStrategy),
  TenDlcStrategy: pureService(TenDlcStrategy),
  TollFreeStrategy: pureService(TollFreeStrategy),
  SenderIdsStrategy: pureService(SenderIdsStrategy),
  SubAccountsImportFlowStrategy: pureService(SubAccountsImportFlowStrategy),
  UsersWorkflowNotUserRoleStrategy: pureService(UsersWorkflowNotUserRoleStrategy),
  UsersWorkflowSuperAdminRoleStrategy: pureService(UsersWorkflowSuperAdminRoleStrategy),
  TasksReadOnlyStrategy: pureService(TasksReadOnlyStrategy),
  SuperAdminRoleStrategy: pureService(SuperAdminRoleStrategy),
  UserWorkflowStrategy: pureService(UserWorkflowStrategy),
  SubAccountsWorkflowStrategy: pureService(SubAccountsWorkflowStrategy),
  SubAccountDetailsStrategy: pureService(SubAccountDetailsStrategy),
  WebWidgetsStrategy: pureService(WebWidgetsStrategy),
  TicketsStrategy: pureService(TicketsStrategy),
  DealsStrategy: pureService(DealsStrategy),
  DealsReadOnlyStrategy: pureService(DealsReadOnlyStrategy),
  CampaignsStrategy: pureService(CampaignsStrategy),
  NotCampaignsCalendarStrategy: pureService(NotCampaignsCalendarStrategy),
  NotCampaignsScheduledStrategy: pureService(NotCampaignsScheduledStrategy),
  NotCampaignsScheduledUpcomingStrategy: pureService(NotCampaignsScheduledUpcomingStrategy),
  NotCampaignsScheduledPausedStrategy: pureService(NotCampaignsScheduledPausedStrategy),
  NotCampaignsScheduledNotSentStrategy: pureService(NotCampaignsScheduledNotSentStrategy),
  NotCampaignsScheduledCompletedStrategy: pureService(NotCampaignsScheduledCompletedStrategy),
  NotCampaignsScheduledDetailsStrategy: pureService(NotCampaignsScheduledDetailsStrategy),
  NotCampaignsScheduledEditStrategy: pureService(NotCampaignsScheduledEditStrategy),
  NotCampaignsComposeMessageStrategy: pureService(NotCampaignsComposeMessageStrategy),
  NotCampaignsComposeSuccessMessageStrategy: pureService(NotCampaignsComposeSuccessMessageStrategy),
  NotCampaignsComposeSuccessMessagesStrategy: pureService(NotCampaignsComposeSuccessMessagesStrategy),
  NotCampaignsComposeSuccessScheduledStrategy: pureService(NotCampaignsComposeSuccessScheduledStrategy),
  NotCampaignsComposeCampaignStrategy: pureService(NotCampaignsComposeCampaignStrategy),
  NotCampaignsComposeEmailStrategy: pureService(NotCampaignsComposeEmailStrategy),
  NotCampaignsSmsSurveysStrategy: pureService(NotCampaignsSmsSurveysStrategy),
  NotCampaignsSmsSurveysDetailsStrategy: pureService(NotCampaignsSmsSurveysDetailsStrategy),
  NotCampaignsSmsSurveysViewFlowStrategy: pureService(NotCampaignsSmsSurveysViewFlowStrategy),
  NotCampaignsSmsSurveysEditStrategy: pureService(NotCampaignsSmsSurveysEditStrategy),
  CampaignsEmailStrategy: pureService(CampaignsEmailStrategy),
  SegmentsStrategy: pureService(SegmentsStrategy),
  MonthlyPlansStrategy: pureService(MonthlyPlansStrategy),
  ByocStrategy: pureService(ByocStrategy),
  PaymentCongratsStrategy: pureService(PaymentCongratsStrategy),
  NotCampaignsTemplatesAllStrategy: pureService(NotCampaignsTemplatesAllStrategy),
  NotCampaignsTemplatesCategoryStrategy: pureService(NotCampaignsTemplatesCategoryStrategy),
  PaymentStrategy: pureService(PaymentStrategy),
  InstagramStrategy: pureService(InstagramStrategy),
  PointAiManagementStrategy: pureService(PointAiManagementStrategy),
  UnsubscribedEmailStrategy: pureService(UnsubscribedEmailStrategy),
  HistoryOutboundEmailsStrategy: pureService(HistoryOutboundEmailsStrategy),
  // accessControlStrategies

  UiStateService: domainService(UiStateService),
  UiStateRepository: repositoryService(UiStateRepository),

  // here because we need ReopenAccountRepository in interceptor, modules load after loading interceptors
  ReopenAccountRepository: repositoryService(ReopenAccountRepository),
  ReopenAccountService: pureService(ReopenAccountService),
  ReopenAccountResolver: resolverService(ReopenAccountResolver),
  ReopenAccountFormService: formService(ReopenAccountFormService),

  // errors processing
  ErrorSerializerService: pureService(ErrorSerializerService),
  ErrorsCustomRulesByStatusesMap: staticValue(errorsCustomRulesMap),

  OptimisticUpdateService: pureService(OptimisticUpdateService),

  PriorityHelperService: pureService(PriorityHelperService),
  FieldDataCacheService: pureService(FieldDataCacheService),
} as ServicesType<RegisteredServices>

registerModuleDeclaration(AppModule.DateTime, () => import('@/config/dateTime.module'), DATE_TIME_SERVICE_TYPES)
registerModuleDeclaration(
  AppModule.SmartBanner,
  () => import('@/config/smartBanner.module'),
  SMART_BANNER_SERVICE_TYPES,
  { startup: true },
)
registerModuleDeclaration(AppModule.Chat, () => import('@/config/chat.module'), CHAT_SERVICE_TYPES)
registerModuleDeclaration(
  AppModule.ChatStatistics,
  () => import('@/config/chatStatistics.module'),
  CHAT_STATISTICS_SERVICE_TYPES,
)
registerModuleDeclaration(
  AppModule.ChatPreviewNext,
  () => import('@/config/chatPreviewNext.module'),
  CHAT_PREVIEW_NEXT_SERVICE_TYPES,
)
registerModuleDeclaration(AppModule.Reporting, () => import('@/config/reporting.module'), REPORTING_SERVICE_TYPES)
registerModuleDeclaration(AppModule.EmailToSms, () => import('@/config/emailToSms.module'), EMAIL_TO_SMS_SERVICE_TYPES)
registerModuleDeclaration(
  AppModule.CarrierLookup,
  () => import('@/config/lookup/carrierLookup.module'),
  CARRIER_LOOKUP_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.CarrierLookupImport,
  () => import('@/config/lookup/carrierLookupImport.module'),
  CARRIER_LOOKUP_IMPORT_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.CarrierLookupSession,
  () => import('@/config/lookup/carrierLookupSession.module'),
  CARRIER_LOOKUP_SESSION_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.SmsSurveys, () => import('@/config/smsSurveys.module'), SMS_SURVEYS_SERVICE_TYPES)
registerModuleDeclaration(
  AppModule.SenderSettings,
  () => import('@/config/senderSettings/senderSettings.module'),
  SENDER_SETTINGS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.SenderSettingsCountries,
  () => import('@/config/senderSettings/senderSettingsCountries.module'),
  SENDER_SETTINGS_COUNTRIES_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.EmailLookup,
  () => import('@/config/lookup/emailLookup.module'),
  EMAIL_LOOKUP_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.EmailLookupImport,
  () => import('@/config/lookup/emailLookupImport.module'),
  EMAIL_LOOKUP_IMPORT_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.EmailLookupSession,
  () => import('@/config/lookup/emailLookupSession.module'),
  EMAIL_LOOKUP_SESSION_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.AutomationRules,
  () => import('@/config/automationRules.module'),
  AUTOMATION_RULES_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.AutomationRule,
  () => import('@/config/automationRule.module'),
  AUTOMATION_RULE_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.AutomationRulesDetails,
  () => import('@/config/automationRulesDetails.module'),
  AUTOMATION_RULES_DETAILS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.AutomationRulesLog,
  () => import('@/config/automationRulesLog.module'),
  AUTOMATION_RULES_LOG_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.Keywords,
  () => import('@/config/automationRules/keywords.module'),
  KEYWORDS_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.Invoices, () => import('@/config/invoices.module'), INVOICES_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.InvoicesBase,
  () => import('@/config/invoicesBase.module'),
  INVOICES_BASE_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.Auth, () => import('@/config/unauthorized/auth.module'), AUTH_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.CheckEmailForSignup,
  () => import('@/config/unauthorized/checkEmailForSignup.module'),
  CHECK_EMAIL_FOR_SIGNUP_SERVICE_TYPES,
)
registerModuleDeclaration(
  AppModule.EmailConfirmation,
  () => import('@/config/unauthorized/emailConfirmation.module'),
  EMAIL_CONFIRMATION_SERVICE_TYPES,
)
registerModuleDeclaration(
  AppModule.LoginSSO,
  () => import('@/config/unauthorized/loginSSO.module'),
  LOGIN_SSO_SERVICE_TYPES,
)
registerModuleDeclaration(AppModule.Logout, () => import('@/config/unauthorized/logout.module'), LOGOUT_SERVICE_TYPES)
registerModuleDeclaration(
  AppModule.SignupByInvitePage,
  () => import('@/config/unauthorized/signupByInvite.module'),
  SIGNUP_BY_INVITE_SERVICE_TYPES,
)
registerModuleDeclaration(
  AppModule.SignupByOAuthPage,
  () => import('@/config/unauthorized/signupByOAuth.module'),
  SIGNUP_BY_OAUTH_SERVICE_TYPES,
)
registerModuleDeclaration(AppModule.TFAPages, () => import('@/config/unauthorized/tfa.module'), TFA_SERVICE_TYPES)
registerModuleDeclaration(
  AppModule.Notifications,
  () => import('@/config/notifications.module'),
  NOTIFICATIONS_SERVICE_TYPES,
)
registerModuleDeclaration(AppModule.ServicesApi, () => import('@/config/servicesApi.module'), API_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.AccountSettings,
  () => import('@/config/accountSettings.module'),
  ACCOUNT_SETTINGS_SERVICE_TYPES,
)
registerModuleDeclaration(
  AppModule.VerifyEmail,
  () => import('@/config/unauthorized/verifyEmail.module'),
  VERIFY_EMAIL_SERVICE_TYPES,
)
registerModuleDeclaration(
  AppModule.SignupConfirmation,
  () => import('@/config/unauthorized/signupConfirmation.module'),
  SIGNUP_CONFIRMATION_SERVICE_TYPES,
)
registerModuleDeclaration(
  AppModule.ForgotPassword,
  () => import('@/config/unauthorized/forgotPassword.module'),
  FORGOT_PASSWORD_SERVICE_TYPES,
)
registerModuleDeclaration(
  AppModule.ResetPassword,
  () => import('@/config/unauthorized/resetPassword.module'),
  RESET_PASSWORD_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.SubAccounts,
  () => import('@/config/subAccounts/subAccounts.module'),
  SUB_ACCOUNTS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.SubAccountsBase,
  () => import('@/config/subAccounts/subAccountsBase.module'),
  SUB_ACCOUNTS_BASE_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.SubAccountsDetails,
  () => import('@/config/subAccounts/subAccountsDetails.module'),
  SUB_ACCOUNTS_DETAILS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.SubAccountsForm,
  () => import('@/config/subAccounts/subAccountsForm.module'),
  SUB_ACCOUNTS_FORM_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.SubAccountsImportFlow,
  () => import('@/config/subAccounts/import/subAccountsImportFlow.module'),
  SUB_ACCOUNTS_IMPORT_FLOW_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.SubAccountsImportList,
  () => import('@/config/subAccounts/import/subAccountsImportList.module'),
  SUB_ACCOUNTS_IMPORT_LIST_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.SubAccountsImportDetails,
  () => import('@/config/subAccounts/import/subAccountsImportDetails.module'),
  SUB_ACCOUNTS_IMPORT_DETAILS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.AutoDetectedCountry,
  () => import('@/config/autoDetectedCountry.module'),
  AUTO_DETECTED_COUNTRY_TYPES,
)

registerModuleDeclaration(AppModule.Billing, () => import('@/config/billing.module'), BILLING_SERVICE_TYPES)
registerModuleDeclaration(
  AppModule.BillingBase,
  () => import('@/config/billingBase.module'),
  BILLING_BASE_SERVICE_TYPES,
)
registerModuleDeclaration(AppModule.MyData, () => import('@/config/myData.module'), MY_DATA_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.SentSmsBase,
  () => import('@/config/history/sentSmsBase.module'),
  SENT_SMS_BASE_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.HistorySentSms,
  () => import('@/config/history/historySentSms.module'),
  HISTORY_SENT_SMS_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.Templates, () => import('@/config/templates.module'), TEMPLATES_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.HistoryReceivedSms,
  () => import('@/config/history/historyReceivedSms.module'),
  HISTORY_RECEIVED_SMS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.HistoryForwardedCalls,
  () => import('@/config/history/historyForwardedCalls.module'),
  HISTORY_FORWARDED_CALLS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.HistoryOutboundCalls,
  () => import('@/config/history/historyOutboundCalls.module'),
  HISTORY_OUTBOUND_CALLS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.HistoryInboundCalls,
  () => import('@/config/history/historyInboundCalls.module'),
  HISTORY_INBOUND_CALLS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.Scheduled,
  () => import('@/config/scheduled/scheduled.module'),
  SCHEDULED_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.ScheduledUpcoming,
  () => import('@/config/scheduled/scheduledUpcoming.module'),
  SCHEDULED_UPCOMING_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.ScheduledPaused,
  () => import('@/config/scheduled/scheduledPauses.module'),
  SCHEDULED_PAUSED_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.ScheduledNotSent,
  () => import('@/config/scheduled/scheduledNotSent.module'),
  SCHEDULED_NOT_SENT_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.ScheduledCompleted,
  () => import('@/config/scheduled/scheduledCompleted.module'),
  SCHEDULED_COMPLETED_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.ScheduledCalendar,
  () => import('@/config/scheduled/scheduledCalendar.module'),
  SCHEDULED_CALENDAR_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.ScheduledEdit,
  () => import('@/config/scheduled/scheduledEdit.module'),
  SCHEDULED_EDIT_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.ScheduledDetails,
  () => import('@/config/scheduled/scheduledDetails.module'),
  SCHEDULED_DETAILS_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.BuyNumber, () => import('@/config/buyNumber.module'), BUY_NUMBER_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.TenDlcTollFree,
  () => import('@/config/tenDlcTollFree.module'),
  TEN_DLC_TOLL_FREE_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.TenDlcTollFreeRequest,
  () => import('@/config/tenDlcTollFreeRequest.module'),
  TEN_DLC_TOLL_FREE_REQUEST_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.Wizard, () => import('@/config/wizard.module'), WIZARD_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.ContactsUnsubscribed,
  () => import('@/config/contacts/unsubscribed.module'),
  CONTACTS_UNSUBSCRIBED_SERVICES_TYPES,
)

registerModuleDeclaration(
  AppModule.ContactsBlocked,
  () => import('@/config/contacts/blocked.module'),
  CONTACTS_BLOCKED_SERVICES_TYPES,
)

registerModuleDeclaration(
  AppModule.ContactsImportFlow,
  () => import('@/config/contacts/import/contactsImportFlow.module'),
  CONTACTS_IMPORT_FLOW_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.ContactsImportDetails,
  () => import('@/config/contacts/import/contactsImportDetails.module'),
  CONTACTS_IMPORT_DETAILS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.ContactsImportList,
  () => import('@/config/contacts/import/contactsImportList.module'),
  CONTACTS_IMPORT_LIST_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.Byoc, () => import('@/config/byoc/byoc.module'), BYOC_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.ByocHelper,
  () => import('@/config/byoc/byocHelper.module'),
  BYOC_HELPER_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.WidgetSubscribe,
  () => import('@/config/widget/widgetSubscribe.module'),
  WIDGET_SUBSCRIBE_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.WidgetClickToText,
  () => import('@/config/widget/widgetClickToText.module'),
  WIDGET_CLICK_TO_TEXT_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.VoiceCall, () => import('@/config/voiceCall.module'), VOICE_CALL_SERVICES_TYPES)

registerModuleDeclaration(
  AppModule.ContactsBase,
  () => import('@/config/contactsBase.module'),
  CONTACTS_BASE_SERVICES_TYPES,
)

registerModuleDeclaration(AppModule.Contacts, () => import('@/config/contacts.module'), CONTACT_SERVICES_TYPES)

registerModuleDeclaration(AppModule.Segments, () => import('@/config/segments.module'), SEGMENT_SERVICES_TYPES)

registerModuleDeclaration(AppModule.Compose, () => import('@/config/compose.module'), COMPOSE_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.MessagesBulksProgress,
  () => import('@/config/messagesBulksProgress.module'),
  MESSAGES_BULKS_PROGRESS_SERVICES_TYPES,
)

registerModuleDeclaration(
  AppModule.ContactList,
  () => import('@/config/contactList.module'),
  CONTACT_LIST_SERVICES_TYPES,
)

registerModuleDeclaration(AppModule.RRule, () => import('@/config/rrule.module'), RRULE_SERVICE_TYPES)
registerModuleDeclaration(AppModule.MessageSend, () => import('@/config/messageSend.module'), MESSAGE_SEND_TYPES)

registerModuleDeclaration(
  AppModule.ContactCounter,
  () => import('@/config/contactCounter.module'),
  CONTACT_COUNTER_SERVICES_TYPES,
)

registerModuleDeclaration(
  AppModule.ContactListCounter,
  () => import('@/config/contactListCounter.module'),
  CONTACT_LIST_COUNTER_SERVICES_TYPES,
)

registerModuleDeclaration(AppModule.Activity, () => import('@/config/activity.module'), ACTIVITY_SERVICES_TYPES)

registerModuleDeclaration(AppModule.CustomFields, () => import('@/config/customField.module'), CUSTOM_FIELD_SERVICES)

registerModuleDeclaration(
  AppModule.Statements,
  () => import('@/config/accounts/statements.module'),
  STATEMENTS_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.ComingSoon, () => import('@/config/comingSoon.module'), COMING_SOON_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.TeamWorkflow,
  () => import('@/config/teamWorkflow.module'),
  TEAM_WORKFLOW_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.Payment, () => import('@/config/payment.module'), PAYMENT_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.TasksBase,
  () => import('@/config/tasks/tasksBase.module'),
  TASKS_BASE_SERVICES_TYPES,
)
registerModuleDeclaration(AppModule.Tasks, () => import('@/config/tasks/tasks.module'), TASKS_SERVICES_TYPES)

registerModuleDeclaration(
  AppModule.ForwardedCalls,
  () => import('@/config/history/forwardedCalls.module'),
  FORWARDED_CALLS_SERVICES_TYPES,
)

registerModuleDeclaration(
  AppModule.NavigationOrder,
  () => import('@/config/navigationOrder.module'),
  NAVIGATION_ORDER_SERVICES_TYPES,
)

registerModuleDeclaration(
  AppModule.RecentRecipient,
  () => import('@/config/recentRecipient.module'),
  RECENT_RECIPIENT_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.EmailUnsubscribe,
  () => import('@/config/emailUnsubscribe.module'),
  EMAIL_UNSUBSCRIBE_SERVICES_TYPES,
)

registerModuleDeclaration(AppModule.Sandbox, () => import('@/config/sandbox/sandbox.module'), SANDBOX_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.PinnedContacts,
  () => import('@/config/contacts/pinned.module'),
  PINNED_CONTACTS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.WebWidgets,
  () => import('@/config/messages/settings/webWidgets.module'),
  WEB_WIDGET_TYPES,
)
registerModuleDeclaration(
  AppModule.WhatsApp,
  () => import('@/config/messenger/whatsapp.module'),
  WHATSAPP_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.Facebook,
  () => import('@/config/messenger/facebook.module'),
  FACEBOOK_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.StripeBilling,
  () => import('@/config/stripeBilling.module'),
  STRIPE_BILLING_SERVICES_TYPES,
)

registerModuleDeclaration(
  AppModule.UserSession,
  () => import('@/config/user/userSession.module'),
  USER_SESSION_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.AuditLogs, () => import('@/config/auditLogs.module'), AUDIT_LOGS_SERVICES_TYPES)

registerModuleDeclaration(AppModule.Tickets, () => import('@/config/tickets/tickets.module'), TICKETS_SERVICES_TYPES)

registerModuleDeclaration(
  AppModule.TicketsNotifications,
  () => import('@/config/tickets/ticketsNotifications.module'),
  TICKETS_NOTIFICATIONS_SERVICES_TYPES,
)

registerModuleDeclaration(AppModule.Tags, () => import('@/config/tags.module'), TAGS_SERVICES_TYPES)

registerModuleDeclaration(
  AppModule.FilteredViews,
  () => import('@/config/filteredViews.module'),
  FILTERED_VIEWS_SERVICES_TYPES,
)

registerModuleDeclaration(AppModule.Deals, () => import('@/config/deals/deals.module'), DEALS_SERVICES_TYPES)

registerModuleDeclaration(AppModule.Typing, () => import('@/config/typing.module'), TYPING_SERVICE_TYPES)

registerModuleDeclaration(AppModule.Kanban, () => import('@/config/kanban.module'), KANBAN_SERVICE_TYPES)

registerModuleDeclaration(AppModule.AlsoHere, () => import('@/config/alsoHere.module'), ALSO_HERE_SERVICE_TYPES)

registerModuleDeclaration(
  AppModule.ChannelPresence,
  () => import('@/config/channelPresence.module'),
  CHANNEL_PRESENCE_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.SmsCampaigns,
  () => import('@/config/campaigns/smsCampaigns.module'),
  SMS_CAMPAIGNS_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.CampaignsSenders,
  () => import('@/config/campaigns/emailCampaigns.module'),
  EMAIL_CAMPAIGNS_SERVICE_TYPES,
)

registerModuleDeclaration(AppModule.PointAi, () => import('@/config/pointAi.module'), POINT_AI_SERVICES_TYPES)

registerModuleDeclaration(
  AppModule.Instagram,
  () => import('@/config/messenger/instagram.module'),
  INSTAGRAM_SERVICE_TYPES,
)

registerModuleDeclaration(
  AppModule.TmTiptapEditor,
  () => import('@/config/tmTiptapEditor.module'),
  TM_TIPTAP_EDITOR_SERVICES_TYPES,
)

registerModuleDeclaration(
  AppModule.OutboundEmails,
  () => import('@/config/history/outboundEmails.module'),
  OUTBOUND_EMAILS_SERVICES_TYPES,
)

registerModuleDeclaration(AppModule.Recipient, () => import('@/config/recipient.module'), RECIPIENT_SERVICE_TYPES)

export default servicesList

import { get } from '@/core/container/ioc'
import type { UseTableBuilderSettings } from '@/composition/tables'
import type TablePaginatorManagerInterface from '@/services/tables/managers/tablePaginatorManagerInterface'
import type { Ref } from '@/composition/vue/compositionApi'
import { computed, ref, watch } from '@/composition/vue/compositionApi'

type UsePaginators = Pick<UseTableBuilderSettings, 'tableModelId' | 'tablePaginatorManager' | 'paginatorFactory'>

export const usePaginators = (settings: Pick<UsePaginators, 'tablePaginatorManager'>) =>
  get<TablePaginatorManagerInterface>(settings.tablePaginatorManager ?? 'TablePaginatorManager')

export const createPaginators = (settings: UsePaginators) => {
  const manager = get<TablePaginatorManagerInterface>(settings.tablePaginatorManager ?? 'TablePaginatorManager')

  if (settings.paginatorFactory) {
    manager.setFactoryForTable(settings.tableModelId, settings.paginatorFactory)
  }

  return usePaginators(settings)
}

export const useFrontendPaging = <I>(entityName: string, list: Ref<I[]>) => {
  const page = ref(0)
  const perPage = ref(10)

  const pageCount = computed(() => Math.ceil(list.value.length / perPage.value))
  const lastPage = computed(() => pageCount.value - 1)

  const isFirstPage = computed(() => page.value === 0)
  const isLastPage = computed(() => page.value === lastPage.value)

  const firstIndex = computed(() => Math.min(page.value, lastPage.value) * perPage.value)
  const lastIndex = computed(() => Math.min(firstIndex.value + perPage.value, list.value.length))

  const slice = computed(() => list.value.slice(firstIndex.value, lastIndex.value))

  watch([page, lastPage], ([pageValue, lastPageValue]) => {
    if (pageValue > lastPageValue) {
      page.value = lastPageValue
    }
  })

  const prev = () => {
    if (isFirstPage.value) return
    page.value -= 1
  }
  const next = () => {
    if (isLastPage.value) return
    page.value += 1
  }
  const setPerPage = (value: number) => {
    perPage.value = value
  }

  return {
    page,
    perPage,
    pageCount,
    isFirstPage,
    isLastPage,
    firstIndex,
    lastIndex,
    slice,
    prev,
    next,
    setPerPage,
  }
}

import { AttrField, BooleanField, HasManyByField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { IUserTeamWorkflow } from '@/services/domain/user/types'
import TeamSubscription from '@/data/models/domain/TeamSubscription'

interface UserSettingsVerifyIdentityBase<TState, TReason> {
  resubmitReason: TReason
  state: TState
}

export type UserSettingsNotVerifyIdentity =
  | UserSettingsVerifyIdentityBase<'INITIATED' | 'REQUESTED' | 'SUBMITTED', null>
  | UserSettingsVerifyIdentityBase<'RESUBMIT', string>

export type UserSettingsVerifyIdentity =
  | UserSettingsVerifyIdentityBase<'VERIFIED', null>
  | UserSettingsNotVerifyIdentity

@OrmModel(modelNames.USER_SETTINGS)
export default class UserSettings extends BaseModel {
  @StringField()
  public id: string

  @BooleanField()
  public showSenderIds: boolean

  @BooleanField()
  public showTenDlcTab: boolean

  @BooleanField()
  public showTollFreeTab: boolean

  @AttrField()
  public showTenDlcAlert: boolean | null

  @AttrField()
  public showTollFreeAlert: boolean | null

  @BooleanField()
  public showBackendPanel: boolean

  @AttrField()
  public verifyIdentity: UserSettingsVerifyIdentity | null

  @BooleanField()
  public showCompleteNumberRegistrationNotice: boolean

  @BooleanField()
  public showTermsUpdate: boolean

  @AttrField()
  public teamWorkflow: IUserTeamWorkflow

  @StringField()
  public parentId: string

  @AttrField([])
  public subscriptionIds: string[]

  @HasManyByField(TeamSubscription, 'subscriptionIds')
  public subscriptions: TeamSubscription[]

  @BooleanField()
  public billingDetailsExist: boolean

  @BooleanField(false)
  public isImpersonated: boolean

  @AttrField()
  public impersonatedBy: string | null
}

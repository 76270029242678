import { OrmModel, StringField, BooleanField, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { PreloaderType } from '@/services/preloaders/types'
import { ServiceGroups } from '@/config/types'

@OrmModel(modelNames.PRELOAD)
export default class Preload<P = void> extends BaseModel {
  @StringField()
  public id: string

  @BooleanField(false)
  public isPreloaded: boolean

  @BooleanField(false)
  public isLoading: boolean

  @BooleanField(false)
  public isFailed: boolean

  @StringField('')
  public modelName: string

  @StringField(ServiceGroups.PRELOADERS)
  public preloaderType: PreloaderType

  @StringField()
  public updatedAt: string

  @AttrField()
  public payload: P
}

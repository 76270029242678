import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type OrderRepository from '@/data/repositories/OrderRepository'
import Order from '@/data/models/Order'

@injectable()
export default class OrderService {
  constructor(@inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService) {}

  public getOrder(key: string) {
    return this.getRepository().getOrder(key)
  }

  public async setOrder(key: string, newOrder: string[]) {
    await this.getRepository().setOrder(key, newOrder)
  }

  protected getRepository() {
    return this.entityManager.getRepository<OrderRepository>(Order)
  }
}

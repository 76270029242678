import { mmsAttachmentFileFormats } from '@/utils/file/const/mmsAttachmentFileFormats'
import { smsAttachmentFileFormats } from '@/utils/file/const/smsAttachmentFileFormats'
import { whatsAppAttachmentFileFormats } from '@/utils/file/const/whatsAppAttachmentFileFormats'
import { liveChatAttachmentFileFormats } from '@/utils/file/const/liveChatAttachmentFileFormats'

export type FileFormatMap = Record<string, string | string[]>

export const fileFormatMap = {
  ...mmsAttachmentFileFormats,
  ...smsAttachmentFileFormats,
  ...whatsAppAttachmentFileFormats,
  ...liveChatAttachmentFileFormats,
}

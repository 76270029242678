<template>
  <component
    :is="tag"
    :class="{
      'tm-sticky-cell': true,
      [`tm-sticky-cell--${position}`]: true,
      'tm-sticky-cell--with-shadow': hasShadow,
      'tm-sticky-cell--selected': selected,
      'tm-sticky-cell--transparent': tag === 'th',
    }"
  >
    <div
      :class="[
        'tm-sticky-cell__wrapper',
        {
          'tm-sticky-cell__wrapper--selected': selected,
        },
      ]"
    >
      <slot />
    </div>
  </component>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'

export default defineComponent({
  props: {
    tag: {
      type: String as PropType<'td' | 'th'>,
      default: 'td',
    },
    position: {
      type: String as PropType<'left' | 'right'>,
      default: 'right',
    },
    hasShadow: {
      type: Boolean,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.tm-sticky-cell {
  position: sticky !important;
  z-index: 1;
  background-color: $white;

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }

  &--with-shadow {
    overflow-x: visible !important;
    overflow-y: clip !important;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -10px;
      height: calc(100% + 1px);
      width: 10px;
      background: linear-gradient(90deg, transparent 30%, rgba(0, 0, 0, 0.0403));
    }
  }

  &--transparent {
    background: transparent !important;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &--selected {
      background: $background-blue !important;
    }
  }
}
</style>

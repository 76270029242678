import { inject, injectable } from 'inversify'
import { isEmpty, isEqualWith, pick, omit, isObject } from 'lodash-es'
import type { WrapperParams, WrapperTypesServicesKeys } from '@/services/wrappers/types'
import BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import { SERVICE_TYPES } from '@/core/container/types'
import TableWrapperService from '@/services/wrappers/table/tableWrapperService'
import { getKeyForQueryToDotNotation } from '@/utils/router'
import type BaseWrapperService from '@/services/wrappers/baseWrapperService'
import type LoggerService from '@/services/loggerService'
import type { KeyStorageServiceInterface } from '@/services/storage/types'
import type { WrapperTableParams, WrapperTableParamsToSave } from '@/services/wrappers/table/types'
import type { ValueOf } from '@/types'
import { COLUMNS_URL_QUERY_PARAM, PAGING_URL_QUERY_PARAM } from '@/core/tables/types'

@injectable()
export default class TableBaseStorageWrapperService extends TableWrapperService {
  constructor(
    @inject(SERVICE_TYPES.BaseWrapperService) protected readonly baseWrapperService: BaseWrapperService<BaseWrapper>,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    protected readonly storageService: KeyStorageServiceInterface,
  ) {
    super(baseWrapperService, loggerService)
  }

  // WrapperServiceInterface

  public build(wrapperId: string, wrapperType?: WrapperTypesServicesKeys, model?: typeof BaseWrapper): void {
    super.build(wrapperId, wrapperType, BaseWrapper)

    const params = this.storageService.getParams(wrapperId)
    super.setParams(wrapperId, params)
  }

  // WrapperTableServiceInterface

  public async setParamsByTableData(
    wrapperId: string,
    params: WrapperTableParams,
    paramsToSave: WrapperTableParamsToSave,
    shouldReplaceState = true,
  ) {
    const toStorage = this.extractDataToStorage(params, paramsToSave)
    await this.storageService.setParams(wrapperId, toStorage, shouldReplaceState)

    const toStore = this.extractDataToStore(params)
    const result = await super.setParamsByTableData(wrapperId, toStore, paramsToSave, shouldReplaceState)

    return {
      ...result,
      canBeRestored: isEqualWith(
        this.prepareToCompare(this.storageService.getParams(wrapperId)),
        this.prepareToCompare(this.getParams(wrapperId)),
      ),
    }
  }

  public getTableParamKey(key: string, wrapperId: string): string {
    return getKeyForQueryToDotNotation(key, wrapperId)
  }

  public getTableParams(wrapperId: string): WrapperParams {
    return this.getParams(wrapperId)
  }

  public async removeParamsByTableKeys(wrapperId: string, keysToRemove: string[], shouldReplaceState = true) {
    await this.storageService.removeParams(wrapperId, keysToRemove, shouldReplaceState)
    return super.removeParamsByTableKeys(wrapperId, keysToRemove, shouldReplaceState)
  }

  // protected

  protected extractDataToStore(params: WrapperTableParams): WrapperTableParams {
    return this.filterParams(params)
  }

  protected extractDataToStorage(
    params: WrapperTableParams,
    paramsToSave: WrapperTableParamsToSave,
  ): WrapperTableParams {
    const pickedParams = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [key, this.pickByParamsToSave(value, paramsToSave[key])]),
    )
    return this.filterParams(pickedParams)
  }

  private pickByParamsToSave(
    value?: ValueOf<WrapperTableParams>,
    params?: ValueOf<WrapperTableParamsToSave>,
  ): ValueOf<WrapperTableParams> {
    if (!params || isEmpty(value)) {
      return value
    }

    return Array.isArray(value)
      ? (value.map((item) => pick(item, params)).filter((item) => !isEmpty(item)) as ValueOf<WrapperTableParams>)
      : (pick(value, params) as ValueOf<WrapperTableParams>)
  }

  private filterParams(params: WrapperTableParams): WrapperTableParams {
    return Object.fromEntries(Object.entries(params).map(([key, value]) => [key, !isEmpty(value) ? value : null]))
  }

  /**
   * Preparing params for comparison between each other
   * @description method removes pagination and all empty values, arrays with empty values, and objects with nested empty values one level deeper
   * @example <caption>Example usage of method:</caption>
   * // params = { search: { search: "test" } }
   * this.prepareToCompare(params) // return { search: { search: "test" } }
   *
   * // params = { search: { search: null } }
   * this.prepareToCompare(params) // return {}
   *
   * // params = { search: { search: undefined } }
   * this.prepareToCompare(params) // return {}
   *
   * // params = { search: { search: '' } }
   * this.prepareToCompare(params) // return {}
   *
   * // params = { filters: [{ onlyWithoutInbound: { eq: true }, dateRange: { id: { eq: "This week"}}}] }
   * this.prepareToCompare(params) // return { filters: [{ onlyWithoutInbound: { eq: true }, dateRange: { id: { eq: "This week"}}}] }
   *
   * // params = { filters: [{ onlyWithoutInbound: { eq: false }, dateRange: { id: { eq: ""}}}] }
   * this.prepareToCompare(params) // return { filters: [{ onlyWithoutInbound: { eq: false }, dateRange: { id: { eq: ""}}}] }
   *
   * // params = { filters: [{}] }
   * this.prepareToCompare(params) // return {}
   *
   * // params = { pagging: [{ currentPage: 1, lastId: 0, perPage: 50}] }
   * this.prepareToCompare(params) // return {}
   *
   * // params = { sorts: { messageOut: { messageTime: "desc" } } }
   * this.prepareToCompare(params) // return { sorts: { messageOut: { messageTime: "desc" } } }
   *
   * // params = { sorts: { messageOut: { messageTime: "" } } }
   * this.prepareToCompare(params) // return {}
   */
  private prepareToCompare(params: Record<string, any>): WrapperTableParams {
    return Object.fromEntries(
      Object.entries(omit(params, [PAGING_URL_QUERY_PARAM, COLUMNS_URL_QUERY_PARAM]))
        .map(([key, value]) => {
          if (isEmpty(value)) {
            return [key, value]
          }
          if (Array.isArray(value)) {
            return [key, value.filter((v) => !isEmpty(v))]
          }
          if (isObject(value)) {
            return [key, this.prepareToCompare(value)]
          }
          return [key, value]
        })
        .filter(([_, value]) => !isEmpty(value)),
    )
  }
}

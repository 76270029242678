import type { RouteMeta, RouteRecordRaw } from 'vue-router'
import { defineAsyncComponent, ref } from '@/composition/vue/compositionApi'
import { getGlobalStateService } from '@/core/container/ioc'

const defaultSidebarCollapsed = false

const sidebarWidth = ref()
const sidebarCollapsed = ref()
const sidebarShowTransition = ref(true)

const _defaultSidebarWidth = 260

export const useSidebarState = (defaultSidebarWidth?: number) => {
  const globalStateService = getGlobalStateService()

  sidebarWidth.value =
    globalStateService.getFieldValueSync('sidebarWidth') ?? defaultSidebarWidth ?? _defaultSidebarWidth
  sidebarCollapsed.value = globalStateService.getFieldValueSync('sidebarCollapsed') ?? defaultSidebarCollapsed

  const hasSavedSidebarWidthValue = () => {
    return !!globalStateService.getFieldValueSync('sidebarWidth')
  }

  const setSidebarWidth = (value: number, saveToStore = true) => {
    sidebarWidth.value = value

    if (saveToStore) globalStateService.setFieldValueIfChanged('sidebarWidth', value)
  }

  const setSidebarCollapsed = (value: boolean) => {
    sidebarCollapsed.value = value

    globalStateService.setFieldValueIfChanged('sidebarCollapsed', value)
  }

  const setSidebarShowTransition = (value: boolean) => {
    sidebarShowTransition.value = value
  }

  return {
    sidebarWidth,
    hasSavedSidebarWidthValue,
    setSidebarWidth,
    sidebarCollapsed,
    setSidebarCollapsed,
    sidebarShowTransition,
    setSidebarShowTransition,
  }
}

export const useSidebarMenuComponent = (route: { meta?: RouteMeta }): RouteRecordRaw['component'] | null => {
  if (route.meta?.menu) {
    if (typeof route.meta.menu === 'function') {
      return defineAsyncComponent(route.meta?.menu)
    }

    return route.meta?.menu
  }

  return null
}

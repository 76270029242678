import { inject, injectable } from 'inversify'
import type {
  FieldDiscardStrategy,
  FormHook,
  FormHookType,
  FormState,
  TpFormData,
  SelectOption,
  FormEventCallback,
} from '@/services/forms/types'
import type BaseForm from '@/services/forms/baseForm'
import { SERVICE_TYPES } from '@/core/container/types'
import { TmFormError } from '@/core/error/tmFormError'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import Form from '@/data/models/forms/Form'
import type BaseFormRepository from '@/data/repositories/form/baseFormRepository'
import type BaseFieldRepository from '@/data/repositories/form/baseFieldRepository'
import BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import type { LowLevelUpdateBody } from '@/services/vuex/types'
import FieldArray from '@/data/models/forms/FieldArray'
import FieldGroup from '@/data/models/forms/FieldGroup'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type { TmApiValidationError } from '@/core/error/transport/tmApiValidationError'
import type { FormPartialableInterface } from '@/decorators/types'
import type { ParamsPartial } from '@/types'
import type { PartialUIConfig } from '@/services/wrappers/types'
import type { TypedFormBuilderService } from '@/services/forms/baseForm/typedFormBuilder/TypedFormBuilderService'
import type { TypedFormBuilderInterface } from '@/services/forms/baseForm/typedFormBuilder/types'
import type { ILogger } from '@/services/types'
import type { Forms } from '@/services/forms/formTypes'
import type { FieldValue } from '@/data/models/forms/types'
import type { InternalErrorResponse } from '@/services/transport/types'

/*
// Idea for proper typing of setFiledValue:

type Values<T> = T[keyof T]
type ToTuple<Mapping> = Values<{
  [Prop in keyof Mapping]: [key: Prop, data: Mapping[Prop]]
}>

type SomeSchema = {
  str: string,
  num: number,
}

// Usage
function setFiledValue(...args: ToTuple<SomeSchema>) {}
setFiledValue('str', 12) // error
setFiledValue('str', '12') //  ok
setFiledValue('new', '12') //  error
*/

@injectable()
export default abstract class BaseFormService<
  B extends FormBuilderInterface,
  P extends ParamsPartial = ParamsPartial,
  TFormScheme extends Record<string, any> = TpFormData,
  TSubmitConfig = unknown,
> implements FormPartialableInterface<P>
{
  protected builder: B

  @inject(SERVICE_TYPES.TypedFormBuilderFactoryService)
  private readonly typedFormBuilderFactoryService: () => TypedFormBuilderService<TFormScheme>

  private typedBuilder: TypedFormBuilderInterface<TFormScheme> | null = null

  private formEventCallback: FormEventCallback | undefined

  constructor(
    @inject(SERVICE_TYPES.FormBuilderFactoryService) formBuilderFactoryService: () => B,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: ILogger,
  ) {
    this.builder = formBuilderFactoryService()
  }

  public abstract buildForm(): Promise<void>
  public abstract submit(formData: TFormScheme, config: TSubmitConfig): Promise<unknown>

  public async doBuild() {
    await this.buildForm()
    this.setIsFormLoading(false)
  }

  public getBuilder(): B {
    return this.builder
  }

  /**
   *
   * Get typed wrapper for builder
   */
  public getTypedBuilder() {
    if (this.typedBuilder === null) {
      const typedFormBuilderFactoryService = this.typedFormBuilderFactoryService()
      typedFormBuilderFactoryService.setDependencys(this.builder)
      this.typedBuilder = typedFormBuilderFactoryService
    }
    return this.typedBuilder
  }

  // Set value with all hooks
  public onInput(name: string, value: any) {
    this.log(`onInput: ${name} ${value}`)
    const f = this.getField(name)
    const { field } = f

    this.triggerHook('beforeEach', field, value)
    this.triggerHook('afterEach', field, value)

    let valueToSet = value

    if (f.config.inputHandler) {
      const inputHandlerResponse = f.config.inputHandler(value, name, field.parentIndex)
      if (inputHandlerResponse) {
        const { value: handlerValue, prevent } = inputHandlerResponse
        valueToSet = handlerValue

        if (prevent) {
          return
        }
      }
    }

    this.setFieldValue(name, valueToSet)

    this.triggerHook('afterEach', field, value)
  }

  public onChange(name: string) {
    this.log(`onChange: ${name}`)
    const { field } = this.getField(name)
    this.triggerHook('change', field, field.value)
  }

  public setFieldValue(name: string, value: unknown, setInitial = false) {
    this.log(`set field value for ${name} value: ${value}`)
    const { field } = this.getField(name)
    this.updateFieldValue(field, value, setInitial)
  }

  public setFieldValueById(id: string, value: unknown, setInitial = false) {
    this.log(`set field value for ${id} value: ${value}`)
    const { field } = this.getFieldById(id)
    this.updateFieldValue(field, value, setInitial)
  }

  public setFieldOptions(name: string, options: SelectOption[]) {
    const { field } = this.getField(name)
    this.updateFieldOptions(field, options)
  }

  public getFieldOptions(name: string) {
    const { field } = this.getField(name)
    return field.options
  }

  public getForm() {
    return this.builder.getForm()
  }

  public getField(name: string) {
    return this.builder.getField(name)
  }

  public getFieldOrNull(name: string) {
    try {
      const field = this.builder.getField(name)
      return field
    } catch {
      return null
    }
  }

  public isArrayField(field: BaseForm<BaseFieldModel>): field is BaseForm<FieldArray> {
    return this.builder.isArrayField(field)
  }

  public isGroupField(field: BaseForm<BaseFieldModel>): field is BaseForm<FieldGroup> {
    return this.builder.isGroupField(field)
  }

  public hasField(id: string) {
    return this.builder.hasField(id)
  }

  public hasFieldByName(name: string) {
    return this.builder.hasFieldByName(name)
  }

  public serverErrorSerializer(errors: InternalErrorResponse) {
    return errors
  }

  public hasErrorField(id: string, errorFieldName: string) {
    return this.builder.hasErrorField(id, errorFieldName)
  }

  public getFieldById(id: string): BaseForm<BaseFieldModel> {
    return this.builder.getFieldById(id)
  }

  public getErrorFieldName(name: string): string {
    return this.builder.getErrorFieldName(name)
  }

  public getFieldDataById(id: string) {
    if (!this.hasField(id)) {
      throw new TmFormError(`Not exists field ${id}`)
    }
    return this.getFieldData(this.getFieldById(id))
  }

  public getFieldDataByName(name: string) {
    return this.getFieldData(this.getField(name))
  }

  public getFormData(): TFormScheme
  public getFormData(type: 'object'): TFormScheme
  public getFormData(type: 'array'): TpFormData
  public getFormData(type: 'array' | 'object' = 'object'): TpFormData | TFormScheme {
    switch (type) {
      case 'object': {
        return this.getForm().childrens.reduce((acc: Record<string, any>, c) => {
          acc[c.field.name] = this.getFieldData(c)
          return acc
        }, {})
      }
      case 'array': {
        return this.getForm().childrens.map((c) => this.getFieldData(c), {})
      }
      default: {
        const unknownType: never = type
        throw new TmFormError(`Unknown type ${unknownType}`)
      }
    }
  }

  public initForm(): void {
    this.builder.init(this.getFormId())
  }

  public buildField(name: string) {
    throw new TmFormError(`Implement build field method for ${this.getFormId()}`)
  }

  public getFormId(): Forms {
    // @see this method is monkey patched in src/config/types.ts formService method
    throw new TmFormError(`Implement "getFormId" method for ${this.constructor.name}`)
  }

  public setFormErrorMessageFrom400(error: TmApiValidationError) {
    this.clearFormSuccessMessage()
    this.setFormState('formErrorMessage', error.message)
  }

  public setFormErrorMessage(error: string) {
    this.clearFormSuccessMessage()
    this.setFormState('formErrorMessage', error)
  }

  public clearFormErrorMessage() {
    this.setFormState('formErrorMessage', '')
  }

  public clearFormSuccessMessage() {
    this.setFormState('formSuccessMessage', '')
  }

  public setIsLoading(value: boolean) {
    this.setFormState('isLoading', value)
  }

  public setIsFormLoading(value: boolean) {
    this.setFormState('isFormLoading', value)
  }

  public setFieldLoading(fieldName: string, value: boolean) {
    const { field } = this.getField(fieldName)
    const data: { [key: string]: any } = { loading: value }
    this.updateField([
      {
        id: field.id,
        ...data,
      },
    ])
  }

  public async loadOptions(fieldName: string, getOptions: () => Promise<SelectOption[]>) {
    this.setFieldLoading(fieldName, true)
    const options = await getOptions()
    this.setFieldOptions(fieldName, options)
    this.setFieldLoading(fieldName, false)
  }

  public selectFirstOption(fieldName: string) {
    const options = this.getFieldOptions(fieldName)
    if (!options.length) return
    this.setFieldValue(fieldName, options[0].value)
  }

  public selectFirstOptionIfEmpty(fieldName: string) {
    if (!this.isFieldValueEmpty(fieldName)) return
    this.selectFirstOption(fieldName)
  }

  public setInitialFieldValuesByCurrentValues() {
    const fields = this.getForm().toArray()
    fields.forEach((field) => this.setInitialFieldValueByCurrentValue(field.field))
  }

  protected setInitialFieldValueByCurrentValue(field: BaseFieldModel) {
    this.updateField([
      {
        id: field.id,
        initialValue: field.value,
      },
    ] as LowLevelUpdateBody<BaseFieldModel>[])
  }

  public getFieldLoadingState(fieldName: string): boolean {
    const { field } = this.getField(fieldName)
    return !!this.getFieldRepository().findEntityByIdOrNull(field.id)?.loading
  }

  public clearFieldError(fieldName: string) {
    const { field, childrens } = this.getField(fieldName)
    const fieldRepository = this.getFieldRepository()
    fieldRepository.updateItem({
      id: field.id,
      errors: [],
      serverErrors: [],
      invalid: false,
      valid: true,
      validated: false,
    })
    childrens.forEach((child) => {
      const childName = `${fieldName}.${child.field.name}`
      this.clearFieldError(childName)
    })
  }

  public clearFieldsErrors() {
    const fields = this.getFields()
    fields.forEach(({ field: { name } }) => this.clearFieldError(name))
  }

  public setFieldServerError(fieldName: string, serverError = '') {
    const {
      field: { id },
    } = this.getField(fieldName)
    const payload: Partial<BaseFieldModel> = {
      id,
      serverErrors: [serverError],
    }
    this.updateField([payload as LowLevelUpdateBody<BaseFieldModel>])
  }

  public setIsFormSent(value: boolean) {
    this.setFormState('isFormSent', value)
  }

  public getFormEntity() {
    return this.getFormRepository().find(this.getFormId())
  }

  // Form State
  public getFormState(): FormState {
    const form = this.getFormEntity()
    if (!form) {
      throw new TmFormError(`Form model not created yet. Form id = ${this.getFormId()}`)
    }
    const { formState, ...formData } = form
    return { ...formState, ...formData }
  }

  public getFocusedField(): string {
    return this.getFormState().focusedField
  }

  public setFormState(key: keyof FormState, value: string | boolean) {
    this.log(`Set formState [${key}]: ${value}`)

    this.getFormRepository().update([
      {
        id: this.getFormId(),
        formState: {
          ...this.getFormState(),
          [key]: value,
        },
      },
    ])
  }

  public setFocusedField(fieldName: string) {
    this.setFormState('focusedField', fieldName)
  }

  public setErrorScrollField(fieldName: string) {
    this.setFormState('errorScrollField', fieldName)
  }

  public setEditingId(id: string) {
    this.setFormState('editingId', id)
  }

  public getEditingId(): string {
    return this.getFormState().editingId
  }

  public destroy() {
    this.log('destroy')
    this.setIsFormLoading(true)
    this.getFieldRepository().deleteByCondition((field) => field.formId === this.getFormId())
    this.builder.destroy()
  }

  public isInit() {
    return this.builder.isInit()
  }

  public isChanged() {
    return this.getFieldRepository()
      .query()
      .where('formId', this.getFormId())
      .get()
      .some((field) => field.isChanged())
  }

  public getChangeset(data: Record<string, any>) {
    const changeset: LowLevelUpdateBody<BaseFieldModel>[] = []

    // eslint-disable-next-line no-restricted-syntax
    for (const field of this.getForm().toArray()) {
      changeset.push({
        id: field.field.id,
        ...data,
      })
    }
    return changeset
  }

  public getFieldChangeset(data: Record<string, any>, fieldName: string) {
    const { field } = this.getField(fieldName)
    return {
      id: field.id,
      ...data,
    }
  }

  public triggerServerErrorHandler(name: string, payload: any) {
    this.log(`triggerServerErroHandler: ${name}`)
    const { serverErrorHandler } = this.getField(name).config
    if (serverErrorHandler) {
      throw new TmFormError('Please implement `serverErrorHandler`')
      // serverErrorHandler(payload)
    }
  }

  public buildByData(data: Partial<TFormScheme>, parentName = '') {
    this.builder.buildByData(data, parentName)
  }

  public populate(data: Partial<TFormScheme>, setInitial = true) {
    this.log('Populate form')
    this.buildByData(data)
    this.populateWithoutBuild(data, setInitial)
  }

  public populateFromEntityId(entityId: string) {
    const formId = this.getFormId()
    throw new TmFormError(`Please implement \`populateFromEntityId\` in form with id ${formId}`)
  }

  public initializeWithCurrentData() {
    this.populate(this.getFormData(), true)
  }

  public extendArray(name: string, data?: Record<string, any>) {
    const field = this.getBuilder().extendArray(name)

    if (data) {
      field.childrens.forEach((child) => {
        if (Object.prototype.hasOwnProperty.call(data, child.field.name)) {
          this.setFieldValueById(child.field.id, data[child.field.name], true)
        }
      })
    }

    return field
  }

  public removeField(name: string) {
    this.getBuilder().removeField(name)
  }

  public removeFieldById(id: string) {
    this.getBuilder().removeFieldById(id)
  }

  public reorderArrayItems(newIdsOrder: string[]) {
    this.getBuilder().reorderArrayItems(newIdsOrder)
  }

  public getNameById(id: string): string {
    return this.getBuilder().getNameById(id)
  }

  public getName(name: string, parentName = ''): string {
    return this.getBuilder().getNameById(this.getBuilder().getFieldId(name, parentName))
  }

  public getFieldPath(name: string): string[] {
    return this.getBuilder().getFieldPath(name)
  }

  public discard() {
    this.discardNestedFields(this.getForm().childrens)
  }

  public discardField(field: BaseForm<BaseFieldModel>, strategy?: FieldDiscardStrategy) {
    this.log('Discard field')

    if (this.isArrayField(field) && strategy === 'clear') {
      field.childrens.forEach(({ id }) => this.removeFieldById(id))
      field.field.children = []
    } else if (this.isArrayField(field) || this.isGroupField(field)) {
      this.discardNestedFields(field.childrens)
    } else {
      this.updateFieldValue(field.field, field.field.initialValue)
      field.field.value = field.field.initialValue
    }
  }

  public discardFieldById(fieldId: string, strategy?: FieldDiscardStrategy) {
    this.log('Discard field initial values (get name by id and call discartField)')
    this.discardFieldByName(this.getNameById(fieldId), strategy)
  }

  public setHook(hookName: FormHookType, hook: FormHook) {
    this.getBuilder().setHook(hookName, hook)
  }

  public getFieldValueSafe<T>(name: string, safeValue: T = '' as unknown as T): T {
    if (this.getFormState().isFormLoading) {
      return safeValue
    }
    return this.getField(name).field.value as T
  }

  public isFieldValueEmpty(name: string) {
    return !this.getFieldValueSafe(name)
  }

  public getFieldSafe(name: string) {
    if (this.getFormState().isFormLoading) {
      return {} as BaseFieldModel
    }
    return this.getField(name).field
  }

  public getFieldInitialValueSafe<T>(name: string, safeValue: T = '' as unknown as T): T {
    if (this.getFormState().isFormLoading) {
      return safeValue
    }
    return this.getField(name).field.initialValue as T
  }

  public getPartial(): PartialUIConfig<P> {
    return {
      name: this.getFormId(),
      type: 'groupPartial',
      items: [],
    }
  }

  public getInputIds(fieldId: string) {
    return this.getFieldById(fieldId).field.inputIds
  }

  public addInputId(fieldId: string, inputId: string) {
    this.getFieldRepository().update([
      {
        id: fieldId,
        inputIds: [...this.getInputIds(fieldId), inputId],
      },
    ])
  }

  public removeInputId(fieldId: string, inputId: string) {
    if (!this.hasField(fieldId)) {
      return
    }
    this.getFieldRepository().update([
      {
        id: fieldId,
        inputIds: this.getInputIds(fieldId).filter((id) => id !== inputId),
      },
    ])
  }

  public setFormEventCallback(callback: FormEventCallback) {
    this.formEventCallback = callback
  }

  public removeFormEventCallback() {
    delete this.formEventCallback
  }

  public triggerFormEventCallback() {
    if (!this.formEventCallback) return
    const invalidFieldNames = this.getInvalidFieldNames()
    this.formEventCallback(invalidFieldNames)
  }

  public clearArrayField(fieldName: string) {
    if (!this.hasFieldByName(fieldName)) {
      return
    }

    const field = this.getField(fieldName)
    if (!this.isArrayField(field)) {
      throw new TmFormError('Field is not an array')
    }

    field.field.children.forEach((child) => {
      this.removeFieldById(child)
    })
  }

  protected getFields() {
    return this.getForm().childrens
  }

  protected getInvalidFieldNames() {
    return this.builder.getInvalidFieldNames()
  }

  protected discardNestedFields(fieldsArray: BaseForm<BaseFieldModel>[]) {
    // eslint-disable-next-line no-restricted-syntax
    for (const field of fieldsArray) {
      this.discardFieldById(field.field.id, field.config.discardStrategy)
    }
  }

  protected discardFieldByName<T extends string>(fieldName: T, strategy?: FieldDiscardStrategy) {
    const field = this.getField(fieldName)
    this.discardField(field, strategy)
  }

  protected populateWithoutBuild(data: Record<string, any>, setInitial = false, parentName = '') {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const name in data) {
      const id = this.getBuilder().getFieldId(name, parentName)
      if (this.hasField(id)) {
        const field = this.getFieldById(id)
        const fieldName = this.getNameById(field.field.id)
        if (field.field instanceof FieldGroup || field.field instanceof FieldArray) {
          this.populateWithoutBuild(data[name], setInitial, fieldName)
        } else {
          this.setFieldValue(fieldName, data[name], setInitial)
        }
      }
    }
  }

  protected getFormRepository() {
    return this.em.getRepository<BaseFormRepository>(Form)
  }

  protected getFieldRepository() {
    return this.em.getRepository<BaseFieldRepository>(BaseFieldModel)
  }

  protected log(message: string) {
    this.logger.log('forms', message, this.getFormId())
  }

  protected updateField<T>(changeSet: LowLevelUpdateBody<T>[]) {
    this.getFieldRepository().update(changeSet)
  }

  protected getFieldData(field: BaseForm<BaseFieldModel>): any {
    const instance = this.getFieldRepository().find(field.field.id)
    if (instance instanceof FieldArray) {
      const array = field.childrens.map((c) => this.getFieldData(c))
      return field.config.exportHandler ? field.config.exportHandler(array, instance.name) : array
    }
    if (instance instanceof FieldGroup) {
      const group = field.childrens.reduce((acc: Record<string, any>, c) => {
        acc[c.field.name] = this.getFieldData(c)
        return acc
      }, {})
      return field.config.exportHandler ? field.config.exportHandler(group, instance.name) : group
    }
    return field.config.exportHandler
      ? field.config.exportHandler(instance.getApiValue(), instance.name)
      : instance.getApiValue()
  }

  protected updateFieldValue(field: BaseFieldModel, value: unknown, setInitial = false) {
    const data: { [key: string]: any } = { value }

    const { value: fieldValue, initialValue: fieldInitialValue } = field

    if (setInitial && fieldInitialValue === value && fieldValue === value) {
      return
    }

    if (!setInitial && fieldValue === value) {
      return
    }

    if (setInitial) {
      data.initialValue = data.value
    }

    this.updateField([
      {
        id: field.id,
        ...data,
      },
    ])
  }

  protected updateFieldOptions(field: BaseFieldModel, options: SelectOption[]) {
    this.updateField<BaseFieldModel>([
      {
        id: field.id,
        options,
      },
    ])
  }

  private triggerHook(hookName: FormHookType, field: BaseFieldModel, value: FieldValue) {
    const callbacks = this.getBuilder().getHook(hookName)
    callbacks.forEach((callback) => callback(field, value))
  }

  protected getIsFieldChanged(field: BaseForm<BaseFieldModel>): any {
    const instance = this.getFieldRepository().find(field.field.id)
    return instance.isChanged()
  }
}

import { inject, injectable } from 'inversify'
import type { NavigationGuardNext, RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { SERVICE_TYPES, type RegisteredServices } from '@/core/container/types'
import type RouterService from '@/services/route/routerService'
import { composeMessageRoute } from '@/routes/user/compose'
import type { AccessControlStrategy } from '@/services/accessControl/types'
import type { GetLocator } from '@/core/container/container'
import { TmNotResolvedAsyncStrategyError } from '@/core/error/TmNotResolvedAsyncStrategyError'
import type { ILogger } from '@/services/types'
import type { Nullable } from '@/types'

@injectable()
export default class AccessControlService {
  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: ILogger,
    @inject('GetLocator') private readonly get: GetLocator,
  ) {}

  private resolvedStrategies: Partial<Record<RegisteredServices, boolean>> = {}

  private isInstalled = false

  private successInstalledCallback: Nullable<(value: null) => void> = null

  public async waitInstall() {
    if (this.isInstalled) {
      return
    }
    await new Promise<null>((resolve, reject) => {
      this.successInstalledCallback = resolve
    })
  }

  public async install() {
    this.routerService.getRouter().beforeEach((to, _from, next) => this.handleRouteTransition(to, next))
    await this.checkCurrentRoute()
    this.isInstalled = true
    if (this.successInstalledCallback) {
      this.successInstalledCallback(null)
    }
  }

  private async checkCurrentRoute() {
    const currentRoute = this.routerService.getCurrentRoute()
    const redirectRoute = await this.getAccessRedirect(currentRoute)
    if (redirectRoute) {
      await this.routerService.push(redirectRoute)
    }
  }

  private async handleRouteTransition(to: RouteLocationNormalized, next: NavigationGuardNext) {
    try {
      const redirectRoute = await this.getAccessRedirect(to)
      if (redirectRoute) {
        next(redirectRoute)
      } else {
        next()
      }
    } catch (error) {
      this.loggerService.error('router', (error as Error).message, 'AccessControlService')
      next(composeMessageRoute)
    }
  }

  protected getStrategy(strategyKey: RegisteredServices) {
    return this.get<AccessControlStrategy>(strategyKey)
  }

  public isAccessAllowed(strategyKey: RegisteredServices) {
    const strategy = this.getStrategy(strategyKey)
    if (strategy.resolve && !this.resolvedStrategies[strategyKey]) {
      throw new TmNotResolvedAsyncStrategyError(strategyKey)
    }
    return strategy.isAccessAllowed()
  }

  public async resolveStrategies(strategies: RegisteredServices[]) {
    return Promise.all(
      strategies.map(async (key) => {
        const strategy = this.getStrategy(key)
        if (!strategy.resolve) {
          return
        }
        await strategy.resolve()
        this.resolvedStrategies[key] = true
      }),
    )
  }

  private async getAccessRedirect(to: RouteLocationNormalized): Promise<RouteLocationRaw | null> {
    const { accessControlGroups } = to.meta

    if (!Array.isArray(accessControlGroups) || !accessControlGroups.length) {
      return null
    }
    await this.resolveStrategies(accessControlGroups)
    const accessControlGroupsResult = accessControlGroups.map((strategyKey) => {
      if (this.isAccessAllowed(strategyKey)) {
        return null
      }
      const strategy = this.getStrategy(strategyKey)
      if (strategy.getRedirectRoute) {
        return strategy.getRedirectRoute(to)
      }
      return composeMessageRoute
    })

    return accessControlGroupsResult.find((t) => t) ?? null
  }
}

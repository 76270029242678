<template>
  <div :class="['resolver', { 'resolver--failed': isFailed }]">
    <slot
      v-if="isFailed"
      name="failed"
      v-bind="{ onRetry }"
    />

    <slot
      v-else-if="isResolved"
      :resolver-props="resolveResult"
    />

    <slot
      v-else
      name="preloader"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, computed } from '@/composition/vue/compositionApi'
import { type RESOLVER_BASE_PROPS, useResolvers } from '@/composition/resolvers'

const props = defineProps<RESOLVER_BASE_PROPS>()
const emit = defineEmits<{
  'start-resolve': []
  'finish-resolve': []
}>()

const {
  resolve: _resolve,
  unresolve,
  retry: onRetry,
  resolvingState,
  resolveResult,
} = useResolvers(props.routeName, props.resolvers)

const resolve = async () => {
  emit('start-resolve')
  await _resolve()
  emit('finish-resolve')
}

const isFailed = computed<boolean>(() => resolvingState.value === 'FAILED')
const isResolved = computed<boolean>(() => resolvingState.value === 'RESOLVED')

onMounted(() => {
  resolve()
})

onUnmounted(() => {
  unresolve()
})
</script>

<style lang="scss" scoped>
.resolver {
  display: flex;
  flex-flow: column;
  height: 100%;

  &--failed {
    background-color: var(--tm-resolver-failed-bg-color, $white);
  }
}
</style>

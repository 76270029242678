import { AttrField, BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import { type ScheduledNotSentBulkParameters, ScheduledNotSentExceptionCode } from '@/data/models/domain/types'

@OrmModel(modelNames.SCHEDULED_NOT_SENT_MESSAGE)
export default class ScheduledNotSentMessage extends BaseModel {
  @StringField()
  public title: string

  @NumberField()
  public messagesIcsId: number

  @StringField()
  public failedAt: string

  @AttrField()
  public bulkParameters: ScheduledNotSentBulkParameters

  @StringField()
  public exceptionClass: string

  /**
   * exceptionCode == null after success resend message
   */
  @AttrField()
  public exceptionCode: ScheduledNotSentExceptionCode | null

  @StringField()
  public exceptionText: string

  @StringField()
  public enqueueAt: string

  @StringField()
  public source: string

  @StringField()
  public contactName: string

  @BooleanField()
  public isEnqueued: boolean

  @NumberField()
  public realRecipientsNumber: number

  public get isAllowResend() {
    return this.exceptionCode === ScheduledNotSentExceptionCode.LOW_BALANCE
  }

  public get isSuccessResend() {
    return this.exceptionCode === null
  }
}

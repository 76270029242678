<template>
  <div class="info-card-rows">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.info-card-rows {
  & > :not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $gray2;
    border-radius: 0 !important; // Fix default styles for tag "a" from main.scss
  }
}
</style>

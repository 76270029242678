import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  BillingAutoRechargeSettingsEditFormService,
  VerifyBillingAllowedResolver,
  BillingDetailsFormService,
  BillingDowngradeToPayAsYouGoFormService,
  UserTrialService,
  UserTrialRepository,
  ByCustomParamsResolverService,
  StatementsSummaryService,
  StatementsSummaryRepository,
  BillingPlansInfoService,
  BillingPlansInfoRepository,
}

export type BillingServices = keyof typeof SERVICES

export const BILLING_SERVICE_TYPES: Record<BillingServices, symbol> = makeServicesTypes<BillingServices>(
  enumKeysToArray<BillingServices>(SERVICES),
)

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable, SingleModelResolverParams } from '@/services/resolvers/types'
import type TenDlcPhoneNumbersService from '@/services/domain/services/regulations/tenDlcPhoneNumbers/tenDlcPhoneNumbersService'
import type BaseResolverService from '@/services/resolvers/baseResolverService'

@injectable()
export default class TenDlcNumbersCountResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.BaseResolverService)
    protected readonly baseResolverService: BaseResolverService,
    @inject(SERVICE_TYPES.TenDlcPhoneNumbersService)
    protected readonly tenDlcPhoneNumbersService: TenDlcPhoneNumbersService,
  ) {}

  public async resolve(params: SingleModelResolverParams): Promise<void> {
    const id = this.baseResolverService.getId(params)
    // @todo replace with facet/count endpoint
    await this.tenDlcPhoneNumbersService.getNumbersByCampaignId(id)
  }
}

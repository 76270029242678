import { injectable } from 'inversify'
import type { Endpoint } from '@/services/endpoints'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import GettingStartedInfo from '@/data/models/domain/GettingStartedInfo'
import OrmApiRepository from '@/data/repositories/ormApiRepository'

@RepoSettings<Endpoint>({
  model: GettingStartedInfo,
})
@injectable()
export default class GettingStartedInfoRepository extends OrmApiRepository<GettingStartedInfo> {
  private id = '1'

  public async getInfo() {
    const path = this.endpointsService.getPath('gettingStartedInfo')
    const response = await this.getApiSource().get<GettingStartedInfo>(path)
    const statuses = response.data
    await this.insertOrUpdate([{ ...response.data, id: this.id }])
    return statuses!
  }

  public findInfo() {
    return this.findEntityByIdOrNull(this.id)
  }

  public findInfoOrFail() {
    return this.findEntityByIdOrFail(this.id)
  }
}

<template>
  <resolver
    v-if="isOpen"
    :key="resolverKey"
    :resolvers="wrapperResolvers"
    :route-name="currentRouteName"
    @start-resolve="$emit('start-resolve')"
    @finish-resolve="$emit('finish-resolve')"
  >
    <slot />

    <template v-slot:failed="{ onRetry }">
      <slot
        name="failed"
        v-bind="{ onRetry }"
      >
        <tm-error-state
          size="small"
          @on-retry="onRetry(false)"
        />
      </slot>
    </template>

    <template v-slot:preloader>
      <slot name="preloader" />
    </template>
  </resolver>
</template>

<script lang="ts">
import { getRouterService, getWrapperManagerService } from '@/core/container/ioc'
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  type PropType,
  watch,
} from '@/composition/vue/compositionApi'
import TmErrorState from '@/components/shared/TmErrorState.vue'
import Resolver from '@/components/resolvers/Resolver.vue'
import type { TmWrappers } from '@/wrappers'
import type { WrapperParams } from '@/services/wrappers/types'
import { wrappersConfig } from '@/wrappers'
import { useWrapper } from '@/composition/modal'
import type { ResolverConfig, Resolvers } from '@/services/types'

export default defineComponent({
  components: {
    TmErrorState,
    Resolver,
  },
  props: {
    wrapperId: {
      type: String as PropType<TmWrappers>,
      required: true,
    },
    wrapperParams: {
      type: Object as PropType<WrapperParams>,
    },
    spinnerSizePx: {
      type: Number,
      default: 40,
    },
    withTransition: {
      type: Boolean,
      default: false,
    },
    delayOpenOnMounted: {
      type: Boolean,
    },
    autoOpen: {
      type: Boolean,
      default: true,
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    /**
     * @deprecated only for Stripe billing, temporary solution.
     * Will be removed after feature flag will be removed.
     * Please do not use this prop.
     */
    additionalResolvers: Array as PropType<Resolvers>,
  },
  emits: {
    'start-resolve': () => true,
    'finish-resolve': () => true,
  },
  setup(props) {
    const wrapperManagerService = getWrapperManagerService()
    const wrapperConfigItem = wrappersConfig[props.wrapperId]
    const routerService = getRouterService()
    const currentRoute = computed(() => routerService.getCurrentRoute())
    const currentRouteName = computed(() => currentRoute.value.name?.toString() || '')

    if (!wrapperManagerService.isBuilt(props.wrapperId)) {
      wrapperManagerService.build(props.wrapperId, wrapperConfigItem.type)
    }

    const { open, close, getWrapperData, setParams } = useWrapper(props.wrapperId)
    const openWrapper = () => open(props.wrapperParams)

    const isOpen = computed(() => getWrapperData().isOpen)

    const wrapperResolvers = computed(() => {
      if (!isOpen.value) {
        return []
      }
      const configResolvers = wrapperConfigItem.resolvers || []
      let resolvers = [...configResolvers, ...(props.additionalResolvers || [])]
      const modules = wrapperConfigItem.modules
      if (modules && modules.length !== 0) {
        resolvers.unshift({
          service: 'AppModuleResolverService',
          params: {
            modules,
          },
        })
        resolvers = [resolvers as ResolverConfig[]]
      }
      return resolvers
    }) as unknown as Resolvers

    watch(
      () => props.wrapperParams,
      (newParams) => {
        setParams(newParams)
      },
      { deep: true },
    )

    onMounted(() => {
      if (!props.autoOpen) {
        return
      }

      /**
       * Removes incorrect queue of hook calls for sidebar
       * when moving from desktop to table screen, first onMounted then onUnmounted is triggered
       * if the wrapper is already mounted in the page (example PinnedContactList),
       * when moving table to desktop the queue is correct, first onUnmounted and then onMounted is triggered.
       */
      if (props.delayOpenOnMounted) setTimeout(openWrapper, 0)
      else openWrapper()
    })

    onUnmounted(() => {
      if (props.autoClose) {
        close()
      }
    })

    const resolverKey = computed<string>(() => {
      if (!props.wrapperParams) {
        return props.wrapperId
      }
      return props.wrapperId + '-' + JSON.stringify(props.wrapperParams)
    })

    return {
      wrapperResolvers,
      isOpen,
      currentRouteName,
      resolverKey,
    }
  },
})
</script>

import type { RegisteredServices } from '@/core/container/types'
import {
  baseReportingHistoricalTableFilters,
  baseReportingHistoricalTableSchemaFilters,
} from '@/services/domain/reporting/common/types'

export const ReportingMessagesTableId = 'reportingMessagesTableId' as RegisteredServices

export const reportingMessagesFilters = baseReportingHistoricalTableFilters

export const reportingMessagesSchemaFilters = baseReportingHistoricalTableSchemaFilters

export const reportingMessagesChartTypes = ['sent', 'received', 'deliveryRate', 'replyRate', 'costs'] as const

export type ReportingMessagesChartType = (typeof reportingMessagesChartTypes)[number]

import { AttrField, BooleanField, OrmModel, StringField } from 'vuex-orm-decorators'
import { isEmpty } from 'lodash-es'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'
import type { Nullable } from '@/types'
import {
  TicketInboxForwardingStatus,
  TicketInboxType,
  type TicketParticipantPersonalInfo,
} from '@/data/models/domain/tickets/types'

@OrmModel(modelNames.TICKET_INBOX)
export default class TicketInbox extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public email: string

  @BooleanField()
  public isSystem: boolean

  @StringField()
  public senderName: string

  @BooleanField()
  public useAgentNameForReplies: boolean

  @AttrField()
  public defaultAssigneeId: Nullable<number>

  @AttrField()
  public defaultAssignee: Nullable<TicketParticipantPersonalInfo>

  @StringField()
  public type: TicketInboxType

  @AttrField()
  public forwardingSourceEmail: Nullable<string>

  @AttrField()
  public forwardingInboxStatus: Nullable<TicketInboxForwardingStatus>

  @StringField()
  public checkForwardingExpiryAt: string

  @AttrField()
  public forwardingVerificationLink: Nullable<string>

  @StringField()
  public createdAt: string

  @StringField()
  public updatedAt: string

  public get displayEmail() {
    return this.isManaged ? this.email : (this.forwardingSourceEmail as string)
  }

  public get isManaged() {
    return this.type === TicketInboxType.MANAGED
  }

  public get isForwardingVerified() {
    return this.forwardingInboxStatus === TicketInboxForwardingStatus.VERIFIED
  }

  public get isFinishedSetUp() {
    return this.isForwardingVerified || this.isManaged || !this.forwardingInboxStatus
  }

  public get canBeDeleted() {
    return !this.isSystem
  }

  public get hasParsedLink() {
    return !this.isManaged && !isEmpty(this.forwardingVerificationLink)
  }
}

import type { PreloaderSettingsType } from '@/services/preloaders/types'
import { REFLECT_METADATA } from '@/decorators/types'

export function PreloaderSettings(settings: PreloaderSettingsType) {
  return function <T extends { new (...args: any[]): any }>(constructor: T) {
    Reflect.defineMetadata(REFLECT_METADATA.PreloaderSettings, settings, constructor)
    return class extends constructor {
      public settings() {
        return settings
      }
    }
  }
}

import { AttrField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

type Prices = {
  chatForwardPrice: number
  phoneForwardPrice: number
}
@OrmModel(modelNames.NUMBER_PRICE)
export default class NumberPrice extends BaseModel {
  @StringField(null)
  public countryName: string

  @StringField(null)
  public phone: string

  @StringField(null)
  public currency: string

  @AttrField(null)
  public prices: Prices
}

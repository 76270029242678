import { inject, injectable } from 'inversify'
import type BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import { SERVICE_TYPES } from '@/core/container/types'
import type LoggerService from '@/services/loggerService'
import TableBaseStorageWrapperService from '@/services/wrappers/table/tableBaseStorageWrapperService'
import type BaseWrapperService from '@/services/wrappers/baseWrapperService'
import type QueryStorageService from '@/services/storage/queryStorageService'

@injectable()
export default class TableQueryWrapperService extends TableBaseStorageWrapperService {
  constructor(
    @inject(SERVICE_TYPES.BaseWrapperService) protected readonly baseWrapperService: BaseWrapperService<BaseWrapper>,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.QueryStorageService) protected readonly storageService: QueryStorageService,
  ) {
    super(baseWrapperService, loggerService, storageService)
  }
}

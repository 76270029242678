import { AttrField, BelongsToField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import type { FromNumber, SessionCountry } from '@/services/domain/session/types'
import SessionRecipients from '@/data/models/domain/session/SessionRecipients'
import SessionShort from '@/data/models/domain/session/SessionShort'
import type { CreatedBy } from '@/data/models/domain/types'

@OrmModel(modelNames.SESSION)
export default class Session extends SessionShort {
  @AttrField()
  public recipientsId: string

  @BelongsToField(SessionRecipients, 'recipientsId')
  public recipients: SessionRecipients

  @AttrField([])
  public fromNumbers: FromNumber[]

  @AttrField([])
  public countries: SessionCountry[]

  @AttrField()
  public createdBy: CreatedBy

  @AttrField()
  public archivedAt: string | null

  public get isArchived() {
    return this.archivedAt !== null
  }

  public get canBeArchived() {
    return !this.isArchived
  }

  public get canBeRestored() {
    return this.isArchived
  }

  public get canBeDuplicated() {
    return !this.isArchived
  }
}

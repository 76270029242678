import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  BaseSmsService,
  SentSmsColumnPropsService,
  BulkSentSmsCounterRepository,
  BulkSentSmsCounterService,
  SentSmsAppearanceService,
  SentSmsFullService,
  SentSmsShortService,
  SentSmsLocalStorageService,
  BulkSentSmsService,
  BulkSentSmsColumnServiceFactory,
  BulkSentSmsDetailsGridable,
  SentSmsRepository,
  SentSmsFullRepository,
  SentSmsShortRepository,
  BulkSentSmsRepository,
  SmsSessionRecipientsService,
  SmsSessionRecipientsRepository,
}

export type SentSmsBaseServices = keyof typeof SERVICES

export const SENT_SMS_BASE_SERVICE_TYPES: Record<SentSmsBaseServices, symbol> = makeServicesTypes<SentSmsBaseServices>(
  enumKeysToArray<SentSmsBaseServices>(SERVICES),
)

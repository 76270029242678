import { AttrField, BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import type { SmsTextParameters } from '@/data/models/domain/types'
import SentSms from '@/data/models/domain/SentSms'

@OrmModel(modelNames.SENT_SMS_FULL)
export default class SentSmsFull extends SentSms {
  @StringField()
  public id: string

  @StringField()
  public sender: string

  @StringField()
  public charset: string

  @NumberField()
  public chars: number

  @NumberField()
  public partsCount: number

  @StringField()
  public creditsPrice: string

  @StringField()
  public price: string

  @NumberField()
  public userId: number

  @StringField()
  public countryId: string

  @StringField()
  public source: string

  @StringField()
  public fromEmail: string

  @StringField()
  public avatar: string

  @StringField()
  public smscId: string

  @StringField()
  public charsetLabel: string

  @BooleanField()
  public deleted: boolean

  @AttrField()
  public textParameters: SmsTextParameters
}

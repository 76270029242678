<template>
  <div
    ref="scroll"
    :class="[
      scrollbarClassName,
      observedScrollbarQuasarClass,
      `${scrollbarClassName}--${scrollbarStyle}`,
      {
        'tm-scrollbar--scroll-x': allowScrollX,
        'tm-scrollbar--inactive': inactive,
      },
    ]"
    :data-scrollbar-id="scrollbarId ?? rootScrollbarId"
  >
    <div
      ref="scrollContent"
      class="tm-scrollbar__content"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, provide, ref } from '@/composition/vue/compositionApi'
import type { Scrollbars } from '@/services/browser/types'
import {
  observedScrollbarQuasarClass,
  rootScrollbarId,
  scrollbarClassName,
  type ScrollbarStyle,
  type ScrollXYPosition,
} from '@/components/shared/types'
import { useScrollProvide } from '@/composition/scroll'

const props = withDefaults(
  defineProps<{
    scrollbarId?: Scrollbars
    allowScrollX?: boolean
    stable?: boolean
    centering?: 'both' | 'x' | 'y'
    scrollbarStyle?: ScrollbarStyle
    inactive?: boolean
  }>(),
  {
    scrollbarStyle: 'thumb-with-track',
  },
)

const emit = defineEmits<{
  'update:scroll-position': [ScrollXYPosition]
  'update:scrolled-to-left': [boolean]
  'update:scrolled-to-right': [boolean]
  'update:scrolled-to-top': [boolean]
  'update:scrolled-to-bottom': [boolean]
  'update:scrolled-x': [boolean]
}>()

let resizeObserver: ResizeObserver | undefined
const scroll = ref<HTMLDivElement>()
const scrollContent = ref<HTMLDivElement>()

const { emitScrollEvent } = useScrollProvide()

provide('scrollBar', scroll)

const onScroll = () => {
  if (!scroll.value) return

  emitScrollEvent()

  const { offsetWidth, scrollWidth, scrollLeft, offsetHeight, scrollHeight, scrollTop } = scroll.value
  emit('update:scroll-position', {
    up: scrollTop,
    down: Math.max(scrollHeight - offsetHeight - scrollTop - 1, 0),
    left: scrollLeft,
    right: Math.max(scrollWidth - offsetWidth - scrollLeft, 0),
  })

  emit('update:scrolled-to-left', scrollLeft === 0)
  emit('update:scrolled-x', scrollLeft > 0)
  emit('update:scrolled-to-right', Math.ceil(offsetWidth + scrollLeft) >= scrollWidth)
  emit('update:scrolled-to-top', scrollTop === 0)
  emit('update:scrolled-to-bottom', Math.ceil(offsetHeight + scrollTop) >= scrollHeight)
}

const onScrollCallback = () => {
  // showScrollbar()
  onScroll()
}

onMounted(() => {
  // spec: Observation will fire when observation starts if Element is being rendered, and Element’s size is not 0,0.
  resizeObserver = new ResizeObserver(onScroll)
  resizeObserver.observe(scroll.value!)
  scroll.value!.addEventListener('scroll', onScrollCallback)
  if (props.centering === 'both') centeringByBoth()
  else if (props.centering === 'x') centeringByX()
  else if (props.centering === 'y') centeringByY()
})

onBeforeUnmount(() => {
  resizeObserver?.disconnect()

  if (scroll.value) {
    scroll.value.removeEventListener('scroll', onScrollCallback)
  }
})

// methods for using from parents
const setScroll = (axes: { x?: number; y?: number }) => {
  if (!scroll.value) {
    return
  }
  if (axes.x) {
    scroll.value.scrollLeft = axes.x
  }
  if (axes.y) {
    scroll.value.scrollTop = axes.y
  }
}
const centeringByX = () => {
  const { scrollWidth, offsetWidth } = scroll.value!
  if (scrollWidth > offsetWidth) setScroll({ x: (scrollWidth - offsetWidth) / 2 })
}
const centeringByY = () => {
  const { scrollHeight, offsetHeight } = scroll.value!
  if (scrollHeight > offsetHeight) setScroll({ y: (scrollHeight - offsetHeight) / 2 })
}
const centeringByBoth = () => {
  centeringByX()
  centeringByY()
}
const scrollYToEnd = () => {
  if (!scroll.value) return
  setScroll({ y: scroll.value.scrollHeight })
}

const getScrollContentElement = () => {
  return scrollContent.value
}

defineExpose({
  scrollYToEnd,
  getScrollContentElement,
  scroll,
})
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.tm-scrollbar {
  @include scrollbar-variants();
  display: flex;
  flex-flow: column;
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &--show-scroll-y {
    overflow-y: scroll;
  }

  &--inactive {
    overflow: unset;
  }

  &--stable {
    scrollbar-gutter: stable;
  }

  &--scroll-x {
    overflow-x: auto;
  }
  &__content {
    display: flex;
    flex-flow: column;
    flex: 1;
  }
}
</style>

import { AttrField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

export type FacebookPageAccount = {
  name: string
}

export type FacebookPageStatus = 'connected' | 'unavailable'

@OrmModel(modelNames.FACEBOOK_PAGE)
export default class FacebookPage extends BaseModel {
  @AttrField()
  public facebookAccount: FacebookPageAccount

  @StringField()
  public name: string

  @StringField()
  public status: FacebookPageStatus

  @StringField()
  public picture: string

  @StringField()
  public link: string

  @StringField()
  public chatLink: string

  @StringField()
  public platformPageId: string

  @StringField()
  public createdAt: string

  @StringField()
  public updatedAt: string
}

import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

enum SIGNUP_BY_OAUTH_SERVICES {
  SignUpByOAuthFormService,
}

export type SignUpByOAuthServices = keyof typeof SIGNUP_BY_OAUTH_SERVICES

export const SIGNUP_BY_OAUTH_SERVICE_TYPES: Record<SignUpByOAuthServices, symbol> =
  makeServicesTypes<SignUpByOAuthServices>(enumKeysToArray<SignUpByOAuthServices>(SIGNUP_BY_OAUTH_SERVICES))

<template>
  <div
    class="page-content-sidebar-hr"
    :style="{ paddingLeft: paddingLeft || paddingX, paddingRight: paddingRight || paddingX }"
  >
    <hr :class="{ 'page-content-sidebar-hr__in-sidebar': !inSideBar }" />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import { useLayoutMenuInSidebar } from '@/composition/layoutMenu'
import type { StylesheetSizeProp } from '@/types'

export default defineComponent({
  props: {
    paddingX: {
      type: String as PropType<StylesheetSizeProp>,
      default: '16px',
    },
    paddingLeft: {
      type: String as PropType<StylesheetSizeProp>,
    },
    paddingRight: {
      type: String as PropType<StylesheetSizeProp>,
    },
  },
  setup() {
    const inSideBar = useLayoutMenuInSidebar().inject()

    return {
      inSideBar,
    }
  },
})
</script>

<style lang="scss" scoped>
.page-content-sidebar-hr {
  padding-top: 8px;
  padding-bottom: 8px;

  hr {
    width: 100%;
  }

  &__in-sidebar {
    background-color: $gray5;
  }
}
</style>

<template>
  <div class="tm-status-reject-reason">
    <sent-sms-reject-reason-byoc
      v-if="provider"
      :provider="provider"
    />

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.MISSING_NUMBER"
      keypath="status.smsRejection.missingNumber"
    >
      <template v-slot:buy-number>
        <router-link
          v-close-popup
          :to="{ name: SenderSettingsRoutes.buyNumber }"
        >
          {{ $t('status.smsRejection.buyNumber') }}
        </router-link>
      </template>
    </tm-translate>

    <template v-else-if="rejectReason === SmsRejectReason.CONTACT_BLOCKED">
      {{ $t('status.smsRejection.contactBlocked') }}
    </template>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.RESTRICTED_COUNTRY"
      keypath="status.smsRejection.restrictedCountry"
    >
      <template v-slot:contact-support>
        <support-link :text="$t('common.contactSupportInline')" />
      </template>
    </tm-translate>

    <template v-else-if="rejectReason === SmsRejectReason.SMS_SURVEY_COUNTRY_RESTRICTION">
      {{ $t('status.smsRejection.smsSurveyRestriction') }}
    </template>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.TEN_DLC_REQUIRED"
      keypath="status.smsRejection.tenDlcRequired"
    >
      <template v-slot:complete>
        <router-link
          v-close-popup
          :to="{ name: RegulationsSettingsRoutes.tenDlcSettings }"
        >
          {{ $t('status.smsRejection.completeTenDLc') }}
        </router-link>
      </template>
    </tm-translate>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.FAILED_NUMBER || rejectReason === SmsRejectReason.BAD_AE_NUMBER"
      keypath="status.smsRejection.failedNumber"
    >
      <template v-slot:buy-number>
        <router-link
          v-close-popup
          :to="{ name: SenderSettingsRoutes.buyNumber }"
        >
          {{ $t('status.smsRejection.buyNumber') }}
        </router-link>
      </template>
    </tm-translate>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.REGULTION_GB"
      keypath="status.smsRejection.regultionGb"
    >
      <template v-slot:dedicate-virtual-number>
        <router-link
          v-close-popup
          :to="{ name: SenderSettingsRoutes.numbers }"
        >
          {{ $t('status.smsRejection.dedicateVirtualNumber') }}
        </router-link>
      </template>

      <template v-slot:sender-id>
        <router-link
          v-close-popup
          :to="{ name: senderIdsRouteName }"
        >
          {{ $t('status.smsRejection.senderId') }}
        </router-link>
      </template>

      <template v-slot:sender-settings>
        <router-link
          v-close-popup
          :to="{ name: SenderSettingsRoutes.countries }"
        >
          {{ $t('status.smsRejection.senderSettings') }}
        </router-link>
      </template>
    </tm-translate>

    <template v-else-if="rejectReason === SmsRejectReason.URL_SHORTENER">
      {{ $t('status.smsRejection.urlShortener') }}
    </template>

    <template v-else-if="rejectReason === SmsRejectReason.INVALID_NUMBER">
      {{ $t('status.smsRejection.invalidNumber') }}
    </template>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.SENDING_DISABLED_ON_ACCOUNT"
      keypath="status.smsRejection.sendingDisabledOnAccount"
    >
      <template v-slot:contact-support>
        <support-link :text="$t('common.contactSupportInline')" />
      </template>
    </tm-translate>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.PROVIDER_REQUIRED"
      keypath="status.smsRejection.connectProvider"
    >
      <template v-slot:action>
        <router-link
          v-close-popup
          :to="byocProvidersRoute"
        >
          {{ $t('status.smsRejection.connectProviderAction') }}
        </router-link>
      </template>
    </tm-translate>

    <template v-else-if="rejectReason === SmsRejectReason.DISABLED_VOICE_NUMBER">
      {{ $t('status.smsRejection.disabledVoiceNumber') }}
    </template>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.SUPPLIER_RESTRICTION"
      keypath="status.smsRejection.supplierRestriction"
    >
      <template v-slot:contact-support>
        <support-link :text="$t('common.contactSupportInline')" />
      </template>
    </tm-translate>

    <template v-else-if="rejectReason === SmsRejectReason.MMS_NUMBER_RESTRICTION">
      {{ $t('status.smsRejection.mmsRestriction') }}
    </template>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.CPAAS_AUTH_ISSUE"
      keypath="status.smsRejection.cpaasAuthIssue"
    >
      <template v-slot:update>
        <router-link
          v-close-popup
          :to="byocProvidersRoute"
        >
          {{ $t('status.smsRejection.updateCpass') }}
        </router-link>
      </template>
    </tm-translate>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.CPAAS_PHONE_ISSUE"
      keypath="status.smsRejection.cpaasPhoneIssue"
    >
      <template v-slot:your-provider>
        <router-link
          v-close-popup
          :to="byocProvidersRoute"
        >
          {{ $t('status.smsRejection.yourProvider') }}
        </router-link>
      </template>
    </tm-translate>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.REGULTION_AU"
      keypath="status.smsRejection.regultionAu"
    >
      <template v-slot:dedicate-virtual-number>
        <router-link
          v-close-popup
          :to="{ name: SenderSettingsRoutes.numbers }"
        >
          {{ $t('status.smsRejection.dedicateVirtualNumber') }}
        </router-link>
      </template>

      <template v-slot:sender-id>
        <router-link
          v-close-popup
          :to="{ name: senderIdsRouteName }"
        >
          {{ $t('status.smsRejection.senderId') }}
        </router-link>
      </template>

      <template v-slot:sender-settings>
        <router-link
          v-close-popup
          :to="{ name: SenderSettingsRoutes.countries }"
        >
          {{ $t('status.smsRejection.senderSettings') }}
        </router-link>
      </template>
    </tm-translate>

    <template v-else-if="rejectReason === SmsRejectReason.SUSPICIOUS_SESSION">
      {{ $t('status.smsRejection.suspiciousSession') }}
    </template>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.TOLL_FREE_UNVERIFIED"
      keypath="status.smsRejection.tollFreeUnverified"
    >
      <template v-slot:complete>
        <router-link
          v-close-popup
          :to="{ name: RegulationsSettingsRoutes.tollFreeSettings }"
        >
          {{ $t('status.smsRejection.tollFreeUnverifiedComplete') }}
        </router-link>
      </template>
    </tm-translate>

    <template v-else-if="rejectReason === SmsRejectReason.CONTACT_UNSUBSCRIBED">
      {{ $t('status.smsRejection.contactUnsubscribed') }}
    </template>

    <template v-else-if="rejectReason === SmsRejectReason.MMS_FORMAT_NOT_SUPPORTED">
      {{ $t('status.smsRejection.mmsFormatNotSupported') }}
    </template>

    <template v-else-if="rejectReason === SmsRejectReason.SUPPORT">
      {{ $t('status.smsRejection.support', { supportEmail }) }}
    </template>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.BYOC_FREE_TRIAL_EXPIRED"
      keypath="status.smsRejection.byocFreeTrialHasExpired"
    >
      <template v-slot:choose-a-plan>
        <router-link
          v-close-popup
          :to="activateRoute"
        >
          {{ $t('status.smsRejection.chooseAPlan') }}
        </router-link>
      </template>
    </tm-translate>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.BYOC_NUMBER_NOT_SUPPORT_SMS"
      keypath="status.smsRejection.byocNumberNotSupportSms.text"
    >
      <template v-slot:connect>
        <router-link :to="byocFlowRoute">
          {{ $t('status.smsRejection.byocNumberNotSupportSms.connect') }}
        </router-link>
      </template>
    </tm-translate>

    <tm-translate
      v-else-if="rejectReason === SmsRejectReason.BYOC_NUMBER_DEACTIVATED"
      keypath="status.smsRejection.byocNumberDeactivated"
    />

    <tm-translate
      v-else
      keypath="status.smsRejection.undetected"
    >
      <template v-slot:contact-support>
        <support-link :text="$t('common.contactSupportInline')" />
      </template>
    </tm-translate>
  </div>
</template>

<script setup lang="ts">
import { SmsRejectReason } from '@/types'
import TmTranslate from '@/components/TmTranslate.vue'
import { RegulationsSettingsRoutes, senderIdsRouteName, SenderSettingsRoutes } from '@/routes/user/services/types'
import SentSmsRejectReasonByoc from '@/components/domain/history/sms/sent/status/SentSmsRejectReasonByoc.vue'
import SupportLink from '@/components/shared/SupportLink.vue'
import { getByocHelperService, getByocRouteHelperService } from '@/core/container/ioc'
import { computed } from '@/composition/vue/compositionApi'
import { supportEmail } from '@/constants/links'
import type { TmRoutes } from '@/services/route/routerTypes'
import { ByocRouteNames } from '@/services/domain/byoc/types'

const props = defineProps<{
  rejectReason: SmsRejectReason
}>()

const byocHelperService = getByocHelperService()

const provider = computed(() => {
  return byocHelperService.getProviderBySmsRejectReason(props.rejectReason)
})

const byocRouteHelperService = getByocRouteHelperService()
const byocProvidersRoute = byocRouteHelperService.getProvidersRoute()

const activateRoute = computed(() => byocRouteHelperService.getByocActivateRoute())
const byocFlowRoute: TmRoutes = {
  name: ByocRouteNames.flow,
}
</script>

<style lang="scss" scoped>
.tm-status-reject-reason {
  display: inline-block;
  width: auto;
  line-height: 20px;
  vertical-align: text-top;
}
</style>

import { defineAsyncComponent, defineComponent, h, Suspense, type VNode } from '@/composition/vue/compositionApi'
import type { AnyAsyncComponent, AnyComponent } from '@/components/types'

type WithAsyncWrapperOptions = {
  delay?: number
  wrapper?: AnyComponent
  withFallback?: boolean
}

export const withAsyncWrapper = (
  target: AnyAsyncComponent,
  skeleton?: AnyComponent,
  options?: WithAsyncWrapperOptions,
) =>
  defineComponent({
    setup(props, { attrs }) {
      const wrapperComponent = options?.wrapper

      const targetComponent = defineAsyncComponent({
        suspensible: true,
        loader: target,
        loadingComponent: skeleton,
        delay: options?.delay,
      })

      return {
        wrapperComponent,
        targetComponent,
        skeleton,
        props,
        attrs,
      }
    },
    render() {
      const slots: Record<string, () => VNode> =
        options?.withFallback && this.skeleton
          ? {
              default: () => h(this.targetComponent, { ...this.attrs, ...this.props }),
              fallback: () => h(this.skeleton, { ...this.attrs, ...this.props }),
            }
          : {
              default: () => h(this.targetComponent, { ...this.attrs, ...this.props }),
            }

      if (this.wrapperComponent) {
        return h(this.wrapperComponent, null, {
          default: () => h(Suspense, null, slots),
          preloader: () => h(this.skeleton, { ...this.attrs, ...this.props }),
        })
      }

      return h(Suspense, null, slots)
    },
  })

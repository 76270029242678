import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum NAVIGATION_ORDER_SERVICES {
  NavigationOrderRepository,
  NavigationOrderResolver,
  NavigationOrderService,
}

export type NavigationOrderServices = keyof typeof NAVIGATION_ORDER_SERVICES

export const NAVIGATION_ORDER_SERVICES_TYPES =
  makeServicesTypesFromEnum<NavigationOrderServices>(NAVIGATION_ORDER_SERVICES)

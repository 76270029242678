import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum SERVICES {
  OutboundEmailsGridable,
  OutboundEmailsBulkService,
  OutboundEmailsBulkManager,
  OutboundEmailsColumnServiceFactory,
  OutboundEmailsTitlerService,
  OutboundEmailsResolverService,
  OutboundEmailsBulkResolverService,
  OutboundEmailsDetailsService,
  OutboundEmailsDetailsRepository,

  OutboundEmailsBulkDetailsRecipientsColumnService,
  OutboundEmailsBulkDetailsRecipientsFilterServiceFactory,
  OutboundEmailsBulkDetailsRecipientsGridable,
  OutboundEmailsBulkDetailsRecipientsExportService,

  OutboundEmailsBulkDetailsOpensGridable,
  OutboundEmailsBulkDetailsOpensExportService,
  OutboundEmailsBulkDetailsOpensColumnService,

  OutboundEmailsBulkDetailsUnsubscribersGridable,
  OutboundEmailsBulkDetailsUnsubscribeExportService,
  OutboundEmailsBulkDetailsUnsubscribeColumnService,

  OutboundEmailsBulkDetailsSpamReportsColumnService,
  OutboundEmailsBulkDetailsSpamReportsGridable,
  OutboundEmailsBulkDetailsSpamReportsExportService,

  OutboundEmailsBulkDetailsClicksSummaryColumnService,
  OutboundEmailsBulkDetailsClicksSummaryGridable,
  OutboundEmailsBulkDetailsClicksSummaryExportService,

  OutboundEmailsBulkDetailsClicksColumnService,
  OutboundEmailsBulkDetailsClicksGridable,
  OutboundEmailsBulkDetailsClicksExportService,
}

export type OutboundEmailsServices = keyof typeof SERVICES

export const OUTBOUND_EMAILS_SERVICES_TYPES = makeServicesTypesFromEnum<OutboundEmailsServices>(SERVICES)

import { inject, injectable } from 'inversify'
import type DomainBaseService from '@/services/domain/domainBaseService'
import type OrmApiRepository from '@/data/repositories/ormApiRepository'
import type BaseModel from '@/data/models/BaseModel'
import type { Autocompletable } from '@/services/types'
import type { DomainServices } from '@/services/domain/types'
import { SERVICE_TYPES, type RegisteredServices } from '@/core/container/types'
import type VuexService from '@/services/vuex/vuexService'
import ModelMapper from '@/data/utils/modelMapper'
import type { DomainAliasesType } from '@/services/preloaders/types'
import { TmDomainError } from '@/core/error/tmDomainError'
import { isServiceAutocompletable, isServicePreloadable } from '@/services/serviceGuards'
import type DomainSettingsService from '@/services/domain/domainSettingsService'
import type ModelSubscriptionService from '@/services/transport//modelSubscriptionService'
import type CleanUpManager from '@/services/cleanUp/cleanUpManager'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import { ModelEventType } from '@/services/transport/types'
import type ModelPreloaderService from '@/services/preloaders/modelPreloaderService'
import type { IServiceManager } from '@/core/middlewares/types'
import { REFLECT_METADATA, type DomainServiceSettings, type DomainServiceSettingsRequired } from '@/decorators/types'
import type { Service } from '@/config/types'
import type { Dict } from '@/types'
import type { GetLocator } from '@/core/container/container'

@injectable()
export default class DomainManagerService implements IServiceManager {
  private services: Dict<{ id: string; model: typeof BaseModel }> = {}

  private cache: DomainServices = {}

  private constructor(
    @inject(SERVICE_TYPES.VuexService) protected readonly vuexService: VuexService,
    @inject(SERVICE_TYPES.CleanUpManager) protected readonly cleanUpManager: CleanUpManager,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.ModelSubscriptionService)
    protected readonly modelSubscriptionService: ModelSubscriptionService,
    @inject('GetLocator') private readonly get: GetLocator,
  ) {}

  private guardAutocompletable(service: DomainBaseService<OrmApiRepository<any>>, label: string) {
    if (!isServiceAutocompletable(service)) throw new TmDomainError(`Not found autocompletable service for ${label}`)
  }

  public getAllDomains(): (typeof BaseModel)[] {
    const domains: (typeof BaseModel)[] = []
    Object.keys(this.cache).forEach((model) => {
      if (this.cache[model].getDomainSettings().model) {
        const domain = this.vuexService.getModelByModelEntity(model)
        domains.push(domain)
      }
    })
    return domains
  }

  public gatherAllDomainsWithRelated(): (typeof BaseModel)[] {
    return ModelMapper.gatherRelatedModelsByModels(this.getAllDomains())
  }

  public getAllDomainWithRelated(model: typeof BaseModel): (typeof BaseModel)[] {
    return this.getAllDomainWithRelatedTo(model, this.getAllDomainWithRelatedFrom(model))
  }

  public getAllDomainWithRelatedTo(model: typeof BaseModel, acc: (typeof BaseModel)[]): (typeof BaseModel)[] {
    return ModelMapper.gatherRelatedModels(model, acc)
  }

  public getAllDomainWithRelatedFrom(targetModel: typeof BaseModel): (typeof BaseModel)[] {
    return this.getAllDomains().reduce((acc: (typeof BaseModel)[], model: typeof BaseModel) => {
      if (ModelMapper.isDependsOn(model, targetModel)) {
        acc.push(model)
      }

      return acc
    }, [])
  }

  public addService(service: Service) {
    const settings = Reflect.getMetadata(REFLECT_METADATA.DomainSettings, service.bindingValue)
    if (this.guardModel(settings)) {
      this.services[settings.model.entity] = { id: service.id!, model: settings.model }
    }
    if (this.guardAlias(settings)) {
      this.services[settings.alias] = { id: service.id!, model: settings.model }
    }
    this.addModelSubcsription(settings.model)
  }

  public getService<T extends DomainBaseService<any>>(model: typeof BaseModel) {
    /* @ts-ignore */ /* eslint-disable-line */
    return this.getServiceByModelName(model.entity) as T
  }

  public getSettingsService<T extends DomainSettingsService<any>>(model: typeof BaseModel) {
    return this.getServiceByModelName(model.entity) as unknown as T
  }

  public getServiceByAlias(alias: DomainAliasesType) {
    /* @ts-ignore */ /* eslint-disable-line */
    return this.getServiceByModelName(alias)
  }

  public getServiceByModelName<T extends DomainBaseService<any>>(entity: string) {
    if (this.cache[entity]) {
      return this.cache[entity]
    }
    if (!this.services[entity]) {
      throw new TmDomainError(`Not found service for ${entity}`)
    }
    const info = this.services[entity]
    const result = this.get(info.id as RegisteredServices) as T
    const db = this.vuexService.getDB()
    if (db && !db.schemas[info.model.entity]) {
      db.register(info.model)
    }
    this.cache[entity] = result
    return result
  }

  public getAutocompleteService<T extends Autocompletable & DomainBaseService<any>>(model: typeof BaseModel) {
    /* @ts-ignore */ /* eslint-disable-line */
    const service = this.getService<T>(model)
    this.guardAutocompletable(service, model.entity)
    return service as T
  }

  public getAutocompleteServiceByAlias<T extends Autocompletable & DomainBaseService<any>>(alias: DomainAliasesType) {
    const service = this.getServiceByAlias(alias)
    this.guardAutocompletable(service, alias)
    return service as T
  }

  public getPreloadableService<T extends DomainBaseService<any>>(model: typeof BaseModel) {
    const service = this.getService<T>(model)
    if (isServicePreloadable(service)) {
      return service
    }
    throw new TmDomainError(`Not found preloadable service for ${model.entity}`)
  }

  public addModelSubcsription(model: typeof BaseModel) {
    this.modelSubscriptionService.subscribeByModel(
      model,
      (payload: { id?: string; ids?: string[] }) => {
        let ids: string[] | undefined
        if (payload.ids) {
          ids = payload.ids
        } else if (payload.id) {
          ids = [payload.id]
        }
        this.cleanUpManager.trigger([{ model, ids }])
      },
      true,
    )
    this.modelSubscriptionService.subscribeByModel(
      model,
      (payload: { id?: string; ids?: string[]; eventType: ModelEventType }) => {
        let ids: string[] = []
        if (payload.ids) {
          ids = payload.ids
        } else if (payload.id) {
          ids = [payload.id]
        }
        if (!ids.length) {
          return
        }

        const srv = this.getService(model)
        if ([ModelEventType.DELETE, ModelEventType.BULK_DELETE].includes(payload.eventType)) {
          srv.getDomainRepository().delete(ids)
          return
        }
        const preloader = this.preloaderManager.getPreloader<ModelPreloaderService>(model)
        ids.forEach((id) => {
          if (isServicePreloadable(srv)) {
            return srv.reloadById(id)
          }
          return preloader.reloadById(model, id)
        })
      },
      true,
    )
  }

  private guardAlias(settings: DomainServiceSettings): settings is DomainServiceSettingsRequired {
    return typeof settings.alias === 'string' && settings.alias.length > 0
  }

  private guardModel(settings: DomainServiceSettings): settings is DomainServiceSettingsRequired {
    return typeof settings.model !== 'undefined'
  }
}

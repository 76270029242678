export const getFileSizeLabel = (bytes: number, fractionDigits = 0): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))

  if (fractionDigits) {
    return `${(bytes / 1024 ** i).toFixed(fractionDigits).replace(/\.?0+$/, '')} ${sizes[i]}`
  }
  return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`
}

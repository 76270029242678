import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type {
  HttpCodeToErrorMap,
  HTTPMethods,
  TmTransportErrorClass,
  TmTransportErrorSubCode,
  TmTransportHttpErrorCode,
} from '@/core/error/transport/types'
import type { HttpInterceptorResponseError, InternalErrorResponse } from '@/services/transport/types'
import type { Optional } from '@/types'

@injectable()
export default class ErrorSerializerService {
  constructor(
    @inject(SERVICE_TYPES.ErrorsCustomRulesByStatusesMap)
    protected readonly errorsCustomRulesByStatusesMap: HttpCodeToErrorMap,
  ) {}

  public getErrorClass(error: HttpInterceptorResponseError<InternalErrorResponse>): Optional<TmTransportErrorClass> {
    const errorItem = this.errorsCustomRulesByStatusesMap[error.response.status as TmTransportHttpErrorCode]

    if (!errorItem) {
      return undefined
    }

    const { customRules = [] } = errorItem

    if (customRules.length === 0) {
      return errorItem.default
    }

    const subCode = error.response.data?.errorCode
    const method = error.config.method
    const endpoint = error.config.url

    const matchedRules = customRules.filter((rule) => {
      const endpointMatches = rule.endpoint === undefined || rule.endpoint === endpoint
      const subCodeMatches =
        rule.subCodes === undefined ||
        rule.subCodes.length === 0 ||
        rule.subCodes.includes(subCode as TmTransportErrorSubCode)
      const methodMatches =
        rule.methods === undefined || rule.methods.length === 0 || rule.methods.includes(method as HTTPMethods)
      const predicateFnMatches =
        rule.predicateFn === undefined ||
        ((err) => {
          try {
            return rule.predicateFn(err)
          } catch (e) {
            return false
          }
        })(error)

      return endpointMatches && subCodeMatches && methodMatches && predicateFnMatches
    })

    const rulesWithSubCodes = matchedRules.filter((rule) => rule.subCodes !== undefined && rule.subCodes.length > 0)
    if (rulesWithSubCodes.length > 0) {
      return rulesWithSubCodes[0].klass
    }

    return matchedRules[0]?.klass || errorItem.default
  }
}

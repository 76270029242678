import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  AccountNotificationsFormService,
  AccountNotificationsSettingsService,
  AccountNotificationsSettingsRepository,
}

export type NotificationsServices = keyof typeof SERVICES

export const NOTIFICATIONS_SERVICE_TYPES: Record<NotificationsServices, symbol> =
  makeServicesTypes<NotificationsServices>(enumKeysToArray<NotificationsServices>(SERVICES))

import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import FilteredView from '@/data/models/domain/filteredViews/FilteredView'
import { FilteredViewsSupportedEntities } from '@/data/models/domain/filteredViews/types'

@OrmModel(modelNames.SEGMENT, modelNames.FILTERED_VIEW, {
  SEGMENT: Segment,
  FILTERED_VIEW: FilteredView,
})
export class Segment extends FilteredView {
  @StringField(FilteredViewsSupportedEntities.CONTACTS)
  public entityName: FilteredViewsSupportedEntities
}

<template>
  <tm-button
    ref="buttonRef"
    v-close-popup
    icon-only
    flat
    no-border
    :size="buttonSize"
    class="tm-modal-close-btn"
    :class="{ 'tm-modal-close-btn--animation': animation }"
    :style="{ animationDuration: `${blinkDuration}ms` }"
    @click="$emit('close')"
  >
    <tm-icon
      name="close"
      :size="iconSize"
    />
  </tm-button>
</template>

<script setup lang="ts">
import { ref } from '@/composition/vue/compositionApi'
import TmButton from '@/components/shared/TmButton.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import type { ButtonSize, IconSize } from '@/components/shared/types'

withDefaults(
  defineProps<{
    iconSize?: IconSize
    buttonSize?: ButtonSize
  }>(),
  {
    iconSize: 'xLarge',
    buttonSize: 'xMedium',
  },
)

defineEmits<{
  close: []
}>()

const animation = ref(false)
const blinkDuration = 1000

const focus = () => {
  if (!animation.value) {
    animation.value = true
    setTimeout(() => {
      animation.value = false
    }, blinkDuration)
  }
}

defineExpose({
  focus,
})
</script>

<style lang="scss" scoped>
@keyframes blinker {
  0% {
    background-color: $blue100;
  }
  50% {
    background-color: $input;
  }
  100% {
    background-color: transparent;
  }
}

.tm-modal-close-btn {
  &--animation:not([disabled]) {
    animation-name: blinker;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }
}
</style>

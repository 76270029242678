import { injectable } from 'inversify'
import OrmLocalStorageRepository from '@/data/repositories/ormLocalStorageRepository'
import OAuthSignUp from '@/data/models/OAuthSignUp'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Optional } from '@/types'

@RepoSettings({
  model: OAuthSignUp,
  fetch: OAuthSignUp.entity,
  create: OAuthSignUp.entity,
  single: OAuthSignUp.entity,
})
@injectable()
export default class OAuthSignUpRepository extends OrmLocalStorageRepository<OAuthSignUp> {
  protected id = '1'

  public async fetchOAuthSignUpData(): Promise<Optional<OAuthSignUp>> {
    const data = await this.getRequest<Optional<OAuthSignUp>>(this.id)

    if (!data) {
      return undefined
    }

    await this.storeOAuthSignUpData(data)
    return this.getOAuthSignUpData()
  }

  public getOAuthSignUpData(): Optional<OAuthSignUp> {
    return this.find(this.id)
  }

  public async storeOAuthSignUpData(
    signUpData: Pick<OAuthSignUp, 'token' | 'username' | 'firstName' | 'lastName' | 'email'>,
  ) {
    await this.postRequest({
      ...signUpData,
      id: this.id,
    })
    await this.fill(this.id)
  }

  public clearOAuthSignUpData() {
    return this.deleteRequest(this.id)
  }
}

import type { PaginationUrlFilterType, PaginationUrlParametersSortType } from '@/services/tables/types'
import type { ComputedRef, Ref } from '@/composition/vue/compositionApi'
import type { BulkWrapperParams, WrapperParams, WrapperTypesServicesKeys } from '@/services/wrappers/types'
import type BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import type { SelectOption } from '@/services/forms/types'
import type { ModelRaw, ParamsPartial } from '@/types'
import type Grouping from '@/data/models/tables/Grouping'
import type { TmWrappers } from '@/wrappers'

export type PaginatedSelectComposition = (
  entityName: string,
  perPage: number,
  valueField: string,
  textField: string,
  preparer: ((item: any) => SelectOption) | undefined,
  filter: PaginationUrlFilterType,
  sort: PaginationUrlParametersSortType,
  searchQuery?: Ref<string>,
  searchFields?: string[] | undefined,
) => {
  blockLoading: Ref<boolean>
  options: Ref<any[]>
  isLoading: Ref<boolean>
  isAllLoaded: Ref<boolean>
  onIntersect: () => void
}

export type UseWrapper<T extends WrapperParams, R extends BaseWrapper> = {
  wrapperId: TmWrappers
  wrapperParams: ComputedRef<T>
  wrapperData: ComputedRef<ModelRaw<R>>
  isOpened: ComputedRef<boolean>
  open: (params?: T, wrapperType?: WrapperTypesServicesKeys) => void
  close: () => void
  build: () => void
  destroy: () => void
  getWrapperData: () => ModelRaw<R>
  getParams: () => T
  setParams: (params?: T) => void
  patchParams: (params?: T) => any
  removeParams: (paramsToDelete: string[]) => void
  clearParams: () => void
}
export type UseBulkWrapper = UseWrapper<BulkWrapperParams, BaseWrapper> & { selected: () => string[] }
export type UsePartial<T extends ParamsPartial> = {
  slots: Array<UsePartialSlot<T>>
  openedInGroup: () => Record<string, boolean>
  open: (part: string) => void
  isOpened: (part: string) => boolean
  openAll: () => void
  close: (part: string) => void
  closeAll: () => void
  destroy: () => void
}
export type UsePartialSlot<T> = {
  canonicalName: string
  name: string
  params: T
  required: boolean
}

export enum DiscardingStrategy {
  SET_TO_INITIAL,
  POPULATE,
  BOTH,
}

export type GroupingProps = {
  grouping: Grouping | null
  rowRelativeIndex: number
  firstVisibleRowAbsoluteIndex: number
}

export type TabsWrapperMapItem = {
  wrapperId: TmWrappers
  params: WrapperParams
}
export type TabsWrapperMap = Record<string, TabsWrapperMapItem>
export type Media = {
  name: string
  isImage: boolean
  isVideo: boolean
  fileExt: string
  resourceFullUrl: string
  thumbnail: string
  size: number
  fileSizeLabel: string
  width?: string
  height?: string
}

export type BaseWrapperParams = {
  editingId: string
}

export type UsePaginationProviderResponse<T> = {
  items: T[]
  pagination: {
    page: number
    pageCount: number
  }
}

export interface UsePaginationProviderOptions {
  page: number
  perPage: number
}

export interface UsePaginationOptions<T> {
  provider: (options: UsePaginationProviderOptions) => Promise<UsePaginationProviderResponse<T>>
  perPage?: number
}

export const defaultSearchDelay = 1000

export type SetupContext = {
  attrs: any
  emit: any
}

export const enum AuthLoaderMode {
  Off,
  BeforeSubmit,
  AfterSubmit,
}

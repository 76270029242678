import { injectable } from 'inversify'
import FaceterService from '@/services/facets/faceterService'
import type { FromPaginationFacet } from '@/services/facets/types'
import { isServiceGriddable } from '@/services/serviceGuards'
import TmFaceterError from '@/core/error/transport/tmFaceterError'
import { FaceterSettings } from '@/decorators/faceterSettingsDecorator'
import PhoneNumber from '@/data/models/domain/PhoneNumber'
import SenderId from '@/data/models/domain/senderId/SenderId'
import type { NumbersObjectNullable } from '@/types'
import { isRecordNumberOrNull } from '@/utils/typeGuards'
import { TmRepositoryError } from '@/core/error/tmRepositoryError'
import type Facet from '@/data/models/facets/Facet'
import TenDlcCampaign from '@/data/models/domain/services/regulations/TenDlcCampaign'

@injectable()
@FaceterSettings({
  models: [PhoneNumber, SenderId, TenDlcCampaign],
})
export default class FromPaginationFaceterService extends FaceterService<FromPaginationFacet> {
  public override async fetchFacets() {
    const modelEntity = this.settings.entityName
    const response = await this.getRepository().gridRequest(
      { filter: [] },
      // @todo back-end does not accept perPage: 0
      { requestParams: { page: 1, perPage: 1 }, pageKey: '1-0' },
    )

    this.mergeFacets({
      [modelEntity]: response.pagination.totalCount,
    })
  }

  public getFacets(): Facet {
    return this.getFacetRepository().find('paginationFacets')
  }

  public storeFacets(facets: NumbersObjectNullable) {
    if (isRecordNumberOrNull(facets)) {
      this.getFacetRepository().insertOrUpdateRaw([
        {
          id: 'paginationFacets',
          payload: facets,
        },
      ])
    } else {
      throw new TmRepositoryError('Broken contract with backend')
    }
  }

  protected getRepository() {
    const repo = this.getEntityRepository()
    if (!isServiceGriddable(repo)) {
      throw new TmFaceterError(`Repository for ${this.settings.entityName} is not griddable`)
    }

    return repo
  }
}

import type { RouteRecordRaw } from 'vue-router'
import { ticketsRouteNames, ticketsRoutePaths } from '@/routes/user/tickets/types'
import TicketWorkspace, { TICKET_WORKSPACE_ID } from '@/data/models/domain/tickets/TicketWorkspace'
import TicketInbox from '@/data/models/domain/tickets/TicketInbox'
import { ticketInboxIdViewParam } from '@/services/domain/tickets/const'
import type { SingleModelResolverParams } from '@/services/resolvers/types'
import TicketSpamFilter, { TICKET_SPAM_FILTER_ID } from '@/data/models/domain/tickets/TicketSpamFilter'
import { FilteredViewsSupportedEntities } from '@/data/models/domain/filteredViews/types'
import type { EditableFilteredViewsResolverServiceParams } from '@/services/domain/filteredViews/resolvers/types'
import { FILTERED_VIEW_PARAM_NAME } from '@/services/domain/filteredViews/const'
import TicketStatus from '@/data/models/domain/tickets/TicketStatus'

export const ticketsSettingsIndexRoute: RouteRecordRaw = {
  name: ticketsRouteNames.settingsIndex,
  path: ticketsRoutePaths.settingsIndex,
  component: () => import('@/components/views/tickets/settings/TicketsSettingsIndex.vue'),
  meta: {
    tabTitle: 'pageTabTitle.tickets.settings.index',
  },
}

export const ticketsSettingsInboxesTableRoute: RouteRecordRaw = {
  name: ticketsRouteNames.settingsInboxesTable,
  path: ticketsRoutePaths.settingsInboxesTable,
  component: () => import('@/components/views/tickets/settings/TicketsSettingsConnectedInboxes.vue'),
  meta: {
    tabTitle: 'pageTabTitle.tickets.settings.inboxes.table',
    resolvers: [
      {
        service: 'TicketInboxForwardingVerificationEventsResolverService',
      },
    ],
  },
}

export const ticketsSettingsInboxesConnectRoute: RouteRecordRaw = {
  name: ticketsRouteNames.settingsInboxesConnect,
  path: ticketsRoutePaths.settingsInboxesConnect,
  meta: {
    tabTitle: 'pageTabTitle.tickets.settings.inboxes.connect',
    resolvers: [
      {
        service: 'SingleModelResolverService',
        params: { model: TicketWorkspace, id: TICKET_WORKSPACE_ID },
      },
      {
        service: 'TicketConnectInboxContinueResolverService',
      },
      {
        service: 'TicketInboxForwardingVerificationEventsResolverService',
      },
    ],
    resolverComponent: () =>
      import('@/components/shared/templates/settingsPage/resolvers/SettingsPageCommonResolver.vue'),
  },
  component: () => import('@/components/views/tickets/settings/TicketsSettingsConnectNewInbox.vue'),
}

export const ticketsSettingsInboxesDetailsRoute: RouteRecordRaw = {
  name: ticketsRouteNames.settingsInboxesDetails,
  path: ticketsRoutePaths.settingsInboxesDetails,
  component: () => import('@/components/views/tickets/settings/TicketSettingsInboxDetails.vue'),
  meta: {
    tabTitle: 'pageTabTitle.tickets.settings.inboxes.details',
    resolvers: [
      {
        service: 'SingleModelResolverService',
        params: {
          model: TicketInbox,
          source: 'router',
          requestParam: ticketInboxIdViewParam,
        } satisfies SingleModelResolverParams,
      },
      {
        service: 'SingleModelResolverService',
        params: { model: TicketWorkspace, id: TICKET_WORKSPACE_ID },
      },
    ],
    resolverComponent: () =>
      import('@/components/shared/templates/settingsPage/resolvers/SettingsPageCommonResolver.vue'),
  },
}

export const ticketsSettingsInboxesRoute: RouteRecordRaw = {
  name: ticketsRouteNames.settingsInboxes,
  path: ticketsRoutePaths.settingsInboxes,
  redirect: { name: ticketsRouteNames.settingsInboxesTable },
  children: [ticketsSettingsInboxesTableRoute, ticketsSettingsInboxesConnectRoute, ticketsSettingsInboxesDetailsRoute],
}

export const ticketsSettingsSpamFilterRoute: RouteRecordRaw = {
  name: ticketsRouteNames.settingsSpamFilter,
  path: ticketsRoutePaths.settingsSpamFilter,
  meta: {
    tabTitle: 'pageTabTitle.tickets.settings.spamFilter',
    resolvers: [
      {
        service: 'SingleModelResolverService',
        params: { model: TicketSpamFilter, id: TICKET_SPAM_FILTER_ID, cache: false },
      },
    ],
    resolverComponent: () =>
      import('@/components/shared/templates/settingsPage/resolvers/SettingsPageCommonResolver.vue'),
  },
  component: () => import('@/components/views/tickets/settings/TicketsSettingsSpamFilter.vue'),
}

export const ticketsSettingsGeneralRoute: RouteRecordRaw = {
  name: ticketsRouteNames.settingsGeneral,
  path: ticketsRoutePaths.settingsGeneral,
  meta: {
    tabTitle: 'pageTabTitle.tickets.settings.general',
    resolvers: [
      {
        service: 'SingleModelResolverService',
        params: { model: TicketWorkspace, id: TICKET_WORKSPACE_ID },
      },
    ],
    resolverComponent: () =>
      import('@/components/shared/templates/settingsPage/resolvers/SettingsPageCommonResolver.vue'),
  },
  component: () => import('@/components/views/tickets/settings/TicketsSettingsGeneral.vue'),
}

export const ticketsSettingsFilteredViewsRoute: RouteRecordRaw = {
  name: ticketsRouteNames.settingsFilteredViews,
  path: ticketsRoutePaths.settingsFilteredViews,
  meta: {
    tabTitle: 'pageTabTitle.tickets.settings.filteredViews',
    resolvers: [
      {
        service: 'SingleModelResolverService',
        params: { model: TicketWorkspace, id: TICKET_WORKSPACE_ID },
      },
    ],
    resolverComponent: () =>
      import('@/components/shared/templates/settingsPage/resolvers/SettingsPageTableResolver.vue'),
  },
  component: () => import('@/components/views/tickets/settings/TicketsSettingsFilteredViews.vue'),
}

export const ticketsSettingsFilteredViewsDetailsRoute: RouteRecordRaw = {
  name: ticketsRouteNames.settingsFilteredViewsDetails,
  path: ticketsRoutePaths.settingsFilteredViewsDetails,
  meta: {
    tabTitle: 'pageTabTitle.tickets.settings.filteredViewsDetails',
    resolvers: [
      {
        service: 'SingleModelResolverService',
        params: { model: TicketWorkspace, id: TICKET_WORKSPACE_ID },
      },
      {
        service: 'ModelResolver',
        params: [TicketStatus],
      },
      {
        service: 'TicketsBasicDataResolverService',
      },
      [
        {
          service: 'FilteredViewsResolverService',
          params: [FilteredViewsSupportedEntities.TICKETS],
        },
        {
          service: 'EditableFilteredViewsResolverService',
          params: {
            idParam: FILTERED_VIEW_PARAM_NAME,
            entity: FilteredViewsSupportedEntities.TICKETS,
            redirectToIfNotFound: {
              name: ticketsRouteNames.settingsFilteredViews,
            },
          } satisfies EditableFilteredViewsResolverServiceParams,
        },
      ],
    ],
    resolverComponent: () =>
      import('@/components/shared/templates/settingsPage/resolvers/SettingsPageCommonResolver.vue'),
  },
  props: (route) => ({
    fvId: route.params[FILTERED_VIEW_PARAM_NAME],
  }),
  component: () => import('@/components/views/tickets/settings/TicketsSettingsFilteredViewsDetails.vue'),
}

export const ticketsSettingsFilteredViewsCreateRoute: RouteRecordRaw = {
  name: ticketsRouteNames.settingsFilteredViewsCreate,
  path: ticketsRoutePaths.settingsFilteredViewsCreate,
  meta: {
    tabTitle: 'pageTabTitle.tickets.settings.filteredViewsCreate',
    resolvers: [
      {
        service: 'SingleModelResolverService',
        params: { model: TicketWorkspace, id: TICKET_WORKSPACE_ID },
      },
      {
        service: 'ModelResolver',
        params: [TicketStatus],
      },
      {
        service: 'TicketsBasicDataResolverService',
      },
    ],
    resolverComponent: () =>
      import('@/components/shared/templates/settingsPage/resolvers/SettingsPageCommonResolver.vue'),
  },
  component: () => import('@/components/views/tickets/settings/TicketsSettingsFilteredViewsCreate.vue'),
}

export const ticketsSettingsRoute: RouteRecordRaw = {
  name: ticketsRouteNames.settings,
  path: ticketsRoutePaths.settings,
  component: () => import('@/components/views/tickets/settings/TicketsSettings.vue'),
  meta: {
    parent: ticketsRouteNames.root,
    menu: () => import('@/components/domain/tickets/settings/TicketsSettingsMenu.vue'),
  },
  redirect: { name: ticketsRouteNames.settingsIndex },
  children: [
    ticketsSettingsIndexRoute,
    ticketsSettingsInboxesRoute,
    ticketsSettingsSpamFilterRoute,
    ticketsSettingsGeneralRoute,
    {
      name: ticketsRouteNames.settingsFilteredViewsIndex,
      path: ticketsRoutePaths.settingsFilteredViewsIndex,
      redirect: { name: ticketsRouteNames.settingsFilteredViews },
      children: [
        ticketsSettingsFilteredViewsRoute,
        ticketsSettingsFilteredViewsCreateRoute,
        ticketsSettingsFilteredViewsDetailsRoute,
      ],
    },
  ],
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { PaginationUrlFilterType } from '@/services/tables/types'
import type { BulkServiceInterface } from '@/services/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type BaseModel from '@/data/models/BaseModel'
import type { BulkBaseBody, BulkDeleteParams } from '@/services/bulk/types'
import type OrmApiRepository from '@/data/repositories/ormApiRepository'
import type BaseFilters from '@/services/tables/filters/baseFilters'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import { ModelEventType } from '@/services/transport/types'
import type { EndpointParams } from '@/services/endpoints'

@injectable()
export default class BulkService implements BulkServiceInterface<BulkBaseBody> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService)
    protected readonly modelSubscriptionService: ModelSubscriptionService,
    @inject(SERVICE_TYPES.BaseFilters) protected readonly baseFilters: BaseFilters,
  ) {}

  public async bulkDelete<T extends BulkBaseBody>(
    data: T,
    ids: string[],
    // eslint-disable-next-line @typescript-eslint/default-param-last
    isAll = false,
    filters: PaginationUrlFilterType,
    model: typeof BaseModel,
    otherDeleteParams?: Partial<BulkDeleteParams>,
    endpoindParams?: EndpointParams,
  ): Promise<void> {
    const preparedFilters = this.prepareFiltersForDelete(isAll, filters)
    const deleteParams = this.prepareDeleteParams(ids, isAll)
    await this.em.getRepository<OrmApiRepository<any, any>>(model).bulkDelete<T>(
      data,
      preparedFilters,
      {
        ...(otherDeleteParams ?? {}),
        ...deleteParams,
      },
      endpoindParams,
    )
    this.notify(model, ids, ModelEventType.BULK_DELETE)
  }

  private prepareFiltersForDelete(isAll: boolean, filters: PaginationUrlFilterType) {
    return this.prepareFilters([], isAll, filters)
  }

  public async bulkUpdate<T extends BulkBaseBody>(
    data: T,
    ids: string[],
    // eslint-disable-next-line @typescript-eslint/default-param-last
    isAll = false,
    filters: PaginationUrlFilterType,
    model: typeof BaseModel,
  ) {
    const baseFilters = this.prepareFilters(ids, isAll, filters)
    await this.em.getRepository<OrmApiRepository<any, any>>(model).bulkUpdate<T>(data, ids, isAll, baseFilters)
    this.notify(model, ids, ModelEventType.BULK_UPDATE)
  }

  protected prepareDeleteParams(ids: string[], isAll = false): BulkDeleteParams {
    return {
      ids,
      all: isAll,
    }
  }

  // eslint-disable-next-line @typescript-eslint/default-param-last
  protected prepareFilters(ids: string[], isAll = false, filters: PaginationUrlFilterType) {
    let baseFilters = [...filters]
    if (ids && ids.length > 0 && !isAll) {
      baseFilters =
        baseFilters.length > 0
          ? baseFilters.map((f) => ({ ...f, id: this.baseFilters.toIdFilter(ids) }))
          : [{ id: this.baseFilters.toIdFilter(ids) }]
    }
    return baseFilters
  }

  protected notify(model: typeof BaseModel, ids: string[], eventType: ModelEventType) {
    this.modelSubscriptionService.emitByModel(model, { ids, eventType })
  }
}

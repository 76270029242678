import { OrmModel, AttrField, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import Preload from '@/data/models/Preload'

@OrmModel(modelNames.PAGE_MAP_PRELOAD, modelNames.PRELOAD, {
  PRELOAD: Preload,
  PAGE_MAP_PRELOAD: PageMapPreload, // eslint-disable-line
})
export default class PageMapPreload extends Preload {
  @AttrField([])
  public map: string[]

  @StringField()
  public pageKey: string

  @StringField()
  public accessoryId: string
}

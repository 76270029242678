import { injectable } from 'inversify'
import BaseFilterRepository from '@/data/repositories/filters/baseFilterRepository'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import RangeFilterModel from '@/data/models/filters/RangeFilterModel'

@RepoSettings<Endpoint>({
  model: RangeFilterModel,
})
@injectable()
export default class RangeFilterRepository extends BaseFilterRepository {}

import { fileFormatMap } from '@/utils/file/const/fileFormats'

export const getFileTypeByExt = (ext: string): string | null => {
  let found = fileFormatMap[ext]
  if (found) {
    if (Array.isArray(found)) {
      found = found[0]
    }
    return found.split('/')[0]
  }
  return null
}

import type { PluginComponents, Model as BaseBaseModel, Query, InstanceOf } from '@vuex-orm/core'
import type Item from '@vuex-orm/core/dist/src/data/Item'
import type Collection from '@vuex-orm/core/dist/src/data/Collection'
import { deleteProp } from '@/utils/object/deleteProp'

export type FixTypeKeyPluginConfig = {
  stiTypeKey: string
}

const cleanRecord = (callFn: () => Item<InstanceOf<BaseBaseModel>>, cleanKey: string) => {
  const result = callFn()
  if (!result) {
    return result
  }
  return deleteProp(result, cleanKey) as Item<InstanceOf<BaseBaseModel>>
}

const cleanCollection = (callFn: () => Collection<InstanceOf<BaseBaseModel>>, cleanKey: string) => {
  const result = callFn()
  if (!result) {
    return result
  }
  return result.map((rec) => deleteProp(rec, cleanKey)) as Collection<InstanceOf<BaseBaseModel>>
}

export const ModelMixin = (stiTypeKey: string) =>
  function (model: typeof BaseBaseModel) {
    const modelAllOriginal = model.all
    model.all = function () {
      return cleanCollection(() => modelAllOriginal.call(this), stiTypeKey)
    }
    const modelAllOriginal$ = model.prototype.$all
    model.prototype.$all = function () {
      return cleanCollection(() => modelAllOriginal$.call(this), stiTypeKey)
    }
    const modelFindOriginal = model.find
    model.find = function (id: string | number | (number | string)[]) {
      return cleanRecord(() => modelFindOriginal.call(this, id), stiTypeKey)
    }
    const modelFindOriginal$ = model.prototype.$find
    model.prototype.$find = function (id: string | number | (number | string)[]) {
      return cleanRecord(() => modelFindOriginal$.call(this, id), stiTypeKey)
    }
    const modelFindInOriginal = model.findIn
    model.findIn = function (idList: (number | string | (number | string)[])[]) {
      return cleanCollection(() => modelFindInOriginal.call(this, idList), stiTypeKey)
    }
    const modelFindInOriginal$ = model.prototype.$findIn
    model.prototype.$findIn = function (idList: (number | string | (number | string)[])[]) {
      return cleanCollection(() => modelFindInOriginal$.call(this, idList), stiTypeKey)
    }
  }

export const QueryMixin = (stiTypeKey: string) =>
  function (query: typeof Query) {
    const queryAllOriginal = query.prototype.all
    query.prototype.all = function () {
      return cleanCollection(() => queryAllOriginal.call(this), stiTypeKey)
    }
    const queryFindOriginal = query.prototype.find
    query.prototype.find = function (id: string | number | (number | string)[]) {
      return cleanRecord(() => queryFindOriginal.call(this, id), stiTypeKey)
    }
    const queryGetOriginal = query.prototype.get
    query.prototype.get = function () {
      return cleanCollection(() => queryGetOriginal.call(this), stiTypeKey)
    }
    const queryFirstOriginal = query.prototype.first
    query.prototype.first = function () {
      return cleanRecord(() => queryFirstOriginal.call(this), stiTypeKey)
    }
    const queryLastOriginal = query.prototype.last
    query.prototype.last = function () {
      return cleanRecord(() => queryLastOriginal.call(this), stiTypeKey)
    }
    const queryFindInOriginal = query.prototype.findIn
    query.prototype.findIn = function (idList: (number | string | (number | string)[])[]) {
      return cleanCollection(() => queryFindInOriginal.call(this, idList), stiTypeKey)
    }
  }

export const fixTypeKeyStiPlugin = {
  install: ({ Model, Query }: PluginComponents, config: FixTypeKeyPluginConfig) => {
    ModelMixin(config.stiTypeKey)(Model)
    QueryMixin(config.stiTypeKey)(Query)
  },
}

import { inject, injectable } from 'inversify'
import type { RouteLocationRaw, Router, RouteRecordRaw } from 'vue-router'
import { createRouter } from 'vue-router'
import { parseQuery, stringifyQuery } from '@/utils/router'
import BaseRouterService from '@/services/route/baseRouterService'
import { SERVICE_TYPES } from '@/core/container/types'
import type LoggerService from '@/services/loggerService'
import type HistoryService from '@/services/route/historyService'
import type SubscriptionService from '@/services/transport/subscriptionService'
import type WindowService from '@/services/browser/windowService'
import type { ICurrentRouteService, TmNamedRoute, TmNamedRouteRecord } from '@/services/route/types'
import { getWindowService } from '@/core/container/ioc'
import type TitlerManager from '@/services/route/titlers/titlerManager'

@injectable()
export default class RouterService extends BaseRouterService<Router> implements ICurrentRouteService {
  constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.HistoryService) protected readonly historyService: HistoryService,
    @inject(SERVICE_TYPES.TitlerManager) protected readonly titlerManager: TitlerManager,
  ) {
    super(loggerService, subscriptionService, windowService, titlerManager)
  }

  public initRouter() {
    this.historyService.createRouterHistory()
    this.router = createRouter({
      history: this.historyService.getRouterHistory(),
      routes: this.getRoutes() as RouteRecordRaw[],
      stringifyQuery,
      parseQuery,
    })

    this.assignPreAppLoader()
    return this.router
  }

  public getRawRouteName(route: RouteLocationRaw) {
    if (typeof route === 'string' || !('name' in route) || typeof route.name !== 'string') return undefined
    return route.name
  }

  public getNamedRouteFromRecord(route: TmNamedRouteRecord): TmNamedRoute {
    return { name: route.name }
  }

  public getNamedRoutesFromRecords(routes: TmNamedRouteRecord[]): TmNamedRoute[] {
    return routes.map((route) => this.getNamedRouteFromRecord(route))
  }

  public getOriginRoute(route: RouteRecordRaw): RouteRecordRaw {
    if (typeof route.name === 'string' && route.name.startsWith('resolver-') && route.children && route.children[0]) {
      return this.getOriginRoute(route.children[0])
    }
    return route
  }

  private assignPreAppLoader() {
    this.router.afterEach((to) => {
      // this timeout is necessary for slow internet connections
      setTimeout(() => getWindowService().self().__preAppLoader?.toggle(false), 5000)
    })
  }
}

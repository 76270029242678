import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import VoiceCallPrice from '@/data/models/domain/VoiceCallPrice'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import { TmIncorrectContractError } from '@/core/error/tmIncorrectContractError'

@RepoSettings<Endpoint>({
  model: VoiceCallPrice,
})
@injectable()
export default class VoiceCallPriceRepository extends OrmApiRepository<VoiceCallPrice> {
  public async fillAll(): Promise<VoiceCallPrice[]> {
    const path = this.endpointsService.getPath('chatCallsPrice')
    const { data } = await this.getApiSource().get<VoiceCallPrice[]>(path)
    if (!data) {
      throw new TmIncorrectContractError('Response data expected')
    }
    await this.insertOrUpdate(data)
    return this.all()
  }
}

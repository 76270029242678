import { injectable } from 'inversify'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import type {
  DistributionDetailsPayload,
  DistributionDetailsResponse,
} from '@/services/domain/services/distribution/types'
import type { TransportResponse } from '@/services/transport/types'
import DistributionDetails, {
  type DistributionDetailsFields,
} from '@/data/models/domain/services/distribution/DistributionDetails'

@injectable()
@RepoSettings<Endpoint>({
  model: DistributionDetails,
  create: 'distributions',
  single: 'distribution',
})
export default class DistributionDetailsRepository extends OrmApiRepository<DistributionDetails> {
  public async createDistribution(payload: DistributionDetailsPayload) {
    const response = await this.postRequest<DistributionDetailsResponse>(payload)
    await this.updateRepositoryDistribution(response.data.id, response.data.email, payload)
    return response.data
  }

  public async updateDistribution(id: string, payload: DistributionDetailsPayload) {
    const response = await this.putForFormRequest<TransportResponse<DistributionDetailsResponse>>({ id, ...payload })
    await this.updateRepositoryDistribution(id, response.data.email, payload)
    return response.data
  }

  private updateRepositoryDistribution(
    id: string,
    email: string,
    { sender, ...restPayload }: DistributionDetailsPayload,
  ) {
    const distributionDetails: DistributionDetailsFields = {
      id,
      email,
      ...restPayload,
      maxLength: Number(restPayload.maxLength),
      senderSourcePhone: sender,
    }
    return this.insertOrUpdate([distributionDetails])
  }
}

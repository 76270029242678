import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import AllSelectedTables from '@/data/models/tables/AllSelectedTables'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { ModelRelationArray } from '@/services/domain/types'

@RepoSettings<Endpoint>({
  model: AllSelectedTables,
})
@injectable()
export default class AllSelectedTablesRepository extends OrmApiRepository<AllSelectedTables> {
  public cleanUp(tableModelId: string) {
    this.delete([tableModelId])
  }

  public selectAll(tableModelId: string) {
    this.insertOrUpdateItem({
      id: tableModelId,
      isAllSelected: true,
    })
  }

  public unselectAll(tableModelId: string) {
    this.insertOrUpdateItem({
      id: tableModelId,
      isAllSelected: false,
    })
  }

  public override find(id: string, withRelations: ModelRelationArray<AllSelectedTables> = []) {
    const res = super.find(id, withRelations)

    return res
  }
}

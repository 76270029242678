<template>
  <div
    class="page-content-sidebar"
    :class="{
      'page-content-sidebar--hidden': hidden,
      'page-content-sidebar--collapsed': sidebarCollapsed,
      'page-content-sidebar--in-hover': sidebarInHover || sidebarLocked,
      'page-content-sidebar--transition': sidebarShowTransition,
    }"
    @mouseleave="sidebarInHover = false"
  >
    <div
      v-if="!isHideCollapsedButton"
      class="page-content-sidebar__btn"
      @click="btnClickHandle"
    >
      <tm-icon
        :name="sidebarCollapsed ? 'tmi-chevron-right' : 'tmi-chevron-left'"
        size="small"
      />
    </div>
    <div class="page-content-sidebar__resize">
      <div class="page-content-sidebar__border" />
      <div
        ref="resizerRef"
        class="page-content-sidebar__resize-button"
      />
    </div>
    <tm-scrollbar
      class="h100pr mr-1px"
      scrollbar-style="thumb-without-track"
      @mouseenter="sidebarInHover = true"
    >
      <page-content-main>
        <div class="d-flex align-center mb-3">
          <h2 class="page-content-sidebar__title">{{ title }}</h2>
          <slot name="after-title" />
        </div>

        <div class="page-content-sidebar__content">
          <slot @lock-sidebar="sidebarLocked = $event" />
        </div>
      </page-content-main>
    </tm-scrollbar>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted, onBeforeUnmount } from '@/composition/vue/compositionApi'

import TmIcon from '@/components/shared/TmIcon.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import PageContentMain from '@/components/layout/pageContent/PageContentMain.vue'

export default defineComponent({
  components: {
    TmIcon,
    PageContentMain,
    TmScrollbar,
  },
  props: {
    title: {
      type: String,
    },
    hidden: {
      type: Boolean,
    },
    sidebarWidth: {
      type: Number,
      required: true,
    },
    sidebarCollapsed: {
      type: Boolean,
    },
    sidebarShowTransition: {
      type: Boolean,
    },
    isHideCollapsedButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'set-sidebar-width': (_value: number, _saveToStore: boolean) => true,
    'set-sidebar-collapsed': (_value: boolean) => true,
    'set-sidebar-show-transition': (_value: boolean) => true,
  },
  setup(props, context) {
    const resizerRef = ref<HTMLDivElement>()
    const sidebarInHover = ref(false)
    const sidebarLocked = ref(false)

    let pageContentElement: HTMLElement | undefined
    let resizerElement: HTMLElement | undefined
    let resizeObserver: ResizeObserver | undefined

    const btnClickHandle = () => {
      context.emit('set-sidebar-collapsed', !props.sidebarCollapsed)
      sidebarInHover.value = false
    }

    const styleUpdate = (elementWidth: number) => {
      if (!pageContentElement) {
        return
      }

      const minWidth = 200
      const maxWidth = pageContentElement.offsetWidth * 0.3 // 30% of page

      if (elementWidth <= minWidth) context.emit('set-sidebar-width', minWidth, false)
      else if (elementWidth >= maxWidth) context.emit('set-sidebar-width', maxWidth, false)
      else context.emit('set-sidebar-width', elementWidth, false)

      pageContentElement.style.setProperty('--page-content-sidebar-width', `${props.sidebarWidth}px`)
    }

    const stopResize = () => {
      context.emit('set-sidebar-show-transition', true)
      window.removeEventListener('mousemove', resize)
      context.emit('set-sidebar-width', props.sidebarWidth, true)
    }

    const resize = (e: MouseEvent) => {
      if (!pageContentElement) {
        return
      }

      const offset = e.pageX - pageContentElement.getBoundingClientRect().left
      context.emit('set-sidebar-show-transition', false)

      styleUpdate(offset)
    }

    const onResizerMouseDown = (e: MouseEvent) => {
      e.preventDefault()
      window.addEventListener('mousemove', resize)
      window.addEventListener('mouseup', stopResize)
    }

    onMounted(() => {
      pageContentElement = document.querySelector('.page-content-sidebar-wrapper') as HTMLElement
      resizerElement = resizerRef.value

      resizeObserver = new ResizeObserver(() => {
        styleUpdate(props.sidebarWidth)
      })

      if (pageContentElement) {
        resizeObserver.observe(pageContentElement)
      }

      resizerElement?.addEventListener('mousedown', onResizerMouseDown)
    })

    onBeforeUnmount(() => {
      resizerElement?.removeEventListener('mousedown', onResizerMouseDown)
      window.removeEventListener('mousemove', resize)
      window.removeEventListener('mouseup', stopResize)
      resizeObserver?.disconnect()
    })

    return {
      resizerRef,
      sidebarInHover,
      sidebarLocked,
      btnClickHandle,
    }
  },
})
</script>

<style lang="scss" scoped>
$padding: 16px;

.mr-1px {
  margin-right: 1px;
}
.page-content-sidebar {
  background: $canvas;
  position: absolute;
  height: 100%;
  width: var(--page-content-sidebar-width);
  max-width: 100%;
  z-index: 11;
  will-change: width;

  &--transition {
    transition: all $transition-300ms-ease-in-out;
  }

  &:not(:hover):not(.page-content-sidebar--collapsed) {
    .page-content-sidebar__btn {
      opacity: 0;
    }
  }

  &__title {
    flex-grow: 1;
    white-space: nowrap;
  }
  &__btn {
    position: absolute;
    top: 24px;
    display: flex;
    color: $distinct;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
    right: 0;
    transform: translateX(50%);
    width: 28px;
    height: 28px;
    transition: all $transition-300ms-ease-in-out;
    border-radius: 20px;
    background: $white;
    border: 1px solid $gray2;
    box-shadow: 0 2px 12px 0 rgba($black, 0.04);
    opacity: 1;
    will-change: opacity;
  }

  &__resize {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    transform: translateX(-1px);
    z-index: 2;

    &-button {
      width: 6px;
      height: 100%;
      position: absolute;
      cursor: ew-resize;
      left: 0;
      top: 0;
      z-index: 2;
    }

    &:hover {
      .page-content-sidebar__border {
        transform: scaleX(2);
        background-color: $primary;
      }
    }
  }

  &__border {
    transition: background-color $transition-300ms-ease-in-out;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    background-color: $border-color;
    will-change: transform, background-color;
  }

  :deep(.tm-scrollbar) {
    transition: all $transition-300ms-ease-in-out;
  }

  &.page-content-sidebar--collapsed {
    left: 0;
    width: 32px;
    box-shadow: -4px 0 8px 0 rgba($black, 0.04) inset;
    .page-content-sidebar__resize {
      pointer-events: none;
    }

    :deep(.tm-scrollbar) {
      opacity: 0;
    }

    &.page-content-sidebar--in-hover {
      width: var(--page-content-sidebar-width);
      box-shadow: 0 10px 30px 0 rgba($black, 0.11);

      :deep(.tm-scrollbar) {
        opacity: 1;
      }
    }
  }

  & > .tm-scrollbar {
    height: 100%;
  }

  &--hidden {
    width: 0;
  }

  &__content {
    margin: 0 #{-$padding};
  }
}
</style>

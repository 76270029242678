import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type GettingStartedInfoService from '@/services/domain/gettingStartedInfoService'

@injectable()
export default class SettingStartedInfoResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.GettingStartedInfoService)
    protected readonly gettingStartedInfoService: GettingStartedInfoService,
  ) {}

  public async resolve() {
    const info = this.gettingStartedInfoService.findInfo()
    if (!info) {
      await this.gettingStartedInfoService.getInfo()
    }
  }
}

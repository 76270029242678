<template>
  <div class="app-wrapper">
    <tm-notifications />
    <router-view />
    <tm-auth-loader
      v-if="shouldDisplayAuthLoader"
      @reload="onReloadClick"
    />
    <tm-modal-wrapper />
    <tm-tooltip-manager />
  </div>
</template>

<script lang="ts">
import { useQuasar } from 'quasar'

import { defineComponent } from '@/composition/vue/compositionApi'

import { getWindowService } from '@/core/container/ioc'

import TmModalWrapper from '@/components/TmModalWrapper.vue'
import TmAuthLoader from '@/components/shared/TmAuthLoader.vue'
import TmNotifications from '@/components/shared/notifications/TmNotifications.vue'
import TmTooltipManager from '@/components/shared/tooltip/TmTooltipManager.vue'

import { useAuthLoader } from '@/composition/useAuthLoader'

export default defineComponent({
  components: {
    TmTooltipManager,
    TmModalWrapper,
    TmAuthLoader,
    TmNotifications,
  },
  setup() {
    const { shouldDisplayAuthLoader } = useAuthLoader()
    const windowService = getWindowService()

    const $q = useQuasar()

    // Add support for custom icons
    $q.iconMapFn = (iconName) => {
      if (iconName.startsWith('tmi-')) {
        return {
          cls: `tmi ${iconName}`,
        }
      }

      return {
        cls: `mdi-r mdi-${iconName}`,
      }
    }

    const onReloadClick = () => {
      windowService.reload()
    }

    return {
      shouldDisplayAuthLoader,
      onReloadClick,
    }
  },
})
</script>

<!-- eslint-disable-next-line vue/enforce-style-attribute -->
<style lang="scss">
#app {
  height: 100%;
  position: relative;
}
</style>

<style lang="scss" scoped>
.app-wrapper {
  height: 100%;
}
</style>

import { inject, injectable } from 'inversify'
import type { HttpInterceptor, InternalErrorResponse, HttpInterceptorResponseError } from '@/services/transport/types'
import { SERVICE_TYPES } from '@/core/container/types'
import { TmApiPlanExpiredError } from '@/core/error/transport/tmApiPlanExpiredError'
import type RouterService from '@/services/route/routerService'
import type { TmRoutes } from '@/services/route/routerTypes'

@injectable()
export default class PlanExpiredInterceptor implements HttpInterceptor<any, InternalErrorResponse> {
  constructor(@inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService) {}

  public async responseError(error: HttpInterceptorResponseError<InternalErrorResponse>) {
    if (error instanceof TmApiPlanExpiredError) {
      const route: TmRoutes = {
        name: 'planExpired',
      }
      this.routerService.replace(route)
    }
    return Promise.reject(error)
  }
}

import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import type { TpFormData } from '@/services/forms/types'
import type BaseModel from '@/data/models/BaseModel'
import { isRecordUnknown } from '@/utils/typeGuards'
import type { BULK_STATUS } from '@/services/bulk/types'
import { BULK_STATUS_COMPLETED, BULK_STATUS_IN_PROGRESS } from '@/services/bulk/types'
import type { Dict } from '@/types'
import type { VoiceCallType } from '@/data/models/domain/chats/types'
import type PinnedContact from '@/data/models/domain/pinnedContact'
import type { TaskBoardView } from '@/services/domain/tasks/types'
import type { ServerEventBase, SocketEventBase } from '@/services/transport/serverEvents'
import type { CentrifugeTokensResponse } from '@/services/domain/accounts/types'

export type CreatedEntityData = {
  id: string
  url: string
}
export type CreatedEntityResponse = TransportResponse<CreatedEntityData>

export type EmptyResponse = {
  data: Record<string, any>
}
export type UpdatedEntityResponse = {
  data: {
    id: string
    url: string
  }
}

export type BlobResponse = {
  status: 200 | 201 | 204
  data: Blob
  headers: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'content-disposition': string
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'content-type': string
  }
}
export type ExportJustInTimeResponse = BlobResponse & {
  status: 201
}

export type ExportNoContentResponse = BlobResponse & {
  status: 204
}

export type ExportDeferredResponse = {
  status: 200
  data: {
    exportType: 'deferred'
  }
}
export type ExportErrorResponse = {
  status: 400 | 413
  data: {
    message: string
  }
}
export type ExportResultResponse =
  | ExportJustInTimeResponse
  | ExportNoContentResponse
  | ExportDeferredResponse
  | ExportErrorResponse

export type BulkProcessResponse = {
  data: {
    createdAt: string
    failedProcessedEntities: number
    id: string
    status: BULK_STATUS
    successfullyProcessedEntities: number
    totalEntities: number
    updatedAt: string
  }
}
export const isBulkProcessStatus = (rawStatus: unknown): rawStatus is BULK_STATUS =>
  typeof rawStatus === 'string' && (rawStatus === BULK_STATUS_IN_PROGRESS || rawStatus === BULK_STATUS_COMPLETED)
export const isBulkProcessResponse = (rawResponse: unknown): rawResponse is BulkProcessResponse =>
  !!(
    isRecordUnknown(rawResponse) &&
    isRecordUnknown(rawResponse.data) &&
    typeof rawResponse.data.id === 'string' &&
    typeof rawResponse.data.updatedAt === 'string' &&
    typeof rawResponse.data.createdAt === 'string' &&
    typeof rawResponse.data.failedProcessedEntities === 'number' &&
    typeof rawResponse.data.successfullyProcessedEntities === 'number' &&
    typeof rawResponse.data.totalEntities === 'number' &&
    isBulkProcessStatus(rawResponse.data.status)
  )

export type ExportToPdfResultResponse = BlobResponse & {
  status: 200
}
export type PdfDownloadLinkResultResponse = Omit<ExportToPdfResultResponse, 'data'> & {
  data: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    invoice_link: string
  }
}

export type InternalErrorResponseBase = {
  message: string
  code: number
  errorCode?: string
}

export type ServerErrors = string | string[] | { fields: Dict<ServerErrors> }
export type ServerErrorFields = string[]

export type InternalErrorResponse = {
  errors: {
    common?: string[]
    fields: Dict<ServerErrors>
  } | null
  question?: string
  tfaId?: number
} & InternalErrorResponseBase

export type ConfirmationResponse = {
  success: boolean
  message: {
    alert: string
  }
  data: {
    [key: string]: any[]
  }
}

export type FileResponse = {
  id: string
  href: string
  originalName: string
  uploadedAt: string
  mimeType: string
}

export type WebSocketConnectionConfig = {
  url: string
  path: string
}

export type SubscribeOnRoomPayload = {
  room: string
  domains: string[]
}

export type BroadcastPayload = {
  room: string
  payload: Record<string, any>
}

export type JobType = 'common' | 'shared'
export type DomainAction = 'domain.create' | 'domain.update' | 'domain.delete'
export type AuthAction = 'logout'
export type JobAction = DomainAction | AuthAction

export type CommonSignalData = {
  userId: string
  sessionId: string
  domain: string | null
  action: JobAction
  [key: string]: any
}

export type ShareSignalData = Record<string, any>

export type SignalError = {
  message: string
  [key: string]: any
}

export type Signal<T> = {
  job: JobType
  room: string | null
  data: T
  errors: SignalError[]
}

export type SignalResolver<T> = (message: Signal<T>) => void
export type InternalSubscriptionItem<T> = {
  key: string
  eventName: string
  callback: InternalSubscriberCallback<T>
  lastTriggerTime: number
  isPersistent: boolean
}
export type InternalSubscriberCallback<T = InternalEmitterPayload> = (payload: T) => void | Promise<void>
export type InternalSubscriberByModelsCallback<T = InternalEmitterPayload> = (
  payload: T,
  model: typeof BaseModel,
) => void | Promise<void>
export type InternalUnmountSubscriberCallback = () => void | Promise<void>
export type InternalSubscriptions<T> = { [key: string]: InternalSubscriptionItem<T> }
export type InternalEmitterPayload = {
  [key: string]: string | string[] | boolean | number | Record<string, any>
}
export enum ModelEventType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  PRELOADED = 'PRELOADED',
  BULK_UPDATE = 'BULK_UPDATE',
  BULK_DELETE = 'BULK_DELETE',
  RELOAD = 'RELOAD',
}

export type ModelEmitterPayload = {
  ids: string[]
  entityId?: string
  eventType: ModelEventType
  [key: string]: any
}

export const isModelEventType = (event: unknown): event is ModelEmitterPayload => {
  if (!isRecordUnknown(event)) return false
  if (!('eventType' in event)) return false
  if (!('ids' in event)) return false

  if (!Object.values(ModelEventType).includes(event.eventType as ModelEventType)) return false
  return Array.isArray(event.ids)
}

export interface Transport {
  // @todo: add events when local storage updated ??
  get<T, P extends Record<string, unknown> = Record<string, any>>(key: string, data?: P): Promise<TransportResponse<T>>
  getFile<P extends Dict = Dict<any>>(key: string, data?: P): Promise<BlobResponse>
  delete(key: string, config?: TransportConfig): Promise<EmptyResponse>
  post<R = CreatedEntityData, T = unknown>(
    key: string,
    data?: T,
    config?: TransportConfig,
  ): Promise<TransportResponse<R>>
  put<B>(key: string, data?: B): Promise<UpdatedEntityResponse>
  patch<B>(key: string, data?: B): Promise<unknown>
  getPathSeparator?(): string
}
export interface TransportConfig {
  [key: string]: any
}
export interface TransportResponse<T = unknown> {
  data: T
  status?: number
}

export interface Transportable<T> {
  getRequest(id: string): Promise<TransportResponse<T>>
  putRequest(toUpdate: any): Promise<UpdatedEntityResponse>
  postRequest(toCreate: any): Promise<CreatedEntityResponse>
  deleteRequest(id: string, config?: any): Promise<EmptyResponse>
}

export type TmAxiosResponse<T> = Pick<AxiosResponse<T>, 'status' | 'headers' | 'data'>

export type HttpInterceptorResponseError<R> = Omit<AxiosError<R>, 'request' | 'response'> & {
  response: TmAxiosResponse<R>
}

// T - response data type
// R - response error data type
export interface HttpInterceptor<T = any, R = any> {
  request?: (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>
  requestError?: (error: any) => any
  response?: (value: AxiosResponse<T>) => AxiosResponse<T> | Promise<AxiosResponse<T>>
  responseError?: (error: HttpInterceptorResponseError<R>) => any
}

export type FormSubscriptionPayload = {
  data: TpFormData
  response: CreatedEntityResponse
  queryParams: Record<string, string>
}

export type UploadProgressEvent = Pick<ProgressEvent, 'loaded' | 'total'>
export type OnUploadProgress = (progressEvent: UploadProgressEvent) => void

export const socketIOStatusChangeEventName = 'SocketIOStatusChangeEvent'
export type SocketIOStatusChangeEventPayload = boolean

export enum BroadcastEvent {
  NewPrimaryTab = 'NewPrimaryTab',
  CloseBrowserTab = 'CloseBrowserTab',
  ChangePrimaryTab = 'ChangePrimaryTab',
  VoiceCallStart = 'VoiceCallStart',
  VoiceCallConnected = 'VoiceCallConnected',
  VoiceCallRinging = 'VoiceCallRinging',
  VoiceCallHangup = 'VoiceCallHangup',
  VoiceCallMute = 'VoiceCallMute',
  VoiceCallBusy = 'VoiceCallBusy',
  VoiceCallCollapse = 'VoiceCallCollapse',
  UserLoggedIn = 'UserLoggedIn',
  TasksViewChange = 'TasksViewChange',
  UserLoggedOut = 'UserLoggedOut',
}

export type VoiceCallBroadcastPayload = {
  callType: VoiceCallType
  phone: string
  phoneCounterparty: string
  muted: boolean
  duration?: number
}

export type TaskBoardViewPayload = {
  view: TaskBoardView
  boardId: string
}

type PickProperties<T> = { [K in keyof T]: K }[keyof T]
type BroadcastEventKeys = PickProperties<typeof BroadcastEvent>
type ValidateKeys<K, T extends [keyof T] extends [K] ? ([K] extends [keyof T] ? unknown : never) : never> = T

export interface IUserLoggedInEvent {
  isLogged: boolean
  userId: string | null
}

export type BroadcastEventMap = ValidateKeys<
  BroadcastEventKeys,
  {
    NewPrimaryTab: null
    CloseBrowserTab: null
    ChangePrimaryTab: null
    VoiceCallStart: VoiceCallBroadcastPayload
    VoiceCallConnected: VoiceCallBroadcastPayload
    VoiceCallRinging: VoiceCallBroadcastPayload
    VoiceCallHangup: null
    VoiceCallBusy: null
    VoiceCallMute: boolean
    VoiceCallCollapse: boolean
    UserLoggedIn: IUserLoggedInEvent
    TasksViewChange: TaskBoardViewPayload
    UserLoggedOut: null
  }
>

export type BroadcastCallback<T extends BroadcastEventKeys> = (data: BroadcastEventMap[T]) => void

export type EventToPayloadAbstractMap = Record<string, unknown> // <EventType, EventPayload>

export type ServerEventsUnionFromMap<EventMap extends EventToPayloadAbstractMap> = {
  [K in keyof EventMap]: ServerEventBase<K, EventMap[K]>
}[keyof EventMap]

export type SocketEventsUnionFromMap<EventMap extends EventToPayloadAbstractMap> = {
  [K in keyof EventMap]: SocketEventBase<K, EventMap[K]>
}[keyof EventMap]

export type HandlerOfAllEvents<EventMap extends EventToPayloadAbstractMap> = (
  payload: ServerEventsUnionFromMap<EventMap>,
) => unknown

export interface ITypedWebSocketService<EventMap extends EventToPayloadAbstractMap = EventToPayloadAbstractMap> {
  connect(): void | Promise<void>
  disconnect(): void
  isConnected(): boolean
  addEventListener(channel: string, handler: HandlerOfAllEvents<EventMap>): void
  removeEventListener(channel: string, handler: HandlerOfAllEvents<EventMap>): void
  removeAllEventListener(channel: string): void
  addDefaultChannelListener(handler: HandlerOfAllEvents<EventMap>): void
  removeDefaultChannelListener(handler: HandlerOfAllEvents<EventMap>): void
  removeAllDefaultChannelListener(): void
  publish(channel: string, data: unknown): Promise<void>
  addStatusChangeListener(handler: (isConnected: boolean) => void): void
  removeStatusChangeListener(handler: (isConnected: boolean) => void): void
}

export interface IWebSocketService {
  connect(): void | Promise<void>
  disconnect(): void
  isConnected(): boolean
  addEventListener(channel: string, handler: (...args: unknown[]) => unknown): void
  removeEventListener(channel: string, handler: (...args: any[]) => unknown): void
  removeAllEventListener(channel: string): void
  addDefaultChannelListener(handler: (...args: unknown[]) => unknown): void
  removeDefaultChannelListener(handler: (...args: any[]) => unknown): void
  removeAllDefaultChannelListener(): void
  publish(channel: string, data: unknown): Promise<void>
  addStatusChangeListener(handler: (isConnected: boolean) => void): void
  removeStatusChangeListener(handler: (isConnected: boolean) => void): void
}

export type SocketTokenGettingCallbackParams = { isTokenRefreshing?: boolean }
export type SocketTokenGettingCallback = (params: SocketTokenGettingCallbackParams) => Promise<CentrifugeTokensResponse>

export const getWorkspaceChannelName = (parentId: string) => {
  return `workspace_${parentId}`
}

export type PinnedContactWS = PinnedContact & { id: number; entityId: number }

import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

enum SMS_SURVEYS_SERVICES {
  SmsSurveyDetailsPreloaderService,
  SmsSurveyEditNameFormService,
  SmsSurveyScheduleFormService,
  SmsSurveyQuestionFormService,
  SmsSurveyRecipientsFormService,
  SmsSurveyQuestionDelayFormService,
  SmsSurveyReplyFormService,
  CreateSmsSurveyFormService,
  SmsSurveyFinalMessageFormService,
  SmsSurveysService,
  SmsSurveyDetailsService,
  SmsSurveyRepliesService,
  SmsSurveyRecipientsService,
  SmsSurveyRecipientsCountriesColumnService,
  SmsSurveyRecipientsCountriesGridable,
  SmsSurveyNodesService,
  SmsSurveyRecipientCountryService,
  SmsSurveysColumnServiceFactory,
  SmsSurveyRepliesColumnServiceFactory,
  SmsSurveyRepliesGridable,
  SmsSurveyRepliesFilterServiceFactory,
  SmsSurveyRecipientsCountriesFilterServiceFactory,
  SmsSurveyFlowResolver,
  SmsSurveyDetailsStatusGuardResolver,
  SmsSurveysRepository,
  SmsSurveyDetailsRepository,
  SmsSurveyRepliesRepository,
  SmsSurveyNodesWithRowsRepository,
  SmsSurveyNodesRepository,
  SmsSurveyRecipientCountryRepository,
  SmsSurveyNodesTreeService,
  SmsSurveyNodesTreeRepository,
  SmsSurveyNodeTreeResolver,
}

export type SmsSurveysServices = keyof typeof SMS_SURVEYS_SERVICES

export const SMS_SURVEYS_SERVICE_TYPES: Record<SmsSurveysServices, symbol> = makeServicesTypes<SmsSurveysServices>(
  enumKeysToArray<SmsSurveysServices>(SMS_SURVEYS_SERVICES),
)

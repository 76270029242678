import { isUndefined } from 'lodash-es'
import { computed, type MaybeRef, unref } from '@/composition/vue/compositionApi'

export const useSortedByOrder = <T extends { id?: string }>(
  order: MaybeRef<Record<string, number>>,
  items: MaybeRef<T[]>,
) => {
  return computed(() => {
    const orderRaw = unref(order)
    const itemsRaw = unref(items)
    if (!Array.isArray(itemsRaw)) {
      return []
    }

    return itemsRaw.toSorted((a, b) => {
      if (isUndefined(a.id) || isUndefined(b.id)) {
        return 0
      }

      return orderRaw[a.id] - orderRaw[b.id]
    })
  })
}

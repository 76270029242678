import { injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import UiSettings from '@/data/models/domain/UiSettings'
import type UiSettingsRepository from '@/data/repositories/domain/uiSettingsRepository'
import type { ModelRaw } from '@/types'

@DomainSettings({
  model: UiSettings,
})
@injectable()
export default class UiSettingsService extends DomainBaseService<UiSettingsRepository> {
  public async fetchSettings() {
    return this.getDomainRepository().fetchSettings()
  }

  public updateSettings(settings: Partial<ModelRaw<UiSettings>>) {
    return this.getDomainRepository().updateSettings(settings)
  }
}

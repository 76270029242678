import { inject, injectable } from 'inversify'
import type { CleanUpable, CleanUpTriggerTask } from '@/services/cleanUp/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type PreloaderManager from '@/services/preloaders/preloaderManager'

@injectable()
export default class AutocompleteCleanUper implements CleanUpable {
  constructor(@inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager) {}

  public trigger(tasks: CleanUpTriggerTask[]): void {
    tasks.forEach(({ model }) => {
      const preloader = this.preloaderManager.getAutocompletePreloader(model)
      preloader.cleanUp(model)
    })
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  SubAccountsPermissionsService,
  SubAccountPermissionsRepository,
  PermissionsResolverService,
  PermissionsSubscriptionResolverService,
}

export type SubAccountsBaseServices = keyof typeof SERVICES

export const SUB_ACCOUNTS_BASE_SERVICE_TYPES: Record<SubAccountsBaseServices, symbol> =
  makeServicesTypes<SubAccountsBaseServices>(enumKeysToArray<SubAccountsBaseServices>(SERVICES))

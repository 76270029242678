import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import TableRecoveryData from '@/data/models/tables/TableRecoveryData'
import type { LowLevelInsertBody } from '@/services/vuex/types'

@RepoSettings<Endpoint>({
  model: TableRecoveryData,
})
@injectable()
export default class TableRecoveryDataRepository extends OrmApiRepository<TableRecoveryData> {
  public insertOne(item: LowLevelInsertBody<TableRecoveryData>) {
    this.insertOrUpdate([item])
  }
}

<template>
  <div class="details-table">
    <slot>
      <details-table-item
        v-for="item in items"
        :key="kebabCase(item.label)"
        :label="item.label"
        :label-size="labelSize"
        :label-align-center="labelAlignCenter"
        :cell-align-center="cellAlignCenter"
        :left-class="item.leftClass || leftClass"
        :right-class="item.rightClass || rightClass"
        :preserve-whitespace="item.preserveWhitespace"
        :fixed-height="item.fixedHeight"
      >
        <slot
          :name="item.slot || kebabCase(item.label)"
          v-bind="item"
        >
          {{ item.value }}
        </slot>
        <template v-slot:label>
          <slot name="label" />
        </template>
      </details-table-item>
    </slot>
  </div>
</template>

<script lang="ts">
import { kebabCase } from 'lodash-es'
import { defineComponent, toRef } from '@/composition/vue/compositionApi'
import type { PropType } from '@/composition/vue/compositionApi'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import type { DetailsTableItemType, SizeProp } from '@/components/shared/types'
import { useDetailsTableLabelSize } from '@/composition/detailsTable'

export default defineComponent({
  components: { DetailsTableItem },
  props: {
    labelSize: {
      type: String as SizeProp<'' | 'large'>,
    },
    labelAlignCenter: {
      type: Boolean,
    },
    cellAlignCenter: {
      type: Boolean,
    },
    leftClass: {
      type: String,
    },
    rightClass: {
      type: String,
    },
    items: {
      type: Array as PropType<DetailsTableItemType[]>,
    },
  },
  setup(props) {
    useDetailsTableLabelSize().provide(toRef(props, 'labelSize'))

    return {
      kebabCase,
    }
  },
})
</script>

<style lang="scss" scoped>
.details-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid $purple100;
  border-radius: $border-radius-md;
  box-shadow: 0 1px 2px 0 #1018280d;
  overflow: hidden;
}
</style>

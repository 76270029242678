<template>
  <i18n-t
    :keypath="keypath"
    :plural="count"
    :count="count"
    :tag="tag"
  >
    <template
      v-for="{ name, camelCaseName } in slotNames"
      v-slot:[camelCaseName]
    >
      <slot :name="name" />
    </template>
  </i18n-t>
</template>

<script lang="ts">
import { camelCase } from 'lodash-es'
import { computed, defineComponent, type PropType } from '@/composition/vue/compositionApi'
import type { TranslationKey } from '@/services/types'

export default defineComponent({
  props: {
    keypath: {
      type: String as PropType<TranslationKey | string>,
      required: true,
    },
    count: {
      type: Number,
    },
    tag: {
      type: String,
    },
  },
  setup(_, context) {
    const slotNames = computed(() => {
      const names = Object.keys(context.slots)
      return names.map((name) => ({
        name,
        camelCaseName: camelCase(name),
      }))
    })

    return {
      slotNames,
    }
  },
})
</script>

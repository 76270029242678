import type BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import type { FormHook, FormHookType } from '@/services/forms/types'
import type BaseForm from '@/services/forms/baseForm'

interface RootBaseFormConfig {
  name: string
}

export default class RootBaseForm {
  public config: RootBaseFormConfig

  public childrens: BaseForm<BaseFieldModel>[] = []

  public hash: number

  public hooks: Record<FormHookType, FormHook[]> = {
    beforeEach: [],
    afterEach: [],
    change: [],
  }

  constructor(config: RootBaseFormConfig) {
    this.config = config
    this.hash = Math.random()
  }

  public addChild(child: BaseForm<BaseFieldModel>, id: string) {
    this.childrens = this.childrens.filter((c) => c.id !== id)
    child.parent = this
    child.id = id
    this.childrens.push(child)
  }

  public toObject() {
    return {
      name: this.config.name,
      childrens: this.childrens.map((c) => c.toObject()),
    }
  }

  public toArray(): BaseForm<BaseFieldModel>[] {
    return this.flatten(this.childrens)
  }

  protected flatten(childrens: BaseForm<BaseFieldModel>[]): BaseForm<BaseFieldModel>[] {
    if (childrens.length === 0) {
      return []
    }
    return [
      ...childrens,
      ...childrens.reduce((acc: BaseForm<BaseFieldModel>[], c) => {
        acc = [...acc, ...this.flatten(c.childrens)]
        return acc
      }, []),
    ]
  }
}

import { ServiceGroups } from '@/config/types'
import type { Service } from '@/config/types'
import type { RegisteredServices } from '@/core/container/types'

export interface IServiceManager {
  addService(service: Service, group?: ServiceGroups): void
}

export const ServiceManagerMap: Record<ServiceGroups, RegisteredServices> = {
  [ServiceGroups.DOMAINS]: 'DomainManagerService',
  [ServiceGroups.REPOSITORIES]: 'EntityManager',
  [ServiceGroups.REPOSITORIES_STORE]: 'EntityManager',
  [ServiceGroups.REPOSITORIES_MOCK]: 'EntityManager',
  [ServiceGroups.BULK]: 'BulkManager',
  [ServiceGroups.PRELOADERS]: 'PreloaderManager',
  [ServiceGroups.PRELOADERS_TABLE]: 'PreloaderManager',
  [ServiceGroups.PRELOADERS_AUTOCOMPLETE]: 'PreloaderManager',
  [ServiceGroups.RESOLVERS]: 'ResolverManager',
  [ServiceGroups.VALIDATION_REGISTRATOR]: 'ValidationService',
  [ServiceGroups.HTTP_INTERCEPTOR]: 'HttpInterceptorManager',
  [ServiceGroups.TITLER]: 'TitlerManager',
  [ServiceGroups.WRAPPERS]: 'WrapperManagerService',
  [ServiceGroups.CLEANUPER_TABLE]: 'CleanUpManager',
  [ServiceGroups.CLEANUPER_AUTOCOMPLETE]: 'CleanUpManager',
  [ServiceGroups.FORM]: 'FormManager',
  [ServiceGroups.FILTER_SERVICES]: 'TableFilterServiceManager',
  [ServiceGroups.SEARCHER_SERVICES]: 'TableSearcherManager',
  [ServiceGroups.SORTER_SERVICES]: 'TableSorterManager',
  [ServiceGroups.GROUPER_SERVICES]: 'TableGrouperManager',
  [ServiceGroups.PAGINATOR_SERVICES]: 'TablePaginatorManager',
  [ServiceGroups.COLUMN_SERVICES]: 'TableColumnManager',
  [ServiceGroups.FACETER]: 'FacetManager',
}

import type { RegisteredServices } from '@/core/container/types'
import {
  baseReportingHistoricalTableFilters,
  baseReportingHistoricalTableSchemaFilters,
} from '@/services/domain/reporting/common/types'
import type { LineChartOptions } from '@/components/shared/charts/lineChart/types'
import { styleVars } from '@/constants/styleVariables'
import { defaultFontFamily } from '@/utils/string/consts'

export const reportingCallsFilters = baseReportingHistoricalTableFilters

export const reportingCallsSchemaFilters = baseReportingHistoricalTableSchemaFilters

export const ReportingCallsTableId = 'reportingCallsTableId' as RegisteredServices

export const reportingCallsChartTypes = ['calls', 'talksTime', 'costs'] as const

export type ReportingCallsChartType = (typeof reportingCallsChartTypes)[number]

export const defaultReportingChartOptions: LineChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      bodyAlign: 'center',
      displayColors: false,
    },
    title: {
      display: true,
      padding: 36,
      font: {
        size: 16,
        lineHeight: 1.25,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        weight: 600,
        family: defaultFontFamily,
      },
      color: styleVars.gray7,
    },
  },
  elements: {
    line: {
      borderWidth: 2,
      borderColor: styleVars.chartColorBlue,
    },
    point: {
      pointStyle: 'circle',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pointHoverBorderColor: '#fff',
      pointBorderWidth: 0,
      pointRadius: 5,
      pointHoverBorderWidth: 0,
      pointHoverRadius: 5,
      pointBackgroundColor: styleVars.chartColorBlue,
    },
  },
  scales: {
    y: {
      position: 'left',
      grid: {
        color: styleVars.gray3,
        drawTicks: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: styleVars.gray5,
        padding: 12,
        align: 'end',
        maxTicksLimit: 10,
        font: {
          size: 12,
          style: 'normal',
          weight: '600',
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      offset: true,
      ticks: {
        color: styleVars.gray5,
        maxTicksLimit: 20,
        padding: 12,
        maxRotation: 0,
        minRotation: 0,
        font: {
          size: 12,
          style: 'normal',
          weight: '600',
        },
      },
    },
  },
}

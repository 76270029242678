import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type RouterService from '@/services/route/routerService'
import type BillingService from '@/services/domain/billing/billingService'
import type ByocRouteHelperService from '@/services/domain/byoc/byocRouteHelperService'

@injectable()
export default class ByocSubscribeResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.BillingService) protected readonly billingService: BillingService,
    @inject(SERVICE_TYPES.ByocRouteHelperService) protected readonly byocRouteHelperService: ByocRouteHelperService,
  ) {}

  public async resolve() {
    await this.billingService.getSettings()
    if (this.billingService.needByocSubscribe()) return

    const byocMainRoute = this.byocRouteHelperService.getMainRoute()
    await this.routerService.replace(byocMainRoute)
  }
}

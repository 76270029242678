import { AttrField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type SenderSource from '@/data/models/domain/SenderSource'
import type { ByocProvider } from '@/data/models/domain/services/byoc/types'

@OrmModel(modelNames.USER_NUMBERS)
export default class UserNumbers extends BaseModel {
  @AttrField()
  public dedicated: SenderSource[]

  @AttrField()
  public shared: SenderSource[]

  @AttrField()
  public userNumbers: SenderSource[]

  @AttrField()
  public senderIds: SenderSource[]

  @AttrField()
  public byoc: Record<ByocProvider, SenderSource[]>
}

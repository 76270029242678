import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import type { ClickToTextFormComposed } from '@/services/domain/services/forms/clickToText/types'
import FormDetails from '@/data/models/domain/services/forms/FormDetails'
import type { PhoneType } from '@/services/types'

@OrmModel(modelNames.FORM_CLICK_TO_TEXT_DETAILS)
export default class FormClickToTextDetails extends FormDetails<ClickToTextFormComposed> {
  @StringField()
  public phone: string

  @StringField()
  public phoneType: PhoneType
}

import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import SenderIdPrice from '@/data/models/domain/senderId/SenderIdPrice'
import type SenderIdPriceRepository from '@/data/repositories/domain/services/senderIdPriceRepository'

@DomainSettings({
  model: SenderIdPrice,
})
@injectable()
export default class SenderIdPriceService extends DomainBaseService<SenderIdPriceRepository> {
  public fillPriceDetails() {
    return this.getDomainRepository().fillPriceDetails()
  }

  public getPriceDetails() {
    return this.getDomainRepository().getPriceDetails()
  }
}

import type { CustomErrorRule } from '@/core/error/transport/types'
import { EndpointsEnum } from '@/services/endpoints'
import { TmAuthEmailVerificationResendError } from '@/core/error/transport/auth/TmAuthEmailVerificationResendError'

export const emailVerificationResendErrorRule: CustomErrorRule = {
  klass: TmAuthEmailVerificationResendError,
  endpoint: EndpointsEnum.registrationEmailResend,
  methods: ['post'],
  predicateFn: (error) => {
    return Boolean('availableToResendAt' in error.response.data && error.response.data.availableToResendAt)
  },
}

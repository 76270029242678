import { AttrField, BelongsToField, BooleanField, OrmModel, StringField } from 'vuex-orm-decorators'

import BaseModel from '@/data/models/BaseModel'

import User from '@/data/models/domain/User'
import type { AutomationRuleLogsResponseType } from '@/services/domain/services/automationRule/types'
import type AutomationRuleStatus from '@/data/models/domain/services/automationRule/AutomationRuleStatus'

import { modelNames } from '@/data/models/types'

@OrmModel(modelNames.AUTOMATION_RULE)
export default class AutomationRule extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @StringField()
  public userId: string

  @BelongsToField(User, 'userId')
  public user: User

  @AttrField([])
  public keywords: string[]

  @StringField()
  public responseType: AutomationRuleLogsResponseType

  @StringField()
  public status: AutomationRuleStatus

  @BooleanField()
  public isDeleted: boolean
}

import type { BaseTableInterface } from '@/core/tables/baseTableInterface'
import { get } from '@/core/container/container'
import { computed } from '@/composition/vue/compositionApi'
import type { RegisteredServices } from '@/core/container/types'
import type { ColumnLoader, DefaultColumn } from '@/core/tables/types'
import { tableCellLoaderWidth } from '@/core/tables/types'
import { TM_CHECKBOX_LOADER_CLASS } from '@/composition/tables'
import type { UseTableSkeletonOptions } from '@/composition/table/types/useTableSkeletonOptions'
import { isNonEmptyArray } from '@/utils/array'

export const useTableSkeleton = (serviceId: RegisteredServices, opts: UseTableSkeletonOptions) => {
  const { hideCheckboxes = false, hasActions = true, rowsToDisplay } = opts

  const baseTable = get<BaseTableInterface>(serviceId)
  const isInit = computed(() => baseTable.isInit())

  const headerColumns = computed<DefaultColumn[]>(() => {
    const columnService = baseTable.getColumns()

    const actualColumns = columnService.getVisibleColumns()
    if (isNonEmptyArray(actualColumns)) {
      return actualColumns
    }

    return Object.entries(baseTable.getColumns().getInitColumns())
      .filter(([_, column]) => column.visibleInPreview !== false)
      .map(([columnName, column]) => {
        return {
          label: `tableColumns.${columnName}`, // This must stand before the spread operator to avoid overwriting the label
          columnName,
          ...column,
        } satisfies DefaultColumn
      })
      .toSorted((a, b) => a.columnOrder - b.columnOrder)
  })

  const columns = computed<ColumnLoader[]>(() => {
    const cols: ColumnLoader[] = []
    if (!hideCheckboxes) {
      cols.push({
        loaderWidth: tableCellLoaderWidth.checkbox,
        loaderType: 'rect',
        loaderClass: TM_CHECKBOX_LOADER_CLASS,
      })
    }

    if (headerColumns.value.length) {
      headerColumns.value
        .map((col) => {
          col.loaderWidth = col.loaderWidth ?? col.minWidth
          col.dynamicLoaderWidth = true

          return col
        })
        .forEach((col) => {
          cols.push(col)
        })
    }

    if (hasActions) {
      cols.push({ loaderWidth: tableCellLoaderWidth.actions })
    }

    return cols
  })

  const rows = computed<number>(() => {
    if (rowsToDisplay !== undefined) {
      return rowsToDisplay
    }

    const tableRows =
      isInit.value && baseTable.getCurrentPageRowsIds().length
        ? baseTable.getCurrentPageRowsIds().length
        : baseTable.getPagination().getPerPage()

    return Math.min(tableRows, 20)
  })

  return {
    tableCellLoaderWidth,
    headerColumns,
    columns,
    rows,
  }
}

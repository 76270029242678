import { injectable } from 'inversify'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import { TmIncorrectContractError } from '@/core/error/tmIncorrectContractError'
import type BaseModel from '@/data/models/BaseModel'
import type { ModelRaw } from '@/types'
import { TmRepositoryError } from '@/core/error/tmRepositoryError'
import type { LowLevelUpdateBody } from '@/services/vuex/types'
import type { Endpoint } from '@/services/endpoints'
import type { ModelRelationArray } from '@/services/domain/types'

@injectable()
export default abstract class OrmApiSettingsRepository<
  T extends BaseModel = BaseModel,
  U extends Record<string, any> = Record<string, any>,
> extends OrmApiRepository<T> {
  protected readonly id = '1'

  public getId() {
    return this.id
  }

  public async getSettings(withRelations: ModelRelationArray<T> = []): Promise<T | null> {
    const { single } = this.settings()
    const path = this.getPathIfExists(single)

    const response = await this.getApiSource().get<ModelRaw<T>>(path)
    if (!response) {
      throw new TmIncorrectContractError('Response data expected')
    }

    if (!response.data) return null

    await this.insertOrUpdate([{ ...response.data, id: this.id }] as LowLevelUpdateBody<T>[])
    return this.findSettings(withRelations)
  }

  public findSettings(withRelations: ModelRelationArray<T> = []) {
    return this.query().with(withRelations).find(this.id)
  }

  public hasSettings(withRelations: ModelRelationArray<T> = []) {
    return !!this.findSettings(withRelations)
  }

  public getSettingsIfNeed() {
    const settings = this.findSettings()
    return settings ?? this.getSettings()
  }

  public async fetchUpdateSettings(settings: U) {
    const { single, update } = this.settings()
    const path = this.getPathIfExists(update || single)

    await this.getApiSource().put(path, settings)

    this.updateSettings(settings)
  }

  public updateSettings(settings: U) {
    const updateData = this.getUpdateData(settings)
    this.update([updateData])
  }

  public patchRepoSettings(patch: Partial<U>) {
    const settings = this.findSettings()
    if (!settings) {
      throw new TmRepositoryError('Settings not found')
    }
    this.updateSettings({ ...settings, ...patch } as U)
  }

  public deleteSettings() {
    this.delete([this.id])
  }

  protected getUpdateData(settings: Record<string, unknown>) {
    return { ...settings, id: this.id } as LowLevelUpdateBody<T>
  }

  protected getPathIfExists(endpoint?: Endpoint) {
    if (!endpoint) {
      throw new TmRepositoryError('You must define valid property in settings()')
    }
    return this.endpointsService.getPath(endpoint)
  }
}

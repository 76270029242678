import { type FilterOperationEnum, isFilterOperation, type Operation } from '@/services/types'
import { isRecordUnknown } from '@/utils/typeGuards'

export const RANGE_DELIMITER = '::'

export type TRangeFilterValueString<T extends string = string> =
  | `${Operation}${typeof RANGE_DELIMITER}${T}${typeof RANGE_DELIMITER}${Operation}${typeof RANGE_DELIMITER}${T}`
  | `${Operation}${typeof RANGE_DELIMITER}${T}${typeof RANGE_DELIMITER}${typeof RANGE_DELIMITER}`
  | `${typeof RANGE_DELIMITER}${typeof RANGE_DELIMITER}${Operation}${typeof RANGE_DELIMITER}${T}`
  | `${FilterOperationEnum.isEmpty}${typeof RANGE_DELIMITER}${'0' | '1'}`

export type TFilterRangeFull<T extends string> = {
  start: T
  startOperation: Operation
  end: T
  endOperation: Operation
}
export type TFilterRangeStart<T extends string> = Pick<TFilterRangeFull<T>, 'start' | 'startOperation'>
export type TFilterRangeEnd<T extends string> = Pick<TFilterRangeFull<T>, 'end' | 'endOperation'>

export type TFilterRange<T extends string = string> =
  | TFilterRangeFull<T>
  | (TFilterRangeStart<T> & { [K in keyof TFilterRangeEnd<T>]: undefined })
  | (TFilterRangeEnd<T> & { [K in keyof TFilterRangeStart<T>]: undefined })

export const isTFilterRangeStart = (value: unknown): value is TFilterRangeStart<string> => {
  return (
    isRecordUnknown(value) &&
    'start' in value &&
    (typeof value.start === 'string' || typeof value.start === 'number') &&
    'startOperation' in value &&
    isFilterOperation(value.startOperation)
  )
}

export const isTFilterRangeEnd = (value: unknown): value is TFilterRangeEnd<string> => {
  return (
    isRecordUnknown(value) &&
    'end' in value &&
    (typeof value.end === 'string' || typeof value.end === 'number') &&
    'endOperation' in value &&
    isFilterOperation(value.endOperation)
  )
}

export const isTFilterRangeFull = (value: unknown): value is TFilterRangeFull<string> => {
  return isTFilterRangeStart(value) && isTFilterRangeEnd(value)
}

export const isTFilterRange = (value: unknown): value is TFilterRange => {
  return isTFilterRangeStart(value) || isTFilterRangeEnd(value)
}

export enum FilterLastMessageSource {
  All = 'all',
  Agent = 'agent',
  Customer = 'customer',
}

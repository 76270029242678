import type { CountryCode } from 'libphonenumber-js'
import type OAuthSignUp from '@/data/models/OAuthSignUp'
import type { Nullable } from '@/types'

export type SignupApiPayload = {
  firstName: string
  lastName: string
  email: string
  password: string
  country: string
}

export type SignupApiPostPayload = SignupApiPayload & {
  utmParams?: UtmLabelsPayload
}

export type SignupByInvitePayload = Pick<SignupApiPayload, 'firstName' | 'lastName' | 'password'> & {
  token: string
}

export type SignupByInviteSSOPayload = Pick<SignupApiPayload, 'firstName' | 'lastName'> & {
  token: string
  email: string
}

export type SignUpViaOAuthPayload = Pick<OAuthSignUp, 'token' | 'firstName' | 'lastName' | 'username'> & {
  countryId: CountryCode
}

export type RawTokens = {
  token: string
  username: string
  countryId: string
}

export type LoginTfaResponse = {
  code: number
  message: string
  errors: any
  question: string
  tfaId: string
  phone: string
  email: string
}

export type LoginResponse = RawTokens | LoginTfaResponse

export type LoginSSOPayload = {
  email: string
}

export type LoginSSOResponse = {
  authUrl: string
}

export type LoginBody = {
  appName: string
  username: string
  password?: string
  tfaCode?: string
  tfaId?: string
  // @todo: CAR-10096, remove extendedTfa
  extendedTfa: boolean
}

interface LoginRequestBodyBase {
  username: string
}

export interface LoginRequestBodyPassword extends LoginRequestBodyBase {
  password: string
}

export interface LoginRequestBodyTfaCode extends LoginRequestBodyBase {
  addToWhitelist: boolean
  tfaCode: string
  isBackup?: boolean
}

export interface LoginRequestBodyTfaCodeAndId extends LoginRequestBodyTfaCode {
  tfaId: string
}

export type LoginRequestBody = LoginRequestBodyPassword | LoginRequestBodyTfaCode | LoginRequestBodyTfaCodeAndId

export type TfaAdditionalData = {
  question?: string
  tfaId?: string
  phone?: string
  email?: string
  tfaToken?: string
}

export type TfaAdditionalDataWithMessage = TfaAdditionalData & {
  message: string
}

export type TfaAdditionalDataWithUserId = TfaAdditionalDataWithMessage & {
  userId: number
}

export type VerificationEmailResendPayload = {
  userId: string
  email: string
  newEmail?: string
}

export type VerificationEmailResendAvailability = {
  availableAt: number // unix timestamp
}

export type RegistrationConfirmationResponse = {
  token: string
  username: string
  disallowedRules: string[]
  expires?: string
  minVersions?: {
    ios: string
    android: string
    desktop: string
  }
  countryId: string
}

export type UserVat = {
  vatNumber: string
}

export type Address = {
  street: string
  area: string
  state: string
  city: string
  zip: string
  countryCode: string
  countryName: string
}

export interface LoginFormScheme {
  username: string
  password: string
}

export interface EmailCodeFormScheme {
  username: string
  tfaCode: string
  addToWhitelist: boolean
}

export interface SmsCodeFormScheme {
  tfaCode: string
  addToWhitelist: boolean
}

export interface BackupCodeFormScheme {
  tfaCode: string
}

export interface UtmLabelsPayload {
  source: Nullable<string>
  medium: Nullable<string>
  campaign: Nullable<string>
  term: Nullable<string>
  content: Nullable<string>
  gclid: Nullable<string>
}

export const LOGIN_INCORRECT_CREDENTIALS_MARK = 'username or password is incorrect'

export const REDIRECT_AFTER_LOGIN_KEY = 'tm::redirectAfterLoginKey'

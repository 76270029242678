import type { HttpCodeToErrorMap } from '@/core/error/transport/types'
import { TmRedirectError } from '@/core/error/transport/tmRedirectError'
import { TmApiValidationError } from '@/core/error/transport/tmApiValidationError'
import { TmApiAuthentificationError } from '@/core/error/transport/tmApiAuthentificationError'
import { TmApiPaymentRequiredError } from '@/core/error/transport/tmApiPaymentRequiredError'
import { TmApiAccessError } from '@/core/error/transport/tmApiAccessError'
import { TmApiNotFoundError } from '@/core/error/transport/tmApiNotFoundError'
import { TmApiUserBlockedError } from '@/core/error/transport/tmApiUserBlockedError'
import { TmApiConflictError } from '@/core/error/transport/tmApiConflictError'
import { TmApiGoneError } from '@/core/error/transport/tmApiGoneError'
import { TmApiTooLargeError } from '@/core/error/transport/tmApiTooLargeError'
import { TmApiExpectationFailedError } from '@/core/error/transport/tmApiExpectationFailedError'
import { TmApiLockedError } from '@/core/error/transport/tmApiLockedError'
import { TmApiPreconditionRequiredError } from '@/core/error/transport/tmApiPreconditionRequiredError'
import { TmApiRetryAfterError } from '@/core/error/transport/tmApiRetryAfterError'
import { TmApiByocAuthentificationError } from '@/core/error/transport/tmApiByocAuthentificationError'
import { TmApiServerError } from '@/core/error/transport/tmApiServerError'
import { emailVerificationResendErrorRule } from '@/services/transport/errorsProcessing/rules/emailVerificationResendErrorRule'
import { authSignupForbiddenErrorRule } from '@/services/transport/errorsProcessing/rules/authSignupForbiddenErrorRule'
import {
  emailIsAvailableForRegistrationCaptchaIsInvalidErrorRule,
  emailIsAvailableForRegistrationCaptchaIsInvalidErrorRule400,
  emailIsAvailableForRegistrationNeedVerifyEmail,
  emailIsForbiddenForRegistrationErrorRule,
} from '@/services/transport/errorsProcessing/rules/emailIsAvailableForRegistrationErrorRules'
import { needVerifyEmailErrorRule } from '@/services/transport/errorsProcessing/rules/needVerifyEmailOnLoginErrorRule'
import { phoneVerificationIncorrectCodeErrorRule } from '@/services/transport/errorsProcessing/rules/phoneVerificationIncorrectCodeErrorRule'
import { authNeedTFASmsConfirmationRule } from '@/services/transport/errorsProcessing/rules/authNeedTFASmsConfirmationRule'
import { authNeedTFAEmailConfirmationRule } from '@/services/transport/errorsProcessing/rules/authNeedTFAEmailConfirmationRule'
import { authAccountClosedErrorRule } from '@/services/transport/errorsProcessing/rules/authAccountClosedErrorRule'
import { authAccountIsForPersonalUseErrorRule } from '@/services/transport/errorsProcessing/rules/authAccountIsForPersonalUseErrorRule'
import { authSubAccountClosedErrorRule } from '@/services/transport/errorsProcessing/rules/authSubAccountClosedErrorRule'
import { authVerificationEmailCodeInvalidRule } from '@/services/transport/errorsProcessing/rules/authVerificationEmailCodeInvalidRule'
import { authTFAVerificationInvalidCodeRule } from '@/services/transport/errorsProcessing/rules/authTFAVerificationInvalidCodeRule'
import { registrationUserDeniedErrorRule } from '@/services/transport/errorsProcessing/rules/registrationUserDeniedErrorRule'
import { reopenAccountNeedTFAEmailConfirmationRule } from '@/services/transport/errorsProcessing/rules/reopenAccountNeedTFAEmailConfirmationRule'
import { reopenAccountNeedTFASmsConfirmationRule } from '@/services/transport/errorsProcessing/rules/reopenAccountNeedTFASmsConfirmationRule'
import { oauthSubAccountClosedErrorRule } from '@/services/transport/errorsProcessing/rules/oauth/oauthSubAccountClosedErrorRule'
import { oauthAccountIsForPersonalUseErrorRule } from '@/services/transport/errorsProcessing/rules/oauth/oauthAccountIsForPersonalUseErrorRule'
import { oauthAccountClosedErrorRule } from '@/services/transport/errorsProcessing/rules/oauth/oauthAccountClosedErrorRule'
import { ssoLoginValidOnlyErrorRule } from '@/services/transport/errorsProcessing/rules/ssoLoginValidOnlyErrorRule'
import { notEnoughBalanceErrorRule } from '@/services/transport/errorsProcessing/rules/notEnoughBalanceErrorRule'
import { planExpiredErrorRule } from '@/services/transport/errorsProcessing/rules/planExpiredErrorRule'
import { numberAlreadyUseErrorRule } from '@/services/transport/errorsProcessing/rules/numberAlreadyUseErrorRule'
import { senderIdMarkAsPendingErrorRule } from '@/services/transport/errorsProcessing/rules/senderIdMarkAsPendingErrorRule'
import { tasksStageNotFoundErrorRule } from '@/services/transport/errorsProcessing/rules/tasksStageNotFoundErrorRule'
import { tasksBoardNotFoundErrorRule } from '@/services/transport/errorsProcessing/rules/tasksBoardNotFoundErrorRule'
import { tasksTaskNotFoundErrorRule } from '@/services/transport/errorsProcessing/rules/tasksTaskNotFoundErrorRule'
import { tokenIsLockedErrorRule } from '@/services/transport/errorsProcessing/rules/tokenIsLockedErrorRule'
import { whatsAppFlowErrorRule } from '@/services/transport/errorsProcessing/rules/whatsAppFlowError'
import {
  stripeInvalidBillingDetailsGenerateLink,
  stripeInvalidBillingDetailsPaymentLink,
  stripeInvalidBillingDetailsPlanChange,
} from '@/services/transport/errorsProcessing/rules/stripeInvalidBillingDetails'
import { taskChecklistNotFoundErrorRule } from '@/services/transport/errorsProcessing/rules/taskChecklistNotFoundErrorRule'
import { taskChecklistItemNotFoundErrorRule } from '@/services/transport/errorsProcessing/rules/taskChecklistItemNotFoundErrorRule'
import { authCaptchaNotProvidedErrorRule } from '@/services/transport/errorsProcessing/rules/authCaptchaNotProvidedErrorRule'
import { authTooManyRequestsErrorRule } from '@/services/transport/errorsProcessing/rules/authTooManyRequestsErrorRule'
import { TmApiPreconditionFailedError } from '@/core/error/transport/tmApiPreconditionFailedError'
import { tasksCommentNotFoundErrorRule } from '@/services/transport/errorsProcessing/rules/taskCommentNotFoundErrorRule'
import { emailVerificationResendNeedCaptchaErrorRule } from '@/services/transport/errorsProcessing/rules/emailVerificationResendNeedCaptchaErrorRule'
import { oauth2FARequiredErrorRule } from '@/services/transport/errorsProcessing/rules/oauth/oauth2FARequiredErrorRule'
import { fileUploadMaxFileSizeErrorRule } from '@/services/transport/errorsProcessing/rules/fileUploadMaxFileSizeErrorRule'
import { dealsPipelineNotFoundErrorRule } from '@/services/transport/errorsProcessing/rules/dealsPipelineNotFoundErrorRule'
import { dealsStageNotFoundErrorRule } from '@/services/transport/errorsProcessing/rules/dealsStageNotFoundErrorRule'
import { dealsDealNotFoundErrorRule } from '@/services/transport/errorsProcessing/rules/dealsDealNotFoundErrorRule'
import { dealActivityNotFoundErrorRule } from '@/services/transport/errorsProcessing/rules/dealActivityNotFoundErrorRule'
import { dealActivityItemNotFoundErrorRule } from '@/services/transport/errorsProcessing/rules/dealActivityItemNotFoundErrorRule'
import { apiAuthenticationSilencedErrorRule } from '@/services/transport/errorsProcessing/rules/apiAuthenticationSilencedErrorRule'
import { privacyPolicyAlreadyAcceptedErrorRule } from '@/services/transport/errorsProcessing/rules/privacyPolicyAlreadyAcceptedErrorRule'
import { phoneNumberAlreadyVerifiedErrorRule } from '@/services/transport/errorsProcessing/rules/phoneNumberAlreadyVerifiedErrorRule'
import { authNeedTFATotpConfirmationRule } from '@/services/transport/errorsProcessing/rules/authNeedTFATotpConfirmationRule'
import { oauth2FATotpRequiredErrorRule } from '@/services/transport/errorsProcessing/rules/oauth/oauth2FATotpRequiredErrorRule'

/* eslint-disable @typescript-eslint/naming-convention */
export const errorsCustomRulesMap: HttpCodeToErrorMap = {
  303: { default: TmRedirectError },
  400: {
    default: TmApiValidationError,
    customRules: [
      emailIsAvailableForRegistrationCaptchaIsInvalidErrorRule400,
      phoneVerificationIncorrectCodeErrorRule,
      authVerificationEmailCodeInvalidRule,
      authTFAVerificationInvalidCodeRule,
      registrationUserDeniedErrorRule,
      numberAlreadyUseErrorRule,
      notEnoughBalanceErrorRule,
      senderIdMarkAsPendingErrorRule,
      whatsAppFlowErrorRule,
      fileUploadMaxFileSizeErrorRule,
      privacyPolicyAlreadyAcceptedErrorRule,
      phoneNumberAlreadyVerifiedErrorRule,
    ],
  },
  401: {
    default: TmApiAuthentificationError,
    customRules: [apiAuthenticationSilencedErrorRule],
  },
  402: {
    default: TmApiPaymentRequiredError,
    customRules: [planExpiredErrorRule],
  },
  403: {
    default: TmApiAccessError,
    customRules: [
      authAccountClosedErrorRule,
      emailVerificationResendErrorRule,
      authSignupForbiddenErrorRule,
      emailIsForbiddenForRegistrationErrorRule,
      authAccountIsForPersonalUseErrorRule,
      authSubAccountClosedErrorRule,
      oauthSubAccountClosedErrorRule,
      oauthAccountIsForPersonalUseErrorRule,
      oauthAccountClosedErrorRule,
      ssoLoginValidOnlyErrorRule,
      tokenIsLockedErrorRule,
    ],
  },
  404: {
    default: TmApiNotFoundError,
    customRules: [
      tasksStageNotFoundErrorRule,
      tasksBoardNotFoundErrorRule,
      tasksTaskNotFoundErrorRule,
      taskChecklistNotFoundErrorRule,
      taskChecklistItemNotFoundErrorRule,
      tasksCommentNotFoundErrorRule,
      dealsPipelineNotFoundErrorRule,
      dealsStageNotFoundErrorRule,
      dealsDealNotFoundErrorRule,
      dealActivityNotFoundErrorRule,
      dealActivityItemNotFoundErrorRule,
    ],
  },
  406: { default: TmApiUserBlockedError },
  409: { default: TmApiConflictError },
  410: { default: TmApiGoneError },
  412: {
    default: TmApiPreconditionFailedError,
  },
  413: { default: TmApiTooLargeError },
  417: { default: TmApiExpectationFailedError },
  423: {
    default: TmApiLockedError,
    customRules: [
      authNeedTFAEmailConfirmationRule,
      reopenAccountNeedTFAEmailConfirmationRule,
      //
    ],
  },
  428: {
    default: TmApiPreconditionRequiredError,
    customRules: [
      emailIsAvailableForRegistrationNeedVerifyEmail,
      needVerifyEmailErrorRule,
      authNeedTFASmsConfirmationRule,
      authNeedTFATotpConfirmationRule,
      reopenAccountNeedTFASmsConfirmationRule,
      stripeInvalidBillingDetailsPaymentLink,
      stripeInvalidBillingDetailsGenerateLink,
      stripeInvalidBillingDetailsPlanChange,
      oauth2FARequiredErrorRule,
      oauth2FATotpRequiredErrorRule,
    ],
  },
  429: {
    default: TmApiRetryAfterError,
    customRules: [
      emailVerificationResendNeedCaptchaErrorRule,
      emailIsAvailableForRegistrationCaptchaIsInvalidErrorRule,
      authTooManyRequestsErrorRule, // order is important (covered with tests)
      authCaptchaNotProvidedErrorRule,
    ],
  },
  430: { default: TmApiByocAuthentificationError },
  500: { default: TmApiServerError },
  501: { default: TmApiServerError },
  502: { default: TmApiServerError },
  503: { default: TmApiServerError },
}

import type { RouteRecordRaw } from 'vue-router'
import type { RegisteredServices } from '@/core/container/types'
import type { TollFreeRequestResolverParams } from '@/services/resolvers/domain/regulations/tenDlcTollFreeRequestResolver'
import PassThrough from '@/components/layout/PassThrough.vue'
import { AppModule } from '@/config/types'
import { ChatSettingsAliasedRoutes, TenDlcTollFreeRoutes } from '@/routes/types'

const routerKeyId = 'id'

interface IRequestResolver<T> {
  service: RegisteredServices
  params: T
}

const tenDlcTollFreeRequestResolver: IRequestResolver<TollFreeRequestResolverParams> = {
  service: 'TenDlcTollFreeRequestResolver',
  params: {
    routerKeyId,
  },
}

export const getTenDlcTollFreeRoutes = (): RouteRecordRaw[] => [
  {
    name: TenDlcTollFreeRoutes.base,
    path: 'compliance',
    component: () => import('@/components/containers/SinglePageContainer.vue'),
    meta: {
      parent: (route) => (route.path.startsWith(ChatSettingsAliasedRoutes.complianceRoot) ? 'user.chats' : undefined),
      resolvers: [tenDlcTollFreeRequestResolver],
      modules: [AppModule.TenDlcTollFree, AppModule.TenDlcTollFreeRequest],
      permission: 'senderSettings',
    },
    alias: ChatSettingsAliasedRoutes.complianceRoot,
    children: [
      {
        name: TenDlcTollFreeRoutes.chooseNumber,
        path: 'choose-number',
        meta: { tabTitle: 'pageTabTitle.usaFlow.chooseNumber' },
        component: () => import('@/components/views/tenDlcTollFree/TenDlcTollFreeChooseNumber.vue'),
        props: (route) => ({
          phone: route.params.phone,
          isTypeTollFree: !route.query.typeTollFree || route.query.typeTollFree === 'true',
          country: route.query.country,
        }),
      },
      {
        name: TenDlcTollFreeRoutes.tenDlcBase,
        path: '10dlc',
        component: PassThrough,
        meta: {
          accessControlGroups: ['UsersWorkflowNotUserRoleStrategy'],
        },
        children: [
          {
            name: 'usaFlow.tenDlc.info',
            path: `info/:${routerKeyId}`,
            meta: { tabTitle: 'pageTabTitle.usaFlow.tenDlc.info' },
            component: () => import('@/components/views/tenDlcTollFree/TenDlcInfo.vue'),
            props: (route) => ({
              id: route.params[routerKeyId],
            }),
          },
          {
            name: TenDlcTollFreeRoutes.tenDlcRegisterBrand,
            path: `register-brand/:${routerKeyId}`,
            meta: { tabTitle: 'pageTabTitle.usaFlow.tenDlc.registerBrand' },
            component: () => import('@/components/views/tenDlcTollFree/TenDlcRegisterBrand.vue'),
            props: (route) => ({
              id: route.params[routerKeyId],
            }),
          },
          {
            name: TenDlcTollFreeRoutes.tenDlcRegisterCampaign,
            path: `register-campaign/:${routerKeyId}`,
            meta: { tabTitle: 'pageTabTitle.usaFlow.tenDlc.registerCampaign' },
            component: () => import('@/components/views/tenDlcTollFree/TenDlcRegisterCampaign.vue'),
            props: (route) => ({
              id: route.params[routerKeyId],
            }),
          },
          {
            name: TenDlcTollFreeRoutes.tenDlcSelectCampaign,
            path: `select-campaign/:${routerKeyId}`,
            meta: { tabTitle: 'pageTabTitle.usaFlow.tenDlc.selectCampaign' },
            component: () => import('@/components/views/tenDlcTollFree/TenDlcSelectCampaign.vue'),
            props: (route) => ({
              id: route.params[routerKeyId],
            }),
          },
          {
            name: TenDlcTollFreeRoutes.tenDlcSummary,
            path: `summary/:${routerKeyId}`,
            meta: { tabTitle: 'pageTabTitle.usaFlow.tenDlc.summary' },
            component: () => import('@/components/views/tenDlcTollFree/TenDlcSummary.vue'),
            props: (route) => ({
              id: route.params[routerKeyId],
            }),
          },
          {
            name: TenDlcTollFreeRoutes.tenDlcSuccess,
            path: 'success',
            meta: { tabTitle: 'pageTabTitle.usaFlow.tenDlc.success' },
            component: () => import('@/components/views/tenDlcTollFree/TenDlcSuccess.vue'),
          },
          {
            name: TenDlcTollFreeRoutes.tenDlcSmsPrivacyPolicy,
            path: 'sms-privacy-policy',
            meta: {
              tabTitle: 'tenDlcSmsPrivacyPolicy.pageTitle',
            },
            component: () => import('@/components/views/services/tenDlc/examples/TenDlcSmsPrivacyPolicy.vue'),
          },
          {
            name: TenDlcTollFreeRoutes.tenDlcSmsTerms,
            path: 'sms-terms',
            meta: {
              tabTitle: 'tenDlcSmsTerms.pageTitle',
            },
            component: () => import('@/components/views/services/tenDlc/examples/TenDlcSmsTerms.vue'),
          },
        ],
      },
      {
        name: TenDlcTollFreeRoutes.tollFreeBase,
        path: 'toll-free',
        component: PassThrough,
        meta: {
          accessControlGroups: ['UsersWorkflowNotUserRoleStrategy'],
        },
        children: [
          {
            name: 'usaFlow.tollFree.info',
            path: `info/:${routerKeyId}`,
            meta: { tabTitle: 'pageTabTitle.usaFlow.tollFree.info' },
            component: () => import('@/components/views/tenDlcTollFree/TollFreeInfo.vue'),
            props: (route) => ({
              id: route.params[routerKeyId],
            }),
          },
          {
            name: TenDlcTollFreeRoutes.tollFreeRegisterBrand,
            path: `register-brand/:${routerKeyId}`,
            meta: { tabTitle: 'pageTabTitle.usaFlow.tollFree.registerBrand' },
            component: () => import('@/components/views/tenDlcTollFree/TollFreeRegisterBrand.vue'),
            props: (route) => ({
              id: route.params[routerKeyId],
            }),
          },
          {
            name: TenDlcTollFreeRoutes.tollFreeRegisterCampaign,
            path: `register-campaign/:${routerKeyId}`,
            meta: { tabTitle: 'pageTabTitle.usaFlow.tollFree.registerCampaign' },
            component: () => import('@/components/views/tenDlcTollFree/TollFreeRegisterCampaign.vue'),
            props: (route) => ({
              id: route.params[routerKeyId],
            }),
          },
          {
            name: TenDlcTollFreeRoutes.tollFreeSummary,
            path: `summary/:${routerKeyId}`,
            meta: { tabTitle: 'pageTabTitle.usaFlow.tollFree.summary' },
            component: () => import('@/components/views/tenDlcTollFree/TollFreeSummary.vue'),
            props: (route) => ({
              id: route.params[routerKeyId],
            }),
          },
          {
            name: TenDlcTollFreeRoutes.tollFreeSuccess,
            path: 'success',
            meta: { tabTitle: 'pageTabTitle.usaFlow.tollFree.success' },
            component: () => import('@/components/views/tenDlcTollFree/TollFreeSuccess.vue'),
          },
        ],
      },
    ],
  },
]

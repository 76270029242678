import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  BuyNumberFormService,
}

export type BuyNumberServices = keyof typeof SERVICES

export const BUY_NUMBER_SERVICE_TYPES: Record<BuyNumberServices, symbol> = makeServicesTypes<BuyNumberServices>(
  enumKeysToArray<BuyNumberServices>(SERVICES),
)

import type { RouteRecordRaw } from 'vue-router'
import { ChatSettingsRoutes } from '@/routes/types'
import { AppModule } from '@/config/types'
import FacebookConfig from '@/data/models/domain/messenger/facebook/FacebookConfig'

export const facebookSettingsRoute: RouteRecordRaw = {
  name: `${ChatSettingsRoutes.facebook}.settings`,
  path: '',
  component: () => import('@/components/views/messenger/facebook/FacebookSettings.vue'),
}

export const facebookFlowRoute: RouteRecordRaw = {
  name: `${ChatSettingsRoutes.facebook}.flow`,
  path: 'flow',
  component: () => import('@/components/views/messenger/facebook/FacebookFlow.vue'),
  meta: {
    resolvers: [
      {
        service: 'SingleSettingsModelResolverService',
        params: { model: FacebookConfig },
      },
    ],
  },
}

export const facebookDetailsRoute: RouteRecordRaw = {
  name: `${ChatSettingsRoutes.facebook}.details`,
  path: ':id',
  props: true,
  component: () => import('@/components/views/messenger/facebook/FacebookDetails.vue'),
}

export const facebookRoute: RouteRecordRaw = {
  name: ChatSettingsRoutes.facebook,
  path: 'facebook',
  children: [facebookSettingsRoute, facebookFlowRoute, facebookDetailsRoute],
  redirect: { name: facebookSettingsRoute.name },
  meta: {
    modules: [AppModule.Facebook],
    tabTitle: 'facebook.settings.title',
    resolvers: [
      {
        service: 'ChatFacebookResolver',
      },
    ],
  },
}

<template>
  <div
    class="menu-item"
    :class="{
      'menu-item--dropdown-active': dropdownState,
      'menu-item--highlight-exact-active': highlightExactActive,
      'menu-item--disabled': disabled,
      'menu-item--in-group': inGroup,
      'menu-item--submenu': isSubmenu,
    }"
  >
    <tm-icon
      v-if="showDragIndicator"
      name="drag_indicator"
      class="menu-item__drag show-on-hover"
    />

    <div
      v-if="icon"
      class="menu-item__icon"
    >
      <slot name="icon">
        <tm-icon :name="icon" />
      </slot>
    </div>

    <div
      class="menu-item__text"
      :title="tooltip || title"
    >
      <span>
        {{ title }}
      </span>
    </div>
    <div
      class="menu-item__right-col d-flex"
      :class="{ 'menu-item__right-col--badge': badge }"
    >
      <div
        v-if="badge"
        class="menu-item__badge"
      >
        {{ $t(`badges.${badge}`) }}
      </div>
      <slot name="menu-item-counter">
        <div
          class="menu-item__counter"
          :class="{ 'menu-item__counter_hide': actions, 'menu-item__counter-red': redCounter && number }"
        >
          <span v-if="isLoadingNumber || number === null">&hellip;</span>
          <span v-else-if="isLimitExceeded">{{ formatNumber(number) }}+</span>
          <span v-else-if="number || number === 0">
            {{ formatNumber(number) }}
          </span>
        </div>
      </slot>

      <slot name="append" />

      <tm-icon
        v-if="rightIcon"
        v-bind="rightIcon"
      />

      <div
        v-if="actions"
        class="menu-item-sidebar-menu-actions"
      >
        <tm-sidebar-menu-actions
          v-model:dropdown-state="dropdownState"
          :actions="actions"
          @action-click="$emit('action-click', $event)"
        />
      </div>
    </div>
    <slot name="after-link-content" />
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, type PropType, watch, computed } from '@/composition/vue/compositionApi'
import TmSidebarMenuActions from '@/components/layout/pageContent/sidebar/PageContentSidebarItemActions.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import type {
  PageContentSidebarItemAction,
  PageContentSidebarLink,
} from '@/components/layout/pageContent/sidebar/types'
import { getNumericService } from '@/core/container/ioc'
import type { TmIconType } from '@/components/shared/types'
import type { IconName } from '@/assets/icons/icons'

export default defineComponent({
  components: { TmIcon, TmSidebarMenuActions },
  props: {
    icon: {
      type: String as PropType<IconName>,
    },
    img: {
      type: String,
    },
    rightIcon: {
      type: Object as PropType<TmIconType>,
    },
    title: {
      type: String,
    },
    number: {
      type: Number,
    },
    numberLimit: {
      type: Number,
    },
    isLoadingNumber: {
      type: Boolean,
    },
    actions: {
      type: Array as PropType<PageContentSidebarItemAction[]>,
    },
    tooltip: {
      type: String,
    },
    badge: {
      type: String as PropType<PageContentSidebarLink['badge']>,
    },
    highlightExactActive: {
      type: Boolean,
    },
    showDragIndicator: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    inGroup: {
      type: Boolean,
    },
    isSubmenu: {
      type: Boolean,
    },
    redCounter: {
      type: Boolean,
    },
  },
  emits: {
    'action-click': (_action: PageContentSidebarItemAction) => true,
    'lock-sidebar': (_lock: boolean) => true,
  },
  setup(props, context) {
    const dropdownState = ref(false)
    watch(dropdownState, (value) => context.emit('lock-sidebar', value))

    const numericService = getNumericService()
    const formatNumber = (val?: number) => {
      return val ? numericService.formatNumber(val) : 0
    }

    const isLimitExceeded = computed(() => {
      return props.number && props.numberLimit && props.number > props.numberLimit
    })

    return {
      dropdownState,
      formatNumber,
      isLimitExceeded,
    }
  },
})
</script>

<style lang="scss" scoped>
.menu-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 16px;
  line-height: 20px;
  font-size: $font-size-root;
  font-weight: $semi-bold-font;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  &:not(:hover):not(.menu-item--dropdown-active) {
    .show-on-hover {
      opacity: 0;
    }
    .menu-item-sidebar-menu-actions {
      display: none;
    }
  }
  &:hover:not(.menu-item--disabled, .menu-item--submenu),
  &--dropdown-active {
    background: $page-content-sidebar-item-hover;
    .menu-item__counter_hide {
      display: none;
    }
  }

  &--in-group {
    padding-left: 42px;
  }

  &--disabled {
    cursor: auto;
  }
  &__drag {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: $light;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &--in-group &__drag {
    left: 8px;
  }

  &__icon {
    display: flex;
    margin-right: 8px;
  }

  &__text {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    flex-grow: 1;
    max-width: 100%;
    font-weight: $medium-font;
    white-space: nowrap !important;
    margin-right: 6px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--submenu &__text {
    font-weight: $semi-bold-font;
  }

  &--in-group &__text {
    font-weight: $medium-font;
  }

  &__right-col {
    flex-shrink: 0;

    &--badge {
      width: auto;
    }
  }
  &__counter {
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: $sm-font;
    font-weight: $bold-font;
    color: $gray6;

    &-red {
      justify-content: center;
      height: 16px;
      min-width: 20px;
      line-height: 16px;
      background: $error;
      border-radius: $border-radius-lg;
      color: $white;
      padding: 0 7px;
    }
  }

  &__badge {
    text-transform: uppercase;
    flex-grow: 1;
    font-weight: $bold-font;
    font-size: $xs-font;
    color: $white;
    padding: 0 7px;
    border-radius: 4px;
    background: linear-gradient(90.71deg, #aa41cf 7.36%, #7154cb 50%, #4d60c9 85.2%);
  }
}
.menu-item:has(.menu-item__counter-red) {
  padding-right: 10px;
}
.active-link .menu-item:not(.menu-item--highlight-exact-active),
.router-link-exact-active .menu-item--highlight-exact-active,
.active-item.menu-item--highlight-exact-active {
  background: $page-content-sidebar-item-hover;
  .menu-item__counter:not(.menu-item__counter-red),
  .menu-item__icon,
  .menu-item__text {
    color: $blue;
  }
}

.menu-item-sidebar-menu-actions {
  width: 20px;
}
</style>

import { isObject } from 'lodash-es'

export const isUrl = (text: string): boolean => {
  if (typeof URL.canParse === 'function') {
    return URL.canParse(text)
  }

  try {
    const url = new URL(text)
    return isObject(url)
  } catch (e) {
    return false
  }
}

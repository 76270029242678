<template>
  <a
    :href="href"
    target="_blank"
    class="min-w0"
  >
    <span
      v-if="tag === 'span'"
      class="truncate"
    >
      {{ text }}
    </span>
    <div
      v-else
      class="truncate"
    >
      {{ text }}
    </div>
    <tm-icon
      v-if="withIcon"
      class="link-icon ml-1"
      name="tmi-open-in-new"
      :size="iconSize ?? 'large'"
    />
  </a>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import TmIcon from '@/components/shared/TmIcon.vue'
import type { IconSize, SizeProp } from '@/components/shared/types'

export default defineComponent({
  components: {
    TmIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    withIcon: {
      type: Boolean,
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
    },
    tag: {
      type: String as PropType<'span' | 'div'>,
      default: 'span',
    },
  },
})
</script>
<style lang="scss" scoped>
.link-icon {
  vertical-align: middle;
  transform: translateY(-2px);
}
</style>

import { type TransportName, type Options, Centrifuge } from 'centrifuge'

const buildPathname = (basePathname: string, name: TransportName | 'emulation') => {
  const parts = basePathname.split('/')

  const index = parts.indexOf('connection')

  if (index === -1) {
    return `${basePathname}/${name}`
  }

  const prefixParts = parts.slice(0, index)

  if (name === 'emulation') {
    return [...prefixParts, name].join('/')
  }

  return [...prefixParts, 'connection', name].join('/')
}

export const buildTransportEndpoint = (baseUrl: string, name: TransportName) => {
  const url = new URL(baseUrl)

  if (name !== 'websocket') {
    url.protocol = document.location.protocol
  }

  url.pathname = buildPathname(url.pathname, name)

  return url.toString()
}

export const buildEmulationEndpoint = (baseUrl: string) => {
  const url = new URL(baseUrl)

  url.protocol = document.location.protocol
  url.pathname = buildPathname(url.pathname, 'emulation')

  return url.toString()
}

export const buildTransport = (baseUrl: string, name: TransportName) => {
  return {
    transport: name,
    endpoint: buildTransportEndpoint(baseUrl, name),
  }
}

type CreateCentrifugeOptions = {
  baseUrl: string
  transports: TransportName[]
}

export const createCentrifuge = ({ baseUrl, transports }: CreateCentrifugeOptions) => {
  const endpoints = transports.map((transport) => buildTransport(baseUrl, transport))

  const options: Partial<Options> = {
    emulationEndpoint: transports.includes('http_stream') ? buildEmulationEndpoint(baseUrl) : undefined,
  }

  return new Centrifuge(endpoints, options)
}

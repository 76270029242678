import type { Model } from '@vuex-orm/core'
import type { FormConfig, FormFieldFilterType, FormFieldsType } from '@/services/forms/types'
import { defaultFormState } from '@/services/forms/types'
import type BaseFilterRepository from '@/data/repositories/filters/baseFilterRepository'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import BaseFilterModel from '@/data/models/filters/BaseFilterModel'
import Form from '@/data/models/forms/Form'
import type BaseFormRepository from '@/data/repositories/form/baseFormRepository'
import type { LowLevelUpdateBody } from '@/services/vuex/types'
import { TmFormError } from '@/core/error/tmFormError'
import { getLoggerService } from '@/core/container/ioc'
import type LoggerService from '@/services/loggerService'

export default class BaseFilterForm<T extends FormFieldFilterType> {
  public repository: BaseFilterRepository

  protected form: FormFieldsType<T>

  protected formId: string

  protected formConfig: FormConfig<T>

  protected formRepository: BaseFormRepository

  protected logger: LoggerService

  constructor(formId: string, em: EntityManagerService) {
    this.form = {}
    this.formId = formId

    this.setEm(em)
    this.formRepository.insertOrUpdate([{ id: formId, formState: defaultFormState }])

    this.logger = getLoggerService()
    this.log('Init BaseForm constructor')
  }

  public buildForm(formConfig: FormConfig<T>) {
    if (!formConfig) {
      throw new TmFormError('Form config must not be empty')
    }

    if (formConfig.models) {
      for (const model of formConfig.models) {
        this.form[model.getName()] = model
      }
    }

    this.log('build form')

    return this.form
  }

  public getRawForm(): FormFieldsType<T> {
    return this.form
  }

  public getValueForUpdate(field: Model, value: any, setInitial = false) {
    if (typeof value === 'string') {
      return setInitial ? { value, isApplied: false, initialValue: value } : { value, isApplied: false }
    }
    if (setInitial && value && value.value !== undefined) {
      return { ...value, initialValue: value.value }
    }
    return value
  }

  public setFieldValue(name: string, value: any, setInitial = false) {
    const field: Model = this.getField(name)
    const data: { [key: string]: any } = this.getValueForUpdate(field, value, setInitial)
    this.updateField([
      {
        id: field.$id!.toString(),
        ...data,
      },
    ])
  }

  public setEm(em: EntityManagerService) {
    this.repository = em.getRepository<BaseFilterRepository>(BaseFilterModel)
    this.formRepository = em.getRepository<BaseFormRepository>(Form)
  }

  public populate(data: { [key: string]: any }, setInitial = true) {
    const fields = this.getFields()

    for (const field of fields) {
      const fieldName = field.getName()
      this.setFieldValue(fieldName, data[fieldName], setInitial)
    }
  }

  public getFields(): T[] {
    return Object.values(this.form)
  }

  public destroy() {
    this.log('destroy form')
    this.form = {}

    this.repository.deleteByCondition((field) => field.formId === this.formId)
    this.formRepository.delete([this.formId])
  }

  // Set value with all hooks
  public onInput(name: string, value: any) {
    this.log(`onInput: ${name} ${value}`)
    this.setFieldValue(name, value)
  }

  public getField(name: string): T {
    const field: T = this.form[name]
    if (!field) {
      throw new TmFormError(`Field: ${name} is not added to the form`)
    }

    return this.repository.find(field.id) as T
  }

  public getForm() {
    return this.form
  }

  public getFormId() {
    return this.formId
  }

  protected updateField<M>(changeSet: LowLevelUpdateBody<M>[]) {
    this.repository.update(changeSet)
  }

  protected log(message: string) {
    this.logger.log('forms', message, this.formId)
  }
}

import { inject, injectable } from 'inversify'
import type { HttpInterceptor, InternalErrorResponse, HttpInterceptorResponseError } from '@/services/transport/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type RouterService from '@/services/route/routerService'
import type { TmRoutes } from '@/services/route/routerTypes'
import { TmApiTokenIsLockedError } from '@/core/error/transport/tmApiTokenIsLockedError'

@injectable()
export default class TokenIsLockedInterceptor implements HttpInterceptor<any, InternalErrorResponse> {
  constructor(@inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService) {}

  public async responseError(error: HttpInterceptorResponseError<InternalErrorResponse>) {
    if (error instanceof TmApiTokenIsLockedError) {
      const currentRoute = this.routerService.getCurrentRoute()
      if (currentRoute.name === 'tokenIsLocked') {
        return Promise.reject(error)
      }
      const route: TmRoutes = {
        name: 'tokenIsLocked',
        query: {
          fromUrl: currentRoute.fullPath,
        },
      }
      this.routerService.replace(route)
    }
    return Promise.reject(error)
  }
}

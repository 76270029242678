<template>
  <tm-auto-size-panel-item :disable="disabled">
    <tm-button
      :to="to"
      :title="title"
      :disable="disabled"
      :href="href"
      :color="color"
      :icon-only="iconOnly"
      :flat="flat"
      :no-border="noBorder"
      :size="btnSize"
      :target="target"
      :skeleton="loading"
      :loading="submitLoading"
      :loading-color="submitLoadingColor"
      :loading-title="submitLoadingTitle"
      :class="buttonClass"
      :clicked="clicked"
      @click="$emit('click', $event)"
    >
      <tm-icon
        v-if="icon"
        :name="icon"
        :size="iconSize"
        :left="!iconOnly"
        :rotate="iconRotate"
      />
      <div v-if="!iconOnly">{{ text }}</div>
      <tm-learning-tooltip
        v-if="isLearningTooltip && learningTooltipKey"
        :learning-tooltip-key="learningTooltipKey"
        :tooltip="String(tooltip)"
        :tooltip-learning="String(tooltipLearning)"
      />
      <tm-tooltip
        v-else-if="tooltip"
        :model-value="showTooltip"
        :no-parent-event="manualShowTooltip"
      >
        <span>{{ tooltip }}</span>
      </tm-tooltip>
      <div
        v-if="badge"
        class="tm-auto-size-panel-item-btn__counter"
      >
        {{ badge }}
      </div>
    </tm-button>
    <template v-slot:dropdown-item>
      <tm-dropdown-item
        :title="title"
        :disable="disabled"
        :href="href"
        :icon-rotate="iconRotate"
        :icon="icon"
        :label="text"
        :to="to"
        :loading="loading"
        @click="$emit('click', $event)"
      />
    </template>
  </tm-auto-size-panel-item>
</template>

<script lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import { defineComponent, defineAsyncComponent, type PropType } from '@/composition/vue/compositionApi'

import type { IconName } from '@/assets/icons/icons'

import type { IconSize, SizeProp, ButtonColor, ButtonSize, IconRotate } from '@/components/shared/types'
import TmIcon from '@/components/shared/TmIcon.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAutoSizePanelItem from '@/components/shared/autoSize/panel/TmAutoSizePanelItem.vue'
import { VueLikeClassProp } from '@/components/types'

const TmTooltip = defineAsyncComponent(() => import('@/components/shared/tooltip/TmTooltip.vue'))
const TmDropdownItem = defineAsyncComponent(() => import('@/components/shared/TmDropdownItem.vue'))
const TmLearningTooltip = defineAsyncComponent(() => import('@/components/shared/tooltip/TmLearningTooltip.vue'))

export default defineComponent({
  components: {
    TmLearningTooltip,
    TmIcon,
    TmTooltip,
    TmDropdownItem,
    TmButton,
    TmAutoSizePanelItem,
  },
  props: {
    icon: {
      type: String as PropType<IconName>,
    },
    color: {
      type: String as PropType<ButtonColor>,
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
      default: 'small',
    },
    iconRotate: {
      type: Number as PropType<IconRotate>,
    },
    btnSize: {
      type: String as SizeProp<ButtonSize>,
      default: '',
    },
    flat: {
      type: Boolean,
    },
    noBorder: {
      type: Boolean,
    },
    iconOnly: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    manualShowTooltip: {
      type: Boolean,
    },
    showTooltip: {
      type: Boolean,
    },
    tooltip: {
      type: String,
    },
    href: {
      type: String,
    },
    target: {
      type: String,
    },
    clicked: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    submitLoading: {
      type: Boolean,
    },
    submitLoadingColor: {
      type: String,
    },
    submitLoadingTitle: {
      type: String,
    },
    title: {
      type: String,
    },
    isLearningTooltip: {
      type: Boolean,
    },
    learningTooltipKey: {
      type: String,
    },
    tooltipLearning: {
      type: String,
    },
    badge: {
      type: String,
    },
    buttonClass: VueLikeClassProp,
  },
  emits: {
    click: (_event: PointerEvent) => true,
  },
})
</script>

<style lang="scss" scoped>
.tm-auto-size-panel-item-btn {
  &__counter {
    position: absolute;
    top: -8px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    height: 16px;
    font-size: $sm-font;
    font-weight: $semi-bold-font;
    line-height: 16px;
    text-align: center;
    color: $white;
    border-radius: 8px;
    background: $blue;

    > span {
      padding-bottom: 0.2px;
    }
  }
}
</style>

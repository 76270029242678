import type { RegisteredServices } from '@/core/container/types'
import type { TmWrappers } from '@/wrappers'
import { CallDirection } from '@/types'
import type { IconName } from '@/assets/icons/icons'
import type { CustomFieldSchemaFormData } from '@/services/forms/customFieldSchemaFormService'

export const selectingContactsId: TmWrappers = 'selectingContacts'
export const selectingContactsProgressId: TmWrappers = 'selectingContactsProgress'
export const selectingContactsListsId: TmWrappers = 'selectingContactsLists'
export const selectingSegmentsId: TmWrappers = 'selectingSegments'
export const UnsubscribeContactModal: TmWrappers = 'UnsubscribeContactModal'

export const contactListDeleteModal: TmWrappers = 'contactListDeleteModal'
export const contactListBulkDeleteModal: TmWrappers = 'contactListBulkDeleteModal'
export const ContactListTable = 'ContactListTable' as RegisteredServices
export const ContactListTempDetailsTable = 'ContactListTempDetailsTable' as RegisteredServices
export const ContactsTable = 'ContactTable' as RegisteredServices
export const ListTable = 'ListTable' as RegisteredServices
export const AllContactListTable = 'AllContactListTable' as RegisteredServices
export const ContactImportsTable = 'ContactImportsTable' as RegisteredServices
export const UnsubscribedTable = 'UnsubscribedTable' as RegisteredServices
export const BlockedContactsTableId = 'BlockedContacsTable' as RegisteredServices
export const ListIdRouteParam = 'contactListId'
export const SelectingContactsTable = 'SelectingContactsTable' as RegisteredServices
export const SelectingContactsListsTable = 'SelectingContactsListsTable' as RegisteredServices
export const SegmentsTableId = 'SegmentsTable' as RegisteredServices
export const SelectingSegmentsTable = 'SelectingSegmentsTable' as RegisteredServices
export const customFieldsTable = 'customFieldsTable' as RegisteredServices
export const CustomFieldsSchemaModalId = 'customFieldsSchemaModal' as const
export const CustomFieldsTableIdNew = 'customFieldsTableNew' as const

export const addContactsChooseSourceModal: TmWrappers = 'addContactsChooseSourceModal'

export type CustomFieldSchemaModalParams = {
  editingId?: string
  onSuccessCallback?: (formData: CustomFieldSchemaFormData) => void
}

export enum ContactsActivitiesType {
  call = 'call',
  sent = 'sent',
  received = 'received',
  scheduled = 'scheduled',
}

export const contactsActivitiesVariant = [
  ContactsActivitiesType.scheduled,
  ContactsActivitiesType.sent,
  ContactsActivitiesType.received,
  ContactsActivitiesType.call,
] as const

export const contactsActivitiesCallsVariant = [
  {
    type: CallDirection.OUTBOUND,
    icon: 'tmi-phone-forwarded' satisfies IconName,
    colorClass: 'text-blue',
  },
  {
    type: CallDirection.FORWARDED,
    icon: 'phone_forwarded' satisfies IconName,
    colorClass: 'text-orange',
  },
  {
    type: CallDirection.INBOUND,
    icon: 'phone_callback' satisfies IconName,
    colorClass: 'text-green',
  },
  {
    type: CallDirection.MISSED,
    icon: 'phone_missed' satisfies IconName,
    colorClass: 'text-red',
  },
] as const

import type { TmCountryCode } from '@/services/types'
import type { TenDlcCampaignsUseCasesType } from '@/services/domain/services/regulations/tenDlcCampaigns/types'
import type { NumberFeatures } from '@/data/models/domain/AvailableToBuyNumbers'

interface BaseTenDlcRequestServiceApplyBody<T extends string> {
  type: T
}

interface TenDlcRequestServiceApplyPurchaseBody extends BaseTenDlcRequestServiceApplyBody<'number_purchase'> {
  phone: string
  country: TmCountryCode
  userId: string
}

interface TenDlcRequestServiceApplyInfoBody extends BaseTenDlcRequestServiceApplyBody<'info'> {
  requestId: string
  infoConsent: boolean
}

export type TenDlcRequestServiceApplyBrandUpsertionEntityTypeBody =
  | 'PUBLIC_PROFIT'
  | 'PRIVATE_PROFIT'
  | 'NON_PROFIT'
  | 'GOVERNMENT'

export type TenDlcRequestServiceApplyBrandUpsertionContactJobPositionBody =
  | 'Director'
  | 'GM'
  | 'VP'
  | 'CEO'
  | 'CFO'
  | 'General Counsel'
  | 'Other'

export type ContactJobPositionOption = {
  text: string
  value: TenDlcRequestServiceApplyBrandUpsertionContactJobPositionBody
}

export type TenDlcRequestServiceApplyBrandUpsertionBusinessRegionsOfOperationsBody =
  | 'USA_AND_CANADA'
  | 'AFRICA'
  | 'ASIA'
  | 'EUROPE'
  | 'LATIN_AMERICA'

export type TenDlcRequestServiceApplyBrandUpsertionTaxNumberIdentifierBody = 'EIN' | 'DUNS' | 'CBN' | 'CN' | 'ACN'

export interface TenDlcRequestServiceApplyBrandUpsertionBody
  extends BaseTenDlcRequestServiceApplyBody<'brand_upsertion'> {
  requestId: string
  organizationType: TenDlcTwilioRequestServiceApplyBrandUpsertionEntityTypeBody
  legalStructure: TenDlcTwilioRequestServiceApplyBrandUpsertionLegalStructureBody
  legalName: string
  brandName: string
  taxNumber: string
  taxNumberIdentifier: TenDlcRequestServiceApplyBrandUpsertionTaxNumberIdentifierBody
  taxNumberCountryCode: TmCountryCode
  state: string
  city: string
  street: string
  zipCode: string
  country: string
  industry: string
  website: string
  contactFirstName: string
  contactLastName: string
  contactJobPosition: TenDlcRequestServiceApplyBrandUpsertionContactJobPositionBody
  contactExactJobTitle: string
  contactPhone: string
  contactEmail: string
  companyStockSymbol?: string
  companyStockExchange?: string
  businessRegionsOfOperations: TenDlcRequestServiceApplyBrandUpsertionBusinessRegionsOfOperationsBody[]
  socialProfileLinks?: string[]
}

export interface TenDlcApplyBrandDetailsBody extends BaseTenDlcRequestServiceApplyBody<'brand_org_details'> {
  requestId: string
  organizationType: TenDlcTwilioRequestServiceApplyBrandUpsertionEntityTypeBody
  legalStructure: TenDlcTwilioRequestServiceApplyBrandUpsertionLegalStructureBody
  legalName: string
  brandName: string
  taxNumber: string
  taxNumberIdentifier: TenDlcRequestServiceApplyBrandUpsertionTaxNumberIdentifierBody
  industry: string
  website: string
  businessRegionsOfOperations: TenDlcRequestServiceApplyBrandUpsertionBusinessRegionsOfOperationsBody[]
  companyStockSymbol?: string
  companyStockExchange?: string
  socialProfileLinks?: string[]
}

export interface TenDlcApplyBrandAddressBody extends BaseTenDlcRequestServiceApplyBody<'brand_org_address'> {
  requestId: string
  state: string
  city: string
  street: string
  zipCode: string
  country: string
}

export interface TenDlcApplyBrandContactBody extends BaseTenDlcRequestServiceApplyBody<'brand_contact'> {
  requestId: string
  contactFirstName: string
  contactLastName: string
  contactJobPosition: TenDlcRequestServiceApplyBrandUpsertionContactJobPositionBody
  contactExactJobTitle: string
  contactPhone: string
  contactEmail: string
}

export interface TenDlcRequestServiceApplyEditRequestBody extends BaseTenDlcRequestServiceApplyBody<'edit_request'> {
  requestId: string
}

export interface TenDlcRequestServiceApplyBrandRequestBody extends BaseTenDlcRequestServiceApplyBody<'brand_submit'> {
  requestId: string
}

export interface TenDlcRequestServiceApplySubmissionForReviewBody
  extends BaseTenDlcRequestServiceApplyBody<'submission_for_review'> {
  requestId: string
}

interface TenDlcRequestServiceApplySwitchToCampaignCreationBody
  extends BaseTenDlcRequestServiceApplyBody<'switch_to_campaign_creation'> {
  requestId: string
}

interface TenDlcRequestServiceApplyCampaignSelectionBody
  extends BaseTenDlcRequestServiceApplyBody<'campaign_selection'> {
  requestId: string
  campaignId: string
}

interface TenDlcRequestServiceApplyBackStepActionBody extends BaseTenDlcRequestServiceApplyBody<'back_step_action'> {
  requestId: string
}

export interface TenDlcRequestServiceApplyCampaignUseCaseBody
  extends BaseTenDlcRequestServiceApplyBody<'campaign_use_case'> {
  requestId: string
  useCase: string[]
}

export interface TenDlcRequestServiceApplyCampaignDetailsBody
  extends BaseTenDlcRequestServiceApplyBody<'campaign_details'> {
  requestId: string
  name: string
  description: string
}

export interface TenDlcRequestServiceApplyCampaignSampleMessagesBody
  extends BaseTenDlcRequestServiceApplyBody<'campaign_sample_messages'> {
  requestId: string
  sampleMessages: string[]
  includesLinkInMessageContent: boolean
  includesPhoneNumberInMessageContent: boolean
}

export interface TenDlcRequestServiceApplyCampaignContentAttributesBody
  extends BaseTenDlcRequestServiceApplyBody<'campaign_content_attributes'> {
  requestId: string
  subscriberOptIn: { keyword: string; autoReplyMessage: string; flowDescription: string; screenshotLink: string | null }
  subscriberOptOut: { keyword: string; autoReplyMessage: string }
  subscriberHelp: { keyword: string; autoReplyMessage: string }
}

export interface TenDlcRequestServiceApplyCampaignSubmissionBody
  extends BaseTenDlcRequestServiceApplyBody<'campaign_submission'> {
  requestId: string
}

export interface TenDlcRequestServiceApplyCampaignUpsertBody
  extends BaseTenDlcRequestServiceApplyBody<'campaign_upsert'> {
  campaignId?: string | null
}

export type TenDlcRequestServiceApplyBody =
  | TenDlcRequestServiceApplyPurchaseBody
  | TenDlcRequestServiceApplyInfoBody
  | TenDlcRequestServiceApplyBrandUpsertionBody
  | TenDlcRequestServiceApplyEditRequestBody
  | TenDlcRequestServiceApplyBrandRequestBody
  | TenDlcRequestServiceApplySubmissionForReviewBody
  | TenDlcRequestServiceApplySwitchToCampaignCreationBody
  | TenDlcRequestServiceApplyCampaignSelectionBody
  | TenDlcRequestServiceApplyBackStepActionBody
  | TenDlcRequestServiceApplyCampaignUseCaseBody
  | TenDlcRequestServiceApplyCampaignDetailsBody
  | TenDlcRequestServiceApplyCampaignSampleMessagesBody
  | TenDlcRequestServiceApplyCampaignContentAttributesBody
  | TenDlcRequestServiceApplyCampaignSubmissionBody
  | TenDlcRequestServiceApplyCampaignUpsertBody
  | TenDlcApplyBrandDetailsBody
  | TenDlcApplyBrandAddressBody
  | TenDlcApplyBrandContactBody

export type TenDlcRequestServiceApplyResponseStep =
  | 'introduced'
  | 'number_selection'
  | 'number_activation'
  | 'info'
  | 'number_skip'
  | 'brand_creation'
  | 'brand_edit'
  | 'campaign_creation'
  | 'campaign_edit'
  | 'campaign_selection'
  | 'customer_review'
  | 'support_review'
  | 'provider_review'
  | 'verified'

export interface TenDlcRequestServiceApplyResponse {
  requestId: string
  nextStep: TenDlcRequestServiceApplyResponseStep
  hasBackStep: boolean
  updatedAt: string
}

export interface TenDlcVerticalType {
  id: string
  description: string
  displayName: string
  industryId: string
}

export interface TenDlcStockExchange {
  id: string
  country: TmCountryCode
  description: string
}

// src/Textmagic/ServiceBundle/Enum/TenDlc/Request/TenDlcRequestStatus.php
export enum TenDlcRequestStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  IN_REVIEW = 'IN_REVIEW',
  VERIFIED = 'VERIFIED',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
}

export interface TenDlcCampaignDetailsFormScheme {
  name: string
  description: string
}

export interface TenDlcAttributesFormScheme {
  subscriberOptIn: {
    flowDescription: string
    keyword: string
    autoReplyMessage: string
    // screenshotLink: string
  }
  subscriberOptOut: {
    keyword: string
    autoReplyMessage: string
  }
  subscriberHelp: {
    keyword: string
    autoReplyMessage: string
  }

  optInScreenshotLink: string | null
  optInScreenshot: File | null
}

interface TenDlcRequestCost {
  chargePeriodicity: string
  stepCost: string
  stepName: string
}

export interface TenDlcRequestCostResponse {
  bundle: string | null
  requestCost: TenDlcRequestCost[]
  totalCost: string
}

export interface TenDlcSummary {
  selectedNumber: string | null
  brandName: string
  contactEmail: string
  contactPhone: string
  useCase: TenDlcCampaignsUseCasesType[]
  campaignDescription: string
  optInFlowDescription: string
  sampleMessages: string
  cost: TenDlcRequestCostResponse
  features: NumberFeatures[]
}

export enum TenDlcTwilioRequestStatus {
  draft = 'draft',
  supportReview = 'support_review',
  providerReview = 'provider_review',
  verified = 'verified',
  actionRequired = 'action_required',
}

export enum TenDlcTwilioNumberStatus {
  verified = 'verified',
  failed = 'failed',
  inProgress = 'in_progress',
}

export type TenDlcTwilioRequestServiceApplyBrandUpsertionEntityTypeBody =
  | 'public'
  | 'private'
  | 'government'
  | 'non-profit'

export type TenDlcTwilioRequestServiceApplyBrandUpsertionLegalStructureBody =
  | 'Co-operative'
  | 'Corporation'
  | 'Limited Liability Corporation'
  | 'Non-profit Corporation'
  | 'Partnership'

export enum TenDlcVertical {
  automotive = 'AUTOMOTIVE',
  agriculture = 'AGRICULTURE',
  banking = 'BANKING',
  construction = 'CONSTRUCTION',
  consumer = 'CONSUMER',
  education = 'EDUCATION',
  engineering = 'ENGINEERING',
  energy = 'ENERGY',
  oilAndGas = 'OIL_AND_GAS',
  fastMoving = 'FAST_MOVING_CONSUMER_GOODS',
  financial = 'FINANCIAL',
  fintech = 'FINTECH',
  food = 'FOOD_AND_BEVERAGE',
  government = 'GOVERNMENT',
  healthcare = 'HEALTHCARE',
  hospitality = 'HOSPITALITY',
  insurance = 'INSURANCE',
  legal = 'LEGAL',
  manufacturing = 'MANUFACTURING',
  media = 'MEDIA',
  online = 'ONLINE',
  professional = 'PROFESSIONAL_SERVICES',
  rawMaterials = 'RAW_MATERIALS',
  realEstate = 'REAL_ESTATE',
  religion = 'RELIGION',
  retail = 'RETAIL',
  jewelry = 'JEWELRY',
  technology = 'TECHNOLOGY',
  telecommunications = 'TELECOMMUNICATIONS',
  transportation = 'TRANSPORTATION',
  travel = 'TRAVEL',
  electronics = 'ELECTRONICS',
  nonProfit = 'NOT_FOR_PROFIT',
  empty = '',
}

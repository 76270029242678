import type { RouteRecordRaw } from 'vue-router'

import { TasksRoutes } from '@/routes/user/tasks/types'
import { AppModule } from '@/config/types'

export const getTasksBoardsRoutes = (): RouteRecordRaw[] => [
  {
    name: TasksRoutes.base,
    path: '',
    meta: {
      resolvers: [
        {
          service: 'TaskBoardResolver',
        },
        {
          service: 'FetchAllAccountsResolver',
        },
      ],
      resolverComponent: () => import('@/components/resolvers/TasksBoardResolver.vue'),
    },
    redirect: { name: TasksRoutes.boardBase },
    children: [
      {
        name: TasksRoutes.boardBase,
        path: '',
        meta: {
          resolvers: [
            {
              service: 'TaskRedirectToFirstBoardResolver',
            },
          ],
          resolverComponent: () => import('@/components/resolvers/TasksBoardResolver.vue'),
        },
        component: () => import('@/components/layout/PassThrough.vue'),
      },
      {
        name: TasksRoutes.overview,
        path: 'overview',
        meta: {
          modules: [AppModule.ComingSoon],
        },
        component: () => import('@/components/views/tasks/TasksEmptyOverview.vue'),
      },
      {
        name: TasksRoutes.board,
        path: 'board/:boardId',
        props: true,
        component: () => import('@/components/views/tasks/Board.vue'),
      },
    ],
  },
]

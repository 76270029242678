import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import { ComingSoonRoutes } from '@/routes/types'

export const getComingSoonRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.comingSoon',
    path: 'coming-soon',
    meta: {
      tags: ['COMING_SOON'],
      menu: () => import('@/components/domain/comingSoon/ComingSoonMenu.vue'),
      modules: [AppModule.ComingSoon],
    },
    redirect: { name: ComingSoonRoutes.deals },
    component: () => import('@/components/views/comingSoon/ComingSoon.vue'),
    children: [
      {
        name: ComingSoonRoutes.tickets,
        path: 'tickets',
        meta: {
          tabTitle: 'comingSoon.pageTitle.tickets',
        },
        component: () => import('@/components/views/comingSoon/ComingSoonTickets.vue'),
      },
      {
        name: ComingSoonRoutes.deals,
        path: 'deals',
        meta: {
          tabTitle: 'comingSoon.pageTitle.deals',
        },
        component: () => import('@/components/views/comingSoon/ComingSoonDeals.vue'),
      },
      {
        name: ComingSoonRoutes.liveChat,
        path: 'live-chat',
        meta: {
          tabTitle: 'comingSoon.pageTitle.liveChat',
        },
        component: () => import('@/components/views/comingSoon/ComingSoonLiveChat.vue'),
      },
      {
        name: ComingSoonRoutes.facebook,
        path: 'facebook',
        meta: {
          tabTitle: 'comingSoon.pageTitle.facebook',
          modules: [AppModule.Facebook],
        },
        component: () => import('@/components/views/comingSoon/ComingSoonFacebook.vue'),
      },
      {
        name: ComingSoonRoutes.crm,
        path: 'crm',
        meta: {
          tabTitle: 'comingSoon.pageTitle.crm',
        },
        component: () => import('@/components/views/comingSoon/ComingSoonCrm.vue'),
      },
      {
        name: ComingSoonRoutes.users,
        path: 'users',
        meta: {
          tabTitle: 'comingSoon.pageTitle.users',
        },
        component: () => import('@/components/views/comingSoon/ComingSoonUsers.vue'),
      },
      {
        name: ComingSoonRoutes.tasks,
        path: 'tasks',
        meta: {
          tabTitle: 'comingSoon.pageTitle.tasks',
        },
        component: () => import('@/components/views/comingSoon/ComingSoonTasks.vue'),
      },
    ],
  },
]

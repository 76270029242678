import { injectable } from 'inversify'
import type { Collections } from '@vuex-orm/core'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type BaseModel from '@/data/models/BaseModel'

@injectable()
export default class SmsRepository<T extends BaseModel = BaseModel> extends OrmApiRepository<T> {
  public async fillMessage(id: string): Promise<Collections> {
    const result = await this.getRequest<T>(id)
    return this.insertOrUpdate([result])
  }
}

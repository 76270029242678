import { inject, injectable } from 'inversify'
import getDecorators from 'inversify-inject-decorators'
import type { FilterStrategyInterface } from '@/services/tables/filters/strategies/types/filterStrategyInterface'
import type BaseFilterModel from '@/data/models/filters/BaseFilterModel'
import { FilterOperationEnum, type FilterOperationValue, type Operation } from '@/services/types'
import { SERVICE_TYPES } from '@/core/container/types'
import { TmTableFilterError } from '@/core/error/table/tmTableFilterError'
import {
  isTFilterRange,
  isTFilterRangeEnd,
  isTFilterRangeFull,
  isTFilterRangeStart,
  type TFilterRange,
} from '@/services/filters/types'
import type { BaseFilterStrategy } from '@/services/tables/filters/strategies/baseFilterStrategy'
import type { RangeFilterService } from '@/services/tables/filters/strategies/rangeFilterService'
import type { ParsedFilterType } from '@/services/tables/types'
import RangeFilterModel from '@/data/models/filters/RangeFilterModel'
import type { FilterStrategyGetPopulateDataResult } from '@/services/tables/filters/strategies/types/filterStrategyGetPopulateDataResult'
import type { RelativeDateTimeService } from '@/services/datetime/relativeDateTimeService'
import { isTRelativeDateTimeRange, type TDateTimeRange } from '@/services/datetime/types'
import type DateTimeService from '@/services/dateTimeService'
import { getContainer } from '@/core/container/container'

type DateRangeOperation = Extract<Operation, 'eq' | 'gte' | 'gt' | 'lte' | 'lt' | 'isNull'>

const { lazyInject } = getDecorators(getContainer())

@injectable()
export class RangeFilterStrategy implements FilterStrategyInterface {
  // lazyInject is used because to RelativeDateTimeService is in the dateTime.module
  @lazyInject(SERVICE_TYPES.RelativeDateTimeService)
  protected readonly relativeDateTimeService: RelativeDateTimeService

  // lazyInject is used because to DateTimeService is in the dateTime.module
  @lazyInject(SERVICE_TYPES.DateTimeService)
  protected readonly dateTimeService: DateTimeService

  private _filterValueCache = new WeakMap<{ name: string }, { value: unknown; range: TDateTimeRange }>()

  private _filterKeys: Record<string, { name: string }> = {}

  constructor(
    @inject(SERVICE_TYPES.BaseFilterStrategy) protected readonly baseFilterStrategy: BaseFilterStrategy,
    @inject(SERVICE_TYPES.RangeFilterService) protected readonly rangeFilterService: RangeFilterService,
  ) {}

  public toQueryPart(filter: BaseFilterModel): Partial<FilterOperationValue> {
    const relationField = filter.getRelationField()

    if (filter.operation === 'isEmpty') {
      return this.baseFilterStrategy.fieldDataToQueryPart({
        operation: filter.operation,
        relationField,
        value: filter.value,
      })
    }

    const value = filter.getApiValue()
    const rangeValue = this.rangeFilterService.fromRangeString(value as string)

    ;[rangeValue.startOperation, rangeValue.endOperation].filter(Boolean).forEach((op) => {
      if (!filter.availableOperations.includes(op as Operation)) {
        throw new TmTableFilterError(`Operation "${op}" is not available for filter "${filter.getName()}"`)
      }
    })

    if (isTFilterRangeFull(rangeValue)) {
      return {
        ...this.baseFilterStrategy.fieldDataToQueryPart({
          operation: rangeValue.startOperation,
          relationField,
          value: rangeValue.start,
        }),
        ...this.baseFilterStrategy.fieldDataToQueryPart({
          operation: rangeValue.endOperation,
          relationField,
          value: rangeValue.end,
        }),
      }
    }

    if (isTFilterRangeStart(rangeValue)) {
      return this.baseFilterStrategy.fieldDataToQueryPart({
        operation: rangeValue.startOperation,
        relationField,
        value: rangeValue.start,
      })
    }

    if (isTFilterRangeEnd(rangeValue)) {
      return this.baseFilterStrategy.fieldDataToQueryPart({
        operation: rangeValue.endOperation,
        relationField,
        value: rangeValue.end,
      })
    }

    throw new TmTableFilterError(`Incorrect range value "${value}" for filter "${filter.getName()}"`)
  }

  public getPopulateData(filter: BaseFilterModel, payload: ParsedFilterType[]): FilterStrategyGetPopulateDataResult {
    const rangeStartOperations: Set<Operation> = new Set(['gt', 'gte', 'isNull'])
    const rangeEndOperations: Set<Operation> = new Set(['lt', 'lte'])

    if (!(filter instanceof RangeFilterModel)) {
      return null
    }

    const fiteredPayload = payload.filter((item) => item.name === filter.getName())

    const isEmptyPayload = fiteredPayload.find((item) => item.operation === 'isEmpty' && item.value !== null)

    if (isEmptyPayload) {
      return {
        isApplied: true,
        operation: 'isEmpty',
        innerOperation: 'isEmpty',
        value: isEmptyPayload.value,
        innerValue: isEmptyPayload.value,
        relatedField: isEmptyPayload.relatedField,
      }
    }

    const range = payload
      .filter((item) => item.name === filter.getName())
      .reduce<TFilterRange & { relatedFieldName?: string }>((accum, item) => {
        if (!item.value) {
          return accum
        }

        if (rangeStartOperations.has(item.operation) || rangeEndOperations.has(item.operation)) {
          accum.relatedFieldName = item.relatedField
        }

        // Do not overwrite existing non-empty values in accum
        if (rangeStartOperations.has(item.operation) && !(accum.start && accum.startOperation)) {
          accum.start = item.value as string
          accum.startOperation = item.operation
        }

        // Do not overwrite existing non-empty values in accum
        if (rangeEndOperations.has(item.operation) && !(accum.end && accum.endOperation)) {
          accum.end = item.value as string
          accum.endOperation = item.operation
        }

        // Overwrite existing non-empty values in accum
        if (item.operation === FilterOperationEnum.isEmpty) {
          accum.start = undefined
          accum.startOperation = undefined
          accum.end = undefined
          accum.endOperation = undefined

          accum[FilterOperationEnum.isEmpty] = item.value
        }

        return accum
      }, {} as TFilterRange)

    if (!isTFilterRange(range)) {
      return null
    }

    const rangeValueString = this.rangeFilterService.toRangeString(range)

    return {
      isApplied: true,
      operation: 'eq',
      innerOperation: 'eq',
      value: rangeValueString,
      innerValue: rangeValueString,
      relatedField: range.relatedFieldName ?? '',
    }
  }

  public checkFilterValue(filter: BaseFilterModel, dt: string | Date): boolean {
    const value = filter.getApiValue()
    if (!value) {
      return true
    }
    if (!this.relativeDateTimeService.isValidDate(dt)) {
      return true
    }
    if (!(dt instanceof Date)) {
      dt = this.dateTimeService.toDate(dt)
    }
    let range: TDateTimeRange
    try {
      const cacheKey = this.getCachedFilterKey(filter)
      const cachedValue = this._filterValueCache.get(cacheKey)
      if (value === cachedValue?.value && cachedValue?.range) {
        range = cachedValue.range
      } else {
        const rangeValue = this.rangeFilterService.fromRangeString(value as string)
        if (!isTRelativeDateTimeRange(rangeValue)) {
          return true
        }
        range = this.relativeDateTimeService.getDateRange(rangeValue)
        this._filterValueCache.set(cacheKey, { value, range })
      }
      const startDate = range.start
      const endDate = range.end
      if (!this.checkDate(range.startOperation as DateRangeOperation, dt, startDate)) {
        return false
      }
      if (!this.checkDate(range.endOperation as DateRangeOperation, dt, endDate)) {
        return false
      }
      return true
    } catch (e) {
      return false
    }
  }

  private checkDate(operation: DateRangeOperation, date: Date, rangeDate: Date): boolean {
    if (operation === 'isNull' && !date) {
      return true
    }
    if (operation === 'eq' && date.getTime() !== rangeDate.getTime()) {
      return false
    }
    if (operation === 'gte' && date.getTime() < rangeDate.getTime()) {
      return false
    }
    if (operation === 'gt' && date.getTime() <= rangeDate.getTime()) {
      return false
    }
    if (operation === 'lte' && date.getTime() > rangeDate.getTime()) {
      return false
    }
    if (operation === 'lt' && date.getTime() >= rangeDate.getTime()) {
      return false
    }
    return true
  }

  private getCachedFilterKey(filter: BaseFilterModel): { name: string } {
    if (!this._filterKeys[filter.name]) {
      this._filterKeys[filter.name] = { name: filter.name }
    }
    return this._filterKeys[filter.name]
  }
}

import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

export enum TAGS_SERVICES {
  TagService,
  TagRepository,
}

export type TagsServices = keyof typeof TAGS_SERVICES

export const TAGS_SERVICES_TYPES: Record<TagsServices, symbol> = makeServicesTypes<TagsServices>(
  enumKeysToArray<TagsServices>(TAGS_SERVICES),
)

import { AttrField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import WebWidget from '@/data/models/domain/messages/settings/webWidget/webWidget'
import type {
  WebWidgetDetailsChannelsResponse,
  WebWidgetDetailsChannelSorting,
  WebWidgetDetailsTarget,
} from '@/data/models/domain/messages/settings/webWidget/types'
import type AttachmentFile from '@/data/models/domain/attachmentFile/AttachmentFile'

@OrmModel(modelNames.WEB_WIDGET_DETAILS)
export default class WebWidgetDetails extends WebWidget {
  @StringField()
  public appearance: string // JSON with type WebWidgetDetailsAppearance

  @AttrField(null)
  public channels: WebWidgetDetailsChannelsResponse

  @AttrField(null)
  public channelSorting: WebWidgetDetailsChannelSorting

  @AttrField(null)
  public target: WebWidgetDetailsTarget

  @AttrField(null)
  public logo: AttachmentFile | null
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import SessionStat from '@/data/models/domain/session/SessionStat'

@RepoSettings<Endpoint>({
  model: SessionStat,
  single: 'sessionsIndexStat',
})
@injectable()
export default class SessionStatRepository extends OrmApiRepository<SessionStat> {
  public async getRequest<M = unknown>(id: string) {
    const sessionStat = await super.getRequest<SessionStat>(id)
    sessionStat.id = id
    return sessionStat as M
  }
}

import type { TmNamedRouteRecord } from '@/services/route/types'
import Contact from '@/data/models/domain/Contact'
import type { DomainTitlerParams } from '@/services/route/titlers/types'
import { AppModule } from '@/config/types'
import { CONTACT_ID_PARAM_NAME } from '@/routes/user/contacts/types'
import type { SegmentsForContactResolverServiceParams } from '@/services/domain/contact/segments/resolvers/segmentsForContactResolverService'

export const contactsMyListRoute: TmNamedRouteRecord = {
  name: 'user.contacts.myContacts',
  path: '',
  component: () => import('@/components/views/contacts/myContacts/MyContacts.vue'),
  meta: {
    modules: [AppModule.WhatsApp],
    tabTitle: 'pageTitle.user.contacts.myContacts',
    resolvers: [{ service: 'WhatsAppResolver' }], // Whatsapp column needs to know if we have any whatsapp account
  },
}

export const contactsMyDetailsRoute: TmNamedRouteRecord = {
  name: 'user.contacts.myContact.contactDetails',
  path: `:${CONTACT_ID_PARAM_NAME}`,
  props: (route) => ({
    key: route.params[CONTACT_ID_PARAM_NAME],
    contactId: route.params[CONTACT_ID_PARAM_NAME],
  }),
  meta: {
    titler: {
      service: 'DomainTitlerService',
      params: {
        model: Contact,
        field: 'fullName',
        idParamName: CONTACT_ID_PARAM_NAME,
      } as DomainTitlerParams,
    },
    modules: [
      AppModule.Activity,
      AppModule.SentSmsBase,
      AppModule.ForwardedCalls,
      AppModule.Scheduled,
      AppModule.SenderSettingsCountries,
      AppModule.HistoryReceivedSms,
      AppModule.Chat,
      AppModule.Facebook,
      AppModule.FilteredViews,
      AppModule.Segments,
    ],
    resolverComponent: () => import('@/components/resolvers/AsyncResolver.vue'),
    resolvers: [
      {
        service: 'RedirectableSingleModelResolverService',
        params: {
          model: Contact,
          source: 'router',
          requestParam: 'contactId',
          redirectToIfNotFound: { name: 'user.contacts.myContacts' },
        },
      },
      {
        service: 'SegmentsForContactResolverService',
        params: {
          contactId: {
            paramSource: 'router',
            routerParamName: CONTACT_ID_PARAM_NAME,
          },
          skipResolve: true,
        } satisfies SegmentsForContactResolverServiceParams,
      },
    ],
  },
  component: () => import('@/components/views/contacts/myContacts/MyContactDetails.vue'),
}

export const contactsMyRoute: TmNamedRouteRecord = {
  name: 'user.contacts.myContacts.index',
  path: 'contacts',
  meta: {
    tags: ['CONTACTS_BAR_LIST'],
  },
  redirect: { name: 'user.contacts.myContacts' },
  children: [contactsMyListRoute, contactsMyDetailsRoute],
}

import type { RepositorySettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import SipCall from '@/data/models/domain/SipCall'
import type Invoice from '@/data/models/domain/billing/Invoice'
import type EmailCampaignMessage from '@/data/models/domain/campaign/EmailCampaignMessage'
import type { ModelRaw } from '@/types'
import type EmailCampaignOpen from '@/data/models/domain/campaign/EmailCampaignOpen'

export const sipCallsRepoSettings = {
  model: SipCall,
  single: 'sipCallSingle',
  bulkDeleteEndpoint: 'sipCallsBulkDelete',
  exportEndpoint: 'sipCallExport',
  pdfDownloadEndpoint: 'sipCallPdf',
} as RepositorySettings<Endpoint>

export type InvoiceByProviderSessionLoadingResult =
  | {
      invoice: Invoice
      isDeferred: false
    }
  | {
      invoice: null
      isDeferred: true
    }

interface OutboundEmailDetailsResponseEngagement {
  spam: null | Record<string, never>
  unsubscribe: null | Record<string, never>
  open: null | ModelRaw<EmailCampaignOpen>
  firstClick: null | Record<string, never>
}

export interface OutboundEmailDetailsResponse {
  isPartOfBulkSending: boolean
  engagement: OutboundEmailDetailsResponseEngagement
  outboundEmailId: string
  outboundEmail: ModelRaw<EmailCampaignMessage>
}

import { get, getTableManager } from '@/core/container/ioc'
import type FilterServiceManagerInterface from '@/services/tables/managers/filterServiceManagerInterface'
import type { UseTableBuilderSettings } from '@/composition/tables'
import type { Endpoint } from '@/services/endpoints'

type UseFiltersSettings = Pick<UseTableBuilderSettings, 'tableModelId' | 'filterServiceManager' | 'filterFactory'> & {
  endpoint: Endpoint
}

export const useFilters = (settings: Pick<UseFiltersSettings, 'filterServiceManager'>) =>
  get<FilterServiceManagerInterface>(settings.filterServiceManager ?? 'TableFilterServiceManager')

export const createFilters = (settings: UseFiltersSettings) => {
  const manager = get<FilterServiceManagerInterface>(settings.filterServiceManager ?? 'TableFilterServiceManager')

  if (settings.filterFactory) {
    manager.setFactoryForTable(settings.tableModelId, settings.filterFactory)
  }

  return useFilters(settings)
}

export const setFilterValue = ({
  serviceId,
  name,
  value,
  serviceKey = String(0),
  shouldReplaceState = false,
}: {
  serviceId: string
  name: string
  value: string
  serviceKey?: string
  shouldReplaceState?: boolean
}) => {
  const table = getTableManager().getTable(serviceId)
  const filterService = table.getFilterServiceManager().getServiceForTable(serviceId, serviceKey)
  filterService.setInnerValue(name, value)
  filterService.applyFilterValue(name)
  table.onApplyFilter(shouldReplaceState)
}

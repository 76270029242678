import { NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.PRIMARY_BROWSER_TAB)
export default class PrimaryBrowserTab extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public tabId: string

  @NumberField()
  public timestamp: number
}

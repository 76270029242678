import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { RouterBuilderInterface, TmNavigationGuard } from '@/services/route/types'
import type { RouteLeaveService } from '@/services/route/routeLeaveService'
import type WindowService from '@/services/browser/windowService'

@injectable()
export class RouteLeaveResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.RouteLeaveService) protected readonly routeLeaveService: RouteLeaveService,
    @inject(SERVICE_TYPES.RouterBuilderService) protected readonly routerBuilderService: RouterBuilderInterface,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
  ) {}

  public async resolve() {
    this.routerBuilderService.addBeforeResolveHandler(this.routeEnterHandler.bind(this))
    this.routerBuilderService.addBeforeEachHandler(this.routeLeaveHandler.bind(this))
    this.windowService.self().addEventListener('beforeunload', this.beforeUnloadHandler.bind(this))
  }

  public async unresolve() {
    this.routerBuilderService.removeBeforeResolveHandler(this.routeEnterHandler)
    this.routerBuilderService.removeBeforeEachHandler(this.routeLeaveHandler)
    this.windowService.self().removeEventListener('beforeunload', this.beforeUnloadHandler)
  }

  protected routeEnterHandler(...args: Parameters<TmNavigationGuard>): void {
    this.routeLeaveService.cleanup()
  }

  protected routeLeaveHandler(...args: Parameters<TmNavigationGuard>): void {
    const [, , prevent] = args

    const hasUnsavedChanges = this.routeLeaveService.hasUnsavedChangesOnCurrentRoute()
    const shouldSkipConfirmation = this.routeLeaveService.shouldSkipRouteLeaveConfirmation()
    const message = this.routeLeaveService.getRouteLeaveMessage()

    if (hasUnsavedChanges && !shouldSkipConfirmation) {
      const confirmationResult = this.windowService.self().confirm(message)
      // noinspection PointlessBooleanExpressionJS
      if (confirmationResult === false) {
        prevent()
      }
    }
  }

  protected beforeUnloadHandler(event: BeforeUnloadEvent) {
    const hasUnsavedChanges = this.routeLeaveService.hasUnsavedChangesOnCurrentRoute()
    const shouldSkipConfirmation = this.routeLeaveService.shouldSkipRouteLeaveConfirmation()
    const message = this.routeLeaveService.getRouteLeaveMessage()

    if (hasUnsavedChanges && !shouldSkipConfirmation) {
      event.preventDefault()
      event.returnValue = message
    }
  }
}

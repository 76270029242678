import { AttrField, BooleanField, NumberField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { AvailableByocPlan, ByocPlan } from '@/services/domain/byoc/types'
import type { Currency } from '@/types'

export interface BillingBundle {
  id: number
  bundle: number
  currency: string
  bonus: number
}

export interface BillingMonthlyPlan {
  cost: number
  currency: Currency
  id: number
  messagePrice: number
  name: string
  discountRate: number
}

type BillingActiveMonthlyPlanType = 'simple' | 'professional' | 'connect'

interface BillingActiveMonthlyPlanBase<T extends BillingActiveMonthlyPlanType, R> extends BillingMonthlyPlan {
  type: T
  renewalAt: string
  subscribedAt: string
  messagesCount: R
}

export type BillingActiveMonthlyPlan =
  | BillingActiveMonthlyPlanBase<'simple', null>
  | BillingActiveMonthlyPlanBase<'professional' | 'connect', number>

interface AutoRechargeTrigger {
  bundle: number
  currency: string
}

@OrmModel(modelNames.BILLING)
export default class Billing extends BaseModel {
  @AttrField()
  public activeMonthlyPlan: BillingActiveMonthlyPlan | null

  @AttrField()
  public monthlyPlansList: BillingMonthlyPlan[]

  @BooleanField()
  public autoRechargeDisableAllowed: boolean

  @BooleanField()
  public autoRechargeEnabled: boolean

  @AttrField()
  public autoRechargeTriggerList: AutoRechargeTrigger[]

  @AttrField()
  public priceList: BillingBundle[]

  @AttrField()
  public autoRechargePrice: BillingBundle | null

  @NumberField()
  public autoRechargeTrigger: number

  @NumberField()
  public commonMessagePrice: number

  @NumberField()
  public countryVatRate: number

  @BooleanField()
  public vatExempt: boolean

  @NumberField()
  public paymentBonusRate: number

  @AttrField()
  public byocPlansList: AvailableByocPlan[]

  @AttrField()
  public availableByocPlan: ByocPlan | null

  @BooleanField()
  public countryTaxable: boolean

  public get notByocOutboundMessagePrice() {
    if (this.activeMonthlyPlan && this.activeMonthlyPlan.type !== 'connect') {
      return this.activeMonthlyPlan.messagePrice
    }
    return this.commonMessagePrice
  }
}

export const ipRegexp = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/
export const ipRegexpWithSubnetMask =
  /^((?=\d+\.\d+\.\d+\.\d+(\/[0-9]{1,3})?$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4})(\/([0-9]|[12][0-9]|3[0-2]))?$/

export const ipV6Reqexp =
  /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$|^(?:[a-fA-F0-9]{1,4}:){1,6}:[a-fA-F0-9]{1,4}$|^(?:[a-fA-F0-9]{1,4}:){1,5}(?::[a-fA-F0-9]{1,4}){1,2}$|^(?:[a-fA-F0-9]{1,4}:){1,4}(?::[a-fA-F0-9]{1,4}){1,3}$|^(?:[a-fA-F0-9]{1,4}:){1,3}(?::[a-fA-F0-9]{1,4}){1,4}$|^(?:[a-fA-F0-9]{1,4}:){1,2}(?::[a-fA-F0-9]{1,4}){1,5}$|^[a-fA-F0-9]{1,4}:(?::[a-fA-F0-9]{1,4}){1,6}$|^:(?::[a-fA-F0-9]{1,4}){1,7}$|^::$|^(?:[a-fA-F0-9]{1,4}:){1,7}:$|^(?:[a-fA-F0-9]{1,4}:){6}:[a-fA-F0-9]{1,4}$|^(?:[a-fA-F0-9]{1,4}:){0,4}:((25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})$/
export const ipV6ReqexpWithSubnetMask =
  /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}(?:\/(?:12[0-8]|[1-9]?[0-9]))?$|^(?:[a-fA-F0-9]{1,4}:){1,6}:[a-fA-F0-9]{1,4}(?:\/(?:12[0-8]|[1-9]?[0-9]))?$|^(?:[a-fA-F0-9]{1,4}:){1,5}(?::[a-fA-F0-9]{1,4}){1,2}(?:\/(?:12[0-8]|[1-9]?[0-9]))?$|^(?:[a-fA-F0-9]{1,4}:){1,4}(?::[a-fA-F0-9]{1,4}){1,3}(?:\/(?:12[0-8]|[1-9]?[0-9]))?$|^(?:[a-fA-F0-9]{1,4}:){1,3}(?::[a-fA-F0-9]{1,4}){1,4}(?:\/(?:12[0-8]|[1-9]?[0-9]))?$|^(?:[a-fA-F0-9]{1,4}:){1,2}(?::[a-fA-F0-9]{1,4}){1,5}(?:\/(?:12[0-8]|[1-9]?[0-9]))?$|^[a-fA-F0-9]{1,4}:(?::[a-fA-F0-9]{1,4}){1,6}(?:\/(?:12[0-8]|[1-9]?[0-9]))?$|^:(?::[a-fA-F0-9]{1,4}){1,7}(?:\/(?:12[0-8]|[1-9]?[0-9]))?$|^::$|^(?:[a-fA-F0-9]{1,4}:){1,7}:(?:\/(?:12[0-8]|[1-9]?[0-9]))?$|^(?:[a-fA-F0-9]{1,4}:){6}:[a-fA-F0-9]{1,4}(?:\/(?:12[0-8]|[1-9]?[0-9]))?$|^(?:[a-fA-F0-9]{1,4}:){0,4}:((25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})(?:\/(?:12[0-8]|[1-9]?[0-9]))?$/

export const simpleUrlRegexp =
  /^(https?:\/\/)?[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.[a-zA-Z0-9]{2,}([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/

export const urlAnywhereRegexp =
  /(https?:\/\/)?[a-zA-Z0-9@][a-zA-Z0-9-@]{0,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

export const emailAnywhereRegexp =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

// services covered: bit.ly, goo.gl, tinyurl.com, tiny.cc, lc.chat, is.gd, soo.gd, s2r.co, clicky.me, budurl.com, bc.vc
// shorturl.com, alturl.com, shorturl.at
export const shortUrlServicesRegexp =
  /\b(bit\.ly|goo\.gl|tinyurl\.com|tiny\.cc|lc\.chat|is\.gd|soo\.gd|s2r\.co|clicky\.me|budurl\.com|bc\.vc|shorturl\.com|shorturl\.at|alturl\.com)\b/i

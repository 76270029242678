import type { Nullable } from '@/types'

export const getCookieByName = (name: string): Nullable<string> => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts.length === 2) {
    return parts.pop()?.split(';').shift() || null
  }

  return null
}

export const deleteCookieByName = (name: string): void => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export function setCookieByName(name: string, value: string, days: number) {
  const date = new Date()

  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/'
}

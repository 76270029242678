/* eslint-disable @typescript-eslint/naming-convention */
export const mmsAttachmentFileFormats: Record<string, string | string[]> = {
  '.json': 'application/json',
  '.ogv': 'video/ogg',
  '.oga': 'audio/ogg',
  '.ogx': 'application/ogg',
  '.ogg': 'audio/ogg',
  '.rtf': 'application/rtf',
  '.pdf': 'application/pdf',
  '.zip': ['application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip'],
  '.tar': 'application/x-tar',
  '.xml': 'application/xml',
  '.gz': ['application/gzip', 'application/x-gzip'],
  '.bz2': 'application/x-bzip2',
  '.smil': 'application/smil',
  '.mp4': 'video/mp4',
  '.m4a': 'audio/mp4',
  '.m4p': 'audio/mp4',
  '.m4b': 'audio/mp4',
  '.m4r': 'audio/mp4',
  '.mp3': 'audio/mpeg',
  '.m1a': 'audio/mpeg',
  '.m2a': 'audio/mpeg',
  '.flac': 'audio/flac',
  '.wav': 'audio/wav',
  '.amr': 'audio/amr',
  '.3ga': 'audio/amr',
  '.bmp': 'image/bmp',
  '.dib': 'image/bmp',
  '.gif': 'image/gif',
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.pjpeg': 'image/pjpeg',
  '.png': 'image/png',
  '.svg': 'image/svg+xml',
  '.tiff': 'image/tiff',
  '.webp': 'image/webp',
  '.ico': ['image/x-icon', 'image/vnd.microsoft.icon'],
  '.avi': 'video/avi',
  '.mpg': 'video/mpeg',
  '.mpeg': 'video/mpeg',
  '.wmv': 'video/x-ms-wmv',
  '.flv': 'video/x-flv',
  '.webm': 'video/webm',
  '.quicktime': 'video/quicktime',
}

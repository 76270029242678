<template>
  <div
    ref="root"
    class="tm-auto-size-panel-item"
    :disabled="disable ? true : undefined"
  >
    <slot :name="inDropdown ? 'dropdown-item' : 'default'" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from '@/composition/vue/compositionApi'
import { useInsideDropdown } from '@/composition/dropdown'

export default defineComponent({
  props: {
    disable: {
      type: Boolean,
    },
  },
  setup() {
    const root = ref()
    const inDropdown = ref(false)

    onMounted(() => {
      inDropdown.value = !!useInsideDropdown().inject()
    })

    return {
      root,
      inDropdown,
    }
  },
})
</script>

<style scoped lang="scss">
.tm-auto-size-panel-item + .tm-auto-size-panel-item {
  margin-left: 8px;
  // tm-auto-size-panel-parent = TmAutoSizePanelParentClass
  .tm-auto-size-panel-parent & {
    margin-left: 0;
  }
}
</style>

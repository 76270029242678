<template>
  <component
    :is="isLink && to ? 'router-link' : tag"
    :to="isLink && to"
    :target="isBlank ? '_blank' : undefined"
    class="font-weight-inherit"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'

export default defineComponent({
  props: {
    isLink: {
      type: Boolean,
      default: true,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
    },
    isBlank: {
      type: Boolean,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
})
</script>

import { NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { ScheduledRequestStatus } from '@/components/views/scheduled/types'

@OrmModel(modelNames.SCHEDULED_SHORT_MESSAGE)
export default class ScheduledShortMessage extends BaseModel {
  @StringField()
  public title: string

  @StringField()
  public avatar: string

  @StringField()
  public contactName: string

  @NumberField()
  public recipientsCount: number

  @StringField()
  public type: string

  @StringField()
  public message: string

  @StringField()
  public lastSent: string

  @StringField()
  public nextSend: string

  @StringField()
  public firstOccurrence: string

  @StringField()
  public rrule: string

  @StringField()
  public status: ScheduledRequestStatus
}

import { AttrField, BelongsToField, BooleanField, HasManyByField, OrmModel, StringField } from 'vuex-orm-decorators'

import BaseModel from '@/data/models/BaseModel'
import Country from '@/data/models/domain/Country'
import PhoneNumber from '@/data/models/domain/PhoneNumber'

import User from '@/data/models/domain/User'
import Keyword from '@/data/models/domain/Keyword'
import type {
  AutomationRuleLogsResponseType,
  AutomationRuleScheduleTimeParamItem,
} from '@/services/domain/services/automationRule/types'
import type AutomationRuleStatus from '@/data/models/domain/services/automationRule/AutomationRuleStatus'
import type AutomationRuleAction from '@/data/models/domain/services/automationRule/AutomationRuleAction'
import type { AutomationRuleScheduleTimeType } from '@/data/models/domain/services/automationRule/AutomationRuleAction'

import { modelNames } from '@/data/models/types'

export type AutomationRuleDetailsUserCarrierPhone = {
  phone: string
}

@OrmModel(modelNames.AUTOMATION_RULE_DETAILS)
export default class AutomationRuleDetails extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @StringField()
  public text: string

  @StringField()
  public userId: string

  @BelongsToField(User, 'userId')
  public user: User

  @AttrField([])
  public keywordsIds: string[]

  @HasManyByField(Keyword, 'keywordsIds')
  public keywords: Keyword[]

  @StringField()
  public responseType: AutomationRuleLogsResponseType

  @StringField()
  public status: AutomationRuleStatus

  @AttrField([], (value: number[]) => value?.map((item) => item.toString()))
  public countriesIds: string[]

  @HasManyByField(Country, 'countriesIds')
  public countries: Country[]

  @AttrField([], (value: number[]) => value?.map((item) => item.toString()))
  public usersInboundsIds: string[]

  @HasManyByField(PhoneNumber, 'usersInboundsIds')
  public usersInbounds: PhoneNumber[]

  @AttrField([])
  public actions: AutomationRuleAction[]

  @StringField()
  public scheduleTimeType: AutomationRuleScheduleTimeType

  @AttrField([], (value) => value || [])
  public scheduleTimeParams: AutomationRuleScheduleTimeParamItem[]

  @BooleanField()
  public isSingleTime: boolean

  @BooleanField()
  public isBeginningOnly: boolean

  @AttrField()
  public userCarrierPhones: AutomationRuleDetailsUserCarrierPhone[]
}

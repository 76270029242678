import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  // Domain services
  FormSubscribeService,
  FormSubscribeDetailsService,

  // Repositories
  FormSubscribeRepository,
  FormSubscribeDetailsRepository,

  // Forms
  SubscribeSettingsFormService,
  SubscribeAppearanceFormService,
  SubscribeTextsFormService,
  SubscribeCreateFormService,
  SubscribeRenameFormService,
  SubscribeLandingFormService,

  // Table
  FormSubscribeColumnServiceFactory,
}

export type WidgetSubscribeServices = keyof typeof SERVICES

export const WIDGET_SUBSCRIBE_SERVICE_TYPES: Record<WidgetSubscribeServices, symbol> =
  makeServicesTypes<WidgetSubscribeServices>(enumKeysToArray<WidgetSubscribeServices>(SERVICES))

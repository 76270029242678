import { NumberField, OrmModel, PrimaryKey, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { TmCountryCode } from '@/services/types'

@OrmModel(modelNames.MESSAGES_PRICE_FOR_COUNTRY)
export default class MessagesPriceForCountry extends BaseModel {
  @PrimaryKey()
  @StringField()
  public countryId: TmCountryCode

  @StringField()
  public currency: string

  @NumberField(0)
  public price: number
}

<template>
  <div class="body-text-regular-14 neutral--text">
    <tm-translate keypath="contacts.unpinActionConfirmModal.text1">
      <template v-slot:type>
        <span>
          {{ type }}
        </span>
      </template>

      <template v-slot:name>
        <span class="body-text-semi-bold-14 neutral--text">
          {{ name }}
        </span>
      </template>
    </tm-translate>

    <span class="d-block mt-4">
      {{ $t('contacts.unpinActionConfirmModal.text2', { type }) }}
    </span>
  </div>
</template>
<script lang="ts">
import TmTranslate from '@/components/TmTranslate.vue'
import { defineComponent } from '@/composition/vue/compositionApi'

export default defineComponent({
  components: {
    TmTranslate,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
})
</script>

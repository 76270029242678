import { injectable } from 'inversify'
import type { UniqueKeys } from '@/types'

type PromiseExecutorPoolItem = {
  keys: UniqueKeys
  promise: Promise<unknown>
}

@injectable()
export class PromiseExecutorPoolManager {
  protected pool: Array<PromiseExecutorPoolItem> = []

  public hasPromiseInPool(keys: UniqueKeys) {
    return this.pool.some((item) => this.isMatchedByKeys(keys, item))
  }

  public getPromiseFromPool(keys: UniqueKeys) {
    return this.pool.find((item) => this.isMatchedByKeys(keys, item))?.promise
  }

  public addPromiseToPool(keys: UniqueKeys, promise: Promise<unknown>) {
    this.pool.push({ keys, promise })
  }

  public removePromiseFromPool(keys: UniqueKeys) {
    const index = this.pool.findIndex((item) => this.isMatchedByKeys(keys, item))
    if (index === -1) {
      return
    }

    this.pool.splice(index, 1)
  }

  protected isMatchedByKeys(keys: UniqueKeys, item: PromiseExecutorPoolItem) {
    return keys.every((key) => item.keys.includes(key))
  }
}

import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

enum AUTH_SERVICES {
  LoginFormService,
  SignupFormService,
}

export type AuthServices = keyof typeof AUTH_SERVICES

export const AUTH_SERVICE_TYPES: Record<AuthServices, symbol> = makeServicesTypes<AuthServices>(
  enumKeysToArray<AuthServices>(AUTH_SERVICES),
)

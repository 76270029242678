import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum LOGIN_SSO_SERVICES {
  LoginSSOFormService,
  LoginSSOQueryErrorResolver,
}

export type LoginSSOServices = keyof typeof LOGIN_SSO_SERVICES

export const LOGIN_SSO_SERVICE_TYPES = makeServicesTypesFromEnum<LoginSSOServices>(LOGIN_SSO_SERVICES)

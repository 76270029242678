import { OrmModel, StringField, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.SEARCH)
export default class Search extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public value: string

  @AttrField([])
  public searchFields: string[]

  @AttrField([])
  public history: string[]
}

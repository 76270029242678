import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ScheduledPausedColumnServiceFactory,
  ScheduledPausedGridable,
  ScheduledPausedExportService,
}

export type ScheduledPausedServices = keyof typeof SERVICES

export const SCHEDULED_PAUSED_SERVICE_TYPES: Record<ScheduledPausedServices, symbol> =
  makeServicesTypes<ScheduledPausedServices>(enumKeysToArray<ScheduledPausedServices>(SERVICES))

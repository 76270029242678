import { injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import MessagesPriceForCountry from '@/data/models/domain/MessagesPriceForCountry'
import type MessagesPriceForCountryRepository from '@/data/repositories/domain/messagesPriceForCountryRepository'

@DomainSettings({
  model: MessagesPriceForCountry,
})
@injectable()
export default class MessagesPriceForCountryService extends DomainBaseService<MessagesPriceForCountryRepository> {
  public getMessagesPriceForCountries() {
    return this.getDomainRepository().getMessagesPriceForCountries()
  }
}

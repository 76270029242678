import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { TmCountryCode } from '@/services/types'

export enum UnsubscribeReason {
  REASON_STOP = 's',
  REASON_MANUAL = 'm',
  REASON_RULE = 'a',
  REASON_SPAM = 'x',
  REASON_BLOCK = 'b',
  REASON_AUTOUNSUB = 'u',
  REASON_COMPLAINT = 'c',
}

@OrmModel(modelNames.UNSUBSCRIBED_CONTACT)
export default class UnsubscribedContact extends BaseModel {
  @StringField()
  public phone: string

  @StringField()
  public unsubscribeTime: string

  @StringField()
  public countryId: TmCountryCode

  @StringField()
  public reason: UnsubscribeReason

  @StringField()
  public firstName: string

  @StringField()
  public lastName: string

  @StringField()
  public unsubscribeText: string
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  SubAccountImportSessionService,
  SubAccountImportSessionRepository,
  SubAccountImportUploadFormService,
  SubAccountImportSettingsFormService,
}

export type SubAccountsImportFlowServices = keyof typeof SERVICES

export const SUB_ACCOUNTS_IMPORT_FLOW_SERVICE_TYPES: Record<SubAccountsImportFlowServices, symbol> =
  makeServicesTypes<SubAccountsImportFlowServices>(enumKeysToArray<SubAccountsImportFlowServices>(SERVICES))

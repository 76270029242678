<template>
  <div class="chat-sidebar-header">
    <div class="chat-sidebar-header__top">
      <tm-skeleton
        type="QChip"
        width="106px"
        height="15px"
      />
      <div class="chat-sidebar-header-right">
        <tm-skeleton
          type="circle"
          width="20px"
          height="20px"
        />
        <tm-skeleton
          class="ml-4"
          type="circle"
          width="20px"
          height="20px"
        />
      </div>
    </div>
    <tm-skeleton
      type="QChip"
      width="100%"
      height="32px"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'

export default defineComponent({
  components: {
    TmSkeleton,
  },
})
</script>
<style lang="scss" scoped>
.chat-sidebar-header-right {
  display: flex;
  align-items: center;
}

.chat-sidebar-header {
  border-bottom: 1px solid $gray2;
  padding: 0 16px 12px;

  &__top {
    display: flex;
    height: 55px;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
</style>

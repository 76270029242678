import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import UserAnalyticsFields from '@/data/models/domain/UserAnalyticsFields'

@RepoSettings<Endpoint>({
  model: UserAnalyticsFields,
})
@injectable()
export default class UserAnalyticsFieldsRepository extends OrmApiRepository<UserAnalyticsFields> {
  private id = '1'

  public updateFields(analyticsFields: UserAnalyticsFields) {
    const path = this.endpointsService.getPath('userAnalyticsFieldsUpdate')
    return this.getApiSource().put(path, analyticsFields)
  }

  public storeAnalyticsFields(analyticsFields: UserAnalyticsFields) {
    return this.insertOrUpdate([{ ...analyticsFields, id: this.id }])
  }

  public findAnalyticsFields(): UserAnalyticsFields {
    return this.find(this.id)
  }
}

import { OrmModel, StringField, BooleanField, NumberField, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { NumberPrice } from '@/services/domain/services/regulations/types'

@OrmModel(modelNames.TEN_DLC_PRICING)
export default class TenDlcPricing extends BaseModel {
  @NumberField()
  public brandRegistration: number

  @NumberField()
  public campaignRegistration: number

  @StringField()
  public currency: string

  @NumberField()
  public minimumRequiredCredit: number

  @AttrField()
  public numberPrice: NumberPrice

  @BooleanField()
  public hasFreeCampaignRegistration: boolean
}

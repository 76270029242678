<template>
  <div class="chat-message-editor-loading">
    <div class="chat-message-editor-loading__text">
      <tm-skeleton
        type="QChip"
        height="10px"
        width="130px"
        animation="none"
      />
    </div>

    <div
      v-if="isUsersWorkflow"
      class="chat-message-editor-loading__typing"
    />

    <div class="d-flex mt-auto justify-between align-center">
      <div class="d-flex">
        <tm-skeleton
          v-for="i in 5"
          :key="i"
          type="circle"
          width="20px"
          height="20px"
          animation="none"
          class="mr-4"
        />
      </div>

      <div class="flex-grow-1 d-flex justify-end">
        <tm-skeleton
          class="chat-message-editor-loading__bar"
          type="QChip"
          height="20px"
          animation="none"
          width="100%"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from '@/composition/vue/compositionApi'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import { getTeamWorkflowService } from '@/core/container/ioc'

const teamWorkflowService = getTeamWorkflowService()
const isUsersWorkflow = computed(() => teamWorkflowService.isUsersWorkflow())
</script>

<style lang="scss" scoped>
.chat-message-editor-loading {
  position: relative;
  padding: 20px 16px 16px;
  margin: 0 11px 11px 11px;
  border: 1px solid $input;
  border-radius: $border-radius-md;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: $white;

  &__text {
    height: 100px;

    @media (max-height: 900px) {
      max-height: 80px;
    }
    @media (max-height: 800px) {
      max-height: 50px;
    }
    @media (max-height: 700px) {
      max-height: 40px;
    }
  }

  &__typing {
    height: 24px;
  }

  &__bar {
    max-width: 300px;
  }
}
</style>

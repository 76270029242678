import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'

export const getEmailToSmsRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.services.emailToSms',
    path: 'email-to-sms',
    meta: {
      tags: ['SERVICES_BAR_LIST'],
      permission: 'emailToSms',
      modules: [AppModule.EmailToSms],
    },
    redirect: { name: 'user.services.emailToSms.allowedEmails' },
    component: () => import('@/components/views/services/emailToSms/EmailToSms.vue'),
    children: [
      {
        name: 'user.services.emailToSms.allowedEmails',
        path: '',
        component: () => import('@/components/views/services/emailToSms/AllowedEmails.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.allowedEmails',
        },
      },
      {
        name: 'user.services.emailToSms.sendReceiveSettings',
        path: 'send-and-receive-settings',
        meta: {
          tabTitle: 'pageTabTitle.user.services.sendReceiveSettings',
          resolvers: [
            {
              service: 'SendReceiveSettingsResolver',
            },
          ],
        },
        component: () => import('@/components/views/services/emailToSms/SendReceiveSettings.vue'),
      },
      {
        name: 'user.services.emailToSms.distributionLists',
        path: 'distribution-lists',
        component: () => import('@/components/views/services/emailToSms/Distribution.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.distributionLists',
          permission: 'distributionLists',
        },
      },
    ],
  },
]

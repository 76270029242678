<script lang="ts">
/* eslint-disable no-else-return */
import {
  computed,
  defineComponent,
  h,
  Text as TextNode,
  Fragment as FragmentNode,
  type VNode,
} from '@/composition/vue/compositionApi'
import TmAbsurdError from '@/core/error/tmAbsurdError'

type Node = {
  type: NodeType
  value: Node[] | string
  class?: string
}

const enum NodeType {
  Text,
  Bold,
  Italic,
  LineFeed,
}

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
    },
    skipBold: {
      type: Boolean,
      default: false,
    },
    skipItalic: {
      type: Boolean,
      default: false,
    },
    skipLineFeed: {
      type: Boolean,
      default: false,
    },
    boldClass: {
      type: String,
    },
    italicClass: {
      type: String,
    },
  },
  setup(props) {
    const getRegExp = (): RegExp => {
      if (props.skipBold && props.skipItalic) {
        return /^(?<prefix>.*?)$/s
      } else if (props.skipBold) {
        return /^(?<prefix>.*?)_(?<italic>.*?)_(?<suffix>.*)?$/s
      } else if (props.skipItalic) {
        return /^(?<prefix>.*?)\*\*(?<bold>.*?)\*\*(?<suffix>.*)?$/s
      } else {
        return /^(?<prefix>.*?)(?:\*\*(?<bold>.*?)\*\*|_(?<italic>.*?)_)(?<suffix>.+)?$/s
      }
    }

    const parseNewline = (s: string): Node[] => {
      if (props.skipLineFeed) {
        return [{ type: NodeType.Text, value: s }]
      }

      const re = /\n/s
      const nodes: Node[] = []

      const m = s.match(re)
      if (typeof m?.index === 'number') {
        const prefix = s.slice(0, m.index)
        const suffix = s.slice(m.index + 1)

        nodes.push({ type: NodeType.Text, value: prefix })
        nodes.push({ type: NodeType.LineFeed, value: [] })
        if (suffix) {
          nodes.push({ type: NodeType.Text, value: parseNewline(suffix) })
        }
      } else {
        nodes.push({ type: NodeType.Text, value: s })
      }

      return nodes
    }

    const parseString = (s: string): Node[] => {
      const nodes: Node[] = []
      const m = getRegExp().exec(s)

      if (m) {
        nodes.push(...parseNewline(m.groups!.prefix))

        if (m.groups!.bold) {
          nodes.push({ type: NodeType.Bold, class: props.boldClass, value: [...parseString(m.groups!.bold)] })
        } else if (m.groups!.italic) {
          nodes.push({ type: NodeType.Italic, class: props.italicClass, value: [...parseString(m.groups!.italic)] })
        }

        if (m.groups!.suffix) {
          nodes.push(...parseString(m.groups!.suffix))
        }
      } else if (s) {
        nodes.push(...parseNewline(s))
      }

      return nodes
    }

    const renderNode = (node: Node): VNode => {
      const value = typeof node.value === 'string' ? node.value : node.value.map((n) => renderNode(n))

      if (node.type === NodeType.Text) {
        if (typeof node.value === 'string') {
          return h(TextNode, node.value)
        } else {
          if (node.value.length === 1 && typeof node.value[0].value === 'string') {
            return h(TextNode, node.value[0].value as string)
          }

          return h(
            FragmentNode,
            null,
            node.value.map((n) => renderNode(n)),
          )
        }
      } else if (node.type === NodeType.Bold) {
        return h('b', node.class ? { class: node.class } : null, value)
      } else if (node.type === NodeType.Italic) {
        return h('em', node.class ? { class: node.class } : null, value)
      } else if (node.type === NodeType.LineFeed) {
        return h('br')
      } else {
        throw new TmAbsurdError(node.type)
      }
    }

    const vNodeArr = computed<VNode[]>(() => parseString(props.content).map((node) => renderNode(node)))

    return () => vNodeArr.value
  },
})
</script>

import { inject, injectable } from 'inversify'
import type {
  ValidationRuleError,
  ValidationRuleFunctionCustom,
  RuleConfig,
  ValidationRuleErrorWithRuleName,
} from '@/services/validation/types'
import type { BaseValidationRules } from '@/services/validation/validationRulesBuilderService'
import type ValidationService from '@/services/validation/validationService'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Dict } from '@/types'

export type ValidationConditionCallback = (...props: Parameters<ValidationRuleFunctionCustom>) => boolean
export type ItemValidationPayload = {
  invalidItems: boolean[]
  itemErrors: ValidationRuleErrorWithRuleName[][]
}

export const ifValidationRuleName = 'if'
export const itemValidationRuleName = 'forEachItem'

@injectable()
export default class SubValidationService {
  constructor(@inject(SERVICE_TYPES.ValidationService) protected readonly validationService: ValidationService) {}

  private validate(value: any, formValues: Dict<string>, rules: BaseValidationRules) {
    return this.validationService.validateFields({
      value,
      validators: rules.getRules(),
      formValues,
      localValidators: rules.getLocalValidators(),
    })
  }

  public if(
    condition: ValidationConditionCallback,
    ifThen: BaseValidationRules,
    ifElse?: BaseValidationRules,
  ): [name: string, func: ValidationRuleFunctionCustom] {
    return [
      ifValidationRuleName,
      (value, formValues, config) => {
        const processValidation = async (validationRules: BaseValidationRules) => {
          const results = await this.validate(value, formValues, validationRules)
          return results.reduce((result, { errors }) => [...result, ...errors], [] as ValidationRuleError[])
        }

        if (condition(value, formValues, config)) {
          return processValidation(ifThen)
        }
        if (ifElse) {
          return processValidation(ifElse)
        }
        return true
      },
    ]
  }

  public forEachItem(
    rules: BaseValidationRules,
    config?: RuleConfig & {
      getMessage?: (itemErrors: ValidationRuleErrorWithRuleName[][]) => ValidationRuleError['message']
    },
  ): [name: string, func: ValidationRuleFunctionCustom<unknown[], ItemValidationPayload>] {
    return [
      itemValidationRuleName,
      async (list, formValues) => {
        const results = await Promise.all(list.map((value) => this.validate(value, formValues, rules)))

        const invalidItems = results.map((itemResults) => itemResults.some(({ valid }) => !valid))
        const isValid = invalidItems.every((invalid) => !invalid)
        if (isValid) return true

        const itemErrors = results.map((itemResults) =>
          itemResults.reduce((result, { errors }) => [...result, ...errors], [] as ValidationRuleErrorWithRuleName[]),
        )

        const defaultMessage = config?.getMessage?.(itemErrors) ?? {
          path: 'commonValidationRegistrator.hasEveryValid',
        }

        return {
          message: this.validationService.composeErrorMessageWithRule(defaultMessage, config),
          payload: {
            invalidItems,
            itemErrors,
          },
        }
      },
    ]
  }
}

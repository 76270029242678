import type { TmWrappers } from '@/wrappers'
import type { RegisteredServices } from '@/core/container/types'

export const ticketIdViewParam = 'ticketId'
export const ticketInboxIdViewParam = 'inboxId'

export const ticketsTableId = 'TicketsTable' as RegisteredServices
export const ticketsFilteredViewPreviewTableId = 'TicketsFilteredViewPreviewTable' as RegisteredServices

export const ticketCreateModalId = 'ticketCreateModal' as TmWrappers
export const ticketAssignModalId = 'ticketAssignModal' as TmWrappers
export const ticketBulkMarkAsSpamModalId = 'ticketBulkMarkAsSpamModal' as TmWrappers
export const ticketBulkAssignModalId = 'ticketBulkAssignModal' as TmWrappers

export const TICKET_FILTERED_VIEWS_CREATE_FORM_ID =
  'TicketFilteredViewsCreateFormService' as const satisfies RegisteredServices

export const TICKET_FILTERED_VIEWS_EDIT_FORM_ID =
  'TicketFilteredViewsEditFormService' as const satisfies RegisteredServices

export const TICKET_DETAILS_MARK_READ_TIMEOUT = 3000

import { inject, injectable } from 'inversify'
import type { Gridable } from '@/services/types'
import type User from '@/data/models/domain/User'
import { SERVICE_TYPES } from '@/core/container/types'
import type UserRepository from '@/data/repositories/domain/users/userRepository'
import type { EndpointParams } from '@/services/endpoints'
import type { PaginationUrlType } from '@/services/tables/types'
import type { PaginationOffsetResponse, PaginationParams } from '@/services/tables/pagination/types'

@injectable()
export default class UserAdminGridable implements Gridable<User> {
  constructor(@inject(SERVICE_TYPES.UserRepository) protected readonly userRepository: UserRepository) {}

  private paging: PaginationParams = {
    pageKey: '',
    requestParams: {
      page: 1,
      perPage: 25,
    },
  }

  public getFetchEndpointParams(): EndpointParams {
    return []
  }

  public async gridRequest(
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
    searchFields?: string[],
  ): Promise<PaginationOffsetResponse<User>> {
    const query = this.composeQuery(queryParameterBag)
    return this.userRepository.adminsGridRequest(query, this.paging, searchQuery, searchFields)
  }

  private composeQuery(rawQuery: PaginationUrlType): PaginationUrlType {
    const billingFilter = { withPermission: { eq: 'BILLING' } }
    return { ...rawQuery, filter: [...rawQuery.filter, billingFilter] }
  }
}

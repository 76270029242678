import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { RedirectableSingleResolverParams, Resolvable } from '@/services/resolvers/types'
import SingleModelResolverService from '@/services/resolvers/singleModelResolver'
import type RouterService from '@/services/route/routerService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type LoggerService from '@/services/loggerService'
import type BaseResolverService from '@/services/resolvers/baseResolverService'
import { TmApiNotFoundError } from '@/core/error/transport/tmApiNotFoundError'
import { TmApiAccessError } from '@/core/error/transport/tmApiAccessError'
import { TmEntityNotFoundError } from '@/core/error/tmEntityNotFoundError'

@injectable()
export class RedirectableSingleModelResolverService
  extends SingleModelResolverService
  implements Resolvable<RedirectableSingleResolverParams>
{
  constructor(
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.BaseResolverService) protected readonly baseResolverService: BaseResolverService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
  ) {
    super(preloaderManager, loggerService, baseResolverService)
  }

  public async resolve(params: RedirectableSingleResolverParams) {
    try {
      await super.resolve(params)
    } catch (error) {
      if (
        (error instanceof TmApiNotFoundError || error instanceof TmEntityNotFoundError) &&
        params.redirectToIfNotFound
      ) {
        await this.routerService.replace(params.redirectToIfNotFound)
      } else if (error instanceof TmApiAccessError && params.redirectToIfAccessError) {
        await this.routerService.replace(params.redirectToIfAccessError)
      } else {
        throw error
      }
    }
  }
}

import vars from '@/styles/variables.module.scss'
import type { StyleColors } from '@/constants/styleColors'

// prettier-ignore
export type StyleVarsKeys = StyleColors |

'calendarEventColors' |

'actionLight' |
'action' |
'actionDark' |
'note' |
'highlight' |
'overlay' |
'canvas' |
'lift' |
'shade' |
'input' |

'emphasize' |
'strong' |
'neutral' |
'distinct' |
'light' |
'subtle' |
'weak' |

'headerHeight' |
'pageBlocksWidth' |
'sidebarOpenedWidth' |
'sidebarCollapsedWidth' |
'pageContentSidebarWidth' |
'scrollbarWidth' |
'dropUpHeaderHeight' |
'modalPadding' |

'breakpointXsMax' |
'breakpointSmMin' |
'breakpointSmMax' |
'breakpointSmXMin' |
'breakpointSmXMax' |
'breakpointMdMin' |
'breakpointMdMax' |
'breakpointLgMin' |
'breakpointLgMax' |
'breakpointXlMin' |
'breakpointXlMax' |
'breakpointXxlMin' |
'breakpointXxlMax' |
'breakpoint3XlMin' |
'breakpoint3XlMax' |

'chartColorBlue' |
'chatSidebarItemHeight' |

'voicePopupWidth' |
'voicePopupHeight'

export const styleVars = vars as unknown as Record<StyleVarsKeys, string> & { calendarEventColors: string[] }

import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import SipCall from '@/data/models/domain/SipCall'
import type SipCallsRepository from '@/data/repositories/domain/sipCallsRepository'
import type { ModelRaw } from '@/types'
import BaseSmsService from '@/services/domain/baseSmsService'

@DomainSettings({
  model: SipCall,
})
@injectable()
export default class SipCallsService<T extends SipCallsRepository = SipCallsRepository> extends BaseSmsService<T> {
  public isCallContact<Call extends SipCall>(call: Call | ModelRaw<Call>): boolean {
    return !!call.contactId
  }

  public isCallAnswered<Call extends SipCall>(call: Call | ModelRaw<Call>): boolean {
    return call.length > 0
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type TenDlcPricingService from '@/services/domain/services/regulations/tenDlcPricingService'
import type ModelPreloaderService from '@/services/preloaders/modelPreloaderService'

@injectable()
export class TenDlcPricesPreloaderService {
  constructor(
    @inject(SERVICE_TYPES.TenDlcPricingService) protected readonly pricingService: TenDlcPricingService,
    @inject(SERVICE_TYPES.ModelPreloaderService) protected readonly modelPreloaderService: ModelPreloaderService,
  ) {}

  public async preload() {
    if (this.isPreloaded()) {
      this.pricingService.fetchPrices() // We don't have to await this
      return this.pricingService.getPrices()
    }

    try {
      const result = await this.pricingService.fetchPrices()
      this.markAsPreloaded()
      return result
    } catch (e) {
      this.markAsFailed()
      throw e
    }
  }

  public isPreloaded(): boolean {
    return this.modelPreloaderService.isPreloaded(this.getKey())
  }

  public markAsFailed(): void {
    this.modelPreloaderService.markAsFailed(this.getKey())
  }

  public markAsNotPreloaded(): void {
    this.modelPreloaderService.markAsNotPreloaded(this.getKey())
  }

  public markAsPreloaded(): void {
    this.modelPreloaderService.markAsPreloaded(this.getKey())
  }

  protected getKey() {
    return 'TenDlcPricing'
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  InvoicesService,
  InvoiceRepository,
}

export type InvoicesBaseServices = keyof typeof SERVICES

export const INVOICES_BASE_SERVICE_TYPES: Record<InvoicesBaseServices, symbol> =
  makeServicesTypes<InvoicesBaseServices>(enumKeysToArray<InvoicesBaseServices>(SERVICES))

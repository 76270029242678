import { cloneDeep } from 'lodash-es'
import { inject, injectable } from 'inversify'
import '@/polyfills/BroadcastChannelPolyfill'
import type {
  BroadcastCallback,
  BroadcastEvent,
  BroadcastEventMap,
  InternalEmitterPayload,
  InternalSubscriberCallback,
  InternalSubscriptionItem,
  InternalSubscriptions,
} from '@/services/transport/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type LoggerService from '@/services/loggerService'
import TmSubscriptionError from '@/core/error/tmSubscriptionError'
import type { IBroadcastSubscriptionService } from '@/services/types'

@injectable()
export default class SubscriptionService implements IBroadcastSubscriptionService {
  private subscriptions: InternalSubscriptions<any> = {}

  private broadcastChannels: Record<string, { channel: BroadcastChannel; callbacks: unknown[] }> = {}

  constructor(@inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService) {}

  public subscribeWithSubscriptionKey<T = InternalEmitterPayload>(
    eventName: string,
    callback: InternalSubscriberCallback<T>,
    key: string,
    isPersistent = false,
  ) {
    eventName = this.normalizeEventName(eventName)
    if (this.exist(key)) {
      throw new TmSubscriptionError(`Already subscribed to key = ${key}`)
    }
    this.subscriptions[key] = {
      key,
      eventName,
      callback,
      lastTriggerTime: 0,
      isPersistent,
    }
    this.logger.log('bus', `subscribed with key #eventName ${eventName} #key ${key}`, 'internal')
    return key
  }

  public subscribe<T = InternalEmitterPayload>(
    eventName: string,
    callback: InternalSubscriberCallback<T>,
    isPersistent = false,
  ) {
    eventName = this.normalizeEventName(eventName)
    let key = Math.random().toString()
    while (this.exist(key)) {
      key = Math.random().toString()
    }
    this.subscriptions[key] = {
      key,
      eventName,
      callback,
      lastTriggerTime: 0,
      isPersistent,
    }
    this.logger.log('bus', `subscribed #eventName ${eventName} #key ${key}`, 'internal')

    return key
  }

  /**
   * Subscribe to an event generated by our application in another browser tab
   * @param eventName
   * @param callback
   */
  public subscribeBroadcast<T extends BroadcastEvent>(eventName: T, callback: BroadcastCallback<T>) {
    this.innerSubscribeBroadcast(eventName, callback)
  }

  public unsubscribe(key: string, withPersistent = false) {
    if (!withPersistent && this.subscriptions[key] && this.subscriptions[key].isPersistent) {
      return
    }
    this.logger.log('bus', `unsubscribed #key ${key}, eventName ${this.subscriptions[key]?.eventName}`, 'internal')
    delete this.subscriptions[key]
  }

  /**
   * Unsubscribe from an event generated by our application in another browser tab
   * @param eventName
   * @param callback
   * @returns
   */
  public unsubscribeBroadcast<T extends BroadcastEvent>(eventName: T, callback?: BroadcastCallback<T>) {
    const channelInfo = this.broadcastChannels[eventName]
    if (!channelInfo) {
      return
    }
    if (callback) {
      const i = channelInfo.callbacks.findIndex((cb) => cb === callback)
      if (i >= 0) {
        channelInfo.callbacks.splice(i, 1)
      }
    }
    if (!callback || !channelInfo.callbacks.length) {
      channelInfo.channel.close()
      delete this.broadcastChannels[eventName]
    }
  }

  /**
   * Send an event to our application in another browser tab.
   * This event will not be processed on the event source tab
   * @param eventName
   * @param payload
   */
  public broadcastEmit<T extends BroadcastEvent>(eventName: T, payload?: BroadcastEventMap[T]) {
    let channelInfo = this.broadcastChannels[eventName]
    if (!channelInfo) {
      this.innerSubscribeBroadcast(eventName)
      channelInfo = this.broadcastChannels[eventName]
    }
    channelInfo.channel.postMessage(payload)
  }

  public emit<T = InternalEmitterPayload>(eventName: string, payload: T, debounce = 0) {
    for (const evName of this.expandEventName(this.normalizeEventName(eventName))) {
      this.emit_<T>(evName, payload, debounce)
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected emit_<T>(eventName: string, payload: T, debounce = 0) {
    const timestamp = new Date().getTime()
    Object.values(this.subscriptions).forEach((item: InternalSubscriptionItem<T>) => {
      if (item.eventName === eventName && timestamp - item.lastTriggerTime >= debounce) {
        this.logger.log('bus', `fired ${eventName} ${item.key}`, 'internal')
        item.callback(payload)
        item.lastTriggerTime = timestamp
      }
    })
  }

  public exist(key: string): boolean {
    return typeof this.subscriptions[key] !== 'undefined'
  }

  public unsubscribeFromAll(withPersistent = false) {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.unsubscribe(key, withPersistent)
    })
  }

  protected normalizeEventName(name: string) {
    return name
      .split('/')
      .filter((s) => s && s !== '*')
      .join('/')
  }

  protected expandEventName(name: string) {
    return name.split('/').reduce((acc: string[], val: string) => {
      acc.push(acc.length ? [acc[acc.length - 1], val].join('/') : val)
      return acc
    }, [])
  }

  protected cloneSubscriptions() {
    return cloneDeep(this.subscriptions)
  }

  private innerSubscribeBroadcast<T extends BroadcastEvent>(eventName: T, callback?: BroadcastCallback<T>) {
    let channelInfo = this.broadcastChannels[eventName]
    if (channelInfo) {
      channelInfo.callbacks.push(callback)
    } else {
      channelInfo = { channel: new BroadcastChannel(eventName), callbacks: callback ? [callback] : [] }
      this.broadcastChannels[eventName] = channelInfo
      channelInfo.channel.onmessage = (event: MessageEvent) => {
        channelInfo.callbacks.forEach((cb) => {
          if (typeof cb === 'function') {
            cb(event.data)
          }
        })
      }
    }
  }
}

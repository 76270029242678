import { inject, injectable } from 'inversify'
import type { INativeNotification } from '@/services/types'
import type LoggerService from '@/services/loggerService'
import { AWS_S3_URL } from '@/constants/constants'
import { SERVICE_TYPES } from '@/core/container/types'
import LogoImageUrl from '@/assets/images/logo.png'
import ButtonTinyAudioUrl from '@/assets/sounds/button_tiny.mp3'
import type { MonitoringServiceInterface } from '@/services/monitoring/types'
import type WindowService from '@/services/browser/windowService'

@injectable()
export default class NativeNotificationService implements INativeNotification {
  protected failedAudioId: string | null

  protected failedAudioInstance: HTMLAudioElement | null

  constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.MonitoringService) protected readonly monitoringService: MonitoringServiceInterface,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
  ) {}

  public isHasNotificationFeature(): boolean {
    return Boolean('Notification' in window)
  }

  public isNotificationDenied() {
    return this.isHasNotificationFeature() && Notification.permission === 'denied'
  }

  public isNotificationGranted() {
    return this.isHasNotificationFeature() && Notification.permission === 'granted'
  }

  public isAllowNotificationRequest() {
    return this.isHasNotificationFeature() && Notification.permission === 'default'
  }

  public notificationRequest(callback?: (result: string) => void) {
    if (this.isAllowNotificationRequest()) {
      Notification.requestPermission(callback)
    }
  }

  public notify(tag: string, title: string, text: string, icon?: string, onClickCallback?: () => void) {
    if (!this.isNotificationGranted()) {
      return
    }
    icon = icon || LogoImageUrl
    const notification = new Notification(title, {
      icon,
      tag,
      body: text,
    })
    if (onClickCallback) {
      notification.onclick = () => {
        try {
          onClickCallback()
        } catch (e) {
          this.loggerService.error('error', (e as Error).message)
        }
        window.focus()
        notification.close()
      }
    }
  }

  public playSound(id: string, path?: string, volume?: number) {
    const audio = this.createAudioInstance(id, path, volume)

    audio.play().catch((e: Error) => {
      this.handlePlayError(e, id, path, volume)
    })
  }

  public playAudioInstance(audio: HTMLAudioElement) {
    audio.play().catch((e: Error) => {
      this.handlePlayError(e, audio.id, undefined, audio.volume, audio)
    })
  }

  public createAudioInstance(id: string, path?: string, volume?: number, load?: boolean): HTMLAudioElement {
    const url = path ? AWS_S3_URL + path : ButtonTinyAudioUrl
    const audio = new Audio(url)
    audio.id = id
    audio.preload = 'auto'
    audio.volume = volume ?? 0.5
    audio.loop = false

    if (load) {
      audio.load()
    }

    return audio
  }

  private handlePlayError(error: Error, id: string, path?: string, volume?: number, audio?: HTMLAudioElement): void {
    const notAllowedError: string = 'NotAllowedError'
    if (error.name === notAllowedError) {
      this.failedAudioId = id
      this.failedAudioInstance = audio ?? null
      const event: string = 'click'

      const playnOnActivate = () => {
        if (this.failedAudioId || this.failedAudioInstance) {
          this.failedAudioId = null
          this.failedAudioInstance = null
          if (audio) {
            this.playAudioInstance(audio)
          } else {
            this.playSound(id, path, volume)
          }
        }
      }

      this.windowService.self().addEventListener(event, playnOnActivate, { once: true })
    } else {
      const msg = `${(error as Error).message}, id=${id}, path=${path}, src=${audio?.src}`
      this.monitoringService.logInfo(msg)
    }
  }
}

import { injectable } from 'inversify'

import type { PaginationUrlFilterStrictType, PaginationUrlFilterType } from '@/services/tables/types'
import FilterQueryService from '@/services/filterQueryService'

@injectable()
export default class FilterBracketNotationQueryService extends FilterQueryService {
  public decorateFilterToStrictQueryPart(queryParts: PaginationUrlFilterType): PaginationUrlFilterStrictType {
    return super.serializeFiltersToBracketNotation({ filters: queryParts })
  }
}

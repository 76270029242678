import { AttrField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { NumberPrice } from '@/services/domain/services/regulations/types'
import type { Nullable } from '@/types'

export type NumberFeatures = 'SMS' | 'MMS' | 'VOICE'

export type AvailableToBuyNumber = {
  number: string
  features: NumberFeatures[]
}
@OrmModel(modelNames.AVAILABLE_TO_BUY_NUMBERS)
export default class AvailableToBuyNumbers extends BaseModel {
  @AttrField(null)
  public numbers: AvailableToBuyNumber[]

  @AttrField(null)
  public numberPrice: Nullable<NumberPrice>
}

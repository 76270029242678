import { inject, injectable } from 'inversify'
import type { DateFieldConfig, SelectFieldConfig, SelectOption } from '@/services/forms/types'
import type {
  FormGroupConfigurationInterface,
  TypedFormBuilderInterface,
} from '@/services/forms/baseForm/typedFormBuilder/types'
import type { Translatable, HoursIntervalType } from '@/services/types'
import type DateTimeService from '@/services/dateTimeService'
import { SERVICE_TYPES } from '@/core/container/types'
import type UserService from '@/services/domain/user/userService'
import type { BaseScheduleFormSendDateTimeFormScheme } from '@/services/forms/baseScheduleFormService'
import type Timezone from '@/data/models/domain/Timezone'

@injectable()
export class SendingDateTimeFormGroupConfiguration
  implements FormGroupConfigurationInterface<BaseScheduleFormSendDateTimeFormScheme>
{
  constructor(
    @inject(SERVICE_TYPES.TranslateService) private readonly translateService: Translatable,
    @inject(SERVICE_TYPES.DateTimeService) private readonly dateTimeService: DateTimeService,
    @inject(SERVICE_TYPES.UserService) private readonly usersService: UserService,
  ) {}

  private getSelectHoursIntervalTypeOptions(): SelectOption<HoursIntervalType>[] {
    return [
      {
        text: this.translateService.t('units.datetime.hoursIntervalType.am'),
        value: 'AM',
      },
      {
        text: this.translateService.t('units.datetime.hoursIntervalType.pm'),
        value: 'PM',
      },
    ]
  }

  public getFirstScheduledDate(timezone: Timezone) {
    const currentUser = this.usersService.currentUser()

    const initialDate = this.dateTimeService.utcToZonedTime(this.dateTimeService.now(), timezone?.timezone)
    initialDate.setMinutes(initialDate.getMinutes() < 30 ? 0 : 30)

    const date = this.dateTimeService.addMinutes(initialDate, 30)

    const minutes = date.getMinutes()

    const hours =
      currentUser.displayTimeFormat === '12h'
        ? this.dateTimeService.hoursTo12hFormat(date.getHours()).hours
        : date.getHours()

    return {
      date,
      hours,
      minutes,
    }
  }

  public configure(builder: TypedFormBuilderInterface<BaseScheduleFormSendDateTimeFormScheme>): void {
    const currentUser = this.usersService.currentUser(['timezone'])

    const { date, hours, minutes } = this.getFirstScheduledDate(currentUser.timezone)

    const timeIntervalType: HoursIntervalType =
      currentUser.displayTimeFormat === '12h' ? this.dateTimeService.hoursTo12hFormat(date.getHours()).type : 'AM'

    builder
      .property((t) => t.date)
      .control<DateFieldConfig>({
        fieldType: 'datepicker',
        value: date.toString(),
      })
    builder
      .property((t) => t.hours)
      .control({
        fieldType: 'timeHours',
        value: hours,
      })
    builder
      .property((t) => t.minutes)
      .control({
        fieldType: 'timeMinutes',
        value: minutes,
      })
    builder
      .property((t) => t.timeIntervalType)
      .control<SelectFieldConfig<HoursIntervalType>>({
        fieldType: 'select',
        value: timeIntervalType,
        options: () => this.getSelectHoursIntervalTypeOptions(),
      })
  }
}

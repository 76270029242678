import { inject, injectable } from 'inversify'
import BaseLogChannel from '@/core/logger/logChannel'
import BaseLogger from '@/core/logger/baseLogger'
import type { Config, LoggerConfig } from '@/core/types'
import type { LogRule } from '@/core/logger/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { LoggerChannels } from '@/config/configDev'
import TmLogicError from '@/core/error/tmLogicError'
import type { ILogger } from '@/services/types'

export const NULL_CHANNEL = 'null'

@injectable()
export default class LoggerService implements ILogger {
  protected channels: Record<string, BaseLogChannel> = {}

  constructor(@inject(SERVICE_TYPES.Config) protected readonly config: Config) {}

  public isDisabled() {
    return !this.config.logger || !this.config.logger.enabled
  }

  public addChannel(channel: string, instance: BaseLogChannel) {
    this.channels[channel] = instance
  }

  public removeChannel(channel: string) {
    delete this.channels[channel]
  }

  public hasChannel(channel: string): boolean {
    return !!this.channels[channel]
  }

  public getChannel(channel: LoggerChannels) {
    return this.channels[channel] ? this.channels[channel] : this.channels[NULL_CHANNEL]
  }

  public createBaseChannel(channel: LoggerConfig, rule: LogRule) {
    return new BaseLogChannel()
      .setChannel(channel)
      .setRule(rule)
      .setLogger(channel.instance ? (channel.instance as any) : BaseLogger)
  }

  public log(channel: LoggerChannels, message: string, subchannel = '') {
    this.getChannel(channel).toChannel(message, subchannel)
  }

  public table(channel: LoggerChannels, data: Record<string, any>, subchannel = '') {
    this.getChannel(channel).table(data, subchannel)
  }

  public stack(channel: LoggerChannels, stack: string) {
    this.getChannel(channel).stack(stack)
  }

  public profilingStart(channel: LoggerChannels, label: string) {
    this.time(channel, label)
  }

  public profilingIntermediate(channel: LoggerChannels, label: string, description: string) {
    this.log(channel, description, label)
    this.timeLog(channel, label)
  }

  public profilingEnd(channel: LoggerChannels, label: string, description: string) {
    this.log(channel, description, label)
    this.timeEnd(channel, label)
  }

  public timeLog(channel: LoggerChannels, label: string) {
    this.getChannel(channel).timeLog(label)
  }

  public time(channel: LoggerChannels, label: string) {
    this.getChannel(channel).time(label)
  }

  public timeEnd(channel: LoggerChannels, label: string) {
    this.getChannel(channel).timeEnd(label)
  }

  public raw(channel: LoggerChannels, raw: any) {
    this.getChannel(channel).raw(raw)
  }

  public group(channel: LoggerChannels, groupName: string, callback: () => void, subchannel = '') {
    this.getChannel(channel).group(groupName, callback, subchannel)
  }

  public shouldLogByChannel(channel: LoggerChannels, payloads: string[]) {
    return this.shouldLogByRule(this.getChannel(channel).getRule(), payloads)
  }

  public shouldLogByRule(rule: LogRule, payloads: string[]) {
    if (rule && !this.isCorrectRule(rule)) {
      throw new TmLogicError('There is rule with both `include` and `exclude`')
    }

    if (rule && rule.exclude) {
      return !rule.exclude.some((ruleItem) =>
        payloads.some((payload) => (ruleItem instanceof RegExp ? !!payload.match(ruleItem) : payload === ruleItem)),
      )
    }

    if (rule && rule.include) {
      return rule.include.some((ruleItem) =>
        payloads.some((payload) => (ruleItem instanceof RegExp ? payload.match(ruleItem) : payload === ruleItem)),
      )
    }

    return true
  }

  public isCorrectRule(rule: LogRule) {
    return !(rule.include && rule.exclude)
  }

  public isDisabledChannel(channel: LoggerChannels) {
    return this.getChannel(channel).isDisabled()
  }

  public groupStart(channel: LoggerChannels, groupName: string, subchannel = '') {
    this.getChannel(channel).groupStart(groupName, subchannel)
  }

  public args(channel: LoggerChannels, payload: string, subchannel = '') {
    this.debug(channel, payload, subchannel)
  }

  public info(channel: LoggerChannels, payload: string, subchannel = '') {
    this.getChannel(channel).info(payload, subchannel)
  }

  public error(channel: LoggerChannels, payload: string, subchannel = '') {
    this.getChannel(channel).error(payload, subchannel)
  }

  public debug(channel: LoggerChannels, payload: string, subchannel = '') {
    this.getChannel(channel).debug(payload, subchannel)
  }

  public groupEnd(channel: LoggerChannels) {
    this.getChannel(channel).groupEnd()
  }
}

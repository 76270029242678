<template>
  <div class="main-sidebar-item-child-title">
    {{ title }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'

export default defineComponent({
  props: {
    title: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.main-sidebar-item-child-title {
  padding: 0 0 0 48px;
  margin-bottom: 8px;
  line-height: 16px;
  font-size: $sm-font;
  font-weight: $bold-font;
  color: $subtle;
  text-transform: uppercase;
  &:not(:first-child) {
    margin-top: 20px;
  }
}
</style>

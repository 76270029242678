/* eslint-disable camelcase, @typescript-eslint/naming-convention */
import alpha from './alpha'
import alpha_dash from './alpha_dash'
import alpha_num from './alpha_num'
import alpha_spaces from './alpha_spaces'
import between from './between'
import confirmed from './confirmed'
import digits from './digits'
import dimensions from './dimensions'
import email from './email'
import ext from './ext'
import image from './image'
import integer from './integer'
import ip from './ip'
import is from './is'
import is_not from './is_not'
import length from './length'
import max from './max'
import max_value from './max_value'
import mimes from './mimes'
import min from './min'
import min_value from './min_value'
import not_one_of from './not_one_of'
import numeric from './numeric'
import one_of from './one_of'
import regex from './regex'
import required from './required'
import size from './size'
import url from './url'
import urlRegex from './url_regex'
import type { ValidationRuleFunction } from '@/services/validation/types'

export {
  alpha_dash,
  alpha_num,
  alpha_spaces,
  alpha,
  between,
  confirmed,
  digits,
  dimensions,
  email,
  ext,
  image,
  integer,
  ip,
  is_not,
  is,
  length,
  max_value,
  max,
  mimes,
  min_value,
  min,
  not_one_of,
  numeric,
  one_of,
  regex,
  required,
  size,
  url,
  urlRegex,
}

const defaultRules: Record<string, ValidationRuleFunction> = {
  alpha_dash,
  alpha_num,
  alpha_spaces,
  alpha,
  between,
  confirmed,
  digits,
  dimensions,
  ext,
  image,
  integer,
  ip,
  is_not,
  is,
  length,
  max_value,
  max,
  mimes,
  min_value,
  min,
  not_one_of,
  numeric,
  one_of,
  regex,
  required,
  size,
  url,
  urlRegex,
}

export default defaultRules

import { Model } from '@vuex-orm/core'
import { StringField } from 'vuex-orm-decorators'
import { STI_TYPE_KEY } from '@/data/models/types'

export const baseState = {}

export default class BaseModel extends Model {
  public static typeKey = STI_TYPE_KEY

  public static state = baseState

  public static isModelType(ToCheck: typeof Model, needed: typeof Model) {
    const instance = new ToCheck()

    return instance instanceof needed
  }

  @StringField()
  public id: string

  // @note Do not delete it NEVER!
  // @see our fixTypeKeyStiPlugin for VUEX ORM
  @StringField('')
  public [STI_TYPE_KEY]: string
}

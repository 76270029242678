import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum EMAIL_UNSUBSCRIBE_SERVICES {
  EmailUnsubscribeService,
  EmailUnsubscribeRepository,
}

export type EmailUnsubscribeServices = keyof typeof EMAIL_UNSUBSCRIBE_SERVICES

export const EMAIL_UNSUBSCRIBE_SERVICES_TYPES =
  makeServicesTypesFromEnum<EmailUnsubscribeServices>(EMAIL_UNSUBSCRIBE_SERVICES)

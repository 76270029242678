import { cloneDeep } from 'lodash-es'
import { injectable } from 'inversify'
import type { RouteRecordRaw } from 'vue-router'
import type RouterBuilderMiddlewareInterface from '@/services/route/routerBuilderMiddleware/types'
import { getUuid } from '@/utils/uuid'

@injectable()
export default class TitlersRouterBuilderMiddleware implements RouterBuilderMiddlewareInterface {
  public pipe(routes: RouteRecordRaw[]): RouteRecordRaw[] {
    return routes.map((route) => {
      if (route.children && route.children.length > 0) {
        ;(route as any).children = this.pipe(route.children)
      }

      if (route.meta && route.meta.titler && route.meta.titler.service) {
        const component = () => import('@/components/Titler.vue')
        const { titler, ...meta } = cloneDeep(route.meta)

        const titlerRoute: RouteRecordRaw = {
          path: '',
          name: `titler-${getUuid()}`,
          props: { titler },
          component,
          meta: { titler },
          children: [route as any],
        }
        if (route.name) {
          route.meta = meta
        }

        return titlerRoute
      }

      return route
    })
  }
}

import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

export enum UnsubscribeEmailReasonType {
  MANUAL = 'manual',
  LINK_CLICK = 'link_click',
}

@OrmModel(modelNames.UNSUBSCRIBED_EMAIL_CONTACT)
export default class UnsubscribedEmailContact extends BaseModel {
  @StringField()
  public email: string

  @StringField()
  public reasonType: UnsubscribeEmailReasonType

  @StringField()
  public createdAt: string

  @StringField()
  public contactId: string

  @StringField()
  public firstName: string

  @StringField()
  public lastName: string

  public get canBeResubscribed() {
    return this.reasonType !== UnsubscribeEmailReasonType.LINK_CLICK
  }
}

import { BelongsToField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import User from '@/data/models/domain/User'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.NOTE)
export default class Note extends BaseModel {
  @StringField()
  public id: string

  @StringField(null)
  public note: string

  @StringField()
  public userId: string

  @BelongsToField(User, 'userId')
  public user: User

  @StringField()
  public createdAt: string
}

export const getNumericHash = (string: string): number => {
  let accum = 0
  for (let i = 0; i < string.length; i++) {
    accum += (i % 2 ? -1 : 1) * string.charCodeAt(i)
  }
  return accum
}

export const getHashCode = (string: string): number => {
  let accum = 0
  for (let i = 0; i < string.length; i++) {
    // eslint-disable-next-line no-bitwise
    accum = (accum << 5) - accum + string.charCodeAt(i)
    // eslint-disable-next-line no-bitwise
    accum &= accum
  }
  return accum
}

import type { EndpointParams } from '@/services/endpoints'
import type BaseModel from '@/data/models/BaseModel'
import type PageMapPreload from '@/data/models/PageMapPreload'
import type {
  PaginationUrlFilterType,
  PaginationUrlParametersSortType,
  PaginationUrlOtherType,
} from '@/services/tables/types'
import type { AutocompleteResult } from '@/services/types'
import { ServiceGroups } from '@/config/types'

export const PRELOADER_TYPES = [
  ServiceGroups.PRELOADERS,
  ServiceGroups.PRELOADERS_TABLE,
  ServiceGroups.PRELOADERS_AUTOCOMPLETE,
] as const

export type PreloaderType = (typeof PRELOADER_TYPES)[number]

export enum DomainAliases {
  billing = 'billing',
  user = 'user',
  unsubscribedSettings = 'unsubscribedSettings',
  chatSettings = 'chatSettings',
  subAccountsImport = 'subAccountsImport',
  contactImport = 'contactImport',
  smsSurveyDetails = 'smsSurveyDetails',
  pinnedContacts = 'pinnedContacts',
}
export type DomainAliasesType = keyof typeof DomainAliases
export type PreloaderSettingsType = {
  models: (typeof BaseModel)[]
  alias?: DomainAliasesType
}
export type PreloaderSettingsWithAliasType = Required<PreloaderSettingsType>
export interface Preloadable {
  // @TODO do something
  // @WARNING do not remove any methods
  preload(model: typeof BaseModel, preloaderType?: PreloaderType): Promise<BaseModel[]>
  preloadById(model: typeof BaseModel, id: string): Promise<BaseModel | void>
  reloadById(model: typeof BaseModel, id: string): Promise<BaseModel | void>
  markAsPreloaded(key: string, model: typeof BaseModel): void
  markAsNotPreloaded(key: string, model: typeof BaseModel): void
  markAsFailed(key: string): void
  createAndMarkAsPreloaded(key: string, model: typeof BaseModel): void
  isPreloaded(key: string, preloaderType?: PreloaderType): boolean
  startPreloading(key: string, model: typeof BaseModel, preloaderType: PreloaderType): void
  isFailed(key: string, model: typeof BaseModel, preloaderType?: PreloaderType): boolean
  isFailedById(model: typeof BaseModel, id: string, preloaderType?: PreloaderType): boolean
  settings?(): PreloaderSettingsType
  // @TODO do something
  // @WARNING do not remove any methods
}
export interface TablePreloadable {
  allTablePreloaders(model: typeof BaseModel): PageMapPreload[]
  deleteTablePreloadersByCondition(tableModelId: string): void
  updateTablePreloaders(pageKey: string, tableModelId: string, ids: string[], model: typeof BaseModel): void
  findTablePreloader(pageKeyId: string): PageMapPreload
  isPreloaded(pageKeyId: string): boolean
  isFailed(pageKeyId: string, model: typeof BaseModel): boolean
  markAsFailed(pageKeyId: string, tableModelId: string, model: typeof BaseModel): void
  settings?(): PreloaderSettingsType
}

export interface StrictPreloadable {
  preload(): Promise<void>
  isPreloaded(): boolean
  startPreloading(): void
  markAsPreloaded(): void
  markAsNotPreloaded(): void
  markAsFailed(): void
}

export interface ServicePreloadable {
  preloadById(id: string): Promise<any>
  reloadById(id: string): Promise<any>
}
export interface AutocompletePreloadable<T extends BaseModel = BaseModel> {
  autocomplete(
    model: typeof BaseModel,
    search: string,
    searchFields: string[],
    endpointParams: EndpointParams,
    page: number,
    filters: PaginationUrlFilterType,
    perPage?: number,
    sort?: PaginationUrlParametersSortType,
    other?: PaginationUrlOtherType,
  ): Promise<AutocompleteResult<T>>
  isPreloaded(model: typeof BaseModel, page: number, filters?: PaginationUrlFilterType): boolean
  cleanUp(model: typeof BaseModel): void
  settings?(): PreloaderSettingsType
}

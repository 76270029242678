import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { SingleModelResolverParams } from '@/services/resolvers/types'
import type RouterService from '@/services/route/routerService'
import type WrapperManagerService from '@/services/wrappers/wrapperManagerService'
import TmLogicError from '@/core/error/tmLogicError'
import type NewQueryParamsService from '@/services/route/newQueryParamsService'
import type BaseModel from '@/data/models/BaseModel'
import type DomainBaseService from '@/services/domain/domainBaseService'
import type OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { BulkBaseBody } from '@/services/bulk/types'
import { TmDomainError } from '@/core/error/tmDomainError'
import type DomainManagerService from '@/services/domain/domainManagerService'

@injectable()
export default class BaseResolverService {
  constructor(
    @inject(SERVICE_TYPES.WrapperManagerService) protected readonly wrapperManagerService: WrapperManagerService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.NewQueryParamsService) protected readonly newQueryParamsService: NewQueryParamsService,
    @inject(SERVICE_TYPES.DomainManagerService) protected readonly domainManagerService: DomainManagerService,
  ) {}

  public getId(params: SingleModelResolverParams) {
    if (params.id) {
      return params.id
    }

    if (!params.source || !params.requestParam) {
      throw new TmLogicError('There is no source or requestParam')
    }

    switch (params.source) {
      case 'router':
        return this.routerService.getCurrentRoute().params[params.requestParam] as string
      case 'query':
        return this.newQueryParamsService.getQueryParams()[params.requestParam] as string
      default:
        return this.wrapperManagerService.getParams(params.source)[params.requestParam] as string
    }
  }

  public getDomainService(model: typeof BaseModel): DomainBaseService<OrmApiRepository<BaseModel, BulkBaseBody>> {
    try {
      return this.domainManagerService.getService(model)
    } catch (error) {
      if (error instanceof TmDomainError && error.message.toLowerCase().indexOf('not found service') !== -1) {
        throw new TmLogicError(`Domain service for ${model.entity} not specified`)
      }
      throw error
    }
  }
}

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ForwardedCallsService,
  ForwardedCallsRepository,
}

export type ForwardedCallsServices = keyof typeof SERVICES

export const FORWARDED_CALLS_SERVICES_TYPES = makeServicesTypesFromEnum<ForwardedCallsServices>(SERVICES)

import { injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import type MessagesAttachmentFileRepository from '@/data/repositories/domain/messagesAttachmentFileRepository'
import MessagesAttachmentFile from '@/data/models/domain/MessagesAttachmentFile'
import type { TpFormData } from '@/services/forms/types'

@DomainSettings({
  model: MessagesAttachmentFile,
})
@injectable()
export default class MessagesAttachmentFileService extends DomainBaseService<MessagesAttachmentFileRepository> {
  public attachSmsFile(formData: TpFormData, onUploadProgressCallback?: (progressEvent: ProgressEvent) => void) {
    return this.getDomainRepository().attachSmsFile(formData, onUploadProgressCallback)
  }

  public attachMmsFile(formData: TpFormData, onUploadProgressCallback?: (progressEvent: ProgressEvent) => void) {
    return this.getDomainRepository().attachMmsFile(formData, onUploadProgressCallback)
  }

  public attachMessengerFile(formData: TpFormData, onUploadProgressCallback?: (progressEvent: ProgressEvent) => void) {
    return this.getDomainRepository().attachMessengerFile(formData, onUploadProgressCallback)
  }

  public attachFacebookFile(formData: TpFormData, onUploadProgressCallback?: (progressEvent: ProgressEvent) => void) {
    return this.getDomainRepository().attachFacebookFile(formData, onUploadProgressCallback)
  }

  public attachInstagramFile(formData: TpFormData, onUploadProgressCallback?: (progressEvent: ProgressEvent) => void) {
    return this.getDomainRepository().attachInstagramFile(formData, onUploadProgressCallback)
  }
}

import type { RouteRecordRaw } from 'vue-router'

export const getDownloadsRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.services.downloads',
    path: 'downloads',
    meta: {
      tags: ['SERVICES_BAR_LIST'],
      tabTitle: 'pageTabTitle.user.services.downloads',
    },
    component: () => import('@/components/views/services/downloads/Downloads.vue'),
    props: { tab: true },
  },
]

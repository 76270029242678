import type { SerializerFn } from '@/services/serializers/types'
import type { MessagesPriceCountries } from '@/services/domain/messages/types'

export const messagePriceSerializer: SerializerFn = (messagesPrice) => ({
  total: messagesPrice.total,
  parts: messagesPrice.parts,
  countries: {
    ...Object.keys(messagesPrice.countries).reduce((acc: any, el: any) => {
      acc[el] = {
        allowDedicated: messagesPrice.countries[el].allow_dedicated,
        count: messagesPrice.countries[el].count,
        landline: messagesPrice.countries[el].landline,
        max: messagesPrice.countries[el].max,
        countryId: messagesPrice.countries[el].countryId,
        countryName: messagesPrice.countries[el].country_name,
        sum: messagesPrice.countries[el].sum,
      }
      return acc
    }, {} as MessagesPriceCountries),
  },
})

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import { TmFilterStrategyManagerError } from '@/core/error/tmFilterStrategyManagerError'
import type LoggerService from '@/services/loggerService'
import type BaseFilterModel from '@/data/models/filters/BaseFilterModel'
import type { FilterStrategyInterface } from '@/services/tables/filters/strategies/types/filterStrategyInterface'
import { FILTER_TYPE_ARRAY, FILTER_TYPE_BASE, FILTER_TYPE_RANGE } from '@/data/models/filters/types'
import type { BaseFilterStrategy } from '@/services/tables/filters/strategies/baseFilterStrategy'
import type { RangeFilterStrategy } from '@/services/tables/filters/strategies/rangeFilterStrategy'
import type { ArrayFilterStrategy } from '@/services/tables/filters/strategies/arrayFilterStrategy'

@injectable()
export class FilterStrategyManager {
  private readonly strategies = new Map<symbol, FilterStrategyInterface>([
    [FILTER_TYPE_BASE, this.baseFilterStrategy],
    [FILTER_TYPE_RANGE, this.rangeFilterStrategy],
    [FILTER_TYPE_ARRAY, this.arrayFilterStrategy],
  ])

  constructor(
    @inject(SERVICE_TYPES.BaseFilterStrategy) protected readonly baseFilterStrategy: BaseFilterStrategy,
    @inject(SERVICE_TYPES.RangeFilterStrategy) protected readonly rangeFilterStrategy: RangeFilterStrategy,
    @inject(SERVICE_TYPES.ArrayFilterStrategy) protected readonly arrayFilterStrategy: ArrayFilterStrategy,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {}

  public getFilterStrategy(filter: BaseFilterModel): FilterStrategyInterface {
    this.log(`Getting filter strategy for filter: ${filter.getName()}`)

    if (!this.strategies.has(filter.filterType)) {
      throw new TmFilterStrategyManagerError('Expected supported filter type')
    }

    return this.strategies.get(filter.filterType)!
  }

  private log(message: string) {
    this.loggerService.log('filters', message, 'FilterStrategyManager')
  }
}

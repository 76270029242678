import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ReportingHistoricalTablePagerService,
  ReportingOverviewService,
  ReportingHistoricalTableQueryParamsService,
  ReportingHistoricalTableStateService,
  ReportingHistoricalTableExportService,
  ReportingCallsChartService,
  ReportingMessagesChartService,
  ReportingCallsGridable,
  ReportingMessagesGridable,
  ReportingSubAccountsColumnServiceFactory,
  ReportingUsersColumnServiceFactory,
  ReportingNumbersColumnServiceFactory,
  ReportingNumberFilterServiceFactory,
  ReportingCallsColumnServiceFactory,
  ReportingMessagesColumnServiceFactory,
  ReportingHistoricalTableFilterServiceFactory,
  ReportingCallsService,
  ReportingOverviewRepository,
  ReportingSubAccountsRepository,
  ReportingUsersRepository,
  ReportingUsersService,
  ReportingNumbersRepository,
  ReportingCallsRepository,
  ReportingMessagesRepository,
  ReportingSubAccountsFilterServiceFactory,
}

export type ReportingServices = keyof typeof SERVICES

export const REPORTING_SERVICE_TYPES: Record<ReportingServices, symbol> = makeServicesTypes<ReportingServices>(
  enumKeysToArray<ReportingServices>(SERVICES),
)

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type RouterService from '@/services/route/routerService'
import type { TmRoutes } from '@/services/route/routerTypes'
import type TeamSubscriptionService from '@/services/domain/accounts/teamSubscriptionService'
import type UserService from '@/services/domain/user/userService'
import { UserRole } from '@/services/domain/user/types'

@injectable()
export default class PlanExpiredResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.RouterService) private readonly routerService: RouterService,
    @inject(SERVICE_TYPES.TeamSubscriptionService) private readonly teamSubscriptionService: TeamSubscriptionService,
    @inject(SERVICE_TYPES.UserService) private readonly userService: UserService,
  ) {}

  private isPlanExpired() {
    const currentUser = this.userService.currentUser()
    return (
      currentUser.subaccountType !== UserRole.SUPER_ADMIN && this.teamSubscriptionService.hasFailedTeamSubscription()
    )
  }

  public resolve() {
    return this.runResolver()
  }

  public async runResolver() {
    const planExpiredRoute: TmRoutes = {
      name: 'planExpired',
    }
    const currentRoute = this.routerService.getCurrentRoute()
    return currentRoute.name === planExpiredRoute.name
      ? this.resolvePlanExpiredRoute()
      : this.resolveNotPlanExpiredRoute()
  }

  private async resolvePlanExpiredRoute() {
    const composeRoute: TmRoutes = {
      name: 'user.compose.message',
    }
    if (this.isPlanExpired()) {
      return
    }
    await this.routerService.replace(composeRoute)
  }

  private async resolveNotPlanExpiredRoute() {
    const planExpiredRoute: TmRoutes = {
      name: 'planExpired',
    }
    if (!this.isPlanExpired()) {
      return
    }
    await this.routerService.replace(planExpiredRoute)
  }
}

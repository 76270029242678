import { TmApiClientError } from '@/core/error/transport/tmApiClientError'
import type { Optional } from '@/types'
import type { TmTransportHttpErrorCode } from '@/core/error/transport/types'

interface TmApiAccessErrorData {
  code: 403
  message: string
  status?: 'personal-use' | 'closed-acc' | 'closed-subacc'
  emailOrLogin?: string
}

export class TmApiAccessError extends TmApiClientError<TmApiAccessErrorData> {
  public name = 'TmApiAccessError'

  protected override code: TmTransportHttpErrorCode = 403

  private get errorData() {
    return this.data.data || {}
  }

  public getLoginOrEmail(): Optional<string> {
    return this.errorData.emailOrLogin
  }

  public shouldBeMonitored(): boolean {
    return false
  }
}

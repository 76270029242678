import type { Model } from '@vuex-orm/core'
import type { Ref, VNode } from '@/composition/vue/compositionApi'
import type BaseModel from '@/data/models/BaseModel'
import type { STI_TYPE_KEY } from '@/data/models/types'
import type { ConfirmationTextSlot } from '@/services/types'
import type { ButtonColor, StatusColor } from '@/components/shared/types'
import { isRecordUnknown } from '@/utils/typeGuards'
import type { ErrorInitiator } from '@/services/forms/types'
import type { IconName } from '@/assets/icons/icons'
import type BaseFormService from '@/services/forms/baseFormService'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import type { AnyComponent } from '@/components/types'

export type ExcludeMethods<T> = Pick<T, { [K in keyof T]: T[K] extends (_: any) => any ? never : K }[keyof T]>
export type ModelRaw<T> = Omit<T, keyof Model | '$name' | typeof STI_TYPE_KEY> & { id: string }
export type M<T> = Exclude<ModelRaw<T>, (...args: any[]) => any>
export type ModelFields<T> = Partial<keyof ModelRaw<T>>
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
export type RequiredBy<T, K extends keyof T> = Partial<T> & Pick<T, K>
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]
export type DeepPartial<T> = T extends Record<string, unknown> ? { [P in keyof T]?: DeepPartial<T[P]> } : T
export type GetSecondArgumentOfAnyFunction<T> = T extends (first: any, second: infer R, ...args: any[]) => any
  ? R
  : never
export type ArrayAsConstValuesType<T extends readonly any[]> = T extends (infer R)[] ? R : any
export type RecordAsConstValuesType<T extends Record<string, any>> = T extends Record<string, infer R> ? R : any
export type DomainModelConstructor = typeof BaseModel
export type NumbersObject = Record<string, number>
export type NumbersObjectNullable = Record<string, number | null>
// Requires all fields to be listed. The values of fields can be "undefined"
export type PutBody<PostBody> = { [key in keyof Required<Omit<PostBody, 'id'>>]?: PostBody[key] }
export type Factory<T, P extends unknown[] = []> = (...args: P) => T
export type ConstructorType<T> = new (...args: any[]) => T
export type Dict<T = unknown> = Record<string, T>
export type Nullable<T> = T | null
export type Voidable<T> = T | void
export type Optional<T> = T | undefined
export type Maybe<T> = Nullable<T> | Optional<T>
export type ValueOf<T> = T[keyof T]
export type PromiseOr<T> = Promise<T> | T
export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`
}[keyof ObjectType & (string | number)]
export type Primitive = null | undefined | string | number | boolean | symbol | bigint
export type NonEmptyArray<T> = [T, ...T[]]
export type RefOrGetter<T> = Ref<T> | (() => T)
export type ValueOrRefOrGetter<T> = T | RefOrGetter<T>
export type ExtractSafely<T, K> = T extends K ? T : never

export enum PageAvailableFor {
  authenticated,
  notAuthenticated,
  all,
}

export enum ContactImportStatus {
  IN_PROGRESS = 1,
  REMOVED = 2,
  DONE = 3,
  ERROR = 4,
  COMPLETED = 5,
  PARSE_CSV = 6,
  PARSE_EXCEL = 7,
  D = 8,
  LOOKUP = 9,
}

export enum SmsStatus {
  NONE = '',
  SMS_QUEUE = 'q',
  SMS_SCHEDULED_QUEUE = 's',
  SMS_SENDING_ERROR = 'e',
  SMS_ENROUTE = 'r',
  SMS_ACKED = 'a',
  SMS_DELIVERED = 'd',
  SMS_BUFFERED = 'b',
  SMS_FAILED = 'f',
  SMS_UNKNOWN = 'u',
  SMS_REJECTED = 'j',
  SMS_BULK_INSERT = 'i',
  SMS_SCHEDULED_SUSPEND = 'p',
  SMS_QUEUE_SUSPEND = 'h',
  SMS_READ = 'l',
  // these statuses are only used on the client
  SMS_CLIENT_SENDING = '-1',
  SMS_CLIENT_NOT_SENT = '-2',
}

export enum SmsStatusGroup {
  delivered = 'delivered',
  sent = 'sent',
  failed = 'failed',
  rejected = 'rejected',
}

export enum SmsRejectReason {
  MISSING_NUMBER = 'a',
  CONTACT_BLOCKED = 'b',
  RESTRICTED_COUNTRY = 'c',
  SMS_SURVEY_COUNTRY_RESTRICTION = 'd',
  TEN_DLC_REQUIRED = 'e',
  FAILED_NUMBER = 'f',
  REGULTION_GB = 'g',
  URL_SHORTENER = 'h',
  INVALID_NUMBER = 'i',
  SENDING_DISABLED_ON_ACCOUNT = 'j',
  PROVIDER_REQUIRED = 'k',
  DISABLED_VOICE_NUMBER = 'l',
  SUPPLIER_RESTRICTION = 'm',
  MMS_NUMBER_RESTRICTION = 'n',
  CPAAS_AUTH_ISSUE = 'o',
  CPAAS_PHONE_ISSUE = 'p',
  BYOC_PROVIDER_REASON_SINCH = 'q',
  REGULTION_AU = 'r',
  SUSPICIOUS_SESSION = 's',
  TOLL_FREE_UNVERIFIED = 't',
  CONTACT_UNSUBSCRIBED = 'u',
  BYOC_PROVIDER_REASON_VONAGE = 'v',
  BYOC_PROVIDER_REASON_TWILIO = 'w',
  MMS_FORMAT_NOT_SUPPORTED = 'x',
  BYOC_PROVIDER_REASON_BANDWIDTH = 'y',
  SUPPORT = 'z',
  BYOC_FREE_TRIAL_EXPIRED = '1',
  BYOC_NUMBER_NOT_SUPPORT_SMS = '2',
  BYOC_NUMBER_DEACTIVATED = '3',
  BAD_AE_NUMBER = 'æ',
}

export enum SessionSource {
  NONE = '',
  EMAIL = 'E',
  MAGICMESSAGE = 'M',
  TM_ONLINE = 'O',
  TM_MESSENGER = 'T',
  TM_TEXTME = 'X',
  API = 'A',
  TM_WIDGET = 'W',
  TM_MOBILE = 'P',
  AUTOMATION = 'R',
  IOS_MOBILE = 'I',
  ANDROID_MOBILE = 'D',
  WS_CHAT = 'C',
  SURVEY = 'Y',
}

export enum SessionDestination {
  DEST_TEXT_SMS = 't',
  DEST_TEXT_TO_SPEECH = 's',
  DEST_VOICE_BROADCAST = 'v',
  DEST_MMS = 'm',
}

export enum ReceivedSmsSource {
  SMS = 'A_SMS',
  MMS = 'A_MMS',
}

export enum CallDirection {
  OUTBOUND = 'outbound',
  FORWARDED = 'forwarded',
  INBOUND = 'inbound',
  MISSED = 'missed',
}

export type ParamsPartial = Record<string, string | string[]>

export type GetFormSubmitConfigType<T extends BaseFormService<FormBuilderInterface>> = Parameters<T['submit']>[1]

export enum StatusStyle {
  filled = 'filled',
  outlined = 'outlined',
}

type Increment<A extends unknown[]> = [...A, 0]
export type RecursivePropsOfType<T, P, Depth extends number = 0, CurrentDepth extends unknown[] = []> = {
  [K in keyof T]: Exclude<T[K], null> extends P
    ? K extends string
      ? CurrentDepth['length'] extends Depth
        ? K
        : RecursivePropsOfType<T[K], P, Depth, Increment<CurrentDepth>> extends string
          ? K | `${K}.*` | `${K}.${RecursivePropsOfType<Exclude<T[K], null>, P, Depth, Increment<CurrentDepth>>}` | '*'
          : K
      : never
    : never
}[keyof T]

export type Callback<T, A extends any[] = any[]> = (...args: A) => T
export type TransformerCallback<T> = Callback<T, [T]>
export type TransformerAsyncCallback<T> = Callback<Promise<T>, [T]>
export type ConfirmationTextConfig =
  | string
  | {
      translateKey: string
      slots: ConfirmationTextSlot[]
      count?: number
      tag?: string
      class?: string
    }

export type ConfirmationConfigWithoutContent = {
  title?: string
  submitText?: string
  cancelButtonText?: string
  hideCancelButton?: boolean
  submitLoadingText?: string
  submitColor?: ButtonColor
  submitIcon?: IconName
  keepSubmitting?: boolean // Show pending state on the submit button after the submit action until the modal is closed
}

export type ConfirmationConfig = ConfirmationConfigWithoutContent & {
  text?: ConfirmationTextConfig
  textComponent?: AnyComponent | VNode
  textComponentProps?: Record<string, any>
}

export type PromptConfig = Omit<ConfirmationConfig, 'submitLoadingText'> & {
  inputLabel?: string
  inputPlaceholder?: string
  validations?: Callback<boolean, [string]>[]
  invalidInputText?: string
}

export type PromptConfigWithId = PromptConfig & {
  promptId: string
}

export type ConfirmationPasswordConfig = ConfirmationConfigWithoutContent & {
  target: string
}

export enum ContactsForDisplay {
  MyContacts = 'myContacts',
  SharedWithMe = 'sharedWithMe',
}

export type StatusData = {
  value: string
  color: StatusColor
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  AUD = 'AUD',
}
export const isCurrency = (value: unknown): value is Currency => {
  return Object.values(Currency).includes(value as Currency)
}

export type StylesheetSizeUnit = 'px' | 'em' | 'rem' | 'vw' | 'vh' | 'vmin' | 'vmax' | '%'
export type StylesheetSizeProp = `${number}${StylesheetSizeUnit}` | '0' | 'auto'
export type StylesheetTimelineUnit = 's' | 'ms'

export type UrlWrappedAssetPath<T extends string = string> = `url(${T})`
export const enum ImageFormat {
  AVIF = 'avif',
  WEBP = 'webp',
  PNG = 'png',
  JPEG = 'jpeg',
}
export type ImageMetadata = {
  src: string
  width: number
  height: number
  format: ImageFormat
  pixelDensityDescriptor?: `${number}x`
}
export type ResponsivePicture = {
  sources: Record<string, string>
  img: {
    src: string
    w: number
    h: number
  }
}
export const isResponsivePicture = (value: unknown): value is ResponsivePicture =>
  isRecordUnknown(value) &&
  isRecordUnknown(value.img) &&
  typeof value.img.src === 'string' &&
  typeof value.img.w === 'number' &&
  typeof value.img.h === 'number' &&
  isRecordUnknown(value.sources)

export type BoolString = 'true' | 'false'

export type Filterable<T> = T & { excluded?: boolean }

export type UniqueKeys = Array<string | symbol>
export type DynamicImportType<T> = () => Promise<{ default: T }>

export type FormFieldErrorPayload = {
  initiator?: ErrorInitiator
  fieldHtmlElement?: HTMLElement
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { ConfirmationConfig } from '@/types'
import type WrapperManagerService from '@/services/wrappers/wrapperManagerService'
import type { TmWrappers } from '@/wrappers'
import type { AppModule } from '@/config/types'

@injectable()
export default class ConfirmationService {
  private resolver: (...args: any[]) => any

  private confirmationConfig: ConfirmationConfig = {}

  private wrapperId: TmWrappers = 'confirmation'

  constructor(
    @inject(SERVICE_TYPES.WrapperManagerService) protected readonly wrapperManagerService: WrapperManagerService,
  ) {}

  public getWrapperId() {
    return this.wrapperId
  }

  public getConfirmationConfig() {
    return this.confirmationConfig
  }

  public resolve(value = true) {
    if (this.resolver) {
      this.resolver(value)
    }
  }

  public confirm(
    confirmationConfig: ConfirmationConfig,
    wrapperId: TmWrappers = 'confirmation',
    modules?: AppModule[],
  ) {
    return new Promise<boolean>((resolve) => {
      this.wrapperId = wrapperId
      this.confirmationConfig = confirmationConfig
      this.resolver = resolve

      this.wrapperManagerService.open(this.wrapperId, confirmationConfig)
      if (modules) {
        this.wrapperManagerService.setModules(this.wrapperId, modules)
      }
    })
  }

  public getFormId() {
    return ''
  }

  public close() {
    this.wrapperManagerService.close(this.wrapperId)
  }
}

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum STRIPE_BILLING_SERVICES {
  StripePaygPriceRepository,
  StripeMonthlyPlanPriceRepository,
  StripePaymentMethodsRepository,

  StripeBillingProviderService,
  BillingPlansToProviderAdapter,

  LastInvoiceResolverService,

  StripePaymentMethodService,
}

export type StripeBillingServices = keyof typeof STRIPE_BILLING_SERVICES

export const STRIPE_BILLING_SERVICES_TYPES = makeServicesTypesFromEnum<StripeBillingServices>(STRIPE_BILLING_SERVICES)

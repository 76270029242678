import type { StatusColor } from '@/components/shared/types'
import type { AvatarFile } from '@/data/models/domain/Contact'
import { isRecordUnknown } from '@/utils/typeGuards'
import type { TmCountryCode } from '@/services/types'

export enum SmsSurveyStatus {
  STATUS_ACTIVE = 'a',
  STATUS_DRAFT = 'd',
  STATUS_CANCELED = 'c',
  STATUS_QUEUE = 'q',
  STATUS_SCHEDULED = 's',
  STATUS_REMOVED = 'r',
}

export interface SmsTextParameters {
  encoding: string
  charsetLabel: string
}

export interface ScheduledNotSentBulkRecipients {
  contacts: string[]
  groups: string[]
  numbers: string[]
}

export interface ScheduledTextParameters {
  cost: number
  parts: number
  chars: number
  encoding: string
  countries: Record<TmCountryCode, number>
  charsetLabel: string
}

export enum SenderIdStatus {
  accepted = 'A',
  rejected = 'R',
  pending = 'P',
}

export const SenderIdStatusClass: Record<SenderIdStatus, StatusColor> = {
  [SenderIdStatus.accepted]: 'green',
  [SenderIdStatus.rejected]: 'red',
  [SenderIdStatus.pending]: 'orange',
}

export type ScheduledNotSentBulkParameters = {
  audioInfo?: any[]
  createChat?: boolean
  fromId?: number | string | null
  fromType?: number | string | null
  isSchedule?: boolean
  message: {
    from: string
    text: string
  }
  recipients: ScheduledNotSentBulkRecipients
  scheduleParams?: any
  senderId?: number | string | null
  sendingTime?: number
  serviceType?: string
  source?: string
  total?: number
  useFastQueue?: boolean
}

export enum ScheduledNotSentExceptionCode {
  INTERNAL_ERROR = 0,
  LOW_BALANCE = 2,
  INVALID_NUMBER = 5,
  NO_RECIPIENTS = 9,
}

export enum SubaccountsImportStatus {
  WAITING_FOR_PROCESSING = 0,
  DONE = 1,
  INVITES_SENT = 2,
}

export type PinnedContactType = 'contact' | 'list' | 'filtered_view'

export type PinnedContactEntity = {
  entityId: number
  entityType: PinnedContactType
}

export const isAvatarFile = (value: unknown): value is AvatarFile =>
  isRecordUnknown(value) && typeof value.href === 'string'

export enum PriorityEnum {
  Low = '100',
  Normal = '200',
  High = '300',
  Urgent = '400',
}

export type CreatedBy = {
  id: string
  avatarUrl?: string
  email: string
  firstName: string | null
  lastName: string | null
}

import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { TmCountryCode } from '@/services/types'

@OrmModel(modelNames.UNAUTHENTICATED_COUNTRY)
export default class UnauthenticatedCountry extends BaseModel {
  @StringField()
  public id: TmCountryCode

  @StringField()
  public name: string
}

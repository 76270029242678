import { TmApiError } from '@/core/error/transport/tmApiError'
import type { InternalErrorResponseBase, TmAxiosResponse } from '@/services/transport/types'

export abstract class TmApiClientError<
  T extends InternalErrorResponseBase = InternalErrorResponseBase,
> extends TmApiError<TmAxiosResponse<T>> {
  public name = 'TmApiClientError'

  protected errors: T | undefined

  constructor(msg?: string, data?: TmAxiosResponse<T>, stack?: string) {
    super(msg, data, stack)
    if (data?.data) {
      this.message = data.data.message
      this.errors = data.data || {}
    }
  }

  public getErrors(): T | undefined {
    return this.errors
  }

  public setErrors(errors: T | undefined) {
    this.errors = errors
  }

  public override getData<R extends Pick<InternalErrorResponseBase, 'message'>>(): TmAxiosResponse<R> {
    return super.getData() as any
  }
}

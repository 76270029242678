import { inject, injectable } from 'inversify'
import type { PaginationParams, BasePaginationUrlParams, PaginationData } from '@/services/tables/pagination/types'
import AbstractPaginationServiceFactory from '@/services/tables/pagination/abstractPaginationServiceFactory'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type Counter from '@/data/models/tables/Counter'

@injectable()
export default class BasePaginatorServiceFactory extends AbstractPaginationServiceFactory<BasePaginationUrlParams> {
  constructor(@inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService) {
    super(em)
  }

  public isFirstPage(): boolean {
    const { page } = this.getData()

    return page === 1
  }

  public init(params?: BasePaginationUrlParams) {
    this.repo.insertOrUpdateRaw([
      this.buildModelData({
        page: this.getDefaultModelData().page,
        perPage: this.getDefaultModelData().perPage,
        ...(params || {}),
      }),
    ])
    return true // The paging record is missing and should re-init the pagination
  }

  public toQuery(): BasePaginationUrlParams {
    const { page, perPage } = this.getData() || this.getDefaultModelData()
    return { page, perPage }
  }

  public changePerPage(perPage: number) {
    this.setData(this.buildModelData({ page: 1, perPage }))
  }

  public pageRequestParams(): PaginationParams<BasePaginationUrlParams> {
    const { page, perPage } = this.getData()
    return this.buildPageParams(page, perPage)
  }

  protected computeNextPage(counterServiceData?: Counter): PaginationParams<BasePaginationUrlParams> {
    const curParams = this.pageRequestParams()
    const { page } = this.getData()
    let { pageCount } = this.getData()
    if (counterServiceData) {
      pageCount = Math.ceil(counterServiceData.total / curParams.requestParams.perPage)
    }

    if (page >= pageCount) {
      return curParams
    }

    return this.buildPageParams(curParams.requestParams.page + 1, curParams.requestParams.perPage)
  }

  protected computePrevPage(): PaginationParams<BasePaginationUrlParams> {
    const curParams = this.pageRequestParams()
    const { page } = this.getData()
    if (page === 1) {
      return this.pageRequestParams()
    }

    return this.buildPageParams(curParams.requestParams.page - 1, curParams.requestParams.perPage)
  }

  public moveToFirstPage(): PaginationParams<BasePaginationUrlParams> {
    return this.moveToPage(this.getFirstPage())
  }

  public moveToPrevPage(): PaginationParams<BasePaginationUrlParams> {
    return this.moveToPage(this.computePrevPage())
  }

  public moveToNextPage(nextPageData): PaginationParams<BasePaginationUrlParams> {
    return this.moveToPage(this.computeNextPage(nextPageData.counterServiceData))
  }

  public getCurrentPage(): PaginationParams<BasePaginationUrlParams> {
    return this.pageRequestParams()
  }

  public getFirstPage(): PaginationParams<BasePaginationUrlParams> {
    return this.buildPageParams(this.getDefaultModelData().page, this.pageRequestParams().requestParams.perPage)
  }

  public pageKey({ page, perPage }: BasePaginationUrlParams): string {
    return [page, perPage].join('-')
  }

  public isDefaultState() {
    const { page: defaultPage } = this.getDefaultModelData()
    const { page: currentPage } = this.getCurrentPage().requestParams

    return currentPage === defaultPage
  }

  protected getDefaultModelData() {
    return this.buildModelData({ page: 1, perPage: this.settings.perPage })
  }

  protected buildPageParams(page: number, perPage: number) {
    return {
      requestParams: { page, perPage },
      pageKey: this.pageKey({ page, perPage }),
    }
  }

  public getTotal(): number {
    return this.getData().totalCount
  }

  protected buildModelData({ page, perPage }: BasePaginationUrlParams) {
    return {
      id: this.tableModelId,
      page,
      perPage,
      pageKey: this.pageKey({ page, perPage }),
    }
  }

  public onDataLoad(_pagination: PaginationData): void {}

  public setTotalCount(_count: number): void {}
}

import { injectable } from 'inversify'
import Country from '@/data/models/domain/Country'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { Endpoint } from '@/services/endpoints'

@RepoSettings<Endpoint>({
  model: Country,
  fetch: 'countries',
})
@injectable()
export default class CountryRepository extends OrmApiRepository<Country> {
  public findByName(name: string): Country {
    return this.query().where('name', name).first() as Country
  }
}

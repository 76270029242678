<template>
  <tm-scrollbar
    class="h100pr"
    :class="rootScrollClassName"
    :scrollbar-id="rootScrollbarId"
    stable
  >
    <div class="page-scroll-container h100pr">
      <slot />
    </div>
  </tm-scrollbar>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import { rootScrollbarId, rootScrollClassName } from '@/components/shared/types'

export default defineComponent({
  components: {
    TmScrollbar,
  },
  setup() {
    return {
      rootScrollbarId,
      rootScrollClassName,
    }
  },
})
</script>

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum TASKS_SERVICES {
  KanbanService,
  TaskService,
  TaskStageService,
  TaskRepository,
  TaskStageRepository,
  TaskBoardStagesPreloaderService,
  TaskBoardResolver,
  TaskBoardViewSwitcherService,
  TaskListWebsocketService,
  TasksColumnService,
  TasksGridableService,
  TaskSettingsResolver,
  TaskRedirectToFirstBoardResolver,
  TaskBoardFormService,
  TaskStageFormService,
  TaskEditStageFormService,
  CreateTaskFormService,
  FastCreateTaskFormService,
  TaskViewFormService,
  TaskMoveAllFormService,
  TaskStageDeleteFormService,
  TaskBoardPreloaderService,
  TaskChecklistRepository,
  TaskChecklistService,
  TaskChecklistItemRepository,
  TaskChecklistItemService,
  TaskChecklistItemFormService,
  TaskChecklistFormService,
  TaskViewModalResolverService,
  TaskEditorService,
  TaskEditorRepository,
  TaskCommentRepository,
  TaskCommentService,
  TaskCommentFormService,
  TaskBoardArchivedService,
  TaskBoardArchivedRepository,
  TaskBoardArchivatorService,
  TaskBoardsArchivedPreloaderService,
  TaskBoardArchivedSinglePreloaderService,
  TaskCommentSubscriptionResolverService,
  TaskChecklistResolverService,
  TaskAttachmentsFormService,
  TaskAttachmentsService,
}

export type TasksServices = keyof typeof TASKS_SERVICES

export const TASKS_SERVICES_TYPES = makeServicesTypesFromEnum<TasksServices>(TASKS_SERVICES)

<template>
  <external-link
    :href="textMagicSupport"
    :text="text"
    :with-icon="withIcon"
    :icon-size="iconSize"
  />
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import { textMagicSupport } from '@/constants/links'
import ExternalLink from '@/components/shared/ExternalLink.vue'
import type { IconSize, SizeProp } from '@/components/shared/types'

export default defineComponent({
  components: {
    ExternalLink,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    withIcon: {
      type: Boolean,
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
    },
  },
  setup() {
    return {
      textMagicSupport,
    }
  },
})
</script>

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum CONTACTS_BLOCKED_SERVICES {
  BlockedContactsGridable,
  BlockedContactRepository,
  BlockedContactService,
  BlockedContactFormService,
  BlockedContactsColumnServiceFactory,
}

export type ContactsBlockedServices = keyof typeof CONTACTS_BLOCKED_SERVICES

export const CONTACTS_BLOCKED_SERVICES_TYPES =
  makeServicesTypesFromEnum<ContactsBlockedServices>(CONTACTS_BLOCKED_SERVICES)

import type { AxiosResponse } from 'axios'
import type { TfaAdditionalData } from '@/services/auth/types'
import { TmApiPreconditionRequiredError } from '@/core/error/transport/tmApiPreconditionRequiredError'
import type { InternalErrorResponse } from '@/services/transport/types'

export class TmApiNeedSmsTfaError extends TmApiPreconditionRequiredError {
  public name = 'TmApiNeedSmsTfaError'

  public get credentials(): TfaAdditionalData {
    return this.data.data as TfaAdditionalData
  }

  constructor(_?: string, data?: AxiosResponse<InternalErrorResponse>, stack?: string) {
    super('Need to pass 2fa check (sms)', data, stack)
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ContactImportsColumnService,
}

export type ContactsImportListServices = keyof typeof SERVICES

export const CONTACTS_IMPORT_LIST_SERVICE_TYPES: Record<ContactsImportListServices, symbol> =
  makeServicesTypes<ContactsImportListServices>(enumKeysToArray<ContactsImportListServices>(SERVICES))

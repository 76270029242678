import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  RRuleService,
  RRuleTextFormatterService,
}

export type RRuleServices = keyof typeof SERVICES

export const RRULE_SERVICE_TYPES: Record<RRuleServices, symbol> = makeServicesTypes<RRuleServices>(
  enumKeysToArray<RRuleServices>(SERVICES),
)

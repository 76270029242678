import { inject, injectable } from 'inversify'
import type { TmRoutes } from '@/services/route/routerTypes'
import { SERVICE_TYPES } from '@/core/container/types'
import type RouterService from '@/services/route/routerService'

@injectable()
export default class RestrictionPagesRouterService {
  private readonly loginPersonalRoute: TmRoutes = {
    name: 'auth.login.personal',
  }

  private readonly accountSuspendedRoute: TmRoutes = {
    name: 'auth.login.suspended',
  }

  private readonly unavailableLocationRoute: TmRoutes = {
    name: 'auth.signup.unavailable',
  }

  private readonly reopenAccountRoute: TmRoutes = {
    name: 'auth.login.reopenAccount',
  }

  constructor(@inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService) {}

  public goToAccountSuspendedRoute() {
    return this.routerService.replace(this.accountSuspendedRoute)
  }

  public goToLoginPersonalRoute() {
    return this.routerService.replace(this.loginPersonalRoute)
  }

  public goToUnavailableLocationRoute(strategy: 'push' | 'replace' = 'push') {
    if (strategy === 'replace') {
      return this.routerService.replace(this.unavailableLocationRoute)
    }
    return this.routerService.push(this.unavailableLocationRoute)
  }

  public goToReopenAccountRoute() {
    return this.routerService.replace(this.reopenAccountRoute)
  }

  public getPersonalUseRoute(): TmRoutes {
    return this.loginPersonalRoute
  }
}

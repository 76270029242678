import { inject, injectable } from 'inversify'
import { PreloaderSettings } from '@/decorators/preloaderSettings'
import User from '@/data/models/domain/User'
import type { Preloadable, PreloaderType } from '@/services/preloaders/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type ModelPreloaderService from '@/services/preloaders/modelPreloaderService'
import type BaseModel from '@/data/models/BaseModel'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type UserRepository from '@/data/repositories/domain/users/userRepository'
import { TmPreloadError } from '@/core/error/tmPreloadError'
import TmLogicError from '@/core/error/tmLogicError'
import { TmApiServerError } from '@/core/error/transport/tmApiServerError'
import type UserService from '@/services/domain/user/userService'
import type { SubAccountFetchFilter } from '@/services/domain/accounts/subAccounts/types'
import { ServiceGroups } from '@/config/types'

@PreloaderSettings({
  models: [User],
  alias: 'user',
})
@injectable()
export default class UserPreloaderService implements Preloadable {
  constructor(
    @inject(SERVICE_TYPES.ModelPreloaderService) protected readonly modelPreloaderService: ModelPreloaderService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {}

  public isPreloaded(key: string, preloaderType: PreloaderType): boolean {
    return this.modelPreloaderService.isPreloaded(key, preloaderType)
  }

  public startPreloading(key: string, model: typeof BaseModel, preloaderType: PreloaderType) {
    return this.modelPreloaderService.startPreloading(key, model, preloaderType)
  }

  public markAsPreloaded(key: string, model: typeof BaseModel): void {
    return this.modelPreloaderService.markAsPreloaded(key)
  }

  public preload(model: typeof BaseModel): Promise<BaseModel[]> {
    return this.modelPreloaderService.preload(model)
  }

  public preloadById(model: typeof BaseModel, id: string) {
    return this.modelPreloaderService.preloadById(model, id)
  }

  public async preloadCurrentUser(userId: string) {
    const key = this.getKey()
    const repo = this.em.getRepository<UserRepository>(User)

    if (this.modelPreloaderService.isPreloaded(key, ServiceGroups.PRELOADERS)) {
      const user = repo.find(userId)

      if (!user) {
        throw new TmPreloadError('User preloaded but not in store')
      }

      return user
    }

    this.modelPreloaderService.startPreloading(key, User)

    try {
      const result = await repo.fillCurrentUser()
      this.modelPreloaderService.markAsPreloaded(key)
      return result
    } catch (e) {
      if (e instanceof TmApiServerError) {
        this.modelPreloaderService.markAsFailed(key)
      }
      throw e
    }
  }

  public async reloadCurrentUser(userId: string): Promise<User> {
    this.modelPreloaderService.markAsNotPreloaded(this.getKey())
    return this.preloadCurrentUser(userId)
  }

  public isFailed(): boolean {
    return this.modelPreloaderService.isFailed(this.getKey(), User)
  }

  public isFailedByModel(): boolean {
    return this.modelPreloaderService.isFailedByModel(User)
  }

  public isFailedById(
    model: typeof BaseModel,
    id: string,
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ): boolean {
    return this.modelPreloaderService.isFailedById(model, id, preloaderType)
  }

  public reloadById(model: typeof BaseModel, id: string) {
    return this.modelPreloaderService.reloadById(model, id)
  }

  public markAsNotPreloaded(key: string, model: typeof BaseModel): void {
    this.modelPreloaderService.markAsNotPreloaded(key)
  }

  public markAsFailed(key: string): void {
    this.modelPreloaderService.markAsFailed(key)
  }

  public createAndMarkAsPreloaded(key: string, model: typeof BaseModel): void {
    throw new TmLogicError('Method not implemented.')
  }

  public async preloadSubAccounts(filter: SubAccountFetchFilter) {
    const key = `subAccounts-${filter.statuses?.sort().join('')}-${[
      filter.includeRemoved,
      filter.includeParent,
      filter.includeSelf,
    ].join('-')}`

    if (this.modelPreloaderService.isPreloaded(key, ServiceGroups.PRELOADERS)) {
      return this.userService.getSubAccounts(filter)
    }

    this.modelPreloaderService.startPreloading(key, User)

    try {
      const result = await this.userService.fetchSubAccounts(filter)
      this.modelPreloaderService.markAsPreloaded(key)
      return result
    } catch (e) {
      if (e instanceof TmApiServerError) {
        this.modelPreloaderService.markAsFailed(key)
      }
      throw e
    }
  }

  protected getKey() {
    return 'currentUser'
  }
}

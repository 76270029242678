import { cloneDeep } from 'lodash-es'
import { injectable } from 'inversify'
import type { RouteRecordRaw } from 'vue-router'
import type RouterBuilderMiddlewareInterface from '@/services/route/routerBuilderMiddleware/types'
import { getUuid } from '@/utils/uuid'
import PageResolver from '@/components/resolvers/PageResolver.vue'

@injectable()
export default class ResolversRouterBuilderMiddleware implements RouterBuilderMiddlewareInterface {
  public pipe(routes: RouteRecordRaw[]): RouteRecordRaw[] {
    return routes.map((route: RouteRecordRaw) => {
      if (route.children && route.children.length > 0) {
        ;(route as any).children = this.pipe(route.children)
      }

      if (route.meta?.resolvers?.length) {
        const component = route.meta.resolverComponent ? route.meta.resolverComponent : PageResolver
        const { resolvers, ...meta } = cloneDeep(route.meta)
        const key = getUuid()

        const resolverRoute: RouteRecordRaw = {
          name: `resolver-${key}`,
          path: route.path,
          props: {
            resolvers: route.meta.resolvers,
            routeName: route.name,
            key: route.meta.resolverComponent ? `${key}-ForPageResolver` : `${key}-ForResolverComponent`,
          },
          component,
          meta: {
            permission: route.meta.permission,
          },
          children: [{ ...route, path: '' } as any],
          redirect: { name: route.name },
        }
        if (route.name) {
          route.meta = meta
        }

        return resolverRoute
      }

      return route
    })
  }
}

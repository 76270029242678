<template>
  <q-skeleton
    class="tm-skeleton"
    :class="{
      'tm-skeleton--dark': dark,
    }"
    :type="type"
    :tag="tag"
    :animation="animation"
    :width="widthValue"
    :height="height"
  />
</template>

<script setup lang="ts">
import { computed } from '@/composition/vue/compositionApi'
import type { SkeletonTypes } from '@/components/shared/types'
import { randomNumber } from '@/utils/number'

type SkeletonAnimationTypes = 'wave' | 'pulse' | 'pulse-x' | 'pulse-y' | 'fade' | 'blink' | 'none'

type TmSkeletonProps = {
  type?: SkeletonTypes
  animation?: SkeletonAnimationTypes
  dark?: boolean
  tag?: string
  width?: string
  height?: string
  randomWidth?: boolean
}

const props = withDefaults(defineProps<TmSkeletonProps>(), {
  type: 'rect',
  animation: 'wave',
  tag: 'div',
})

const widthValue = computed(() => {
  if (props.width) {
    return props.width
  }
  if (props.randomWidth) {
    return `${randomNumber(20, 80)}%`
  }
  return undefined
})
</script>

<style lang="scss" scoped>
.tm-skeleton {
  background: $input;
  max-width: 100%;

  &--dark {
    background: rgba(20, 20, 43, 0.05);
  }
}
</style>

import { inject, injectable } from 'inversify'
import type { BaseSorterSettingsType } from '@/services/tables/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type LoggerService from '@/services/loggerService'
import type { SorterInterface } from '@/core/tables/types'
import type TableSorterManagerInterface from '@/services/tables/managers/tableSorterManagerInterface'
import type SorterRepository from '@/data/repositories/table/sorterRepository'
import type { LoggerChannels } from '@/config/configDev'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type BaseSorterServiceFactory from '@/services/tables/sort/baseSorterServiceFactory'
import type { Factory } from '@/types'
import { TmTableElementManagerFactoryNotFoundError } from '@/core/error/table/tableManager/tmTableElementManagerFactoryNotFoundError'
import TableElementManagerWithEndpoint from '@/services/tables/managers/tableElementManagerWithEndpoint'
import Sorter from '@/data/models/tables/Sorter'

@injectable()
export default class TableSorterManager
  extends TableElementManagerWithEndpoint<SorterInterface, BaseSorterSettingsType>
  implements TableSorterManagerInterface
{
  protected readonly managerId = 'TableSorterManager'

  protected readonly loggerChannel: LoggerChannels = 'sorter'

  private constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.BaseSorterServiceFactory)
    protected readonly baseSorterServiceFactory: Factory<BaseSorterServiceFactory>,
  ) {
    super(em, loggerService)
  }

  public getFactoryForTable(tableId: string): Factory<SorterInterface> {
    try {
      return super.getFactoryForTable(tableId)
    } catch (e) {
      if (e instanceof TmTableElementManagerFactoryNotFoundError) {
        this.log(`No explicitly set factory found for the "${tableId}" table. Falling back to default one.`)
        return this.baseSorterServiceFactory
      }

      throw e
    }
  }

  public addServiceForTable(tableId: string, settings: BaseSorterSettingsType) {
    const { serviceId } = super.addServiceForTable(tableId, settings)

    this.services[serviceId].setEndpoint(this.getEndpointForTable(tableId))
    this.services[serviceId].setDefaultSorter(settings.defaultSorter)
    this.services[serviceId].setTableId(tableId)
    this.services[serviceId].setEntityName(settings.entityName)

    return {
      serviceId,
      instance: this.services[serviceId],
    }
  }

  protected removeServiceById(tableId: string, serviceId: string) {
    const instance = this.getServiceByServiceId(serviceId)
    this.getSorterRepo().deleteByCondition((model) => model.tableModelId === instance.getTableId())

    super.removeServiceById(tableId, serviceId)
  }

  protected getSorterRepo() {
    return this.em.getRepository<SorterRepository>(Sorter)
  }
}

import { inject, injectable } from 'inversify'
import { pick } from 'lodash-es'
import getDecorators from 'inversify-inject-decorators'
import { SERVICE_TYPES } from '@/core/container/types'
import type RouterService from '@/services/route/routerService'
import {
  type SignupApiPayload,
  type TfaAdditionalData,
  type VerificationEmailResendPayload,
  type LoginRequestBodyTfaCode,
  type LoginRequestBodyTfaCodeAndId,
  type SignupByInvitePayload,
  type SignUpViaOAuthPayload,
  type VerificationEmailResendAvailability,
  type SignupApiPostPayload,
  REDIRECT_AFTER_LOGIN_KEY,
  type SignupByInviteSSOPayload,
} from '@/services/auth/types'
import type UserRepository from '@/data/repositories/domain/users/userRepository'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import { TmApiError } from '@/core/error/transport/tmApiError'
import type LoggerService from '@/services/loggerService'
import { TmStorageError } from '@/core/error/transport/tmStorageError'
import { logError } from '@/decorators/errorCatch'
import type AuthStateRepository from '@/data/repositories/AuthStateRepository'
import AuthState from '@/data/models/AuthState'
import { TmApiNeedSmsTfaError } from '@/core/error/transport/customErrors/tmApiNeedSmsTfaError'
import { TmApiNeedEmailTfaError } from '@/core/error/transport/customErrors/tmApiNeedEmailTfaError'
import type WindowService from '@/services/browser/windowService'
import type { OAuthProvider } from '@/services/domain/user/types'
import { UserStatus } from '@/services/domain/user/types'
import TmLogicError from '@/core/error/tmLogicError'
import type { ModelRelationArray } from '@/services/domain/types'
import User from '@/data/models/domain/User'
import { BroadcastEvent, type IWebSocketService } from '@/services/transport/types'
import { TmApiAccessError } from '@/core/error/transport/tmApiAccessError'
import type { SignUpViaOAuthResponse } from '@/data/repositories/domain/users/types'
import type OAuthSignUpService from '@/services/signUp/oAuthSignUpService'
import type LoggedInStatusService from '@/services/auth/loggedInStatusService'
import { TmAuthNeedVerifyEmailError } from '@/core/error/transport/auth/TmAuthNeedVerifyEmailError'
import { TmApiAuthentificationError } from '@/core/error/transport/tmApiAuthentificationError'
import { deleteCookieByName, getCookieByName } from '@/utils/cookies'
import { TmApiValidationError } from '@/core/error/transport/tmApiValidationError'
import { TmApiServerError } from '@/core/error/transport/tmApiServerError'
import type { TmBaseError } from '@/core/error/tmBaseError'
import { TmApiExpectationFailedError } from '@/core/error/transport/tmApiExpectationFailedError'
import type { AuthLoaderService } from '@/services/authLoaderService'
import { LOGIN_INCORRECT_CREDENTIALS_MARK } from '@/services/auth/types'
import type RestrictionPagesRouterService from '@/services/auth/restrictionPagesRouterService'
import { TmAuthEmailVerificationResendError } from '@/core/error/transport/auth/TmAuthEmailVerificationResendError'
import TmAuthSignUpForbiddenError from '@/core/error/transport/auth/TmAuthSignUpForbiddenError'
import type { LocalStorageServiceInterface } from '@/services/storage/types'
import { PageAvailableFor } from '@/types'
import type { IBroadcastSubscriptionService } from '@/services/types'
import { getContainer } from '@/core/container/container'
import type UserSettingsService from '@/services/domain/user/userSettingsService'
import { TmApiNeedTotpTfaError } from '@/core/error/transport/customErrors/tmApiNeedTotpTfaError'
import type TemplatesTableExpandLocalStorageService from '@/services/resolvers/containers/templatesTableExpandLocalStorageService'
import type BrowserIdentifierService from '@/services/browser/browserIdentifierService'

const { lazyInject } = getDecorators(getContainer())

// This is test class decorating for common example of usage logging feature
@logError(TmStorageError)
@logError(TmApiError)
@injectable()
export default class AuthService {
  // circular dependency UserSettingsService -> UserService -> AuthService
  @lazyInject(SERVICE_TYPES.UserSettingsService) private readonly userSettingsService: UserSettingsService

  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly router: RouterService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.WebSocketService) protected readonly socketService: IWebSocketService,
    @inject(SERVICE_TYPES.OAuthSignUpService) protected readonly oAuthSignUpService: OAuthSignUpService,
    @inject(SERVICE_TYPES.LoggedInStatusService) protected readonly loggedInStatusService: LoggedInStatusService,
    @inject(SERVICE_TYPES.AuthLoaderService) protected readonly authLoaderService: AuthLoaderService,
    @inject(SERVICE_TYPES.RestrictionPagesRouterService)
    protected readonly restrictionPagesRouterService: RestrictionPagesRouterService,
    @inject(SERVICE_TYPES.LocalStorageService) protected readonly localStorageService: LocalStorageServiceInterface,
    @inject(SERVICE_TYPES.SubscriptionService) private readonly subscriptionService: IBroadcastSubscriptionService,
    @inject(SERVICE_TYPES.TemplatesTableExpandLocalStorageService)
    protected readonly templatesTableExpandLocalStorageService: TemplatesTableExpandLocalStorageService,
    @inject(SERVICE_TYPES.BrowserIdentifierService) private readonly browserIdentifierService: BrowserIdentifierService,
  ) {}

  public getCurrentUser(withRelations: ModelRelationArray<User> = []) {
    return this.getUserRepo().getCurrentUser(withRelations)
  }

  public async tryFillCurrentUser(callback?: () => void, isCheckPrefetchData = false) {
    try {
      const user = await this.getUserRepo().fillCurrentUser(isCheckPrefetchData)
      this.loggedInStatusService.setUserIsLoggedIn(true, user.id)
      callback?.()
      return user
    } catch (e) {
      this.loggerService.error('exception', (e as TmBaseError).message)
      callback?.()
      switch (true) {
        case e instanceof TmApiAuthentificationError:
          this.loggedInStatusService.setUserIsLoggedIn(false, null)
          break
        case e instanceof TmApiAccessError:
          await this.router.push({ name: 'user.base' })
          break
        case e instanceof TmApiServerError:
          await this.router.push({ name: 'error' })
          break
        default:
          break
      }
      return undefined
    }
  }

  public async authenticateByLoginAndPassword(username: string, password: string, recaptchaResponse?: string) {
    try {
      await this.getUserRepo().login(
        {
          username,
          password,
        },
        recaptchaResponse,
      )
      await this.onSuccessLogin()
    } catch (e) {
      if (e instanceof TmApiNeedTotpTfaError) {
        await this.openTotpTfaPage(username, e.credentials)
      } else if (e instanceof TmApiNeedSmsTfaError) {
        await this.openSmsTfaPage(username, e.credentials)
      } else if (e instanceof TmApiNeedEmailTfaError) {
        await this.openEmailTfaPage(username, e.credentials)
      } else if (e instanceof TmAuthNeedVerifyEmailError) {
        await this.toVerifyEmailPage(username)
      } else if (e instanceof TmApiValidationError && e.message?.indexOf(LOGIN_INCORRECT_CREDENTIALS_MARK) >= 0) {
        await this.authLoaderService.hideLoader()
        await this.toInitialRoute({ errorMessage: e.message })
      } else {
        throw e
      }
    }
  }

  public async authenticateByTfaCode(data: LoginRequestBodyTfaCode | LoginRequestBodyTfaCodeAndId) {
    await this.getUserRepo().login(data)
    await this.onSuccessLogin()
  }

  public async signup(payload: SignupApiPayload) {
    try {
      const payloadWithUtmLabels = this.addUtmLabelsToSignupPayload(payload)
      const signupResult = await this.getUserRepo().signup(payloadWithUtmLabels)

      this.removeUtmLabelsFromCookies()

      return signupResult
    } catch (error) {
      if (error instanceof TmAuthSignUpForbiddenError) {
        await this.restrictionPagesRouterService.goToUnavailableLocationRoute()
        return undefined
      }

      throw error
    }
  }

  public async signupByInvite(payload: SignupByInvitePayload | SignupByInviteSSOPayload) {
    await this.getUserRepo().signupByInvite(payload)
    await this.onSuccessLogin()
  }

  public async signupByOAuth(payload: SignUpViaOAuthPayload) {
    await this.getUserRepo().signupByOAuth(payload)

    await this.oAuthSignUpService.clearOAuthSignUpData()

    await this.onSuccessLogin()
  }

  public toVerifyEmailPage(email: string) {
    return this.router.push({ name: 'auth.signup.verifyEmail', query: { email } })
  }

  public async toInitialRoute(query?: { errorMessage: string }) {
    return this.router.push({ name: 'auth.login', query })
  }

  public async logout(force = false) {
    if (!force && !this.loggedInStatusService.isUserLoggedIn()) {
      return
    }

    try {
      await this.getUserRepo().logout()
    } catch (e) {
      const isAuthError = e instanceof TmApiAuthentificationError
      if (!isAuthError) {
        throw e
      }
    } finally {
      this.logoutInternal()
    }
  }

  public logoutInternal() {
    this.subscriptionService.broadcastEmit(BroadcastEvent.UserLoggedOut)
    this.socketService.disconnect()
    this.loggedInStatusService.setUserIsLoggedIn(false, null)
  }

  public async logoutRedirect() {
    this.loggedInStatusService.setUserIsLoggedIn(false, null)
    await this.router.push({ name: 'auth.logout' }).catch(() => {})
  }

  public getStatus(): UserStatus {
    return UserStatus.NONE
  }

  public async resendVerification(payload: VerificationEmailResendPayload) {
    try {
      const { data } = await this.getUserRepo().resendVerification(payload)
      return data
    } catch (error) {
      if (error instanceof TmAuthEmailVerificationResendError) {
        return Promise.resolve({ availableToResendAt: error.availableToResendAt })
      }

      throw error
    }
  }

  public sendVerifyCodeOnEmail() {
    return this.getUserRepo().sendVerifyCodeOnEmail()
  }

  public sendVerifyCodeOnPhone() {
    return this.getUserRepo().sendVerifyCodeOnPhone()
  }

  public verifyPhoneByCode(verificationCode: string) {
    const user = this.getCurrentUser()
    if (!user) {
      throw new TmLogicError('Current user not found')
    }
    return this.getUserRepo().verifyPhoneByCode(user.id, verificationCode)
  }

  public requestResetPassword(email: string, recaptchaResponse?: string) {
    return this.getUserRepo().requestResetPassword(email, recaptchaResponse)
  }

  public resetPassword(token: string, password: string) {
    return this.getUserRepo().resetPassword(token, password)
  }

  public resetPasswordByUserId(userId: string) {
    return this.getUserRepo().resetPasswordByUserId(userId)
  }

  public changePassword(oldPassword: string, newPassword: string) {
    return this.getUserRepo().changePassword(oldPassword, newPassword)
  }

  public verifyPasswordCode(confirmationCode: string) {
    return this.getUserRepo().verifyPasswordCode(confirmationCode, this.browserIdentifierService.getUid())
  }

  public resendPasswordVerificationCode() {
    return this.getUserRepo().resendPasswordVerificationCode()
  }

  public verifyEmailCode(confirmationCode: string) {
    return this.getUserRepo().verifyEmailCode(confirmationCode, this.browserIdentifierService.getUid())
  }

  public resendEmailVerificationCode() {
    return this.getUserRepo().resendEmailVerificationCode()
  }

  public async resendSmsCode(tfaToken: string) {
    return this.getUserRepo().resendSmsCode(tfaToken)
  }

  public resendEmailCode(tfaToken: string) {
    return this.getUserRepo().resendEmailCode(tfaToken)
  }

  public validatePasswordResetToken(token: string) {
    return this.getUserRepo().validatePasswordResetToken(token)
  }

  public async completeRegistration(token: string) {
    await this.getUserRepo().completeRegistration(token)
  }

  public getCredentialsWithAdditionalData() {
    return this.getAuthStateRepo().getCredentialsWithAdditionalData()
  }

  public async openSmsTfaPage(username: string, additionalData: TfaAdditionalData) {
    await this.getAuthStateRepo().storeCredentialsWithAdditionalData(username, additionalData)

    await this.router.push({ name: 'auth.smsCode' })
  }

  public async openTotpTfaPage(username: string, additionalData: TfaAdditionalData) {
    await this.getAuthStateRepo().storeCredentialsWithAdditionalData(username, additionalData)

    await this.router.push({ name: 'auth.totpCode' })
  }

  public async openEmailTfaPage(username: string, additionalData: TfaAdditionalData) {
    await this.getAuthStateRepo().storeCredentialsWithAdditionalData(username, additionalData)

    await this.router.push({ name: 'auth.emailCode' })
  }

  public async redirectAfterSuccessLogin() {
    try {
      const { name, params } = JSON.parse(this.localStorageService.get(REDIRECT_AFTER_LOGIN_KEY) || '{}')
      if (name && name !== 'auth.logout' && this.router.resolve(name)) {
        await this.router.replace({ name, params })
        return
      }
    } catch {}

    await this.router.replace({ name: 'user.gettingStarted' })
  }

  public async onSuccessLogin() {
    this.socketService.connect()
    const user = await this.tryFillCurrentUser()
    if (user) {
      this.templatesTableExpandLocalStorageService.init(user.status)
    }
    this.getAuthStateRepo().removeCredentials()

    if (this.userSettingsService.currentUserSettingsOrFail().showTermsUpdate) {
      await this.router.replace({ name: 'privacyPolicy' })
      return
    }
    await this.redirectAfterSuccessLogin()
  }

  public async goToAuthByOAuthProviderPage(provider: OAuthProvider) {
    const link = await this.getUserRepo().getAuthLink(provider)
    this.windowService.open(link)
  }

  public async goToSSOLogin(email: string) {
    const link = await this.getUserRepo().getSSOLoginLink({ email })
    this.windowService.open(link)
  }

  public async authByOAuthProvider(provider: OAuthProvider, authParams: Record<string, any>) {
    try {
      const { status, data } = await this.getUserRepo().loginByOAuthProvider(provider, authParams)

      if (status === 206) {
        const { token, username, email, firstName, lastName } = data as SignUpViaOAuthResponse
        if (!token) {
          return
        }

        await this.oAuthSignUpService.storeOAuthSignUpData({ token, username, firstName, lastName, email })
        await this.router.push({ name: 'auth.signup.oauth' })
        return
      }

      await this.onSuccessLogin()
    } catch (error) {
      if (error instanceof TmApiNeedTotpTfaError) {
        await this.openTotpTfaPage(error.credentials.email!, error.credentials)
        return
      }
      if (error instanceof TmApiNeedSmsTfaError) {
        await this.openSmsTfaPage(error.credentials.email!, error.credentials)
        return
      }
      if (error instanceof TmApiExpectationFailedError) {
        const errorMessage = this.oAuthSignUpService.getExpectationFailedErrorMessage(provider)
        await this.toInitialRoute({ errorMessage })
        return
      }
      throw error
    }
  }

  public verifyEmail(token: string) {
    return this.getUserRepo().fetchVerifyEmail(token)
  }

  public async confirmRegistrationCodeFromEmail({ code, email }: { code: string; email: string }) {
    await this.getUserRepo().confirmRegistrationCodeFromEmail({ code, email })

    await this.onSuccessLogin()
  }

  public async checkEmailCodeResendAvailability(
    email: string,
    newEmail?: string,
  ): Promise<VerificationEmailResendAvailability> {
    try {
      await this.getUserRepo().checkEmailCodeResendAvailability(email, newEmail)
    } catch (error) {
      // if code is 403 and there is availableToResendAt field with timestamp string we will use it
      // in other cases we will return Date.now()

      if (error instanceof TmApiAccessError) {
        const { availableToResendAt } = (error.getData().data as any) || {}

        return {
          availableAt: typeof availableToResendAt === 'string' ? new Date(availableToResendAt).getTime() : Date.now(),
        }
      }
    }

    return { availableAt: Date.now() }
  }

  public checkInvitationToken(token: string) {
    return this.getUserRepo().checkInvitationToken(token)
  }

  public saveCurrentRouteForRedirectAfterLogin() {
    try {
      const { pathname } = new URL(this.windowService.getLocation().href)
      this.router
        .getRouter()
        .isReady()
        .then(() => {
          if (pathname === '' || pathname === '/') {
            return
          }
          const route = this.router.resolve(pathname)
          if (route.name && [undefined, PageAvailableFor.authenticated].includes(route.meta.pageAvailableFor)) {
            this.localStorageService.set(REDIRECT_AFTER_LOGIN_KEY, JSON.stringify(pick(route, ['name', 'params'])))
          }
        })
    } catch {}
  }

  private addUtmLabelsToSignupPayload(payload: SignupApiPayload): SignupApiPostPayload {
    return {
      ...payload,
      utmParams: {
        source: getCookieByName('utm_source'),
        medium: getCookieByName('utm_medium'),
        campaign: getCookieByName('utm_campaign'),
        content: getCookieByName('utm_content'),
        term: getCookieByName('utm_term'),
        gclid: getCookieByName('gclid'),
      },
    }
  }

  private removeUtmLabelsFromCookies() {
    ;['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'gclid'].forEach((item) => {
      deleteCookieByName(item)
    })
  }

  private getAuthStateRepo() {
    return this.em.getRepository<AuthStateRepository>(AuthState)
  }

  public getUserRepo() {
    return this.em.getRepository<UserRepository>(User)
  }
}

import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import PassThrough from '@/components/layout/PassThrough.vue'
import AccountSettingsSecurity from '@/data/models/domain/AccountSettingsSecurity'

export const userAccountsDetailsRoute: RouteRecordRaw = {
  name: 'user.accounts.details',
  path: 'my-account',
  component: () => import('@/components/views/accounts/AccountDetails.vue'),
  meta: {
    modules: [AppModule.AccountSettings],
    tabTitle: 'pageTitle.user.accounts.details',
    tags: ['ACCOUNTS_BAR_LIST'],
    permission: 'accountSettings',
  },
}

export const userAccountsSecurityRoute: RouteRecordRaw = {
  name: 'user.accounts.security',
  path: 'security',
  component: PassThrough,
  meta: {
    tags: ['ACCOUNTS_BAR_LIST'],
    permission: 'accountSettings',
  },
  redirect: { name: 'user.accounts.security.base' },
  children: [
    {
      name: 'user.accounts.security.base',
      path: '',
      component: () => import('@/components/views/accounts/security/AccountSecurity.vue'),
      props: { tab: true },
      meta: {
        tabTitle: 'pageTitle.user.accounts.security',
      },
    },
    {
      name: 'user.accounts.security.2faSetup',
      path: 'two-factor-authentication',
      component: () => import('@/components/views/accounts/security/AccountSecurity2faSetup.vue'),
      meta: {
        tabTitle: 'pageTitle.user.accounts.twoFactorAuthentication',
        parent: 'user.accounts.security',
        permission: 'accountSettings',
        modules: [AppModule.TFAPages],
        accessControlGroups: ['TwoFactorSetupStrategy'],
        resolvers: [
          {
            service: 'ModelResolver',
            params: [AccountSettingsSecurity],
          },
        ],
        resolverComponent: () =>
          import('@/components/shared/templates/settingsPage/resolvers/SettingsPageCommonResolver.vue'),
      },
    },
  ],
}

export const userAccountsNotificationsRoute: RouteRecordRaw = {
  name: 'user.accounts.notifications',
  path: 'notifications',
  meta: {
    tags: ['ACCOUNTS_BAR_LIST'],
    permission: 'accountNotifications',
    modules: [AppModule.Notifications],
    pageContentBgShade: true,
    tabTitle: 'pageTabTitle.user.accounts.notifications',
  },
  component: () => import('@/components/views/accounts/notifications/AccountNotifications.vue'),
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type UserService from '@/services/domain/user/userService'
import type { Resolvable } from '@/services/resolvers/types'

@injectable()
export default class UserResolver implements Resolvable {
  constructor(@inject(SERVICE_TYPES.UserService) protected readonly userService: UserService) {}

  public async resolve() {
    this.userService.loadUser()
  }

  public isFailed() {
    return this.userService.isFailedByToken()
  }
}

import { inject, injectable } from 'inversify'
import type { FilterStrategyInterface } from '@/services/tables/filters/strategies/types/filterStrategyInterface'
import type BaseFilterModel from '@/data/models/filters/BaseFilterModel'
import type { FilterOperationValue } from '@/services/types'
import type { ArrayFilterModel } from '@/data/models/filters/ArrayFilterModel'
import { SERVICE_TYPES } from '@/core/container/types'
import type { BaseFilterStrategy } from '@/services/tables/filters/strategies/baseFilterStrategy'
import type { ParsedFilterType } from '@/services/tables/types'
import type { FilterStrategyGetPopulateDataResult } from '@/services/tables/filters/strategies/types/filterStrategyGetPopulateDataResult'

@injectable()
export class ArrayFilterStrategy implements FilterStrategyInterface {
  constructor(@inject(SERVICE_TYPES.BaseFilterStrategy) protected readonly baseFilterStrategy: BaseFilterStrategy) {}

  public toQueryPart(filter: BaseFilterModel): Partial<FilterOperationValue> {
    const value = filter.getApiValue()
    const operation = (filter as ArrayFilterModel).operation
    const relationField = filter.getRelationField()

    return this.baseFilterStrategy.fieldDataToQueryPart({ operation, relationField, value })
  }

  public getPopulateData(filter: BaseFilterModel, payload: ParsedFilterType[]): FilterStrategyGetPopulateDataResult {
    return this.baseFilterStrategy.getPopulateData(filter, payload)
  }

  public checkFilterValue(filter: BaseFilterModel, valueToCheck: unknown): boolean {
    return this.baseFilterStrategy.checkFilterValue(filter, valueToCheck)
  }
}

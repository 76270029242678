import { inject, injectable } from 'inversify'
import ColumnsFromApiFactory from '@/services/tables/columnsFromApiFactory'
import type { TypedRawDefaultColumns } from '@/core/tables/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ColumnsFromStoreFactory from '@/services/tables/columnsFromStoreFactory'
import type LoggerService from '@/services/loggerService'
import type ColumnsSizeService from '@/services/tables/columnsSizeService'

type TableColumns = 'name' | 'email'

@injectable()
export default class UserAdminsColumnServiceFactory extends ColumnsFromApiFactory {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ColumnsFromStoreFactory) columnsFromStore: () => ColumnsFromStoreFactory,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.ColumnsSizeService) protected readonly columnsSizeService: ColumnsSizeService,
  ) {
    super(em, columnsFromStore, loggerService)
  }

  public getInitColumns(): TypedRawDefaultColumns<TableColumns> {
    return {
      name: {
        columnOrder: 1,
        visible: true,
        growRatio: 2,
        minWidth: this.columnsSizeService.getPersonMinWidth(),
        required: true,
      },
      email: {
        columnOrder: 2,
        visible: true,
        growRatio: 2,
        minWidth: 200,
        required: true,
      },
    }
  }
}

import { AttrField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { ApiV2SettingsCallbackFormat } from '@/data/models/domain/service/api/types'

@OrmModel(modelNames.API_V2_SETTINGS)
export default class ApiV2Settings extends BaseModel {
  @StringField(null)
  public callbackOutUrl: string | null

  @StringField(null)
  public callbackInUrl: string | null

  @StringField()
  public format: ApiV2SettingsCallbackFormat

  @AttrField()
  public ipList: string[]
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import UserPresence from '@/data/models/domain/UserPresence'
import type { ModelRaw } from '@/types'
import type { UserPresenceBody } from '@/services/domain/user/types'

@RepoSettings<Endpoint>({
  model: UserPresence,
})
@injectable()
export default class UserPresenceRepository extends OrmApiRepository<UserPresence> {
  private readonly id = '1'

  public async fetchPresence() {
    const path = this.endpointsService.getPath('userPresence')
    const { data } = await this.getApiSource().get(path)
    if (!data) {
      return null
    }
    return this.storePresence(data)
  }

  public async updatePresence(userPresence: UserPresenceBody) {
    const path = this.endpointsService.getPath('userPresence')
    await this.getApiSource().post(path, userPresence)
    return this.storePresence(userPresence)
  }

  public storePresence(userPresence: Omit<Partial<ModelRaw<UserPresence>>, 'id'>) {
    return this.insertOrUpdate([{ ...userPresence, id: this.id }])
  }

  public findPresence() {
    return this.findEntityByIdOrNull(this.id)
  }
}

function join<T extends Record<string, unknown>, U extends Record<string, unknown>>(
  obj1: T,
  obj2: keyof U extends Exclude<keyof U, keyof T> ? U : never,
) {
  return Object.assign(obj1, obj2) as Record<keyof T | keyof U, T[keyof T] | U[keyof U]>
}

export function objectWithUniqueFieldBuilder<T extends Record<string, unknown> = Record<string, unknown>>(destObj: T) {
  return {
    join: <U extends Record<string, unknown>>(obj2: keyof U extends Exclude<keyof U, keyof T> ? U : never) => {
      const unionObj = join(destObj, obj2)
      return objectWithUniqueFieldBuilder(unionObj)
    },
    getObject: () => destObj as Record<keyof T, T[keyof T]>,
  }
}

import { inject, injectable } from 'inversify'
import type { RedirectByPrevRouteResolverParams, Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type RouterService from '@/services/route/routerService'

@injectable()
export default class RedirectByPrevRouteResolverService implements Resolvable<RedirectByPrevRouteResolverParams> {
  constructor(@inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService) {}

  public isFailed(): boolean {
    return false
  }

  public async resolve({ grantAccessFrom, redirectTo }: RedirectByPrevRouteResolverParams) {
    const prevRoute = this.routerService.getPreviousRoute()
    if (prevRoute?.name && grantAccessFrom.includes(prevRoute.name.toString())) return
    await this.routerService.replace({ name: redirectTo })
  }

  public unresolve() {
    return Promise.resolve(undefined)
  }
}

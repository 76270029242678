import { isNull, isObject, isString } from 'lodash-es'

/**
 * Deep check all object values on empty
 * Empty values are [], '', {}, null here
 * @param {Record<string, any>} obj
 * @returns {boolean}
 */
export const objectEmptyRecursive = (obj: Record<string, any>): boolean => {
  let result = true
  for (const [, value] of Object.entries(obj)) {
    if (isObject(value) || Array.isArray(value)) {
      result = result && objectEmptyRecursive(value)
    } else if (isString(value)) {
      result = result && value.length === 0
    } else {
      result = result && isNull(value)
    }
  }
  return result
}

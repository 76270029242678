import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import RegulationsStatus from '@/data/models/domain/services/regulations/RegulationsStatus'

@RepoSettings<Endpoint>({
  model: RegulationsStatus,
})
@injectable()
export default class RegulationsStatusRepository extends OrmApiRepository<RegulationsStatus> {
  private id = '1'

  public async fetchStatus(): Promise<RegulationsStatus> {
    const path = this.endpointsService.getPath('regulationsStatus')
    const response = await this.getApiSource().get<RegulationsStatus>(path)
    const statuses = response.data as RegulationsStatus
    await this.insertOrUpdate([{ ...statuses, id: this.id } as RegulationsStatus])

    return statuses
  }

  public findStatus(): RegulationsStatus | null {
    return this.find(this.id) ?? null
  }

  public async loadStatus(): Promise<RegulationsStatus> {
    const status: RegulationsStatus | null = this.findStatus()
    if (status) {
      return status
    }

    return this.fetchStatus()
  }
}

import { injectable } from 'inversify'
import type { RouteRecordRaw } from 'vue-router'
import type RouterBuilderMiddlewareInterface from '@/services/route/routerBuilderMiddleware/types'

@injectable()
export default class RoutesGatheringMiddleware implements RouterBuilderMiddlewareInterface {
  public pipe(routes: RouteRecordRaw[]): RouteRecordRaw[] {
    const result = []
    this.addRoutesToList(routes, result)
    // console.log(result)
    return routes
  }

  private addRoutesToList(routes: RouteRecordRaw[], list: string[], path = '') {
    const paths = new Set<string>()
    routes.forEach((route) => {
      if (path) {
        paths.add(path)
      }
      if (route.children?.length) {
        this.addRoutesToList(route.children, list, route.path ? route.path : Array.from(paths).join('/'))
      } else {
        let p = route.path
        if (paths.size) {
          p = Array.from(paths).join('/')
          if (route.path) {
            p += '/' + route.path
          }
        }
        list.push(p)
      }
    })
  }
}

import { merge } from 'lodash-es'
import getConfig, { type CoreUrlsConfig } from '@/config/config'
import configDev from '@/config/configDev'
import type { Config } from '@/core/types'
import { isDev } from '@/utils/system'

class ConfigReader {
  public static config(envSource = import.meta.env): Config {
    if (isDev()) {
      return merge(getConfig(envSource), configDev) as Config
    }

    if (envSource.VUE_APP_ALLOW_REWRITE_CONFIG === 'true' && window.__appConfiguration) {
      const coreUrlsConfig: CoreUrlsConfig = {
        appUrl: window.__appConfiguration.appUrl,
        apiUrl: window.__appConfiguration.apiUrl,
        socketUrl: window.__appConfiguration.socketUrl,
      }

      return merge(getConfig(envSource), coreUrlsConfig) as Config
    }

    return merge(getConfig(envSource), (window as any).__appConfiguration) as Config
  }
}

export default ConfigReader

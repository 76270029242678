<template>
  <q-btn
    ref="btnEl"
    no-caps
    unelevated
    class="tm-btn"
    :ripple="false"
    :outline="false"
    :title="title"
    :class="{
      [`tm-btn--${kebabSize}`]: size,
      [`tm-btn--${color}`]: color,
      'tm-btn--text-link': textLink,
      'tm-btn--icon-only': iconOnly,
      'tm-btn--text-caret': textCaretEnabled,
      'tm-btn--flat-clicked': flatClicked,
      'tm-btn--no-border': noBorder,
      'tm-btn--rounded': rounded,
      'tm-btn--flat': flat,
      'tm-btn--outline': outline,
      'tm-btn--hovered': hovered,
      'tm-btn--clicked': clicked,
      'tm-btn--focused': focused,
      'tm-btn--ignore-expanded': ignoreExpanded,
      [`tm-btn--padding-${xPadding}`]: xPadding,
    }"
    :disable="disable || loading"
    :href="href"
    :tabindex="tabindex"
    :align="align"
    @click="onClick"
  >
    <div
      v-if="skeleton"
      class="tm-btn__skeleton"
    >
      <tm-skeleton
        type="QChip"
        width="50px"
        height="8px"
        dark
      />
    </div>
    <div
      v-else-if="loading"
      class="tm-btn__loading"
      :class="{ 'tm-btn--text-link': textLink }"
    >
      <slot name="loader">
        <tm-spinner
          :class="[{ 'mr-2': !fullLoader && !iconOnly }, { 'align-self-center': textLink }]"
          :color="loadingColor"
        />
      </slot>

      <span v-if="!fullLoader">
        {{ loadingTitle || $t('common.loading') }}
      </span>
    </div>
    <slot v-else>
      {{ title }}
    </slot>
  </q-btn>
</template>

<script lang="ts">
import { kebabCase } from 'lodash-es'
import type { QBtn } from 'quasar'
import { ref, defineComponent, computed } from '@/composition/vue/compositionApi'
import TmSpinner from '@/components/shared/TmSpinner.vue'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import { TmButtonProps } from '@/components/shared/types'
import TmLogicError from '@/core/error/tmLogicError'

export default defineComponent({
  components: {
    TmSkeleton,
    TmSpinner,
  },
  props: TmButtonProps,
  setup(props) {
    const btnEl = ref<InstanceType<typeof QBtn> | undefined>()

    const getRootElement = () => {
      if (!btnEl.value) {
        throw new TmLogicError('btnEl is undefined')
      }
      return btnEl.value.$el as HTMLButtonElement
    }
    const onClick = () => getRootElement().blur()
    const kebabSize = computed(() => kebabCase(props.size))

    return {
      btnEl,
      onClick,
      kebabSize,
      getRootElement,
    }
  },
})
</script>

<style lang="scss" scoped>
.tm-btn {
  will-change: border-color, background-color, color;

  flex-shrink: 0;
  border-radius: $border-radius-x;
  font-weight: $semi-bold-font;
  text-transform: none;
  color: $gray8;
  transition:
    background $transition-100ms-ease-in-out,
    box-shadow $transition-100ms-ease-in-out,
    color $transition-100ms-ease-in-out;

  &::before,
  &::after {
    content: '';
    visibility: hidden;
  }

  &:not(.tm-btn--flat):not(.tm-btn--text-link) {
    box-shadow: $button-shadow-5;
  }

  &--transparent {
    box-shadow: none !important;
  }

  &:focus-visible,
  &--focused {
    outline: 2px solid $blue400 !important;
    outline-offset: 2px;
  }
  &[disabled],
  &[disabled] :deep(*) {
    opacity: 1 !important;
    cursor: default !important;
  }

  &--rounded {
    border-radius: var(--tm-button-rounded, 28px);
  }
  &--text-caret {
    user-select: auto !important;
  }

  &--padding-12px {
    padding: 0 12px !important;
  }

  &,
  &--medium,
  &--large {
    :deep(.tm-icon--left) {
      margin-left: -4px !important;
    }
    :deep(.tm-icon--right) {
      margin-right: -4px !important;
    }
  }
  &--small {
    :deep(.tm-icon--left) {
      margin-left: 0 !important;
    }
    :deep(.tm-icon--right) {
      margin-right: 0 !important;
    }
  }
  &--x-small {
    :deep(.tm-icon--left) {
      margin-left: -4px !important;
      margin-right: 2px !important;
    }
    :deep(.tm-icon--right) {
      margin-left: 2px !important;
      margin-right: -4px !important;
    }
  }

  &.tm-btn--text-link {
    min-height: auto;
    min-width: 0;
    padding: 0;
    vertical-align: inherit;
    color: inherit;
    background: none;

    :deep(.tm-icon--left) {
      margin-left: 0 !important;
    }
    :deep(.tm-icon--right) {
      margin-right: 0 !important;
    }

    &.primary--text,
    &.blue--text {
      &:hover:not([disabled]) {
        color: $gray8 !important;
      }
      [aria-expanded='true'] &:not([disabled]:not(.tm-btn--ignore-expanded)) {
        color: $primary !important;
      }
    }
    &.dark--text,
    &.secondary--text {
      &:hover:not([disabled]) {
        color: $gray8 !important;
      }
      [aria-expanded='true'] &:not([disabled]:not(.tm-btn--ignore-expanded)) {
        color: $secondary !important;
      }
    }
    &.error--text,
    &.red--text {
      &:hover:not([disabled]) {
        color: $red900 !important;
      }
      [aria-expanded='true'] &:not([disabled]:not(.tm-btn--ignore-expanded)) {
        color: $error !important;
      }
    }
    &.success--text,
    &.green--text {
      &:hover:not([disabled]) {
        color: $green900 !important;
      }
      [aria-expanded='true'] &:not([disabled]:not(.tm-btn--ignore-expanded)) {
        color: $success !important;
      }
    }
    &.warning--text,
    &.orange--text {
      &:hover:not([disabled]) {
        color: $orange900 !important;
      }
      [aria-expanded='true'] &:not([disabled]:not(.tm-btn--ignore-expanded)) {
        color: $warning !important;
      }
    }
    &.white--text {
      &:hover:not([disabled]) {
        color: $white !important;
      }
      [aria-expanded='true'] &:not([disabled]:not(.tm-btn--ignore-expanded)) {
        color: $white !important;
      }
    }
  }

  & + & {
    margin-left: 8px;
  }
  &.full-width {
    & + & {
      margin-left: 0;
      margin-top: 8px;
    }
  }

  :deep {
    .q-focus-helper {
      display: none;
    }
    .q-btn__content {
      flex-wrap: nowrap;
      white-space: nowrap;
    }
    .tm-icon--left {
      margin-right: 6px;
    }
    .tm-icon--right {
      margin-left: 6px;
    }
  }

  &__loading {
    display: flex;
    align-items: center;
  }
}

@each $name, $size, $font-size, $x-padding in $btn-sizes-set {
  @if $name == '' {
    .tm-btn {
      @include btn-sizes($size, $font-size, $x-padding);
    }
  } @else {
    .tm-btn--#{$name} {
      @include btn-sizes($size, $font-size, $x-padding);
    }
  }
}

@each $name, $props in $btn-styles {
  .#{$name} {
    @if map-get($props, color) {
      color: map-get($props, color);
    }
    &:not(.tm-btn--text-link) {
      @if map-get($props, background) == $transparent {
        &::before {
          visibility: visible;
          transition:
            background $transition-100ms-ease-in-out,
            box-shadow $transition-100ms-ease-in-out,
            color $transition-100ms-ease-in-out;
        }
      }
      @if map-get($props, background) {
        background: var(--tm-button-background, #{map-get($props, background)});
      }
    }
    &:not(.tm-btn--flat):not(.tm-btn--text-link) {
      @if map-get($props, box-shadow) {
        box-shadow: map-get($props, box-shadow);
      }
    }
    &:not(.tm-btn--no-border):not(.tm-btn--text-link) {
      @if map-get($props, border) {
        border: 1px solid map-get($props, border);
      }
    }

    @if map-get($props, hover-background) {
      &:not(.tm-btn--text-link):hover,
      &.tm-btn--hovered:not(.tm-btn--text-link) {
        @if map-get($props, hover-background) == $transparent {
          background: $transparent;
          &::before {
            background: rgb(164, 178, 210, 0.22);
          }
        } @else {
          background: var(--tm-button-hover-background, #{map-get($props, hover-background)});
        }
      }
    }

    &:not(.tm-btn--text-link):not([disabled]):not(.tm-btn--ignore-expanded) {
      &:active,
      &.tm-btn--clicked,
      &[aria-expanded='true'],
      [aria-expanded='true'] & {
        @if map-get($props, hover-background) == $transparent {
          background: $transparent;
          &::before {
            background: rgb(152, 164, 196, 0.32);
          }
        } @else {
          background: var(--tm-button-clicked-background, #{map-get($props, clicked-background)});
          @if map-get($props, clicked-border) {
            border-color: map-get($props, clicked-border);
          }
        }
        &:not(.tm-btn--flat-clicked) {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16) inset;
        }
      }
    }

    &[disabled] {
      &::before {
        visibility: hidden;
      }
      @if map-get($props, disabled-color) {
        color: map-get($props, disabled-color) !important;
      }
      &:not(.tm-btn--text-link) {
        @if map-get($props, disabled-background) {
          background: var(--tm-button-disabled-background, #{map-get($props, disabled-background)}) !important;
        }
      }
      &:not(.tm-btn--text-link):not(.tm-btn--no-border) {
        @if map-get($props, disabled-border) {
          border-color: map-get($props, disabled-border) !important;
        }
      }
    }
  }
}
</style>

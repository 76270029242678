import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  SmsCampaignRepository,
  SmsCampaignService,

  CampaignsRenameModalFormService,

  CampaignsSmsColumnService,

  CampaignArchivedSmsGridable,
  CampaignSentSmsGridable,

  CampaignFilterServiceFactory,
  CampaignSentFilterServiceFactory,

  CampaignsScheduledColumnService,
  CampaignsScheduledBaseColumnService,
  CampaignsScheduledCompletedColumnService,
  CampaignsScheduledNotSentColumnService,
}

export type SmsCampaignsServices = keyof typeof SERVICES

export const SMS_CAMPAIGNS_SERVICE_TYPES: Record<SmsCampaignsServices, symbol> =
  makeServicesTypes<SmsCampaignsServices>(enumKeysToArray<SmsCampaignsServices>(SERVICES))

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import NumberAvailableCountry from '@/data/models/domain/NumberAvailableCountry'

@RepoSettings<Endpoint>({
  model: NumberAvailableCountry,
})
@injectable()
export default class NumberAvailableCountriesRepository extends OrmApiRepository<NumberAvailableCountry> {
  public async getNumberAvailableCountries(): Promise<NumberAvailableCountry[]> {
    const path = this.endpointsService.getPath('numberAvailableCountries')
    const result = await this.getApiSource().get<{ countryList: NumberAvailableCountry[] }>(path)

    await this.create(result.data.countryList)
    return this.all()
  }
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import Grouping from '@/data/models/tables/Grouping'
import OrmApiRepository from '@/data/repositories/ormApiRepository'

@RepoSettings<Endpoint>({
  model: Grouping,
})
@injectable()
export default class GroupingRepository extends OrmApiRepository<Grouping> {}

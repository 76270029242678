import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ReceivedSmsRepository,
  ReceivedSmsService,
  ReceivedSmsColumnServiceFactory,
  ReceivedSmsFilterServiceFactory,
}

export type HistoryReceivedSmsServices = keyof typeof SERVICES

export const HISTORY_RECEIVED_SMS_SERVICE_TYPES: Record<HistoryReceivedSmsServices, symbol> =
  makeServicesTypes<HistoryReceivedSmsServices>(enumKeysToArray<HistoryReceivedSmsServices>(SERVICES))

import { TmBaseError } from '@/core/error/tmBaseError'

const getErrorMessage = <T>(t?: string | ((entity: T) => boolean)) => {
  if (typeof t === 'string') {
    return `Not found entity in the store. Entity id = "${t}"`
  }
  if (typeof t === 'function') {
    return `Not found entity in the store. Search expression: ${t.toString()}`
  }
  return 'Not found entity in the store'
}

export class TmEntityNotFoundError<T> extends TmBaseError {
  public readonly name = 'EntityNotFoundError'

  constructor(t?: string | ((entity: T) => boolean)) {
    super(getErrorMessage(t))
  }
}

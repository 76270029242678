import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  SubAccountEditPermissionsFormService,
  SubAccountDetailsFormService,
  AuditLogColumnService,
  MessageFromUserBulkManager,
  MessageFromUserBulkService,
}

export type SubAccountsDetailsServices = keyof typeof SERVICES

export const SUB_ACCOUNTS_DETAILS_SERVICE_TYPES: Record<SubAccountsDetailsServices, symbol> =
  makeServicesTypes<SubAccountsDetailsServices>(enumKeysToArray<SubAccountsDetailsServices>(SERVICES))

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { RawDefaultColumns } from '@/core/tables/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type LoggerService from '@/services/loggerService'
import ColumnsFromApiFactory from '@/services/tables/columnsFromApiFactory'
import type ColumnsFromStoreFactory from '@/services/tables/columnsFromStoreFactory'
import type ColumnsSizeService from '@/services/tables/columnsSizeService'

@injectable()
export default class TenDlcPhoneNumbersDetailsColumnServiceFactory extends ColumnsFromApiFactory {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ColumnsFromStoreFactory) columnsFromStore: () => ColumnsFromStoreFactory,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.ColumnsSizeService) protected readonly columnsSizeService: ColumnsSizeService,
  ) {
    super(em, columnsFromStore, loggerService)
  }

  public getInitColumns(): RawDefaultColumns {
    return {
      phone: {
        columnOrder: 1,
        label: 'tableColumns.textmagicNumber',
        visible: true,
        minWidth: this.columnsSizeService.getPhoneMinWidth(),
      },
      account: {
        columnOrder: 2,
        label: 'tableColumns.account',
        visible: true,
        minWidth: this.columnsSizeService.getPersonMinWidth(),
      },
      status: {
        columnOrder: 3,
        label: 'tableColumns.status',
        visible: true,
        growRatio: 0.5,
      },
      nextRenewal: {
        columnOrder: 4,
        label: 'tableColumns.nextRenewal',
        visible: true,
        growRatio: 0.5,
        minWidth: 220,
      },
    }
  }
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import FilterState from '@/data/models/filters/FilterState'
import OrmApiRepository from '@/data/repositories/ormApiRepository'

@RepoSettings({
  model: FilterState,
})
@injectable()
export default class FilterStateRepository extends OrmApiRepository<FilterState> {}

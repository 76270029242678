import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import { ServicesRouteName } from '@/routes/types'

export const getCarrierLookupDetailsRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.services.carrierLookup.flow',
    path: 'carrier-lookup/bulk-carrier-lookup',
    meta: {
      parent: ServicesRouteName,
      tabTitle: 'pageTabTitle.user.services.carrierLookup.flow',
      modules: [AppModule.CarrierLookupSession, AppModule.CarrierLookupImport],
    },
    component: () => import('@/components/views/services/carrierLookup/CarrierLookupFlow.vue'),
  },
]

import type { interfaces } from 'inversify'
import type BaseModel from '@/data/models/BaseModel'
import type { ConstructorType, Dict, ParamsPartial } from '@/types'
import type ORMBaseRepository from '@/data/repositories/ormBaseRepository'
import type { Preloadable } from '@/services/preloaders/types'
import type { GroupedWrapperInterface, WrapperServiceInterface } from '@/services/wrappers/types'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import type BaseFormService from '@/services/forms/baseFormService'
import type DomainBaseService from '@/services/domain/domainBaseService'
import type OrmApiRepository from '@/data/repositories/ormApiRepository'
import type DomainSettingsService from '@/services/domain/domainSettingsService'
import type OrmApiSettingsRepository from '@/data/repositories/ormApiSettingsRepository'
import type { ColumnsInterface, GrouperInterface, SearcherTableInterface, SorterInterface } from '@/core/tables/types'
import type { Resolvable } from '@/services/resolvers/types'
import type BaseFilters from '@/services/tables/filters/baseFilters'
import type FaceterService from '@/services/facets/faceterService'
import type { ValidationRegistratorInterface } from '@/services/validation/validationRegistratorInterface'
import type { CleanUpable } from '@/services/cleanUp/types'
import type { PaginationUrlParams } from '@/services/tables/pagination/types'
import type AbstractPaginationServiceFactory from '@/services/tables/pagination/abstractPaginationServiceFactory'
import type { TitlerInterface } from '@/services/route/titlers/types'
import type { HttpInterceptor } from '@/services/transport/types'
import type { RegisteredServices } from '@/core/container/types'
import { dynamicClassDependsSymbol } from '@/core/container/container'
import { getSymbolFor } from '@/utils/app'
import { REFLECT_METADATA } from '@/decorators/types'
import TmLogicError from '@/core/error/tmLogicError'
import type VuexService from '@/services/vuex/vuexService'
import { getUuid } from '@/utils/uuid'

export enum ServiceGroups {
  DOMAINS = 'DOMAINS',
  REPOSITORIES = 'REPOSITORIES',
  BULK = 'BULK',
  PRELOADERS = 'PRELOADERS',
  PRELOADERS_TABLE = 'PRELOADERS_TABLE',
  PRELOADERS_AUTOCOMPLETE = 'PRELOADERS_AUTOCOMPLETE',
  REPOSITORIES_STORE = 'REPOSITORIES_STORE',
  REPOSITORIES_MOCK = 'REPOSITORIES_MOCK',
  RESOLVERS = 'RESOLVERS',
  VALIDATION_REGISTRATOR = 'VALIDATION_REGISTRATOR',
  HTTP_INTERCEPTOR = 'HTTP_INTERCEPTOR',
  TITLER = 'TITLER',
  WRAPPERS = 'WRAPPERS',
  CLEANUPER_TABLE = 'CLEANUPER_TABLE',
  CLEANUPER_AUTOCOMPLETE = 'CLEANUPER_AUTOCOMPLETE',
  FORM = 'FORM',
  FILTER_SERVICES = 'FILTER_SERVICES',
  SEARCHER_SERVICES = 'SEARCHER_SERVICES',
  SORTER_SERVICES = 'SORTER_SERVICES',
  GROUPER_SERVICES = 'GROUPER_SERVICES',
  PAGINATOR_SERVICES = 'PAGINATOR_SERVICES',
  COLUMN_SERVICES = 'COLUMN_SERVICES',
  FACETER = 'FACETER',
}

export enum AppModule {
  Common = 'common',
  DateTime = 'dateTime',
  SmartBanner = 'smartBanner',
  Chat = 'chat',
  ChatStatistics = 'chatStatistics',
  ChatPreviewNext = 'chatPreviewNext',
  Reporting = 'reporting',
  EmailToSms = 'emailToSms',
  CarrierLookup = 'CarrierLookup',
  CarrierLookupImport = 'CarrierLookupImport',
  CarrierLookupSession = 'CarrierLookupSession',
  SenderSettings = 'senderSettings',
  SenderSettingsCountries = 'senderSettingsCountries',
  EmailLookup = 'emailLookup',
  EmailLookupImport = 'emailLookupImport',
  EmailLookupSession = 'emailLookupSession',
  SmsSurveys = 'smsSurveys',
  AutomationRules = 'automationRules',
  AutomationRulesDetails = 'automationRulesDetails',
  AutomationRulesLog = 'automationRulesLog',
  AutomationRule = 'automationRule',
  Keywords = 'keywords',
  Byoc = 'byoc',
  ByocHelper = 'byocHelper',
  WidgetSubscribe = 'widgetSubscribe',
  WidgetClickToText = 'widgetClickToText',
  UserSession = 'userSession',

  // Unauthorized zone
  Auth = 'auth',
  LoginSSO = 'loginSSO',
  Logout = 'logout',
  SignupByInvitePage = 'signupByInvitePage',
  SignupByOAuthPage = 'signupByOAuthPage',
  TFAPages = 'tfaPages',
  VerifyEmail = 'verifyEmail',
  SignupConfirmation = 'signupConfirmation',
  EmailConfirmation = 'emailConfirmation',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
  CheckEmailForSignup = 'checkEmailForSignup',
  //-------------------
  Notifications = 'notifications',
  AccountSettings = 'accountSettings',
  ServicesApi = 'servicesApi',
  SubAccounts = 'subAccounts',
  SubAccountsBase = 'subAccountsBase',
  SubAccountsDetails = 'subAccountsDetails',
  SubAccountsForm = 'SubAccountsForm',
  SubAccountsImportFlow = 'subAccountsImportFlow',
  SubAccountsImportDetails = 'subAccountsImportDetails',
  SubAccountsImportList = 'subAccountsImportList',
  AutoDetectedCountry = 'autoDetectedCountry',
  Billing = 'billing',
  BillingBase = 'billingBase',
  BillingPaymentMethods = 'BillingPaymentMethods',
  Invoices = 'invoices',
  InvoicesBase = 'invoicesBase',
  HistorySentSms = 'historySentSms',
  SentSmsBase = 'sentSmsBase',
  Templates = 'templates',
  MyData = 'myData',
  HistoryReceivedSms = 'historyReceivedSms',
  HistoryForwardedCalls = 'historyForwardedCalls',
  HistoryOutboundCalls = 'historyOutboundCalls',
  HistoryInboundCalls = 'historyInboundCalls',
  Scheduled = 'scheduled',
  ScheduledUpcoming = 'scheduledUpcoming',
  ScheduledPaused = 'scheduledPaused',
  ScheduledNotSent = 'scheduledNotSent',
  ScheduledCompleted = 'scheduledCompleted',
  ScheduledCalendar = 'scheduledCalendar',
  ScheduledEdit = 'scheduledEdit',
  ScheduledDetails = 'scheduledDetails',
  BuyNumber = 'buyNumber',
  TenDlcTollFree = 'TenDlcTollFree',
  TenDlcTollFreeRequest = 'TenDlcTollFreeRequest',
  Wizard = 'wizard',
  VoiceCall = 'voiceCall',
  Compose = 'compose',
  MessagesBulksProgress = 'messagesBulksProgress',
  Statements = 'statements',

  // Campaigns
  SmsCampaigns = 'smsCampaigns',
  CampaignsSenders = 'campaignsSendersFlow',

  // Contacts
  ContactsUnsubscribed = 'contactsUnsubscribed',
  ContactsBlocked = 'contactsBlocked',
  ContactsBase = 'contactsBase',
  Contacts = 'contacts',
  ContactsImportFlow = 'contactsImportFlow',
  ContactsImportDetails = 'contactsImportDetails',
  ContactsImportList = 'contactsImportList',
  ContactCounter = 'contactCounter',
  ComingSoon = 'comingSoon',
  PinnedContacts = 'pinnedContacts',

  // Segments
  Segments = 'segments',

  // Contact lists
  ContactList = 'contactList',
  ContactListCounter = 'contactListCounter',

  // Messages
  MessageSend = 'msgSend',

  // External deps
  RRule = 'rrule',

  // Activity
  Activity = 'activity',

  // Custom fields
  CustomFields = 'customFields',

  TeamWorkflow = 'teamWorkflow',
  Payment = 'payment',
  ForwardedCalls = 'ForwardedCalls',
  OutboundEmails = 'OutboundEmails',

  NavigationOrder = 'navigationOrder',
  RecentRecipient = 'recentRecipient',
  AuditLogs = 'auditLogs',

  EmailUnsubscribe = 'emailUnsubscribe',
  WhatsApp = 'whatsapp',
  Facebook = 'facebook',
  Instagram = 'Instagram',

  // Messages settings
  WebWidgets = 'webWidgets',

  StripeBilling = 'stripeBilling',

  // Kanban
  Kanban = 'kanban',

  // Tasks
  TasksBase = 'tasksBase',
  Tasks = 'tasks',

  // Deals
  DealsBase = 'dealsBase',
  Deals = 'deals',

  // Tickets
  Tickets = 'tickets',
  TicketsNotifications = 'ticketsNotifications',

  Tags = 'tags',

  FilteredViews = 'filteredViews',

  Typing = 'typing',

  AlsoHere = 'alsoHere',

  ChannelPresence = 'channelPresence',

  PointAi = 'pointAi',

  TmTiptapEditor = 'tmTiptapEditor',
  Recipient = 'recipient',

  // Sandbox
  Sandbox = 'sandbox',
}

type FactoryAfterServiceInstantiateHook<T = unknown> = (
  createdService: T,
  factoryBindingKey: string,
  factoryParam: Dict,
) => T

type BaseService<BindingType extends string> = {
  bindingValue: any
  bindingType?: BindingType
  group?: ServiceGroups | ServiceGroups[]
  id?: string
  loader?: () => any
  routeName?: string
  module?: AppModule
  afterBindingHook?: (key: string, service: Service, containerValue: unknown, context: interfaces.Context) => unknown
  autoCreate?: boolean
}

type FactoryService<T = any> = BaseService<'factory'> & {
  afterServiceInstantiateHook?: FactoryAfterServiceInstantiateHook<T>
}

export type Service = BaseService<'value' | 'class' | 'dynamicClass'> | FactoryService

export type ServicesType<T extends string> = Record<T, Service>

export type ServiceModuleOptions = {
  startup?: boolean
  background?: boolean
}

export type ServiceModule = {
  loader: () => Promise<unknown>
  services: Record<string, symbol>
  promise?: Promise<unknown>
  options?: ServiceModuleOptions
}

const afterBindingFormHook: Service['afterBindingHook'] = (key, serviceValue, containerValue) => {
  // @note This is monkey patching to set each form uniq ID === form injection token
  ;(containerValue as BaseFormService<FormBuilderInterface>).getFormId = () => key as RegisteredServices
}

export const repositoryService = <T extends BaseModel>(service: ConstructorType<ORMBaseRepository<T>>): Service => ({
  bindingValue: service,
  group: ServiceGroups.REPOSITORIES,
  afterBindingHook: (key, serviceValue, _, context) => {
    const settings = Reflect.getMetadata(REFLECT_METADATA.RepoSettings, serviceValue.bindingValue)
    if (!settings) {
      throw new TmLogicError('Please use @RepositorySettings decorator')
    }
    const model = settings.model
    const serviceKey: RegisteredServices = 'VuexService'
    const vuexService = context.container.get<VuexService>(getSymbolFor(serviceKey))
    vuexService.setDefaultMutation(model)
  },
})

export const preloaderService = (service: ConstructorType<Preloadable>): Service => ({
  bindingValue: service,
  group: ServiceGroups.PRELOADERS,
})

export const wrapperService = (
  service: ConstructorType<WrapperServiceInterface | GroupedWrapperInterface<ParamsPartial>>,
): Service => ({
  bindingValue: service,
  group: ServiceGroups.WRAPPERS,
})

export const formService = <B extends FormBuilderInterface>(service: ConstructorType<BaseFormService<B>>): Service => ({
  bindingValue: service,
  group: ServiceGroups.FORM,
  afterBindingHook: afterBindingFormHook,
})

export const domainService = <M extends BaseModel>(
  service: ConstructorType<DomainBaseService<OrmApiRepository<M>> | DomainSettingsService<OrmApiSettingsRepository<M>>>,
): Service => ({
  bindingValue: service,
  group: ServiceGroups.DOMAINS,
})

export const columnService = (service: ConstructorType<ColumnsInterface>): Service => ({
  bindingValue: service,
  bindingType: 'factory',
  group: ServiceGroups.COLUMN_SERVICES,
})

export const resolverService = (service: ConstructorType<Resolvable<any>>): Service => {
  return {
    bindingValue: service,
    group: ServiceGroups.RESOLVERS,
  }
}

export const filterService = (service: ConstructorType<BaseFilters>): Service => ({
  bindingValue: service,
  bindingType: 'factory',
  group: ServiceGroups.FILTER_SERVICES,
})

export const faceterService = (service: ConstructorType<FaceterService>): Service => ({
  bindingValue: service,
  group: ServiceGroups.FACETER,
})

export const validationRegistratorService = (service: ConstructorType<ValidationRegistratorInterface>): Service => ({
  bindingValue: service,
  group: ServiceGroups.VALIDATION_REGISTRATOR,
})

export const cleanuperTableService = (service: ConstructorType<CleanUpable>): Service => ({
  bindingValue: service,
  group: ServiceGroups.CLEANUPER_TABLE,
})

export const cleanuperAutocompleteService = (service: ConstructorType<CleanUpable>): Service => ({
  bindingValue: service,
  group: ServiceGroups.CLEANUPER_AUTOCOMPLETE,
})

export const grouperService = (service: ConstructorType<GrouperInterface>): Service => ({
  bindingValue: service,
  bindingType: 'factory',
  group: ServiceGroups.GROUPER_SERVICES,
})

export const sorterService = (service: ConstructorType<SorterInterface>): Service => ({
  bindingValue: service,
  bindingType: 'factory',
  group: ServiceGroups.SORTER_SERVICES,
})

export const paginatorService = <PUP extends PaginationUrlParams>(
  service: ConstructorType<AbstractPaginationServiceFactory<PUP>>,
): Service => ({
  bindingValue: service,
  bindingType: 'factory',
  group: ServiceGroups.PAGINATOR_SERVICES,
})

export const searcherService = (service: ConstructorType<SearcherTableInterface>): Service => ({
  bindingValue: service,
  bindingType: 'factory',
  group: ServiceGroups.SEARCHER_SERVICES,
})

export const titlerService = (service: ConstructorType<TitlerInterface<unknown>>): Service => ({
  bindingValue: service,
  group: ServiceGroups.TITLER,
})

export const interceptorService = (service: ConstructorType<HttpInterceptor>): Service => ({
  bindingValue: service,
  group: ServiceGroups.HTTP_INTERCEPTOR,
})

export const pureService = (service: unknown, bindingType?: Service['bindingType'], autoCreate?: boolean): Service => ({
  bindingValue: service,
  bindingType,
  autoCreate,
})

export const factoryService = <T extends ConstructorType<any>>(
  service: T,
  afterServiceInstantiateHook?: FactoryAfterServiceInstantiateHook<InstanceType<T>>,
): Service => ({
  bindingValue: service,
  bindingType: 'factory',
  afterServiceInstantiateHook,
})

export const staticValue = (value: unknown): Service => ({
  bindingValue: value,
  bindingType: 'value',
})

type GetType<T> = (name: T) => any

export const dynamicClassService = <T extends RegisteredServices>(
  depends: T[],
  getService: (get: GetType<T>) => Service['bindingValue'],
  group?: ServiceGroups,
): Service => {
  const bindingValue = (get: GetType<T>) => getService(get)
  const mappedDeps: Pick<interfaces.Target, 'serviceIdentifier'>[] = depends.map((t) => {
    return {
      serviceIdentifier: getSymbolFor(t),
    }
  })
  bindingValue[dynamicClassDependsSymbol] = mappedDeps

  return {
    bindingValue,
    bindingType: 'dynamicClass',
    group,
    afterBindingHook(key, serviceValue, containerValue, context) {
      if (group === ServiceGroups.FORM) {
        afterBindingFormHook(key, serviceValue, containerValue, context)
      }
    },
  }
}

export const formFactoryService = <T extends BaseFormService<FormBuilderInterface>>(
  service: ConstructorType<T>,
): FactoryService<T> => {
  return {
    bindingValue: service,
    bindingType: 'factory',
    afterServiceInstantiateHook(createdService, formServiceKey) {
      const formIdPostfix = getUuid()
      createdService.getFormId = () => `${formServiceKey}-${formIdPostfix}` as RegisteredServices
      return createdService
    },
  }
}

import { OrmModel, StringField, NumberField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

export enum KeywordStatus {
  ACTIVE = 'A',
  UNPAID = 'U',
  BLOCKED = 'B',
}

@OrmModel(modelNames.KEYWORD)
export default class Keyword extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public keyword: string

  @StringField()
  public status: KeywordStatus

  @NumberField()
  public usedCount: number

  @StringField()
  public createdAt: string
}

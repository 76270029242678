import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { PaginationUrlFilterType } from '@/services/tables/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type PreloadRepository from '@/data/repositories/preloadRepository'
import type OrmApiRepository from '@/data/repositories/ormApiRepository'
import type BaseModel from '@/data/models/BaseModel'
import type { Preloadable, PreloaderType } from '@/services/preloaders/types'
import Preload from '@/data/models/Preload'
import { TmApiServerError } from '@/core/error/transport/tmApiServerError'
import { TmRepositoryError } from '@/core/error/tmRepositoryError'
import type LoggerService from '@/services/loggerService'
import type BaseFilters from '@/services/tables/filters/baseFilters'
import type SubscriptionService from '@/services/transport/subscriptionService'
import { ModelEventType } from '@/services/transport/types'
import { TmApiNotFoundError } from '@/core/error/transport/tmApiNotFoundError'
import { isByCustomParamsFillable } from '@/data/repositories/types'
import { TmPreloadError } from '@/core/error/tmPreloadError'
import { ServiceGroups } from '@/config/types'

@injectable()
export default class ModelPreloaderService implements Preloadable {
  /* final */ private constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.BaseFilters) protected readonly baseFilters: BaseFilters,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
  ) {}

  public async preloadByCustomParams<P>(key: string, model: typeof BaseModel, params: P) {
    const repo = this.getRepository(model)
    if (!isByCustomParamsFillable(repo)) {
      throw new TmPreloadError('Repository is not ByCustomParamsFillable')
    }

    if (this.isPreloaded(key, ServiceGroups.PRELOADERS)) {
      return repo.findByCustomParams(params)
    }

    this.startPreloading(key, model, ServiceGroups.PRELOADERS)
    try {
      const result = await repo.fillByCustomParams(params)
      this.markAsPreloaded(key)
      return result
    } catch (e) {
      if (e instanceof TmRepositoryError) {
        this.logger.error('preloader', `Repository isn't registered ${model.entity}`)
      }
      if (e instanceof TmApiServerError) {
        this.markAsFailed(key)
      }
      throw e
    }
  }

  public async preloadByParams<T extends OrmApiRepository<any>>(
    key: string,
    model: typeof BaseModel,
    // @todo figure out how and where to use params as extra hash for key
    params: PaginationUrlFilterType = [],
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ): Promise<BaseModel[]> {
    if (this.isPreloaded(key, preloaderType)) {
      return []
    }

    this.startPreloading(key, model, preloaderType)

    try {
      const result =
        Object.values(params).length > 0
          ? await this.getRepository<T>(model).fillByFilter(params)
          : await this.getRepository<T>(model).fillAll()
      this.markAsPreloaded(key)

      return result
    } catch (e) {
      if (e instanceof TmRepositoryError) {
        this.logger.error('preloader', `Repository isn't registered ${model.entity}`)
      }
      if (e instanceof TmApiServerError) {
        this.markAsFailed(key)
      }
      throw e
    }
  }

  public async preloadByIds<T extends OrmApiRepository<any>>(
    model: typeof BaseModel,
    ids: string[],
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ): Promise<BaseModel[]> {
    const idsToPreload = ids.filter((id: string) => !this.isPreloaded(this.getIdByModel(model, id), preloaderType))
    if (idsToPreload.length === 0) {
      return this.getRepository<T>(model).findIn(ids)
    }
    idsToPreload.forEach((id) => {
      this.startPreloading(this.getIdByModel(model, id), model, preloaderType)
    })

    try {
      await this.getRepository<T>(model).fillByFilter([{ id: this.baseFilters.toIdFilter(idsToPreload) }])

      return this.getRepository<T>(model).findIn(ids)
    } catch (e) {
      if (e instanceof TmRepositoryError) {
        this.logger.error('preloader', `Repository isn't registered ${model.entity}`)
      }
      if (e instanceof TmApiServerError) {
        idsToPreload.forEach((id) => {
          this.markAsFailed(this.getIdByModel(model, id))
        })
      }
      throw e
    }
  }

  public async preloadById(
    model: typeof BaseModel,
    id: string,
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ) {
    const key = this.getIdByModel(model, id)
    if (this.isPreloaded(key, preloaderType)) {
      return Promise.resolve()
    }

    const repository = this.getRepository<OrmApiRepository>(model)
    if (!repository.hasSingleSetting()) {
      return Promise.resolve()
    }

    this.startPreloading(key, model, preloaderType)
    try {
      const result = await repository.fill(id)
      this.markAsPreloaded(key)

      return result
    } catch (e) {
      if (e instanceof TmApiServerError || e instanceof TmApiNotFoundError) {
        this.markAsFailed(key)
      }
      throw e
    }
  }

  public async reloadById(
    model: typeof BaseModel,
    id: string,
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ) {
    const key = this.getIdByModel(model, id)
    this.markAsNotPreloaded(key)
    return this.preloadById(model, id, preloaderType)
  }

  public async reload(
    model: typeof BaseModel,
    params: PaginationUrlFilterType = [],
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ) {
    const key = this.getKeyByModel(model)
    this.markAsNotPreloaded(key)

    return this.preloadByParams(key, model, params, preloaderType)
  }

  public async reset(model: typeof BaseModel, params: PaginationUrlFilterType = []) {
    return this.reload(model, params)
  }

  public async preload(model: typeof BaseModel, preloaderType: PreloaderType = ServiceGroups.PRELOADERS) {
    return this.preloadByParams(this.getKeyByModel(model), model, [])
  }

  public startPreloading(
    key: string,
    model: typeof BaseModel,
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ) {
    return this.getPreloadRepo().insertOrUpdateRaw(
      [
        {
          id: key,
          isPreloaded: false,
          isLoading: true,
          preloaderType,
          modelName: model.entity,
          updatedAt: new Date().toISOString(),
        },
      ],
      Preload,
    )
  }

  public markAsPreloaded(key: string) {
    this.getPreloadRepo().update([
      {
        id: key,
        isPreloaded: true,
        isLoading: false,
        isFailed: false,
        updatedAt: new Date().toISOString(),
      },
    ])

    this.subscriptionService.emit(Preload.entity, { ids: [key], eventType: ModelEventType.PRELOADED })
  }

  public createAndMarkAsPreloaded(
    key: string,
    model: typeof BaseModel,
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ) {
    this.startPreloading(key, model, preloaderType)
    this.markAsPreloaded(key)
  }

  public markAsNotPreloaded(key: string) {
    this.getPreloadRepo().update([
      {
        id: key,
        isPreloaded: false,
        isLoading: false,
        updatedAt: new Date().toISOString(),
      },
    ])
  }

  public markAsNotPreloadedByCondition(condition: (key: string) => boolean) {
    this.getPreloadRepo().updateByCondition(
      { isPreloaded: false, isLoading: false, updatedAt: new Date().toISOString() },
      (p: Preload) => {
        if (p.id) {
          return condition(p.id)
        }

        return false
      },
    )
  }

  public find(key: string) {
    return this.getPreloadRepo().find(key)
  }

  public findAll() {
    return this.getPreloadRepo().all()
  }

  public isPreloaded(key: string, preloaderType: PreloaderType = ServiceGroups.PRELOADERS) {
    // // TODO: check if model was preloaded long ago. (updatedAt)
    const preloadModel = this.find(key)

    return preloadModel && preloadModel.isPreloaded && preloadModel.preloaderType === preloaderType
  }

  public deletePreloadersByModel(
    model: typeof BaseModel,
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ): void {
    return this.getPreloadRepo().deleteByCondition(
      (p: Preload) => p.modelName === model.entity && p.preloaderType === preloaderType,
    )
  }

  public isFailedByIds(
    model: typeof BaseModel,
    ids: string[],
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ) {
    return ids.some((id) => this.isFailedById(model, id, preloaderType))
  }

  public isFailedById(
    model: typeof BaseModel,
    id: string,
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ): boolean {
    return this.isFailed(this.getIdByModel(model, id), model, preloaderType)
  }

  public isFailedByModel(model: typeof BaseModel, preloaderType: PreloaderType = ServiceGroups.PRELOADERS) {
    return this.isFailed(this.getKeyByModel(model), model, preloaderType)
  }

  public isFailed(
    key: string,
    model: typeof BaseModel,
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ): boolean {
    const preload = this.getPreloadRepo()
      .query()
      .where((p: Preload) => p.modelName === model.entity && p.preloaderType === preloaderType && p.id === key)
      .first()
    return !!preload && preload.isFailed
  }

  public markAsFailed(key: string) {
    this.getPreloadRepo().update([
      {
        id: key,
        isFailed: true,
        isLoading: false,
        isPreloaded: false,
        updatedAt: new Date().toISOString(),
      },
    ])
  }

  public getIdByModel(model: typeof BaseModel, id: string) {
    return model.entity + id
  }

  protected getKeyByModel(model: typeof BaseModel) {
    return model.entity
  }

  protected getRepository<T extends OrmApiRepository<any>>(model: typeof BaseModel): T {
    return this.em.getRepository<T>(model)
  }

  protected getPreloadRepo() {
    return this.em.getRepository<PreloadRepository>(Preload)
  }
}

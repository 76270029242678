<template>
  <div
    ref="wrapRef"
    class="tm-dropdown-wrap"
    :aria-expanded="internalValue"
    :class="{
      'tm-dropdown-wrap--hidden': hideDropdownWrapper,
      'tm-dropdown-wrap--expanded': internalValue,
    }"
    :disabled="disable ? 'disabled' : null"
  >
    <slot
      :is-show="internalValue"
      :toggle="toggle"
    />

    <q-menu
      ref="qMenu"
      v-model="internalValue"
      v-bind="attrs"
      :target="target"
      :max-height="maxHeight"
      :max-width="maxWidth"
      :class="[
        dropdownClassName,
        contentClass,
        { 'filter-dropdown': filterDropdown },
        { 'tm-dropdown--max-height-unset': maxHeightUnset },
        { small: size === 'small' },
      ]"
      :style="{ '--tm-dropdown-width': width }"
      :auto-close="autoClose"
      :no-parent-event="noParentEvent || disable"
      :persistent="!closeOnOutsideClick"
      :fit="fit"
      no-focus
      :no-position-overflow="false"
      @before-show="beforeShow"
      @before-hide="$emit('before-hide')"
      @hide="onHide"
    >
      <div
        ref="innerMenuRef"
        class="tm-dropdown__main"
        :class="[
          `tm-dropdown__main--border-radius-${borderRadius}`,
          `tm-dropdown__main--${scrollbarStyle}`,
          { 'tm-dropdown__main--strong-shadow': strongShadow },
          { 'tm-dropdown__main--centering-shadow': centeringShadow },
          { 'tm-dropdown__main--overflow-hidden': overflowHidden },
          { 'tm-dropdown__main--unset-background': backgroundUnset },
        ]"
      >
        <tm-button
          v-if="showClose"
          class="tm-dropdown__close"
          size="small"
          flat
          icon-only
          no-border
          @click="close"
        >
          <tm-icon
            name="close"
            size="small"
          />
        </tm-button>
        <div
          ref="menuResizeObserverRef"
          class="tm-dropdown__menu-wrapper"
        >
          <slot
            name="menu"
            v-bind="{ close, qMenu, updatePosition }"
          />
        </div>
      </div>
    </q-menu>
  </div>
</template>

<script lang="ts">
import type { PropType } from '@/composition/vue/compositionApi'
import { computed, defineComponent, nextTick, ref, watch } from '@/composition/vue/compositionApi'
import type { FakeQMenu, ControlPosition } from '@/composition/tooltip/renderTooltip'
import { getTooltipAngleAttrs, renderTooltip } from '@/composition/tooltip/renderTooltip'
import TmButton from '@/components/shared/TmButton.vue'
import type { ScrollbarStyle, SizeProp } from '@/components/shared/types'
import { dropdownClassName, dropdownSizes } from '@/components/shared/types'
import TmIcon from '@/components/shared/TmIcon.vue'
import { useResizeObserver } from '@/composition/resizeObserver'
import { useInsideDropdown } from '@/composition/dropdown'

export default defineComponent({
  name: 'TmDropdown',
  components: { TmIcon, TmButton },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    closeOnContentClick: {
      type: Boolean,
      default: true,
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
    },
    filterDropdown: {
      type: Boolean,
      default: false,
    },
    overflowHidden: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as SizeProp<'' | 'small'>,
      default: '',
    },
    maxHeight: {
      type: String,
      default: dropdownSizes.maxHeight.md,
    },
    maxWidth: {
      type: String,
    },
    maxHeightUnset: {
      type: Boolean,
    },
    width: {
      type: String,
    },
    contentClass: {
      type: String,
      default: '',
    },
    strongShadow: {
      type: Boolean,
    },
    centeringShadow: {
      type: Boolean,
    },
    showClose: {
      type: Boolean,
    },
    target: {
      type: HTMLElement,
    },
    offset: {
      type: Array as PropType<number[]>,
    },
    offsetWithAngle: {
      type: Boolean,
    },
    position: {
      type: String as PropType<ControlPosition>,
      default: 'bottom-right',
    },
    hideDropdownWrapper: {
      type: Boolean,
    },
    noParentEvent: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
    },
    borderRadius: {
      type: String as PropType<'def' | 'md'>,
      default: 'def',
    },
    ignoreAutosize: {
      type: Boolean,
      default: false,
    },
    fit: {
      type: Boolean,
      default: true,
    },
    backgroundUnset: {
      type: Boolean,
    },
    scrollbarStyle: {
      type: String as PropType<ScrollbarStyle>,
      default: 'thumb-with-track-hover',
    },
  },
  emits: {
    'update:model-value': (_val: boolean) => true,
    show: () => true,
    hide: (_val: MouseEvent) => true,
    'before-hide': () => true,
  },
  setup(props, context: any) {
    const qMenu = ref<FakeQMenu>()
    const wrapRef = ref()
    const innerMenuRef = ref()
    const menuResizeObserverRef = ref()
    const internalValue = ref(props.modelValue)
    const { show, hide } = renderTooltip('tm-dropdown')
    const angleAttrs = computed(() => getTooltipAngleAttrs('tm-dropdown', props.position, props.offsetWithAngle))

    const autoClose = ref(props.filterDropdown ? false : props.closeOnContentClick)

    if (!props.ignoreAutosize) {
      useInsideDropdown().provide(true)
    }

    // create reactive attributes without class
    const attrsWithoutClass = computed(() => {
      const clonedAttrs = { ...context.attrs }
      delete clonedAttrs.class
      return clonedAttrs
    })

    const attrs = computed(() => ({
      ...angleAttrs.value,
      ...attrsWithoutClass.value,
      offset: props.offset || angleAttrs.value.offset,
    }))

    watch(
      () => props.modelValue,
      (newValue) => {
        internalValue.value = newValue
      },
    )
    watch(
      () => internalValue.value,
      (newValue) => {
        context.emit('update:model-value', newValue)
      },
    )

    const beforeShow = async () => {
      const anchorEl = props.target || wrapRef.value
      // for correct angle display need event between before-show and show, one nextTick not enough
      await nextTick()
      const currentMaxHeight = innerMenuRef.value?.parentElement.offsetHeight
      await nextTick()
      const menuEl = innerMenuRef.value?.parentElement

      context.emit('show')

      show({
        anchorEl,
        menuEl,
        attrs: attrs.value,
        qMenu,
        maxHeight: Math.min(currentMaxHeight, parseInt(props.maxHeight!, 10)),
      })
    }

    const onHide = (e: MouseEvent) => {
      context.emit('hide', e)
      hide()
    }

    const close = () => {
      internalValue.value = false
    }

    const updatePosition = () => qMenu.value?.updatePosition()

    useResizeObserver(menuResizeObserverRef, updatePosition)

    const getMenuWrapperElement = () => {
      return innerMenuRef.value
    }

    return {
      dropdownClassName,
      attrs,
      autoClose,
      qMenu,
      wrapRef,
      innerMenuRef,
      menuResizeObserverRef,
      internalValue,
      beforeShow,
      onHide,
      close,
      // public
      updatePosition,
      getMenuWrapperElement,
      show: () => qMenu.value?.show(),
      toggle: () => {
        internalValue.value = !internalValue.value
      },
    }
  },
})
</script>

<!-- eslint-disable-next-line vue/enforce-style-attribute -->
<style lang="scss">
// Here we have non-scoped style because dropdown appears at the end on body without special uniq attr
// so scoped styles doesn't work
@import '@/styles/mixins.scss';

.tm-dropdown-wrap {
  display: inline-block;

  &--expanded {
    visibility: visible;
  }

  &--hidden {
    display: none;
  }
}
.tm-dropdown {
  @include offset-tooltip();
}
.tm-dropdown {
  visibility: hidden;
  display: flex !important;
  flex-flow: column;
  overflow: unset !important;
  box-shadow: unset !important;
  background: unset !important;
  border-radius: unset !important;
  width: var(--tm-dropdown-width) !important;

  &--max-height-unset.q-menu {
    max-height: unset !important;
  }
  &__main {
    @include scrollbar-variants();
    position: relative;
    overflow: auto;
    box-shadow: $box-shadow;
    background: $white;
    line-height: 1.5;
    &--border-radius-def {
      border-radius: $border-radius-x;
    }
    &--border-radius-md {
      border-radius: $border-radius-md;
    }
    &--strong-shadow {
      box-shadow: $tooltip-shadow;
    }
    &--centering-shadow {
      box-shadow: $tooltip-centering-shadow;
    }
    &--overflow-hidden {
      display: flex;
      overflow: hidden;

      .tm-dropdown__menu-wrapper {
        display: flex;
        flex-grow: 1;
      }
    }
    &--unset-background {
      background: unset;
    }
  }

  &__menu-wrapper {
    max-width: 100%;
    & > * {
      max-width: 100%;
    }
  }

  &__close {
    position: absolute !important;
    top: 5px !important;
    right: 5px !important;
  }

  .dropdown-list {
    padding: 6px 0;
  }

  &.filter-dropdown {
    min-width: 170px;
  }
  &.small {
    padding: 5px 0;
    .tm-dropdown-item {
      padding: 4px 20px;
      min-height: 25px;
      border-bottom: 0;
      .tm-icon {
        margin-right: 4px;
      }
    }
  }
}
</style>

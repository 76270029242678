export const enum ByocPlanStatus {
  TRIAL = 'T',
  ACTIVE = 'A',
  OUTSTANDING = 'O',
  CANCELLED = 'C',
  EXPIRED = 'E',
}

export type ByocProviderPhoneStatus = 'free' | 'used' | 'currentlyUsed'

export enum ByocProvider {
  TWILIO = 'twilio',
  VONAGE = 'vonage',
  BANDWIDTH = 'bandwidth',
  SINCH = 'sinch',
  INFOBIP = 'infobip',
  MESSAGEBIRD = 'messagebird',
  AWS = 'aws',
  TELNYX = 'telnyx',
}

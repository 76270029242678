import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import Counter from '@/data/models/tables/Counter'
import { TmRepositoryError } from '@/core/error/tmRepositoryError'
import type { PaginationUrlType } from '@/services/tables/types'
import type { PaginationParams } from '@/services/tables/pagination/types'
import { httpBuildQuery } from '@/utils/url'
import type { Dict } from '@/types'

@RepoSettings<Endpoint>({
  model: Counter,
})
@injectable()
export default class CounterRepository<T extends Counter> extends OrmApiRepository<T> {
  public async fetchCount(
    id: string,
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
    searchFields?: string[],
  ) {
    if (!this.hasCountEndpoint()) {
      throw new TmRepositoryError('Please define count endpoint in settings()')
    }
    const filterValues = this.filterQueryService.serializeFiltersToBracketNotation({
      filters: queryParameterBag.filter,
    })
    const params = Object.assign(
      this.createGridRequestParams(queryParameterBag, paginationParamsBag, searchQuery, searchFields),
      filterValues,
    )
    const url = this.endpointsService.getPath(this.getEndpoint()!)
    const result = await this.getApiSource().get<T>(url, {
      params,
      paramsSerializer: (serializableParams: Dict) => httpBuildQuery(serializableParams),
    })

    await this.insertOrUpdate([{ ...result.data, id }])
  }

  protected getEndpoint() {
    return this.settings().countEndpoint
  }

  protected hasCountEndpoint(): boolean {
    return !!this.getEndpoint()
  }
}

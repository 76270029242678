export const tenDlcRouteNamespace = 'tenDlc'

export const tollFreeRouteNamespace = 'tollFree'
export type TenDlcTollFreeRouteNamespace = typeof tenDlcRouteNamespace | typeof tollFreeRouteNamespace

export enum TenDlcTollFreeRoutes {
  base = 'usaFlow',
  chooseNumber = 'usaFlow.chooseNumber',
  tenDlcBase = `usaFlow.${tenDlcRouteNamespace}.base`,
  tenDlcInfo = `usaFlow.${tenDlcRouteNamespace}.info`,
  tenDlcRegisterBrand = `usaFlow.${tenDlcRouteNamespace}.registerBrand`,
  tenDlcRegisterCampaign = `usaFlow.${tenDlcRouteNamespace}.registerCampaign`,
  tenDlcSelectCampaign = `usaFlow.${tenDlcRouteNamespace}.selectCampaign`,
  tenDlcSummary = `usaFlow.${tenDlcRouteNamespace}.summary`,
  tenDlcSuccess = `usaFlow.${tenDlcRouteNamespace}.success`,
  tenDlcSmsPrivacyPolicy = `usaFlow.${tenDlcRouteNamespace}.smsPrivacyPolicy`,
  tenDlcSmsTerms = `usaFlow.${tenDlcRouteNamespace}.smsTerms`,
  tollFreeBase = `usaFlow.${tollFreeRouteNamespace}.base`,
  tollFreeInfo = `usaFlow.${tollFreeRouteNamespace}.info`,
  tollFreeRegisterBrand = `usaFlow.${tollFreeRouteNamespace}.registerBrand`,
  tollFreeRegisterCampaign = `usaFlow.${tollFreeRouteNamespace}.registerCampaign`,
  tollFreeSummary = `usaFlow.${tollFreeRouteNamespace}.summary`,
  tollFreeSuccess = `usaFlow.${tollFreeRouteNamespace}.success`,
}

export enum ComingSoonRoutes {
  users = 'user.comingSoon.users.overview',
  tasks = 'user.comingSoon.tasks.overview',
  tickets = 'user.comingSoon.tickets.overview',
  deals = 'user.comingSoon.deals.overview',
  liveChat = 'user.comingSoon.liveChat.overview',
  facebook = 'user.comingSoon.facebook.overview',
  crm = 'user.comingSoon.crm.overview',
}

export const ServicesRouteName = 'user.services'

export const ChatSettingsRoutes = {
  root: 'user.chats.settings',
  allSettings: 'user.chats.settings.all',
  smsMmsCalls: 'user.chats.settings.smsMmsCalls',
  smsMmsCallsNumbers: 'user.chats.settings.smsMmsCalls.numbers',
  smsMmsCallsSenderIds: 'user.chats.settings.smsMmsCalls.senderIds',
  whatsapp: 'user.chats.settings.whatsapp',
  whatsappIndex: 'user.chats.settings.whatsappIndex',
  whatsappSettings: 'user.chats.settings.whatsappSettings',
  whatsappFlow: 'user.chats.settings.whatsapp.flow',
  whatsappAccount: 'user.chats.settings.whatsapp.account',
  whatsappAccountIndex: 'user.chats.settings.whatsapp.account.index',
  whatsappPhone: 'user.chats.settings.whatsapp.phone',
  facebook: 'user.chats.settings.facebook',
  instagramIndex: 'user.chats.settings.instagram.index',
  instagramSettings: 'user.chats.settings.instagram.settings',
  instagramDetails: 'user.chats.settings.instagram.details',
  instagramFlow: 'user.chats.settings.instagram.flow',
  widgets: 'user.chats.settings.web-widget',
  widgetsIndex: 'user.chats.settings.web-widget.index',
  widgetEdit: 'user.chats.settings.web-widget.edit',
  widgetPreview: 'user.chats.settings.web-widget.preview',
  tenDlc: 'user.chats.settings.10dlc',
  tollFree: 'user.chats.settings.tollFree',
  general: 'user.chats.settings.general',
  senderSettings: 'user.chats.settings.senderSettings',
} as const

export const enum ChatSettingsAliasedRoutes {
  buyNumber = '/messenger/settings/sms-mms-calls/numbers/buy-number',
  buyNumberNumber = '/messenger/settings/sms-mms-calls/numbers/buy-number/number',
  buyNumberForwarding = '/messenger/settings/sms-mms-calls/numbers/buy-number/call-forwarding/:id',

  complianceRoot = '/messenger/settings/sms-mms-calls/buy-number/compliance',
  complianceChooseNumber = '/messenger/settings/sms-mms-calls/buy-number/compliance/choose-number',

  complianceTenDlcInfo = `${ChatSettingsAliasedRoutes.complianceRoot}/10dlc/info/:id`,
  complianceTenDlcRegisterBrand = `${ChatSettingsAliasedRoutes.complianceRoot}/10dlc/register-brand/:id`,
  complianceTenDlcRegisterCampaign = `${ChatSettingsAliasedRoutes.complianceRoot}/10dlc/register-campaign/:id`,
  complianceTenDlcSelectCampaign = `${ChatSettingsAliasedRoutes.complianceRoot}/10dlc/select-campaign/:id`,
  complianceTenDlcSummary = `${ChatSettingsAliasedRoutes.complianceRoot}/10dlc/summary/:id`,
  complianceTenDlcSuccess = `${ChatSettingsAliasedRoutes.complianceRoot}/10dlc/success`,
  complianceTenDlcSenderSettings = '/messenger/settings/10dlc/campaign/:campaignId',
  complianceTenDlcSenderSettingsNumbers = '/messenger/settings/10dlc/campaign/:campaignId/numbers',

  complianceTollFreeInfo = `${ChatSettingsAliasedRoutes.complianceRoot}/toll-free/info/:id`,
  complianceTollFreeRegisterBrand = `${ChatSettingsAliasedRoutes.complianceRoot}/toll-free/register-brand/:id`,
  complianceTollFreeRegisterCampaign = `${ChatSettingsAliasedRoutes.complianceRoot}/toll-free/register-campaign/:id`,
  complianceTollFreeSummary = `${ChatSettingsAliasedRoutes.complianceRoot}/toll-free/summary/:id`,
  complianceTollFreeSuccess = `${ChatSettingsAliasedRoutes.complianceRoot}/toll-free/success`,
}

export const tenDlcTollFreeRouteNames = [
  TenDlcTollFreeRoutes.base,
  TenDlcTollFreeRoutes.chooseNumber,
  TenDlcTollFreeRoutes.tollFreeBase,
  TenDlcTollFreeRoutes.tollFreeRegisterBrand,
  TenDlcTollFreeRoutes.tollFreeRegisterCampaign,
  TenDlcTollFreeRoutes.tollFreeSummary,
  TenDlcTollFreeRoutes.tollFreeSuccess,
  TenDlcTollFreeRoutes.tenDlcBase,
  TenDlcTollFreeRoutes.tenDlcInfo,
  TenDlcTollFreeRoutes.tenDlcRegisterBrand,
  TenDlcTollFreeRoutes.tenDlcRegisterCampaign,
  TenDlcTollFreeRoutes.tenDlcSelectCampaign,
  TenDlcTollFreeRoutes.tenDlcSummary,
  TenDlcTollFreeRoutes.tenDlcSuccess,
  ChatSettingsRoutes.tenDlc,
  ChatSettingsRoutes.tollFree,
  ChatSettingsAliasedRoutes.complianceRoot,
  ChatSettingsAliasedRoutes.complianceChooseNumber,
  ChatSettingsAliasedRoutes.complianceTenDlcInfo,
  ChatSettingsAliasedRoutes.complianceTenDlcRegisterBrand,
  ChatSettingsAliasedRoutes.complianceTenDlcRegisterCampaign,
  ChatSettingsAliasedRoutes.complianceTenDlcSelectCampaign,
  ChatSettingsAliasedRoutes.complianceTenDlcSummary,
  ChatSettingsAliasedRoutes.complianceTenDlcSuccess,
  ChatSettingsAliasedRoutes.complianceTenDlcSenderSettings,
  ChatSettingsAliasedRoutes.complianceTenDlcSenderSettingsNumbers,
  ChatSettingsAliasedRoutes.complianceTollFreeInfo,
  ChatSettingsAliasedRoutes.complianceTollFreeRegisterBrand,
  ChatSettingsAliasedRoutes.complianceTollFreeRegisterCampaign,
  ChatSettingsAliasedRoutes.complianceTollFreeSummary,
  ChatSettingsAliasedRoutes.complianceTollFreeSuccess,
]

export const PointAiRoutes = {
  root: 'user.services.pointAi',
  main: 'user.services.pointAi.main',
  websites: 'user.services.pointAi.main.websites',
  documents: 'user.services.pointAi.main.documents',
  websitesDownloaded: 'user.services.pointAi.websitesDownloaded',
} as const

<template>
  <details-table>
    <details-table-item loading />

    <details-table-item loading />

    <details-table-item loading />

    <details-table-item
      loading
      :cell-align-center="true"
    >
      <tm-skeleton
        type="circle"
        height="28px"
        width="28px"
        class="mr-2"
      />
      <tm-skeleton
        type="QChip"
        width="59px"
        height="12px"
      />
    </details-table-item>

    <details-table-item
      loading
      :cell-align-center="true"
    >
      <tm-skeleton
        type="QChip"
        height="12px"
        width="14px"
        class="mr-2"
      />
      <tm-skeleton
        type="QChip"
        height="12px"
        width="90px"
      />
    </details-table-item>

    <details-table-item
      loading
      :cell-align-center="true"
    >
      <div class="w100pr">
        <tm-skeleton
          type="QChip"
          height="12px"
          random-width
          class="mb-2"
        />
        <tm-skeleton
          type="QChip"
          height="12px"
          random-width
        />
      </div>
    </details-table-item>

    <details-table-item loading />

    <details-table-item loading />

    <details-table-item loading />

    <details-table-item loading />

    <details-table-item loading />

    <details-table-item loading />
  </details-table>
</template>

<script lang="ts">
// layout name TmDetailsTableLoading.vue
import { defineComponent } from '@/composition/vue/compositionApi'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'

export default defineComponent({
  components: {
    DetailsTable,
    DetailsTableItem,
    TmSkeleton,
  },
})
</script>

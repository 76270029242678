import { computed, nextTick, ref, watch } from '@/composition/vue/compositionApi'
import { useBreakpoints } from '@/composition/breakpoints'
import { getGlobalStateService } from '@/core/container/ioc'

export type AvailableTabs = 'details' | 'pointAi'

const _isSidebarOpen = ref(false)
const _isOverlaySidebarOpen = ref(false)
const _isVisibleCustomFields = ref(false)
const _showAnimation = ref(false)
const _sidebarTransition = 'transition-width'
const _sidebarButtonTransition = 'transition-button-width'
const _backdropTransition = 'transition-backdrop-opacity'
const _activeTab = ref<AvailableTabs>('details')

export const useChatDetailsSidebarState = () => {
  const globalStateService = getGlobalStateService()
  const { isLargeLaptop, is3xlMin } = useBreakpoints()
  const isSidebarOpen = computed(() => (isLargeLaptop.value ? _isOverlaySidebarOpen.value : _isSidebarOpen.value))
  const isVisibleCustomFields = computed(() => _isVisibleCustomFields.value)
  const sidebarTransition = computed(() => (_showAnimation.value ? _sidebarTransition : ''))
  const sidebarButtonTransition = computed(() => (_showAnimation.value ? _sidebarButtonTransition : ''))
  const backdropTransition = computed(() => (_showAnimation.value ? _backdropTransition : ''))
  const activeTab = computed(() => _activeTab.value)
  const isSidebarStaticPosition = computed(() => is3xlMin.value)

  const setActiveTab = (tab: AvailableTabs) => {
    _activeTab.value = tab
  }
  const toggleSidebar = (isOpen: boolean, showAnimation = false) => {
    _showAnimation.value = showAnimation
    nextTick(() => {
      _showAnimation.value = false
    })

    if (isLargeLaptop.value) {
      _isOverlaySidebarOpen.value = isOpen
    } else {
      _isSidebarOpen.value = isOpen
      globalStateService.setFieldValue('isChatDetailsSidebarOpen', isOpen)
    }
  }
  const openSidebar = (showAnimation = false) => {
    toggleSidebar(true, showAnimation)
  }
  const closeSidebar = (showAnimation = false) => {
    toggleSidebar(false, showAnimation)
  }
  const setState = ({ sidebarOpen, visibleCustomFields }: { sidebarOpen: boolean; visibleCustomFields: boolean }) => {
    _isSidebarOpen.value = sidebarOpen
    _isVisibleCustomFields.value = visibleCustomFields
  }
  const toggleVisibleCustomFields = (isOpen: boolean) => {
    _isVisibleCustomFields.value = isOpen
    globalStateService.setFieldValue('isChatDetailsSidebarVisibleCustomFields', isOpen)
  }

  watch(isLargeLaptop, () => {
    _isOverlaySidebarOpen.value = false
  })

  return {
    activeTab,
    isSidebarOpen,
    isVisibleCustomFields,
    sidebarTransition,
    sidebarButtonTransition,
    backdropTransition,
    setActiveTab,
    openSidebar,
    closeSidebar,
    setState,
    toggleVisibleCustomFields,
    isSidebarStaticPosition,
  }
}

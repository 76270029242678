<template>
  <div class="tm-loader">
    <tm-spinner
      :size="spinnerSize"
      class="tm-loader__spinner"
    />
    <div class="tm-loader__title">
      <slot name="title" />
    </div>
    <div class="tm-loader__description">
      <slot name="description" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmSpinner from '@/components/shared/TmSpinner.vue'

export default defineComponent({
  components: { TmSpinner },
  props: {
    spinnerSize: {
      type: String,
      default: '40px',
    },
  },
})
</script>

<style lang="scss" scoped>
.tm-loader {
  text-align: center;
  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.2px;

    margin-top: 24px;
    margin-bottom: 8px;
  }
  &__description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: $gray6;
  }
  &__spinner {
    color: #2196f3 !important;
  }
}
</style>

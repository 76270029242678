import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.TAG)
export default class Tag extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public title: string
}

import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import PassThrough from '@/components/layout/PassThrough.vue'

export const getPaymentRoutes = (): RouteRecordRaw[] => [
  {
    name: 'payment.base',
    path: 'payment',
    component: () => import('@/components/containers/SinglePageContainer.vue'),
    meta: {
      modules: [AppModule.StripeBilling, AppModule.Billing, AppModule.Payment],
    },
    children: [
      {
        name: 'payment',
        path: '',
        props: (route) => ({
          query: route.query,
        }),
        component: () => import('@/components/views/payment/Payment.vue'),
        meta: {
          accessControlGroups: ['PaymentStrategy'],
          modules: [AppModule.AccountSettings],
          tabTitle: 'pageTabTitle.payment',
        },
      },
      // redirect handler from stripe(need for correct analytics)
      {
        name: 'payment.congratsRedirect',
        path: 'congrats-redirect',
        meta: {
          resolvers: [
            {
              service: 'PaymentCongratsRedirectResolverService',
            },
          ],
          resolverComponent: () => import('@/components/domain/payment/PaymentCongratsResolver.vue'),
        },
        component: PassThrough,
      },
      // two routes need for GA(https://textmagic.atlassian.net/browse/TM-1830)
      // it private routes, only for redirect from payment.congratsRedirect (check PaymentCongratsStrategy)
      {
        name: 'payment.congrats',
        path: 'congrats',
        props: (route) => ({
          invoiceId: route.query.invoiceId,
        }),
        meta: {
          accessControlGroups: ['PaymentCongratsStrategy'],
        },
        component: () => import('@/components/views/payment/PaymentCongrats.vue'),
      },
      {
        name: 'payment.congratsNew',
        path: 'congrats-new',
        props: (route) => ({
          invoiceId: route.query.invoiceId,
        }),
        meta: {
          resolvers: [
            {
              service: 'FirstPaymentResolverService',
            },
          ],
          accessControlGroups: ['PaymentCongratsStrategy'],
        },
        component: () => import('@/components/views/payment/PaymentCongrats.vue'),
      },
      //--------------------
      {
        name: 'payment.orderSummary',
        path: 'order-summary',
        props: (route) => ({
          invoiceId: route.query.invoiceId,
        }),
        component: () => import('@/components/views/payment/PaymentCongrats.vue'),
      },
    ],
  },
]

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type RouterService from '@/services/route/routerService'
import type { RouterPermissionInterface, Translatable } from '@/services/types'
import type UserService from '@/services/domain/user/userService'
import { UserRole } from '@/services/domain/user/types'
import type { TmRoutes } from '@/services/route/routerTypes'
import { composeMessageRouteName } from '@/routes/user/compose'
import type { RouterBuilderInterface, TmNavigationGuard } from '@/services/route/types'

export type PageContentSidebarLink = {
  itemType: 'link'
  title: string
  route: TmRoutes
  badge?: 'new' | 'beta'
  overrideActive?: boolean
  icon: string
}

export type PageContentSidebarGroup = {
  itemType: 'group'
  title: string
  links: PageContentSidebarLink[]
}

@injectable()
export default class AdminSectionRedirectResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.RouterPermissionsService)
    private readonly routerPermissionsService: RouterPermissionInterface,
    @inject(SERVICE_TYPES.RouterService) private readonly routerService: RouterService,
    @inject(SERVICE_TYPES.TranslateService) private readonly translateService: Translatable,
    @inject(SERVICE_TYPES.UserService) private readonly userService: UserService,
    @inject(SERVICE_TYPES.RouterBuilderService) private readonly routerBuilderService: RouterBuilderInterface,
  ) {}

  public getRoutesGroups() {
    const isAdmin = this.userService.currentUser().subaccountType !== UserRole.USER
    const currentRoute = this.routerService.getCurrentRoute()

    const subAccountsRelatedRoutes = [
      'user.accounts.subAccounts.import.flow',
      'user.accounts.subAccountDetails',
      'user.accounts.subAccounts.import.details',
    ]
    const workspaceLinks: PageContentSidebarLink[] = [
      {
        itemType: 'link',
        title: this.translateService.t('pageTitle.user.accounts.users'),
        route: { name: 'user.accounts.users' },
        icon: 'tmi-account-box',
        overrideActive:
          typeof currentRoute.name === 'string' && currentRoute.name.startsWith('user.accounts.userDetails')
            ? true
            : undefined,
      },
      {
        itemType: 'link',
        title: this.translateService.t('pageTitle.user.accounts.subAccounts.title'),
        route: { name: 'user.accounts.subAccounts' },
        icon: 'tmi-account-box',
        overrideActive: subAccountsRelatedRoutes.some((t) => {
          return typeof currentRoute.name === 'string' && currentRoute.name.startsWith(t)
        })
          ? true
          : undefined,
      },
      {
        itemType: 'link',
        title: this.translateService.t('pageTitle.user.accounts.sso'),
        route: { name: 'user.accounts.sso' },
        icon: 'verified_user',
      },
      {
        itemType: 'link',
        title: this.translateService.t('pageTitle.user.accounts.dataRetention'),
        route: { name: 'user.accounts.dataRetention' },
        icon: 'tmi-database',
      },
    ]

    const billingLinks: PageContentSidebarLink[] = [
      {
        itemType: 'link',
        title: this.translateService.t('pageTitle.user.accounts.newBilling.title'),
        route: { name: 'user.accounts.billingOverview' },
        icon: 'account_balance',
      },
      {
        itemType: 'link',
        title: this.translateService.t('pageTitle.user.accounts.monthlyPlans'),
        route: { name: 'user.accounts.monthlyPlans' },
        icon: 'credit_card',
      },
      {
        itemType: 'link',
        title: this.translateService.t('pageTitle.user.accounts.invoices'),
        route: { name: 'user.accounts.invoices' },
        icon: 'tmi-file-text-outline',
      },
      {
        itemType: 'link',
        title: this.translateService.t('pageTitle.user.accounts.statements'),
        route: { name: 'user.accounts.statements' },
        icon: 'tmi-money',
      },
    ]

    const personalLinks: PageContentSidebarLink[] = [
      {
        itemType: 'link',
        title: this.translateService.t('pageTitle.user.accounts.details'),
        route: { name: 'user.accounts.details' },
        icon: 'tmi-account-circle-filled',
      },
      {
        itemType: 'link',
        title: this.translateService.t('pageTitle.user.accounts.security'),
        route: { name: 'user.accounts.security' },
        icon: 'lock',
      },
      {
        itemType: 'link',
        title: this.translateService.t('pageTitle.user.accounts.notifications'),
        route: { name: 'user.accounts.notifications' },
        icon: 'notifications',
      },
    ]

    const _menu: PageContentSidebarGroup[] = isAdmin
      ? [
          {
            itemType: 'group',
            title: this.translateService.t('pageTitle.user.accounts.workspace'),
            links: workspaceLinks,
          },
          {
            itemType: 'group',
            title: this.translateService.t('pageTitle.user.accounts.billing'),
            links: billingLinks,
          },
          {
            itemType: 'group',
            title: this.translateService.t('pageTitle.user.accounts.personal'),
            links: personalLinks,
          },
        ]
      : [
          {
            itemType: 'group',
            title: this.translateService.t('pageTitle.user.accounts.personal'),
            links: [...personalLinks, ...workspaceLinks],
          },
        ]
    return _menu
  }

  public getFilteredRoutesGroups() {
    const _menu = this.getRoutesGroups()

    const menu: PageContentSidebarGroup[] = []
    _menu.forEach((t) => {
      const links = t.links.filter((item) => this.routerPermissionsService.isAllowAccessToRoute(item.route))
      if (links.length === 0) {
        return
      }
      menu.push({
        ...t,
        links,
      })
    })
    return menu
  }

  private beforeEachHandler: TmNavigationGuard = async (to, from, prevent) => {
    const isAdminPage = this.routerService.isCurrentRouteChildOf({ name: 'user.accounts' })

    if (to.name === 'user.accounts' && isAdminPage) {
      await this.setFirstAvailableRoute()
    }
  }

  private setFirstAvailableRoute = async () => {
    const routesGroups = this.getFilteredRoutesGroups()
    const routesList = routesGroups
      .map((menuGroup) => menuGroup.links)
      .flat()
      .map((menuLink) => menuLink.route)
    const preparedRoute: TmRoutes = routesList[0] ?? {
      name: composeMessageRouteName,
    }

    await this.routerService.replace(preparedRoute)
  }

  public async resolveStrategies() {
    await Promise.all(
      this.getRoutesGroups()
        .map((menuGroup) => menuGroup.links)
        .flat()
        .map((menuLink) => {
          return this.routerPermissionsService.resolveStrategiesForRoute(menuLink.route)
        }),
    )
  }

  public async resolve() {
    this.routerBuilderService.addBeforeEachHandler(this.beforeEachHandler)
    await this.resolveStrategies()
    const currentRoute = this.routerService.getCurrentRoute()
    const targetRoute: TmRoutes = {
      name: 'user.accounts',
    }
    if (currentRoute.name !== targetRoute.name) {
      return
    }
    await this.setFirstAvailableRoute()
  }

  public async unresolve() {
    this.routerBuilderService.removeBeforeEachHandler(this.beforeEachHandler)
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ByocHelperService,
  ByocRouteHelperService,
}

export type ByocHelperServices = keyof typeof SERVICES

export const BYOC_HELPER_SERVICE_TYPES: Record<ByocHelperServices, symbol> = makeServicesTypes<ByocHelperServices>(
  enumKeysToArray<ByocHelperServices>(SERVICES),
)

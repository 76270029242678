class MemoryStorage implements Storage {
  private storage = new Map<string, string>()

  public get length(): number {
    return this.storage.size
  }

  public clear(): void {
    this.storage.clear()
  }

  public getItem(key: string): string | null {
    return this.storage.get(key) ?? null
  }

  public key(index: number): string | null {
    return Array.from(this.storage.keys())[index] ?? null
  }

  public removeItem(key: string): void {
    this.storage.delete(key)
  }

  public setItem(key: string, value: string): void {
    this.storage.set(key, value)
  }
}

/*
 * We're using Proxy to implement magic getters and setters the way the Storage interface does it
 */
const memoryStorageFactory = () => {
  return new Proxy(new MemoryStorage(), {
    get: (target, prop, receiver) => {
      if (prop in target) {
        return target[prop]
      }

      return target.getItem(prop as string)
    },
    set: (target, prop, value) => {
      if (prop in target) {
        target[prop] = value
      } else {
        target.setItem(prop as string, value)
      }

      return true
    },
  })
}

const isStorageSupported = (getStorage: () => Storage): boolean => {
  try {
    const key = '__tm-local-storage-probe__'
    getStorage().setItem(key, key)
    getStorage().removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

if (!isStorageSupported(() => window.localStorage)) {
  Object.defineProperty(window, 'localStorage', {
    value: memoryStorageFactory(),
  })
}

if (!isStorageSupported(() => window.sessionStorage)) {
  Object.defineProperty(window, 'sessionStorage', {
    value: memoryStorageFactory(),
  })
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  PhoneNumbersColumnServiceFactory,
  PhoneNumbersLabelFormService,
  SenderSettingsCountriesColumnService,
  TenDlcPhoneNumbersColumnServiceFactory,
  TenDlcCampaignsColumnServiceFactory,
  TollFreePhoneNumbersColumnServiceFactory,
  SenderIdsColumnServiceFactory,
  SenderIdApplyFormService,
  SenderIdService,
  SenderIdRepository,
  SenderSettingsRouteHelperService,
  CallForwardingTitlerService,
}

export type SenderSettingsServices = keyof typeof SERVICES

export const SENDER_SETTINGS_SERVICE_TYPES: Record<SenderSettingsServices, symbol> =
  makeServicesTypes<SenderSettingsServices>(enumKeysToArray<SenderSettingsServices>(SERVICES))

<template>
  <tm-app-loader>
    <template v-slot:title>
      {{ $t('auth.authLoader.title') }}
    </template>

    <template v-slot:subtitle>
      <transition
        name="slide-up"
        mode="out-in"
      >
        <div v-if="isSlowLoading && !isVerySlowLoading">
          <tm-formatted-text-renderer
            :content="$t('auth.authLoader.longLoadPlaceholder1')"
            skip-bold
            skip-italic
          />
        </div>

        <div v-else-if="isVerySlowLoading">
          <tm-translate keypath="auth.authLoader.longLoadPlaceholder2">
            <template v-slot:number>
              <span>5</span>
            </template>

            <template v-slot:click>
              <tm-button
                text-link
                class="primary--text"
                @click="$emit('reload')"
              >
                <span class="subhead-semi-bold-16">
                  {{ $t('auth.authLoader.clickHereLink') }}
                </span>
              </tm-button>
            </template>
          </tm-translate>
        </div>
      </transition>
    </template>
  </tm-app-loader>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from '@/composition/vue/compositionApi'
import TmButton from '@/components/shared/TmButton.vue'
import TmTranslate from '@/components/TmTranslate.vue'
import TmFormattedTextRenderer from '@/components/shared/TmFormattedTextRenderer.vue'
import TmAppLoader from '@/components/shared/TmAppLoader.vue'
import { getWindowService } from '@/core/container/ioc'

export default defineComponent({
  components: {
    TmAppLoader,
    TmFormattedTextRenderer,
    TmTranslate,
    TmButton,
  },
  props: {
    indefinite: {
      type: Boolean,
      default: false,
    },
    slowLoadingTimeout: {
      type: Number,
      default: 5000,
    },
    verySlowLoadingTimeout: {
      type: Number,
      default: 8000,
    },
  },
  emits: {
    reload: () => true,
  },
  setup(props) {
    const isSlowLoading = ref(false)
    const isVerySlowLoading = ref(false)

    onMounted(() => {
      // eslint-disable-next-line no-undef
      window.__preAppLoader?.toggle(false)

      setTimeout(() => {
        isSlowLoading.value = true
      }, props.slowLoadingTimeout)

      setTimeout(() => {
        isVerySlowLoading.value = true
      }, props.verySlowLoadingTimeout)
    })

    onUnmounted(() => {
      getWindowService().self().__preAppLoader?.toggle(false)
    })

    return {
      isSlowLoading,
      isVerySlowLoading,
    }
  },
})
</script>

<style lang="scss" scoped>
.slide-up-enter-active,
.slide-up-leave-active {
  transition:
    transform $transition-300ms-ease-out,
    opacity $transition-200ms-ease-in;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import PrimaryBrowserTabLock from '@/data/models/PrimaryBrowserTabLock'
import OrmLocalStorageRepository from '@/data/repositories/ormLocalStorageRepository'

@RepoSettings({
  model: PrimaryBrowserTabLock,
  fetch: PrimaryBrowserTabLock.entity,
  single: PrimaryBrowserTabLock.entity,
  create: PrimaryBrowserTabLock.entity,
})
@injectable()
export default class PrimaryBrowserTabLockRepository extends OrmLocalStorageRepository<PrimaryBrowserTabLock> {
  protected id = '1'

  public setLock(tabId: string) {
    return this.postRequest({
      id: this.id,
      tabId,
    })
  }

  public getLock() {
    return this.getRequest<Partial<PrimaryBrowserTabLock>>(this.id)
  }

  public removeLock() {
    return this.deleteRequest(this.id)
  }
}

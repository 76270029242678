import { OrmModel, StringField, NumberField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.AUTOCOMPLETE)
export default class Autocomplete extends BaseModel {
  @StringField()
  public id: string

  @NumberField(0)
  public allPagesCount: number
}

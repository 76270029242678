import { OrmModel, StringField } from 'vuex-orm-decorators'
import { trim } from 'lodash-es'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'

@OrmModel(modelNames.TASK_EDITOR)
export default class TaskEditor extends BaseModel {
  @StringField()
  public firstName: string

  @StringField()
  public lastName: string

  @StringField(null)
  public avatarUrl: string | null

  public get fullName(): string {
    return trim(`${this.firstName} ${this.lastName}`)
  }
}

import { AttrField, BelongsToField, BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import type { Nullable } from '@/types'
import { modelNames } from '@/data/models/types'
import TaskEditor from '@/data/models/domain/tasks/TaskEditor'
import type { PriorityEnum } from '@/data/models/domain/types'
import type { KanbanChecklistItemsCount } from '@/data/models/domain/kanban/kanbanChecklistItemsCount'
import FilterableModel from '@/data/models/FilterableModel'

@OrmModel(modelNames.TASK)
export default class Task extends FilterableModel {
  @StringField('')
  public id: string

  @NumberField(null)
  public previousId: Nullable<number>

  @StringField(null, (value) => value && `${value}`)
  public stageId: string

  @StringField(null, (value) => value && `${value}`)
  public boardId: string

  @StringField()
  public title: string

  @StringField()
  public description: string

  @StringField(null, (value) => value && `${value}`)
  public priority: PriorityEnum

  @BooleanField(true)
  public isEditable: boolean

  @AttrField(null)
  public dueDate: Nullable<string>

  @AttrField(null)
  public checklistItemsCount: Nullable<KanbanChecklistItemsCount>

  @AttrField()
  public assigneeId: number | null

  @StringField()
  public createdById: string

  @BelongsToField(TaskEditor, 'createdById')
  public createdBy: TaskEditor

  @StringField()
  public updatedById: string

  @BelongsToField(TaskEditor, 'updatedById')
  public updatedBy: TaskEditor

  @StringField()
  public createdAt: string

  @StringField()
  public updatedAt: string
}

import { AttrField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { StoredColumn } from '@/core/tables/types'

@OrmModel(modelNames.COLUMNS)
export default class Columns extends BaseModel {
  @StringField()
  public id: string

  @AttrField([])
  public columns: StoredColumn[]
}

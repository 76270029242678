import type { RouteLocationRaw } from 'vue-router'
import { useProvideInject } from '@/composition/provideInject'
import { getFacetManager, getRouterPermissionsService, getRouterService } from '@/core/container/ioc'
import type BaseModel from '@/data/models/BaseModel'
import type { Facetable } from '@/services/facets/types'
import type { Dict } from '@/types'
import type { PageContentSidebarItem } from '@/components/layout/pageContent/sidebar/types'

const LayoutMenuInSidebarSymbol = Symbol('layoutMenuInSideBar')
export const useLayoutMenuInSidebar = () => useProvideInject<boolean>(LayoutMenuInSidebarSymbol)

export const useLayoutMenuFaceter = <T extends Dict<number>>(model: typeof BaseModel) => {
  const inSideBar = useLayoutMenuInSidebar().inject()

  if (inSideBar) {
    const faceter = getFacetManager().getFaceter<Facetable<T>>(model)
    return {
      getNumber: (key: keyof T) => faceter.getFacet(key),
      updateFacets: (keys: (keyof T)[]) => faceter.fetchFacets(keys),
    }
  }

  return {
    getNumber: () => null,
    updateFacets: async () => {},
  }
}

export const prepareLayoutMenuItems = (items: PageContentSidebarItem[]) => {
  const routerService = getRouterService()
  const routerPermissionsService = getRouterPermissionsService()

  const isAllowAccessToRawRouteByName = (route: RouteLocationRaw) => {
    const routeName = routerService.getRawRouteName(route)
    if (!routeName) {
      return true
    }
    return routerPermissionsService.isAllowAccessToRoute(routeName)
  }

  const res: PageContentSidebarItem[] = []
  items.forEach((t) => {
    if (t.itemType === 'simple') {
      return
    }
    if (t.itemType === 'link') {
      if (isAllowAccessToRawRouteByName(t.route)) {
        res.push(t)
      }
      return
    }
    const links = t.links.filter(
      (r) => r.itemType === 'simple' || (r.itemType === 'link' && isAllowAccessToRawRouteByName(r.route)),
    )

    if (!links.length) {
      return
    }

    if (t.itemType === 'groupCollapsible') {
      res.push({
        ...t,
        links,
      })
    } else if (t.itemType === 'group') {
      res.push({
        itemType: 'group',
        title: t.title,
        links: [],
      })
      links.forEach((link) => res.push(link))
    }
  })
  return res
}

import { includes } from 'lodash-es'
import type { NormalizeRule } from '@/data/utils/types'
import TmLogicError from '@/core/error/tmLogicError'

export type NormalizerPrimitiveType = 'string' | 'number' | 'boolean'

/**
 * Static class for casting specific cases for not matched "in" and "out" data
 */
export class DataNormalizer {
  protected static stringRules: NormalizeRule<string>[] = [
    { in: [null, 'null', false, 'undefined'], out: '' },
    { in: [true], out: 'true' },
  ]

  protected static numberRules: NormalizeRule<number>[] = [
    { in: ['', false, 'false', null, 'null', 'undefined'], out: 0 },
    { in: [true, 'true'], out: 1 },
  ]

  protected static booleanRules: NormalizeRule<boolean>[] = [
    { in: [null, '', 0, 'N', 'n', 'null', 'false', 'undefined'], out: false },
    { in: ['Y', 'y', 'true', 1], out: true },
  ]

  public static normalizePrimitive<T>(value: any, typeParam: NormalizerPrimitiveType): T {
    const rules = this.getRules<T>(typeParam)
    const normalizedValue = this.normalize<T>(value, rules)
    return this.getCastClass(typeParam)(normalizedValue) as T
  }

  protected static getRules<T>(typeParam: NormalizerPrimitiveType): NormalizeRule<T>[] {
    switch (typeParam) {
      case 'string':
        return DataNormalizer.stringRules as unknown as NormalizeRule<T>[]
      case 'number':
        return DataNormalizer.numberRules as unknown as NormalizeRule<T>[]
      case 'boolean':
        return DataNormalizer.booleanRules as unknown as NormalizeRule<T>[]
      default:
        return []
    }
  }

  protected static getCastClass(typeParam: NormalizerPrimitiveType) {
    switch (typeParam) {
      case 'string':
        return String
      case 'number':
        return Number
      case 'boolean':
        return Boolean
      default:
        throw new TmLogicError('Cast class not found')
    }
  }

  protected static normalize<T>(value: any, rules: NormalizeRule<T>[]): T {
    for (const rule of rules) {
      if (includes(rule.in, value)) {
        return rule.out
      }
    }
    return value
  }
}

import { injectable } from 'inversify'
import type {
  Column,
  ColumnsServiceInterface,
  DefaultColumn,
  DefaultColumns,
  RawDefaultColumns,
  StoredColumn,
} from '@/core/tables/types'
import type Columns from '@/data/models/tables/Columns'
import { TmTableColumnError } from '@/core/error/table/tmTableColumnError'
import type { ColumnsSettings, PaginationUrlColumnType } from '@/services/tables/types'
import TmLogicError from '@/core/error/tmLogicError'

@injectable()
export default class NoColumnsServiceFactory implements ColumnsServiceInterface {
  protected tableId: string

  public getInitColumns(): RawDefaultColumns {
    return {}
  }

  public async reload(): Promise<void> {
    // Do nothing
  }

  public getColumn(columnName: string): DefaultColumn {
    throw new TmTableColumnError(`Column ${columnName} does not exist`)
  }

  public hasColumn(name: string): boolean {
    return false
  }

  public getColumnEditorColumns(): Array<DefaultColumn> {
    return []
  }

  public getColumns(): Columns {
    throw new TmTableColumnError('No need to be here')
  }

  public toQuery(): PaginationUrlColumnType {
    throw new TmTableColumnError('No need to be here')
  }

  public getDefaultColumns(): DefaultColumns {
    return {}
  }

  public getPrependColumns(): Array<DefaultColumn> {
    return []
  }

  public getVisibleColumns(): Array<DefaultColumn> {
    return []
  }

  public getInvisibleColumns(): Array<DefaultColumn> {
    return []
  }

  public init(columns?: DefaultColumns): void {}

  public async makeColumns(tableId: string): Promise<StoredColumn[]> {
    return []
  }

  public recoveryColumns(tableId: string): Promise<StoredColumn[]> {
    return this.makeColumns(tableId)
  }

  public async saveColumns(columns: Column[], entity: string) {
    return []
  }

  public async loadColumns(tableId: string) {
    return []
  }

  public setTableId(tableId: string): void {
    this.tableId = tableId
  }

  public reset() {}

  public getTableId(): string {
    if (!this.tableId) {
      throw new TmLogicError('No tableId is set for service')
    }

    return this.tableId
  }

  public getSettings(): ColumnsSettings {
    return {
      tableId: this.tableId,
    }
  }

  public columnsToPaginationUrlColumn(columns: DefaultColumn[]): PaginationUrlColumnType {
    return columns.map((column, index) => ({
      name: column.columnName,
      visible: column.visible,
      order: index + 1,
    }))
  }

  public isEditable(): boolean {
    return false
  }

  public isSortable(): boolean {
    return false
  }

  public isDefaultState(): boolean {
    return true
  }

  public isRowDisabled() {
    return false
  }
}

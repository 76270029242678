<template>
  <tm-icon
    class="main-sidebar-warning-icon"
    v-bind="$props"
  />
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmIcon, { tmIconProps } from '@/components/shared/TmIcon.vue'

export default defineComponent({
  components: {
    TmIcon,
  },
  props: tmIconProps,
})
</script>

<style lang="scss" scoped>
.main-sidebar-warning-icon {
  position: relative;

  &:before {
    position: absolute;
    top: 6px;
    left: 50%;
    z-index: -1;
    width: 4px;
    height: 11px;
    border-radius: 20px;
    background: $white;
    transform: translateX(-50%);
  }
}
</style>

import { OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { Nullable } from '@/types'

@OrmModel(modelNames.AUTH_STATE)
export default class AuthState extends BaseModel {
  @StringField()
  public id: string

  @StringField('')
  public username: string

  @StringField('')
  public email: string

  @StringField('')
  public phone: string

  @StringField('')
  public question: string

  @StringField('')
  public tfaId: string

  @StringField('')
  public tfaToken: string

  @StringField(null)
  public firstName: Nullable<string>

  @StringField(null)
  public lastName: Nullable<string>
}

import { inject, injectable } from 'inversify'
import { omit, cloneDeep, merge } from 'lodash-es'
import type { RouteLocationRaw } from 'vue-router'
import { SERVICE_TYPES } from '@/core/container/types'
import type LoggerService from '@/services/loggerService'
import type RouterService from '@/services/route/routerService'
import type { OnChange, QueryParamsToSet } from '@/services/route/types'
import { isDotNotatedKey, mergeDotNotationQueries } from '@/utils/router'
import type WindowService from '@/services/browser/windowService'

@injectable()
export default class NewQueryParamsService {
  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
  ) {}

  public getQueryParams() {
    return this.routerService.getCurrentRoute().query
  }

  public getQueryParam(key: string) {
    return this.getQueryParams()[key]
  }

  public subscribe(key: string, onChange: OnChange) {
    this.routerService.subscribe(key, onChange)
  }

  public unsubscribe(key: string) {
    this.routerService.unsubscribe(key)
  }

  public async addQuery(queryParams: QueryParamsToSet, shouldReplaceState = false, force = false) {
    const currRoute = this.getCurrentRoute()
    this.log(`${JSON.stringify(queryParams)} ${JSON.stringify(currRoute.query)}`, 'newAddQuery')
    const newState: RouteLocationRaw = {
      ...currRoute,
      query: {
        ...currRoute.query,
        ...queryParams,
      },
      force,
    }
    return shouldReplaceState ? this.routerService.replace(newState) : this.routerService.push(newState)
  }

  /**
   * Removing query by keys. Works only with first level keys.
   * Push state by default
   * @param keys keys to remove
   * @param shouldReplaceState should call replace if true and push if false
   * @param force should use force flag for router
   */
  public async removeQuery(keys: string[], shouldReplaceState = false, force = false) {
    const query = this.getParamsWithoutKeys(keys)
    this.log(JSON.stringify(query), 'newRemoveQuery')
    const currRoute = this.getCurrentRoute()
    const newState = {
      ...currRoute,
      query,
      force,
    }
    if (typeof this.windowService.self().history?.replaceState === 'function') {
      this.windowService.self().history.replaceState(null, '', this.routerService.resolve(newState).fullPath)
    }
    return shouldReplaceState ? this.routerService.replace(newState) : this.routerService.push(newState)
  }

  /**
   * Removing all empty values from object in case if it was presented in query before replace.
   * @param query query to set
   * @param shouldReplaceState should call replace if true and push if false
   * @param force should use force flag for router
   */
  public async replaceQuery(query: Record<string, any>, shouldReplaceState = false, force = false) {
    this.log(JSON.stringify(query), 'newReplaceQuery')
    const currRoute = this.getCurrentRoute()
    const newState = {
      ...currRoute,
      query,
      force,
    }
    return shouldReplaceState ? this.routerService.replace(newState) : this.routerService.push(newState)
  }

  public async cleanQuery(shouldReplaceState = false, force = false) {
    this.log('', 'newCleanQuery')
    const currRoute = this.getCurrentRoute()
    const newState = {
      ...currRoute,
      query: {},
      force,
    }
    return shouldReplaceState ? this.routerService.replace(newState) : this.routerService.push(newState)
  }

  public async extendQuery(extendWithParams: QueryParamsToSet, shouldReplaceState = false, force = false) {
    const queryParams = this.getQueryParams()

    const dotNotated: Record<string, any> = {}
    const dotNotatedExtendWith: Record<string, any> = {}

    const rest: Record<string, any> = {}
    const restExtendWith: Record<string, any> = {}

    // Split dot-notated and non-dot-notated merge flows
    for (const key in queryParams) {
      if (isDotNotatedKey(key)) {
        dotNotated[key] = queryParams[key]
      } else {
        rest[key] = queryParams[key]
      }
    }

    for (const key in extendWithParams) {
      if (isDotNotatedKey(key)) {
        dotNotatedExtendWith[key] = extendWithParams[key]
      } else {
        restExtendWith[key] = extendWithParams[key]
      }
    }

    return this.replaceQuery(
      {
        ...mergeDotNotationQueries(dotNotated, dotNotatedExtendWith),
        ...merge(rest, restExtendWith),
      },
      shouldReplaceState,
      force,
    )
  }

  protected getCurrentRoute() {
    return this.routerService.getCurrentRoute()
  }

  protected getParamsWithoutKeys(keys: string[]) {
    return omit(cloneDeep(this.getCurrentRoute().query), keys)
  }

  private log(log: string, subchannel: string) {
    if (this.loggerService.shouldLogByChannel('queryParams', [subchannel])) {
      this.loggerService.log('queryParams', log, subchannel)
    }
  }
}

import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import { getTasksBoardsRoutes } from '@/routes/user/tasks/tasksBoards'
import { getTasksSettingsRoutes } from '@/routes/user/tasks/tasksSettings'
import { TasksRoutes } from '@/routes/user/tasks/types'

export const getTasksRoutes = (): RouteRecordRaw[] => [
  {
    name: TasksRoutes.index,
    path: 'tasks',
    props: true,
    meta: {
      tags: ['USER_TOP_MENU'],
      tabTitle: 'pageTitle.user.tasks.tasks',
      menu: () => import('@/components/domain/tasks/TasksMenu.vue'),
      modules: [AppModule.TasksBase, AppModule.Tasks],
    },
    redirect: { name: TasksRoutes.base },
    component: () => import('@/components/views/tasks/Tasks.vue'),
    children: [...getTasksBoardsRoutes()],
  },
  {
    name: TasksRoutes.settingsIndex,
    path: 'tasks/settings',
    meta: {
      parent: TasksRoutes.index,
      tabTitle: 'pageTitle.user.tasks.settings',
      menu: () => import('@/components/domain/tasks/settings/TasksSettingsMenu.vue'),
      accessControlGroups: ['TasksReadOnlyStrategy'],
      modules: [AppModule.TasksBase, AppModule.Tasks],
    },
    redirect: { name: 'user.tasks.settings.base' },
    component: () => import('@/components/views/tasks/TasksSettings.vue'),
    children: [...getTasksSettingsRoutes()],
  },
]

import { inject, injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { SERVICE_TYPES } from '@/core/container/types'
import { DomainSettings } from '@/decorators/domainDecorators'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type TeamWorkflowRepository from '@/data/repositories/domain/TeamWorkflowRepository'
import TeamWorkflow from '@/data/models/domain/TeamWorkflow'
import type UserService from '@/services/domain/user/userService'
import type { TmRoutes } from '@/services/route/routerTypes'
import type User from '@/data/models/domain/User'
import type UserSettingsService from '@/services/domain/user/userSettingsService'
import { UserRole } from '@/services/domain/user/types'

@DomainSettings({
  model: TeamWorkflow,
})
@injectable()
export default class TeamWorkflowService extends DomainBaseService<TeamWorkflowRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
    @inject(SERVICE_TYPES.UserSettingsService) protected readonly userSettingsService: UserSettingsService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public isActiveUserWorkflowFeature() {
    return true
  }

  public isUsersWorkflow() {
    if (!this.isActiveUserWorkflowFeature()) {
      return false
    }
    return this.userSettingsService.currentUserSettingsOrFail().teamWorkflow.type === 'users'
  }

  public isUsersWorkflowNotUserRole(): boolean {
    if (!this.isUsersWorkflow()) {
      return true
    }
    return this.userService.currentUser().subaccountType !== UserRole.USER
  }

  public isSubaccountsWorkflow() {
    if (!this.isActiveUserWorkflowFeature()) {
      return true
    }
    return this.userSettingsService.currentUserSettingsOrFail().teamWorkflow.type === 'subaccounts'
  }

  public getUserIdForWorkflow() {
    if (this.isUsersWorkflow()) {
      return this.userSettingsService.currentUserSettingsOrFail().parentId
    }
    return this.userService.currentUser().id
  }

  public getAccountDetailsTo(user: User): TmRoutes | undefined {
    if (!user.isAllowDetailsPage) {
      return undefined
    }

    return this.getSubAccountDetailsTo(user.id)
  }

  public getSubAccountDetailsTo(userId: string): TmRoutes | undefined {
    if (this.userService.isCurrentUser(userId)) {
      return { name: 'user.accounts' }
    }

    if (this.isUsersWorkflow()) {
      return { name: 'user.accounts.userDetails', params: { subAccountId: userId } }
    }

    return { name: 'user.accounts.subAccountDetails', params: { subAccountId: userId } }
  }
}

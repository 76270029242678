import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import { ticketIdViewParam, ticketsTableId } from '@/services/domain/tickets/const'
import { ticketsRouteNames, ticketsRoutePaths } from '@/routes/user/tickets/types'
import TicketStatus from '@/data/models/domain/tickets/TicketStatus'
import Ticket from '@/data/models/domain/tickets/Ticket'
import type { FilteredViewTableApplyResolverParams } from '@/services/domain/filteredViews/resolvers/types'
import { FILTERED_VIEW_PARAM_NAME } from '@/services/domain/filteredViews/const'
import type { DomainTitlerParams } from '@/services/route/titlers/types'
import FilteredView from '@/data/models/domain/filteredViews/FilteredView'
import type { TicketByWorkspaceIdResolverParams } from '@/services/domain/tickets/resolvers/ticketByWorkspaceIdResolverService'

export const ticketsGettingStartedRoute: RouteRecordRaw = {
  name: ticketsRouteNames.gettingStarted,
  path: ticketsRoutePaths.gettingStarted,
  meta: {
    modules: [AppModule.ComingSoon],
    resolverComponent: () => import('@/components/domain/tickets/resolvers/TicketCommonResolver.vue'),
  },
  component: () => import('@/components/views/tickets/TicketsGettingStarted.vue'),
}

export const ticketsFilterRoute: RouteRecordRaw = {
  name: ticketsRouteNames.tableFilteredView,
  path: ticketsRoutePaths.tableFilteredView,
  meta: {
    resolvers: [
      {
        service: 'ModelResolver',
        params: [TicketStatus],
      },
      {
        service: 'FilteredViewTableApplyResolverService',
        params: {
          tableModelId: ticketsTableId,
          routeParamName: FILTERED_VIEW_PARAM_NAME,
          redirectToIfNotFound: { name: ticketsRouteNames.root },
        } satisfies FilteredViewTableApplyResolverParams,
      },
      {
        service: 'TicketServerEventsResolverService',
      },
    ],
    resolverComponent: () => import('@/components/domain/tickets/resolvers/TicketCommonResolver.vue'),
    titler: {
      service: 'DomainTitlerService',
      params: {
        model: FilteredView,
        field: 'name',
        idParamName: FILTERED_VIEW_PARAM_NAME,
      } as DomainTitlerParams,
    },
  },
  props: (route) => ({
    filteredViewId: route.params[FILTERED_VIEW_PARAM_NAME],
  }),
  component: () => import('@/components/views/tickets/TicketFilteredViewDetails.vue'),
}

export const ticketsDetailsRoute: RouteRecordRaw = {
  name: ticketsRouteNames.ticketDetails,
  path: ticketsRoutePaths.ticketDetails,
  component: () => import('@/components/views/tickets/TicketDetails.vue'),
  meta: {
    modules: [AppModule.Typing],
    resolvers: [
      {
        service: 'ModelResolver',
        params: [TicketStatus],
      },
      {
        service: 'TicketServerEventsResolverService',
      },
      {
        service: 'TicketDetailsServerEventsResolverService',
      },
      {
        service: 'TypingResolver',
      },
      [
        {
          service: 'TicketByWorkspaceIdResolverService',
          params: {
            redirectToIfNotFound: { name: ticketsRouteNames.root },
          } satisfies TicketByWorkspaceIdResolverParams,
        },
        {
          service: 'TicketMarkAsReadResolverService',
        },
      ],
    ],
    resolverComponent: () => import('@/components/domain/tickets/resolvers/TicketCommonResolver.vue'),
    titler: {
      service: 'DomainTitlerService',
      params: {
        model: Ticket,
        field: 'subject',
        idParamName: ticketIdViewParam,
      } as DomainTitlerParams,
    },
  },
}

export const ticketsRootRoute: RouteRecordRaw = {
  name: ticketsRouteNames.root,
  path: ticketsRoutePaths.root,
  component: () => import('@/components/views/tickets/Tickets.vue'),
  meta: {
    tags: ['USER_TOP_MENU'],
    tabTitle: 'pageTitle.user.tickets.tickets',
    menu: () => import('@/components/domain/tickets/menu/TicketsMenu.vue'),
    modules: [
      AppModule.FilteredViews,
      AppModule.Tickets,
      AppModule.Tags,
      AppModule.ChannelPresence,
      AppModule.AlsoHere,
    ],
  },
  redirect: { name: ticketsRouteNames.resolveNavigation },
  children: [
    {
      name: ticketsRouteNames.resolveNavigation,
      path: ticketsRoutePaths.resolveNavigation,
      meta: {
        resolvers: [
          [
            {
              service: 'TicketsBasicDataResolverService',
            },
            {
              service: 'TicketDefaultRouteRedirectResolverService',
            },
            {
              service: 'FetchAllAccountsResolver',
            },
            {
              service: 'TicketFirstLastMessageResolverService',
            },
            {
              service: 'AlsoHereResolver',
            },
          ],
        ],
        resolverComponent: () => import('@/components/domain/tickets/resolvers/TicketCommonResolver.vue'),
      },
      children: [ticketsGettingStartedRoute, ticketsFilterRoute, ticketsDetailsRoute],
    },
  ],
}

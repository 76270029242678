import type { MiddlewareInterface } from '@/core/middlewares/middlewareInterface'
import type { GetLocator } from '@/core/container/container'
import { getLoggerService, getNotificationService, getTranslateService } from '@/core/container/ioc'
import { TmApiServerError } from '@/core/error/transport/tmApiServerError'

export default class ErrorHandlerMiddleware implements MiddlewareInterface {
  public async make(get: GetLocator) {
    window.addEventListener('error', (e) => {
      this.handler(e.error ? e.error : e)
      return false
    })
    window.addEventListener('unhandledrejection', (e) => this.handler(e.reason))
  }

  protected handler(err: unknown) {
    if (err instanceof Error) {
      getLoggerService().error('globalException', err.message, `ErrorHandlerMiddleware::${err.name}`)
    } else {
      getLoggerService().error('globalException', 'Unknown error', `ErrorHandlerMiddleware::unknown`)
    }

    if (err instanceof TmApiServerError) {
      getNotificationService().notifyError(err.message, getTranslateService().t('errors.default'))
    }
  }
}

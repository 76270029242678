import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ApiV2KeysColumnServiceFactory,
  ApiV2KeysCreateFormService,
  ApiV2EntryService,
  ApiV2EntryRepository,
  ApiV2SettingsFormService,
  ApiV2SettingsService,
  ApiV2SettingsRepository,
  ApiV1KeyFormService,
  ApiV1SettingsFormService,
  ApiV1SettingsService,
  ApiV1SettingsRepository,
}

export type ApiServices = keyof typeof SERVICES

export const API_SERVICE_TYPES: Record<ApiServices, symbol> = makeServicesTypes<ApiServices>(
  enumKeysToArray<ApiServices>(SERVICES),
)

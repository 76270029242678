import { inject, injectable } from 'inversify'

import type { CacheService } from '@/services/cache/cacheService'
import type { TableRecoveryServiceInterface } from '@/services/tables/recovery/tableRecoveryServiceInterface'
import type TableRecoveryData from '@/data/models/tables/TableRecoveryData'

import { SERVICE_TYPES } from '@/core/container/types'

@injectable()
export class TableBaseRecoveryService implements TableRecoveryServiceInterface {
  constructor(
    @inject(SERVICE_TYPES.LRUCacheService)
    protected readonly cacheService: CacheService<TableRecoveryData>,
  ) {}

  public save(data: TableRecoveryData): void {
    this.cacheService.set(data.id, data)
  }

  public get(key: string): TableRecoveryData | null {
    return this.cacheService.get(key)
  }
}

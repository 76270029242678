import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  SenderSettingsCountriesService,
}

export type SenderSettingsCountriesServices = keyof typeof SERVICES

export const SENDER_SETTINGS_COUNTRIES_SERVICE_TYPES: Record<SenderSettingsCountriesServices, symbol> =
  makeServicesTypes<SenderSettingsCountriesServices>(enumKeysToArray<SenderSettingsCountriesServices>(SERVICES))

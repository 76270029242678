import { AttrField, OrmModel, PrimaryKey, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { CustomFieldValueType, CustomFieldType } from '@/services/domain/cusomFields/types'

@OrmModel(modelNames.CUSTOM_FIELD_VALUE)
export class CustomFieldValue extends BaseModel {
  @PrimaryKey()
  @StringField()
  public uid: string

  @StringField('', (value: string | number) => String(value))
  public entityId: string

  @StringField('', (value: string | number) => String(value))
  public fieldId: string

  @StringField()
  public fieldTitle: string

  @StringField()
  public fieldType: CustomFieldType

  @AttrField(null)
  public value: CustomFieldValueType | null

  @StringField()
  public createdAt: string
}

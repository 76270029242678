import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'

export const campaignsScheduledPausedSmsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.paused.sms',
  path: 'sms',
  component: () => import('@/components/views/campaigns/scheduled/paused/CampaignsScheduledPausedSms.vue'),
  meta: {
    modules: [AppModule.ScheduledPaused],
  },
}

export const campaignsScheduledPausedEmailRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.paused.email',
  path: 'email',
  component: () => import('@/components/views/campaigns/email/CampaignsEmailStub.vue'),
}

export const campaignsScheduledPausedRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.paused',
  path: 'paused',
  component: () => import('@/components/views/campaigns/scheduled/paused/CampaignsScheduledPaused.vue'),
  children: [campaignsScheduledPausedSmsRoute, campaignsScheduledPausedEmailRoute],
  redirect: { name: campaignsScheduledPausedSmsRoute.name },
  meta: {
    tabTitle: 'pageTabTitle.user.campaigns.scheduled.paused',
  },
}

<template>
  <tm-button
    :size="btnSize"
    :flat="flat"
    :color="color"
    :no-border="noBorder"
    :icon-only="!withText"
  >
    {{ iconClass }}
    <tm-icon
      :size="iconSize"
      :class="iconClass"
      :name="iconName"
      :left="withText"
    />
    <div v-if="withText">{{ $t('common.more') }}</div>
    <tm-tooltip
      v-if="tooltip"
      v-bind="tooltipProps"
    >
      {{ tooltip }}
    </tm-tooltip>
  </tm-button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/tooltip/TmTooltip.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import type { ButtonColor, ButtonSize, IconSize, SizeProp } from '@/components/shared/types'
import type { IconName } from '@/assets/icons/icons'

export default defineComponent({
  components: { TmIcon, TmTooltip, TmButton },
  props: {
    btnSize: {
      type: String as SizeProp<ButtonSize>,
      default: '',
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
      default: 'medium',
    },
    color: {
      type: String as PropType<ButtonColor>,
      default: 'transparent',
    },
    iconClass: {
      type: String,
      default: '',
    },
    flat: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    tooltipProps: {
      type: Object,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String as PropType<IconName>,
      default: 'more_vert',
    },
    withText: {
      type: Boolean,
    },
  },
})
</script>

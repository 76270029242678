import { inject, injectable } from 'inversify'
import type LoggerService from '@/services/loggerService'
import { SERVICE_TYPES } from '@/core/container/types'
import type {
  IMainServerSub,
  IServerEventsMap,
  TServerSubscriberCallback,
  TServerEvent,
  ServerEventBase,
} from '@/services/transport/serverEvents'
import type { IWebSocketService } from '@/services/transport/types'
import type UserService from '@/services/domain/user/userService'
import type { MonitoringServiceInterface } from '@/services/monitoring/types'

type TSubscriptionsRecord<T extends keyof IServerEventsMap> = { [key in T]?: Array<TServerSubscriberCallback<T>> }
type TEvents = keyof IServerEventsMap

@injectable()
export default class ServerSubscriptionService implements IMainServerSub {
  private _subscriptions: TSubscriptionsRecord<TEvents> = {}

  private _connected = false

  private eventHandler: <T extends keyof IServerEventsMap>(event: TServerEvent<T>) => void

  constructor(
    @inject(SERVICE_TYPES.WebSocketService) protected readonly socketService: IWebSocketService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.UserService) private readonly userService: UserService,
    @inject(SERVICE_TYPES.MonitoringService) protected readonly monitoringService: MonitoringServiceInterface,
  ) {
    this.eventHandler = this.handleServerEvent.bind(this)
  }

  public subscribe<T extends keyof IServerEventsMap>(eventName: T, callback: TServerSubscriberCallback<T>) {
    if (!this._connected) {
      this.connect()
    }
    const arr = this.getSubscriptionsByEventName(eventName)
    if (!arr.find((cb) => cb === callback)) {
      arr.push(callback)
    }
    this.setSubscriptionsByEventName(eventName, arr)
    this.log(`subscribed to server event #eventName ${eventName}`)
  }

  public unsubscribe<R extends keyof IServerEventsMap>(
    eventName: R,
    callback?: (event: ServerEventBase<R, IServerEventsMap[R]>) => void,
  ) {
    const callbacks = this.getSubscriptionsByEventName(eventName)
    const i = callbacks.findIndex((cb) => cb === callback)
    if (i < 0) return
    callbacks.splice(i, 1)
    this.log(`unsubscribed from server event #eventName ${eventName}`)
  }

  public unsubscribeAll<T extends keyof IServerEventsMap>(eventName: T) {
    delete this._subscriptions[eventName]
    this.log(`unsubscribed from server event #eventName ${eventName}`)
  }

  // this getter is need for tests
  public get subscriptions(): TSubscriptionsRecord<TEvents> {
    return this._subscriptions
  }

  protected log(message: string) {
    if (this.logger.shouldLogByChannel('bus', ['internal'])) {
      this.logger.log('bus', message, 'internal')
    }
  }

  private getSubscriptionsByEventName<T extends keyof IServerEventsMap>(
    eventName: T,
  ): Array<TServerSubscriberCallback<T>> {
    return this._subscriptions[eventName] || []
  }

  private setSubscriptionsByEventName<T extends keyof IServerEventsMap>(
    eventName: T,
    subscriptions: TServerSubscriberCallback<T>[],
  ) {
    this._subscriptions[eventName] = subscriptions as Array<TServerSubscriberCallback<keyof IServerEventsMap>>
  }

  private connect() {
    this.socketService.addDefaultChannelListener(this.eventHandler as (...args: unknown[]) => unknown)
    this._connected = true
  }

  protected handleServerEvent<T extends keyof IServerEventsMap>(event: TServerEvent<T>) {
    if (!event || !event.type) {
      return
    }
    // @todo remove this temporary log
    if (this.userService.currentUserId() === '498820') {
      // eslint-disable-next-line no-console
      console.log(`${event.timestamp} | ${event.type} |`, JSON.stringify(event.payload))
    }
    if ('user_id' in event && event.user_id!.toString() !== this.userService.currentUserId()) {
      return
    }
    const callbacks = this.getSubscriptionsByEventName(event.type)
    callbacks.forEach((callback) => {
      try {
        callback(event)
        this.log(`fired ${event.type}`)
      } catch (e) {
        if (e instanceof Error) {
          this.log(`${event.type} error ${e.message}`)
        } else {
          this.log(`${event.type} error`)
        }
        this.monitoringService.logError(e as Error)
      }
    })
  }
}

<template>
  <q-expansion-item
    ref="expansionItem"
    :model-value="opened"
    class="tm-expansion-item"
    :class="{ 'tm-expansion-item__icon-font-size': iconFontSize }"
    :expand-separator="expandSeparator"
    :expand-icon="expandIcon"
    :disable="disabled"
    @update:model-value="$emit('update:opened', $event)"
  >
    <template v-slot:header>
      <div
        v-if="onBeforeHide && opened"
        class="tm-expansion-item__click-blocker"
        @click.stop.prevent="handleBeforeHide"
      />
      <div class="q-item__section column q-item__section--main justify-center">
        <div class="tm-expansion-item__label">
          <slot name="label">
            {{ label }}
          </slot>
          <slot name="append-label" />
        </div>
      </div>
    </template>
    <slot />
  </q-expansion-item>
</template>

<script setup lang="ts">
import type { QExpansionItem } from 'quasar'
import { ref, computed } from '@/composition/vue/compositionApi'
import { useExpansionHooks } from '@/composition/domain/expansion'

const props = defineProps<{
  label?: string
  opened?: boolean
  disabled?: boolean
  expandSeparator?: boolean
  expandIcon?: string
  iconFontSize?: number
}>()

defineEmits<{
  'update:opened': [boolean]
}>()

const { onBeforeHide } = useExpansionHooks()
const expansionItem = ref<QExpansionItem>()

const iconFontSizePrepared = computed(() => `${props.iconFontSize}px`)

const handleBeforeHide = () => {
  if (onBeforeHide.value && onBeforeHide.value() === false) {
    return
  }

  expansionItem.value?.hide()
}

const show = () => {
  expansionItem.value?.show()
}

defineExpose({
  show,
})
</script>

<style lang="scss" scoped>
.tm-expansion-item {
  border-bottom: 1px solid $input;

  padding-top: var(--tm-expansion-item-offset-y, 16px);
  padding-bottom: var(--tm-expansion-item-offset-y, 16px);

  &__label {
    display: flex;
    align-items: center;
    color: var(--tm-expansion-item-label-color, $gray7);
    font-size: var(--tm-expansion-item-label-font-size, $md-font);
    font-style: normal;
    font-weight: $semi-bold-font;
    line-height: var(--tm-expansion-item-label-line-height, 34px) !important;
  }

  &__click-blocker {
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  &__icon-font-size {
    :deep(.q-expansion-item__toggle-icon) {
      font-size: v-bind(iconFontSizePrepared) !important;
    }
  }

  :deep(.q-item) {
    min-height: var(--tm-expansion-item-label-line-height, 0);
    padding-top: 0;
    padding-bottom: 0;
    padding-right: var(--tm-expansion-item-offset-x, 0);
    padding-left: var(--tm-expansion-item-offset-x, 0);
  }

  :deep(.q-item.disabled) {
    opacity: 1 !important;

    .tm-expansion-item__label {
      color: var(--tm-expansion-item-label-color_disabled, $gray7);
    }
  }

  :deep(.q-focus-helper) {
    opacity: 0 !important;
    background: none !important;
  }

  :deep(.q-expansion-item__toggle-icon) {
    width: var(--tm-expansion-item-toggle-icon-size, 32px);
    height: var(--tm-expansion-item-toggle-icon-size, 32px);
    font-size: var(--tm-expansion-item-toggle-icon-size);
    background: var(--tm-expansion-item-toggle-icon-bg, $gray2);
    color: var(--tm-expansion-item-toggle-icon-color);
    border-radius: 50%;
  }

  :deep(.q-item__section--side) {
    color: $gray6;
  }
}
</style>

import type { QInputProps } from 'quasar'
import type { RouteLocationRaw } from 'vue-router'
import type { JSONContent } from '@tiptap/core'
import type Field from '@/data/models/forms/Field'
import type {
  Operation,
  TmCountryCode,
  TranslationKey,
  PhoneType,
  UrlConstraint,
  ServicePhoneType,
} from '@/services/types'
import type { FieldValue } from '@/data/models/forms/types'
import type {
  FileResponse,
  InternalErrorResponse,
  OnUploadProgress,
  ServerErrorFields,
  ServerErrors,
} from '@/services/transport/types'
import type { PaginationUrlFilterType } from '@/services/tables/types'
import type BaseModel from '@/data/models/BaseModel'
import type Country from '@/data/models/domain/Country'
import type { GetFormSubmitConfigType, ModelRaw } from '@/types'
import type Timezone from '@/data/models/domain/Timezone'
import type { BulkBaseBody } from '@/services/bulk/types'
import type BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import type { FieldModelType } from '@/services/forms/baseForm/fieldMapper'
import type BaseFilterForm from '@/services/tables/filters/baseFilterForm'
import type { BaseFilterInterface } from '@/core/tables/types'
import type { FilterModel, FiltersToCreate, DateRange } from '@/services/tables/filters/types'
import type { BaseValidationRules } from '@/services/validation/validationRulesBuilderService'
import type BaseFormService from '@/services/forms/baseFormService'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import type ContactList from '@/data/models/domain/ContactList'
import type { RecipientOption } from '@/services/domain/messages/types'
import type PhoneNumber from '@/data/models/domain/PhoneNumber'
import type { StatusColor } from '@/components/shared/types'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type User from '@/data/models/domain/User'
import type { AutomationRuleFlowAction } from '@/services/domain/services/automationRule/flow/types'
import type { SubAccountFetchFilter } from '@/services/domain/accounts/subAccounts/types'
import type { CountryPhoneData } from '@/constants/countryPhoneData'
import type BaseForm from '@/services/forms/baseForm'
// TODO: Get rid of component imports
import type { TagListItemType } from '@/components/forms/fields/customTags/types'
import type { Forms } from '@/services/forms/formTypes'
import type { IconName } from '@/assets/icons/icons'
import type { ColorPaletteOption, PaletteColor } from '@/components/shared/color/types'
import type { UserRole } from '@/services/domain/user/types'
import type { WebWidgetDetailsMinimizedStateIcon } from '@/data/models/domain/messages/settings/webWidget/types'
import type { ChatSettingsColor } from '@/data/models/domain/chats/ChatSettings'
import type { ValidationRuleError } from '@/services/validation/types'
import type { PriorityEnum } from '@/data/models/domain/types'
import type { CustomFieldOption, CustomFieldType } from '@/services/domain/cusomFields/types'
import type { CarbonCopySelectOption } from '@/services/forms/tickets/types'
import type { TmToggleCardConfig } from '@/components/shared/toggleCards/types'
import type { TicketInboxType } from '@/data/models/domain/tickets/types'
import type { TenDlcVertical } from '@/services/domain/services/regulations/tenDlcRequest/types'
import type { BadgeAppearance } from '@/components/shared/badge/types'
import type KanbanBoardBase from '@/data/models/domain/kanban/kanbanBoardBase'
import type { AttachedFileWithRelatedId } from '@/components/domain/attachments/types'
import type { AnyComponent } from '@/components/types'
import type AttachmentFile from '@/data/models/domain/attachmentFile/AttachmentFile'
import type { EditorValue, Emoji } from '@/services/forms/tmTiptap/types'

export interface FormFieldValidationInterface {
  valid: boolean
  invalid: boolean
  changed: boolean
  touched: boolean
  untouched: boolean
  pristine: boolean
  dirty: boolean
  pending: boolean
  required: boolean
  validated: boolean
  passed: boolean
  failed: boolean
  errors: string[]
  validators: string
  validationMode: string
}
// TODO: make desicion about potential null in styles or string using
export interface FormFieldInterface<T = FieldValue> {
  getName(): string
  getApiValue(): any
  reset(): void
  getFieldType(): string
  getComponent(): string
  isChanged(): boolean
  value: T
  icon?: IconName
}

export interface FormFieldFilterInterface<T = FieldValue> extends FormFieldInterface<T> {
  getCurrentOperation(): Operation
  getAvailableOperations(): Operation[]
  getRelationField(): string
  isAppliedFilter(): boolean
}

export type FormFieldType = Field & FormFieldValidationInterface
export type FormFieldFilterType = FilterModel & FormFieldType & FormFieldFilterInterface
export type FormFieldsType<T> = {
  [key: string]: T
}

export type DefaultLockMode = {
  name: LockModeName.default
}
export type OptimisticLockMode = {
  name: LockModeName.optimistic
}
export enum LockModeName {
  default = 'default',
  optimistic = 'optimistic',
}
export type LockMode = DefaultLockMode | OptimisticLockMode

export type UploadProgressSubmitConfig = {
  onUploadProgress?: OnUploadProgress
}

export type FormSubmitStrategy<T extends BaseFormService<FormBuilderInterface>> = (
  formService: T,
  config: GetFormSubmitConfigType<T>,
) => Promise<unknown>

export type FormSubmitStrategyBuilder = <T extends BaseFormService<FormBuilderInterface>>(
  shouldClear?: boolean,
  byModel?: typeof BaseModel,
) => FormSubmitStrategy<T>

export interface FilterServiceInterface<T extends FormFieldFilterType> {
  initForm(filters?: FiltersToCreate): BaseFilterForm<FormFieldFilterType>
  buildForm(): Promise<void>
  getForm(): BaseFilterForm<T>
  getConfig?(): FormConfig<T>
  getFormData?(): TpFormData
  clearForm?(): void
  submit?(data: TpFormData): Promise<any>
  onInput?(fieldName: string, value: FieldValue): void
}

export interface FilterInterface extends BaseFilterInterface, FilterServiceInterface<FormFieldFilterType> {}

export interface FormBulkUpdatable<B extends BulkBaseBody> {
  bulkSubmit(data: B, ids: string[], isAll: boolean, filters: PaginationUrlFilterType): Promise<any>
}

export type ValidationInitiator = 'form' | 'field' | 'crossField'
export type ErrorInitiator = ValidationInitiator | 'server'

type CommonParamsOfFormValidation = {
  formId: Forms
  formData?: TpFormData
  shouldSyncFormValidation?: boolean
  validationInitiator?: ValidationInitiator
}
export type ParamsOfFormValidationByName = CommonParamsOfFormValidation & {
  name: string
  shouldValidateChild?: boolean
}
export type ParamsOfFormValidationById = CommonParamsOfFormValidation & {
  id: string
  shouldValidateChild?: boolean
}
export type ParamsOfFormGroupValidation = CommonParamsOfFormValidation & {
  id: string
}

export interface FormValidatorInterface {
  onInputAction(name: string, actionType: string, formId: string): void
  validate(params: ParamsOfFormValidationById): Promise<boolean>

  validateGroup(params: ParamsOfFormGroupValidation): Promise<boolean>
  validateForm(formId: string): Promise<boolean>
  resetFormValidator(formId: string): void
  setServerErrors(errors: InternalErrorResponse, formId: string): void
  setErrors(errors: ServerErrors, formId: string): void
  makeAggresiveMode(formId: string, name: string): void
}

export enum ValidationModes {
  // on blur or on change
  'lazy' = 'lazy',
  // triggers on form submit on manual call
  'passive' = 'passive',
  // validates on input or on blur
  'aggressive' = 'aggressive',
  // This is arguably a better UX for your users since it isn't aggressive initially. It is eager to pass which is reason of its name.
  // Behaves like lazy when the field wasn't interacted with yet, and if it is invalid it will be aggressive until the input becomes valid again.
  'eager' = 'eager',
  // Opposite of eager. Should be passive but, if it is invalid it will be aggressive until the input becomes valid again.
  'onSubmit' = 'onSubmit',
}

export type FieldValidationErrorEventPayload = {
  name: string // full name of the field without the form id
  errors: ValidationRuleError[]
  initiator?: ErrorInitiator
}

export type FormConfig<T> = {
  formId: string
  hooks?: {
    // would be triggered before any field would be updated
    beforeEach?: FormHook
    // would be triggered after any field update
    afterEach?: FormHook
  }
  fields?: FieldsConfig[]
  models?: Array<T>
}

export type FormHookType = 'beforeEach' | 'afterEach' | 'change'
export type FormHook = (field: BaseFieldModel, value: any) => void

export type InputHandlerFunction<V = any> = (
  value: V,
  name: string,
  parentIndex: number,
) => void | InputHandlerResponse<V>
export type ExportHandlerFunction<V = any> = (value: V, name: string) => any
export type ErrorHandlerFunction = (formId: Forms, params: any) => any

export type InputHandlerResponse<V = any> = {
  value: V
  prevent?: boolean
}

export const fieldTypesToComponentMap = {
  avatar: 'FieldAvatar',
  datepicker: 'FieldDatepicker',
  timepicker: 'FieldTimepicker',
  file: 'FieldFile',
  imageFile: 'FieldImageFile',
  password: 'FieldPassword',
  text: 'FieldText',
  maskedText: 'FieldMaskedText',
  email: 'FieldText',
  textarea: 'FieldTextarea',
  pointAiDocuments: 'FieldPointAiDocuments',
  select: 'FieldSelect',
  autocomplete: 'FieldAutocomplete',
  autocompleteDropdown: 'FieldAutocompleteDropdown', // not used pure
  tags: 'FieldTags',
  contactTags: 'FieldContactTags',
  tagsList: 'FieldTagsList',
  tagsRecipient: 'FieldTagsRecipient',
  pinnedTagsRecipient: 'FieldPinnedTagsRecipient',
  selectSearchList: 'FieldSelectSearchList',
  selectSearchCountry: 'FieldSelectSearchCountry',
  selectSearchSenderSettings: 'FieldSelectSearchSenderSettings',
  checkbox: 'FieldCheckbox',
  toggle: 'FieldToggle',
  radio: 'FieldRadio',
  radioArea: 'FieldRadioArea',
  radioHidden: 'FieldRadioHidden',
  timezone: 'FieldSelectSearch',
  selectSearch: 'FieldSelectSearch',
  country: 'FieldCountry',
  multipleCountries: 'FieldMultipleCountries',
  newPassword: 'FieldNewPassword',
  code: 'FieldCode',
  number: 'FieldNumber',
  monetary: 'FieldMonetary',
  editableBadges: 'FieldEditableBadges',
  editableBadgesWithAddButton: 'FieldEditableBadgesWithAddButton',
  badge: 'FieldBadge',
  statusSettings: 'FieldStatusSettings',
  phone: 'FieldPhone',
  phoneWithAddButton: 'FieldPhoneWithAddButton',
  whatsappPhone: 'FieldWhatsappPhone',
  messageEditor: 'FieldMessageEditor',
  messageEditorFull: 'FieldMessageEditorFull',
  fileUploadArea: 'FieldFileUploadArea',
  narrowFileUploadArea: 'FieldNarrowFileUploadArea',
  timeHours: 'FieldTimeHours',
  timeMinutes: 'FieldTimeMinutes',
  timeSeconds: 'FieldTimeSeconds',
  checkButton: 'FieldCheckButton',
  selectWithAutocomplete: 'FieldSelectWithAutocomplete', // not used pure
  fileImport: 'FieldImportFile',
  kanbanItemAttachFileUploadArea: 'FieldKanbanItemAttachFile',
  tilesSwitch: 'FieldTilesSwitch',
  selectStatus: 'FieldSelectStatus',
  fieldLabel: 'FieldFieldLabel',
  visibilityButton: 'FieldVisibilityButton',
  colorPicker: 'FieldColorPicker',
  countriesList: 'FieldCountriesList',
  countrySenderPhoneList: 'FieldCountrySenderPhoneList',
  pageUrlRules: 'FieldPageUrlRules',
  selectSenderPhone: 'FieldSelectSenderPhone',
  selectColumn: 'FieldSelectColumn',
  selectSearchUser: 'FieldSelectSearchUser',
  selectWithIcon: 'FieldSelectWithIcon',
  automationRulesActions: 'FieldAutomationRulesActions',
  timeRange: 'FieldTimeRange',
  spinTimepicker: 'FieldSpinTimepicker',
  timeRangeList: 'FieldTimeRangeList',
  weekDaysTimeRangeLists: 'FieldWeekDaysTimeRangeLists',
  fullName: 'FieldFullName',
  phoneWithCountrySelect: 'FieldPhoneWithCountrySelect',
  paletteColorPicker: 'FieldPaletteColorPicker',
  emojiText: 'FieldEmojiText',
  textWithEmojiPicker: 'FieldTextWithEmojiPicker',
  selectPriority: 'FieldSelectPriority',
  selectSearchTenDlcVertical: 'FieldSelectSearch',
  selectKanbanBoardStage: 'FieldSelectTaskStage',
  selectKanbanBoard: 'FieldSelectKanbanBoard',
  richEditor: 'FieldRichEditor',
  taskViewDueDate: 'FieldTaskViewDueDate',
  taskCreateDueDate: 'FieldTaskCreateDueDate',
  fieldAssignee: 'FieldAssignee',
  fieldCarbonCopies: 'FieldCarbonCopies',
  messengerSettingsThemePalette: 'FieldMessengerSettingsThemePalette',
  selectAndSearch: 'FieldSelectAndSearch',
  fieldContactSelect: 'FieldContactSelect',
  customFieldType: 'FieldCustomFieldType',
  fieldCFText: 'FieldCFText',
  fieldCFNumber: 'FieldCFNumber',
  fieldCFMonetary: 'FieldCFMonetary',
  fieldCFDate: 'FieldCFDate',
  fieldCFSingleOption: 'FieldCFSingleOption',
  fieldCFMultiOptions: 'FieldCFMultiOptions',
  ticketStatusSelect: 'FieldSelectTicketStatus',
  toggleCardGroup: 'FieldToggleCardGroup',
  fieldSorting: 'FieldSorting',
  tableColumns: 'FieldTableColumns',
  tableFilters: 'FieldTableFilters',
  selectSearchInbox: 'FieldSelectSearchInbox',
  campaignsEmailSenderReply: 'FieldCampaignsEmailSenderReply',
  selectSearchFacebookPage: 'FieldSelectSearchFacebookPage',
  selectSearchInstagramAccount: 'FieldSelectSearchInstagramAccount',
  emailEditor: 'FieldEmailEditor',
} as const
export type FieldType = keyof typeof fieldTypesToComponentMap

export type TQInputProps = Extract<QInputProps['type'], FieldType>

export type FieldDiscardStrategy = 'default' | 'clear'
export type ComponentProps = Record<string, (field?: BaseForm<BaseFieldModel>) => unknown>

export interface AbstractFieldConfig<V = any> {
  // Type would be passed to the field component
  fieldType?: FieldType

  // Field name
  name: string

  // Type of STI Field model
  modelType?: FieldModelType

  // Initial value
  value?: V

  // Field component
  component?: string

  /**
   * Function would handle value from the input and update it to set in model
   * for example
   * inputHandler: (value) => value + 'New'
   */
  inputHandler?: InputHandlerFunction<V>
  /**
   * Function to get value from model to FormData
   * getFormData would use it to get all data from form
   */
  exportHandler?: ExportHandlerFunction<V>

  /**
   * Function to be called on serverError
   */
  serverErrorHandler?: ErrorHandlerFunction
  serverErrorFields?: ServerErrorFields

  /**
   * Validators has option to pass another field value parameters.
   *
   * to pass parameter to validator you should use :
   * example max:1
   *
   * to pass link as params use @ sign
   * example max:@maxField
   *
   * to pass some element of array as a link use {index}
   * example phoneNumberForRegion:@phones.{index}.countryId
   */
  validators?: BaseValidationRules
  /**
   * @deprecated Should not be used, please consider messagePath param in validation config instead
   */
  errorCustomMessage?: string
  /**
   * Validation strategy.
   * 'lazy': validate on blur
   * 'passive': validate only when validation trigger manually
   * 'aggressive': validate on input and on blur
   * 'eager': same as lazy till field is valid. Once it become invalid it would behave as agressive till
   * when it's valid -> lazy again
   */
  validationMode?: ValidationModes
  parentIndex?: number
  crossField?: string[]
  discardStrategy?: FieldDiscardStrategy
  componentProps?: ComponentProps
}

interface AbstractSelectFieldConfig<V, SO = SelectOption> extends AbstractFieldConfig<V> {
  value: V
  options?: () => SO[]
  // Refill the model records if it is updated
  subscribeOnModel?: typeof BaseModel
}

interface AbstractEntityAutocompleteFieldConfig<V, E extends BaseModel>
  extends Omit<AbstractSelectFieldConfig<V, EntitySelectOption<E>>, 'options'> {
  // Filtering or map options after they have been updated
  optionsPostProcess?: SelectOptionsPostProcessCallback<EntitySelectOption<E>>
  filters?: PaginationUrlFilterType
}

interface ModelBasedFieldConfig extends AbstractFieldConfig {
  modelType: FieldModelType
}

export interface StringFieldConfig extends AbstractFieldConfig<string> {
  fieldType:
    | 'text'
    | 'textarea'
    | 'email'
    | 'datepicker'
    | 'newPassword'
    | 'code'
    | 'timepicker'
    | 'spinTimepicker'
    | 'tilesSwitch'
    | 'fieldLabel'
    | 'colorPicker'
    | 'textWithEmojiPicker'
    | 'campaignsEmailSenderReply'
    | 'maskedText'
    | 'emailEditor'
  value: string
  maxLength?: number
  trim?: boolean
}

export interface FullNameConfig extends AbstractFieldConfig<FullNameValue> {
  fieldType: 'fullName'
}

export interface PasswordFieldConfig extends AbstractFieldConfig<string> {
  fieldType: 'password'
  value: string
  allowCyrillic?: boolean
}

export interface FileFieldConfig extends AbstractFieldConfig<FileUploadAreaValue> {
  fieldType: 'fileImport' | 'file' | 'avatar' | 'fileUploadArea' | 'kanbanItemAttachFileUploadArea' | 'pointAiDocuments'
}

export interface FileModelFieldConfig extends AbstractFieldConfig<FileUploadModelAreaValue> {
  fieldType: 'narrowFileUploadArea'
  componentProps: {
    accept: () => string[]
  }
}

export interface ImageFileFieldConfig extends AbstractFieldConfig<ImageFileFieldValue> {
  fieldType: 'imageFile'
}

export interface NumberFieldConfig extends AbstractFieldConfig<number> {
  fieldType: 'number'
  value: number
  min?: number
  max?: number
}

export interface MonetaryFieldConfig extends AbstractFieldConfig<number> {
  fieldType: 'monetary'
  value: number
  min?: number
  max?: number
}

export interface RadioFieldConfig extends AbstractFieldConfig<number | string | boolean> {
  fieldType: 'radio' | 'radioHidden' | 'radioArea'
  value: number | string | boolean
}

export interface BooleanFieldConfig extends AbstractFieldConfig<boolean> {
  fieldType: 'checkbox' | 'toggle' | 'visibilityButton'
  value: boolean
}

export interface DateFieldConfig extends AbstractFieldConfig<string> {
  fieldType: 'datepicker'
  value: string
  min?: Date
}

export interface TaskViewDueDateFieldConfig extends AbstractFieldConfig<string | null> {
  fieldType: 'taskViewDueDate'
  value: string | null
}

export interface TaskCreateDueDateFieldConfig extends AbstractFieldConfig<string | null> {
  fieldType: 'taskCreateDueDate'
  value: string | null
}

export interface AssigneeFieldConfig extends AbstractFieldConfig<string> {
  fieldType: 'fieldAssignee'
  value: string
}

export interface CarbonCopiesFieldConfig extends AbstractFieldConfig<CarbonCopySelectOption[]> {
  fieldType: 'fieldCarbonCopies'
}

export interface StringListFieldConfig extends AbstractFieldConfig<string[]> {
  fieldType: 'editableBadges' | 'editableBadgesWithAddButton' | 'countriesList'
  value: string[]
  componentProps?: {
    removeDuplicates?: () => boolean
  }
}

export enum SelectSearchInboxType {
  DEFAULT = 'inbox',
  CUSTOM = 'customEmail',
}

export type SelectSearchInboxValue = {
  valueType: SelectSearchInboxType
  inbox?: SelectOption
  customEmail?: string
}

export enum SelectSearchFacebookPageType {
  DEFAULT = 'facebookPage',
  CUSTOM = 'customFacebookPage',
}

export type SelectSearchFacebookPageValue = {
  valueType: SelectSearchFacebookPageType
  facebookPage?: SelectOption
  customFacebookPage?: string
}

export enum SelectSearchInstagramAccountType {
  DEFAULT = 'instagramAccount',
  CUSTOM = 'customInstagramAccount',
}

export type SelectSearchInstagramAccountValue = {
  valueType: SelectSearchInstagramAccountType
  instagramAccount?: SelectOption
  customInstagramAccount?: string
}

export type SenderNumberValue = {
  numberId: string
  numberType: PhoneType
  numberCountryId: TmCountryCode
  phone: string
}
export enum SenderPhoneNumberType {
  DEFAULT = 'number',
  CUSTOM = 'customNumber',
}

export type SelectSenderPhoneValue = {
  valueType: SenderPhoneNumberType
  number?: SenderNumberValue
  customNumber?: PhoneWithCountryCode
}

export type SelectSenderPhoneSubfields = Pick<
  SelectSenderPhoneValue,
  SenderPhoneNumberType.DEFAULT | SenderPhoneNumberType.CUSTOM
>
export type SelectSenderPhoneSubfieldNames = keyof SelectSenderPhoneSubfields

export type SelectSearchInboxSubfields = Pick<
  SelectSearchInboxValue,
  SelectSearchInboxType.DEFAULT | SelectSearchInboxType.CUSTOM
>
export type SelectSearchInboxSubfieldNames = keyof SelectSearchInboxSubfields

export type SelectSearchFacebookPageSubfields = Pick<
  SelectSearchFacebookPageValue,
  SelectSearchFacebookPageType.DEFAULT | SelectSearchFacebookPageType.CUSTOM
>
export type SelectSearchFacebookPageSubfieldNames = keyof SelectSearchFacebookPageSubfields

export type SelectSearchInstagramAccountSubfields = Pick<
  SelectSearchInstagramAccountValue,
  SelectSearchInstagramAccountType.DEFAULT | SelectSearchInstagramAccountType.CUSTOM
>
export type SelectSearchInstagramAccountSubfieldNames = keyof SelectSearchInstagramAccountSubfields

export type CountrySenderPhoneValue = {
  countryId?: TmCountryCode
} & SelectSenderPhoneValue

export interface SelectSenderPhoneFieldConfig extends AbstractFieldConfig<SelectSenderPhoneValue> {
  fieldType: 'selectSenderPhone'
  options?: () => SenderSettingsOption[]
  componentProps?: {
    phoneCountryOptions?: () => CountryPhoneData[]
    withCustomNumber?: () => boolean | undefined
  }
}

export interface CountrySenderPhoneListFieldConfig extends AbstractFieldConfig<CountrySenderPhoneValue[]> {
  fieldType: 'countrySenderPhoneList'
  options?: () => SenderSettingsOption[]
  componentProps?: {
    phoneCountryOptions?: () => CountryPhoneData[]
    withCustomNumber?: () => boolean | undefined
  }
}

export interface SelectSearchInboxFieldConfig extends AbstractFieldConfig<SelectSearchInboxValue> {
  fieldType: 'selectSearchInbox'
  options?: () => SelectOption[]
  value: SelectSearchInboxValue
  componentProps?: {
    withCustomEmail?: () => boolean | undefined
    isDisabled?: () => boolean | undefined
  }
}

export interface SelectSearchFacebookPageFieldConfig extends AbstractFieldConfig<SelectSearchFacebookPageValue> {
  fieldType: 'selectSearchFacebookPage'
  options?: () => SelectOption[]
  value: SelectSearchFacebookPageValue
  componentProps?: {
    withCustomFacebookPage?: () => boolean | undefined
  }
}

export interface SelectSearchInstagramAccountFieldConfig
  extends AbstractFieldConfig<SelectSearchInstagramAccountValue> {
  fieldType: 'selectSearchInstagramAccount'
  options?: () => SelectOption[]
  value: SelectSearchInstagramAccountValue
  componentProps?: {
    withCustomInstagramAccount?: () => boolean | undefined
  }
}

export interface SelectAndSearchFieldConfigValue<T extends string = string> {
  inputValue: string
  selectValue: T
}

export interface SelectAndSearchFieldConfig<T extends string = string>
  extends AbstractFieldConfig<SelectAndSearchFieldConfigValue<T>> {
  fieldType: 'selectAndSearch'
  options?: () => SelectOption<T>[]
}

export type PageUrlRuleValue = {
  constraint: UrlConstraint
  value: string
}

export interface PageUrlRulesFieldConfig extends AbstractFieldConfig<PageUrlRuleValue[]> {
  fieldType: 'pageUrlRules'
  componentProps: {
    constraintOptions: () => UrlConstraintOption[]
    keepEmptyItem: () => boolean
  }
}

export interface BadgeFieldConfig extends AbstractFieldConfig<string> {
  fieldType: 'badge'
  value: string
}

export interface SelectFieldConfig<T extends SelectValue = SelectValue>
  extends AbstractSelectFieldConfig<T, SelectOption<T>> {
  fieldType: 'select' | 'autocomplete' | 'selectSearch'
}

export interface SelectStatusFieldConfig extends AbstractSelectFieldConfig<UserRole, SelectStatusOption> {
  fieldType: 'selectStatus'
}

export interface SelectPriorityFieldConfig extends AbstractSelectFieldConfig<PriorityEnum, SelectPriorityOption> {
  fieldType: 'selectPriority'
}

export interface SelectSearchTenDlcVerticalFieldConfig
  extends AbstractSelectFieldConfig<TenDlcVertical, SelectTenDlcVerticalOption> {
  fieldType: 'selectSearchTenDlcVertical'
}

export interface SelectTicketStatusFieldConfig extends AbstractSelectFieldConfig<string> {
  fieldType: 'ticketStatusSelect'
}

export interface SelectKanbanBoardStageFieldConfig
  extends AbstractSelectFieldConfig<SelectKanbanBoardStageValue, SelectKanbanBoardStageOption> {
  fieldType: 'selectKanbanBoardStage'
}

export interface SelectKanbanBoardFieldConfig
  extends AbstractSelectFieldConfig<SelectKanbanBoardValue, SelectKanbanBoardOption> {
  fieldType: 'selectKanbanBoard'
}

export interface SelectWithIconFieldConfig<T extends SelectWithIconValue = SelectWithIconValue>
  extends AbstractSelectFieldConfig<T, SelectWithIconOption<T>> {
  fieldType: 'selectWithIcon'
}

export interface SelectContactFieldConfig<T extends SelectWithIconValue = SelectWithIconValue>
  extends AbstractSelectFieldConfig<string | number | { value: string; query: string }, SelectWithIconOption<T>> {
  fieldType: 'fieldContactSelect'
}

export interface RichEditorFieldConfig extends AbstractFieldConfig<JSONContent> {
  fieldType: 'richEditor'
}

interface TagsFieldConfig extends AbstractSelectFieldConfig<SelectValue[]> {
  fieldType: 'tags'
}

interface ContactTagsFieldConfig extends AbstractSelectFieldConfig<SelectValue[]> {
  fieldType: 'contactTags'
}

interface AutocompleteDropdownFieldConfig extends AbstractSelectFieldConfig<SelectValue[]> {
  fieldType: 'autocompleteDropdown'
}

export interface CountryFieldConfig<T = SelectValue> extends AbstractSelectFieldConfig<T> {
  fieldType: 'country'
  options: () => CountrySelectOption[]
}

export interface MultipleCountriesFieldConfig extends AbstractSelectFieldConfig<TmCountryCode[]> {
  fieldType: 'multipleCountries'
  options: () => CountrySelectOption[]
}

export interface TimezoneFieldConfig extends AbstractSelectFieldConfig<SelectValue> {
  fieldType: 'timezone'
  options: () => TimezoneSelectOption[]
}

export interface SelectSearchListFieldConfig extends AbstractEntityAutocompleteFieldConfig<SelectValue, ContactList> {
  fieldType: 'selectSearchList'
  filters?: PaginationUrlFilterType
}

export interface SelectSearchSenderSettingsFieldConfig
  extends AbstractSelectFieldConfig<SelectValue, SenderSettingsOption> {
  fieldType: 'selectSearchSenderSettings'
  options: () => SenderSettingsOption[]
}

interface SelectSearchUserFieldConfig extends AbstractEntityAutocompleteFieldConfig<SelectValue, User> {
  fieldType: 'selectSearchUser'
  filter?: SubAccountFetchFilter
}

interface TagsListFieldConfig extends AbstractEntityAutocompleteFieldConfig<SelectValue[], ContactList> {
  fieldType: 'tagsList'
  componentProps?: {
    itemType?: () => TagListItemType
    sortValuesOnSelected?: () => boolean
  }
}

export interface TagsRecipientFieldConfig extends AbstractFieldConfig<RecipientOption[]> {
  fieldType: 'tagsRecipient'
}

export interface PinnedTagsRecipientFieldConfig extends AbstractFieldConfig<RecipientOption[]> {
  fieldType: 'pinnedTagsRecipient'
}

export interface PhoneWithCountryFieldConfig extends AbstractFieldConfig<PhoneWithCountryCode> {
  fieldType: 'phone' | 'phoneWithAddButton' | 'whatsappPhone'
  options: () => CountryPhoneData[]
}

export interface MessageEditorConfig extends AbstractFieldConfig<EditorValue> {
  fieldType: 'messageEditor' | 'messageEditorFull'
  maxLength?: number
}

export interface SelectSearchCountryFieldConfig extends AbstractSelectFieldConfig<TmCountryCode> {
  fieldType: 'selectSearchCountry'
  options?: () => CountrySelectOption[]
}

interface TimeHoursEditorConfig extends AbstractFieldConfig<number> {
  fieldType: 'timeHours'
}
interface TimeMinutesEditorConfig extends AbstractFieldConfig<number> {
  fieldType: 'timeMinutes'
}
interface TimeSecondsEditorConfig extends AbstractFieldConfig<number> {
  fieldType: 'timeSeconds'
}
export interface CheckButtonConfig<T extends string = string> extends AbstractFieldConfig<T[]> {
  fieldType: 'checkButton'
  options: () => CheckButtonOption<T>[]
}

export interface SelectColumnFieldConfig extends AbstractFieldConfig<string[]> {
  fieldType: 'selectColumn'
  options: () => SelectOption[]
}

export interface SelectWithAutocompleteConfig<T extends string = string> extends AbstractFieldConfig<T> {
  fieldType: 'selectWithAutocomplete'
}

export interface AutomationRulesActionsConfig extends AbstractFieldConfig<AutomationRuleFlowAction[]> {
  fieldType: 'automationRulesActions'
}

export interface TimeRangeFieldConfig extends AbstractFieldConfig<DateRange> {
  fieldType: 'timeRange'
}

export interface TimeRangeListFieldConfig extends AbstractFieldConfig<DateRange[]> {
  fieldType: 'timeRangeList'
}

export interface WeekDaysTimeRangeListsFieldConfig extends AbstractFieldConfig<WeekDaysTimeRangeList[]> {
  fieldType: 'weekDaysTimeRangeLists'
  componentProps?: {
    maxRanges?: () => number
    canSelectAnyRange?: () => boolean
    defaultTimeRange?: () => DateRange
  }
}

export interface PhoneWithCountrySelectFieldConfig extends AbstractSelectFieldConfig<SelectValue> {
  fieldType: 'phoneWithCountrySelect'
  options: () => EntitySelectOption<PhoneNumber>[]
}

export interface PaletteColorFieldConfig<C extends PaletteColor = PaletteColor>
  extends AbstractSelectFieldConfig<C, ColorPaletteOption<C>> {
  fieldType: 'paletteColorPicker'
  options: () => ColorPaletteOption<C>[]
}

export interface MessengerSettingsThemePaletteFieldConfig extends AbstractFieldConfig<ChatSettingsColor> {
  fieldType: 'messengerSettingsThemePalette'
}

export interface EmojiTextFieldConfig extends AbstractFieldConfig<EmojiText> {
  fieldType: 'emojiText'
}

export interface CustomFieldTypeConfig extends AbstractSelectFieldConfig<CustomFieldType, { value: CustomFieldType }> {
  fieldType: 'customFieldType'
  options: () => Array<{ value: CustomFieldType }>
}

export interface CustomFieldConfig extends AbstractSelectFieldConfig<CustomFieldType, { value: CustomFieldType }> {
  fieldType: 'fieldCFText' | 'fieldCFNumber' | 'fieldCFMonetary' | 'fieldCFDate'
}

export interface CustomFieldWithOptionsConfig extends AbstractSelectFieldConfig<CustomFieldType, CustomFieldOption> {
  fieldType: 'fieldCFSingleOption' | 'fieldCFMultiOptions'
  options: () => Array<CustomFieldOption>
}

export interface ToggleCardGroupFieldConfig<T = TicketInboxType>
  extends AbstractSelectFieldConfig<T, TmToggleCardConfig> {
  fieldType: 'toggleCardGroup'
  options: () => TmToggleCardConfig[]
}

export interface TableFiltersFieldConfig extends AbstractFieldConfig<PaginationUrlFilterType> {
  fieldType: 'tableFilters'
  componentProps: Record<string, AnyComponent>
}

export interface TableColumnsFieldConfig extends AbstractFieldConfig<string[]> {
  fieldType: 'tableColumns'
}

export type FieldConfig =
  | ModelBasedFieldConfig
  | SelectFieldConfig
  | SelectStatusFieldConfig
  | SelectPriorityFieldConfig
  | SelectKanbanBoardStageFieldConfig
  | TagsFieldConfig
  | ContactTagsFieldConfig
  | TagsListFieldConfig
  | TagsRecipientFieldConfig
  | CountryFieldConfig
  | MultipleCountriesFieldConfig
  | TimezoneFieldConfig
  | StringListFieldConfig
  | BadgeFieldConfig
  | BooleanFieldConfig
  | NumberFieldConfig
  | StringFieldConfig
  | PasswordFieldConfig
  | AutocompleteDropdownFieldConfig
  | SelectSearchListFieldConfig
  | SelectSearchSenderSettingsFieldConfig
  | PhoneWithCountryFieldConfig
  | MessageEditorConfig
  | TimeHoursEditorConfig
  | TimeMinutesEditorConfig
  | TimeSecondsEditorConfig
  | CheckButtonConfig
  | SelectSearchCountryFieldConfig
  | SelectSearchUserFieldConfig
  | SelectWithAutocompleteConfig
  | FileFieldConfig
  | FileModelFieldConfig
  | ImageFileFieldConfig
  | RadioFieldConfig
  | SelectColumnFieldConfig
  | AutomationRulesActionsConfig
  | TimeRangeFieldConfig
  | TimeRangeListFieldConfig
  | WeekDaysTimeRangeListsFieldConfig
  | FullNameConfig
  | PhoneWithCountrySelectFieldConfig
  | PaletteColorFieldConfig
  | MessengerSettingsThemePaletteFieldConfig
  | EmojiTextFieldConfig
  | DateFieldConfig
  | TaskViewDueDateFieldConfig
  | TaskCreateDueDateFieldConfig
  | AssigneeFieldConfig
  | SelectAndSearchFieldConfig
  | CountrySenderPhoneListFieldConfig
  | CustomFieldTypeConfig
  | CustomFieldConfig
  | CustomFieldWithOptionsConfig
  | CarbonCopiesFieldConfig
  | ToggleCardGroupFieldConfig
  | SelectSearchTenDlcVerticalFieldConfig
  | TableFiltersFieldConfig
  | TableColumnsFieldConfig

export type FieldConfigWithRequiredValidators<T extends AbstractFieldConfig> = T & {
  validators: ReturnType<ValidationRulesBuilderService['createRules']>
}

export type FieldsConfig = FieldConfig & {
  fields?: FieldsConfig[]
}

export type FieldsMapConfig = FieldConfig & {
  fields?: FieldsMap
}

export type FieldsMap = {
  [key: string]: FieldsMapConfig
}

export type FormState = {
  isLoading: boolean
  isFormLoading: boolean
  formErrorMessage: string
  formSuccessMessage: string
  focusedField: string
  errorScrollField: string
  isFormSent: boolean
  editingId: string
  [key: string]: any
}

export const defaultFormState = {
  isLoading: false,
  isFormLoading: true,
  isFormSent: false,
  formErrorMessage: '',
  formSuccessMessage: '',
  focusedField: '',
  errorScrollField: '',

  editingId: '',
}

export type TpFormData<T extends Record<string, unknown> = Record<string, any>> = T

export const DefaultInputEvent = 'update:model-value'

export const selectOptionValueKey = 'value'
export const selectOptionLabelKey = 'text'
export const selectSelectedOptionLabelKey = 'selectedItemText'

export type SelectValue = string

export type PhoneWithCountryCode = {
  phone: string
  countryCode: TmCountryCode
}

export type FullNameValue = { firstName: string; lastName: string }

export type SelectOption<TValue = SelectValue> = {
  [selectOptionValueKey]: TValue
  [selectOptionLabelKey]: string
  [selectSelectedOptionLabelKey]?: string
  [key: string]: any
}

export type SelectOptionWithIcon = SelectOption & {
  icon?: IconName
}

export type MatchingSelectOption = SelectOption & {
  disabled?: boolean
}

export type SenderSettingsSubheader = SelectOption & {
  subheader: string
  disabled: true
}

export type SenderSettingsOverrideOption = SelectOption & {
  overrideOption: true
  type: 'button' | 'string'
  to?: RouteLocationRaw
  disabled?: true
  callback?: () => void
}

export type SenderSettingsSender<T = SelectValue> = SelectOption<T> & {
  /**
   * isSenderSettings flag helps to easily separate SenderSettingsSender from other types
   * in the array such as SenderSettingsSubheader, SenderSettingsOverrideOption.
   */
  isSenderSettings?: boolean
  /**
   * isByoc flag helps to easily separate Byoc numbers from other numbers.
   */
  isByoc?: boolean
  /**
   * isSenderId flag helps to easily separate SenderId numbers from other numbers.
   */
  isSenderId?: boolean
  phoneType?: PhoneType
  servicePhoneType?: ServicePhoneType
  additionalData?: string
  country?: Country
  disable?: boolean
  disabled?: boolean
  icon?: IconName
  iconImage?: string
  tooltipKeypath?: TranslationKey
  error?: boolean
  id?: string
}

export type UrlConstraintOption = SelectOption<UrlConstraint>

export type EmojiText = {
  text: string
  emoji?: Partial<Emoji>
}

export type SenderSettingsOption = SenderSettingsSender | SenderSettingsOverrideOption | SenderSettingsSubheader

export type CheckButtonOption<TValue extends string = string> = {
  text: string
  value: TValue
}

export type CountrySelectOption = SelectOption & { country: Pick<Country, 'id' | 'name'> }
export type TimezoneSelectOption = SelectOption & { timezone: ModelRaw<Timezone> }

export type EntityTitle = {
  subhead?: string
  notForSearch?: boolean
}
export type EntitySelectOption<E extends BaseModel = BaseModel> = SelectOption & {
  entity: ModelRaw<E>
  [key: string]: any
}
export type MultiEntitySelectOption = EntitySelectOption & { modelEntity: string }
export type MultiEntitySelectOptionWithTitle = MultiEntitySelectOption & EntityTitle
export type GetOptionFromMultiEntityCallback<E = BaseModel> = (entity: ModelRaw<E>) => MultiEntitySelectOption
export type GetOptionFromEntityCallback<E extends BaseModel = BaseModel> = (
  entity: ModelRaw<E>,
) => EntitySelectOption<E>

export const fieldEmits = <V>() =>
  ({
    [DefaultInputEvent]: (_value: V) => true,
    change: (_value: V) => true,
    // eslint-disable-next-line @typescript-eslint/naming-convention
  }) as { 'update:model-value': (_value: V) => true; change: (_value: V) => true }

export type FieldEmits<V> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'update:model-value': [V]
  change: [V]
}

export type SelectOptionsPostProcessCallback<SO> = (options: SO[], searchString: string) => SO[]

export enum StatusBaseEditFieldNames {
  name = 'name',
  color = 'color',
  style = 'style',
}

export type FileUploadAreaValue = File | null
export type FileUploadModelAreaValue = FileUploadAreaValue | FileResponse

export type SelectStatusValue = SelectValue
export type SelectStatusOption = SelectOption & {
  color: StatusColor | string
  outline?: boolean
  gradient?: boolean
  appearance?: BadgeAppearance
}

export type SelectPriorityValue = PriorityEnum
export type SelectPriorityOption = SelectOption<SelectPriorityValue>

export type SelectTenDlcVerticalValue = TenDlcVertical
export type SelectTenDlcVerticalOption = SelectOption<SelectTenDlcVerticalValue>

export type SelectKanbanBoardStageValue = string
export type SelectKanbanBoardStageOption = SelectOption<SelectKanbanBoardStageValue> & { color: string }

export type SelectKanbanBoardValue = string
export type SelectKanbanBoardOption = SelectOption<SelectKanbanBoardValue> & { kanbanBoard: KanbanBoardBase }

export type SelectWithIconValue = string
export type SelectWithIconOption<TValue extends SelectWithIconValue = SelectWithIconValue> = SelectOption<TValue> & {
  icon: IconName
}

export type SelectWebWidgetIconOption = SelectWithIconOption<WebWidgetDetailsMinimizedStateIcon>

export interface SelectSearchOption {
  // @todo: will be deleted
  text: string
  subheader?: string | null
  value: string
  disable?: boolean
  country?: Country | null
}

export type SelectInboxSubheaderOption = SelectOption & {
  subheader: string
  disabled: true
}

export type SelectInboxOverrideOption = SelectOption & {
  overrideOption: true
  type: 'button' | 'string'
  to?: RouteLocationRaw
  disabled?: true
}

export type SelectInboxOption = SelectOption | SelectInboxSubheaderOption | SelectInboxOverrideOption

export type SelectFacebookPageSubheaderOption = SelectOption & {
  subheader: string
  disabled: true
}

export type SelectFacebookPageOverrideOption = SelectOption & {
  overrideOption: true
  type: 'button' | 'string'
  to?: RouteLocationRaw
  disabled?: true
}

export type SelectFacebookPageOption =
  | SelectOption
  | SelectFacebookPageSubheaderOption
  | SelectFacebookPageOverrideOption

export type SelectInstagramAccountSubheaderOption = SelectOption & {
  subheader: string
  disabled: true
}

export type SelectInstagramAccountOverrideOption = SelectOption & {
  overrideOption: true
  type: 'button' | 'string'
  to?: RouteLocationRaw
  disabled?: true
}

export type SelectInstagramAccountOption =
  | SelectOption
  | SelectInstagramAccountSubheaderOption
  | SelectInstagramAccountOverrideOption

export type WeekDaysTimeRangeList = {
  enabled: boolean
  ranges: DateRange[]
}

export type NumberLabelFormSchema = {
  phone: string
  label: string
  countryId: TmCountryCode | null
}

export type FormEventCallback = (invalidFieldNames: string[]) => void

export interface AttachmentProviderInterface {
  addFileId: (fileId: string) => void
  removeFileId: (relatedId: string) => void
  getAttachedFiles: () => AttachedFileWithRelatedId[]
}

export type ImageFileFieldUploadedValue = {
  type: 'uploaded'
  file: AttachmentFile
  needDelete?: boolean
}
export type ImageFileFieldSelectedValue = {
  type: 'selected'
  file: File
}
export type ImageFileFieldValue = ImageFileFieldUploadedValue | ImageFileFieldSelectedValue | null | undefined
export const isImageFileFieldUploadedValue = (value: ImageFileFieldValue): value is ImageFileFieldUploadedValue =>
  value?.type === 'uploaded'
export const isImageFileFieldSelectedValue = (value: ImageFileFieldValue): value is ImageFileFieldSelectedValue =>
  value?.type === 'selected'

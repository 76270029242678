import { injectable } from 'inversify'
import SelectedRow from '@/data/models/tables/SelectedRow'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'

@RepoSettings<Endpoint>({
  model: SelectedRow,
})
@injectable()
export default class SelectedRowRepository extends OrmApiRepository<SelectedRow> {
  public unselectAll(tableModelId: string) {
    return this.insertOrUpdateItem({
      id: tableModelId,
      selectedRows: [],
    })
  }

  public selectRows(tableModelId: string, ids: string[], isSelected: boolean) {
    const selectedRowsSet = this.getSelectedRows(tableModelId)

    ids.forEach((id) => {
      if (isSelected) {
        selectedRowsSet.add(id)
      } else {
        selectedRowsSet.delete(id)
      }
    })

    return this.insertOrUpdateItem({
      id: tableModelId,
      selectedRows: [...selectedRowsSet],
    })
  }

  public getSelectedRows(tableModelId: string) {
    const entity = this.findEntityByIdOrNull(tableModelId)
    return new Set(entity?.selectedRows || [])
  }

  public isRowSelected(tableModelId: string, rowId: string) {
    const selectedRows = this.getSelectedRows(tableModelId)
    return selectedRows.has(rowId)
  }

  public cleanUp(tableModelId: string) {
    this.lowLevelDelete([tableModelId])
  }
}

import {
  AttrField,
  BelongsToField,
  BooleanField,
  HasManyByField,
  HasManyField,
  OrmModel,
  StringField,
} from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import ContactList from '@/data/models/domain/ContactList'
import Country from '@/data/models/domain/Country'
import { modelNames } from '@/data/models/types'
import type { TmCountryCode, TmUndefinedCountryCode } from '@/services/types'
import { CustomFieldValue } from '@/data/models/domain/CustomFieldValue'
import { CUSTOM_FIELD_FORM_FIELD_NAME } from '@/services/domain/cusomFields/types'

@OrmModel(modelNames.CONTACT_IMPORT_RESULT)
export default class ContactImportResult extends BaseModel {
  @StringField()
  public id: string

  @StringField(null)
  public companyName: string

  @AttrField()
  public countryId: TmCountryCode | typeof TmUndefinedCountryCode

  @BelongsToField(Country, 'countryId')
  public country: Country

  @StringField(null)
  public email: string

  @StringField(null)
  public firstName: string

  @StringField(null)
  public lastName: string

  @StringField()
  public phone: string

  @StringField()
  public phoneType: string

  @AttrField([])
  public listIds: string[]

  @HasManyByField(ContactList, 'listIds')
  public lists: ContactList[]

  @HasManyField(CustomFieldValue, 'entityId')
  public [CUSTOM_FIELD_FORM_FIELD_NAME]: CustomFieldValue[]

  @BooleanField()
  public isEmailValid: boolean

  @BooleanField()
  public isPhoneValid: boolean

  @BooleanField()
  public isValidCustomFields: boolean

  @AttrField([])
  public invalidCustomFieldIds: number[]

  @BooleanField()
  public blocked: boolean

  @BooleanField()
  public unsubscribed: boolean

  @StringField()
  public createdAt: string
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type { AutocompletePreloadable } from '@/services/preloaders/types'
import type PageMapPreloadRepository from '@/data/repositories/pageMapPreloadRepository'
import PageMapPreload from '@/data/models/PageMapPreload'
import type BaseModel from '@/data/models/BaseModel'
import type { EndpointParams } from '@/services/endpoints'
import type OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { AutocompleteResult } from '@/services/types'
import type AutocompleteRepository from '@/data/repositories/autocompleteRepository'
import Autocomplete from '@/data/models/Autocomplete'
import type { AutocompleteKeyBuilder } from '@/services/autocompleteKeyBuilder'
import type { ModelRaw } from '@/types'
import type {
  PaginationUrlFilterType,
  PaginationUrlParametersSortType,
  PaginationUrlOtherType,
} from '@/services/tables/types'
import type { ModelRelationArray } from '@/services/domain/types'
import { ServiceGroups } from '@/config/types'

@injectable()
export default class AutocompletePreloaderService implements AutocompletePreloadable {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.AutocompleteKeyBuilder) protected readonly autocompleteKeyBuilder: AutocompleteKeyBuilder,
  ) {}

  public async autocomplete<T extends BaseModel>(
    model: typeof BaseModel,
    search: string,
    searchFields: string[],
    endpointParams: EndpointParams,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    page = 1,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    filters: PaginationUrlFilterType = [],
    perPage: number,
    sort: PaginationUrlParametersSortType,
    other?: PaginationUrlOtherType,
  ): Promise<AutocompleteResult<T>> {
    const domainRepository = this.getDomainRepository<T>(model)
    const shouldBePreloaded = !search && page === 1
    const filtersKey = this.autocompleteKeyBuilder.autocompleteFiltersKey(filters)

    if (shouldBePreloaded && this.isPreloaded(model, page, filters)) {
      return this.getPreloadedAutocompleteResult<T>(model, page, filtersKey)
    }
    try {
      const { items, pagination } = await domainRepository.autocompleteRequest(
        search,
        searchFields,
        endpointParams,
        page,
        filters,
        perPage,
        sort,
        other,
      )

      await domainRepository.insertOrUpdate(items)

      // If there is no pagination then consider that there is one page (we have got all items)
      const pageCount = pagination?.pageCount || 1

      if (shouldBePreloaded) {
        await this.savePreloadedResult(model, page, filtersKey, items, pageCount)
      }

      return { items, pageCount }
    } catch (e) {
      this.markAsFailed(model, page, filtersKey)
      throw e
    }
  }

  public isPreloaded(model: typeof BaseModel, page: number, filters: PaginationUrlFilterType = []) {
    const preloader = this.findPreloader(model, page, this.autocompleteKeyBuilder.autocompleteFiltersKey(filters))
    return Boolean(
      preloader && preloader.isPreloaded && preloader.preloaderType === ServiceGroups.PRELOADERS_AUTOCOMPLETE,
    )
  }

  public cleanUp(model: typeof BaseModel) {
    this.getPageMapPreloadRepository().cleanUp(ServiceGroups.PRELOADERS_AUTOCOMPLETE, model.entity)
    this.getAutocompleteRepository().deleteByCondition(
      (item) => this.autocompleteKeyBuilder.entityFromAutocompleteKey(item.id) === model.entity,
    )
  }

  protected async savePreloadedResult(
    model: typeof BaseModel,
    page: number,
    filtersKey: string,
    items: ModelRaw<any>[],
    allPagesCount: number,
  ) {
    await this.getDomainRepository(model).insertOrUpdate(items)
    await this.getAutocompleteRepository().insertOrUpdate([
      {
        id: this.autocompleteKeyBuilder.autocompleteKey(model, filtersKey),
        allPagesCount,
      },
    ])
    this.getPageMapPreloadRepository().updatePreload({
      model,
      key: this.autocompleteKeyBuilder.pageMapKey(model, page, filtersKey),
      pageKey: String(page),
      accessoryId: model.entity,
      ids: items.map(({ id }) => id),
      updatedAt: new Date().toISOString(),
      preloaderType: ServiceGroups.PRELOADERS_AUTOCOMPLETE,
    })
  }

  protected markAsFailed(model: typeof BaseModel, page: number, filtersKey: string) {
    this.getPageMapPreloadRepository().markAsFailed({
      model,
      key: this.autocompleteKeyBuilder.pageMapKey(model, page, filtersKey),
      pageKey: String(page),
      accessoryId: model.entity,
      updatedAt: new Date().toISOString(),
      preloaderType: ServiceGroups.PRELOADERS_AUTOCOMPLETE,
    })
  }

  protected getPreloadedAutocompleteResult<T extends BaseModel>(
    model: typeof BaseModel,
    page: number,
    filtersKey: string,
  ): AutocompleteResult<T> {
    const ids = this.findPreloader(model, page, filtersKey).map
    const items = this.getDomainRepository<T>(model).findIn(ids, [`${model.entity}.*`] as ModelRelationArray<T>)
    const pageCount = this.findAutocomplete(model, filtersKey).allPagesCount
    return {
      items,
      pageCount,
    }
  }

  protected findPreloader(model: typeof BaseModel, page: number, filtersKey: string) {
    const autocompleteKey = this.autocompleteKeyBuilder.pageMapKey(model, page, filtersKey)
    return this.getPageMapPreloadRepository().find(autocompleteKey)
  }

  protected findAutocomplete(model: typeof BaseModel, filtersKey: string) {
    const autocompleteKey = this.autocompleteKeyBuilder.autocompleteKey(model, filtersKey)
    return this.getAutocompleteRepository().find(autocompleteKey)
  }

  protected getPageMapPreloadRepository() {
    return this.entityManager.getRepository<PageMapPreloadRepository>(PageMapPreload)
  }

  protected getAutocompleteRepository() {
    return this.entityManager.getRepository<AutocompleteRepository>(Autocomplete)
  }

  protected getDomainRepository<T extends BaseModel>(model: typeof BaseModel) {
    return this.entityManager.getRepository<OrmApiRepository<T>>(model)
  }
}

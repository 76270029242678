import type { TmRoutes } from '@/services/route/routerTypes'

export const senderIdsRouteName = 'user.services.senderSettings.senderIds'
export const senderIdsRoute: TmRoutes = {
  name: senderIdsRouteName,
}

export enum RegulationsSettingsRoutes {
  tollFreeSettings = 'user.services.senderSettings.tollFree',
  tenDlcSettings = 'user.services.senderSettings.tenDlc',
  tenDlcCampaignDetails = 'user.services.senderSettings.tenDlcCampaignDetails',
  tenDlcCampaignDetailsOverview = 'user.services.senderSettings.tenDlcCampaignDetails.overview',
  tenDlcCampaignDetailsNumbers = 'user.services.senderSettings.tenDlcCampaignDetails.numbers',
}

export const regulationsSettingsRouteNames = [
  RegulationsSettingsRoutes.tollFreeSettings,
  RegulationsSettingsRoutes.tenDlcSettings,
  RegulationsSettingsRoutes.tenDlcCampaignDetails,
  RegulationsSettingsRoutes.tenDlcCampaignDetailsOverview,
  RegulationsSettingsRoutes.tenDlcCampaignDetailsNumbers,
]

export enum SenderSettingsRoutes {
  index = 'user.services.senderSettings',
  senderSettingsIndex = 'user.services.senderSettings.index',
  senderSettingsBase = 'user.services.senderSettings.senderSettingsBase',
  countries = 'user.services.senderSettings.countries',
  numbers = 'user.services.senderSettings.numbers',
  buyNumberBase = 'user.services.senderSettings.buyNumberBase',
  buyNumber = 'user.services.senderSettings.buyNumber.number',
  callForwarding = 'user.services.senderSettings.buyNumber.callForwarding',
}

export enum SmsSurveyRoutes {
  index = 'user.services.smsSurveys',
  overview = 'user.services.smsSurveys.overview',
  details = 'user.services.smsSurveys.details',
  detailsOverview = 'user.services.smsSurveys.details.overview',
  detailsReplies = 'user.services.smsSurveys.details.replies',
  detailsAnalytics = 'user.services.smsSurveys.details.analytics',
  viewFlow = 'user.services.smsSurveys.viewFlow',
  edit = 'user.services.smsSurveys.edit',
}

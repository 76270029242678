import { isEqual, isNil } from 'lodash-es'
import { injectable } from 'inversify'
import {
  isPaginationUrlFilterScalarValueType,
  type PaginationUrlFilterNullableScalarValueType,
  type PaginationUrlFilterNullableSingleType,
  type PaginationUrlFilterNullableType,
  type PaginationUrlFilterNullableValueType,
  type PaginationUrlFilterType,
} from '@/services/tables/types'
import { FILTER_RESERVED_NAMES } from '@/services/tables/filters/types'
import type { Nullable } from '@/types'
import type { FilterOperationValue } from '@/services/types'
import { isRecordUnknown } from '@/utils/typeGuards'
import { isEmptyArray } from '@/services/validation/defaultValidators/utils'
import {
  CUSTOM_FIELD_SUPPORTED_VALUES,
  customFieldFilterNamePrefix,
  prepareCustomFieldFilters,
} from '@/utils/custom-fields/customFieldsFilter'
import type { CustomFieldPaginationUrlFilterScalarValueType } from '@/utils/custom-fields/types'

@injectable()
export class FilterCompareService {
  public isEqual(a: PaginationUrlFilterNullableType, b: PaginationUrlFilterNullableType): boolean {
    FILTER_RESERVED_NAMES.forEach((name) => {
      // Delete reserved field names because it cannot be filters
      a.forEach((i) => {
        delete i[name]
      })
      b.forEach((i) => {
        delete i[name]
      })
    })

    const [aFilter, bFilter] = this.getMostExhaustiveArray(a, b)
    const bBuffer = [...prepareCustomFieldFilters(bFilter as PaginationUrlFilterType)]

    return prepareCustomFieldFilters(aFilter as PaginationUrlFilterType).every((item) => {
      for (const bIndex in bBuffer) {
        const bItem = bBuffer[bIndex]

        if (this.isObjectsEqual(item, bItem)) {
          // The item is found, remove it from the buffer so it cannot be matched during following iterations
          delete bBuffer[bIndex]
          return true
        }
      }

      // Trying to compare the item with empty object, if the buffer is empty
      // For example, for this case:
      //
      // a: [{ "foo": { "eq": 1 } }, { "bar": null }]
      // b: [{ "foo": { "eq": 1 } }]
      //
      // If we'll just return false (because there's no such items) it will not be correct.
      // But this case can also be interpreted as:
      // a: [{ "foo": { "eq": 1 } }, { "bar": null }]
      // b: [{ "foo": { "eq": 1 } }, {}]
      //
      // And the result will be true, which is correct
      return bBuffer.length === 0 && this.isObjectsEqual(item, {})
    })
  }

  public isCustomFieldsValuesEqual(
    a: CustomFieldPaginationUrlFilterScalarValueType[] | null,
    b: CustomFieldPaginationUrlFilterScalarValueType[] | null,
  ): boolean {
    const [aVal, bVal] = this.getMostExhaustiveArray(a ?? [], b ?? [])
    const bBuffer = [...bVal]

    return aVal.every((aItem) => {
      const cfId = aItem.id
      const bItemIndex = bBuffer.findIndex((i) => String(i.id) === String(cfId))

      const bItem = bBuffer[bItemIndex]

      const valueType = Object.keys(aItem).find(
        (key) => (CUSTOM_FIELD_SUPPORTED_VALUES as readonly string[]).includes(key) && !isNil(aItem[key]),
      )
      if (!valueType) {
        return false
      }

      if (bItemIndex !== -1 && valueType in aItem && valueType in bItem) {
        return this.isValuesEqual(aItem[valueType], bItem[valueType])
      }

      return bBuffer.length === 0 && this.isValuesEqual(aItem[valueType], null)
    })
  }

  public isValuesEqual(a: PaginationUrlFilterNullableValueType, b: PaginationUrlFilterNullableValueType): boolean {
    if (isNil(a) && isNil(b)) {
      return true
    }

    // Relation filter
    if (isRecordUnknown(a) && Object.values(a).every((i) => isNil(i) || isPaginationUrlFilterScalarValueType(i))) {
      return Object.entries(a).every(([relation, scalar]) => {
        return this.isEqualPaginationUrlFilterScalarValueType(
          scalar as unknown as Nullable<Partial<FilterOperationValue>>,
          b?.[relation],
        )
      })
    }

    // Scalar filter
    if (isPaginationUrlFilterScalarValueType(a)) {
      return this.isEqualPaginationUrlFilterScalarValueType(a, b)
    }

    return false
  }

  public isObjectsEqual(a: PaginationUrlFilterNullableSingleType, b: PaginationUrlFilterNullableSingleType): boolean {
    const [aFilter, bFilter] = this.getMostExhaustiveObject(a, b)

    // The most complex part starts here, sorry :)
    return Object.entries(aFilter).every(([filterName, value]) => {
      if (filterName === customFieldFilterNamePrefix) {
        return this.isCustomFieldsValuesEqual(
          value,
          bFilter[filterName] as CustomFieldPaginationUrlFilterScalarValueType[],
        )
      }

      return this.isValuesEqual(value, bFilter[filterName])
    })
  }

  protected isEqualPaginationUrlFilterScalarValueType(
    first?: PaginationUrlFilterNullableScalarValueType,
    second?: PaginationUrlFilterNullableScalarValueType,
  ): boolean {
    if (isNil(first) && isNil(second)) {
      return true
    }

    const [a, b] = !isNil(first) ? [first, second] : [second, first]

    // It's safe to use nullish coalescing here 'cause we've checked it before:
    // If both values are null, we've returned true.
    // If no, at least one of them is not null, and we've swapped it if the first one is null
    return Object.entries(a!).every(([operation, aValue]) => {
      const bValue = b?.[operation]

      // Empty values are obviously always equal
      if (isNil(aValue) && isNil(bValue)) {
        return true
      }

      // Empty arrays have no sense in filters. E.g. { "foo": { "in": [] } } does nothing.
      if ((isNil(bValue) && isEmptyArray(aValue)) || (isNil(aValue) && isEmptyArray(bValue))) {
        return true
      }

      if (Array.isArray(aValue) && Array.isArray(bValue)) {
        return isEqual(this.normalizeValue(aValue), this.normalizeValue(bValue))
      }

      return this.normalizeValue(aValue) === this.normalizeValue(bValue)
    })
  }

  /**
   * We need to find the most exhaustive filter to iterate it.
   *
   * For example: we have two filters:
   * 1. { "foo": { "eq": 1 } }
   * 2. { "foo": { "eq": 1 }, "bar": { "eq": "baz" } }
   *
   * The second one is a most exhaustive one because it has more keys in it.
   * If we'll iterate over the first one, we'll not find the difference.
   *
   */
  protected getMostExhaustiveObject(
    a: PaginationUrlFilterNullableSingleType,
    b: PaginationUrlFilterNullableSingleType,
  ): [PaginationUrlFilterNullableSingleType, PaginationUrlFilterNullableSingleType] {
    const aKeysLength = isRecordUnknown(a) ? Object.keys(a).length : 0
    const bKeysLength = isRecordUnknown(b) ? Object.keys(b).length : 0

    return aKeysLength > bKeysLength ? [a, b] : [b, a]
  }

  protected getMostExhaustiveArray<T = PaginationUrlFilterNullableType[number]>(a: T[], b: T[]): [T[], T[]] {
    const aLength = a.length
    const bLength = b.length

    return aLength > bLength ? [a, b] : [b, a]
  }

  protected normalizeValue<T = unknown>(value: T): string | string[] | T {
    // Numbers and string are the same for filters because we're passing them as query params (strings)

    if (Array.isArray(value)) {
      if (value.every((i) => typeof i === 'number')) {
        return value.map((i) => i.toString()).toSorted()
      }

      if (value.every((i) => typeof i === 'string')) {
        return value.toSorted()
      }

      return value
    }

    if (typeof value === 'number') {
      return value.toString()
    }

    if (typeof value === 'boolean') {
      return value ? '1' : '0'
    }

    if (value === null) {
      return ''
    }

    return value
  }
}

import type { Channelable, LogChannel, Loggable, LogRule } from '@/core/logger/types'
import { LogLevel } from '@/core/logger/types'

export default class BaseLogChannel implements Channelable {
  protected channel: LogChannel

  protected rule: LogRule

  protected logger: Loggable

  public setLogger(logger: Loggable): this {
    this.logger = logger

    return this
  }

  public setChannel(channel: LogChannel): this {
    this.channel = channel

    return this
  }

  public setRule(rule: LogRule): this {
    this.rule = rule

    return this
  }

  public getRule() {
    return this.rule
  }

  public isDisabled() {
    return this.channel.level === LogLevel.NULL
  }

  public log(message: string, subchannel: string): void {
    this.toChannel(message, subchannel)
  }

  public table(data: Record<string, any>, subchannel: string): void {
    this.logger.table(
      this.getChannelName(this.channel.channel!, subchannel),
      data,
      this.channel.format,
      this.channel.level,
    )
  }

  public toChannel(message: string, subchannel: string): void {
    this.logger.log(
      this.getChannelName(this.channel.channel!, subchannel),
      message,
      this.channel.format,
      this.channel.level,
    )
  }

  public stack(stack: string) {
    this.logger.stack(stack, this.channel.level)
  }

  public time(label: string) {
    this.logger.time(label, this.channel.level)
  }

  public timeLog(label: string) {
    this.logger.timeLog(label, this.channel.level)
  }

  public timeEnd(label: string) {
    this.logger.timeEnd(label, this.channel.level)
  }

  public group(groupName: string, callback: () => void, subchannel: string) {
    this.groupStart(groupName, subchannel)
    callback()
    this.groupEnd()
  }

  public groupStart(groupName: string, subchannel: string) {
    this.logger.log(
      this.getChannelName(this.channel.channel!, subchannel),
      `***  START_GROUP:${groupName}  *****`,
      this.channel.format,
      this.channel.level === LogLevel.NULL ? LogLevel.NULL : LogLevel.DEBUG,
    )
  }

  public groupEnd() {
    this.logger.log(
      this.channel.channel!,
      '***  END_GROUP  *****\n',
      this.channel.format,
      this.channel.level === LogLevel.NULL ? LogLevel.NULL : LogLevel.DEBUG,
    )
  }

  public info(args: string, subchannel: string) {
    this.logger.log(
      this.getChannelName(this.channel.channel!, subchannel),
      args,
      this.channel.format,
      this.channel.level === LogLevel.NULL ? LogLevel.NULL : LogLevel.INFO,
    )
  }

  public error(args: string, subchannel: string) {
    this.logger.log(
      this.getChannelName(this.channel.channel!, subchannel),
      args,
      this.channel.format,
      this.channel.level === LogLevel.NULL ? LogLevel.NULL : LogLevel.ERROR,
    )
  }

  public debug(args: string, subchannel: string) {
    this.logger.log(
      this.getChannelName(this.channel.channel!, subchannel),
      args,
      this.channel.format,
      this.channel.level === LogLevel.NULL ? LogLevel.NULL : LogLevel.DEBUG,
    )
  }

  public raw(raw: any) {
    this.logger.raw(raw, this.channel.level === LogLevel.NULL ? LogLevel.NULL : LogLevel.DEBUG)
  }

  protected getChannelName(channel: string, subchannel: string) {
    return [channel, subchannel].filter((value) => value).join(':')
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type UserService from '@/services/domain/user/userService'
import type { Resolvable } from '@/services/resolvers/types'
import type GlobalStateService from '@/services/GlobalStateService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import User from '@/data/models/domain/User'
import type PermissionsService from '@/services/permissionsService'
import SubAccountPermissions from '@/data/models/domain/SubAccountPermissions'

@injectable()
export default class SubAccountsResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.UserService)
    protected readonly userService: UserService,
    @inject(SERVICE_TYPES.GlobalStateService)
    protected readonly globalStateService: GlobalStateService,
    @inject(SERVICE_TYPES.PermissionsService)
    protected readonly permissionsService: PermissionsService,
    @inject(SERVICE_TYPES.ModelSubscriptionService)
    protected readonly modelSubscriptionService: ModelSubscriptionService,
  ) {}

  private subscriptionKeys: string[] | null = null

  public async resolve() {
    await this.updateGlobalState()

    this.subscriptionKeys = this.modelSubscriptionService.subscribeByModels([User, SubAccountPermissions], () => {
      this.updateGlobalState()
    })
  }

  public async unresolve() {
    if (this.subscriptionKeys) {
      this.modelSubscriptionService.unsubscribe(this.subscriptionKeys)
    }
  }

  private async updateGlobalState() {
    if (!this.permissionsService.isActivePermission('subaccountSettings')) {
      await this.globalStateService.setFieldValueIfChanged('hasActiveSubAccounts', false)
    } else {
      const totalCount = await this.userService.getSubAccountsTotalCountByFilter({
        other: {
          statuses: this.userService.getStatusesForEnabledSubAccount(),
        },
      })
      await this.globalStateService.setFieldValueIfChanged('hasActiveSubAccounts', totalCount > 1)
    }
  }
}

/* eslint-disable @typescript-eslint/naming-convention */

export const liveChatAttachmentFileFormats: Record<string, string | string[]> = {
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.odt': 'application/vnd.oasis.opendocument.text',
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xlsm': 'application/vnd.ms-excel.sheet.macroenabled.12',
  '.ods': 'application/vnd.oasis.opendocument.spreadsheet',
  '.numbers': 'application/x-iwork-numbers-sffnumbers',
  '.csv': 'text/csv',
  '.pdf': 'application/pdf',
  '.png': 'image/png',
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.gif': 'image/gif',
  '.svg': 'image/svg+xml',
  '.tiff': 'image/tiff',
  '.ico': ['image/x-icon', 'image/vnd.microsoft.icon'],
  '.webp': 'image/webp',
  '.aif': ['audio/aiff', 'audio/x-aiff'],
  '.aiff': ['audio/aiff', 'audio/x-aiff'],
  '.aifc': ['audio/aiff', 'audio/x-aiff'],
  '.aifr': ['audio/aiff', 'audio/x-aiff'],
  '.mid': 'audio/midi',
  '.midi': 'audio/midi',
  '.mp3': 'audio/mpeg',
  '.mpa': 'audio/mpeg',
  '.ogg': 'audio/ogg',
  '.wav': 'audio/wav',
  '.wma': 'audio/x-ms-wma',
  '.3g2': 'video/3gpp2',
  '.3gp': ['video/3gpp', 'audio/3gpp'],
  '.avi': 'video/avi',
  '.flv': 'video/x-flv',
  '.h264': 'video/h264',
  '.m4v': 'video/x-m4v',
  '.mkv': 'video/x-matroska',
  '.mov': 'video/quicktime',
  '.mp4': 'video/mp4',
  '.mpg': 'video/mpeg',
  '.mpeg': 'video/mpeg',
  '.wmv': 'video/x-ms-wmv',
  '.key': 'application/vnd.apple.keynote',
  '.odp': 'application/vnd.oasis.opendocument.presentation',
  '.pps': 'application/vnd.ms-powerpoint',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.email': 'message/rfc822',
  '.eml': 'message/rfc822',
  '.emlx': 'message/rfc822',
  '.msg': 'application/vnd.ms-outlook',
  '.oft': 'application/vnd.ms-outlook',
  '.ost': 'application/vnd.ms-outlook',
  '.pst': 'application/vnd.ms-outlook',
  '.vcf': 'text/vcard',
  '.html': 'text/html',
}

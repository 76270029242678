import type { Config } from '@/core/types'
import { FeatureFlags } from '@/services/types'

type ProdConfig = Omit<Config, 'toolbar'>

export type CoreUrlsConfig = {
  appUrl: string
  apiUrl: string
  socketUrl: string
}

const getConfig = (envSource = import.meta.env): ProdConfig => ({
  env: 'prod',
  appUrl: envSource.VUE_APP_APP_URL,
  apiUrl: envSource.VUE_APP_API_URL,
  telemetryUrl: envSource.VUE_APP_TELEMETRY_URL,
  socketUrl: envSource.VUE_APP_WIRE_URL,
  logger: {
    enabled: false,
    loggerRules: {},
    channels: {},
  },
  sentry: {
    dsn: envSource.VUE_APP_SENTRY_DSN ?? '',
    timeout: Number(envSource.VUE_APP_SENTRY_TIMEOUT) || 2000,
    maskSelectors: ['input[name=password]'],
    unMaskSelectors: ['*'],
  },
  monitoring: {
    key: envSource.VUE_APP_MONITORING_KEY,
  },
  tracking: {
    forceTracking: Boolean(envSource.VUE_APP_FORCE_TRACKING),
    zendeskKey: envSource.VUE_APP_ZENDESK_KEY,
    googleAnalyticsMeasurementKey: envSource.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_KEY,
    segmentAnalyticsKey: envSource.VUE_APP_SEGMENT_ANALYTICS_KEY,
    segmentSnippetVersion: envSource.VUE_APP_SEGMENT_ANALYTICS_SNIPPET_VERSION,
    firstPromoterId: envSource.VUE_APP_FIRST_PROMOTER_ID,
    firstPromoterHost: envSource.VUE_APP_FIRST_PROMOTER_HOST,
    hotJarKey: envSource.VUE_APP_HOTJAR_KEY,
    hotJarSnippetVersion: envSource.VUE_APP_HOTJAR_SNIPPET_VERSION,
    postHogUrl: envSource.VUE_APP_POSTHOG_URL,
    postHogKey: envSource.VUE_APP_POSTHOG_KEY,
    convertKey: envSource.VUE_APP_CONVERT_KEY,
  },
  featureFlags: {
    [FeatureFlags.Tickets]: envSource.VUE_APP_TICKETS === 'true',
    [FeatureFlags.TicketsNotifications]: envSource.VUE_APP_TICKETS_NOTIFICATION === 'true',
    [FeatureFlags.Tasks]: envSource.VUE_APP_TASKS === 'true',
    [FeatureFlags.Deals]: envSource.VUE_APP_DEALS === 'true',
    [FeatureFlags.Segments]: envSource.VUE_APP_SEGMENTS === 'true',
    [FeatureFlags.ChatsUseCache]: envSource.VUE_APP_CHATS_USE_CACHE === 'true',
    [FeatureFlags.Instagram]: envSource.VUE_APP_INSTAGRAM === 'true',
  },
})

export default getConfig

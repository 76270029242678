import { OrmModel, StringField, BooleanField, NumberField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.COUNTER)
export default class Counter extends BaseModel {
  @StringField()
  public id: string

  @BooleanField(false)
  public overLimit: boolean

  @NumberField(null)
  public total: number

  @NumberField(null)
  public exportTotal: number
}

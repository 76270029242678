import { inject, injectable } from 'inversify'
import type { RegisteredServices } from '@/core/container/types'
import type BaseFormService from '@/services/forms/baseFormService'
import type { FormBuilderInterface } from '@/services/forms/baseForm/types'
import type { Dict, Factory } from '@/types'
import type { GetLocator } from '@/core/container/container'
import type { Forms } from '@/services/forms/formTypes'
import { TmFormError } from '@/core/error/tmFormError'

@injectable()
export default class FormFactoryManager {
  protected readonly formServices: Map<RegisteredServices, BaseFormService<FormBuilderInterface>> = new Map()

  constructor(@inject('GetLocator') private readonly get: GetLocator) {}

  public createFormFromFactoryAndGetId(serviceId: RegisteredServices, factoryArgument?: Dict): Forms {
    const formFactory = this.get<Factory<BaseFormService<FormBuilderInterface>, [Dict]>>(serviceId)
    const formService = formFactory(factoryArgument ?? {})
    const formId = formService.getFormId()

    this.formServices.set(formId, formService)
    return formId
  }

  public removeForm(formId: Forms) {
    this.formServices.delete(formId)
  }

  public getForm<T extends BaseFormService<FormBuilderInterface>>(formId: Forms): T {
    if (!this.hasForm(formId)) {
      throw new TmFormError(`Not exist form ${formId}`)
    }

    return this.formServices.get(formId)! as T
  }

  public hasForm(formId: Forms) {
    return this.formServices.has(formId)
  }
}

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum CONTACT_LIST_COUNTER_SERVICES {
  ListContactsCounterRepository,
  ListContactsCounterService,
  ContactListTempDetailsCounterService,
}

export type ContactListCounterServices = keyof typeof CONTACT_LIST_COUNTER_SERVICES

export const CONTACT_LIST_COUNTER_SERVICES_TYPES =
  makeServicesTypesFromEnum<ContactListCounterServices>(CONTACT_LIST_COUNTER_SERVICES)

<template>
  <div class="tm-breadcrumbs-skeleton">
    <tm-skeleton
      v-for="item in count"
      :key="item"
      type="QChip"
      width="80px"
      height="10px"
      class="mr-3"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'

export default defineComponent({
  components: { TmSkeleton },
  props: {
    count: {
      type: Number,
      default: 2,
    },
  },
})
</script>

<style lang="scss" scoped>
.tm-breadcrumbs-skeleton {
  display: flex;
  align-items: center;
  padding: 11px 0;
}
</style>

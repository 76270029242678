import { OrmModel, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.SUB_ACCOUNT_PERMISSIONS)
export default class SubAccountPermissions extends BaseModel {
  @AttrField()
  public actionsPermissions: DisabledOptions<SubAccountPermissionsActions>

  @AttrField()
  public messagingPermissions: DisabledOptions<SubAccountPermissionsMessaging>

  @AttrField()
  public contactsPermissions: DisabledOptions<SubAccountPermissionsContacts>

  @AttrField()
  public servicesPermissions: DisabledOptions<SubAccountPermissionsServices>

  @AttrField()
  public accountPermissions: DisabledOptions<SubAccountPermissionsAccount>

  @AttrField()
  public reportingPermissions: DisabledOptions<SubAccountPermissionsReporting>
}

export interface DisabledOption<T> {
  value: T
  disabled: boolean
}

export type DisabledOptions<T> = {
  [P in keyof T]: DisabledOption<T[P]>
}

export interface SubAccountPermissionsActions {
  exportData: boolean
  deleteData: boolean
  mobileApp: boolean
  importContacts: boolean
}
export interface SubAccountPermissionsMessaging {
  scheduled: boolean
  history: boolean
  composePage: boolean
  sendMms: boolean
  byoc: boolean
}

export interface SubAccountPermissionsContacts {
  contacts: boolean
  resubscribe: boolean
  createNewLists: boolean
}

export interface SubAccountPermissionsServices {
  emailToSms: boolean
  distributionLists: boolean
  textToSpeechSettings: boolean
  api: boolean
  automationRules: boolean
  carrierLookup: boolean
  subscribeForms: boolean
  senderSettings: boolean
  makeCalls: boolean
  emailLookup: boolean
  verifyApi: boolean
}

export interface SubAccountPermissionsAccount {
  accountSettings?: boolean
  /**
   * new rule, not implemented on BE
   */
  billing?: boolean
  /**
   * @deprecated - will be replaced on "billing"
   */
  autorechargeSettings?: boolean
  /**
   * @deprecated - will be replaced on "billing"
   */
  invoices: boolean
  subaccountSettings: boolean
  messagingData: boolean
  accountNotifications: boolean
}

export interface SubAccountPermissionsReporting {
  reportingOverview: boolean
  reportingMessages: boolean
  reportingCalls: boolean
  /**
   * @deprecated - will be replaced on "billing"
   */
  reportingStatements: boolean // now it is just statements
  reportingAuditLogs: boolean
  reportingSubAccounts: boolean
  reportingUsers: boolean
  reportingNumbers: boolean
}

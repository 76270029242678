import { inject, injectable } from 'inversify'
import type { Router } from 'vue-router'
// TODO: Fix ignored tp/forbid-import-composable-to-service
// eslint-disable-next-line tp/using-vue-in-services-restriction,tp/forbid-import-composable-to-service
import type { Plugin } from '@/composition/vue/compositionApi'
import { SERVICE_TYPES } from '@/core/container/types'
import type { ExternalTrackerAsPlugin, TrackableConfig } from '@/services/tracking/types'
import type RouterService from '@/services/route/routerService'
import type SubscriptionService from '@/services/transport/subscriptionService'
import ExternalTrackerService from '@/services/tracking/externalTrackerService'
import ConfigReader from '@/core/configReader'
import { ROUTER_AFTER_EACH } from '@/services/route/types'

@injectable()
export default class FirstPromoterService extends ExternalTrackerService {
  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
  ) {
    super()
  }

  public getConfig(): TrackableConfig {
    const config = ConfigReader.config()

    return {
      id: config.tracking.firstPromoterId,
      host: config.tracking.firstPromoterHost,
    }
  }

  public getPlugin(): Plugin {
    return {
      install: () => this.initialize(),
    } as Plugin
  }

  protected initialize(): void {
    const trackUser = () => {
      this.track()
    }
    const trackingHandler = async () => {
      const router: Router = this.routerService.getRouter()
      await router.isReady()
      trackUser()
      this.subscriptionService.subscribe(ROUTER_AFTER_EACH, trackUser, true)
    }
    const trackerAsPlugin = {
      src: 'https://cdn.firstpromoter.com/fprom.js',
      trackingHandler,
    } as ExternalTrackerAsPlugin

    this.initializeTrackerAsPlugin(trackerAsPlugin)
  }

  /* eslint-disable */
  protected track(): void {
    const id = this.getConfig().id
    const host = this.getConfig().host

    ;(function () {
      try {
        // @ts-expect-error
        $FPROM.init(id, host)
      } catch {}
    })()
  }
  /* eslint-disable */
}

// Adds a plus sign (+) to the beginning of a phone number if it's missing
export const addPlusToPhoneNumber = (phone: number | string): string => {
  if (!phone) {
    return ''
  }
  const strPhone = `${phone}`
  if (strPhone.startsWith('+')) {
    return strPhone
  }
  return `+${strPhone}`
}

import { inject, injectable } from 'inversify'
import type {
  GroupedWrapperInterface,
  PartialUIConfig,
  WrapperManagerInterface,
  WrapperOpenableInterface,
  WrapperParams,
  WrapperServiceInterface,
  WrapperTypesServicesKeys,
} from '@/services/wrappers/types'
import { WrapperTypesServices } from '@/services/wrappers/types'
import type { RegisteredServices } from '@/core/container/types'
import { SERVICE_TYPES } from '@/core/container/types'
import BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import type BaseWrapperRepository from '@/data/repositories/wrappers/baseWrapperRepository'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import { TmWrapperError } from '@/core/error/tmWrapperError'
import type LoggerService from '@/services/loggerService'
import type { ModelRaw, ParamsPartial } from '@/types'
import { isRecordUnknown } from '@/utils/typeGuards'
import type {
  WrapperTableParams,
  WrapperTableParamsToSave,
  WrapperTableServiceInterface,
} from '@/services/wrappers/table/types'
import type { GetLocator } from '@/core/container/container'
import type { IServiceManager } from '@/core/middlewares/types'
import type { Service, AppModule } from '@/config/types'
import type ModalService from '@/services/wrappers/modalService'
import type WrappersConfigService from '@/services/wrappers/wrappersConfigService'
import type { TmWrappers } from '@/wrappers'

@injectable()
export default class WrapperManagerService<T extends BaseWrapper = BaseWrapper, B extends ParamsPartial = ParamsPartial>
  implements WrapperManagerInterface<T>, GroupedWrapperInterface<B>, WrapperTableServiceInterface, IServiceManager
{
  private services = new Set<RegisteredServices>()

  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject('GetLocator') private readonly get: GetLocator,
    @inject(SERVICE_TYPES.ModalService) protected readonly modalService: ModalService,
    @inject(SERVICE_TYPES.WrappersConfigService) protected readonly wrappersConfigService: WrappersConfigService,
  ) {}

  public addService(service: Service) {
    this.services.add(service.id as RegisteredServices)
  }

  public setWrapper(wrapperId: string, wrapperType: WrapperTypesServicesKeys) {
    this.getOrCreateWrapperModel(wrapperId, wrapperType)
    this.getWrapperRepository().update([
      {
        id: wrapperId,
        wrapperType,
      },
    ])
  }

  public build(wrapperId: string, wrapperType?: WrapperTypesServicesKeys) {
    const discoveredType: WrapperTypesServicesKeys | undefined = wrapperType || this.getWrapperTypeFromConfig(wrapperId)

    if (discoveredType) {
      this.setWrapper(wrapperId, discoveredType)
    }
    return this.getWrapperService(wrapperId).build(wrapperId)
  }

  public completeBuilds() {
    this.services.forEach((id) => {
      const service = this.get<WrapperServiceInterface<T>>(id)
      if (service.completeBuilds) {
        service.completeBuilds()
      }
    })
  }

  public getWrapperData(wrapperId: string) {
    return this.getWrapperService(wrapperId).getWrapperData(wrapperId)
  }

  public open(wrapperId: string, params: WrapperParams = {}, wrapperType?: WrapperTypesServicesKeys) {
    if (!this.isBuilt(wrapperId)) {
      this.build(wrapperId, wrapperType)
    } else if (wrapperType) {
      this.setWrapper(wrapperId, wrapperType)
    }

    const wrapperService = this.getWrapperService<WrapperOpenableInterface<T>>(wrapperId)
    if (wrapperService.open) {
      wrapperService.open(wrapperId, params || {})
    } else {
      throw new TmWrapperError(`Open is not implemented in ${wrapperService.getWrapperData(wrapperId)?.wrapperType}`)
    }
  }

  public isOpened(wrapperId: string) {
    return this.getWrapperService<WrapperOpenableInterface<T>>(wrapperId).isOpen(wrapperId)
  }

  public setModules(wrapperId: string, modules: AppModule[]) {
    this.modalService.setModules(wrapperId, modules)
  }

  public close(wrapperId: string) {
    if (!this.isBuilt(wrapperId)) {
      this.build(wrapperId)
    }

    const wrapperService = this.getWrapperService<WrapperOpenableInterface<T>>(wrapperId)
    if (wrapperService.close) {
      wrapperService.close(wrapperId)
    } else {
      throw new TmWrapperError(`Close is not implemented in ${wrapperService.getWrapperData(wrapperId)?.wrapperType}`)
    }
  }

  public setParams(wrapperId: string, params?: WrapperParams) {
    this.getWrapperService(wrapperId).setParams(wrapperId, params)
  }

  public getTableParams<R extends WrapperParams>(wrapperId: string): R {
    return this.getParams<R>(wrapperId)
  }

  public getParams<R extends WrapperParams>(wrapperId: string): R {
    return this.getWrapperService(wrapperId).getParams(wrapperId) as R
  }

  public patchParams(wrapperId: string, params?: WrapperParams): WrapperParams {
    return this.getWrapperService(wrapperId).patchParams(wrapperId, params)
  }

  public removeParams(wrapperId: string, paramsToDelete: string[]): void {
    this.getWrapperService(wrapperId).removeParams(wrapperId, paramsToDelete)
  }

  public clearParams(wrapperId: string): void {
    this.getWrapperService(wrapperId).clearParams(wrapperId)
  }

  public destroy(wrapperId: string): void {
    this.getWrapperService(wrapperId).destroy(wrapperId)
  }

  public destroyGroup<P extends ParamsPartial>(config: PartialUIConfig<P>): void {
    const wrapperService = this.getWrapperServiceByType(config.type)
    if (this.isGrouped(wrapperService)) {
      wrapperService.destroyGroup(config)
    } else {
      throw new TmWrapperError('closeGroup is not implemented')
    }
  }

  public closeGroup<P extends ParamsPartial>(parts: string[], config: PartialUIConfig<P>): void {
    const wrapperService = this.getWrapperServiceByType(config.type)
    if (this.isGrouped(wrapperService)) {
      wrapperService.closeGroup(parts, config)
    } else {
      throw new TmWrapperError('closeGroup is not implemented')
    }
  }

  public openGroup<P extends ParamsPartial>(parts: string[], config: PartialUIConfig<P>): void {
    const wrapperService = this.getWrapperServiceByType(config.type)
    if (this.isGrouped(wrapperService)) {
      wrapperService.openGroup(parts, config)
    } else {
      throw new TmWrapperError('openGroup is not implemented')
    }
  }

  public buildGroup(config: PartialUIConfig<B>): void {
    this.loggerService.log('partialUI', 'Build group')
    this.loggerService.raw('partialUI', config)
    const groupService = this.getWrapperServiceByType(config.type)
    if (this.isGrouped(groupService)) {
      groupService.buildGroup(config)
    }
  }

  public openedInGroup(config: PartialUIConfig<B>): Record<string, boolean> {
    const wrapperService = this.getWrapperServiceByType(config.type)
    if (this.isGrouped(wrapperService)) {
      return wrapperService.openedInGroup(config)
    }
    throw new TmWrapperError(
      `openedInGroup is not implemented in ${wrapperService.getWrapperData(config.type)?.wrapperType}`,
    )
  }

  public isExist(wrapperId: string): boolean {
    return this.getWrapperService(wrapperId).isExist(wrapperId)
  }

  public isExistGroup(config: PartialUIConfig<B>): boolean {
    const groupService = this.getWrapperServiceByType(config.type)
    if (this.isGrouped(groupService)) {
      return groupService.isExistGroup(config)
    }
    throw new TmWrapperError('isExistGroup is not implemented')
  }

  public isBuilt(wrapperId: string) {
    return this.getWrapperRepository().find(wrapperId) !== null
  }

  public setParamsByTableData(
    wrapperId: string,
    params: WrapperTableParams,
    paramsToSave: WrapperTableParamsToSave,
    shouldReplaceState?: boolean,
  ) {
    const wrapper = this.getWrapperService(wrapperId)
    if (this.isTableWrapper(wrapper)) {
      return wrapper.setParamsByTableData(wrapperId, params, paramsToSave, shouldReplaceState)
    }
    throw new TmWrapperError(`setParamsByTableData is not implemented for ${wrapperId}`)
  }

  public getTableParamKey(key: string, wrapperId: string): string {
    const wrapper = this.getWrapperService(wrapperId)
    if (this.isTableWrapper(wrapper)) {
      return wrapper.getTableParamKey(key, wrapperId)
    }
    throw new TmWrapperError(`getParamKey is not implemented for ${wrapperId}`)
  }

  public removeParamsByTableKeys(wrapperId: string, keysToRemove: string[], shouldReplaceState = false) {
    const wrapper = this.getWrapperService(wrapperId)
    if (this.isTableWrapper(wrapper)) {
      return wrapper.removeParamsByTableKeys(wrapperId, keysToRemove, shouldReplaceState)
    }
    throw new TmWrapperError(`removeParamsByTableKeys is not implemented for ${wrapperId}`)
  }

  public getTableParam(wrapperId: string, key: string) {
    const wrapper = this.getWrapperService(wrapperId)
    if (this.isTableWrapper(wrapper)) {
      return wrapper.getTableParam(wrapperId, key)
    }
    throw new TmWrapperError(`getParam is not implemented for ${wrapperId}`)
  }

  public getAllTableParamsKeys(wrapperId: string): string[] {
    const wrapper = this.getWrapperService(wrapperId)
    if (this.isTableWrapper(wrapper)) {
      return wrapper.getAllTableParamsKeys(wrapperId)
    }
    throw new TmWrapperError(`getAllTableParamsKeys is not implemented for ${wrapperId}`)
  }

  protected isGrouped(service: Record<string, any>): service is GroupedWrapperInterface<ParamsPartial> {
    return service.closeGroup && service.buildGroup && service.openGroup && service.openedInGroup
  }

  protected isTableWrapper(service: unknown): service is WrapperTableServiceInterface {
    return !!(
      isRecordUnknown(service) &&
      service.setParamsByTableData &&
      typeof service.setParamsByTableData === 'function' &&
      service.getTableParamKey &&
      typeof service.getTableParamKey === 'function' &&
      service.getTableParam &&
      typeof service.getTableParam === 'function' &&
      service.removeParamsByTableKeys &&
      typeof service.removeParamsByTableKeys === 'function' &&
      service.isBuilt &&
      typeof service.isBuilt === 'function'
    )
  }

  protected getWrapperService<R extends WrapperServiceInterface<T>>(
    wrapperId: string,
    wrapperType?: WrapperTypesServicesKeys,
  ): R {
    const wrapper = this.getOrCreateWrapperModel(wrapperId, wrapperType).wrapperType
    return this.getWrapperServiceByType(wrapper)
  }

  protected getWrapperServiceByType<R extends WrapperServiceInterface<T>>(wrapperType: WrapperTypesServicesKeys): R {
    return this.get(WrapperTypesServices[wrapperType]) as R
  }

  protected getOrCreateWrapperModel(wrapperId: string, wrapperType?: WrapperTypesServicesKeys): BaseWrapper {
    const wrapper = this.getWrapperRepository().find(wrapperId)
    if (wrapper) {
      return wrapper
    }

    const discoveredType: WrapperTypesServicesKeys | undefined = wrapperType || this.getWrapperTypeFromConfig(wrapperId)

    this.getWrapperRepository().insert([
      {
        id: wrapperId,
        params: {},
        wrapperType: discoveredType || 'wrapper',
      },
    ])

    return this.getWrapperRepository().find(wrapperId)
  }

  protected getWrapperTypeFromConfig(wrapperId: string) {
    return this.wrappersConfigService.getWrapperType(wrapperId as TmWrappers)
  }

  public getWrappersByTypes<W extends BaseWrapper>(types: WrapperTypesServicesKeys[]) {
    return this.getWrapperRepository()
      .getAllRaw()
      .filter((wrapper) => types.includes(wrapper.wrapperType)) as ModelRaw<W>[]
  }

  public getWrapperRepository() {
    return this.em.getRepository<BaseWrapperRepository>(BaseWrapper)
  }
}

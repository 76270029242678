import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import TenDlcCampaign from '@/data/models/domain/services/regulations/TenDlcCampaign'
import type { Nullable } from '@/types'
import { TmApiNotFoundError } from '@/core/error/transport/tmApiNotFoundError'

@RepoSettings<Endpoint>({
  model: TenDlcCampaign,
  fetch: 'tenDlcCampaign',
  single: 'tenDlcCampaignSingle',
})
@injectable()
export default class TenDlcCampaignsRepository extends OrmApiRepository<TenDlcCampaign> {
  public async getCampaignByRequestId(id: string): Promise<Nullable<TenDlcCampaign>> {
    const campaignPath = this.endpointsService.getPath('tenDlcCampaignByRequest', [id])
    try {
      const result = await this.getApiSource().get<TenDlcCampaign>(campaignPath)
      await this.insertOrUpdate([result.data])

      return result.data
    } catch (e) {
      if (e instanceof TmApiNotFoundError) {
        return null
      }
      throw e
    }
  }

  public async cancelCampaign(id: string) {
    const path = this.endpointsService.getPath('tenDlcCampaignSingle', [id])
    await this.getApiSource().delete(path)
  }

  public async deleteCampaign(id: string) {
    const path = this.endpointsService.getPath('tenDlcCampaignSingle', [id])
    await this.getApiSource().delete(path)
  }

  public async restoreCampaign(id: string) {
    const path = this.endpointsService.getPath('tenDlcCampaignRestore', [id])
    await this.getApiSource().post(path)
  }
}

import { injectable } from 'inversify'
import type { FilteredViewsParsedBodySortType, PaginationUrlParametersSortType } from '@/services/tables/types'

@injectable()
export class SorterSerializerService {
  public getQueryForSorter(
    entityName: string,
    sorter: FilteredViewsParsedBodySortType,
  ): PaginationUrlParametersSortType {
    if (sorter.relation) {
      const relations = sorter.relation.split('.')
      const query = {
        [sorter.name]: {},
      }
      let tmp: Record<string, any> = query[sorter.name]
      relations.forEach((relation, index) => {
        if (index === relations.length - 1) {
          tmp[relation] = sorter.direction
          return
        }
        tmp[relation] = {}
        tmp = tmp[relation]
      })

      return query
    }
    return {
      [entityName]: {
        [sorter.name]: sorter.direction,
      },
    }
  }

  public parseSorter(entityName: string, sorter: PaginationUrlParametersSortType): FilteredViewsParsedBodySortType {
    const firstKey = Object.keys(sorter)[0]
    if (firstKey === entityName) {
      const columnName = Object.keys(sorter[entityName])[0]
      return {
        name: columnName,
        direction: sorter[entityName][columnName],
      }
    }

    const relation = Object.keys(sorter[firstKey])[0]
    return {
      name: firstKey,
      relation,
      direction: sorter[firstKey][relation],
    }
  }
}

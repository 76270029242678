import { injectable } from 'inversify'
import Form from '@/data/models/forms/Form'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'

@RepoSettings<Endpoint>({
  model: Form,
})
@injectable()
export default class BaseFormRepository extends OrmApiRepository<Form> {}

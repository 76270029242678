import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type SenderId from '@/data/models/domain/senderId/SenderId'
import SenderIdPrice from '@/data/models/domain/senderId/SenderIdPrice'

@RepoSettings<Endpoint>({
  model: SenderIdPrice,
})
@injectable()
export default class SenderIdPriceRepository extends OrmApiRepository<SenderId> {
  protected id = '1'

  public getPriceDetails() {
    return this.find(this.id)
  }

  public async fillPriceDetails() {
    const response = await this.getApiSource().get<SenderIdPrice>(this.endpointsService.getPath('senderIdPrice'))
    await this.insertOrUpdate([{ ...response.data, id: this.id }])
    return response.data
  }
}

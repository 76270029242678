import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

export enum CONTACT_SERVICES {
  ContactColumnServiceFactory,
  ContactFormService,
  ContactDetailsFormService,
  ContactDetailsSidebarFormService,
  ContactSettingsFormService,
  SelectingContactsColumnServiceFactory,
  ContactFilterServiceFactory,
  ContactsResolver,
  ContactPreloaderService,
  MyContactsFilterServiceFactory,
  ContactsBulkManager,
  ContactsBulkService,
  ContactBulkFormService,
}

export type ContactServices = keyof typeof CONTACT_SERVICES

export const CONTACT_SERVICES_TYPES: Record<ContactServices, symbol> = makeServicesTypes<ContactServices>(
  enumKeysToArray<ContactServices>(CONTACT_SERVICES),
)

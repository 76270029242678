import { QTooltip } from 'quasar'

Object.assign(QTooltip.props, {
  persistent: {
    type: Boolean,
    default: false,
  },
})

export { QTooltip as QTooltipFixed }

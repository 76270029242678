import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import Preload from '@/data/models/Preload'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'

@RepoSettings<Endpoint>({
  model: Preload,
})
@injectable()
export default class PreloadRepository<P = void> extends OrmApiRepository<Preload<P>> {}

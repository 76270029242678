import { type CommandProps, Node, nodeInputRule, type PasteRule } from '@tiptap/core'
import { EMOJI_COLONS_ATTRIBUTE, HTML_DATA_ATTRIBUTE } from '@/services/forms/tmTiptap/const'
import { findEmoji, getEmojiRegexp, getEmojiShortnameRegexp, getImgInlineStyle } from '@/utils/emojiHelpers'
import { nodePasteRule } from '@/services/forms/tmTiptap/utils/nodePasteRule'
import type { Emoji } from '@/services/forms/tmTiptap/types'

export const emailEmojiAttributeName = 'emailEmoji' as const
export const htmlEmojiDefaultUrl = 'https://downloads.textmagic.com/email_assets/images/empty-filler.gif' as const
export const emailEmojiImgClasses = 'tm-emoji tm-email-emoji-type-image tm-email-emoji-set-apple' as const

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    emailEmoji: {
      insertEmoji: (emoji: Emoji) => ReturnType
    }
  }
}

const inputRuleHandler = (match: string[]) => {
  const emoji = findEmoji(match[0])
  // this need to show system (e.g. MacOS) emoji
  delete match[1]
  if (emoji) {
    return {
      alt: emoji.native,
      src: htmlEmojiDefaultUrl,
      class: emailEmojiImgClasses,
      style: getImgInlineStyle(emoji),
      [HTML_DATA_ATTRIBUTE]: emailEmojiAttributeName,
      [EMOJI_COLONS_ATTRIBUTE]: emoji.colons,
    }
  }
  const text = match[0]
  // emoji not found - leave the text as is
  delete match[0]
  delete match[1]
  return {
    text,
    isText: true,
  }
}

export const TmEmailEmojiExtension = Node.create({
  name: emailEmojiAttributeName,
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addOptions() {
    return {
      // we should always show emojis from our set - not the system ones
      // so this method returns HTML for our emoji
      renderTextAsHtml: true,
    }
  },

  addAttributes() {
    return {
      alt: {},
      src: {
        default: htmlEmojiDefaultUrl,
      },
      class: {
        default: emailEmojiImgClasses,
      },
      style: {},
      [HTML_DATA_ATTRIBUTE]: {
        default: emailEmojiAttributeName,
      },
      [EMOJI_COLONS_ATTRIBUTE]: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: `img[${HTML_DATA_ATTRIBUTE}="${this.name}"]`,
      },
    ]
  },

  renderText({ node }) {
    const { attrs } = node

    if (!this.options.renderTextAsHtml) {
      return attrs.alt
    }

    const img = document.createElement('img')

    for (const [key, value] of Object.entries(attrs)) {
      img.setAttribute(key, value)
    }

    return img.outerHTML
  },

  renderHTML({ HTMLAttributes }) {
    if (!HTMLAttributes.alt) {
      return ''
    }

    const emoji = findEmoji(HTMLAttributes.alt)
    if (!emoji) {
      return ['span', HTMLAttributes, HTMLAttributes.alt]
    }

    return [
      'img',
      {
        ...HTMLAttributes,
        src: htmlEmojiDefaultUrl,
        class: emailEmojiImgClasses,
        [HTML_DATA_ATTRIBUTE]: emailEmojiAttributeName,
        [EMOJI_COLONS_ATTRIBUTE]: emoji.colons,
      },
    ]
  },

  addInputRules() {
    const regexps = [getEmojiShortnameRegexp(true), getEmojiRegexp(true)]
    return regexps.map((regex) =>
      nodeInputRule({
        find: regex,
        type: this.type,
        getAttributes: (match) => inputRuleHandler(match),
      }),
    )
  },

  addPasteRules() {
    const regexps = [getEmojiShortnameRegexp(false, true), getEmojiRegexp(false, true)]
    return regexps.map((regex) =>
      nodePasteRule({
        find: regex,
        type: this.type,
        getAttributes: (match: string[]) => inputRuleHandler(match),
      }),
    ) as unknown as PasteRule[]
  },

  addCommands() {
    return {
      insertEmoji:
        (emoji: Emoji) =>
        ({ chain }: CommandProps) =>
          chain()
            .insertContent({
              type: emailEmojiAttributeName,
              attrs: {
                alt: emoji.native,
                src: htmlEmojiDefaultUrl,
                class: emailEmojiImgClasses,
                style: getImgInlineStyle(emoji),
                [HTML_DATA_ATTRIBUTE]: emailEmojiAttributeName,
                [EMOJI_COLONS_ATTRIBUTE]: emoji.colons,
              },
            })
            .run(),
    }
  },
})

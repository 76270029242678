import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { FormConfig, FormFieldFilterType } from '@/services/forms/types'
import BaseFilterForm from '@/services/tables/filters/baseFilterForm'
import type EntityManagerService from '@/data/repositories/entityManagerService'

@injectable()
export class FilterBuilderService {
  constructor(@inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService) {}

  public build<T extends FormFieldFilterType>(formConfig: FormConfig<T>): { form: BaseFilterForm<T> } {
    const form = new BaseFilterForm<T>(formConfig.formId, this.em)
    form.buildForm(formConfig)
    return { form }
  }
}

<template>
  <div class="page-blocks-embed">
    <div
      class="page-blocks-embed__content"
      :class="{
        'page-blocks-embed__content--left-wide': wideLeft,
        'page-blocks-embed__content--wide': wide,
        'page-blocks-embed__content--full-width': fullWidth,
      }"
    >
      <div
        v-if="breadcrumbs || skeleton || $slots['page-breadcrumbs-left']"
        class="d-flex align-center justify-between"
      >
        <tm-breadcrumbs-skeleton
          v-if="skeleton"
          :count="3"
        />

        <slot name="page-breadcrumbs-left">
          <tm-breadcrumbs-wrapper v-if="breadcrumbs">
            <tm-breadcrumbs :breadcrumbs="breadcrumbs" />
          </tm-breadcrumbs-wrapper>
        </slot>

        <slot name="page-breadcrumbs-right" />
      </div>

      <slot name="page-blocks-top" />

      <template v-if="hasBlocksLeftSlot">
        <div class="d-flex">
          <div class="page-blocks-embed__left">
            <slot name="page-blocks-left" />
          </div>
          <div class="page-blocks-embed__right">
            <slot name="page-blocks-right" />
          </div>
        </div>
      </template>

      <slot />

      <div
        v-if="hasWhiteWrapperSlot"
        class="page-blocks-embed__wrapper"
        :class="{
          'page-blocks-embed__wrapper--no-padding': noPadding,
        }"
      >
        <slot
          name="title"
          :title="title"
        >
          <div
            v-if="title"
            class="d-flex align-center mb-6"
          >
            <div class="page-title-20 d-flex align-center emphasize--text">
              {{ title }}
              <tm-dropdown
                v-if="hasTitleActionsSlot"
                class="d-flex"
              >
                <tm-button
                  class="ml-1"
                  size="xSmall"
                  icon-only
                  flat
                  no-border
                >
                  <tm-icon
                    class="distinct--text"
                    name="tmi-arrow-down"
                    size="large"
                  />
                </tm-button>
                <template v-slot:menu>
                  <slot name="title-actions" />
                </template>
              </tm-dropdown>
            </div>

            <slot name="title-info" />
          </div>
        </slot>

        <slot name="white-wrapper" />
      </div>

      <slot name="after-wrapper" />
    </div>
  </div>
</template>
<script lang="ts">
import TmButton from '@/components/shared/TmButton.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmBreadcrumbs from '@/components/shared/TmBreadcrumbs.vue'
import { defineComponent, type PropType, ref } from '@/composition/vue/compositionApi'
import type { Breadcrumbs } from '@/components/shared/types'
import TmBreadcrumbsWrapper from '@/components/shared/TmBreadcrumbsWrapper.vue'
import TmBreadcrumbsSkeleton from '@/components/shared/skeletons/TmBreadcrumbsSkeleton.vue'

export default defineComponent({
  components: {
    TmBreadcrumbsSkeleton,
    TmBreadcrumbsWrapper,
    TmButton,
    TmBreadcrumbs,
    TmDropdown,
    TmIcon,
  },
  props: {
    fullWidth: {
      type: Boolean,
    },
    wideLeft: {
      type: Boolean,
    },
    wide: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    breadcrumbs: {
      type: Array as PropType<Breadcrumbs>,
    },
    noPadding: {
      type: Boolean,
    },
    skeleton: {
      type: Boolean,
    },
  },
  setup(_, context) {
    const hasBlocksLeftSlot = !!context.slots['page-blocks-left']
    const hasWhiteWrapperSlot = !!context.slots['white-wrapper']
    const hasTitleActionsSlot = !!context.slots['title-actions']
    const titleMenu = ref(false)

    return {
      hasBlocksLeftSlot,
      hasWhiteWrapperSlot,
      hasTitleActionsSlot,
      titleMenu,
    }
  },
})
</script>

<style lang="scss" scoped>
$horizontalPaddings: 32px;
$mobileHorizontalPaddings: 16px;

.page-blocks-embed {
  background: $shade;
  height: 100%;
  position: relative;

  &__wrapper {
    background: $white;
    border-radius: $border-radius-md;
    border: 1px solid $border-color;
    padding: 32px $horizontalPaddings 24px;
    box-shadow: $white-block-shadow;
    &--no-padding {
      padding: 0;
    }
    @include media-md-max {
      padding: $mobileHorizontalPaddings;
      &--no-padding {
        padding: 0;
      }
    }
  }
  &__content {
    max-width: calc(#{$pageBlocksWidth} + #{2 * $horizontalPaddings});
    margin: 0 auto 40px;
    padding-left: $horizontalPaddings;
    padding-right: $horizontalPaddings;

    &--wide {
      max-width: calc(#{$pageBlocksWideWidth} + #{2 * $horizontalPaddings});
    }

    @include media-md-max {
      margin-bottom: 32px;
      padding-left: $mobileHorizontalPaddings;
      padding-right: $mobileHorizontalPaddings;

      body:not(.platform-ios) & {
        width: 100%;
      }
    }

    &--full-width {
      max-width: 100% !important;
    }
  }
  &__right {
    flex-grow: 1;
    overflow: hidden;

    .page-blocks-embed__content--left-wide & {
      flex-grow: 0;
      width: 400px;
      min-width: 400px;

      @include media-md-max {
        width: 336px;
        min-width: 336px;
      }
    }
  }
  &__left {
    width: 420px;
    min-width: 420px;
    flex: 0 auto;
    margin-right: 20px;
    .page-blocks-embed__content--left-wide & {
      flex-grow: 1;
      min-width: 0;
    }
  }
}
</style>

import type { NodeType } from '@tiptap/pm/model'
import { PasteRule } from '@tiptap/core'
import type { ExtendedRegExpMatchArray, PasteRuleFinder } from '@tiptap/core'

export function nodePasteRule(config: {
  find: PasteRuleFinder
  type: NodeType
  getAttributes?: (match: ExtendedRegExpMatchArray) => Record<string, unknown>
}) {
  return new PasteRule({
    find: config.find,
    handler({ match, chain, range }) {
      const attributes = config.getAttributes ? config.getAttributes(match) : null
      if (attributes === null) {
        return null
      }

      if (match.input) {
        chain().deleteRange(range).insertContentAt(range.from, {
          type: config.type.name,
          attrs: attributes,
        })
      }

      return undefined
    },
  })
}

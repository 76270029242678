import type { BaseFilterInterface } from '@/core/tables/types'
import type { FormFieldFilterType, FilterServiceInterface, SelectOption } from '@/services/forms/types'
import type { RegisteredServices } from '@/core/container/types'
import type BaseModel from '@/data/models/BaseModel'
import type { Operation } from '@/services/types'
import type BaseFilterModel from '@/data/models/filters/BaseFilterModel'
import type RangeFilterModel from '@/data/models/filters/RangeFilterModel'
import type FieldIdFilter from '@/data/models/filters/FieldId'
import type { PromiseOr, TransformerCallback } from '@/types'
import type { IconName } from '@/assets/icons/icons'
import type { BasePaginationUrlParams, PaginationData } from '@/services/tables/pagination/types'
import { FILTERED_VIEW_TYPE_PROP_NAME } from '@/services/domain/filteredViews/const'
import type { CounterInterface } from '@/services/tables/types'

export type SupportedField = {
  availableOperations: Operation[]
  name: string
}
export type FilterToCreateCallbackData = {
  id: string
  [key: string]: any
  options: FilterOptions | FetchOptionsFuncWrapper
}
export type FilterOption = SelectOption
export type FilterOptions = Array<FilterOption>
export type FetchOptionsFunc = (search?: string) => PromiseOr<SelectOption[]>
export type FetchOptionsFuncWrapper = () => FetchOptionsFunc
export type FilterToCreateCallback = TransformerCallback<FilterToCreateCallbackData>

export type FilterToCreate<T extends string = string> = {
  icon?: IconName
  disabled?: boolean
  model?: typeof BaseModel
  label?: string
  relatedFieldName?: string
  availableOperations?: Operation[]
  operation?: Operation
  callback?: FilterToCreateCallback
  readonly?: boolean
  filterType?: string
  isDefault?: boolean
} & ({ name: T; isHidden?: false } | { name: string; isHidden: true })

export type FiltersToCreate<T extends string = string> = Array<FilterToCreate<T>>
export type BaseFiltersType = BaseFilterInterface & FilterServiceInterface<FormFieldFilterType>

export interface FactoryItem<T> {
  id: RegisteredServices
  instance: T
}

export type ToFilterQueryPart = {
  operation: Operation
  relationField?: string
  value: unknown
}

export type FilterSupportInfo = {
  isSupported: boolean
  availableOperations: Operation[]
}
export type FilterModalParams = {
  name: string
  title: string
  description?: string
  isHidden?: boolean
}

export const ALL_FILTER_OPTION = '[ALL]'
export type StringRange = [string, string]
export type DateRange = [Date, Date]

export type FilterModel = BaseFilterModel | RangeFilterModel | FieldIdFilter

export type TIsLastPageFunc = (page: number, pagination: PaginationData) => boolean
export type TFetchOptionsFunc<T> = (
  search: string,
  requestParams: BasePaginationUrlParams,
) => Promise<{ items: T[]; pagination: PaginationData }>
export type TConvertToOptionsFunc<T> = (items: T[]) => FilterOptions
export type TStandaloneCounterSettings = {
  getCounterService: () => CounterInterface
  counterId: string
}

export const FILTER_RESERVED_NAMES = [FILTERED_VIEW_TYPE_PROP_NAME] as string[]

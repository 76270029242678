import { injectable } from 'inversify'
import type { MessageSendRepeatYearlyFormScheme, OrdinalDay } from '@/services/domain/messages/types'
import type { SelectFieldConfig, SelectOption } from '@/services/forms/types'
import type {
  FormGroupConfigurationInterface,
  TypedFormBuilderInterface,
} from '@/services/forms/baseForm/typedFormBuilder/types'
import type { Months, WeekDaysFull } from '@/services/types'
import { monthsVariant } from '@/services/types'
import { AbstractFormGroupConfiguration } from '@/services/forms/messages/formGroupConfiguration/AbstractFormGroupConfiguration'

@injectable()
export class YearlyFormGroupConfiguration
  extends AbstractFormGroupConfiguration
  implements FormGroupConfigurationInterface<MessageSendRepeatYearlyFormScheme>
{
  private getSelectMonthsOptions(): SelectOption<Months>[] {
    const monthsNames = this.dateTimeService.getMonthsNames('abbreviated')
    return monthsVariant.map((t, index) => ({
      text: monthsNames[index],
      value: t,
    }))
  }

  public configure(builder: TypedFormBuilderInterface<MessageSendRepeatYearlyFormScheme>): void {
    builder
      .property((t) => t.count)
      .control({
        fieldType: 'number',
        value: 1,
      })
    builder
      .property((t) => t.type)
      .control({
        fieldType: 'radio',
        value: 'year-day',
      })
    builder
      .property((t) => t.month)
      .control<SelectFieldConfig<Months>>({
        fieldType: 'select',
        value: 'January',
        options: () => this.getSelectMonthsOptions(),
      })
    builder
      .property((t) => t.monthDay)
      .control<SelectFieldConfig>({
        fieldType: 'select',
        value: '1',
        options: () => this.getSelectMonthDayOptions(),
      })
    builder
      .property((t) => t.firstLastDay)
      .control<SelectFieldConfig<OrdinalDay>>({
        fieldType: 'select',
        value: 'First',
        options: () => this.getSelectOrdinalDaysOptions(),
      })
    builder
      .property((t) => t.weekDay)
      .control<SelectFieldConfig<WeekDaysFull>>({
        fieldType: 'select',
        value: 'Sunday',
        options: () => this.getSelectWeekDaysOptions(),
      })
    builder
      .property((t) => t.dayOfMonth)
      .control<SelectFieldConfig<Months>>({
        fieldType: 'select',
        value: 'January',
        options: () => this.getSelectMonthsOptions(),
      })
  }
}

import type { CustomErrorRule } from '@/core/error/transport/types'
import { EndpointsEnum } from '@/services/endpoints'
import { TmApiRetryAfterError } from '@/core/error/transport/tmApiRetryAfterError'

export const authTooManyRequestsErrorRule: CustomErrorRule = {
  klass: TmApiRetryAfterError,
  endpoint: EndpointsEnum.login,
  methods: ['post'],
  predicateFn: (error) => {
    return error.response.headers['cf-mitigated'] === 'challenge'
  },
}

import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'
import { campaignsSurveysDetailsRoute } from '@/routes/user/campaigns/surveys/campaignsSurveysDetails'

export const campaignsSurveysTableRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.surveys.table',
  path: '',
  component: () => import('@/components/views/campaigns/surveys/CampaignsSurveys.vue'),
  meta: {
    tabTitle: 'pageTitle.user.services.smsSurveys',
  },
}

export const campaignsSurveysRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.surveys',
  path: 'surveys',
  children: [campaignsSurveysTableRoute, campaignsSurveysDetailsRoute],
  redirect: { name: campaignsSurveysTableRoute.name },
  meta: {
    modules: [AppModule.SmsSurveys],
  },
}

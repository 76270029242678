import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import UnauthenticatedCountry from '@/data/models/domain/UnauthenticatedCountry'
import type UnauthenticatedCountryRepository from '@/data/repositories/domain/unauthenticatedCountryRepository'

@DomainSettings({
  model: UnauthenticatedCountry,
})
@injectable()
export default class UnauthenticatedCountryService extends DomainBaseService<UnauthenticatedCountryRepository> {
  public async detectCountry(): Promise<UnauthenticatedCountry | null> {
    return this.getDomainRepository().detectCountry()
  }

  public getCountry(): UnauthenticatedCountry | null {
    return this.getDomainRepository().getCountry()
  }

  public async loadCountry(): Promise<UnauthenticatedCountry | null> {
    const country: UnauthenticatedCountry | null = this.getCountry()
    if (country) {
      return country
    }

    return this.detectCountry()
  }
}

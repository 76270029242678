import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'
import type { TmRoutesDictionary } from '@/services/route/routerTypes'

export const campaignsSmsDetailsOverviewRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.smsDetails.overview',
  path: 'overview',
  props: true,
  component: () => import('@/components/views/campaigns/sms/CampaignsSmsDetailsOverview.vue'),
}

export const campaignsSmsDetailsDeliveredRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.smsDetails.delivered',
  path: 'delivered',
  props: true,
  component: () => import('@/components/views/campaigns/sms/CampaignsSmsDetailsDelivered.vue'),
}

export const campaignsSmsDetailsSentRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.smsDetails.sent',
  path: 'sent',
  props: true,
  component: () => import('@/components/views/campaigns/sms/CampaignsSmsDetailsSent.vue'),
}

export const campaignsSmsDetailsFailedRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.smsDetails.failed',
  path: 'failed',
  props: true,
  component: () => import('@/components/views/campaigns/sms/CampaignsSmsDetailsFailed.vue'),
}

export const campaignsSmsDetailsRejectedRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.smsDetails.rejected',
  path: 'rejected',
  props: true,
  component: () => import('@/components/views/campaigns/sms/CampaignsSmsDetailsRejected.vue'),
}

export const campaignsSmsDetailsRoute = {
  name: 'user.campaigns.smsDetails',
  path: 'sms/:id',
  props: (route) => {
    const _route = route as unknown as TmRoutesDictionary['user.campaigns.smsDetails']
    return {
      id: _route.params.id,
      isSuccess: _route.query!.success === 'true',
    }
  },
  component: () => import('@/components/views/campaigns/sms/CampaignsSmsDetails.vue'),
  children: [
    campaignsSmsDetailsOverviewRoute,
    campaignsSmsDetailsDeliveredRoute,
    campaignsSmsDetailsSentRoute,
    campaignsSmsDetailsFailedRoute,
    campaignsSmsDetailsRejectedRoute,
  ],
  redirect: { name: campaignsSmsDetailsOverviewRoute.name },
  meta: {
    modules: [AppModule.SmsCampaigns],
    tabTitle: 'pageTabTitle.user.campaigns.sms.details',
  },
} as const satisfies TmNamedRouteRecord

export const campaignsSmsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.sms',
  path: 'sms',
  component: () => import('@/components/views/campaigns/sms/CampaignsSms.vue'),
  meta: {
    modules: [AppModule.SmsCampaigns],
  },
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import Centrifuge from '@/data/models/domain/Сentrifuge'
import type { CentrifugeTokensResponse } from '@/services/domain/accounts/types'

@RepoSettings<Endpoint>({
  model: Centrifuge,
})
@injectable()
export default class CentrifugeRepository extends OrmApiRepository<Centrifuge> {
  public async getTokens(channels: string[]) {
    const path = this.endpointsService.getPath('centrifugeToken')
    const res = await this.getApiSource().get<CentrifugeTokensResponse>(path, {
      params: {
        channels,
      },
    })
    return res.data
  }
}

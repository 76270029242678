<template>
  <ul v-if="items.length">
    <main-sidebar-item-child
      v-for="(child, i) in items"
      :key="i"
      v-bind="child"
      :highlight-exact-active="highlightExactActive"
    />
  </ul>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import type { PageContentSidebarItem } from '@/components/layout/pageContent/sidebar/types'
import MainSidebarItemChild from '@/components/layout/mainSidebar/mainSidebarItemChild/MainSidebarItemChild.vue'

export default defineComponent({
  components: {
    MainSidebarItemChild,
  },
  props: {
    items: {
      type: Array as PropType<PageContentSidebarItem[]>,
      required: true,
    },
    highlightExactActive: {
      type: Boolean,
    },
  },
})
</script>

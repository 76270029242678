import { injectable } from 'inversify'
import TmLogicError from '@/core/error/tmLogicError'
import type { CacheableFields } from '@/services/forms/fieldCache/types'

type FieldMapEntryParams = {
  field: CacheableFields
  maxElements: number
}
type FieldCache<T> = Map<string, T>
type FieldCacheEntry<T> = { values: FieldCache<T> } & FieldMapEntryParams
type FieldCacheMap = Map<string, FieldCacheEntry<unknown>>

@injectable()
export default class FieldDataCacheService {
  protected readonly cache: FieldCacheMap = new Map()

  public initializeCacheForFieldIfNotExists({ field, maxElements }: FieldMapEntryParams): void {
    if (this.cache.has(field)) {
      return
    }
    this.cache.set(field, {
      values: new Map(),
      field,
      maxElements,
    })
  }

  public clearAll() {
    this.cache.clear()
  }

  public set(fieldKey: CacheableFields, cacheKey: string, value: unknown) {
    const { values, maxElements } = this.getFieldCacheEntry(fieldKey)

    if (values.has(cacheKey)) {
      return
    }
    if (values.size === maxElements) {
      const oldestElementKey = values.keys().next().value
      if (typeof oldestElementKey === 'string') {
        values.delete(oldestElementKey)
      }
    }
    values.set(cacheKey, value)
  }

  public get<T>(fieldKey: CacheableFields, cacheKey: string) {
    return this.getCacheForField<T>(fieldKey).get(cacheKey)
  }

  public delete(fieldKey: CacheableFields, cacheKey: string) {
    this.getCacheForField(fieldKey).delete(cacheKey)
  }

  public clearForField(fieldKey: CacheableFields) {
    // if there is no cache - nothing to clear, should not throw an error
    if (!this.cache.has(fieldKey)) {
      return
    }
    this.getCacheForField(fieldKey).clear()
  }

  public getCacheForField<T>(fieldKey: CacheableFields) {
    return this.getFieldCacheEntry<T>(fieldKey).values
  }

  public getFieldCacheEntry<T>(fieldKey: CacheableFields): FieldCacheEntry<T> {
    if (!this.cache.has(fieldKey)) {
      throw new TmLogicError(`Cache for field ${fieldKey} is not initialized`)
    }
    return this.cache.get(fieldKey)! as FieldCacheEntry<T>
  }
}

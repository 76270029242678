import { injectable } from 'inversify'

import type { BaseTableInterface } from '@/core/tables/baseTableInterface'
import { TmTableNotFoundError } from '@/core/error/table/tmTableNotFoundError'

@injectable()
export default class TableManager {
  private tables: Record<string, BaseTableInterface> = {}

  public addTable(table: BaseTableInterface) {
    this.tables[table.getTableModelId()] = table
  }

  public getTable<T extends BaseTableInterface = BaseTableInterface>(name: string): T {
    if (!this.hasTable(name)) {
      throw new TmTableNotFoundError(`Table "${name}" not found`)
    }

    return this.tables[name] as T
  }

  public hasTable(name: string) {
    return !!this.tables[name]
  }

  public removeTable(name: string) {
    if (this.hasTable(name)) {
      delete this.tables[name]
    }
  }

  public getWrapperIdByTableId<T extends string>(tableId: T): `${T}Wrapper` {
    return `${tableId}Wrapper`
  }

  public getWrapperIdFromTable(tableId: string) {
    return this.getTable(tableId).getTableWrapperId()
  }
}

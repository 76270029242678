import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  MyDataFaceterService,
  MyDataExportService,
  MyDataFacetResolver,
}

export type MyDataServices = keyof typeof SERVICES

export const MY_DATA_SERVICE_TYPES: Record<MyDataServices, symbol> = makeServicesTypes<MyDataServices>(
  enumKeysToArray<MyDataServices>(SERVICES),
)

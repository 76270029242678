import type { TmNamedRouteRecord } from '@/services/route/types'
import ContactsSettingsMenu from '@/components/domain/contacts/ContactsSettingsMenu.vue'
import { AppModule } from '@/config/types'
import Contact from '@/data/models/domain/Contact'
import ContactList from '@/data/models/domain/ContactList'
import { CustomFieldSchema } from '@/data/models/domain/CustomFieldSchema'
import ContactImport from '@/data/models/domain/contactImport/ContactImport'
import UnsubscribedContact from '@/data/models/domain/UnsubscribedContact'
import BlockedContact from '@/data/models/domain/BlockedContact'

export const contactsSettingsRoute: TmNamedRouteRecord = {
  name: 'user.contacts.settings',
  path: 'contacts/settings',
  meta: {
    parent: 'user.contacts',
    title: 'pageTitle.user.contacts.settings.title',
    tabTitle: 'pageTitle.user.contacts.settings.title',
    menu: ContactsSettingsMenu,
    permission: 'contacts',
    modules: [
      AppModule.Contacts,
      AppModule.ContactList,
      AppModule.ContactCounter,
      AppModule.ContactsUnsubscribed,
      AppModule.ContactsBlocked,
      AppModule.PinnedContacts,
    ],
  },
  redirect: { name: 'user.contacts.settings.all' },
  component: () => import('@/components/views/contacts/settings/ContactsSettings.vue'),
  children: [
    {
      path: 'all',
      name: 'user.contacts.settings.all',
      meta: {
        tabTitle: 'pageTabTitle.user.contacts.settings.all',
      },
      component: () => import('@/components/views/contacts/settings/ContactsSettingsAll.vue'),
    },
    {
      path: 'unsubscribe-rules',
      name: 'user.contacts.settings.unsubscribe-rules',
      component: () => import('@/components/views/contacts/settings/ContactsSettingsUnsubscribeRules.vue'),
      meta: {
        tabTitle: 'pageTabTitle.user.contacts.settings.unsubscribe-rules',
        modules: [AppModule.Templates],
      },
    },
    {
      path: 'sharing',
      name: 'user.contacts.settings.sharing',
      component: () => import('@/components/views/contacts/settings/ContactsSettingsSharing.vue'),
      meta: {
        tabTitle: 'pageTabTitle.user.contacts.settings.sharing',
        accessControlGroups: ['SubAccountsWorkflowStrategy'],
      },
    },
    {
      path: 'navigation',
      name: 'user.contacts.settings.navigation',
      meta: {
        tabTitle: 'pageTabTitle.user.contacts.settings.navigation',
        resolvers: [
          {
            service: 'FacetResolver',
            params: {
              model: Contact,
              subscriptionModels: [
                ContactList,
                Contact,
                CustomFieldSchema,
                ContactImport,
                UnsubscribedContact,
                BlockedContact,
              ],
            },
          },
        ],
        resolverComponent: () => import('@/components/resolvers/AsyncResolver.vue'),
      },
      component: () => import('@/components/views/contacts/settings/ContactsSettingsNavigationMenu.vue'),
    },
    {
      path: 'custom-fields',
      name: 'user.contacts.settings.custom-fields',
      meta: {
        tabTitle: 'pageTabTitle.user.contacts.settings.custom-fields',
        resolvers: [
          {
            service: 'FacetResolver',
            params: {
              model: Contact,
              subscriptionModels: [CustomFieldSchema],
            },
          },
        ],
      },
      component: () => import('@/components/views/contacts/settings/ContactsSettingsCustomFields.vue'),
    },
  ],
}

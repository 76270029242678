<template>
  <tm-section-wrapper
    v-if="isTablet"
    wrapper-id="contactsNavigationOrderSection"
  >
    <layout-menu
      :items="routes"
      :highlight-exact-active="highlightPinnedContactList"
    />
    <template v-slot:preloader>
      <tm-skeleton
        v-for="width in randomWidths"
        :key="width"
        height="24px"
        dark
        :width="width + 'px'"
        class="ml-11 mb-1 mt-1"
      />
    </template>
  </tm-section-wrapper>
  <layout-menu
    v-else
    :items="routes"
    :highlight-exact-active="highlightPinnedContactList"
  />

  <pinned-contacts-list @lock-sidebar="$emit('lock-sidebar', $event)" />
</template>

<script lang="ts">
import { computed, defineComponent } from '@/composition/vue/compositionApi'
import { get, getRouterService } from '@/core/container/ioc'
import LayoutMenu from '@/components/layout/LayoutMenu.vue'
import { useContactsSidebarMenu } from '@/composition/domain/contacts'
import { useSortedByOrder } from '@/composition/navigationOrder'
import { useBreakpoints } from '@/composition/breakpoints'
import TmSectionWrapper from '@/components/TmSectionWrapper.vue'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import { isNavigationOrderable } from '@/services/domain/guards'
import { TmComponentError } from '@/core/error/TmComponentError'
import PinnedContactsList from '@/components/domain/contacts/pinned/PinnedContactsList.vue'
import { getRandomsRange } from '@/utils/getRandomsRange'
import { useSidebarPinnedContactItems } from '@/composition/domain/pinnedContacts/useSidebarPinnedContactItems'
import { contactListDetailsRouteName, contactsSegmentDetailsRoute } from '@/routes/user/contacts/contacts'
import type { TmRoutes, TmRoutesDictionary } from '@/services/route/routerTypes'

export default defineComponent({
  components: {
    PinnedContactsList,
    TmSkeleton,
    TmSectionWrapper,
    LayoutMenu,
  },
  emits: {
    'lock-sidebar': (_lock: boolean) => true,
  },
  setup() {
    const routerService = getRouterService()
    const { isTablet } = useBreakpoints()
    const { pinnedContactListsIds, pinnedSegmentsIds, pinnedContactsIds } = useSidebarPinnedContactItems()

    const currentRoute = computed(() => routerService.getCurrentRoute())

    const highlightPinnedContactList = computed(() => {
      const contactDetailsRoute: TmRoutes['name'] = 'user.contacts.myContact.contactDetails'

      if (String(currentRoute.value.name) === contactListDetailsRouteName) {
        const { contactListId } = currentRoute.value.params
        return pinnedContactListsIds.value.has(contactListId as string)
      }

      if (String(currentRoute.value.name) === contactsSegmentDetailsRoute.name) {
        const { segmentId } = currentRoute.value.params
        return pinnedSegmentsIds.value.has(segmentId as string)
      }

      if (String(currentRoute.value.name) === contactDetailsRoute) {
        const params = currentRoute.value
          .params as TmRoutesDictionary['user.contacts.myContact.contactDetails']['params']
        return pinnedContactsIds.value.has(params.contactId)
      }

      return false
    })

    const routes = useContactsSidebarMenu()

    const service = get('ContactNavigationOrderService')
    if (!isNavigationOrderable(service)) {
      throw new TmComponentError(`Service is not NavigationOrderable`)
    }
    const orderComputed = computed(() => service.getNavigationOrder().order)

    const randomWidths = getRandomsRange(6, 80, 120)

    return {
      routes: useSortedByOrder(orderComputed, routes),
      highlightPinnedContactList,
      isTablet,
      randomWidths,
    }
  },
})
</script>

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import ColumnsFromApiStructuredFactory from '@/services/tables/columnsFromApiStructuredFactory'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type LoggerService from '@/services/loggerService'
import type ColumnsFromStoreFactory from '@/services/tables/columnsFromStoreFactory'
import type ColumnsSizeService from '@/services/tables/columnsSizeService'

@injectable()
export default class SubAccountsSwitchColumnService extends ColumnsFromApiStructuredFactory {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.ColumnsFromStoreFactory) columnsFromStore: () => ColumnsFromStoreFactory,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.ColumnsSizeService) protected readonly columnsSizeService: ColumnsSizeService,
  ) {
    super(em, columnsFromStore, loggerService)
  }

  protected getStructuredColumns() {
    const nameMinWidth = this.columnsSizeService.getPersonMinWidth()
    return {
      name: { growRatio: 2, minWidth: nameMinWidth },
      email: { growRatio: 2, minWidth: 200 },
      subaccountType: { dynamicLoaderWidth: true },
      logInButton: { minWidth: 150 },
    }
  }
}

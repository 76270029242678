import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import type NumbersPriceRepository from '@/data/repositories/domain/numbersPriceRepository'
import NumberPrice from '@/data/models/domain/NumberPrice'

@DomainSettings({
  model: NumberPrice,
})
@injectable()
export default class NumbersPriceService extends DomainBaseService<NumbersPriceRepository> {
  public async getNumbersPrice(number: string) {
    return this.getDomainRepository().getNumbersPrice(number)
  }
}

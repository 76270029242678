import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import TenDlcPhoneNumber from '@/data/models/domain/services/regulations/TenDlcPhoneNumber'
import type { TenDlcPhoneNumbersLinkToCampaignBody } from '@/services/domain/services/regulations/tenDlcPhoneNumbers/types'
import type { TenDlcTwilioRequestStatus } from '@/services/domain/services/regulations/tenDlcRequest/types'

@RepoSettings<Endpoint>({
  model: TenDlcPhoneNumber,
  fetch: 'tenDlcPhoneNumbers',
})
@injectable()
export default class TenDlcPhoneNumbersRepository extends OrmApiRepository<TenDlcPhoneNumber> {
  public async linkToCampaign(body: TenDlcPhoneNumbersLinkToCampaignBody): Promise<void> {
    const path = this.endpointsService.getPath('tenDlcNumberLinkToCampaign')
    await this.getApiSource().post(path, body)
  }

  public getNumbersByStatus(status: TenDlcTwilioRequestStatus) {
    const queryParameters = {
      filter: [],
      other: {
        status,
      },
    }
    return this.doGrid(this.getSettingsFetch(), [], queryParameters)
  }

  public async getNumbersByCampaignId(campaignId: string): Promise<TenDlcPhoneNumber[]> {
    const queryParameters = {
      perPage: 250,
      filter: [],
      other: {
        campaignId,
      },
    }

    const data = await this.doGrid(this.getSettingsFetch(), [], queryParameters)
    await this.insertOrUpdate(data.items)

    return data.items as TenDlcPhoneNumber[]
  }

  public findNumbersByCampaignId(campaignId: string): TenDlcPhoneNumber[] {
    return this.query().where('campaignId', campaignId).all()
  }
}

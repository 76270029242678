import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum ACTIVITY_SERVICES {
  ActivityCallService,
  ActivityScheduledGridable,
  ActivitySentSmsGridable,
  ActivityReceivedSmsGridable,
  ActivityCallRepository,
  ActivityCallGridable,
  ActivityWhatsAppGridable,
  ActivityTicketsGridable,
  WhatsAppActivityRepository,
  WhatsAppActivityService,
  LiveChatActivityRepository,
  LiveChatActivityService,
  FacebookActivityRepository,
  FacebookActivityService,
  InstagramActivityRepository,
  InstagramActivityService,
}

export type ActivityServices = keyof typeof ACTIVITY_SERVICES

export const ACTIVITY_SERVICES_TYPES = makeServicesTypesFromEnum<ActivityServices>(ACTIVITY_SERVICES)

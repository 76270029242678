import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import TeamWorkflow from '@/data/models/domain/TeamWorkflow'

@RepoSettings<Endpoint>({
  model: TeamWorkflow,
})
@injectable()
export default class TeamWorkflowRepository extends OrmApiRepository<TeamWorkflow> {}

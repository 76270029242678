<script lang="ts">
import { computed, defineComponent, h } from '@/composition/vue/compositionApi'
import type { IconSize, SizeProp } from '@/components/shared/types'
import { getIconSizePx } from '@/composition/icon'

export default defineComponent({
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String,
    },
    size: {
      type: String as SizeProp<IconSize>,
    },
  },
  setup(props, context) {
    const width = computed(() => props.size && getIconSizePx(props.size))

    return () =>
      h('img', {
        ...context.attrs,
        src: props.src,
        alt: props.alt,
        style: {
          width: width.value,
        },
      })
  },
})
</script>

import type { SerializerFn } from '@/services/serializers/types'
import type { Dict, ModelRaw } from '@/types'
import type User from '@/data/models/domain/User'
import type UserAnalyticsTraits from '@/data/models/domain/UserAnalyticsTraits'

export const userAnalyticsSerializer: SerializerFn = (user: Dict<ModelRaw<User>>) => ({
  id: user.id,
  email: user.email,
  createdAt: new Date(String(user.createdAt)),
  firstName: user.firstName,
  lastName: user.lastName,
  phone: user.phone,
  username: user.username,
  status: user.status,
  type: user.subaccountType,
  company: user.company,
  balance: user.balance,
  lastActive: user.lastActive ? new Date(String(user.lastActive)) : null,
  lastLogin: user.lastActive ? new Date(String(user.lastActive)) : null,
  currency: user.currency?.id,
  organization: user.company,
  orgname: user.company,
  acCompany: user.company,
  acUsername: user.username,
  acUserId: user.id,
  acCreatedAt: new Date(String(user.createdAt)),
  acCountry: user.countryId,
  acTimezone: user.timezone?.timezone,
})

export const userAnalyticsTraitsSerializer: SerializerFn = (trait: Dict<ModelRaw<UserAnalyticsTraits>>) => ({
  address: {
    state: trait.state,
    postalCode: trait.postalCode,
    street: trait.street,
  },
  ltv: trait.lifeTimeValue,
  mostUsedProduct: trait.mostUsedProduct,
  autorecharge: trait.autoRechargeEnabled,
  acZip: trait.postalCode,
  acState: trait.state,
})

import { difference, isEmpty, intersection } from 'lodash-es'
import { getNumericHash } from '@/utils/hash'
import type { NonEmptyArray } from '@/types'

/**
 * Return intersection between 2 arrays with the same array items type
 * Work only with basic types
 * @param array1
 * @param array2
 */
export const intersect = <T>(array1: T[], array2: T[]): T[] => array1.filter((value) => array2.includes(value))

/**
 * Return items of first array without items in second array
 * Items should be the same scalar type
 * @param array1
 * @param array2
 */
export const subtract = <T>(array1: T[], array2: T[]): T[] => array1.filter((value) => !array2.includes(value))

export type DifferenceType<T> = {
  isSame: boolean
  inserted: T[]
  deleted: T[]
}
/**
 * Return difference of two arrays
 *
 * @see DifferenceType
 * @param a
 * @param b
 */
export const smartDifference = <T>(a: T[], b: T[]): DifferenceType<T> => {
  const isSame = a.every((value, index) => value === b[index]) && a.length === b.length
  return {
    isSame,
    deleted: difference<T>(a, b),
    inserted: difference<T>(b, a),
  }
}

/**
 * Extend each array item's value from another array using relation by the same property
 *
 * For example if [{id: 1, name: 'Test1', count: 0}] array extend by [{id: 1, count: 1}] array by count field via id prop
 * Result will be equal [{id: 1, name: 'Test1', count: 1}]
 *
 * @see unittests for the function
 * @param toExtend
 * @param source
 * @param moveProps
 * @param anchorProp
 */
export const arrayItemsExtendBy = <T extends Record<string, any>, R extends Record<string, any>>(
  toExtend: T[],
  source: R[],
  // eslint-disable-next-line @typescript-eslint/default-param-last
  moveProps: [keyof R, keyof T][] = [],
  anchorProp: keyof T & keyof R,
) => {
  const map = source.reduce((acc: Record<string, any>, item) => {
    for (const prop of moveProps) {
      if (!acc[item[anchorProp]]) {
        acc[item[anchorProp]] = {}
      }
      acc[item[anchorProp]][prop[1]] = item[prop[0]]
    }
    return acc
  }, {})
  return toExtend.map((item) => ({ ...item, ...map[item[anchorProp]] }))
}

export const getItemByHashNumber = <T>(hashNumber: number, items: T[] | readonly T[]) =>
  items[Math.abs(hashNumber) % items.length]

export const getItemByHashString = <T>(hashString: string, items: T[] | readonly T[]) =>
  getItemByHashNumber(getNumericHash(hashString), items)

export const removeArrayItemByIndex = <T>(array: T[], index: number): T[] =>
  array.slice(0, index).concat(array.slice(index + 1))

/**
 * Determines whether two arrays have any common elements.
 * @param arr1
 * @param arr2
 * @return {boolean}
 */
export const hasIntersection = <T>(arr1: T[], arr2: T[]): boolean => !isEmpty(intersection(arr1, arr2))

export const fillNewArray = <T>(length: number, fill: (i: number) => T) =>
  new Array(length).fill('').map((_, i) => fill(i))

export const fillNewArrayStatic = <T>(length: number, value: T) => new Array(length).fill(value)

export const isNonEmptyArray = <T>(value: unknown): value is NonEmptyArray<T> => {
  return Array.isArray(value) && value.length > 0
}

export const getHashMapFromFlatArray = <T extends string>(array: T[]): Record<T, true> =>
  array.reduce((sum, item) => ({ ...sum, [item]: true }), {} as Record<T, true>)

import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import FilterSchema from '@/data/models/tables/FilterSchema'
import { filterSchema } from '@/services/resolvers/domain/filterSchema/types'
import type FilterSchemaRepository from '@/data/repositories/filters/filterSchemaRepository'

@injectable()
export default class FilterSchemaResolver implements Resolvable {
  constructor(@inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService) {}

  public resolve(): any {
    this.entityManager.getRepository<FilterSchemaRepository>(FilterSchema).insert(
      filterSchema.map((schema) => ({
        id: schema.name,
        schema,
      })),
    )
  }
}

import type { RouteRecordRaw } from 'vue-router'
import { ChatSettingsRoutes } from '@/routes/types'
import { AppModule } from '@/config/types'
import PassThrough from '@/components/layout/PassThrough.vue'
import type { RedirectableSingleResolverParams } from '@/services/resolvers/types'
import WebWidgetDetails from '@/data/models/domain/messages/settings/webWidget/webWidgetDetails'
import { getByocTabsRoutes } from '@/routes/user/services/byoc'
import { ByocMessengerRouteNames } from '@/services/domain/byoc/types'
import { facebookRoute } from '@/routes/user/messenger/facebook'
import TicketInbox from '@/data/models/domain/tickets/TicketInbox'
import { messengerSettingsEmailsRoute } from '@/routes/user/messenger/messengerSettingsEmails'
import { instagramRoute } from '@/routes/user/messenger/instagram'
import { getByocDetailsFlowRoutes } from '@/routes/user/services/byocFlow'
import FacebookPage from '@/data/models/domain/messenger/facebook/FacebookPage'
import InstagramAccount from '@/data/models/domain/messenger/instagram/InstagramAccount'

export const getMessengerSettingsRoutes = (): RouteRecordRaw[] => [
  {
    name: ChatSettingsRoutes.root,
    path: 'settings',
    component: () => import('@/components/views/chats/settings/MessengerSettings.vue'),
    meta: {
      menu: () => import('@/components/views/chats/settings/MessengerSettingsMenu.vue'),
    },
    redirect: { name: ChatSettingsRoutes.allSettings },
    children: [
      {
        name: ChatSettingsRoutes.allSettings,
        path: 'all',
        meta: {
          tabTitle: 'pageTabTitle.user.chats.settings.all',
        },
        component: () => import('@/components/views/chats/settings/MessengerSettingsAll.vue'),
      },
      {
        name: ChatSettingsRoutes.smsMmsCalls,
        path: 'sms-mms-calls',
        meta: {
          modules: [AppModule.SenderSettings],
        },
        component: () => import('@/components/views/chats/settings/MessengerSettingsSmsMmsCalls.vue'),
        redirect: { name: ChatSettingsRoutes.smsMmsCallsNumbers },
        children: [
          {
            name: ChatSettingsRoutes.smsMmsCallsNumbers,
            path: 'numbers',
            component: () => import('@/components/views/services/senderSettings/PhoneNumbersTable.vue'),
            meta: {
              tabTitle: 'pageTabTitle.user.services.numbers',
            },
          },
          {
            name: ChatSettingsRoutes.smsMmsCallsSenderIds,
            path: 'sender-ids',
            meta: {
              tabTitle: 'pageTabTitle.user.services.senderIds',
              accessControlGroups: ['SenderIdsStrategy'],
            },
            component: () => import('@/components/views/services/senderSettings/SenderIds.vue'),
          },
        ],
      },
      {
        name: ByocMessengerRouteNames.main,
        path: 'byoc',
        redirect: { name: ByocMessengerRouteNames.providers },
        children: [
          {
            name: ByocMessengerRouteNames.byocProvidersBase,
            path: '',
            meta: {
              modules: [AppModule.Byoc],
              resolvers: [{ service: 'ByocResolverService' }],
              permission: 'byoc',
              accessControlGroups: ['ByocStrategy'],
            },
            component: () => import('@/components/views/chats/settings/MessengerSettingsByoc.vue'),
            redirect: { name: ByocMessengerRouteNames.providers },
            children: [...getByocTabsRoutes(ByocMessengerRouteNames)],
          },
          ...getByocDetailsFlowRoutes(ByocMessengerRouteNames.flow),
        ],
      },
      messengerSettingsEmailsRoute as RouteRecordRaw,
      {
        name: ChatSettingsRoutes.whatsapp,
        path: 'whatsapp',
        meta: {
          tabTitle: 'whatsapp.title',
          modules: [AppModule.WhatsApp],
          resolvers: [
            {
              service: 'WhatsAppResolver',
            },
          ],
          isNew: true,
        },
        component: PassThrough,
        redirect: { name: ChatSettingsRoutes.whatsappIndex },
        children: [
          {
            name: ChatSettingsRoutes.whatsappIndex,
            path: '',
            component: () => import('@/components/views/chats/whatsapp/WhatsAppSettings.vue'),
          },
          {
            name: ChatSettingsRoutes.whatsappFlow,
            path: 'flow/:stepNumber?',
            props: true,
            component: () => import('@/components/views/chats/whatsapp/WhatsAppSettingsFlow.vue'),
          },
          {
            name: ChatSettingsRoutes.whatsappAccount,
            path: 'account/:id',
            component: PassThrough,
            redirect: { name: ChatSettingsRoutes.whatsappAccountIndex },
            children: [
              {
                name: ChatSettingsRoutes.whatsappAccountIndex,
                path: '',
                props: true,
                component: () => import('@/components/views/chats/whatsapp/WhatsAppAccountDetails.vue'),
              },
              {
                name: ChatSettingsRoutes.whatsappPhone,
                path: 'phone/:phoneId',
                props: true,
                component: () => import('@/components/views/chats/whatsapp/WhatsAppPhoneDetails.vue'),
              },
            ],
          },
        ],
      },
      facebookRoute,
      instagramRoute,
      {
        name: ChatSettingsRoutes.widgets,
        path: 'web-widget',
        meta: {
          modules: [AppModule.WebWidgets],
          accessControlGroups: ['WebWidgetsStrategy'],
          tabTitle: 'pageTitle.user.chatsSettings.webWidgets',
        },
        component: PassThrough,
        redirect: { name: ChatSettingsRoutes.widgetsIndex },
        children: [
          {
            name: ChatSettingsRoutes.widgetsIndex,
            path: '',
            component: () => import('@/components/views/chats/settings/webWidget/WebWidgetSettings.vue'),
          },
          {
            name: ChatSettingsRoutes.widgetEdit,
            path: ':id',
            props: true,
            component: () => import('@/components/views/chats/settings/webWidget/WebWidgetEdit.vue'),
            meta: {
              resolverComponent: () => import('@/components/resolvers/MessengerWebWidgetDetailsPageResolver.vue'),
              resolvers: [
                {
                  service: 'ModelResolver',
                  params: [TicketInbox, FacebookPage, InstagramAccount],
                },
                {
                  service: 'RedirectableSingleModelResolverService',
                  params: {
                    model: WebWidgetDetails,
                    source: 'router',
                    requestParam: 'id',
                    redirectToIfNotFound: { name: ChatSettingsRoutes.widgets },
                    redirectToIfAccessError: { name: ChatSettingsRoutes.widgets },
                  } as RedirectableSingleResolverParams,
                },
              ],
            },
          },
        ],
      },
      {
        name: ChatSettingsRoutes.tenDlc,
        path: '10dlc',
        component: () => import('@/components/views/chats/settings/MessengerSettingsTenDlc.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.tenDlc',
          accessControlGroups: ['TenDlcStrategy'],
          modules: [AppModule.TenDlcTollFree],
        },
      },
      {
        name: ChatSettingsRoutes.tollFree,
        path: 'toll-free-verification',
        component: () => import('@/components/views/chats/settings/MessengerSettingsTollFree.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.tollFree',
          accessControlGroups: ['TollFreeStrategy'],
          modules: [AppModule.TenDlcTollFree],
        },
      },
      {
        name: ChatSettingsRoutes.general,
        path: 'general',
        meta: {
          tabTitle: 'pageTabTitle.user.chats.settings.general',
        },
        component: () => import('@/components/views/chats/settings/MessengerSettingsGeneral.vue'),
      },
      {
        name: ChatSettingsRoutes.senderSettings,
        path: 'sender-settings',
        component: () => import('@/components/views/chats/settings/MessengerSettingsSenderSettings.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.countries',
        },
      },
    ],
  },
]

import type { RouteRecordRaw } from 'vue-router'
import { ScheduledRoutes } from '@/routes/user/scheduled/types'
import { AppModule } from '@/config/types'

export const scheduledPausedRoutes: RouteRecordRaw = {
  name: ScheduledRoutes.paused,
  path: 'paused',
  redirect: { name: ScheduledRoutes.pausedOverview },
  children: [
    {
      name: ScheduledRoutes.pausedOverview,
      path: '',
      meta: {
        tags: ['SCHEDULED_BAR_LIST'],
        title: 'pageTitle.user.scheduled.paused', // page content title @todo: get rid of it
        tabTitle: 'pageTabTitle.user.scheduled.paused',
        modules: [AppModule.ScheduledPaused],
        accessControlGroups: ['NotCampaignsScheduledPausedStrategy'],
      },
      component: () => import('@/components/views/scheduled/ScheduledPaused.vue'),
    },
  ],
}

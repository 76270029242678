import { inject, injectable } from 'inversify'
import type { FieldConfigWithRequiredValidators, PasswordFieldConfig } from '@/services/forms/types'
import { SERVICE_TYPES } from '@/core/container/types'
import { passwordMaxLength, passwordMinLength } from '@/constants/password'
import type ValidationRulesBuilderService from '@/services/validation/validationRulesBuilderService'
import type { RuleConfig } from '@/services/validation/types'

@injectable()
export default class PasswordFieldBuilderService {
  constructor(
    @inject(SERVICE_TYPES.ValidationRulesBuilderService)
    protected readonly validationRulesBuilderService: ValidationRulesBuilderService,
  ) {}

  public getPasswordConfig(): Omit<FieldConfigWithRequiredValidators<PasswordFieldConfig>, 'name'> {
    const charactersCountConfig: RuleConfig = {
      messagePath: 'specialValidationRegistrator.fromToCharacters',
      messageParams: {
        min: passwordMinLength,
        max: passwordMaxLength,
      },
    }

    const numbersConfig: RuleConfig = {
      messagePath: 'specialValidationRegistrator.oneNumber',
    }

    const specialCharactersConfig: RuleConfig = {
      messagePath: 'specialValidationRegistrator.oneSpecialChar',
    }

    const lowerAndUpperCharacterConfig: RuleConfig = {
      messagePath: 'specialValidationRegistrator.lowerAndUppercase',
    }

    return {
      fieldType: 'password',
      value: '',
      validators: this.validationRulesBuilderService
        .createRules()
        .required(charactersCountConfig)
        .minLength(passwordMinLength, charactersCountConfig)
        .maxLength(passwordMaxLength, charactersCountConfig)
        .hasNumbers(numbersConfig)
        .hasSpecialChars(specialCharactersConfig)
        .hasLowercaseAndUppercase(lowerAndUpperCharacterConfig),
    }
  }
}

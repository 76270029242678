import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  EmailLookupSingleFormService,
  EmailLookupService,
  EmailLookupRepository,
  EmailLookupBulkColumnService,
  EmailLookupHistoryColumnService,
}

export type EmailLookupServices = keyof typeof SERVICES

export const EMAIL_LOOKUP_SERVICE_TYPES: Record<EmailLookupServices, symbol> = makeServicesTypes<EmailLookupServices>(
  enumKeysToArray<EmailLookupServices>(SERVICES),
)

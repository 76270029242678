import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum SIGNUP_CONFIRMATION_SERVICES {
  ConfirmRegistrationResolver,
}

export type SignupConfirmationServices = keyof typeof SIGNUP_CONFIRMATION_SERVICES

export const SIGNUP_CONFIRMATION_SERVICE_TYPES =
  makeServicesTypesFromEnum<SignupConfirmationServices>(SIGNUP_CONFIRMATION_SERVICES)

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  SubAccountsImportService,
  SubAccountsImportDetailsService,
  SubAccountImportRepository,
  SubAccountImportDetailsRepository,
  SubAccountImportPreloaderService,
  SubAccountsImportResolverService,
  SubAccountsImportDetailsFailedFormService,
  SubAccountsImportDetailsColumnServiceFactory,
  SubAccountImportDetailsGridable,
}

export type SubAccountsImportDetailsServices = keyof typeof SERVICES

export const SUB_ACCOUNTS_IMPORT_DETAILS_SERVICE_TYPES: Record<SubAccountsImportDetailsServices, symbol> =
  makeServicesTypes<SubAccountsImportDetailsServices>(enumKeysToArray<SubAccountsImportDetailsServices>(SERVICES))

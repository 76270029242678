import { EmojiIndex } from 'emoji-mart-vue-fast'
import emojiRegex from 'emoji-regex' /* eslint-disable-line tp/forbid-complex-utils */
import type { IconSize } from '@/components/shared/types'
import { getIconSizePx } from '@/composition/icon'
import {
  EMOJI_COLONS_ATTRIBUTE,
  EMOJI_TEXT_ATTRIBUTE,
  emojiAttributeName,
  HTML_DATA_ATTRIBUTE,
} from '@/services/forms/tmTiptap/const'
import { emailEmojiImgClasses } from '@/services/forms/tmTiptap/extensions/TmEmailEmojiExtension'
import type { Emoji } from '@/services/forms/tmTiptap/types'

type EmojiOptions = {
  size?: IconSize
  customSize?: string // example: 22px
  withVerticalAlignCompensation?: boolean
}

const emojiShortnameRegexp = '(:[^\\s:]+(?:::skin-tone-[1-6])?:)'
const emojiRegexp = emojiRegex().source

let emojiIndex: { findEmoji: (name: string) => Emoji | null } = { findEmoji: (name: string) => null }
let emojiLoaded = false

// the "all.json" file is too big (about 1MB) so it should be in a separate bundle
export const getEmojiData = () => import('emoji-mart-vue-fast/data/all.json')

export const lazyLoadEmojiData = () => {
  requestIdleCallback(
    () => {
      loadEmojiData()
    },
    { timeout: 500 },
  )
}

export const loadEmojiData = async () => {
  if (emojiLoaded) {
    return Promise.resolve()
  }
  const data = await getEmojiData()
  emojiIndex = new EmojiIndex(data.default) as { findEmoji: (name: string) => Emoji | null }
  emojiLoaded = true
  return true
}

export const isEmojiLoaded = () => emojiLoaded

export const findEmoji = (emojiName: string): Emoji | null => {
  try {
    return emojiIndex.findEmoji(emojiName)
  } catch (e) {
    return null
  }
}

export const getEmojiImgSrc = () => 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

export const getImgInlineStyle = (emoji: Emoji, options?: EmojiOptions) => {
  const positionStyle = `background-position: ${emoji.getPosition()};`

  let sizePx = ''
  if (options?.customSize) {
    sizePx = options?.customSize
  } else {
    sizePx = getIconSizePx(options?.size ?? 'xMedium')
  }
  const sizeStyle = `width: ${sizePx}; height: ${sizePx};`

  const alignCompensationStyle = options?.withVerticalAlignCompensation ? 'margin-top: -0.2em;' : ''

  return `${positionStyle}${sizeStyle}${alignCompensationStyle}`
}

const getRegexp = (regexp: string, isEnd = false, isGlobal = false, ignoreCharacters?: string): RegExp => {
  let r = regexp
  if (ignoreCharacters) {
    r = `(?!(?:${ignoreCharacters}))(${r})`
  }
  if (isEnd) {
    r += '$'
  }
  if (isGlobal) {
    return new RegExp(r, 'g')
  }
  return new RegExp(r)
}

export const getEmojiRegexp = (isEnd = false, isGlobal = false): RegExp =>
  getRegexp(emojiRegexp, isEnd, isGlobal, '©|®|™')

export const getEmojiShortnameRegexp = (isEnd = false, isGlobal = false): RegExp =>
  getRegexp(emojiShortnameRegexp, isEnd, isGlobal)

export const getImgAttributes = (emoji: Emoji, options?: EmojiOptions) => ({
  class: emailEmojiImgClasses,
  style: getImgInlineStyle(emoji, options),
  [EMOJI_TEXT_ATTRIBUTE]: emoji.native,
  [HTML_DATA_ATTRIBUTE]: emojiAttributeName,
  [EMOJI_COLONS_ATTRIBUTE]: emoji.colons,
  src: getEmojiImgSrc(),
})

const jsonToHtml = (json: Record<string, unknown>): string => {
  const keys = Object.keys(json)
  let result = ''
  keys.forEach((key) => {
    result += `${key}="${json[key]}"`
  })
  return result
}

export const emojiToHtmlReplacer = (emoji: string, options?: EmojiOptions): string => {
  const emojiObject = emojiIndex.findEmoji(emoji)
  if (!emojiObject) {
    return emoji
  }
  return `<img ${jsonToHtml(getImgAttributes(emojiObject, options))} />`
}

export const emojiToHtml = (value: string, options?: EmojiOptions): string => {
  if (!value) {
    return value
  }
  return value.replace(getEmojiRegexp(false, true), (match: string) => emojiToHtmlReplacer(match, options))
}

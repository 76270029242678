import { StringField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import { SmsSurveyStatus } from '@/data/models/domain/types'

export default class BaseSmsSurvey extends BaseModel {
  @StringField()
  public name: string

  @StringField()
  public status: SmsSurveyStatus

  @StringField()
  public createdAt: string

  public get isActive() {
    return [SmsSurveyStatus.STATUS_ACTIVE, SmsSurveyStatus.STATUS_QUEUE, SmsSurveyStatus.STATUS_SCHEDULED].includes(
      this.status,
    )
  }
}

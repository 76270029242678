import deepmerge from 'deepmerge'
import { injectable, inject } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { WrapperParams, WrapperServiceInterface, WrapperTypesServicesKeys } from '@/services/wrappers/types'
import BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import type { ModelRaw } from '@/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import { omitAndCleanArray } from '@/utils/object/omitAndCleanArray'

@injectable()
export default class BaseWrapperService<T extends BaseWrapper = BaseWrapper> implements WrapperServiceInterface<T> {
  constructor(@inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService) {}

  public build(wrapperId: string, wrapperType?: WrapperTypesServicesKeys, model: typeof BaseWrapper = BaseWrapper) {
    const wrapperModel = this.getRepository(model).find(wrapperId)
    if (wrapperModel) {
      return wrapperModel
    }

    return this.getRepository(model).insertOrUpdateRaw([
      {
        id: wrapperId,
        wrapperType: wrapperType || 'wrapper',
      },
    ])
  }

  public getWrapperData(wrapperId: string, model: typeof BaseWrapper = BaseWrapper): ModelRaw<T> {
    return this.getRepository(model).find(wrapperId) as T
  }

  public setParams(wrapperId: string, params?: WrapperParams, model: typeof BaseWrapper = BaseWrapper): void {
    this.getRepository(model).update([
      {
        id: wrapperId,
        params,
      },
    ])
  }

  public patchParams(
    wrapperId: string,
    params?: WrapperParams,
    model: typeof BaseWrapper = BaseWrapper,
  ): WrapperParams {
    const mergedParams = deepmerge(this.getParams(wrapperId), params || {})
    this.setParams(wrapperId, mergedParams)

    return mergedParams
  }

  public getParams(wrapperId: string, model: typeof BaseWrapper = BaseWrapper) {
    return this.getRepository(model).find(wrapperId)?.params
  }

  public removeParams(wrapperId: string, paramsToDelete: string[], model: typeof BaseWrapper = BaseWrapper) {
    const params = this.getParams(wrapperId)
    this.getRepository(model).update([
      {
        id: wrapperId,
        params: omitAndCleanArray(params, paramsToDelete),
      },
    ])
  }

  public clearParams(wrapperId: string, model: typeof BaseWrapper = BaseWrapper) {
    this.getRepository(model).update([
      {
        id: wrapperId,
        params: {},
      },
    ])
  }

  public destroy(wrapperId: string, model: typeof BaseWrapper = BaseWrapper) {
    this.getRepository(model).update([
      {
        id: wrapperId,
        params: {},
      },
    ])
  }

  public isExist(wrapperId: string, model: typeof BaseWrapper = BaseWrapper): boolean {
    return !!this.getRepository(model).find(wrapperId)
  }

  protected getRepository(model: typeof BaseWrapper) {
    return this.em.getRepository(model)
  }
}

import { inject, injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type SessionRepository from '@/data/repositories/domain/session/sessionRepository'
import Session from '@/data/models/domain/session/Session'
import { SessionSource } from '@/types'
import type { Translatable } from '@/services/types'
import type SessionShortService from '@/services/domain/session/sessionShortService'
import type SessionShort from '@/data/models/domain/session/SessionShort'
import { TmEntityNotFoundError } from '@/core/error/tmEntityNotFoundError'
import type { SessionArchiveQueryParams } from '@/services/domain/session/types'

@DomainSettings({
  model: Session,
})
@injectable()
export default class SessionService extends DomainBaseService<SessionRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscription: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: Translatable,
    @inject(SERVICE_TYPES.SessionShortService) protected readonly sessionShortService: SessionShortService,
  ) {
    super(entityManager, subscription, preloaderManager)
  }

  public getSessionSourceText(record: Session | SessionShort): string {
    const map: Partial<Record<SessionSource, string>> = {
      [SessionSource.NONE]: this.translateService.t('status.session.none'),
      [SessionSource.EMAIL]: this.translateService.t('status.session.email'),
      [SessionSource.MAGICMESSAGE]: this.translateService.t('status.session.e2s'),
      [SessionSource.TM_ONLINE]: this.translateService.t('status.session.webApp'),
      [SessionSource.TM_MESSENGER]: this.translateService.t('status.session.messenger'),
      [SessionSource.TM_TEXTME]: this.translateService.t('status.session.list'),
      [SessionSource.API]: this.translateService.t('status.session.api'),
      [SessionSource.TM_WIDGET]: this.translateService.t('status.session.widget'),
      [SessionSource.TM_MOBILE]: this.translateService.t('status.session.mobile'),
      [SessionSource.AUTOMATION]: this.translateService.t('status.session.automation'),
      [SessionSource.IOS_MOBILE]: this.translateService.t('status.session.iosMobile'),
      [SessionSource.ANDROID_MOBILE]: this.translateService.t('status.session.androidMobile'),
      [SessionSource.WS_CHAT]: this.translateService.t('status.session.smsChat'),
      [SessionSource.SURVEY]: this.translateService.t('status.session.survey'),
    }

    return map[record.source] ?? this.translateService.t('status.undefined')
  }

  public getSessionSourceIcon(record: Session | SessionShort): string {
    // @todo replace 'chat_bubble' with proper icon when ready
    const map: Partial<Record<SessionSource, string>> = {
      [SessionSource.NONE]: 'chat',
      [SessionSource.EMAIL]: 'email',
      [SessionSource.MAGICMESSAGE]: 'email',
      [SessionSource.TM_ONLINE]: 'tmi-earth-pixel',
      [SessionSource.TM_MESSENGER]: 'chat_bubble',
      [SessionSource.TM_TEXTME]: 'call_split',
      [SessionSource.API]: 'tmi-application',
      [SessionSource.TM_WIDGET]: 'tmi-form',
      [SessionSource.TM_MOBILE]: 'tmi-phone-android',
      [SessionSource.AUTOMATION]: 'tmi-automation',
      [SessionSource.IOS_MOBILE]: 'tmi-apple',
      [SessionSource.ANDROID_MOBILE]: 'tmi-android',
      [SessionSource.WS_CHAT]: 'chat',
      [SessionSource.SURVEY]: 'tmi-sitemap',
    }

    return map[record.source] ?? 'chat'
  }

  public isBulkSession(session: Session | SessionShort) {
    return session.numbersCount > 1
  }

  public isBulkSessionById(sessionId: string) {
    return this.findAnyTypeEntityByIdOrFail(sessionId).numbersCount > 1
  }

  public async archive(id: string) {
    await this.getDomainRepository().archive(id)
    this.notify([id])
  }

  public async unarchive(id: string) {
    await this.getDomainRepository().unarchive(id)
    this.notify([id])
  }

  public async bulkArchive(params: SessionArchiveQueryParams) {
    await this.getDomainRepository().bulkArchive(params)
    this.notify([])
  }

  public async bulkUnarchive(params: SessionArchiveQueryParams) {
    await this.getDomainRepository().bulkUnarchive(params)
    this.notify([])
  }

  public async updateTitle(id: string, title: string) {
    await this.getDomainRepository().updateTitle(id, title)
    await this.updateItemIfExists({ id, title })
  }

  public findAnyTypeEntityById(id: string): Session | SessionShort | null {
    const session = this.findEntityByIdOrNull(id)
    if (session) return session
    return this.sessionShortService.findEntityByIdOrNull(id)
  }

  public findAnyTypeEntityByIdOrFail(id: string) {
    const session = this.findAnyTypeEntityById(id)
    if (!session) throw new TmEntityNotFoundError(id)
    return session
  }
}

export enum AttachmentFileEntityType {
  task = 'task',
  ticketMessage = 'ticket-message',
  openAIAssistant = 'open-ai-assistant',
  deal = 'deal',
  liveChatMessage = 'live-chat-message',
  webWidget = 'web-widget',
  messengerNote = 'messenger-note',
}

export enum AttachmentFileType {
  attachment = 'attachment',
  chatAttachment = 'chat-attachment', // Deprecated on BE. Use 'attachment' instead
  logo = 'logo',
}

type PreviewableAttachmentFileMetadata = {
  width: number
  height: number
  preview: {
    width: number
    height: number
  }
}

type AttachmentFileImageMetadata = PreviewableAttachmentFileMetadata & {
  type: 'image'
}

type AttachmentFileVideoMetadata = PreviewableAttachmentFileMetadata & {
  type: 'video'
}

export type AttachmentFileMetadata = AttachmentFileImageMetadata | AttachmentFileVideoMetadata

export const isAttachmentFileImageMetadata = (
  metadata?: AttachmentFileMetadata | null,
): metadata is AttachmentFileImageMetadata => metadata?.type === 'image'

export const isAttachmentFileVideoMetadata = (
  metadata?: AttachmentFileMetadata | null,
): metadata is AttachmentFileVideoMetadata => metadata?.type === 'video'

import { inject, injectable } from 'inversify'
import type { Collection } from '@vuex-orm/core'
import { isEmpty, isObject } from 'lodash-es'
import type { ExplicitGroupingDirection, GroupingDirection, GrouperInterface } from '@/core/tables/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type {
  PaginationUrlParametersGroupingType,
  FilteredViewsParsedBodyGroupingType,
  PaginationUrlType,
} from '@/services/tables/types'
import type { GroupingSchemaValueType, SchemaValueType } from '@/services/types'
import type { ModelRaw } from '@/types'
import type FilterSchemaService from '@/services/filterSchemaService'
import type GroupingRepository from '@/data/repositories/table/groupingRepository'
import Grouping from '@/data/models/tables/Grouping'
import { TmTableGroupingError } from '@/core/error/table/tmTableGroupingError'

import type LoggerService from '@/services/loggerService'
import type { Endpoint } from '@/services/endpoints'
import TmLogicError from '@/core/error/tmLogicError'
import { TmTableSortError } from '@/core/error/table/tmTableSortError'
import type BaseModel from '@/data/models/BaseModel'
import type EntityManagerService from '@/data/repositories/entityManagerService'

@injectable()
export default class BaseGrouperServiceFactory implements GrouperInterface {
  private _tableId: string | null = null

  private _endpoint: Endpoint | null = null

  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.FilterSchemaService) protected readonly filterSchemaService: FilterSchemaService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {}

  public getSchema() {
    return this.filterSchemaService.getFiltersSchema(this.endpoint)
  }

  public init(params?: PaginationUrlParametersGroupingType) {
    const data: ModelRaw<Grouping>[] = []
    const schema = this.getSchema()
    if (isEmpty(params)) {
      params = {}
    }

    this.getGroupingRepo().insert(this.initDataTransformer(data, schema))
    this.applyGroupingByUrlParams(params!)
    this.log(`Grouping initialized with following params: "${JSON.stringify(params)}"`)
  }

  public reset(excludeNames: string[] = []) {
    Object.values(this.getSchema().schema.groupBy).forEach((group: GroupingSchemaValueType) => {
      if (excludeNames.indexOf(group.name) === -1) {
        this.getGroupingRepo().update([{ id: this.getGroupingId(group.name), direction: '' }])
      }
    })
  }

  public applyGroupingByUrlParams(params: PaginationUrlParametersGroupingType) {
    if (isEmpty(params)) {
      return undefined
    }

    params = this.applyGroupingByUrlParamsTransformer(params)

    const [key, value] = Object.entries(params)[0]
    if (!isObject(value)) {
      return this.applyGrouping(key, value)
    }
    const [, paramValue] = Object.entries(value)[0]

    this.log(`Apply grouping by URL: ${key} ${paramValue}`)
    this.applyGrouping(key, paramValue)

    return undefined
  }

  public getAllGroupings(): Collection<Grouping> {
    return this.getGroupingRepo()
      .query()
      .where((grouping: Grouping) => grouping.tableModelId === this.getTableId())
      .get() as Collection<Grouping>
  }

  public getActiveGrouping() {
    return this.getAllGroupings().filter((grouping) => grouping.direction.length > 0)
  }

  public getCurrentGrouping(): Grouping {
    const activeGroupings = this.getActiveGrouping()
    if (activeGroupings.length > 1) {
      throw new TmTableSortError('we trust - we will use only group by one column')
    }
    return activeGroupings[0]
  }

  public applyGrouping(name: string, direction?: GroupingDirection) {
    const id = this.getGroupingId(name)
    this.reset([name])
    const grouping = this.getGroupingRepo().findEntityByIdOrNull(id)

    if (!grouping) {
      throw new TmTableGroupingError(`There is no available grouping for - ${name}`)
    }

    let groupingDirection: GroupingDirection = 'asc'
    if (direction) {
      groupingDirection = direction
    } else if (grouping.direction) {
      groupingDirection = this.getOppositeGroupingDirection(grouping.direction)
    }

    this.log(`Apply grouping: "${id} ${groupingDirection}"`)
    this.getGroupingRepo().update([{ id, direction: groupingDirection }])
  }

  public toQuery() {
    return this.toQueryByGroupings(this.getActiveGrouping())
  }

  public toQueryByGroupings(groupings: FilteredViewsParsedBodyGroupingType[]) {
    return groupings.reduce<PaginationUrlParametersGroupingType>((acc, grouping) => {
      if (grouping.relation) {
        return {
          ...acc,
          [grouping.relation]: {
            [grouping.name]: grouping.direction,
          },
        }
      }
      return {
        ...acc,
        [grouping.name]: grouping.direction,
      }
    }, {})
  }

  public setTableId(tableId: string) {
    this._tableId = tableId
  }

  public getTableId(): string {
    if (!this._tableId) {
      throw new TmLogicError('No tableId is set for service')
    }

    return this._tableId
  }

  public setEndpoint(endpoint: Endpoint) {
    this._endpoint = endpoint
  }

  public async loadGroupingFacets(
    model: typeof BaseModel,
    queryParametersBag: PaginationUrlType,
    searchQuery: string,
    searchFields: string[],
  ): Promise<void> {}

  protected get endpoint(): Endpoint {
    if (!this._endpoint) {
      throw new TmLogicError('No endpoint is set for service')
    }

    return this._endpoint
  }

  protected initDataTransformer(data: ModelRaw<Grouping>[], schema: SchemaValueType): ModelRaw<Grouping>[] {
    const newData: ModelRaw<Grouping>[] = [...data]

    Object.values(schema.schema.groupBy).forEach((group) => {
      newData.push({
        id: this.getGroupingId(group.name),
        direction: '',
        name: group.name,
        relation: group.type === 'relation' ? group.fields![0].name : '',
        tableModelId: this.getTableId(),
      })
    })

    return newData
  }

  protected applyGroupingByUrlParamsTransformer(
    params: PaginationUrlParametersGroupingType,
  ): PaginationUrlParametersGroupingType {
    return params
  }

  protected getGroupingId(name: string) {
    return [this.getTableId(), name].join('.')
  }

  protected getOppositeGroupingDirection(direction: ExplicitGroupingDirection): ExplicitGroupingDirection {
    return direction === 'asc' ? 'desc' : 'asc'
  }

  protected log(message: any, subchannel = 'internal') {
    if (this.loggerService.shouldLogByChannel('grouping', [subchannel])) {
      this.loggerService.log('grouping', typeof message === 'string' ? message : JSON.stringify(message), subchannel)
    }
  }

  protected getGroupingRepo() {
    return this.em.getRepository<GroupingRepository>(Grouping)
  }
}

import { injectable } from 'inversify'
import type { RouterHistory } from 'vue-router'
import { createWebHistory } from 'vue-router'
import type { Historyable } from '@/services/route/types'
import TmRouterError from '@/core/error/tmRouterError'

@injectable()
export default class HistoryService implements Historyable {
  protected routerHistory: RouterHistory

  public subscribePopState(callback: () => any) {
    window.addEventListener('popstate', callback, false)
  }

  public unsubscribePopState(callback: () => any) {
    window.removeEventListener('popstate', callback, false)
  }

  public subscribePushState(callback: () => any) {
    window.addEventListener('pushstate', callback, false)
  }

  public unsubscribePushState(callback: () => any) {
    window.removeEventListener('pushstate', callback, false)
  }

  public getLocationQuery() {
    return window.location.search
  }

  public createRouterHistory() {
    this.routerHistory = createWebHistory(import.meta.env.BASE_URL || '/')
  }

  public getRouterHistory() {
    if (!this.routerHistory) {
      throw new TmRouterError('Initialize router history firstly')
    }
    return this.routerHistory
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  PointAiAssistantRepository,
  PointAiAssistantService,
  PointAiWebsitesRepository,
  PointAiWebsitesService,
  PointAiWebsitesColumnService,
  PointAiWebsitesGridable,
  PointAiWebsitesFormService,
  PointAiDocumentsFormService,
  PointAiDocumentsService,
  PointAiDocumentsColumnService,
  PointAiDocumentsGridable,
  PointAiWebsocketService,
  PointAiChatResolver,
  PointAiWebsitesDownloadedService,
  PointAiWebsitesDownloadedRepository,
  PointAiWebsitesDownloadedColumnService,
  PointAiWebsitesDownloadedGridable,
}

export type PointAiServices = keyof typeof SERVICES

export const POINT_AI_SERVICES_TYPES: Record<PointAiServices, symbol> = makeServicesTypes<PointAiServices>(
  enumKeysToArray<PointAiServices>(SERVICES),
)

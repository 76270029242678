import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'

export const getPlanExpiredRoutes = (): RouteRecordRaw[] => [
  {
    name: 'planExpired.base',
    path: 'plan-expired',
    component: () => import('@/components/containers/PageLockContainer.vue'),
    meta: {
      tabTitle: 'pageTabTitle.planExpired',
      modules: [AppModule.Wizard, AppModule.TenDlcTollFreeRequest],
      resolvers: [
        {
          service: 'FilterSchemaResolver',
        },
      ],
    },
    children: [
      {
        name: 'planExpired',
        path: '',
        meta: {
          tabTitle: 'pageTabTitle.planExpired',
        },
        component: () => import('@/components/views/PlanExpired.vue'),
      },
    ],
  },
]

import { injectable } from 'inversify'
import { get as lodashGet } from 'lodash-es'
import { isDomainValidator } from '@/services/validation/defaultValidators/domain'
import { email } from '@/services/validation/defaultValidators'
import type { AddRuleFunc, ValidationRegistratorInterface } from '@/services/validation/validationRegistratorInterface'
import type FieldArray from '@/data/models/forms/FieldArray'
import type { FullNameValue } from '@/services/forms/types'
import { hasLetters } from '@/utils/string/hasLetters'
import requiredValidator from '@/services/validation/defaultValidators/required'
import maxLengthValidator from '@/services/validation/defaultValidators/max'

@injectable()
export default class CommonValidationRegistrator implements ValidationRegistratorInterface {
  public register(addRule: AddRuleFunc) {
    addRule('fullNameRequired', (value: FullNameValue) => {
      if (!value.firstName) {
        return { message: { path: 'commonValidationRegistrator.fullName.firstName' } }
      }

      if (!value.lastName) {
        return { message: { path: 'commonValidationRegistrator.fullName.lastName' } }
      }

      return true
    })

    addRule('confirmation', (value: string, [target]: string[]) =>
      value === target ? true : { message: { path: 'commonValidationRegistrator.confirmation' } },
    )

    addRule('equal', (value: string, [target]: string[]) =>
      value === target ? true : { message: { path: 'commonValidationRegistrator.equal' } },
    )

    addRule('noEqual', (value: string, [target]: string[]) =>
      value !== target ? true : { message: { path: 'commonValidationRegistrator.noEqual' } },
    )

    addRule('hasLetters', (value: string) =>
      hasLetters(value) ? true : { message: { path: 'commonValidationRegistrator.hasLetters', tc: 1 } },
    )

    addRule('hasNumbers', (value: string) =>
      /[0-9]/.test(value) ? true : { message: { path: 'commonValidationRegistrator.hasNumbers', tc: 1 } },
    )

    addRule('hasSpecialChars', (value: string) =>
      /[^0-9a-zA-Z]/.test(value) ? true : { message: { path: 'commonValidationRegistrator.hasSpecialChars', tc: 1 } },
    )

    addRule('hasLowercaseAndUppercase', (value: string) =>
      /[a-z]/.test(value) && /[A-Z]/.test(value)
        ? true
        : { message: { path: 'commonValidationRegistrator.hasLowercaseAndUppercase' } },
    )

    addRule('hasLowercase', (value: string) =>
      /[a-z]/.test(value) ? true : { message: { path: 'commonValidationRegistrator.hasLowercas' } },
    )

    addRule('hasUppercase', (value: string) =>
      /[A-Z]/.test(value) ? true : { message: { path: 'commonValidationRegistrator.hasUppercase' } },
    )

    addRule('hasAtLeastOne', (field: FieldArray) =>
      field.fieldsIndexes.length > 0 ? true : { message: { path: 'commonValidationRegistrator.hasAtLeastOne' } },
    )

    addRule('hasNoText', (value: string, [text]: string[]) =>
      !value.toLowerCase().includes(text.toLowerCase())
        ? true
        : { message: { path: 'commonValidationRegistrator.hasNoText' } },
    )

    addRule('minValue', (value: string, [min]: [number]) =>
      parseFloat(value) >= min
        ? true
        : { message: { path: 'commonValidationRegistrator.mustBeGreaterOrEqual', tc: min } },
    )

    addRule('domain', (value: string) =>
      this.isDomain(value) ? true : { message: { path: 'commonValidationRegistrator.domain' } },
    )

    addRule('domainOrEmail', (value: string) =>
      this.isDomain(value) || email(value) ? true : { message: { path: 'commonValidationRegistrator.domainOrEmail' } },
    )

    addRule('email', (value: string) =>
      email(value) ? true : { message: { path: 'commonValidationRegistrator.emailNotValid' } },
    )

    addRule('requiredNested', (value: Record<string, unknown>, [key]: [string]) => {
      const nestedValue = lodashGet(value, key)

      const isValid = requiredValidator(nestedValue)
      if (!isValid) {
        return { message: { path: 'commonValidationRegistrator.required' } }
      }

      return true
    })

    addRule('maxLenNested', (value: Record<string, unknown>, [key, length]: [string, `${number}`]) => {
      const nestedValue = lodashGet(value, key)

      const isValid = maxLengthValidator(nestedValue, { length })
      if (!isValid) {
        return { message: { path: 'commonValidationRegistrator.noMoreThanCharactersLong', params: { count: length } } }
      }

      return true
    })

    addRule('lessThan', (value: string, [max]: string[]) => {
      if (parseInt(value, 10) < parseInt(max, 10)) {
        return true
      }

      return { message: { path: 'commonValidationRegistrator.lessThan', params: { max } } }
    })
  }

  protected isDomain(value: unknown): boolean {
    if (typeof value !== 'string') return false

    return isDomainValidator(value)
  }
}

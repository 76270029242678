import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  SentSmsColumnServiceFactory,
  SentSmsPaginationServiceFactory,
  SentSmsCounterService,
  BulkSentSmsDetailsResolver,
  BulkSentSmsCounterResolver,
  SentSmsBulkDetailsModalResolverService,
  OutboundSmsResolverService,
  SentSmsFilterServiceFactory,
  SentSmsCounterRepository,
}

export type HistorySentSmsServices = keyof typeof SERVICES

export const HISTORY_SENT_SMS_SERVICE_TYPES: Record<HistorySentSmsServices, symbol> =
  makeServicesTypes<HistorySentSmsServices>(enumKeysToArray<HistorySentSmsServices>(SERVICES))

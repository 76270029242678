import type { RouteRecordRaw } from 'vue-router'
import type { TmNamedRouteRecord } from '@/services/route/types'
import { showSandbox } from '@/utils/system'
import { AppModule } from '@/config/types'

export const sandboxFormsRoute: TmNamedRouteRecord = {
  path: 'forms',
  name: 'sandbox.forms',
  component: () => import('@/components/views/sandbox/Forms.vue'),
}

export const sandboxFormLineRoute: TmNamedRouteRecord = {
  path: 'formLine',
  name: 'sandbox.formLine',
  component: () => import('@/components/views/sandbox/FormLine.vue'),
}

export const sandboxFieldsRoute: TmNamedRouteRecord = {
  path: 'fields',
  name: 'sandbox.fields',
  component: () => import('@/components/views/sandbox/Fields.vue'),
  redirect: { name: 'sandbox.fields.input' },
  children: [
    {
      path: 'input',
      name: 'sandbox.fields.input',
      component: () => import('@/components/views/sandbox/fields/FieldsInput.vue'),
    },
    {
      path: 'textarea',
      name: 'sandbox.fields.textarea',
      component: () => import('@/components/views/sandbox/fields/FieldsTextarea.vue'),
    },
    {
      path: 'select',
      name: 'sandbox.fields.select',
      component: () => import('@/components/views/sandbox/fields/FieldsSelect.vue'),
    },
    {
      path: 'checkbox',
      name: 'sandbox.fields.checkbox',
      component: () => import('@/components/views/sandbox/fields/FieldsCheckbox.vue'),
    },
    {
      path: 'datepicker',
      name: 'sandbox.fields.datepicker',
      component: () => import('@/components/views/sandbox/fields/FieldsDatepicker.vue'),
    },
    {
      path: 'timepicker',
      name: 'sandbox.fields.timepicker',
      component: () => import('@/components/views/sandbox/fields/FieldsTimepicker.vue'),
    },
    {
      path: 'fileUpload',
      name: 'sandbox.fields.fileUpload',
      component: () => import('@/components/views/sandbox/fields/FieldsFileUpload.vue'),
    },
    {
      path: 'richEditor',
      name: 'sandbox.fields.richEditor',
      component: () => import('@/components/views/sandbox/fields/FieldsRichEditor.vue'),
    },
    {
      path: 'email-editor',
      name: 'sandbox.fields.emailEditor',
      component: () => import('@/components/views/sandbox/fields/FieldsEmailEditor.vue'),
    },
  ],
}

export const sandboxControlsRoute: TmNamedRouteRecord = {
  path: 'controls',
  name: 'sandbox.controls',
  component: () => import('@/components/views/sandbox/Controls.vue'),
  redirect: { name: 'sandbox.controls.button' },
  meta: { tabTitle: 'pageTabTitle.sandbox.controls' },
  children: [
    {
      path: 'button',
      name: 'sandbox.controls.button',
      component: () => import('@/components/views/sandbox/controls/ControlsButton.vue'),
    },
  ],
}

export const sandboxWidgetsRoute: TmNamedRouteRecord = {
  path: 'widgets',
  name: 'sandbox.widgets',
  component: () => import('@/components/views/sandbox/Widgets.vue'),
}

export const sandboxWidgetControlsRoute: TmNamedRouteRecord = {
  path: 'widget-controls',
  name: 'sandbox.widget-controls',
  component: () => import('@/components/views/sandbox/WidgetControls.vue'),
}

export const sandboxIconsRoute: TmNamedRouteRecord = {
  path: 'icons',
  name: 'sandbox.icons',
  component: () => import('@/components/views/sandbox/Icons.vue'),
}

export const sandboxCustomIconsRoute: TmNamedRouteRecord = {
  path: 'custom-icons',
  name: 'sandbox.custom-icons',
  component: () => import('@/components/views/sandbox/CustomIcons.vue'),
}

export const sandboxFlagsRoute: TmNamedRouteRecord = {
  path: 'flags',
  name: 'sandbox.flags',
  component: () => import('@/components/views/sandbox/Flags.vue'),
}

export const sandboxButtonsRoute: TmNamedRouteRecord = {
  path: 'buttons',
  name: 'sandbox.buttons',
  component: () => import('@/components/views/sandbox/Buttons.vue'),
}

export const sandboxTagsRoute: TmNamedRouteRecord = {
  path: 'tags',
  name: 'sandbox.tags',
  component: () => import('@/components/views/sandbox/Tags.vue'),
}

export const sandboxTooltipsRoute: TmNamedRouteRecord = {
  path: 'tooltips',
  name: 'sandbox.tooltips',
  component: () => import('@/components/views/sandbox/Tooltips.vue'),
}

export const sandboxInfoCardRowsRoute: TmNamedRouteRecord = {
  path: 'infoCardRows',
  name: 'sandbox.infoCardRows',
  component: () => import('@/components/views/sandbox/InfoCardRows.vue'),
}

export const sandboxSidebarRoute: TmNamedRouteRecord = {
  path: 'sidebar',
  name: 'sandbox.sidebar',
  component: () => import('@/components/views/sandbox/Sidebar.vue'),
}

export const sandboxRoute: TmNamedRouteRecord = {
  path: 'sandbox',
  name: 'sandbox',
  component: () => import('@/components/views/sandbox/Sandbox.vue'),
  redirect: { name: sandboxFieldsRoute.name },
  meta: {
    modules: [AppModule.Sandbox, AppModule.TmTiptapEditor],
  },
  children: [
    sandboxFormsRoute,
    sandboxFormLineRoute,
    sandboxFieldsRoute,
    sandboxControlsRoute,
    sandboxWidgetsRoute,
    sandboxWidgetControlsRoute,
    sandboxIconsRoute,
    sandboxCustomIconsRoute,
    sandboxFlagsRoute,
    sandboxButtonsRoute,
    sandboxTagsRoute,
    sandboxTooltipsRoute,
    sandboxInfoCardRowsRoute,
    sandboxSidebarRoute,
  ],
}

export const getSandboxRoutes = (): RouteRecordRaw[] => (showSandbox() ? [sandboxRoute as RouteRecordRaw] : [])

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type ByocService from '@/services/domain/byoc/byocService'
import type ServerSubscriptionService from '@/services/transport/serverSubscriptionService'
import type { TServerEvent } from '@/services/transport/serverEvents'
import type PermissionsService from '@/services/permissionsService'

@injectable()
export default class ByocAccessResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.ByocService) protected readonly byocService: ByocService,
    @inject(SERVICE_TYPES.ServerSubscriptionService)
    private readonly serverSubscriptionService: ServerSubscriptionService,
    @inject(SERVICE_TYPES.PermissionsService) private readonly permissionsService: PermissionsService,
  ) {}

  public async resolve() {
    this.serverSubscriptionService.subscribe(
      'systemAccountPermissionsChanged',
      this.systemAccountPermissionsChangedEventHandler,
    )
    await this.byocService.getSettings()
  }

  public async unresolve() {
    this.serverSubscriptionService.unsubscribe(
      'systemAccountPermissionsChanged',
      this.systemAccountPermissionsChangedEventHandler,
    )
  }

  private systemAccountPermissionsChangedEventHandler = (e: TServerEvent<'systemAccountPermissionsChanged'>) => {
    const isActiveByocPermission = this.permissionsService.isActivePermission('byoc')
    if (
      isActiveByocPermission === e.payload.messagingPermissions.byoc.value ||
      !e.payload.messagingPermissions.byoc.value
    ) {
      return
    }
    this.byocService.refreshSettings()
  }
}

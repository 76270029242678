import type { TranslationKey } from '@/services/types'

export type SendReceiveSettingsPutBody = {
  includeOptions: string
  maxLength: number

  removeExtraBlank: boolean

  autoTransliterate: boolean

  sendUndelivered: boolean

  e2sEnabled: boolean

  customCutLines: string
}

export interface SendReceiveSettingSaveFormScheme {
  includeOptions: string
  maxLength: string

  removeExtraBlank: boolean

  autoTransliterate: boolean

  sendUndelivered: boolean

  e2sEnabled: boolean

  customCutLines: string
}

export const getSendReceiveSettingsMaxLengthOptions = (translate: (key: TranslationKey) => string) => {
  const options = ['1', '2', '3', '4', '5', '6'] as const
  return options.map((t) => ({
    value: t,
    text: translate(`sendReceiveSettings.maximumLengthOptions.${t}`),
  }))
}

<template>
  <page-content-sidebar-hr :padding-left="inSideBar ? undefined : '48px'" />

  <tm-section-wrapper
    wrapper-id="pinnedContactsSection"
    :class="['pinned-contacts-list', { 'mb-2': !inSideBar }]"
    with-transition
    delay-open-on-mounted
  >
    <template v-slot:preloader>
      <tm-skeleton
        v-for="width in randomWidths"
        :key="width"
        height="18px"
        dark
        :width="width + 'px'"
        class="ml-8 mb-2 mt-2"
      />
    </template>

    <div class="pinned-contacts-list__wrapper">
      <page-content-sidebar-list-title
        :title="$t('contacts.pinned.title')"
        :padding-left="inSideBar ? undefined : '48px'"
        show-add-button
        :dark="!inSideBar"
        @add-btn-click="handleAddPinnedItems"
      />

      <pinned-contacts-empty-state
        v-if="isEmpty"
        :class="{ 'ml-12': !inSideBar, 'pinned-contacts-list__empty-state': inSideBar }"
        :dark="!inSideBar"
        :padding="!inSideBar ? '16px 12px' : undefined"
      />

      <template v-else>
        <page-content-sidebar-draggable-list
          v-if="inSideBar"
          :items="pinnedContacts"
          @action-click="onActionClick"
          @sort="onSort"
          @lock-sidebar="$emit('lock-sidebar', $event)"
        />

        <main-sidebar-dropdown-menu
          v-else
          :items="pinnedContacts"
        />
      </template>
    </div>
  </tm-section-wrapper>
</template>

<script lang="ts">
import { defineComponent, computed } from '@/composition/vue/compositionApi'
import PageContentSidebarListTitle from '@/components/layout/pageContent/sidebar/sidebarItem/PageContentSidebarListTitle.vue'
import TmSectionWrapper from '@/components/TmSectionWrapper.vue'
import PinnedContactsEmptyState from '@/components/domain/contacts/pinned/PinnedContactsEmptyState.vue'
import { openAddNewPinnedItemModal } from '@/composition/domain/pinnedContacts/addNewPinnedItemModal'
import PageContentSidebarDraggableList from '@/components/layout/pageContent/sidebar/PageContentSidebarDraggableList.vue'
import { useSidebarPinnedContactItems } from '@/composition/domain/pinnedContacts/useSidebarPinnedContactItems'
import { useLayoutMenuInSidebar } from '@/composition/layoutMenu'
import MainSidebarDropdownMenu from '@/components/layout/mainSidebar/MainSidebarDropdownMenu.vue'
import PageContentSidebarHr from '@/components/layout/pageContent/sidebar/PageContentSidebarHr.vue'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'

export default defineComponent({
  components: {
    TmSkeleton,
    PageContentSidebarHr,
    MainSidebarDropdownMenu,
    PageContentSidebarDraggableList,
    PinnedContactsEmptyState,
    TmSectionWrapper,
    PageContentSidebarListTitle,
  },
  emits: {
    'lock-sidebar': (_lock: boolean) => true,
  },
  setup() {
    const { pinnedContacts, onActionClick, onSort } = useSidebarPinnedContactItems()
    const isEmpty = computed(() => pinnedContacts.value.length === 0)

    const handleAddPinnedItems = () => {
      openAddNewPinnedItemModal()
    }

    const inSideBar = useLayoutMenuInSidebar().inject()

    const randomWidths = [101, 115, 113, 84, 105]

    return {
      pinnedContacts,
      isEmpty,
      handleAddPinnedItems,
      onActionClick,
      onSort,
      inSideBar,
      randomWidths,
    }
  },
})
</script>

<style scoped lang="scss">
.pinned-contacts-list {
  height: auto;
  width: 100%;
  &__empty-state {
    width: calc(100% - 32px);
    min-width: 150px !important;
  }
}
</style>

import { injectable, inject } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { LocalStorageServiceInterface } from '@/services/storage/types'
import type TranslateService from '@/services/translateService'

const UNSAVED_CHANGES_PARAM_KEY = 'hasUnsavedChangesOnCurrentRoute' as const
const SKIP_LEAVE_CONFIRMATION_PARAM_KEY = 'skipRouteLeaveConfirmation' as const
const ROUTE_LEAVE_MESSAGE_PARAM_KEY = 'routeLeaveMessage' as const
const TRUE_VAL = '1' as const

@injectable()
export class RouteLeaveService {
  constructor(
    @inject(SERVICE_TYPES.SessionStorageService) protected readonly sessionStorageService: LocalStorageServiceInterface,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
  ) {}

  public setHasUnsavedChangesOnCurrentRoute(state: boolean) {
    if (state) {
      this.sessionStorageService.set(UNSAVED_CHANGES_PARAM_KEY, TRUE_VAL)
    } else {
      this.sessionStorageService.delete(UNSAVED_CHANGES_PARAM_KEY)
    }
  }

  public hasUnsavedChangesOnCurrentRoute(): boolean {
    return this.sessionStorageService.get(UNSAVED_CHANGES_PARAM_KEY) === TRUE_VAL
  }

  public setSkipRouteLeaveConfirmation(state: boolean) {
    if (state) {
      this.sessionStorageService.set(SKIP_LEAVE_CONFIRMATION_PARAM_KEY, TRUE_VAL)
    } else {
      this.sessionStorageService.delete(SKIP_LEAVE_CONFIRMATION_PARAM_KEY)
    }
  }

  public shouldSkipRouteLeaveConfirmation(): boolean {
    return this.sessionStorageService.get(SKIP_LEAVE_CONFIRMATION_PARAM_KEY) === TRUE_VAL
  }

  public setRouteLeaveMessage(message: string) {
    if (message) {
      this.sessionStorageService.set(ROUTE_LEAVE_MESSAGE_PARAM_KEY, message)
    } else {
      this.sessionStorageService.delete(ROUTE_LEAVE_MESSAGE_PARAM_KEY)
    }
  }

  public getRouteLeaveMessage(): string {
    return (
      this.sessionStorageService.get(ROUTE_LEAVE_MESSAGE_PARAM_KEY) ??
      this.translateService.t('common.leavePageUnsavedChanges')
    )
  }

  public cleanup() {
    this.setHasUnsavedChangesOnCurrentRoute(false)
    this.setSkipRouteLeaveConfirmation(false)
    this.setRouteLeaveMessage('')
  }
}

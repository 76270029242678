import { TmBaseError } from '@/core/error/tmBaseError'

export const makeOfflineRetryableLoader = <T extends () => Promise<any>>(loader: T): T => {
  const offlineRetryable = async () => {
    if (!navigator.onLine) {
      throw new TmBaseError('You are offline')
    }

    return loader()
  }

  return offlineRetryable as T
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type AccessControlService from '@/services/accessControl/accessControlService'
import type GlobalStateService from '@/services/GlobalStateService'

@injectable()
export class RootContainerResolver implements Resolvable<unknown> {
  constructor(
    @inject(SERVICE_TYPES.AccessControlService) protected readonly accessControlService: AccessControlService,
    @inject(SERVICE_TYPES.GlobalStateService) protected readonly globalStateService: GlobalStateService,
  ) {}

  public async resolve() {
    await this.accessControlService.install()
    const loadedState = this.globalStateService.getLoadedState()
    if (!loadedState) {
      await this.globalStateService.init()
    }
  }
}

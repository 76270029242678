import { TmApiClientError } from '@/core/error/transport/tmApiClientError'
import type { InternalErrorResponse } from '@/services/transport/types'
import type { TmTransportHttpErrorCode } from '@/core/error/transport/types'

export class TmApiTasksTaskNotFoundError extends TmApiClientError<InternalErrorResponse> {
  public name = 'TmApiTasksTaskNotFoundError'

  protected override code: TmTransportHttpErrorCode = 404

  public shouldBeMonitored(): boolean {
    return false
  }
}

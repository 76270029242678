import type { RouteRecordRaw } from 'vue-router'
import ScheduledMessage from '@/data/models/domain/scheduled/ScheduledMessage'
import ScheduledShortMessage from '@/data/models/domain/scheduled/ScheduledShortMessage'
import ScheduledNotSentMessage from '@/data/models/domain/scheduled/ScheduledNotSentMessage'
import { AppModule } from '@/config/types'
import ScheduledMenu from '@/components/domain/scheduled/ScheduledMenu.vue'
import type { ResolverConfig } from '@/services/types'
import { ScheduledRoutes } from '@/routes/user/scheduled/types'
import { scheduledUpcomingRoutes } from '@/routes/user/scheduled/upcoming'
import { scheduledPausedRoutes } from '@/routes/user/scheduled/paused'
import { scheduledNotSentRoutes } from '@/routes/user/scheduled/notSent'
import { scheduledCompletedRoutes } from '@/routes/user/scheduled/completed'
import { scheduledCalenderRoutes } from '@/routes/user/scheduled/calendar'
import { scheduledEditRoutes } from '@/routes/user/scheduled/edit'
import { scheduledDetailsRoutes } from '@/routes/user/scheduled/details'

export const scheduledFacetResolver: ResolverConfig = {
  service: 'FacetResolver',
  params: {
    model: ScheduledMessage,
    subscriptionModels: [ScheduledShortMessage, ScheduledMessage, ScheduledNotSentMessage],
  },
}

export const getScheduledRoutes = (): RouteRecordRaw[] => [
  {
    name: ScheduledRoutes.scheduled,
    path: 'scheduled',
    meta: {
      tags: ['USER_TOP_MENU'],
      permission: 'scheduled',
      menu: ScheduledMenu,
      modules: [AppModule.Scheduled],
    },
    redirect: { name: ScheduledRoutes.scheduledIndex },
    children: [
      {
        name: ScheduledRoutes.scheduledIndex,
        path: '',
        meta: {
          resolvers: [scheduledFacetResolver],
          accessControlGroups: ['NotCampaignsScheduledStrategy'],
        },
        redirect: { name: ScheduledRoutes.upcoming },
        component: () => import('@/components/views/scheduled/Scheduled.vue'),
        children: [
          scheduledUpcomingRoutes,
          scheduledPausedRoutes,
          scheduledNotSentRoutes,
          scheduledCompletedRoutes,
          scheduledCalenderRoutes,
          scheduledDetailsRoutes,
          scheduledEditRoutes,
        ],
      },
    ],
  },
]

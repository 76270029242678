import { inject, injectable } from 'inversify'
import type { DomainTitlerParams, TitlerInterface } from '@/services/route/titlers/types'
import type { Route } from '@/services/route/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type DefaultTitlerService from '@/services/route/titlers/defaultTitlerService'

@injectable()
export default class RouteTitlerService implements TitlerInterface<DomainTitlerParams> {
  constructor(
    @inject(SERVICE_TYPES.DefaultTitlerService) protected readonly defaultTitlerService: DefaultTitlerService,
  ) {}

  public getTitle(to: Route): string {
    return this.defaultTitlerService.getTitle(to, to.params as Record<string, string>)
  }
}

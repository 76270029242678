<template>
  <tm-skeleton-filters
    :left-controls="leftControls"
    :right-controls="rightControls"
  />
  <tm-table-skeleton v-bind="$props" />
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import { TmTableSkeletonProps } from '@/components/shared/tables/tableBase'
import TmSkeletonFilters from '@/components/shared/skeletons/TmSkeletonFilters.vue'
import TmTableSkeleton from '@/components/shared/tables/TmTableSkeleton.vue'

export default defineComponent({
  components: { TmTableSkeleton, TmSkeletonFilters },
  props: {
    ...TmTableSkeletonProps,
    leftControls: Array as PropType<string[]>,
    rightControls: Array as PropType<string[]>,
  },
})
</script>

import { injectable } from 'inversify'
import { isObject } from 'lodash-es'
import type { PaginationUrlFilterStrictType, PaginationUrlFilterType } from '@/services/tables/types'
import type { Dict } from '@/types'

@injectable()
export default class FilterQueryService {
  public decorateFilterToStrictQueryPart(queryParts: PaginationUrlFilterType): PaginationUrlFilterStrictType {
    let decoratedQueryParts: PaginationUrlFilterStrictType = {}
    queryParts.forEach((filter) => {
      const filterNames = Object.keys(filter)
      filterNames.forEach((filterName) => {
        const queryPartsValues = Object.values(filter[filterName]).shift()
        const filterValue = this.getFilterValue(queryPartsValues)
        decoratedQueryParts = { ...decoratedQueryParts, ...{ [filterName]: filterValue } }
      })
    })

    return decoratedQueryParts
  }

  // reverse of decorateFilterToStrictQueryPart
  public prepareFilterForRequest(filter: PaginationUrlFilterStrictType): PaginationUrlFilterType {
    const parsedFilter = Object.fromEntries(
      Object.entries(filter).map(([key, value]) => [key, { [Array.isArray(value) ? 'in' : 'eq']: value }]),
    )
    return [parsedFilter]
  }

  public formatDate(date: Date): string {
    // 'yyyy-MM-dd'
    const y = date.getFullYear()
    let m = (date.getMonth() + 1).toString()
    if (m.length < 2) {
      m = `0${m}`
    }
    let d = date.getDate().toString()
    if (d.length < 2) {
      d = `0${d}`
    }
    return `${y}-${m}-${d}`
  }

  public serializeFiltersToBracketNotation(
    queryParts: Dict | unknown[],
    prefix = '',
    result: PaginationUrlFilterStrictType = {},
  ): PaginationUrlFilterStrictType {
    for (const key in queryParts) {
      const value = queryParts[key]
      const newKey = prefix ? `${prefix}[${key}]` : key

      if (typeof value === 'object') {
        this.serializeFiltersToBracketNotation(value, newKey, result)
      } else if (value !== null && value !== undefined) {
        result[newKey] = String(value)
      }
    }

    return result
  }

  private getFilterValue(queryPartsValues: unknown): string {
    if (Array.isArray(queryPartsValues)) return queryPartsValues.join(',')
    if (isObject(queryPartsValues)) return Object.values(queryPartsValues).shift()
    return String(queryPartsValues)
  }
}

import { inject, injectable } from 'inversify'
import type LoggerService from '@/services/loggerService'
import { SERVICE_TYPES } from '@/core/container/types'
import type {
  InternalEmitterPayload,
  InternalSubscriberByModelsCallback,
  InternalSubscriberCallback,
  ModelEmitterPayload,
} from '@/services/transport/types'
import type BaseModel from '@/data/models/BaseModel'
import type SubscriptionService from '@/services/transport/subscriptionService'

@injectable()
export default class ModelSubscriptionService {
  constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly logger: LoggerService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
  ) {}

  public subscribeByModels(
    models: (typeof BaseModel)[],
    callback: InternalSubscriberByModelsCallback<ModelEmitterPayload>,
  ) {
    return models.map((model) =>
      this.subscribeByModel(model, (payload) => {
        callback(payload, model)
      }),
    )
  }

  public subscribeByModel(
    model: typeof BaseModel,
    callback: InternalSubscriberCallback<ModelEmitterPayload>,
    isPersistent = false,
  ) {
    return this.subscribe<ModelEmitterPayload>(
      model.entity,
      (payload) => {
        callback(payload)
      },
      isPersistent,
    )
  }

  public emitByModel(model: typeof BaseModel, payload: ModelEmitterPayload, debounce = 0) {
    this.emit<ModelEmitterPayload>(model.entity, payload, debounce)
  }

  public emit<T = InternalEmitterPayload>(eventName: string, payload: T, debounce = 0) {
    return this.subscriptionService.emit<T>(eventName, payload, debounce)
  }

  public subscribe<T = InternalEmitterPayload>(
    eventName: string,
    callback: InternalSubscriberCallback<T>,
    isPersistent = false,
  ) {
    return this.subscriptionService.subscribe<T>(eventName, callback, isPersistent)
  }

  public unsubscribe(keys: string[]) {
    keys.forEach((key) => this.subscriptionService.unsubscribe(key))
  }

  protected log(message: string) {
    if (this.logger.shouldLogByChannel('bus', ['internal'])) {
      this.logger.log('bus', message, 'internal')
    }
  }
}

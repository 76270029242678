import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import Facet from '@/data/models/facets/Facet'
import type { Facetable, FacetSettings, FacetSettingsPayload } from '@/services/facets/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import { TmRepositoryError } from '@/core/error/tmRepositoryError'
import type FacetRepository from '@/data/repositories/facets/facetRepository'
import { isRecordNumberOrNull } from '@/utils/typeGuards'
import type OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { NumbersObjectNullable } from '@/types'

@injectable()
export default class FaceterService<T extends NumbersObjectNullable = NumbersObjectNullable> implements Facetable<T> {
  constructor(@inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService) {}

  protected settings: FacetSettings

  public setSettings(settings: FacetSettingsPayload) {
    this.settings = {
      ...this.settings,
      ...settings,
    }
  }

  public patchSettings(settings: Partial<FacetSettings>) {
    const { entityName } = this.settings
    if (!entityName) throw Error('Settings must be initialized first')
    this.setSettings({ entityName, ...settings })
  }

  public getFacets(): Facet | null {
    const id = this.settings?.entityName
    const facet = this.getFacetRepository().findEntityByIdOrNull(id)

    if (!facet) {
      this.getFacetRepository().insert([
        {
          id,
          payload: {},
        },
      ])
    }

    return this.getFacetRepository().findEntityByIdOrNull(id)
  }

  public hasFacets(): boolean {
    return !!this.getFacets()
  }

  public getFacet(key: keyof T) {
    const facet = this.getFacets()
    if (!facet?.payload) return null

    const value = (facet.payload as T)[key]
    return value !== null && value >= 0 ? value : null
  }

  public async fetchFacets(types?: string[]) {
    const response = await this.getEntityRepository().facetsRequest(this.settings.endpointParams, types)

    this.mergeFacets(types ? Object.fromEntries(types.map((type) => [type, response[type]])) : response)
  }

  public storeFacets(facets: NumbersObjectNullable) {
    if (isRecordNumberOrNull(facets)) {
      this.getFacetRepository().insertOrUpdateRaw([
        ...this.getFacetRepository().getAllRaw(),
        {
          id: this.settings.entityName,
          payload: facets,
        },
      ])
    } else {
      throw new TmRepositoryError('Broken contract with backend')
    }
  }

  public clearFacets() {
    this.getFacetRepository().insertOrUpdateRaw([
      {
        id: this.settings.entityName,
        payload: undefined,
      },
    ])
  }

  public mergeFacets(facets: NumbersObjectNullable) {
    const currentFacet = this.getFacets() || { payload: {} }
    return this.storeFacets({ ...currentFacet.payload, ...facets })
  }

  protected getFacetRepository<R extends FacetRepository>(): R {
    return this.em.getRepository<R>(Facet)
  }

  protected getEntityRepository<R extends OrmApiRepository>(): R {
    return this.em.getRepositoryByModelNameNew<R>(this.settings.entityName)
  }
}

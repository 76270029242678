import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'
import ScheduledMessage from '@/data/models/domain/scheduled/ScheduledMessage'
import { campaignsScheduledUpcomingRoute } from '@/routes/user/campaigns/scheduled/campaignsScheduledUpcoming'
import { campaignsScheduledPausedRoute } from '@/routes/user/campaigns/scheduled/campaignsScheduledPaused'
import { campaignsScheduledNotSentRoute } from '@/routes/user/campaigns/scheduled/campaignsScheduledNotSent'
import { campaignsScheduledCompletedRoute } from '@/routes/user/campaigns/scheduled/campaignsScheduledCompleted'
import { messageIdRouteParam } from '@/components/views/scheduled/types'
import type { SingleModelResolverParams } from '@/services/resolvers/types'
import { ScheduledRoutes } from '@/routes/user/scheduled/types'

const campaignsScheduledRouteName = 'user.campaigns.scheduled'
export const campaignsScheduledDetailsRouteId = 'messageId'

export const campaignsScheduledDetailsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.details',
  path: `:${campaignsScheduledDetailsRouteId}`,
  props: true,
  component: () => import('@/components/views/campaigns/scheduled/CampaignsScheduledDetails.vue'),
  meta: {
    tabTitle: 'pageTabTitle.user.scheduled.details',
    detailsPage: {
      source: 'router',
      requestParam: campaignsScheduledDetailsRouteId,
      model: ScheduledMessage,
    },
    resolverComponent: () =>
      import('@/components/domain/campaigns/scheduled/details/CampaignsScheduledDetailsResolver.vue'),
    redirectToIfNotFound: { name: campaignsScheduledRouteName },
    titler: { service: 'RouteTitlerService' },
    resolvers: [{ service: 'ScheduledDetailsResolverService' }],
    modules: [AppModule.ScheduledDetails, AppModule.ContactList, AppModule.SentSmsBase, AppModule.RecentRecipient],
  },
}

export const campaignsScheduledEditRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.edit',
  path: `:${messageIdRouteParam}/edit`,
  props: true,
  component: () => import('@/components/views/campaigns/scheduled/CampaignsScheduledEdit.vue'),
  meta: {
    modules: [
      AppModule.RRule,
      AppModule.ScheduledEdit,
      AppModule.MessageSend,
      AppModule.Contacts,
      AppModule.ContactList,
      AppModule.ContactCounter,
      AppModule.SenderSettingsCountries,
      AppModule.RecentRecipient,
      AppModule.Compose,
    ],
    resolvers: [{ service: 'ScheduledEditResolverService' }],
    resolverComponent: () => import('@/components/resolvers/ScheduledEditResolver.vue'),
    tabTitle: 'pageTabTitle.user.scheduled.edit',
    detailsPage: {
      source: 'router',
      requestParam: messageIdRouteParam,
      model: ScheduledMessage,
    } as SingleModelResolverParams,
    titler: {
      service: 'RouteTitlerService',
    },
    redirectToIfNotFound: { name: ScheduledRoutes.upcoming },
    permission: 'composePage',
  },
}

export const campaignsScheduledRoute: TmNamedRouteRecord = {
  name: campaignsScheduledRouteName,
  path: 'scheduled',
  children: [
    campaignsScheduledUpcomingRoute,
    campaignsScheduledPausedRoute,
    campaignsScheduledNotSentRoute,
    campaignsScheduledCompletedRoute,
    campaignsScheduledEditRoute,
    campaignsScheduledDetailsRoute,
  ],
  redirect: { name: campaignsScheduledUpcomingRoute.name },
  meta: {
    modules: [AppModule.SmsCampaigns],
    permission: 'scheduled',
  },
}

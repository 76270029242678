import { OrmModel, StringField, NumberField, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import { DEFAULT_PER_PAGE } from '@/core/tables/types'

@OrmModel(modelNames.PAGINATION)
export default class Pagination extends BaseModel {
  @StringField()
  public id: string

  @NumberField(0)
  public pageCount: number

  @NumberField(0)
  public totalCount: number

  @NumberField(1)
  public page: number

  @StringField(`${1}-${DEFAULT_PER_PAGE}`)
  public pageKey: string

  @NumberField(DEFAULT_PER_PAGE)
  public perPage: number

  @StringField('')
  public nextId: string

  @StringField('')
  public prevId: string

  @StringField('')
  public lastId: string

  @NumberField(1)
  public currentPage: number

  @AttrField(null)
  public totalLimit: number | null

  public get firstRowIndex() {
    return this.perPage * (this.page - 1) + 1
  }
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import TenDlcPricing from '@/data/models/domain/services/regulations/TenDlcPricing'
import type { TransportResponse } from '@/services/transport/types'

@injectable()
@RepoSettings<Endpoint>({
  model: TenDlcPricing,
})
export default class TenDlcPricingRepository extends OrmApiRepository<TenDlcPricing> {
  public async fetchPrices(): Promise<TransportResponse<TenDlcPricing>> {
    const path = this.endpointsService.getPath('tenDlcGetPricing')
    return this.getApiSource().get<TenDlcPricing>(path)
  }
}

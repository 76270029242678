import { injectable } from 'inversify'
import { PublicRepo, RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiSettingsRepository from '@/data/repositories/ormApiSettingsRepository'
import { UiState } from '@/data/models/uiState'
import { TmRepositoryError } from '@/core/error/tmRepositoryError'
import type { ModelRaw } from '@/types'

@PublicRepo()
@RepoSettings<Endpoint>({
  model: UiState,
})
@injectable()
export class UiStateRepository extends OrmApiSettingsRepository<UiState> {
  public init() {
    this.insertOrUpdateRaw([{ id: this.getId() }])
  }

  public getUiStateParam<P extends keyof Omit<ModelRaw<UiState>, 'id'>>(param: P): UiState[P] {
    return this.getUiState()[param]
  }

  public setUiStateParam<P extends keyof Omit<ModelRaw<UiState>, 'id'>>(param: P, value: UiState[P]) {
    const val = this.getUiStateParam(param)
    if (val !== value) {
      this.updateSettings({ [param]: value })
    }
  }

  protected getUiState() {
    const state = this.findEntityByIdOrNull(this.getId())
    if (!state) {
      throw new TmRepositoryError('Trying to access UiState before initialization')
    }
    return state
  }
}

import { intersection, flatten, isEmpty, concat, isArray, cloneDeep } from 'lodash-es'
import { onBeforeRouteLeave } from 'vue-router'
import { detailedDiff } from 'deep-object-diff'
import type { IntersectionValue } from 'quasar'
import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
  useIntervalFn,
  type ComputedRef,
  type Ref,
  type SetupContext,
} from '@/composition/vue/compositionApi'
import {
  getBulkManager,
  getDomainManagerService,
  getEM,
  getExportService,
  getFacetManager,
  getHistoryService,
  getLoggerService,
  getModelSubscriptionService,
  getNewQueryParamsService,
  getNotificationService,
  getPreloaderManager,
  getFilterCompareService,
  getTableBaseRecoveryService,
  getTableBuilder,
  getTableManager,
  getTranslateService,
  getWrapperManagerService,
} from '@/core/container/ioc'
import {
  COLUMNS_URL_QUERY_PARAM,
  DEFAULT_PER_PAGE,
  FILTERS_URL_QUERY_PARAM,
  PAGING_URL_QUERY_PARAM,
  SEARCH_URL_QUERY_PARAM,
  tableCellLoaderWidth,
} from '@/core/tables/types'
import type { ColumnLoader, DefaultColumn, Column, CustomFiltersDefaultState } from '@/core/tables/types'
import { get } from '@/core/container/container'
import type { RegisteredServices } from '@/core/container/types'
import type BaseModel from '@/data/models/BaseModel'
import ModelMapper from '@/data/utils/modelMapper'
import type { RepositorySettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import type { PaginationInterface, PaginationSettings } from '@/services/tables/pagination/types'
import type { Gridable, Resolvers, TranslationKey } from '@/services/types'
import { useTableService } from '@/composition/container'
import type { BaseTableConstructor, BaseTableInterface } from '@/core/tables/baseTableInterface'
import { TmTableError } from '@/core/error/table/tmTableError'
import type { TableKeyBuilder } from '@/core/tables/tableKeyBuilder'
import { createFilters } from '@/composition/filters'
import type {
  CounterInterface,
  PaginationUrlColumnType,
  SorterSettings,
  TableFacetValues,
  TableFacetSettings,
  TableFiltersPanelWrapperParams,
} from '@/services/tables/types'
import { createSorters } from '@/composition/sorters'
import { createGroupers } from '@/composition/groupers'
import { createPaginators } from '@/composition/paginators'
import { createSearchers } from '@/composition/searchers'
import { createColumns } from '@/composition/columns'
import type { DraggableMoveEvent, DraggableRow, MoveRowPayload } from '@/components/shared/types'
import { TmComponentCompositionError } from '@/core/error/composition/tmComponentCompositionError'
import { TmTableFilterError } from '@/core/error/table/tmTableFilterError'
import type { QueryChangedPayload, TableExpandable } from '@/services/route/types'
import { queryToDotNotation } from '@/utils/router'
import { fromDotNotation } from '@/utils/object/fromDotNotation'
import type { WrapperParams, WrapperTypesServicesKeys } from '@/services/wrappers/types'
import { createWrapper } from '@/composition/modal'
import type { TmWrappers } from '@/wrappers'
import type { Facetable } from '@/services/facets/types'
import type { WrapperTableParamsToSave } from '@/services/wrappers/table/types'
import { useProvideInject } from '@/composition/provideInject'
import type BulkManager from '@/services/bulk/bulkManager'
import type { TableRecoveryServiceInterface } from '@/services/tables/recovery/tableRecoveryServiceInterface'
import { ResolvableTable } from '@/core/tables/tableDecorators'
import BaseTable from '@/core/tables/baseTable'

export type UseTableBuilderSettings = {
  tableModelId: RegisteredServices
  tableWrapperId?: string
  entity: typeof BaseModel
  supportedSearchFilters: string[]
  subscriptions?: string | string[]
  excludeRelatedModels?: boolean
  pagination?: PaginationInterface
  gridService?: Gridable<BaseModel>
  recoveryService?: TableRecoveryServiceInterface
  paginationSettings?: PaginationSettings
  defaultSorter?: SorterSettings
  schemaEndpoint?: Endpoint
  wrapperType?: WrapperTypesServicesKeys
  tableConstructor?: BaseTableConstructor
  filterServiceManager?: RegisteredServices
  filterFactory?: RegisteredServices
  tableSorterManager?: RegisteredServices
  sorterFactory?: RegisteredServices
  tableGrouperManager?: RegisteredServices
  grouperFactory?: RegisteredServices
  tablePaginatorManager?: RegisteredServices
  paginatorFactory?: RegisteredServices
  tableSearcherManager?: RegisteredServices
  searcherFactory?: RegisteredServices
  tableColumnManager?: RegisteredServices
  columnFactory?: RegisteredServices
  counter?: RegisteredServices
  sortEntityName?: string
  exportService?: RegisteredServices
  facetSettings?: TableFacetSettings
  customFiltersDefaultState?: CustomFiltersDefaultState
  tableWrapperParamsToSave?: WrapperTableParamsToSave
  canBeRestored?: boolean
  bulkManager?: BulkManager
  fastInsert?: boolean
  noSelectAll?: boolean
  selectedRows?: string
  allowSelectAllWithNonDefaultFilters?: boolean
  shouldResetOnCreateOrDelete?: boolean
  tableExpandService?: TableExpandable
}

export type UseResolvableTableBuilderSettings = UseTableBuilderSettings & {
  resolvers?: Resolvers

  /** Force Resolvers to be resolved before the table sends a request. Considered as false if not provided. */
  requireResolvers?: boolean
}

type WithoutHooksSettings = {
  withoutOnMounted?: boolean
  withoutOnBeforeRouteLeave?: boolean
  withoutOnUnmounted?: boolean
}
export type WithoutHooksAttr = boolean | WithoutHooksSettings

type CreateTableResult = {
  id: RegisteredServices
  table: BaseTableInterface
  initLoading: Promise<void>
  tableLoadPromise: Promise<void>
}

export const defaultTableWrapperParamsToSave: WrapperTableParamsToSave = {
  [PAGING_URL_QUERY_PARAM]: ['perPage'],
  [SEARCH_URL_QUERY_PARAM]: [],
  [FILTERS_URL_QUERY_PARAM]: [],
  [COLUMNS_URL_QUERY_PARAM]: [],
}

export const getTableSubscriptions = (
  tableEntityModel: typeof BaseModel,
  extraEvents: string[] = [],
  excludeRelatedModels = false,
) => {
  if (excludeRelatedModels) return [tableEntityModel.entity]
  const tableEvents = ModelMapper.gatherRelatedModels(tableEntityModel).map((m) => m.entity)
  const eventsIntersection = intersection(tableEvents, extraEvents)
  if (!isEmpty(eventsIntersection)) {
    throw new TmTableError(
      `Subscription events for table entity "${tableEntityModel.entity}" already contains event type ${JSON.stringify(
        eventsIntersection,
      )}`,
    )
  }
  return flatten(isEmpty(tableEvents) ? extraEvents : concat(tableEvents, extraEvents)).filter((v) => v)
}

export const useTableExpandAll = (tableId: RegisteredServices) => {
  const baseTable = get<BaseTableInterface>(tableId)

  const isExpanded = computed(() => baseTable.isExpanded())
  const expandAll = () => baseTable.expandAllRows(true)
  const collapseAll = () => baseTable.expandAllRows(false)

  return {
    isExpanded,
    expandAll,
    collapseAll,
  }
}

const toQueryDotNotationCauseDetailedDiffWorkOnlyWithOneLevel = (data: Record<string, unknown>) => {
  let resultQuery = {}
  for (const [key, value] of Object.entries(data)) {
    resultQuery = {
      ...resultQuery,
      ...queryToDotNotation(key, '', value),
    }
  }
  return resultQuery
}

export const getTableQueryChangePayload = (
  nextWrapperParams: WrapperParams,
  prevWrapperParams: WrapperParams,
): QueryChangedPayload => {
  return {
    prev: prevWrapperParams,
    next: nextWrapperParams,
    diff: detailedDiff(
      toQueryDotNotationCauseDetailedDiffWorkOnlyWithOneLevel(prevWrapperParams),
      toQueryDotNotationCauseDetailedDiffWorkOnlyWithOneLevel(nextWrapperParams),
    ),
  }
}

export const createTable = (
  settings: UseTableBuilderSettings,
  withoutHooks: WithoutHooksAttr = false,
): CreateTableResult => {
  const { promise: tableLoadPromise, resolve: resolveTableLoad } = Promise.withResolvers<void>()

  const em = getEM()
  const tableManager = getTableManager()
  try {
    const existingTable = tableManager.getTable(settings.tableModelId)
    existingTable.cleanUp()
  } catch {
    getLoggerService().log('table', 'Skipping clean up on create....')
  }
  const wrapperId = tableManager.getWrapperIdByTableId<TmWrappers>(
    (settings.tableWrapperId as TmWrappers) || settings.tableModelId,
  )
  const { wrapperParams, build, destroy } = createWrapper(wrapperId, settings.wrapperType || 'lsTable')
  build()
  const repoSettings = em.getRepository(settings.entity).settings() as RepositorySettings<Endpoint>

  if (!repoSettings.fetch && !settings.gridService) {
    throw new TmTableError(
      'To use table you should specify `fetch` params in repository.settings() or provide custom gridService',
    )
  }

  const endpoint = settings.schemaEndpoint ?? repoSettings.schemaEndpoint ?? repoSettings.fetch!

  const columnManager = createColumns(settings)
  const columns = columnManager.addServiceForTable(settings.tableModelId)

  const searcherManager = createSearchers({ ...settings, endpoint })
  searcherManager.addServiceForTable(settings.tableModelId, {
    supportedSearchFields: settings.supportedSearchFilters,
  })

  const filterServiceManager = createFilters({ ...settings, endpoint })

  const sorterManager = createSorters({ ...settings, endpoint })
  sorterManager.addServiceForTable(settings.tableModelId, {
    defaultColumns: columns.instance.getDefaultColumns(),
    defaultSorter: settings.defaultSorter,
    entityName: settings.sortEntityName || settings.entity.entity,
  })

  const grouperManager = createGroupers({ ...settings, endpoint })
  grouperManager.addServiceForTable(settings.tableModelId)

  const paginatorManager = createPaginators(settings)
  paginatorManager.addServiceForTable(
    settings.tableModelId,
    settings.paginationSettings ?? { perPage: DEFAULT_PER_PAGE },
  )

  settings.subscriptions = settings.subscriptions || []
  const table: BaseTableInterface = getTableBuilder().build({
    subscriptions: getTableSubscriptions(
      settings.entity,
      isArray(settings.subscriptions) ? settings.subscriptions : [settings.subscriptions],
      settings.excludeRelatedModels,
    ),
    bulkDeleteEndpoint: repoSettings.bulkDeleteEndpoint,
    tableModelId: settings.tableModelId,
    tableWrapperId: wrapperId,
    filterServiceManager,
    tableExpandService: settings.tableExpandService,
    sorterManager,
    grouperManager,
    paginatorManager,
    searcherManager,
    columnManager,
    em,
    domainManager: getDomainManagerService(),
    export: settings.exportService ? get(settings.exportService) : getExportService(),
    logger: getLoggerService(),
    bulk: settings.bulkManager ?? getBulkManager(),
    preloaderManager: getPreloaderManager(),
    filterCompareService: getFilterCompareService(),
    notification: getNotificationService(),
    history: getHistoryService(),
    subscription: getModelSubscriptionService(),
    entity: settings.entity,
    gridService: settings.gridService,
    tableWrapperService: getWrapperManagerService(),
    tableConstructor: settings.tableConstructor,
    keyBuilder: get<TableKeyBuilder>('TableKeyBuilder'),
    counter: settings.counter ? get<CounterInterface>(settings.counter) : settings.counter,
    facetSettings: settings.facetSettings,
    customFiltersDefaultState: settings.customFiltersDefaultState,
    tableWrapperParamsToSave: settings.tableWrapperParamsToSave || defaultTableWrapperParamsToSave,
    canBeRestored: settings.canBeRestored ?? true,
    recoveryService: settings.recoveryService || getTableBaseRecoveryService(),
    fastInsert: settings.fastInsert ?? false,
    noSelectAll: settings.noSelectAll ?? false,
    allowSelectAllWithNonDefaultFilters: settings.allowSelectAllWithNonDefaultFilters ?? false,
    shouldResetOnCreateOrDelete: settings.shouldResetOnCreateOrDelete ?? true,
  })

  const { id } = useTableService(settings.tableModelId, table)
  tableManager.addTable(table)

  if (withoutHooks === false || (typeof withoutHooks === 'object' && !withoutHooks.withoutOnMounted)) {
    onMounted(() => {
      if (!table.getFilters().length) {
        table.initFilter()
      }
    })
  }

  if (withoutHooks === false || (typeof withoutHooks === 'object' && !withoutHooks.withoutOnBeforeRouteLeave)) {
    onBeforeRouteLeave((to, from, next) => {
      table.unsubscribe()
      next()
    })
  }

  if (withoutHooks === false || (typeof withoutHooks === 'object' && !withoutHooks.withoutOnUnmounted)) {
    onUnmounted(async () => {
      getLoggerService().log('table', 'Unsubscribe table', table.getTableModelId())
      getNewQueryParamsService().unsubscribe(wrapperId)
      table.unsubscribe()
      destroy()
    })
  }

  let initLoading = Promise.resolve()
  try {
    initLoading = table.load()
    initLoading.then(() => {
      resolveTableLoad()
      if (settings.selectedRows) {
        table.selectRows(settings.selectedRows)
      }

      const wrapperManager = getWrapperManagerService()
      if (settings.wrapperType && settings.wrapperType === 'queryTable') {
        getNewQueryParamsService().subscribe(wrapperId, (route) => {
          getLoggerService().log('table', JSON.stringify(route.query), 'onChange')
          const newQuery = cloneDeep(route.query)
          const params: Record<string, unknown> = {}
          wrapperManager.getAllTableParamsKeys(wrapperId).forEach((key) => {
            const k = wrapperManager.getTableParamKey(key, wrapperId)
            if (newQuery[k]) {
              params[key] = fromDotNotation(JSON.parse(newQuery[k] as string))
            }
          })
          wrapperManager.setParams(wrapperId, params)
        })
      }
    })
  } catch (e) {
    getNotificationService().contactSupport(e as Error)
  }

  if (withoutHooks === false || (typeof withoutHooks === 'object' && !withoutHooks.withoutOnUnmounted)) {
    // The point of control a table. Any changes in wrapper start re-fetch of data for a table. Actually, no different
    // what type of wrapper uses for a table "table" or "queryTable". Each table for "queryTable" uses data from state firstly.
    watch(wrapperParams, (next, prev) => {
      table.on(getTableQueryChangePayload(next, prev))
    })
  }

  return { id, table, initLoading, tableLoadPromise }
}

export const createResolvableTable = (
  settings: UseResolvableTableBuilderSettings,
  withoutHooks: WithoutHooksAttr = false,
): CreateTableResult => {
  settings.tableConstructor = ResolvableTable(settings.tableConstructor || BaseTable, {
    resolvers: settings.resolvers ?? [],
    requireResolvers: settings.requireResolvers ?? false,
  })

  return createTable(settings, withoutHooks)
}

export const createTableAsync = async (
  settings: UseTableBuilderSettings,
  withoutHooks: WithoutHooksAttr = false,
): Promise<CreateTableResult> => {
  const result = createTable(settings, withoutHooks)
  await result.tableLoadPromise
  return result
}

export const selectableRow = (serviceId: RegisteredServices, entityId: string) => {
  const baseTable: BaseTableInterface = get(serviceId)

  const isRowSelected = computed<boolean>(() => baseTable.isRowSelected(entityId))

  const selectShiftRows = () => {
    const lastRowId = baseTable.getLastSelectedRow()
    if (!lastRowId || lastRowId === entityId) return

    const rowsIds = baseTable.getCurrentPageNonDisabledRowsIds()
    const lastRowIndex = rowsIds.indexOf(lastRowId)
    if (lastRowIndex === -1) return
    const entityIndex = rowsIds.indexOf(entityId)
    const firstIndex = Math.min(lastRowIndex, entityIndex)
    const lastIndex = Math.max(lastRowIndex, entityIndex)

    const rowsInRange = rowsIds.slice(firstIndex + 1, lastIndex)
    const ids = [...rowsInRange, lastRowId]
    baseTable.selectRowByIds(ids, !isRowSelected.value)
  }

  const input = (event?: PointerEvent) => {
    if (event?.shiftKey) {
      selectShiftRows()
    }

    if (isRowSelected.value) {
      baseTable.unselectRows(entityId)
    } else {
      baseTable.selectRows(entityId)
    }
    baseTable.setLastSelectedRow(entityId)
  }

  const inputAndUnselect = () => {
    const selectedRows = baseTable.getSelectedRowsIds()
    for (const id in selectedRows) {
      baseTable.unselectRows(id)
    }
    input()
  }

  return {
    input,
    inputAndUnselect,
    isRowSelected,
  }
}

export const singularSelectableRow = (serviceId: RegisteredServices, rowId: string) => {
  const tableManager = getTableManager()
  const baseTable = tableManager.getTable(serviceId)!

  const isRowSelected = computed(() => baseTable.isRowSelected(rowId))

  const selectedRowId = computed<string | undefined>(() => baseTable.getSelectedRowsIds()[0])

  const input = () => {
    if (selectedRowId.value === rowId) return
    if (selectedRowId.value) {
      baseTable.unselectRows(selectedRowId.value)
    }
    baseTable.selectRows(rowId)
  }

  return {
    input,
    isRowSelected,
    selectedRowId,
  }
}

export const calcColumnsCount = (initialCount: number, visibleCount: number, hasActions: boolean) => {
  if (visibleCount === 0) return 0
  return initialCount + visibleCount + +hasActions
}

export const getTableContentProps = <EO>(
  baseTable: BaseTableInterface,
  context: SetupContext<EO>,
  initColCount = 0,
) => {
  const hasActions = Object.keys(context.slots).includes('row-actions')
  const visibleColumns = computed(() => baseTable.getColumns().getVisibleColumns())
  const columnsCount = computed(() => calcColumnsCount(initColCount, visibleColumns.value.length, hasActions))

  return { hasActions, columnsCount, visibleColumns }
}

export const hasMore = (table: BaseTableInterface) =>
  computed(() => {
    const paginationData = table.getPagination().getData()
    return paginationData?.page < paginationData?.pageCount
  })

export const TM_CHECKBOX_LOADER_CLASS = 'tm-checkbox' as const
const defaultLoaderColumnConfig: Array<ColumnLoader> = [
  { loaderWidth: tableCellLoaderWidth.checkbox, loaderType: 'rect', loaderClass: TM_CHECKBOX_LOADER_CLASS },
  { loaderWidth: 100, dynamicLoaderWidth: true, loaderType: 'chip' },
  { loaderWidth: 150, dynamicLoaderWidth: true, loaderType: 'chip' },
  { loaderWidth: 100, dynamicLoaderWidth: true, loaderType: 'chip' },
  { loaderWidth: 100, dynamicLoaderWidth: true, loaderType: 'chip' },
  { loaderWidth: 16, loaderType: 'circle', loaderClass: 'td-actions' },
]
export const getDefaultLoaderColumnsConfig = (): Array<ColumnLoader> => defaultLoaderColumnConfig

export const useColumnEditor = <R extends string>(tableId: R) => {
  const isDirty = ref<boolean>(false)

  const query = ref('')

  const translateService = getTranslateService()

  const baseTable = getTableManager().getTable(tableId)
  const columnsService = baseTable.getColumns()
  const editableColumns = computed<DefaultColumn[]>(() => columnsService.getColumnEditorColumns())
  const columns = ref(editableColumns.value)

  watch(columns, () => {
    isDirty.value = true
  })

  const filteredColumns = computed(() =>
    columns.value.filter((col) =>
      col.isCustomFieldColumn
        ? col.label.toLocaleLowerCase().includes(query.value.toLocaleLowerCase())
        : translateService
            .t(col.label as TranslationKey)
            .toLocaleLowerCase()
            .includes(query.value.toLocaleLowerCase()),
    ),
  )

  const isAllShown = computed(() => {
    const optionalColumns = columns.value.filter((col) => !col.required)
    return optionalColumns.filter((col) => col.visible).length === optionalColumns.length
  })

  const isSortable = computed<boolean>(() => columnsService.isSortable())

  const toggleAll = () => {
    columns.value = columns.value.map((col) => {
      const visible = col.required || !isAllShown.value
      if (visible !== col.visible) {
        return { ...col, visible }
      }
      return col
    })
  }

  const toggleColumn = (col: DefaultColumn) => {
    const newVisible = col.required || !col.visible

    columns.value = columns.value.map((item) => {
      const isColToRepeat = col.visibilityBehaviour?.columnsToRepeat?.includes(item.columnName)
      const isColToOppose = col.visibilityBehaviour?.columnsToOppose?.includes(item.columnName)
      const isColToToggle = col.columnName === item.columnName || isColToRepeat

      if (isColToToggle && item.visible !== newVisible) {
        return { ...item, visible: newVisible }
      }

      if (isColToOppose && item.visible === newVisible) {
        return { ...item, visible: !newVisible }
      }

      return item
    })
  }
  const onDragEnd = (cols: DefaultColumn[]) => {
    columns.value = cols.map((c, idx) => {
      return {
        ...c,
        columnOrder: idx + 1,
      }
    })
  }

  const prepareColumnsForSave = (cols: DefaultColumn[]): Column[] =>
    cols.map((column: DefaultColumn, index) => ({
      columnName: column.columnName,
      visible: column.visible,
      columnOrder: index + 1,
    }))

  const saveColumns = async () => {
    if (!isDirty.value) {
      return
    }

    try {
      await baseTable.saveColumns(prepareColumnsForSave(columns.value))
    } finally {
      isDirty.value = false
    }
  }

  const columnsToPaginationUrlColumn = (): PaginationUrlColumnType =>
    columnsService.columnsToPaginationUrlColumn(columns.value)

  const syncColumns = () => {
    columns.value = editableColumns.value
    isDirty.value = false
  }

  const searchField = ref()

  const onDropdownChange = async (opened: boolean) => {
    if (!opened) {
      await saveColumns()
    }
  }

  const onShow = async () => {
    syncColumns()
  }

  const processOldFlowColumns = async () => {
    const defaultColumns = Object.values(columnsService.getDefaultColumns())
    if (editableColumns.value.length < defaultColumns.length && editableColumns.value.every((col) => col.visible)) {
      const columnsToSave = defaultColumns.map<DefaultColumn>((col) => {
        return {
          ...col,
          visible: editableColumns.value.find((c) => c.columnName === col.columnName)?.visible ?? false,
        }
      })
      await baseTable.saveColumns(prepareColumnsForSave(columnsToSave))

      columns.value = editableColumns.value
      isDirty.value = false
    }
  }

  onMounted(() => {
    processOldFlowColumns()
  })

  return {
    onDropdownChange,
    onShow,
    saveColumns,
    onDragEnd,
    toggleColumn,
    toggleAll,
    columnsToPaginationUrlColumn,
    editableColumns,
    columnsService,
    query,
    filteredColumns,
    isAllShown,
    searchField,
    columns,
    isSortable,
  }
}

export const useDraggableRows = (
  rows: Ref<string[]>,
  emit: SetupContext<{
    moveRow: (payload: MoveRowPayload) => void
  }>['emit'],
) => {
  const orderedRows = ref<string[]>([])

  watch(
    rows,
    () => {
      orderedRows.value = [...rows.value]
    },
    { immediate: true },
  )

  const draggableRows = computed<DraggableRow[]>(() =>
    orderedRows.value.reduce(
      (list, id, i) => [
        ...list,
        {
          id,
          index: i,
          type: 'group',
        },
        {
          id,
          index: i,
          type: 'default',
        },
      ],
      [] as DraggableRow[],
    ),
  )

  const getPrevDefaultRowIfExist = (oldIndex: number, newIndex: number, moveId: string) => {
    const goUpwards = oldIndex > newIndex
    const startIndex = goUpwards ? newIndex - 1 : newIndex
    for (let i = startIndex; i >= 0; i -= 1) {
      const row = draggableRows.value[i]
      if (!row) throw new TmComponentCompositionError('Draggable row not found')
      if (row.id === moveId) continue
      if (row.type === 'default') {
        return row
      }
    }

    return undefined
  }
  const moveRow = ({ moved }: DraggableMoveEvent<DraggableRow>) => {
    if (!moved) {
      return
    }

    const { element: movedItem, oldIndex, newIndex } = moved
    const afterItem = getPrevDefaultRowIfExist(oldIndex, newIndex, movedItem.id)

    orderedRows.value = orderedRows.value
      .filter((rowId) => rowId !== movedItem.id)
      .reduce(
        (list, rowId) => {
          list.push(rowId)
          if (rowId === afterItem?.id) list.push(movedItem.id)
          return list
        },
        afterItem?.id ? [] : [movedItem.id],
      )

    emit('moveRow', {
      movedItem,
      afterItem,
      sortedItems: [],
    })
  }

  return {
    draggableRows,
    moveRow,
  }
}

export const useTableFilterServiceManager = (tableId: string) => {
  const table = getTableManager().getTable(tableId)
  if (!table) {
    throw new TmTableFilterError(`No table "${tableId}" found`)
  }

  return table.getFilterServiceManager()
}

export const useTableFacetSettings = <F extends TableFacetValues>(
  facetKey: keyof F,
  model: typeof BaseModel,
): TableFacetSettings =>
  ({
    faceter: getFacetManager().getFaceter<Facetable<F>>(model),
    facetKey,
  }) as TableFacetSettings

export const getIsTableLoading = (tableId: string) => getTableManager().getTable(tableId).isLoading()

const TableServiceId = Symbol('tableServiceId')
export const useTableServiceId = () => useProvideInject<RegisteredServices>(TableServiceId)

export const SYM_TABLE_ROW_HOVERED = Symbol('TableRowHovered')

export const useTableIntersection = (rowsRef: ComputedRef<string[]>, scroll: Ref<HTMLDivElement> | undefined) => {
  const visibleRowsRef = ref<Set<string>>(new Set())
  const restRowsRef = ref<Set<string>>(new Set())

  const initialRowsCount = computed(() => (scroll?.value ? scroll.value.getBoundingClientRect().height / 44 : 20))

  const isRowVisible = (row: string) => visibleRowsRef.value.has(row)

  const getIntersectionOptions = (row: string): IntersectionValue => {
    return {
      handler: (entry) => {
        if (entry?.isIntersecting) {
          visibleRowsRef.value.add(row)
          restRowsRef.value.delete(row)
        }
        return true
      },
      cfg: {
        root: scroll?.value,
        rootMargin: '0px 0px 100px 0px',
      },
    }
  }

  const renderIdleInterval = useIntervalFn(() => {
    if (restRowsRef.value.size === 0) {
      renderIdleInterval.pause()
      return
    }

    requestIdleCallback(
      () => {
        const nextRow = restRowsRef.value.values().next().value

        if (nextRow) {
          visibleRowsRef.value.add(nextRow)
          restRowsRef.value.delete(nextRow)
        } else {
          renderIdleInterval.pause()
        }
      },
      { timeout: 100 },
    )
  }, 400)

  onMounted(() => {
    renderIdleInterval.resume()
  })

  onBeforeUnmount(() => {
    renderIdleInterval.pause()
  })

  watch(
    [initialRowsCount, rowsRef],
    ([count, rows]) => {
      rows.slice(0, count).forEach((row) => {
        visibleRowsRef.value.add(row)
      })
      restRowsRef.value = new Set(rows.slice(count))
    },
    { immediate: true },
  )

  watch(
    [visibleRowsRef, rowsRef],
    ([visibleRows, rows]) => {
      if (visibleRows.size !== rows.length) {
        renderIdleInterval.resume()
      }
    },
    { immediate: true },
  )

  return {
    isRowVisible,
    getIntersectionOptions,
  }
}

export const useTableStats = (tableId: string) => {
  const tableManager = getTableManager()
  const table = tableManager.getTable(tableId)

  const selectedCount = computed(() => table.selectedCount())
  const selectedRowsIds = computed(() => table.getSelectedRowsIds())
  const totalCount = computed(() => table.getTotalCount())

  return {
    table,
    selectedCount,
    selectedRowsIds,
    totalCount,
  }
}

export const useTableFilters = (serviceId: string) => {
  const fsManager = useTableFilterServiceManager(serviceId)

  const appliedFilters = computed(() => fsManager.getAppliedFiltersForTable(serviceId))
  const appliedFiltersCount = computed(() => appliedFilters.value.length)
  const hasFilterFactory = computed(() => fsManager.hasFactoryForTable(serviceId))

  return {
    appliedFilters,
    appliedFiltersCount,
    hasFilterFactory,
  }
}

export const useTableFilterPanel = (tableId: string) => {
  const { build, getParams, setParams } = createWrapper<TableFiltersPanelWrapperParams>(
    `${tableId}FilterPanelWrapper`,
    'wrapper',
  )
  build()

  const visibilityState = computed<boolean>(() => {
    return getParams().isShown ?? false
  })

  const setVisibilityState = (isShown: boolean) => {
    setParams({ isShown })
  }

  const toggle = () => {
    setVisibilityState(!visibilityState.value)
  }

  return {
    visibilityState,
    setVisibilityState,
    toggle,
  }
}

import { inject, injectable } from 'inversify'
import type OAuthSignUpRepository from '@/data/repositories/oAuthSignUpRepository'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Optional } from '@/types'
import OAuthSignUp from '@/data/models/OAuthSignUp'
import type { OAuthProvider } from '@/services/domain/user/types'
import type TranslateService from '@/services/translateService'
import type EntityManagerService from '@/data/repositories/entityManagerService'

@injectable()
export default class OAuthSignUpService {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
  ) {}

  public fetchOAuthSignUpData(): Promise<Optional<OAuthSignUp>> {
    return this.getOAuthSignUpRepo().fetchOAuthSignUpData()
  }

  public getOAuthSignUpData(): Optional<OAuthSignUp> {
    return this.getOAuthSignUpRepo().getOAuthSignUpData()
  }

  public storeOAuthSignUpData(
    signUpData: Pick<OAuthSignUp, 'token' | 'username' | 'firstName' | 'lastName' | 'email'>,
  ) {
    return this.getOAuthSignUpRepo().storeOAuthSignUpData(signUpData)
  }

  public async clearOAuthSignUpData() {
    return this.getOAuthSignUpRepo().clearOAuthSignUpData()
  }

  public getExpectationFailedErrorMessage(provider: OAuthProvider): string {
    const providerName = this.translateService.t(`auth.oauth.providers.${provider}`)
    return this.translateService.t('auth.oauth.errors.expectationFailed', { provider: providerName })
  }

  protected getOAuthSignUpRepo() {
    return this.em.getRepository<OAuthSignUpRepository>(OAuthSignUp)
  }
}

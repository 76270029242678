import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  InvoicesColumnServiceFactory,
  TeamSubscriptionInvoiceService,
  TeamSubscriptionInvoiceRepository,
  TeamSubscriptionInvoicesGridable,
  InvoicesTeamSubscriptionColumnService,
}

export type InvoicesServices = keyof typeof SERVICES

export const INVOICES_SERVICE_TYPES: Record<InvoicesServices, symbol> = makeServicesTypes<InvoicesServices>(
  enumKeysToArray<InvoicesServices>(SERVICES),
)

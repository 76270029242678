import { OrmModel, StringField, NumberField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import { AccountSsoSettingsForceSsoLogin } from '@/services/domain/accounts/types'

@OrmModel(modelNames.ACCOUNT_SSO_SETTINGS)
export default class AccountSsoSettings extends BaseModel {
  @NumberField(AccountSsoSettingsForceSsoLogin.sso, (value) =>
    value in AccountSsoSettingsForceSsoLogin ? value : AccountSsoSettingsForceSsoLogin.sso,
  )
  public forceSsoLogin: AccountSsoSettingsForceSsoLogin

  @StringField()
  public entityId: string

  @StringField()
  public ssoService: string

  @StringField(null)
  public sloService: string | null

  @StringField()
  public x509Cert: string
}

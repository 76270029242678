type RangeValue = number | Date

export const getRangeOverlappingIndexes = <T extends RangeValue>(ranges: [T, T][]): number[] => {
  const overlappingIndexes = new Set<number>()

  for (let i = 0; i < ranges.length; i++) {
    for (let j = i + 1; j < ranges.length; j++) {
      const [a1, a2] = ranges[i]
      const [b1, b2] = ranges[j]

      if (a1 < b2 && b1 < a2) {
        overlappingIndexes.add(i)
        overlappingIndexes.add(j)
      }
    }
  }

  return Array.from(overlappingIndexes).sort((a, b) => a - b)
}

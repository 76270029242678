<template>
  <q-spinner
    :size="size"
    :color="color"
  />
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'

export default defineComponent({
  name: 'TmSpinner',
  props: {
    size: {
      type: String,
      default: '20px',
    },
    color: {
      type: String,
      default: 'white',
    },
  },
})
</script>

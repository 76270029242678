import { BooleanField, OrmModel, StringField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'
import type { UserRole } from '@/services/domain/user/types'

@OrmModel(modelNames.SUBACCOUNTS_IMPORT_DETAILS)
export default class SubAccountsImportDetails extends BaseModel {
  @StringField()
  public phone: string

  @StringField()
  public firstName: string

  @StringField()
  public lastName: string

  @StringField()
  public role: UserRole

  @StringField()
  public email: string

  @BooleanField()
  public isEmailValid: boolean
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import Session from '@/data/models/domain/session/Session'
import type { SessionArchiveQueryParams } from '@/services/domain/session/types'

@RepoSettings<Endpoint>({
  model: Session,
  single: 'sessionSingle',
})
@injectable()
export default class SessionRepository extends OrmApiRepository<Session> {
  public async updateTitle(id: string, title: string) {
    const path = this.getPath('sessionsIndexUpdateTitle', id)
    await this.getApiSource().put(path, { title })
  }

  public async archive(id: string) {
    const apiSource = this.getApiSource()
    const path = this.getPath('sessionArchive', id)
    await apiSource.post(path)
  }

  public async unarchive(id: string) {
    const apiSource = this.getApiSource()
    const path = this.getPath('sessionUnarchive', id)
    await apiSource.post(path)
  }

  public async bulkArchive(params: SessionArchiveQueryParams) {
    const apiSource = this.getApiSource()
    const path = this.endpointsService.getPath('sessionBulkArchive')
    await apiSource.post(path, params)
  }

  public async bulkUnarchive(params: SessionArchiveQueryParams) {
    const apiSource = this.getApiSource()
    const path = this.endpointsService.getPath('sessionBulkUnarchive')
    await apiSource.post(path, params)
  }
}

import { BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.TEMPLATE_CATEGORY)
export default class TemplateCategory extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: string

  @NumberField()
  public templatesCount: number

  @NumberField()
  public order: number

  @BooleanField()
  public isDefault: boolean
}

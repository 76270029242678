import { inject, injectable } from 'inversify'
import { SERVICE_TYPES, type RegisteredServices } from '@/core/container/types'
import type BaseModel from '@/data/models/BaseModel'
import {
  type Preloadable,
  type DomainAliasesType,
  type TablePreloadable,
  type AutocompletePreloadable,
  type PreloaderSettingsType,
  type PreloaderSettingsWithAliasType,
  type PreloaderType,
  PRELOADER_TYPES,
} from '@/services/preloaders/types'
import type ModelPreloaderService from '@/services/preloaders/modelPreloaderService'
import type LoggerService from '@/services/loggerService'
import type TablePreloaderService from '@/services/preloaders/tablePreloaderService'
import type AutocompletePreloaderService from '@/services/preloaders/autocompletePreloaderService'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type { IServiceManager } from '@/core/middlewares/types'
import { REFLECT_METADATA } from '@/decorators/types'
import { ServiceGroups, type Service } from '@/config/types'
import type { Dict } from '@/types'
import type { GetLocator } from '@/core/container/container'
import { getLoggerService } from '@/core/container/ioc'
import { TmPreloadError } from '@/core/error/tmPreloadError'

@injectable()
export default class PreloaderManager implements IServiceManager {
  private preloaders: Record<PreloaderType, Dict<RegisteredServices>>

  private cache: Record<PreloaderType, Dict<Preloadable | TablePreloadable | AutocompletePreloadable>>

  constructor(
    @inject(SERVICE_TYPES.ModelPreloaderService) protected readonly basePreloaderService: ModelPreloaderService,
    @inject(SERVICE_TYPES.TablePreloaderService) protected readonly tablePreloaderService: TablePreloaderService,
    @inject(SERVICE_TYPES.AutocompletePreloaderService)
    protected readonly autocompletePreloaderService: AutocompletePreloaderService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject('GetLocator') private readonly get: GetLocator,
  ) {
    this.preloaders = {} as Record<PreloaderType, Dict<RegisteredServices>>
    this.cache = {} as Record<PreloaderType, Dict<Preloadable | TablePreloadable | AutocompletePreloadable>>
    for (const type of PRELOADER_TYPES) {
      this.preloaders[type] = {}
      this.cache[type] = {}
    }
    this.cache[ServiceGroups.PRELOADERS]['*'] = basePreloaderService
    this.cache[ServiceGroups.PRELOADERS_TABLE]['*'] = tablePreloaderService
    this.cache[ServiceGroups.PRELOADERS_AUTOCOMPLETE]['*'] = autocompletePreloaderService
  }

  public addService(service: Service, preloaderType: ServiceGroups = ServiceGroups.PRELOADERS) {
    const settings = Reflect.getMetadata(REFLECT_METADATA.PreloaderSettings, service.bindingValue)
    if (!settings) {
      getLoggerService().raw('error', service)
      throw new TmPreloadError('Please use @PreloaderSettings decorator')
    }
    this.addPreloaderByModel(settings.models, service, preloaderType)
    if (this.isAliasGuard(settings)) {
      this.addPreloaderByAlias(settings.alias, service, preloaderType)
    }
  }

  public getPreloader<P extends Preloadable>(
    model: typeof BaseModel,
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ): P {
    return this.getPreloaderByName(model.entity, preloaderType) as P
  }

  public getPreloaderByAlias<P extends Preloadable>(
    alias: DomainAliasesType,
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ): P {
    return this.getPreloaderByName(alias, preloaderType) as P
  }

  public getTablePreloader<P extends TablePreloadable>(model: typeof BaseModel): P {
    return this.getPreloaderByName(model.entity, ServiceGroups.PRELOADERS_TABLE) as P
  }

  public getTablePreloaderByAlias<P extends TablePreloadable>(alias: DomainAliasesType): P {
    return this.getPreloaderByName(alias, ServiceGroups.PRELOADERS_TABLE) as P
  }

  public getAutocompletePreloader<P extends AutocompletePreloadable<InstanceType<T>>, T extends typeof BaseModel>(
    model: T,
  ): P {
    return this.getPreloaderByName(model.entity, ServiceGroups.PRELOADERS_AUTOCOMPLETE) as P
  }

  public getAutocompletePreloaderByAlias<P extends AutocompletePreloadable>(alias: DomainAliasesType) {
    return this.getPreloaderByName(alias, ServiceGroups.PRELOADERS_AUTOCOMPLETE) as P
  }

  public getBasePreloader() {
    return this.basePreloaderService
  }

  public isPreloadableEntity(m: typeof BaseModel) {
    return this.em.getRepository(m) instanceof OrmApiRepository
  }

  public getPreloaderByName(
    name: string,
    preloaderType: PreloaderType = ServiceGroups.PRELOADERS,
  ): Preloadable | TablePreloadable | AutocompletePreloadable {
    if (this.cache[preloaderType][name]) {
      return this.cache[preloaderType][name]
    }
    if (!this.preloaders[preloaderType][name]) {
      this.loggerService.log('preloader', `For ${name} use default preloader`, 'manager')
      return this.cache[preloaderType]['*']
    }
    this.loggerService.log('preloader', `For ${name} using preloader`, 'manager')
    this.loggerService.raw('preloader', this.preloaders[name])
    const result = this.get(this.preloaders[preloaderType][name]) as
      | Preloadable
      | TablePreloadable
      | AutocompletePreloadable
    this.cache[preloaderType][name] = result
    return result
  }

  private addPreloaderByModel(
    models: (typeof BaseModel)[],
    service: Service,
    preloaderType: ServiceGroups = ServiceGroups.PRELOADERS,
  ) {
    models.forEach((model: typeof BaseModel) => {
      this.loggerService.log('preloader', `Add preloader for ${model.entity}`)
      this.loggerService.raw('preloader', service)
      this.preloaders[preloaderType][model.entity] = service.id as RegisteredServices
    })
  }

  private addPreloaderByAlias(
    alias: DomainAliasesType,
    service: Service,
    preloaderType: ServiceGroups = ServiceGroups.PRELOADERS,
  ) {
    this.loggerService.log('preloader', `Add preloader for alias ${alias}`)
    this.loggerService.raw('preloader', service)
    this.preloaders[preloaderType][alias] = service.id as RegisteredServices
  }

  private isAliasGuard(settings: PreloaderSettingsType): settings is PreloaderSettingsWithAliasType {
    return typeof settings.alias === 'string' && settings.alias.length > 0
  }
}

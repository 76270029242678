import { NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import SentSms from '@/data/models/domain/SentSms'

@OrmModel(modelNames.SENT_SMS_SHORT)
export default class SentSmsShort extends SentSms {
  @StringField()
  public fromNumber: string

  @NumberField()
  public deliveredCount: number
}

<script lang="ts">
import { computed, defineComponent, h } from '@/composition/vue/compositionApi'
import type { Component, PropType } from '@/composition/vue/compositionApi'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import MainSidebarDropdownMenu from '@/components/layout/mainSidebar/MainSidebarDropdownMenu.vue'
import type { PageContentSidebarItem } from '@/components/layout/pageContent/sidebar/types'
import { prepareLayoutMenuItems, useLayoutMenuInSidebar } from '@/composition/layoutMenu'

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<PageContentSidebarItem[]>,
    },
    customPageContentSidebarList: {
      type: Object as PropType<Component>,
    },
    highlightExactActive: {
      type: Boolean,
    },
  },
  setup(props) {
    const inSideBar = useLayoutMenuInSidebar().inject()

    const preparedItems = computed(() => {
      if (!props.items) {
        return []
      }
      return prepareLayoutMenuItems(props.items)
    })

    return () =>
      h(inSideBar ? props.customPageContentSidebarList || PageContentSidebarList : MainSidebarDropdownMenu, {
        items: preparedItems.value,
        highlightExactActive: props.highlightExactActive,
      })
  },
})
</script>

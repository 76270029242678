import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'

export const messengerSettingsEmailsFlowRoute: TmNamedRouteRecord = {
  name: 'user.messenger.settings.emails.flow',
  path: 'flow',
  component: () => import('@/components/views/messenger/settings/MessengerSettingsEmailFlow.vue'),
}

export const messengerSettingsEmailsTableRoute: TmNamedRouteRecord = {
  name: 'user.messenger.settings.emails.table',
  path: '',
  component: () => import('@/components/views/messenger/settings/MessengerSettingsEmailTable.vue'),
}

export const messengerSettingsEmailsRoute: TmNamedRouteRecord = {
  name: 'user.messenger.settings.emails',
  path: 'emails',
  children: [messengerSettingsEmailsTableRoute, messengerSettingsEmailsFlowRoute],
  redirect: { name: messengerSettingsEmailsTableRoute.name },
  meta: {
    tabTitle: 'pageTabTitle.user.chats.settings.emails',
    modules: [AppModule.CampaignsSenders],
    accessControlGroups: ['CampaignsEmailStrategy'],
  },
}

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'
import Axios from 'axios'
import { inject, injectable } from 'inversify'
import type { BlobResponse, Transport } from '@/services/transport/types'
import { httpBuildQuery } from '@/utils/url'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Config } from '@/core/types'

@injectable()
export class HttpService implements Transport {
  private source: AxiosInstance = Axios.create()

  private cache: Map<string, AxiosPromise> = new Map()

  constructor(@inject(SERVICE_TYPES.Config) private readonly _config: Config) {}

  public getAxios(): AxiosInstance {
    return this.source
  }

  public getCancelToken(): CancelTokenSource {
    return Axios.CancelToken.source()
  }

  public create(config?: AxiosRequestConfig) {
    this.source = Axios.create(this.config(config))
  }

  public request<T>(config: AxiosRequestConfig): AxiosPromise<T> {
    return this.source.request(this.config(config))
  }

  public get<T, P extends AxiosRequestConfig = AxiosRequestConfig>(url: string, config?: P): AxiosPromise<T> {
    const cacheKey = this.getUri(url, config)

    if (!this.cache.has(cacheKey)) {
      this.cache.set(
        cacheKey,
        this.source.get<T>(url, this.config(config)).finally(() => {
          this.cache.delete(cacheKey)
        }),
      )
    }

    return this.cache.get(cacheKey) as AxiosPromise<T>
  }

  public getFile<P extends AxiosRequestConfig = AxiosRequestConfig>(url: string, config?: P) {
    return this.get(url, { ...config, responseType: 'blob' }) as Promise<BlobResponse>
  }

  public delete<T>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.source.delete(url, this.config(config))
  }

  public head<T>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.source.head(url, this.config(config))
  }

  public post<T, B = Record<string, any>>(url: string, data?: B, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.source.post(url, data, this.config(config))
  }

  public put<T, B>(url: string, data?: B, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.source.put(url, data, this.config(config))
  }

  public patch<T, B>(url: string, data?: B, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.source.patch(url, data, this.config(config))
  }

  public success<T>(response: AxiosResponse<T>): T {
    return response.data
  }

  public constructSearchQueryObject(endpoint: string, fields: Array<string>, value: string): Promise<AxiosResponse> {
    const filters = {
      searchQuery: value,
      searchFields: fields,
    }
    return this.get(endpoint, {
      params: filters,
      paramsSerializer: httpBuildQuery,
    })
  }

  private getUri<C extends AxiosRequestConfig = AxiosRequestConfig>(url: string, config?: C): string {
    return Axios.getUri({ url, ...config })
  }

  protected config(config?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...(config || {}),
      baseURL: this._config.apiUrl,
      withCredentials: true,
    }
  }
}

import { inject, injectable } from 'inversify'
import type BaseModel from '@/data/models/BaseModel'
import { SERVICE_TYPES, type RegisteredServices } from '@/core/container/types'
import type { DomainBulkServiceInterface, ILogger } from '@/services/types'
import type { BulkBaseBody } from '@/services/bulk/types'
import type BulkService from '@/services/bulk/bulkService'
import type { IServiceManager } from '@/core/middlewares/types'
import type { Service } from '@/config/types'
import { REFLECT_METADATA } from '@/decorators/types'
import type { GetLocator } from '@/core/container/container'
import { getLoggerService } from '@/core/container/ioc'
import TmLogicError from '@/core/error/tmLogicError'

@injectable()
export default class BulkManager implements IServiceManager {
  private bulks: Record<string, RegisteredServices | BulkService> = {}

  @inject('GetLocator') protected readonly get: GetLocator

  constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: ILogger,
    @inject(SERVICE_TYPES.BulkService) protected readonly bulkService: BulkService,
  ) {
    this.bulks['*'] = bulkService
  }

  public addService(service: Service): void {
    const settings = Reflect.getMetadata(REFLECT_METADATA.BulkSettings, service.bindingValue)
    if (!settings) {
      getLoggerService().raw('error', service)
      throw new TmLogicError('Please use @BulkSettings decorator')
    }
    this.bulks[settings.model.entity] = service.id as RegisteredServices
  }

  public getService<B extends BulkBaseBody, T extends DomainBulkServiceInterface<B> = DomainBulkServiceInterface<B>>(
    model: typeof BaseModel,
  ): T {
    if (!this.bulks[model.entity]) {
      this.loggerService.log('bulk', `Use default bulk for ${model.entity}`, 'get')
      return this.bulks['*'] as unknown as T
    }

    this.loggerService.log('bulk', `Use bulk for ${model.entity}`, 'get')
    this.loggerService.raw('bulk', this.bulks[model.entity])

    return this.get(this.bulks[model.entity] as RegisteredServices) as T
  }
}

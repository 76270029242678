<template>
  <tm-skeleton-filters
    v-if="showHeader"
    :left-controls="leftControls"
    :right-controls="rightControls"
  />
  <tm-table-container>
    <tm-table-abstract-skeleton v-bind="$props" />
  </tm-table-container>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import TmSkeletonFilters from '@/components/shared/skeletons/TmSkeletonFilters.vue'
import TmTableAbstractSkeleton from '@/components/shared/tables/TmTableAbstractSkeleton.vue'
import { TmTableAbstractSkeletonProps } from '@/components/shared/tables/tableBase'
import TmTableContainer from '@/components/shared/tables/TmTableContainer.vue'

export default defineComponent({
  components: { TmTableContainer, TmTableAbstractSkeleton, TmSkeletonFilters },
  props: {
    ...TmTableAbstractSkeletonProps,
    leftControls: Array as PropType<string[]>,
    rightControls: Array as PropType<string[]>,
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
})
</script>

import { inject, injectable } from 'inversify'
import type { RouteLocationNamedRaw } from 'vue-router'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type { RouterPermissionInterface } from '@/services/types'
import { gettingStartedRoutName } from '@/routes/user/getting-started'
import { ticketsRouteNames } from '@/routes/user/tickets/types'
import { TasksRoutes } from '@/routes/user/tasks/types'
import { PointAiRoutes, ServicesRouteName } from '@/routes/types'
import { DealsRoutes } from '@/routes/user/deals/types'
import { campaignsRoute } from '@/routes/user/campaigns/campaigns'
import TmLogicError from '@/core/error/tmLogicError'
import type AdminSectionRedirectResolver from '@/services/resolvers/adminSectionRedirectResolver'
import type ByocRouteHelperService from '@/services/domain/byoc/byocRouteHelperService'
import type { ContactNavigationOrderKey } from '@/services/domain/contact/types'
import type ContactNavigationOrderService from '@/services/domain/contact/contactNavigationOrderService'
import type { TmNamedRoute } from '@/services/route/types'
import type { Dict } from '@/types'
import {
  userHistoryForwardedCallsRouteName,
  userHistoryInboundCallsRouteName,
  userHistoryOutboundCallsRouteName,
  userHistoryOutboundEmailsRouteName,
  userHistoryReceivedSmsRouteName,
  userHistoryRouteName,
  userHistorySentSmsRouteName,
} from '@/routes/user/history'
import { ScheduledRoutes } from '@/routes/user/scheduled/types'
import { SenderSettingsRoutes, SmsSurveyRoutes } from '@/routes/user/services/types'

type MainSidebarRouteInfo =
  | string
  | string[]
  | {
      routeName: string
      childrenRoutesList: Readonly<{ name: string; route: TmNamedRoute | RouteLocationNamedRaw }[]>
      navigateToParentRoute?: boolean
    }

@injectable()
export default class MainSidebarRoutesResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.RouterPermissionsService)
    private readonly routerPermissionsService: RouterPermissionInterface,
    @inject(SERVICE_TYPES.AdminSectionRedirectResolver)
    private readonly adminSectionRedirectResolver: AdminSectionRedirectResolver,
    @inject(SERVICE_TYPES.ByocRouteHelperService) protected readonly byocRouteHelperService: ByocRouteHelperService,
    @inject(SERVICE_TYPES.ContactNavigationOrderService)
    protected readonly contactNavigationOrderService: ContactNavigationOrderService,
  ) {}

  public getContactsSectionRoutes() {
    const keys: ContactNavigationOrderKey[] = ['contact', 'list', 'import', 'unsubscriber', 'blockedContact']
    return keys.map((t) => {
      return {
        name: t,
        route: this.contactNavigationOrderService.getRouteLocationByItemKey(t),
      }
    })
  }

  public getScheduledSectionRoutes() {
    return [
      {
        name: 'upcoming',
        route: { name: ScheduledRoutes.upcoming },
      },
      {
        name: 'paused',
        route: { name: ScheduledRoutes.paused },
      },
      {
        name: 'notSent',
        route: { name: ScheduledRoutes.notSent },
      },
      {
        name: 'completed',
        route: { name: ScheduledRoutes.completed },
      },
      {
        name: 'calendar',
        route: { name: ScheduledRoutes.calendar },
      },
    ] as const
  }

  public getHistorySectionRoutes() {
    return [
      {
        name: 'sentSms',
        route: { name: userHistorySentSmsRouteName },
      },
      {
        name: 'receivedSms',
        route: { name: userHistoryReceivedSmsRouteName },
      },
      {
        name: 'outboundEmails',
        route: { name: userHistoryOutboundEmailsRouteName },
      },
      {
        name: 'forwardedCalls',
        route: { name: userHistoryForwardedCallsRouteName },
      },
      {
        name: 'outboundCalls',
        route: { name: userHistoryOutboundCallsRouteName },
      },
      {
        name: 'inboundCalls',
        route: { name: userHistoryInboundCallsRouteName },
      },
    ] as const
  }

  public getServicesSectionRoutes() {
    return [
      {
        name: 'senderSettings',
        route: { name: SenderSettingsRoutes.senderSettingsIndex },
      },
      {
        name: 'byoc',
        route: this.byocRouteHelperService.getMainRoute() as RouteLocationNamedRaw,
      },
      {
        name: 'emailToSms',
        route: { name: 'user.services.emailToSms' },
      },
      {
        name: 'api',
        route: { name: 'user.services.api' },
      },
      {
        name: 'pointAi',
        route: { name: PointAiRoutes.root },
      },
      {
        name: 'automationRules',
        route: { name: 'user.services.automationRules' },
      },
      {
        name: 'smsSurveys',
        route: { name: SmsSurveyRoutes.index },
      },
      {
        name: 'forms',
        route: { name: 'user.services.forms' },
      },
      {
        name: 'downloads',
        route: { name: 'user.services.downloads' },
      },
      {
        name: 'carrierLookup',
        route: { name: 'user.services.carrierLookup' },
      },
      {
        name: 'emailLookup',
        route: { name: 'user.services.emailLookup' },
      },
    ] as const
  }

  public getReportSectionRoutes() {
    return [
      {
        name: 'overview',
        route: { name: 'user.reporting.overview' },
      },
      {
        name: 'messages',
        route: { name: 'user.reporting.messages' },
      },
      {
        name: 'calls',
        route: { name: 'user.reporting.calls' },
      },
      {
        name: 'auditLogs',
        route: { name: 'user.reporting.auditLogs' },
      },
      {
        name: 'subAccounts',
        route: { name: 'user.reporting.subAccounts' },
      },
      {
        name: 'numbers',
        route: { name: 'user.reporting.numbers' },
      },
    ] as const
  }

  private _getRoutesMap(): Dict<MainSidebarRouteInfo> {
    return {
      GettingStartedMenuItem: gettingStartedRoutName,
      ComposeMainSidebarItem: 'user.compose',
      CampaignsMainSidebarItem: campaignsRoute.name,
      MessengerMainSidebarItem: 'user.chats',
      TicketsMainSidebarItem: ticketsRouteNames.root,
      ContactsMainSidebarItem: {
        routeName: 'user.contacts',
        childrenRoutesList: this.getContactsSectionRoutes(),
        navigateToParentRoute: true,
      },
      TasksMainSidebarItem: [TasksRoutes.index, DealsRoutes.index],
      ScheduledMainSidebarItem: {
        routeName: ScheduledRoutes.scheduled,
        childrenRoutesList: this.getScheduledSectionRoutes(),
      },
      HistoryMainSidebarItem: {
        routeName: userHistoryRouteName,
        childrenRoutesList: this.getHistorySectionRoutes(),
      },
      TemplatesMainSidebarItem: 'user.templates',
      ServicesMainSidebarItem: {
        routeName: ServicesRouteName,
        childrenRoutesList: this.getServicesSectionRoutes(),
      },
      AccountsMainSidebarItem: {
        routeName: 'user.accounts',
        childrenRoutesList: this.adminSectionRedirectResolver
          .getRoutesGroups()
          .map((menuGroup) => menuGroup.links)
          .flat()
          .map((menuLink) => {
            return {
              name: menuLink.route.name,
              route: menuLink.route,
            }
          }),
      },
      ReportingMainSidebarItem: {
        routeName: 'user.reporting',
        childrenRoutesList: this.getReportSectionRoutes(),
      },
    } as const
  }

  public getMainRoutesMap() {
    const routesMap = this._getRoutesMap()
    const preparedRoutes: Partial<Record<keyof typeof routesMap, string | string[] | undefined>> = {}
    for (const _key in routesMap) {
      const key = _key as keyof typeof routesMap
      const routeInfo = routesMap[key]
      if (typeof routeInfo === 'string') {
        preparedRoutes[key] = routeInfo
      } else if (Array.isArray(routeInfo)) {
        preparedRoutes[key] = routeInfo
      } else {
        preparedRoutes[key] = routeInfo.routeName
      }
    }
    return preparedRoutes
  }

  public getRoutesMap() {
    const routesMap = this._getRoutesMap()
    const preparedRoutes: Partial<Record<keyof typeof routesMap, string | undefined>> = {}
    for (const _key in routesMap) {
      const key = _key as keyof typeof routesMap
      const routeInfo = routesMap[key]
      if (typeof routeInfo === 'string' || Array.isArray(routeInfo)) {
        preparedRoutes[key] = Array.isArray(routeInfo) ? routeInfo[0] : routeInfo
      } else if (!routeInfo.childrenRoutesList || routeInfo.childrenRoutesList.length === 0) {
        preparedRoutes[key] = routeInfo.routeName
      } else {
        const firstAllowedChildrenRoute = routeInfo.childrenRoutesList.find((t) => {
          return this.routerPermissionsService.isAllowAccessToRoute(t.route)
        })
        if (!firstAllowedChildrenRoute) {
          continue
        }
        const firstAllowedChildrenRouteName = firstAllowedChildrenRoute.route.name
        if (typeof firstAllowedChildrenRouteName !== 'string') {
          throw new TmLogicError(
            `firstAllowedChildrenRoute is typeof "${typeof firstAllowedChildrenRouteName}", expected string`,
          )
        }
        preparedRoutes[key] = routeInfo.navigateToParentRoute ? routeInfo.routeName : firstAllowedChildrenRouteName
      }
    }
    return preparedRoutes
  }

  public async resolve() {
    const routes = Object.values(this._getRoutesMap())
    await Promise.all(
      routes.map((t) => {
        if (typeof t === 'string') {
          return this.routerPermissionsService.resolveStrategiesForRoute(t)
        }

        if (Array.isArray(t)) {
          return Promise.all(
            t.map((child) => {
              return this.routerPermissionsService.resolveStrategiesForRoute(child)
            }),
          )
        }

        return Promise.all([
          this.routerPermissionsService.resolveStrategiesForRoute(t.routeName),
          t.childrenRoutesList.map((child: (typeof t.childrenRoutesList)[number]) => {
            return this.routerPermissionsService.resolveStrategiesForRoute(child.route)
          }),
        ])
      }),
    )
  }
}

/* eslint-disable  @typescript-eslint/naming-convention */
import { ref, onBeforeUnmount, nextTick, type Ref } from '@/composition/vue/compositionApi'
import { observedScrollbarQuasarClass } from '@/components/shared/types'
import { addPxToNumber } from '@/utils/string/addPxToNumber'
import { useBreakpoints } from '@/composition/breakpoints'

// duplicates the QMenu type description, but avoids error CAR-4181
export type FakeQMenu = {
  show: (evt?: Event) => void
  hide: (evt?: Event) => void
  toggle: (evt?: Event) => void
  updatePosition: () => void
  focus: () => void
  readonly contentEl: Element
}

export type AngleAttrsAnchor =
  | 'top left'
  | 'top middle'
  | 'top right'
  | 'center left'
  | 'center middle'
  | 'center right'
  | 'bottom left'
  | 'bottom middle'
  | 'bottom right'

type AngleAttrs = {
  anchor: AngleAttrsAnchor
  self: AngleAttrsAnchor
  offset: number[]
  class: string
}
type TooltipSettings = {
  anchorEl: HTMLElement
  menuEl: HTMLElement
  attrs: AngleAttrs
  maxHeight?: number
  qMenu?: Ref<FakeQMenu | undefined>
}
type RenderTooltip = {
  show: (settings: TooltipSettings) => Promise<void>
  hide: () => void
}

export type ControlPosition =
  | 'top'
  | 'top-left'
  | 'top-right'
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'left'
  | 'left-top'
  | 'left-bottom'
  | 'right'
  | 'right-top'
  | 'right-bottom'

/* quasar/src/utils/scroll.js
  func getScrollbarWidth necessary for correct positioning TmDropdown, TmTooltip with angle */
export const getScrollbarWidth = (() => {
  const applyCss = (element: HTMLElement, css: { [key: string]: any }) => {
    const style = element.style as { [key: string]: any }

    Object.keys(css).forEach((prop) => {
      style[prop] = css[prop]
    })
  }

  let size: number
  return () => {
    if (size !== undefined) {
      return size
    }

    const inner = document.createElement('p')
    const outer = document.createElement('div')

    applyCss(inner, {
      width: '100%',
      height: '200px',
    })
    applyCss(outer, {
      position: 'absolute',
      top: '0px',
      left: '0px',
      visibility: 'hidden',
      width: '200px',
      height: '150px',
      overflow: 'hidden',
    })

    outer.appendChild(inner)

    document.body.appendChild(outer)

    const w1 = inner.offsetWidth
    outer.style.overflow = 'scroll'
    let w2 = inner.offsetWidth

    if (w1 === w2) {
      w2 = outer.clientWidth
    }

    outer.remove()
    size = w1 - w2

    return size
  }
})()

/* quasar/src/utils/private/position-engine.js
  func getTargetRect necessary for correct positioning TmDropdown, TmTooltip with angle */
export const getTargetRect = (el: HTMLElement): { [key: string]: number } => ({
  top: 0,
  center: el.offsetHeight / 2,
  bottom: el.offsetHeight,
  left: 0,
  middle: el.offsetWidth / 2,
  right: el.offsetWidth,
})

/* quasar/src/utils/private/position-engine.js
  func getTargetRect necessary for correct positioning TmDropdown, TmTooltip with angle */
export const getAnchorRect = (el: HTMLElement, offset: number[]): { [key: string]: number } => {
  let { top, left, right, bottom, width, height } = el.getBoundingClientRect()

  if (offset !== undefined) {
    top -= offset[1]
    left -= offset[0]
    bottom += offset[1]
    right += offset[0]

    width += offset[0]
    height += offset[1]
  }

  return {
    top,
    left,
    right,
    bottom,
    width,
    height,
    middle: left + (right - left) / 2,
    center: top + (bottom - top) / 2,
  }
}

const getPos = (value: AngleAttrsAnchor): { vertical: string; horizontal: string } => {
  const [vertical, horizontal] = value.split(' ')
  return {
    vertical,
    horizontal,
  }
}

export const getTooltipAngleAttrs = (rootClass: string, position: ControlPosition, angle: boolean): AngleAttrs => {
  const attrsSet: { [key: string]: AngleAttrs } = {
    left: {
      anchor: 'center left',
      self: 'center right',
      offset: [0, 0],
      class: `${rootClass}--left-center${angle ? '-angle' : ''}`,
    },
    'left-top': {
      anchor: 'bottom left',
      self: 'bottom right',
      offset: [0, 0],
      class: `${rootClass}--left-top${angle ? '-angle' : ''}`,
    },
    'left-bottom': {
      anchor: 'top left',
      self: 'top right',
      offset: [0, 0],
      class: `${rootClass}--left-bottom${angle ? '-angle' : ''}`,
    },
    right: {
      anchor: 'center right',
      self: 'center left',
      offset: [0, 0],
      class: `${rootClass}--right-center${angle ? '-angle' : ''}`,
    },
    'right-top': {
      anchor: 'bottom right',
      self: 'bottom left',
      offset: [0, 0],
      class: `${rootClass}--right-top${angle ? '-angle' : ''}`,
    },
    'right-bottom': {
      anchor: 'top right',
      self: 'top left',
      offset: [0, 0],
      class: `${rootClass}--right-bottom${angle ? '-angle' : ''}`,
    },
    top: {
      anchor: 'top middle',
      self: 'bottom middle',
      offset: [0, 0],
      class: `${rootClass}--top-center${angle ? '-angle' : ''}`,
    },
    'top-left': {
      anchor: 'top right',
      self: 'bottom right',
      offset: angle ? [30, 0] : [0, 0],
      class: `${rootClass}--top-left${angle ? '-angle' : ''}`,
    },
    'top-right': {
      anchor: 'top left',
      self: 'bottom left',
      offset: angle ? [30, 0] : [0, 0],
      class: `${rootClass}--top-right${angle ? '-angle' : ''}`,
    },
    bottom: {
      anchor: 'bottom middle',
      self: 'top middle',
      offset: [0, 0],
      class: `${rootClass}--bottom-center${angle ? '-angle' : ''}`,
    },
    'bottom-left': {
      anchor: 'bottom right',
      self: 'top right',
      offset: angle ? [30, 0] : [0, 0],
      class: `${rootClass}--bottom-left${angle ? '-angle' : ''}`,
    },
    'bottom-right': {
      anchor: 'bottom left',
      self: 'top left',
      offset: angle ? [30, 0] : [0, 0],
      class: `${rootClass}--bottom-right${angle ? '-angle' : ''}`,
    },
  }

  return { ...attrsSet[position] }
}

export const renderTooltip = (internalRootClass: string): RenderTooltip => {
  const closestScrolls = ref([] as HTMLElement[])
  let internalAnchorEl: HTMLElement
  let internalMenuEl: HTMLElement
  let internalQMenu: any
  let internalAttrs = {} as AngleAttrs
  let internalMaxHeight: number | void
  let xReversed = false
  let yReversed = false

  const definePosition = async (event?: any) => {
    if (!internalAnchorEl || !internalMenuEl) {
      return
    }

    const { anchor: anchorStr, self: selfStr, offset } = internalAttrs
    const anchorPosition = getPos(anchorStr)
    const targetPosition = getPos(selfStr)

    const anchorRect = getAnchorRect(internalAnchorEl, offset)
    const targetRect = getTargetRect(internalMenuEl)
    if (internalMaxHeight) {
      targetRect.bottom = internalMaxHeight
    }
    const halfAngleWidth = 10 / 2
    const xAngleOffset = anchorRect.right - anchorRect.middle - halfAngleWidth
    const yAngleOffset = Math.abs(anchorRect.top - anchorRect.center) - halfAngleWidth
    internalMenuEl.style.setProperty('--x-angle-offset', addPxToNumber(xAngleOffset))
    internalMenuEl.style.setProperty('--y-angle-offset', addPxToNumber(yAngleOffset))

    const boundaries = {
      top:
        anchorPosition.vertical === 'bottom'
          ? anchorRect[anchorPosition.vertical] - targetRect[targetPosition.vertical]
          : anchorRect[anchorPosition.vertical] - targetRect[targetPosition.vertical],
      left: anchorRect[anchorPosition.horizontal] - targetRect[targetPosition.horizontal],
      maxWidth: internalMenuEl.offsetWidth,
      maxHeight: internalMenuEl.offsetHeight,
    }

    const scrollbarWidth = getScrollbarWidth()
    const innerHeight = window.innerHeight - scrollbarWidth
    const { clientWidth: innerWidth } = document.body
    const currentHeight = targetRect.bottom
    const currentWidth = targetRect.right

    const activeScroll = event?.srcElement || closestScrolls.value[0]
    const {
      top: activeScrollOffsetTop,
      left: activeScrollOffsetLeft,
      bottom: activeScrollOffsetBottom,
    } = activeScroll?.getBoundingClientRect() || {}

    // anchorEl visibility check
    if (
      anchorRect.top + anchorRect.height < activeScrollOffsetTop ||
      anchorRect.top + offset[1] > innerHeight ||
      anchorRect.top + offset[1] > activeScrollOffsetBottom ||
      anchorRect.left + anchorRect.width < activeScrollOffsetLeft ||
      anchorRect.left + offset[0] > innerWidth
    ) {
      const lockYScroll = activeScroll.scrollTop
      const lockXScroll = activeScroll.scrollLeft
      if (internalQMenu) {
        internalQMenu.value.hide()
      }
      await nextTick()
      activeScroll.scrollTop = lockYScroll
      activeScroll.scrollLeft = lockXScroll
      return
    }

    yReversed = boundaries.top < 0 || boundaries.top + currentHeight > innerHeight
    if (yReversed) {
      const { vertical: anchorV } = anchorPosition
      const { vertical: targetV } = targetPosition
      /*
        yReversed if statement was taken from quasar/src/utils/private/position-engine.js
        for correct positioning when two and more scrollbar nesting
        and for a correct understanding of the position on Y axis
       */
      if (targetPosition.vertical === 'center') {
        /*
          does not allow to go beyond the borders of the yScreen (min: 0, max: innerHeight)
          props: left | right
         */
        boundaries.top =
          anchorRect[anchorPosition.vertical] > innerHeight / 2 ? Math.max(0, innerHeight - currentHeight) : 0
        boundaries.maxHeight = Math.min(currentHeight, innerHeight)
      } else if (anchorRect[anchorPosition.vertical] > innerHeight / 2) {
        internalMenuEl.classList.toggle(`${internalRootClass}--y-reversed`, anchorV === 'bottom')
        /*
          does not allow to go beyond the bottom border (max: anchorY - currentHeight)
          anchorY like innerHeight, currentHeight like menuEl.offsetHeight
          props everything except: left | right
         */
        const anchorY = Math.min(
          innerHeight,
          // eslint-disable-next-line no-nested-ternary
          anchorPosition.vertical === 'center'
            ? anchorRect.center
            : anchorPosition.vertical === targetPosition.vertical
              ? anchorRect.bottom
              : anchorRect.top,
        )
        boundaries.maxHeight = Math.min(currentHeight, anchorY)
        boundaries.top = Math.max(0, anchorY - currentHeight)
      } else {
        internalMenuEl.classList.toggle(`${internalRootClass}--y-reversed`, anchorV !== targetV && anchorV === 'top')
        /*
          does not allow to go beyond the top border (min: 0)
          props everything except: left | right
         */
        boundaries.top = Math.max(
          0,
          // eslint-disable-next-line no-nested-ternary
          anchorPosition.vertical === 'center'
            ? anchorRect.center
            : anchorPosition.vertical === targetPosition.vertical
              ? anchorRect.top
              : anchorRect.bottom,
        )
        boundaries.maxHeight = Math.min(currentHeight, innerHeight - boundaries.top)
      }
    } else {
      internalMenuEl.classList.remove(`${internalRootClass}--y-reversed`)
    }

    const setXReversedClass = () => {
      const { horizontal: anchorH } = anchorPosition
      const { horizontal: targetH } = targetPosition
      // cancels repulsion from opposite sides for props: top-left | top-right | bottom-left | bottom-right
      if (
        (boundaries.left < 0 && (anchorH !== targetH || anchorH !== 'left')) ||
        (boundaries.left + currentWidth > innerWidth && (anchorH !== targetH || anchorH !== 'right'))
      ) {
        internalMenuEl.classList.add(`${internalRootClass}--x-reversed`)
      }
    }

    xReversed = boundaries.left < 0 || boundaries.left + currentWidth > innerWidth
    if (xReversed) {
      /*
        xReversed if statement was taken from quasar/src/utils/private/position-engine.js
        for correct positioning when two and more scrollbar nesting
        and for a correct understanding of the position on X axis
       */
      setXReversedClass()
      boundaries.maxWidth = Math.min(currentWidth, innerWidth)
      if (targetPosition.horizontal === 'middle') {
        /*
          does not allow to go beyond the borders of the xScreen (min: 0, max: innerWidth - currentWidth)
          props: top | bottom
         */
        boundaries.left =
          anchorRect[anchorPosition.horizontal] > innerWidth / 2 ? Math.max(0, innerWidth - currentWidth) : 0
      } else if (anchorRect[anchorPosition.horizontal] > innerWidth / 2) {
        /*
          does not allow to go beyond the right border (max: anchorX - boundaries.maxWidth)
          anchorY like innerWidth, boundaries.maxWidth like menuEl.offsetWidth
          props everything except: top | bottom
         */
        const anchorX = Math.min(
          innerWidth,
          // eslint-disable-next-line no-nested-ternary
          anchorPosition.horizontal === 'middle'
            ? anchorRect.middle
            : anchorPosition.horizontal === targetPosition.horizontal
              ? anchorRect.right
              : anchorRect.left,
        )
        boundaries.maxWidth = Math.min(currentWidth, anchorX)
        boundaries.left = Math.max(0, anchorX - boundaries.maxWidth)
      } else {
        /*
          does not allow to go beyond the left border (min: 0)
          props everything except: top | bottom
         */
        boundaries.left = Math.max(
          0,
          // eslint-disable-next-line no-nested-ternary
          anchorPosition.horizontal === 'middle'
            ? anchorRect.middle
            : anchorPosition.horizontal === targetPosition.horizontal
              ? anchorRect.left
              : anchorRect.right,
        )
        boundaries.maxWidth = Math.min(currentWidth, innerWidth - boundaries.left)
      }
    } else {
      internalMenuEl.classList.remove(`${internalRootClass}--x-reversed`)
    }

    internalMenuEl.style.top = addPxToNumber(boundaries.top)
    internalMenuEl.style.left = addPxToNumber(boundaries.left)
  }

  const show = async (settings: TooltipSettings) => {
    internalAttrs = settings.attrs
    internalAnchorEl = settings.anchorEl
    internalMenuEl = settings.menuEl
    internalQMenu = settings.qMenu
    internalMaxHeight = settings.maxHeight
    closestScrolls.value = getScrollbarWraps(internalAnchorEl)

    definePosition()
    setListeners()
  }

  const getScrollbarWraps = (el: HTMLElement, acc: HTMLElement[] = []) => {
    if (el?.parentElement && el.parentElement !== document.body) {
      if (el.parentElement.classList.contains(observedScrollbarQuasarClass)) {
        acc.push(el.parentElement)
      }
      getScrollbarWraps(el.parentElement, acc)
    }

    return acc
  }

  onBeforeUnmount(() => {
    closestScrolls.value.forEach((scroll) => {
      scroll.removeEventListener('scroll', definePosition)
    })
  })

  const setListeners = () => {
    closestScrolls.value.forEach((scroll) => {
      scroll.addEventListener('scroll', definePosition)
    })
  }

  const unsetListeners = () => {
    closestScrolls.value.forEach((scroll) => {
      scroll.removeEventListener('scroll', definePosition)
    })
  }

  return {
    show,
    hide: unsetListeners,
  }
}

export const useTooltipMobilePosition = (defaultPosition: ControlPosition = 'bottom-right'): ControlPosition => {
  const { isMobile } = useBreakpoints()
  return isMobile.value ? 'bottom' : defaultPosition
}

import { get } from '@/core/container/ioc'
import type { UseTableBuilderSettings } from '@/composition/tables'
import type { Endpoint } from '@/services/endpoints'
import type TableSorterManagerInterface from '@/services/tables/managers/tableSorterManagerInterface'

type UseSortersSettings = Pick<UseTableBuilderSettings, 'tableModelId' | 'tableSorterManager' | 'sorterFactory'> & {
  endpoint: Endpoint
}

export const useSorters = (settings: Pick<UseSortersSettings, 'tableSorterManager'>) =>
  get<TableSorterManagerInterface>(settings.tableSorterManager ?? 'TableSorterManager')

export const createSorters = (settings: UseSortersSettings) => {
  const manager = get<TableSorterManagerInterface>(settings.tableSorterManager ?? 'TableSorterManager')

  manager.setEndpointForTable(settings.tableModelId, settings.endpoint)
  if (settings.sorterFactory) {
    manager.setFactoryForTable(settings.tableModelId, settings.sorterFactory)
  }

  return useSorters(settings)
}

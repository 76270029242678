import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'

export const campaignsScheduledNotSentSmsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.notSent.sms',
  path: 'sms',
  component: () => import('@/components/views/campaigns/scheduled/notSent/CampaignsScheduledNotSentSms.vue'),
  meta: {
    modules: [AppModule.ScheduledNotSent],
  },
}

export const campaignsScheduledNotSentEmailRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.notSent.email',
  path: 'email',
  component: () => import('@/components/views/campaigns/email/CampaignsEmailStub.vue'),
}

export const campaignsScheduledNotSentRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.notSent',
  path: 'not-sent',
  component: () => import('@/components/views/campaigns/scheduled/notSent/CampaignsScheduledNotSent.vue'),
  children: [campaignsScheduledNotSentSmsRoute, campaignsScheduledNotSentEmailRoute],
  redirect: { name: campaignsScheduledNotSentSmsRoute.name },
  meta: {
    tabTitle: 'pageTabTitle.user.campaigns.scheduled.notSent',
  },
}

import { inject, injectable } from 'inversify'
import type { CleanUpable, CleanUpTriggerTask } from '@/services/cleanUp/types'
import type BaseModel from '@/data/models/BaseModel'
import { SERVICE_TYPES } from '@/core/container/types'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type TableManager from '@/services/tables/tableManager'
import type LoggerService from '@/services/loggerService'
import type DomainManagerService from '@/services/domain/domainManagerService'

@injectable()
export default class TableCleanUper implements CleanUpable {
  constructor(
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.TableManager) protected readonly tableManager: TableManager,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.DomainManagerService) protected readonly domainManagerService: DomainManagerService,
  ) {}

  public trigger(tasks: CleanUpTriggerTask[]): void {
    const clearedModels: string[] = []
    tasks.forEach(({ model }) => {
      const withRelated = this.domainManagerService.getAllDomainWithRelated(model)
      this.loggerService.log('cleanUp', 'Gathered models from related', 'table')
      this.loggerService.raw('cleanUp', withRelated)
      withRelated.forEach((relatedModel: typeof BaseModel) => {
        this.loggerService.log('cleanUp', 'With related', 'table')
        this.loggerService.raw('cleanUp', relatedModel)
        const preloader = this.preloaderManager.getTablePreloader(relatedModel)
        preloader.allTablePreloaders(relatedModel).forEach((pageMapPreload) => {
          if (
            this.tableManager.hasTable(pageMapPreload.accessoryId) &&
            !this.tableManager.getTable(pageMapPreload.accessoryId)!.isActive() &&
            clearedModels.indexOf(relatedModel.entity) === -1
          ) {
            preloader.deleteTablePreloadersByCondition(pageMapPreload.accessoryId)
            clearedModels.push(relatedModel.entity)
            this.loggerService.log('cleanUp', `Clean up for table id ${pageMapPreload.accessoryId}`, 'table')
          } else {
            this.loggerService.log('cleanUp', `Abort clean up for table id ${pageMapPreload.accessoryId}`, 'table')
          }
        })
      })
    })
  }
}

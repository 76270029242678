import { injectable } from 'inversify'
import type { SortDirection } from '@/core/tables/types'
import type { FilteredViewsParsedBodySortType, PaginationUrlParametersSortType } from '@/services/tables/types'
import BaseSorterServiceFactory from '@/services/tables/sort/baseSorterServiceFactory'
import type Sorter from '@/data/models/tables/Sorter'

@injectable()
export default class NoopSorterServiceFactory extends BaseSorterServiceFactory {
  public init(params?: PaginationUrlParametersSortType) {}

  public reset(excludeNames: string[] = []) {}

  public applySort(name: string, direction?: SortDirection) {}

  public applySorts(toApply: Array<FilteredViewsParsedBodySortType>) {}

  public getAllSorters() {
    return []
  }

  public getActiveSorter(): Array<Sorter> {
    return []
  }

  public toQuery(): PaginationUrlParametersSortType {
    return {} as PaginationUrlParametersSortType
  }
}

<template>
  <div
    class="d-flex align-center w100pr"
    :class="{ 'ml-n2': isMobile && showPromoChat }"
  >
    <tm-button
      v-if="isMobile && showPromoChat"
      flat
      no-border
      icon-only
      class="mr-2 no-print"
      @click="$emit('update:show-mobile-chat', false)"
    >
      <tm-icon
        name="tmi-chevron-left"
        size="small"
        class="distinct--text"
      />
    </tm-button>

    <tm-skeleton
      type="circle"
      width="40px"
      height="40px"
      class="mr-3"
      :animation="isLoading ? 'wave' : 'none'"
    />

    <div class="flex-grow-1">
      <tm-skeleton
        type="QChip"
        width="140px"
        height="15px"
        class="mb-2"
        :animation="isLoading ? 'wave' : 'none'"
      />
      <tm-skeleton
        type="QChip"
        width="85px"
        height="8px"
        :animation="isLoading ? 'wave' : 'none'"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import { useBreakpoints } from '@/composition/breakpoints'

defineProps<{
  isLoading?: boolean
  showPromoChat?: boolean
}>()

defineEmits<{
  'update:show-mobile-chat': [boolean]
}>()

const { isMobile } = useBreakpoints()
</script>

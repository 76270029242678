import { watch, ref, onUnmounted, onMounted } from '@/composition/vue/compositionApi'
import { getModelSubscriptionService, getServerSubscriptionService, getSubscriptionService } from '@/core/container/ioc'
import type {
  ModelEmitterPayload,
  InternalEmitterPayload,
  InternalSubscriberCallback,
  InternalUnmountSubscriberCallback,
} from '@/services/transport/types'
import type BaseModel from '@/data/models/BaseModel'
import type { IServerEventsMap, TServerSubscriberCallback } from '@/services/transport/serverEvents'

export const subscribe = <T = InternalEmitterPayload>(
  eventName: string,
  callback: InternalSubscriberCallback<T>,
  unmountCallback?: InternalUnmountSubscriberCallback,
) => {
  const subscriptionService = getSubscriptionService()
  const subKey = subscriptionService.subscribe<T>(eventName, callback)
  onUnmounted(() => {
    if (unmountCallback && typeof unmountCallback === 'function') {
      unmountCallback()
    }
    subscriptionService.unsubscribe(subKey)
  })
}
export const subscribeByModel = (
  model: typeof BaseModel,
  callback: InternalSubscriberCallback<ModelEmitterPayload>,
  unmountCallback?: InternalUnmountSubscriberCallback,
) => {
  const modelSubscriptionService = getModelSubscriptionService()
  const subKey = modelSubscriptionService.subscribeByModel(model, callback)
  onUnmounted(() => {
    if (unmountCallback && typeof unmountCallback === 'function') {
      unmountCallback()
    }
    modelSubscriptionService.unsubscribe([subKey])
  })
}
export const oneTimeSubscribeByModel = (
  model: typeof BaseModel,
  callback: (payload: ModelEmitterPayload) => boolean | void | Promise<boolean | void>,
  unmountCallback?: InternalUnmountSubscriberCallback,
) => {
  const needUnsubscribe = ref(false)
  const modelSubscriptionService = getModelSubscriptionService()
  const subKey = modelSubscriptionService.subscribeByModel(model, async (payload) => {
    needUnsubscribe.value = !!(await callback(payload))
  })

  watch(needUnsubscribe, (value) => {
    if (value) {
      if (unmountCallback && typeof unmountCallback === 'function') {
        unmountCallback()
      }
      modelSubscriptionService.unsubscribe([subKey])
    }
  })
}

export const useSubscription = <T = InternalEmitterPayload>(
  eventName: string,
  callback: InternalSubscriberCallback<T>,
) => {
  const subscriptionService = getSubscriptionService()
  let subscription: string = ''

  onMounted(() => {
    subscription = subscriptionService.subscribe(eventName, callback)
  })

  onUnmounted(() => {
    subscriptionService.unsubscribe(subscription)
  })
}

export const useServerSubscription = <T extends keyof IServerEventsMap>(
  eventName: T,
  callback: TServerSubscriberCallback<T>,
) => {
  const serverSubscriptionService = getServerSubscriptionService()

  serverSubscriptionService.subscribe(eventName, callback)

  const unsubscribe = () => serverSubscriptionService.unsubscribe(eventName, callback)
  onUnmounted(unsubscribe)

  return { unsubscribe }
}

import type { CustomErrorRule } from '@/core/error/transport/types'
import { EndpointsEnum } from '@/services/endpoints'
import { TmApiDealActivityItemNotFoundError } from '@/core/error/transport/tmApiDealActivityItemNotFoundError'

export const dealActivityItemNotFoundErrorRule: CustomErrorRule = {
  klass: TmApiDealActivityItemNotFoundError,
  methods: ['put'],
  predicateFn: (error) => {
    const endpointUrl = error.config.url

    if (!endpointUrl) {
      return false
    }

    const regex = new RegExp(`${EndpointsEnum.dealActivityItems.replace('{0}', '([0-9]+)')}`)
    return regex.test(endpointUrl)
  },
}

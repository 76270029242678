import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import PassThrough from '@/components/layout/PassThrough.vue'

export const userAccountsBillingOverviewRoute: RouteRecordRaw = {
  name: 'user.accounts.billingOverview',
  path: 'billing-overview',
  meta: {
    tags: ['ACCOUNTS_BAR_LIST'],
    permission: 'autorechargeSettings',
    tabTitle: 'pageTitle.user.accounts.billingOverview',
    resolvers: [
      {
        service: 'VerifyBillingAllowedResolver',
      },
    ],
    modules: [AppModule.Billing, AppModule.ByocHelper, AppModule.StripeBilling],
    pageContentBgShade: true,
  },
  component: () => import('@/components/views/accounts/BillingOverview.vue'),
}

export const userAccountsMonthlyPlansRoute: RouteRecordRaw = {
  name: 'user.accounts.monthlyPlans',
  path: 'monthly-plans',
  meta: {
    tags: ['ACCOUNTS_BAR_LIST'],
    modules: [AppModule.Billing, AppModule.StripeBilling],
    accessControlGroups: ['MonthlyPlansStrategy'],
  },
  redirect: { name: 'user.accounts.monthlyPlans.default' },
  children: [
    {
      name: 'user.accounts.monthlyPlans.default',
      path: '',
      meta: {
        tabTitle: 'pageTabTitle.user.billing.plans',
      },
      component: () => import('@/components/views/accounts/PlansWrapper.vue'),
    },
  ],
  component: PassThrough,
}

export const userAccountsInvoicesRoute: RouteRecordRaw = {
  name: 'user.accounts.invoices',
  path: 'invoices',
  meta: {
    tags: ['ACCOUNTS_BAR_LIST'],
    permission: 'invoices',
    modules: [AppModule.InvoicesBase, AppModule.Invoices],
  },
  component: () => import('@/components/views/accounts/Invoices.vue'),
  redirect: { name: 'user.accounts.invoices.payAsYouGo' },
  children: [
    {
      name: 'user.accounts.invoices.payAsYouGo',
      path: '',
      meta: {
        tabTitle: 'pageTabTitle.user.accounts.invoices',
      },
      component: () => import('@/components/views/accounts/InvoicesPayAsYouGo.vue'),
    },
    {
      name: 'user.accounts.invoices.teamSubscription',
      path: 'team-subscription',
      meta: {
        accessControlGroups: ['UserWorkflowStrategy'],
        tabTitle: 'pageTabTitle.user.accounts.invoices',
      },
      component: () => import('@/components/views/accounts/InvoicesTeamSubscription.vue'),
    },
  ],
}

export const userAccountsStatementsRoute: RouteRecordRaw = {
  name: 'user.accounts.statements',
  path: 'statements',
  meta: {
    tags: ['ACCOUNTS_BAR_LIST'],
    title: 'pageTitle.user.accounts.statements',
    permission: 'reportingStatements',
    modules: [AppModule.Statements],
    accessControlGroups: ['UsersWorkflowNotUserRoleStrategy'],
    tabTitle: 'pageTitle.user.accounts.statements',
  },
  component: () => import('@/components/views/accounts/Statements.vue'),
}

import { ref, computed, type ComputedRef, type Ref, watch } from '@/composition/vue/compositionApi'
import { styleVars } from '@/constants/styleVariables'
import { kebabToPascalCase } from '@/utils/string/kebabToPascalCase'
import { isDev, isTest } from '@/utils/system'

const sizes = ['xs', 'sm', 'sm-x', 'md', 'lg', 'xl', 'xxl', '3xl']

type Rule = {
  name: string
  rule: string
}

type UseBreakpoints = () => {
  is4XL: ComputedRef<boolean>
  isXXL: ComputedRef<boolean>
  isXXLMin: ComputedRef<boolean>
  isXXLargeLaptopRange: ComputedRef<boolean>
  isLargeLaptopMin: ComputedRef<boolean>
  isLargeLaptop: ComputedRef<boolean>
  isLaptop: ComputedRef<boolean>
  isTablet: ComputedRef<boolean>
  isMobile: ComputedRef<boolean>
  isSmBreakpoint: ComputedRef<boolean>
  isSmallMobile: ComputedRef<boolean>
  isLaptopRange: ComputedRef<boolean>
  isLargeLaptopRange: ComputedRef<boolean>
  is3xlMin: ComputedRef<boolean>
  is3xlMax: ComputedRef<boolean>
  activeBreakpoints: Ref<string[]>
  breakpointRules: Rule[]
}

const breakpointRules: Rule[] = []
const activeBreakpoints = ref<string[]>([])
// there is no window in the testing environment
const matches = (rule: string) => (isTest() ? false : window.matchMedia(rule).matches)

sizes.forEach((name: string) => {
  const rules: Array<{ name: string; rule: string }> = []
  const pascalName = kebabToPascalCase(name)
  const min = styleVars[`breakpoint${pascalName}Min` as keyof typeof styleVars]
  const max = styleVars[`breakpoint${pascalName}Max` as keyof typeof styleVars]

  if (min) rules.push({ name: `${name}-min`, rule: `(min-width: ${min})` })
  if (max) rules.push({ name: `${name}-max`, rule: `(max-width: ${max})` })
  if (min && max) rules.push({ name: `${name}-in-range`, rule: `(max-width: ${max}) and (min-width: ${min})` })

  breakpointRules.push(...rules)
})

breakpointRules.forEach((rule) => {
  if (matches(rule.rule)) {
    activeBreakpoints.value = [...activeBreakpoints.value, rule.name]
  }
})

const update = (event: MediaQueryListEvent) => {
  const mediaQueryName = breakpointRules.find((rule) => rule.rule === event.media)?.name
  if (!mediaQueryName) {
    return
  }
  if (event.matches) {
    if (!activeBreakpoints.value.includes(mediaQueryName)) {
      activeBreakpoints.value = [...activeBreakpoints.value, mediaQueryName]
    }
  } else if (activeBreakpoints.value.includes(mediaQueryName)) {
    activeBreakpoints.value = [...activeBreakpoints.value].filter((breakpoint) => breakpoint !== mediaQueryName)
  }
}
if (!isTest()) {
  breakpointRules.forEach((rule) => {
    window.matchMedia(rule.rule).addEventListener('change', update)
  })
}

const is4XL = computed(() => activeBreakpoints.value.includes('4-xl-min'))
const isXXL = computed(() => activeBreakpoints.value.includes('xxl-max'))
const isXXLMin = computed(() => activeBreakpoints.value.includes('xxl-min'))
const isLargeLaptopMin = computed(() => activeBreakpoints.value.includes('xl-min'))
const isLargeLaptop = computed(() => activeBreakpoints.value.includes('xl-max'))
const isLaptop = computed(() => activeBreakpoints.value.includes('lg-max'))
const isTablet = computed(() => activeBreakpoints.value.includes('md-max'))
const isMobile = computed(() => activeBreakpoints.value.includes('sm-x-max'))
const isSmBreakpoint = computed(() => activeBreakpoints.value.includes('sm-max'))
const isSmallMobile = computed(() => activeBreakpoints.value.includes('xs-max'))
const isLaptopRange = computed(() => activeBreakpoints.value.includes('lg-in-range'))
const isLargeLaptopRange = computed(() => activeBreakpoints.value.includes('xl-in-range'))
const isXXLargeLaptopRange = computed(() => activeBreakpoints.value.includes('xxl-in-range'))
const is3xlMin = computed(() => activeBreakpoints.value.includes('3xl-min'))
const is3xlMax = computed(() => activeBreakpoints.value.includes('3xl-max'))

if (isDev()) {
  const watchBreakpoint = (name: string, breakpoint: ComputedRef<boolean>) => {
    watch(
      breakpoint,
      (value) => {
        if (!value) {
          return
        }
        // eslint-disable-next-line
        console.log(`${name} breakpoint is active`)
      },
      { immediate: true },
    )
  }

  const logBpChanges = false
  if (logBpChanges) {
    watchBreakpoint('4XL', is4XL)
    watchBreakpoint('XXL', isXXL)
    watchBreakpoint('XXL min', isXXLMin)
    watchBreakpoint('Large Laptop Min', isLargeLaptopMin)
    watchBreakpoint('Large Laptop', isLargeLaptop)
    watchBreakpoint('Laptop', isLaptop)
    watchBreakpoint('Tablet', isTablet)
    watchBreakpoint('Mobile', isMobile)
    watchBreakpoint('isSmBreakpoint', isSmBreakpoint)
    watchBreakpoint('Small Mobile', isSmallMobile)
    watchBreakpoint('Laptop Range', isLaptopRange)
    watchBreakpoint('Large Laptop Range', isLargeLaptopRange)
    watchBreakpoint('XXL Laptop Range', isXXLargeLaptopRange)
    watchBreakpoint('3xl min', is3xlMin)
    watchBreakpoint('3xl max', is3xlMax)
  }
}

export const useBreakpoints: UseBreakpoints = () => ({
  is4XL,
  isXXL,
  isXXLMin,
  isXXLargeLaptopRange,
  isLargeLaptopMin,
  isLargeLaptop,
  isLaptop,
  isTablet,
  isMobile,
  isSmBreakpoint,
  isSmallMobile,
  isLaptopRange,
  isLargeLaptopRange,
  is3xlMin,
  is3xlMax,
  activeBreakpoints,
  breakpointRules,
})

/**
 * Workaround for creating a Worker with a cross-origin URL.
 *
 * https://github.com/vitejs/vite/issues/13680#issuecomment-1819274694
 *
 * @param {string} url
 * @param {WorkerOptions|undefined} options
 *
 * @return {Worker}
 */
export const createWorkerCrossoriginWorkaround = (url: string, options?: WorkerOptions) => {
  // language=JavaScript
  const js = `import ${JSON.stringify(new URL(url, import.meta.url))}`
  const blob = new Blob([js], { type: 'application/javascript' })

  const objURL = URL.createObjectURL(blob)
  const worker = new Worker(objURL, { type: 'module', name: options?.name })
  worker.addEventListener('error', (e) => {
    URL.revokeObjectURL(objURL)
  })

  return worker
}

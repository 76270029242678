import { injectable } from 'inversify'
import type { Endpoint } from '@/services/endpoints'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import UiSettings from '@/data/models/domain/UiSettings'
import type { ModelRaw } from '@/types'

@RepoSettings<Endpoint>({
  model: UiSettings,
})
@injectable()
export default class UiSettingsRepository extends OrmApiRepository<UiSettings> {
  public async fetchSettings() {
    const path = this.endpointsService.getPath('uiSettings')
    const response = await this.getApiSource().get<ModelRaw<UiSettings>>(path)
    return this.insertOrUpdate([{ ...response.data, id: '1' }])
  }

  public async updateSettings(settings: Partial<ModelRaw<UiSettings>>) {
    const path = this.endpointsService.getPath('uiSettings')
    await this.getApiSource().patch(path, settings)
    const id = this.firstOrFail().id
    await this.insertOrUpdate([{ ...settings, id }])
  }
}

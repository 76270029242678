/* eslint-disable no-else-return */
import { cloneDeep, intersection } from 'lodash-es'
import PromisePool from '@supercharge/promise-pool'
import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type ResolverManager from '@/services/resolvers/resolverManager'
import type { Route } from '@/services/route/types'
import type LoggerService from '@/services/loggerService'
import type { ResolverConfig, Resolvers } from '@/services/types'
import type { AppModule } from '@/config/types'
import { priorityLoadAndBindAllDepsForModules } from '@/core/container/deps'
import type { ResolverExecutorService } from '@/services/resolvers/resolverExecutorService'
import { isRecordUnknown } from '@/utils/typeGuards'
import type { Dict, Optional, Voidable } from '@/types'
import { TmApiError } from '@/core/error/transport/tmApiError'

// more info here - https://textmagic.atlassian.net/wiki/spaces/TM30/pages/1885995009/Resolvers
@injectable()
export default class ResolverService {
  /* final */ private constructor(
    @inject(SERVICE_TYPES.ResolverManager) protected readonly resolverManager: ResolverManager,
    @inject(SERVICE_TYPES.ResolverExecutorService) protected readonly resolverExecutorService: ResolverExecutorService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {}

  public async resolveComponent(resolvers: Resolvers, route?: Route, fromRoute?: Route) {
    this.logResolvers(resolvers, 'resolveComponent')
    return this.resolveParallel(resolvers, route, fromRoute)
  }

  public resolveDeps(routeName: string, modules?: AppModule[]) {
    return priorityLoadAndBindAllDepsForModules(routeName, modules)
  }

  public async unresolveComponent(resolvers: Resolvers, route?: Route, fromRoute?: Route) {
    const unresolvers = cloneDeep(resolvers).reverse()
    this.logResolvers(unresolvers, 'unresolveComponent')
    return this.usePool(resolvers, this.unresolveDefault, route, fromRoute, 5)
  }

  public async resolveParallel(resolvers: Resolvers, route?: Route, fromRoute?: Route) {
    const result = await Promise.all(resolvers.map(async (r) => this.resolveDefault(r as Resolvers, route, fromRoute)))
    return result.reduce<Dict>((sum, item) => {
      if (isRecordUnknown(item)) {
        const intersectedKeys = intersection(Object.keys(sum), Object.keys(item))
        if (intersectedKeys.length) {
          // eslint-disable-next-line no-console
          console.warn(`There are resolvers with same keys for route ${String(route?.name)}`)
        }
      }
      return { ...sum, ...item }
    }, {})
  }

  public isFailed(resolvers: Resolvers, route?: Route): boolean {
    return resolvers.some((r) => {
      if (Array.isArray(r)) {
        return this.isFailed(r, route)
      }

      const resolver = this.resolverManager.getResolver(r.service)
      const isFailed = typeof resolver.isFailed === 'function' ? resolver.isFailed(r.params, route) : false
      if (!isFailed) {
        this.loggerService.log('resolver', `${r.service.toString()} is not failed`, 'isFailed')
      }
      return isFailed
    })
  }

  protected async resolveDefault(
    resolvers: Resolvers | ResolverConfig,
    route?: Route,
    fromRoute?: Route,
  ): Promise<Optional<Voidable<Dict>>> {
    if (Array.isArray(resolvers)) {
      let result = {}
      for (const resolver of resolvers) {
        let resultI: Optional<Voidable<Dict>>
        if (Array.isArray(resolver)) {
          resultI = (await this.resolveDefault(resolver, route, fromRoute)) as Optional<Voidable<Dict>>
        } else {
          resultI = (await this.resolverExecutorService.resolve(resolver, route, fromRoute)) as Optional<Voidable<Dict>>
        }
        result = { ...result, ...resultI }
      }

      return result
    } else {
      try {
        return (await this.resolverExecutorService.resolve(resolvers, route, fromRoute)) as Optional<Voidable<Dict>>
      } catch (e) {
        this.loggerService.error('debug', `Failed to resolve ${(resolvers as ResolverConfig).service}`)
        this.loggerService.raw('debug', e)
        if (!(e instanceof TmApiError)) {
          throw e
        }
        return undefined
      }
    }
  }

  private async unresolveDefault(resolvers: Resolvers | ResolverConfig, route?: Route, fromRoute?: Route) {
    if (Array.isArray(resolvers)) {
      for (const resolver of resolvers) {
        try {
          if (Array.isArray(resolver)) {
            await this.resolveDefault(resolver, route, fromRoute)
          } else {
            await this.resolverExecutorService.unresolve(resolver, route, fromRoute)
          }
        } catch (e) {
          if (!Array.isArray(resolver)) {
            this.loggerService.error('debug', `Failed to resolve ${resolver.service}`)
          }
          this.loggerService.raw('debug', e)
          // needs for correct working sequence resolvers
          return
        }
      }
    } else {
      try {
        await this.resolverExecutorService.unresolve(resolvers, route, fromRoute)
      } catch (e) {
        this.loggerService.error('debug', `Failed to resolve ${resolvers.service}`)
        this.loggerService.raw('debug', e)
      }
    }
  }

  protected async usePool(
    resolvers: Resolvers,
    callback: typeof this.unresolveDefault,
    route?: Route,
    fromRoute?: Route,
    concurrency = 5,
  ) {
    return PromisePool.withConcurrency(concurrency)
      .for(resolvers)
      .handleError(async (error) => {
        throw error
      })
      .process(async (resolver) => {
        await callback.call(this, resolver, route, fromRoute)
      })
  }

  protected logResolvers(resolvers: Resolvers, subchannel: string) {
    if (this.loggerService.shouldLogByChannel('resolver', [subchannel])) {
      resolvers.forEach((resolver) => {
        if (Array.isArray(resolver)) {
          this.logResolvers(resolver, subchannel)
        } else {
          this.loggerService.log('resolver', JSON.stringify({ service: resolver.service.toString() }), subchannel)
        }
      })
    }
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  TemplatesByCategoryColumnServiceFactory,
  TemplatesColumnServiceFactory,
  TemplatesSubAccountsActiveGridable,
  SubAccountsColumnServiceFactory,
  TemplatesFormService,
  TemplatesSelectColumnServiceFactory,
  TemplatesEditCategoryFormService,
  TemplateCategoriesDeleteFormService,
  TemplateCategoriesFormService,
  TemplatesFilterServiceFactory,
  TemplatesService,
  TemplatesRepository,
  TemplateCategoriesService,
  TemplateCategoriesRepository,
  TemplatesTableExpandService,
}

export type TemplatesServices = keyof typeof SERVICES

export const TEMPLATES_SERVICE_TYPES: Record<TemplatesServices, symbol> = makeServicesTypes<TemplatesServices>(
  enumKeysToArray<TemplatesServices>(SERVICES),
)

import { injectable } from 'inversify'
import type {
  BlobResponse,
  EmptyResponse,
  Transport,
  TransportConfig,
  TransportResponse,
  UpdatedEntityResponse,
} from '@/services/transport/types'
import type { Storable } from '@/services/transport/storage/types'
import { TransformError } from '@/decorators/errorCatch'
import { TmStorageError } from '@/core/error/transport/tmStorageError'
import TmLogicError from '@/core/error/tmLogicError'

@TransformError(TmStorageError)
@injectable()
export default class BaseStorageTransport implements Transport {
  protected storage: Storable

  protected repoSeparator = '/'

  constructor(storage: Storable) {
    this.storage = storage
  }

  public getPathSeparator() {
    return this.repoSeparator
  }

  public async get<T>(key: string): Promise<TransportResponse<T>> {
    const [parent, item] = key.split(this.repoSeparator)
    const modelGroup = this.storage.getJSON(parent) || {}
    let data = modelGroup
    if (item) {
      data = modelGroup[item] || {}
    }
    return { data } as any
  }

  public async getFile(key: string): Promise<BlobResponse> {
    throw new TmStorageError('Not implemented')
  }

  public async delete(key: string): Promise<EmptyResponse> {
    const [parent, id] = key.split(this.repoSeparator)
    const modelGroup = this.storage.getJSON(parent)
    if (!id) {
      this.storage.delete(key)
    } else {
      const data = modelGroup
      if (data) {
        delete data[id]
        this.storage.setJSON(parent, data)
      }
    }
    return { data: {} }
  }

  public async post<T, R>(key: string, data?: T, config?: TransportConfig): Promise<TransportResponse<R>> {
    if (!data) {
      throw new TmStorageError('Cannot post in storage without data providen')
    }
    const [parent, id] = key.split(this.repoSeparator)
    const item = await this.get<any>(parent)
    if (!id) {
      throw new TmStorageError('Cannot post data to Storage, Id should be presented')
    }
    const dataToPost = {
      [id]: data,
    }

    const itemToSet = {
      ...item.data,
      ...dataToPost,
    }
    this.storage.setJSON(parent, itemToSet)
    return {
      data: itemToSet as R,
    }
  }

  public async put<T>(key: string, data?: T, config?: TransportConfig): Promise<UpdatedEntityResponse> {
    if (!data) {
      throw new TmStorageError('Cannot put in storage without data providen')
    }
    const [parent, id] = key.split(this.repoSeparator)

    if (!id) {
      throw new TmStorageError('Cannot put data to Storage, Id should be presented')
    }
    const item = await this.get<any>(parent)
    const dataToSet = {
      ...item.data,
      [id]: {
        ...item.data[id],
        ...data,
      },
    }
    this.storage.setJSON(parent, dataToSet)
    return {
      data: dataToSet,
    }
  }

  public async patch<T>(key: string, data?: T) {
    throw new TmLogicError('Not implemented')
  }
}

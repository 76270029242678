import { inject, injectable } from 'inversify'
import type { BaseFilterInterface, BaseFilterPartialParams } from '@/core/tables/types'
import type { RegisteredServices } from '@/core/container/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type BaseFilterForm from '@/services/tables/filters/baseFilterForm'
import type { FilterServiceInterface, FormFieldFilterType, SelectOption, TpFormData } from '@/services/forms/types'
import type {
  PaginationUrlFilterScalarValueType,
  PaginationUrlFilterSingleType,
  ParsedFilterType,
} from '@/services/tables/types'
import { isPaginationUrlFilterScalarType, isPaginationUrlFilterScalarValueType } from '@/services/tables/types'
import type { Operation } from '@/services/types'
import { isBoolFilterOperation, isFilterOperation } from '@/services/types'
import { FILTER_RESERVED_NAMES, type FiltersToCreate } from '@/services/tables/filters/types'
import { ALL_FILTER_OPTION } from '@/services/tables/filters/types'
import type BaseFilterRepository from '@/data/repositories/filters/baseFilterRepository'
import type TranslateService from '@/services/translateService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import BaseFilterModel from '@/data/models/filters/BaseFilterModel'
import FieldIdFilter from '@/data/models/filters/FieldId'
import type FilterSchemaService from '@/services/filterSchemaService'
import type { FilterBuilderService } from '@/services/forms/filterBuilderService'
import type LoggerService from '@/services/loggerService'
import { TmTableFilterError } from '@/core/error/table/tmTableFilterError'
import TmLogicError from '@/core/error/tmLogicError'
import type FilterStateRepository from '@/data/repositories/filters/filterStateRepository'
import FilterState from '@/data/models/filters/FilterState'
import type { PartialUIConfig } from '@/services/wrappers/types'
import { TmEntityNotFoundError } from '@/core/error/tmEntityNotFoundError'
import type { FilterStrategyManager } from '@/services/tables/filters/strategies/filterStrategyManager'
import type { Nullable } from '@/types'
import { coersceBoolean } from '@/utils/boolean/coersceBoolean'

@injectable()
export default abstract class BaseFilters implements BaseFilterInterface, FilterServiceInterface<FormFieldFilterType> {
  protected _id: string | null = null

  protected form: BaseFilterForm<FormFieldFilterType>

  protected current: RegisteredServices

  protected openOnAdd = true

  constructor(
    @inject(SERVICE_TYPES.FilterSchemaService) protected readonly filterSchemaService: FilterSchemaService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.FilterStrategyManager) protected readonly filterStrategyManager: FilterStrategyManager,
    /* eslint-disable tp/no-unused-injects */
    /* This is valid injections because it's an abstract class, and it will be used inside it's children */
    @inject(SERVICE_TYPES.FilterBuilderService) protected readonly filterBuilderService: FilterBuilderService,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService,
    /* eslint-enable tp/no-unused-injects */
  ) {}

  public abstract initForm(filters?: FiltersToCreate): BaseFilterForm<FormFieldFilterType>

  public setId(id: string) {
    this._id = id
  }

  public get id(): string {
    if (!this._id) {
      throw new TmLogicError(
        `No filter service id is assigned to "${this.id}" filter service. Please set it using setId() method before accessing the "key" property`,
      )
    }

    return this._id
  }

  public getServiceId(): string {
    return this.id
  }

  public addFilters(filtersToCreate: FiltersToCreate): Array<FormFieldFilterType> {
    const models: unknown[] = []
    for (const filter of filtersToCreate) {
      if (FILTER_RESERVED_NAMES.includes(filter.name)) {
        throw new TmTableFilterError(`"${filter.name}" is reserved and can't be used as filter name`)
      }

      if (filter.disabled) {
        continue
      }

      filter.model = filter.model ?? BaseFilterModel
      const repo = this.em.getRepository(filter.model)
      const availableOperations = filter.availableOperations ?? (filter.operation ? [filter.operation] : [])
      const operation = filter.operation ?? 'in'
      const firstSupportedOperation = availableOperations[0]
      const supportedOperation =
        !availableOperations.includes(operation) && firstSupportedOperation ? firstSupportedOperation : operation

      const baseData = {
        $id: this.buildFilterId(filter.name),
        id: this.buildFilterId(filter.name),
        formId: this.id,
        name: filter.name,
        label: filter.label,
        icon: filter.icon,
        availableOperations,
        relatedFieldName: filter.relatedFieldName,
        readonly: filter?.readonly ?? false,
        isDefault: filter?.isDefault,
        isHidden: filter?.isHidden ?? false,
        operation: supportedOperation,
        innerOperation: supportedOperation,
        initialOperation: supportedOperation,
        options: [],
        innerSelectedItemsValue: [],
        selectedItemsValue: [],
      }
      const data = filter.callback ? filter.callback(baseData) : baseData

      repo.insert([data], filter.model)
      models.push(repo.findEntityByIdOrFail(baseData.id))
    }
    return models as Array<FormFieldFilterType>
  }

  /**
   * Hidden filters are filters that exists but can't be visible
   * or controlled directly by user in any way
   *
   * @param {FormFieldFilterType} filter
   * @return {boolean}
   */
  public isFilterHidden(filter: FormFieldFilterType): boolean {
    return filter.isHidden
  }

  /**
   * That's the opposite to {@link isFilterHidden} method.
   * Observable filters are filters that CAN be visible in FilterBar
   * and / or controlled by user from there
   *
   * @param {FormFieldFilterType} filter
   * @return {boolean}
   */
  public isFilterObservable(filter: FormFieldFilterType): boolean {
    return !this.isFilterHidden(filter)
  }

  public makeFieldVisible(filter: FormFieldFilterType): void {
    const filterName = filter.getName()
    if (this.openOnAdd) {
      this.setOpenOnAddFlag(filter.id, true)
    }

    this.setFieldVisibility(filterName, true)
  }

  public setOpenOnAddFlag(filterId: string, flag: boolean) {
    const currentValue = this.getBaseFilterRepo().findEntityByIdOrNull(filterId)?.openOnAdd
    if (currentValue !== flag) {
      this.getBaseFilterRepo().update([
        {
          id: filterId,
          openOnAdd: flag,
        },
      ])
    }
  }

  public makeFilterReadOnly(filterName: string) {
    this.getBaseFilterRepo().update([
      {
        id: this.buildFilterId(filterName),
        readonly: true,
      },
    ])
  }

  public resetField(filterName: string) {
    this.getBaseFilterRepo().update([
      {
        id: this.buildFilterId(filterName),
        value: '',
        innerValue: '',
        innerSelectedItemsValue: [],
      },
    ])

    this.setFieldVisibility(filterName, false)
  }

  public getData(exportHidden = true) {
    const filters = this.getActiveFilters(exportHidden)

    return filters.reduce((query, filter) => {
      const value = filter.getApiValue()
      if (value === ALL_FILTER_OPTION) return query
      return {
        ...query,
        [filter.getName()]: value,
      }
    }, {})
  }

  public toQuery(exportHidden = true, filters: FormFieldFilterType[] | null = null): PaginationUrlFilterSingleType {
    if (!filters) {
      filters = this.getActiveFilters(exportHidden)
    }

    return filters.reduce<PaginationUrlFilterSingleType>((query, filter) => {
      if (filter.getApiValue() !== ALL_FILTER_OPTION) {
        return {
          ...query,
          [filter.getName()]: this.filterToQueryPart(filter),
        }
      }

      return query
    }, {})
  }

  public isDefaultState(includeHiddenFilters = true) {
    return !Object.keys(this.toQuery(includeHiddenFilters)).length
  }

  public getActiveFilters(exportHidden = true): FormFieldFilterType[] {
    const form = this.getForm()

    if (!form) {
      return [] // on start when form is not init yet
    }

    return form
      .getFields()
      .map((filter: FormFieldFilterType) => form.getField(filter.getName()))
      .filter((filter: FormFieldFilterType) => {
        if (Array.isArray(filter.value)) {
          return filter.value.length
        }
        return filter.value != null && filter.value !== ''
      })
      .filter((filter) => exportHidden || this.isFilterObservable(filter))
  }

  /**
   * @return Filters which are observable filters
   * (i.e. filters that can be visible for user or controlled by him)
   */
  public getObservableFilters(): FormFieldFilterType[] {
    return this.getFormFields().filter((filter) => this.isFilterObservable(filter))
  }

  public getNonObservableFilters(): FormFieldFilterType[] {
    return this.getFormFields().filter((filter) => !this.isFilterObservable(filter))
  }

  /**
   * @return Filters that ARE currently visible for user
   */
  public getVisibleFilters(): FormFieldFilterType[] {
    return this.getObservableFilters().filter((filter) => this.isVisibleFilter(filter.getName()))
  }

  /**
   * @return Filters that ARE NOT currently visible for user
   */
  public getInvisibleFilters(): FormFieldFilterType[] {
    return this.getObservableFilters().filter((filter) => !this.isVisibleFilter(filter.getName()))
  }

  public reset(toSkip: string[] = []) {
    const resetChangeSet = this.getForm()
      .getFields()
      .filter((filter: FormFieldFilterType) => !toSkip.includes(filter.getName()))
      .map((filter: FormFieldFilterType) => ({ id: filter.id, value: '', innerValue: '', innerSelectedItemsValue: [] }))
    this.getBaseFilterRepo().update(resetChangeSet)

    this.resetAllFilterVisibility()
  }

  public populate(payload: ParsedFilterType[], setInitial = true): void {
    this.reset()

    const fieldsState: Record<string, boolean> = {}

    const availableFilters = this.getForm().getFields()
    const shownFilters: Record<string, Nullable<Record<string, unknown>>> = {}
    availableFilters.forEach((item) => {
      const strategy = this.filterStrategyManager.getFilterStrategy(item)
      const populateData = strategy.getPopulateData(item, payload)
      if (populateData) {
        fieldsState[item.getName()] = true
        shownFilters[item.getName()] = populateData
      } else if (item.isDefault) {
        fieldsState[item.getName()] = true
        shownFilters[item.getName()] = null
      } else {
        fieldsState[item.getName()] = false
      }
    })

    this.setFieldsVisibility(fieldsState)

    this.getForm().populate(shownFilters, setInitial)
  }

  public populateFromUrl(params: PaginationUrlFilterSingleType, setInitial = false) {
    this.populate(this.parseUrlFilter(params), setInitial)
  }

  public getForm(): BaseFilterForm<FormFieldFilterType> {
    return this.form
  }

  public isFilterChanged(filterName: string) {
    return this.getForm().getField(filterName).isChangedAndApplied()
  }

  /**
   * Filters that are set by user (filters which has the non-default value)
   * @return {FormFieldFilterType[]}
   */
  public getAppliedFilters() {
    return this.getObservableFilters().filter((filter) => {
      const { value, initialValue, operation, initialOperation } = this.getBaseFilterRepo().findEntityByIdOrFail(
        filter.id,
      )

      const isOperationModified = operation !== initialOperation

      if (isBoolFilterOperation(filter.operation)) {
        const v = coersceBoolean(value, true, false)
        const iv = coersceBoolean(initialValue, true, false)
        return isOperationModified || v !== iv
      }

      return isOperationModified || (!!value && value !== initialValue)
    })
  }

  public setInnerSelectedItems(name: string, selectedItems: SelectOption[]) {
    const { id } = this.getForm().getField(name)
    this.getBaseFilterRepo().update([
      {
        id,
        innerSelectedItemsValue: selectedItems,
        isApplied: false,
      },
    ])
  }

  public setInnerValue(name: string, innerValue: unknown) {
    const { id } = this.getForm().getField(name)
    this.getBaseFilterRepo().update([
      {
        id,
        innerValue,
        isApplied: false,
      },
    ])
  }

  public setValue(name: string, innerValue: string) {
    const { id } = this.getForm().getField(name)
    this.getBaseFilterRepo().update([
      {
        id,
        innerValue,
        value: innerValue,
        isApplied: true,
      },
    ])
  }

  public setSelectedItems(name: string, selectedItemsValue: SelectOption[]) {
    const { id } = this.getForm().getField(name)
    this.getBaseFilterRepo().update([
      {
        id,
        selectedItemsValue,
        isApplied: true,
      },
    ])
  }

  public setInnerOperation(name: string, innerOperation: Operation) {
    const filter = this.getBaseFilterRepo().find(this.buildFilterId(name))
    if (filter && filter.availableOperations && !filter.availableOperations.includes(innerOperation)) {
      throw new TmTableFilterError(
        `Operation "${innerOperation}" is not available. Available operations are "${filter.availableOperations.join(
          ', ',
        )}"`,
      )
    }

    const { id } = this.getForm().getField(name)
    this.getBaseFilterRepo().update([
      {
        id,
        innerOperation,
        isApplied: false,
      },
    ])
  }

  public applyFilterValue(name: string) {
    const { id, innerValue, innerOperation, innerSelectedItemsValue } = this.getForm().getField(name)
    this.getBaseFilterRepo().update([
      {
        id,
        value: innerValue,
        selectedItemsValue: innerSelectedItemsValue,
        operation: innerOperation,
        isApplied: true,
      },
    ])
  }

  protected getFormField(name: string) {
    return this.getForm().getField(name)
  }

  public getFilterInnerValue(name: string) {
    return this.getFormField(name).innerValue
  }

  public getFilterInnerSelectedItems(name: string) {
    return this.getFormField(name).innerSelectedItemsValue
  }

  public getFilterSelectedItems(name: string): SelectOption[] {
    return this.getFormField(name).selectedItemsValue
  }

  public getFilterValue<T>(name: string): T {
    return this.getFormField(name).value as T
  }

  public isVisibleFilter(name: string) {
    return this.isDefaultFilter(name) || this.getFieldVisibility(name)
  }

  public isDefaultFilter(name: string) {
    return this.getBaseFilterRepo()
      .query()
      .where('formId', this.getForm().getFormId())
      .where('name', name)
      .where('isDefault', true)
      .exists()
  }

  public isHiddenFilter(name: string) {
    return this.getBaseFilterRepo()
      .query()
      .where('formId', this.getForm().getFormId())
      .where('name', name)
      .where('isHidden', true)
      .exists()
  }

  public isObservableFilter(name: string) {
    return this.getBaseFilterRepo()
      .query()
      .where('formId', this.getForm().getFormId())
      .where('name', name)
      .where('isHidden', false)
      .exists()
  }

  public setApplied(state: boolean) {
    const filters = this.getForm().getFields()
    for (const filter of filters) {
      this.getBaseFilterRepo().update([
        {
          id: filter.id,
          isApplied: state,
        },
      ])
    }
  }

  public toIdFilter(ids: string[]): PaginationUrlFilterScalarValueType {
    const filterId = new FieldIdFilter({ value: ids })

    return this.filterToQueryPart(filterId)
  }

  public parseUrlFilter(condition: PaginationUrlFilterSingleType): ParsedFilterType[] {
    const result: ParsedFilterType[] = []

    FILTER_RESERVED_NAMES.forEach((name) => {
      // Delete reserved field names because it cannot be filters
      delete condition[name]
    })

    const mapNumToStr = (arr: unknown[]) => {
      return arr.map((item) => {
        if (typeof item === 'number') {
          return item.toString()
        }
        return item
      })
    }

    for (const fieldName in condition) {
      const fieldCondition = condition[fieldName]

      for (const key in fieldCondition) {
        const fieldConditionItem = { [key]: fieldCondition[key] } satisfies PaginationUrlFilterSingleType

        if (isPaginationUrlFilterScalarType(fieldConditionItem)) {
          const val = fieldConditionItem[key]

          if (val === null) {
            if (isFilterOperation(key)) {
              result.push({
                name: fieldName,
                operation: key,
                value: null,
              })
            }
          } else {
            Object.entries(val).forEach(([operation, value]) => {
              if (isFilterOperation(operation)) {
                result.push({
                  name: fieldName,
                  operation,
                  value: Array.isArray(value) ? mapNumToStr(value) : value,
                  relatedField: key,
                })
              }
            })
          }
        } else if (isPaginationUrlFilterScalarValueType(fieldConditionItem)) {
          const val = fieldConditionItem[key]
          if (isFilterOperation(key)) {
            result.push({
              name: fieldName,
              operation: key,
              value: Array.isArray(val) ? mapNumToStr(val) : val,
            })
          }
        }
      }
    }

    return result
  }

  public buildForm(): Promise<void> {
    throw new TmTableFilterError('This method should not be called directly')
  }

  public submit(data: TpFormData): Promise<any> {
    throw new TmTableFilterError('This method should not be called directly')
  }

  public filterToQueryPart(filter: BaseFilterModel) {
    return this.filterStrategyManager.getFilterStrategy(filter).toQueryPart(filter)
  }

  /**
   * @param {string} fieldName The name of filter field which state we want to know
   * @return {boolean} Actual visibility state. "true" is for visible, "false" is for hidden
   */
  public getFieldVisibility(fieldName: string): boolean {
    try {
      const { visibleFields } = this.getFilterStateRepository().findEntityByIdOrFail(this.id)
      return visibleFields.includes(fieldName)
    } catch (e) {
      if (e instanceof TmEntityNotFoundError) {
        return false
      }

      throw e
    }
  }

  public getFieldOrder(): string[] {
    const state = this.getFilterStateRepository().findEntityByIdOrNull(this.id)
    return state ? state.visibleFields : []
  }

  public checkFilterValue(filter: BaseFilterModel, valueToCheck: unknown): boolean {
    if (!filter) {
      throw new TmTableFilterError('Filter not passed')
    }
    const strategy = this.filterStrategyManager.getFilterStrategy(filter)
    return strategy.checkFilterValue(filter, valueToCheck)
  }

  public checkFilterValueByName(filterName: string, valueToCheck: unknown): boolean {
    const filter = this.getFormFieldByName(filterName)
    if (!filter) {
      throw new TmTableFilterError(`Filter "${filterName}" not found`)
    }
    return this.checkFilterValue(filter, valueToCheck)
  }

  public getPartial(): PartialUIConfig<BaseFilterPartialParams> {
    return {
      name: `filter:${this.id}`,
      type: 'groupPartial',
      items: this.getForm()
        .getFields()
        .filter((field) => !field.isHidden)
        .map((field) => {
          return {
            name: field.name,
            params: {
              filterKey: this.id,
            },
          }
        }),
    }
  }

  protected buildFilterId(filterName: string) {
    return `${this.id}.${filterName}`
  }

  public setFieldsVisibility(fields: Record<string, boolean>): void {
    // Filter hidden fields
    const filteredFields = Object.fromEntries(
      Object.entries(fields).filter(([fieldName, _newState]) => !this.isHiddenFilter(fieldName)),
    )

    this.getFilterStateRepository().updateByCondition(
      (filterState) => {
        const fieldsToHide = Object.entries(filteredFields)
          .filter(([_fieldName, newState]) => !newState)
          .map(([fieldName]) => fieldName)
        const fieldsToAdd = Object.entries(filteredFields)
          .filter(([fieldName, newState]) => newState && !filterState.visibleFields.includes(fieldName))
          .map(([fieldName]) => fieldName)

        this.log(
          `Hide "${fieldsToHide.join(', ')}" filters and show "${fieldsToAdd.join(', ')}" inside "${
            this.id
          }" filter service`,
        )

        filterState.visibleFields = [
          ...filterState.visibleFields.filter((field) => !fieldsToHide.includes(field)),
          ...fieldsToAdd,
        ]

        return filterState
      },
      ({ id }) => id === this.id,
    )
  }

  /**
   * @param {string} fieldName The name of filter field which needs to be set
   * @param {boolean} newState Visibility state which needs to be set for the field. "true" is visible, "false" is hidden
   * @protected
   */
  public setFieldVisibility(fieldName: string, newState: boolean): void {
    this.setFieldsVisibility({ [fieldName]: newState })
  }

  /**
   * Reverts all filter field visibility back to defaults
   *
   * @protected
   */
  protected resetAllFilterVisibility(): void {
    this.log(`Reset filter visibility inside "${this.id}" filter service`)

    this.getFilterStateRepository().updateByCondition(
      {
        visibleFields: this.getForm()
          .getFields()
          .filter(({ isDefault }) => isDefault)
          .map(({ name }) => name),
      },
      ({ id }) => id === this.id,
    )
  }

  protected log(message: any, subchannel = 'baseFilters') {
    if (this.loggerService.shouldLogByChannel('filters', [subchannel])) {
      this.loggerService.log('filters', typeof message === 'string' ? message : JSON.stringify(message), subchannel)
    }
  }

  protected getFormFields(): FormFieldFilterType[] {
    return this.getBaseFilterRepo().query().where('formId', this.getForm().getFormId()).get() as FormFieldFilterType[]
  }

  protected getFormFieldByName(name: string): FormFieldFilterType | undefined {
    return this.getFormFields().find((field) => field.name === name)
  }

  protected getFilterStateRepository() {
    return this.em.getRepository<FilterStateRepository>(FilterState)
  }

  protected getBaseFilterRepo() {
    return this.em.getRepository<BaseFilterRepository>(BaseFilterModel)
  }
}

import { inject, injectable } from 'inversify'
import { isEmpty, cloneDeep, isEqual } from 'lodash-es'
import type {
  Column,
  ColumnsServiceInterface,
  DefaultColumn,
  DefaultColumns,
  RawDefaultColumns,
  StoredColumn,
} from '@/core/tables/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type NoColumnsServiceFactory from '@/services/tables/noColumnsServiceFactory'
import { TmTableColumnError } from '@/core/error/table/tmTableColumnError'
import Columns from '@/data/models/tables/Columns'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type ORMBaseRepository from '@/data/repositories/ormBaseRepository'
import type BaseModel from '@/data/models/BaseModel'
import type { ColumnsSettings, PaginationUrlColumnType } from '@/services/tables/types'
import type ColumnRepository from '@/data/repositories/table/columnRepository'
import type LoggerService from '@/services/loggerService'
import type { TranslationKey } from '@/services/types'

@injectable()
export default class ColumnsFromStoreFactory implements ColumnsServiceInterface {
  protected defaultColumns: DefaultColumns = {} // all available columns, this.defaultColumns

  protected columnRepository: ColumnRepository

  protected noColumnsService: NoColumnsServiceFactory

  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.NoColumnsServiceFactory) noColumnsServiceFactory: () => NoColumnsServiceFactory,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {
    this.columnRepository = this.em.getRepository<ColumnRepository>(Columns)
    this.noColumnsService = noColumnsServiceFactory()
  }

  public async reload(): Promise<void> {
    // Do nothing
  }

  public async makeColumns(tableId: string, columns?: PaginationUrlColumnType): Promise<StoredColumn[]> {
    let extended: DefaultColumns = {}
    const defaultExtended = this.extendColumns(this.defaultColumns)
    if (columns && Array.isArray(columns)) {
      extended = columns
        .filter((column) => this.hasColumn(column.name))
        .reduce((acc: DefaultColumns, column) => {
          acc[column.name] = defaultExtended[column.name]
          acc[column.name].columnOrder = column.order
          acc[column.name].visible = column.visible ?? true // We don't have a visible props in old column format, which may be stored locally in user's storage
          return acc
        }, {})
      this.log('makeColumns from url', this.getSettings().tableId)
    } else {
      extended = defaultExtended
    }
    const resolved = this.toStoreValue(extended)
    this.log('makeColumns', this.getSettings().tableId)
    this.logRaw(resolved)
    this.updateStore(resolved)
    return resolved
  }

  public recoveryColumns(tableId: string, columns?: PaginationUrlColumnType): Promise<StoredColumn[]> {
    return this.makeColumns(tableId, columns)
  }

  public async loadColumns(tableId: string): Promise<Array<Column>> {
    return []
  }

  public async saveColumns(columns: Column[], entity: string): Promise<PaginationUrlColumnType> {
    this.updateStore(
      columns
        .sort((col1, col2) => col1.columnOrder - col2.columnOrder)
        .map((column, index) => {
          return {
            name: column.columnName,
            isVisible: column.visible,
          }
        }),
    )
    return this.columnsToPaginationUrlColumn(
      columns.map((c) => ({
        ...this.getColumn(c.columnName),
        columnOrder: c.columnOrder,
        visible: c.visible,
      })),
    )
  }

  public getColumn(columnName: string): DefaultColumn {
    const column = this.defaultColumns[columnName]
    if (!column) {
      throw new TmTableColumnError(`Column ${columnName} does not exist`)
    }

    return column
  }

  public toQuery(): PaginationUrlColumnType {
    return this.getColumns().columns.map((column, index) => {
      return {
        name: column.name,
        visible: column.isVisible,
        order: index + 1,
      }
    })
  }

  public hasColumn(name: string): boolean {
    return !!this.defaultColumns[name]
  }

  public getColumnEditorColumns(): DefaultColumn[] {
    const selectedColumns = this.getColumns().columns

    const availableColumns = Object.values(this.getDefaultColumns())

    if (
      selectedColumns.length &&
      selectedColumns.length < availableColumns.length &&
      selectedColumns.every((col) => col.isVisible)
    ) {
      // We've got only visible columns from API (the old flow), so we need to properly add the hidden columns
      // So we're using the old sorting method

      return availableColumns
        .sort((columnA, columnB) => columnA.columnOrder - columnB.columnOrder)
        .map((column) => ({
          ...column,
          visible:
            selectedColumns.filter(({ isVisible }) => isVisible).findIndex(({ name }) => name === column.columnName) !==
            -1,
        }))
    }

    return availableColumns
      .sort((columnA, columnB) => {
        const aIndex = selectedColumns.findIndex((col) => col.name === columnA.columnName)
        const bIndex = selectedColumns.findIndex((col) => col.name === columnB.columnName)
        // If both columns are enabled, sort them by their order in the selected columns
        if (aIndex !== -1 && bIndex !== -1) {
          return aIndex - bIndex
        }

        // If only first of them is enabled, put it first
        if (aIndex !== -1 && bIndex === -1) {
          return -1
        }

        if (bIndex !== -1 && aIndex === -1) {
          return 1
        }

        // If both columns are disabled, sort them by their order in the default columns
        return columnA.columnOrder - columnB.columnOrder
      })
      .map((column, index) => ({
        ...column,
        columnOrder: index + 1,
        visible: selectedColumns.find(({ name }) => name === column.columnName)?.isVisible ?? false,
      }))
  }

  public getColumns(): Columns {
    return (
      this.getColumnsFromRepo() ??
      ({
        id: this.getSettings().tableId,
        columns: [],
      } as unknown as Columns)
    )
  }

  public getDefaultColumns(): DefaultColumns {
    return this.defaultColumns
  }

  public getInitColumns(): RawDefaultColumns {
    throw new TmTableColumnError('Please implement `getInitColumns`')
  }

  public getVisibleColumns(): Array<DefaultColumn> {
    return this.getColumnEditorColumns().filter((column) => column.visible)
  }

  public getInvisibleColumns(): Array<DefaultColumn> {
    return this.getColumnEditorColumns().filter((column) => !column.visible)
  }

  public async init(columns?: RawDefaultColumns) {
    if (!this.getColumnsFromRepo()) {
      this.getColumnRepository().insert([{ id: this.getSettings().tableId }])
    }
    const extended = this.extendColumns(columns || this.getInitColumns())
    this.log('new default columns', `init:${this.getSettings().tableId}`)
    this.logRaw(extended)
    this.defaultColumns = extended
  }

  public setTableId(tableId: string): void {
    this.noColumnsService.setTableId(tableId)
  }

  public getTableId(): string {
    return this.noColumnsService.getTableId()
  }

  public getSettings(): ColumnsSettings {
    return this.noColumnsService.getSettings()
  }

  public updateStore(columns: StoredColumn[]) {
    this.getColumnRepository().update([
      {
        id: this.getSettings().tableId,
        columns,
      },
    ])
  }

  public getColumnRepository() {
    return this.em.getRepository<ColumnRepository>(Columns)
  }

  public columnsToPaginationUrlColumn(columns: DefaultColumn[]): PaginationUrlColumnType {
    return this.noColumnsService.columnsToPaginationUrlColumn(columns)
  }

  public reset() {
    this.log('reset')
    this.makeColumns(this.getTableId())
  }

  public isEditable(): boolean {
    return false
  }

  public isSortable(): boolean {
    return false
  }

  public isDefaultState(ignoreOrder = true): boolean {
    const defaultColumnsRaw = Object.values(this.getDefaultColumns())
      .sort((a, b) => a.columnOrder - b.columnOrder)
      .filter((c) => c.visible)
      .map((c) => c.columnName)
    const visibleColumnsRaw = Object.values(this.getVisibleColumns())
      .sort((a, b) => a.columnOrder - b.columnOrder)
      .map((c) => c.columnName)

    return ignoreOrder
      ? isEqual(defaultColumnsRaw.toSorted(), visibleColumnsRaw.toSorted())
      : isEqual(defaultColumnsRaw, visibleColumnsRaw)
  }

  public isRowDisabled() {
    return false
  }

  protected toStoreValue(columns: DefaultColumns): StoredColumn[] {
    return Object.values(columns)
      .sort((columnA, columnB) => columnA.columnOrder - columnB.columnOrder)
      .map((column) => {
        return {
          name: column.columnName,
          isVisible: column.visible,
        }
      })
  }

  protected extendColumns(columns: RawDefaultColumns): DefaultColumns {
    if (isEmpty(columns)) {
      return {}
    }

    for (const [id, col] of Object.entries(columns)) {
      if (!col.columnName) {
        col.columnName = id
      }

      if ((col as DefaultColumn).label === undefined) {
        ;(col as DefaultColumn).label = `tableColumns.${col.columnName}` as TranslationKey
      }
    }
    return cloneDeep(columns) as DefaultColumns
  }

  protected getColumnsFromRepo() {
    return this.getColumnRepository().findEntityByIdOrNull(this.getSettings().tableId)
  }

  protected getRepository<T extends ORMBaseRepository<any>>(model: typeof BaseModel): T {
    return this.em.getRepository<T>(model)
  }

  protected log(message: string, subchannel?: string) {
    this.loggerService.log('columns', message, subchannel)
  }

  protected logRaw(raw: unknown) {
    this.loggerService.raw('columns', raw)
  }
}

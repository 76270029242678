import type { CustomErrorRulePredicateFn } from '@/core/error/transport/types'
import { EndpointsEnum } from '@/services/endpoints'

export const oauthEndpointProvidersPredicateFn: CustomErrorRulePredicateFn = (error) => {
  const endpoints: EndpointsEnum[] = [
    EndpointsEnum.googleAuth,
    EndpointsEnum.appleAuth,
    EndpointsEnum.microsoftAuth,
    //
  ]

  return endpoints.includes(error.config.url as unknown as EndpointsEnum)
}

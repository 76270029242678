import { TmTransportError } from '@/core/error/transport/tmTransportError'
import type { InternalErrorResponseBase, TmAxiosResponse } from '@/services/transport/types'
import type { TmTransportHttpErrorCode } from '@/core/error/transport/types'

export abstract class TmApiError<
  T extends Record<any, any> = TmAxiosResponse<InternalErrorResponseBase>,
> extends TmTransportError<T> {
  public name = 'TmApiError'

  protected code: TmTransportHttpErrorCode
}

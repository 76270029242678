import { injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import type SmsRepository from '@/data/repositories/domain/smsRepository'
import BaseModel from '@/data/models/BaseModel'

@DomainSettings({
  model: BaseModel,
})
@injectable()
export default class BaseSmsService<T extends SmsRepository = SmsRepository> extends DomainBaseService<T> {
  /**
   * @todo better always refresh data and remove it method from repo
   */
  public async preloadById(id: string) {
    const model = this.getDomainSettings().model as typeof BaseModel
    return this.preloaderManager.getPreloader(model).preloadById(model, id)
  }

  public async reloadById(id: string) {
    const model = this.getDomainSettings().model as typeof BaseModel
    return this.preloaderManager.getPreloader(model).reloadById(model, id)
  }
}

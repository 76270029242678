import { inject } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import AbstractPaginationServiceFactory from '@/services/tables/pagination/abstractPaginationServiceFactory'
import type { CursorPaginationUrlParams, PaginationParams, PaginationData } from '@/services/tables/pagination/types'
import { firstPageNumber, lastIdForFirstPage } from '@/services/tables/pagination/types'
import type { ModelRaw, Nullable } from '@/types'
import type { LowLevelUpdateBody } from '@/services/vuex/types'
import type CursorPaginationPage from '@/data/models/tables/CursorPaginationPage'

type CursorModelData = LowLevelUpdateBody<CursorPaginationPage>

export default class CursorPaginationServiceFactory extends AbstractPaginationServiceFactory<CursorPaginationUrlParams> {
  constructor(@inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService) {
    super(em)
  }

  public init(params?: CursorPaginationUrlParams) {
    this.repo.insertOrUpdateRaw([
      this.buildModelData({
        ...this.getDefaultModelData(),
        ...(params || {}),
      }),
    ])

    return true // The paging record is missing and should re-init the pagination
  }

  public isFirstPage(): boolean {
    const { lastId } = this.getData()
    return lastId === lastIdForFirstPage
  }

  public moveToPrevPage() {
    const { currentPage, perPage } = this.getData()
    const prevPage = currentPage - 1
    const prevId = this.getCursorPageData(prevPage)?.prevId
    const lastId = prevId || lastIdForFirstPage
    const page = currentPage - 1
    // @todo fix to always load first page after possibility to move params from url appears
    // load first page if previous CursorPage is missing (after loading copypasted url in new window etc.)
    if (lastId === lastIdForFirstPage && prevPage !== firstPageNumber) {
      this.reset()
      return this.moveToPage({
        requestParams: { currentPage: firstPageNumber, lastId: lastIdForFirstPage, perPage },
        pageKey: this.pageKey({ currentPage: firstPageNumber, lastId: lastIdForFirstPage, perPage }),
      })
    }

    return this.moveToPage({
      requestParams: { currentPage: page, lastId, perPage },
      pageKey: this.pageKey({ currentPage: page, lastId, perPage }),
    })
  }

  public moveToFirstPage() {
    return this.moveToPage(this.getFirstPage())
  }

  public moveToNextPage({ lastId }: { lastId: string }) {
    const { currentPage, perPage } = this.getData()
    const targetCursorData = this.getCursorPageData(currentPage)
    let targetLastId = lastId
    let requestParams = { currentPage: currentPage + 1, lastId, perPage }
    if (targetCursorData) {
      requestParams = { ...requestParams, lastId: targetCursorData.nextId }
      targetLastId = targetCursorData.nextId
    }

    return this.moveToPage({
      requestParams,
      pageKey: this.pageKey({ currentPage: currentPage + 1, lastId: targetLastId, perPage }),
    })
  }

  public pageRequestParams() {
    const { currentPage, lastId, perPage } = this.getData()
    return this.buildPageParams(currentPage, lastId, perPage)
  }

  public getFirstPage(): PaginationParams<CursorPaginationUrlParams> {
    return this.buildPageParams(firstPageNumber, lastIdForFirstPage, this.pageRequestParams().requestParams.perPage)
  }

  public toQuery(): CursorPaginationUrlParams {
    const { currentPage, lastId, perPage } = this.getData() || this.getDefaultModelData()
    return { currentPage, lastId, perPage }
  }

  public pageKey({ currentPage, lastId, perPage }: CursorPaginationUrlParams): string {
    return `${lastId || lastIdForFirstPage}-${perPage}`
  }

  public changePerPage(perPage: number) {
    this.setData(this.buildModelData({ currentPage: firstPageNumber, lastId: lastIdForFirstPage, perPage }))
  }

  protected buildPageParams(currentPage: number, lastId: string, perPage: number) {
    return {
      requestParams: { currentPage, lastId, perPage },
      pageKey: this.pageKey({ currentPage, lastId, perPage }),
    }
  }

  public applyUrlParams(params: CursorPaginationUrlParams, recalculate: boolean) {
    const { currentPage, perPage, lastId } = params
    this.setData({
      id: this.tableModelId,
      currentPage,
      perPage,
      lastId,
      pageKey: this.pageKey({ currentPage, lastId, perPage }),
    })
  }

  public isDefaultState() {
    const { lastId: defaultLastId, currentPage: defaultPage } = this.getDefaultModelData()
    const { lastId: currentLastId, currentPage } = this.getCurrentPage().requestParams
    return defaultLastId === currentLastId && defaultPage === currentPage
  }

  public reset() {
    this.setData(this.getDefaultModelData())
    this.resetCursor()
  }

  public setLastId(lastId: string) {
    this.setData({ id: this.tableModelId, lastId })
  }

  public resetCursor() {
    this.cursorPageRepo.deleteAllById(`${this.tableModelId}%`)
  }

  public onDataLoad(pagination: PaginationData): void {
    const { currentPage } = this.getData()
    const { prevId, nextId, perPage } = pagination
    this.setCursorPageData(
      this.buildCursorPageModelData({
        currentPage,
        lastId: prevId,
        nextId,
        perPage,
      }),
    )
  }

  public getTotal(): number {
    return this.getData().totalCount
  }

  public setTotalCount(count: number) {
    this.setData({ id: this.tableModelId, totalCount: count })
  }

  protected buildModelData({ currentPage, lastId, perPage }: CursorPaginationUrlParams) {
    return {
      id: this.tableModelId,
      currentPage,
      perPage,
      lastId,
      pageKey: this.pageKey({ currentPage, lastId, perPage }),
    }
  }

  protected getDefaultModelData() {
    return this.buildModelData({
      currentPage: firstPageNumber,
      lastId: lastIdForFirstPage,
      perPage: this.settings.perPage,
    })
  }

  protected buildCursorPageModelData({
    currentPage,
    lastId,
    nextId,
    perPage,
  }: CursorPaginationUrlParams & Partial<CursorPaginationPage>) {
    return {
      id: this.getCursorId(currentPage, perPage),
      page: currentPage,
      prevId: lastId,
      nextId,
    }
  }

  protected setCursorPageData(data: CursorModelData) {
    this.cursorPageRepo.insertOrUpdateRaw([data])
  }

  protected getCursorPageData(currentPage: number): Nullable<ModelRaw<CursorPaginationPage>> {
    const { perPage } = this.getData()
    return this.cursorPageRepo.findEntityByIdOrNull(this.getCursorId(currentPage, perPage))
  }

  protected getCursorId(currentPage: number, perPage: number): string {
    return `${this.tableModelId}-${currentPage}-${perPage}`
  }
}

export function axiosCreateError(
  message: string,
  config: Record<string, any>,
  code: string,
  request?: Record<string, unknown>,
  response?: any,
) {
  const error = new Error(message)
  return enhanceError(error, config, code, request, response)
}

function enhanceError(error, config, code, request, response) {
  error.config = config
  if (code) {
    error.code = code
  }

  error.request = request
  error.response = response
  error.isAxiosError = true

  error.toJSON = function toJSON() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: this.config,
      code: this.code,
      status: this.response && this.response.status ? this.response.status : null,
    }
  }
  return error
}

import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'

export const getEmailLookupRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.services.emailLookup',
    path: 'email-lookup',
    meta: {
      tags: ['SERVICES_BAR_LIST'],
      permission: 'emailLookup',
      modules: [AppModule.EmailLookup, AppModule.EmailLookupSession],
    },
    component: () => import('@/components/views/services/emailLookup/EmailLookup.vue'),
    redirect: { name: 'user.services.emailLookup.singleLookup' },
    children: [
      {
        name: 'user.services.emailLookup.singleLookup',
        path: '',
        component: () => import('@/components/views/services/emailLookup/EmailLookupSingle.vue'),
        props: { tab: true },
        meta: {
          tabTitle: 'pageTabTitle.user.services.emailLookup.singleLookup',
        },
      },
      {
        name: 'user.services.emailLookup.bulkLookup',
        path: 'bulk-lookup',
        component: () => import('@/components/views/services/emailLookup/EmailLookupBulk.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.emailLookup.bulkLookup',
        },
      },
      {
        name: 'user.services.emailLookup.history',
        path: 'history',
        component: () => import('@/components/views/services/emailLookup/EmailLookupHistory.vue'),
        props: { tab: true },
        meta: {
          tabTitle: 'pageTabTitle.user.services.emailLookup.history',
        },
      },
    ],
  },
]

import { dateFilterSchemaFilters } from '@/components/shared/tables/topFilter/dateFilter/types'
import type { RawSchemaFilter } from '@/services/resolvers/domain/filterSchema/schemaGenerator'

export const smsSurveyRepliesTableFilterFields = {
  question: 'questionId',
}

export const smsSurveyRepliesTableSchemaFilters: RawSchemaFilter[] = [
  ...dateFilterSchemaFilters,
  smsSurveyRepliesTableFilterFields.question,
]

import { OrmModel, BooleanField, StringField, BelongsToField, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import Country from '@/data/models/domain/Country'
import type { TmCountryCode } from '@/services/types'
import type {
  TenDlcTwilioRequestStatus,
  TenDlcTwilioNumberStatus,
} from '@/services/domain/services/regulations/tenDlcRequest/types'
import type { TenDlcTwilioCampaignsStatus } from '@/services/domain/services/regulations/tenDlcCampaigns/types'

@OrmModel(modelNames.TEN_DLC_PHONE_NUMBER)
export default class TenDlcPhoneNumber extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public number: string

  @StringField()
  public accountId: string

  @AttrField()
  public accountName: string | null

  @StringField()
  public accountEmail: string

  @AttrField()
  public campaignId: string | null

  @AttrField()
  public campaignName: string | null

  @AttrField()
  public requestId: string

  @AttrField()
  public requestStatus: string | null

  @AttrField()
  public status: TenDlcTwilioRequestStatus | null

  @AttrField()
  public countryId: TmCountryCode

  @BelongsToField(Country, 'countryId')
  public country: Country

  @AttrField()
  public campaignStatus: TenDlcTwilioCampaignsStatus | null

  @AttrField()
  public numberStatus: TenDlcTwilioNumberStatus | null

  @BooleanField()
  public canLink: boolean

  @StringField()
  public expireAt: string

  @BooleanField()
  public isCanceled: boolean
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  MessagesBulksProgressRepository,
  MessagesBulksProgressService,
}

export type MessagesBulksProgressServices = keyof typeof SERVICES

export const MESSAGES_BULKS_PROGRESS_SERVICES_TYPES: Record<MessagesBulksProgressServices, symbol> =
  makeServicesTypes<MessagesBulksProgressServices>(enumKeysToArray<MessagesBulksProgressServices>(SERVICES))

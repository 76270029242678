import { inject, injectable } from 'inversify'
import type { HttpInterceptor, InternalErrorResponse, HttpInterceptorResponseError } from '@/services/transport/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type AuthService from '@/services/auth/authService'
import type LoggedInStatusService from '@/services/auth/loggedInStatusService'
import type { TmApiClientError } from '@/core/error/transport/tmApiClientError'
import type RouterService from '@/services/route/routerService'
import { PageAvailableFor } from '@/types'
import { TmApiUserBlockedError } from '@/core/error/transport/tmApiUserBlockedError'
import type ReopenAccountService from '@/services/signUp/reopenAccountService'
import { TmApiAuthentificationError } from '@/core/error/transport/tmApiAuthentificationError'
import type NotificationService from '@/services/notificationService'
import type { AuthLoaderService } from '@/services/authLoaderService'
import type RestrictionPagesRouterService from '@/services/auth/restrictionPagesRouterService'
import { TmAuthAccountClosedError } from '@/core/error/transport/auth/TmAuthAccountClosedError'
import { TmAuthAccountForBusinessUsersError } from '@/core/error/transport/auth/TmAuthAccountForBusinessUsersError'
import { TmDummyError } from '@/core/error/tmDummyError'
import { TmAuthAccountSubAccountClosedError } from '@/core/error/transport/auth/TmAuthAccountSubAccountClosedError'
import type { Translatable } from '@/services/types'

@injectable()
export default class AuthInterceptor implements HttpInterceptor<any, InternalErrorResponse> {
  constructor(
    @inject(SERVICE_TYPES.AuthService) protected readonly authService: AuthService,
    @inject(SERVICE_TYPES.LoggedInStatusService) protected readonly loggedInStatusService: LoggedInStatusService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.ReopenAccountService) protected readonly reopenAccountService: ReopenAccountService,
    @inject(SERVICE_TYPES.NotificationService) protected readonly notificationService: NotificationService,
    @inject(SERVICE_TYPES.AuthLoaderService) protected readonly authLoaderService: AuthLoaderService,
    @inject(SERVICE_TYPES.RestrictionPagesRouterService)
    protected readonly restrictionPagesRouterService: RestrictionPagesRouterService,
    @inject(SERVICE_TYPES.TranslateService) protected readonly translateService: Translatable,
  ) {}

  public async responseError(error: HttpInterceptorResponseError<InternalErrorResponse> | TmApiClientError) {
    if (error instanceof TmAuthAccountClosedError) {
      await this.reopenAccountService.storeReopenAccountData({ loginOrEmail: error.getLoginOrEmail()! })
      await this.restrictionPagesRouterService.goToReopenAccountRoute()
      return Promise.reject(new TmDummyError())
    }

    if (error instanceof TmAuthAccountForBusinessUsersError) {
      await this.restrictionPagesRouterService.goToLoginPersonalRoute()
      return Promise.reject(new TmDummyError())
    }

    if (error instanceof TmAuthAccountSubAccountClosedError || error instanceof TmApiUserBlockedError) {
      await this.restrictionPagesRouterService.goToAccountSuspendedRoute()
      return Promise.reject(new TmDummyError())
    }

    const currentRoute = this.routerService.getCurrentRoute()
    const pageAvailableFor = currentRoute.meta.pageAvailableFor
    if (
      error instanceof TmApiAuthentificationError &&
      (pageAvailableFor === undefined || pageAvailableFor === PageAvailableFor.authenticated)
    ) {
      // in order to skip error on logout from LogoutResolver
      if (currentRoute.name === undefined || currentRoute.name !== 'auth.logout') {
        this.authService.saveCurrentRouteForRedirectAfterLogin()
      }

      if (this.loggedInStatusService.isUserLoggedIn()) {
        await this.authService.logoutRedirect()
        this.notificationService.notifyError(this.translateService.t('systemExitResolver.successNotification'))
      }
      return Promise.reject(error)
    }

    if (error instanceof TmApiAuthentificationError && this.routerService.getCurrentRoute().name === 'auth.login') {
      await this.authLoaderService.hideLoader()
      this.notificationService.contactSupport()
      await this.authService.toInitialRoute()
    }

    return Promise.reject(error)
  }
}

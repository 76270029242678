import { OrmModel } from 'vuex-orm-decorators'
import type { FormFieldFilterInterface } from '@/services/forms/types'
import BaseFilterModel from '@/data/models/filters/BaseFilterModel'
import { modelNames } from '@/data/models/types'

@OrmModel(modelNames.FIELD_ID_FILTER, modelNames.BASE_FILTER, {
  BASE_FILTER: BaseFilterModel,
  FIELD_ID_FILTER: FieldIdFilter,
})
export default class FieldIdFilter extends BaseFilterModel implements FormFieldFilterInterface<unknown> {
  public getRelationField(): string {
    return ''
  }
}

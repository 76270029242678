import type {
  SchemaValueType,
  SortSchemaValueType,
  FilterSchemaType,
  FilterSchemaOperatorConstraintsType,
  FilterSchemaOperatorConstraintsValueType,
} from '@/services/types'

type RawSchemaSort =
  | string
  | {
      name: string
      fields?: RawSchemaSort[]
    }

type RawSchemaFilterConstraint = { type: 'length' } | { type: 'choice'; value: string[] }
type RawSchemaFilterObj = {
  name: string
  constraints: RawSchemaFilterConstraint
}
export type RawSchemaFilter = string | RawSchemaFilterObj

type RawSchema = {
  name: string
  sort?: RawSchemaSort[]
  /** @deprecated Not used anymore, will be removed in CAR-8943 */
  filter?: RawSchemaFilter[]
}

const makeSchemaFilterConstraint = (constraint: RawSchemaFilterConstraint): FilterSchemaOperatorConstraintsType => {
  let options: FilterSchemaOperatorConstraintsValueType[]
  switch (constraint.type) {
    case 'choice':
      options = [{ name: 'choices', value: constraint.value }]
      break
    default:
      options = []
  }
  return {
    type: constraint.type,
    options,
  }
}

export const makeSchemaFilter = (filter: RawSchemaFilter[]): FilterSchemaType[] =>
  filter.map((rawItem) => {
    const isString = typeof rawItem === 'string'
    const itemName = isString ? rawItem : rawItem.name
    const constraints = isString ? [] : [makeSchemaFilterConstraint(rawItem.constraints)]
    return {
      name: itemName,
      type: 'field',
      fields: null,
      operators: [
        {
          name: 'eq',
          constraints,
        },
        {
          name: 'in',
          constraints,
        },
        {
          name: 'notIn',
          constraints,
        },
        {
          name: 'gt',
          constraints,
        },
        {
          name: 'gte',
          constraints,
        },
        {
          name: 'lt',
          constraints,
        },
        {
          name: 'lte',
          constraints,
        },
        {
          name: 'anyOf',
          constraints,
        },
        {
          name: 'allOf',
          constraints,
        },
        {
          name: 'noneOf',
          constraints,
        },
        {
          name: 'isNull',
          constraints,
        },
      ],
    }
  })

export const makeSchemaSort = (sort: RawSchemaSort[]): SortSchemaValueType[] =>
  sort.map((rawItem) => {
    const item = typeof rawItem === 'string' ? { name: rawItem } : rawItem
    return item.fields
      ? {
          name: item.name,
          type: 'relation',
          values: null,
          fields: makeSchemaSort(item.fields),
        }
      : {
          name: item.name,
          type: 'field',
          values: ['asc', 'desc'],
          fields: null,
        }
  })

export const makeSchema = ({ name, sort, filter }: RawSchema): SchemaValueType => ({
  name,
  schema: {
    filter: filter ? makeSchemaFilter(filter) : [],
    sort: sort ? makeSchemaSort(sort) : [],
    searchFields: [],
    groupBy: [],
  },
})

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  CarrierLookupSingleFormService,
  CarrierLookupService,
  CarrierLookupRepository,
  CarrierLookupBulkColumnService,
  CarrierLookupHistoryColumnServiceFactory,
}

export type CarrierLookupServices = keyof typeof SERVICES

export const CARRIER_LOOKUP_SERVICE_TYPES: Record<CarrierLookupServices, symbol> =
  makeServicesTypes<CarrierLookupServices>(enumKeysToArray<CarrierLookupServices>(SERVICES))

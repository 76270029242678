import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'

export const userAccountsSubAccountsRoute: RouteRecordRaw = {
  name: 'user.accounts.subAccounts',
  path: 'sub-accounts',
  meta: {
    tags: ['ACCOUNTS_BAR_LIST'],
    permission: 'subaccountSettings',
    modules: [AppModule.SubAccounts, AppModule.SubAccountsForm, AppModule.TeamWorkflow],
    accessControlGroups: ['SubAccountsWorkflowStrategy', 'UsersWorkflowNotUserRoleStrategy'],
  },
  component: () => import('@/components/views/accounts/subAccounts/SubAccountsPage.vue'),
  redirect: { name: 'user.accounts.subAccounts.active' },
  children: [
    {
      name: 'user.accounts.subAccounts.active',
      path: 'active',
      meta: {
        modules: [AppModule.AccountSettings],
        tabTitle: 'pageTabTitle.user.accounts.subAccounts.active',
      },
      component: () => import('@/components/views/accounts/subAccounts/SubAccountsActive.vue'),
    },
    {
      name: 'user.accounts.subAccounts.closed',
      path: 'closed',
      meta: {
        modules: [AppModule.AccountSettings],
        tabTitle: 'pageTabTitle.user.accounts.subAccounts.closed',
      },
      component: () => import('@/components/views/accounts/subAccounts/SubAccountsClosed.vue'),
    },
    {
      name: 'user.accounts.subAccounts.import',
      path: 'import',
      component: () => import('@/components/views/accounts/subAccounts/import/SubAccountsImport.vue'),
      meta: {
        modules: [AppModule.SubAccountsImportList],
        tabTitle: 'pageTabTitle.user.accounts.subAccounts.import',
      },
    },
  ],
}

export const userAccountsUsersRoute: RouteRecordRaw = {
  name: 'user.accounts.users',
  path: 'users',
  meta: {
    tags: ['ACCOUNTS_BAR_LIST'],
    permission: 'subaccountSettings',
    modules: [AppModule.SubAccounts, AppModule.TeamWorkflow],
    accessControlGroups: ['UserWorkflowStrategy', 'UsersWorkflowNotUserRoleStrategy'],
  },
  component: () => import('@/components/views/accounts/usersWorkflow/UsersWorkflowPage.vue'),
  redirect: { name: 'user.accounts.users.default' },
  children: [
    {
      name: 'user.accounts.users.default',
      path: '',
      meta: {
        tabTitle: 'pageTitle.user.accounts.userWorkflow.title',
        resolvers: [
          {
            service: 'UsersWorkflowEmptyPageResolverService',
          },
        ],
      },
      component: () => import('@/components/views/accounts/UsersWorkflow.vue'),
    },
    {
      name: 'user.accounts.users.active',
      path: 'active',
      meta: {
        tabTitle: 'pageTitle.user.accounts.userWorkflow.active.title',
        modules: [AppModule.AccountSettings],
        resolvers: [
          {
            service: 'UsersWorkflowTabResolverService',
          },
        ],
      },
      component: () => import('@/components/views/accounts/usersWorkflow/UsersWorkflowActive.vue'),
    },
    {
      name: 'user.accounts.users.closed',
      path: 'closed',
      meta: {
        tabTitle: 'pageTitle.user.accounts.userWorkflow.closed.title',
        modules: [AppModule.AccountSettings],
        resolvers: [
          {
            service: 'UsersWorkflowTabResolverService',
          },
        ],
      },
      component: () => import('@/components/views/accounts/usersWorkflow/UsersWorkflowClosed.vue'),
    },
    // @todo add AccessControlStrategy for check quantity invited users
    {
      name: 'user.accounts.users.invited',
      path: 'invited',
      meta: {
        tabTitle: 'pageTitle.user.accounts.userWorkflow.invited.title',
        modules: [AppModule.AccountSettings],
        resolvers: [
          {
            service: 'UserWorkflowInvitedTabResolverService',
          },
        ],
      },
      component: () => import('@/components/views/accounts/usersWorkflow/UsersWorkflowInvited.vue'),
    },
  ],
}

export const userAccountsDataRetentionRoute: RouteRecordRaw = {
  name: 'user.accounts.dataRetention',
  path: 'data-retention',
  component: () => import('@/components/views/accounts/AccountDataRetention.vue'),
  meta: {
    tabTitle: 'pageTitle.user.accounts.dataRetention',
    tags: ['ACCOUNTS_BAR_LIST'],
    resolvers: [
      {
        service: 'MyDataFacetResolver',
      },
    ],
    permission: 'messagingData',
    modules: [AppModule.MyData],
    accessControlGroups: ['UsersWorkflowNotUserRoleStrategy'],
  },
}

export const userAccountsMyDataRoute: RouteRecordRaw = {
  name: 'user.accounts.myData',
  path: 'my-data',
  redirect: { name: 'user.accounts.dataRetention' },
}

import { inject, injectable } from 'inversify'
import type BaseModel from '@/data/models/BaseModel'
import type { Faceter } from '@/services/facets/types'
import { SERVICE_TYPES, type RegisteredServices } from '@/core/container/types'
import type LoggerService from '@/services/loggerService'
import type { IServiceManager } from '@/core/middlewares/types'
import { REFLECT_METADATA } from '@/decorators/types'
import type { Service } from '@/config/types'
import type { GetLocator } from '@/core/container/container'
import type FaceterService from '@/services/facets/faceterService'

@injectable()
export default class FacetManager implements IServiceManager {
  protected faceters: Record<string, RegisteredServices | FaceterService> = {}

  constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.FaceterService) protected readonly faceterFactory: () => FaceterService,
    @inject('GetLocator') private readonly get: GetLocator,
  ) {}

  public getFaceter<T extends Faceter>(model: typeof BaseModel): T {
    if (!this.faceters[model.entity]) {
      this.loggerService.log('facet', `Use default faceter for ${model.entity}`, 'getFaceter')
      this.faceters[model.entity] = this.faceterFactory()
    }

    const faceter = this.faceters[model.entity]

    const service = typeof faceter === 'string' ? (this.get(faceter) as T) : (faceter as unknown as T)

    service.setSettings({ entityName: model.entity })

    return service
  }

  public addService(service: Service) {
    const settings = Reflect.getMetadata(REFLECT_METADATA.FaceterSettings, service.bindingValue)
    settings.models.forEach((model) => {
      this.loggerService.log('facet', `Add faceter for model ${model.entity}`)
      this.loggerService.raw('facet', service.id)
      this.faceters[model.entity] = service.id as RegisteredServices
    })
  }
}

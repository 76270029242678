export const tmAutoSizeIgnoreClass = 'ignore-hidden'
export const tmAutoSizeVisibleClass = 'visible-item'
export const tmAutoSizeHiddenClass = 'hidden-item'
export const tmAutoSizePanelParentClass = 'tm-auto-size-panel-parent'

// Sort with priority
export const prepareData = (arr: HTMLElement[]): HTMLElement[] =>
  arr.sort((a, b) => {
    if (a.classList.contains(tmAutoSizeIgnoreClass) || b.classList.contains(tmAutoSizeIgnoreClass)) {
      if (a.classList.contains(tmAutoSizeIgnoreClass) && b.classList.contains(tmAutoSizeIgnoreClass)) {
        return -1
      }

      if (a.classList.contains(tmAutoSizeIgnoreClass)) {
        return -1
      }

      return 1
    }

    if (a.dataset.autoSizePriority || b.dataset.autoSizePriority) {
      if (!a.dataset.autoSizePriority) {
        return 1
      }

      if (!b.dataset.autoSizePriority) {
        return -1
      }

      return +a.dataset.autoSizePriority > +b.dataset.autoSizePriority ? -1 : 1
    }

    return 0
  })

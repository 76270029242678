import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  CarrierLookupUploadFormService,
}

export type CarrierLookupImportServices = keyof typeof SERVICES

export const CARRIER_LOOKUP_IMPORT_SERVICE_TYPES: Record<CarrierLookupImportServices, symbol> =
  makeServicesTypes<CarrierLookupImportServices>(enumKeysToArray<CarrierLookupImportServices>(SERVICES))

import { AttrField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { NumbersObjectNullable } from '@/types'

@OrmModel(modelNames.FACETS)
export default class Facet extends BaseModel {
  @StringField()
  public id: string

  @AttrField({})
  public payload: NumbersObjectNullable
}

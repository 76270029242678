import { OrmModel, StringField, NumberField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'

@OrmModel(modelNames.TICKET_COUNT)
export default class TicketCount extends BaseModel {
  @StringField()
  public id: string

  @NumberField()
  public count: number
}

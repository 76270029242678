import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  AutomationRuleLogRepository,
  AutomationRuleLogShortRepository,
  AutomationRuleLogService,
  AutomationRuleLogShortService,
}

export type AutomationRulesLogServices = keyof typeof SERVICES

export const AUTOMATION_RULES_LOG_SERVICE_TYPES: Record<AutomationRulesLogServices, symbol> =
  makeServicesTypes<AutomationRulesLogServices>(enumKeysToArray<AutomationRulesLogServices>(SERVICES))

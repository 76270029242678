import { inject, injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import UserNumbers from '@/data/models/domain/UserNumbers'
import { TmIncorrectContractError } from '@/core/error/tmIncorrectContractError'
import type { ModelRaw } from '@/types'
import type { RawUserNumbers } from '@/services/domain/user/types'
import type { GetUserNumbersOptions, RawByocNumbers } from '@/services/domain/byoc/types'
import { ByocProvider } from '@/data/models/domain/services/byoc/types'
import type SenderSource from '@/data/models/domain/SenderSource'
import { SERVICE_TYPES } from '@/core/container/types'
import type ByocHelperService from '@/services/domain/byoc/byocHelperService'
import type SerializerService from '@/services/serializerService'
import type { HttpService } from '@/services/transport/httpService'
import type { AbstractEndpointsInterface } from '@/services/endpointsService'
import type LoggerService from '@/services/loggerService'
import type FilterQueryService from '@/services/filterQueryService'

@RepoSettings<Endpoint>({
  model: UserNumbers,
})
@injectable()
export default class UserNumbersRepository extends OrmApiRepository<UserNumbers> {
  constructor(
    @inject(SERVICE_TYPES.SerializerService) protected readonly serializerService: SerializerService,
    @inject(SERVICE_TYPES.Api) protected readonly api: HttpService,
    @inject(SERVICE_TYPES.EndpointsService) protected readonly endpointsService: AbstractEndpointsInterface,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.FilterQueryService) protected readonly filterQueryService: FilterQueryService,
    @inject(SERVICE_TYPES.ByocHelperService) protected readonly byocHelperService: ByocHelperService,
  ) {
    super(serializerService, api, endpointsService, loggerService, filterQueryService)
  }

  public async getUserNumbers({ userId, enableByocSenderId }: GetUserNumbersOptions) {
    const apiSource = this.getApiSource()
    const path = this.endpointsService.getPath('userNumbers')
    const params = { userId }
    const result = await apiSource.get<RawUserNumbers>(path, { params })
    if (!result.data) {
      throw new TmIncorrectContractError('Response data expected')
    }

    const userNumbers = this.parseUserNumbers(result.data, enableByocSenderId)
    await this.create([userNumbers])
    return userNumbers
  }

  public findUserNumbers() {
    return this.findEntityByIdOrNull('1')
  }

  public findByocSenderSources() {
    const userNumbers = this.findUserNumbers()
    if (!userNumbers) return []
    return Object.values(userNumbers.byoc).reduce<SenderSource[]>((sum, list) => [...sum, ...list], [])
  }

  public getByocSenderSourceByPhone(phone: string) {
    const senderSources = this.findByocSenderSources()
    return senderSources.find((senderSource) => senderSource.phone === phone)
  }

  /*
  public findSenderSources() {
    const userNumbers = this.findUserNumbers()

    const byocNumbers = Object.values(userNumbers.byoc).reduce<SenderSource[]>((sum, list) => [...sum, ...list], [])
    return [
      ...userNumbers.dedicated,
      ...userNumbers.shared,
      ...userNumbers.userNumbers,
      ...userNumbers.senderIds,
      ...byocNumbers,
    ]
  }

  public findUserNumberByPhone(phone: string) {
    const senderSources = this.findSenderSources()
    return senderSources.find((source) => source.phone === phone)
  }

   */

  public byocParseNumbers(
    rawNumbers: RawByocNumbers,
    enableByocSenderId?: boolean,
  ): Record<ByocProvider, SenderSource[]> {
    const providerFields: Record<ByocProvider, keyof RawByocNumbers> = {
      [ByocProvider.TWILIO]: 'userCarrierTwilio',
      [ByocProvider.VONAGE]: 'userCarrierVonage',
      [ByocProvider.SINCH]: 'userCarrierSinch',
      [ByocProvider.BANDWIDTH]: 'userCarrierBandwidth',
      [ByocProvider.INFOBIP]: 'userCarrierInfobip',
      [ByocProvider.MESSAGEBIRD]: 'userCarrierMessageBird',
      [ByocProvider.AWS]: 'userCarrierAws',
      [ByocProvider.TELNYX]: 'userCarrierTelnyx',
    }

    return Object.keys(providerFields).reduce(
      (acc, provider) => {
        const providerField = providerFields[provider]
        if (enableByocSenderId && this.byocHelperService.doesProviderSupportSenderId(provider as ByocProvider)) {
          const senderIdField = `${providerField}SenderId` as keyof RawByocNumbers
          acc[provider] = [...(rawNumbers[providerField] || []), ...(rawNumbers[senderIdField] || [])]
        } else {
          acc[provider] = rawNumbers[providerField] || []
        }

        return acc
      },
      {} as Record<ByocProvider, SenderSource[]>,
    )
  }

  private parseUserNumbers(rawUserNumbers: RawUserNumbers, enableByocSenderId?: boolean): ModelRaw<UserNumbers> {
    const { dedicated, shared, userNumbers, senderIds } = rawUserNumbers

    return {
      id: '1',
      dedicated: dedicated || [],
      shared: shared || [],
      userNumbers: userNumbers || [],
      senderIds: senderIds || [],
      byoc: this.byocParseNumbers(rawUserNumbers, enableByocSenderId),
    }
  }
}

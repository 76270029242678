import AbstractPaginationServiceFactory from '@/services/tables/pagination/abstractPaginationServiceFactory'
import {
  type TokenPaginationUrlParams,
  firstPageNumber,
  lastTokenForFirstPage,
} from '@/services/tables/pagination/types'
import type { LowLevelUpdateBody } from '@/services/vuex/types'
import type Pagination from '@/data/models/tables/Pagination'

export default class TokenPaginationServiceFactory extends AbstractPaginationServiceFactory<TokenPaginationUrlParams> {
  public init(params?: TokenPaginationUrlParams) {
    const defaultModelData = this.getDefaultModelData()
    const modelData = this.buildModelData({
      ...defaultModelData,
      ...params,
      perPage: params?.perPage || defaultModelData.perPage,
    })

    this.repo.insertOrUpdateRaw([modelData])

    return true // The paging record is missing and should re-init the pagination
  }

  public isFirstPage(): boolean {
    const { page } = this.getData()
    return page === 1
  }

  public moveToPrevPage() {
    const data = this.getData()
    const page = data.page - 1
    const tokensList = this.getTokensList()
    const nextId = tokensList[page]
    const pageKey = this.pageKey({ page, perPage: data.perPage })

    const requestParams = {
      lastId: data.lastId,
      nextId,
      perPage: data.perPage,
      page,
      currentPage: page,
      pageKey,
    }

    return this.moveToPage({ requestParams, pageKey })
  }

  public moveToFirstPage() {
    return this.moveToPage(this.getFirstPage())
  }

  public moveToNextPage(nextPageData) {
    const data = this.getData()
    const page = data.page + 1
    const tokensList = this.addToken(nextPageData.lastId)
    const nextId = tokensList[data.page]
    const pageKey = this.pageKey({ page, perPage: data.perPage })

    const requestParams = {
      lastId: tokensList.join('-'),
      nextId,
      perPage: data.perPage,
      page,
      currentPage: page,
      pageKey,
    }

    return this.moveToPage({ requestParams, pageKey })
  }

  public pageRequestParams() {
    const { page, nextId, perPage } = this.getData()
    return this.buildPageParams(page, nextId, perPage)
  }

  public getFirstPage() {
    const {
      requestParams: { perPage },
    } = this.pageRequestParams()
    return this.buildPageParams(1, lastTokenForFirstPage, perPage)
  }

  public toQuery(): TokenPaginationUrlParams {
    const { nextId, perPage } = this.getData() || this.getDefaultModelData()
    return { nextId, perPage }
  }

  public pageKey(data) {
    const { page, perPage } = data
    return `${page}-${perPage}`
  }

  public changePerPage(perPage: number) {
    const paginationParams = {
      currentPage: firstPageNumber,
      lastId: lastTokenForFirstPage,
      perPage,
    }
    const modelData = this.buildModelData(paginationParams)
    this.setData(modelData)
  }

  public setData(pagination: LowLevelUpdateBody<Pagination>) {
    const data = this.getData()
    const lastId = this.getNewLastId(pagination)
    const newData = {
      ...data,
      perPage: pagination.perPage,
      pageKey: pagination.pageKey,
      nextId: pagination.nextId,
      lastId,
      totalCount: pagination.totalCount,
    }
    super.setData(newData)
  }

  protected getNewLastId(pagination: LowLevelUpdateBody<Pagination>) {
    const data = this.getData()
    if (pagination.perPage && data.perPage !== pagination.perPage) return ''
    const tokensList = this.addToken(pagination.nextId || '')
    return tokensList.join('-')
  }

  protected buildPageParams(page: number, nextId: string, perPage: number) {
    const requestParams = { nextId, perPage }
    const pageKey = this.pageKey({ page, perPage })
    return { requestParams, pageKey }
  }

  public applyUrlParams(params: TokenPaginationUrlParams) {
    const modelData = {
      id: this.tableModelId,
      ...params,
    }
    this.setData(modelData)
  }

  public isDefaultState() {
    return false
  }

  public onDataLoad(): void {}

  public getTotal(): number {
    return 0
  }

  protected buildModelData(paginationParams) {
    const data = this.getData()
    return {
      id: this.tableModelId,
      ...paginationParams,
      lastId: data?.lastId ?? '',
    }
  }

  protected getDefaultModelData() {
    const rawModelData = {
      lastId: lastTokenForFirstPage,
      nextId: lastTokenForFirstPage,
      page: 1,
      currentPage: 1,
      perPage: this.settings.perPage,
      totalCount: 0,
    }
    return this.buildModelData(rawModelData)
  }

  protected getTokensList() {
    const data = this.getData()
    return data.lastId.split('-')
  }

  protected addToken(newToken: string) {
    const tokensList = this.getTokensList()
    if (!tokensList.includes(newToken) && newToken !== '0') {
      tokensList.push(newToken)
    }
    return tokensList
  }

  public setTotalCount(_count: number): void {}
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type TranslateService from '@/services/translateService'
import type { TitlerInterface } from '@/services/route/titlers/types'
import type { Route } from '@/services/route/types'
import type { TranslationKey } from '@/services/types'
import type { Dict } from '@/types'

@injectable()
export default class DefaultTitlerService implements TitlerInterface {
  constructor(@inject(SERVICE_TYPES.TranslateService) protected readonly translateService: TranslateService) {}

  public baseTitle(isWithColon: boolean, rawTitle: string) {
    if (isWithColon) {
      return this.translateService.t('pageTabTitle.withColon', { title: rawTitle })
    }
    return this.translateService.t('pageTabTitle.withoutColon')
  }

  public getTitle(to: Route, params: Record<string, string>): string {
    const tabTitleTranslate = to.meta?.tabTitle as TranslationKey
    const isWithColon = this.translateService.exists(tabTitleTranslate)
    const rawTitle = isWithColon ? this.getPageTitle(tabTitleTranslate, params) : ''
    return this.baseTitle(isWithColon, rawTitle)
  }

  public getPageTitle(routeName: TranslationKey, params?: Dict<string>) {
    return this.translateService.t(routeName, params)
  }
}

import type { DomainServiceSettings } from '@/decorators/types'
import { REFLECT_METADATA } from '@/decorators/types'
import type { BaseSearcherSettings } from '@/services/search/types'

export function DomainSettings(settings: DomainServiceSettings) {
  return function _<T extends { new (...args: any[]): Record<string, any> }>(constructor: T) {
    Reflect.defineMetadata(REFLECT_METADATA.DomainSettings, settings, constructor)
    return class extends constructor {
      public getDomainSettings() {
        return settings
      }
    }
  }
}

export function Searchable(searchSettings: BaseSearcherSettings) {
  return function _<T extends { new (...args: any[]): Record<string, any> }>(constructor: T) {
    return class extends constructor {
      protected emptyState: boolean = false

      public getSearchSettings(): BaseSearcherSettings {
        return searchSettings
      }

      public search(value: string, queryValue?: any): void {
        const repo = constructor.prototype.getDomainRepository.call(this)
        const searchFields = this.getSearchSettings().supportedSearchFields
        const queryField = this.getSearchSettings().supportedQueryField

        const filteredItemsQueryBuilder = repo.query()
        searchFields.forEach((field: string): void => {
          filteredItemsQueryBuilder.where(
            (item) => !!item[field] && item[field]?.toLowerCase().includes(value.toLowerCase()),
          )
        })
        if (queryField && queryValue) {
          filteredItemsQueryBuilder.where((item) => item[queryField] === queryValue)
        }
        const filteredItems = filteredItemsQueryBuilder.all().map((item) => {
          item.isVisible = true
          return item
        })
        this.emptyState = filteredItems.length === 0

        const hiddenItemsQueryBuilder = repo.query()
        searchFields.forEach((field: string): void => {
          hiddenItemsQueryBuilder.where(
            (item) => !!item[field] && !item[field]?.toLowerCase().includes(value.toLowerCase()),
          )
        })
        if (queryField && queryValue) {
          hiddenItemsQueryBuilder.where((item) => item[queryField] === queryValue)
        }
        const hiddenItems = hiddenItemsQueryBuilder.all().map((item) => {
          item.isVisible = false
          return item
        })

        repo.update(hiddenItems)
        repo.update(filteredItems)
      }

      public isEmptyState(): boolean {
        return this.emptyState
      }

      public setIsEmptyState(value: boolean): void {
        this.emptyState = value
      }
    }
  }
}

import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import type UserMetadataRepository from '@/data/repositories/domain/userMetadataRepository'
import UserMetadata from '@/data/models/domain/UserMetadata'

@DomainSettings({
  model: UserMetadata,
})
@injectable()
export default class UserMetadataService extends DomainBaseService<UserMetadataRepository> {
  public fetchMetadata(): Promise<UserMetadata> {
    return this.getDomainRepository().fetchUserMetadata()
  }

  public findMetadata(): UserMetadata | null {
    return this.getDomainRepository().findUserMetadata()
  }

  public async getMetadata(): Promise<UserMetadata> {
    return this.getDomainRepository().getUserMetadata()
  }

  public saveOpenAIConsent(value: boolean | null) {
    return this.getDomainRepository().saveOpenAIConsent(value)
  }
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { Endpoint } from '@/services/endpoints'
import BaseWrapper from '@/data/models/wrappers/BaseWrapper'

@RepoSettings<Endpoint>({
  model: BaseWrapper,
})
@injectable()
export default class BaseWrapperRepository extends OrmApiRepository<BaseWrapper> {}

import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

export enum WEB_WIDGETS_SERVICES {
  // Domain services
  WebWidgetDetailsService,

  // Repositories
  WebWidgetDetailsRepository,

  WebWidgetsService,
  WebWidgetSettingCastService,
  WebWidgetsRepository,
  WebWidgetsColumnFactoryService,
  WebWidgetFormService,
  CopyWebWidgetFormService,
  SendWebWidgetInstructionsModalFormService,
  WebWidgetDetailsFormService,
  WebWidgetDetailsAppearanceBuilderService,
  WebWidgetDetailsTargetingBuilderService,
  WebWidgetDetailsChannelsBuilderService,
}

export type WebWidgetsServices = keyof typeof WEB_WIDGETS_SERVICES

export const WEB_WIDGET_TYPES: Record<WebWidgetsServices, symbol> = makeServicesTypes<WebWidgetsServices>(
  enumKeysToArray<WebWidgetsServices>(WEB_WIDGETS_SERVICES),
)

import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import AvailableToBuyNumbers from '@/data/models/domain/AvailableToBuyNumbers'
import type AvailableToBuyNumbersRepository from '@/data/repositories/domain/availableToBuyNumbersRepository'
import { type TmCountryCode, USRegulationsCountries } from '@/services/types'
import type { GetAvailableToBuyNumbersForCountry } from '@/services/domain/phoneNumbers/types'

@DomainSettings({
  model: AvailableToBuyNumbers,
})
@injectable()
export default class AvailableToBuyNumbersService extends DomainBaseService<AvailableToBuyNumbersRepository> {
  public async getAvailableToBuyNumbersForCountry(
    countryId: TmCountryCode,
    options: GetAvailableToBuyNumbersForCountry = {},
  ) {
    if (!options.tollfree && USRegulationsCountries.includes(countryId)) {
      return this.getDomainRepository().getTwilioAvailableToBuyNumbersForCountry(countryId, options.prefix)
    }
    return this.getDomainRepository().getAvailableToBuyNumbersForCountry(countryId, options)
  }

  public findNumberFeatures(number: string) {
    return this.getDomainRepository().findNumberFeatures(number)
  }
}

import { OrmModel, NumberField, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.CURSOR_PAGINATION_PAGE)
export default class CursorPaginationPage extends BaseModel {
  @NumberField(1)
  public page: number

  @StringField('0')
  public prevId: string

  @StringField('0')
  public nextId: string
}

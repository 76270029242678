<template>
  <page-blocks>
    <template v-slot:page-blocks-top>
      <tm-breadcrumbs-skeleton :count="3" />
    </template>

    <template v-slot:title>
      <tm-skeleton
        type="QChip"
        width="232px"
        height="20px"
        class="mb-7"
      />
    </template>

    <template v-slot:white-wrapper>
      <tm-skeleton-details-page />
    </template>
  </page-blocks>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import PageBlocks from '@/components/layout/PageBlocks.vue'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import TmSkeletonDetailsPage from '@/components/shared/skeletons/TmSkeletonDetailsPage.vue'
import TmBreadcrumbsSkeleton from '@/components/shared/skeletons/TmBreadcrumbsSkeleton.vue'

export default defineComponent({
  components: {
    TmBreadcrumbsSkeleton,
    PageBlocks,
    TmSkeleton,
    TmSkeletonDetailsPage,
  },
})
</script>

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/container'
import type { Resolvable } from '@/services/resolvers/types'
import type BillingService from '@/services/domain/billing/billingService'

@injectable()
export default class MonthlyPlansResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.BillingService)
    private readonly billingService: BillingService,
  ) {}

  public async resolve() {
    await this.billingService.fetchSettings()
  }
}

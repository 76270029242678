import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import DomainSettingsService from '@/services/domain/domainSettingsService'
import type { UiStateRepository } from '@/data/repositories/domain/uiStateRepository'
import { DomainSettings } from '@/decorators/domainDecorators'
import { UiState } from '@/data/models/uiState'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'

@DomainSettings({
  model: UiState,
})
@injectable()
export class UiStateService extends DomainSettingsService<UiStateRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService)
    protected readonly modelSubscriptionService: ModelSubscriptionService,
  ) {
    super(entityManager, modelSubscriptionService)
    this.getDomainRepository().init()
  }

  public getIsNotificationRequestWasClosed() {
    return this.getDomainRepository().getUiStateParam('isNotificationRequestWasClosed')
  }

  public setIsNotificationRequestWasClosed(value: boolean) {
    return this.getDomainRepository().setUiStateParam('isNotificationRequestWasClosed', value)
  }

  public getChatShowMobileChat() {
    return this.getDomainRepository().getUiStateParam('chatShowMobileChat')
  }

  public setChatShowMobileChat(value: boolean) {
    return this.getDomainRepository().setUiStateParam('chatShowMobileChat', value)
  }

  public getChatIsChatsLoading() {
    return this.getDomainRepository().getUiStateParam('chatIsChatsLoading')
  }

  public setChatIsChatsLoading(value: boolean) {
    return this.getDomainRepository().setUiStateParam('chatIsChatsLoading', value)
  }
}

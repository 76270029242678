import { mapValues, pick } from 'lodash-es'
import type { Record as VuexOrmRecord } from '@vuex-orm/core'
import { BelongsTo, Boolean, HasManyBy, HasMany, HasOne, MorphMany, Number, String } from '@vuex-orm/core'
import type { Insert, InsertOrUpdate, Update, UpdateObject } from '@vuex-orm/core/dist/src/modules/payloads/Actions'
import type Attribute from '@vuex-orm/core/dist/src/attributes/Attribute'
import type { AllMapping } from '@/services/vuex/types'
import { DataNormalizer } from '@/data/utils/dataNormalizer'
import type BaseModel from '@/data/models/BaseModel'
import type { Dict } from '@/types'

/**
 * Static class to provide operations over Vuex ORM Model structure
 */
export default class ModelMapper {
  /**
   * Is complex mapping allowed in application
   * @param {AllMapping} mapping
   * @returns {boolean}
   */
  public static isSupportedRelations(mapping: AllMapping) {
    return (
      mapping instanceof BelongsTo ||
      mapping instanceof HasManyBy ||
      mapping instanceof HasMany ||
      mapping instanceof MorphMany ||
      mapping instanceof HasOne
    )
  }

  /**
   * Is it scalar mapping
   * @param {AllMapping} mapping
   * @returns {boolean}
   */
  public static isScalarMapping(mapping: AllMapping): boolean {
    return (
      mapping instanceof String || mapping instanceof Boolean || mapping instanceof Attr || mapping instanceof Number
    )
  }

  /**
   * Get all models typeof BaseModel current model depends with
   * @param {typeof BaseModel} model Entry point Model
   * @param {typeof BaseModel[]} acc Initial list of models
   * @returns {typeof BaseModel[]} Return list with models the model depends with
   */
  public static gatherRelatedModels(model: typeof BaseModel, acc: (typeof BaseModel)[] = []) {
    if (!acc.find((m) => m.entity === model.entity)) {
      acc.push(model)
    }
    ModelMapper.forEachFields(model, (field) => {
      const relatedModel = field instanceof HasMany ? (field as any).related : (field as any).parent
      if (!relatedModel) {
        return
      }
      if (ModelMapper.isSupportedRelations(field as AllMapping)) {
        if (!acc.find((m) => m.entity === relatedModel.entity)) {
          acc.push(relatedModel)
          ModelMapper.gatherRelatedModels(relatedModel, acc)
        }
      }
    })

    return acc
  }

  public static isDependsOn(model: typeof BaseModel, dependOnModel: typeof BaseModel) {
    let isDepend = false
    ModelMapper.forEachFields(model, (field) => {
      const relatedModel = (field as any).parent
      if (
        relatedModel &&
        ModelMapper.isSupportedRelations(field as AllMapping) &&
        dependOnModel.entity === (field as any).parent.entity
      ) {
        isDepend = true
      }
    })
    return isDepend
  }

  public static gatherRelatedModelsByModels(models: (typeof BaseModel)[], acc: (typeof BaseModel)[] = []) {
    models.forEach((m) => {
      acc = ModelMapper.gatherRelatedModels(m, acc)
    })

    return acc
  }

  /**
   * Fill records (VUEX Model payload) missing data with default data from model declaration
   * @param {typeof BaseModel} model Data model
   * @param {T[]} records Data set to update
   * @returns {T[]} Updated data set
   */
  public static mappingWithDefault<T>(model: typeof BaseModel, records: T[]): T[] {
    const modelFields = model.getFields()
    const allowedKeys = Object.keys(modelFields)

    const modelValues = mapValues(modelFields, ({ value }) => value)
    return records.map(
      (record) =>
        pick(
          {
            ...modelValues,
            ...record,
          },
          allowedKeys,
        ) as unknown as T,
    )
  }

  /**
   * Clean payload for insert and insertOrUpdate vuex orm actions
   * see PrimitiveCaster
   * @param {Payloads.InsertOrUpdate | Payloads.Insert} payload Payload to cast
   * @param {typeof BaseModel} model Vuex Orm model
   * @returns {Payloads.InsertOrUpdate | Payloads.Insert} Casted payload
   */
  public static normalizeInsertPayload(
    payload: InsertOrUpdate | Insert,
    model: typeof BaseModel,
  ): InsertOrUpdate | Insert {
    payload.data = ModelMapper.normalizePayload(payload.data, model)
    return payload
  }

  /**
   * Clean payload for update vuex orm actions
   * see PrimitiveCaster
   * @param {Payloads.Update} payload Payload to cast
   * @param {typeof BaseModel} model Vuex Orm model
   * @returns {Payloads.Update} Casted payload
   */
  public static normalizeUpdatePayload(payload: Update, model: typeof BaseModel): Update {
    if ((payload as UpdateObject).data) {
      ;(payload as UpdateObject).data = ModelMapper.normalizeInsertPayload(
        { data: (payload as UpdateObject).data! },
        model,
      )
      return payload
    }
    ModelMapper.normalizePayload(payload, model)
    return payload
  }

  /**
   * Apply primitive data cleaning. see PrimitiveCaster
   * @param {Record[]} data Data to cast
   * @param {typeof Model} model Vuex Orm model
   * @returns {Record[]} Casted data
   */
  public static normalizePayload<T extends VuexOrmRecord = VuexOrmRecord>(data: T[], model: typeof BaseModel): T[]
  public static normalizePayload<T extends VuexOrmRecord = VuexOrmRecord>(data: T, model: typeof BaseModel): T
  public static normalizePayload<T extends VuexOrmRecord = VuexOrmRecord>(
    data: T | T[],
    model: typeof BaseModel,
  ): T | T[] {
    const normalizer = (record: T) => {
      const recordDict = record as Dict<any>
      for (const [field] of Object.entries(record)) {
        if (field in model.fields()) {
          const attr = model.fields()[field]
          if (attr instanceof String) {
            recordDict[field] = DataNormalizer.normalizePrimitive(record[field], 'string')
          }
          if (attr instanceof Number) {
            recordDict[field] = DataNormalizer.normalizePrimitive(record[field], 'number')
          }
          if (attr instanceof Boolean) {
            recordDict[field] = DataNormalizer.normalizePrimitive(record[field], 'boolean')
          }
          if ((attr instanceof BelongsTo || attr instanceof HasManyBy) && record[field] !== null) {
            recordDict[field] = this.normalizePayload(record[field], attr.parent as typeof BaseModel)
          }
        }
      }
      return recordDict as T
    }
    return Array.isArray(data) ? data.map<T>((item) => normalizer(item)) : normalizer(data)
  }

  public static forEachFields(model: typeof BaseModel, callback: (field: Attribute) => void) {
    Object.values(model.getFields()).forEach((field) => callback(field))
  }
}

<template>
  <div class="tm-sidebar-header-wrapper">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.tm-sidebar-header-wrapper {
  margin-right: apply-spacer(-2);
  margin-bottom: apply-spacer(-3);
  margin-top: apply-spacer(-3);

  :deep {
    .tm-sidebar-header-button + .tm-sidebar-header-button {
      margin-left: apply-spacer(1);
    }
  }
}
</style>

import { AttrField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

type AreaCode = {
  area: string
  code: number
  name: string
}
@OrmModel(modelNames.AREA_CODES)
export default class AreaCodes extends BaseModel {
  @AttrField(null)
  public areaCodes: AreaCode[]
}

import { injectable } from 'inversify'
import type { Record as VuexOrmRecord } from '@vuex-orm/core'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import AvailableToBuyNumbers from '@/data/models/domain/AvailableToBuyNumbers'
import type { TmCountryCode } from '@/services/types'
import type { GetAvailableToBuyNumbersForCountry } from '@/services/domain/phoneNumbers/types'

@RepoSettings<Endpoint>({
  model: AvailableToBuyNumbers,
})
@injectable()
export default class AvailableToBuyNumbersRepository extends OrmApiRepository<AvailableToBuyNumbers> {
  private getId(countryId: string, options: GetAvailableToBuyNumbersForCountry = {}) {
    return `${countryId}${JSON.stringify(options ?? {})}`
  }

  public async getAvailableToBuyNumbersForCountry(
    countryId: TmCountryCode,
    options: GetAvailableToBuyNumbersForCountry = {},
  ) {
    const params = {
      ...options,
      country: countryId,
    }
    const result = await this.getApiSource().get<VuexOrmRecord>(
      this.endpointsService.getPath('availableToBuyNumbers'),
      { params },
    )

    result.data.id = this.getId(countryId, options)
    await this.create(result.data)
    return result.data as AvailableToBuyNumbers
  }

  public async getTwilioAvailableToBuyNumbersForCountry(countryId: TmCountryCode, prefix?: number) {
    const params = {
      areaCode: prefix,
      countryId,
    }
    const result = await this.getApiSource().get<VuexOrmRecord>(
      this.endpointsService.getPath('twilioAvailableToBuyNumbers'),
      { params },
    )

    result.data.id = this.getId(countryId, {
      tollfree: false,
      prefix,
    })
    await this.create(result.data)
    return result.data as AvailableToBuyNumbers
  }

  public findNumberFeatures(number: string) {
    const all = this.query().all()
    for (let i = 0; i < all.length; i++) {
      const item = all[i]
      const res = item.numbers.find((t) => t.number === number)
      if (res) {
        return res.features
      }
    }

    return undefined
  }
}

import type { Directive, DirectiveHook } from '@/composition/vue/compositionApi'
import { hasModifier } from '@/directives/utils'
import type { SanitizeHtmlMode, SanitizeHtmlConfig } from '@/utils/string/html/types'
import { sanitizeHtml } from '@/utils/string/html/sanitizeHtml'

export const HIDE_EMPTY_MODIFIER = 'hide-empty'

const handleDirective: DirectiveHook<HTMLElement> = (el, { value, arg, modifiers }) => {
  const content = sanitizeHtml(value, arg as SanitizeHtmlMode | undefined)

  if (hasModifier(HIDE_EMPTY_MODIFIER, modifiers) && !content.length) {
    el.classList.add('safe-html-directive--hidden')
  } else {
    el.classList.remove('safe-html-directive--hidden')
  }

  el.innerHTML = content
}

export const safeHtmlDirective: Directive<HTMLElement, string | SanitizeHtmlConfig> = {
  mounted: handleDirective,
  updated: handleDirective,
}

import { NumberField, OrmModel, StringField } from 'vuex-orm-decorators'

import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'

export enum AutomationRuleActions {
  ADD_CONTACT = 'A',
  REMOVE_CONTACT = 'R',
  UNSUBSCRIBE = 'U',
  REMOVE_COMPLETELY = 'X',
}

export enum AutomationRuleScheduleTimeType {
  ALWAYS = 'a',
  SELECTED_HOURS = 't',
}

@OrmModel(modelNames.AUTOMATION_RULE_ACTION)
export default class AutomationRuleAction extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public action: AutomationRuleActions

  @NumberField()
  public argument: number

  @StringField()
  public listName: string | null
}

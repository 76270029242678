import { trim } from 'lodash-es'
import { getNotificationService, getPinnedContactsService, getTranslateService } from '@/core/container/ioc'
import { useConfirmation } from '@/composition/confirmation'
import UnpinModalContent from '@/components/domain/contacts/pinned/UnpinModalContent.vue'
import type { PinnedContactType } from '@/data/models/domain/types'

export const unpinItemAction = async (id: string | number, type: PinnedContactType, name: string) => {
  const translatedType = getTranslateService().t(`contacts.unpinActionConfirmModal.types.${type}`)

  await useConfirmation().confirmDelete(
    '',
    async () => {
      await getPinnedContactsService().unpinPinnedEntity('' + id)

      getNotificationService().notifySuccess(getTranslateService().t('contacts.unpinActionConfirmModal.success'))
    },
    {
      title: getTranslateService().t('contacts.unpinActionConfirmModal.title', { type: translatedType }),
      textComponent: UnpinModalContent as any,
      textComponentProps: {
        type: translatedType,
        name: trim(name),
      },
      submitText: getTranslateService().t('common.unpin'),
    },
  )
}

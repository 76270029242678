import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import CounterRepository from '@/data/repositories/table/counterRepository'
import FailedSmsCounter from '@/data/models/domain/counters/FailedSmsCounter'

@RepoSettings<Endpoint>({
  model: FailedSmsCounter,
})
@injectable()
export default class FailedSmsCounterRepository extends CounterRepository<FailedSmsCounter> {}

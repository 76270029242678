export const randomNumber = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1) + min)

export const getRandomDigit = () => Math.floor(Math.random() * 10)

export const getFallbackIfNotNumber = <FB>(value: any, fallback: FB): number | FB =>
  Number.isFinite(value) ? value : fallback

export const clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max)

export const isInt = (n: number) => n % 1 === 0

export const parseIntWithFallback = <FB = null>(input: string, fallback: FB): number | FB => {
  const parsed = parseInt(input, 10)
  return getFallbackIfNotNumber<FB>(parsed, fallback)
}

export const safeParseInt = (value?: string) => parseInt(value || '0', 10) || 0

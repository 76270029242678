import type {
  NavigationFailure,
  NavigationGuardWithThis,
  NavigationHookAfter,
  RouteLocation,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
  Router,
  RouteRecordName,
  RouteRecordRaw,
  RouterHistory,
  RouteParamsRaw,
  RouteLocationPathRaw,
  RouteLocationNamedRaw,
} from 'vue-router'
import type { RegisteredServices } from '@/core/container/types'
import type BaseModel from '@/data/models/BaseModel'
import type RouterBuilderMiddlewareInterface from '@/services/route/routerBuilderMiddleware/types'
import type { AppModule } from '@/config/types'
import { isRecordUnknown } from '@/utils/typeGuards'

export const sidebarRoutesTags = {
  CONTACTS_BAR_LIST: 'CONTACTS_BAR_LIST',
  HISTORY_BAR_LIST: 'HISTORY_BAR_LIST',
  SERVICES_BAR_LIST: 'SERVICES_BAR_LIST',
  ACCOUNTS_BAR_LIST: 'ACCOUNTS_BAR_LIST',
  SCHEDULED_BAR_LIST: 'SCHEDULED_BAR_LIST',
  REPORTING_BAR_LIST: 'REPORTING_BAR_LIST',
}
export const RoutesTags = {
  ...sidebarRoutesTags,
  USER_TOP_MENU: 'USER_TOP_MENU',
  COMING_SOON: 'COMING_SOON',
}
export type RouteTag = keyof typeof RoutesTags
export type Titler = {
  service: RegisteredServices
  params?: {
    [key: string]: any
  }
}
export type QueryParamsToSet = Record<string, any>

export interface Historyable {
  subscribePopState(callback: () => any): void
  unsubscribePopState(callback: () => any): void
  subscribePushState(callback: () => any): void
  unsubscribePushState(callback: () => any): void
  getLocationQuery(): string
  getRouterHistory(): RouterHistory
  createRouterHistory(): void
}
export const QUERY_CHANGED_EVENT = 'query/changed'
export type QueryChangedPayload = {
  prev: Record<string, any>
  next: Record<string, any>
  diff: {
    added: Record<string, any>
    updated: Record<string, any>
    deleted: Record<string, any>
  }
}

export interface RouterInterface {
  beforeEach(guard: NavigationGuardWithThis<undefined>): () => void
  beforeResolve(guard: NavigationGuardWithThis<undefined>): () => void
  afterEach(guard: NavigationHookAfter): () => void
  push(to: RouteLocationRaw): Promise<NavigationFailure | void | undefined>
  replace(to: RouteLocationRaw): Promise<NavigationFailure | void | undefined>
  back(): ReturnType<Router['go']>
  resolve(to: RouteLocationRaw, currentLocation?: RouteLocationNormalizedLoaded): RouteLocation & { href: string }
  addRoute(route: RouteRecordRaw): () => void
  currentRoute: { value: RouteLocationNormalizedLoaded }
}

type NavigationGuardPrevent = () => void
export type TmNavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  prevent: NavigationGuardPrevent,
) => unknown

export interface RouterBuilderInterface {
  build(modules: AppModule[]): void
  addBeforeEachHandler(fn: TmNavigationGuard): void
  removeBeforeEachHandler(fn: TmNavigationGuard)
  addBeforeResolveHandler(fn: TmNavigationGuard): void
  removeBeforeResolveHandler(fn: TmNavigationGuard)
  addMiddleware(middleware: RouterBuilderMiddlewareInterface): void
}

export type Route = RouteLocationNormalized | RouteLocationNormalizedLoaded

export type RouteEntityMapItem = {
  model: typeof BaseModel
  detailsPage: RouteRecordName
  paramsKey: string
}

export interface TmNamedRoute {
  name: string
  params?: RouteParamsRaw
}

export type TmNamedRouteRecord = Omit<RouteRecordRaw, 'children'> & {
  name: string
  children?: TmNamedRouteRecord[]
}

export type OnChange = (router: Route) => void

export const ROUTER_BEFORE_EACH = 'routerBeforeEach'
export const ROUTER_AFTER_EACH = 'routerAfterEach'

export interface ICurrentRouteService {
  getCurrentRoute(): Route
}

export type RouteLocationNamedOrPathWithParams =
  | (RouteLocationPathRaw & Pick<RouteLocationNamedRaw, 'params'>)
  | RouteLocationNamedRaw

export const isRouteWithStringPathAndName = (value: unknown): value is { path: string; name: string } => {
  return isRecordUnknown(value) && typeof value.path === 'string' && typeof value.name === 'string'
}

export const isRouteWithPathAndParamsOnly = (
  value: unknown,
): value is Required<Pick<RouteLocationNamedRaw, 'params'>> & Pick<RouteLocationPathRaw, 'path'> => {
  return isRecordUnknown(value) && isRecordUnknown(value.params) && typeof value.path === 'string'
}

export const isRouteLocationNamedWithParams = (
  value: unknown,
): value is Omit<RouteLocationNamedRaw, 'params' | 'name'> &
  Required<Pick<RouteLocationNamedRaw, 'params' | 'name'>> => {
  return isRecordUnknown(value) && isRecordUnknown(value.params) && typeof value.name === 'string'
}

export interface TableExpandable {
  init(tableId: string): void
  isExpanded(tableId: string): boolean
  expandAllRows(tableId: string, isExpanded: boolean): void
}

import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

enum SERVICES {
  StatementsColumnServiceFactory,
  StatementsService,
  StatementsFilterServiceFactory,
  StatementsRepository,
}

export type StatementsServices = keyof typeof SERVICES

export const STATEMENTS_SERVICE_TYPES: Record<StatementsServices, symbol> = makeServicesTypes<StatementsServices>(
  enumKeysToArray<StatementsServices>(SERVICES),
)

<template>
  <div class="users-workflow-team-plan-modal-preloader">
    <tm-loader />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmLoader from '@/components/shared/TmLoader.vue'

export default defineComponent({
  components: {
    TmLoader,
  },
})
</script>
<style lang="scss" scoped>
.users-workflow-team-plan-modal-preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 373px;
}
</style>

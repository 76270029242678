import { AttrField, HasManyByField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import KanbanBoardBase from '@/data/models/domain/kanban/kanbanBoardBase'
import type { Currency, Nullable } from '@/types'
import DealStage from '@/data/models/domain/deals/DealStage'

@OrmModel(modelNames.DEAL_PIPELINE)
export default class DealPipeline extends KanbanBoardBase {
  @NumberField(null)
  public dealCount: Nullable<number>

  @StringField()
  public currency: Currency

  @AttrField([], (value: number[]) => value?.map((item) => item.toString()))
  public stageIds: string[]

  @HasManyByField(DealStage, 'stageIds')
  public stages: DealStage[]

  @NumberField(null)
  public defaultAssigneeId: Nullable<number>
}

<template>
  <div
    ref="modalEl"
    class="tm-modal__content-wrapper"
  >
    <slot name="aside" />
    <div
      v-if="!noHeader"
      class="tm-modal__header"
      :class="{
        'tm-modal__header--hide-shadow': scrollAtStart,
      }"
    >
      <div
        class="tm-modal__title"
        :class="truncateTitle ? 'truncate' : 'd-flex align-center'"
      >
        <div
          v-if="titleIcon"
          class="tm-modal__title-icon"
        >
          <tm-icon
            :name="titleIcon"
            :class="titleIconClass"
            size="xxxLarge"
          />
        </div>
        <slot name="modal-title">
          {{ title }}
        </slot>
      </div>
      <div class="tm-modal__header-right">
        <slot name="modal-top-right" />
        <tm-modal-close-btn
          ref="closeButtonEl"
          class="mx-n2 my-n1"
          @close="handleCloseClick"
        />
      </div>
    </div>
    <div
      v-if="hasTabs"
      class="tm-modal__tabs"
    >
      <slot name="modal-tabs" />
    </div>
    <slot name="main">
      <div class="tm-modal__scroll">
        <tm-scrollbar
          :scrollbar-id="scrollbarIdSetup"
          @update:scrolled-to-top="scrollAtStart = $event"
          @update:scrolled-to-bottom="scrollAtEnd = $event"
        >
          <div
            class="tm-modal__main"
            :class="{
              'tm-modal__main--no-header': noHeader,
              'tm-modal__main--no-footer': noFooter,
              'tm-modal__main--without-content-padding': withoutContentPadding,
            }"
          >
            <component
              :is="preloader.content"
              v-if="isDevMode && preloader !== false && preloader?.content && preloader.dev"
              class="absolute tm-modal__main tm-modal__main__preloader-dev"
              v-bind="preloader.props || {}"
            />

            <slot
              name="modal-content"
              :create-another="isAllowCreateAnother"
            />
          </div>
        </tm-scrollbar>
      </div>
    </slot>
    <slot name="before-footer" />
    <div
      v-show="!noFooter"
      class="tm-modal__footer"
      :class="{
        'tm-modal__footer--hide-shadow': scrollAtEnd,
      }"
    >
      <div
        v-if="showCreateAnother"
        class="tm-modal__create-another"
      >
        <field-checkbox v-model="createAnother">
          {{ $t('common.createAnother') }}
        </field-checkbox>
      </div>
      <div class="tm-modal__footer-right">
        <slot name="cancel-button">
          <tm-button
            v-if="!hideCancelButton"
            @click="handleCloseClick"
          >
            {{ cancelButtonText }}
          </tm-button>
        </slot>
        <slot
          name="modal-footer-button"
          :create-another="isAllowCreateAnother"
        />
      </div>
    </div>
    <slot name="after-footer" />
  </div>
</template>

<script lang="ts">
import type { PropType } from '@/composition/vue/compositionApi'
import { computed, defineComponent, onMounted, ref } from '@/composition/vue/compositionApi'
import FieldCheckbox from '@/components/forms/fields/FieldCheckbox.vue'
import TmModalCloseBtn from '@/components/shared/modals/tmModal/TmModalCloseBtn.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmIcon from '@/components/shared/TmIcon.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import { baseModalContentProps } from '@/components/shared/modals/tmModal/const'
import { useDialog, useModalConfigMergedWithProps } from '@/composition/dialog'
import { getUuid } from '@/utils/uuid'
import type { ModalDialogConfig } from '@/components/shared/modals/tmModal/types'
import { isDev } from '@/utils/system'

export default defineComponent({
  components: { TmScrollbar, TmIcon, TmButton, TmModalCloseBtn, FieldCheckbox },
  props: {
    ...baseModalContentProps,
    preloader: {
      type: Object as PropType<ModalDialogConfig['preloader']>,
    },
    isWrapper: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    close: () => true,
  },
  setup(props, context) {
    const scrollbarIdSetup = ref(props.scrollbarId ?? getUuid())
    const { dialogEmit, onOutsideClick, canCloseCallback } = useDialog()
    const modalContentConfig = props.isWrapper ? useModalConfigMergedWithProps(props) : props
    const modalEl = ref()
    const parentModalEl = ref() // q-dialog__inner
    const closeButtonEl = ref<InstanceType<typeof TmModalCloseBtn>>()
    const scrollAtStart = ref(false)
    const scrollAtEnd = ref(false)
    const createAnother = ref(false)

    const isAllowCreateAnother = computed(() => props.showCreateAnother && createAnother.value)

    onOutsideClick(() => {
      closeButtonEl.value?.focus()
    })

    onMounted(() => {
      if (!('scrollable' in modalContentConfig) || !modalContentConfig.scrollable) {
        return
      }
      parentModalEl.value = modalEl.value.closest('.q-dialog__inner')
    })

    canCloseCallback(() => (props.canCloseCallback ? props.canCloseCallback() : true))

    const hasTabs = computed(() => context.slots['modal-tabs'])

    const handleCloseClick = () => {
      dialogEmit('update:model-value', false)
      context.emit('close')
    }

    return {
      scrollbarIdSetup,
      scrollAtStart,
      scrollAtEnd,
      createAnother,
      isAllowCreateAnother,
      hasTabs,
      dialogEmit,
      modalEl,
      closeButtonEl,
      isDevMode: isDev(),
      handleCloseClick,
      ...modalContentConfig,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/custom-variables';

.tm-modal {
  .tm-modal__content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__scroll {
    position: relative;
    display: flex;
    flex-flow: column;
    max-height: 100%;
    overflow: hidden;
    flex-grow: 1;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: $modalPaddingHeader;
    box-shadow: $modal-shadow-top;
    gap: 8px;
    border-bottom: var(--tm-base-modal-content-header-border-bottom, 1px solid $gray2);

    &--hide-shadow {
      box-shadow: none;
    }

    @include media-sm-x-max {
      padding: $mobileModalPaddingHeader;
    }
    &-right {
      display: flex;
      align-items: center;
    }
  }
  &__main {
    flex-grow: 1;
    word-break: break-word;

    &__preloader-dev {
      top: 0;
      left: 0;
      right: 0;
      opacity: 0.85;
      z-index: 20;
    }

    &:not(&--without-content-padding) {
      padding: $modalPaddingHasContent;
      @include media-sm-x-max {
        padding-left: $mobileModalPadding;
        padding-right: $mobileModalPadding;
      }
    }
    &--no-header:not(&--without-content-padding) {
      padding-top: $modalPaddingSingle;
      @include media-sm-x-max {
        padding-top: $mobileModalPadding;
      }
    }
    &--no-footer:not(&--without-content-padding) {
      padding-bottom: $modalPaddingSingle;
      @include media-sm-x-max {
        padding-bottom: $mobileModalPadding;
      }
    }
    :deep(.q-tab-panel) {
      padding: 16px 0 0;
    }
  }
  &__footer {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    padding: var(--tm-base-modal-content-footer-padding, $modalPaddingFooter);
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.05);
    border-top: var(--tm-base-modal-content-footer-border-top, 1px solid $gray2);
    background: var(--tm-base-modal-content-footer-background, $canvas);

    &--hide-shadow {
      box-shadow: none;
    }

    @include media-sm-x-max {
      padding: var(--tm-base-modal-content-footer-padding, $mobileModalPaddingFooter);
    }

    &-right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
    }
  }
  &__title {
    flex-grow: 1;
    font-size: 20px;
    font-weight: $bold-font;
    line-height: 24px;
  }
  &__title-icon {
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__tabs {
    margin-top: -4px;
    padding: 0 $modalPadding;
  }

  &--with-aside {
    position: relative;
  }
}
</style>

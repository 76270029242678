import { inject, injectable } from 'inversify'
import type { Router } from 'vue-router'
// eslint-disable-next-line tp/forbid-import-composable-to-service,tp/using-vue-in-services-restriction
import type { Plugin } from '@/composition/vue/compositionApi'
import ExternalTrackerService from '@/services/tracking/externalTrackerService'
import { SERVICE_TYPES } from '@/core/container/types'
import type RouterService from '@/services/route/routerService'
import {
  type ExternalTrackerAsPlugin,
  type TrackableConfig,
  zukoRoutes,
  zukoTollFreeFormId,
} from '@/services/tracking/types'
import { ROUTER_AFTER_EACH } from '@/services/route/types'
import type SubscriptionService from '@/services/transport/subscriptionService'
import type LoggerService from '@/services/loggerService'

@injectable()
export class ZukoAnalyticsService extends ExternalTrackerService {
  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.SubscriptionService) protected readonly subscriptionService: SubscriptionService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {
    super()
  }

  public getConfig(): TrackableConfig {
    // no credentials required
    return {
      id: '',
    }
  }

  public getPlugin(): Plugin {
    return {
      install: () => this.initialize(),
    } satisfies Plugin
  }

  protected async initialize(): Promise<void> {
    const router: Router = this.routerService.getRouter()
    let isInitialized: boolean = false
    const subscriptionKey = 'zukoAnalytics'
    const track = async () => {
      const isZukoRoute = router.currentRoute.value?.name && zukoRoutes.includes(router.currentRoute.value?.name)
      if (isZukoRoute) {
        if (!isInitialized) {
          isInitialized = true
          await this.initializeTrackerAsPlugin(trackerAsPlugin)
        }
        this.trackEvent()
      }
    }
    const trackingHandler = async () => {
      await router.isReady()
      await track()
      if (!this.subscriptionService.exist(subscriptionKey)) {
        this.subscriptionService.subscribeWithSubscriptionKey(ROUTER_AFTER_EACH, track, subscriptionKey, true)
      }
    }
    const trackerAsPlugin = {
      src: 'https://assets.zuko.io/js/v2/client.min.js',
      id: 'zuko',
      trackingHandler,
    } as ExternalTrackerAsPlugin

    await trackingHandler()
  }

  /* eslint-disable */
  protected trackEvent(): void {
    try {
      ;(function () {
        // @ts-expect-error library object call
        Zuko.trackForm({ target: document.body, slug: zukoTollFreeFormId }).trackEvent(Zuko.FORM_VIEW_EVENT)
      })()
    } catch (e) {
      this.loggerService.error('error', (e as Error).message)
    }
  }
  /* eslint-disable */
}

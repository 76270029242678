import type { RegisteredServices } from '@/core/container/types'

export type SubscriberHelp = {
  keyword: string
  autoReplyMessage: string
}

export type SubscriberOptIn = {
  flowDescription: string
  keyword: string
  autoReplyMessage: string
  screenshotLink: string | null
}

export type SubscriberOptOut = {
  keyword: string
  autoReplyMessage: string
}

// src/Textmagic/ServiceBundle/Enum/TenDlc/Campaign/CampaignStatus.php
export enum TenDlcCampaignsStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  IN_REVIEW = 'IN_REVIEW',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  ACTIVE = 'ACTIVE',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
  CANCELED = 'CANCELED',
  SUSPENDED = 'SUSPENDED',
  TERMINATED = 'TERMINATED',
}

export enum TenDlcTwilioCampaignsStatus {
  draft = 'draft',
  supportReview = 'support_review',
  providerReview = 'provider_review',
  verified = 'verified',
  actionRequired = 'action_required',
}

export interface TenDlcCampaignsUseCase {
  id: string
  classification: string
  description: string
  displayName: string
  minSubUsecases: number
  maxSubUsecases: number
  validSubUsecase: number
}

// frontend/ws-chat/src/TdtfBundle/services/ten-dlc-service.js
export type TenDlcCampaignsBaseUseCasesType =
  | '2FA'
  | 'CUSTOMER_CARE'
  | 'FRAUD_ALERT'
  | 'MARKETING'
  | 'POLLING_VOTING'
  | 'ACCOUNT_NOTIFICATION'
  | 'DELIVERY_NOTIFICATION'
  | 'HIGHER_EDUCATION'
  | 'PUBLIC_SERVICE_ANNOUNCEMENT'
  | 'SECURITY_ALERT'

export type TenDlcCampaignsUseCasesType = TenDlcCampaignsBaseUseCasesType | 'MIXED'

export interface TenDlcCampaignsUseCaseFormScheme {
  singleUseCase: TenDlcCampaignsUseCasesType | ''
  mixedUseCase: Record<TenDlcCampaignsBaseUseCasesType, boolean>
}

export const tenDlcCampaignsTableId = 'tenDlcCampaignsTable' as RegisteredServices

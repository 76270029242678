export type ConfiguredSkeletonItem = {
  width: string
  height?: string
  classes?: string
  style?: string
}
export type ConfiguredSkeletonRowFlat = ConfiguredSkeletonItem | ConfiguredSkeletonItem[]
export type ConfiguredSkeletonRowComplex = ConfiguredSkeletonRowFlat | ConfiguredSkeletonConfig
export type ConfiguredSkeletonConfig<T = ConfiguredSkeletonRowFlat> = T[]

export type ConfirmationSkeletonParams = {
  textLines: number
  items: number
  itemsTextLines: number
  itemClass: string
  itemBlockClass: string
  dev: boolean
}
export const defaultConfirmSkeletonParams: ConfirmationSkeletonParams = {
  textLines: 3,
  items: 2,
  itemsTextLines: 2,
  itemClass: 'mr-2',
  dev: false,
  itemBlockClass: 'mb-5',
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmLocalStorageRepository from '@/data/repositories/ormLocalStorageRepository'
import Order from '@/data/models/Order'

@RepoSettings({
  model: Order,
  create: 'Order',
  single: 'Order',
})
@injectable()
export default class OrderRepository extends OrmLocalStorageRepository<Order> {
  public async setOrder(key: string, newOrder: string[]) {
    await this.postRequest({
      id: key,
      order: newOrder,
    })
    await this.fill(key)
  }

  public getOrder(key: string) {
    return this.find(key)
  }
}

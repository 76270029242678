import { injectable } from 'inversify'
import type { Locale, createI18n } from 'vue-i18n'
import { get } from 'lodash-es'
import type { Translatable, TranslationKey, TranslationNode } from '@/services/types'
import type { ValidationRuleError } from '@/services/validation/types'
import { mapObject } from '@/utils/object/mapObject'
// eslint-disable-next-line
import type { App } from '@/composition/vue/compositionApi'
import type { LocaleTranslationData, SupportedLocales } from '@/services/translateService.types'
import { APP_DEFAULT_LOCALE } from '@/services/translateService.types'

// https://vue-i18n.intlify.dev/guide/advanced/component.html#basic-usage
@injectable()
export default class TranslateService implements Translatable {
  protected i18n: ReturnType<typeof createI18n> | null = null

  protected readonly defaultLocale = APP_DEFAULT_LOCALE

  constructor() {
    this.t = this.t.bind(this)
    this.tc = this.tc.bind(this)
  }

  public setI18n(i18n: any) {
    this.i18n = i18n
  }

  public t(key: TranslationKey, params?: Record<string, any>): string {
    if (key === '') {
      return ''
    }

    if (!this.i18n) return key
    // @ts-expect-error t not callable
    return this.i18n.global.t(key, params)
  }

  // t with fallback
  // eslint-disable-next-line @typescript-eslint/default-param-last
  public tf(key: TranslationKey, values: Record<string, any> = {}, fallback: string): string {
    return this.exists(key) ? this.t(key, values) : fallback
  }

  public n(str: number, type: string, locale?: Locale, params?: Record<string, any>): string {
    if (!this.i18n) return ''
    // @ts-expect-error unknown props
    return this.i18n.global.n(str, type, locale, params)
  }

  // Check translateService.plugin.d.ts
  public getPlugin() {
    return {
      install: (app: App) => {
        this.i18n?.install(app)
        app.config.globalProperties.$traw = this.traw.bind(this)
      },
    }
  }

  public tc(key: TranslationKey, count?: number, params?: { [key: string]: any }): string {
    if (key === '') {
      return ''
    }

    return this.t(key, { ...params, count })
  }

  public exists(str: TranslationKey): boolean {
    return this.i18n?.global.te(str) ?? false
  }

  public getEntityLabel(entityName: string, count = 1): string {
    return this.t(`entities.${entityName}.name`, { count })
  }

  public getLowerCaseEntityLabel(entityName: string, count = 1): string {
    const key = `entities.${entityName}.lowerCaseName`
    return this.tf(key, { count }, this.getEntityLabel(entityName, count))
  }

  public setLocaleMessages(messages: Record<string, string>) {
    this.i18n?.global.setLocaleMessage(this.defaultLocale, {
      ...this.getLocaleMessages(),
      ...messages,
    })
  }

  public addLocaleMessages(messages: LocaleTranslationData, locale: SupportedLocales) {
    this.i18n?.global.mergeLocaleMessage(locale, messages)
  }

  public traw(key: string): TranslationNode {
    const messages = this.getLocaleMessages()
    const value = get(messages, key)

    if (Array.isArray(value)) {
      return value.map((_t, i) => this.traw(`${key}.${i}`)) as TranslationNode
    }
    if (typeof value === 'object') {
      return mapObject(value, (keyI) => [keyI, this.traw(`${key}.${keyI}`)])
    }
    return this.translate(value)
  }

  public translateValidationError(validationError: ValidationRuleError, fieldName?: string) {
    const params: Record<string, unknown> = { ...validationError.message.params }
    params.field = params.field ?? fieldName

    if (validationError.message.tc) {
      return this.tc(validationError.message.path as TranslationKey, validationError.message.tc, params)
    }

    return this.t(validationError.message.path as TranslationKey, params)
  }

  /**
   * @todo Implement this when we have a way to change the locale
   *
   * @return {SupportedLocales}
   */
  public getCurrentLocale(): SupportedLocales {
    return this.defaultLocale
  }

  private getLocaleMessages() {
    return this.i18n?.global.messages[this.defaultLocale]
  }

  private translate(fn: (ctx: { normalize: (fragments: string[]) => string }) => string) {
    return fn({ normalize: (fragments: string[]) => fragments[0] })
  }
}

import { inject, injectable } from 'inversify'
import type { CancelToken } from 'axios'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint, EndpointParams } from '@/services/endpoints'
import AttachmentFile from '@/data/models/domain/attachmentFile/AttachmentFile'
import { type AttachmentFileEntityType, AttachmentFileType } from '@/data/models/domain/attachmentFile/types'
import type { PaginationUrlType } from '@/services/tables/types'
import type { PaginationParams } from '@/services/tables/pagination/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Config } from '@/core/types'
import type SerializerService from '@/services/serializerService'
import type { HttpService } from '@/services/transport/httpService'
import type { AbstractEndpointsInterface } from '@/services/endpointsService'
import type LoggerService from '@/services/loggerService'
import type FilterQueryService from '@/services/filterQueryService'
import type { UploadFileParams } from '@/services/domain/attachmentFile/types'

@injectable()
@RepoSettings<Endpoint>({
  model: AttachmentFile,
  fetch: 'files',
})
export default class AttachmentFileRepository extends OrmApiRepository<AttachmentFile> {
  constructor(
    @inject(SERVICE_TYPES.Config) private readonly _config: Config,
    @inject(SERVICE_TYPES.SerializerService) protected readonly serializerService: SerializerService,
    @inject(SERVICE_TYPES.Api) protected readonly api: HttpService,
    @inject(SERVICE_TYPES.EndpointsService) protected readonly endpointsService: AbstractEndpointsInterface,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.FilterQueryService) protected readonly filterQueryService: FilterQueryService,
  ) {
    super(serializerService, api, endpointsService, loggerService, filterQueryService)
  }

  public async uploadFile({
    file,
    fileType,
    onUploadProgress,
    entityId,
    entityType,
    cancelToken,
  }: UploadFileParams & { cancelToken?: CancelToken }) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('fileType', fileType ?? AttachmentFileType.attachment)

    if (entityType) {
      formData.append('entityType', entityType)
    }
    if (entityId) {
      formData.append('entityId', entityId)
    }

    const path = this.endpointsService.getPath('uploadFile')
    const { data: attachmentFile } = await this.getApiSource().post<AttachmentFile>(path, formData, {
      onUploadProgress,
      cancelToken,
    })

    await this.insertOrUpdate([attachmentFile])

    return attachmentFile.id
  }

  public async attachFiles(fileIds: string[], entityId: string, entityType: AttachmentFileEntityType) {
    const path = this.endpointsService.getPath('attachFiles')
    return this.getApiSource().post<AttachmentFile>(path, {
      fileIds,
      entity: {
        entityId,
        entityType,
      },
    })
  }

  public uploadFileCancellable(params: UploadFileParams) {
    return this.makeCancelableRequest((cancelToken) => {
      return this.uploadFile({
        ...params,
        cancelToken,
      })
    })
  }

  public getDownloadLink(fileId: string, entityType: AttachmentFileEntityType): string {
    const apiUrl = this._config.apiUrl
    const path = `${this.endpointsService.getPath('downloadFile', [fileId, entityType])}`

    if (!apiUrl) {
      return path
    }

    const url = new URL(apiUrl)
    url.pathname = path

    return url.toString()
  }

  public async detachFile(entityId: string, entityType: AttachmentFileEntityType, fileId: string) {
    const path = this.endpointsService.getPath('detachFile')
    await this.getApiSource().post(path, { fileId, entityType, entityId })

    this.delete([fileId])
  }

  public async fetchListGridRequest(
    queryParameterBag: PaginationUrlType,
    endpointParams: EndpointParams,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
  ) {
    // CAR-10047: temporary mapping, until clarity is added from the backend, there is a bug here, or they will add a new property with a entityType
    const response = await this.doGrid(
      this.settings().fetch!,
      endpointParams,
      queryParameterBag,
      paginationParamsBag,
      searchQuery,
    )
    response.items = response.items.map((el) => ({
      ...el,
      type: queryParameterBag.other!.entityType as AttachmentFileEntityType,
    }))
    // end

    await this.insertOrUpdate(response.items)
    return response
  }
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { ModelSettingsResolverParams, Resolvable } from '@/services/resolvers/types'
import type DomainManagerService from '@/services/domain/domainManagerService'

@injectable()
export default class ModelSettingsResolverService implements Resolvable<ModelSettingsResolverParams> {
  constructor(
    @inject(SERVICE_TYPES.DomainManagerService) protected readonly domainManagerService: DomainManagerService,
  ) {}

  public async resolve(params: ModelSettingsResolverParams) {
    const service = this.domainManagerService.getSettingsService(params.model)
    if (params.cache === false) {
      await service.getDomainRepository().getSettings()
    } else {
      await service.getSettings()
    }
  }
}

import type { RegisteredServices } from '@/core/container/types'
import type { PaginationUrlFilterNullableSingleType } from '@/services/tables/types'
import type { SegmentSaveAsModalFormSchema } from '@/services/domain/contact/segments/types'

export const SEGMENT_ID_PARAM_NAME = 'segmentId' as const
export const SEGMENT_DRAFT_NAME_PARAM_NAME = 'name' as const

export const SegmentContactsTableId = 'SegmentContactsTable' as RegisteredServices
export const segmentSaveViewAsFormId = 'SegmentSaveAsModalFormService' as const satisfies RegisteredServices

export const segmentCreateModalId = 'segmentCreateModal' as const
export const segmentDeleteModalId = 'segmentDeleteModal' as const
export const segmentRenameModalId = 'segmentRenameModal' as const
export const segmentSaveAsModalId = 'segmentSaveAsModal' as const

export interface SegmentSaveAsFormParams {
  title: string
  loadingButtonLabel: string
  filters: PaginationUrlFilterNullableSingleType[]
  afterSuccessSubmit: (id: string) => Promise<void>
  initFormData?: SegmentSaveAsModalFormSchema
}

export interface SegmentDuplicateModalParams {
  name: string
  filters: PaginationUrlFilterNullableSingleType[]
  title?: string
  loadingButtonLabel?: string
}

export interface SegmentSaveAsModalParams {
  name: string
  tableId: RegisteredServices
}

export interface SegmentDeleteModalParams {
  segmentId: string
  onSuccess?: () => void
}

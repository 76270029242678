import { AttrField, BelongsToField, BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import Session from '@/data/models/domain/session/Session'
import SenderSource from '@/data/models/domain/SenderSource'
import type { CreatedBy, ScheduledTextParameters } from '@/data/models/domain/types'
import type { ScheduledType } from '@/components/views/scheduled/types'
import ScheduledMessageParameters from '@/data/models/domain/scheduled/ScheduledMessageParameters'

@OrmModel(modelNames.SCHEDULED_MESSAGE)
export default class ScheduledMessage extends BaseModel {
  @StringField()
  public title: string

  @StringField()
  public nextSend: string

  @StringField()
  public rrule: string

  @StringField()
  public sessionId: string

  @BelongsToField(Session, 'sessionId')
  public session: Session

  @StringField()
  public parametersId: string

  @BelongsToField(ScheduledMessageParameters, 'parametersId')
  public parameters: ScheduledMessageParameters

  @StringField()
  public summary: string

  @StringField()
  public lastOccurrence: string

  @StringField()
  public firstOccurrence: string

  @StringField()
  public type: string

  @StringField()
  public timezone: string

  @StringField()
  public avatar: string

  @NumberField()
  public recipientsCount: number

  @StringField()
  public contactName: string

  @AttrField()
  public textParameters: ScheduledTextParameters

  @BooleanField()
  public completed: boolean

  @StringField()
  public lastSent: string

  @StringField()
  public createdAt: string

  @AttrField()
  public senderSourcePhone: string

  @BelongsToField(SenderSource, 'senderSourcePhone')
  public senderSource: SenderSource

  @StringField()
  public status: ScheduledType

  @AttrField()
  public createdBy: CreatedBy
}

import { BooleanField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.UI_SETTINGS)
export default class UiSettings extends BaseModel {
  @BooleanField()
  public gettingStartedPropsIsSetUpMessagingChannelChecked: boolean

  @BooleanField()
  public gettingStartedPropsIsAddContactsChecked: boolean

  @BooleanField()
  public gettingStartedPropsIsInviteTeamMembersChecked: boolean

  @BooleanField()
  public gettingStartedPropsIsCreateYourFirstCampaignChecked: boolean

  @BooleanField()
  public gettingStartedPropsIsDiscoverMoreServicesChecked: boolean

  @BooleanField()
  public gettingStartedPropsIsAddFundsToYourAccountChecked: boolean

  @BooleanField()
  public gettingStartedPropsIsGettingStartedPageHidden: boolean

  @BooleanField()
  public ticketsPropsIsGettingStartedPageHidden: boolean
}

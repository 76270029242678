import { BooleanField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { TmCountryCode } from '@/services/types'

@OrmModel(modelNames.SENDER_SETTINGS_COUNTRY)
export default class SenderSettingsCountry extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public countryId: TmCountryCode

  @StringField()
  public name: string

  @StringField()
  public replyOptionsType: ReplyOptionsType | null

  @StringField()
  public sender: string

  @NumberField()
  public buyable: number

  @StringField()
  public category: SenderSettingsCategory

  @BooleanField()
  public ssiAllowMobilePhone: boolean

  @BooleanField()
  public ssiAllowSenderId: boolean

  @BooleanField()
  public ssiAllowShared: boolean

  @BooleanField()
  public isNativeDedicatedOnly: boolean

  @StringField()
  public status: string

  public get allowedPhoneNumberCountryForNativeDedicatedOnly(): TmCountryCode[] {
    if (this.countryId === 'CA') {
      return [this.countryId, 'US']
    }
    return [this.countryId]
  }
}

export type SenderSettingsCategory = 'myCountries' | 'popularCountries' | 'otherCountries'

export type ReplyNonByocOptionsType = 'reply_phones' | 'senders_id' | 'users.phone' | 'users_inbound'

export type ReplyByocSenderIdOptionsType = 'uc_twilio_sender_id'

export type ReplyByocOptionsType =
  | 'user_carrier_twilio'
  | 'user_carrier_vonage'
  | 'user_carrier_bandwidth'
  | 'user_carrier_sinch'
  | 'user_carrier_infobip'
  | 'user_carrier_message_bird'
  | 'user_carrier_aws'
  | 'user_carrier_telnyx'

export type ReplyOptionsType = ReplyNonByocOptionsType | ReplyByocOptionsType | ReplyByocSenderIdOptionsType

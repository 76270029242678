export enum DealsRoutes {
  index = 'user.deals',
  base = 'user.deals.base',
  overview = 'user.deals.overview',
  pipeline = 'user.deals.pipeline',
  pipelineBase = 'user.deals.pipeline.base',
  settingsIndex = 'user.deals.settings.index',
  settingsBase = 'user.deals.settings.base',
  settingsOverview = 'user.deals.settings.overview',
  settingsPipelineBase = 'user.deals.settings.pipelineBase',
  settingsPipeline = 'user.deals.settings.pipeline.details',
  settingsPipelineArchived = 'user.deals.settings.pipelineArchived',
  settingsPipelineStages = 'user.deals.settings.pipeline.stages',
}

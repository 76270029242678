import { inject, injectable } from 'inversify'
import { isEmpty } from 'lodash-es'
import type { RouteLocationRaw } from 'vue-router'
import { SERVICE_TYPES } from '@/core/container/types'
import { queryToDotNotation } from '@/utils/router'
import { fromDotNotation } from '@/utils/object/fromDotNotation'
import type { KeyStorageServiceInterface } from '@/services/storage/types'
import type RouterService from '@/services/route/routerService'

@injectable()
export default class QueryStorageService implements KeyStorageServiceInterface {
  constructor(@inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService) {}

  public getParams(key: string) {
    const query = this.getStore()

    return Object.entries(query).reduce<Record<string, unknown>>((params, [queryKey, value]) => {
      const { storageKey, paramKey } = this.splitQueryKey(queryKey)
      if (storageKey !== key) return params
      return {
        ...params,
        [paramKey]: fromDotNotation(JSON.parse(String(value))),
      }
    }, {})
  }

  public setParams(key: string, params: Record<string, unknown>, replace = false) {
    const query = Object.entries(params).reduce<Record<string, unknown>>((toQuery, [paramKey, value]) => {
      if (!value || isEmpty(value)) return toQuery
      return {
        ...toQuery,
        ...queryToDotNotation(this.makeQueryKey(key, paramKey), '', value),
      }
    }, {})
    const location = this.makeUpdateLocation(query)
    return this.updateRouteLocation(location, replace)
  }

  public removeParams(key: string, keysToRemove: string[], replace = false) {
    const store = this.getStore()
    const query = Object.fromEntries(
      Object.entries(store).filter(([queryKey]) => {
        const { storageKey, paramKey } = this.splitQueryKey(queryKey)
        return storageKey !== key || !keysToRemove.includes(paramKey)
      }),
    )

    const location = this.makeReplaceLocation(query)
    return this.updateRouteLocation(location, replace)
  }

  protected makeUpdateLocation(query: Record<string, any>) {
    const currentRoute = this.getCurrentRoute()
    return {
      ...currentRoute,
      query: {
        ...currentRoute.query,
        ...query,
      },
    }
  }

  protected makeReplaceLocation(query: Record<string, any>) {
    const currentRoute = this.getCurrentRoute()
    return {
      ...currentRoute,
      query,
    }
  }

  protected updateRouteLocation(location: RouteLocationRaw, replace: boolean) {
    return replace ? this.routerService.replace(location) : this.routerService.push(location)
  }

  protected getCurrentRoute() {
    return this.routerService.getCurrentRoute()
  }

  protected getStore() {
    return this.getCurrentRoute().query
  }

  protected makeQueryKey(storageKey: string, paramKey: string) {
    return `${paramKey}.${storageKey}`
  }

  protected splitQueryKey(queryKey: string) {
    const [paramKey, storageKey] = queryKey.split('.')
    return { storageKey, paramKey }
  }
}

import { createI18n } from 'vue-i18n'
import type { MiddlewareInterface } from '@/core/middlewares/middlewareInterface'
import { LogLevel } from '@/core/logger/types'
import type { GetLocator } from '@/core/container/container'
import { getMonitoringLoggerService, getTranslateService } from '@/core/container/ioc'
import type { LocaleTranslationData } from '@/services/translateService.types'
import { numberFormats } from '@/config/formatting'
import translationDataCommon from '@/resources/i18n/common.json'
import { APP_DEFAULT_LOCALE } from '@/services/translateService.types'

export default class TranslateMiddleware implements MiddlewareInterface {
  public make(get: GetLocator) {
    const translateService = getTranslateService()
    const monitoringLoggerService = getMonitoringLoggerService()

    const i18n = createI18n<[LocaleTranslationData], 'en-US'>({
      numberFormats,
      legacy: true,
      globalInjection: false,
      locale: APP_DEFAULT_LOCALE,
      fallbackLocale: APP_DEFAULT_LOCALE,
      messages: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'en-US': translationDataCommon satisfies LocaleTranslationData,
        /* eslint-enable @typescript-eslint/naming-convention */
      },
      silentFallbackWarn: true,
      missing: (locale, key) => {
        if (locale.length > 2) {
          // Filtering short locale definitions to avoid double logging

          monitoringLoggerService.logAndCapture(
            `Missing translation for "${key}" in "${locale}" locale`,
            LogLevel.WARNING,
            'i18n',
          )
        }
      },
    })

    translateService.setI18n(i18n)
  }
}

<template>
  <q-checkbox
    v-bind="propsAndAttrs"
    class="tm-field-checkbox"
    :class="[
      fieldClass,
      {
        'tm-field-checkbox--align-top': alignTop,
        'tm-field-checkbox--required': required,
        [`tm-field-checkbox--${size}`]: size,
      },
    ]"
    :model-value="modelValue"
    :val="val"
    :disable="isDisabled"
    @update:model-value="onInput"
  >
    <slot />
  </q-checkbox>
</template>

<script lang="ts">
import { defineComponent, type PropType } from '@/composition/vue/compositionApi'
import { fieldProps } from '@/components/forms/fieldBase'
import { useField } from '@/composition/forms/useField'
import { fieldEmits } from '@/services/forms/types'
import type { SizeProp } from '@/components/shared/types'

type FieldCheckboxSize = '' | 'large' | 'medium'

export default defineComponent({
  props: {
    ...fieldProps,
    modelValue: {
      // string[] is used when multiple checkboxes work as one field
      type: [Boolean, Array] as PropType<boolean | string[]>,
    },
    // val prop is used when modelValue type is string[]
    val: {
      type: String,
    },
    alignTop: {
      type: Boolean,
      default: false,
    },
    fieldClass: {
      type: String,
    },
    size: {
      type: String as SizeProp<FieldCheckboxSize>,
      default: '',
    },
  },
  emits: {
    ...fieldEmits<boolean>(),
  },
  setup(props, context) {
    const { propsAndAttrs, emitChangeAndInput } = useField<boolean>(props, context)

    const onInput = (value: boolean) => {
      emitChangeAndInput(value)
    }

    return {
      onInput,
      propsAndAttrs,
      focus: () => {}, // error fix from initiator TmFormLine after click
    }
  },
})
</script>

<style lang="scss" scoped>
// checkbox style
.tm-field-checkbox {
  &.q-checkbox {
    display: flex;
  }
  &--align-top {
    align-items: flex-start;
    :deep(.q-checkbox__inner) {
      margin-top: 1px;
    }
  }

  &:deep(.q-checkbox__inner) {
    font-size: $xm-font;
    background-color: $white;
    border: 1px solid $border-button;
    border-radius: $border-radius;
    transition: all $transition-300ms-ease-in-out;
    box-shadow: 0 2px 4px rgba($black, 0.05);
    &:hover {
      background: $shade;
    }

    // hide ripple animation
    &::before {
      content: none !important;
    }

    // icon background
    .q-checkbox__bg {
      top: 50%;
      left: 50%;
      width: 0.7em;
      height: 0.7em;
      transform: translate(-50%, -50%);
      border: 0;
      background-color: transparent;
    }

    // color on active states
    &.q-checkbox__inner--truthy,
    &.q-checkbox__inner--indet {
      background-color: $blue;
      border-color: $blue;
      &:hover {
        background-color: $blue600;
        border-color: $blue600;
      }
    }
  }

  &:deep(.q-checkbox__label) {
    min-width: 0;
  }

  &--required:deep(.q-checkbox__label):after {
    content: '*';
    color: $red600;
    font-weight: $semi-bold-font;
  }

  &:deep(.q-checkbox__label):not(:empty) {
    font-size: $font-size-root;
    font-weight: $semi-bold-font;
    margin-left: 8px;
    line-height: 20px;
  }

  &[disabled],
  &.disabled {
    opacity: 1 !important;
    cursor: not-allowed;
    &:deep(.q-checkbox__inner) {
      background: var(--field-checkbox-disabled-bg, $lift);
      border-color: var(--field-checkbox-disabled-border, $gray1);
    }
    &[aria-checked='true'] {
      &:deep(.q-checkbox__inner) {
        background: var(--field-checkbox-checked-disabled-bg, $blue200);
        border-color: var(--field-checkbox-checked-disabled-border, $blue200);
      }
    }
    &:deep(.q-checkbox__inner--indet) {
      background: var(--field-checkbox-checked-disabled-bg, $blue200) !important;
      border-color: var(--field-checkbox-checked-disabled-border, $blue200) !important;
    }
  }

  // sizes
  &--large:deep(.q-checkbox__inner) {
    font-size: $lg-font;
  }
  &--medium:deep(.q-checkbox__inner) {
    font-size: $xm-font;
  }
}
</style>

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES, type RegisteredServices } from '@/core/container/types'
import type LoggerService from '@/services/loggerService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type { CleanUpable, CleanUpFeature, CleanUpTriggerTask } from '@/services/cleanUp/types'
import TmLogicError from '@/core/error/tmLogicError'
import type { IServiceManager } from '@/core/middlewares/types'
import { ServiceGroups, type Service } from '@/config/types'
import type { GetLocator } from '@/core/container/container'

@injectable()
export default class CleanUpManager implements IServiceManager {
  protected features: Partial<Record<CleanUpFeature, RegisteredServices>> = {}

  private callbacks: Array<() => void> = []

  private constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject('GetLocator') private readonly get: GetLocator,
  ) {}

  public trigger(tasks: CleanUpTriggerTask[]) {
    this.loggerService.log('cleanUp', 'Trigger for ...', 'trigger')
    this.loggerService.raw('cleanUp', tasks)
    Object.values(this.features).forEach((feature) => {
      const cleanuper = this.get(feature) as CleanUpable
      cleanuper.trigger(tasks)
    })
    // here is GarbageCollector started
  }

  public cleanUp(): void {
    Object.values(this.em.all(ServiceGroups.REPOSITORIES))
      .filter((repo) => !repo.isPublic())
      .forEach((repo) => repo.deleteByCondition(() => true))

    // to avoid creating memory leaks, callbacks will be removed after they are called
    let cb = this.callbacks.pop()
    while (cb) {
      try {
        cb()
      } catch {
        this.loggerService.error('cleanUp', `Error in callback ${cb}`)
      }
      cb = this.callbacks.pop()
    }
  }

  public addService(service: Service, group?: ServiceGroups) {
    if (group && this.features[group]) {
      throw new TmLogicError(`Duplicate registration cleanuper for feature ${group}`)
    }
    if (group) {
      this.loggerService.log('cleanUp', `Use cleanUper for feature ${group}`)
      this.loggerService.raw('cleanUp', service.id)
      this.features[group] = service.id as RegisteredServices
    }
  }

  /**
   * Add a callback to clear something
   * After it is called, it will be removed (one-time call)
   * @param cb callback that will be called in the cleanup procedure
   */
  public addOneTimeCallback(cb: () => void) {
    if (this.callbacks.indexOf(cb) < 0) {
      this.callbacks.push(cb)
    }
  }
}

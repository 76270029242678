import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import { PointAiRoutes } from '@/routes/types'
import PassThrough from '@/components/layout/PassThrough.vue'

export const getPointAIRoutes = (): RouteRecordRaw[] => [
  {
    name: PointAiRoutes.root,
    path: 'point-ai',
    component: PassThrough,
    redirect: { name: PointAiRoutes.websites },
    meta: {
      modules: [AppModule.PointAi],
      tabTitle: 'pageTitle.user.services.pointAi',
      tags: ['SERVICES_BAR_LIST'],
      resolvers: [{ service: 'PointAiChatResolver' }],
      accessControlGroups: ['PointAiManagementStrategy'],
    },
    children: [
      {
        name: PointAiRoutes.main,
        path: '',
        component: () => import('@/components/views/services/pointAi/PointAi.vue'),
        redirect: { name: PointAiRoutes.websites },
        children: [
          {
            name: PointAiRoutes.websites,
            path: 'websites',
            component: () => import('@/components/views/services/pointAi/PointAiWebsites.vue'),
          },
          {
            name: PointAiRoutes.documents,
            path: 'documents',
            component: () => import('@/components/views/services/pointAi/PointAiDocuments.vue'),
          },
        ],
      },
      {
        name: PointAiRoutes.websitesDownloaded,
        path: 'websites/:id',
        props: true,
        component: () => import('@/components/views/services/pointAi/PointAiWebsitesDownloaded.vue'),
      },
    ],
  },
]

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum CHECK_EMAIL_FOR_SIGNUP_SERVICES {
  EmailAvailabilityForSignUpService,
}

export type CheckEmailForSignupServices = keyof typeof CHECK_EMAIL_FOR_SIGNUP_SERVICES

export const CHECK_EMAIL_FOR_SIGNUP_SERVICE_TYPES = makeServicesTypesFromEnum<CheckEmailForSignupServices>(
  CHECK_EMAIL_FOR_SIGNUP_SERVICES,
)

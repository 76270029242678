export enum FilteredViewSharingType {
  SHARED = 'shared',
  PERSONAL = 'personal',
}

export enum FilteredViewsSupportedEntities {
  TICKETS = 'ticket',
  CONTACTS = 'contact',
}

export const isFilteredViewSupportEntity = (value: unknown): value is FilteredViewsSupportedEntities => {
  return value === FilteredViewsSupportedEntities.TICKETS || value === FilteredViewsSupportedEntities.CONTACTS
}

export enum FilteredViewAliases {
  ALL_TICKETS = 'all-tickets',
  SPAM_TICKETS = 'spam-tickets',
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { MessagesPricePostBody, MessagesPriceSendResponseData } from '@/services/domain/messages/types'
import MessagesPrice from '@/data/models/domain/MessagesPrice'

@RepoSettings<Endpoint>({
  model: MessagesPrice,
  create: 'messagesPriceCreate',
})
@injectable()
export default class MessagesPriceRepository extends OrmApiRepository<MessagesPrice> {
  public async getMessagesPrice(params: MessagesPricePostBody) {
    const path = this.endpointsService.getPath('messagesPrice')
    return this.getApiSource().get<MessagesPrice>(path, { params })
  }

  public createMessageFromFormData(data: MessagesPricePostBody) {
    return this.postRequest<MessagesPriceSendResponseData>(data)
  }
}

import { isEmpty } from './utils'

const urlValidator = (value: unknown) => {
  if (isEmpty(value)) {
    return true
  }

  try {
    // eslint-disable-next-line no-new
    new URL(value as string)
  } catch {
    return false
  }

  return true
}

export default urlValidator

import type { RouteRecordRaw } from 'vue-router'
import ApiV2Settings from '@/data/models/domain/service/api/ApiV2Settings'
import ApiV1Settings from '@/data/models/domain/service/api/ApiV1Settings'
import { AppModule } from '@/config/types'

export const getApiRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.services.api',
    path: 'api',
    meta: {
      tags: ['SERVICES_BAR_LIST'],
      permission: 'api',
      modules: [AppModule.ServicesApi],
    },
    component: () => import('@/components/views/services/api/Api.vue'),
    redirect: { name: 'user.services.api.apiv2Keys' },
    children: [
      {
        name: 'user.services.api.apiv2Keys',
        path: '',
        component: () => import('@/components/views/services/api/ApiV2Keys.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.api.apiv2Keys',
        },
      },
      {
        name: 'user.services.api.apiv2Settings',
        path: 'api-v2-settings',
        component: () => import('@/components/views/services/api/ApiV2Settings.vue'),
        meta: {
          tabTitle: 'pageTabTitle.user.services.api.apiv2Settings',
          resolvers: [
            {
              service: 'ModelSettingsResolverService',
              params: {
                model: ApiV2Settings,
                cache: false,
              },
            },
          ],
        },
      },
      {
        name: 'user.services.api.integrations',
        path: 'integrations',
        component: () => import('@/components/views/services/integrations/Integrations.vue'),
        props: { tab: true },
        meta: {
          tabTitle: 'pageTabTitle.user.services.api.integrations',
        },
      },
      {
        name: 'user.services.api.apiv1',
        path: 'api-v1',
        meta: {
          tabTitle: 'pageTabTitle.user.services.api.apiv1',
          resolvers: [
            {
              service: 'ModelSettingsResolverService',
              params: {
                model: ApiV1Settings,
                cache: false,
              },
            },
          ],
        },
        component: () => import('@/components/views/services/api/ApiV1Settings.vue'),
      },
    ],
  },
]

import { injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import Timezone from '@/data/models/domain/Timezone'
import type TimezoneRepository from '@/data/repositories/domain/timezoneRepository'

@DomainSettings({
  model: Timezone,
})
@injectable()
export default class TimezoneService extends DomainBaseService<TimezoneRepository> {
  public getEntityByTimezone(timezone: Timezone['timezone']) {
    return this.getDomainRepository().query().where('timezone', timezone).first()
  }
}

import { makeServicesTypes } from '@/core/container/makeServicesTypes'
import { enumKeysToArray } from '@/utils/enum'

enum SERVICES {
  RecentRecipientService,
  RecentRecipientRepository,
}

export type RecentRecipientServices = keyof typeof SERVICES

export const RECENT_RECIPIENT_SERVICE_TYPES: Record<RecentRecipientServices, symbol> =
  makeServicesTypes<RecentRecipientServices>(enumKeysToArray<RecentRecipientServices>(SERVICES))

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

export enum ALSO_HERE_SERVICES {
  AlsoHereRepository,
  AlsoHereService,
  AlsoHereResolver,
}

export type AlsoHereServices = keyof typeof ALSO_HERE_SERVICES

export const ALSO_HERE_SERVICE_TYPES: Record<AlsoHereServices, symbol> = makeServicesTypes<AlsoHereServices>(
  enumKeysToArray<AlsoHereServices>(ALSO_HERE_SERVICES),
)

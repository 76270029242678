import type {
  DefaultColumns,
  BaseFilterInterface,
  GroupingDirection,
  SortDirection,
  TableKeyBuilderInterface,
  CustomFiltersDefaultState,
} from '@/core/tables/types'
import type { FilterOperationEnum, FilterOperationValue, Gridable, SerializerInterface } from '@/services/types'
import { isFilterOperation } from '@/services/types'
import type NotificationService from '@/services/notificationService'
import type ExportService from '@/services/exportService'
import type BaseModel from '@/data/models/BaseModel'
import type { Endpoint } from '@/services/endpoints'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import type LoggerService from '@/services/loggerService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type { BaseTableConstructor } from '@/core/tables/baseTableInterface'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type BulkManager from '@/services/bulk/bulkManager'
import type FilterServiceManagerInterface from '@/services/tables/managers/filterServiceManagerInterface'
import type { RegisteredServices } from '@/core/container/types'
import type DomainManagerService from '@/services/domain/domainManagerService'
import { isRecordUnknown } from '@/utils/typeGuards'
import type TableSorterManagerInterface from '@/services/tables/managers/tableSorterManagerInterface'
import type TableGrouperManagerInterface from '@/services/tables/managers/tableGrouperManagerInterface'
import type TablePaginatorManagerInterface from '@/services/tables/managers/tablePaginatorManagerInterface'
import type TableSearcherManagerInterface from '@/services/tables/managers/tableSearcherManagerInterface'
import type TableColumnManagerInterface from '@/services/tables/managers/tableColumnManagerInterface'
import type { PaginationData, PaginationParams } from '@/services/tables/pagination/types'
import type Counter from '@/data/models/tables/Counter'
import type { WrapperTableParamsToSave, WrapperTableServiceInterface } from '@/services/wrappers/table/types'
import type { Facetable } from '@/services/facets/types'
import type { TableExpandable, Historyable } from '@/services/route/types'
import type { NumbersObjectNullable, Nullable } from '@/types'
import type { TableRecoveryServiceInterface } from '@/services/tables/recovery/tableRecoveryServiceInterface'
import type { FilterCompareService } from '@/services/tables/filters/filterCompareService'

export type BaseSorterSettingsType = {
  defaultColumns: DefaultColumns
  defaultSorter?: SorterSettings
  entityName: string
}

export type TableFacetValues = NumbersObjectNullable

export type TableFacetSettings<F extends TableFacetValues = TableFacetValues> = {
  faceter: Facetable<F>
  facetKey: keyof F
}

/**
 * @property {string} name Sorter column name
 * @property {SortDirection} direction "desc" by default
 */
export type SorterSettings = {
  name: string
  direction?: SortDirection
}

export type SortDirectionWithRelation = {
  [relation: string]: {
    [field: string]: SortDirection
  }
}

export type PaginationUrlParametersSortType = Record<string, SortDirection | SortDirectionWithRelation[string]>
export type PaginationUrlParametersSortTypeWithoutDirection = Record<string, SortDirection>
export type PaginationUrlParametersGroupingType = PaginationUrlParametersSortType

export type PaginationUrlType<TOther extends PaginationUrlOtherType = PaginationUrlOtherType> = {
  filter: PaginationUrlFilterType
  sort?: PaginationUrlParametersSortType
  groupBy?: PaginationUrlParametersSortType
  columns?: PaginationUrlColumnType
  other?: TOther
}

export type PaginationUrlFilterScalarValueType = Partial<FilterOperationValue>
export type PaginationUrlFilterNullableScalarValueType = Nullable<PaginationUrlFilterScalarValueType>

export type PaginationUrlFilterScalarType = {
  [field: string]: PaginationUrlFilterScalarValueType
}

export type PaginationUrlFilterNullableScalarType = {
  [field: string]: PaginationUrlFilterNullableScalarValueType
}

export type PaginationUrlFilterValueType =
  | PaginationUrlFilterScalarValueType
  | Record<string, PaginationUrlFilterScalarValueType>
export type PaginationUrlFilterNullableValueType =
  | PaginationUrlFilterNullableScalarValueType
  | Nullable<Record<string, PaginationUrlFilterNullableScalarValueType>>

export type PaginationUrlColumnType = Array<{
  name: string
  order: number
  visible: boolean
}>
export type PaginationUrlFilterRelationType = {
  [relation: string]: PaginationUrlFilterScalarType
}
export type PaginationUrlFilterNullableRelationType = {
  [relation: string]: PaginationUrlFilterNullableScalarType
}
export type PaginationUrlFilterStrictType = Record<string, string | string[]>
export type PaginationUrlFilterSingleType = PaginationUrlFilterRelationType | PaginationUrlFilterScalarType
export type PaginationUrlFilterNullableSingleType =
  | PaginationUrlFilterNullableRelationType
  | PaginationUrlFilterNullableScalarType
export type PaginationUrlFilterType = PaginationUrlFilterSingleType[] // produced from baseFilters.toQuery
export type PaginationUrlFilterNullableType = PaginationUrlFilterNullableSingleType[]
export type PaginationUrlOtherType = Record<string, any>

export type UiStateUrlParams = { isActive?: boolean }

export const isPaginationUrlFilterScalarValueType = (value: unknown): value is PaginationUrlFilterScalarValueType => {
  return isRecordUnknown(value) && Object.keys(value).some((key) => isFilterOperation(key))
}

export const isPaginationUrlFilterScalarType = (value: unknown): value is PaginationUrlFilterScalarType => {
  return (
    isRecordUnknown(value) &&
    Object.values(value).every((possibleScalarValueType) =>
      isPaginationUrlFilterScalarValueType(possibleScalarValueType),
    )
  )
}

export const isPaginationUrlFilterNullableScalarType = (
  value: unknown,
): value is PaginationUrlFilterNullableScalarType => {
  return (
    isRecordUnknown(value) &&
    Object.values(value).every((possibleScalarValueType) => {
      return possibleScalarValueType === null || isPaginationUrlFilterScalarValueType(possibleScalarValueType)
    })
  )
}

export const isPaginationUrlFilterRelationType = (value: unknown): value is PaginationUrlFilterRelationType => {
  return (
    isRecordUnknown(value) &&
    Object.values(value).every((possibleScalarType) => isPaginationUrlFilterScalarType(possibleScalarType))
  )
}

export const isPaginationUrlFilterNullableRelationType = (
  value: unknown,
): value is PaginationUrlFilterNullableRelationType => {
  return (
    isRecordUnknown(value) &&
    Object.values(value).every((possibleScalarType) => isPaginationUrlFilterNullableScalarType(possibleScalarType))
  )
}

export const isPaginationUrlFilterSingleType = (value: unknown): value is PaginationUrlFilterType => {
  return isPaginationUrlFilterRelationType(value) || isPaginationUrlFilterScalarType(value)
}

export const isPaginationUrlFilterType = (value: unknown): value is PaginationUrlFilterType => {
  return Array.isArray(value) && value.every((item) => isPaginationUrlFilterSingleType(item))
}

export type ParsedFilterType = {
  name: string
  value: unknown
  operation: keyof typeof FilterOperationEnum
  relatedField?: string
}
export type FilteredViewsParsedBodySortType = { name: string; direction: SortDirection; relation?: string }
export type FilteredViewsParsedBodyGroupingType = { name: string; direction: GroupingDirection; relation?: string }

export type CounterInterface = {
  fetchCount(
    id: string,
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    paginationData?: PaginationData,
    searchQuery?: string,
    searchFields?: string[],
  ): Promise<void>
  getCount(id: string): Counter | null
}

export type TableBuilderSettings = {
  subscriptions: string[]
  tableModelId: string
  tableWrapperId: string
  bulkDeleteEndpoint?: Endpoint
  bulk: BulkManager
  subscription: ModelSubscriptionService
  notification: NotificationService
  history: Historyable
  recoveryService: TableRecoveryServiceInterface
  export: ExportService
  logger: LoggerService
  filters?: BaseFilterInterface
  filterServiceManager: FilterServiceManagerInterface
  sorterManager: TableSorterManagerInterface
  grouperManager: TableGrouperManagerInterface
  paginatorManager: TablePaginatorManagerInterface
  searcherManager: TableSearcherManagerInterface
  columnManager: TableColumnManagerInterface
  baseFilterService?: RegisteredServices
  serializer?: SerializerInterface
  entity: typeof BaseModel
  em: EntityManagerService
  domainManager: DomainManagerService
  preloaderManager: PreloaderManager
  filterCompareService: FilterCompareService
  tableExpandService?: TableExpandable
  gridService?: Gridable<BaseModel>
  tableConstructor?: BaseTableConstructor
  keyBuilder: TableKeyBuilderInterface
  counter?: CounterInterface
  tableWrapperService: WrapperTableServiceInterface
  tableWrapperParamsToSave?: WrapperTableParamsToSave
  facetSettings?: TableFacetSettings
  customFiltersDefaultState?: CustomFiltersDefaultState
  canBeRestored: boolean
  fastInsert: boolean
  noSelectAll: boolean
  allowSelectAllWithNonDefaultFilters: boolean
  shouldResetOnCreateOrDelete: boolean
}

export type ColumnsSettings = {
  tableId: string
}

export type TableFiltersPanelWrapperParams = {
  isShown: boolean
}

import { inject, injectable } from 'inversify'
import type { FormBuilderInterface, GroupModifierType } from '@/services/forms/baseForm/types'
import type { TypedGroupFormBuilderService } from '@/services/forms/baseForm/typedFormBuilder/TypedGroupFormBuilderService'
import { formNameBuilder } from '@/services/forms/baseForm/typedFormBuilder/helpers'
import type { AbstractFieldConfig } from '@/services/forms/types'
import type {
  ArrayBuilderInterface,
  PropertyBuilderInterface,
  TypeArrayConfig,
  TypedFieldConfig,
  TypedFromField,
  TypeGroupConfig,
} from '@/services/forms/baseForm/typedFormBuilder/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { TypedArrayFormBuilderService } from '@/services/forms/baseForm/typedFormBuilder/TypedArrayFormBuilderService'

@injectable()
export class GroupPropertyBuilder<T> implements PropertyBuilderInterface<T> {
  private formBuilder!: FormBuilderInterface

  private groupName!: string

  private fieldName!: string

  private groupModifier: GroupModifierType

  constructor(
    @inject(SERVICE_TYPES.TypedGroupFormBuilderFactoryService)
    protected readonly typedGroupFormBuilderFactoryService: <S>() => TypedGroupFormBuilderService<S>,
    @inject(SERVICE_TYPES.TypedArrayFormBuilderService)
    protected readonly typedArrayFormBuilderFactoryService: <S extends any[]>() => TypedArrayFormBuilderService<S>,
  ) {}

  public setDependencys(formBuilder: FormBuilderInterface, groupName: string, fieldName: string) {
    this.formBuilder = formBuilder
    this.groupName = groupName
    this.fieldName = fieldName

    this.groupModifier = this.formBuilder.getGroupModifier(this.groupName)
  }

  public control<TConfig extends AbstractFieldConfig<T>>(fieldConfig: TypedFieldConfig<TConfig>) {
    this.groupModifier.addControl({
      ...fieldConfig,
      name: this.fieldName,
    } as any)
  }

  public getField(): TypedFromField<T> {
    const baseForm = this.groupModifier.instance.childrens.find((t) => t.field.name === this.fieldName)!
    return baseForm.field as TypedFromField<T>
  }

  public getFieldName(): string {
    return formNameBuilder([this.groupName, this.fieldName])
  }

  public hasFieldTypeGroup(config?: TypeGroupConfig) {
    this.groupModifier.addGroup({
      name: this.fieldName,
      validators: config?.validators,
    })
    const typedGroupFormBuilderService = this.typedGroupFormBuilderFactoryService<T>()
    typedGroupFormBuilderService.setDependencys(this.formBuilder, formNameBuilder([this.groupName, this.fieldName]))
    return typedGroupFormBuilderService
  }

  public hasFieldTypeArray(config?: TypeArrayConfig): T extends any[] ? ArrayBuilderInterface<T> : never {
    this.groupModifier.addArray({
      name: this.fieldName,
      validators: config?.validators,
    })
    const typedArrayFormBuilderService = this.typedArrayFormBuilderFactoryService<any>()
    typedArrayFormBuilderService.setDependencys(this.formBuilder, formNameBuilder([this.groupName, this.fieldName]))
    return typedArrayFormBuilderService as any
  }

  public asFieldTypeArray(config?: TypeArrayConfig) {
    const typedArrayFormBuilderService = this.typedArrayFormBuilderFactoryService<any>()
    typedArrayFormBuilderService.setDependencys(this.formBuilder, formNameBuilder([this.groupName, this.fieldName]))
    return typedArrayFormBuilderService
  }
}

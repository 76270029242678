import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  // Repositories
  SandboxRepository,

  // Forms
  SandboxFormService,
  SandboxEmailEditorFormService,
}

export type SandboxServices = keyof typeof SERVICES

export const SANDBOX_SERVICE_TYPES: Record<SandboxServices, symbol> = makeServicesTypes<SandboxServices>(
  enumKeysToArray<SandboxServices>(SERVICES),
)

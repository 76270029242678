import { injectable } from 'inversify'
import Table from '@/data/models/tables/Table'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type AllSelectedTablesRepository from '@/data/repositories/table/allSelectedTablesRepository'

@RepoSettings<Endpoint>({
  model: Table,
})
@injectable()
export default class TableRepository extends OrmApiRepository<Table> {
  public allSelectedTablesRepo: AllSelectedTablesRepository

  public setIsRowsLoading(id: string, isRowsLoading: boolean) {
    return this.update([{ id, isRowsLoading }])
  }

  public getRowsLoading(id: string) {
    return this.findEntityByIdOrNull(id)?.isRowsLoading ?? false
  }

  public setIsDisabled(id: string, isDisabled: boolean) {
    return this.update([{ id, isDisabled }])
  }

  public isDisabled(id: string) {
    return this.findEntityByIdOrNull(id)?.isDisabled ?? false
  }

  public getTableInitFlag(id: string) {
    const entity = this.findEntityByIdOrNull(id)
    return entity ? entity.isInit : false
  }

  public setTableInitFlag(id: string, isInit: boolean) {
    this.update([{ id, isInit }])
  }

  public getIsExportInProgressFlag(id: string) {
    const raw = this.findEntityByIdOrNull(id)
    if (!raw) {
      return false
    }

    return raw.isExportInProgress
  }

  public setIsExportInProgressFlag(id: string, state: boolean) {
    this.update([{ id, isExportInProgress: state }])
  }

  public setCanBeRestored(id: string, value: boolean) {
    this.update([{ id, canBeRestored: value }])
  }

  public cleanUp(id: string) {
    this.reset(id)
  }

  public reset(id: string) {
    const entity = this.findEntityByIdOrNull(id)
    if (!entity) {
      this.insert([
        {
          id,
          isRowsLoading: false,
          isExpand: false,
          isShowFilters: false,
          canBeRestored: false,
        },
      ])
      return
    }
    this.update([
      {
        id,
        isRowsLoading: false,
        isExpand: false,
        isShowFilters: false,
        canBeRestored: false,
      },
    ])
  }

  public getLastSelectedRow(tableId: string) {
    return this.findEntityByIdOrNull(tableId)?.lastSelectedRow
  }

  public setLastSelectedRow(tableId: string, rowId: string) {
    this.update([{ id: tableId, lastSelectedRow: rowId }])
  }
}

import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { PosthogRecordingService } from '@/services/tracking/posthogRecordingService'

@injectable()
export class PosthogRecordingServiceResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.PosthogRecordingService) protected readonly posthogRecordingService: PosthogRecordingService,
  ) {}

  public async resolve(): Promise<void> {
    this.posthogRecordingService.init()
  }

  public async unresolve(): Promise<void> {
    this.posthogRecordingService.stop()
  }
}

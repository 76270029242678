import { inject, injectable } from 'inversify'
import type { Gridable } from '@/services/types'
import type { PaginationParams } from '@/services/tables/pagination/types'
import type { EndpointParams } from '@/services/endpoints'
import type { PaginationUrlType } from '@/services/tables/types'
import type SenderSettingsCountry from '@/data/models/domain/SenderSettingsCountry'
import type SenderSettingsCountriesService from '@/services/domain/senderSettings/senderSettingsCountriesService'
import type { SenderSettingsCategory } from '@/data/models/domain/SenderSettingsCountry'
import { SERVICE_TYPES } from '@/core/container/types'
import type StaticPaginationService from '@/services/tables/pagination/staticPaginationService'

@injectable()
export default class SenderSettingsCountriesGridable implements Gridable<SenderSettingsCountry> {
  constructor(
    @inject(SERVICE_TYPES.SenderSettingsCountriesService)
    protected readonly senderSettingsCountriesService: SenderSettingsCountriesService,
    @inject(SERVICE_TYPES.StaticPaginationService)
    protected readonly staticPaginationService: StaticPaginationService,
  ) {}

  private categories: SenderSettingsCategory[]

  public getFetchEndpointParams(): EndpointParams {
    return []
  }

  public async gridRequest(
    _queryParameterBag: PaginationUrlType,
    _paginationParamsBag?: PaginationParams,
    searchQuery?: string,
  ) {
    if (this.senderSettingsCountriesService.isEmptyStore()) {
      await this.senderSettingsCountriesService.loadCountries()
    }
    const countries = this.senderSettingsCountriesService.searchCountriesByCategories(this.categories, searchQuery)
    return this.staticPaginationService.all<SenderSettingsCountry>(countries)
  }

  public setCategories(categories: SenderSettingsCategory[]) {
    this.categories = categories
  }
}

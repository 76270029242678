import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/container'
import type UserService from '@/services/domain/user/userService'
import type TeamWorkflowService from '@/services/domain/accounts/teamWorkflowService'

@injectable()
export default class FetchAllAccountsResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.TeamWorkflowService) protected readonly teamWorkflowService: TeamWorkflowService,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {}

  public async resolve() {
    if (!this.teamWorkflowService.isUsersWorkflow()) {
      return
    }
    this.userService.fetchAllSubAccounts().catch(() => {})
  }
}

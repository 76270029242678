import { get, initial, omit, set } from 'lodash-es'

export const omitAndCleanArray = (obj: Record<string, any>, keys: string[]): Record<string, any> => {
  const filtered = omit(obj, keys)
  for (const key of keys) {
    if (key.split('.').length > 1) {
      const parentKey = initial(key.split('.')).join('.')
      const item = get(filtered, parentKey)
      if (Array.isArray(item)) {
        set(
          filtered,
          parentKey,
          item.filter((val: any) => !!val),
        )
      }
    }
  }

  return filtered
}

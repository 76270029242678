import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import TollFreePhoneNumber from '@/data/models/domain/services/regulations/TollFreePhoneNumber'

@RepoSettings<Endpoint>({
  model: TollFreePhoneNumber,
  fetch: 'tollFreePhoneNumbers',
  single: 'phoneNumbersSingle',
})
@injectable()
export default class TollFreePhoneNumbersRepository extends OrmApiRepository<TollFreePhoneNumber> {}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmLocalStorageRepository from '@/data/repositories/ormLocalStorageRepository'
import Visibility from '@/data/models/Visibility'

@RepoSettings({
  model: Visibility,
  fetch: 'Visibility',
  single: 'Visibility',
})
@injectable()
export default class VisibilityRepository extends OrmLocalStorageRepository<Visibility> {
  public async setVisibility(key: string, newVisibility: Record<string, boolean>) {
    await this.postRequest({
      id: key,
      visibility: newVisibility,
    })
    await this.fill(key)
  }

  public getVisibility(key: string) {
    return this.find(key)
  }
}

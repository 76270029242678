import { AttrField, NumberField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { SessionSource, SessionDestination } from '@/types'

@OrmModel(modelNames.SESSION_SHORT)
export default class SessionShort extends BaseModel {
  @StringField()
  public title: string

  @StringField()
  public startTime: string

  @StringField()
  public text: string

  @NumberField()
  public price: number

  @NumberField()
  public numbersCount: number

  @StringField()
  public destination: SessionDestination

  @StringField()
  public source: SessionSource

  @StringField()
  public referenceId: string

  @AttrField(null, (t: unknown) => {
    if (typeof t === 'number') {
      return t.toString()
    }
    return t
  })
  public initiatorId: string | null
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  AccountsService,
  AccountDetailsFormService,
  AccountSsoDetailsFormService,
  AccountSsoSettingsService,
  AccountSsoSettingsRepository,
  LoginsHistoryColumnService,
  AccountSecurityTwoFaEditIpAddressesFormService,
  AccountSecurityPasswordChangeFormService,
  AccountSettingsSecurityService,
  AccountSettingsSecurityRepository,
  AccountSettingsLoginHistoryGridable,
  LoginHistoryService,
  AccountSettingsPhoneVerificationFormService,
  LoginHistoryRepository,
  PasswordVerificationFormService,
  EmailVerificationFormService,
  TwoFactorSetupStrategy,
  CloseAccountFormService,
}

export type AccountSettingsServices = keyof typeof SERVICES

export const ACCOUNT_SETTINGS_SERVICE_TYPES: Record<AccountSettingsServices, symbol> =
  makeServicesTypes<AccountSettingsServices>(enumKeysToArray<AccountSettingsServices>(SERVICES))

const getPath = Symbol('getPathKey')
export function getTreePathForObjectField<T>(callback: (t: T) => any) {
  const getNewProxyObject = (parentPath: string[]) =>
    new Proxy<any>(
      {},
      {
        get(target: Record<string, unknown>, key: string | symbol, receiver: any): any {
          if (key === getPath) {
            return parentPath
          }
          const pathToField = [...parentPath]
          if (parentPath !== null) {
            pathToField.push(key as string)
          }
          if (!Reflect.has(target, key)) {
            Reflect.set(target, key, getNewProxyObject(pathToField))
          }
          return Reflect.get(target, key, receiver)
        },
      },
    )
  const proxyObject = getNewProxyObject([])
  const callbackRes = callback(proxyObject)
  return callbackRes[getPath] as string[]
}

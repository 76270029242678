<template>
  <div class="tm-table-container">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/table';

.tm-table-container {
  border: var(--tm-table-border-width) solid var(--tm-table-border-color);
  border-radius: var(--tm-table-border-radius);
  box-shadow: $table-shadow;
  overflow: clip;
  box-sizing: content-box;

  &:has(.tm-table.tm-table--loading) {
    mask-image: linear-gradient(to bottom, #fff 0%, #fff 0%, transparent);
  }
}
</style>

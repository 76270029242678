import type { RouteRecordRaw } from 'vue-router'
import { TasksRoutes } from '@/routes/user/tasks/types'
import type { NotFoundResolverParams } from '@/services/resolvers/types'
import TaskBoard from '@/data/models/domain/tasks/TaskBoard'
import TaskBoardArchived from '@/data/models/domain/tasks/TaskBoardArchived'

export const getTasksSettingsRoutes = (): RouteRecordRaw[] => [
  {
    name: TasksRoutes.settingsBase,
    path: '',
    meta: {
      resolvers: [
        {
          service: 'TaskSettingsResolver',
        },
        {
          service: 'FetchAllAccountsResolver',
        },
      ],
      resolverComponent: () => import('@/components/resolvers/SectionSettingsResolver.vue'),
    },
    redirect: { name: TasksRoutes.settings },
    children: [
      {
        name: TasksRoutes.settings,
        path: 'overview',
        component: () => import('@/components/views/tasks/TasksSettingsAll.vue'),
      },
      {
        name: TasksRoutes.settingsBoard,
        path: ':boardId',
        props: true,
        redirect: { name: TasksRoutes.settingsBoardDetails },
        children: [
          {
            name: TasksRoutes.settingsBoardDetails,
            props: true,
            path: '',
            component: () => import('@/components/views/tasks/TasksSettingsBoard.vue'),
            meta: {
              resolvers: [
                {
                  service: 'RedirectableSingleModelResolverService',
                  params: {
                    requestParam: 'boardId',
                    model: TaskBoard,
                    source: 'router',
                    redirectToIfNotFound: { name: TasksRoutes.settings },
                  } as NotFoundResolverParams,
                },
              ],
              resolverComponent: () => import('@/components/resolvers/SectionSettingsResolver.vue'),
            },
          },
          {
            name: TasksRoutes.settingsBoardArchivedDetails,
            props: true,
            path: 'archived',
            component: () => import('@/components/views/tasks/TasksSettingsBoard.vue'),
            meta: {
              resolvers: [
                {
                  service: 'RedirectableSingleModelResolverService',
                  params: {
                    requestParam: 'boardId',
                    model: TaskBoardArchived,
                    source: 'router',
                    redirectToIfNotFound: { name: TasksRoutes.settings },
                  } as NotFoundResolverParams,
                },
              ],
              resolverComponent: () => import('@/components/resolvers/SectionSettingsResolver.vue'),
            },
          },
          {
            name: TasksRoutes.settingsStages,
            props: true,
            path: 'stages',
            meta: {
              resolvers: [
                {
                  service: 'RedirectableSingleModelResolverService',
                  params: {
                    requestParam: 'boardId',
                    model: TaskBoard,
                    source: 'router',
                    redirectToIfNotFound: { name: TasksRoutes.settings },
                  } as NotFoundResolverParams,
                },
              ],
              resolverComponent: () => import('@/components/resolvers/SectionSettingsResolver.vue'),
            },
            component: () => import('@/components/views/tasks/TasksSettingsStages.vue'),
          },
        ],
      },
    ],
  },
]

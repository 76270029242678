import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type RouterService from '@/services/route/routerService'
import type FormSubscribeDetailsService from '@/services/domain/services/forms/subscribe/FormSubscribeDetailsService'

@injectable()
export class WidgetEmbedCodeResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
    @inject(SERVICE_TYPES.FormSubscribeDetailsService)
    protected readonly formSubscribeDetailsService: FormSubscribeDetailsService,
  ) {}

  public async resolve() {
    try {
      const { code } = this.routerService.getCurrentRoute().params
      return await this.formSubscribeDetailsService.getEmbedData(String(code))
    } catch {
      await this.routerService.replace('/')
      return undefined
    }
  }
}

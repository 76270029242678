import { inject, injectable } from 'inversify'
import { type RegisteredServices, SERVICE_TYPES } from '@/core/container/types'
import type { ModelSearcherSettings, SearcherInterface } from '@/services/search/types'
import type LoggerService from '@/services/loggerService'
import TmLogicError from '@/core/error/tmLogicError'
import type { LoggerChannels } from '@/config/configDev'
import type { Factory } from '@/types'

@injectable()
export default class ModelSearcherManager {
  protected readonly managerId = 'ModelSearcherManager'

  protected readonly loggerChannel: LoggerChannels = 'search'

  protected services: Record<string, SearcherInterface<ModelSearcherSettings>> = {}

  private constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.ModelSearcherServiceFactory)
    protected modelSearcherServiceFactory: Factory<SearcherInterface<ModelSearcherSettings>>,
  ) {}

  public addSearchFactory(
    name: RegisteredServices,
    settings: ModelSearcherSettings,
  ): SearcherInterface<ModelSearcherSettings> {
    if (!this.hasFactory(name)) {
      this.services[name] = this.modelSearcherServiceFactory()
      this.services[name].setSettings(settings)
      this.services[name].init({ search: '' })

      this.loggerService.log(this.loggerChannel, `Added model search service ${name}`)
    }

    return this.services[name]
  }

  public getSearchFactory(name: RegisteredServices): SearcherInterface<ModelSearcherSettings> {
    if (!this.hasFactory(name)) {
      throw new TmLogicError(`No model search service found with name "${name}"`)
    }

    return this.services[name]
  }

  public removeSearchFactory(name: RegisteredServices): void {
    if (this.hasFactory(name)) {
      this.loggerService.log(this.loggerChannel, `Remove model search service ${name}`)
      delete this.services[name]
    }
  }

  public hasFactory(name: RegisteredServices): boolean {
    return !!this.services[name]
  }
}

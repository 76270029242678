import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/container'
import { withUniqueKeys } from '@/decorators/resolvers/withUniqueKeys'
import type { UniqueKeys } from '@/types'
import type FacebookPageService from '@/services/domain/messenger/facebook/facebookPageService'
import type RouterService from '@/services/route/routerService'
import { facebookSettingsRoute } from '@/routes/user/messenger/facebook'

@withUniqueKeys('ChatFacebookResolver')
@injectable()
export default class ChatFacebookResolver implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.FacebookPageService) protected readonly facebookPageService: FacebookPageService,
    @inject(SERVICE_TYPES.RouterService) protected readonly routerService: RouterService,
  ) {}

  public async resolve() {
    if (this.routerService.currentRoute.value.name !== facebookSettingsRoute.name) {
      // ignore the facebookSettingsRoute route because we have request table data there
      const page = this.facebookPageService.firstOrNull()
      if (!page) {
        await this.facebookPageService.fillAll()
      }
    }
  }

  public async unresolve() {}

  public getResolverKeys(): UniqueKeys {
    return []
  }
}

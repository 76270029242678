import { inject, injectable } from 'inversify'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import type { ModelRaw } from '@/types'
import Wizard from '@/data/models/domain/Wizard'
import type { WizardApplyBody, WizardPricesResponse } from '@/services/domain/wizard/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type SerializerService from '@/services/serializerService'
import type { HttpService } from '@/services/transport/httpService'
import type { AbstractEndpointsInterface } from '@/services/endpointsService'
import type LoggerService from '@/services/loggerService'
import type FilterQueryService from '@/services/filterQueryService'

@RepoSettings<Endpoint>({
  model: Wizard,
})
@injectable()
export default class WizardRepository extends OrmApiRepository<Wizard> {
  constructor(
    @inject(SERVICE_TYPES.SerializerService) protected readonly serializerService: SerializerService,
    @inject(SERVICE_TYPES.Api) protected readonly api: HttpService,
    @inject(SERVICE_TYPES.EndpointsService) protected readonly endpointsService: AbstractEndpointsInterface,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.FilterQueryService) protected readonly filterQueryService: FilterQueryService,
  ) {
    super(serializerService, api, endpointsService, loggerService, filterQueryService)
  }

  private id = '1'

  public async getWizardState() {
    const res = await this.getApiSource().get<ModelRaw<Wizard>>(this.endpointsService.getPath('wizardState'))
    this.insert([{ ...res.data, id: this.id }])
    return this.findEntityByIdOrFail(this.id)
  }

  public findWizardState(): Wizard | null {
    return this.find(this.id)
  }

  public async apply(body: WizardApplyBody): Promise<Wizard> {
    const path = this.endpointsService.getPath('wizardApply')
    const res = await this.getApiSource().post(path, body)
    const data: Wizard = res.data as any
    await this.updateWizardState(data)
    return this.findWizardState()!
  }

  public async getWizardPrices() {
    const res = await this.getApiSource().get<WizardPricesResponse>(this.endpointsService.getPath('wizardPrices'))
    return res.data
  }

  public updateWizardState(wizard: Pick<Wizard, 'currentStep' | 'flow'>) {
    return this.insertOrUpdate([{ ...wizard, id: this.id }])
  }
}

import type { CustomErrorRule, CustomErrorRulePredicateFn } from '@/core/error/transport/types'
import { EndpointsEnum } from '@/services/endpoints'
import { TmApiDealActivityNotFoundError } from '@/core/error/transport/customErrors/tmApiDealActivityNotFoundError'

export const dealActivityNotFoundErrorRule: CustomErrorRule = {
  klass: TmApiDealActivityNotFoundError,
  methods: ['put', 'post'],
  predicateFn: (error) => {
    return isChecklistUpdate(error) || isNewChecklistItem(error)
  },
}

const isChecklistUpdate: CustomErrorRulePredicateFn = (error) => {
  const endpointUrl = error.config.url

  if (!endpointUrl) {
    return false
  }

  const regexSingle = new RegExp(`${EndpointsEnum.dealActivitySingle.replace('{0}', '([0-9]+)')}`)
  return error.config.method === 'put' && regexSingle.test(endpointUrl)
}

const isNewChecklistItem: CustomErrorRulePredicateFn = (error) => {
  const endpointUrl = error.config.url

  if (!endpointUrl) {
    return false
  }

  const regex = new RegExp(`${EndpointsEnum.dealActivityItems.replace('{0}', '([0-9]+)')}`)
  return error.config.method === 'post' && regex.test(endpointUrl)
}

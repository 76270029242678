/**
 * Remove property from object
 *
 * @param obj Object
 * @param propName Name of property to remove
 */
export const deleteProp = <T extends Record<string, any>>(obj: unknown, propName: string) => {
  delete (obj as Record<string, any>)[propName]
  return obj as T
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import UserSettings from '@/data/models/domain/UserSettings'
import type UserSettingsRepository from '@/data/repositories/domain/userSettingsRepository'
import type UserService from '@/services/domain/user/userService'

@DomainSettings({
  model: UserSettings,
})
@injectable()
export default class UserSettingsService extends DomainBaseService<UserSettingsRepository> {
  constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
    @inject(SERVICE_TYPES.ModelSubscriptionService) protected readonly subscriptionService: ModelSubscriptionService,
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.UserService) protected readonly userService: UserService,
  ) {
    super(entityManager, subscriptionService, preloaderManager)
  }

  public currentUserSettingsOrFail() {
    return this.findEntityByIdOrFail(this.userService.currentUser().id, ['subscriptions'])
  }

  public currentUserSettingsOrNull() {
    try {
      return this.findEntityByIdOrNull(this.userService.currentUser().id, ['subscriptions'])
    } catch {
      return null
    }
  }

  public updateCurrentUserSettingsInStore(settings: Partial<Omit<UserSettings, 'id'>>) {
    const currentUserId = this.currentUserSettingsOrFail().id
    return this.getDomainRepository().insertOrUpdate([{ ...settings, id: currentUserId }])
  }
}

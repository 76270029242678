import type {
  QuasarIconSet,
  QuasarIconSetArrow,
  QuasarIconSetCarousel,
  QuasarIconSetChevron,
  QuasarIconSetChip,
  QuasarIconSetColorPicker,
  QuasarIconSetDatetime,
  QuasarIconSetEditor,
  QuasarIconSetExpansionItem,
  QuasarIconSetFab,
  QuasarIconSetField,
  QuasarIconSetPagination,
  QuasarIconSetPullToRefresh,
  QuasarIconSetRating,
  QuasarIconSetStepper,
  QuasarIconSetTable,
  QuasarIconSetTabs,
  QuasarIconSetTree,
  QuasarIconSetType,
  QuasarIconSetUploader,
} from 'quasar/dist/types/extras/icon-set'
import type { IconName } from '@/assets/icons/icons'

type StringIconDictionary<T extends string> = Required<{
  [index in T]: IconName
}>

interface CustomQuasarIconSet {
  name: string
  type: StringIconDictionary<QuasarIconSetType>
  arrow: StringIconDictionary<QuasarIconSetArrow>
  chevron: StringIconDictionary<QuasarIconSetChevron>
  colorPicker: StringIconDictionary<QuasarIconSetColorPicker>
  pullToRefresh: StringIconDictionary<QuasarIconSetPullToRefresh>
  carousel: StringIconDictionary<QuasarIconSetCarousel>
  chip: StringIconDictionary<QuasarIconSetChip>
  datetime: StringIconDictionary<QuasarIconSetDatetime>
  editor: StringIconDictionary<QuasarIconSetEditor>
  expansionItem: StringIconDictionary<QuasarIconSetExpansionItem>
  fab: StringIconDictionary<QuasarIconSetFab>
  field: StringIconDictionary<QuasarIconSetField>
  pagination: StringIconDictionary<QuasarIconSetPagination>
  rating: StringIconDictionary<QuasarIconSetRating>
  stepper: StringIconDictionary<QuasarIconSetStepper>
  tabs: StringIconDictionary<QuasarIconSetTabs>
  table: StringIconDictionary<QuasarIconSetTable>
  tree: StringIconDictionary<QuasarIconSetTree>
  uploader: StringIconDictionary<QuasarIconSetUploader>
}

export const quasarIconSet: QuasarIconSet = {
  name: 'material-icons-round',
  type: {
    positive: 'check_circle',
    negative: 'warning',
    info: 'info',
    warning: 'priority_high',
  },
  arrow: {
    up: 'arrow_upward',
    right: 'arrow_forward',
    down: 'arrow_downward',
    left: 'arrow_back',
    dropdown: 'tmi-arrow-down',
  },
  chevron: {
    left: 'tmi-chevron-left',
    right: 'tmi-chevron-right',
  },
  colorPicker: {
    spectrum: 'gradient',
    tune: 'tune',
    palette: 'style',
  },
  pullToRefresh: {
    icon: 'refresh',
  },
  carousel: {
    left: 'tmi-chevron-left',
    right: 'tmi-chevron-right',
    up: 'keyboard_arrow_up',
    down: 'keyboard_arrow_down',
    navigationIcon: 'lens',
  },
  chip: {
    remove: 'cancel',
    selected: 'check',
  },
  datetime: {
    arrowLeft: 'tmi-chevron-left',
    arrowRight: 'tmi-chevron-right',
    now: 'access_time',
    today: 'today',
  },
  editor: {
    bold: 'format_bold',
    italic: 'format_italic',
    strikethrough: 'strikethrough_s',
    underline: 'format_underlined',
    unorderedList: 'format_list_bulleted',
    orderedList: 'format_list_numbered',
    subscript: 'vertical_align_bottom',
    superscript: 'vertical_align_top',
    hyperlink: 'link',
    toggleFullscreen: 'fullscreen',
    quote: 'format_quote',
    left: 'format_align_left',
    center: 'format_align_center',
    right: 'format_align_right',
    justify: 'format_align_justify',
    print: 'print',
    outdent: 'format_indent_decrease',
    indent: 'format_indent_increase',
    removeFormat: 'format_clear',
    formatting: 'text_format',
    fontSize: 'format_size',
    align: 'format_align_left',
    hr: 'remove',
    undo: 'undo',
    redo: 'redo',
    heading: 'format_size',
    heading1: 'none',
    heading2: 'none',
    heading3: 'none',
    heading4: 'none',
    heading5: 'none',
    heading6: 'none',
    code: 'code',
    size: 'format_size',
    size1: 'none',
    size2: 'none',
    size3: 'none',
    size4: 'none',
    size5: 'none',
    size6: 'none',
    size7: 'none',
    font: 'font_download',
    viewSource: 'code',
  },
  expansionItem: {
    icon: 'keyboard_arrow_down',
    denseIcon: 'tmi-arrow-down',
  },
  fab: {
    icon: 'add',
    activeIcon: 'close',
  },
  field: {
    clear: 'cancel',
    error: 'error',
  },
  pagination: {
    first: 'first_page',
    prev: 'tmi-chevron-left',
    next: 'tmi-chevron-right',
    last: 'last_page',
  },
  rating: {
    icon: 'grade',
  },
  stepper: {
    done: 'check',
    active: 'edit',
    error: 'warning',
  },
  tabs: {
    left: 'tmi-chevron-left',
    right: 'tmi-chevron-right',
    up: 'keyboard_arrow_up',
    down: 'keyboard_arrow_down',
  },
  table: {
    arrowUp: 'arrow_upward',
    warning: 'warning',
    firstPage: 'first_page',
    prevPage: 'tmi-chevron-left',
    nextPage: 'tmi-chevron-right',
    lastPage: 'last_page',
  },
  tree: {
    icon: 'play_arrow',
  },
  uploader: {
    done: 'done',
    clear: 'clear',
    add: 'add_box',
    upload: 'cloud_upload',
    removeQueue: 'clear_all',
    removeUploaded: 'done_all',
  },
} satisfies CustomQuasarIconSet

import { inject, injectable } from 'inversify'
import type { HttpInterceptor, InternalErrorResponse, HttpInterceptorResponseError } from '@/services/transport/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type WindowService from '@/services/browser/windowService'
import { TmApiPreconditionFailedError } from '@/core/error/transport/tmApiPreconditionFailedError'
import type UserSettingsService from '@/services/domain/user/userSettingsService'

@injectable()
export default class ImpersonationModeInterceptor implements HttpInterceptor<any, InternalErrorResponse> {
  constructor(
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.UserSettingsService) protected readonly userSettingsService: UserSettingsService,
  ) {}

  public async responseError(error: HttpInterceptorResponseError<InternalErrorResponse>) {
    const isImpersonateModeActive = this.userSettingsService.currentUserSettingsOrNull()?.isImpersonated

    if (isImpersonateModeActive && error instanceof TmApiPreconditionFailedError) {
      this.windowService.self().location.reload()
    }
    return Promise.reject(error)
  }
}

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum VERIFY_EMAIL_SERVICES {
  EditEmailVerificationFormService,
  VerifyEmailFormService,

  VerifyEmailResolver,
}

export type VerifyEmailService = keyof typeof VERIFY_EMAIL_SERVICES

export const VERIFY_EMAIL_SERVICE_TYPES = makeServicesTypesFromEnum<VerifyEmailService>(VERIFY_EMAIL_SERVICES)

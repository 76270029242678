import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'

export const campaignsScheduledCompletedSmsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.completed.sms',
  path: 'sms',
  component: () => import('@/components/views/campaigns/scheduled/completed/CampaignsScheduledCompletedSms.vue'),
  meta: {
    modules: [AppModule.ScheduledCompleted],
  },
}

export const campaignsScheduledCompletedEmailRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.completed.email',
  path: 'email',
  component: () => import('@/components/views/campaigns/email/CampaignsEmailStub.vue'),
}

export const campaignsScheduledCompletedRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.completed',
  path: 'completed',
  component: () => import('@/components/views/campaigns/scheduled/completed/CampaignsScheduledCompleted.vue'),
  children: [campaignsScheduledCompletedSmsRoute, campaignsScheduledCompletedEmailRoute],
  redirect: { name: campaignsScheduledCompletedSmsRoute.name },
  meta: {
    tabTitle: 'pageTabTitle.user.campaigns.scheduled.completed',
  },
}

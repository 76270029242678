import { inject, injectable } from 'inversify'
import type { FormBuilderInterface, ArrayModifierType } from '@/services/forms/baseForm/types'
import type {
  ArrayBuilderInterface,
  ArrayCommandsInterface,
  TypedFieldConfig,
  TypedFormBuilderInterface,
  TypeGroupConfig,
} from '@/services/forms/baseForm/typedFormBuilder/types'
import type { AbstractFieldConfig } from '@/services/forms/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { TypedGroupFormBuilderService } from '@/services/forms/baseForm/typedFormBuilder/TypedGroupFormBuilderService'
import { formNameBuilder } from '@/services/forms/baseForm/typedFormBuilder/helpers'

@injectable()
export class TypedArrayFormBuilderService<TFormScheme extends any[]>
  implements ArrayBuilderInterface<TFormScheme>, ArrayCommandsInterface<TFormScheme>
{
  private formBuilder!: FormBuilderInterface

  private arrayName!: string

  private arrayModifier: ArrayModifierType

  constructor(
    @inject(SERVICE_TYPES.TypedGroupFormBuilderFactoryService)
    protected readonly typedGroupFormBuilderFactoryService: <S>() => TypedGroupFormBuilderService<S>,
  ) {}

  public setDependencys(formBuilder: FormBuilderInterface, arrayName: string) {
    this.formBuilder = formBuilder
    this.arrayName = arrayName

    this.arrayModifier = this.formBuilder.getArrayModifier(this.arrayName)
  }

  public control<TConfig extends AbstractFieldConfig>(fieldConfig: TypedFieldConfig<TConfig>): void {
    const field = this.formBuilder.getField(this.arrayName)
    if (field.childrens.length === 0) {
      this.arrayModifier.initControl({
        ...fieldConfig,
      } as any)
    }
    this.formBuilder.extendArray(this.arrayName)
  }

  public groupControl(config?: TypeGroupConfig): TypedFormBuilderInterface<TFormScheme[0]> {
    const instance = this.arrayModifier.initGroup(config).instance
    const typedGroupFormBuilderService = this.typedGroupFormBuilderFactoryService<TFormScheme[0]>()
    const name = formNameBuilder([this.arrayName, instance.field.name])
    typedGroupFormBuilderService.setDependencys(this.formBuilder, name)
    return typedGroupFormBuilderService
  }

  public push(value: TFormScheme extends any[] ? TFormScheme[number] : never): void {
    this.formBuilder.extendArray(this.arrayName)
    // @todo implement set value
  }

  public remove(index: number): void {
    const field = this.formBuilder.getField(this.arrayName)
    const fieldName = field.childrens[index].field.name
    this.formBuilder.removeField(`${this.arrayName}.${fieldName}`)
  }
}

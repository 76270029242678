import { isObject, merge, reduce } from 'lodash-es'

/**
 * Transform object to dot notated object
 * Example:
 *
 * { a: { b: 'c' } } --> { 'a.b': 'c'}
 * @param obj Source object
 * @param path prefix path for keys
 * @param {boolean} keepArray Arrays remains as arrays if this flag is true
 * @param {string} sep Replace dot with another separator
 * @returns {object} Dotted object
 */
const _toDotNotation = (obj: any, path: string[] = [], keepArray = true, sep = '.'): any =>
  !isObject(obj) || (keepArray && Array.isArray(obj))
    ? { [path.join(sep)]: obj }
    : reduce(obj, (acc, next, key) => merge(acc, _toDotNotation(next, [...path, key], keepArray, sep)), {})

export const toDotNotation = (obj: any, path: string[] = [], keepArray = true, sep = '.'): any =>
  Array.isArray(obj)
    ? obj.map((o) => _toDotNotation(o, path, keepArray, sep))
    : _toDotNotation(obj, path, keepArray, sep)

import { AttrField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type {
  IUserSubscriptionMetadata,
  UserSubscriptionName,
  UserSubscriptionStatus,
} from '@/services/domain/accounts/types'

@OrmModel(modelNames.TEAM_SUBSCRIPTION)
export default class TeamSubscription extends BaseModel {
  @StringField()
  public id: string

  @StringField()
  public name: UserSubscriptionName

  @StringField()
  public status: UserSubscriptionStatus

  @AttrField()
  public metadata: IUserSubscriptionMetadata
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  PaymentVeriffIdentityFormService,
  FirstPaymentResolverService,

  PaymentCongratsRedirectResolverService,
}

export type PaymentServices = keyof typeof SERVICES

export const PAYMENT_SERVICE_TYPES: Record<PaymentServices, symbol> = makeServicesTypes<PaymentServices>(
  enumKeysToArray<PaymentServices>(SERVICES),
)

import { AttrField, HasManyByField, OrmModel, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import Task from '@/data/models/domain/tasks/Task'
import KanbanStageBase from '@/data/models/domain/kanban/kanbanStageBase'

@OrmModel(modelNames.TASK_STAGE)
export default class TaskStage extends KanbanStageBase {
  @StringField(null, (value) => `${value}`)
  public boardId: string

  @AttrField([], (value: number[]) => value?.map((item) => item.toString()))
  public taskIds: string[]

  @HasManyByField(Task, 'taskIds')
  public tasks: Task[]
}

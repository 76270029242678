<template>
  <q-tooltip-fixed
    ref="qTooltip"
    v-bind="attrs"
    class="tm-tooltip"
    :class="[
      wrapperClass,
      {
        'tm-tooltip--white': white,
        'tm-tooltip--hide-arrow': hideArrow,
        'tm-tooltip--full-filled': fullFilled,
        'tm-tooltip--unset-max-height': unsetMaxHeight, // not used
        'tm-tooltip--pointer-event-auto': isAllowPointerEvents,
        'tm-tooltip--default-padding': isDefaultPadding,
      },
    ]"
    :max-width="maxWidth"
    :delay="delayed ? delayTime : 0"
    :hide-delay="hideDelay"
    transition-show="fade"
    transition-hide="fade"
    @before-show="beforeShow"
    @before-hide="beforeHide"
    @hide="hide"
  >
    <div
      ref="innerMenuRef"
      class="tm-tooltip__main"
      :class="contentClass"
    >
      <slot>{{ text }}</slot>
    </div>
  </q-tooltip-fixed>
</template>

<script lang="ts">
import type { PropType } from '@/composition/vue/compositionApi'
import { computed, defineComponent, nextTick, ref } from '@/composition/vue/compositionApi'
import type { ControlPosition } from '@/composition/tooltip/renderTooltip'
import { getTooltipAngleAttrs, renderTooltip } from '@/composition/tooltip/renderTooltip'
import { delayOptions } from '@/components/shared/types'
import { QTooltipFixed } from '@/components/shared/tooltip/QTooltipFixed'
import { VueLikeClassProp } from '@/components/types'

export default defineComponent({
  components: { QTooltipFixed },
  props: {
    text: {
      type: String,
    },
    maxWidth: {
      type: String,
      default: '285px',
    },
    wrapperClass: {
      type: [String, Object],
    },
    delayed: {
      type: Boolean,
    },
    delayTime: {
      type: Number,
      default: delayOptions.default,
    },
    hideDelay: {
      type: Number,
    },
    white: {
      type: Boolean,
    },
    hideArrow: {
      type: Boolean,
    },
    fullFilled: {
      type: Boolean,
    },
    unsetMaxHeight: {
      type: String,
    },
    target: {
      type: HTMLElement,
    },
    offset: {
      type: Array as PropType<number[]>,
    },
    offsetWithAngle: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String as PropType<ControlPosition>,
      default: 'top',
    },
    contentClass: {
      type: VueLikeClassProp,
      default: 'text-left',
    },
    isAllowPointerEvents: {
      type: Boolean,
      default: false,
    },
    isDefaultPadding: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    show: (_: HTMLDivElement) => true,
    'before-hide': () => true,
  },
  setup(props, context) {
    const qTooltip = ref()
    const innerMenuRef = ref()
    const { show, hide } = renderTooltip('tm-tooltip')
    const angleAttrs = getTooltipAngleAttrs('tm-tooltip', props.position, props.offsetWithAngle)

    const attrs = computed(() => ({
      ...angleAttrs,
      ...context.attrs,
      offset: props.offset || angleAttrs.offset,
    }))

    const getAnchorEl = (el: HTMLElement | null) => {
      let anchorEl = el
      while (anchorEl?.classList.contains('q-anchor--skip')) {
        anchorEl = anchorEl?.parentElement as HTMLElement
      }
      return anchorEl
    }

    const beforeShow = async () => {
      const anchorEl = props.target || getAnchorEl(qTooltip.value?.$el?.parentElement)
      if (!anchorEl) {
        return
      }
      // for correct angle display need event between before-show and show, one nextTick not enough
      await nextTick()
      await nextTick()
      const menuEl = innerMenuRef.value.parentElement
      context.emit('show', menuEl)
      show({
        anchorEl,
        menuEl,
        attrs: attrs.value,
      })
    }

    const beforeHide = () => {
      context.emit('before-hide')
    }

    return {
      attrs,
      qTooltip,
      innerMenuRef,
      beforeShow,
      beforeHide,
      hide,
    }
  },
})
</script>

<!-- eslint-disable-next-line vue/enforce-style-attribute -->
<style lang="scss">
// Here we have non-scoped style because dropdown appears at the end on body without special uniq attr
// so scoped styles doesn't work
@import '@/styles/mixins.scss';

.tooltip-title {
  font-weight: $bold-font;
  padding-bottom: 2px;
}
.w270 {
  width: 270px;
}
.w420 {
  width: 420px;
}
.pa-10px {
  padding: 10px !important;
}
.tm-tooltip {
  @include offset-tooltip();
}
.tm-tooltip {
  --angle-color: #{$gray9};
  --angle-border-color: transparent;
  display: flex !important;
  flex-flow: column;
  overflow: unset !important;
  box-shadow: unset !important;
  background: unset !important;
  border-radius: unset !important;

  &.white,
  &--white {
    --angle-color: #{$white};
    --angle-border-color: #{$gray3};
  }

  &--full-filled {
    width: 100%;
  }

  &--unset-max-height {
    max-height: unset !important;
  }

  &--pointer-event-auto {
    pointer-events: auto !important;
  }

  &--default-padding {
    .tm-tooltip__main {
      padding: 4px 8px;
    }
  }

  &__main {
    position: relative;
    width: 100%;
    font-weight: $regular-font;
    font-size: $sm-x-font;
    line-height: 20px;
    word-break: break-word;
    color: $white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    background: $gray9;
    overflow: auto;

    .tm-tooltip.white &,
    .tm-tooltip--white & {
      color: $neutral;
      font-weight: $regular-font;
      background: $white;
    }
  }
}
</style>

import { injectable } from 'inversify'
import type { ModelRaw } from '@/types'
import type { PaginationOffsetResponse } from '@/services/tables/pagination/types'
import type BaseModel from '@/data/models/BaseModel'

@injectable()
export default class StaticPaginationService {
  public slice<T extends BaseModel>(allItems: ModelRaw<T>[], page = 1, perPage = 10): PaginationOffsetResponse<T> {
    const items = this.sliceItems(allItems, page, perPage)
    return this.formatPaginationOffsetResponse<T>(items, allItems.length, page, perPage)
  }

  public all<T extends BaseModel>(allItems: ModelRaw<T>[]): PaginationOffsetResponse<T> {
    return this.formatPaginationOffsetResponse<T>(allItems, allItems.length, 1, allItems.length)
  }

  public formatPaginationOffsetResponse<T extends BaseModel>(
    items: ModelRaw<T>[],
    totalCount: number,
    page: number,
    perPage: number,
  ): PaginationOffsetResponse<T> {
    return {
      items,
      pagination: {
        type: 'offset',
        perPage,
        page,
        totalCount,
        pageCount: Math.ceil(totalCount / perPage),
        nextId: '',
        prevId: '',
      },
    }
  }

  private sliceItems<T>(items: T[], page: number, perPage: number): T[] {
    const end = page * perPage
    const start = end - perPage
    return items.slice(start, end)
  }
}

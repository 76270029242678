import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  EmailLookupUploadFormService,
}

export type EmailLookupImportServices = keyof typeof SERVICES

export const EMAIL_LOOKUP_IMPORT_SERVICE_TYPES: Record<EmailLookupImportServices, symbol> =
  makeServicesTypes<EmailLookupImportServices>(enumKeysToArray<EmailLookupImportServices>(SERVICES))

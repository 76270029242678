import { injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import Centrifuge from '@/data/models/domain/Сentrifuge'
import type CentrifugeRepository from '@/data/repositories/domain/CentrifugeRepository'

@DomainSettings({
  model: Centrifuge,
})
@injectable()
export default class CentrifugeService extends DomainBaseService<CentrifugeRepository> {
  public getTokens(channels: string[]) {
    return this.getDomainRepository().getTokens(channels)
  }
}

import type { RouteRecordRaw } from 'vue-router'

export const getPrivacyPolicyRoutes = (): RouteRecordRaw[] => [
  {
    name: 'privacyPolicy.base',
    path: 'privacy-policy',
    component: () => import('@/components/containers/SinglePageContainer.vue'),
    props: {
      isPrivacyPolicyPage: true,
    },
    children: [
      {
        name: 'privacyPolicy',
        path: '',
        component: () => import('@/components/views/PrivacyPolicy.vue'),
      },
    ],
  },
]

import { OrmModel, StringField, BooleanField, AttrField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.SEND_RECEIVE_SETTINGS)
export default class SendReceiveSettings extends BaseModel {
  @StringField()
  public includeOptions: string

  @StringField()
  public maxLength: string

  @BooleanField()
  public removeExtraBlank: boolean

  @BooleanField()
  public autoTransliterate: boolean

  @BooleanField()
  public sendUndelivered: boolean

  @BooleanField()
  public e2sEnabled: boolean

  @AttrField(null, (t) => t ?? '')
  public customCutLines: string
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type VoiceCallConfigService from '@/services/domain/voiceCallConfigService'
import type VoiceCallPriceService from '@/services/domain/voiceCallPriceService'
import { getEM } from '@/core/container/ioc'
import type VoiceCallConfigRepository from '@/data/repositories/domain/voiceCallConfigRepository'
import VoiceCallConfig from '@/data/models/domain/VoiceCallConfig'

@injectable()
export default class VoiceCallConfigResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.VoiceCallPriceService) protected readonly voiceCallPriceService: VoiceCallPriceService,
    @inject(SERVICE_TYPES.VoiceCallConfigService) protected readonly voiceCallConfigService: VoiceCallConfigService,
  ) {}

  public async resolve() {
    const prices = this.voiceCallPriceService.getPrices()
    let pricesPromise
    if (prices?.length) {
      pricesPromise = Promise.resolve(prices)
    } else {
      pricesPromise = this.voiceCallPriceService.fetchPrices()
    }
    let configPromise
    const voiceCallConfig = getEM().getRepository<VoiceCallConfigRepository>(VoiceCallConfig).query().first()
    if (voiceCallConfig) {
      configPromise = Promise.resolve(voiceCallConfig)
    } else {
      configPromise = this.voiceCallConfigService.fetchConfig()
    }
    await Promise.all([pricesPromise, configPromise])
  }
}

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum SERVICES {
  CustomFieldSchemaResolver,
  CustomFieldFormBuilderService,
  CustomFieldColumnServiceFactory,
  CustomFieldSchemaFormService,
  CustomFieldSchemaService,
  CustomFieldSchemaRepository,
  CustomFieldService,
  CustomFieldValueRepository,
  CustomFieldValueService,
  CustomFieldValidationRegistrator,
}

export type CustomFieldServices = keyof typeof SERVICES

export const CUSTOM_FIELD_SERVICES = makeServicesTypesFromEnum<CustomFieldServices>(SERVICES)

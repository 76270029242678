import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import SendReceiveSettings from '@/data/models/domain/SendReceiveSettings'
import type { SendReceiveSettingsPutBody } from '@/services/domain/sendReceiveSettings/types'

@RepoSettings<Endpoint>({
  model: SendReceiveSettings,
})
@injectable()
export default class SendReceiveSettingsRepository extends OrmApiRepository<SendReceiveSettings> {
  public async fetchSettings() {
    const res = await this.api.get<SendReceiveSettings>(this.endpointsService.getPath('sendReceiveSetting'))
    const insertedData = await this.insertOrUpdate([res.data])
    return insertedData[0]
  }

  public getSettings(): SendReceiveSettings {
    return this.query().first()!
  }

  public async updateSettings(data: SendReceiveSettingsPutBody): Promise<void> {
    await this.api.put(this.endpointsService.getPath('sendReceiveSetting'), data)
    await this.query().first()!.$update(data)
  }
}

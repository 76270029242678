import { injectable } from 'inversify'
import Field from '@/data/models/forms/Field'
import FieldArray from '@/data/models/forms/FieldArray'
import FieldGroup from '@/data/models/forms/FieldGroup'
import FieldMultiEntity from '@/data/models/forms/FieldMultiEntity'
import type BaseFieldModel from '@/data/models/forms/BaseFieldModel'

@injectable()
export class FieldMapper {
  public static map() {
    return {
      field: Field,
      fieldArray: FieldArray,
      fieldGroup: FieldGroup,
      fieldMultiEntity: FieldMultiEntity,
    } as const
  }

  public static getType(name: FieldModelType | undefined) {
    return name ? this.map()[name] : this.map().field
  }

  public static getFieldType(field: BaseFieldModel) {
    if (field instanceof Field) {
      return Field
    }
    if (field instanceof FieldArray) {
      return FieldArray
    }
    if (field instanceof FieldGroup) {
      return FieldGroup
    }
    if (field instanceof FieldMultiEntity) {
      return FieldMultiEntity
    }
    return null
  }
}
export type FieldModelType = keyof ReturnType<typeof FieldMapper.map>

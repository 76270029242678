import { NumberField, OrmModel, PrimaryKey, StringField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { TmCountryCode } from '@/services/types'

@OrmModel(modelNames.VOICE_CALL_PRICE)
export default class VoiceCallPrice extends BaseModel {
  @PrimaryKey()
  @StringField()
  public countryId: TmCountryCode

  @NumberField(0)
  public forward: number

  @NumberField(0)
  public inbound: number

  @NumberField(0)
  public outbound: number

  @StringField()
  public currency: string
}

export class TmBaseError<T extends Record<any, any> = Record<any, any>> extends Error {
  public name = 'BaseError'

  protected isMonitored = true

  protected isNotified = true

  protected data: T

  constructor(msg?: string, data?: T, stack?: string) {
    super(msg)
    if (stack) {
      this.stack = stack
    }
    this.setData(data || ({} as T))
  }

  public setData(data: T) {
    this.data = data
  }

  public getData() {
    return this.data
  }

  public static is(err: Error): err is TmBaseError {
    return !!(err as any).getData
  }

  public shouldBeMonitored(): boolean {
    return this.isMonitored
  }

  public shouldBeNotified(): boolean {
    return this.isNotified
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  AutomationRulesListColumnServiceFactory,
  AutomationRuleLogColumnServiceFactory,
}

export type AutomationRulesServices = keyof typeof SERVICES

export const AUTOMATION_RULES_SERVICE_TYPES: Record<AutomationRulesServices, symbol> =
  makeServicesTypes<AutomationRulesServices>(enumKeysToArray<AutomationRulesServices>(SERVICES))

export const useLastPromise = <T>() => {
  let lastRequest: Promise<T> | null = null

  const perform = async (request: Promise<T>): Promise<T> => {
    lastRequest = request

    try {
      await request
    } catch {}

    if (lastRequest === request) {
      return request
    }

    return perform(lastRequest)
  }

  return perform
}

import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'
import FormSubscribeDetails from '@/data/models/domain/services/forms/FormSubscribeDetails'
import type { NotFoundResolverParams } from '@/services/resolvers/types'
import FormClickToTextDetails from '@/data/models/domain/services/forms/FormClickToTextDetails'
import { ServicesRouteName } from '@/routes/types'

export const getFormsDetailsRoutes = (): RouteRecordRaw[] => [
  {
    name: 'user.services.forms.clickToText.new',
    path: 'forms/click-to-text/new',
    meta: {
      parent: ServicesRouteName,
      tabTitle: 'pageTabTitle.user.services.forms.clickToText.new',
      modules: [AppModule.ContactList, AppModule.WidgetClickToText],
      permission: 'subscribeForms',
    },
    component: () => import('@/components/views/services/forms/FormClickToText.vue'),
  },
  {
    name: 'user.services.forms.clickToText.edit',
    path: 'forms/click-to-text/:formId',
    props: true,
    meta: {
      parent: ServicesRouteName,
      tabTitle: 'pageTabTitle.user.services.forms.clickToText.edit',
      modules: [AppModule.ContactList, AppModule.WidgetClickToText],
      permission: 'subscribeForms',
      resolvers: [
        {
          service: 'RedirectableSingleModelResolverService',
          params: {
            model: FormClickToTextDetails,
            source: 'router',
            requestParam: 'formId',
            redirectToIfNotFound: { name: 'user.services.forms.clickToText' },
          } as NotFoundResolverParams,
        },
      ],
    },
    component: () => import('@/components/views/services/forms/FormClickToText.vue'),
  },
  {
    name: 'user.services.forms.subscribe.edit',
    path: 'forms/subscribe/:formId',
    props: true,
    meta: {
      parent: ServicesRouteName,
      tabTitle: 'pageTabTitle.user.services.forms.subscribe.edit',
      modules: [AppModule.ContactList, AppModule.WidgetSubscribe],
      permission: 'subscribeForms',
      resolvers: [
        {
          service: 'RedirectableSingleModelResolverService',
          params: {
            model: FormSubscribeDetails,
            source: 'router',
            requestParam: 'formId',
            redirectToIfNotFound: { name: 'user.services.forms.subscribe' },
          } as NotFoundResolverParams,
        },
      ],
    },
    component: () => import('@/components/views/services/forms/FormSubscribe.vue'),
  },
]

import { get } from 'lodash-es'
import type { Collections, Record } from '@vuex-orm/core'
import type * as Contracts from '@vuex-orm/core/dist/src/query/contracts'
import type BaseModel from '@/data/models/BaseModel'
import type LoggerService from '@/services/loggerService'
import ORMBaseRepository from '@/data/repositories/ormBaseRepository'
import type { LowLevelInsertBody, LowLevelUpdateBody } from '@/services/vuex/types'
import type { Transport } from '@/services/transport/types'
import { TmRepositoryError } from '@/core/error/tmRepositoryError'
import { TmIncorrectContractError } from '@/core/error/tmIncorrectContractError'

export default abstract class OrmStorageRepository<T extends BaseModel> extends ORMBaseRepository<T> {
  constructor(storageService: Transport, loggerService: LoggerService) {
    super(loggerService)
    this.transport = storageService
  }

  public async insertOrUpdate(payload: Record[]) {
    const apiModel = await this.getApiSource().get(this.model().name)

    // eslint-disable-next-line no-restricted-syntax
    for (const record of payload) {
      if (get(apiModel.data, record.id)) {
        await this.update([record] as LowLevelUpdateBody<T>[])
      } else {
        this.insert([record] as LowLevelInsertBody<T>[])
      }
    }
    return payload as unknown as Promise<Collections>
  }

  public async fillAll(isReset = false): Promise<T[]> {
    this.logGroupStart('fillAll')
    const result = await this.getApiSource().get<T[]>(this.settings().single as string)
    if (!result.data) {
      throw new TmIncorrectContractError('Response data expected')
    }

    await this.insertOrUpdate(Object.values(result.data))
    this.logGroupEnd()
    return result.data
  }

  public async findOrFillOrCreate(id: string) {
    const item = this.model().find(id)
    if (item) {
      return item as unknown as T
    }

    const model = await this.getRequest<T>(id)
    if (model && model.id) {
      return this.lowLevelInsert([model])
    }

    this.log('findOrFillOrCreate | create')
    this.lowLevelInsert([{ id }] as LowLevelInsertBody<T>[])
    const newModel = this.find(id)
    await this.postRequest(newModel)

    return undefined
  }

  protected getPath(key: string, id: string) {
    return `${key}${this.getRepoSeparator()}${id}`
  }

  protected getUpdatePath(key: string, data: T): string {
    return this.getPath(key, data.id)
  }

  protected getRepoSeparator() {
    return this.transport.getPathSeparator ? this.transport.getPathSeparator() : '/'
  }

  protected serializeData(data: T) {
    return data
  }

  protected log(message: string) {
    this.loggerService.log('orm', message, 'storageRepository')
  }

  protected logError(message: string) {
    this.loggerService.error('orm', message, 'storageRepository')
  }

  protected async lowLevelDeleteByCondition(condition: Contracts.Predicate<T>, stiModel?: typeof BaseModel) {
    super.lowLevelDeleteByCondition(condition, stiModel)

    const singleKey = this.settings().single
    if (!singleKey) {
      throw new TmRepositoryError(`${this.model().entity} storage repository without 'single' in settings`)
    }
    const records = (await this.transport.get<T[]>(singleKey)).data
    if (!records) {
      throw new TmIncorrectContractError('Response data expected')
    }

    Object.values<T>(records).forEach((model) => {
      if (condition(model)) {
        this.transport.delete(`${singleKey}${this.getPathSeparator()}${model.id}`)
      }
    })
  }

  protected getPathSeparator() {
    if (!this.transport.getPathSeparator) {
      throw new TmRepositoryError(`${this.model().entity} repository wrong transport`)
    }

    return this.transport.getPathSeparator()
  }
}

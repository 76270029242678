import {
  OrmModel,
  StringField,
  AttrField,
  BelongsToField,
  NumberField,
  HasManyByField,
  BooleanField,
} from 'vuex-orm-decorators'
import type { CountryCode } from 'libphonenumber-js'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import User from '@/data/models/domain/User'
import TenDlcCampaign from '@/data/models/domain/services/regulations/TenDlcCampaign'
import type {
  TendDlcBrandVerticalType,
  TendDlcBrandEntityType,
  TenDlcBrandStatus,
} from '@/services/domain/services/regulations/tenDlcBrand/types'
import type { TmCountryCode } from '@/services/types'

@OrmModel(modelNames.TEN_DLC_BRAND)
export default class TenDlcBrand extends BaseModel {
  @StringField()
  public id: string

  @AttrField()
  public vonageBrandId: string

  @NumberField()
  public userId: number

  @BelongsToField(User, 'userId')
  public user: User

  @AttrField()
  public entityType: TendDlcBrandEntityType | null

  @StringField()
  public brandStatus: TenDlcBrandStatus

  @AttrField()
  public vettingStatus: string

  @AttrField()
  public vettingId: string | null

  @AttrField()
  public companyNameLegal: string | null

  @AttrField()
  public companyNameDisplay: string | null

  @AttrField()
  public taxNumber: string | null

  @AttrField(null)
  public taxNumberIdentifier: null

  @AttrField()
  public taxNumberCountryCode: TmCountryCode | null

  @AttrField()
  public state: string | null

  @AttrField()
  public city: string | null

  @AttrField()
  public street: string | null

  @AttrField()
  public postalCode: string | null

  @AttrField()
  public countryId: CountryCode | null

  @AttrField()
  public verticalTypeOfCompany: TendDlcBrandVerticalType | null

  @AttrField()
  public website: string | null

  @AttrField()
  public contactFirstName: string | null

  @AttrField()
  public contactLastName: string | null

  @AttrField(null)
  public contactJobPosition: null

  @AttrField(null)
  public contactExactJobTitle: null

  @AttrField()
  public contactPhone: string | null

  @AttrField()
  public contactEmail: string | null

  @AttrField()
  public companyStockSymbol: string | null

  @AttrField()
  public companyStockExchange: string | null

  @AttrField()
  public campaignIds: string[]

  @HasManyByField(TenDlcCampaign, 'campaignIds')
  public campaigns: TenDlcCampaign[]

  @StringField()
  public createdAt: string

  @StringField()
  public updatedAt: string

  @AttrField()
  public registeredAt: string | null

  @AttrField()
  public errorFields: string[]

  @AttrField()
  public draftRequestId: string

  @AttrField()
  public actionRequiredRequestId: string | null

  @AttrField(null)
  public businessRegionsOfOperations: null

  @AttrField(null)
  public socialProfileLinks: null

  @BooleanField()
  public isDetailsFilled: boolean

  @BooleanField()
  public isAddressFilled: boolean

  @BooleanField()
  public isContactFilled: boolean
}

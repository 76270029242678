import {
  getContactService,
  getNotificationService,
  getTranslateService,
  getUnsubscribedContactService,
} from '@/core/container/ioc'
import { useConfirmation } from '@/composition/confirmation'

export const useResubscribeEmailContactModal = () => {
  const translateService = getTranslateService()
  const notificationService = getNotificationService()
  const unsubscribedContactService = getUnsubscribedContactService()

  const open = (email: string, contactId?: string, onSuccess?: () => void) => {
    useConfirmation().confirm(
      async () => {
        await unsubscribedContactService.resubscribe(
          {
            email,
          },
          contactId,
        )
        onSuccess?.()
        notificationService.success(translateService.t('resubscribeEmailContactModal.successText'))
      },
      {
        submitText: translateService.t('resubscribeEmailContactModal.submitText'),
        submitLoadingText: translateService.t('resubscribeEmailContactModal.submitLoadingText'),
        title: translateService.t('resubscribeEmailContactModal.title'),
        text: {
          translateKey: 'resubscribeEmailContactModal.text',
          slots: [{ name: 'email', value: email }],
        },
      },
    )
  }

  return {
    open,
  }
}

interface UnsubscribeEmailContactModalParams {
  contactId?: string
  email: string
  onSuccess?: () => void
}

export const useUnsubscribeEmailContactModal = () => {
  const translateService = getTranslateService()
  const notificationService = getNotificationService()
  const contactService = getContactService()

  const open = (params: UnsubscribeEmailContactModalParams) => {
    useConfirmation().confirm(
      async () => {
        await contactService.unsubscribe(
          {
            email: params.email,
          },
          params.contactId,
        )
        params.onSuccess?.()
        notificationService.success(translateService.t('unsubscribeEmailContactModal.successText'))
      },
      {
        submitText: translateService.t('unsubscribeEmailContactModal.submitText'),
        submitLoadingText: translateService.t('unsubscribeEmailContactModal.submitLoadingText'),
        title: translateService.t('unsubscribeEmailContactModal.title'),
        text: {
          translateKey: 'unsubscribeEmailContactModal.text',
          slots: [{ name: 'email', value: params.email }],
        },
      },
    )
  }

  return {
    open,
  }
}

import type { LogChannel } from '@/core/logger/types'
import type BaseModel from '@/data/models/BaseModel'
import type { DomainAliasesType } from '@/services/preloaders/types'
import type { PartialUIConfig } from '@/services/wrappers/types'
import type { ParamsPartial } from '@/types'

export enum LoggableStrategyEnum {
  INCLUSIVE = 'INCLUSIVE',
  EXCLUSIVE = 'EXCLUSIVE',
}

export type LoggableSetting = {
  strategy?: LoggableStrategyEnum
  inverseMethodsByStrategy?: string[]
  channel?: LogChannel
}
export const defaultEditingIdKeys = ['modalParams']

export type DomainServiceSettings = {
  model?: typeof BaseModel
  alias?: DomainAliasesType
}
export type DomainServiceSettingsRequired = Required<DomainServiceSettings>

export interface FormPartialableInterface<T extends ParamsPartial> {
  getPartial(): PartialUIConfig<T>
}

export const enum REFLECT_METADATA {
  RepoSettings = 'repo-settings',
  DomainSettings = 'domain-settings',
  BulkSettings = 'bulk-settings',
  PreloaderSettings = 'preloader-settings',
  FaceterSettings = 'faceter-settings',
}

<template>
  <div
    class="tm-dialog-wrapper"
    v-bind="dataTestIdAttr"
  >
    <q-dialog
      :model-value="isOpen"
      v-bind="computedAttrs"
      :no-backdrop-dismiss="noBackdropDismiss"
      :class="[
        {
          'tm-dialog--scrollable': scrollable,
        },
        modalClass,
      ]"
      transition-show="jump-up"
      transition-hide="jump-down"
      :no-route-dismiss="keepAliveOnRouteChange"
      no-refocus
      @update:model-value="handleModelValueUpdate"
      @hide="handleClose"
      @show="handleOpen"
      @before-hide="handleBeforeHide"
    >
      <slot name="dialog-root">
        <div
          ref="modalEl"
          :class="[
            'tm-modal',
            'relative',
            {
              'tm-modal--with-aside': leftAsideWidth,
              'tm-modal--scrollable': scrollable,
              'with-nav': !!navigationComponent,
            },
          ]"
          data-model-el="true"
          :style="modalStyles"
        >
          <slot />
        </div>
        <slot name="navigation-buttons" />
      </slot>
    </q-dialog>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, provide, ref, watch } from '@/composition/vue/compositionApi'
import {
  baseDialogProps,
  baseDialogEmits,
  TmModalSizesMap,
  DIALOG_CONTEXT_NAME,
} from '@/components/shared/modals/tmModal/const'
import type { DialogInjectContext } from '@/composition/dialog'
import type { ModalStyles } from '@/components/shared/modals/tmModal/types'
import type { Callback } from '@/types'
import { useOutsideClick } from '@/composition/useOutsideClick'
import { TestIdAttrName } from '@/composition/testing/types'

export default defineComponent({
  props: {
    ...baseDialogProps,
  },
  emits: {
    ...baseDialogEmits,
  },
  setup(props, context) {
    const onBeforeHideCallbacks = ref<Array<() => void>>([])
    const onOpenCallbacks = ref<Array<() => void>>([])
    const onCloseCallbacks = ref<Array<() => void>>([])
    const onOutsideClickCallbacks = ref<Array<() => void>>([])
    const canCloseCallback = ref<Callback<boolean | Promise<boolean>> | null>(null)
    const modalEl = ref()
    const parentModalEl = ref()

    useOutsideClick(
      modalEl,
      () => {
        onOutsideClickCallbacks.value.forEach((callback) => {
          callback()
        })
      },
      parentModalEl,
    )

    const handleBeforeHide = () => {
      context.emit('before-hide')
      onBeforeHideCallbacks.value.forEach((callback) => callback())
    }

    const computedAttrs = computed(() => {
      const { class: className, ...attrs } = context.attrs
      const { modalClass, ...rest } = props
      return { ...attrs, ...rest }
    })

    const dataTestIdAttr = computed(() => {
      return { [TestIdAttrName]: props[TestIdAttrName] }
    })

    const handleOpen = () => {
      context.emit('open')
      onOpenCallbacks.value.forEach((callback) => callback())
    }

    const handleClose = () => {
      context.emit('close')
      onCloseCallbacks.value.forEach((callback) => callback())

      onOpenCallbacks.value = []
      onCloseCallbacks.value = []
      onBeforeHideCallbacks.value = []
    }

    const handleModelValueUpdate = async (value: boolean) => {
      if (value) {
        context.emit('update:model-value', value)
        return
      }
      if (typeof canCloseCallback.value === 'function') {
        try {
          let doClose = canCloseCallback.value()
          if (doClose instanceof Promise) {
            doClose = await doClose
          }
          if (!doClose) {
            return
          }
        } catch {
          return
        }
      }
      context.emit('update:model-value', value)
    }

    /**
     * Watch needed because the element .q-dialog is removed from a DOM while dialog is hidden.
     */
    watch(
      () => props.isOpen,
      async () => {
        await nextTick()

        /**
         * The element .q-dialog__inner has 'pointer-events: none' styles,
         * can't use it for click handler, using a .q-dialog instead.
         */
        parentModalEl.value = props.isOpen ? modalEl.value?.closest('.q-dialog') : null
      },
      { immediate: true },
    )

    provide(DIALOG_CONTEXT_NAME, {
      open: handleOpen,
      close: handleClose,
      onBeforeHide(callback: () => void) {
        onBeforeHideCallbacks.value.push(callback)
      },
      onOpen(callback: () => void) {
        onOpenCallbacks.value.push(callback)
      },
      onClose(callback: () => void) {
        onCloseCallbacks.value.push(callback)
      },
      onOutsideClick(callback: () => void) {
        onOutsideClickCallbacks.value.push(callback)
      },
      dialogEmit(event: string, value?: any) {
        return context.emit(event as any, value)
      },
      canCloseCallback(cb: Callback<boolean | Promise<boolean>>) {
        canCloseCallback.value = cb
      },
    } satisfies DialogInjectContext)

    const modalSize = computed<string>(() => {
      if (props.exactSize) return props.exactSize
      return TmModalSizesMap[props.size]
    })

    const modalStyles = computed<ModalStyles>(() => {
      const styles = {
        width: modalSize.value,
        maxHeight: props.maxHeight,
        minHeight: props.minHeight,
        height: props.maxHeight ? '100%' : '',
        ...(!!props.leftAsideWidth && { paddingLeft: props.leftAsideWidth }),
        ...(props.overrideModalStyles ?? {}),
      }

      if (props.navigationComponent) {
        styles.maxWidth = modalSize.value
      }

      return styles
    })

    return {
      handleBeforeHide,
      computedAttrs,
      handleOpen,
      handleClose,
      handleModelValueUpdate,
      modalStyles,
      modalEl,
      dataTestIdAttr,
    }
  },
})
</script>

<style lang="scss" scoped>
.tm-dialog-wrapper {
  display: inline;
}
.tm-modal {
  display: flex;
  max-height: 100%;
  max-width: none;
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: $border-radius-md;

  &.with-nav {
    flex: 1;
  }
}
</style>

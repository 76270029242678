import { inject, injectable } from 'inversify'
import type { Factory } from '@/types'
import TmFilterManagerError from '@/core/error/tmFilterManagerError'
import type { FilterInterface, FormFieldFilterType } from '@/services/forms/types'
import type { PaginationUrlFilterType } from '@/services/tables/types'
import { TmTableElementManagerFactoryNotFoundError } from '@/core/error/table/tableManager/tmTableElementManagerFactoryNotFoundError'
import { type RegisteredServices, SERVICE_TYPES } from '@/core/container/types'
import type { FilterServiceManager } from '@/services/tables/managers/filterServiceManager'
import type TableElementManagerWithEndpointInterface from '@/services/tables/managers/tableElementManagerWithEndpointInterface'
import type { IServiceManager } from '@/core/middlewares/types'
import type { Service } from '@/config/types'
import type { GetLocator } from '@/core/container/container'
import type LoggerService from '@/services/loggerService'
import type { Endpoint } from '@/services/endpoints'
import { TmNotImplementedError } from '@/core/error/tmNotImplementedError'
import { TmTableElementManagerFactoryInvalidError } from '@/core/error/table/tableManager/tmTableElementManagerFactoryInvalidError'
import type FilterServiceManagerInterface from '@/services/tables/managers/filterServiceManagerInterface'

@injectable()
export class TableFilterServiceManager
  implements TableElementManagerWithEndpointInterface<FilterInterface>, FilterServiceManagerInterface, IServiceManager
{
  protected factories = new Set<RegisteredServices>()

  protected tableFactories: Map<string, RegisteredServices> = new Map()

  @inject('GetLocator')
  protected readonly get: GetLocator

  constructor(
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
    @inject(SERVICE_TYPES.FilterServiceManager) protected readonly filterServiceManager: FilterServiceManager,
  ) {}

  /**
   * This method is required only for comply with TableElementManagerWithEndpointInterface interface
   */
  public registerFactory(serviceId: RegisteredServices, factory: Factory<FilterInterface>): void {
    // Do nothing, we don't need to register factories here.
  }

  /**
   * This method is required only for comply with TableElementManagerWithEndpointInterface interface
   */
  public setEndpointForTable(tableId: string, endpoint: Endpoint): void {
    throw new TmNotImplementedError()
  }

  /**
   * This method is required only for comply with TableElementManagerWithEndpointInterface interface
   */
  public getEndpointForTable(tableId: string): Endpoint {
    throw new TmNotImplementedError()
  }

  public setFactoryForTable(tableId: string, serviceId: RegisteredServices): Factory<FilterInterface> {
    if (this.hasFactoryForTable(tableId)) {
      this.log(`Using existing service factory "${serviceId}" for table "${tableId}"`, 'setFactoryForTable')
      return this.getFactoryForTable(tableId)
    }

    if (!this.validateFactory(serviceId)) {
      throw new TmTableElementManagerFactoryInvalidError(
        `The service "${serviceId}" you've provided is not a valid factory`,
        { tableId },
      )
    }

    this.tableFactories.set(tableId, serviceId)

    this.log(`Set table "${tableId}" service factory to "${serviceId}"`, 'setFactoryForTable')
    return this.getFactoryForTable(tableId)!
  }

  public hasFactoryForTable(tableId: string): boolean {
    return this.tableFactories.has(tableId)
  }

  public getFactoryForTable(tableId: string): Factory<FilterInterface> {
    try {
      const serviceId = this.tableFactories.get(tableId)
      if (!serviceId) {
        throw new TmTableElementManagerFactoryNotFoundError(`No factory "${serviceId}" is registered`, { tableId })
      }

      const factory = this.getFactoryByServiceId(serviceId)
      if (!factory) {
        throw new TmTableElementManagerFactoryNotFoundError('Service factory for table must be set before getting it', {
          tableId,
        })
      }

      return factory
    } catch (e) {
      if (e instanceof TmTableElementManagerFactoryNotFoundError) {
        throw new TmFilterManagerError(`No factory is set for "${tableId}" table`)
      }

      throw e
    }
  }

  public validateFactory(serviceId: RegisteredServices): boolean {
    return this.factories.has(serviceId)
  }

  public addService(service: Service): void {
    this.factories.add(service.id as RegisteredServices)
  }

  public removeAllServicesForTable(tableId: string): void {
    this.filterServiceManager.removeAllFilterServicesForServiceId(tableId)
  }

  public removeFirstServiceForTable(tableId: string): void {
    this.filterServiceManager.removeFirstFilterServiceForServiceId(tableId)
  }

  public getFirstServiceForTable(tableId: string): FilterInterface {
    return this.filterServiceManager.getFirstFilterServiceForServiceId(tableId)
  }

  public addServiceForTable(tableId: string) {
    const { serviceId, instance } = this.filterServiceManager.addFilterServiceForServiceId(
      tableId,
      this.getFactoryForTable(tableId),
    )

    return {
      serviceId,
      instance,
    }
  }

  public getFieldVisibility(tableId: string, fsKey: string, fieldName: string): boolean {
    return this.filterServiceManager.getFieldVisibility(tableId, fsKey, fieldName)
  }

  public setFieldVisibility(tableId: string, fsKey: string, fieldName: string, newState: boolean) {
    return this.filterServiceManager.setFieldVisibility(tableId, fsKey, fieldName, newState)
  }

  public resetFieldVisibility(tableId: string, fsKey: string): void {
    return this.filterServiceManager.resetFieldVisibility(tableId, fsKey)
  }

  public resetFieldVisibilityForTable(tableId: string): void {
    Object.keys(this.getServicesForTable(tableId)).forEach((fsKey) => {
      this.resetFieldVisibility(tableId, fsKey)
    })
  }

  public resetFiltersForTable(tableId: string, toSkip: string[] = []) {
    return this.filterServiceManager.resetAllFilterServicesForServiceId(tableId, toSkip)
  }

  public getQueryForTable(tableId: string, exportHidden = true): PaginationUrlFilterType {
    return this.filterServiceManager.getQuery(tableId, exportHidden)
  }

  public getServiceForTable(tableId: string, serviceKey: string): FilterInterface {
    return this.filterServiceManager.getFilterServiceForServiceId(tableId, serviceKey)
  }

  public getServicesForTable(tableId: string): Record<string, FilterInterface> {
    return Object.fromEntries(
      this.filterServiceManager.getAllFilterServicesForServiceId(tableId).map((i) => {
        const { index } = this.filterServiceManager.parseFilterServiceId(i.getServiceId())
        return [index, i]
      }),
    )
  }

  public hasServicesForTable(tableId: string): boolean {
    return this.filterServiceManager.hasFilterServicesForServiceId(tableId)
  }

  public hasServiceForTable(tableId: string, serviceKey: string): boolean {
    return this.filterServiceManager.hasFilterServiceForServiceId(tableId, serviceKey)
  }

  public removeServiceForTable(tableId: string, serviceKey: string): void {
    return this.filterServiceManager.removeFilterServiceForServiceId(tableId, serviceKey)
  }

  public getServiceByServiceId(fsId: string): FilterInterface {
    return this.filterServiceManager.getFilterServiceById(fsId)
  }

  public populateFromUrl(tableId: string, params: PaginationUrlFilterType, setInitial = false): void {
    this.log(`Populate "${tableId}" with following params: ${JSON.stringify(params)}`)

    if (this.hasFactoryForTable(tableId)) {
      this.filterServiceManager.populateFromUrl(tableId, this.getFactoryForTable(tableId), params, setInitial)
    }
  }

  public getAppliedFiltersForTable(tableId: string) {
    return Array.prototype.concat(
      ...Object.values(this.getServicesForTable(tableId)).map<FormFieldFilterType[]>((filter) =>
        filter.getAppliedFilters(),
      ),
    ) as FormFieldFilterType[]
  }

  public cleanup() {
    this.tableFactories = new Map()
    this.filterServiceManager.cleanup()
  }

  protected getFactoryByServiceId(serviceId: RegisteredServices): Factory<FilterInterface> | undefined {
    return this.get(serviceId)
  }

  private log(message: string, methodName?: string) {
    const subchannel = methodName ? `manager:${methodName}` : 'manager'

    if (this.loggerService.shouldLogByChannel('filters', [subchannel])) {
      this.loggerService.log('filters', message, subchannel)
    }
  }
}

import { OrmModel, StringField, BooleanField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.TABLE)
export default class Table extends BaseModel {
  @StringField()
  public id: string

  @BooleanField(false)
  public isShowFilters: boolean

  @BooleanField(false)
  public isInit: boolean

  @BooleanField(true)
  public isRowsLoading: boolean

  @BooleanField(false)
  public isDisabled: boolean

  @BooleanField(false)
  public isExpand: boolean

  @BooleanField(false)
  public isExportInProgress: boolean

  @BooleanField(false)
  public canBeRestored: boolean

  @StringField()
  public lastSelectedRow: string
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  SubAccountImportBulkService,
  SubAccountImportBulkManager,
}

export type SubAccountsImportListServices = keyof typeof SERVICES

export const SUB_ACCOUNTS_IMPORT_LIST_SERVICE_TYPES: Record<SubAccountsImportListServices, symbol> =
  makeServicesTypes<SubAccountsImportListServices>(enumKeysToArray<SubAccountsImportListServices>(SERVICES))

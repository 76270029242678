import { inject, injectable } from 'inversify'
import { isString, intersection } from 'lodash-es'
import type { JSONContent } from '@tiptap/core'
import type { AddRuleFunc, ValidationRegistratorInterface } from '@/services/validation/validationRegistratorInterface'
import { SERVICE_TYPES } from '@/core/container/types'
import type ValidationService from '@/services/validation/validationService'
import type FieldArray from '@/data/models/forms/FieldArray'
import type FieldGroup from '@/data/models/forms/FieldGroup'
import type { RuleConfig } from '@/services/validation/types'
import type FormValidationService from '@/services/forms/formValidationService'
import type FormManager from '@/services/forms/baseForm/formManager'
import type { Forms } from '@/services/forms/formTypes'
import requiredValidator from '@/services/validation/defaultValidators/required'
import { richEditorJsonToText } from '@/services/forms/tmTiptap/utils/richEditor'

@injectable()
export default class SpecialValidationRegistrator implements ValidationRegistratorInterface {
  constructor(
    @inject(SERVICE_TYPES.ValidationService) protected readonly validationService: ValidationService,
    @inject(SERVICE_TYPES.FormValidationService) protected readonly formValidationService: FormValidationService,
    @inject(SERVICE_TYPES.FormManager) protected readonly formManager: FormManager,
  ) {}

  public register(addRule: AddRuleFunc) {
    addRule('someOfValidators', async (value: string, validators: string[], formValues) => {
      let hasValid = false
      for (const validator of validators) {
        const { valid } = await this.validationService.validate({
          value,
          validators: [{ name: validator }],
          formValues,
        })
        if (valid) {
          hasValid = true
          break
        }
      }
      return hasValid
    })

    addRule('hasSomeValid', (field: FieldArray | FieldGroup) =>
      field.hasSomeValid ? true : { message: { path: 'commonValidationRegistrator.hasSomeValid' } },
    )

    addRule('hasEveryValid', (field: FieldArray | FieldGroup, params: any, values, config?: RuleConfig) => {
      if (field.hasEveryValid) {
        return true
      }

      if (config && config.showFirstMessageFromNested) {
        const error = this.formValidationService.getFirstError(field.id, field.formId as Forms)
        if (error) {
          return error
        }
      }

      return { message: { path: 'commonValidationRegistrator.hasEveryValid' } }
    })

    addRule('notOverlapWithFields', (value: string | FieldArray, [crossFieldValues]: string[]) => {
      if (isString(value)) {
        if (!value) return true

        return !crossFieldValues.includes(value)
      }

      const arrData = this.formManager.getForm(value.formId as Forms).getFieldDataById(value.id)
      const overlappedValues = intersection(arrData, crossFieldValues)

      if (overlappedValues.length === 0) {
        return true
      }

      return {
        message: { path: 'specialValidationRegistrator.overlaps', params: { overlaps: overlappedValues.join(', ') } },
      }
    })

    addRule('isUniqueInArray', (value: string, [crossFieldValues]: string[]) => {
      if (!value) return true
      if (crossFieldValues.indexOf(value) === crossFieldValues.lastIndexOf(value)) return true

      return {
        message: {
          path: 'specialValidationRegistrator.unique',
        },
      }
    })

    addRule('richEditorRequired', (value: JSONContent) => {
      return requiredValidator(richEditorJsonToText(value))
    })
  }
}

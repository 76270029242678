import { injectable } from 'inversify'
import CursorPaginationPage from '@/data/models/tables/CursorPaginationPage'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'

@RepoSettings<Endpoint>({
  model: CursorPaginationPage,
})
@injectable()
export default class CursorPaginationPageRepository extends OrmApiRepository<CursorPaginationPage> {
  public deleteAllById(id: string): void {
    this.query().where('id', id).deleteAll()
  }
}

import { inject, injectable } from 'inversify'
import PromisePool from '@supercharge/promise-pool/dist'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Resolvable } from '@/services/resolvers/types'
import type BaseModel from '@/data/models/BaseModel'
import type PreloaderManager from '@/services/preloaders/preloaderManager'
import type ModelPreloaderService from '@/services/preloaders/modelPreloaderService'
import type EntityManagerService from '@/data/repositories/entityManagerService'

@injectable()
export default class ModelResolver implements Resolvable<(typeof BaseModel)[]> {
  constructor(
    @inject(SERVICE_TYPES.PreloaderManager) protected readonly preloaderManager: PreloaderManager,
    @inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService,
  ) {}

  public async resolve(models: (typeof BaseModel)[]) {
    await PromisePool.withConcurrency(5)
      .for(models)
      .handleError(async (error) => {
        throw error
      })
      .process(async (model: typeof BaseModel) => {
        if (this.preloaderManager.isPreloadableEntity(model)) {
          await this.preloaderManager.getPreloader(model).preload(model)
        } else {
          await this.entityManager.getRepository(model).fillAll()
        }
      })
  }

  public isFailed(models: (typeof BaseModel)[]) {
    return models.some((model: typeof BaseModel) =>
      this.preloaderManager.getPreloader<ModelPreloaderService>(model).isFailedByModel(model),
    )
  }
}

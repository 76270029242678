import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import type ModelSubscriptionService from '@/services/transport/modelSubscriptionService'
import { SERVICE_TYPES } from '@/core/container/types'
import type FieldDataCacheService from '@/services/forms/fieldCache/fieldDataCacheService'
import type { FieldDataCacheClearResolverParams } from '@/services/resolvers/fieldDataCache/types'

@injectable()
export default class FieldsDataCacheClearByModelEventResolverService
  implements Resolvable<FieldDataCacheClearResolverParams>
{
  protected readonly subscriptionKeys: string[] = []

  constructor(
    @inject(SERVICE_TYPES.ModelSubscriptionService)
    protected readonly modelSubscriptionService: ModelSubscriptionService,
    @inject(SERVICE_TYPES.FieldDataCacheService) protected readonly fieldDataCacheService: FieldDataCacheService,
  ) {}

  public async resolve(params: FieldDataCacheClearResolverParams) {
    for (const { model, fields } of params) {
      const subscriptionKey = this.modelSubscriptionService.subscribeByModel(model, () => {
        fields.forEach((field) => {
          this.fieldDataCacheService.clearForField(field)
        })
      })
      this.subscriptionKeys.push(subscriptionKey)
    }
  }

  public async unresolve() {
    this.modelSubscriptionService.unsubscribe(this.subscriptionKeys)
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  // Domain services
  KeywordService,

  // Repositories
  KeywordRepository,

  // Forms
  KeywordAddFormService,

  // Tables - columns
  KeywordColumnService,
}

export type KeywordsServices = keyof typeof SERVICES

export const KEYWORDS_SERVICE_TYPES: Record<KeywordsServices, symbol> = makeServicesTypes<KeywordsServices>(
  enumKeysToArray<KeywordsServices>(SERVICES),
)

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import NumberPrice from '@/data/models/domain/NumberPrice'

@RepoSettings<Endpoint>({
  model: NumberPrice,
})
@injectable()
export default class NumbersPriceRepository extends OrmApiRepository<NumberPrice> {
  public async getNumbersPrice(number: string): Promise<NumberPrice> {
    const result = await this.getApiSource().get<NumberPrice>(this.endpointsService.getPath('numberPrice'), {
      params: {
        number,
      },
    })
    result.data.id = number
    await this.create(result.data)
    return this.find(number)
  }
}

import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'
import { withAsyncWrapper } from '@/components/resolvers/wrappers/withAsyncWrapper'
import SmsSurveyFlowResolver from '@/components/resolvers/SmsSurveyFlowResolver.vue'
import AsyncComponentResolverWrapper from '@/components/resolvers/wrappers/AsyncComponentResolverWrapper.vue'
import { campaignsSurveysDetailsSummaryRoute } from '@/routes/user/campaigns/surveys/campaignsSurveysSummary'

export const campaignsSurveysDetailsFlowRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.surveys.details.flow',
  path: 'flow',
  props: true,
  component: withAsyncWrapper(
    () => import('@/components/views/campaigns/surveys/CampaignsSurveysDetailsFlow.vue'),
    SmsSurveyFlowResolver,
    {
      withFallback: true,
      wrapper: AsyncComponentResolverWrapper,
    },
  ),
  meta: {
    tabTitle: 'pageTabTitle.user.services.smsSurveyDetails',
    title: 'pageTitle.user.services.smsSurveys',
    hideHeader: true,
    modules: [AppModule.SmsSurveys, AppModule.Contacts],
    suspenseResolvers: [{ service: 'SmsSurveyFlowResolver' }, { service: 'SmsSurveyNodeTreeResolver' }],
  },
}

export const campaignsSurveysDetailsEditRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.surveys.details.edit',
  path: 'edit',
  props: true,
  component: withAsyncWrapper(
    () => import('@/components/views/campaigns/surveys/CampaignsSurveysDetailsEdit.vue'),
    SmsSurveyFlowResolver,
    {
      withFallback: true,
      wrapper: AsyncComponentResolverWrapper,
    },
  ),
  meta: {
    hideHeader: true,
    title: 'pageTitle.user.services.smsSurveys',
    suspenseResolvers: [{ service: 'SmsSurveyFlowResolver' }, { service: 'SmsSurveyNodeTreeResolver' }],
    modules: [
      AppModule.SmsSurveys,
      AppModule.Contacts,
      AppModule.RecentRecipient,
      AppModule.SenderSettingsCountries,
      AppModule.ContactCounter,
    ],
  },
}

export const campaignsSurveysDetailsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.surveys.details',
  path: ':smsSurveyId',
  children: [campaignsSurveysDetailsSummaryRoute, campaignsSurveysDetailsFlowRoute, campaignsSurveysDetailsEditRoute],
  redirect: { name: campaignsSurveysDetailsSummaryRoute.name },
}

import { AttrField, OrmModel } from 'vuex-orm-decorators'
import type { FormFieldInterface, MultiEntitySelectOption } from '@/services/forms/types'
import BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import { modelNames } from '@/data/models/types'
import type BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.FIELD_MULTI_ENTITY, modelNames.BASE_FIELD, {
  BASE_FIELD: BaseFieldModel,
  FIELD_MULTI_ENTITY: FieldMultiEntity,
})
export default class FieldMultiEntity extends BaseFieldModel implements FormFieldInterface {
  @AttrField()
  public domainModels: (typeof BaseModel)[]

  @AttrField()
  public value: MultiEntitySelectOption | MultiEntitySelectOption[]
}

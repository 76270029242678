if (__SHOULD_POLYFILL_PROMISE_WITHRESOLVERS__) {
  window.Promise.withResolvers =
    window.Promise.withResolvers ||
    (() => {
      let resolve
      let reject

      const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve
        reject = _reject
      })
      return { promise, resolve, reject }
    })
}

import { computed, ref, watch } from '@/composition/vue/compositionApi'
import { useBreakpoints } from '@/composition/breakpoints'
import { getGlobalStateService } from '@/core/container/ioc'
import type { TmRoutesDictionary } from '@/services/route/routerTypes'
import { useCurrentRoute } from '@/composition/useCurrentRoute'

const _isOverlayMenuOpen = ref(false)
const _isLaptopMenuOpen = ref(false)
const _isProfileOpen = ref(false)

const useIsMenuWithOverlay = () => {
  const { isLaptop, isLargeLaptopRange, isXXLargeLaptopRange } = useBreakpoints()
  const currentRoute = useCurrentRoute()
  const messengerRoutes: (keyof TmRoutesDictionary)[] = ['user.chats', 'user.chats.index']
  const isMenuWithOverlay = computed(() => {
    if (messengerRoutes.find((t) => t === currentRoute.value.name)) {
      return isXXLargeLaptopRange.value || isLargeLaptopRange.value || isLaptop.value
    }
    return isLaptop.value
  })

  return {
    isMenuWithOverlay,
  }
}

export const useMenuState = (laptopMenuOpen?: boolean) => {
  const { isTablet } = useBreakpoints()
  const { isMenuWithOverlay } = useIsMenuWithOverlay()
  const globalStateService = getGlobalStateService()

  if (laptopMenuOpen !== undefined) {
    _isLaptopMenuOpen.value = laptopMenuOpen
  }

  const isMenuOpen = computed(() => {
    if (!isMenuWithOverlay.value) {
      return _isLaptopMenuOpen.value
    }
    return _isOverlayMenuOpen.value
  })

  const isProfileVisible = computed(() => _isProfileOpen.value)

  const isOverlayVisible = computed(() => {
    if (!isMenuWithOverlay.value) {
      return false
    }
    return _isOverlayMenuOpen.value || _isProfileOpen.value
  })

  const isMobileMenu = computed(() => isTablet.value)

  const closeMenu = () => {
    updateMenuState(false)
    updateProfileState(false)
  }

  const updateMenuState = (value = !isMenuOpen.value) => {
    if (!isMenuWithOverlay.value) {
      _isLaptopMenuOpen.value = value
      globalStateService.setFieldValue('isMenuOpen', value)
    } else {
      _isOverlayMenuOpen.value = value
    }

    _isProfileOpen.value = false
  }

  const updateProfileState = (value = !isProfileVisible.value) => {
    _isProfileOpen.value = value
    _isOverlayMenuOpen.value = false
  }

  watch(isTablet, () => {
    _isProfileOpen.value = false
  })

  watch(isMenuWithOverlay, () => {
    _isOverlayMenuOpen.value = false
  })

  return {
    isMenuOpen,
    isProfileVisible,
    isOverlayVisible,
    updateMenuState,
    updateProfileState,
    closeMenu,
    isMenuWithOverlay,
    isMobileMenu,
  }
}

import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import type { Endpoint } from '@/services/endpoints'
import VoiceCallConfig from '@/data/models/domain/VoiceCallConfig'
import OrmApiSettingsRepository from '@/data/repositories/ormApiSettingsRepository'

@RepoSettings<Endpoint>({
  model: VoiceCallConfig,
  single: 'userVoiceCallSettings',
})
@injectable()
export default class VoiceCallConfigRepository extends OrmApiSettingsRepository<VoiceCallConfig> {}

import { AttrField, NumberField, OrmModel } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'
import type { SchemaValueType } from '@/services/types'

@OrmModel(modelNames.FILTERS_SCHEMA)
export default class FilterSchema extends BaseModel {
  @NumberField()
  public id: string

  @AttrField({})
  public schema: SchemaValueType
}

import { useProvideInject } from '@/composition/provideInject'
import { onBeforeUnmount } from '@/composition/vue/compositionApi'

export type ScrollCallback = () => any
export type ScrollProvideInjectData = {
  listenScroll: (callback: ScrollCallback) => void
  unListenScroll: (callback: ScrollCallback) => void
}

const ScrollSymbol = Symbol('scroll')
const useScrollProvideInject = () => useProvideInject<ScrollProvideInjectData>(ScrollSymbol)

export const useScrollInject = (callback: ScrollCallback) => {
  const scrollProvideInjectData = useScrollProvideInject().inject()
  if (!scrollProvideInjectData) return
  const { listenScroll, unListenScroll } = scrollProvideInjectData

  listenScroll(callback)
  onBeforeUnmount(() => unListenScroll(callback))
}

export const useScrollProvide = () => {
  const callbacks = new Set<ScrollCallback>()

  const emitScrollEvent = () => callbacks.forEach((callback) => callback())

  useScrollInject(emitScrollEvent)

  const listenScroll = (callback: ScrollCallback) => callbacks.add(callback)
  const unListenScroll = (callback: ScrollCallback) => callbacks.delete(callback)
  useScrollProvideInject().provide({ listenScroll, unListenScroll })

  return {
    emitScrollEvent,
  }
}

<template>
  <div class="scheduled-sidebar">
    <layout-menu :items="scheduledSidebarRoutes" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type ComputedRef } from '@/composition/vue/compositionApi'
import {
  getMainSidebarRoutesResolverService,
  getRouterService,
  getScheduledService,
  getTranslateService,
} from '@/core/container/ioc'
import type { PageContentSidebarItem } from '@/components/layout/pageContent/sidebar/types'
import LayoutMenu from '@/components/layout/LayoutMenu.vue'
import ScheduledMessage from '@/data/models/domain/scheduled/ScheduledMessage'
import type { ScheduledFacet } from '@/services/domain/scheduled/types'
import { useLayoutMenuFaceter } from '@/composition/layoutMenu'
import type { IconName } from '@/assets/icons/icons'
import { ScheduledType } from '@/components/views/scheduled/types'

export default defineComponent({
  components: {
    LayoutMenu,
  },
  setup() {
    const translateService = getTranslateService()
    const mainSidebarRoutesResolverService = getMainSidebarRoutesResolverService()
    const routerService = getRouterService()

    const { getNumber } = useLayoutMenuFaceter<ScheduledFacet>(ScheduledMessage)

    type RoutesKeys = ReturnType<typeof mainSidebarRoutesResolverService.getScheduledSectionRoutes>[number]['name']
    const messageId = computed(() => routerService.getCurrentRoute().params.messageId)
    const message = computed(() =>
      messageId.value ? getScheduledService().findEntityByIdOrNull(String(messageId.value)) : null,
    )
    const routesInfo = computed<
      Record<
        RoutesKeys,
        { title: string; number?: number | null; icon: IconName; overrideActive?: ComputedRef<boolean> }
      >
    >(() => {
      return {
        upcoming: {
          title: translateService.t('scheduledSideBar.upcoming'),
          number: getNumber('upcoming'),
          icon: 'tmi-event-calendar-upcoming',
          overrideActive: computed(() => message.value?.status === ScheduledType.UPCOMING),
        },
        paused: {
          title: translateService.t('scheduledSideBar.paused'),
          number: getNumber('paused'),
          icon: 'pause',
          overrideActive: computed(() => message.value?.status === ScheduledType.PAUSED),
        },
        notSent: {
          title: translateService.t('scheduledSideBar.notSent'),
          number: getNumber('notSent'),
          icon: 'warning',
          overrideActive: computed(() => message.value?.status === ScheduledType.NOT_SENT),
        },
        completed: {
          title: translateService.t('scheduledSideBar.completed'),
          number: getNumber('completed'),
          icon: 'tmi-check-circle',
          overrideActive: computed(() => message.value?.status === ScheduledType.COMPLETED),
        },
        calendar: {
          title: translateService.t('scheduledSideBar.calendar'),
          icon: 'tmi-event',
        },
      }
    })

    const scheduledSidebarRoutes = computed<PageContentSidebarItem[]>(() => {
      return mainSidebarRoutesResolverService.getScheduledSectionRoutes().map((t) => {
        const routeInfo = routesInfo.value[t.name]
        return {
          itemType: 'link',
          title: routeInfo.title,
          route: t.route,
          number: routeInfo.number,
          icon: routeInfo.icon,
          overrideActive: routeInfo.overrideActive?.value ?? false,
        }
      })
    })

    return {
      scheduledSidebarRoutes,
    }
  },
})
</script>

import type { RouteLocationRaw } from 'vue-router'
import type { RegisteredServices } from '@/core/container/types'
import { ScheduledRoutes } from '@/routes/user/scheduled/types'

export const scheduledUpcomingTableId = 'scheduledUpcomingTable' as RegisteredServices
export const scheduledPausedTableId = 'scheduledPausedTable' as RegisteredServices
export const scheduledCompletedTableId = 'scheduledCompletedTable' as RegisteredServices
export const scheduledNotSentTableId = 'scheduledNotSentTable' as RegisteredServices

export enum ScheduledRequestStatus {
  ACTUAL = 'a',
  PAUSED = 'p',
  COMPLETED = 'c',
  ALL = 'x',
}

export enum ScheduledType {
  UPCOMING = 'scheduled',
  PAUSED = 'paused',
  NOT_SENT = 'notSent',
  COMPLETED = 'completed',
}

export const ScheduledRequestStatusMap: Partial<Record<ScheduledRequestStatus, ScheduledType>> = {
  [ScheduledRequestStatus.ACTUAL]: ScheduledType.UPCOMING,
  [ScheduledRequestStatus.PAUSED]: ScheduledType.PAUSED,
  [ScheduledRequestStatus.COMPLETED]: ScheduledType.COMPLETED,
}

export const messageIdRouteParam = 'messageId'

export type ScheduledEditRouteParams = {
  [messageIdRouteParam]: string
}

export const getScheduledEditRoute = (params: ScheduledEditRouteParams): RouteLocationRaw => ({
  name: ScheduledRoutes.edit,
  params,
})

import { injectable } from 'inversify'
import { merge } from 'lodash-es'
import type { StringListFieldConfig } from '@/services/forms/types'

@injectable()
export default class EditableBadgesFieldBuilderService {
  public withForbiddenDuplicatesConfig(config: StringListFieldConfig): StringListFieldConfig {
    const baseConfig: Pick<StringListFieldConfig, 'componentProps'> = {
      componentProps: {
        removeDuplicates: () => true,
      },
    }
    return merge(baseConfig, config)
  }
}

import { ticketIdViewParam, ticketInboxIdViewParam } from '@/services/domain/tickets/const'
import { FILTERED_VIEW_PARAM_NAME } from '@/services/domain/filteredViews/const'

export const ticketsRouteNames = {
  root: 'user.tickets',
  resolveNavigation: 'user.tickets.for-resolver',
  gettingStarted: 'user.tickets.getting-started',
  tableFilteredView: 'user.tickets.table.fv',
  ticketDetails: 'user.tickets.details',

  // settings
  settings: 'user.tickets.settings',
  settingsIndex: 'user.tickets.settings.index',
  settingsInboxes: 'user.tickets.settings.inboxes',
  settingsInboxesTable: 'user.tickets.settings.inboxes.table',
  settingsInboxesConnect: 'user.tickets.settings.inboxes.connect',
  settingsInboxesDetails: 'user.tickets.settings.inboxes.details',
  settingsSpamFilter: 'user.tickets.settings.spamFilter',
  settingsGeneral: 'user.tickets.settings.general',
  settingsFilteredViewsIndex: 'user.tickets.settings.filteredViewsIndex',
  settingsFilteredViews: 'user.tickets.settings.filteredViews',
  settingsFilteredViewsCreate: 'user.tickets.settings.filteredViews.create',
  settingsFilteredViewsDetails: 'user.tickets.settings.filteredViews.details',
} as const satisfies Record<string, string>

export const ticketsRoutePaths = {
  root: 'tickets',
  resolveNavigation: '',
  gettingStarted: 'getting-started',
  tableFilteredView: `filter/:${FILTERED_VIEW_PARAM_NAME}`,
  ticketDetails: `:${ticketIdViewParam}`,

  // settings
  settings: 'tickets/settings',
  settingsIndex: 'index',
  settingsInboxes: 'inboxes',
  settingsInboxesTable: '',
  settingsInboxesConnect: 'connect',
  settingsInboxesDetails: `:${ticketInboxIdViewParam}`,
  settingsSpamFilter: 'spam-filter',
  settingsGeneral: 'general',
  settingsFilteredViewsIndex: 'filtered-views',
  settingsFilteredViews: '',
  settingsFilteredViewsCreate: `new`,
  settingsFilteredViewsDetails: `edit/:${FILTERED_VIEW_PARAM_NAME}`,
} as const satisfies Record<keyof typeof ticketsRouteNames, string>

import type { PurifierConfig } from '@/utils/string/types'

export type SanitizeHtmlConfig = {
  html: string
  config?: PurifierConfig
}

export enum SanitizeHtmlMode {
  MERGE = 'merge',
  STRICT = 'strict',
  RICH = 'rich',
}

import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import type VisibilityRepository from '@/data/repositories/VisibilityRepository'
import Visibility from '@/data/models/Visibility'

@injectable()
export default class VisibilityService {
  constructor(@inject(SERVICE_TYPES.EntityManager) protected readonly entityManager: EntityManagerService) {}

  public getVisibility(key: string) {
    return this.getRepository().getVisibility(key)
  }

  public async setVisibility(key: string, newVisibility: Record<string, boolean>) {
    await this.getRepository().setVisibility(key, newVisibility)
  }

  protected getRepository() {
    return this.entityManager.getRepository<VisibilityRepository>(Visibility)
  }
}

import { OrmModel, StringField, BooleanField } from 'vuex-orm-decorators'
import { modelNames } from '@/data/models/types'
import BaseModel from '@/data/models/BaseModel'

@OrmModel(modelNames.ALL_SELECTED_TABLES)
export default class AllSelectedTables extends BaseModel {
  @StringField()
  public id: string

  @BooleanField(false)
  public isAllSelected: boolean

  public static reset(id: string) {
    AllSelectedTables.update({ where: id, data: { isAllSelected: false } })
  }
}

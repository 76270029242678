<template>
  <tm-dialog
    :is-open="isWrapperOpen"
    v-bind="computedProps"
    :modal-class="`tm-dialog-${id}`"
    @open="show"
    @close="hide"
    @update:model-value="onHide"
  >
    <template v-slot="data">
      <slot
        name="default"
        v-bind="data"
      />
    </template>
    <template v-slot:dialog-root="data">
      <slot
        v-if="$slots['dialog-root']"
        name="dialog-root"
        v-bind="data"
      />
    </template>

    <template v-slot:navigation-buttons>
      <slot name="navigation-buttons" />
    </template>
  </tm-dialog>
</template>

<script lang="ts">
import { isFunction } from 'lodash-es'
import { onBeforeRouteLeave } from 'vue-router'
import { computed, defineComponent, ref, watch, type PropType, provide } from '@/composition/vue/compositionApi'
import {
  baseDialogEmits,
  baseDialogProps,
  DIALOG_WRAPPER_MODAL_CONFIG,
  isWrapperConfigItemModal,
} from '@/components/shared/modals/tmModal/const'
import TmDialog from '@/components/shared/modals/tmModal/TmDialog.vue'
import { useWrapper } from '@/composition/modal'
import { type TmWrappers, wrappersConfig } from '@/wrappers'
import { TmComponentError } from '@/core/error/TmComponentError'
import type { ModalDialogConfigWithTitle } from '@/components/shared/modals/tmModal/types'
import { getTranslateService } from '@/core/container/ioc'
import { useDataTestId } from '@/composition/testing/dataTestId'

export default defineComponent({
  components: { TmDialog },
  props: {
    ...baseDialogProps,
    id: {
      type: String as PropType<TmWrappers>,
      required: true,
    },
  },
  emits: {
    ...baseDialogEmits,
  },
  setup(props, context) {
    const { t } = getTranslateService()
    const { close, getWrapperData, getParams } = useWrapper(props.id)
    const params = getParams()

    const wrapperConfig = wrappersConfig[props.id]
    if (!isWrapperConfigItemModal(wrapperConfig)) {
      throw new TmComponentError(`Config for wrapper "${props.id}" is not ModalWrapperConfig`)
    }
    const { modalConfig } = wrapperConfig
    const { titleMessagePath, titleMessageParams, getTitle } = modalConfig

    const getConfigTitleKey = () => {
      if (isFunction(titleMessagePath)) {
        return titleMessagePath(params)
      }
      return titleMessagePath ?? null
    }

    const getConfigTitleParams = () => {
      if (isFunction(titleMessageParams)) {
        return titleMessageParams(params)
      }
      return titleMessageParams ?? {}
    }

    const getConfigTitle = () => {
      if (getTitle) return getTitle(params)

      const titleKey = getConfigTitleKey()
      const titleParams = getConfigTitleParams()

      if (titleKey === null) {
        return ''
      }

      return t(titleKey, titleParams)
    }

    provide<ModalDialogConfigWithTitle>(DIALOG_WRAPPER_MODAL_CONFIG, {
      ...modalConfig,
      title: getConfigTitle(),
    })

    const modalData = computed(() => getWrapperData())

    const isWrapperOpen = ref(props.isOpen)
    watch(
      modalData,
      () => {
        isWrapperOpen.value = modalData.value ? modalData.value?.isOpen : false
      },
      { immediate: true },
    )

    const show = () => {
      context.emit('open')
    }

    const hide = () => {
      context.emit('close')
      close()
    }

    const onHide = async (value: boolean) => {
      isWrapperOpen.value = value
    }

    const { getDataTestId } = useDataTestId()('wrapper', props.id)

    const computedProps = computed(() => {
      const { isOpen, id, ...rest } = props
      return { ...rest, ...getDataTestId() }
    })

    onBeforeRouteLeave(() => {
      if (props.keepAliveOnRouteChange) {
        return
      }
      close()
    })

    return {
      isWrapperOpen,
      show,
      hide,
      onHide,
      computedProps,
    }
  },
})
</script>

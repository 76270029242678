import { inject, injectable } from 'inversify'
import type { Centrifuge, ServerPublicationContext } from 'centrifuge'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Config } from '@/core/types'
import type LoggerService from '@/services/loggerService'
import type { IWebSocketService } from '@/services/transport/types'
import type { IServerEventName, TSocketEvent } from '@/services/transport/serverEvents'
import { createCentrifuge } from '@/utils/centrifuge'

type TCallback = (...args: unknown[]) => unknown

@injectable()
export default class SocketCentrifugeService implements IWebSocketService {
  private connection: Centrifuge

  private defaultChannelListeners: TCallback[] = []

  private channelListeners: { [channel: string]: TCallback[] } = {}

  private statusChangeListeners: ((isConnected: boolean) => void)[] = []

  constructor(
    @inject(SERVICE_TYPES.Config) protected readonly config: Config,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {
    this.connection = createCentrifuge({
      baseUrl: config.socketUrl,
      transports: ['websocket', 'http_stream', 'sse'],
    })
  }

  public connect() {
    if (this.connection?.state === 'connected') {
      return
    }
    try {
      this.connection.connect()
      this.connection.once('connected', () => {
        this.log('Web Socket connected')
      })
      this.connection.on('error', (ctx) => {
        this.logError('Web Socket error.')
        this.logError(ctx.error?.message)
      })
      this.connection.on('disconnected', () => {
        this.statusChangeListeners.forEach((cb) => cb(false))
      })
      this.connection.on('connected', () => {
        this.statusChangeListeners.forEach((cb) => cb(true))
      })
      this.connection.on('publication', <T extends IServerEventName>(ctx: ServerPublicationContext) => {
        const channelCallbacks = this.channelListeners[ctx.channel]
        if (channelCallbacks) {
          channelCallbacks.forEach((cb) => cb((ctx as TSocketEvent<T>).data))
        } else {
          this.defaultChannelListeners.forEach((cb) => cb((ctx as TSocketEvent<T>).data))
        }
      })
    } catch (e) {
      if (e instanceof Error) {
        this.logError('Cannot connect to websocket')
        this.logError(e.message)
        this.logError(`${e.stack}`)
      }
    }
  }

  public disconnect() {
    const connection = this.getConnection()
    try {
      if (connection.state === 'disconnected') {
        return
      }
      connection.disconnect()
    } finally {
      connection.removeAllListeners()
    }
  }

  public async addEventListener(channel: string, handler: TCallback) {
    let channelCallbacks = this.channelListeners[channel]
    if (!channelCallbacks) {
      channelCallbacks = []
      this.channelListeners[channel] = channelCallbacks
    }
    if (channelCallbacks.indexOf(handler) === -1) {
      channelCallbacks.push(handler)
    }
    if (!this.isConnected()) {
      this.connect()
    }
  }

  public removeEventListener(channel: string, handler: TCallback) {
    const channelCallbacks = this.channelListeners[channel]
    if (!channelCallbacks) {
      return
    }
    const index = channelCallbacks.indexOf(handler)
    if (index >= 0) {
      channelCallbacks.splice(index, 1)
    }
  }

  public async publish(channel: string, data: unknown): Promise<void> {
    await this.connection.publish(channel, data)
  }

  public removeAllEventListener(channel: string): void {
    delete this.channelListeners[channel]
  }

  public addDefaultChannelListener(handler: TCallback): void {
    if (this.defaultChannelListeners.indexOf(handler) === -1) {
      this.defaultChannelListeners.push(handler)
    }
    if (!this.isConnected()) {
      this.connect()
    }
  }

  public removeDefaultChannelListener(handler: TCallback): void {
    const index = this.defaultChannelListeners.indexOf(handler)
    if (index >= 0) {
      this.defaultChannelListeners.splice(index, 1)
    }
  }

  public removeAllDefaultChannelListener() {
    this.defaultChannelListeners = []
  }

  public addStatusChangeListener(handler: (isConnected: boolean) => void) {
    if (this.statusChangeListeners.indexOf(handler) === -1) {
      this.statusChangeListeners.push(handler)
      handler(this.connection?.state === 'connected')
    }
  }

  public removeStatusChangeListener(handler: (isConnected: boolean) => void) {
    const index = this.statusChangeListeners.indexOf(handler)
    if (index >= 0) {
      this.statusChangeListeners.splice(index, 1)
    }
  }

  public isConnected() {
    return this.connection && this.connection.state === 'connected'
  }

  protected getConnection() {
    if (!this.connection) {
      throw Error('WebSocket connection not established')
    }
    return this.connection
  }

  protected log(message: string) {
    if (this.loggerService.shouldLogByChannel('bus', ['socket'])) {
      this.loggerService.log('bus', message, 'socket')
    }
  }

  protected logError(message: string) {
    if (this.loggerService.shouldLogByChannel('bus', ['socket'])) {
      this.loggerService.error('bus', message, 'socket')
    }
  }
}

import type { RouteRecordRaw } from 'vue-router'
import { AppModule } from '@/config/types'

export const getByocDetailsFlowRoutes = (name: string): RouteRecordRaw[] => [
  {
    name,
    path: 'flow',
    component: () => import('@/components/views/services/byoc/ByocFlow.vue'),
    meta: {
      tabTitle: 'pageTabTitle.user.services.byoc.flow',
      permission: 'byoc',
      modules: [AppModule.Byoc],
    },
  },
]

import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

export enum TASKS_BASE_SERVICES {
  TaskBoardService,
  TaskBoardRepository,
  KanbanPreloaderService,
  TaskBoardsSidebarResolver,
}

export type TasksBaseServices = keyof typeof TASKS_BASE_SERVICES

export const TASKS_BASE_SERVICES_TYPES = makeServicesTypesFromEnum<TasksBaseServices>(TASKS_BASE_SERVICES)

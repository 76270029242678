import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { LocalStorageServiceInterface } from '@/services/storage/types'
import { UserStatus } from '@/services/domain/user/types'

@injectable()
export default class TemplatesTableExpandLocalStorageService {
  constructor(
    @inject(SERVICE_TYPES.LocalStorageService) protected readonly localStorageService: LocalStorageServiceInterface,
  ) {}

  private getLocalStorageKey() {
    return `expand-table-state:templatesTable`
  }

  private serializeValue(isExpand: boolean): string {
    return `${isExpand}`
  }

  private deserializeValue(value: string): boolean | null {
    if (value === 'true') {
      return true
    }
    if (value === 'false') {
      return false
    }
    return null
  }

  public getValue() {
    const savedValue = this.localStorageService.get(this.getLocalStorageKey())
    return savedValue !== null ? this.deserializeValue(savedValue) : null
  }

  public setValue(isExpand: boolean) {
    this.localStorageService.set(this.getLocalStorageKey(), this.serializeValue(isExpand))
  }

  public async init(userStatus: UserStatus) {
    const deserializedSavedValue = this.getValue()
    if (deserializedSavedValue !== null) {
      return
    }
    this.setValue(userStatus === UserStatus.ST_TRIAL)
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {}

export type KanbanServices = keyof typeof SERVICES

export const KANBAN_SERVICE_TYPES: Record<KanbanServices, symbol> = makeServicesTypes<KanbanServices>(
  enumKeysToArray<KanbanServices>(SERVICES),
)

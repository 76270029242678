import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import { BroadcastEvent, type IUserLoggedInEvent } from '@/services/transport/types'
import type WindowService from '@/services/browser/windowService'
import type { TmRoutes } from '@/services/route/routerTypes'
import type { ICurrentRouteService } from '@/services/route/types'
import type { IBroadcastSubscriptionService } from '@/services/types'

@injectable()
export default class LoggedInStatusService {
  private userLoggedIn = false

  constructor(
    @inject(SERVICE_TYPES.SubscriptionService) private readonly subscriptionService: IBroadcastSubscriptionService,
    @inject(SERVICE_TYPES.WindowService) private readonly windowService: WindowService,
    @inject(SERVICE_TYPES.RouterService) private readonly routerService: ICurrentRouteService,
  ) {
    this.subscriptionService.subscribeBroadcast(BroadcastEvent.UserLoggedIn, (payload) => {
      // there is no case when user on one tab is logged in and on the another one is logged out
      if (payload.isLogged && !this.userLoggedIn && this.isAllowRefreshConfirmRegistrationRoute(payload)) {
        this.windowService.getLocation().href = '/'
      }
    })
  }

  /**
   * it is necessary to keep the confirmation tab open when the user logs in under another account
   */
  public isAllowRefreshConfirmRegistrationRoute(payload: IUserLoggedInEvent) {
    const confirmRegistrationRoute: TmRoutes = {
      name: 'auth.signup.verifyEmail',
    }
    const currentRoute = this.routerService.getCurrentRoute()
    if (currentRoute.name !== confirmRegistrationRoute.name) {
      return true
    }
    const { userId } = this.routerService.getCurrentRoute().query
    return payload.userId === userId
  }

  public setUserIsLoggedIn(userLoggedIn: boolean, userId: string | null) {
    this.userLoggedIn = userLoggedIn
    this.subscriptionService.broadcastEmit(BroadcastEvent.UserLoggedIn, { isLogged: userLoggedIn, userId })
  }

  public isUserLoggedIn() {
    return this.userLoggedIn
  }
}

import { inject, injectable } from 'inversify'
import type { EndpointParams } from '@/services/endpoints'
import type { PaginationUrlFilterType, PaginationUrlParametersSortType } from '@/services/tables/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { AutocompleteResultBase, PhoneOrContactAutocompleteItem, AutocompletableBase } from '@/services/types'
import type PhoneValidateAutocompleteService from '@/services/phoneValidateAutocompleteService'
import type { Recipient } from '@/services/domain/messages/types'
import type ContactService from '@/services/domain/contact/contactService'

@injectable()
export default class PhoneAutocompleteService implements AutocompletableBase<PhoneOrContactAutocompleteItem> {
  constructor(
    @inject(SERVICE_TYPES.PhoneValidateAutocompleteService)
    private readonly phoneValidateAutocompleteService: PhoneValidateAutocompleteService,
    @inject(SERVICE_TYPES.ContactService) private readonly contactService: ContactService,
  ) {}

  private cancelLastRequestFn: (() => void) | null = null

  private isExistSavedNumbers(e: Recipient[]) {
    if (e.length === 0) {
      return false
    }
    return !(e.length === 1 && e[0].entityId === 0)
  }

  public async autocomplete(
    search: string,
    endpointParams: EndpointParams,
    page: number,
    filters: PaginationUrlFilterType,
    perPage?: number,
    sort?: PaginationUrlParametersSortType,
  ): Promise<AutocompleteResultBase<PhoneOrContactAutocompleteItem>> {
    this.cancelLastRequestFn?.()
    if (search.length < 2) {
      return {
        items: [],
        pageCount: 1,
      }
    }

    const contactsAutocompletePromise = this.contactService.onContactsAutocomplete(search, false, false)
    this.cancelLastRequestFn = contactsAutocompletePromise.cancel
    const contactsAutocomplete = await contactsAutocompletePromise
    const prepareRes = contactsAutocomplete.filter((t) => t.entityType === 'contact')
    if (this.isExistSavedNumbers(prepareRes)) {
      return {
        items: prepareRes.map((t) => ({
          id: t.entityId!.toString(),
          fullName: t.title,
          phone: t.value,
          avatar: t.avatarHref,
          value: t.value,
          autocompleteType: 'contact',
        })),
        pageCount: 1,
      }
    }
    const contacts = await this.phoneValidateAutocompleteService.autocomplete(
      search,
      endpointParams,
      page,
      filters,
      perPage,
      sort,
    )
    return {
      items: contacts.items.map((t) => ({
        ...t,
        autocompleteType: 'phone',
      })),
      pageCount: contacts.pageCount,
    }
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ComposeLocalRepository,
  ComposeLocalService,
  ComposeSuccessSendService,
  ComposeValidationRegistrator,
  MessagesPreviewRepository,
  MessagesPreviewService,
  ComposeScheduledTextFormatterService,
}

export type ComposeServices = keyof typeof SERVICES

export const COMPOSE_SERVICE_TYPES: Record<ComposeServices, symbol> = makeServicesTypes<ComposeServices>(
  enumKeysToArray<ComposeServices>(SERVICES),
)

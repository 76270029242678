<template>
  <div class="page-content-sidebar-list-group-collapsible">
    <tm-expansion-item
      v-model:opened="openedRef"
      class="tm-expansion-item--menu"
      :icon-font-size="toggleIconFontSize"
      :disabled="isCollapsingDisabled"
    >
      <template v-slot:label>
        <page-content-sidebar-list-title
          class="tm-expansion-item--menu__title"
          :title="title"
        >
          <template v-slot:title>
            <slot name="title" />
          </template>
        </page-content-sidebar-list-title>
      </template>

      <slot class="pb-4" />
    </tm-expansion-item>
  </div>
</template>

<script setup lang="ts">
import PageContentSidebarListTitle from '@/components/layout/pageContent/sidebar/sidebarItem/PageContentSidebarListTitle.vue'
import TmExpansionItem from '@/components/shared/TmExpansionItem.vue'
import { ref, watch } from '@/composition/vue/compositionApi'

const { defaultOpened = true } = defineProps<{
  title: string
  defaultOpened?: boolean
  toggleIconFontSize?: number
  isCollapsingDisabled?: boolean
}>()

const emit = defineEmits<{
  collapse: []
  expand: []
}>()

const openedRef = ref(defaultOpened)

watch(
  openedRef,
  (opened) => {
    if (opened) {
      emit('expand')
    } else {
      emit('collapse')
    }
  },
  { immediate: true },
)
</script>

<style lang="scss" scoped>
.page-content-sidebar-list-group-collapsible {
  .tm-expansion-item--menu {
    border-bottom: none;

    :deep(.q-item) {
      min-height: 0;
      padding: 0;

      .q-expansion-item__toggle-icon {
        background: none;
      }

      .q-item__section--main ~ .q-item__section--side {
        padding-right: apply-spacer(1);
      }
    }

    .page-content-sidebar-list-title {
      margin-top: 0;
    }

    &__title {
      cursor: pointer;
    }
  }
}
</style>

import { injectable, inject } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type UserService from '@/services/domain/user/userService'
import { DATE_FORMATES } from '@/services/types'

@injectable()
export default class ColumnsSizeService {
  constructor(@inject(SERVICE_TYPES.UserService) protected readonly userService: UserService) {}

  public getDateMinWidth(format: string = DATE_FORMATES.GENERAL_FORMAT) {
    switch (format) {
      case DATE_FORMATES.GENERAL_FORMAT:
        return this.userService.currentUser().displayTimeFormat === '12h' ? 180 : 160
      default:
        return 120
    }
  }

  public getMoneyMinWidth() {
    return 190
  }

  public getStageMinWidth() {
    return 190
  }

  public getTitleMinWidth() {
    return 190
  }

  public getCountryMinWidth() {
    return 140
  }

  public getPhoneMinWidth() {
    return 190
  }

  public getCompanyMinWidth() {
    return 190
  }

  public getPhoneWithIconMinWidth() {
    return this.getPhoneMinWidth() + 30
  }

  public getPersonMinWidth() {
    return 190
  }
}

import { AttrField, BooleanField, OrmModel } from 'vuex-orm-decorators'
import type { FormFieldInterface } from '@/services/forms/types'
import BaseFieldModel from '@/data/models/forms/BaseFieldModel'
import { modelNames } from '@/data/models/types'
import type { ValidationRule } from '@/services/validation/types'

@OrmModel(modelNames.FIELD_GROUP, modelNames.BASE_FIELD, {
  BASE_FIELD: BaseFieldModel,
  FIELD_GROUP: FieldGroup,
})
export default class FieldGroup extends BaseFieldModel implements FormFieldInterface {
  @BooleanField(false)
  public hasSomeValid: boolean

  @BooleanField(true)
  public hasEveryValid: boolean

  @AttrField([])
  public validators: ValidationRule[]

  public getComponent(): string {
    return 'FieldGroup'
  }

  public getApiValue(): string[] {
    return []
  }
}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  InboundCallsColumnServiceFactory,
  InboundCallsFilterServiceFactory,
  InboundCallsService,
  InboundCallsRepository,
}

export type HistoryInboundCallsServices = keyof typeof SERVICES

export const HISTORY_INBOUND_CALLS_SERVICE_TYPES: Record<HistoryInboundCallsServices, symbol> =
  makeServicesTypes<HistoryInboundCallsServices>(enumKeysToArray<HistoryInboundCallsServices>(SERVICES))

import { injectable } from 'inversify'
import DomainBaseService from '@/services/domain/domainBaseService'
import { DomainSettings } from '@/decorators/domainDecorators'
import type SessionStatRepository from '@/data/repositories/domain/session/sessionStatRepository'
import SessionStat from '@/data/models/domain/session/SessionStat'

@DomainSettings({
  model: SessionStat,
})
@injectable()
export default class SessionStatService extends DomainBaseService<SessionStatRepository> {}

import { enumKeysToArray } from '@/utils/enum'
import { makeServicesTypes } from '@/core/container/makeServicesTypes'

enum SERVICES {
  ContactImportResultService,
  ContactImportsService,
  ContactImportResultsRepository,
  ContactImportsRepository,
  ContactsImportPreloaderService,
  ContactsImportDetailsResolverService,
  ContactImportResultFormService,
  ContactImportResultsColumnService,
  ContactImportResultsFailedColumnService,
  ContactImportResultsImportedGridable,
  ContactImportResultsDuplicatesGridable,
  ContactImportResultsExcludedGridable,
  ContactImportResultsFailedGridable,
}

export type ContactsImportDetailsServices = keyof typeof SERVICES

export const CONTACTS_IMPORT_DETAILS_SERVICE_TYPES: Record<ContactsImportDetailsServices, symbol> =
  makeServicesTypes<ContactsImportDetailsServices>(enumKeysToArray<ContactsImportDetailsServices>(SERVICES))

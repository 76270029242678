import { injectable } from 'inversify'
import type { TranslationKey } from '@/services/types'
import priorityLow from '@/assets/images/tasks/priorities/low.svg'
import priorityNormal from '@/assets/images/tasks/priorities/normal.svg'
import priorityHigh from '@/assets/images/tasks/priorities/high.svg'
import priorityUrgent from '@/assets/images/tasks/priorities/urgent.svg'
import { PriorityEnum } from '@/data/models/domain/types'

@injectable()
export default class PriorityHelperService {
  public getPriorityName(priority: PriorityEnum): TranslationKey {
    const priorityToLabelMap: Record<PriorityEnum, TranslationKey> = {
      [PriorityEnum.Urgent]: 'commonPriority.urgent',
      [PriorityEnum.High]: 'commonPriority.high',
      [PriorityEnum.Normal]: 'commonPriority.normal',
      [PriorityEnum.Low]: 'commonPriority.low',
    }

    return priorityToLabelMap[priority]
  }

  public getPriorityIconImage(priority: PriorityEnum): string {
    const priorityToImgMap: Record<PriorityEnum, string> = {
      [PriorityEnum.Low]: priorityLow as string,
      [PriorityEnum.Normal]: priorityNormal as string,
      [PriorityEnum.High]: priorityHigh as string,
      [PriorityEnum.Urgent]: priorityUrgent as string,
    }

    return priorityToImgMap[priority]
  }

  public getPrioritiesOptions() {
    return this.getOrderedPriorities().map((item) => ({
      value: item,
      text: this.getPriorityName(item),
    }))
  }

  public getDefaultPriority(): PriorityEnum {
    return PriorityEnum.Normal
  }

  private getOrderedPriorities(): PriorityEnum[] {
    return [PriorityEnum.Urgent, PriorityEnum.High, PriorityEnum.Normal, PriorityEnum.Low]
  }
}

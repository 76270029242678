import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type { IMainServerSub, IUnreadTicketsCountUpdated, ServerEventBase } from '@/services/transport/serverEvents'
import { FeatureFlags } from '@/services/types'
import type { FeatureFlagsService } from '@/services/featureFlagsService'
import type TicketCountService from '@/services/domain/tickets/ticketCountService'

@injectable()
export default class TicketCountResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.ServerSubscriptionService)
    protected readonly serverSubscriptionService: IMainServerSub,
    @inject(SERVICE_TYPES.TicketCountService) protected readonly ticketCountService: TicketCountService,
    @inject(SERVICE_TYPES.FeatureFlagsService) protected readonly featureFlagsService: FeatureFlagsService,
  ) {}

  private readonly unreadTicketsCountUpdatedEvent = 'unreadTicketsCountUpdated'

  private unreadTicketsCountUpdatedHandler = (
    e: ServerEventBase<'unreadTicketsCountUpdated', IUnreadTicketsCountUpdated>,
  ) => {
    this.ticketCountService.updateUnreadTicketsCount(e.payload.count)
  }

  public async resolve() {
    if (!this.featureFlagsService.isFeatureEnabled(FeatureFlags.Tickets)) {
      return
    }
    this.serverSubscriptionService.subscribe(this.unreadTicketsCountUpdatedEvent, this.unreadTicketsCountUpdatedHandler)
    this.ticketCountService.fetchUnreadTicketsCount()
  }

  public async unresolve() {
    this.serverSubscriptionService.unsubscribe(
      this.unreadTicketsCountUpdatedEvent,
      this.unreadTicketsCountUpdatedHandler,
    )
  }
}

import type { TmNamedRouteRecord } from '@/services/route/types'
import { AppModule } from '@/config/types'

export const campaignsScheduledUpcomingSmsRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.upcoming.sms',
  path: 'sms',
  component: () => import('@/components/views/campaigns/scheduled/upcoming/CampaignsScheduledUpcomingSms.vue'),
  meta: {
    modules: [AppModule.ScheduledUpcoming],
  },
}

export const campaignsScheduledUpcomingEmailRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.upcoming.email',
  path: 'email',
  component: () => import('@/components/views/campaigns/email/CampaignsEmailStub.vue'),
}

export const campaignsScheduledUpcomingRoute: TmNamedRouteRecord = {
  name: 'user.campaigns.scheduled.upcoming',
  path: 'upcoming',
  component: () => import('@/components/views/campaigns/scheduled/upcoming/CampaignsScheduledUpcoming.vue'),
  children: [campaignsScheduledUpcomingSmsRoute, campaignsScheduledUpcomingEmailRoute],
  redirect: { name: campaignsScheduledUpcomingSmsRoute.name },
  meta: {
    tabTitle: 'pageTabTitle.user.campaigns.scheduled.upcoming',
  },
}

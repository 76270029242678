import { TM_ATTACHMENT_URL, TM_URL } from '@/constants/constants'
import { joinPaths } from '@/utils/string/joinPaths'
import ConfigReader from '@/core/configReader'
import type { Dict, Primitive } from '@/types'

type EncType = 'PHP_QUERY_RFC1738' | 'PHP_QUERY_RFC3986'
type UrlEncodeMethod = (url: string) => string

// Tilde should be allowed unescaped in future versions of PHP (as reflected below),
// but if you want to reflect current
// PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
const rawUrlEncode: UrlEncodeMethod = (url: string) =>
  encodeURIComponent(url)
    .replace(/!/g, '%21')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/\*/g, '%2A')

const urlEncode: UrlEncodeMethod = (url: string) => rawUrlEncode(url).replace(/~/g, '%7E').replace(/%20/g, '+')

const getUrlEncodeMethod = (encType?: EncType): UrlEncodeMethod =>
  encType === 'PHP_QUERY_RFC3986' ? rawUrlEncode : urlEncode

const buildObjectQueryItem = (key: string, value: Dict, separator: string, encodeMethod: UrlEncodeMethod): string =>
  Object.entries(value)
    .filter(([_, valueI]) => valueI !== null)
    .map(([keyI, valueI]) => {
      const newKey = key ? `${key}[${keyI}]` : keyI
      return buildQueryItem(newKey, valueI, separator, encodeMethod)
    })
    .join(separator)

const buildPrimitiveQueryItem = (key: string, value: Primitive, encodeMethod: UrlEncodeMethod): string => {
  const normalizedValue = String(value)
  return `${encodeMethod(key)}=${encodeMethod(normalizedValue)}`
}

const buildQueryItem = (key: string, value: unknown, separator: string, encodeMethod: UrlEncodeMethod): string => {
  if (typeof value === 'function') throw new Error('There was an error processing for http_build_query().')
  if (value === null) return ''
  if (typeof value === 'object') return buildObjectQueryItem(key, value as Dict, separator, encodeMethod)
  return buildPrimitiveQueryItem(key, value as Primitive, encodeMethod)
}

export const httpBuildQuery = (formData: Dict, separator = '&', encType?: EncType) => {
  const encodeMethod = getUrlEncodeMethod(encType)
  return buildObjectQueryItem('', formData, separator, encodeMethod)
}

export const flattenObject = (
  object: Record<string, any>,
  path: string | null = null,
  separator = '',
): Record<string, any> =>
  Object.keys(object).reduce(
    (acc: Record<string, any>, key: string) => {
      const value = object[key]
      const newPath = Array.isArray(object)
        ? `${path || ''}[${key}]`
        : [path, `[${key}]`].filter(Boolean).join(separator)
      const isObject = [
        typeof value === 'object',
        value !== null,
        !(value instanceof Date),
        !(value instanceof RegExp),
        !(Array.isArray(value) && value.length === 0),
      ].every(Boolean)
      return isObject ? { ...acc, ...flattenObject(value, newPath, separator) } : { ...acc, [newPath]: value }
    },
    {} as Record<string, any>,
  )

export const getFullHref = (href: string) => (href ? joinPaths(ConfigReader.config().appUrl, href) : '')

export const getFullApiHref = (href: string) => (href ? joinPaths(ConfigReader.config().apiUrl, href) : '')

export const getFullOldHref = (href: string) => (href ? joinPaths(TM_URL, href) : '')

export const getAttachmentHref = (attachmentId: string) =>
  attachmentId ? joinPaths(TM_ATTACHMENT_URL, attachmentId) : ''

export const removeParams = (url: string) => {
  const urlObj = new URL(url)
  urlObj.search = ''
  return urlObj.toString()
}

import { inject, injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'
import { SERVICE_TYPES } from '@/core/container/types'
import type FieldDataCacheService from '@/services/forms/fieldCache/fieldDataCacheService'
import type { IMainServerSub } from '@/services/transport/serverEvents'
import { CacheableFields } from '@/services/forms/fieldCache/types'

@injectable()
export default class FieldAssigneeCacheClearResolverService implements Resolvable {
  constructor(
    @inject(SERVICE_TYPES.ServerSubscriptionService)
    protected readonly serverSubscriptionService: IMainServerSub,
    @inject(SERVICE_TYPES.FieldDataCacheService) protected readonly fieldDataCacheService: FieldDataCacheService,
  ) {}

  private readonly clearCacheEvent = 'subAccountsClosed'

  private clearCache = () => {
    this.fieldDataCacheService.clearForField(CacheableFields.FIELD_ASSIGNEE)
  }

  public async resolve() {
    this.serverSubscriptionService.subscribe(this.clearCacheEvent, this.clearCache)
  }

  public async unresolve() {
    this.serverSubscriptionService.unsubscribe(this.clearCacheEvent, this.clearCache)
  }
}

import { injectable } from 'inversify'
import type { Resolvable } from '@/services/resolvers/types'

type ScriptResolverServiceParams = { src: string }

@injectable()
export default class ScriptResolverService implements Resolvable<ScriptResolverServiceParams> {
  public async resolve(params: ScriptResolverServiceParams) {
    await this.load(params.src)
  }

  public async unresolve(params: ScriptResolverServiceParams) {
    await this.unload(params.src)
  }

  protected async load(src: string) {
    return new Promise((resolve, reject) => {
      let shouldAppend = false
      let el: any = document.querySelector(`script[src="${src}"]`)
      if (!el) {
        el = document.createElement('script')
        el.type = 'text/javascript'
        el.src = src
        shouldAppend = true
      } else if (el.hasAttribute('data-loaded')) {
        resolve(el)
        return
      }

      el.addEventListener('error', reject)
      el.addEventListener('abort', reject)
      el.addEventListener('load', () => {
        el.setAttribute('data-loaded', true)
        resolve(el)
      })

      if (shouldAppend) document.head.appendChild(el)
    })
  }

  protected unload(src: string) {
    return new Promise((resolve) => {
      const el = document.querySelector(`script[src="${src}"]`)

      if (!el) {
        resolve(false)
        return
      }

      document.head.removeChild(el)

      resolve(true)
    })
  }
}

import { getDependencies, type interfaces } from 'inversify'
import { uniq } from 'lodash-es'
import type { AppModule, Service, ServiceModule, ServiceModuleOptions, ServicesType } from '@/config/types'
import { bindService, dynamicClassDependsSymbol, getContainer } from './container'
import { getSymbolFor } from '@/utils/app'
import TmLogicError from '@/core/error/tmLogicError'
import { regisiterInServiceManager } from '@/core/middlewares/middleware'
import { idleTask } from '@/utils/idleTask'
import type { Dict } from '@/types'
import { Queue } from '@/utils/queue'
import { isTest } from '@/utils/system'
import { TmBaseError } from '@/core/error/tmBaseError'
import type { RegisterServicesForModuleHooks } from '@/core/container/deps.types'

// not loaded and not registered in the IoC dependencies
const servicesDeclaration = new Map<string, Service>()
// queue of deps to registration in the IoC
const servicesToBindQueue = new Queue<Service>('id')
// deps are not registered in the IoC dependencies
const unboundDeps = new Map<string, Service>()
// not loaded modules
const modules = new Map<string, ServiceModule>()
// module loader hooks
const moduleLoadedHooks = new Map<string, RegisterServicesForModuleHooks['moduleLoaded']>()
// dependencies are currently downloading and binding in the IoC
const processingDeps = new Map<string, Promise<void>>()
// routes with loaded and resolved dependencies
const resolvedRoutesSet = new Set<string>()
// dependencies are bound to IoC
const boundBackgroundDepsSet = new Set<string>()

// used in background deps loading
const registeredDepsSet = new Set()
const completedBackgroundDepsSet = new Set<string>()
const completedForegroundDepsSet = new Set<string>()
let resolveBindAllDepsFn = (_n: number) => {}
let rejectBindAllDepsFn = (_e: Error) => {}
let callIdleTaskCounter = 0
let isIdleTaskRunning = false
// to VUE_APP_SERVICES_IN_MODULES_WARNING = true
const servicesInModules: Record<string, { module: string; from: Set<string> }> = {}

export const getNotLoadedModules = (): Dict<ServiceModule> => {
  const result: Dict<ServiceModule> = {}
  for (const [name, srv] of modules.entries()) {
    if (srv.promise instanceof Promise) {
      continue
    }
    result[name] = srv
  }
  return result
}

export const loadModules = async (moduleNames: string[]) => {
  const promises: Array<Promise<unknown>> = []
  moduleNames = uniq(moduleNames)
  for (const name of moduleNames) {
    const module = modules.get(name)
    if (!module) {
      continue
    }
    if (module.promise instanceof Promise) {
      promises.push(module.promise)
    } else {
      // this is necessary to avoid double loading of module
      module.promise = module.loader()
      promises.push(module.promise)
    }
  }
  await Promise.all(promises)
  for (const name of moduleNames) {
    modules.delete(name)
  }
}

export const getServicesIterator = (routeName?: string, moduleNames?: AppModule[]): IterableIterator<Service> => {
  const condition = (srv: Service) =>
    Boolean(srv.routeName === routeName || (srv.module && moduleNames?.includes(srv.module as AppModule)))
  return servicesToBindQueue.cutSubQueue(condition)
}

/**
 * Loading and binding into IoC all startup dependencies
 * @returns
 */
export const loadAndBindStartupDeps = async () => {
  const foregroundModules: AppModule[] = []
  for (const [name, module] of modules.entries()) {
    if (module.options?.startup) {
      if (!module.options.background) {
        foregroundModules.push(name as AppModule)
      }
    }
  }
  if (foregroundModules.length) {
    return priorityLoadAndBindAllDepsForModules('', foregroundModules)
  }
  return Promise.resolve()
}

/**
 * Loading and binding into IoC all dependencies in the BACKGROUND
 * @returns
 */
export const backgroundLoadAndBindAllDeps = async () => {
  return new Promise<number>((resolve, reject) => {
    resolveBindAllDepsFn = resolve
    rejectBindAllDepsFn = reject
    idleTask(modules.entries(), async ([, module]) => {
      if (!module.promise) {
        module.promise = module.loader()
      }
    }).catch((e) => {
      reject(e)
    })
  })
}

/**
 * Loading and binding specific dependencies in IoC in FOREGROUND
 * @param routeName route name (optional)
 * @param modulesList array of module names (optional)
 */
export const priorityLoadAndBindAllDepsForModules = async (routeName: string = '', modulesList: AppModule[] = []) => {
  if (Array.isArray(modulesList) && modulesList.length) {
    await loadModules(modulesList)
  }
  const services = getServicesIterator(routeName, modulesList)
  for (const srv of services) {
    await bindDep(srv)
  }
}

export const registerServicesForModule = async (
  name: AppModule,
  services: ServicesType<string>,
  hooks?: RegisterServicesForModuleHooks,
) => {
  if (typeof hooks?.moduleLoaded === 'function') {
    moduleLoadedHooks.set(name, hooks.moduleLoaded)
  }

  Object.keys(services).forEach((id: string) => {
    const srv = servicesDeclaration.get(id)
    if (srv) {
      servicesDeclaration.delete(id)
      Object.assign(srv, services[id])
      srv.id = id
      srv.loader = () => services[id].bindingValue
      srv.module = name
      servicesToBindQueue.add(srv)
      registeredDepsSet.add(id)
      unboundDeps.set(id, srv)
    } else {
      throw new TmLogicError(`Service with the id=${id} from module ${name} was not found in the module declaration`)
    }
  })
  modules.delete(name)
  if (isTest()) {
    // we don't need to load dependencies in the background for unit tests
    return
  }
  try {
    if (!isIdleTaskRunning) {
      callIdleTaskCounter += 1
      isIdleTaskRunning = true
      let errorCounter = 0
      await idleTask(
        servicesToBindQueue,
        (srv: Service) => {
          // we can do it synchronously for background loading
          bindDep(srv, false)
          if (boundBackgroundDepsSet.has(srv.id!)) {
            regisiterInServiceManager(srv)
            completedBackgroundDepsSet.add(srv.id!)
          }
        },
        (e: Error, srv: Service) => {
          errorCounter += 1
          if (errorCounter > 10) {
            throw e
          }
          servicesToBindQueue.add(srv)
        },
      )

      isIdleTaskRunning = false
      callIdleTaskCounter -= 1
      if (!modules.size && !callIdleTaskCounter) {
        //  these lines is need to diagnostic
        // console.log('bound in background count = ', completedBackgroundDepsSet.size)
        // console.log('bound in foreground count = ', completedForegroundDepsSet.size)
        // console.log('registered deps count = ', registeredDepsSet.size)
        // console.log('boundForegroundDepsSet = ', Array.from(completedForegroundDepsSet.keys()).join(','))
        const allSrvCount = servicesDeclaration.size + registeredDepsSet.size
        if (
          allSrvCount === completedBackgroundDepsSet.size ||
          allSrvCount === completedBackgroundDepsSet.size + completedForegroundDepsSet.size
        ) {
          resolveBindAllDepsFn(completedBackgroundDepsSet.size)
        } else {
          const ids: unknown[] = []
          if (completedBackgroundDepsSet.size + completedForegroundDepsSet.size !== allSrvCount) {
            for (const key of servicesDeclaration.keys()) {
              if (!completedBackgroundDepsSet.has(key) && !completedForegroundDepsSet.has(key)) {
                ids.push(key)
              }
            }
            for (const key of registeredDepsSet.keys()) {
              if (!completedBackgroundDepsSet.has(key as string) && !completedForegroundDepsSet.has(key as string)) {
                ids.push(key)
              }
            }
          }
          rejectBindAllDepsFn(
            new TmLogicError(
              'Background services loading: these services were registered from modules but not registered in middlewares: ' +
                ids.join(','),
            ),
          )
        }
        registeredDepsSet.clear()
        boundBackgroundDepsSet.clear()
        completedBackgroundDepsSet.clear()
        completedForegroundDepsSet.clear()
      }
    }
  } catch (e) {
    rejectBindAllDepsFn(e as Error)
  }
}

const getDynamicClassDependencies = (bindingValue: () => unknown): Pick<interfaces.Target, 'serviceIdentifier'>[] => {
  if (!bindingValue[dynamicClassDependsSymbol]) {
    throw new TmLogicError('Not set dependencies for dynamic class')
  }
  return bindingValue[dynamicClassDependsSymbol]
}

export const checkDeps = (key: string, service: Service): AppModule[] => {
  if (service.bindingType === 'value') {
    return []
  }
  const metadataReader = (getContainer() as unknown as { _metadataReader: interfaces.MetadataReader })._metadataReader
  let deps: Pick<interfaces.Target, 'serviceIdentifier'>[] = []
  try {
    deps =
      service.bindingType === 'dynamicClass'
        ? getDynamicClassDependencies(service.bindingValue)
        : getDependencies(metadataReader, service.bindingValue)
  } catch (e) {
    if ((e as Error).message?.indexOf('Missing required @inject') >= 0) {
      return []
    }
  }
  const result = new Set<AppModule>()
  for (let i = 0; i < deps.length; i++) {
    const dep = deps[i]
    let depId: string = dep.serviceIdentifier.toString()
    if (typeof dep.serviceIdentifier === 'symbol') {
      depId = dep.serviceIdentifier.description as string
    }
    const srv = servicesDeclaration.get(depId)
    if (srv?.module) {
      result.add(srv.module)
      if (import.meta.env.VUE_APP_SERVICES_IN_MODULES_WARNING === 'true') {
        let record = servicesInModules[depId]
        if (!record) {
          record = { module: srv.module, from: new Set() }
        }
        record.from.add(key)
        servicesInModules[depId] = record
      }
    }
  }
  return Array.from(result)
}

export const printServicesInModules = () => {
  const obj = {}
  Object.keys(servicesInModules).forEach((key) => {
    obj[key] = { module: servicesInModules[key].module, services: Array.from(servicesInModules[key].from).join(',') }
  })
  // eslint-disable-next-line no-console
  console.table(obj)
}

export const bindDep = async (service: Service, foreground = true) => {
  if (!service) {
    return
  }
  const processingPromise = processingDeps.get(service.id!)
  if (processingPromise) {
    if (foreground) {
      await processingPromise
    } else {
      return
    }
  }
  if (getContainer().isBound(getSymbolFor(service.id!))) {
    return
  }
  let resolveFunc
  processingDeps.set(
    service.id!,
    new Promise<void>((resolve) => {
      resolveFunc = resolve
    }),
  )
  if (service.module) {
    const module = modules.get(service.module)
    if (module?.promise) {
      await module.promise
    } else if (module) {
      await loadModules([service.module])
    }
  }
  if (typeof service.bindingValue !== 'function') {
    // service.bindingValue is not constructor
    if (!service.loader) {
      throw new TmLogicError(`Dependency with id=${service.id!.toString()} must have a loader function`)
    }
    const res = await service.loader()
    service.bindingValue = res.default
  }
  const metadataReader = (getContainer() as unknown as { _metadataReader: interfaces.MetadataReader })._metadataReader
  let deps: Array<Pick<interfaces.Target, 'serviceIdentifier'>> = []
  try {
    deps =
      service.bindingType === 'dynamicClass'
        ? getDynamicClassDependencies(service.bindingValue)
        : getDependencies(metadataReader, service.bindingValue)
  } catch (e) {
    if (e instanceof Error) {
      if (typeof service.id === 'string') {
        throw new Error(`An error occurred when trying to bind the "${service.id}" service: ${e.message}`)
      } else {
        throw new Error(`An error occurred when trying to bind the untitled dependency: ${e.message}`)
      }
    } else {
      throw e
    }
  }
  for (let i = 0; i < deps.length; i++) {
    const dep = deps[i]
    let depId: string = dep.serviceIdentifier.toString()
    if (typeof dep.serviceIdentifier === 'symbol') {
      depId = dep.serviceIdentifier.description as string
    }
    const srv = unboundDeps.get(depId) ?? servicesDeclaration.get(depId)
    if (srv) {
      if (foreground) {
        await bindDep(srv)
      } else {
        processingDeps.delete(service.id!)
        servicesToBindQueue.remove(service)
        // add to end of queue
        servicesToBindQueue.add(service)
        return
      }
    }
  }
  bindService(service.id!, service)
  unboundDeps.delete(service.id!)
  if (foreground) {
    regisiterInServiceManager(service)
    completedForegroundDepsSet.add(service.id!)
  } else {
    boundBackgroundDepsSet.add(service.id!)
  }
  resolveFunc()
  processingDeps.delete(service.id!)
}

export const registerDepsForRoute = (services: Service[], routeName: string): Record<symbol, Service> => {
  const result: Record<symbol, Service> = {}
  services.forEach((srv) => {
    srv.routeName = routeName
    srv.id = srv.bindingValue
    servicesToBindQueue.add(srv)
    registeredDepsSet.add(srv.id)
    result[srv.bindingValue] = srv
  })
  return result
}

export const registerModuleDeclaration = (
  name: AppModule,
  loader: () => Promise<unknown>,
  services: Record<string, symbol>,
  options?: ServiceModuleOptions,
): Record<symbol, Service> => {
  modules.set(name, {
    loader: () => {
      return loader().then(() => {
        if (moduleLoadedHooks.has(name)) {
          moduleLoadedHooks.get(name)!(name)
        }
      })
    },
    services,
    options,
  })
  Object.keys(services).forEach((type: string) => {
    const srv: Service = {
      id: type,
      module: name,
      bindingValue: type,
    }
    servicesDeclaration.set(type, srv)
  })
  return services
}

export const isModulesAreLoaded = (moduleNames: string[]) => {
  let result = true
  for (const name of moduleNames) {
    const module = modules.get(name)
    if (module && !module.promise) {
      result = false
      break
    }
  }
  return result
}

export const isAllDepsAreResolved = (routeName: string, moduleNames: string[] = []) => {
  if (resolvedRoutesSet.has(routeName)) {
    return true
  }
  const serviceMatchCondition = (srv: Service) =>
    Boolean(
      processingDeps.get(srv.id!) || srv.routeName === routeName || (srv.module && moduleNames.includes(srv.module)),
    )
  for (const srv of servicesDeclaration.values()) {
    if (serviceMatchCondition(srv)) {
      return false
    }
  }
  const services = servicesToBindQueue.filter(serviceMatchCondition)
  if (services?.length) {
    return false
  }
  resolvedRoutesSet.add(routeName)
  return true
}

export const getModulesMap = () => {
  if (!isTest()) {
    throw new TmBaseError('Allowed only in tests')
  }
  return modules
}

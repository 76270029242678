<template>
  <div class="page-content-sidebar-items">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.page-content-sidebar-items {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
</style>

import { inject, injectable } from 'inversify'
import { isEmpty } from 'lodash-es'
import type { WrapperServiceInterface, WrapperParams, WrapperTypesServicesKeys } from '@/services/wrappers/types'
import BaseWrapper from '@/data/models/wrappers/BaseWrapper'
import { SERVICE_TYPES } from '@/core/container/types'
import type BaseWrapperService from '@/services/wrappers/baseWrapperService'
import type LoggerService from '@/services/loggerService'
import type { ModelRaw } from '@/types'
import {
  COLUMNS_URL_QUERY_PARAM,
  FILTERS_URL_QUERY_PARAM,
  GROUPING_URL_QUERY_PARAM,
  PAGING_URL_QUERY_PARAM,
  SEARCH_URL_QUERY_PARAM,
  SORTS_URL_QUERY_PARAM,
  UI_STATE_URL_QUERY_PARAM,
} from '@/core/tables/types'
import type {
  WrapperTableParams,
  WrapperTableParamsToSave,
  WrapperTableServiceInterface,
} from '@/services/wrappers/table/types'

@injectable()
export default class TableWrapperService implements WrapperServiceInterface, WrapperTableServiceInterface {
  constructor(
    @inject(SERVICE_TYPES.BaseWrapperService) protected readonly baseWrapperService: BaseWrapperService<BaseWrapper>,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {}

  public build(wrapperId: string, wrapperType?: WrapperTypesServicesKeys, model?: typeof BaseWrapper): void {
    this.baseWrapperService.build(wrapperId, wrapperType, BaseWrapper)
    this.log(wrapperId, 'build')
  }

  public clearParams(wrapperId: string): void {
    this.baseWrapperService.clearParams(wrapperId)
    this.log(wrapperId, 'clearParams')
  }

  public destroy(wrapperId: string): void {
    this.baseWrapperService.destroy(wrapperId)
    this.log(wrapperId, 'destroy')
  }

  public getParams(wrapperId: string): WrapperParams {
    return this.baseWrapperService.getParams(wrapperId)
  }

  public getTableParams(wrapperId: string): WrapperParams {
    return this.getParams(wrapperId)
  }

  public getTableParam(wrapperId: string, key: string): unknown {
    return this.getParam(wrapperId, key)
  }

  public getParam(wrapperId: string, key: string): unknown {
    return this.getParams(wrapperId)[key]
  }

  public getWrapperData(wrapperId: string): ModelRaw<BaseWrapper> {
    return this.baseWrapperService.getWrapperData(wrapperId)
  }

  public isExist(wrapperId: string): boolean {
    return this.baseWrapperService.isExist(wrapperId)
  }

  public patchParams(wrapperId: string, params?: WrapperParams): WrapperParams {
    this.log({ wrapperId, params }, 'patchParams')
    params = this.replaceParams(wrapperId, params ?? {})

    return params
  }

  public removeParams(wrapperId: string, paramsToDelete: string[]): void {
    this.log({ wrapperId, paramsToDelete }, 'removeParams')
    this.baseWrapperService.removeParams(wrapperId, paramsToDelete)
  }

  public setParams(wrapperId: string, params?: WrapperParams): void {
    this.log({ wrapperId, params }, 'setParams')
    this.baseWrapperService.setParams(wrapperId, params)
  }

  public async setParamsByTableData(
    wrapperId: string,
    params: WrapperTableParams,
    _paramsToSave: WrapperTableParamsToSave,
    _shouldReplaceState = false,
  ) {
    this.log({ wrapperId, params }, 'setParamsByTableData')

    const resultQuery: Record<string, unknown> = {}
    for (const [key, value] of Object.entries(params)) {
      if (isEmpty(value)) {
        this.log(`remove key${key}`, 'setParamsByTableData:remove')
        this.removeParams(wrapperId, [key])
      } else {
        this.log({ [key]: value }, 'setParamsByTableData:extend')
        resultQuery[key] = value
      }
    }

    this.log(resultQuery, 'setParamsByTableData:resultQuery')

    this.replaceParams(wrapperId, resultQuery)
    return { canBeRestored: false }
  }

  public replaceParams(wrapperId: string, paramsToReplace: Record<string, unknown>) {
    const params = { ...this.getParams(wrapperId) }
    Object.entries(paramsToReplace).forEach(([key, value]) => {
      params[key] = value
    })
    this.setParams(wrapperId, params)
    return params
  }

  public getTableParamKey(key: string, wrapperId: string): string {
    return key
  }

  public async removeParamsByTableKeys(wrapperId: string, keysToRemove: string[], shouldReplaceState = false) {
    this.log({ wrapperId, keysToRemove }, 'removeParamsByTableKeys')
    this.removeParams(wrapperId, keysToRemove)
  }

  public isBuilt(wrapperId: string): boolean {
    return this.baseWrapperService.isExist(wrapperId)
  }

  public getAllTableParamsKeys(): string[] {
    return [
      FILTERS_URL_QUERY_PARAM,
      SORTS_URL_QUERY_PARAM,
      PAGING_URL_QUERY_PARAM,
      GROUPING_URL_QUERY_PARAM,
      COLUMNS_URL_QUERY_PARAM,
      UI_STATE_URL_QUERY_PARAM,
      SEARCH_URL_QUERY_PARAM,
    ]
  }

  protected log(message: unknown, subchannel?: string) {
    this.loggerService.log('tableWrapper', JSON.stringify(message), subchannel)
  }
}

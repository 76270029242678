import { LogFormat, LogLevel } from '@/core/logger/types'
import TmLogicError from '../error/tmLogicError'

export default class BaseLogger {
  // eslint-disable-next-line @typescript-eslint/default-param-last
  public static log(channel: string, message: string, format: LogFormat = LogFormat.GENERAL, level: LogLevel): void {
    const toLog = this.toFormat(channel, message, format)
    switch (level) {
      case LogLevel.ERROR:
        // eslint-disable-next-line
        console.error(toLog)
        break
      case LogLevel.INFO:
        // eslint-disable-next-line
        console.info(toLog)
        break
      case LogLevel.WARNING:
        // eslint-disable-next-line
        console.warn(toLog)
        break
      case LogLevel.NULL:
        break
      case LogLevel.DEBUG:
        // eslint-disable-next-line
        console.debug(toLog)
        break
      case LogLevel.LOG:
      default:
        // eslint-disable-next-line
        console.log(toLog)
    }
  }

  public static stack(stack: string, level: LogLevel) {
    if (level === LogLevel.NULL) {
      return
    }
    // eslint-disable-next-line
    console.debug(stack)
  }

  // eslint-disable-next-line @typescript-eslint/default-param-last
  public static groupStart(channel: string, groupName: string, format: LogFormat = LogFormat.GENERAL, level: LogLevel) {
    if (level === LogLevel.NULL) {
      return
    }
    // eslint-disable-next-line
    console.groupCollapsed(this.toFormat(channel, groupName, format))
  }

  public static groupEnd(level: LogLevel) {
    if (level === LogLevel.NULL) {
      return
    }
    // eslint-disable-next-line
    console.groupEnd()
  }

  public static table(
    channel: string,
    data: Record<string, string>[],
    // eslint-disable-next-line @typescript-eslint/default-param-last
    format: LogFormat = LogFormat.GENERAL,
    level: LogLevel,
  ) {
    if (level === LogLevel.NULL) {
      return
    }
    this.log(channel, '', format, level)
    // eslint-disable-next-line
    console.table(data)
  }

  public static toFormat(channel: string, message: string, format: LogFormat): any {
    if (format === LogFormat.GENERAL) {
      return `| ${new Date().toLocaleString()} | ${channel} | ${message}`
    }
    throw new TmLogicError('Unknown format')
  }

  public static raw(raw: any, level: LogLevel) {
    switch (level) {
      case LogLevel.DEBUG:
        // eslint-disable-next-line
        console.debug(raw)
        break
      case LogLevel.NULL:
        break
      default:
        // eslint-disable-next-line
        console.log(raw)
    }
  }

  public static time(label: string, level: LogLevel) {
    switch (level) {
      case LogLevel.NULL:
        break
      default:
        // eslint-disable-next-line
        console.time(label)
    }
  }

  public static timeLog(label: string, level: LogLevel) {
    switch (level) {
      case LogLevel.NULL:
        break
      default:
        // eslint-disable-next-line
        console.timeLog(label)
    }
  }

  public static timeEnd(label: string, level: LogLevel) {
    switch (level) {
      case LogLevel.NULL:
        break
      default:
        // eslint-disable-next-line
        console.timeEnd(label)
    }
  }
}

import { inject, injectable } from 'inversify'
import type { InternalTrackingPayload, TrackableConfig, TrackingEvent, Trackable } from '@/services/tracking/types'
import TmLogicError from '@/core/error/tmLogicError'
import { SERVICE_TYPES } from '@/core/container/types'
// TODO: Fix ignored tp/forbid-import-composable-to-service
// eslint-disable-next-line tp/using-vue-in-services-restriction,tp/forbid-import-composable-to-service
import type { Plugin } from '@/composition/vue/compositionApi'
import { isProd, isTest } from '@/utils/system'
import ConfigReader from '@/core/configReader'
import type { HttpService } from '@/services/transport/httpService'
import type { AbstractEndpointsInterface } from '@/services/endpointsService'
import type SegmentAnalyticsService from '@/services/tracking/segmentAnalyticsService'
import type WindowService from '@/services/browser/windowService'

@injectable()
export default class TrackingService {
  private trackers: Trackable[] = []

  private isLoaded = false

  constructor(
    @inject(SERVICE_TYPES.Api) protected readonly httpService: HttpService,
    @inject(SERVICE_TYPES.EndpointsService) protected readonly endpointsService: AbstractEndpointsInterface,
    @inject(SERVICE_TYPES.WindowService) protected readonly windowService: WindowService,
    @inject(SERVICE_TYPES.ZendeskService) protected readonly zendeskService: Trackable,
    @inject(SERVICE_TYPES.SegmentAnalyticsService) protected readonly segmentAnalyticsService: Trackable,
    @inject(SERVICE_TYPES.ShareASaleService) protected readonly shareASaleService: Trackable,
    @inject(SERVICE_TYPES.FirstPromoterService) protected readonly firstPromoterService: Trackable,
    @inject(SERVICE_TYPES.PostHogService) protected readonly postHogService: Trackable,
    @inject(SERVICE_TYPES.GoogleAnalyticsService) protected readonly googleAnalyticsService: Trackable,
    @inject(SERVICE_TYPES.HotJarService) protected readonly hotJarService: Trackable,
    @inject(SERVICE_TYPES.ZukoAnalyticsService) protected readonly zukoAnalyticsService: Trackable,
    @inject(SERVICE_TYPES.ConvertService) protected readonly convertService: Trackable,
  ) {}

  public registerApp(app: { use: (plugin: Plugin, config: TrackableConfig) => void }): void {
    this.initialize()
    this.windowService.self().onload = () => {
      if (!this.isLoaded) {
        this.isLoaded = true
        this.getTrackers().forEach((tracker: Trackable) => {
          app.use(tracker.getPlugin(), tracker.getConfig())
        })
      }
    }
  }

  public initialize(): void {
    this.addTracker(this.zendeskService)
      .addTracker(this.segmentAnalyticsService)
      .addTracker(this.shareASaleService)
      .addTracker(this.firstPromoterService)
      .addTracker(this.postHogService)
      .addTracker(this.googleAnalyticsService)
      .addTracker(this.hotJarService)
      .addTracker(this.zukoAnalyticsService)
      .addTracker(this.convertService)
  }

  public addTracker(tracker: Trackable): TrackingService {
    const config = ConfigReader.config()
    if (!this.isSupportedEnvironment() && !config.tracking.forceTracking) {
      return this
    }
    if (this.getTrackers().includes(tracker)) {
      throw new TmLogicError('Already existed tracker cannot be added')
    }

    this.getTrackers().push(tracker)
    return this
  }

  public getTrackers(): Trackable[] {
    return this.trackers
  }

  /**
   * Do not use it as asynchronous function await track(), it will block code execution for tracking server responses time
   */
  public track(event: TrackingEvent, disableSegmentTracking?: boolean): void {
    const internalTrackingPath = this.endpointsService.getPath('auditLogs')
    const segmentService: SegmentAnalyticsService = this.segmentAnalyticsService as SegmentAnalyticsService
    let trackingTraits: Record<string, any> = {}
    if (event.properties) {
      trackingTraits = event.properties
    }
    trackingTraits.category = event.category

    try {
      if (!disableSegmentTracking) {
        segmentService.track(event, trackingTraits)
      }
      this.httpService.post(internalTrackingPath, {
        eventName: event.name,
        properties: trackingTraits,
      } as InternalTrackingPayload)
    } catch {
      // do nothing if tracking failed for some reason
    }
  }

  public cleanUp() {
    this.getTrackers().forEach((tracker) => {
      tracker.cleanUp()
    })
  }

  private isSupportedEnvironment(): boolean {
    return isTest() || isProd()
  }
}

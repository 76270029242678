import { inject, injectable } from 'inversify'
import { SERVICE_TYPES } from '@/core/container/types'
import type { Factory } from '@/types'
import type LoggerService from '@/services/loggerService'
import type TableColumnManagerInterface from '@/services/tables/managers/tableColumnManagerInterface'
import type { ColumnsServiceInterface } from '@/core/tables/types'
import TmColumnManagerError from '@/core/error/tmColumnManagerError'
import type ColumnRepository from '@/data/repositories/table/columnRepository'
import TableElementManagerBase from '@/services/tables/managers/tableElementManagerBase'
import type { LoggerChannels } from '@/config/configDev'
import type EntityManagerService from '@/data/repositories/entityManagerService'
import { TmTableElementManagerFactoryNotFoundError } from '@/core/error/table/tableManager/tmTableElementManagerFactoryNotFoundError'
import Columns from '@/data/models/tables/Columns'

@injectable()
export default class TableColumnManager
  extends TableElementManagerBase<ColumnsServiceInterface>
  implements TableColumnManagerInterface
{
  protected readonly managerId = 'TableColumnManager' as const

  protected readonly loggerChannel: LoggerChannels = 'columns'

  private constructor(
    @inject(SERVICE_TYPES.EntityManager) protected readonly em: EntityManagerService,
    @inject(SERVICE_TYPES.LoggerService) protected readonly loggerService: LoggerService,
  ) {
    super(em, loggerService)
  }

  public getFactoryForTable(tableId: string): Factory<ColumnsServiceInterface> {
    try {
      return super.getFactoryForTable(tableId)
    } catch (e) {
      if (e instanceof TmTableElementManagerFactoryNotFoundError) {
        throw new TmColumnManagerError(`No factory is set for "${tableId}" table`)
      }

      throw e
    }
  }

  public addServiceForTable(tableId: string) {
    const { serviceId } = super.addServiceForTable(tableId)
    this.services[serviceId].setTableId(tableId)

    return {
      serviceId,
      instance: this.services[serviceId],
    }
  }

  protected removeServiceById(tableId: string, serviceId: string) {
    const instance = this.getServiceByServiceId(serviceId)
    this.getColumnRepo().deleteByCondition((model) => model.id === instance.getTableId())

    super.removeServiceById(tableId, serviceId)
  }

  protected getColumnRepo() {
    return this.em.getRepository<ColumnRepository>(Columns)
  }
}

import { injectable } from 'inversify'
import { DomainSettings } from '@/decorators/domainDecorators'
import DomainBaseService from '@/services/domain/domainBaseService'
import NumberAvailableCountry from '@/data/models/domain/NumberAvailableCountry'
import type NumberAvailableCountriesRepository from '@/data/repositories/domain/numberAvailableCountriesRepository'

@DomainSettings({
  model: NumberAvailableCountry,
})
@injectable()
export default class NumberAvailableCountriesService extends DomainBaseService<NumberAvailableCountriesRepository> {
  public getNumberAvailableCountries() {
    return this.getDomainRepository().getNumberAvailableCountries()
  }
}

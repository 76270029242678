import { AttrField, BooleanField, OrmModel, StringField } from 'vuex-orm-decorators'
import BaseModel from '@/data/models/BaseModel'
import { modelNames } from '@/data/models/types'
import { type FormState, defaultFormState } from '@/services/forms/types'

@OrmModel(modelNames.FORM)
export default class Form extends BaseModel {
  @StringField()
  public id: string

  @AttrField(defaultFormState)
  public formState: FormState

  /**
   * VALIDATION
   * */
  @BooleanField(false)
  /** If The form is valid. */
  public valid: boolean

  @BooleanField(false)
  /** If the form is invalid. */
  public invalid: boolean

  @BooleanField(false)
  /** If the form value has been changed. * */
  public changed: boolean

  @BooleanField(false)
  /** If the form has been blurred. */
  public touched: boolean

  @BooleanField(true)
  /** If the form wasn't blurred. */
  public untouched: boolean

  @BooleanField(true)
  /** If the form value was not manipulated. */
  public pristine: boolean

  @BooleanField(false)
  /** If the form value has been manipulated. */
  public dirty: boolean

  @BooleanField(false)
  /** Indicates if the form validation is in progress. */
  public pending: boolean

  @BooleanField(false)
  /** If the form is required. */
  public required: boolean

  @BooleanField(false)
  /** If the form has been validated at least once. */
  public validated: boolean

  @BooleanField(false)
  /** If the form has been validated and is valid. */
  public passed: boolean

  @BooleanField(false)
  /** If the form has been validated and is invalid. */
  public failed: boolean
}

import type { CancelToken } from 'axios'
import type { Model } from '@vuex-orm/core'
import type { CountryCode } from 'libphonenumber-js'
import type { RouteRecordRaw, RouteLocationRaw } from 'vue-router'
// eslint-disable-next-line tp/import-from-vue-composition-only
import type { Component } from 'vue'
import type { SortDirection } from '@/core/tables/types'
import type {
  PaginationUrlFilterType,
  PaginationUrlParametersSortType,
  PaginationUrlType,
} from '@/services/tables/types'
import type { EndpointParams } from '@/services/endpoints'
import type { RegisteredServices } from '@/core/container/types'
import type { ArrayAsConstValuesType, Dict, ModelRaw, NumbersObject, RecordAsConstValuesType } from '@/types'
import type BaseModel from '@/data/models/BaseModel'
import type { PaginationOffsetResponse, PaginationParams } from '@/services/tables/pagination/types'
import type { BulkBaseBody, BulkDeleteParams, BulkSettingsType } from '@/services/bulk/types'
import type { ModelRelationArray, RepoModel } from '@/services/domain/types'
import type { TmWrappers } from '@/wrappers'
import type {
  BlobResponse,
  ExportResultResponse,
  ExportToPdfResultResponse,
  BroadcastCallback,
  BroadcastEvent,
  BroadcastEventMap,
} from '@/services/transport/types'
import type { Facetable, FaceterSettingsType } from '@/services/facets/types'
import type { LoggerChannels } from '@/config/configDev'
import { isRecordUnknown } from '@/utils/typeGuards'
import type { LocaleTranslationData, SupportedLocales } from '@/services/translateService.types'

export interface AutocompleteResultBase<T> {
  items: T[]
  pageCount: number
}
export interface AutocompleteResult<T extends BaseModel> extends AutocompleteResultBase<ModelRaw<T>> {}

export interface AutocompletableBase<T> {
  autocomplete(
    search: string,
    endpointParams: EndpointParams,
    page: number,
    filters: PaginationUrlFilterType,
    perPage?: number,
    sort?: PaginationUrlParametersSortType,
  ): Promise<AutocompleteResultBase<T>>
}

export interface Autocompletable<T extends BaseModel = BaseModel> extends AutocompletableBase<ModelRaw<T>> {
  getEntitiesByIds(ids: string[], withRelations: ModelRelationArray<RepoModel<T>>): Promise<T[]>
}

export interface AutocompleteKeyBuilderI {
  pageMapKey(model: typeof BaseModel, page: number, key: string): string
  autocompleteKey(model: typeof BaseModel, key: string): string
  entityFromAutocompleteKey(key: string): string
  autocompleteFiltersKey(filters: PaginationUrlFilterType): string
}

interface IBaseOption {
  value: string
}

export interface ContactAutocompleteItem extends IBaseOption {
  id: string
  fullName: string
  phone: string
  avatar?: string
}

export interface PhoneAutocompleteItem extends IBaseOption {
  id?: string
  country: TmCountryCode
}

export type PhoneOrContactAutocompleteItem =
  | (ContactAutocompleteItem & { autocompleteType: 'contact' })
  | (PhoneAutocompleteItem & { autocompleteType: 'phone' })

export interface Gridable<T> {
  init?: () => void
  gridRequest(
    queryParameterBag: PaginationUrlType,
    paginationParamsBag?: PaginationParams,
    searchQuery?: string,
    searchFields?: string[],
    cancelToken?: CancelToken,
    endpoindParams?: EndpointParams,
  ): Promise<PaginationOffsetResponse<T>>
  getFetchEndpointParams(): EndpointParams
  filterIdsByStore?(ids: string[]): string[]
}

export interface Exportable {
  exportByEntity(
    entity: typeof BaseModel,
    params: ExportBody,
    tableServiceId?: RegisteredServices,
    endpointParams?: EndpointParams,
  ): Promise<ExportResultResponse>
  exportToPdf(id: string, entity: typeof BaseModel): Promise<ExportToPdfResultResponse>
  export(
    extension: ExportAvailableExtensions,
    entity: typeof BaseModel,
    tableServiceId?: RegisteredServices,
    endpointParams?: EndpointParams,
    email?: string,
  ): Promise<ExportResultResponse>
  downloadFile(response: BlobResponse): void
}

export interface SerializerInterface {
  serialize<T extends Dict<any>>(model: typeof BaseModel, toSerialize: T): Dict<any>
  unserialize(raw: any, modelInstance: Model): Model
}

export const DATE_FORMATES = {
  DAY_FORMAT: 'd', // 2
  DATE_FORMAT: 'd MMM', // 2 May
  DATE_FORMAT_YEAR: 'd MMM yyyy', // 2 May 2021
  DATE_FORMAT_FULL_MONTH_YEAR: 'MMMM yyyy', // January 2021
  DATE_FORMAT_FULL_DAY_FULL_MONTH_YEAR: 'dd MMMM yyyy', // 01 January 2021
  GENERAL_FORMAT: 'd MMM, h:mm aaa', // 2 May 10:51 am
  GENERAL_FORMAT_YEAR: 'd MMM yyyy, h:mm aaa', // 2 May 2021 10:51 am
  GENERAL_FORMAT_YEAR_24H: 'd MMM yyyy, H:mm', // 2 May 2021 22:51
  TIME_FORMAT: 'h:mm aaa', // 10:51 am
  HISTORY_DATE_FORMAT: "d MMM, h:mm a '(UTC' XXX')'",
  SHORT_DATE_FORMAT: 'd MMM yyyy', // 2 May 2021
  FULL_DATE_FORMAT: 'LLLL', // Friday, June 24, 2016 1:42 AM || depends on locale
  FULL_DATE_FIXED_FORMAT: 'EEEE, d MMM yyyy, h:mm aaa', // Tuesday, 7 Jul 2022 4:17 pm
  DATEPICKER_DATE_FORMAT: 'd MMM yyyy', // 2 May 2021
  DATEPICKER_TIME_FORMAT: 'HH:mm',
  DATEPICKER_DATETIME_FORMAT: 'd MMM yyyy, HH:mm',
  DATEPICKER_INNER_FORMAT: 'yyyy/MM/dd', // Use only in the FieldDatepicker
  DATEPICKER_INNER_QUASAR_FORMAT: 'YYYY/MM/DD', // Use only in the FieldDatepicker, moment format for quasar
  DATEPICKER_INNER_SHORT_DATE_FORMAT: 'dd/MM/yyyy', // Use in the FieldDatepicker menu and tpDropdownReminderBase
  DATEPICKER_INNER_DATETIME_FORMAT: 'dd/MM/yyyy, h:mm aaa', // Use only in the FieldDatepicker menu
  DUE_DATE_DATEPICKER_FORMAT: 'dd/MM/yyyy', // Use only in the TmDropdownDateTimePicker menu
  DUE_DATE_DATEPICKER_QUASAR_FORMAT: 'DD/MM/YYYY', // Use only in the TmDropdownDateTimePicker menu
  EXPIRATION_FORMAT: 'dd MMM, HH:mm a', // Use only in the FieldDatepicker menu
  YEAR_FORMAT: 'yyyy',
  HOURS_ONLY: 'h',
  TIMEZONE_OFFSET_FORMAT: 'xxx',
  ISO_FORMAT: "yyyy-MM-dd'T'HH:mm:ss'Z'",
  ISO_FORMAT_WITH_TIMEZONE: "yyyy-MM-dd'T'HH:mm:ssxxx",
  ISO_FORMAT_WITH_TIMEZONE_2: "yyyy-MM-dd'T'HH:mm:ssxxxx",
  DATE_ONLY_FORMAT: 'yyyy-MM-dd',
  WITH_TIME_AND_TIMEZONE: "d MMM, hh:mm a '(UTC' XXX': UTC)'",
  DATE_TIME_AND_TIMEZONE: "d MMM, hh:mm a ('UTC' xxxxx: 'UTC')",
  TIMEZONE: "('UTC' xxxxx: zzzz)",
  DATE_TIME_WITH_YEAR_AND_TIMEZONE: "d MMM, hh:mm aaa ('UTC' xxxxx: zzzz)",
  WEEK_DAY_WITH_DATE_AND_TIMEZONE: "EEEE, d MMM, hh:mm aaa ('UTC' xxxxx: zzzz)",
  SCHEDULED_MESSAGE_RRULE: "yyyyMMdd't'HHmmss'z'",
  D_MMM_YY: 'd MMM yy', // 2 May 21
  WEEK_DAY_3: 'EEE',
  IMPORT_NEW_LIST: 'd MMM yyyy, h:mm:ss aaa',
  STATISTICS: 'dd_MM_yy_HH_mm_ss',
  SHORT_MONTH_FIRST: 'MMM d',
} as const
export type DateFormatesType = RecordAsConstValuesType<typeof DATE_FORMATES>

export const DATE_FORMAT_NOT_CURRENT_YEAR = {
  [DATE_FORMATES.DATE_FORMAT]: 'd MMM yyyy',
  [DATE_FORMATES.GENERAL_FORMAT]: 'd MMM yyyy, h:mm aaa',
  [DATE_FORMATES.HISTORY_DATE_FORMAT]: "d MMM yyyy, h:mm a '(UTC' XXX')'",
  [DATE_FORMATES.EXPIRATION_FORMAT]: 'dd MMM yyyy, HH:mm a',
  [DATE_FORMATES.WITH_TIME_AND_TIMEZONE]: "d MMM yyyy, hh:mm a '(UTC' XXX': UTC)'",
  [DATE_FORMATES.DATE_TIME_WITH_YEAR_AND_TIMEZONE]: "d MMM yyyy, hh:mm aaa ('UTC' xxxxx: zzzz)",
  [DATE_FORMATES.WEEK_DAY_WITH_DATE_AND_TIMEZONE]: "EEEE, d MMM yyyy, hh:mm aaa ('UTC' xxxxx: zzzz)",
} as const

export const DATE_24_FORMAT = {
  [DATE_FORMATES.DATE_TIME_AND_TIMEZONE]: "d MMM, H:mm ('UTC' xxxxx: 'UTC')",
} as const

export type CommonDateUnit = 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second' | 'millisecond'

export type BaseDateUnit =
  | 'year'
  | 'years'
  | 'y'
  | 'month'
  | 'months'
  | 'M'
  | 'week'
  | 'weeks'
  | 'w'
  | 'day'
  | 'days'
  | 'd'
  | 'hour'
  | 'hours'
  | 'h'
  | 'minute'
  | 'minutes'
  | 'm'
  | 'second'
  | 'seconds'
  | 's'
  | 'millisecond'
  | 'milliseconds'
  | 'ms'
export type AvailableDateShift = Partial<
  | 'today'
  | 'in20Min'
  | 'in3Hour'
  | 'thisEvening'
  | 'tomorrow'
  | 'nextWeek'
  | 'fifteenMinutesBefore'
  | 'halfHourBefore'
  | 'oneHourBefore'
  | 'oneDayBefore'
  | 'twoDaysBefore'
  | 'weekBefore'
>

export const weekDaysStartingSunday = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const
export const weekDaysStartingMonday = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const

export type WeekDays = (typeof weekDaysStartingMonday)[number]

export const weekDaysFullVariant = [...weekDaysStartingMonday, 'Day', 'Weekday', 'WeekendDay'] as const

export type WeekDaysFull = (typeof weekDaysFullVariant)[number]

export const monthsVariant = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const

export type Months = (typeof monthsVariant)[number]

export type CreateDateTimeParams = {
  date: string
  hours?: number
  minutes?: number
  seconds?: number
  timezone?: string
}

export enum FilterOperationEnum {
  /**
   * Used for not string field
   */
  isNull = 'isNull',
  /**
   * Used for string field
   */
  isEmpty = 'isEmpty',
  id = 'id',
  /**
   * Equal
   */
  eq = 'eq',
  /**
   * Not equal
   */
  neq = 'neq',
  like = 'like',
  notLike = 'notLike',
  in = 'in',
  notIn = 'notIn',
  /**
   * Less than
   */
  lt = 'lt',
  /**
   * Less than or equal
   */
  lte = 'lte',
  /**
   * Greater than or equal
   */
  gte = 'gte',
  /**
   * Greater than
   */
  gt = 'gt',
  anyOf = 'anyOf',
  allOf = 'allOf',
  noneOf = 'noneOf',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  noneOff = 'noneOff',
}
export type FilterOperationValue<
  ArrayTypes extends FilterOperationEnum = FilterOperationEnum.in | FilterOperationEnum.notIn,
> = {
  [O in Exclude<FilterOperationEnum, ArrayTypes>]: unknown
} & {
  [O in Extract<FilterOperationEnum, ArrayTypes>]: unknown[]
}

export type Operation = keyof typeof FilterOperationEnum
export const isFilterOperation = (value: any): value is Operation => Object.values(FilterOperationEnum).includes(value)
export const isBoolFilterOperation = (operation: Operation): boolean => {
  return ([FilterOperationEnum.isEmpty, FilterOperationEnum.isNull] as Operation[]).includes(operation)
}

export type SchemaType = Array<SchemaValueType>
export type FilterType = 'field' | 'relation'
export type SchemaValueType = {
  name: string
  schema: {
    filter: Array<FilterSchemaType>
    sort: Array<SortSchemaValueType>
    searchFields: string[]
    groupBy: Array<GroupingSchemaValueType>
  }
}
export type FilterSchemaType = {
  name: string
  fields: null | Array<FilterSchemaType>
  type: FilterType
  operators: null | Array<FilterSchemaOperatorType>
}
export type FilterSchemaOperatorType = {
  name: Operation
  constraints: Array<FilterSchemaOperatorConstraintsType>
}
export type FilterSchemaOperatorConstraintsType = {
  type: string
  options: Array<FilterSchemaOperatorConstraintsValueType>
}
export type FilterSchemaOperatorConstraintsValueType = {
  name: string
  value: Array<string | boolean | number> | string | boolean | number
}
export type SortSchemaValueType = {
  name: string
  type: FilterType
  values: null | Array<SortDirection>
  fields: null | Array<SortSchemaValueType>
}
type GroupingField = {
  name: string
  type: string
  values: [string]
  fields: null
}
export type GroupingSchemaValueType = {
  name: string
  type: string
  values: null | Array<string>
  fields: null | Array<GroupingField>
}

export type RoutesListItem = {
  linkToName: string
  route: RouteRecordRaw
}

export interface BulkServiceInterface<B extends BulkBaseBody> {
  bulkDelete(
    data: B,
    ids: string[],
    isAll: boolean,
    filters: PaginationUrlFilterType,
    model: typeof BaseModel,
    otherDeleteParams?: Partial<BulkDeleteParams>,
    endpoindParams?: EndpointParams,
  ): Promise<void>
  bulkUpdate(
    data: B,
    ids: string[],
    isAll: boolean,
    filters: PaginationUrlFilterType,
    model: typeof BaseModel,
  ): Promise<void>
}

export interface DomainBulkServiceInterface<B extends BulkBaseBody> extends BulkServiceInterface<B> {
  settings?(): BulkSettingsType
}

export interface FaceterInterface extends Facetable<NumbersObject> {
  getFaceterSettings(): FaceterSettingsType
}

export type ConfirmationTextSlot = {
  name: string
  value: string
  className?: string
}
export const exportModalId: TmWrappers = 'export'
export type ExportAvailableExtensions = 'csv' | 'xlsx' | 'pdf'
export type ExportBody = {
  fileFormat?: ExportAvailableExtensions
  sort?: PaginationUrlParametersSortType
  searchQuery?: string
  email?: string
  [key: string]: any
}
export type ExportConfig = {
  entity: typeof BaseModel
  tableServiceId?: RegisteredServices
  exportServiceId?: RegisteredServices
  count?: number
  forceDeferredExport?: boolean
  extension: ExportAvailableExtensions
  extensions?: ExportAvailableExtensions[]
  defaultEmail?: string
  endpointParams?: EndpointParams
}
export type SipCallsExportBody = ExportBody & {
  callDirection: string
}
export type ListContactsExportBody = ExportBody & {
  listId: string
}
export const NoticeTypes = ['success', 'info', 'warning', 'error'] as const
export type NoticeType = ArrayAsConstValuesType<typeof NoticeTypes>
export type Notice = {
  group?: string
  type?: NoticeType
  title?: string
  text?: string
  duration?: number
  component?: Component
}

export type ResolverConfig<P = { [key: string]: any }> = {
  service: RegisteredServices
  params?: P
}
export type ResolverItem = Array<ResolverConfig>
export type Resolvers = Array<ResolverConfig | ResolverItem>
export const isResolversConfig = (value: unknown): value is Resolvers => {
  if (!Array.isArray(value)) {
    return false
  }

  return value.every((item) => {
    if (Array.isArray(item)) {
      return isResolversConfig(item)
    }

    return isRecordUnknown(item) && typeof item.service === 'string'
  })
}

export type ModalQueryParams = Record<string, string | undefined>
export type ModalFormQueryParams = ModalQueryParams & {
  editingId?: string
  formId?: string
}
export type ModalFormPartedQueryParams<T> = ModalFormQueryParams & {
  formPart: T
}

export type TranslationKey = string
export type TranslationValue = undefined | string | number | boolean | null
export type TranslationNode = TranslationValue | TranslationValue[] | Dict<TranslationValue>
export interface Translatable {
  t(key: TranslationKey, params?: { [key: string]: any }): string
  tc(key: TranslationKey, count?: number, params?: { [key: string]: any }): string
  getEntityLabel(entityName: string, count: number): string
  exists(str: TranslationKey): boolean
  setLocaleMessages(messages: Record<string, string>): void
  addLocaleMessages(messages: LocaleTranslationData, locale: SupportedLocales): void
  getCurrentLocale(): SupportedLocales
}

export interface Notifiable {
  notify(notice: Notice): void
  notifyError(text: string, title?: string): void
  contactSupport(err?: Error): void
  notifyWarning(text: string, title?: string): void
  entityNotFound(entity: typeof BaseModel, count: number): void
  entityCreated(entity: typeof BaseModel): void
  entityDuplicated(entity: typeof BaseModel, count: number): void
  entityDeleted(entity: typeof BaseModel, count: number): void
  entityUpdated(entity: typeof BaseModel, count: number): void
  notifyFromError(error: unknown): void
}

export interface INativeNotification {
  isHasNotificationFeature(): boolean
  isNotificationGranted(): boolean
  isNotificationDenied(): boolean
  isAllowNotificationRequest(): boolean
  notificationRequest(callback?: (result: string) => void): void
  notify(tag: string, title: string, text: string, icon?: string, onClickCallback?: () => void): void
  playSound(id: string, path: string, volume?: number): void
  playAudioInstance(HTMLAudioElement): void
  createAudioInstance(id: string, path?: string, volume?: number, load?: boolean): HTMLAudioElement
}

export interface ILogger {
  log(channel: LoggerChannels, message: string, subchannel?: string): void
  raw(channel: LoggerChannels, raw: any): void
  error(channel: LoggerChannels, payload: string, subchannel?: string): void
}

export type TimeFormatValue = 'H' | 'h'

export type HoursIntervalType = 'AM' | 'PM'

export type HoursTo12hFormat = {
  hours: number
  type: HoursIntervalType
}

export enum DateTimeRanges {
  ALL_TIME = 'ALL_TIME',
  NO_DATE = 'NO_DATE',
  LAST = 'LAST',
  NEXT = 'NEXT',
  CUSTOM = 'CUSTOM',
  THIS_DAY = 'THIS_DAY',
  LAST_DAY = 'LAST_DAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  NOW_OVERDUE = 'NOW_OVERDUE',
  TIME_FROM_NOW = 'TIME_FROM_NOW',
  // THIS_MONTH = 'THIS_MONTH',
  // LAST_MONTH = 'LAST_MONTH',
  // LAST_THREE_MONTH = 'LAST_THREE_MONTH',
  // THIS_YEAR = 'THIS_YEAR',
  // LAST_YEAR = 'LAST_YEAR'
}

export enum NumericOption {
  BETWEEN = 'BETWEEN',
  GTE = 'GTE',
  LTE = 'LTE',
  IS_EMPTY = 'IS_EMPTY',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
}

export enum NumberFormat {
  DECIMAL = 'decimal',
  CURRENCY = 'currency',
  PERCENT = 'percent',
  INTEGER = 'integer',
}
export const notificationGroups = {
  main: 'main',
  custom: 'custom',
} as const

export const MAX_EXPORT_COUNT = 500

export enum PhoneNumberFormat {
  NATIONAL,
  INTERNATIONAL,
  RFC3966,
}

export const minPhoneNumberLength = 4 // the length of the real number cannot be less than 6 characters, but the Textmagic number can
export const maxPhoneNumberLength = 15

export const allowedPhoneFormatChars = '\\s()*#.\u2014\u2013\u2212-' // \u2014, \u2013 and \u2212 varieties of dash
export const allowedPhoneChars = `+0-9${allowedPhoneFormatChars}`
export const groupingPhoneRegExp = new RegExp(`[${allowedPhoneChars}]+`, 'g')

export type PotentialPhonesData = {
  indexes: number[]
  phone: string
}

export interface FileUploader {
  uploadFile(file: Blob): Promise<any>
}

export type DateTimeFormatOptions = {
  relative?: boolean
  is24h?: boolean
  timeZone?: string
  noTime?: boolean
}

export const TmEmptyCountryCode = ''
export const TmFakeCountryCode = 'ZZ'
/**
 * It is returned from BE if it was not possible to get the country code from the phone number
 */
export const TmUndefinedCountryCode = 'X'
export type TmRealCountryCode = Exclude<TmCountryCode, typeof TmFakeCountryCode>
export type TmCountryCode = CountryCode | typeof TmFakeCountryCode

export const USRegulationsCountries: TmCountryCode[] = ['US', 'CA', 'PR']
export const countriesNotSupportAllSendingSettings: TmCountryCode[] = USRegulationsCountries
export const countriesWithNationalFormat: TmCountryCode[] = USRegulationsCountries
export const countryForGettingStartedPage: TmCountryCode[] = USRegulationsCountries

export const countriesWithoutDefaultSender: TmCountryCode[] = USRegulationsCountries

export type RRulestrByWeekday = {
  weekday: number
  n: unknown
}

export interface RouterPermissionInterface {
  /**
   *
   * @throws {TmNotResolvedAsyncStrategyError} if async strategy has not been resolved before use
   *
   */
  isAllowAccessToRoute(route: RouteLocationRaw): boolean
  /**
   *
   * @throws {TmNotResolvedAsyncStrategyError} if async strategy has not been resolved before use
   *
   */
  getFirstAllowedChildrenRoute(route: RouteLocationRaw): RouteRecordRaw | null
  resolveStrategiesForRoute(route: RouteLocationRaw): Promise<void>

  /**
   * Check permissions for route
   * @see {@link TmPermissions}
   */
  checkPermissionsByRouteByName(routeName: string): boolean
}

export type TmPermissions =
  | 'accountNotifications'
  | 'accountSettings'
  | 'billing'
  | 'autorechargeSettings'
  | 'invoices'
  | 'messagingData'
  | 'subaccountSettings'
  | 'deleteData'
  | 'exportData'
  | 'importContacts'
  | 'mobileApp'
  | 'contacts'
  | 'createNewLists'
  | 'resubscribe'
  | 'composePage'
  | 'history'
  | 'scheduled'
  | 'sendMms'
  | 'reportingAuditLogs'
  | 'reportingCalls'
  | 'reportingMessages'
  | 'reportingNumbers'
  | 'reportingOverview'
  | 'reportingStatements'
  | 'reportingSubAccounts'
  | 'reportingUsers'
  | 'api'
  | 'automationRules'
  | 'carrierLookup'
  | 'distributionLists'
  | 'emailLookup'
  | 'emailToSms'
  | 'makeCalls'
  | 'senderSettings'
  | 'subscribeForms'
  | 'textToSpeechSettings'
  | 'verifyApi'
  | 'byoc'

export type DateLocaleFormat = 'abbreviated' | 'wide' | 'narrow' | 'short'

export enum FeatureFlags {
  Tickets = 'tickets',
  TicketsNotifications = 'ticketsNotifications',
  Tasks = 'tasks',
  Deals = 'deals',
  Campaigns = 'campaigns',
  CampaignsEmail = 'campaignsEmail',
  Segments = 'segments',
  CampaignsSendersFlowTesting = 'campaignsSendersFlowTesting',
  ChatsUseCache = 'chatsUseCache',
  Instagram = 'instagram',
  EmailUnsubscribers = 'EmailUnsubscribers',
  HistoryOutboundEmails = 'HistoryOutboundEmails',
}

export interface ICancelable {
  cancel(): void
}
export interface ICancelablePromise<T> extends Promise<T>, ICancelable {}

export interface IBroadcastSubscriptionService {
  subscribeBroadcast<T extends BroadcastEvent>(eventName: T, callback: BroadcastCallback<T>)
  unsubscribeBroadcast<T extends BroadcastEvent>(eventName: T, callback?: BroadcastCallback<T>)
  broadcastEmit<T extends BroadcastEvent>(eventName: T, payload?: BroadcastEventMap[T])
}

export interface IDataProvider<T extends BaseModel> {
  /**
   *
   * @throws TmEntityNotFoundError
   */
  findEntityByIdOrFail(id: string, withRelations?: ModelRelationArray<T>): T
  findEntityByIdOrNull(id: string, withRelations?: ModelRelationArray<T>): T | null

  /**
   *
   * @throws TmEntityNotFoundError
   */
  findEntityOrFail(fn: (t: T) => boolean, withRelations?: ModelRelationArray<T>): T
  findEntityOrNull(fn: (t: T) => boolean, withRelations?: ModelRelationArray<T>): T | null
  /**
   *
   * @throws TmEntityNotFoundError
   */
  firstOrFail(): T
  firstOrNull(): T | null
}

export enum PhoneType {
  DEDICATED = 'user_inbound',
  BYOC = 'byoc',
  WHATSAPP = 'whatsapp',
}

export enum ServicePhoneType {
  TM = 'textmagic',
  BYOC = 'byoc',
}

export enum UrlConstraint {
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'doesNotContain',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  EQUALS_TO = 'equalsTo',
  DOES_NOT_EQUAL_TO = 'doesNotEqualTo',
}
export const urlConstraints = Object.values(UrlConstraint)

export enum DeviceType {
  ALL = 'all',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export interface IPermissionsService {
  isActivePermission(permission: TmPermissions): boolean
}

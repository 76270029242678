import { injectable } from 'inversify'
import { RepoSettings } from '@/decorators/repositoryDecorators'
import OrmApiRepository from '@/data/repositories/ormApiRepository'
import type { Endpoint } from '@/services/endpoints'
import SenderSettingsCountry from '@/data/models/domain/SenderSettingsCountry'
import type { SenderSettingsCategory } from '@/data/models/domain/SenderSettingsCountry'
import type { TmCountryCode } from '@/services/types'
import { promisifyVoid } from '@/utils/promisifyVoid'

@RepoSettings<Endpoint>({
  model: SenderSettingsCountry,
})
@injectable()
export default class SenderSettingsCountriesRepository extends OrmApiRepository<SenderSettingsCountry> {
  public async loadCountries() {
    const path = this.endpointsService.getPath('senderSettingsCountries')
    const res =
      await this.getApiSource().get<Record<SenderSettingsCategory, Omit<SenderSettingsCountry, 'category'>[]>>(path)
    const data: SenderSettingsCountry[] = []
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const dataKey in res.data) {
      const dataKeyCast = dataKey as SenderSettingsCategory
      res.data[dataKeyCast].forEach((t) => {
        const val: SenderSettingsCountry = {
          ...t,
          id: t.countryId,
          category: dataKeyCast,
        }
        data.push(val)
      })
    }
    await this.insertOrUpdate(data)
    return this.all()
  }

  public getCountriesByCategories(categories: SenderSettingsCategory[]) {
    return this.all().filter(({ category }) => categories.includes(category))
  }

  public getCountriesByCategory(category: SenderSettingsCategory) {
    return this.getCountriesByCategories([category])
  }

  public async updateSenderSettingsForCountry(country: TmCountryCode, value: string, sourceType?: string) {
    const path = this.endpointsService.getPath('senderSettingsCountries')
    await this.getApiSource().put(path, {
      country,
      value,
      sourceType,
    })
    // @todo better replace on method for get country info by id(if it method will be added BE)
    await this.loadCountries()
  }

  public async restoreDefaultSettingForCountry(country: TmCountryCode) {
    const path = this.endpointsService.getPath('senderSettingsRestoreForCountry')
    await this.getApiSource().put(path, {
      country,
    })
    return promisifyVoid(this.loadCountries())
  }

  public async restoreDefaultSetting() {
    const path = this.endpointsService.getPath('senderSettingsRestore')
    await this.getApiSource().get<unknown>(path)
    return promisifyVoid(this.loadCountries())
  }
}

<template>
  <div class="d-flex mb-7">
    <tm-auto-size>
      <tm-skeleton
        type="QChip"
        width="105px"
        height="36px"
        class="mr-2 flex-shrink-0"
      />
      <tm-skeleton
        type="QChip"
        width="106px"
        height="36px"
        class="mr-2 flex-shrink-0"
      />
      <tm-skeleton
        type="QChip"
        width="175px"
        height="36px"
        class="mr-2 flex-shrink-0"
      />
      <tm-skeleton
        type="QChip"
        width="138px"
        height="36px"
        class="mr-2 flex-shrink-0"
      />
      <tm-skeleton
        type="QChip"
        width="78px"
        height="36px"
        class="flex-shrink-0"
      />
    </tm-auto-size>
  </div>

  <tm-skeleton-details-table />
</template>

<script lang="ts">
import { defineComponent } from '@/composition/vue/compositionApi'
import TmSkeleton from '@/components/shared/skeletons/TmSkeleton.vue'
import TmSkeletonDetailsTable from '@/components/shared/skeletons/TmSkeletonDetailsTable.vue'
import TmAutoSize from '@/components/shared/autoSize/TmAutoSize.vue'

export default defineComponent({
  components: {
    TmSkeleton,
    TmSkeletonDetailsTable,
    TmAutoSize,
  },
})
</script>

/* eslint-disable @typescript-eslint/naming-convention */

export const whatsAppAttachmentMediaFileFormats: Record<string, string | string[]> = {
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.webp': 'image/webp',
  '.3gp': ['video/3gpp', 'audio/3gpp'],
  '.mp4': 'video/mp4',
  '.aac': 'audio/aac',
  '.amr': 'audio/amr',
  '.mp3': 'audio/mpeg',
  '.m4a': 'audio/mp4',
  '.ogg': 'audio/ogg',
}

export const whatsAppAttachmentFileFormats: Record<string, string | string[]> = {
  ...whatsAppAttachmentMediaFileFormats,
  '.pdf': 'application/pdf',
  '.txt': 'text/plain',
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

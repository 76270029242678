import { makeServicesTypesFromEnum } from '@/core/container/makeServicesTypes'

enum RESET_PASSWORD_SERVICES {
  ResetPasswordResolver,
  ResetPasswordFormService,
}

export type ResetPasswordServices = keyof typeof RESET_PASSWORD_SERVICES

export const RESET_PASSWORD_SERVICE_TYPES = makeServicesTypesFromEnum<ResetPasswordServices>(RESET_PASSWORD_SERVICES)

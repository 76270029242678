<template>
  <tm-auto-size
    ref="tmAutoSize"
    v-model:hidden-items="hiddenItemsCount"
    v-model:visible-items="visibleItemsCount"
    :full-width="fullWidth"
    :extra-offset-for-focus="extraOffsetForFocus"
    :scroll-class="scrollClass"
    :class="{
      'justify-start': align === 'left',
      'justify-end': align === 'right',
    }"
    @update:visible-items="$emit('update:visible-items', $event)"
  >
    <slot />

    <div
      :class="[
        dropdownWrapperClass,
        tmAutoSizeIgnoreClass,
        { 'd-none': hiddenItemsCount <= 0 },
        { 'ml-2': visibleItemsCount > 1 },
      ]"
    >
      <tm-dropdown
        ref="dropdownRef"
        v-model="dropdownState"
        :position="dropdownPosition"
        :close-on-content-click="dropdownCloseOnClick"
        :close-on-outside-click="dropdownCloseOnOutsideClick"
        @show="updateHiddenItems"
      >
        <slot
          name="dropdown-activator"
          v-bind="{ visibleItemsCount }"
        >
          <tm-button
            :flat="btnFlat"
            :icon-only="btnFlat"
            :size="btnSize"
            :color="btnColor"
            :no-border="btnNoBorder"
          >
            <tm-icon
              name="more_vert"
              :left="!hideText"
              :size="iconSize"
            />
            <span v-if="!hideText">
              {{ $t('common.more') }}
            </span>
            <tm-tooltip v-if="dropdownTooltip">
              {{ dropdownTooltip }}
            </tm-tooltip>
          </tm-button>
        </slot>

        <template v-slot:menu>
          <div
            ref="dropdownMenu"
            :class="tmAutoSizePanelParentClass"
          >
            <slot />
          </div>
        </template>
      </tm-dropdown>

      <slot name="dropdown-after" />
    </div>
  </tm-auto-size>
</template>

<script lang="ts">
import { computed, type PropType, useTemplateRef } from '@/composition/vue/compositionApi'
import { defineComponent, ref, watch } from '@/composition/vue/compositionApi'
import TmAutoSize from '@/components/shared/autoSize/TmAutoSize.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import { tmAutoSizeIgnoreClass, tmAutoSizePanelParentClass, prepareData } from '@/components/shared/autoSize/types'
import type { SizeProp, ButtonSize, ButtonColor, IconSize } from '@/components/shared/types'
import TmIcon from '@/components/shared/TmIcon.vue'
import type { ControlPosition } from '@/composition/tooltip/renderTooltip'
import TmTooltip from '@/components/shared/tooltip/TmTooltip.vue'
import TmLogicError from '@/core/error/tmLogicError'

export default defineComponent({
  components: {
    TmTooltip,
    TmIcon,
    TmAutoSize,
    TmButton,
    TmDropdown,
  },
  props: {
    align: {
      type: String as PropType<'left' | 'right'>,
    },
    btnFlat: {
      type: Boolean,
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
      default: 'medium',
    },
    btnSize: {
      type: String as SizeProp<ButtonSize>,
    },
    hideText: {
      type: Boolean,
    },
    btnColor: {
      type: String as PropType<ButtonColor>,
    },
    btnNoBorder: {
      type: Boolean,
    },
    dropdownCloseOnClick: {
      type: Boolean,
      default: true,
    },
    dropdownCloseOnOutsideClick: {
      type: Boolean,
    },
    dropdownModelValue: {
      type: Boolean,
    },
    fullWidth: {
      type: Boolean,
    },
    extraOffsetForFocus: {
      type: Boolean,
    },
    dropdownWrapperClass: {
      type: String,
    },
    dropdownPosition: {
      type: String as PropType<ControlPosition>,
    },
    scrollClass: {
      type: String,
    },
    dropdownTooltip: {
      type: String,
    },
  },
  emits: {
    'update:dropdown-model-value': (_val: boolean) => true,
    'update:visible-items': (_val: number) => true,
  },
  setup(props, context) {
    const hiddenItemsCount = ref(0)
    const visibleItemsCount = ref(0)

    const dropdownRef = useTemplateRef<InstanceType<typeof TmDropdown>>('dropdownRef') // for use in parent components
    const dropdownMenu = useTemplateRef<HTMLDivElement>('dropdownMenu')
    const tmAutoSize = useTemplateRef<InstanceType<typeof TmAutoSize>>('tmAutoSize')

    const listRef = computed(() => tmAutoSize.value?.listRef)

    const updateHiddenItems = () => {
      if (!dropdownMenu.value) {
        return
      }

      const children: HTMLElement[] = prepareData([...dropdownMenu.value.children].reverse() as HTMLElement[])

      children.forEach((e: HTMLElement, i) => {
        if (i < hiddenItemsCount.value) {
          e.classList.remove('d-none')
        } else {
          e.classList.add('d-none')
        }
      })
    }

    const updateVisibleChildren = () => {
      if (!tmAutoSize.value) {
        throw new TmLogicError('tmAutoSize is null')
      }
      return tmAutoSize.value.updateVisibleChildren()
    }

    watch(() => hiddenItemsCount.value, updateHiddenItems)

    const dropdownState = computed({
      get() {
        return props.dropdownModelValue
      },
      set(val) {
        context.emit('update:dropdown-model-value', val)
      },
    })

    return {
      visibleItemsCount,
      hiddenItemsCount,
      tmAutoSizeIgnoreClass,
      tmAutoSizePanelParentClass,
      dropdownRef,
      dropdownMenu,
      listRef,
      updateHiddenItems,
      dropdownState,
      tmAutoSize,
      // public
      updateVisibleChildren,
    }
  },
})
</script>
